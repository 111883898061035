import { curry } from 'ramda';
import {
  initUseStateDataType,
  getData,
  updateState
} from '../../../lib/data-hook';

/*
 * Hook change view Text Editor for display text what you wrote
 */

const NO_SELECTED = 'NONE';
const EDITOR = 'EDITOR';
const VIEWER = 'VIEWER';
const makeCustomEditorMode = () => ({
  type: 'CustomEditorMode',
  mode: NO_SELECTED
});
const setModeCEM = curry((mode, customEditorMode) => ({
  ...customEditorMode,
  mode
}));
const getModeCEM = (customEditorMode) => customEditorMode.mode;
const isModeNoSelectedCEM = (customEditorMode) =>
  getModeCEM(customEditorMode) === NO_SELECTED;
const isModeEditorCEM = (customEditorMode) =>
  getModeCEM(customEditorMode) === EDITOR;
const isModeViewerCEM = (customEditorMode) =>
  getModeCEM(customEditorMode) === VIEWER;

export const useCustomEditorMode = initUseStateDataType(makeCustomEditorMode);
export const getModeCustomEditorMode = getData(getModeCEM);
export const isModeNoSelected = getData(isModeNoSelectedCEM);
export const isModeEditor = getData(isModeEditorCEM);
export const isModeViewer = getData(isModeViewerCEM);
export const toEditor = updateState(setModeCEM, EDITOR);
export const toViewer = updateState(setModeCEM, VIEWER);
