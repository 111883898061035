import React from 'react';
import {
  Modal,
  Col,
  Form,
  FormGroup,
  FormControl,
  ControlLabel,
  Button
} from 'react-bootstrap';
import { connect } from 'react-redux';
import { translate } from 'react-i18next';
import * as modalActionCreators from '../../../../../action-creators/modal';
import * as featureActionCreators from '../../../../../action-creators/product/feature';
import * as servicesAction from '../../../../../action-creators/services';

const SingleProductCharacteristicsModal = translate(['ui'], { wait: true })(
  class extends React.Component {
    componentWillUnmount() {
      const { clearValidator } = this.props;
      clearValidator();
    }

    getProperty(field) {
      return this.props.feature.has(field) ? this.props.feature.get(field) : '';
    }

    validationState(field) {
      return this.props.validator &&
        this.props.validator.hasIn(['invalidFields', field])
        ? { validationState: 'error' }
        : {};
    }

    asterisk() {
      return <span style={{ color: 'red' }}>*</span>;
    }

    allowSave() {
      const { isCreate, saveDisabled, feature, listOfFeatures } = this.props;
      const featureId = feature && feature.get('id');

      if (isCreate && !featureId) return !saveDisabled;

      if (!featureId) return false;
      const currentFeature = listOfFeatures.find(
        (f) => f.get('id') === featureId
      );

      return !(currentFeature.equals(feature) || saveDisabled);
    }

    render() {
      const {
        t,
        captionKey,
        isCreate,
        featureId,
        hideModalDialog,
        updateProductFeatureField,
        trimProductFeatureField,
        createProductFeature,
        updateProductFeature,
        callback = () => ({}),
        gaSend,
        user
      } = this.props;

      const submitAction = isCreate
        ? createProductFeature
        : updateProductFeature.bind(this, featureId);

      const inputProps = (field) => ({
        value: this.getProperty(field),
        onChange: (e) => updateProductFeatureField(field, e.target.value),
        onBlur: () => trimProductFeatureField(field)
      });

      return (
        <Modal
          id="productCharacteristicsModalWindow"
          onHide={() => hideModalDialog()}
          show>
          <Modal.Header>
            <Modal.Title>
              {t(captionKey)}
              <span
                className="modal-header-cross pull-right"
                onClick={() => hideModalDialog()}
              />
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form horizontal>
              <FormGroup controlId="name" {...this.validationState('name')}>
                <Col
                  componentClass={ControlLabel}
                  xs={5}
                  style={{ textAlign: 'left', marginTop: 25 }}>
                  {t('Name')}
                  {this.asterisk()}
                </Col>
                <Col xs={7}>
                  <h6 className="pull-right" style={{ marginTop: 5 }}>
                    <small>
                      {this.getProperty('name').length}
                      /30
                    </small>
                  </h6>
                  <FormControl
                    type="text"
                    maxLength={30}
                    {...inputProps('name')}
                  />
                </Col>
              </FormGroup>
              <FormGroup controlId="value" {...this.validationState('value')}>
                <Col
                  componentClass={ControlLabel}
                  xs={5}
                  style={{ textAlign: 'left' }}>
                  {`${t('Value')} `}
                  {this.asterisk()}
                </Col>
                <Col xs={7}>
                  <FormControl type="text" {...inputProps('value')} />
                </Col>
              </FormGroup>
              <FormGroup controlId="unit">
                <Col
                  componentClass={ControlLabel}
                  xs={5}
                  style={{ textAlign: 'left' }}>
                  {t('Units')}
                </Col>
                <Col xs={7}>
                  <FormControl type="text" {...inputProps('unit')} />
                </Col>
              </FormGroup>
              <FormGroup controlId="unit" style={{ marginBottom: 0 }}>
                <Col
                  componentClass={ControlLabel}
                  xs={5}
                  style={{
                    fontSize: '12px',
                    color: 'lightgrey',
                    textAlign: 'left'
                  }}>
                  {this.asterisk()}
                  {` ${t('Required fields')}`}
                </Col>
              </FormGroup>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={() => hideModalDialog()} className="pull-left">
              {t('Cancel')}
            </Button>
            <Button
              id="saveCharacteristicButton"
              bsStyle="primary"
              disabled={!this.allowSave()}
              onClick={async () => {
                await gaSend({
                  category: 'Supplier',
                  action: 'new_add_feature_success',
                  label: user.get('email')
                });
                return submitAction().then(hideModalDialog).then(callback);
              }}>
              {t('Save')}
            </Button>
          </Modal.Footer>
        </Modal>
      );
    }
  }
);

const mapStateToProps = (state) => {
  const feature = state.getIn(['product', 'item', 'feature']);
  return {
    user: state.getIn(['user', 'user']),
    catalog: state.getIn(['catalog', 'item']),
    category: state.getIn(['category', 'item']),
    product: state.getIn(['product', 'item']),
    validator: state.get('validator'),
    feature,
    listOfFeatures: state.getIn(['tables', 'features', 'items']),
    saveDisabled: !(feature && feature.get('name') && feature.get('value'))
  };
};

export default connect(mapStateToProps, {
  ...modalActionCreators,
  ...featureActionCreators,
  gaSend: servicesAction.gaSend
})(SingleProductCharacteristicsModal);
