import { getFirstSelectedItem } from 'core/data/partList';
import { getId, getNumber } from 'core/data/order';
import { equalPrevName } from 'core/data/light/modalManager';
import * as getterContact from '../modules/contact/getter';
import * as getterDialog from '../modules/dialog/getter';
import { getModalManager } from '../storeGetters';

import { showModal, showPrevModal } from '../action-creators/modal';

export const confirmDeleteSelectedOrders = () => (dispatch) =>
  dispatch(
    showModal('CONFIRM', {
      title: 'Delete order',
      text: 'Deleted order can not be restored Delete the order',
      btnTextConfirm: 'Delete',
      useCaseName: 'removeSelectedOrders'
    })
  );

export const confirmDeleteOrder = (id) => (dispatch) =>
  dispatch(
    showModal('CONFIRM', {
      title: 'Delete order',
      text: 'Deleted order can not be restored Delete the order',
      btnTextConfirm: 'Delete',
      useCaseName: 'removeOrder',
      args: id
    })
  );

export const confirmCopyOrderToSupplier = () => (dispatch, getState) => {
  const order = getFirstSelectedItem(getState().get('order'));
  dispatch(
    showModal('CONFIRM', {
      title: 'Copy order',
      text: 'To create a new order based on the order №',
      textArgs: { number: getNumber(order) },
      btnTextConfirm: 'Copy',
      useCaseName: 'copyOrder',
      args: getId(order)
    })
  );
};

export const toggleBlockContact =
  ({ from = 'contact', dialogId } = {}) =>
  (dispatch, getState) => {
    let modalArgs;
    const state = getState();
    const getter = from === 'dialog' ? getterDialog : getterContact;

    const name = getter.getName(state, dialogId);
    const employeeId = getter.getEmployeeId(state, dialogId);

    if (getter.isBlockedContact(state, dialogId)) {
      modalArgs = {
        title: 'modals.confirm_unblock_contact.header',
        text: 'modals.confirm_unblock_contact.desc',
        textArgs: { name },
        btnTextConfirm: 'Unblock',
        useCaseName: 'toggleBlock',
        args: { employeeId }
      };
    } else {
      modalArgs = {
        title: 'modals.confirm_block_contact.header',
        titleArgs: { name },
        text: 'modals.confirm_block_contact.desc',
        btnTextConfirm: 'Block',
        useCaseName: 'toggleBlock',
        args: { employeeId }
      };
    }

    dispatch(showModal('CONFIRM', modalArgs));
  };

export const showPrevIfHasOneName = (names) => (dispatch, getState) => {
  const modalManager = getModalManager(getState());

  const isShownPrevModal = names.some((name) =>
    equalPrevName(name, modalManager)
  );

  if (isShownPrevModal) {
    dispatch(showPrevModal());
  }
};
