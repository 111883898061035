import React from 'react';
import cn from 'classnames';
import Button from './components/Button/Button';
import Status from '../Status';
import classes from './SuppliersActivities.module.css';
import ResponsesInfo from './components/ResponsesInfo/ResponsesInfo';

const SuppliersActivities = ({
  requestId,
  requestStatus,
  responsesCount,
  newResponsesCount,
  className,
  isCustomerView = false,
  noHover = false
}) => (
  <div className={cn(classes.wrapper, noHover && classes.noHover, className)}>
    <div className={classes.content}>
      <div className={classes.info}>
        <Status
          className={classes.status}
          iconClassName={classes.statusIcon}
          textClassName={classes.statusText}
          status={requestStatus}
        />
        {isCustomerView && (
          <>
            &nbsp;
            <ResponsesInfo
              count={responsesCount}
              newCount={newResponsesCount}
            />
          </>
        )}
      </div>
      {isCustomerView && (
        <div className={classes.button}>
          <Button requestId={requestId} />
        </div>
      )}
    </div>
  </div>
);

export default SuppliersActivities;
