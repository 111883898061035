import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { translate, Interpolate } from 'react-i18next';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import { withRouter } from '../../hoc/withRouter';

import Table from '../../components/TableNew';

import * as modalActions from '../../action-creators/modal';
import * as companyActions from '../../action-creators/company';
import * as serviceActions from '../../action-creators/services';

class TableOfInviteEmployees extends Component {
  constructor(props) {
    super(props);
    this.showModalAddEmployee = this.showModalAddEmployee.bind(this);
  }

  componentWillUnmount() {
    this.props.clearSelectedInvite();
  }

  get columns() {
    const { havePermissionToUpdate, t, resendInvite } = this.props;

    // const employeesLimit = ['unlim'].includes(company.getIn(['billing', 'tariff'])) ? 999999999
    //   : company.getIn(['billing', 'tariffInfo', 'employees']) + (company.getIn(['billing', 'additions', 'employees']) || 0);

    const list = [
      Table.ColText({
        accessor: 'to',
        title: t('Email'),
        width: '70%'
      })
    ];

    if (havePermissionToUpdate) {
      list.push(
        Table.ColText({
          showTooltip: false,
          accessor: ({ to }) =>
            true ? (
              <button
                type="button"
                className="btn pull-right"
                id="resend"
                onClick={() => resendInvite(to)}>
                {t('Resend')}
              </button>
            ) : (
              <OverlayTrigger
                trigger={['hover']}
                placement="left"
                overlay={
                  <Popover
                    style={{ transitionDelay: '.4s' }}
                    id="popover-positioned-left">
                    {t('tariff_limit_txt')}
                  </Popover>
                }>
                <div style={{ textAlign: 'right', cursor: 'pointer' }}>
                  {t('Resend')}
                </div>
              </OverlayTrigger>
            )
        })
      );
    }
    return list;
  }

  showModalAddEmployee() {
    const { t, showModal, hideModalDialog, sendInvite } = this.props;
    showModal('INVITE_EMPLOYEE', {
      title: 'Invite',
      text: t('Invite employee text'),
      hideModalDialog,
      sendInvite
    });
  }

  render() {
    const {
      t,
      user,
      selectInvite,
      selectedInvites,
      invites,
      havePermissionToUpdate
    } = this.props;

    const dataIsSelected = invites.map((invite) => {
      const id = invite.get('id');
      const isInviteSelected = selectedInvites && selectedInvites.has(id);
      return isInviteSelected
        ? invite.set('isSelected', true)
        : invite.set('isSelected', false);
    });

    // const employeesLimit = ['unlim'].includes(company.getIn(['billing', 'tariff'])) ? 999999999
    //   : company.getIn(['billing', 'tariffInfo', 'employees']) + (company.getIn(['billing', 'additions', 'employees']) || 0);

    return (
      <Table
        columns={this.columns}
        ifEmpty={
          <div className="text-description">
            <Interpolate
              i18nKey="empty_company_invite"
              addEmployee={
                user.get('type') === 'demo' || !havePermissionToUpdate ? (
                  <span>{t('add_employee')}</span>
                ) : (
                  <b>
                    <a onClick={this.showModalAddEmployee}>
                      {t('add_employee')}
                    </a>
                  </b>
                )
              }
            />
          </div>
        }
        onSelect={havePermissionToUpdate && selectInvite}
        data={dataIsSelected}
      />
    );
  }
}

TableOfInviteEmployees.propTypes = {
  t: PropTypes.func.isRequired
};

export default withRouter(
  connect(
    (state) => ({
      user: state.getIn(['user', 'user']),
      company: state.get('company'),
      ownerUserId: state.getIn(['company', 'ownerUserId']),

      selectedInvites: state.getIn(['company', 'selectedInvites']),
      invites: state.getIn(['company', 'invites']),

      havePermissionToUpdate: state.getIn([
        'user',
        'user',
        'permissions',
        'employees',
        'update'
      ])
    }),
    (dispatch) =>
      bindActionCreators(
        {
          showModal: modalActions.showModal,
          hideModalDialog: modalActions.hideModalDialog,

          getInvites: companyActions.getInvites,
          sendInvite: companyActions.sendInvite,
          selectInvite: companyActions.selectInvite,
          removeSelected: companyActions.removeSelectedInvite,
          clearSelectedInvite: companyActions.clearSelectedInvite,

          gaSend: serviceActions.gaSend,
          ...companyActions
        },
        dispatch
      )
  )(translate(['ui'], { wait: true })(TableOfInviteEmployees))
);
