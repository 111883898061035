import { setInfoMessage, setSuccessMessage } from '../action-creators/message';
import {
  updateInternetConnection,
  updateServerConnection
} from '../action-creators/connections';
import { isMessengerService } from '../lib/goodwix';
import {
  onInterruptedConnection,
  onOffline,
  onOnline,
  onRestoredConnection
} from '../lib/internetConnection';
import {
  socketClosed,
  socketConnected,
  socketServerClosed
} from '../lib/serverConnection';
import { getChatMode } from '../components/ChatWidget/getter';
import { prevChatMode } from '../components/ChatWidget/modules/chatMode';
import { isMessengerServiceText } from '../components/ChatWidget/useCases/utils';

const reloadPage = () => location.reload();

let IS_FIRST_RENDER = true;

export const connectionHandler = () => (dispatch, getState) => {
  onInterruptedConnection(() => {
    showTryToConnectMessage();
  });
  onRestoredConnection(() => hideTryToConnectMessage());
  onOffline(() => {
    dispatch(updateInternetConnection(false));
  });
  onOnline(() => {
    handleReconnect();
  });
  socketClosed(() => {
    showServerDisconnected();
    dispatch(updateServerConnection(false));
  });
  socketConnected(() => {
    if (!IS_FIRST_RENDER) {
      handleReconnect();
      showServerConnected();
    }
    IS_FIRST_RENDER = false;
  });
  socketServerClosed(() => {
    showServerDisconnected();
    dispatch(updateServerConnection(false));
  });

  return undefined;

  function clearNoInternetConnectionPlugChat() {
    const { currentMode } = getChatMode(getState());

    if (currentMode === 'no-connection-internet') {
      dispatch(prevChatMode());
    }
  }

  function showTryToConnectMessage() {
    dispatch(
      setInfoMessage(
        {
          key: `Messenger ${isMessengerServiceText()} is trying to connect to the Internet`
        },
        false
      )
    );
  }

  function showServerDisconnected() {
    dispatch(
      setInfoMessage(
        { key: `${isMessengerServiceText()} service disconnect socket` },
        false
      )
    );
  }

  function showServerConnected() {
    dispatch(
      setSuccessMessage({
        key: 'Goodwix service connect socket',
        linkKey: 'Refresh the page',
        linkClickHandler: reloadPage
      })
    );
  }

  function hideTryToConnectMessage() {
    dispatch(setInfoMessage());
  }

  function handleReconnect() {
    const key = isMessengerService()
      ? 'Messenger IstockLink restored Internet connection'
      : 'Goodwix restored Internet connection';
    dispatch(updateInternetConnection(true));
    dispatch(updateServerConnection(true));
    clearNoInternetConnectionPlugChat();
    dispatch(
      setSuccessMessage({
        key,
        linkKey: 'Refresh the page',
        linkClickHandler: reloadPage
      })
    );
  }
};
