import React, { useEffect, useMemo, useState, useRef } from 'react';
import DOMPurify from 'dompurify';
import { ControlLabel, Form } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { useActions, useLocation } from '@hooks';
import { equals, isEmpty, isNil } from 'ramda';
import { useNavigate } from 'react-router-dom';
import { RequestWithConfirmationButtonsBlock } from '../../../../components/PurchaseRequests/Request/components/RequestWithConfirmationButtons';
import GoBack from '../../../../components/Buttons/GoBack';
import {
  formatRequestNumber,
  isNilOrEmpty,
  noop
} from '../../../../utils/utils';
import SuppliersActivities from '../../../../components/SuppliersActivities';
import {
  countNewResponses,
  getResponses
} from '../../../../components/PurchaseRequests/MyRequests/RequestTable/utils';
import SectionOffset from '../../../../components/PurchaseRequests/Request/SectionOffset';
import { useTranslate } from '../../../../TranslateProvider';
import styles from './withConfirmationRequestContent.module.css';
import BtnIcon from '../../../../components/ChatWidget/ButtonIcon';

import ApprovalLog from '../../../../components/PurchaseRequests/ApprovalLog';
import {
  executorActionTypes,
  purchaseRequestTypes
} from '../../../../components/PurchaseRequests/domain/entities';
import GeneralInfo from '../../../../components/PurchaseRequests/common/GeneralInfo/GeneralInfo';
import { MODES } from '../../../../components/PurchaseRequests/common/constants';

import FilesBlock from '../../../../components/PurchaseRequests/Request/FilesBlock';

import { makeLinkVM } from '../../../../components/PurchaseRequests/Request/linksVM';
import { downloadFile } from '../../../../components/PurchaseRequests/Request/files';
import { makeFileVM } from '../../../../components/PurchaseRequests/Request/filesVM';
import * as modalActions from '../../../../action-creators/modal';
import * as storeGetters from '../../../../storeGetters';
import ProductsTable from '../../../../components/PurchaseRequests/common/ProductsTable';
import LinksListBlock from '../../../../components/PurchaseRequests/Request/LinksListBlock';
import {
  convertFileUrl,
  convertFileUrls,
  convertUrlToFile
} from '../../../../components/ChatWidget/modules/files/utils';
import { makeBtnSmallIcon } from '../../../../components/PurchaseRequests/Request/btnSmall';
import { showFilesViewer } from '../../../../utils/filesViewerInterfaceCreator';
import { useSelectIdsWithCheckbox } from '../../../../components/PurchaseRequests/NewRequest/hooks/useSelectIdsWithCheckbox';
import { PublishOrCaseContext } from '../../publishOrCaseContext';
import { PurchaseRequestMapper } from '../../../../components/PurchaseRequests/infrastructure';
import { purchaseRequestService } from '../../../../api';

// TODO: Нужен рефакторинг

const MIN_TITLE_LENGTH = 4;

const getIsResponsePage = (location) => location.search.includes('response');

const BtnCollapse = ({ isCollapsed, onClick }) => {
  const icon = isCollapsed ? 'arrow-down-circle' : 'arrow-up-circle';
  return <BtnIcon icon={icon} width={34} height={34} onClick={onClick} />;
};

const initialErrorState = {
  save: false,
  publish: false
};

export const ACTION_TYPES = {
  SAVE: 'SAVE',
  PUBLISH: 'PUBLISH'
};

export const WithConfirmationRequestContent = () => {
  const navigate = useNavigate();

  const location = useLocation();

  const t = useTranslate();

  const { showModal } = useActions(modalActions);

  const request = useSelector(storeGetters.getCurrentPurchaseRequest).toJS();
  const user = useSelector(storeGetters.getCurrentUser);
  const company = useSelector(storeGetters.getCompany);
  const [categoriesId, setCategoriesId] = useState(request.categoriesId || []);
  const [title, setTitle] = useState(request.title || '');
  const [date, setDate] = useState(request.date || '');
  const [country, setCountry] = useState(request.country?.id || '');
  const [region, setRegion] = useState(request.region?.id || '');
  const [city, setCity] = useState(request.city?.id || '');
  const [supplierRequirements, setSupplierRequirements] = useState(
    request.supplierRequirements || ''
  );
  const [responseEndDate, setResponseEndDate] = useState(
    request.responseEndDate || null
  );
  const [responsibleUser, setResponsibleUser] = useState(
    request.responsibleUser?.id || null
  );

  const [initialData, setInitialData] = useState(null);

  const [number, setNumber] = useState(request.number || null);
  const [authorId, setAuthorId] = useState(request.authorId || null);
  const [isErrorShown, setIsErrorShown] = useState(initialErrorState);

  useEffect(() => {
    const pr = generatePurchaseRequest();
    const { ...plainObject } = pr;
    setInitialData(plainObject);
  }, [request.updatedAt, authorId]);

  const typeaheadRef = useRef(null);
  const typeaheadTitleKey = 'purchase-request-title';

  useEffect(() => {
    if (!isNil(request)) {
      const defaultLocation = (localStorage.getItem('pr-location') || '').split(
        ';'
      );
      const defaultCountry = country || defaultLocation[0];
      const defaultRegion = region || defaultLocation[1];
      const defaultCity = city || defaultLocation[2];
      setCategoriesId(request.categoriesId || []);
      setTitle(request.title || '');
      setDate(request.date || '');
      setRegion(request.region?.id || defaultRegion || '');
      setCity(request.city?.id || defaultCity || '');
      setCountry(request.country?.id || defaultCountry || '');
      setSupplierRequirements(request.supplierRequirements || '');
      setResponseEndDate(request.responseEndDate || null);
      setResponsibleUser(request.responsibleUser?.id || user.get('id'));
      setNumber(request.number || null);
      setAuthorId(request.authorId || null);
    }
  }, [JSON.stringify(request)]);

  useEffect(() => {
    const categories = company?.get('categoriesId');
    if (isEmpty(categoriesId) && categories) {
      setCategoriesId(categories);
    }
  }, [company]);

  const {
    selectedList: accessCompanyList,
    setSelectedList: setAccessCompanyList,
    isChecked: isShowForAllCompanies,
    onToggle: onToggleShowForAllCompanies
  } = useSelectIdsWithCheckbox({
    initialCheckboxValue:
      isNil(request.companyId) || isEmpty(request.accessCompanyList),
    initialSelectedList: request.accessCompanyList
  });

  const body = {
    categoriesId,
    title,
    date,
    country,
    region,
    city,
    supplierRequirements,
    responseEndDate,
    responsibleUser,
    number,
    accessCompanyList,
    isShowForAllCompanies,
    authorId
  };

  const setBody = {
    setCategoriesId,
    setTitle,
    setDate,
    setCountry,
    setRegion,
    setCity,
    setSupplierRequirements,
    setResponseEndDate,
    setResponsibleUser,
    setNumber,
    setAccessCompanyList,
    onToggleShowForAllCompanies,
    setAuthorId
  };

  function generatePurchaseRequest() {
    return PurchaseRequestMapper.toDomain({
      ...request,
      ...body
    });
  }

  const [isCollapsed, setIsCollapsed] = useState(getIsResponsePage(location));
  const onToggleCollapse = () => setIsCollapsed((prevState) => !prevState);

  function runValidation(type) {
    switch (type) {
      case ACTION_TYPES.PUBLISH:
        return runPublishValidation();
      case ACTION_TYPES.SAVE:
        return runSaveValidation();
      default:
        console.error('Unknown action type');
    }
  }

  const isRequestFromCurrentCompany =
    request.size > 0 && request.companyId === company.get('id');

  const isTitleValid = () => title.trim().length >= MIN_TITLE_LENGTH;

  function isFieldsValid() {
    return (
      number.trim() &&
      isTitleValid() &&
      country &&
      region &&
      categoriesId.length > 0 &&
      responsibleUser &&
      responseEndDate
    );
  }
  const isSaveFieldsValid = () => isTitleValid();

  function runSaveValidation() {
    if (!isSaveFieldsValid()) {
      setIsErrorShown((prevState) => ({ ...prevState, save: true }));
      document.getElementById('title-form').scrollIntoView();
      return false;
    }
    setIsErrorShown(initialErrorState);
    return true;
  }

  function runPublishValidation() {
    if (!isFieldsValid()) {
      setIsErrorShown((prevState) => ({ ...prevState, publish: true }));
      document.getElementById('title-form').scrollIntoView();
      return false;
    }
    setIsErrorShown(initialErrorState);
    return true;
  }

  const isShowApprovalLog = !isNilOrEmpty(request.executors);

  useEffect(() => {
    const categories = company.categoriesId;
    if (isEmpty(categoriesId) && categories) {
      setCategoriesId(categories);
    }
  }, [company]);

  const files = useMemo(() => {
    if (!request.attachments) return [];
    const { attachments } = request;
    const fileInfoArray = convertFileUrls(
      convertFileUrls(attachments.map((f) => f.fileInfo)),
      'originalUrl'
    );

    return attachments.map((file) =>
      makeFileVM({
        key: file.fileInfo.id,
        createdAt: file.fileInfo.createdAt,
        name: file.fileInfo.name,
        size: file.fileInfo.size,
        buttons: [
          makeBtnSmallIcon({
            name: 'download',
            icon: 'download-1',
            onClick: () =>
              downloadFile({
                ...file,
                url: convertUrlToFile(file.fileInfo.originalUrl)
              })
          }),
          makeBtnSmallIcon({
            name: 'preview',
            icon: 'view',
            onClick: () => {
              showFilesViewer({
                showModal,
                files: fileInfoArray,
                file: convertFileUrl(file.fileInfo, 'originalUrl')
              });
            }
          })
        ]
      })
    );
  }, [request]);

  const products = useMemo(() => {
    if (!request.products) return [];
    return request.products.map((product) =>
      !product.price ? { ...product, price: 0 } : product
    );
  }, [request]);

  const exportToExcelBtn = {
    icon: {
      name: 'excel',
      size: 18
    },
    tkeyText: 'respondForm.form.btn-export-to-excel',
    onClick: async () => purchaseRequestService.exportRequestToExcel(request.id)
  };

  const links = useMemo(() => {
    if (!request?.links) return [];
    return request.links.map((link) =>
      makeLinkVM({
        ...link,
        key: `${link.id}l`
      })
    );
  }, [request]);

  function hasChanges() {
    const pr = generatePurchaseRequest();
    const { ...plainObject } = pr;
    return !equals(initialData, plainObject);
  }

  function goBack() {
    navigate('/requests/my');
  }

  const showSimpleSubmitModal = () =>
    showModal('SIMPLE_SUBMIT', {
      title: 'purchaseRequest.modals.cancelPublication.title',
      text: 'purchaseRequest.modals.cancelPublication.content',
      textBtnConfirm: 'modals.add-link.btn-confirm',
      textBtnCancel: 'modals.add-link.btn-cancel',
      onSubmited: goBack,
      submitAction: noop
    });

  const onCancelAction = async () => {
    if (hasChanges()) {
      showSimpleSubmitModal();
    } else {
      goBack();
    }
  };

  return (
    <>
      <Form className={styles.formRequest}>
        <div style={{ position: 'absolute', top: 4, left: -60 }}>
          <GoBack
            dataTestid="request-go-back-button"
            onClick={onCancelAction}
          />
        </div>
        <div className="request-title">
          <div style={{ width: '100%' }}>
            <div className="request-title-row">
              <div
                className="request-title-row__title"
                data-testid="request-purchase-request">
                {t('Purchase request')}
                {` № ${formatRequestNumber(request.id)} - ${
                  request.number || formatRequestNumber(request.id)
                }`}
              </div>
              {/* TODO Оля сказала что этих кнопок не должно быть */}
              {/* {true && <ShareDropdown requestId={request.id} />} */}
              <div
                className="request-title-row__status"
                data-testid="request-purchase-status">
                <SuppliersActivities
                  requestId={request.id}
                  requestStatus={request.status}
                  responsesCount={
                    getResponses({ responses: request.responses || [] }).length
                  }
                  newResponsesCount={countNewResponses(request.responses || [])}
                  isCustomerView={isRequestFromCurrentCompany}
                  noHover
                />
              </div>
              <BtnCollapse
                isCollapsed={isCollapsed}
                onClick={onToggleCollapse}
              />
            </div>
            <span style={{ color: '#767B92' }}>
              {request.sum && <div>{`${request.sum} p.`}</div>}
            </span>
          </div>
        </div>
        <hr style={{ opacity: '0.3' }} />

        {!isCollapsed && (
          <>
            {isShowApprovalLog && (
              <ApprovalLog
                isCollapsed={false}
                className="request-card-approval-log"
                request={request}
              />
            )}
            <SectionOffset offset={20} />
            {request.executorType === executorActionTypes.publication ? (
              <GeneralInfo
                request={request}
                isErrorShown={isErrorShown}
                setBody={setBody}
                body={body}
                requestType={purchaseRequestTypes.regular}
                typeaheadRef={typeaheadRef}
                typeaheadTitleKey={typeaheadTitleKey}
              />
            ) : (
              <GeneralInfo mode={MODES.readonly} />
            )}

            <hr style={{ opacity: '0.3' }} />

            <ControlLabel
              data-testid="request-purchase-content-label"
              className="control-label-row-request">
              {t('content')}
            </ControlLabel>
            <section
              className="request-row-content description"
              data-testid="request-purchase-products-list"
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(request.productsList)
              }}
            />

            {Boolean(request?.products?.length) && (
              <ProductsTable
                products={products}
                editMode={false}
                exportToExcelBtn={exportToExcelBtn}
                isShowExportToExcelBtn={false}
              />
            )}

            {!request?.products?.length > 0 && request.sum && (
              <>
                <SectionOffset offset={20} />
                <div
                  style={{ display: 'flex', justifyContent: 'space-between' }}>
                  {t('Purchase price')}
                  &nbsp;
                  {request.sum}
                </div>
              </>
            )}

            {request?.supplierRequirements && (
              <div>
                <div className="row-delimiter" />
                <ControlLabel
                  className="control-label-row-request"
                  data-testid="request-purchase-supplier-label">
                  {t('Supplier requirements')}
                </ControlLabel>
                <div
                  className="request-row-content"
                  data-testid="request-purchase-supplier-requirements">
                  {request.supplierRequirements}
                </div>
              </div>
            )}

            <SectionOffset offset={20} />

            {Boolean(files?.length) && (
              <FilesBlock total={files.length} files={files} />
            )}

            <SectionOffset offset={20} />

            {Boolean(links?.length) && (
              <LinksListBlock total={links.length} links={links} />
            )}

            <SectionOffset offset={28} />
          </>
        )}
        <PublishOrCaseContext.Provider
          value={{
            runValidation,
            generatePurchaseRequest,
            onCancelAction,
            hasChanges
          }}>
          <RequestWithConfirmationButtonsBlock
            type={request?.executorType}
            canExecute={request.canExecute}
          />
        </PublishOrCaseContext.Provider>
      </Form>
    </>
  );
};
