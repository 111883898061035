import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { translate } from 'react-i18next';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Modal } from 'react-bootstrap';
import { ModalContainer } from '../elements';
import Helper from '../../Helper';
import './styles.css';

import * as modalActions from '../../../action-creators/modal';
import * as partnersActions from '../../../action-creators/partners';
import * as servicesAction from '../../../action-creators/services';

class ModalInvitePartner extends Component {
  constructor(props) {
    super(props);
    this.copyLink = this.copyLink.bind(this);
  }

  async UNSAFE_componentWillMount() {
    const { getToken } = this.props;
    await getToken();
  }

  copyLink = () => {
    const { userEmail, gaSend } = this.props;
    gaSend({
      category: 'Common',
      action: 'сhat_add_partner_copy',
      label: userEmail
    });
    const inputElement = document.getElementById('modal-add-partner-input');
    inputElement.select();
    document.execCommand('copy');
  };

  render() {
    const { t, hideModal, token, refreshToken } = this.props;

    return (
      <ModalContainer mods={{ theme: 'default' }}>
        <Modal id="addContact" onHide={hideModal} show>
          <Modal.Header style={{ paddingBottom: 0 }}>
            <Modal.Title>
              {t('addContact')}
              <span
                className="modal-header-cross pull-right"
                onClick={hideModal}
              />
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="modal-add-partner__caption-container">
              {t('addContactLink')}
              <span style={{ marginLeft: 5 }}>
                <Helper text="addContactHelpText" />
              </span>
            </div>
            <div className="modal-add-partner__refresh-container">
              <button
                type="button"
                className="modal-add-partner__refresh-button"
                onClick={refreshToken}>
                {t('addContactRefreshLink')}
              </button>
              <span style={{ marginLeft: 1 }}>
                <Helper text="addContactRefreshLinkHelpText" />
              </span>
            </div>
            <div className="modal-add-partner__link-container">
              <input
                id="modal-add-partner-input"
                className="modal-add-partner__link-input"
                value={`${process.env.UNICAT_DEFAULT_SERVICE}/dialogs/welcome/${token}`}
                readOnly
              />
              <button
                type="button"
                className="modal-add-partner__link-copy"
                onClick={this.copyLink}>
                <img src="/img/copy-link.svg" alt="copy" />
              </button>
            </div>
          </Modal.Body>
          <Modal.Footer />
        </Modal>
      </ModalContainer>
    );
  }
}

ModalInvitePartner.propTypes = {
  t: PropTypes.func.isRequired,

  token: PropTypes.string.isRequired,

  hideModal: PropTypes.func.isRequired,
  getToken: PropTypes.func.isRequired,
  refreshToken: PropTypes.func.isRequired
};

const mapState = (state) => ({
  token: state.getIn(['partners', 'token']),
  userEmail: state.getIn(['user', 'user', 'email'])
});

const mapDispatch = (dispatch) =>
  bindActionCreators(
    {
      hideModal: modalActions.hideModal,
      getToken: partnersActions.getToken,
      refreshToken: partnersActions.refreshToken,
      gaSend: servicesAction.gaSend
    },
    dispatch
  );

export default connect(mapState, mapDispatch)(translate()(ModalInvitePartner));
