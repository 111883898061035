import React from 'react';
import { Checkbox, Typography } from '@link/react-components';
import { useTranslate } from '@hooks';
import {
  Container,
  Header,
  Content,
  Row,
  Cell,
  CheckboxRow
} from '../components';
import styles from './PurchaseRequests.module.css';

const CHECKBOX_SIZE = 16;

export function PurchaseRequestsSection({ settings, onChange }) {
  const t = useTranslate();

  function translateSection(tKey) {
    return t(`modules.settings.sections.${tKey}`);
  }

  function translateOption(tKey) {
    return t(`modules.settings.sections.purchaseRequests.options.${tKey}`);
  }

  return (
    <Container>
      <Header>
        <Row>
          <Cell>
            <Typography className={styles.title} variant="h2">
              {translateSection('purchaseRequests.title')}
            </Typography>
          </Cell>
          <Cell center>
            <Typography className={styles.columnTitle} variant="body1Med">
              {translateSection('columns.mail')}
            </Typography>
          </Cell>
        </Row>
      </Header>
      <Content>
        <CheckboxRow title={translateOption('changeRequestStatus')}>
          <Checkbox
            id="changeRequestStatus"
            checked={settings.changeRequestStatus}
            onChange={onChange}
            size={CHECKBOX_SIZE}
          />
        </CheckboxRow>
        <CheckboxRow title={translateOption('changeResponseStatus')}>
          <Checkbox
            id="changeResponseStatus"
            checked={settings.changeResponseStatus}
            onChange={onChange}
            size={CHECKBOX_SIZE}
          />
        </CheckboxRow>
        <CheckboxRow
          title={translateOption('newRequests')}
          url="/requests/subscribe">
          <Checkbox
            id="newRequests"
            checked={settings.newRequests}
            onChange={onChange}
            size={CHECKBOX_SIZE}
          />
        </CheckboxRow>
      </Content>
    </Container>
  );
}
