import styled from 'styled-components';
import { Button as BButton } from 'react-bootstrap';

export const Wrapper = styled.div`
  display: flex;
  min-width: 800px;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  position: relative;
`;

export const Content = styled.div`
  width: 100%;
`;

export const GoBackWrapper = styled.div`
  margin-right: 16px;
  margin-top: 8px;
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const Title = styled.h1`
  font-size: ${(props) => props.theme.font.size.big};
  line-height: ${(props) => props.theme.font.lineHeight.base};
  color: ${(props) => props.theme.colors.gray.dark};
  font-weight: 400;
`;

export const TextTitle = styled.div`
  color: #767b92;
  font-weight: 500;
  font-size: 14px;
`;

export const Text = styled.div`
  color: #252b49;
  font-size: 14px;
`;

export const Body = styled.div`
  margin-bottom: 28px;
`;

export const Row = styled.div`
  display: flex;
  margin: 20px 0;
`;

export const Divider = styled.div`
  height: 1px;
  width: 100%;
  background-color: #ebeef4;
`;

export const ColHalf = styled.div`
  display: flex;
  width: 50%;
`;

export const SupplierBlock = styled.div`
  display: flex;
  gap: 20px;
`;

export const IconsBlock = styled.div`
  margin-left: auto;
`;

export const StatusBlock = styled.div`
  display: flex;
  white-space: nowrap;
  align-items: center;
  gap: 5px;
  font-size: ${(props) => props.theme.font.size.extra};
  line-height: ${(props) => props.theme.font.lineHeight.base};
  color: ${(props) => props.theme.colors.gray.additional};

  .status-text {
    font-size: ${(props) => props.theme.font.size.extra};
    line-height: ${(props) => props.theme.font.lineHeight.base};
    color: ${(props) => props.theme.colors.gray.additional};
  }
`;

export const Footer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  margin: 0 auto;
`;

export const Button = styled(BButton)`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: ${(props) => props.theme.font.size.extra};
  line-height: ${(props) => props.theme.font.lineHeight.base};
  font-weight: 500;
  width: 183px;
  height: 34px;
`;
