import React from 'react';
import {
  Modal,
  Col,
  Form,
  FormGroup,
  FormControl,
  ControlLabel,
  Button,
  InputGroup,
  HelpBlock
} from 'react-bootstrap';
import { connect } from 'react-redux';
import { translate } from 'react-i18next';
// import block from 'bem-cn-lite';

import * as modalActionCreators from '../../../action-creators/modal';
import * as priceActions from '../../../action-creators/prices';
import InputNumber from '../../InputNumber';
import Helper from '../../Helper';

// const b = block('modal-container');

const ModalAddPriceCategory = translate(['ui'], { wait: true })(
  class extends React.Component {
    constructor(props) {
      super(props);
      this.validateInteger = this._validateInteger.bind(this);
      this.inputProps = this._inputProps.bind(this);
      this.isPriceCategoryNameUnique =
        this.isPriceCategoryNameUnique.bind(this);
      this.state = {
        namePristine: true,
        minOrderPristine: true,
        minOrderStyle: ''
      };
    }

    UNSAFE_componentWillMount() {
      const { updateNewCategory } = this.props;
      updateNewCategory('type', 1);
    }

    getProperty(field) {
      return this.props.newPriceCategory &&
        this.props.newPriceCategory.has(field)
        ? this.props.newPriceCategory.get(field)
        : '';
    }

    validationState(field) {
      const { newPriceCategory } = this.props;
      if (field === 'name') {
        return (newPriceCategory &&
          newPriceCategory.get(field) &&
          this.isPriceCategoryNameUnique(this.getProperty('name'))) ||
          this.state.namePristine
          ? ''
          : 'error';
      }
      return newPriceCategory && newPriceCategory.get(field) ? '' : 'error';
    }

    validationMinOrderState() {
      const { newPriceCategory } = this.props;
      return (newPriceCategory &&
        newPriceCategory.get('minOrder') &&
        newPriceCategory.get('minOrder') >= 0 &&
        this.isPriceCategoryMinOrderUnique(this.getProperty('minOrder'))) ||
        this.state.minOrderPristine
        ? ''
        : 'error';
    }

    getDiscountValidationState() {
      const { newPriceCategory } = this.props;
      return (newPriceCategory &&
        newPriceCategory.get('discount') &&
        newPriceCategory.get('discount') >= 0 &&
        newPriceCategory.get('discount') < 100) ||
        (newPriceCategory && newPriceCategory.get('discount') === '')
        ? null
        : 'error';
    }

    asterisk() {
      return <span style={{ color: 'red' }}>*</span>;
    }

    getFieldValidationState(field) {
      const prop = this.getProperty(field);
      return !(prop === '' || prop === undefined || prop === null);
    }

    showHelpInfo(field) {
      const { t } = this.props;
      if (!this.getFieldValidationState(field) && !this.state.namePristine) {
        return (
          <HelpBlock id="invalidFieldMessage" style={{ color: 'red' }}>
            {t('Required fields empty')}
          </HelpBlock>
        );
      }
      return null;
    }

    _inputProps(field) {
      const { updateNewCategory } = this.props;
      return {
        value: this.getProperty(field),
        onChange: (e) => updateNewCategory(field, e.target.value)
      };
    }

    _validateInteger(e) {
      const isInteger = /[0-9]/g.test(e.key);
      if (!isInteger) {
        e.preventDefault();
      }
    }

    normalizeName(name = '') {
      return name.toLowerCase().trim();
    }

    isPriceCategoryNameUnique(name) {
      const { priceCategories } = this.props;
      const normalizedName = this.normalizeName(name);
      const found = priceCategories
        .filter((category) => category.get('name') !== null)
        .find(
          (category) =>
            normalizedName === this.normalizeName(category.get('name'))
        );
      const isUnique = !found;
      return isUnique;
    }

    isPriceCategoryMinOrderUnique(minOrder) {
      const { priceCategories } = this.props;
      const find = priceCategories.find(
        (category) => +minOrder === category.get('minOrder')
      );
      const isUnique = !find;
      return isUnique;
    }

    render() {
      const {
        t,
        price,
        captionKey,
        createPriceCategory,
        hideModalDialog,
        saveDisabled,
        updateNewCategory,
        clearNewCategory,
        gaSend,
        user
      } = this.props;

      return (
        <Modal
          id="productCharacteristicsModalWindow"
          onHide={() => {
            hideModalDialog();
            clearNewCategory();
          }}
          show>
          <Modal.Header>
            <Modal.Title>
              {t(captionKey)}
              <span
                className="modal-header-cross pull-right"
                onClick={() => {
                  hideModalDialog();
                  clearNewCategory();
                }}
              />
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form horizontal>
              <FormGroup
                controlId="name"
                validationState={this.validationState('name')}>
                <Col
                  componentClass={ControlLabel}
                  xs={5}
                  style={{ textAlign: 'left', marginTop: 15 }}>
                  {`${t('Name')} `}
                  {this.asterisk()}
                </Col>
                <Col xs={7}>
                  <span
                    className="input-col-length pull-right"
                    style={{ marginBottom: 5 }}>
                    {this.getProperty('name').length}
                    /20
                  </span>
                  <FormControl
                    type="text"
                    maxLength={20}
                    {...this.inputProps('name')}
                    onKeyDown={() => {
                      if (this.state.namePristine) {
                        this.setState({ namePristine: false });
                      }
                    }}
                  />
                  {this.showHelpInfo('name')}
                  {this.isPriceCategoryNameUnique(
                    this.getProperty('name')
                  ) ? null : (
                    <HelpBlock
                      id="invalidFieldMessageUniqueName"
                      style={{ color: 'red' }}>
                      {t('Name already exist')}
                    </HelpBlock>
                  )}
                </Col>
              </FormGroup>
              <FormGroup controlId="discount">
                <Col
                  componentClass={ControlLabel}
                  xs={5}
                  style={{ textAlign: 'left' }}>
                  {`${t('discount')} `}
                  <Helper text="price_list_discount_help" />
                </Col>
                <Col xs={7}>
                  <InputGroup>
                    <InputNumber
                      defaultValue={this.getProperty('discount')}
                      value={this.getProperty('discount')}
                      min={0}
                      max={100}
                      onChange={(e) => updateNewCategory('discount', e)}
                      width="100%"
                      className="catalog-basic-information"
                    />
                    <InputGroup.Addon>%</InputGroup.Addon>
                  </InputGroup>
                </Col>
              </FormGroup>
              <FormGroup
                controlId="minOrder"
                validationState={this.validationMinOrderState()}>
                <Col
                  componentClass={ControlLabel}
                  xs={5}
                  style={{ textAlign: 'left' }}>
                  {`${t('Minimal order')} `}
                  {this.asterisk()}
                </Col>
                <Col xs={7}>
                  <InputGroup>
                    <div
                      style={
                        this.validationMinOrderState() === 'error'
                          ? {
                              border: '1px solid',
                              borderRadius: '4px 0px 0px 4px',
                              borderShadow:
                                'inset 0 1px 1px rgba(0, 0, 0, .075)',
                              borderColor: '#fd4f53'
                            }
                          : {
                              border: '1px solid',
                              borderRadius: '4px 0px 0px 4px',
                              borderShadow:
                                'inset 0 1px 1px rgba(0, 0, 0, .075)',
                              borderColor: '#ccc'
                            }
                      }>
                      <InputNumber
                        defaultValue={this.getProperty('minOrder')}
                        value={this.getProperty('minOrder')}
                        min={0}
                        onChange={(e) => updateNewCategory('minOrder', e)}
                        width="100%"
                        className="catalog-basic-information"
                        style={{ border: 'none' }}
                        onKeyDown={() => {
                          if (this.state.namePristine) {
                            this.setState({ minOrderPristine: false });
                          }
                        }}
                      />
                    </div>
                    <InputGroup.Addon>{price.get('currency')}</InputGroup.Addon>
                  </InputGroup>
                  {this.showHelpInfo('minOrder')}
                  {!this.isPriceCategoryMinOrderUnique(
                    this.getProperty('minOrder')
                  ) && (
                    <HelpBlock
                      id="invalidFieldMessageMinOrder"
                      style={{ color: 'red' }}>
                      {t('MinOrder already exist')}
                    </HelpBlock>
                  )}
                </Col>
              </FormGroup>
              <FormGroup
                controlId="requiredFieldsLabel"
                style={{ marginBottom: 0, marginTop: 10 }}>
                <Col
                  componentClass={ControlLabel}
                  xs={5}
                  style={{
                    fontSize: '12px',
                    color: 'lightgrey',
                    textAlign: 'left'
                  }}>
                  {this.asterisk()}
                  {` ${t('Required fields')}`}
                </Col>
              </FormGroup>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button
              id="cancel"
              className="pull-left"
              onClick={() => {
                clearNewCategory();
                hideModalDialog();
              }}>
              {t('Cancel')}
            </Button>
            <Button
              id="saveCharacteristicButton"
              bsStyle="primary"
              disabled={
                saveDisabled ||
                !this.isPriceCategoryNameUnique(this.getProperty('name')) ||
                !this.isPriceCategoryMinOrderUnique(
                  this.getProperty('minOrder')
                ) ||
                !+this.getProperty('minOrder')
              }
              onClick={async () => {
                await gaSend({
                  category: 'Supplier',
                  action: 'discount_sum_order_success',
                  label: user.get('email')
                });
                return createPriceCategory().then(() => {
                  hideModalDialog();
                  clearNewCategory();
                });
              }}>
              {t('Save')}
            </Button>
          </Modal.Footer>
        </Modal>
      );
    }
  }
);

const mapStateToProps = (state) => {
  const newPriceCategory = state.getIn(['prices', 'newPriceCategory']);
  return {
    price: state.getIn(['prices', 'price']),
    priceCategories: state.getIn(['prices', 'priceCategories']),
    newPriceCategory,
    saveDisabled: !(
      newPriceCategory &&
      newPriceCategory.get('name') &&
      newPriceCategory.get('discount') < 100 &&
      newPriceCategory.get('discount') >= 0 &&
      newPriceCategory.get('minOrder') >= 0 &&
      newPriceCategory.get('minOrder') !== ''
    )
  };
};

export default connect(mapStateToProps, {
  ...modalActionCreators,
  ...priceActions
})(ModalAddPriceCategory);
