import React from 'react';
import { useSelector } from 'react-redux';
import { isNil } from 'ramda';
import { SidebarThemeDefault } from '../../../components/Sidebar';
import EditTaskSidebar from '../../components/Sidebars/EditTask';
import * as getters from '../../getters';

const Sidebar = () => {
  const currentTask = useSelector(getters.getCurrentTask);

  if (isNil(currentTask)) return null;

  return (
    <SidebarThemeDefault
      insideState
      enableToggle
      id="edit-task"
      theme="wide"
      isSaveToLS={false}>
      <EditTaskSidebar />
    </SidebarThemeDefault>
  );
};

export default Sidebar;
