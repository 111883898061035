const assert = require('assert');
const { assoc } = require('ramda');

/**
 * @typedef {string} ADMIN
 * @typedef {string} EDITOR
 * @typedef {string} PARTICIPANT
 * @typedef {string} READER
 * @typedef {(ADMIN|EDITOR|PARTICIPANT|READER)} MemberRole
 */

const ADMIN = 'ADMIN';
exports.ADMIN = ADMIN;

const EDITOR = 'EDITOR';
exports.EDITOR = EDITOR;

const PARTICIPANT = 'PARTICIPANT';
exports.PARTICIPANT = PARTICIPANT;

const READER = 'READER';
exports.READER = READER;

const possibleRolesForAdmin = [ADMIN, EDITOR, READER, PARTICIPANT];
const possibleRolesForEditor = [EDITOR, READER, PARTICIPANT];

function setMemberRoleAfterCheckRole({ currentMember, targetMember, role }) {
  // eslint-disable-next-line global-require
  const {
    haveAdminMR,
    haveEditorMR,
    setMemberRole
  } = require('../channelMember');

  return setMemberRole(targetMember, checkRole());

  function getPossibleRoles() {
    if (haveAdminMR(currentMember)) return possibleRolesForAdmin;
    if (haveEditorMR(currentMember)) {
      if (haveAdminMR(targetMember)) return [];
      return possibleRolesForEditor;
    }
    return [];
  }

  function checkRole() {
    const possibleRoles = getPossibleRoles();

    assert(possibleRoles.includes(role), 'Change role failed. Permission deny');

    return role;
  }
}

exports.updateMembersRoles = (channel, currentMember, membersRoles) => {
  const updatedMembers = channel.members.map((channelMember) => {
    const updatedMemberRole = membersRoles.find(
      (memberRole) => +memberRole[0] === +channelMember.employeeId
    );
    if (updatedMemberRole) {
      const [memberEmployeeId, role] = updatedMemberRole;

      assert(
        currentMember.employeeId !== memberEmployeeId,
        'You can not change member role for yourself'
      );

      return setMemberRoleAfterCheckRole({
        currentMember,
        targetMember: channelMember,
        role
      });
    }
    return channelMember;
  });

  return assoc('members', updatedMembers, channel);
};
