import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
// import block from 'bem-cn-lite';
import { translate } from 'react-i18next';
import { List } from 'immutable';
import { Modal, Button, Form } from 'react-bootstrap';

import './styles/modal_theme_default.styl';

import Table from '../TableNew';
import SearchBlockDefault from '../SearchBlock/SearchBlockDefault';
import Pagination from '../Pagination';
import { ModalContainer } from './elements';
import * as modalActions from '../../action-creators/modal';
import * as featureActions from '../../action-creators/product/feature';
import * as priceActions from '../../action-creators/prices';

import { capitalize } from '../../utils';

// const b = block('modal-container');

class ModalAddPriceFeature extends Component {
  constructor(props) {
    super(props);
    this.hideModal = this.hideModal.bind(this);
    this.getData = this.getData.bind(this);
    this.onSearch = this.onSearch.bind(this);
    this.isShowPagination = this.isShowPagination.bind(this);
    this.selectNextPagination = this.selectNextPagination.bind(this);
  }

  UNSAFE_componentWillMount() {
    const { getPublishedProductsFeatures, catalogId } = this.props;
    getPublishedProductsFeatures(catalogId);
  }

  componentWillUnmount() {
    const { clearSelectedFeatures } = this.props;
    clearSelectedFeatures();
  }

  onSearch(search = '') {
    const { catalogId, getPublishedProductsFeatures } = this.props;
    getPublishedProductsFeatures(catalogId, search);
  }

  getData() {
    const { features, selected, price } = this.props;

    if (!features) return List();

    return features.map((item) => {
      const name = item.get('name');
      const unit = item.get('unit');
      const hasId = selected.find(
        (i) => i.get('name') === name && (i.get('unit') || '') === (unit || '')
      );
      const i = price
        .get('additionProductsAttributes')
        .find(
          (it) =>
            it.get('name') === item.get('name') &&
            (it.get('unit') || '') === (item.get('unit') || '')
        );
      return item
        .set('isSelected', hasId)
        .set('backgroundColor', i ? '#eee' : '')
        .set('withoutCheckbox', !!i);
    });
  }

  hideModal() {
    const { clearSelectedFeatures, hideModalDialog } = this.props;
    clearSelectedFeatures();
    hideModalDialog();
  }

  isShowPagination() {
    const { features, size, limit } = this.props;
    return (
      features &&
      features.size > 0 &&
      size > limit &&
      Math.ceil(size / limit) !== 1
    );
  }

  selectNextPagination(value) {
    const { catalogId, search, getPublishedProductsFeatures, limit } =
      this.props;
    getPublishedProductsFeatures(catalogId, search, limit, value);
  }

  handleClick = () => {
    const { selected, addPriceFeatures, priceId, getPriceFeatures } =
      this.props;

    addPriceFeatures(priceId, selected).then(() => {
      getPriceFeatures(priceId);
      this.hideModal();
    });
  };

  render() {
    const { t, hideModalDialog, selectFeature, size, limit, offset } =
      this.props;

    return (
      <Modal id="orderStatusHistory" onHide={this.hideModal} show>
        <ModalContainer mods={{ type: 'default', theme: 'default' }}>
          <Modal.Header>
            <Modal.Title>
              {t('Add feature')}
              <span
                className="modal-header-cross pull-right"
                onClick={() => this.hideModal()}
              />
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <p>{t('add_feature_price_text')}</p>
              <div style={{ marginBottom: 25 }}>
                <SearchBlockDefault
                  placeholder={t('Feature name')}
                  onSubmit={this.onSearch}
                  onEmpty={this.onSearch}
                />
              </div>
              <Table
                newVersion
                columns={[
                  Table.ColText({
                    accessor: ({ name }) => capitalize(name),
                    title: t('Feature name'),
                    width: '75%'
                  }),
                  Table.ColText({
                    accessor: 'unit',
                    title: t('Unit'),
                    width: '20%'
                  })
                ]}
                data={this.getData()}
                onSelect={(id, item) => selectFeature(item)}
              />
            </Form>
            {this.isShowPagination() && (
              <Pagination
                totalSize={size}
                limit={limit}
                offset={offset || 0}
                nextPage={this.selectNextPagination}
              />
            )}
          </Modal.Body>
          <Modal.Footer>
            <Button
              id="cancel"
              className="pull-left"
              onClick={() => hideModalDialog()}>
              {t('Cancel')}
            </Button>
            <Button
              id="saveCharacteristicButton"
              bsStyle="primary"
              onClick={this.handleClick}>
              {t('Add')}
            </Button>
          </Modal.Footer>
        </ModalContainer>
      </Modal>
    );
  }
}

ModalAddPriceFeature.propTypes = {
  t: PropTypes.func.isRequired
};

ModalAddPriceFeature.childContextTypes = {
  b: PropTypes.func
};

export default connect(
  (state) => ({
    price: state.getIn(['prices', 'price']),
    features: state.getIn(['catalog', 'features']),
    size: state.getIn(['catalog', 'size']),
    limit: state.getIn(['catalog', 'limit']),
    offset: state.getIn(['catalog', 'offset']),
    selected: state.getIn(['catalog', 'selectedFeatures'])
  }),
  (dispatch) =>
    bindActionCreators(
      {
        showModal: modalActions.showModal,
        hideModalDialog: modalActions.hideModalDialog,
        selectFeature: featureActions.selectFeature,
        getPublishedProductsFeatures:
          featureActions.getPublishedProductsFeatures,
        addPriceFeatures: priceActions.addPriceFeatures,
        getPriceFeatures: priceActions.getPriceFeatures,
        clearSelectedFeatures: featureActions.clearSelectedFeatures
      },
      dispatch
    )
)(translate(['ui'], { wait: true })(ModalAddPriceFeature));
