import React, { useState } from 'react';
import { isEmpty, isNil } from 'ramda';
import { translate } from 'react-i18next';
import block from 'bem-cn-lite';
import styled from 'styled-components';
import { ControlLabel, Row, Button } from 'react-bootstrap';

import { withRouter } from '../../../hoc/withRouter';

import { Select } from '../../../components/FormControls';
import InputNumber from '../../../components/InputNumber';
import Checkbox from '../../../components/Checkbox';
import CustomOverlay from '../../../components/Overlay';
import ErrorHint from '../../../components/Hints/ErrorHint';
import './style.css';
import CategoriesSelect from '../../../components/CategoriesSelect';

const b = block('request-response-filter');

const View = (props) => {
  const {
    t,
    title,
    titleSelectCountry,
    countrySelect,
    regionSelect,
    citySelect,
    titleSelectRegion,
    titleSelectCity,
    categories,
    partner,
    sumInputNumber,
    onFilter,
    isFilterChange,
    checkboxes = [],
    isUseNoRegionsHint,
    isShowStatus = false
  } = props;
  // TODO: add prop types

  const [toVisited, setToVisited] = useState(false);
  const [fromVisited, setFromVisited] = useState(false);

  const handleFromValue = (e) => {
    const currValue = e.target.value;
    if (toVisited && currValue > +sumInputNumber.to.value) {
      sumInputNumber.to.onChange(currValue);
    }
    setFromVisited(true);
  };

  const handleToValue = (e) => {
    const currValue = e.target.value;
    if (fromVisited && currValue < +sumInputNumber.from.value) {
      sumInputNumber.from.onChange(currValue);
    }
    setToVisited(true);
  };

  return (
    <div className={b()}>
      <div className={b('header')}>
        <div className="groups-title-wrap">
          <div className="groups-title">{title}</div>
        </div>
        <hr style={{ borderTop: '1px solid #ccc', margin: '0 0 5px 0' }} />
      </div>
      <div className={b('body')}>
        <Row className="form-row-margin">
          <ControlLabel className="control-label-row">
            {titleSelectCountry}
          </ControlLabel>
          <Select
            id="country-select"
            arrowRenderer={() => (
              <img
                style={{ marginLeft: 5, marginBottom: 3 }}
                src="/img/magnifier.svg"
                alt=""
              />
            )}
            {...countrySelect}
            placeholder={t('start_input')}
            noResultsText={t('noResultsText')}
            clearable={false}
            removeSelected
          />
        </Row>
        <MultiSelectWrapper>
          <Row className="form-row-margin region">
            <ControlLabel className="control-label-row">
              {titleSelectRegion}
            </ControlLabel>
            <CustomOverlay
              isUseOverlay={isUseNoRegionsHint}
              popoverId="no_regions_for_selected_country_hint"
              hint={<ErrorHint hint="no_regions_for_selected_country" />}>
              <Select
                id="region-select"
                arrowRenderer={() => (
                  <img
                    id="region-search-button"
                    style={{ marginLeft: 5, marginBottom: 3 }}
                    src="/img/magnifier.svg"
                    alt=""
                  />
                )}
                {...regionSelect}
                multi
                placeholder={t('start_input')}
                noResultsText={t('noResultsText')}
                clearable
                removeSelected
              />
            </CustomOverlay>
          </Row>
        </MultiSelectWrapper>
        {!isEmpty(regionSelect.value) && (
          <MultiSelectWrapper>
            <Row className="form-row-margin">
              <ControlLabel className="control-label-row">
                {titleSelectCity}
              </ControlLabel>
              <Select
                id="city-select"
                arrowRenderer={() => (
                  <img
                    style={{ marginLeft: 5, marginBottom: 3 }}
                    src="/img/magnifier.svg"
                    alt=""
                  />
                )}
                {...citySelect}
                placeholder={t('start_input')}
                noResultsText={t('noResultsText')}
                multi
                clearable
                removeSelected
              />
            </Row>
          </MultiSelectWrapper>
        )}
        {!isNil(categories) && (
          <Row className="form-row-margin">
            <ControlLabel className="control-label-row">
              {categories.title}
            </ControlLabel>
            <CategoriesSelect
              selectedList={categories.selectedIds}
              list={categories.categoriesList}
              onChoose={categories.onChoose}
            />
          </Row>
        )}
        <Row className="form-row-margin">
          <ControlLabel className="control-label-row">
            {sumInputNumber.title}
          </ControlLabel>
          <div style={{ display: 'flex' }}>
            <div style={{ padding: 5, marginTop: 2 }}>{t('minvalue')}</div>
            <InputNumber
              {...sumInputNumber.from}
              style={{ height: 34, width: '45%' }}
              min={0}
              placeholder={0}
              onBlur={handleFromValue}
            />
            <div style={{ padding: 5, marginTop: 2 }}>{t('maxvalue')}</div>
            <InputNumber
              {...sumInputNumber.to}
              style={{ height: 34, width: '45%' }}
              min={0}
              placeholder={0}
              onBlur={handleToValue}
            />
          </div>
        </Row>
        <Row className="form-row-margin">
          <ControlLabel className="control-label-row">
            {partner.title}
          </ControlLabel>
          <Select
            id="suppliers-select"
            {...partner.select}
            placeholder={t('not selected')}
            noResultsText={t('noResultsText')}
            clearable={false}
            removeSelected
          />
        </Row>
        {isShowStatus && (
          <Row className="form-row-margin">
            <div className="control-label-row">{t('Status')}</div>
            {checkboxes.map(({ set, onClick, label }) => (
              <div style={{ display: 'flex' }}>
                <Checkbox
                  set={set}
                  onClick={onClick}
                  style={{ marginTop: 5 }}
                />
                <div>
                  <span className="option-name">{t(label)}</span>
                </div>
              </div>
            ))}
          </Row>
        )}
        <Row style={{ display: 'flex', justifyContent: 'center' }}>
          <Button
            id="apply-filter"
            style={{ fontWeight: 500, cursor: 'pointer', border: 'none' }}
            onClick={onFilter}
            bsStyle="primary">
            {t('apply_filter')}
          </Button>
        </Row>
        <Row
          style={{ display: 'flex', justifyContent: 'center', marginTop: 15 }}>
          <Button
            id="clear-filter"
            style={{
              fontWeight: 500,
              cursor: 'pointer',
              border: 'none',
              color: '#1c50de'
            }}
            onClick={(e) => onFilter(e, true)}
            disabled={isFilterChange()}>
            {t('clear_filter')}
          </Button>
        </Row>
      </div>
    </div>
  );
};

const MultiSelectWrapper = styled.div`
  .Select--multi .Select-input {
    display: inline-block !important;
  }

  .Select--multi .Select-multi-value-wrapper {
    padding-bottom: 0;
  }

  .Select--multi .Select-clear-zone {
    padding-right: 8px;
  }
`;

export default withRouter(translate(['ui'], { wait: true })(View));
