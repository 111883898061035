import { Map, fromJS } from 'immutable';

const clearProductPhysicalDimensions = (state) => state.clear();

const loadProductPhysicalDimensions = (state, items) => fromJS(items);

const updatePhysicalDimensionsField = (state, field, value) =>
  state.setIn(field.split('.'), (value || '').trim());

const physicalDimensions = (state = Map(), action) => {
  switch (action.type) {
    case 'PRODUCT_PHYSICAL_DIMENSIONS_CLEAR':
      return clearProductPhysicalDimensions(state);
    case 'PRODUCT_PHYSICAL_DIMENSIONS_LOAD':
      return loadProductPhysicalDimensions(state, action.items);
    case 'PRODUCT_PHYSICAL_DIMENSIONS_UPDATE_FIELD':
      return updatePhysicalDimensionsField(state, action.field, action.value);
    default:
      return state;
  }
};

export default physicalDimensions;
