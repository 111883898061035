import React, { Component } from 'react';
import PropTypes from 'prop-types';
import block from 'bem-cn-lite';
import { translate } from 'react-i18next';
import {
  Modal,
  Col,
  Form,
  FormGroup,
  ControlLabel,
  Button
} from 'react-bootstrap';

import '../styles/modal_theme_default.styl';
import InputNumber from '../../InputNumber';

import { ModalContainer } from '../elements';

const b = block('modal-container');

class ModalAddDiscount extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: 0
    };
    this.inputProps = this.inputProps.bind(this);
    this.setValue = this.setValue.bind(this);
  }

  getChildContext() {
    return { b };
  }

  getProperty(field) {
    return this.props.newPriceCategory && this.props.newPriceCategory.has(field)
      ? this.props.newPriceCategory.get(field)
      : '';
  }

  setValue(value) {
    this.setState({ value });
  }

  getErrorInfo(fieldName) {
    return this.props.errors.get(fieldName);
  }

  inputProps(field) {
    return {
      value: this.props.fields.get(field),
      onChange: (e) => {
        if (this.getErrorInfo(field)) {
          this.props.removeErrorByField(field);

          return this.props.updateFieldStock(field, e.target.value);
        }
        return undefined;
      }
    };
  }

  render() {
    const {
      t,
      captionKey,
      hideModalDialog,
      submit,
      priceId,
      getProducts,
      search,
      limit,
      offset,
      filters,
      orderBy,
      direction,
      gaSend,
      user
    } = this.props;

    return (
      <ModalContainer mods={{ theme: 'default' }}>
        <Modal id="createStock" onHide={() => hideModalDialog()} show>
          <Modal.Header>
            <Modal.Title>
              {t(captionKey || 'Add discount to product')}
              <span
                className="modal-header-cross pull-right"
                onClick={() => hideModalDialog()}
              />
            </Modal.Title>
          </Modal.Header>
          <Modal.Body style={{ overflow: 'hidden' }}>
            <Col xs={12}>
              <Form horizontal onSubmit={(e) => e.preventDefault()}>
                <FormGroup
                  controlId="discount"
                  style={{ display: 'flex', alignItems: 'center' }}>
                  <ControlLabel style={{ fontWeight: 'normal' }}>
                    {t('The discount percentage to be recalculated')}
                  </ControlLabel>
                  <InputNumber
                    style={{ marginLeft: 15 }}
                    value={this.state.value}
                    min={0}
                    max={99}
                    onChange={this.setValue}
                  />
                </FormGroup>
              </Form>
            </Col>
          </Modal.Body>
          <Modal.Footer>
            <Button
              id="cancel"
              className="pull-left"
              onClick={() => {
                hideModalDialog();
              }}>
              {t('Cancel')}
            </Button>
            <Button
              id="saveCharacteristicButton"
              bsStyle="primary"
              onClick={async () => {
                await gaSend({
                  category: 'Supplier',
                  action: 'product_discount',
                  label: user.get('email')
                });
                return submit(priceId, this.state.value)
                  .then(hideModalDialog)
                  .then(() =>
                    getProducts(
                      priceId,
                      search,
                      offset,
                      limit,
                      orderBy,
                      direction,
                      {},
                      filters
                    )
                  );
              }}>
              {t('Add')}
            </Button>
          </Modal.Footer>
        </Modal>
      </ModalContainer>
    );
  }
}

ModalAddDiscount.propTypes = {
  t: PropTypes.func.isRequired
};

ModalAddDiscount.childContextTypes = {
  b: PropTypes.func
};

export default translate(['ui'], { wait: true })(ModalAddDiscount);
