import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { isEmpty } from 'ramda';
import * as emailMessagesGetters from '../../../../../components/ChatWidget/modules/emailMessages/getters';
import * as emailMessagesCases from '../../../../../components/ChatWidget/modules/emailMessages/useCases';
import Button from '../../../../../components/Buttons/Button';
import './style.css';

const ConnectedEmailsContainer = () => {
  const connectedEmails = useSelector(emailMessagesGetters.getConnectedEmails);

  const { fetchConnectedEmails } = emailMessagesCases.useFetchConnectedEmails();

  useEffect(() => {
    fetchConnectedEmails();
  }, []);

  const onDelete = (email) => async () => {
    await emailMessagesCases.revokeEmail(email);
    await fetchConnectedEmails();
  };

  if (isEmpty(connectedEmails)) {
    return null;
  }

  return (
    <div className="connected-emails-container">
      <h1>Подключенные аккаунты:</h1>
      {connectedEmails.map((connectedEmail) => (
        <div className="connected-email" key={connectedEmail.id}>
          <div>{connectedEmail.email}</div>
          <Button onClick={onDelete(connectedEmail.email)}>Удалить</Button>
        </div>
      ))}
    </div>
  );
};

export default ConnectedEmailsContainer;
