import React from 'react';
import { Link } from 'react-router-dom';

import MessengerOther from '../components/Layouts/MessengerOther';

import ContainersDefault from '../components/Containers/ContainersDefault';
import NewRequest from '../components/PurchaseRequests/NewRequest';
import { useTranslate } from '../TranslateProvider';

const MCreatePurchaseRequest = () => {
  const t = useTranslate();

  const breadcrumbs = [
    {
      name: t('Purchase requests'),
      element: (
        <Link
          to={{
            pathname: '/requests/my',
            state: { myRequestsPage: true }
          }}>
          {t('Purchase requests')}
        </Link>
      )
    },
    { name: t('new_request_creaton') }
  ];

  return (
    <MessengerOther
      theme="gray"
      title={t('Purchase request')}
      breadcrumbs={breadcrumbs}
      content={
        <ContainersDefault>
          <NewRequest />
        </ContainersDefault>
      }
    />
  );
};

export default MCreatePurchaseRequest;
