export class RouteDTO {
  constructor(props) {
    this.id = props.id;
    this.path = props.path;
    this.name = props.name;
    this.employeeId = props.employeeId;
    this.companyId = props.companyId;
    this.isCanBeDeleted = props.isCanBeDeleted;
  }
}
