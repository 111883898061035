import React from 'react';
import { ConfirmationInput } from '@link/react-components';
import { useTranslate, useAsyncFn } from '@hooks';
import { companyService } from '../../../../api';

const MAX_NOTE_LENGTH = 500;

export function CompanyNote({ companyId, note, updateCompany }) {
  const t = useTranslate();

  const [_, onSubmit] = useAsyncFn(
    async (note) => {
      await companyService.saveNote(companyId, note);
      updateCompany({ note });
    },
    [companyId, updateCompany]
  );

  return (
    <ConfirmationInput
      placeholder={t('modules.company.card.emptyNote')}
      defaultValue={note}
      onSubmit={onSubmit}
      maxLength={MAX_NOTE_LENGTH}
      tooltipPlacement="top-start"
    />
  );
}
