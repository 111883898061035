import React, { useEffect, useReducer, useMemo } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { useUpdateEffect, useUnmount } from 'react-use';
import { isEmpty, assoc, equals } from 'ramda';
import { useTranslate } from '@hooks';
import { withRouter } from '../../../hoc/withRouter';
import {
  optionSelect,
  selectProps,
  creatableProps,
  inputNumberProps
} from '../../../viewModelAdapters/components/form';
import View from './View';

import * as serviceActions from '../../../action-creators/services';
import * as storage from '../../../components/PurchaseRequests/Responses/MyResponses/storage';
import { actions as responseActions } from '../../../components/PurchaseRequests/Responses/MyResponses/reducer';

import keyDebounce from '../../../utils/debounce';
import { getCurrentUserLanguage } from '../../../storeGetters';
import * as getters from '../../../components/PurchaseRequests/Responses/MyResponses/getters';
import {
  setData as setDataToLS,
  getData as getDataFromLS
} from '../../../storage/lib/LS';
import {
  toggleStatusState,
  withStatusList,
  actionTypeToggleStatus,
  actionToggleStatus,
  statusForSupplier
} from './status';
import * as dictionariesGetters from '../../../modules/dictionaries/getters';
import * as dictionariesStorage from '../../../modules/dictionaries/storage';

const DEFAULT_COUNTRY_ID = '3159'; // Россия
const DEFAULT_CURRENCY = 'RUB';
const DEBOUNCE = 500;
const GA_ACTION = 'filter_request';
const LS_KEY = 'my-responses-filter';

const DEFAULT_FILTER_VALUE = {
  country: '',
  region: [],
  city: [],
  currency: '',
  sum: { from: '', to: '' },
  categories: [],
  buyer: '',
  statuses: withStatusList(statusForSupplier)
};

const PurchaseRequestResponsesSidebar = ({
  buyers,
  countries,
  regions,
  cities,
  language,
  categoriesList,
  getCountries,
  getRegions,
  getCities,
  getRegionsCount,
  getMyResponses,
  getAllBuyers,
  limit,
  offset,
  search,
  selectedStatus,
  order,
  direction,
  selectedCategoriesId,
  gaSend,
  cleanRegion,
  cleanCities,
  setFilters,
  getCategories
}) => {
  const t = useTranslate();
  const [state, dispatch] = useReducer(reducer, undefined, initState);

  const actions = useMemo(
    () => ({
      setCountry: (payload) => dispatch({ type: 'set_country', payload }),
      autoSetCountry: (payload) =>
        dispatch({ type: 'auto_set_country', payload }),
      setRegion: (payload) => dispatch({ type: 'set_region', payload }),
      setCity: (payload) => dispatch({ type: 'set_city', payload }),
      setSum: (payload) => dispatch({ type: 'set_sum', payload }),
      setSumFrom: (payload) => dispatch({ type: 'set_sum_from', payload }),
      setSumTo: (payload) => dispatch({ type: 'set_sum_to', payload }),
      setCurrency: (payload) => dispatch({ type: 'set_currency', payload }),
      setBuyer: (payload) => dispatch({ type: 'set_buyer', payload }),
      setCategories: (payload) => dispatch({ type: 'set_categories', payload }),
      toggleStatus: actionToggleStatus(dispatch),
      reset: () => dispatch({ type: 'reset' })
    }),
    []
  );

  useEffect(() => {
    getAllBuyers();
    getCategories();

    const filter = getFilterDataFromLS();
    if (!filter) {
      saveStateToLS();
    } else if (filter.country) {
      getCountries(null, filter.country);

      if (filter.region) {
        getRegions(filter.country, null, filter.region);
      }

      if (filter.city) {
        getCities(filter.country, null, null, filter.city);
      }
    }
    setFilters({ filters: filter });
  }, []);

  useEffect(() => {
    handleClickByIcon('region-search-button', onClickByRegionsSearchIcon);

    return () => {
      removeClickByIconEvent(
        'region-search-button',
        onClickByRegionsSearchIcon
      );
    };
  }, []);

  useEffect(() => {
    if (state.country) getRegionsCount(state.country, language);
  }, [state.country]);

  useUpdateEffect(() => {
    saveFilters();
  }, [state]);

  useUnmount(() => setFilters({ filters: [] }));

  function saveStateToLS() {
    setDataToLS(LS_KEY, state);
  }

  function saveFilters() {
    setFilters({ filters: makeFilters(state) });
  }

  function onClickByRegionsSearchIcon() {
    if (!state.country) setDefaultCountry();
  }

  function addFirstItemByType(list, type) {
    if (type === 'country')
      return addFirstItem(list, getItem(list, state.country));
    if (type === 'region' || type === 'city') return list;

    throw new Error(`No such handler for type: ${type}.`);
  }

  function getItem(list, currentValue) {
    if (currentValue && !list.find((o) => +o.id === +currentValue)) {
      return { id: null, name: currentValue };
    }
    return { id: null, name: t('Not_selected') };
  }

  function getLocationsSelectList(items, type) {
    return addFirstItemByType(items, type).map((i) => ({
      value: i.id ? i.id.toString() : null,
      label: i.name
    }));
  }

  function getAllBuyersOptions() {
    const result = buyers
      .filter((v) => v !== '')
      .map(({ id, name }) => optionSelect(id, t(name)));

    result.unshift(optionSelect(null, t('not selected')));

    return result;
  }

  function isFilterChange() {
    return equals(DEFAULT_FILTER_VALUE, state);
  }

  function applyFilters(event, clear = false) {
    if (clear) {
      actions.reset();

      clearFilterDataFromLS();

      getMyResponses({
        limit,
        offset,
        search,
        order,
        direction,
        selectedStatus
      });

      return;
    }

    gaSend(GA_ACTION);

    saveStateToLS();

    const filters = makeFilters(state);
    getMyResponses({
      limit,
      offset,
      search,
      order,
      direction,
      selectedCategoriesId,
      filters,
      selectedStatus
    });
  }

  function onChangeCountry(value) {
    if (!value) return;

    const country = prepareValue(value);
    actions.setCountry(country);

    cleanRegion();
    cleanCities();
  }

  function onChangeRegion(value) {
    if (!value) return;

    if (!state.country) setDefaultCountry();

    if (Array.isArray(value)) {
      actions.setRegion(value);

      if (isRemoveRegion(state.region, value)) {
        cleanCities();
      }
    }

    if (typeof value === 'string') {
      cleanRegion();
      cleanCities();
    }
  }

  function onChangeCity(value) {
    if (!value) return;

    if (Array.isArray(value)) {
      actions.setCity(value);
    }

    if (typeof value === 'string') cleanCities();
  }

  function onChangeCategories(value) {
    if (!value) return;

    if (Array.isArray(value)) {
      actions.setCategories(value);
    }
  }

  function prepareValue(val) {
    const value = typeof val === 'string' ? val : val.value;
    return value === 'null' ? null : value;
  }

  function onCountrySearch(value) {
    if (value) {
      getCountries(value);
    }
  }

  function onRegionSearch(value) {
    if (!value) {
      cleanRegion();
      return;
    }

    if (!state.country) setDefaultCountry();

    getRegions(state.country || DEFAULT_COUNTRY_ID, value);
  }

  function onCitySearch(value) {
    if (value) {
      getCities(state.country, state.region, value);
    }
  }

  function onCountrySearchDebounced(value) {
    return keyDebounce(onCountrySearch, DEBOUNCE)(value);
  }

  function onRegionSearchDebounced(value) {
    return keyDebounce(onRegionSearch, DEBOUNCE)(value);
  }

  function onCitySearchDebounced(value) {
    return keyDebounce(onCitySearch, DEBOUNCE)(value);
  }

  function setDefaultCountry() {
    actions.autoSetCountry();

    getCountries(null, DEFAULT_COUNTRY_ID);
    cleanCities();
  }

  function generateViewModel() {
    return {
      title: t('filter_of_my_responses'),
      titleSelectCountry: t('Delivery country'),
      titleSelectRegion: t('Delivery region'),
      titleSelectCity: t('Delivery city/locality'),
      countrySelect: creatableProps(
        getLocationsSelectList(countries, 'country'),
        state.country,
        (value) => onChangeCountry(value),
        (value) => onCountrySearchDebounced(value),
        (e) => {
          if (e.keyCode === 13) {
            onChangeCountry(e.target.value);
          }
        }
      ),
      isUseNoRegionsHint: state.country && state.regionsCount === 0,
      regionSelect: creatableProps(
        getLocationsSelectList(regions, 'region'),
        state.region,
        (value) => onChangeRegion(value),
        (value) => onRegionSearchDebounced(value),
        (e) => {
          if (e.keyCode === 13) {
            onChangeRegion([...state.region, e.target.value]);
          }
        },
        () => {
          if (!state.region) {
            cleanRegion();
          }
        }
      ),
      citySelect: creatableProps(
        getLocationsSelectList(cities, 'city'),
        state.city,
        (value) => onChangeCity(value),
        (value) => onCitySearchDebounced(value),
        (e) => {
          if (e.keyCode === 13) {
            onChangeCity([...state.city, e.target.value]);
          }
        },
        () => {
          if (!state.city) {
            cleanCities();
          }
        }
      ),
      sumInputNumber: {
        title: `${t('sum_request')}, ${t('rub')}.`,
        from: inputNumberProps(state.sum.from, (value) => {
          actions.setSumFrom(value);
        }),
        to: inputNumberProps(state.sum.to, (value) => {
          actions.setSumTo(value);
        })
      },
      partner: {
        title: t('Customer'),
        select: selectProps(getAllBuyersOptions(), state.buyer, (option) => {
          actions.setBuyer(option.value);
        })
      },
      checkboxes: [],
      onFilter: applyFilters,
      isFilterChange,
      categories: {
        title: t('category_goods_and_services'),
        selectedIds: state.categories,
        onChoose: onChangeCategories,
        categoriesList,
        placeholder: t('purchaseRequest.filter.placeholders.default')
      }
    };
  }

  return <View {...generateViewModel()} />;
};

export default withRouter(
  connect(
    (state) => ({
      language: getCurrentUserLanguage(state),
      buyers: getters.getAllBuyersOfMyResponses(state),
      countries: getters.getCountries(state),
      regionsCount: getters.getRegionsCount(state),
      regions: getters.getRegions(state),
      cities: getters.getCities(state),
      selectedCategoriesId: getters.getSelectedCategoriesId(state),
      limit: getters.getLimit(state),
      offset: getters.getOffset(state),
      search: getters.getSearch(state),
      order: getters.getOrder(state),
      direction: getters.getDirection(state),
      selectedStatus: getters.getStatus(state),
      categoriesList: dictionariesGetters.getPurchaseRequestsCategories(state)
    }),
    (dispatch) =>
      bindActionCreators(
        {
          getCategories: dictionariesStorage.getPurchaseRequestsCategories,
          getMyResponses: storage.getMyResponses,
          getAllBuyers: storage.getAllBuyersOfMyResponses,
          getCountries: storage.getCountries,
          getRegionsCount: storage.getRegionsCount,
          getRegions: storage.getRegions,
          getCities: storage.getCities,
          cleanRegion: responseActions.purgeRegions,
          cleanCities: responseActions.purgeCities,
          setFilters: responseActions.setFilters,
          gaSend: serviceActions.gaSendServiceAction
        },
        dispatch
      )
  )(PurchaseRequestResponsesSidebar)
);

function handleClickByIcon(iconId, onClickFn = () => {}) {
  document
    .getElementById(iconId)
    .parentElement.addEventListener('click', onClickFn);
}

function removeClickByIconEvent(iconId, onClickFn = () => {}) {
  document
    .getElementById(iconId)
    .parentElement.removeEventListener('click', onClickFn);
}

function addFirstItem(list, item) {
  return [item, ...list];
}

function getFilterDataFromLS() {
  return getDataFromLS(LS_KEY);
}

function clearFilterDataFromLS() {
  setDataToLS(LS_KEY, DEFAULT_FILTER_VALUE);
}

export function makeFilters(state) {
  const filters = [];

  if (state.country) {
    filters.push({ name: 'country', value: state.country });
  }
  if (!isEmpty(state.region)) {
    filters.push({ name: 'region', value: extractValue(state.region) });
  }
  if (!isEmpty(state.city)) {
    filters.push({ name: 'city', value: extractValue(state.city) });
  }
  if (state.currency) {
    filters.push({ name: 'currency', value: state.currency });
  }
  if (state?.sum?.from || state?.sum?.to) {
    filters.push({ name: 'sum', ...state.sum });
  }
  if (state.buyer) {
    filters.push({ name: 'buyer', value: state.buyer });
  }
  if (someStatusChecked(state?.statuses)) {
    filters.push({ name: 'statuses', value: state.statuses });
  }
  if (!isEmpty(state?.categories)) {
    filters.push({ name: 'categories', value: state.categories });
  }
  return filters;

  function someStatusChecked(statuses) {
    return Object.values(statuses).some((status) => status);
  }

  function extractValue(arr = []) {
    return arr.map(({ value }) => value);
  }
}

// Для инициализации фильтров с Redux
//
// function fromFilters(filters) {
//   return filters.reduce((acc, filter) => {
//     switch (filter.name) {
//       case 'region':
//         return { ...acc, [filter.name]: filter.value.map(value => ({ value })) };
//       case 'sum':
//         return { ...acc, [filter.name]: { from: filter.from, to: filter.to } };
//       default:
//         return { ...acc, [filter.name]: filter.value };
//     }
//   }, DEFAULT_FILTER_VALUE);
// }

export function initState() {
  const filter = getFilterDataFromLS();
  return filter || DEFAULT_FILTER_VALUE;
}

function setDefaultCurrency(currency) {
  return currency || DEFAULT_CURRENCY;
}

function isRemoveRegion(prevRegions, currentRegions) {
  return prevRegions.length > currentRegions.length;
}

function reducer(state, action) {
  switch (action.type) {
    case 'auto_set_country':
      return { ...state, country: DEFAULT_COUNTRY_ID };
    case 'set_country':
      return {
        ...state,
        country: action.payload,
        region: DEFAULT_FILTER_VALUE.region,
        city: DEFAULT_FILTER_VALUE.city
      };
    case 'set_region':
      return {
        ...state,
        country: state.country ? state.country : DEFAULT_COUNTRY_ID,
        region: action.payload,
        city: isRemoveRegion(state.region, action.payload)
          ? DEFAULT_FILTER_VALUE.city
          : state.city
      };
    case 'set_city':
      return { ...state, city: action.payload };
    case 'set_sum':
      return {
        ...state,
        sum: action.payload,
        currency: setDefaultCurrency(state.currency)
      };
    case 'set_sum_from':
      return {
        ...state,
        sum: { ...state.sum, from: action.payload },
        currency: setDefaultCurrency(state.currency)
      };
    case 'set_sum_to':
      return {
        ...state,
        sum: { ...state.sum, to: action.payload },
        currency: setDefaultCurrency(state.currency)
      };
    case 'set_currency':
      return { ...state, currency: action.payload };
    case 'set_buyer':
      return { ...state, buyer: action.payload };
    case 'set_categories':
      return { ...state, categories: action.payload };
    case actionTypeToggleStatus:
      return assoc(
        'statuses',
        toggleStatusState(action.status, state.statuses),
        state
      );
    case 'reset':
      return DEFAULT_FILTER_VALUE;
    default:
      throw new Error(`No such handler for type: ${action.type}.`);
  }
}
