import { fromJS, List } from 'immutable';

import {
  initState,
  setPrices,
  clearAllPrices,
  updateDefaultField,
  updateField,
  updateStocksField,
  selectPrice,
  setPrice,
  setPriceFeatures,
  clearForm,
  setPriceCategories,
  selectCategory,
  updateNewCategory,
  clearNewCategory,
  addCategory,
  updateCategories,
  deselectAll,
  updatePriceCategory,
  setSelectedCategory,
  setDefaultPriceForm,
  setFoundProducts,
  setAccessList,
  deletePrices,
  setIsPublished,
  clearSelected,
  setPricesStatuses,
  setPriceStatus,
  setStatusPublishing,
  setSelectedPrice,
  toggleFilterSelected,
  selectProducts,
  unselectProducts,
  setPriceBaidgeStatus,
  setPriceStatusPublished,
  setPriceFailure,
  selectFoundProducts,
  selectAllFoundProducts,
  updateCostFoundProducts,
  updateAdditionAttributeField,
  resetListOfBaseCost,
  setPriceCustomersNotifiedStatus,
  addAdditionalCategory,
  removeAdditionalCategory,
  addProductsToAdditionalCategory,
  removeProductsToAdditionalCategory,
  updateAdditionalCategory,
  setPositionsCategories
} from '../work-with-data/prices';

const removeFromAccessList = (data, { customers }) =>
  data
    .update('accessList', (accessList) =>
      accessList.filter((i) => !customers.includes(i.get('customer')))
    )
    .setIn(['price', 'shareType'], 2);

const removeAccess = (data, { customers }) =>
  data.update('accessList', (accessList) => {
    let newAccessList = accessList;
    const ids = customers.toArray();
    ids.forEach((id) => {
      const index = accessList.findIndex(
        (i) => (i.get('customer') || i.get('id')) === id
      );
      newAccessList = newAccessList.setIn([index, 'added'], false);
    });
    return newAccessList;
  });

const addAccess = (data, { customers }) =>
  data.update('accessList', (accessList) => {
    let newAccessList = accessList;
    const ids = customers.toArray();
    ids.forEach((id) => {
      const index = accessList.findIndex(
        (i) => (i.get('customer') || i.get('id')) === id
      );
      newAccessList = newAccessList.setIn([index, 'added'], true);
    });
    return newAccessList;
  });

const setShareType = (data, { type }) =>
  data.setIn(['price', 'shareType'], +type);

const addProduct = (data, { products }) => {
  let newData = data;
  products.forEach((i) => {
    newData = newData.setIn(
      ['foundProducts', 'products', i.productId, 'isAdded'],
      true
    );
  });
  return newData;
};

const removeProduct = (data, { productPositions }) => {
  let newData = data;
  const pList = newData.getIn(['foundProducts', 'products']);
  productPositions.forEach((i) => {
    const key = pList.findKey((item) => item.get('priceProductId') === i);
    newData = newData.setIn(
      ['foundProducts', 'products', key, 'isAdded'],
      false
    );
  });
  return newData;
};

const setPriceAttrsFilter = (
  data,
  { attrs, complexAttrs, categories, mode }
) => {
  let newData = data.set('attrsFilter', fromJS(attrs));
  if (mode) {
    newData = newData.set('mode', mode);
  }
  if (categories) {
    newData = newData.set('categoriesFilter', fromJS(categories));
  }
  if (complexAttrs !== undefined) {
    return newData.set('complexAttrsFilter', fromJS(complexAttrs));
  }
  return newData;
};

const setViewMode = (data, { mode }) => data.set('mode', mode);

const setPriceComplexAttrsFilter = (data, { complexAttrs }) =>
  data.set('complexAttrsFilter', fromJS(complexAttrs));

const setPriceCategoryAttrsFilter = (data, { categoriesFilter }) =>
  data.set('categoriesFilter', fromJS(categoriesFilter));

const updatePriceCat = (
  data,
  { id, name, minOrder, discount, from, to, unit }
) => {
  const index = data
    .get('priceCategories')
    .findIndex((i) => i.get('id') === id);
  return data
    .setIn(['priceCategories', index, 'name'], name)
    .setIn(['priceCategories', index, 'minOrder'], +minOrder)
    .setIn(['priceCategories', index, 'discount'], +discount)
    .setIn(['priceCategories', index, 'from'], +from)
    .setIn(['priceCategories', index, 'to'], +to)
    .setIn(['priceCategories', index, 'unit'], +unit);
};

const addCustomerInvite = (data, { request, invite, isExists }) =>
  data.update('invites', (invites) =>
    invites.push(fromJS(isExists ? request : invite))
  );

const removePriceShareInvites = (data, { requests, invites }) =>
  data.update('invites', (items) =>
    items.filter((i) => {
      if (i.get('status')) {
        return !requests.includes(i.get('id'));
      }
      return !invites.includes(i.get('id'));
    })
  );

const addNewStock = (data) => {
  const dataStocks = data.getIn(['formCreate', 'stocks']);
  if (dataStocks.last()) {
    return data.updateIn(['formCreate', 'stocks'], (stocks) =>
      stocks.push(null)
    );
  }
  return data;
};

const removeStock = (data, { index }) =>
  data.updateIn(['formCreate', 'stocks'], (stocks) =>
    stocks.filter((i, n) => n !== index)
  );

const addPriceStocksInfo = (data, { stocks }) =>
  data.set('stocks', fromJS(stocks));

const clearStocks = (data) => data.set('stocks', List());

const setPriceFeature = (data, { name, unit, deleted }) =>
  data.updateIn(
    ['formCreate', 'additionProductsAttributes'],
    (additionProductsAttributes) => {
      const index = additionProductsAttributes.findIndex(
        (i) => i.get('name') === name && i.get('unit') === unit
      );
      return additionProductsAttributes.setIn([index, 'deleted'], deleted);
    }
  );

const setUpdatedPrice = (data, price) => {
  // eslint-disable-next-line no-param-reassign
  price.additionProductsAttributes = data
    .getIn(['formCreate', 'additionProductsAttributes'])
    .toJS()
    .map((i) => {
      if (i.deleted) {
        // eslint-disable-next-line no-param-reassign
        i.updated = true;
      } else {
        // eslint-disable-next-line no-param-reassign
        i.updated = false;
      }
      return i;
    });
  // eslint-disable-next-line no-param-reassign
  price.isUploaded = true;
  return data.set('formCreate', fromJS(price));
};

const setAccessToken = (data, { accessToken }) =>
  data.setIn(['price', 'accessToken'], accessToken);
const setAccessByLink = (data, { accessByLink, accessToken }) =>
  data
    .setIn(['price', 'accessByLink'], accessByLink)
    .setIn(['price', 'accessToken'], accessToken);

const turnAttrFilter = (data, { attr, params, allAttrs }) =>
  data.update('attrsFilter', (attrsFilter) => {
    if (params && params.selectAll === true) {
      return new List(allAttrs);
    }
    if (params && params.removeAll === true) {
      return new List([]);
    }
    if (attrsFilter.includes(attr)) {
      return attrsFilter.filter((i) => i !== attr);
    }
    return attrsFilter.push(attr);
  });

const turnAttrComplexFilter = (data, { name, unit, params, allAttrs }) =>
  data.update('complexAttrsFilter', (complexAttrsFilter) => {
    if (params && params.selectAll) {
      return allAttrs;
    }
    if (params && params.removeAll) {
      return List();
    }
    const index = complexAttrsFilter.findIndex(
      (i) => i.get('name') === name && i.get('unit') === unit
    );
    if (index >= 0) {
      return complexAttrsFilter.filter(
        (i) => !((i.get('name') === name && i.get('unit') === unit) || '')
      );
    }
    return complexAttrsFilter.push(fromJS({ name, unit }));
  });

const turnAttrCategoryFilter = (data, { attr, allAttrs, params }) =>
  data.update('categoriesFilter', (categoriesFilter) => {
    if (params && params.selectAll) {
      return allAttrs;
    }
    if (params && params.removeAll) {
      return List();
    }
    if (categoriesFilter.includes(attr)) {
      return categoriesFilter.filter((i) => i !== attr);
    }
    return categoriesFilter.push(attr);
  });

const setRate = (data, { rate }) => data.setIn(['formCreate', 'rate'], rate);

const unblockItems = (data, { ids = [] }) =>
  data.update('items', (items) => {
    if (!items) return items;
    ids.forEach((id) => {
      const index = items.findIndex((i) => i.get('id') === +id);
      // eslint-disable-next-line no-param-reassign
      items = items.setIn([index, 'blocked'], false);
    });
    return items;
  });

const setErrors = (data, { errors }) => data.set('errors', fromJS(errors));

const removeError = (data, { type }) =>
  data.update('errors', (errors) =>
    errors.filter((i) => i.get('type') !== type)
  );

const setUnits = (data, { units }) => data.set('units', fromJS(units));

const addCopiedPrice = (data, { price }) =>
  data.update('items', (items) => items.push(fromJS(price)));

const pricesReducer = (state = initState(), { type, payload }) => {
  switch (type) {
    case 'PRICES_FOUND:FILTER_SELECT_TOGGLE':
      return toggleFilterSelected(state);
    case 'PRICES_FOUND:SELECT_PRODUCTS':
      return selectProducts(state, payload);
    case 'PRICES_FOUND:UNSELECT_PRODUCTS':
      return unselectProducts(state, payload);
    case 'PRICES:CLEAR_FORM':
      return clearForm(state);
    case 'PRICES:GET_ALL_PRICES:SUCCESS':
      return setPrices(state, payload);
    case 'PRICES:CLEAR_STATE':
      return clearAllPrices(state);
    case 'PRICES:GET_MORE_INFO:SUCCESS':
      return updateDefaultField(state, payload);
    case 'PRICES:CREATE_PRICES:UPDATE_FIELD':
      return updateField(state, payload);
    case 'PRICES:CREATE_PRICES:UPDATE_STOCKS_FIELD':
      return updateStocksField(state, payload);
    case 'PRICES:CREATE_PRICES:UPDATE_ATTRIBUTES_FIELD':
      return updateAdditionAttributeField(state, payload);
    case 'PRICES:SELECT':
      return selectPrice(state, payload);
    case 'PRICE:GET_PRICE:SUCCESS':
      return setPrice(state, payload);
    case 'PRICE:GET_PRICE_FEATURES:SUCCESS':
      return setPriceFeatures(state, payload);
    case 'PRICE:GET_PRICE:FAILURE':
      return setPriceFailure(state, payload);
    case 'PRICE:GET_PRICE_CATEGORIES:SUCCESS':
      return setPriceCategories(state, payload);
    case 'PRICE_CATEGORY:SELECT':
      return selectCategory(state, payload);
    case 'PRICE_CATEGORY_SET_SELECTED':
      return setSelectedCategory(state, payload);
    case 'PRICES:UPDATE_CATEGORY:SUCCESS':
      return updatePriceCat(state, payload);
    case 'PRICE_CATEGORY:UPDATE_NEW':
      return updateNewCategory(state, payload);
    case 'PRICE_CATEGORY:UPDATE':
      return updatePriceCategory(state, payload);
    case 'PRICE_CATEGORY:CLEAR':
      return clearNewCategory(state);
    case 'PRICES:CREATE_CATEGORY:SUCCESS':
      return addCategory(state, payload);
    case 'PRICE_CATEGORY:DESELECT_ALL':
      return deselectAll(state);
    case 'PRICE_CATEGORY:DELETE:SUCCESS':
      return updateCategories(state, payload);
    case 'PRICE:PRICE_EDIT:SET_DEFAULT':
      return setDefaultPriceForm(state);
    case 'PRICE:PRICE_ACCESS_LIST:SUCCESS':
      return setAccessList(state, payload);
    case 'PRICES:DELETE:SUCCESS':
      return deletePrices(state, payload);
    case 'PRICE:SEARCH_PRODUCT:SUCCESS':
      return setFoundProducts(state, payload);
    case 'PRICE:SEARCH_PRODUCT:SELECT':
      return selectFoundProducts(state, payload);
    case 'PRICE:SEARCH_PRODUCT:SELECT_ALL':
      return selectAllFoundProducts(state);
    case 'PRICE:SEARCH_PRODUCT:UPDATE_COST':
      return updateCostFoundProducts(state, payload);
    case 'PRICE:CHECK_PUBLISHED:SUCCESS':
      return setIsPublished(state, payload);
    case 'PRICES:CLEAR_SELECTED':
      return clearSelected(state);
    case 'PRICE:GET_STATUSES:SUCCESS':
      return setPricesStatuses(state, payload);
    case 'PRICE:SET_PRICE_STATUS_DRAFT':
      return setPriceStatus(state, payload);
    case 'PRICE:SET_PRICE_STATUS_PUBLISHED':
      return setPriceStatusPublished(state, payload);
    case 'PRICES:SET_SELECTED_PRICE':
      return setSelectedPrice(state, payload);
    case 'PRICE:SET_STATUS_VIEWED:SUCCESS':
      return setPriceBaidgeStatus(state, payload);
    case 'PRICE:PUBLISH:SUCCESS':
      return setPrice(state, payload);
    case 'PRICE:PUBLISH:REQUEST':
      return setStatusPublishing(state, payload);
    case 'PRICE:REMOVE_ACCESS:SUCCESS':
      return removeFromAccessList(state, payload);
    case 'PRICE:EDIT_ACCESS_REMOVE:SUCCESS':
      return removeAccess(state, payload);
    case 'PRICE:EDIT_ACCESS_ADD:SUCCESS':
      return addAccess(state, payload);
    case 'PRICE:SET_SHARE_TYPE:SUCCESS':
      return setShareType(state, payload);
    case 'PRICE:ADD_PRODUCT_TO_PRICE:SUCCESS':
      return addProduct(state, payload);
    case 'TABLE_PRICE_PRODUCT:PRODUCT_DELETE:SUCCESSS':
      return removeProduct(state, payload);
    case 'PRICE:GET_ATTR_FILTER:SUCCESS':
      return setPriceAttrsFilter(state, payload);
    case 'PRICE:SET_FILTER:SUCCESS':
      return setPriceAttrsFilter(state, payload);
    case 'PRICE:SET_COMPLEX_FILTER:SUCCESS':
      return setPriceComplexAttrsFilter(state, payload);
    case 'PRICE:SET_CATEGORY_FILTER:SUCCESS':
      return setPriceCategoryAttrsFilter(state, payload);
    case 'PRICE_SHARE:CUSTOMER_ADD:SUCCESS':
      return addCustomerInvite(state, payload);
    case 'PRICE_SHARE:DELETE_INVITES:SUCCESS':
      return removePriceShareInvites(state, payload);
    case 'PRICES:APPEND_NEWSTOCK':
      return addNewStock(state);
    case 'PRICES:REMOVE_STOCK':
      return removeStock(state, payload);
    case 'PRICE:GET_PRICE_STOCKS:SUCCESS':
      return addPriceStocksInfo(state, payload);
    case 'PRICE:REMOVE_FEATURE:SUCCESS':
      return setPriceFeature(state, payload);
    case 'PRICE:UPDATE_PRICES:SUCCESS':
      return setUpdatedPrice(state, payload);
    case 'PRICE:STOCKS_CLEAR':
      return clearStocks(state);
    case 'PRICE:GENERATE_ACCESS_LINK:SUCCESS':
      return setAccessToken(state, payload);
    case 'PRICE:TURN_ACCESS_BY_LINK:SUCCESS':
      return setAccessByLink(state, payload);
    case 'PRICE:TURN_ATTR_FILTER':
      return turnAttrFilter(state, payload);
    case 'PRICE:TURN_ATTR_COMPLEX_FILTER':
      return turnAttrComplexFilter(state, payload);
    case 'PRICE:TURN_ATTR_CATEGORY_FILTER':
      return turnAttrCategoryFilter(state, payload);
    case 'PRICE:GET_RBK_RATE:SUCCESS':
      return setRate(state, payload);
    case 'PRICES:UNBLOCK_ITEMS:SUCCESS':
      return unblockItems(state, payload);
    case 'PRICES:CREATE_PRICES:ERRORS':
      return setErrors(state, payload);
    case 'PRICES:REMOVE_ERROR':
      return removeError(state, payload);
    case 'PRICE:SET_VIEW_MODE:SUCCESS':
      return setViewMode(state, payload);
    case 'PRICE:GET_UNITS:SUCCESS':
      return setUnits(state, payload);
    case 'TABLE_PRICE_PRODUCT:PRODUCT_DELETE_BY_PUB_PROD_ID:SUCCESSS':
      return resetListOfBaseCost(state, payload);
    case 'PRICES:COPY:SUCCESS':
      return addCopiedPrice(state, payload);
    case 'PRICE:NOTIFY_CUSTOMERS:REQUEST':
      return setPriceCustomersNotifiedStatus(state, payload);
    case 'PRICE:NOTIFY_CUSTOMERS:SUCCESS':
      return setPriceCustomersNotifiedStatus(state, payload);
    case 'PRICE:NOTIFY_CUSTOMERS:FAILURE':
      return setPriceCustomersNotifiedStatus(state, payload);
    case 'PRICE:CREATE_ADDITIONAL_CATEGORY:SUCCESS':
      return addAdditionalCategory(state, payload);
    case 'PRICE:UPDATE_ADDITIONAL_CATEGORY:SUCCESS':
      return updateAdditionalCategory(state, payload);
    case 'PRICE:DELETE_ADDITIONAL_CATEGORY:SUCCESS':
      return removeAdditionalCategory(state, payload);
    case 'PRICE:ADD_PRODUCTS_TO_ADDITIONAL_CATEGORIES:SUCCESS':
      return addProductsToAdditionalCategory(state, payload);
    case 'PRICE:REMOVE_PRODUCTS_TO_ADDITIONAL_CATEGORIES:SUCCESS':
      return removeProductsToAdditionalCategory(state, payload);
    case 'PRICE:SET_POSITIONS_CATEGORIES':
      return setPositionsCategories(state, payload);
    default:
      return state;
  }
};

export default pricesReducer;
