export const getAllResponses = (state) =>
  state.get('purchaseRequestResponses').responses;
export const getSize = (state) => state.get('purchaseRequestResponses').size;
export const getCurrentResponse = (state) =>
  state.get('purchaseRequestResponses').response;
export const getSelectedCategoriesId = (state) =>
  state.get('purchaseRequestResponses').selectedCategoriesId;
export const getCurrencyList = (state) => state.get('currencyList').toJS();
export const getCountries = (state) =>
  state.get('purchaseRequestResponses').countries;
export const getRegions = (state) =>
  state.get('purchaseRequestResponses').regions;
export const getRegionsCount = (state) =>
  state.get('purchaseRequestResponses').regionsCount;
export const getCities = (state) =>
  state.get('purchaseRequestResponses').cities;
export const getLimit = (state) => state.get('purchaseRequestResponses').limit;
export const getOffset = (state) =>
  state.get('purchaseRequestResponses').offset;
export const getSearch = (state) =>
  state.get('purchaseRequestResponses').search;
export const getStatus = (state) =>
  state.get('purchaseRequestResponses').selectedStatus;
export const getOrder = (state) =>
  state.get('purchaseRequestResponses').orderBy;
export const getDirection = (state) =>
  state.get('purchaseRequestResponses').direction;
export const getFilters = (state) =>
  state.get('purchaseRequestResponses').filters;
export const getFilterApplied = (state) =>
  state.get('purchaseRequestResponses').filterApplied;
export const getAllSuppliersOfMyRequests = (state) =>
  state.get('purchaseRequestResponses').suppliers;
export const getIsUploaded = (state) =>
  state.get('purchaseRequestResponses').isUploaded;
