import React, { useMemo } from 'react';
import block from 'bem-cn-lite';
import Info from './Info';
import Content from './Content';
import customHistory from '../../../../customHistory';
import './style.css';

const b = block('card-request');
const RequestCard = ({
  request,
  categories = [],
  company,
  onToggleBookmark,
  haveUpdatePermission = true
}) => {
  const onRespond = () =>
    customHistory.push(`/requests/all/${request.get('id')}?response=true`);

  const icons = useMemo(
    () => [
      {
        icon: 'favorite',
        onClick: () => onToggleBookmark(request.get('id')),
        isSelect: request.get('isBookmarked'),
        size: 'sm'
      }
    ],
    [request]
  );

  return (
    <div className={b()}>
      <div className={b('content')}>
        <Content
          request={request}
          categories={categories}
          icons={icons}
          company={company}
          onRespond={onRespond}
          haveUpdatePermission={haveUpdatePermission}
        />
      </div>
      <div className={b('info')}>
        <Info request={request} />
      </div>
    </div>
  );
};

export default RequestCard;
