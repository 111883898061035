import React, { Component } from 'react';
import PropTypes from 'prop-types';
import block from 'bem-cn-lite';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { translate } from 'react-i18next';
import {
  Modal,
  Form,
  FormControl,
  FormGroup,
  Button,
  ControlLabel,
  HelpBlock
} from 'react-bootstrap';
import './styles/modal_theme_default.styl';
import './styles/modal_type_grant-access.styl';

import { ModalContainer } from './elements';

import * as modalAction from '../../action-creators/modal';
import * as userAction from '../../action-creators/user';
import * as servicesAction from '../../action-creators/services';
import keyDebounce from '../../utils/debounce';

const b = block('modal-container');

class ModalChangePassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      oldPassword: '',
      newPassword: '',
      repeatPassword: '',
      error: { messages: [] }
    };
    this.setOldPassword = this.setOldPassword.bind(this);
    this.setNewPassword = this.setNewPassword.bind(this);
    this.setRepeatPassword = this.setRepeatPassword.bind(this);
    this.setError = this.setError.bind(this);
    this.searchErrorType = this.searchErrorType.bind(this);
    this.validatePassword = keyDebounce(this.validatePassword.bind(this), 1500);
  }

  getChildContext() {
    return { b };
  }

  setOldPassword(e) {
    this.setState({ oldPassword: e.target.value });
  }

  setNewPassword(e) {
    this.setState({ newPassword: e.target.value });
    this.setError([]);
    this.validatePassword();
  }

  setRepeatPassword(e) {
    this.setState({ repeatPassword: e.target.value });
    this.setError([]);
    this.validatePassword();
  }

  setError(messages) {
    this.setState({ error: { messages } });
  }

  validateForm() {
    const { oldPassword, newPassword, repeatPassword } = this.state;
    const { PASSWORD_MIN_LENGTH } = process.env;

    return (
      oldPassword.length >= PASSWORD_MIN_LENGTH &&
      newPassword.length >= PASSWORD_MIN_LENGTH &&
      repeatPassword.length >= PASSWORD_MIN_LENGTH &&
      newPassword === repeatPassword &&
      oldPassword !== newPassword
    );
  }

  validatePassword() {
    const { newPassword, oldPassword, repeatPassword } = this.state;

    if (repeatPassword.length > 0 && !(newPassword === repeatPassword)) {
      this.setError([{ type: 'matchError', message: 'Passwords not match' }]);
    }

    if (newPassword.length < 8) {
      this.setError([{ type: 'lengthError', message: 'Min length 8 symbols' }]);
    }

    if (newPassword === oldPassword) {
      this.setError([
        { type: 'samePassError', message: 'Old and new password match' }
      ]);
    }
  }

  searchErrorType(type) {
    const {
      error: { messages }
    } = this.state;
    const isInArray = (el) => el.type === type;
    return messages.some(isInArray);
  }

  render() {
    const { t, gaSend, user, title, hideModalDialog, changePassword } =
      this.props;

    return (
      <Modal id="changePassword" onHide={() => hideModalDialog()} show>
        <ModalContainer mods={{ type: 'grant-access', theme: 'default' }}>
          <Modal.Header>
            <Modal.Title>
              {t(title)}
              <span
                className="modal-header-cross pull-right"
                onClick={() => hideModalDialog()}
              />
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <FormGroup
                controlId="currentPassword"
                validationState={
                  this.searchErrorType('passwordError') ? 'error' : null
                }>
                <ControlLabel
                  style={{
                    color: '#999999',
                    fontWeight: 'normal',
                    fontSize: '14px'
                  }}>
                  {t('Input old password')}
                  <span style={{ color: 'red' }}>*</span>
                </ControlLabel>
                <FormControl
                  type="password"
                  maxLength="60"
                  value={this.state.oldPassword}
                  onChange={this.setOldPassword}
                />
              </FormGroup>
              {this.state.error.messages.length > 0 &&
                this.state.error.messages.map((error) => {
                  if (error.type === 'passwordError') {
                    return (
                      <HelpBlock style={{ marginBottom: 0, color: 'red' }}>
                        {t(error.message)}
                      </HelpBlock>
                    );
                  }
                  return null;
                })}
              <FormGroup
                controlId="newPassword"
                validationState={
                  this.searchErrorType('matchError') ||
                  this.searchErrorType('lengthError') ||
                  this.searchErrorType('samePassError')
                    ? 'error'
                    : null
                }>
                <ControlLabel
                  style={{
                    color: '#999999',
                    fontWeight: 'normal',
                    fontSize: '14px'
                  }}>
                  {t('Input new password')}
                  <span style={{ color: 'red' }}>* </span>
                </ControlLabel>
                <FormControl
                  type="password"
                  maxLength="60"
                  value={this.state.newPassword}
                  placeholder={t('Min length 8 symbols')}
                  onChange={this.setNewPassword}
                />
              </FormGroup>
              {this.state.error.messages.length > 0 &&
                this.state.error.messages.map((error) => {
                  if (
                    error.type === 'lengthError' ||
                    error.type === 'samePassError'
                  ) {
                    return (
                      <HelpBlock style={{ marginBottom: 0, color: 'red' }}>
                        {t(error.message)}
                      </HelpBlock>
                    );
                  }
                  return null;
                })}
              <FormGroup
                controlId="newPasswordRepeat"
                validationState={
                  this.searchErrorType('matchError') ? 'error' : null
                }>
                <ControlLabel
                  style={{
                    color: '#999999',
                    fontWeight: 'normal',
                    fontSize: '14px'
                  }}>
                  {t('Repeat password')}
                  <span style={{ color: 'red' }}>* </span>
                </ControlLabel>
                <FormControl
                  type="password"
                  maxLength="60"
                  value={this.state.repeatPassword}
                  onChange={this.setRepeatPassword}
                />
              </FormGroup>
              {this.state.error.messages.length > 0 &&
                this.state.error.messages.map((error) => {
                  if (error.type === 'matchError') {
                    return (
                      <HelpBlock style={{ marginBottom: 0, color: 'red' }}>
                        {t(error.message)}
                      </HelpBlock>
                    );
                  }
                  return null;
                })}
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button id="cancel" className="pull-left" onClick={hideModalDialog}>
              {t('Cancel')}
            </Button>
            <Button
              id="saveCharacteristicButton"
              bsStyle="primary"
              disabled={!this.validateForm()}
              onClick={() => {
                gaSend({
                  category: 'Common',
                  action: 'change_password',
                  label: user.get('email')
                });
                this.setError([]);
                return changePassword(this.state).then((err) => {
                  if (err) {
                    this.setError(err);
                  } else {
                    hideModalDialog();
                  }
                });
              }}>
              {t('Change password')}
            </Button>
          </Modal.Footer>
        </ModalContainer>
      </Modal>
    );
  }
}

ModalChangePassword.propTypes = {
  t: PropTypes.func.isRequired
};

ModalChangePassword.childContextTypes = {
  b: PropTypes.func
};

export default connect(
  (state) => ({
    user: state.getIn(['user', 'user'])
  }),
  (dispatch) =>
    bindActionCreators(
      {
        hideModalDialog: modalAction.hideModalDialog,
        changePassword: userAction.changePassword,
        gaSend: servicesAction.gaSend
      },
      dispatch
    )
)(translate(['ui'], { wait: true })(ModalChangePassword));
