import React from 'react';
import Message from '../Message';
import './style.css';

const MessagesContainer = ({ messages }) => (
  <div className="email-messages-container">
    {messages.map((message) => (
      <Message key={message.messageId} message={message} />
    ))}
  </div>
);

export default MessagesContainer;
