import { useMemo } from 'react';
import { Tooltip as MUITooltip, TooltipClasses } from '@mui/material';
import { Typography, Align } from '@/components/Typography';
import styles from './Tooltip.module.scss';
import { TooltipProps } from './Tooltip.types';

const tooltipClasses: Partial<TooltipClasses> = {
  popper: styles.popper
};

interface TooltipTitleProps {
  text: string;
  maxWidth?: string | number;
  align?: Align;
}

function TooltipTitle({ text, maxWidth, align }: TooltipTitleProps) {
  const style = useMemo(
    () => ({
      maxWidth
    }),
    [maxWidth]
  );

  return (
    <Typography style={style} variant="body2Reg" align={align}>
      {text}
    </Typography>
  );
}

export function Tooltip({
  children,
  title,
  placement = 'top',
  maxWidth = 'unset',
  align = 'left'
}: TooltipProps): JSX.Element {
  return (
    <MUITooltip
      classes={tooltipClasses}
      arrow
      placement={placement}
      enterDelay={100}
      enterTouchDelay={700}
      leaveDelay={0}
      leaveTouchDelay={1500}
      title={
        title ? (
          <TooltipTitle text={title} maxWidth={maxWidth} align={align} />
        ) : null
      }>
      <div className={styles.trigger}>{children}</div>
    </MUITooltip>
  );
}
