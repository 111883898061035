import React, { useEffect, useState } from 'react';
import { useActions, useTranslate, useNavigate } from '@hooks';
import { useSelector } from 'react-redux';
import { Button } from '@link/react-components';
import {
  executorActionTypes,
  executorsActions,
  unpublishStatuses
} from '../../../domain/entities';
import styles from './RequestWithConfirmationButtons.module.css';
import * as modalActions from '../../../../../action-creators/modal';
import { withConfirmationRequestService } from '../../../../../api';
import * as alertMessageActions from '../../../../../action-creators/message';
import * as storeGetters from '../../../../../storeGetters';
import * as requestsActions from '../../../../../action-creators/purchaseRequests';

import * as serviceActions from '../../../../../action-creators/services';
import { usePublishOrCaseContext } from '../../../../../pages/withConfirmationRequest/publishOrCaseContext';
import { ACTION_TYPES } from '../../../../../pages/withConfirmationRequest/components/withConfirmationRequestContent';

function getTranslate({ t, type }) {
  return t(`purchaseRequest.view.${type}`);
}

function IntroductionButtons({ canExecute, setCanExecute }) {
  const t = useTranslate();
  const request = useSelector(storeGetters.getCurrentPurchaseRequest).toJS();
  const requestId = request?.id;
  const { setSuccessMessage } = useActions(alertMessageActions);
  const { updatePurchaseRequestStatus } = useActions(requestsActions);
  const onConfirmIntroduction = async () => {
    try {
      const updatedRequest = await withConfirmationRequestService.confirm({
        action: executorsActions.resolve,
        requestId
      });

      updatePurchaseRequestStatus({
        id: requestId,
        status: updatedRequest.request.status
      });
      setSuccessMessage({
        key: 'purchaseRequest.notifications.acceptIntroduction'
      });
      setCanExecute(false);
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <Button
      mode="primary"
      onClick={onConfirmIntroduction}
      disabled={!canExecute}>
      {getTranslate({ t, type: 'notified' })}
    </Button>
  );
}

function AgreementButtons({ canExecute, setCanExecute }) {
  const t = useTranslate();
  const { showModal } = useActions(modalActions);
  const { setSuccessMessage, setErrorMessage } =
    useActions(alertMessageActions);
  const { updatePurchaseRequestStatus } = useActions(requestsActions);
  const request = useSelector(storeGetters.getCurrentPurchaseRequest).toJS();
  const requestId = request?.id;

  const onCancel = () => {
    showModal('SIMPLE_SUBMIT', {
      title: 'purchaseRequest.modals.cancelAgreement.title',
      text: 'purchaseRequest.modals.cancelAgreement.content',
      textBtnConfirm: 'modals.add-link.btn-confirm',
      textBtnCancel: 'modals.add-link.btn-cancel',
      submitAction: async () => {
        const updatedRequest = await withConfirmationRequestService.confirm({
          action: executorsActions.reject,
          requestId
        });
        updatePurchaseRequestStatus({
          id: requestId,
          status: updatedRequest.request.status
        });
        setErrorMessage({
          key: 'purchaseRequest.notifications.cancelAgreement'
        });
        setCanExecute(false);
      }
    });
  };

  const onConfirm = () => {
    showModal('SIMPLE_SUBMIT', {
      title: 'purchaseRequest.modals.confirmAgreement.title',
      text: 'purchaseRequest.modals.confirmAgreement.content',
      textBtnConfirm: 'modals.add-link.btn-confirm',
      textBtnCancel: 'modals.add-link.btn-cancel',
      submitAction: async () => {
        const updatedRequest = await withConfirmationRequestService.confirm({
          action: executorsActions.resolve,
          requestId
        });
        updatePurchaseRequestStatus({
          id: requestId,
          status: updatedRequest.request.status
        });
        setSuccessMessage({
          key: 'purchaseRequest.notifications.acceptAgreement'
        });
        setCanExecute(false);
      }
    });
  };

  return (
    <>
      <Button mode="outline" onClick={onCancel} disabled={!canExecute}>
        {getTranslate({ t, type: 'reject' })}
      </Button>
      <Button mode="primary" onClick={onConfirm} disabled={!canExecute}>
        {getTranslate({ t, type: 'agree' })}
      </Button>
    </>
  );
}

function PublicationButtons({ canExecute }) {
  const t = useTranslate();
  const { showModal } = useActions(modalActions);
  const { runValidation, generatePurchaseRequest, onCancelAction, hasChanges } =
    usePublishOrCaseContext();
  const gaSend = useActions(serviceActions.gaSendServiceAction);
  const { publishRequest, saveRequest } = useActions(requestsActions);
  const request = useSelector(storeGetters.getCurrentPurchaseRequest).toJS();
  const requestId = request?.id;
  const navigate = useNavigate();

  const isPublished = !unpublishStatuses.includes(request.status);

  const onConfirmCb = async () => {
    const purchaseRequest = generatePurchaseRequest();

    await publishRequest(requestId, purchaseRequest);
    navigate('/requests/my');
  };

  const onDraftCb = async () => {
    const purchaseRequest = generatePurchaseRequest();
    await saveRequest(purchaseRequest, true);

    gaSend('complete_request_creation');
  };

  const onDraft = () => {
    const isValid = runValidation(ACTION_TYPES.SAVE);

    if (!isValid) {
      return;
    }

    onDraftCb();
  };

  const onConfirm = () => {
    const isValid = runValidation(ACTION_TYPES.PUBLISH);

    if (!isValid) {
      return;
    }

    showModal('SIMPLE_SUBMIT', {
      title: 'purchaseRequest.modals.confirmPublication.title',
      text: 'purchaseRequest.modals.confirmPublication.content',
      textBtnCancel: 'modals.add-link.btn-cancel',
      textBtnConfirm: 'modals.add-link.btn-publication',
      submitAction: onConfirmCb
    });
  };
  return (
    <>
      <Button mode="text" onClick={onCancelAction}>
        {getTranslate({ t, type: 'cancel' })}
      </Button>
      <Button
        mode="outline"
        onClick={onDraft}
        disabled={!canExecute || isPublished || !hasChanges()}>
        {getTranslate({ t, type: 'save' })}
      </Button>
      <Button mode="primary" onClick={onConfirm} disabled={!canExecute}>
        {getTranslate({ t, type: 'publish' })}
      </Button>
    </>
  );
}

function RequestWithConfirmationBlock({ type, canExecute: canConfirm }) {
  const [canExecute, setCanExecute] = useState(canConfirm);

  useEffect(() => {
    setCanExecute(canConfirm);
  }, [canConfirm]);
  switch (type) {
    case executorActionTypes.agreement:
      return (
        <AgreementButtons
          canExecute={canExecute}
          setCanExecute={setCanExecute}
        />
      );
    case executorActionTypes.introduction:
      return (
        <IntroductionButtons
          canExecute={canExecute}
          setCanExecute={setCanExecute}
        />
      );
    case executorActionTypes.publication:
      return <PublicationButtons canExecute={canExecute} />;
    default:
      console.log('No such type');
      return <></>;
  }
}

export const RequestWithConfirmationButtonsBlock = ({ type, canExecute }) => (
  <div className={styles.container}>
    <RequestWithConfirmationBlock type={type} canExecute={canExecute} />
  </div>
);
