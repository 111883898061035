import React from 'react';
import block from 'bem-cn-lite';
import { translate } from 'react-i18next';
import ArrowLeftIcon from '../common/assets/icons/arrow-left';

const b = block('snm-header');

const Header = ({ t, onClose }) => (
  <div className={b()} onClick={onClose}>
    <ArrowLeftIcon />
    {t('toogle menu')}
  </div>
);

export default translate()(Header);
