export const executorsActions = Object.freeze({
  pending: 'pending',
  resolve: 'resolve',
  reject: 'reject'
});

export class Executor {
  constructor(props) {
    this.id = props.id;
    this.firstName = props.firstName;
    this.middleName = props.middleName;
    this.lastName = props.lastName;
    this.department = props.department;
    this.actionType = props.actionType;
    this.status = props.status;
    this.step = props.step;
  }
}
