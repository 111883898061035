.dateTimePicker {
  :global(.MuiFormControl-root) {
    :global(.MuiInputBase-root) {
      width: 174px;
      border: 1px solid var(--color-gray-500);
      border-radius: 4px;
      padding: 0;
      font-size: inherit;
      font-family: inherit;
      font-weight: inherit;
      color: var(--color-gray-900);

      :global(.MuiInputBase-input) {
        padding: 8px 10px 8px 12px;
        border-right: 1px solid var(--color-gray-500);
      }

      &:hover {
        border: 1px solid var(--color-blue-200);
      }
    }

    .calendarIcon {
      &:hover > path {
        fill: var(--color-blue-500);
      }
    }

    :global(.MuiInputBase-root.Mui-disabled) {
      background-color: var(--color-gray-300);
      color: var(--color-gray-700);

      .calendarIcon > path {
        fill: var(--color-gray-400);
      }

      &:hover {
        border: 1px solid var(--color-gray-500);
      }
    }
  }

  :global(.MuiInputAdornment-root) {
    margin: 0;
    height: 34px;
    width: 48px;

    :global(.MuiButtonBase-root) {
      padding: 0;
      left: 8px;
    }
  }

  :global(.MuiOutlinedInput-notchedOutline) {
    border: none;
    padding: 0;
  }

  :global(.MuiIconButton-root):hover {
    background: none;
  }
}

.active {
  :global(.MuiInputBase-root) {
    border: 1px solid var(--color-blue-400);
    box-shadow: 0 0 5px rgb(35 105 241 / 31%);
  }
}

.arrowLeft {
  position: absolute;
  left: 6px;
  top: -8px;
}

.arrowRight {
  position: absolute;
  left: 224px;
  top: -8px;
}

.paper {
  margin-top: 4px;
  box-shadow: 0 6px 22px rgb(32 34 45 / 21%);
  width: 276px;

  :global(.MuiCalendarPicker-root) {
    width: 276px;
    height: 312px;
    box-sizing: border-box;
    padding: 0;
    margin: 0;
  }

  :global(.css-epd502) {
    width: 276px;
    height: 312px;
    box-sizing: border-box;
    padding: 0;
    margin: 0;
  }

  :global(.MuiPickersCalendarHeader-label) {
    font-weight: var(--text-bold);
    flex-grow: 1;
    color: var(--color-gray-800);

    &::first-letter {
      text-transform: uppercase;
    }
  }

  :global(.MuiDayPicker-header) {
    justify-content: space-between;
    box-sizing: border-box;
    margin: 0 6px 6px;
    padding: 4px 8px;
    width: 264px;
    background-color: var(--color-gray-200);

    :global(.MuiDayPicker-weekDayLabel) {
      width: 28px;
      height: 16px;
      font-family: var(--font);
      color: var(--color-gray-800);
    }
  }

  :global(.MuiDayPicker-monthContainer) {
    box-sizing: border-box;
    margin: 0 6px;
    padding: 4px 8px;
    width: 264px;
  }

  :global(.MuiPickersCalendarHeader-root) {
    justify-content: space-between;
    box-sizing: border-box;
    width: 264px;
    padding: 6px;
    margin: 16px 6px;
    font-family: inherit;

    :global(.MuiPickersCalendarHeader-labelContainer) {
      flex-grow: 1;
      margin: 0;
      text-align: center;
      font-family: inherit;
      font-size: inherit;
      font-weight: inherit;
    }
  }

  :global(.MuiCalendarOrClockPicker-root) {
    height: 312px;
  }

  :global(.MuiDayPicker-weekContainer) {
    justify-content: space-between;
    margin-bottom: 6px;
    font-family: inherit;
    font-size: inherit;
    font-weight: inherit;
  }

  :global(.MuiPickersDay-root) {
    font-family: inherit;
    font-size: inherit;
    font-weight: inherit;
    width: 28px;
    height: 28px;
    color: var(--color-gray-900);

    &:hover {
      background-color: var(--color-blue-100);
      border-color: var(--color-blue-100);
      font-weight: var(--text-regular);
      color: var(--color-gray-900);
    }
  }

  :global(.MuiPickersDay-root.Mui-selected) {
    background-color: var(--color-white);
    color: var(--color-gray-900);
    border: 1px solid var(--color-blue-500);
    font-weight: var(--text-regular);

    &:hover {
      background-color: var(--color-blue-100);
      border-color: var(--color-blue-100);
      color: var(--color-gray-900);
    }
  }

  :global(.MuiPickersDay-root.Mui-disabled) {
    color: var(--color-gray-500);
  }

  :global(.MuiPickersDay-root.MuiPickersDay-today) {
    background-color: var(--color-blue-500);
    border-color: var(--color-blue-500);
    color: var(--color-white);
    font-weight: var(--text-semibold);

    &:hover {
      background-color: var(--color-blue-100);
      border-color: var(--color-blue-100);
      color: var(--color-gray-900);
    }
  }

  :global(.MuiDayPicker-slideTransition) {
    height: 220px;
    min-height: 220px;
  }

  :global(.MuiPickersArrowSwitcher-root) {
    position: absolute;
  }

  :global(.MuiPickersCalendarHeader-switchViewIcon) {
    display: none;
  }

  :global(.MuiYearPicker-root) {
    height: 240px;
    font-size: inherit;
    font-family: var(--font);
    font-weight: inherit;

    :global(.PrivatePickersYear-root) {
      flex-basis: 20%;
    }

    :global(.PrivatePickersYear-yearButton) {
      width: 45px;
      height: 24px;
      margin: 2px;
      padding: 0;
      font-size: var(--small-text-size);
      font-family: var(--font);
      font-weight: inherit;
      border-radius: 4px;
      color: var(--color-gray-800);
      background-color: var(--color-gray-200);

      &:hover {
        background-color: var(--color-gray-400);
      }
    }

    :global(.PrivatePickersYear-yearButton.Mui-selected) {
      background-color: var(--color-blue-500);
      color: var(--color-white);
      border: 1px solid var(--color-blue-500);
    }

    :global(.PrivatePickersYear-yearButton.Mui-disabled) {
      color: var(--color-gray-500);

      &:hover {
        background-color: var(--color-gray-200);
      }
    }
  }
}
