import React from 'react';
import { Icon, Typography } from '@link/react-components';
import { useTranslate } from '../../../TranslateProvider';
import styles from './RequestKPByEmail.module.css';

export function InfoBlock() {
  const t = useTranslate();
  return (
    <div className={styles.infoBlock}>
      <Icon iconName="info" width={18} height={18} className={styles.icon} />
      <Typography variant="body1Reg" className={styles.infoText}>
        {t('requestKPByEmail.info')}
      </Typography>
    </div>
  );
}
