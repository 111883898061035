import React, { Component } from 'react';
import PropTypes from 'prop-types';
import block from 'bem-cn-lite';
import { translate } from 'react-i18next';
import { Modal, Button, Row, Col, ControlLabel } from 'react-bootstrap';
import Select from 'react-select';
import './styles/modal_theme_default.styl';
import Table from '../TableNew';

import { ModalContainer } from './elements';

const b = block('modal-container');

class ModalSettingsAccess extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      accessType: props.accessType,
      employees: props.employees
    };

    this.submit = this.submit.bind(this);
    this.close = this.close.bind(this);
    this.onSelect = this.onSelect.bind(this);
  }

  getChildContext() {
    return { b };
  }

  UNSAFE_componentWillMount() {
    const { resourceType, gaSend, user } = this.props;
    if (resourceType === 'catalogs') {
      gaSend({
        category: 'Supplier',
        action: 'catalog_add_access_open',
        label: user.get('email')
      });
    }
    if (resourceType === 'prices') {
      gaSend({
        category: 'Supplier',
        action: 'price_add_access_open',
        label: user.get('email')
      });
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.accessType !== this.accessType) {
      this.accessType = nextProps.accessType;
    }
    if (nextProps.employees !== this.employees) {
      this.employees = nextProps.employees;
    }
  }

  onSelect(id) {
    const index = this.employees.findIndex(
      (employee) => employee.get('id') === id
    );
    if (index === -1) return;
    const currentIsSelected = this.employees.getIn([index, 'isSelected']);
    this.employees = this.employees.update(index, (employee) =>
      employee.set('isSelected', !currentIsSelected)
    );
  }

  set accessType(value) {
    this.setState({ accessType: value });
  }

  get accessType() {
    return this.state.accessType;
  }

  set employees(listOfEmployees) {
    const index = listOfEmployees.findIndex((employee) =>
      employee.get('isOwner')
    );
    let employees = listOfEmployees.map((employee) =>
      employee.set('id', employee.get('userId'))
    );
    if (index > -1) {
      employees = employees.update(index, (employee) =>
        employee.set('withoutCheckbox', true)
      );
    }
    this.setState({ employees });
  }

  get employees() {
    return this.state.employees.sort(
      (one, two) => two.get('isOwner') - one.get('isOwner')
    );
  }

  get selectedUsers() {
    const { currentUserId } = this.props;

    const users = this.employees
      .filter((employee) => employee.get('isSelected'))
      .toJS()
      .map(({ id }) => id);

    users.push(currentUserId);

    return users;
  }

  get argsToSubmit() {
    return { accessType: this.accessType, usersId: this.selectedUsers };
  }

  get columns() {
    const { t } = this.props;
    return [
      Table.ColText({ accessor: 'name', title: t('Employee name') }),
      Table.ColText({ accessor: 'nickname', title: t('Name in company') }),
      Table.ColText({ accessor: 'position', title: t('Position') }),
      Table.ColText({ accessor: ({ isOwner }) => isOwner && t('Owner') })
    ];
  }

  close() {
    const { hideModalDialog, resourceType, gaSend, user } = this.props;
    if (resourceType === 'catalogs') {
      gaSend({
        category: 'Supplier',
        action: 'catalog_add_access_cancel',
        label: user.get('email')
      });
    }
    if (resourceType === 'prices') {
      gaSend({
        category: 'Supplier',
        action: 'price_add_access_cancel',
        label: user.get('email')
      });
    }
    hideModalDialog();
  }

  submit() {
    const { submit, gaSend, user, resourceType } = this.props;

    // Google Analytics
    if (resourceType === 'stocks') {
      gaSend({
        category: 'Supplier',
        action: 'stock_access',
        label: user.get('email')
      });
    }

    submit(this.argsToSubmit).then(this.close);
  }

  render() {
    const { t } = this.props;

    return (
      <Modal id="grandAccess" onHide={this.close} show>
        <ModalContainer mods={{ type: 'grant-access', theme: 'default' }}>
          <Modal.Header>
            <Modal.Title>
              {t('Assign access')}
              <span
                className="modal-header-cross pull-right"
                onClick={this.close}
              />
            </Modal.Title>
          </Modal.Header>
          <Modal.Body style={{ paddingTop: 0 }}>
            <Row>
              <Col xs={6}>
                <ControlLabel className="control-label-row">
                  {t('Access type')}
                </ControlLabel>
                <Select
                  id="select-type-access"
                  value={this.accessType}
                  onChange={(row) => {
                    this.accessType = row.value;
                  }}
                  placeholder={t('not selected')}
                  options={[
                    { value: 'full', label: t('Full') },
                    { value: 'limited', label: t('Limited') }
                  ]}
                  clearable={false}
                  searchable={false}
                />
              </Col>
            </Row>
            {this.accessType === 'limited' && (
              <Row>
                <Col xs={12}>
                  <p style={{ margin: '15px 0' }}>
                    {t('modal_desc_assign_access')}
                  </p>
                </Col>
              </Row>
            )}
            {this.accessType === 'limited' && (
              <Row>
                <Col>
                  <Table
                    columns={this.columns}
                    data={this.employees}
                    onSelect={this.onSelect}
                  />
                </Col>
              </Row>
            )}
          </Modal.Body>
          <Modal.Footer>
            <Button id="cancel" className="pull-left" onClick={this.close}>
              {t('Cancel')}
            </Button>
            <Button id="save" bsStyle="primary" onClick={this.submit}>
              {t('Save')}
            </Button>
          </Modal.Footer>
        </ModalContainer>
      </Modal>
    );
  }
}

ModalSettingsAccess.propTypes = {
  t: PropTypes.func.isRequired
};

ModalSettingsAccess.childContextTypes = {
  b: PropTypes.func
};

export default translate(['ui'], { wait: true })(ModalSettingsAccess);
