import React, { useState, useCallback } from 'react';
import { useActions, useTranslate, useDebounce } from '@hooks';
import { useSelector } from 'react-redux';
import {
  Button,
  Modal,
  Input,
  Typography,
  Icon,
  Link,
  ButtonIcon,
  Tooltip
} from '@link/react-components';
import cn from 'classnames';
import { validateEmail } from '../../../utils/validateField';
import request from '../../../lib/request';
import { partnersCompanyService } from '../../../api';
import * as storeGetters from '../../../storeGetters';
import * as modalActions from '../../../action-creators/modal';
import styles from './ModalInvitePartnerCompany.module.css';
import { useOpenChat } from '../../OpenChat/useOpenChat';
import * as notificationMessageActions from '../../../action-creators/message';
import { convertUrl } from '../../ChatWidget/modules/files/utils';

const DELAY = 500;

async function getInfo(email) {
  return await request.get(`/api/email-info/${email}`);
}

const ExtraSection = ({
  className,
  option,
  company,
  user,
  getTranslate,
  handleOpenChat
}) => {
  const companyLink = option === 'hiddenUser' && (
    <Link
      className={styles.link}
      href={`/companies/${company.id}/view`}
      displayName={company.name}
      openInNewTab={false}
    />
  );

  return (
    <div className={className}>
      <MessageBlock>
        <Typography variant="body1Reg">
          {getTranslate(option)} {companyLink}
        </Typography>
      </MessageBlock>
      {user && (
        <UserCard
          user={user}
          company={company}
          handleOpenChat={handleOpenChat}
          tooltip={getTranslate('openChatWithEmployee')}
        />
      )}
    </div>
  );
};

const MessageBlock = ({ children }) => (
  <div className={styles.messageBlock}>
    <div className={styles.iconContainer}>
      <Icon iconName="info" height={18} width={18} className={styles.icon} />
    </div>
    {children}
  </div>
);

const UserCard = ({ user, company, tooltip, handleOpenChat }) => {
  const [avatarSrc, setAvatarSrc] = useState(
    convertUrl(user?.avatar) || '/img/user.svg'
  );
  return (
    <div className={styles.userCard}>
      <div className={styles.userInfo}>
        <img
          className={styles.avatar}
          src={avatarSrc}
          onError={() => setAvatarSrc('/img/user.svg')}
          alt="avatar"
          width={40}
          height={40}
        />
        <div className={styles.userContent}>
          <Typography variant="body1Med" className={styles.name} noWrap>
            {getFullEmployeeName(user)}
          </Typography>
          <Typography variant="body2Reg" className={styles.company} noWrap>
            {company.name}
          </Typography>
        </div>
      </div>
      <Tooltip placement="top" title={tooltip}>
        <ButtonIcon
          iconName="chat"
          onClick={handleOpenChat}
          size={18}
          variant="large"
        />
      </Tooltip>
    </div>
  );
};

function defineOption(info) {
  let option;
  if (!info) {
    return '';
  }

  if (!info.userExists) {
    option = info.partnerRequestExists ? 'invitationWasSent' : 'send';
  } else {
    option = info.userIsHidden ? 'hiddenUser' : 'availableUser';
  }
  return option;
}

function getFullEmployeeName(user) {
  const fullName = [user.lastName, user.firstName, user.middleName];
  return fullName.join(' ');
}

export function ModalInvitePartnerCompany() {
  const [email, setEmail] = useState({ email: '', emailValid: true });
  const [option, setOption] = useState('');
  const [emailInfo, setEmailInfo] = useState(null);
  const [loading, setLoading] = useState(false);

  const userEmail = useSelector(storeGetters.getCurrentUserEmail);
  const handleModalClose = useActions(modalActions.hideModalDialog);
  const { setSuccessMessage, setErrorMessage } = useActions(
    notificationMessageActions
  );

  const getChat = useCallback(() => {
    if (!emailInfo?.userInfo.employeeId) {
      return null;
    }
    return request
      .get(`/api/dialog/by-employeeId/${emailInfo.userInfo.employeeId}`)
      .then((dialogId) => request.get(`/api/channel/${dialogId}`))
      .catch((e) => console.log(`Send invitation error: ${e}`));
  }, [emailInfo]);

  const handleOpenChat = useOpenChat({ getChat, isWidget: false });

  const handleInitiateDialog = useCallback(async () => {
    await handleOpenChat();
    handleModalClose();
  }, [handleModalClose, handleOpenChat]);

  const t = useTranslate();

  function getTranslate(key) {
    return t(`modalInvitePartners.${key}`);
  }

  const handleInputChange = useCallback(
    (value) => {
      setOption('');
      const ownEmail = userEmail === value.trim();
      setEmail({ email: value, emailValid: validateEmail(value) });
      if (ownEmail) {
        setOption('ownEmail');
      } else {
        handleGetInfo(value);
      }
    },
    [validateEmail, setEmail]
  );

  const handleGetInfo = useDebounce(async (value) => {
    const res = await getInfo(value).catch(() => {
      // TODO: to add error handler
      console.log('Something went wrong');
    });

    setEmailInfo(res || null);
    setOption(defineOption(res));
  }, DELAY);

  const handleSentInvitation = useCallback(async () => {
    if (option === 'send' || option === 'invitationWasSent') {
      setLoading(true);
      try {
        await partnersCompanyService.invitePartner(email.email);
        setSuccessMessage({
          key:
            option === 'invitationWasSent'
              ? 'modalInvitePartners.sentAgainSuccessfully'
              : 'modalInvitePartners.sentSuccessfully'
        });
      } catch (e) {
        setErrorMessage({
          key: 'modalInvitePartners.failedToSend'
        });
      }
      setLoading(false);
      handleModalClose();
    }
  }, [email, option]);

  return (
    <Modal onClose={handleModalClose} open>
      <Modal.Header>{getTranslate('title')}</Modal.Header>
      <Modal.Content className={styles.content}>
        <Typography variant="body2Reg" className={styles.emailLabel}>
          {getTranslate('email')}
        </Typography>
        <Input
          onChange={handleInputChange}
          valid={email.emailValid}
          placeholder={getTranslate('inputPlaceholder')}
        />
        <Typography
          className={cn(
            styles.emailError,
            email.emailValid && styles.hideError
          )}>
          {getTranslate('validationError')}
        </Typography>
        <ExtraSection
          className={
            option && option !== 'send' ? styles.expanded : styles.hidden
          }
          option={option}
          getTranslate={getTranslate}
          company={emailInfo?.companyInfo}
          user={emailInfo?.userInfo}
          handleOpenChat={handleInitiateDialog}
        />
      </Modal.Content>
      <Modal.Footer>
        <Modal.Actions>
          <Button mode="text" onClick={handleModalClose}>
            {getTranslate('cancel')}
          </Button>
          <Button
            mode="primary"
            disabled={
              !(
                option === 'send' ||
                option === 'invitationWasSent' ||
                (!option && email.emailValid && email.email.length)
              )
            }
            loading={loading}
            onClick={handleSentInvitation}>
            {getTranslate(
              option === 'invitationWasSent' ? 'sendAgain' : 'send'
            )}
          </Button>
        </Modal.Actions>
      </Modal.Footer>
    </Modal>
  );
}
