import React from 'react';
import { useVideoChatContext } from './useVideoChatContext';
import { SidebarMods } from '../components/Sidebar/mods';

export const useToggleTranscriptChat = () => {
  const context = useVideoChatContext();

  return React.useCallback(() => {
    context.changeSidebarMode(SidebarMods.Transcript);
  }, [context.changeSidebarMode]);
};
