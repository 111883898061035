import React, { useRef, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { fromJS } from 'immutable';
import {
  Modal,
  FormGroup,
  FormControl,
  Button,
  HelpBlock,
  ControlLabel
} from 'react-bootstrap';
import InputMask from 'react-input-mask';
import { Typeahead } from '@link/react-components';
import Select from '../Select/Universal';

import {
  validateCyrLatDigitSpaceDash,
  validateEmail
} from '../../utils/validateField';
import { isMessengerService } from '../../lib/goodwix';
import TablePermissions from '../../containers/TablesNew/TablePermissions';
import './styles/modal_theme_default.styl';

import { ModalContainer } from './elements';

import { addEmployee, getListOfUsers } from '../../action-creators/company';
import { hideModalDialog } from '../../action-creators/modal';

import { useTranslate } from '../../TranslateProvider';
import RedStar from '../RedStar';
import ImageControl from '../ImageControl';
import { uploadAvatar } from '../../action-creators/user';
import FileSelectWrap from '../FileSelectWrap';
import { checkFileAllowType } from '../../useCases/files';
import { setErrorMessage } from '../../action-creators/message';

const defaultPermissions = (fromMessenger = false) => {
  if (fromMessenger) {
    return fromJS({
      company: {
        read: true,
        update: true,
        delete: false
      },
      employees: {
        read: true,
        update: true,
        delete: true,
        editPermissions: true
      },
      catalogs: {
        read: false,
        update: false,
        delete: false
      },
      stocks: {
        read: false,
        update: false,
        delete: false
      },
      prices: {
        read: false,
        update: false,
        delete: false
      },
      customers: {
        read: true,
        update: true,
        delete: true
      },
      customerOrders: {
        read: false,
        update: false,
        delete: false
      },
      suppliers: {
        read: true,
        update: true,
        delete: true
      },
      supplierOrders: {
        read: false,
        update: false,
        delete: false
      },
      billing: {
        read: false,
        update: false,
        delete: false
      },
      purchaseRequests: {
        read: true,
        update: false,
        delete: false
      }
    });
  }

  return fromJS({
    company: {
      read: true,
      update: true,
      delete: false
    },
    employees: {
      read: true,
      update: true,
      delete: true,
      editPermissions: true
    },
    catalogs: {
      read: true,
      update: true,
      delete: true
    },
    stocks: {
      read: true,
      update: true,
      delete: true
    },
    prices: {
      read: true,
      update: true,
      delete: true
    },
    customers: {
      read: true,
      update: true,
      delete: true
    },
    customerOrders: {
      read: true,
      update: true,
      delete: true
    },
    suppliers: {
      read: true,
      update: true,
      delete: true
    },
    supplierOrders: {
      read: true,
      update: true,
      delete: true
    },
    billing: {
      read: false,
      update: false,
      delete: false
    },
    purchaseRequests: {
      read: true,
      update: false,
      delete: false
    }
  });
};

const RequiredFieldEmpty = ({ t }) => (
  <HelpBlock style={{ marginBottom: 0 }}>
    {t('Required fields empty')}
  </HelpBlock>
);

const ModalAddEmployee = ({ title, company }) => {
  const t = useTranslate();
  const dispatch = useDispatch();

  const initialPristineFieldsState = {
    firstName: true,
    lastName: true,
    middleName: true,
    avatar: true,
    phoneNumber: true,
    email: true,
    position: true
  };

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [middleName, setMiddleName] = useState('');
  const [avatar, setAvatar] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [email, setEmail] = useState('');
  const [position, setPosition] = useState('');
  const [department, setDepartment] = useState('');
  const [permissions, setPermissions] = useState(
    defaultPermissions(isMessengerService())
  );
  const [employeeVisibility, setEmployeeVisibility] = useState(
    'hidden-from-other-companies'
  );
  const [isPristineField, setPristineField] = useState(
    initialPristineFieldsState
  );

  const typeaheadRef = useRef(null);
  const typeaheadTitleKey = 'department';

  const onChange = ({ target: { name, value } }) => {
    const setter = getSetMethods(name);
    setter(value);

    if (isPristineField[name]) {
      setPristineField((prevState) => ({ ...prevState, [name]: false }));
    }
  };

  function getSetMethods(key) {
    const setters = {
      email: setEmail,
      lastName: setLastName,
      firstName: setFirstName,
      middleName: setMiddleName,
      phoneNumber: setPhoneNumber,
      position: setPosition,
      department: setDepartment
    };

    if (!setters[key]) {
      throw new Error(`Setter for key = ${key} not found`);
    }

    return setters[key];
  }

  const isValid = () =>
    firstName.length > 0 &&
    lastName.length > 0 &&
    validateEmail(email) &&
    email.length > 0 &&
    position.length > 0 &&
    validateCyrLatDigitSpaceDash(position);

  const onSubmit = async () => {
    if (!isValid()) {
      return;
    }
    typeaheadRef.current.save();

    const body = {
      email,
      firstName,
      lastName,
      middleName,
      position,
      department,
      phoneNumber,
      permissions,
      employeeVisibility,
      avatar
    };

    await dispatch(addEmployee(body));
    await dispatch(hideModalDialog());
    await dispatch(getListOfUsers());
  };

  const validateField = (isFieldValid) => {
    if (isFieldValid) {
      return 'success';
    }
    return 'error';
  };

  const checkValidationState = (name) => {
    if (isPristineField[name]) return null;

    switch (name) {
      case 'email':
        return validateField(email.length > 0 && validateEmail(email));
      case 'lastName':
        return validateField(lastName.length > 0);
      case 'firstName':
        return validateField(firstName.length > 0);
      case 'position':
        return validateField(
          position.length > 0 && validateCyrLatDigitSpaceDash(position)
        );
      default:
        throw new Error(
          `No such validation check case for field = ${name} found`
        );
    }
  };

  const handleFileChoose = async (files) => {
    const file = files[0];

    if (file.size > 20971520) {
      return dispatch(
        setErrorMessage({ key: 'File size must be less then 20 MB' })
      );
    }

    try {
      if (checkFileAllowType(file.name)) {
        const uploadedFile = await dispatch(uploadAvatar(file));

        if (uploadedFile) {
          setAvatar(uploadedFile);
        }
      } else {
        dispatch(setErrorMessage({ key: 'image_wrong_formant_error' }));
      }
    } catch (e) {
      dispatch(setErrorMessage({ key: 'image_wrong_formant_error' }));
    }

    return uploadAvatar;
  };

  return (
    <Modal id="inviteEmployee" onHide={() => dispatch(hideModalDialog())} show>
      <ModalContainer mods={{ theme: 'default' }}>
        <Modal.Header>
          <Modal.Title>
            {t(title)}
            <span
              className="modal-header-cross pull-right"
              onClick={() => dispatch(hideModalDialog())}
            />
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div style={{ display: 'flex' }}>
            <div style={{ width: '100%' }}>
              <FormGroup
                controlId="email"
                validationState={checkValidationState('email')}
                style={{ overflow: 'hidden' }}>
                <ControlLabel className="control-label-row">
                  {`${t('Enter email')} (${t('only latin')})`}
                  <RedStar />
                </ControlLabel>
                <FormControl
                  name="email"
                  type="text"
                  placeholder="example@email.com"
                  value={email}
                  onChange={onChange}
                />
                {email.length === 0 && !isPristineField.email && (
                  <RequiredFieldEmpty t={t} />
                )}
                {!validateEmail(email) && email.length !== 0 && (
                  <HelpBlock style={{ marginBottom: 0 }}>
                    {t('Wrong email format')}
                  </HelpBlock>
                )}
              </FormGroup>

              <FormGroup controlId="department" style={{ overflow: 'hidden' }}>
                <ControlLabel className="control-label-row">
                  {t('department')}
                </ControlLabel>
                <Typeahead
                  initialValue={department}
                  onChange={setDepartment}
                  store={typeaheadTitleKey}
                  ref={typeaheadRef}
                />
              </FormGroup>

              <FormGroup
                controlId="lastName"
                validationState={checkValidationState('lastName')}>
                <ControlLabel className="control-label-row">
                  {t('Last Name')}
                  <RedStar />
                </ControlLabel>
                <FormControl
                  name="lastName"
                  type="text"
                  value={lastName}
                  onChange={onChange}
                />
                {lastName.length === 0 && !isPristineField.lastName && (
                  <RequiredFieldEmpty t={t} />
                )}
              </FormGroup>

              <FormGroup
                controlId="firstName"
                validationState={checkValidationState('firstName')}>
                <ControlLabel className="control-label-row">
                  {t('First Name')}
                  <RedStar />
                </ControlLabel>
                <FormControl
                  name="firstName"
                  type="text"
                  value={firstName}
                  onChange={onChange}
                />
                {firstName.length === 0 && !isPristineField.firstName && (
                  <RequiredFieldEmpty t={t} />
                )}
              </FormGroup>

              <FormGroup controlId="middleName">
                <ControlLabel className="control-label-row">
                  {t('Middle Name')}
                </ControlLabel>
                <FormControl
                  name="middleName"
                  type="text"
                  value={middleName}
                  onChange={onChange}
                />
              </FormGroup>
            </div>

            <div style={{ marginLeft: 20 }}>
              <ControlLabel className="control-label-row">
                {t('Main image')}
              </ControlLabel>

              <FileSelectWrap
                onChange={(files) => handleFileChoose(files)}
                component={({ onClick }) => (
                  <ImageControl
                    name="userImage"
                    imgSrc={avatar}
                    onFileChoose={onClick}
                    onImageRemove={() => setAvatar('')}
                    style={{
                      width: '150px',
                      height: '150px'
                    }}
                  />
                )}
                multiple={false}
              />
            </div>
          </div>

          <FormGroup controlId="phoneNumber">
            <ControlLabel className="control-label-row">
              {t('Phone number')}
            </ControlLabel>
            <InputMask
              name="phoneNumber"
              className="form-control"
              type="text"
              value={phoneNumber}
              onChange={onChange}
              mask="+9 (999) 999-9999"
              maskChar=""
            />
          </FormGroup>

          <FormGroup
            controlId="position"
            validationState={checkValidationState('position')}>
            <ControlLabel className="control-label-row">
              {`${t('position')} (${t('cyrillic latin')}) `}
              <RedStar />
            </ControlLabel>
            <FormControl
              name="position"
              type="text"
              placeholder={t('enter_employee_position_placeholder')}
              onChange={onChange}
            />
            {position.length === 0 && !isPristineField.position && (
              <RequiredFieldEmpty t={t} />
            )}
            {!validateCyrLatDigitSpaceDash(position) && position.length > 0 && (
              <HelpBlock style={{ marginBottom: 0 }}>
                {t('wrong_position_in_company')}
              </HelpBlock>
            )}
          </FormGroup>

          <FormGroup controlId="visability">
            <ControlLabel className="control-label-row">
              {`${t('visibility-of-employee')}`}
            </ControlLabel>
            <Select
              value={employeeVisibility}
              onChange={setEmployeeVisibility}
              options={['hidden-from-other-companies', 'visible-to-all']}
              translateGroup="employee-visibility"
            />
          </FormGroup>

          <ControlLabel className="control-label-row">
            {t('Access right to sections')}
          </ControlLabel>
          <TablePermissions
            permissions={permissions}
            billingAllowed={company.get('billingAllowed')}
            onChange={setPermissions}
          />
          <div>
            <RedStar />
            &nbsp;
            {t('Required fields')}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            id="cancel"
            className="pull-left"
            onClick={() => dispatch(hideModalDialog())}>
            {t('Cancel')}
          </Button>
          <Button
            id="saveCharacteristicButton"
            bsStyle="primary"
            disabled={!isValid()}
            onClick={onSubmit}>
            {t('Send invite')}
          </Button>
        </Modal.Footer>
      </ModalContainer>
    </Modal>
  );
};

export default connect((state) => ({
  company: state.get('company'),
  user: state.getIn(['user', 'user'])
}))(ModalAddEmployee);
