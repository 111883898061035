import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { is } from 'ramda';
import { translate } from 'react-i18next';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Modal } from 'react-bootstrap';

import { ModalContainer } from './elements';
import Helper from '../Helper';

import * as modalActions from '../../action-creators/modal';
import * as servicesAction from '../../action-creators/services';
import {
  getTokenHiddenEmployee,
  refreshTokenHiddenEmployee
} from '../../storage/token';

class ModalAddHiddenEmployee extends Component {
  constructor(props) {
    super(props);
    this.state = {
      token: ''
    };
    this.getLinkByType = this.getLinkByType.bind(this);
    this.refreshCurrentToken = this.refreshCurrentToken.bind(this);
    this.copyLink = this.copyLink.bind(this);
  }

  async UNSAFE_componentWillMount() {
    const { companyId } = this.props;
    const { token } = await getTokenHiddenEmployee(companyId);
    this.setToken(token);
  }

  setToken(token) {
    if (is(String, token)) {
      this.setState({ token });
    }
  }

  copyLink() {
    const { userEmail, gaSend } = this.props;
    gaSend({
      category: 'Common',
      action: 'company_info_copy_hidden_employee',
      label: userEmail
    });
    const inputElement = document.getElementById('modal-add-partner-input');
    inputElement.select();
    document.execCommand('copy');
  }

  getLinkByType() {
    const { token } = this.state;
    if (token) {
      return `${process.env.UNICAT_DEFAULT_SERVICE}/employee-partner/${token}`;
    }
    return 'not-token';
  }

  async refreshCurrentToken() {
    const { companyId } = this.props;
    const { token } = await refreshTokenHiddenEmployee(companyId);
    this.setToken(token);
  }

  render() {
    const { t, hideModal } = this.props;

    return (
      <ModalContainer mods={{ theme: 'default' }}>
        <Modal id="addContact" onHide={hideModal} show>
          <Modal.Header>
            <Modal.Title>
              {t('add-contact-by-link')}
              <span
                className="modal-header-cross pull-right"
                onClick={hideModal}
              />
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div
              className="modal-add-partner__refresh-container"
              style={{ paddingBottom: 5 }}>
              <div className="modal-add-partner__caption-container">
                {t('addContactLink')}
                <span style={{ marginLeft: 5 }}>
                  <Helper text="addContactHelpText" />
                </span>
              </div>
              <div>
                <button
                  type="button"
                  className="modal-add-partner__refresh-button"
                  onClick={this.refreshCurrentToken}>
                  {t('addContactRefreshLink')}
                </button>
                <span style={{ marginLeft: 1 }}>
                  <Helper text="addContactRefreshLinkHelpText" />
                </span>
              </div>
            </div>
            <div className="modal-add-partner__link-container">
              <input
                id="modal-add-partner-input"
                className="modal-add-partner__link-input"
                value={this.getLinkByType()}
                readOnly
              />
              <button
                type="button"
                className="modal-add-partner__link-copy"
                onClick={this.copyLink}>
                <img src="/img/copy-link.svg" alt="copy" />
              </button>
            </div>
          </Modal.Body>
          <Modal.Footer />
        </Modal>
      </ModalContainer>
    );
  }
}

ModalAddHiddenEmployee.propTypes = {
  t: PropTypes.func.isRequired,
  hideModal: PropTypes.func.isRequired
};

const mapState = (state) => ({
  userEmail: state.getIn(['user', 'user', 'email'])
});

const mapDispatch = (dispatch) =>
  bindActionCreators(
    {
      hideModal: modalActions.hideModal,
      gaSend: servicesAction.gaSend
    },
    dispatch
  );

export default connect(
  mapState,
  mapDispatch
)(translate()(ModalAddHiddenEmployee));
