import React, { useEffect, useRef } from 'react';
import ScrollContainer from 'react-indiana-drag-scroll';
import { useSelector } from 'react-redux';
import { useXarrow } from 'react-xarrows';
import { Snackbar } from '@components';
import styles from './Workspace.module.css';
import * as getters from '../../../../../components/ChatWidget/modules/constructor/getters';
import { StepCard } from '../stepCard';

import { Line } from '../arrows';
import { CreateNewStep } from '../createNewStep';
import { Step } from '../step';
import {
  getLastStepId,
  getLengthOfPath,
  getPropId,
  isCanDrawLineToCreateButton
} from '../helpers';
import { useIsCanBeDeleted } from '../../../../../contexts/RouteIsCanBeDeletedContext';
import iconsView from '../../../../../assets/images/constants';
import { useTranslate } from '../../../../../TranslateProvider';

export const Workspace = () => {
  const t = useTranslate();
  const {
    builder: { path }
  } = useSelector(getters.getBuilderState);
  const updateXarrow = useXarrow();
  const isCanBeDeleted = useIsCanBeDeleted();
  useEffect(() => {
    updateXarrow();
  }, [path]);
  const snackbarRef = useRef(null);

  useEffect(() => {
    if (!isCanBeDeleted) {
      snackbarRef.current.show();
    }
  }, [isCanBeDeleted]);

  return (
    <>
      <main className={styles.main}>
        <ScrollContainer
          onScroll={updateXarrow}
          className={styles.container}
          hideScrollbars>
          <div className={styles.content}>
            <StepCard type="begin" />
            <Line
              start="begin-step-card"
              end={getLengthOfPath(path) ? path[0].id : 'create-step-button'}
            />
            {Boolean(getLengthOfPath(path)) &&
              path.map((step, stepIndex, steps) => (
                <div key={step.id}>
                  <Step
                    key={`${step.id}-step`}
                    step={step}
                    stepIndex={stepIndex}
                  />

                  {getLengthOfPath(path) > 1 &&
                    stepIndex !== getLengthOfPath(steps) - 1 && (
                      <Line
                        start={`${getPropId(step)}`}
                        end={`${getPropId(steps[stepIndex + 1])}`}
                      />
                    )}
                </div>
              ))}

            {isCanDrawLineToCreateButton(path) && (
              <Line start={getLastStepId(path)} end="create-step-button" />
            )}

            <CreateNewStep path={path} />
          </div>
        </ScrollContainer>
        {!isCanBeDeleted && (
          <Snackbar
            ref={snackbarRef}
            iconName={iconsView.Information}
            iconSize="m"
            withAnimation={false}
            iconClassName={styles.snackbarIcon}
            text={t('constructor.unavailableRoute')}
          />
        )}
      </main>
    </>
  );
};
