import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { translate } from 'react-i18next';
import { Button } from 'react-bootstrap';
import { Set } from 'immutable';
import { withRouter } from '../../hoc/withRouter';
import { numberWithSpaces, numberAsPrice } from '../../utils';
import store from '../../storeSections';
import './style.styl';

import * as orderActions from '../../action-creators/orders';
import * as serviceActions from '../../action-creators/services';

class YourOrder extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isShowList: false
    };

    this.showList = this.showList.bind(this);
    this.hideList = this.hideList.bind(this);
    this.handlerClickOutside = this.handlerClickOutside.bind(this);
    this.action = this.action.bind(this);
    this.getOrderInfoList = this.getOrderInfoList.bind(this);
  }

  showList() {
    this.setState({ isShowList: true });
    document.addEventListener('mouseup', this.handlerClickOutside);
  }

  hideList() {
    this.setState({ isShowList: false });
    document.removeEventListener('mouseup', this.handlerClickOutside);
  }

  handlerClickOutside(e) {
    if (this.list && !this.list.contains(e.target)) {
      this.hideList();
    }
  }

  async action() {
    const {
      saveDraft,
      args: { orderId, priceId, action },
      isEmptyPriceCategoryBySumOrder,
      priceCategoryBySumOfOrder,
      orderedProducts,
      history,
      gaSend,
      user
    } = this.props;
    if (action && typeof action === 'function') {
      return action();
    }
    const rOrderId = await saveDraft({
      priceId,
      orderId,
      items: orderedProducts,
      categoryId:
        !isEmptyPriceCategoryBySumOrder && priceCategoryBySumOfOrder.get('id')
    });
    await gaSend({
      category: 'Customer',
      action: 'issue_order',
      label: user.get('email')
    });

    return history.push(`/orders-company/${priceId}/order-draft/${rOrderId}`);
  }

  getUniqProductsCount(products) {
    return new Set(products.map((i) => i.get('id'))).size;
  }

  getOrderInfoList() {
    const {
      priceCategoryBySumOfOrder,
      isEmptyPriceCategoryBySumOrder,
      orderedProducts,
      args: { draftSum, draftVat, roundUp },
      sum,
      vat,
      totalProducts,
      price
    } = this.props;

    const sumValue = draftSum || sum;
    const list = [
      {
        title: 'Name',
        content: priceCategoryBySumOfOrder.get('name'),
        label: 'category'
      },
      {
        title: 'Size discount',
        content: `${priceCategoryBySumOfOrder.get('discount')}%`,
        label: 'category'
      },
      {
        title: 'Minimal order',
        content: `${priceCategoryBySumOfOrder.get('minOrder')} ${price.get(
          'currency'
        )}`,
        label: 'category'
      },
      { label: 'delimiter' },
      {
        title: 'Total products ordered',
        content: numberWithSpaces(totalProducts),
        label: 'price'
      },
      {
        title: 'Positions ordered',
        content: numberWithSpaces(this.getUniqProductsCount(orderedProducts)),
        label: 'price'
      },
      { label: 'delimiter' },
      {
        title: 'Sum',
        content: `${numberWithSpaces(
          roundUp ? sumValue : numberAsPrice(sumValue)
        )} ${price.get('currency')}`,
        label: 'price'
      },
      {
        title: 'including VAT',
        content: `${numberWithSpaces(
          numberAsPrice(draftVat || vat)
        )} ${price.get('currency')}`,
        label: 'price'
      }
    ].filter(
      ({ label }) =>
        ['price', 'delimiter'].includes(label) ||
        !isEmptyPriceCategoryBySumOrder
    );

    if (list[0].label === 'delimiter') {
      list.splice(0, 1);
    }

    return list;
  }

  render() {
    const { t, totalProducts } = this.props;

    return (
      <div className="your-order" onClick={this.showList}>
        <div className="your-order__title">
          <img
            role="presentation"
            alt=""
            style={{ marginRight: 6, marginBottom: 3 }}
            src="/img/cart.svg"
          />
          {`${t('your_order')}:`}
          <span className="your-order__title-total-products">{` ${totalProducts} `}</span>
          <span
            className={`caret your-order__title-caret ${
              this.state.isShowList ? 'your-order__title-caret_rotate' : ''
            }`}
          />
        </div>
        {this.state.isShowList && (
          <div
            className="your-order__list"
            ref={(elem) => {
              this.list = elem;
            }}>
            {this.getOrderInfoList().map(({ title, content, label }) =>
              label === 'delimiter' ? (
                <div className="order-draft-delimiter" />
              ) : (
                <div className="your-order__item" key={title}>
                  <span className="your-order__item-title">{`${t(
                    title
                  )}: `}</span>
                  <span className="your-order__item-content">{content}</span>
                </div>
              )
            )}
            <div className="your-order__wrapper-btn">
              <Button
                id="goToOrder"
                bsStyle="primary"
                disabled={totalProducts === 0}
                className="your-order__btn"
                onClick={() => this.action()}>
                {t('Go to order')}
              </Button>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default withRouter(
  connect(
    store((state) => ({
      user: state.user.data(),
      priceCategoryBySumOfOrder:
        state.publicPrices.matchedPriceCategoryBySumOfOrder(),
      isEmptyPriceCategoryBySumOrder:
        state.publicPrices.isEmptyPriceCategoryBySumOrder(),
      orderedProducts: state.orderDraft.items(),
      sum: state.orderDraft.sumWithConditions(),
      vat: state.orderDraft.vatWithConditions(),
      price: state.publicPrices.price(),
      totalProducts: state.orderDraft.totalOrderedProducts(),
      unitCodes: state.stocks.unitCodes(),
      units: state.prices.units()
    })),
    (dispatch) =>
      bindActionCreators(
        {
          saveDraft: orderActions.saveDraft,
          gaSend: serviceActions.gaSend
        },
        dispatch
      )
  )(translate(['ui'], { wait: true })(YourOrder))
);
