export const loadTable = (items, table = 'common', size) => ({
  type: 'TABLE_LOAD',
  items,
  table,
  size
});

export const setFilterField = (name, value) => ({
  type: 'TABLE_PRICE_PRODUCT:SET_FILTER_FIELD',
  payload: { name, value }
});

export const applyFilter = (filters) => ({
  type: 'TABLE_PRICE_PRODUCT:APPLY_FILTER',
  payload: { filters }
});

export const loadTableProducts = (items, categoryId, size, limit, skip) => ({
  type: 'TABLE_LOAD_PRODUCTS',
  items,
  size,
  skip,
  limit,
  table: 'products',
  categoryId
});

export const loadPublishedTable = (items, table = 'common') => ({
  type: 'TABLE_LOAD_PUBLISHED',
  items,
  table
});

export const loadTablePublishedProducts = (
  items,
  categoryId,
  size,
  limit,
  skip
) => ({
  type: 'TABLE_LOAD_PUBLISHED_PRODUCTS',
  items,
  size,
  skip,
  limit,
  table: 'products',
  categoryId
});

export const clearTable = (table = 'common') => ({
  type: 'TABLE_CLEAR',
  table
});

export const clearTouch = (table = 'common') => ({
  type: 'TABLE_CLEAR_TOUCH',
  table
});

export const addTableItems = (items, table = 'common') => ({
  type: 'TABLE_ADD',
  items,
  table
});

export const updateTableItems = (items, table = 'common') => ({
  type: 'TABLE_UPDATE_ITEMS',
  items,
  table
});

export const touchRow = (id, table = 'common') => ({
  type: 'TABLE_TOUCH',
  id,
  table
});

export const touchSet = (ids, table = 'common') => ({
  type: 'TABLE_SET_TOUCH',
  ids,
  table
});

export const touchRowProducts = (id, categoryId) => ({
  type: 'TABLE_PRODUCTS_TOUCH',
  id,
  categoryId,
  table: 'products'
});

export const touchSingleRow = (id, table = 'common') => ({
  type: 'TABLE_SINGLE_TOUCH',
  id,
  table
});

export const deselectAll = (table = 'common') => ({
  type: 'TABLE_DESELECT_ALL',
  table
});

export const removeFromTable = (list, table = 'common') => ({
  type: 'TABLE_REMOVE_LIST',
  list,
  table
});

export const removeFromTableProducts = (product) => ({
  type: 'TABLE_PRODUCTS_REMOVE_LIST',
  product,
  table: 'products'
});

export const sortBy = (key, table = 'common') => ({
  type: 'TABLE_SORT',
  key,
  table
});

export const deleteTable = (table = 'common') => ({
  type: 'TABLE_DELETE',
  table
});
