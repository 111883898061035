import { identity, isEmpty, isNil, memoizeWith } from 'ramda';

export const getVatSum = (sum, vat) => sum * (vat / (100 + vat));

export const getRandomInt = (min, max) =>
  Math.floor(Math.random() * (max - min)) + min;

export const storeCatalogOptions = (catalogId, loadOptions) => {
  sessionStorage.setItem(
    `catalog-${catalogId}`,
    JSON.stringify({ loadOptions })
  );
  const catalogsIds = JSON.parse(sessionStorage.getItem('catalogsIds'));
  if (!catalogsIds) {
    sessionStorage.setItem('catalogsIds', JSON.stringify([catalogId]));
  } else if (!catalogsIds.includes(catalogsIds)) {
    catalogsIds.push(catalogId);
    sessionStorage.setItem('catalogsIds', JSON.stringify(catalogsIds));
  }
};

export const removeCatalogOption = (catalogId) =>
  sessionStorage.removeItem(`catalog-${catalogId}`);

export const clearAllCatalogOptions = () => {
  const catalogsIds = JSON.parse(sessionStorage.getItem('catalogsIds'));
  if (catalogsIds) {
    catalogsIds.forEach((id) => sessionStorage.removeItem(`catalog-${id}`));
  }
};

const getConditionCategoriesDiscountSum = (categories) =>
  categories.reduce((acc, cat) => acc + +cat.get('discount'), 0);

export const getResultConditionCost = (priceCategoryConditions, cost) => {
  const conditionDiscount = getConditionCategoriesDiscountSum(
    priceCategoryConditions
  );
  return (cost / 100) * (100 - conditionDiscount);
};

export const formatRequestNumber = (id, symbolsNum = 8) => {
  if (!id) return '';
  const diff = symbolsNum - `${id}`.length;
  const array = Array(diff).fill('0', 0, diff).join('');
  return `${array}${id}`;
};

export const limitText = (text, symbolsCount = 30) => {
  if (text && text.length > symbolsCount) {
    return `${text.substring(0, symbolsCount)}...`;
  }
  return text;
};

export const textMatch = (search, text) => {
  if (undefined === search || typeof search !== 'string') return false;
  if (text.toLowerCase().indexOf(search.toLowerCase()) !== -1) return true;
  return false;
};

export const makeFilters = (state) => {
  const filters = [];
  if (state.country) {
    filters.push({ name: 'country', value: state.country });
  }
  if (!isEmpty(state.region)) {
    filters.push({ name: 'region', value: extractValue(state.region) });
  }
  if (!isEmpty(state.city)) {
    filters.push({ name: 'city', value: extractValue(state.city) });
  }
  if (state.currency) {
    filters.push({ name: 'currency', value: state.currency });
  }
  if (hasSumFilter(state.sum)) {
    filters.push({ name: 'sum', value: getNotEmptySum(state.sum) });
  }
  if (!isEmpty(state.buyers)) {
    filters.push({ name: 'buyers', value: extractValue(state.buyers) });
  }
  return filters;

  function extractValue(arr = []) {
    return arr.map(({ value }) => value);
  }
};

function getNotEmptySum(sum) {
  return sum.filter((item) => item.from !== '' || item.to !== '');
}

function hasSumFilter(sum = []) {
  return Boolean(getNotEmptySum(sum).length);
}

export const getSelectedFilesFromState = (state) => {
  if (!state || !state.selectedFiles) return [];
  return state.selectedFiles;
};

export const formatterText = (text) =>
  text
    .replaceAll('&lt;', '<')
    .replaceAll('&gt;', '>')
    .replaceAll('&amp;', '&')
    .replaceAll('&quot;', '"');

export function getFullName(profile = {}) {
  return `${profile.lastName || ''} ${profile.firstName || ''} ${
    profile.middleName || ''
  }`.trim();
}

export function addQueryParam(baseUrl, queryKey, value) {
  if (isNil(value)) return baseUrl;

  if (typeof value === 'string') return `${baseUrl}&${queryKey}=${value}`;

  if (Array.isArray(value) && !isEmpty(value)) {
    const query = value.reduce(
      (str, item) => `${str}&${queryKey}[]=${item.value}`,
      ''
    );
    return `${baseUrl}${query}`;
  }

  return baseUrl;
}

export function addQueryParams(baseUrl, queryParams = {}) {
  return Object.entries(queryParams).reduce(
    (url, [key, value]) => addQueryParam(url, key, value),
    `${baseUrl}?`
  );
}

export function escapeText(text) {
  return text
    .replaceAll('%', '%25') // Процент
    .replaceAll(' ', '%20') // Пробел
    .replaceAll('\t', '%20') // Табуляция (заменяем на пробел)
    .replaceAll('\n', '%20') // Переход строки (заменяем на пробел)
    .replaceAll('\r', '%20') // Возврат каретки (заменяем на пробел)
    .replaceAll('!', '%21') // Восклицательный знак
    .replaceAll('"', '%22') // Двойная кавычка
    .replaceAll('#', '%23') // Октоторп, решетка
    .replaceAll('\\$', '%24') // Знак доллара
    .replaceAll('&', '%26') // Амперсанд
    .replaceAll("'", '%27') // Одиночная кавычка
    .replaceAll('\\(', '%28') // Открывающаяся скобка
    .replaceAll('\\)', '%29') // Закрывающаяся скобка
    .replaceAll('\\*', '%2a') // Звездочка
    .replaceAll('\\+', '%2b') // Знак плюс
    .replaceAll(',', '%2c') // Запятая
    .replaceAll('-', '%2d') // Дефис
    .replaceAll('\\.', '%2e') // Точка
    .replaceAll('/', '%2f') // Слеш, косая черта
    .replaceAll(':', '%3a') // Двоеточие
    .replaceAll(';', '%3b') // Точка с запятой
    .replaceAll('<', '%3c') // Открывающаяся угловая скобка
    .replaceAll('=', '%3d') // Знак равно
    .replaceAll('>', '%3e') // Закрывающаяся угловая скобка
    .replaceAll('\\?', '%3f') // Вопросительный знак
    .replaceAll('@', '%40') // At sign, по цене, собачка
    .replaceAll('\\[', '%5b') // Открывающаяся квадратная скобка
    .replaceAll('\\\\', '%5c') // Одиночный обратный слеш '\'
    .replaceAll('\\]', '%5d') // Закрывающаяся квадратная скобка
    .replaceAll('\\^', '%5e') // Циркумфлекс
    .replaceAll('_', '%5f') // Нижнее подчеркивание
    .replaceAll('`', '%60') // Гравис
    .replaceAll('\\{', '%7b') // Открывающаяся фигурная скобка
    .replaceAll('\\|', '%7c') // Вертикальная черта
    .replaceAll('\\}', '%7d') // Закрывающаяся фигурная скобка
    .replaceAll('~', '%7e'); // Тильда
}

export function prepareLogin(login) {
  return `@${login}`;
}

export function prepareLocation({ country, city }, lang = 'ru') {
  let result = '';
  const cityPrefix = lang === 'ru' ? 'г.' : '';

  if (country) result += country;
  if (city) result += `, ${cityPrefix} ${city}`;

  return result;
}

export function memoizeFn(fn) {
  return memoizeWith(identity, fn);
}

export function isFunction(fn) {
  return typeof fn === 'function';
}

export const noop = () => {};

const EMPTY_OBJECT = {};
export const getEmptyObject = () => EMPTY_OBJECT;

export const roundNumber = (num, decimal = 2) => Number(num.toFixed(decimal));

/**
 * Util for change part of path
 *
 * @param path - {string}
 * @param from - {string}
 * @param to - {string}
 * @returns {string}
 */
export function changePartOfPath(path = '', from, to) {
  return path
    .split('/')
    .map((part) => (part === from ? to : part))
    .join('/');
}

export const isNilOrEmpty = (value) => isNil(value) || isEmpty(value);

export function removeURLParameter(url, parameter) {
  // prefer to use l.search if you have a location/link object
  const urlparts = url.split('?');
  if (urlparts.length >= 2) {
    const prefix = `${encodeURIComponent(parameter)}=`;
    const pars = urlparts[1].split(/[&;]/g);

    // reverse iteration as may be destructive
    for (let i = pars.length; i-- > 0; ) {
      // idiom for string.startsWith
      if (pars[i].lastIndexOf(prefix, 0) !== -1) {
        pars.splice(i, 1);
      }
    }

    return urlparts[0] + (pars.length > 0 ? `?${pars.join('&')}` : '');
  }
  return url;
}

export function stopPropagation(event) {
  event.stopPropagation();
}

export const isProduction = () => process.env.NODE_ENV === 'production';

export function matchAllLinksFromString(str = '') {
  // eslint-disable-next-line max-len
  const reg =
    /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\\+.~#?&//=]*)/gi;

  return str.match(reg);
}

export function convertSearchParamsToObj(search) {
  return Object.fromEntries(new URLSearchParams(search));
}
