import React from 'react';
import Icons from '../constants';
import IconExcel from './excel';
import IconSelectActive from './select-active';
import IconSelectBlank from './select-blank';
import IconSearch from './search';
import IconArchiveDownload from './archive-download';
import IconChat from './chat';
import IconCloseLarge from './close-large';
import IconSliders from './sliders';
import IconSubmenu from './submenu';
import IconUsers from './users';
import IconVideo from './video';
import IconList from './list';
import IconAddChat from './add-chat';
import IconAdd from './add';
import IconFilter from './filter';
import IconPerson from './person';
import IconContactAdd from './contact-add';
import IconFavorite from './favorite';
import IconDelayedMessageEmpty from './delayed-message-empty';
import IconNotification from './notification';
import IconNotificationMute from './notification-mute';
import IconSettings from './settings';
import IconAlert from './alert';
import IconCopy from './copy';
import IconMail from './mail';
import IconGlobal from './global';
import IconStylePunkt from './style-punkt';
import IconStylePunkt1 from './style-punkt1';
import IconDelayedMessage from './delayed-message';
import IconAttachFileChat from './attach-file-chat';
import IconEmoji from './emoji';
import IconStylePunkt2 from './style-punkt2';
import IconFormatText from './format-text';
import IconEmail from './email';
import IconData from './icon-data';
import IconBtnSendDisabled from './btn-send-disabled';
import IconBtnSendEnable from './btn-send-enable';
import IconAttach from './attach';
import IconHide from './hide';
import IconView from './view';
import IconTrash from './trash';
import IconSelect from './select';
import IconOk from './ok';
import IconPublish from './publish';
import IconSlidersPath from './slidersPath';
import IconSort from './sort';

export const Icons18x18 = {
  [Icons.Excel]: <IconExcel />,
  [Icons.SelectBlank]: <IconSelectBlank />,
  [Icons.SelectActive]: <IconSelectActive />,
  [Icons.Add]: <IconAdd />,
  [Icons.AddChat]: <IconAddChat />,
  [Icons.ArchiveDownload]: <IconArchiveDownload />,
  [Icons.Chat]: <IconChat />,
  [Icons.CloseLarge]: <IconCloseLarge />,
  [Icons.List]: <IconList />,
  [Icons.Search]: <IconSearch />,
  [Icons.Sliders]: <IconSliders />,
  [Icons.Submenu]: <IconSubmenu />,
  [Icons.Users]: <IconUsers />,
  [Icons.Video]: <IconVideo />,
  [Icons.Filter]: <IconFilter />,
  [Icons.Person]: <IconPerson />,
  [Icons.ContactAdd]: <IconContactAdd />,
  [Icons.Favorite]: <IconFavorite />,
  [Icons.DelayedMessageEmpty]: <IconDelayedMessageEmpty />,
  [Icons.DelayedMessage]: <IconDelayedMessage />,
  [Icons.Notification]: <IconNotification />,
  [Icons.NotificationMute]: <IconNotificationMute />,
  [Icons.Settings]: <IconSettings />,
  [Icons.ContactAdd]: <IconContactAdd />,
  [Icons.Alert]: <IconAlert />,
  [Icons.Copy]: <IconCopy />,
  [Icons.Mail]: <IconMail />,
  [Icons.Global]: <IconGlobal />,
  [Icons.StylePunkt]: <IconStylePunkt1 />,
  [Icons.StylePunkt1]: <IconStylePunkt1 />,
  [Icons.StylePunkt2]: <IconStylePunkt2 />,
  [Icons.Email]: <IconEmail />,
  [Icons.Chat]: <IconChat />,
  [Icons.StylePunkt]: <IconStylePunkt />,
  [Icons.Hide]: <IconHide />,
  [Icons.View]: <IconView />,
  [Icons.AttachFileChat]: <IconAttachFileChat />,
  [Icons.Emoji]: <IconEmoji />,
  [Icons.FormatText]: <IconFormatText />,
  [Icons.IconData]: <IconData />,
  [Icons.BtnSendDisabled]: <IconBtnSendDisabled />,
  [Icons.BtnSendEnable]: <IconBtnSendEnable />,
  [Icons.Attach]: <IconAttach />,
  [Icons.Trash]: <IconTrash />,
  [Icons.Select]: <IconSelect />,
  [Icons.Ok]: <IconOk />,
  [Icons.Publish]: <IconPublish />,
  [Icons.Ok]: <IconOk />,
  [Icons.SlidersPath]: <IconSlidersPath />,
  [Icons.Sort]: <IconSort />,
};
