import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import ButtonIcon from 'react-components/src/components/ButtonIcon';
import icons from 'react-components/src/icons/constants';
import { isNil } from 'ramda';
import Select from '../../../Dropdown/Select';
import CWSIconDropdown from '../../../Dropdown/Icon';
import Dashboard from './layout';
import { getChatWidget } from '../../../getter';
import * as chatWidgetUC from '../../../useCases';
import { useTranslate } from '../../../../../TranslateProvider';

const CWSDashboardChat = ({ chatWidget, handleChatBtnClick, config = {} }) => {
  const filter = chatWidget.filter.chat;
  const sortActive = chatWidget.sidebarHeaderView === 'sort';
  const t = useTranslate();
  const checkIfSelected = (value, type) => value === type;

  const filterList = [
    {
      icon: 'without',
      value: 'all-chats',
      selected: checkIfSelected('all-chats', filter.value)
    },
    {
      icon: 'without',
      value: 'dialogs',
      selected: checkIfSelected('dialogs', filter.value)
    },
    {
      icon: 'without',
      value: 'channels',
      selected: checkIfSelected('channels', filter.value),
      helper: 'chat-widget.menu-items.channels-helper'
    },
    {
      icon: 'without',
      value: 'topics',
      selected: checkIfSelected('topics', filter.value),
      helper: 'chat-widget.menu-items.topics-helper'
    }
  ];

  const { addChatDropdown } = config;

  return (
    <Dashboard
      left={
        <Select
          title={filter}
          onSelect={(item) => handleChatBtnClick('change-filter', item)}
          list={filterList}
        />
      }
      right={
        <>
          {config.showSearch && (
            <ButtonIcon
              t={t}
              icon="search"
              size={18}
              tooltip="search-by-messages"
              onClick={() => handleChatBtnClick('search')}
            />
          )}
          {config.showAddChat && (
            <CWSIconDropdown
              title={() => (
                <ButtonIcon
                  t={t}
                  icon={icons.AddChat}
                  size={18}
                  tooltip="create-chat"
                />
              )}
              onSelect={(item) => handleChatBtnClick(item)}
              list={addChatDropdown.list}
            />
          )}
          {config.showFolder && (
            <ButtonIcon
              t={t}
              tooltip="chat-list"
              icon={icons.StylePunkt}
              size={18}
              onClick={() => handleChatBtnClick('folder')}
              active={!isNil(chatWidget.blockGroupChats.kind)}
            />
          )}
          {config.showSort && (
            <ButtonIcon
              t={t}
              tooltip={sortActive ? 'sort-off' : 'sort'}
              icon={icons.Sort}
              size={18}
              onClick={() => handleChatBtnClick('sorting', !sortActive)}
              active={sortActive}
            />
          )}
        </>
      }
    />
  );
};

CWSDashboardChat.propTypes = {
  handleChatBtnClick: PropTypes.func.isRequired,
  chatWidget: PropTypes.object.isRequired
};

export default connect(
  (state) => ({
    chatWidget: getChatWidget(state)
  }),
  (dispatch) =>
    bindActionCreators(
      {
        ...chatWidgetUC
      },
      dispatch
    )
)(CWSDashboardChat);
