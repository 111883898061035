import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useActions } from '@hooks/useActions';
import { useTranslate } from '../../../TranslateProvider';
import './style.css';
import { IstockLayout } from '../../../layout/IstockLayout';
import { Header } from './Compare/Header';
import { Main } from './Compare/Main';
import * as storeGetters from '../../../storeGetters';
import * as compareStorage from '../../../components/PurchaseRequests/MyRequests/storage';

const MCompareResponses = () => {
  const t = useTranslate();

  const currentUserId = useSelector(storeGetters.getCurrentUserId);

  const [deletedList, setDeletedList] = useState([]);

  const { setInitialCompareList } = useActions({
    setInitialCompareList: compareStorage.setInitialCompareList
  });

  useEffect(() => {
    setInitialCompareList();
  }, [currentUserId]);

  return (
    <IstockLayout
      title={t('Purchase requests')}
      header={
        <Header deletedList={deletedList} setDeletedList={setDeletedList} />
      }
      main={<Main deletedList={deletedList} setDeletedList={setDeletedList} />}
    />
  );
};

export default MCompareResponses;
