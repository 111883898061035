import { round } from '../../../utils';

export const initProduct = {
  name: '',
  price: '',
  count: '',
  unit: 'instances'
};

export const getInvalidProducts = (products) =>
  products.reduce((acc, curr) => {
    const isNotEmpty = curr.name || curr.count || curr.price || curr.unit;

    if (isNotEmpty && (!curr.name || !curr.count || !curr.unit)) {
      return [...acc, curr];
    }
    return acc;
  }, []);

export const getValidProducts = (products) =>
  products.reduce((acc, curr) => {
    if (curr.name && curr.count && curr.unit) {
      return [...acc, curr];
    }
    return acc;
  }, []);

export const parseProductsFromClipboard = (clipBoardData) => {
  if (/\t|\n/.exec(clipBoardData)) {
    const paste = clipBoardData.replace(/\\r/g, '');
    const rows = paste.split('\n');

    if (rows[rows.length - 1] === '') {
      rows.pop();
    }

    return rows
      .map((row) => {
        const [name, count, price] = row.split('\t');
        return {
          name,
          count: parseInt(count, 10) || '',
          price: parseFloat(price) || '',
          unit: initProduct.unit
        };
      })
      .filter((row) => row.name || row.price || row.count || row.unit);
  }
  return null;
};

export const countSum = (products) =>
  products.reduce((acc, prev) => acc + (prev.price * prev.count || 0), 0);

export const getSumWithVat = (product) =>
  product.price || product.price === 0
    ? round(product.price * product.count, 2)
    : '';
