.container {
  width: 100%;
  position: relative;
}

.input {
  box-sizing: border-box;
  width: 100%;
  outline: none;
  resize: none;
  border: 1px solid transparent;
  padding: var(--indent-xxs) var(--indent-xs);
  font-family: var(--font);
  font-size: var(--main-text-size);
  font-weight: var(--text-regular);
  line-height: var(--l1-line-height);
  color: var(--main-dark-gray);
}

.input::placeholder {
  color: var(--color-gray-700);
}

.input:hover {
  background-color: var(--hover-color);
}

.inputContainer {
  display: flex;
}

.controls {
  display: flex;
  column-gap: var(--indent-xxs);
  padding: var(--indent-xxs);
  background-color: var(--color-white);
  border: 1px solid var(--color-gray-300);
  box-shadow: 0 0 16px rgb(66 61 99 / 13%);
  border-radius: 2px;
}

.popper {
  display: flex;

  &:global(.MuiTooltip-popper[data-popper-placement*='bottom'] .MuiTooltip-tooltip),
  &:global(.MuiTooltip-popper[data-popper-placement*='top'] .MuiTooltip-tooltip) {
    padding: 0;
  }

  &:global(.MuiTooltip-popper[data-popper-placement*='bottom'] .MuiTooltip-tooltip) {
    margin: var(--indent-xxs) 0 0;
  }

  &:global(.MuiTooltip-popper[data-popper-placement*='top'] .MuiTooltip-tooltip) {
    margin: 0 0 var(--indent-xxs) 0;
  }
}

.focused .input {
  background-color: var(--color-white);
  border: 1px solid var(--color-blue-400);
  box-shadow: 0 0 5px rgb(35 105 241 / 31%);
  border-radius: 4px;
}

.focused .controls {
  opacity: 1;
  visibility: visible;
}
