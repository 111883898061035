import React from 'react';
import classNames from 'classnames';

const PopupContent = ({ menuOptions, style = {} }) =>
  menuOptions
    .filter(({ hide }) => !hide)
    .map(
      ({
        onClick = undefined,
        disabled = false,
        label,
        value,
        icon,
        isFocused = false
      }) => (
        <div
          style={style}
          key={value}
          className={classNames('compare-popup-item', {
            'compare-popup-disabled': disabled,
            'compare-popup-item-hover': !disabled,
            'compare-popup-item-hovered': isFocused
          })}
          onClick={disabled ? undefined : onClick}>
          {icon}
          <div style={{ width: 8 }} />
          {label}
        </div>
      )
    );

export default PopupContent;
