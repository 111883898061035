import TextField, { TextFieldProps } from '@mui/material/TextField';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { StyledEngineProvider, ThemeProvider } from '@mui/material/styles';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker as MUIDatePicker } from '@mui/x-date-pickers';
import { enUS, ru } from 'date-fns/locale';
import cn from 'classnames';
import { Typography } from '@/components/Typography';
import { Paper, PaperProps, PopperProps } from '@mui/material';
import { ButtonIcon } from '@/components/ButtonIcon';
import { Icon } from '@/components/Icon';
import { DateTimePickerProps } from './DateTimePicker.types';
import styles from './DateTimePicker.module.scss';
import theme from './DateTimePicker.theme';

const DATE_RU = 'dd.MM.yyyy';
const DATE_AND_TIME_RU = 'dd.MM.yyyy hh:mm';
const DATE_EN = 'MM/dd/yyyy';
const DATE_AND_TIME_EN = 'MM/dd/yyyy hh:mm';

const DATE_PLACEHOLDER_RU = 'дд.мм.гггг';
const DATE_AND_TIME_PLACEHOLDER_RU = 'дд.мм.гггг чч:мм';

interface ArrowButtonProps {
  onClick: () => void;
}

type DateTimePickerMode = 'date' | 'dateAndTime';

function LeftArrowButton({ onClick }: ArrowButtonProps) {
  return (
    <ButtonIcon
      className={styles.arrowLeft}
      iconName="arrow-left"
      variant="small"
      size={16}
      onClick={onClick}
    />
  );
}

function RightArrowButton({ onClick }: ArrowButtonProps) {
  return (
    <ButtonIcon
      className={styles.arrowRight}
      iconName="arrow-right"
      variant="small"
      size={16}
      onClick={onClick}
    />
  );
}

function OpenPickerIcon() {
  return (
    <Icon
      className={styles.calendarIcon}
      iconName="calendar"
      height={16}
      width={16}
    />
  );
}

function PaperContent(props: PaperProps) {
  return (
    <Typography variant="body1Reg">
      <Paper className={styles.paper} {...props} />
    </Typography>
  );
}

function formatDayOfWeek(day: string) {
  return `${day.toUpperCase()}`;
}

const calendarComponents = {
  OpenPickerIcon,
  PaperContent,
  LeftArrowButton,
  RightArrowButton
};

const popperProps = { placement: 'bottom-start' } as Partial<PopperProps>;

function isValidDate(d: any) {
  return d instanceof Date && !Number.isNaN(d);
}

function getDefaultDate(date: number | undefined, minDate: number | undefined) {
  let selectedDate = date ?? null;
  if (minDate && selectedDate && selectedDate < minDate) {
    selectedDate = minDate;
  }

  return selectedDate;
}

export function DateTimePicker({
  locale = 'ru',
  className = '',
  date,
  minDate,
  onDateChange,
  disabled = false
}: DateTimePickerProps) {
  // TODO: возможность использования Time Picker не реализована окончательно из-за отсутствия дизайнов
  const mode: DateTimePickerMode = 'date';
  const [value, setValue] = useState<number | null>(
    getDefaultDate(date, minDate)
  );
  const [active, setActive] = useState(false);

  useEffect(() => {
    const currentDate = getDefaultDate(date, minDate);
    setValue(currentDate);
  }, [date, minDate]);

  const [format, placeholder] = useMemo(() => {
    let currentFormat = '';
    let currentPlaceholder = '';

    if (locale === 'en') {
      currentFormat = mode === 'date' ? DATE_EN : DATE_AND_TIME_EN;
      currentPlaceholder = currentFormat;
    } else {
      currentFormat = mode === 'date' ? DATE_RU : DATE_AND_TIME_RU;
      currentPlaceholder =
        mode === 'date' ? DATE_PLACEHOLDER_RU : DATE_AND_TIME_PLACEHOLDER_RU;
    }
    return [currentFormat, currentPlaceholder];
  }, [locale, mode]);

  const handleChange = useCallback(
    (newValue: number | null, _keyboardInputValue?: string) => {
      setValue(newValue);
      if (typeof onDateChange === 'function') {
        if (!isValidDate(newValue)) {
          onDateChange(_keyboardInputValue as string);
        } else {
          onDateChange(newValue);
        }
      }
    },
    [onDateChange]
  );

  const handleFocus = useCallback(() => setActive(true), []);
  const handleBlur = useCallback(() => setActive(false), []);

  const renderInputCallback = useCallback(
    (props: TextFieldProps) => (
      <Typography variant="body1Reg">
        <TextField
          classes={{ root: active ? styles.active : '' }}
          onFocus={handleFocus}
          onBlur={handleBlur}
          {...props}
          inputProps={{
            ...props.inputProps,
            placeholder
          }}
        />
      </Typography>
    ),
    [active, handleBlur, handleFocus, placeholder]
  );

  return (
    <div className={cn(className, styles.dateTimePicker)}>
      <LocalizationProvider
        dateAdapter={AdapterDateFns}
        adapterLocale={locale === 'ru' ? ru : enUS}>
        <ThemeProvider theme={theme}>
          <StyledEngineProvider injectFirst>
            <MUIDatePicker
              minDate={minDate}
              renderInput={renderInputCallback}
              value={value}
              onChange={handleChange}
              toolbarFormat={format}
              inputFormat={format}
              disablePast
              dayOfWeekFormatter={formatDayOfWeek}
              components={calendarComponents}
              PopperProps={popperProps}
              reduceAnimations
              showDaysOutsideCurrentMonth
              disabled={disabled}
            />
          </StyledEngineProvider>
        </ThemeProvider>
      </LocalizationProvider>
    </div>
  );
}
