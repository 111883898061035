import React, { Fragment, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import styled from 'styled-components';
import { Select } from '../../../FormControls';
import * as requestsActions from '../../../../action-creators/purchaseRequests';
import * as storage from './storage';
import * as getters from './getters';
import * as modalActions from '../../../../action-creators/modal';

import { actions as responsesActions } from './reducer';
import EmptySearchResult from '../../common/EmptySearchResult';
import './style.css';
import PopupSmall from '../../../PopupSmall';
import Filter from '../../../Buttons/Filter';
import '../../style.css';
import SearchBlockDefault from '../../../SearchBlock/SearchBlockDefault';
import Checkbox from '../../../Checkbox';
import ResponseTable from './ResponseTable';
import ButtonIcon from '../../../ChatWidget/ButtonIcon';
import { useTranslate } from '../../../../TranslateProvider';
import {
  initState,
  makeFilters
} from '../../../../presenters/sidebars/PurchaseRequestResponsesSidebar/MyResponses';

const DEFAULT_SORT_DIRECTION = {
  requestId: 'ASC',
  sum: 'ASC',
  updatedAt: 'DESC'
};

const toggleDirection = (currentDirection) =>
  currentDirection === 'DESC' ? 'ASC' : 'DESC';

const isFilterActive = (filterCheckbox) => {
  const sum = Object.values(filterCheckbox).reduce(
    (acc, prev) => acc + Number(prev || 0),
    0
  );
  return sum > 0;
};

const PopupFilterContent = ({
  t,
  responseStatuses,
  filterCheckbox,
  toggleCheckbox
}) => (
  <div className="compare-popup">
    <div className="compare-popup-item">{t('response_status')}</div>
    {responseStatuses.map(({ id, name }) => (
      <div className="compare-popup-item" key={`${id}res`}>
        <Checkbox onClick={toggleCheckbox(id)} set={filterCheckbox[id]} />
        <span>{name}</span>
      </div>
    ))}
  </div>
);

const MyResponsesContent = ({
  responses,
  limit,
  offset,
  orderBy,
  direction,
  search,
  setSearch,
  getMyResponses,
  filters,
  deleteResponseFromServer,
  removeResponse
}) => {
  const t = useTranslate();
  const sortOptions = [
    { value: 'requestId', label: t('by_request_number') },
    { value: 'responseDate', label: t('by_respose_date') },
    { value: 'sum', label: t('by_sum') }
  ];

  const responseStatuses = [
    { id: 'sent', name: t(`respondStatus.sent-as-supplier`) },
    { id: 'viewed', name: t(`respondStatus.viewed`) },
    { id: 'rejected', name: t(`respondStatus.rejected`) },
    { id: 'clarification', name: t(`respondStatus.clarification`) },
    { id: 'accepted', name: t(`respondStatus.accepted`) },
    { id: 'on-rework', name: t(`respondStatus.on-rework`) },
    { id: 'rework-completed', name: t(`respondStatus.rework-completed`) },
    { id: 'draft', name: t(`respondStatus.draft`) }
  ];

  const [directionList, setDirectionList] = useState(DEFAULT_SORT_DIRECTION);
  const [isFilterOpened, setIsFilterOpened] = useState(false);
  const [isArchive, setIsArchive] = useState(false);
  const [filterCheckbox, setFilterCheckbox] = useState({});
  const [sortValue, setSortValue] = useState(sortOptions[0]);
  const [checkedList, setCheckedList] = useState({});

  const onSearchChange = (value) => setSearch(value);

  const getSelectedStatus = (statuses) =>
    Object.keys(statuses).filter((key) => statuses[key]);

  const onChangeSort = ({ value }) => {
    setDirectionList((prevDirection) => ({
      ...prevDirection,
      [value]: toggleDirection(prevDirection[value])
    }));
    getMyResponses({
      limit,
      offset,
      search,
      filters,
      order: value,
      direction: toggleDirection(directionList[value]),
      selectedStatus: getSelectedStatus(filterCheckbox),
      isArchive
    });
    setSortValue(value);
  };

  useEffect(() => {
    getMyResponses({
      limit,
      search,
      filters: makeFilters(initState(filters)),
      order: orderBy,
      direction,
      selectedStatus: getSelectedStatus(filterCheckbox),
      isArchive
    });
  }, [filterCheckbox, isArchive, filters]);

  const onSearch = (currSearch = '') => {
    getMyResponses({
      limit,
      search: currSearch,
      filters,
      order: orderBy,
      direction,
      selectedStatus: getSelectedStatus(filterCheckbox),
      isArchive
    });
  };

  const toggleIsArchive = () => setIsArchive(!isArchive);

  const toggleCheckbox = (name) => () =>
    setFilterCheckbox({ ...filterCheckbox, [name]: !filterCheckbox[name] });

  const deleteResponse = (responseId) => () => {
    removeResponse(responseId);
    deleteResponseFromServer({ responseId });
  };

  return (
    <>
      <ControlPanel>
        <SearchBlockDefault
          theme="main"
          query={search}
          placeholder={`${t('purchase_request_title')}/${t('request_number')}`}
          onSubmit={onSearch}
          onChange={onSearchChange}
          onEmpty={onSearch}
          width={365}
        />
        <SortBlock>
          <span>{t('Sort by')}</span>
          <Select
            id="sort-select"
            className="sort-select"
            value={sortValue}
            onChange={onChangeSort}
            options={sortOptions}
            clearable={false}
            searchable={false}
            style={{ width: 174 }}
          />
        </SortBlock>
        <div>
          <Filter
            onClick={() => setIsFilterOpened(!isFilterOpened)}
            isFilterActive={isFilterActive(filterCheckbox)}
          />
          <PopupSmall
            content={
              <PopupFilterContent
                t={t}
                responseStatuses={responseStatuses}
                filterCheckbox={filterCheckbox}
                toggleCheckbox={toggleCheckbox}
              />
            }
            isOpened={isFilterOpened}
            top={10}
            setIsOpened={setIsFilterOpened}
          />
        </div>
        <ButtonIcon
          icon="archive"
          tooltip="btn-archive"
          active={isArchive}
          onClick={toggleIsArchive}
        />
      </ControlPanel>
      {responses ? (
        <ResponseTable
          selectedStatus={getSelectedStatus(filterCheckbox)}
          isArchive={isArchive}
          deleteResponse={deleteResponse}
          checkedList={checkedList}
          setCheckedList={setCheckedList}
        />
      ) : (
        <EmptySearchResult tkey="responses_filters_empty_text" />
      )}
    </>
  );
};

const ControlPanel = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 17px;
  margin-right: 12px;
  line-height: 14px;
  column-gap: 28px;
  row-gap: 16px;
`;

const SortBlock = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`;

export default connect(
  (state) => ({
    responses: getters.getAllResponses(state),
    limit: getters.getLimit(state),
    offset: getters.getOffset(state),
    direction: getters.getDirection(state),
    orderBy: getters.getOrder(state),
    filters: getters.getFilters(state),
    search: getters.getSearch(state)
  }),
  (dispatch) =>
    bindActionCreators(
      {
        getMyResponses: storage.getMyResponses,
        setSearch: responsesActions.setSearch,
        removeResponse: responsesActions.removeResponse,
        deleteResponseFromServer: requestsActions.deleteResponse,
        showModal: modalActions.showModal
      },
      dispatch
    )
)(MyResponsesContent);
