import React, { Component } from 'react';
import { Button } from 'react-bootstrap';
import { connect } from 'react-redux';
import { translate } from 'react-i18next';
import { withRouter } from '../hoc/withRouter';

import * as auth from '../action-creators/auth';
import Loading from '../components/MessengerAuth/Loading';
import SecurityBoxLogo from '../components/MessengerAuth/SecurityBoxLogo';

class MessengerVerifyEmail extends Component {
  UNSAFE_componentWillMount() {
    const { verifyToken, location } = this.props;

    const params = new URLSearchParams(location.search);
    const token = params.get('token');
    // Path for redirect after login by email link
    const successRedirect = params.get('successRedirect');
    verifyToken(token, successRedirect);
  }

  onClickHandler() {
    this.props.history.push('/login');
  }

  render() {
    const { verifyEmailError, t } = this.props;

    return (
      <div>
        {verifyEmailError ? (
          <div>
            <SecurityBoxLogo />
            <div style={{ textAlign: 'center' }}>
              <div style={{ color: 'red', marginBottom: 15 }}>
                {t(verifyEmailError)}
              </div>
              <Button
                id="backHomeButton"
                onClick={this.onClickHandler}
                bsStyle="primary">
                {t('Back to Home')}
              </Button>
            </div>
          </div>
        ) : (
          <Loading text={t('create_account_message')} />
        )}
      </div>
    );
  }
}

export default withRouter(
  connect(
    (state) => ({
      verifyEmailError: state.getIn(['verifyEmail', 'error'])
    }),
    auth
  )(translate()(MessengerVerifyEmail))
);
