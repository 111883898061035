import React from 'react';

const RenderHTML = ({
  html = '',
  show = true,
  isClickable = false,
  onClick
}) => {
  if (!show) return null;
  return (
    <div
      className={`render-html render-html_is-clickable_${isClickable}`}
      onClick={onClick}
      dangerouslySetInnerHTML={{ __html: html }}
    />
  );
};

export default RenderHTML;
