module.exports = {
  limiter: {
    windowMs: 5 * 60 * 1000,
    max: 100
  },
  unitCodes: [
    {
      code: 3,
      name: 'Миллиметр',
      condDesign: { nat: 'мм', int: 'mm' },
      codeDesign: { nat: 'ММ', int: 'MMT' }
    },
    {
      code: 4,
      name: 'Сантиметр',
      condDesign: { nat: 'см', int: 'cm' },
      codeDesign: { nat: 'СМ', int: 'CMT' }
    },
    {
      code: 6,
      name: 'Метр',
      condDesign: { nat: 'м', int: 'm' },
      codeDesign: { nat: 'М', int: 'MTR' }
    },
    {
      code: 18,
      name: 'Погонный метр',
      condDesign: { nat: 'пог.м', int: 'пог.м' },
      codeDesign: { nat: 'ПОГ.М', int: 'ПОГ.М' }
    },
    {
      code: 55,
      name: 'Квадратный метр',
      condDesign: { nat: 'м2', int: 'm2' },
      codeDesign: { nat: 'М2', int: 'MTK' }
    },
    {
      code: 111,
      name: 'Кубический сантиметр',
      condDesign: { nat: 'см3', int: 'cm3' },
      codeDesign: { nat: 'CМ3', int: 'CMQ' }
    },
    {
      code: 112,
      name: 'Литр; кубический дециметр',
      condDesign: { nat: 'л; дм3', int: 'L; dm3' },
      codeDesign: { nat: 'Л; ДМ3', int: 'LTR; DMQ' }
    },
    {
      code: 113,
      name: 'Кубический метр',
      condDesign: { nat: 'м3', int: 'm3' },
      codeDesign: { nat: 'М3', int: 'MTQ' }
    },
    {
      code: 163,
      name: 'Грамм',
      condDesign: { nat: 'г', int: 'g' },
      codeDesign: { nat: 'Г', int: 'GRM' }
    },
    {
      code: 166,
      name: 'Килограмм',
      condDesign: { nat: 'кг', int: 'kg' },
      codeDesign: { nat: 'КГ', int: 'KGM' }
    },
    {
      code: 168,
      name: 'Тонна; метрическая тонна (1000 кг)',
      condDesign: { nat: 'т', int: 't' },
      codeDesign: { nat: 'Т', int: 'TNE' }
    },
    {
      code: 625,
      name: 'Лист',
      condDesign: { nat: 'л.', int: '' },
      codeDesign: { nat: 'ЛИСТ', int: 'LEF' }
    },
    {
      code: 657,
      name: 'Изделие',
      condDesign: { nat: 'изд', int: '' },
      codeDesign: { nat: 'ИЗД', int: 'NAR' }
    },
    {
      code: 704,
      name: 'Набор',
      condDesign: { nat: 'набор', int: '' },
      codeDesign: { nat: 'НАБОР', int: 'SET' }
    },
    {
      code: 715,
      name: 'Пара (2 шт.)',
      condDesign: { nat: 'пар', int: 'pr' },
      codeDesign: { nat: 'ПАР', int: 'NPR' }
    },
    {
      code: 778,
      name: 'Упаковка',
      condDesign: { nat: 'упак', int: '' },
      codeDesign: { nat: 'УПАК', int: 'NMP' }
    },
    {
      code: 796,
      name: 'Штука',
      condDesign: { nat: 'шт', int: 'pcs' },
      codeDesign: { nat: 'ШТ', int: 'PCE; NMB' }
    }
  ],
  unitCodeGroups: {
    size: [
      {
        code: 3,
        name: 'Миллиметр',
        condDesign: { nat: 'мм', int: 'mm' },
        codeDesign: { nat: 'ММ', int: 'MMT' }
      },
      {
        code: 4,
        name: 'Сантиметр',
        condDesign: { nat: 'см', int: 'cm' },
        codeDesign: { nat: 'СМ', int: 'CMT' }
      },
      {
        code: 6,
        name: 'Метр',
        condDesign: { nat: 'м', int: 'm' },
        codeDesign: { nat: 'М', int: 'MTR' }
      },
      {
        code: 18,
        name: 'Погонный метр',
        condDesign: { nat: 'пог.м', int: 'пог.м' },
        codeDesign: { nat: 'ПОГ.М', int: 'ПОГ.М' }
      }
    ],
    area: [
      {
        code: 55,
        name: 'Квадратный метр',
        condDesign: { nat: 'м2', int: 'm2' },
        codeDesign: { nat: 'М2', int: 'MTK' }
      }
    ],
    volume: [
      {
        code: 111,
        name: 'Кубический сантиметр',
        condDesign: { nat: 'см3', int: 'cm3' },
        codeDesign: { nat: 'CМ3', int: 'CMQ' }
      },
      {
        code: 112,
        name: 'Литр; кубический дециметр',
        condDesign: { nat: 'л; дм3', int: 'L; dm3' },
        codeDesign: { nat: 'Л; ДМ3', int: 'LTR; DMQ' }
      },
      {
        code: 113,
        name: 'Кубический метр',
        condDesign: { nat: 'м3', int: 'm3' },
        codeDesign: { nat: 'М3', int: 'MTQ' }
      }
    ],
    weight: [
      {
        code: 163,
        name: 'Грамм',
        condDesign: { nat: 'г', int: 'g' },
        codeDesign: { nat: 'Г', int: 'GRM' }
      },
      {
        code: 166,
        name: 'Килограмм',
        condDesign: { nat: 'кг', int: 'kg' },
        codeDesign: { nat: 'КГ', int: 'KGM' }
      },
      {
        code: 168,
        name: 'Тонна; метрическая тонна (1000 кг)',
        condDesign: { nat: 'т', int: 't' },
        codeDesign: { nat: 'Т', int: 'TNE' }
      }
    ],
    other: [
      {
        code: 625,
        name: 'Лист',
        condDesign: { nat: 'л.', int: '' },
        codeDesign: { nat: 'ЛИСТ', int: 'LEF' }
      },
      {
        code: 657,
        name: 'Изделие',
        condDesign: { nat: 'изд', int: '' },
        codeDesign: { nat: 'ИЗД', int: 'NAR' }
      },
      {
        code: 704,
        name: 'Набор',
        condDesign: { nat: 'набор', int: '' },
        codeDesign: { nat: 'НАБОР', int: 'SET' }
      },
      {
        code: 715,
        name: 'Пара (2 шт.)',
        condDesign: { nat: 'пар', int: 'pr' },
        codeDesign: { nat: 'ПАР', int: 'NPR' }
      },
      {
        code: 778,
        name: 'Упаковка',
        condDesign: { nat: 'упак', int: '' },
        codeDesign: { nat: 'УПАК', int: 'NMP' }
      },
      {
        code: 796,
        name: 'Штука',
        condDesign: { nat: 'шт', int: 'pcs' },
        codeDesign: { nat: 'ШТ', int: 'PCE; NMB' }
      }
    ]
  },
  permissions: {
    company: {
      read: true,
      update: true,
      delete: false
    },
    employees: {
      read: true,
      update: true,
      delete: true,
      editPermissions: true
    },
    catalogs: {
      read: true,
      update: true,
      delete: true
    },
    stocks: {
      read: true,
      update: true,
      delete: true
    },
    prices: {
      read: true,
      update: true,
      delete: true
    },
    customers: {
      read: true,
      update: true,
      delete: true
    },
    customerOrders: {
      read: true,
      update: true,
      delete: true
    },
    suppliers: {
      read: true,
      update: true,
      delete: true
    },
    supplierOrders: {
      read: true,
      update: true,
      delete: true
    },
    billing: {
      read: true,
      update: false,
      delete: false
    },
    purchaseRequests: {
      read: true,
      update: false,
      delete: false
    }
  },
  billing: {
    tariffs: {
      free: {
        cost: 0,
        currency: 'RUB',
        // employees: 1,
        catalogs: 1,
        prices: 0,
        stocks: 0,
        storage: 2
      },
      base: {
        cost: 2900,
        currency: 'RUB',
        // employees: 3,
        catalogs: 2,
        prices: 5,
        stocks: 3,
        storage: 5
      },
      standart: {
        cost: 5500,
        currency: 'RUB',
        // employees: 7,
        catalogs: 3,
        prices: 10,
        stocks: 5,
        storage: 15
      },
      premium: {
        cost: 12500,
        currency: 'RUB',
        // employees: 15,
        catalogs: 5,
        prices: 30,
        stocks: 10,
        storage: 50
      },
      unlim: {
        cost: 0,
        currency: 'RUB',
        // employees: 999,
        catalogs: 999,
        prices: 999,
        stocks: 999,
        storage: 999
      }
    },
    additions: [
      // {
      //   name: 'employees',
      //   count: 1,
      //   cost: 500,
      //   currency: 'RUB'
      // },
      {
        name: 'catalogs',
        count: 1,
        cost: 1000,
        currency: 'RUB'
      },
      {
        name: 'prices',
        count: 1,
        cost: 500,
        currency: 'RUB'
      },
      {
        name: 'stocks',
        count: 1,
        cost: 500,
        currency: 'RUB'
      },
      {
        name: 'storage',
        count: 1,
        cost: 200,
        currency: 'RUB'
      }
    ]
  },
  requestLimitCount: {
    // time in minutes
    addCustomer: { count: 20, time: 1 },
    addSupplier: { count: 20, time: 1 }
  }
};
