import { addDays, isValid } from 'date-fns';
import { isValidDate } from 'backend/src/lib/util';

export function dateToMidnight(date = null) {
  return date
    ? new Date(new Date(date).setHours(0, 0, 0, 0))
    : new Date(new Date().setHours(0, 0, 0, 0));
}

export function compareTwoDates(responseEndDate) {
  const responseEndDateNumber = dateToMidnight(responseEndDate).getTime();
  return responseEndDateNumber > Date.now()
    ? responseEndDateNumber
    : Date.now();
}

export function translate(t, text, options) {
  return t(`purchaseRequest.extendRespondEndDateModal.${text}`, options);
}

export function canUpdateRespondEndDate(minDate, responseEndDate) {
  return minDate <= responseEndDate;
}

export function createTomorrow() {
  return addDays(dateToMidnight(), +1);
}

export const isCanUpdate = (datePicker, responseEndDate) => {
  if (!isValidDate(datePicker) && !isValidDate(responseEndDate)) return false;
  return createTomorrow().getTime() <= new Date(responseEndDate).getTime();
};

export const generateDefaultDate = (datePickerState) =>
  isValid(datePickerState) ? datePickerState : createTomorrow();
