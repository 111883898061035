const { makePolicy } = require('../../data/policy');

// Section name
const NOMENCLATURE = 'NOMENCLATURE';

// Use cases
const VIEW_CATALOGS = 'VIEW_CATALOGS';
const VIEW_PRODUCTS = 'VIEW_PRODUCTS';
const PUBLISH_CATALOG = 'PUBLISH_CATALOG';
const IMPORT_PRODUCTS = 'IMPORT_PRODUCTS';
const EXPORT_PRODUCTS = 'EXPORT_PRODUCTS';
const UPDATE_PRODUCT = 'UPDATE_PRODUCT';
const ADD_CATALOG = 'ADD_CATALOG';
const DELETE_CATALOG = 'DELETE_CATALOG';
const ADD_PRODUCT = 'ADD_PRODUCT';
const DELETE_PRODUCT = 'DELETE_PRODUCT';

const settings = [
  makePolicy(NOMENCLATURE, VIEW_CATALOGS, {
    reader: true,
    writer: true,
    advanced: true
  }),
  makePolicy(NOMENCLATURE, VIEW_PRODUCTS, {
    reader: true,
    writer: true,
    advanced: true
  }),
  makePolicy(NOMENCLATURE, PUBLISH_CATALOG, {
    reader: false,
    writer: true,
    advanced: true
  }),
  makePolicy(NOMENCLATURE, IMPORT_PRODUCTS, {
    reader: false,
    writer: true,
    advanced: true
  }),
  makePolicy(NOMENCLATURE, EXPORT_PRODUCTS, {
    reader: false,
    writer: true,
    advanced: true
  }),
  makePolicy(NOMENCLATURE, UPDATE_PRODUCT, {
    reader: false,
    writer: true,
    advanced: true
  }),
  makePolicy(NOMENCLATURE, ADD_CATALOG, {
    reader: false,
    writer: true,
    advanced: true
  }),
  makePolicy(NOMENCLATURE, DELETE_CATALOG, {
    reader: false,
    writer: false,
    advanced: true
  }),
  makePolicy(NOMENCLATURE, ADD_PRODUCT, {
    reader: false,
    writer: true,
    advanced: true
  }),
  makePolicy(NOMENCLATURE, DELETE_PRODUCT, {
    reader: false,
    writer: true,
    advanced: true
  })
];

module.exports = {
  NOMENCLATURE,
  VIEW_CATALOGS,
  VIEW_PRODUCTS,
  PUBLISH_CATALOG,
  IMPORT_PRODUCTS,
  EXPORT_PRODUCTS,
  UPDATE_PRODUCT,
  ADD_CATALOG,
  DELETE_CATALOG,
  ADD_PRODUCT,
  DELETE_PRODUCT,
  settings
};
