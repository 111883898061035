import { useCallback } from 'react';
import * as externalAPIStorage from '../storage';
import { actions } from '../redux';
import { useActions } from '../../../../../hooks/useActions';

const useFetchNewsAPIUrl = () => {
  const { setLoading, setNewsAPIUrl } = useActions(actions);

  return useCallback(async () => {
    try {
      setLoading(true);
      const response = await externalAPIStorage.getNewsAPIUrl();
      setNewsAPIUrl(response.url);
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  }, []);
};

export default useFetchNewsAPIUrl;
