import React from 'react';
import { useOpenChat } from './useOpenChat';
import ButtonIcon from '../ChatWidget/ButtonIcon';

const OpenChatButton = ({
  icon = 'message-1',
  getChat,
  onOpened,
  isWidget = false,
  dataTestId,
  tooltip,
  disabled
}) => {
  const onOpen = useOpenChat({ getChat, onOpened, isWidget });

  return (
    <ButtonIcon
      icon={icon}
      onClick={onOpen}
      tooltip={tooltip}
      disabled={disabled}
      dataTestid={dataTestId}
    />
  );
};

export default OpenChatButton;
