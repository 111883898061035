import fetch from 'isomorphic-fetch';
import getBrowserLanguage from '../utils/languageDetector';
import {
  setSuccessMessage,
  setWarningMessage,
  setErrorMessage,
  setInfoMessage
} from './message';
import { getCurrentLocation } from '../utils/location';
import customHistory from '../customHistory';
import { divideCategoriesIds } from './price-products-list';

export const selectProducts = (ids) => ({
  type: 'PRICES_FOUND:SELECT_PRODUCTS',
  payload: { ids }
});

export const unselectProducts = (ids) => ({
  type: 'PRICES_FOUND:UNSELECT_PRODUCTS',
  payload: { ids }
});

export const toggleFilterSelected = () => ({
  type: 'PRICES_FOUND:FILTER_SELECT_TOGGLE'
});

export const updateField = (field, value) => ({
  type: 'PRICES:CREATE_PRICES:UPDATE_FIELD',
  payload: { field, value }
});

export const updateStocksField = (index, value) => ({
  type: 'PRICES:CREATE_PRICES:UPDATE_STOCKS_FIELD',
  payload: { index, value }
});

export const updateAdditionAttributeField = (field, value) => ({
  type: 'PRICES:CREATE_PRICES:UPDATE_ATTRIBUTES_FIELD',
  payload: { field, value }
});

export const clearForm = () => ({ type: 'PRICES:CLEAR_FORM' });

export const clearPublicPrice = () => ({ type: 'PRICES:CLEAR_PUBLIC' });

export const selectPrice = (id) => ({ type: 'PRICES:SELECT', payload: { id } });

export const clearSelected = () => ({ type: 'PRICES:CLEAR_SELECTED' });

export const importFailure = (priceId, errors) => ({
  type: 'PRICE:IMPORT_EXCEL:FAILURE',
  payload: { priceId, errors }
});

export const setPriceStatusPublished = () => ({
  type: 'PRICE:SET_PRICE_STATUS_PUBLISHED'
});

export const setSelectedPrice = (price) => ({
  type: 'PRICES:SET_SELECTED_PRICE',
  payload: { price }
});

export const appendNewStock = () => ({ type: 'PRICES:APPEND_NEWSTOCK' });

export const removeStock = (index) => ({
  type: 'PRICES:REMOVE_STOCK',
  payload: { index }
});

export const clearPublicPriceCategories = () => ({
  type: 'PUBLIC_PRICE:CATEGORIES'
});

export const clearStocks = () => ({ type: 'PRICE:STOCKS_CLEAR' });

export const getAllPrices =
  (orderBy = '', direction = '') =>
  (dispatch) => {
    dispatch({ type: 'PRICES:GET_ALL_PRICES:REQUEST' });

    const url =
      orderBy && direction
        ? `/api/prices?orderBy=${orderBy}&direction=${direction}`
        : '/api/prices';

    fetch(url, {
      credentials: 'include',
      method: 'GET'
    })
      .then((res) => res.json())
      .then((prices) => {
        dispatch({
          type: 'PRICES:GET_ALL_PRICES:SUCCESS',
          payload: { items: prices }
        });
      })
      .catch((error) => {
        dispatch({ type: 'PRICES:GET_ALL_PRICES:FAILURE', payload: { error } });
      });
  };

export const clearOrderImport = () => ({ type: 'ORDER:CLEAR_IMPORT' });

export const clearPriceImport = () => ({ type: 'PRICE:CLEAR_IMPORT' });

export const setPriceForm = () => ({
  type: 'PRICE:PRICE_EDIT:SET_DEFAULT'
});

export const removeErrorByField = (type) => ({
  type: 'PRICES:REMOVE_ERROR',
  payload: { type }
});

export const getPrice = (id) => (dispatch) => {
  dispatch({ type: 'PRICE:GET_PRICE:REQUEST' });

  return fetch(`/api/prices/${id}`, {
    credentials: 'include',
    method: 'GET'
  })
    .then((res) => res.json())
    .then((price) => {
      if (price.blocked) {
        customHistory.push('/prices');
      }
      dispatch({ type: 'PRICE:GET_PRICE:SUCCESS', payload: { item: price } });
      return price;
    })
    .catch((res) => {
      dispatch({
        type: 'PRICE:GET_PRICE:FAILURE',
        payload: { error: res.error }
      });
      console.error(res);
    });
};

export const getPriceFeatures = (id) => (dispatch) => {
  dispatch({ type: 'PRICE:GET_PRICE_FEATURES:REQUEST' });
  return fetch(`/api/prices/${id}`, {
    credentials: 'include',
    method: 'GET'
  })
    .then((res) => res.json())
    .then((price) => {
      if (price.blocked) {
        customHistory.push('/prices');
      }
      dispatch({
        type: 'PRICE:GET_PRICE_FEATURES:SUCCESS',
        payload: { item: price }
      });
      return price;
    })
    .catch((res) => {
      dispatch({
        type: 'PRICE:GET_PRICE_FEATURES:FAILURE',
        payload: { error: res.error }
      });
      console.error(res);
    });
};

export const getPriceStocks = (id) => (dispatch) => {
  dispatch({ type: 'PRICE:GET_PRICE_STOCKS:REQUEST' });

  return fetch(`/api/prices/${id}/stocks`, {
    credentials: 'include',
    method: 'GET'
  })
    .then((res) => res.json())
    .then((stocks) => {
      dispatch({ type: 'PRICE:GET_PRICE_STOCKS:SUCCESS', payload: { stocks } });
    })
    .catch((res) => {
      dispatch({
        type: 'PRICE:GET_PRICE_STOCKS:FAILURE',
        payload: { error: res.error }
      });
      console.error(res);
    });
};

export const getPublishedPriceStocks = (id) => (dispatch) => {
  dispatch({ type: 'PRICE:GET_PRICE_STOCKS:REQUEST' });

  return fetch(`/api/published-prices/${id}/stocks`, {
    credentials: 'include',
    method: 'GET'
  })
    .then((res) => res.json())
    .then((stocks) => {
      dispatch({ type: 'PRICE:GET_PRICE_STOCKS:SUCCESS', payload: { stocks } });
    })
    .catch((res) => {
      dispatch({
        type: 'PRICE:GET_PRICE_STOCKS:FAILURE',
        payload: { error: res.error }
      });
      console.error(res);
    });
};

export const getPriceProduct = (priceId, id) => async (dispatch) => {
  dispatch({ type: 'PRICE:GET_PRICE_PRODUCT:REQUEST' });

  try {
    const res = await fetch(`/api/prices/${priceId}/products/${id}`, {
      credentials: 'include',
      method: 'GET'
    });
    const resp = await res.json();
    if (res.status === 409) {
      customHistory.push('/prices-suppliers');
      dispatch(setErrorMessage({ key: resp.msg }));
      return;
    }
    dispatch({
      type: 'PRICE:GET_PRICE_PRODUCT:SUCCESS',
      payload: { product: resp }
    });
  } catch (e) {
    console.error(e);
  }
};

export const getPublishedSharePriceProduct =
  (priceId, id, token) => (dispatch) => {
    dispatch({ type: 'PRICE:GET_PRICE_PRODUCT:REQUEST' });

    return fetch(
      `/api/published-prices/${priceId}/share/${token}/products/${id}`,
      {
        credentials: 'include',
        method: 'GET'
      }
    )
      .then((res) => res.json())
      .then((product) => {
        dispatch({
          type: 'PRICE:GET_PRICE_PRODUCT:SUCCESS',
          payload: { product }
        });
      })
      .catch((res) => {
        dispatch({
          type: 'PRICE:GET_PRICE_PRODUCT:FAILURE',
          payload: { error: res.error }
        });
        console.error(res);
      });
  };

export const getPublishedPrice = (id) => async (dispatch) => {
  dispatch({ type: 'PRICE:GET_PUBLISHED_PRICE:REQUEST' });

  try {
    const res = await fetch(`/api/published-prices/${id}`, {
      credentials: 'include',
      method: 'GET'
    });
    const resp = await res.json();
    if (res.status === 409) {
      customHistory.push('/prices-suppliers');
      dispatch(setErrorMessage({ key: resp.msg }));
      return;
    }
    dispatch({
      type: 'PRICE:GET_PUBLISHED_PRICE:SUCCESS',
      payload: { price: resp }
    });
  } catch (e) {
    console.error(e);
  }
};

export const getPublishedSharePriceData =
  (priceId, token) => async (dispatch) => {
    dispatch({ type: 'PRICE:GET_PUBLISHED_SHARE_PRICE:REQUEST' });

    const res = await fetch(`/api/published-prices/${priceId}/share/${token}`, {
      credentials: 'include',
      method: 'GET'
    });
    const json = await res.json();
    if (res.status === 409) {
      window.location = json.location;
    }
    if (res.status >= 500) {
      dispatch({
        type: 'PRICE:GET_PUBLISHED_SHARE_PRICE:FAILURE',
        payload: { error: res.error }
      });
      console.error(res);
    } else {
      const {
        price,
        priceCategories,
        purchaseCategories,
        stocks,
        auth,
        location
      } = json;
      if (auth) {
        window.location = location;
      }
      dispatch({
        type: 'PRICE:GET_PUBLISHED_PRICE:SUCCESS',
        payload: { price }
      });
      dispatch({
        type: 'PUBLIC_PRICE:GET_CATEGORIES:SUCCESS',
        payload: { item: priceCategories }
      });
      dispatch({
        type: 'PUBLIC_PRICE:GET_PRICE_PURCHASE_CATEGORIES:SUCCESS',
        payload: { item: purchaseCategories }
      });
      dispatch({ type: 'PRICE:GET_PRICE_STOCKS:SUCCESS', payload: { stocks } });
    }
  };

export const getPublicPricePositions = (priceId, categoryId) => (dispatch) => {
  dispatch({ type: 'PUBLIC_PRICE:GET_POSITIONS:REQUEST' });

  return fetch(
    `/api/published-prices/${priceId}/categories/${categoryId}/products`,
    {
      credentials: 'include',
      method: 'GET'
    }
  )
    .then((res) => res.json())
    .then((positions) => {
      dispatch({
        type: 'PUBLIC_PRICE:GET_POSITIONS:SUCCESS',
        payload: { item: positions }
      });
    })
    .catch((res) => {
      dispatch({
        type: 'PUBLIC_PRICE:GET_POSITIONS:FAILURE',
        payload: { error: res.error }
      });
      console.error(res);
    });
};

export const selectPublishedCategory = (categoryId) => ({
  type: 'PUBLIC_PRICE:SELECT_CATEGORY',
  payload: { categoryId }
});

export const clearSelectPublishedCategory = (categoryId) => ({
  type: 'PUBLIC_PRICE:CLEAR_SELECT_CATEGORY',
  payload: { categoryId }
});

const fetchGetPublishedProducts = ({
  priceId,
  search = '',
  offset = 0,
  limit = 20,
  categoriesId = [],
  additionalCategoriesId = [],
  orderBy,
  direction,
  params,
  filter
}) => {
  let url = `/api/published-prices/${priceId}/products/search`;
  if (orderBy && direction) {
    url = `/api/published-prices/${priceId}/products/search?orderBy=${orderBy}&direction=${direction}`;
  }
  return fetch(url, {
    credentials: 'include',
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      categoriesId,
      additionalCategoriesId,
      search,
      offset,
      limit,
      stockId: params && params.stockId,
      filter
    })
  }).catch((e) => {
    console.error(e);
    return Promise.reject();
  });
};

export const getPublicPriceProducts =
  (
    priceId,
    search,
    offset = 0,
    lim = 20,
    orderBy = '',
    direction = '',
    params,
    filterValue
  ) =>
  async (dispatch, getState) => {
    const state = getState();

    const filter =
      filterValue ||
      state.getIn(['tablesNew', 'priceProducts', 'appliedFilterValues']);

    let allCategoriesId = state.getIn([
      'productPositions',
      'selectedCategories'
    ]);
    allCategoriesId =
      allCategoriesId && allCategoriesId.size > 0
        ? allCategoriesId.toArray()
        : [];
    const {
      mainCatIds: categoriesId,
      additionalCatIds: additionalCategoriesId
    } = divideCategoriesIds(allCategoriesId);

    const limit =
      lim || getState().getIn(['tablesNew', 'priceProducts', 'limit']);

    dispatch({ type: 'PUBLIC_PRICE:GET_PRODUCTS:REQUEST' });

    try {
      const res = await fetchGetPublishedProducts({
        priceId,
        search,
        offset,
        limit,
        categoriesId,
        additionalCategoriesId,
        orderBy,
        direction,
        params,
        filter
      });
      const resp = await res.json();
      if (res.status === 409) {
        customHistory.push('/prices-suppliers');
        dispatch(setErrorMessage({ key: resp.msg }));
        return;
      }
      const {
        products,
        totalSize,
        currency,
        filterValues,
        showReceiptDate,
        showVat,
        showDiscount,
        showDiscountPrice
      } = resp;
      dispatch({
        type: 'PUBLIC_PRICE:GET_PRODUCTS:SUCCESS',
        payload: {
          products,
          offset,
          limit,
          search,
          totalSize,
          currency,
          filterValues,
          showReceiptDate,
          showVat,
          showDiscount,
          showDiscountPrice
        }
      });
    } catch (e) {
      console.error(e);
    }
  };

export const getPublicSharePriceProducts =
  (
    priceId,
    search,
    offset = 0,
    lim = 20,
    orderBy = '',
    direction = '',
    params
  ) =>
  (dispatch, getState) => {
    let categoriesId = getState()
      .getIn(['publicPrices', 'categories'])
      .filter((category) => category.get('selected'))
      .map((category) => category.get('id'));
    const limit =
      lim || getState().getIn(['publicPrices', 'productsList', 'limit']);
    categoriesId =
      categoriesId && categoriesId.size > 0 ? categoriesId.toArray() : [];

    dispatch({ type: 'PUBLIC_PRICE:GET_PRODUCTS:REQUEST' });

    const filter = getState().getIn([
      'publicPrices',
      'productsList',
      'appliedFilterValues'
    ]);

    let url = `/api/published-prices/${priceId}/share/${params.token}/products/search`;
    if (orderBy && direction) {
      // eslint-disable-next-line max-len
      url = `/api/published-prices/${priceId}/share/${params.token}/products/search?orderBy=${orderBy}&direction=${direction}`;
    }
    return fetch(url, {
      credentials: 'include',
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        categoriesId,
        search,
        offset,
        limit,
        stockId: params && params.stockId,
        filter
      })
    })
      .then((res) => res.json())
      .then(
        ({
          products,
          totalSize,
          currency,
          filterValues,
          showReceiptDate,
          showVat,
          showDiscount,
          showDiscountPrice
        }) => {
          dispatch({
            type: 'PUBLIC_PRICE:GET_PRODUCTS:SUCCESS',
            payload: {
              products,
              offset,
              limit,
              search,
              totalSize,
              currency,
              filterValues,
              showReceiptDate,
              showVat,
              showDiscount,
              showDiscountPrice
            }
          });
        }
      )
      .catch((res) => {
        dispatch({
          type: 'PUBLIC_PRICE:GET_PRODUCTS:FAILURE',
          payload: { error: res.error }
        });
        console.error(res);
      });
  };

export const getPublicPriceCategoryPositions =
  (id, category, offset = 0, limit = 20) =>
  (dispatch) => {
    dispatch({ type: 'PUBLIC_PRICE:GET_PUBLIC_CATEGORY_POSITIONS:REQUEST' });

    return (
      fetch(
        `/api/published-prices/${id}/categories/${category}/products?offset=${offset}&limit=${limit}`,
        {
          credentials: 'include',
          method: 'GET'
        }
      )
        .then((res) => res.json())
        // eslint-disable-next-line no-shadow
        .then(({ productPositions, size, offset, limit, currency }) => {
          dispatch({
            type: 'PUBLIC_PRICE:GET_PUBLIC_CATEGORY_POSITIONS:SUCCESS',
            payload: { productPositions, size, offset, limit, currency }
          });
        })
        .catch((res) => {
          dispatch({
            type: 'PUBLIC_PRICE:GET_PUBLIC_CATEGORY_POSITIONS:FAILURE',
            payload: { error: res.error }
          });
          console.error(res);
        })
    );
  };

export const getPublicPriceCategories = (priceId) => async (dispatch) => {
  dispatch({ type: 'PUBLIC_PRICE:GET_CATEGORIES:REQUEST' });

  try {
    const res = await fetch(`/api/published-prices/${priceId}/categories`, {
      credentials: 'include',
      method: 'GET'
    });
    const resp = await res.json();
    if (res.status === 409) {
      customHistory.push('/prices-suppliers');
      dispatch(setErrorMessage({ key: resp.msg }));
      return;
    }
    dispatch({
      type: 'PUBLIC_PRICE:GET_CATEGORIES:SUCCESS',
      payload: { item: resp }
    });
  } catch (e) {
    console.error(e);
  }
};

export const getPriceCategories = (id) => (dispatch) => {
  dispatch({ type: 'PRICE:GET_PRICE_CATEGORIES:REQUEST' });

  return fetch(`/api/prices/${id}/price-categories`, {
    credentials: 'include',
    method: 'GET'
  })
    .then((res) => res.json())
    .then((categories) => {
      dispatch({
        type: 'PRICE:GET_PRICE_CATEGORIES:SUCCESS',
        payload: { categories }
      });
    })
    .catch((res) => {
      dispatch({
        type: 'PRICE:GET_PRICE_CATEGORIES:FAILURE',
        payload: { error: res.error }
      });
      console.error(res);
    });
};

export const getPublishedPurchasePriceCategories = (id) => async (dispatch) => {
  dispatch({ type: 'PUBLIC_PRICE:GET_PRICE_PURCHASE_CATEGORIES:REQUEST' });

  try {
    const res = await fetch(`/api/published-prices/${id}/price-categories`, {
      credentials: 'include',
      method: 'GET'
    });
    const resp = await res.json();
    if (res.status === 409) {
      customHistory.push('/prices-suppliers');
      dispatch(setErrorMessage({ key: resp.msg }));
      return;
    }
    dispatch({
      type: 'PUBLIC_PRICE:GET_PRICE_PURCHASE_CATEGORIES:SUCCESS',
      payload: { item: resp }
    });
  } catch (e) {
    console.error(e);
  }
};

export const getAccessList =
  (id, name = '') =>
  (dispatch) => {
    dispatch({ type: 'PRICE:PRICE_ACCESS_LIST:REQUEST' });

    return fetch(`/api/prices/${id}/get_access_list?name=${name}`, {
      credentials: 'include',
      method: 'GET'
    })
      .then((res) => res.json())
      .then(({ accessList = [], size, addedSize, invites }) => {
        dispatch({
          type: 'PRICE:PRICE_ACCESS_LIST:SUCCESS',
          payload: { accessList, size, addedSize, invites }
        });
      })
      .catch((res) => {
        dispatch({
          type: 'PRICE:PRICE_ACCESS_LIST:FAILURE',
          payload: { error: res.error }
        });
        console.error(res);
      });
  };

export const getAccessListAndCustomers =
  (id, groups = [], name = '') =>
  async (dispatch) => {
    dispatch({ type: 'PRICE:PRICE_ACCESS_LIST:REQUEST' });
    try {
      const res = await fetch(
        `/api/prices/${id}/get_access_list_and_customers?groups=${groups}&name=${name}`,
        {
          credentials: 'include',
          method: 'GET'
        }
      );
      const { accessList = [], size } = await res.json();
      dispatch({
        type: 'PRICE:PRICE_ACCESS_LIST:SUCCESS',
        payload: { accessList, size }
      });
      return accessList;
    } catch (err) {
      dispatch({
        type: 'PRICE:PRICE_ACCESS_LIST:FAILURE',
        payload: { error: err }
      });
      return console.error(err);
    }
  };

export const setAccessToPrice = (priceId, groupId) => (dispatch) => {
  dispatch({ type: 'PRICE:SET_ACCESS_TO_PRICE:REQUEST' });

  return fetch(`/api/prices/${priceId}/set_access`, {
    credentials: 'include',
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ groupId, language: getBrowserLanguage() })
  })
    .then(() => {
      dispatch({
        type: 'PRICE:SET_ACCESS_TO_PRICE:SUCCESS',
        payload: { priceId, groupId }
      });
    })
    .catch((res) => {
      dispatch({
        type: 'PRICE:SET_ACCESS_TO_PRICE:FAILURE',
        payload: { error: res.error }
      });
      console.error(res);
    });
};

export const setShareType = (priceId, type) => (dispatch) => {
  dispatch({ type: 'PRICE:SET_SHARE_TYPE:REQUEST' });

  return fetch(`/api/prices/${priceId}/set_share_type`, {
    credentials: 'include',
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ type })
  })
    .then(() => {
      dispatch({
        type: 'PRICE:SET_SHARE_TYPE:SUCCESS',
        payload: { priceId, type }
      });
    })
    .catch((res) => {
      dispatch({
        type: 'PRICE:SET_SHARE_TYPE:FAILURE',
        payload: { error: res.error }
      });
      console.error(res);
    });
};

export const addAccess =
  (priceId, customers, edit = false) =>
  (dispatch) => {
    dispatch({ type: 'PRICE:ADD_ACCESS:REQUEST' });

    return fetch(`/api/prices/${priceId}/add_access`, {
      credentials: 'include',
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ customers })
    })
      .then(() => {
        if (edit) {
          dispatch({
            type: 'PRICE:EDIT_ACCESS_ADD:SUCCESS',
            payload: { customers }
          });
        } else {
          dispatch({
            type: 'PRICE:ADD_ACCESS:SUCCESS',
            payload: { priceId, customers }
          });
        }
      })
      .catch((res) => {
        dispatch({
          type: 'PRICE:ADD_ACCESS:FAILURE',
          payload: { error: res.error }
        });
        console.error(res);
      });
  };

export const removeAccess =
  (priceId, customers, edit = false) =>
  (dispatch) => {
    dispatch({ type: 'PRICE:REMOVE_ACCESS:REQUEST' });

    return fetch(`/api/prices/${priceId}/remove_access`, {
      credentials: 'include',
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ customers })
    })
      .then(() => {
        if (edit) {
          dispatch({
            type: 'PRICE:EDIT_ACCESS_REMOVE:SUCCESS',
            payload: { customers }
          });
        } else {
          dispatch({
            type: 'PRICE:REMOVE_ACCESS:SUCCESS',
            payload: { priceId, customers }
          });
        }
      })
      .catch((res) => {
        dispatch({
          type: 'PRICE:REMOVE_ACCESS:FAILURE',
          payload: { error: res.error }
        });
        console.error(res);
      });
  };

export const deletePrices = (prices) => (dispatch) => {
  dispatch({ type: 'PRICES:DELETE:REQUEST' });

  return fetch('/api/prices/delete', {
    credentials: 'include',
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ prices })
  })
    .then(() => {
      dispatch({ type: 'PRICES:DELETE:SUCCESS', payload: { prices } });
    })
    .catch((res) => {
      dispatch({
        type: 'PRICES:DELETE:FAILURE',
        payload: { error: res.error }
      });
      console.error(res);
    });
};

export const clearPrices = () => ({
  type: 'PRICES:CLEAR_STATE'
});

export const getMoreInfo = () => (dispatch) => {
  dispatch({ type: 'PRICES:GET_MORE_INFO:REQUEST' });

  fetch('/api/prices/new', {
    credentials: 'include',
    method: 'GET'
  })
    .then((res) => res.json())
    .then((json) =>
      dispatch({
        type: 'PRICES:GET_MORE_INFO:SUCCESS',
        payload: { ...json }
      })
    )
    .catch((res) => {
      dispatch({
        type: 'PRICES:GET_MORE_INFO:FAILURE',
        payload: { error: res.error }
      });
      console.error(res);
    });
};

export const createPrice = (price) => async (dispatch) => {
  const { name, stocks, language, vat, description, currency } = price;

  dispatch({ type: 'PRICES:CREATE_PRICES:REQUEST' });

  return fetch('/api/prices', {
    credentials: 'include',
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      name,
      stocks: stocks || null,
      language,
      vat: vat || 0,
      description,
      currency
    })
  })
    .then((res) =>
      res.json().then((json) =>
        // eslint-disable-next-line prefer-promise-reject-errors
        res.status < 400
          ? Promise.resolve(json)
          : Promise.reject({ errors: json.errors, status: res.status })
      )
    )
    .then(() =>
      dispatch({
        type: 'PRICES:CREATE_PRICES:SUCCESS',
        payload: { name, stocks, language, vat, description }
      })
    )
    .catch(({ errors, status }) => {
      if (status === 409) {
        dispatch({ type: 'PRICES:CREATE_PRICES:ERRORS', payload: { errors } });
      } else {
        dispatch({ type: 'PRICES:CREATE_PRICES:FAILURE', payload: { errors } });
      }
      return Promise.reject();
    });
};

export const copyPrice = (priceId, name) => async (dispatch) =>
  fetch(`/api/prices/${priceId}/copy`, {
    credentials: 'include',
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ name })
  })
    .then((res) =>
      res.json().then((json) =>
        // eslint-disable-next-line prefer-promise-reject-errors
        res.status < 400
          ? Promise.resolve(json)
          : Promise.reject({ errors: json.errors, status: res.status })
      )
    )
    .then((price) => {
      dispatch(setSuccessMessage({ key: 'Price copied successfully' }));
      dispatch({
        type: 'PRICES:COPY:SUCCESS',
        payload: { price }
      });
    })
    .catch((e) => {
      console.log(e);
      dispatch({ type: 'PRICES:COPY:FAILURE', payload: { errors: e } });
    });

export const updatePrice = () => (dispatch, getState) => {
  const {
    name,
    stocks,
    vat,
    description,
    id,
    currency,
    additionAttributes,
    additionProductsAttributes,
    rate,
    autoConfirm,
    roundUp
  } = getState().getIn(['prices', 'formCreate']).toJS();
  const price = getState().getIn(['prices', 'price']);

  dispatch({ type: 'PRICE:UPDATE_PRICES:REQUEST' });

  return fetch(`/api/prices/${id}`, {
    credentials: 'include',
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      name,
      stocks: stocks.filter((i) => i),
      language: price.get('language'),
      vat: vat || 0,
      description,
      currency,
      additionAttributes,
      additionProductsAttributes,
      rate,
      autoConfirm,
      roundUp
    })
  })
    .then((res) =>
      res.json().then((json) =>
        // eslint-disable-next-line prefer-promise-reject-errors
        res.status < 400
          ? Promise.resolve(json)
          : Promise.reject({ error: json.error, status: res.status })
      )
    )
    .then((newPrice) => {
      dispatch({ type: 'PRICE:UPDATE_PRICES:SUCCESS', payload: newPrice });
      dispatch({
        type: 'PRICE:GET_PRICE:SUCCESS',
        payload: { item: newPrice }
      });
    })
    .catch(({ error }) => error);
};

export const createPriceCategory = () => (dispatch, getState) => {
  const { name, discount, minOrder, from, to, unit, roundUp, type } = getState()
    .getIn(['prices', 'newPriceCategory'])
    .toJS();
  const price = getState().getIn(['prices', 'price']);

  dispatch({ type: 'PRICES:CREATE_CATEGORY:REQUEST' });

  return fetch(`/api/prices/${price.get('id')}/price-categories`, {
    credentials: 'include',
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      type,
      name,
      discount,
      minOrder,
      from,
      to,
      unit,
      roundUp,
      priceId: price.get('id')
    })
  })
    .then((res) =>
      res.json().then((json) =>
        // eslint-disable-next-line prefer-promise-reject-errors
        res.status < 400
          ? Promise.resolve(json)
          : Promise.reject({ errors: json.errors, status: res.status })
      )
    )
    .then((category) => {
      dispatch({
        type: 'PRICES:CREATE_CATEGORY:SUCCESS',
        payload: category
      });

      dispatch({
        type: 'PRICE:SET_PRICE_STATUS_DRAFT',
        payload: { price }
      });
    })
    .catch(({ errors, status }) => {
      if (status === 409) {
        dispatch({
          type: 'PRICES:CREATE_CATEGORY:ERRORS',
          payload: { errors }
        });
      } else {
        dispatch({
          type: 'PRICES:CREATE_CATEGORY:FAILURE',
          payload: { errors }
        });
      }

      console.error('error', errors);
      return Promise.reject();
    });
};

export const savePriceCategory = () => (dispatch, getState) => {
  const { id, name, discount, minOrder, from, to, unit, roundUp } = getState()
    .getIn(['prices', 'selectedCategory'])
    .toJS();
  const { id: priceId } = getState().getIn(['prices', 'price']).toJS();
  const priceState = getState().getIn(['prices', 'price']);

  dispatch({ type: 'PRICES:UPDATE_CATEGORY:REQUEST' });

  return fetch(`/api/prices/${priceId}/price-categories/${id}`, {
    credentials: 'include',
    method: 'PATCH',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      name,
      discount: discount === '' ? null : discount,
      minOrder: minOrder === '' ? null : minOrder,
      from,
      to,
      unit,
      roundUp,
      priceId
    })
  })
    .then((res) =>
      res.json().then((json) =>
        // eslint-disable-next-line prefer-promise-reject-errors
        res.status < 400
          ? Promise.resolve(json)
          : Promise.reject({ errors: json.errors, status: res.status })
      )
    )
    .then(() => {
      dispatch({
        type: 'PRICES:UPDATE_CATEGORY:SUCCESS',
        payload: { id, name, discount, minOrder, from, to, unit, priceId }
      });

      dispatch({
        type: 'PRICE:SET_PRICE_STATUS_DRAFT',
        payload: { price: priceState }
      });
    })
    .catch(({ errors, status }) => {
      if (status === 409) {
        dispatch({
          type: 'PRICES:UPDATE_CATEGORY:ERRORS',
          payload: { errors }
        });
      } else {
        dispatch({
          type: 'PRICES:UPDATE_CATEGORY:FAILURE',
          payload: { errors }
        });
      }

      console.error('error', errors);
      return Promise.reject();
    });
};

export const deletePriceCategory = (categories) => (dispatch, getState) => {
  const { id, language } = getState().getIn(['prices', 'price']).toJS();
  const price = getState().getIn(['prices', 'price']);

  dispatch({ type: 'PRICE_CATEGORY:DELETE:REQUEST' });

  return fetch(`/api/prices/${id}/price-categories/delete`, {
    credentials: 'include',
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ categories, priceId: id, language })
  })
    .then(() => {
      dispatch({
        type: 'PRICE_CATEGORY:DELETE:SUCCESS',
        payload: { categories }
      });
      dispatch({
        type: 'PRICE:SET_PRICE_STATUS_DRAFT',
        payload: { price }
      });
    })
    .catch((res) => {
      dispatch({
        type: 'PRICE_CATEGORY:DELETE:FAILURE',
        payload: { error: res.error }
      });
      console.error(res);
    });
};

export const deselectAll = () => ({ type: 'PRICE_CATEGORY:DESELECT_ALL' });

export const selectCategory = (id) => ({
  type: 'PRICE_CATEGORY:SELECT',
  payload: { id }
});

export const setSelectedCategory = (item) => ({
  type: 'PRICE_CATEGORY_SET_SELECTED',
  payload: { item }
});

export const updateNewCategory = (field, value) => ({
  type: 'PRICE_CATEGORY:UPDATE_NEW',
  payload: { field, value }
});

export const clearNewCategory = () => ({ type: 'PRICE_CATEGORY:CLEAR' });

export const updatePriceCategory = (field, value) => ({
  type: 'PRICE_CATEGORY:UPDATE',
  payload: { field, value }
});

export const selectSearchProduct = (productId) => ({
  type: 'PRICE:SEARCH_PRODUCT:SELECT',
  payload: { productId }
});

export const selectAllSearchProduct = () => ({
  type: 'PRICE:SEARCH_PRODUCT:SELECT_ALL',
  payload: {}
});

export const updateCost = (productId, cost) => ({
  type: 'PRICE:SEARCH_PRODUCT:UPDATE_COST',
  payload: { productId, cost }
});

export const bulkUpdateCost = (cost) => (dispatch, getState) => {
  const selectedProductsId = getState()
    .getIn(['prices', 'foundProducts', 'selected'])
    .toArray();
  selectedProductsId.forEach((id) => dispatch(updateCost(id, cost)));
};

export const searchProductOfPrice =
  ({
    priceId: argPriceId,
    id,
    query: search = '',
    limit = 20,
    offset = 0,
    orderBy: orderByArg,
    direction: directionArg
  }) =>
  (dispatch, getState) => {
    const priceId = argPriceId || id;
    const state = getState();

    const categoriesId = state
      .getIn(['tables', 'common', 'selected'])
      .toArray();
    const isEnabledFilterSelected = state.getIn([
      'prices',
      'foundProducts',
      'isEnabledFilterSelected'
    ]);

    const selectedIdsArray = state
      .getIn(['prices', 'foundProducts', 'selected'])
      .toArray();
    const selected = selectedIdsArray.length === 0 ? [0] : selectedIdsArray;
    const selectedIds = isEnabledFilterSelected ? selected : [];

    const orderBy =
      orderByArg || state.getIn(['prices', 'foundProducts', 'orderBy']);
    const direction =
      directionArg || state.getIn(['prices', 'foundProducts', 'direction']);

    const body = {
      search,
      limit,
      offset,
      categoriesId,
      ids: selectedIds,
      orderBy,
      direction
    };

    dispatch({ type: 'PRICE:SEARCH_PRODUCT:REQUEST', payload: body });

    return fetch(`/api/prices/${priceId}/products/search`, {
      credentials: 'include',
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(body)
    })
      .then((res) =>
        res
          .json()
          // eslint-disable-next-line prefer-promise-reject-errors
          .then((json) =>
            res.status < 400
              ? Promise.resolve(json)
              : Promise.reject({ errors: json, status: res.status })
          )
      )
      .then((data) =>
        dispatch({
          type: 'PRICE:SEARCH_PRODUCT:SUCCESS',
          payload: {
            query: search,
            products: data.items,
            totalProducts: data.totalCount,
            initialLoad: true,
            currency: data.currency,
            limit,
            offset,
            notAdded: data.notAdded,
            stockSize: data.stockSize,
            productsId: data.productsId || [],
            orderBy,
            direction
          }
        })
      )
      .catch((errors) => {
        dispatch({ type: 'PRICE:SEARCH_PRODUCT:FAILURE', payload: { errors } });
        console.error(errors);

        return Promise.reject();
      });
  };

export const addProductsToPriceAndSetCost =
  (priceId, cost = 0) =>
  (dispatch, getState) => {
    const state = getState();
    const selectedProducts = state
      .getIn(['prices', 'foundProducts', 'selected'])
      .toJS();
    const products = selectedProducts.map((id) => ({ id, cost }));

    dispatch({ type: 'PRICE:ADD_PRODUCT_TO_PRICE:REQUEST' });

    return fetch(`/api/prices/${priceId}/products/add`, {
      credentials: 'include',
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ products, rate: '' })
    })
      .then((res) =>
        res.json().then((json) =>
          // eslint-disable-next-line prefer-promise-reject-errors
          res.status < 400
            ? Promise.resolve(json)
            : Promise.reject({ errors: json.errors, status: res.status })
        )
      )
      .then(() =>
        dispatch({
          type: 'PRICE:ADD_PRODUCT_TO_PRICE:SUCCESS',
          payload: { products }
        })
      )
      .catch(({ errors }) => {
        dispatch({
          type: 'PRICE:ADD_PRODUCT_TO_PRICE:FAILURE',
          payload: { errors }
        });
        console.error(errors);

        return Promise.reject();
      });
  };

const getCostSelectedProducts = (state) => {
  const selectedProducts = state
    .getIn(['prices', 'foundProducts', 'selected'])
    .toJS();
  return selectedProducts.map((id) => {
    const cost = state.getIn(['prices', 'listOfBaseCost', id]);
    return { id, cost };
  });
};

export const addProductsToPrice =
  (priceId, rate = '') =>
  (dispatch, getState) => {
    const products = getCostSelectedProducts(getState());

    dispatch({ type: 'PRICE:ADD_PRODUCT_TO_PRICE:REQUEST' });

    return fetch(`/api/prices/${priceId}/products/add`, {
      credentials: 'include',
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ products, rate })
    })
      .then((res) =>
        res.json().then((json) =>
          // eslint-disable-next-line prefer-promise-reject-errors
          res.status < 400
            ? Promise.resolve(json)
            : Promise.reject({ errors: json.errors, status: res.status })
        )
      )
      .then(() =>
        dispatch({
          type: 'PRICE:ADD_PRODUCT_TO_PRICE:SUCCESS',
          payload: { products }
        })
      )
      .catch(({ errors }) => {
        dispatch({
          type: 'PRICE:ADD_PRODUCT_TO_PRICE:FAILURE',
          payload: { errors }
        });
        console.error(errors);

        return Promise.reject();
      });
  };

const getCostsOfProducts = (state) => {
  const selectedProducts = state.getIn(['prices', 'listOfBaseCost']).toJS();
  return Object.keys(selectedProducts).map((id) => ({
    id: Number(id),
    cost: selectedProducts[id]
  }));
};

export const addAllProductsToPrice =
  (priceId, rate) => (dispatch, getState) => {
    const products = getCostsOfProducts(getState());

    dispatch({ type: 'PRICE:ADD_ALL_PRODUCT_TO_PRICE:REQUEST' });
    dispatch(setInfoMessage({ key: 'change_process_in_progress' }));

    return fetch(`/api/prices/${priceId}/products/add/all`, {
      credentials: 'include',
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ products, rate })
    })
      .then((res) =>
        res.json().then((json) =>
          // eslint-disable-next-line prefer-promise-reject-errors
          res.status < 400
            ? Promise.resolve(json)
            : Promise.reject({ errors: json.errors, status: res.status })
        )
      )
      .then(() => {
        dispatch(setInfoMessage());
        dispatch({
          type: 'PRICE:ADD_ALL_PRODUCT_TO_PRICE:SUCCESS'
        });
      })
      .catch(({ errors }) => {
        dispatch({
          type: 'PRICE:ADD_ALL_PRODUCT_TO_PRICE:FAILURE',
          payload: { errors }
        });
        console.error(errors);

        return Promise.reject();
      });
  };

export const publishPrice = (priceId) => async (dispatch) => {
  dispatch({ type: 'PRICE:PUBLISH:REQUEST', payload: { priceId } });

  return fetch(`/api/prices/${priceId}/publish`, {
    credentials: 'include',
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ priceId })
  })
    .then((res) =>
      res.json().then((json) =>
        // eslint-disable-next-line prefer-promise-reject-errors
        res.status < 400
          ? Promise.resolve(json)
          : Promise.reject({ errors: json.errors, status: res.status })
      )
    )
    .then((item) =>
      dispatch({
        type: 'PRICE:PUBLISH:SUCCESS',
        payload: { item }
      })
    )
    .catch(({ errors }) => {
      dispatch({ type: 'PRICE:PUBLISH:FAILURE', payload: { errors } });
      console.error(errors);
      return Promise.reject();
    });
};

export const setAdditionAttrsFilter =
  (priceId, attr, params, published = false) =>
  async (dispatch) => {
    dispatch({ type: 'PRICE:SET_FILTER:REQUEST' });

    return fetch(`/api/published-prices/${priceId}/filter`, {
      credentials: 'include',
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ attr, published, ...params })
    })
      .then((res) =>
        res.json().then((json) =>
          // eslint-disable-next-line prefer-promise-reject-errors
          res.status < 400
            ? Promise.resolve(json)
            : Promise.reject({ errors: json.errors, status: res.status })
        )
      )
      .then(({ attrs, mode }) =>
        dispatch({
          type: 'PRICE:SET_FILTER:SUCCESS',
          payload: { attrs, mode }
        })
      )
      .catch(({ errors }) => {
        dispatch({ type: 'PRICE:SET_FILTER:FAILURE', payload: { errors } });
        console.error(errors);
        return Promise.reject();
      });
  };

export const setComplexAttrFilter =
  (priceId, attributes, params) => async (dispatch) => {
    dispatch({ type: 'PRICE:SET_FILTER:REQUEST' });

    return fetch(`/api/published-prices/${priceId}/complex_filter`, {
      credentials: 'include',
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ attributes, ...params })
    })
      .then((res) =>
        res.json().then((json) =>
          // eslint-disable-next-line prefer-promise-reject-errors
          res.status < 400
            ? Promise.resolve(json)
            : Promise.reject({ errors: json.errors, status: res.status })
        )
      )
      .then(({ complexAttrs }) =>
        dispatch({
          type: 'PRICE:SET_COMPLEX_FILTER:SUCCESS',
          payload: { complexAttrs }
        })
      )
      .catch(({ errors }) => {
        dispatch({
          type: 'PRICE:SET_COMPLEX_FILTER:FAILURE',
          payload: { errors }
        });
        console.error(errors);
        return Promise.reject();
      });
  };

export const setCategoryAttrFilter =
  (priceId, attributes, params) => async (dispatch) => {
    dispatch({ type: 'PRICE:SET_CATEGORY_FILTER:REQUEST' });

    return fetch(`/api/published-prices/${priceId}/category_filter`, {
      credentials: 'include',
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ attributes, ...params })
    })
      .then((res) =>
        res.json().then((json) =>
          // eslint-disable-next-line prefer-promise-reject-errors
          res.status < 400
            ? Promise.resolve(json)
            : Promise.reject({ errors: json.errors, status: res.status })
        )
      )
      .then(({ categoriesFilter }) =>
        dispatch({
          type: 'PRICE:SET_CATEGORY_FILTER:SUCCESS',
          payload: { categoriesFilter }
        })
      )
      .catch(({ errors }) => {
        dispatch({
          type: 'PRICE:SET_CATEGORY_FILTER:FAILURE',
          payload: { errors }
        });
        console.error(errors);
        return Promise.reject();
      });
  };

export const getUserPriceAttrs = (priceId) => async (dispatch) => {
  dispatch({ type: 'PRICE:GET_ATTR_FILTER:REQUEST' });

  return fetch(`/api/published-prices/${priceId}/filter`, {
    credentials: 'include',
    method: 'GET'
  })
    .then((res) =>
      res.json().then((json) =>
        // eslint-disable-next-line prefer-promise-reject-errors
        res.status < 400
          ? Promise.resolve(json)
          : Promise.reject({ errors: json.errors, status: res.status })
      )
    )
    .then((attrs) =>
      dispatch({
        type: 'PRICE:GET_ATTR_FILTER:SUCCESS',
        payload: attrs
      })
    )
    .catch(({ errors }) => {
      dispatch({ type: 'PRICE:GET_ATTR_FILTER:FAILURE', payload: { errors } });
      console.error(errors);
      return Promise.reject();
    });
};

export const exportPublicPrice = (priceId, type) => (dispatch) => {
  dispatch({ type: 'ORDER:ORDER_EXCEL:REQUEST' });
  window.open(`/api/prices/${priceId}/export/${type}`, '_self');
};

export const checkPublishedPrice = (priceId) => (dispatch) => {
  dispatch({ type: 'PRICE:CHECK_PUBLISHED:REQUEST' });

  return fetch(`/api/prices/checkPublished/${priceId}`, {
    credentials: 'include'
  })
    .then((res) => res.json())
    .then(({ isPublished }) =>
      dispatch({
        type: 'PRICE:CHECK_PUBLISHED:SUCCESS',
        payload: { priceId, isPublished }
      })
    )
    .catch(({ errors }) => {
      dispatch({ type: 'PRICE:CHECK_PUBLISHED:FAILURE', payload: { errors } });
      console.error(errors);
      return Promise.reject();
    });
};

export const getBaidgePriceStatuses = () => (dispatch) => {
  dispatch({ type: 'PRICE:GET_STATUSES:REQUEST' });

  return fetch('/api/prices/statuses', { credentials: 'include' })
    .then((res) => res.json())
    .then(({ statuses }) =>
      dispatch({
        type: 'PRICE:GET_STATUSES:SUCCESS',
        payload: { statuses }
      })
    )
    .catch(({ errors }) => {
      dispatch({ type: 'PRICE:GET_STATUSES:FAILURE', payload: { errors } });
      console.error(errors);
      return Promise.reject();
    });
};

export const setStatusViewed = (priceId) => (dispatch) => {
  dispatch({ type: 'PRICE:SET_STATUS_VIEWED:REQUEST' });

  return fetch('/api/prices/baidge_status', {
    credentials: 'include',
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ price: priceId, status: 'viewed' })
  })
    .then((res) =>
      res.json().then((json) =>
        // eslint-disable-next-line prefer-promise-reject-errors
        res.status < 400
          ? Promise.resolve(json)
          : Promise.reject({ errors: json.errors, status: res.status })
      )
    )
    .then(() =>
      dispatch({
        type: 'PRICE:SET_STATUS_VIEWED:SUCCESS',
        payload: { priceId, status: 'viewed' }
      })
    )
    .catch(({ errors }) => {
      dispatch({
        type: 'PRICE:SET_STATUS_VIEWED:FAILURE',
        payload: { errors }
      });
      console.error(errors);
      return Promise.reject();
    });
};

export const importExcelToPrice = (priceId, body, type) => (dispatch) => {
  dispatch({ type: 'PRICE:IMPORT_EXCEL:REQUEST', payload: { priceId } });

  return fetch(`/api/import/price/${priceId}/${type}`, {
    credentials: 'include',
    method: 'POST',
    body
  })
    .then((res) =>
      res.json().then((json) =>
        res.status < 400
          ? Promise.resolve({
              data: json.data,
              errorsList: json.errors.map((error) => error.message) || []
            })
          : // eslint-disable-next-line prefer-promise-reject-errors
            Promise.reject({
              errorsList: json.errors.map((error) => error.message) || [],
              status: res.status
            })
      )
    )
    .then(({ errorsList }) => {
      const errors = errorsList.filter((err) => err.type === 'critical');
      const warnings = errorsList.filter((err) => err.type === 'uncritical');
      const currentLocation = getCurrentLocation();
      const isImportLocation =
        currentLocation === `/prices/import/${priceId}` ||
        currentLocation === `/prices/import/${priceId}/` ||
        currentLocation === '/prices/import';
      const linkParams = {
        linkUrl: `/prices/import/${priceId}`,
        linkKey: 'Move to finish'
      };
      dispatch({
        type: 'PRICE:IMPORT_EXCEL:SUCCESS',
        payload: { priceId, errors, warnings }
      });
      if (!isImportLocation) {
        if (!warnings.length) {
          dispatch(
            setSuccessMessage({
              key: 'Download complete successfully',
              ...(!isImportLocation ? linkParams : {})
            })
          );
        } else {
          dispatch(
            setWarningMessage({
              key: 'Price upload finished with warnings',
              ...(!isImportLocation ? linkParams : {})
            })
          );
        }
      }
    })
    .catch(({ errorsList = [] }) => {
      const errors = errorsList.filter((err) => err.type === 'critical');
      const warnings = errorsList.filter((err) => err.type === 'uncritical');
      // const currentLocation = getCurrentLocation();
      // const isImportLocation = currentLocation === `/prices/import/${priceId}` || currentLocation === '/prices/import';
      // const linkParams = {
      //   linkUrl: `/prices/import/${priceId}`,
      //   linkKey: 'Move to finish'
      // };
      dispatch({
        type: 'PRICE:IMPORT_EXCEL:FAILURE',
        payload: { priceId, errors, warnings }
      });
      // dispatch(setErrorMessage(Object.assign({
      //   key: 'Price cant be upload',
      // }, (!isImportLocation) ? linkParams : {})));
    });
};

export const addPriceFeatures = (priceId, features) => (dispatch) =>
  fetch(`/api/prices/${priceId}/feature`, {
    credentials: 'include',
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ features })
  })
    .then((res) => res.json())
    .then(() => dispatch({ type: 'PRICE:ADD_FEATURES:SUCCESS' }));

export const setFeatureDeleted = (name, unit, deleted) => (dispatch) =>
  dispatch({
    type: 'PRICE:REMOVE_FEATURE:SUCCESS',
    payload: { name, unit, deleted }
  });

export const removePriceFeature = (priceId, name, unit) => (dispatch) =>
  fetch(`/api/prices/${priceId}/feature`, {
    credentials: 'include',
    method: 'DELETE',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ name, unit })
  })
    .then((res) => res.json())
    .then((attr) =>
      dispatch({ type: 'PRICE:REMOVE_FEATURE:SUCCESS', payload: attr })
    );

export const generateAccessLink = (priceId) => (dispatch) =>
  fetch(`/api/prices/${priceId}/generate_link`, {
    credentials: 'include',
    method: 'POST',
    headers: { 'Content-Type': 'application/json' }
  })
    .then((res) => res.json())
    .then(({ accessToken }) =>
      dispatch({
        type: 'PRICE:GENERATE_ACCESS_LINK:SUCCESS',
        payload: { accessToken }
      })
    );

export const setViewMode = (mode) => (dispatch) =>
  fetch('/api/published-prices/view_mode', {
    credentials: 'include',
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ mode, table: 'price' })
  })
    .then((res) => res.json())
    .then(() =>
      dispatch({
        type: 'PRICE:SET_VIEW_MODE:SUCCESS',
        payload: { table: 'price', mode }
      })
    );

export const turnAccessByLink = (priceId) => (dispatch) =>
  fetch(`/api/prices/${priceId}/turn_access`, {
    credentials: 'include',
    method: 'POST',
    headers: { 'Content-Type': 'application/json' }
  })
    .then((res) => res.json())
    .then(({ accessByLink, accessToken }) =>
      dispatch({
        type: 'PRICE:TURN_ACCESS_BY_LINK:SUCCESS',
        payload: { accessByLink, accessToken }
      })
    );

export const turnAttrFilter = (attr, params, allAttrs) => ({
  type: 'PRICE:TURN_ATTR_FILTER',
  payload: { attr, params, allAttrs }
});
export const turnComplexAttrFilter = (name, unit, params, allAttrs) => ({
  type: 'PRICE:TURN_ATTR_COMPLEX_FILTER',
  payload: { name, unit, params, allAttrs }
});
export const turnCategoryAttrFilter = (attr, params, allAttrs) => ({
  type: 'PRICE:TURN_ATTR_CATEGORY_FILTER',
  payload: { attr, params, allAttrs }
});

export const getRBKRate = (priceId, currency) => (dispatch) => {
  dispatch({ type: 'PRICE:GET_RBK_RATE:REQUEST' });

  return fetch(`/api/prices/${priceId}/rbk_rate?currency=${currency}`, {
    credentials: 'include',
    method: 'GET'
  })
    .then((res) => res.json())
    .then((rate) => {
      dispatch({ type: 'PRICE:GET_RBK_RATE:SUCCESS', payload: rate });
      return rate.rate;
    })
    .catch((res) => {
      dispatch({
        type: 'PRICE:GET_RBK_RATE:FAILURE',
        payload: { error: res.error }
      });
      console.error(res);
    });
};

export const revertCurrency = (priceId) => (dispatch) => {
  dispatch({ type: 'PRICE:REVERT_CURRENCY:REQUEST' });

  return fetch(`/api/prices/${priceId}/revert_currency`, {
    credentials: 'include',
    method: 'POST',
    headers: { 'Content-Type': 'application/json' }
  })
    .then((res) => res.json())
    .then((price) => {
      dispatch({ type: 'PRICE:UPDATE_PRICES:SUCCESS', payload: price });
      dispatch({ type: 'PRICE:GET_PRICE:SUCCESS', payload: { item: price } });
    })
    .catch((res) => {
      dispatch({
        type: 'PRICE:REVERT_CURRENCY:FAILURE',
        payload: { error: res.error }
      });
      console.error(res);
    });
};

export const importExcelToOrder = (priceId, body, id) => (dispatch) => {
  dispatch({ type: 'ORDER:IMPORT_EXCEL:REQUEST', payload: { priceId } });

  return fetch(`/api/import/order/${priceId}`, {
    credentials: 'include',
    method: 'POST',
    body
  })
    .then((res) =>
      res.json().then((json) =>
        res.status < 400
          ? Promise.resolve({
              data: json.data,
              errorsList: json.errors.map((error) => error.message) || [],
              orderId: json.orderId
            })
          : // eslint-disable-next-line prefer-promise-reject-errors
            Promise.reject({
              errorsList: json.errors.map((error) => error.message) || [],
              status: res.status
            })
      )
    )
    .then(({ errorsList, orderId }) => {
      const errors = errorsList.filter((err) => err.type === 'critical');
      const warnings = errorsList.filter((err) => err.type === 'uncritical');
      const currentLocation = getCurrentLocation();
      const isImportLocation =
        currentLocation === `/prices-suppliers/${id}/import`;
      const linkParams = {
        linkUrl: `/prices-suppliers/${id}/import`,
        linkKey: 'Move to finish'
      };
      dispatch({
        type: 'ORDER:IMPORT_EXCEL:SUCCESS',
        payload: { priceId: id, orderId, errors, warnings }
      });
      if (!isImportLocation) {
        if (!warnings.length) {
          dispatch(
            setSuccessMessage({
              key: 'Download complete successfully',
              ...(!isImportLocation ? linkParams : {})
            })
          );
        } else {
          dispatch(
            setWarningMessage({
              key: 'Price upload finished with warnings',
              ...(!isImportLocation ? linkParams : {})
            })
          );
        }
      }
    })
    .catch(({ errorsList = [] }) => {
      const errors = errorsList.filter((err) => err.type === 'critical');
      const warnings = errorsList.filter((err) => err.type === 'uncritical');
      // const currentLocation = getCurrentLocation();
      // const isImportLocation = currentLocation === `/prices-suppliers/${id}/import`;
      // const linkParams = {
      //   linkUrl: `/prices-suppliers/${id}/import`,
      //   linkKey: 'Move to finish'
      // };
      dispatch({
        type: 'ORDER:IMPORT_EXCEL:FAILURE',
        payload: { priceId: id, errors, warnings }
      });
      // dispatch(setErrorMessage(Object.assign({
      //   key: 'Price cant be upload',
      // }, (!isImportLocation) ? linkParams : {})));
    });
};

export const importExcelToSupplierOrder =
  (priceId, customerId, body) => (dispatch) =>
    fetch(`/api/import/order/${priceId}/${customerId}`, {
      credentials: 'include',
      method: 'POST',
      body
    })
      .then((res) =>
        res.json().then((json) =>
          res.status < 400
            ? Promise.resolve({
                data: json.data,
                errorsList: json.errors.map((error) => error.message) || [],
                orderId: json.orderId
              })
            : // eslint-disable-next-line prefer-promise-reject-errors
              Promise.reject({
                errorsList: json.errors.map((error) => error.message) || [],
                status: res.status
              })
        )
      )
      .then(({ errorsList, orderId }) => {
        const errors = errorsList.filter((err) => err.type === 'critical');
        const warnings = errorsList.filter((err) => err.type === 'uncritical');
        const currentLocation = getCurrentLocation();
        const isImportLocation = currentLocation === '/orders-customers/import';
        const linkParams = {
          linkUrl: '/orders-customers/import',
          linkKey: 'Move to finish'
        };
        dispatch({
          type: 'ORDER:IMPORT_EXCEL:SUCCESS',
          payload: { priceId, orderId, errors, warnings }
        });
        if (!isImportLocation) {
          if (!warnings.length) {
            dispatch(
              setSuccessMessage({
                key: 'File load success',
                ...(!isImportLocation ? linkParams : {})
              })
            );
          } else {
            dispatch(
              setWarningMessage({
                key: 'Price upload finished with warnings',
                ...(!isImportLocation ? linkParams : {})
              })
            );
          }
        }
      })
      .catch(({ errorsList = [] }) => {
        const errors = errorsList.filter((err) => err.type === 'critical');
        const warnings = errorsList.filter((err) => err.type === 'uncritical');
        dispatch({
          type: 'ORDER:IMPORT_EXCEL:FAILURE',
          payload: { priceId, errors, warnings }
        });
      });

/* eslint-disable */
/**
 * @param name
 * @param value
 */
export function setFilterField(name, value) {
  return {
    type: 'PUBLIC_PRICE:SET_FILTER_FIELD',
    payload: { name, value }
  };
}

/**
 * @param filters
 */
export function applyFilter(filters) {
  return {
    type: 'PUBLIC_PRICE:APPLY_FILTER',
    payload: { filters }
  };
}

/**
 * @param filters
 */
export function clearFilter() {
  return {
    type: 'PUBLIC_PRICE:CLEAR_FILTER'
  };
}
/* eslint-enable */

export const getUnits = (priceId) => (dispatch) =>
  fetch(`/api/prices/${priceId}/units`, {
    credentials: 'include',
    method: 'GET'
  })
    .then((res) => res.json())
    .then((units) => {
      dispatch({ type: 'PRICE:GET_UNITS:SUCCESS', payload: { units } });
    })
    .catch((res) => {
      dispatch({
        type: 'PRICE:GET_UNITS:FAILURE',
        payload: { error: res.error }
      });
      console.error(res);
    });

export const getSharePriceUnits = (priceId, token) => (dispatch) =>
  fetch(`/api/published-prices/${priceId}/share/${token}/units`, {
    credentials: 'include',
    method: 'GET'
  })
    .then((res) => res.json())
    .then((units) => {
      dispatch({ type: 'PRICE:GET_UNITS:SUCCESS', payload: { units } });
    })
    .catch((res) => {
      dispatch({
        type: 'PRICE:GET_UNITS:FAILURE',
        payload: { error: res.error }
      });
      console.error(res);
    });

export const notifyCustomers =
  ({ priceId, nameSupplier, title, text }) =>
  (dispatch) => {
    dispatch({
      type: 'PRICE:NOTIFY_CUSTOMERS:REQUEST',
      payload: { status: 'pending' }
    });

    return (
      fetch(`/api/notification/price/${priceId}/notify`, {
        credentials: 'include',
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ priceId, nameSupplier, title, text })
      })
        // .then(res => res.json())
        .then((data) => {
          if (data.status === 200) {
            dispatch({
              type: 'PRICE:NOTIFY_CUSTOMERS:SUCCESS',
              payload: { status: 'success' }
            });
            return Promise.resolve({});
          }

          dispatch({
            type: 'PRICE:NOTIFY_CUSTOMERS:FAILURE',
            payload: { status: 'error' }
          });
          // eslint-disable-next-line prefer-promise-reject-errors
          return Promise.reject({ status: data.status });
        })
        .catch((errors) => {
          dispatch({
            type: 'PRICE:NOTIFY_CUSTOMERS:FAILURE',
            payload: { status: 'error' }
          });
          console.error(errors);
          return Promise.reject(errors);
        })
    );
  };

export const updateSuppliersPrices = (payload) => (dispatch) =>
  dispatch({ type: 'PRICES_SUPPLIERS:UPDATE_DATA', payload });
