const { makePolicy } = require('../../data/policy');

// Section name
const ORDERS_TO_SUPPLIERS = 'ORDERS_TO_SUPPLIERS';

// Use cases
const VIEW_ORDERS_WITHOUT_RESTRICTIONS = 'VIEW_ORDERS_WITHOUT_RESTRICTIONS';
const VIEW_ORDERS_WITH_DIFF_ACCESS = 'VIEW_ORDERS_WITH_DIFF_ACCESS';
const ASSIGN_RESPONSIBLE = 'ASSIGN_RESPONSIBLE';
const VIEW_LIST_ORDERS = 'VIEW_LIST_ORDERS';
const VIEW_ORDER = 'VIEW_ORDER';
const ADD_COMMENT = 'ADD_COMMENT';
const ADD_DOCUMENT = 'ADD_DOCUMENT';
const CHANGE_ORDER_STATUS = 'CHANGE_ORDER_STATUS';
const CREATE_COPY = 'CREATE_COPY';
const EXPORT_PRODUCTS = 'EXPORT_PRODUCTS';
const DELETE_ORDER = 'DELETE_ORDER';

const settings = [
  makePolicy(ORDERS_TO_SUPPLIERS, VIEW_ORDERS_WITHOUT_RESTRICTIONS, {
    reader: false,
    writer: false,
    advanced: true
  }),
  makePolicy(ORDERS_TO_SUPPLIERS, VIEW_ORDERS_WITH_DIFF_ACCESS, {
    reader: true,
    writer: true,
    advanced: true
  }),
  makePolicy(ORDERS_TO_SUPPLIERS, ASSIGN_RESPONSIBLE, {
    reader: false,
    writer: false,
    advanced: true
  }),
  makePolicy(ORDERS_TO_SUPPLIERS, VIEW_LIST_ORDERS, {
    reader: true,
    writer: true,
    advanced: true
  }),
  makePolicy(ORDERS_TO_SUPPLIERS, VIEW_ORDER, {
    reader: true,
    writer: true,
    advanced: true
  }),
  makePolicy(ORDERS_TO_SUPPLIERS, ADD_COMMENT, {
    reader: false,
    writer: true,
    advanced: true
  }),
  makePolicy(ORDERS_TO_SUPPLIERS, ADD_DOCUMENT, {
    reader: false,
    writer: true,
    advanced: true
  }),
  makePolicy(ORDERS_TO_SUPPLIERS, CHANGE_ORDER_STATUS, {
    reader: false,
    writer: true,
    advanced: true
  }),
  makePolicy(ORDERS_TO_SUPPLIERS, CREATE_COPY, {
    reader: false,
    writer: true,
    advanced: true
  }),
  makePolicy(ORDERS_TO_SUPPLIERS, EXPORT_PRODUCTS, {
    reader: false,
    writer: true,
    advanced: true
  }),
  makePolicy(ORDERS_TO_SUPPLIERS, DELETE_ORDER, {
    reader: false,
    writer: false,
    advanced: true
  })
];

module.exports = {
  ORDERS_TO_SUPPLIERS,
  VIEW_ORDERS_WITHOUT_RESTRICTIONS,
  VIEW_ORDERS_WITH_DIFF_ACCESS,
  ASSIGN_RESPONSIBLE,
  VIEW_LIST_ORDERS,
  VIEW_ORDER,
  ADD_COMMENT,
  ADD_DOCUMENT,
  CHANGE_ORDER_STATUS,
  CREATE_COPY,
  EXPORT_PRODUCTS,
  DELETE_ORDER,
  settings
};
