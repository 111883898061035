import React from 'react';
import { Button, Typography } from '@link/react-components';
import { useSelector } from 'react-redux';

import { useNavigate } from '@hooks';
import MessengerOther from '../../../components/Layouts/MessengerOther';
import ContainersDefault from '../../../components/Containers/ContainersDefault';
import { useTranslate } from '../../../TranslateProvider';
import { Panel } from './components/panel';
import styles from './View.module.css';
import * as getters from '../../../components/ChatWidget/modules/constructor/getters';

export const ConstructorView = () => {
  const t = useTranslate();
  const { list } = useSelector(getters.getConstructorState);

  const navigate = useNavigate();

  return (
    <MessengerOther
      theme="gray"
      title={t('constructor.path')}
      breadcrumbs={[
        {
          element: (
            <Typography variant="h1" className={styles.title}>
              {t('constructor.path')}
            </Typography>
          )
        }
      ]}
      panelBtns={
        list?.length > 0
          ? [
              {
                element: (
                  <Button
                    onClick={() =>
                      navigate('/constructor/create', { replace: true })
                    }>
                    {t('constructor.newPathBtn')}
                  </Button>
                )
              }
            ]
          : []
      }
      content={
        <ContainersDefault style={{ height: '100%' }}>
          <Panel />
        </ContainersDefault>
      }
    />
  );
};
