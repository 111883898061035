import React from 'react';
import Button from '../Button';
import RoundPlusSVG from '../../../tasks-manager/assets/icons/RoundPlus';
import './style.css';

const AddButton = ({
  variant,
  className,
  disabled = false,
  onClick,
  children
}) => (
  <div className="add-button">
    <Button
      variant={variant}
      className={className}
      onClick={onClick}
      disabled={disabled}>
      <RoundPlusSVG />
      {children}
    </Button>
  </div>
);

export default AddButton;
