const { mergeRight, isNil } = require('ramda');
const { defStruct } = require('../../lib');

/**
 * @typedef Policy
 */
const { makePolicy, getSection, getUseCase, getRoleSettings, isPolicy } =
  defStruct('Policy', ['section', 'useCase', 'roleSettings']);

const defaultRoles = (roleSettings) =>
  mergeRight({ no: false, admin: true }, roleSettings);
/**
 * Creates a policy for access by roles
 *
 * @example I want only the administrator and advanced user to have permission to delete the directory
 * I set up a policy
 * makePolicy('catalog', 'delete-catalog', { reader: false, writer: false, advanced: true });
 * Now permission for this operation have only user with roles advanced and admin
 *
 * @param {string} section
 * @param {string} useCase
 * @param {object} roleSettings
 * @param {boolean} roleSettings.reader
 * @param {boolean} roleSettings.writer
 * @param {boolean} roleSettings.advanced
 * @param {boolean} [roleSettings.no=false]
 * @param {boolean} [roleSettings.admin=true]
 * @returns {Policy}
 */
exports.makePolicy = (section, useCase, roleSettings) =>
  makePolicy(section, useCase, defaultRoles(roleSettings));

/**
 * Gives a name of section
 *
 * @param {Policy} policy
 * @returns {string}
 */
exports.getSection = (policy) => getSection(policy);

/**
 * Gives a name of use case
 *
 * @param {Policy} policy
 * @returns {string}
 */
exports.getUseCase = (policy) => getUseCase(policy);

/**
 * Checks if is policy
 *
 * @param {*} a
 * @returns {boolean}
 */
exports.isPolicy = (a) => isPolicy(a);

/**
 * Check if role have permission
 *
 * @param {string} role
 * @param {Policy} policy
 * @returns {boolean}
 */
exports.havePermission = (role, policy) => {
  const rolePermission = getRoleSettings(policy)[role];
  return isNil(rolePermission) ? false : rolePermission;
};
