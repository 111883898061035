import React, { useState, useRef, useEffect } from 'react';
import { translate } from 'react-i18next';
import { Overlay, Popover } from 'react-bootstrap';
import useOutsideClick from '../ChatWidget/hooks/outsideClick';

const Hint = ({ t, hint }) => {
  if (hint instanceof Object && React.isValidElement(hint)) {
    return hint;
  }

  return t(hint);
};

const CustomOverlay = ({
  t,
  isUseOverlay = true,
  popoverId = '',
  hint,
  placement = 'top',
  delayBeforeClose = 3000,
  children
}) => {
  if (!isUseOverlay) return children;

  const containerRef = useRef(null);
  const [isOpen, setOpen] = useState(false);
  const [target, setTarget] = useState(null);

  const handleClick = (event) => {
    setOpen(!isOpen);
    setTarget(event.target);
  };

  const hide = () => setOpen(false);

  useOutsideClick(containerRef, hide);

  useEffect(() => {
    if (isOpen) setTimeout(hide, delayBeforeClose);
  }, [isOpen]);

  return (
    <div ref={containerRef}>
      <div onClick={handleClick}>{children}</div>

      <Overlay
        show={isOpen}
        target={target}
        placement={placement}
        container={this}>
        <Popover id={popoverId}>
          <Hint t={t} hint={hint} />
        </Popover>
      </Overlay>
    </div>
  );
};

export default translate()(CustomOverlay);
