const { makePolicy } = require('../../data/policy');

// Section name
const BILLING = 'BILLING';

// Use cases
const VIEW_DETAILS = 'VIEW_DETAILS';
const CHANGE_TARIFF = 'CHANGE_TARIFF';
const VIEW_HISTORY = 'VIEW_HISTORY';

const settings = [
  makePolicy(BILLING, VIEW_DETAILS, {
    reader: true,
    writer: true,
    advanced: true
  }),
  makePolicy(BILLING, CHANGE_TARIFF, {
    reader: false,
    writer: false,
    advanced: true
  }),
  makePolicy(BILLING, VIEW_HISTORY, {
    reader: false,
    writer: false,
    advanced: true
  })
];

module.exports = {
  BILLING,
  VIEW_DETAILS,
  CHANGE_TARIFF,
  VIEW_HISTORY,
  settings
};
