import React from 'react';
import { useActions } from '@hooks';
import { Button, Icon } from '@link/react-components';
import { useTranslate } from '../../../../TranslateProvider';
import * as partnersCases from '../../../../components/ChatWidget/modules/companyPartners/useCases';
import styles from './InvitePartnerButton.module.css';

export function InvitePartnerButton({ className }) {
  const t = useTranslate();

  const { showInviteModal } = useActions(partnersCases);
  return (
    <Button
      className={className}
      onClick={showInviteModal}
      before={
        <Icon iconName="plus" className={styles.icon} width={18} height={18} />
      }>
      {t('modules.company.invitePartner')}
    </Button>
  );
}
