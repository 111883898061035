import React from 'react';
import './style.styl';

const Loader = ({ theme, style = {} }) => (
  <div className={`loader ${theme ? `loader_${theme}` : ''}`} style={style}>
    <div className="loader-dots-wrap">
      <div className="loader-dots">
        <div className={`loader-dot ${theme ? `loader-dot_${theme}` : ''}`} />
        <div className={`loader-dot ${theme ? `loader-dot_${theme}` : ''}`} />
        <div className={`loader-dot ${theme ? `loader-dot_${theme}` : ''}`} />
      </div>
    </div>
  </div>
);

export default Loader;
