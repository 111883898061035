import {
  show,
  hide,
  setRequest,
  setCategories,
  emptyRequest,
  emptyCategories
} from './redux-storage';
import customHistory from '../../../../customHistory';
import { requestCategories } from '../../../../storage/purchaseRequest.storage';
import { regGetRequest } from '../../../../action-creators/purchaseRequests';
import { getRequestId, isShowPRWPModal } from './data-type';
import { getUserLang } from '../../getter';

export const openResponse = (message) => async (dispatch) => {
  const cats = await requestCategories();
  dispatch(setCategories(cats));

  const request = await regGetRequest(message.requestId);
  dispatch(setRequest(request));

  dispatch(show());
};

export const closeResponse = () => (dispatch) => {
  dispatch(hide());
  dispatch(emptyRequest());
  dispatch(emptyCategories());
};

export const reloadReqByChangeLang = () => async (dispatch, getState) => {
  const state = getState();
  if (isShowPRWPModal(state)) {
    const requestId = getRequestId(state);
    const language = getUserLang(state);

    const request = await regGetRequest(requestId, language);
    dispatch(setRequest(request));
  }
};

export const goToRequest = () => async (dispatch, getState) => {
  customHistory.push(`/requests/all/${getRequestId(getState())}`);
  dispatch(closeResponse());
};
