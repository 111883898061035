import React from 'react';
import { translate } from 'react-i18next';

const CategoriesList = ({ t, categories, categoriesIds }) => {
  function getCategoryNameById(catId) {
    const category = categories.find((cat) => cat.id === catId);
    return category ? t(`purchaseRequestsCategories.${category.name}`) : '';
  }

  const categoriesNames = categoriesIds.map(getCategoryNameById).join(' / ');

  return <div>{categoriesNames}</div>;
};

export default translate()(CategoriesList);
