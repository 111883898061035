import {
  ADD_CARD_TO_STEP,
  ADD_USER_TO_CARD,
  CHANGE_BUILDER_NAME,
  CHANGE_USER_IS_EXECUTE_AUTOMATICALLY,
  CLEAR_CONSTRUCTOR,
  CREATE_STEP,
  DELETE_CARD_FROM_STEP,
  DELETE_STEP,
  ERROR_CHECKED,
  FETCH_CONSTRUCTOR,
  FETCH_CONSTRUCTOR_LIST,
  FETCH_USERS_LIST,
  REMOVE_USER_FROM_CARD,
  SAVE_BUILDER_PATH,
  DELETE_ROUTE,
  INIT_ROUTE_NAME,
  EXCHANGE_USER
} from './constants';
import { routeService } from '../../../../api/routeService';
import { RouteDTO } from '../../../../DTOs/routeDTO';
import { mapDTOToFrontEntity } from './mappers';

export const fetchCompanyRoutesList = () => async (dispatch) => {
  try {
    dispatch({ type: FETCH_CONSTRUCTOR_LIST.START });

    const response = await routeService.getCompanyRoutesList();

    dispatch({
      type: FETCH_CONSTRUCTOR_LIST.SUCCESS,
      payload: response.result
    });
  } catch (e) {
    dispatch({
      type: FETCH_CONSTRUCTOR_LIST.ERROR,
      payload: e
    });
  }
};

export const fetchUsersList = () => async (dispatch) => {
  try {
    dispatch({ type: FETCH_USERS_LIST.START });

    const response = await routeService.getExecutors();

    dispatch({ type: FETCH_USERS_LIST.SUCCESS, payload: response.result });
  } catch (e) {
    dispatch({
      type: FETCH_USERS_LIST.ERROR,
      payload: e
    });
  }
};

export const fetchConstructor = (routeId) => async (dispatch) => {
  try {
    dispatch({
      type: FETCH_CONSTRUCTOR.START
    });
    const route = await routeService
      .getRouteByRouteId(routeId)
      .then((res) => res.result);
    dispatch({
      type: FETCH_CONSTRUCTOR.SUCCESS,
      payload: mapDTOToFrontEntity(route)
    });
  } catch (e) {
    dispatch({
      type: FETCH_CONSTRUCTOR.ERROR,
      payload: e
    });
  }
};

export const changeBuilderName = (payload) => ({
  type: CHANGE_BUILDER_NAME,
  payload
});

export const addUserToCard = (payload) => ({
  type: ADD_USER_TO_CARD,
  payload
});

export const removeUserFromCard = (payload) => ({
  type: REMOVE_USER_FROM_CARD,
  payload
});

export const changeUserIsExecuteAutomatically = (payload) => ({
  type: CHANGE_USER_IS_EXECUTE_AUTOMATICALLY,
  payload
});
export const exchangeUser = (payload) => ({
  type: EXCHANGE_USER,
  payload
});

export const createStep = (payload) => ({
  type: CREATE_STEP,
  payload
});

export const addCardToStep = (payload) => ({
  type: ADD_CARD_TO_STEP,
  payload
});

// temp

export const saveBuilderPath = (builder) => async (dispatch) => {
  try {
    const fromBuilderToDTO = new RouteDTO(builder);
    let route;
    if (builder?.id) {
      route = await routeService
        .updateRoute({ route: fromBuilderToDTO, routeId: builder.id })
        .then((res) => res.result);
    } else {
      route = await routeService
        .saveRoute(fromBuilderToDTO)
        .then((res) => res.result);
    }

    // TODO Now this is do not save in redux after save on back wait task for it
    const savedRoute = mapDTOToFrontEntity(route);
    // console.log("savedRoute: ",savedRoute)
    dispatch({
      type: SAVE_BUILDER_PATH,
      payload: savedRoute
    });
  } catch (e) {
    console.error(e);
  }
};

export const deleteCardFromStep = (payload) => ({
  type: DELETE_CARD_FROM_STEP,
  payload
});

export const deleteStep = (payload) => ({
  type: DELETE_STEP,
  payload
});

export const clearConstructor = () => ({
  type: CLEAR_CONSTRUCTOR
});

export const errorChecked = () => ({
  type: ERROR_CHECKED
});

export const deleteRouteFromList = (payload) => ({
  type: DELETE_ROUTE,
  payload
});

export const initRouteName = (payload) => ({
  type: INIT_ROUTE_NAME,
  payload
});
