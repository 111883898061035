import fetch from 'isomorphic-fetch';
import { showModal, hideModal } from '../modal';
import { removeFromTable, addTableItems, updateTableItems } from '../table';

export const setPriceCategory = (priceCategory) => ({
  type: 'SET_PRICE_CATEGORY',
  priceCategory
});

export const trimPriceCategoryField = (field) => ({
  type: 'TRIM_PRICE_CATEGORY_FIELD',
  field
});

export const updatePriceCategoryField = (field, value) => ({
  type: 'UPDATE_PRICE_CATEGORY_FIELD',
  field,
  value
});

export const clearState = () => ({
  type: 'CLEAR_PRICE_CATEGORY_STATE'
});

export const createUploadBody = (priceCategory) => ({
  credentials: 'include',
  method: 'POST',
  headers: { 'Content-Type': 'application/json' },
  body: JSON.stringify({
    _id: priceCategory.get('_id') || null,
    name: priceCategory.get('name'),
    discount: priceCategory.get('discount'),
    minOrder: priceCategory.get('minOrder')
  })
});

export const updatePriceCategory = (priceCategory, catalogId, dispatch) => {
  fetch(
    `/api/catalogs/${catalogId}/priceCategories/${priceCategory.get('_id')}`,
    createUploadBody(priceCategory)
  )
    .then((response) => response.json())
    .then(() => {
      dispatch(updateTableItems([priceCategory], 'priceCategories'));
      dispatch(clearState());
      dispatch(hideModal());
    });
};

export const createPriceCategory = (priceCategory, catalogId, dispatch) => {
  fetch(
    `/api/catalogs/${catalogId}/priceCategories`,
    createUploadBody(priceCategory)
  )
    .then((response) => response.json())
    .then((result) => {
      dispatch(addTableItems([result], 'priceCategories'));
      dispatch(clearState());
      dispatch(hideModal());
    });
};

export const savePriceCategory = () => (dispatch, getState) => {
  const priceCategory = getState().getIn(['catalog', 'priceCategory']);
  const catalogId = getState().getIn(['catalog', 'item']).get('_id');
  if (priceCategory.get('_id')) {
    updatePriceCategory(priceCategory, catalogId, dispatch);
  } else {
    createPriceCategory(priceCategory, catalogId, dispatch);
  }
};

export const openPriceCategoryEditor = () => (dispatch, getState) => {
  const table = getState().getIn(['tables', 'priceCategories']);
  const selectedId = table.get('selected') && table.get('selected').first();
  const selectedPriceCategory = table
    .get('items')
    .find((value) => value.get('_id') === selectedId);
  if (selectedPriceCategory) {
    dispatch(setPriceCategory(selectedPriceCategory));
    dispatch(
      showModal('ADD_PRICE_CATEGORY', {
        captionKey: 'Edit price category',
        isCreate: false
      })
    );
  } else {
    dispatch(
      showModal('ADD_PRICE_CATEGORY', {
        captionKey: 'New price category',
        isCreate: true
      })
    );
  }
};

export const deletePriceCategory = () => (dispatch, getState) => {
  const catalogId = getState().getIn(['catalog', 'item']).get('_id');
  const selected = getState().getIn(['tables', 'priceCategories', 'selected']);
  if (selected.size === 0) return;
  Promise.all(
    selected.map(
      (priceCategoryId) =>
        new Promise((resolve) =>
          fetch(
            `/api/catalogs/${catalogId}/priceCategories/${priceCategoryId}`,
            {
              credentials: 'include',
              method: 'DELETE'
            }
          )
            .then(({ ok, status, statusText }) => {
              if (ok) {
                return resolve(
                  dispatch(removeFromTable(priceCategoryId, 'priceCategories'))
                );
              }
              return resolve(
                console.log(
                  'ERROR',
                  'PRICE CATEGORY DELETE',
                  status,
                  statusText
                )
              );
            })
            .catch((e) => {
              console.log(`Error while delete price categories: ${e}`);
              return resolve;
            })
        )
    )
  );
};
