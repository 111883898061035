import React, { Component } from 'react';
import PropTypes from 'prop-types';
import block from 'bem-cn-lite';
import { translate } from 'react-i18next';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  Modal,
  Row,
  Form,
  FormGroup,
  FormControl,
  ControlLabel,
  Button,
  HelpBlock
} from 'react-bootstrap';
import { withRouter } from '../../hoc/withRouter';

import { ModalContainer } from './elements';

import './styles/modal_theme_default.styl';

import { validateEmail } from '../../utils/validateField';
import getBrowserLanguage from '../../utils/languageDetector';

import * as modalActions from '../../action-creators/modal';
import * as serviceActions from '../../action-creators/services';
import * as orderActions from '../../action-creators/orders';
import * as userActions from '../../action-creators/user';

const b = block('modal-container');

class ModalSendOrderLogin extends Component {
  constructor(props) {
    super(props);
    this.inputProps = this.inputProps.bind(this);
    this.openModalOrderReg = this.openModalOrderReg.bind(this);
    this.state = {
      passwordPristine: true,
      emailPristine: true,
      password: '',
      email: '',
      lang: getBrowserLanguage(),
      error: ''
    };
  }

  getChildContext() {
    return { b };
  }

  getErrorInfo(fieldName) {
    return this.props.errors.get(fieldName);
  }

  asterisk() {
    // eslint-disable-line
    return <span style={{ color: 'red' }}>*</span>;
  }

  inputProps(field) {
    return {
      value: this.state[field],
      onChange: async (i) => {
        if (this.getErrorInfo(field)) {
          this.props.removeErrorByField(field);
        }
        if (field === 'email') {
          return this.setState({ [field]: i.target.value.trim() });
        }
        return this.setState({ [field]: i.target.value });
      }
    };
  }

  verifyEmail(email) {
    if (email.length) {
      return validateEmail(email);
    }
    return true;
  }

  showEmailError() {
    const { t } = this.props;
    if (this.state.error && this.state.error.type === 'email') {
      return (
        <HelpBlock id="emailErrorMessage" style={{ color: 'red' }}>
          {t(this.state.error.message)}
        </HelpBlock>
      );
    }
    if (!this.verifyEmail(this.state.email)) {
      return (
        <HelpBlock id="emailErrorMessage" style={{ color: 'red' }}>
          {t('Wrong email format')}
        </HelpBlock>
      );
    }
    if (!this.state.email && !this.state.emailPristine) {
      return (
        <HelpBlock className="pull-left">
          {t('Required fields empty')}
        </HelpBlock>
      );
    }
    return null;
  }

  async sendOrderAndLogin() {
    const {
      submitOrderToSupplier,
      saveOrderAndlogin,
      getCurrentUser,
      splitOrder = false,
      history,
      hideModalDialog,
      priceId,
      token,
      items,
      priceCategoryConditions,
      deliveryDate,
      combineOrdersStocks
    } = this.props;
    const { email, password } = this.state;
    const resp = await saveOrderAndlogin(
      email,
      password,
      priceId,
      token,
      items,
      priceCategoryConditions
    );
    if (resp.ok) {
      if (resp.priceOwner) {
        window.location = `${process.env.UNICAT_DEFAULT_SERVICE}/prices/${priceId}/products`;
      }
      await this.setState({ orderId: resp.orderId, error: '' });
      await getCurrentUser();
      await submitOrderToSupplier(
        resp.orderId,
        '',
        splitOrder,
        combineOrdersStocks,
        deliveryDate
      );
      hideModalDialog();
      history.push('/orders-company');
    } else if (resp.error) {
      this.setState({ error: resp.error });
    }
  }

  openModalOrderReg() {
    const { showModal, priceId, token } = this.props;
    showModal('SEND_ORDER_REG', { priceId, token });
  }

  render() {
    const { t, hideModalDialog } = this.props;
    return (
      <ModalContainer mods={{ theme: 'default' }}>
        <Modal id="createStock" onHide={() => hideModalDialog()} show>
          <Modal.Header>
            <Modal.Title>
              {t('Send order')}
              <span
                className="modal-header-cross pull-right"
                onClick={() => hideModalDialog()}
              />
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div style={{ marginTop: 10, fontSize: 12 }}>
              {t('sendOrderLoginText')}
            </div>
            <Form>
              <Row>
                <FormGroup
                  controlId="email"
                  validationState={this.showEmailError() ? 'error' : null}
                  style={{ overflow: 'hidden' }}>
                  <ControlLabel>
                    {`${t('Email')} `}
                    {this.asterisk()}
                  </ControlLabel>
                  <h6 className="pull-right" style={{ marginTop: 5 }}>
                    <small>
                      {this.state.email.length}
                      /60
                    </small>
                  </h6>
                  <FormControl
                    type="text"
                    {...this.inputProps('email')}
                    maxLength="60"
                    disabled={this.state.codeAccepted}
                    onKeyDown={() => {
                      if (this.state.emailPristine) {
                        this.setState({ emailPristine: false });
                      }
                      if (
                        this.state.error &&
                        this.state.error.type === 'email'
                      ) {
                        this.setState({ error: '' });
                      }
                    }}
                  />
                  {this.showEmailError()}
                </FormGroup>
              </Row>
              <Row>
                <FormGroup
                  controlId="password"
                  validationState={
                    !this.state.password.length && !this.state.passwordPristine
                      ? 'error'
                      : null
                  }
                  style={{ overflow: 'hidden' }}>
                  <ControlLabel>
                    {`${t('Password')} `}
                    {this.asterisk()}
                  </ControlLabel>
                  <h6 className="pull-right" style={{ marginTop: 5 }}>
                    <small>
                      {this.state.password.length}
                      /60
                    </small>
                  </h6>
                  <FormControl
                    type="password"
                    {...this.inputProps('password')}
                    minLength="8"
                    maxLength="60"
                    disabled={this.state.codeAccepted}
                    onKeyDown={() => {
                      if (this.state.passwordPristine) {
                        this.setState({ passwordPristine: false, error: '' });
                      }
                      if (
                        this.state.error &&
                        this.state.error.type === 'password'
                      ) {
                        this.setState({ error: '' });
                      }
                    }}
                  />
                  {!this.state.password && !this.state.passwordPristine && (
                    <HelpBlock className="pull-left">
                      {t('Required fields empty')}
                    </HelpBlock>
                  )}
                  {this.state.error && this.state.error.type === 'password' && (
                    <HelpBlock className="pull-left">
                      {t(this.state.error.message)}
                    </HelpBlock>
                  )}
                </FormGroup>
              </Row>
            </Form>
            {this.asterisk()}
            {` ${t('Required fields')}`}
            <div style={{ marginTop: 10, fontSize: 12 }}>
              <a
                onClick={() => {
                  hideModalDialog();
                  this.openModalOrderReg();
                }}>
                {t('back to registration')}
              </a>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              id="cancel"
              className="pull-left"
              onClick={() => {
                hideModalDialog();
              }}>
              {t('Cancel')}
            </Button>
            <Button
              id="saveCharacteristicButton"
              bsStyle="primary"
              disabled={
                !this.state.email || !this.state.password || this.state.error
              }
              onClick={() => this.sendOrderAndLogin()}>
              {t('Send order')}
            </Button>
          </Modal.Footer>
        </Modal>
      </ModalContainer>
    );
  }
}

ModalSendOrderLogin.propTypes = {
  t: PropTypes.func.isRequired
};

ModalSendOrderLogin.childContextTypes = {
  b: PropTypes.func
};

export default withRouter(
  connect(
    (state) => ({
      price: state.getIn(['publicPrices', 'price']),
      items: state.getIn(['ordersCombine', 'orderDraft', 'items']),
      priceCategoryConditions: state.getIn([
        'ordersCombine',
        'orderDraft',
        'priceCategoryConditions'
      ]),
      combineOrdersStocks: state.getIn([
        'ordersCombine',
        'orderDraft',
        'combineOrdersStocks'
      ]),
      errors: state.getIn(['orders', 'errors'])
    }),
    (dispatch) =>
      bindActionCreators(
        {
          showModal: modalActions.showModal,
          hideModalDialog: modalActions.hideModalDialog,
          sendCode: serviceActions.sendCode,
          verifyCode: serviceActions.verifyCode,
          getCurrentUser: userActions.getCurrentUser,
          submitOrderToSupplier: orderActions.submitOrderToSupplier,
          saveOrderAndlogin: serviceActions.saveOrderAndlogin
        },
        dispatch
      )
  )(translate(['ui'], { wait: true })(ModalSendOrderLogin))
);
