const { equals } = require('ramda');
const { defStruct } = require('../../lib');
const { isDateNull } = require('../dateOrNull');

/**
 * @typedef Order
 * @typedef {import('../dateOrNull')} DateOrNull
 * @typedef {import('../price')} Price
 */
const {
  makeOrder,
  getId,
  getNumber,
  getChangedStatusAt,
  getOrderedAt,
  getShippedAt,
  getTotalSum,
  getTotalSumPaid,
  getStatus,
  getPriceListId,
  getBuyerId,
  getSupplierId,
  getPriceListName,
  getSupplierName,
  getSupplierDeletedAt,
  getBuyerName,
  isOrder,
  getHaveUnviewedChanges
} = defStruct('Order', [
  'id',
  'number',
  'changedStatusAt',
  'orderedAt',
  'shippedAt',
  'totalSum',
  'totalSumPaid',
  'status',
  'priceListId',
  'buyerId',
  'supplierId',
  'priceListName',
  'supplierName',
  'supplierDeletedAt',
  'buyerName',
  'haveUnviewedChanges'
]);

/**
 * Create a order data type
 *
 * @param {object} params
 * @param {number} params.id
 * @param {string} params.number
 * @param {DateOrNull} params.changedStatusAt
 * @param {DateOrNull} params.orderedAt
 * @param {DateOrNull} params.shippedAt
 * @param {Price} params.totalSum
 * @param {Price} params.totalSumPaid
 * @param {string} params.status
 * @param {number} params.priceListId
 * @param {number} params.buyerId
 * @param {number} params.supplierId
 * @param {string} params.priceListName
 * @param {string} params.supplierName
 * @param {DateOrNull} params.supplierDeletedAt
 * @param {string} params.buyerName
 * @param {boolean} params.haveUnviewedChanges
 * @returns {Order}
 */
exports.makeOrder = ({
  id,
  number,
  changedStatusAt,
  orderedAt,
  shippedAt,
  totalSum,
  totalSumPaid,
  status,
  priceListId,
  buyerId,
  supplierId,
  priceListName,
  supplierName,
  supplierDeletedAt,
  buyerName,
  haveUnviewedChanges
} = {}) =>
  makeOrder(
    id,
    number,
    changedStatusAt,
    orderedAt,
    shippedAt,
    totalSum,
    totalSumPaid,
    status,
    priceListId,
    buyerId,
    supplierId,
    priceListName,
    supplierName,
    supplierDeletedAt,
    buyerName,
    haveUnviewedChanges
  );

/**
 * Checks if is order
 *
 * @param {*} a
 * @returns {boolean}
 */
exports.isOrder = (a) => isOrder(a);

/**
 * Gives id of order
 *
 * @param {Order} order
 * @returns {number}
 */
exports.getId = (order) => getId(order);

/**
 * Gives number of order
 *
 * @param {Order} order
 * @returns {string}
 */
exports.getNumber = (order) => getNumber(order);

/**
 * Gives changed status date of order
 *
 * @param {Order} order
 * @returns {DateOrNull}
 */
exports.getChangedStatusAt = (order) => getChangedStatusAt(order);

/**
 * Gives ordered date of order
 *
 * @param {Order} order
 * @returns {DateOrNull}
 */
exports.getOrderedAt = (order) => getOrderedAt(order);

/**
 * Gives shipped date of order
 *
 * @param {Order} order
 * @returns {DateOrNull}
 */
exports.getShippedAt = (order) => getShippedAt(order);

/**
 * Gives total sum of order
 *
 * @param {Order} order
 * @returns {Price}
 */
exports.getTotalSum = (order) => getTotalSum(order);

/**
 * Gives total sum paid of order
 *
 * @param {Order} order
 * @returns {Price}
 */
exports.getTotalSumPaid = (order) => getTotalSumPaid(order);

/**
 * Gives status of order
 *
 * @param {Order} order
 * @returns {string}
 */
exports.getStatus = (order) => getStatus(order);

/**
 * Gives price list id of order
 *
 * @param {Order} order
 * @returns {number}
 */
exports.getPriceListId = (order) => getPriceListId(order);

/**
 * Gives buyer id of order
 *
 * @param {Order} order
 * @returns {number}
 */
exports.getBuyerId = (order) => getBuyerId(order);

/**
 * Gives supplier id of order
 *
 * @param {Order} order
 * @returns {number}
 */
exports.getSupplierId = (order) => getSupplierId(order);

/**
 * Checks if status is equal
 *
 * @param {string} status
 * @param {Order} order
 * @returns {boolean}
 */
exports.statusEquals = (status, order) => equals(status, getStatus(order));

/**
 * Gives price list name of order
 *
 * @param {Order} order
 * @returns {string}
 */
exports.getPriceListName = (order) => getPriceListName(order);

/**
 * Gives supplier name of order
 *
 * @param {Order} order
 * @returns {string}
 */
exports.getSupplierName = (order) => getSupplierName(order);

/**
 * Gives buyer name of order
 *
 * @param {Order} order
 * @returns {string}
 */
exports.getBuyerName = (order) => getBuyerName(order);

const getSupplierDeletedAtFunc = (order) => getSupplierDeletedAt(order);
/**
 * Gives supplier deletedAt of order
 *
 * @param {Order} order
 * @returns {DateOrNull}
 */
exports.getSupplierDeletedAt = getSupplierDeletedAtFunc;

/**
 * Check is supplier deleted
 *
 * @param {Order} order
 * @returns {boolean}
 */
exports.isSupplierDeleted = (order) => !isDateNull(getSupplierDeletedAt(order));

/**
 * Check is order have changes
 *
 * @param {Order} order
 * @returns {boolean}
 */
exports.haveUnviewedChanges = (order) => getHaveUnviewedChanges(order);
