import { Map, List, fromJS } from 'immutable';

const initState = () =>
  Map({
    prices: List(),
    badges: List(),
    products: List(),
    limit: 0,
    offset: 0,
    size: 0
  });

const loadSuppliersPrices = (data, { prices, badges }) =>
  data.set('prices', fromJS(prices)).set('badges', fromJS(badges));

const loadSuppliersPricesProducts = (data, { products, limit, offset, size }) =>
  data
    .set('products', fromJS(products))
    .set('size', size)
    .set('offset', offset)
    .set('limit', limit);

const clearSuppliersPrices = (data) => data.set('badges', List());

const updateSuppliersPrices = (state, { prices = [], badges = [] }) =>
  state
    .update('prices', (statePrices) => {
      let newData = statePrices;
      prices.forEach((price) => {
        const index = newData.findIndex(
          (i) => i.get('priceId') === price.priceId
        );
        if (index !== -1) {
          newData = newData.delete(index).unshift(fromJS(price));
        } else {
          newData = newData.unshift(fromJS(price));
        }
      });
      return newData;
    })
    .update('badges', (data) => {
      let newData = data;
      badges.forEach((badge) => {
        const index = newData.findIndex((i) => i.get('id') === badge.id);
        if (index !== -1) {
          newData = newData.setIn([index], fromJS(badge));
        } else {
          newData = newData.unshift(fromJS(badge));
        }
      });
      return newData;
    });

const tablePrices = (state = initState(), { type, payload }) => {
  switch (type) {
    case 'PRICES_SUPPLIERS:GET_PRICES:SUCCESS':
      return loadSuppliersPrices(state, payload);
    case 'PRICES_SUPPLIERS:SEARCH_PRICES_PRODUCTS:SUCCESS':
      return loadSuppliersPricesProducts(state, payload);
    case 'PRICES_SUPPLIERS:CLEAR':
      return clearSuppliersPrices(state);
    case 'PRICES_SUPPLIERS:UPDATE_DATA':
      return updateSuppliersPrices(state, payload);
    default:
      return state;
  }
};

export default tablePrices;
