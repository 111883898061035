import { Map } from 'immutable';

const updateOfferField = (state, field, value) =>
  state.set(field, (value || '').trim());

const offersReducer = (state = Map(), action) => {
  switch (action.type) {
    case 'PRODUCT_OFFERS_UPDATE_FIELD':
      return updateOfferField(state, action.field, action.value);
    default:
      return state;
  }
};

export default offersReducer;
