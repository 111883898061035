import React, { useCallback } from 'react';
import { connect, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Interpolate } from 'react-i18next';
import { isEmpty } from 'ramda';
import * as storage from '../storage';
import * as getters from '../getters';
import Pagination from '../../../../Pagination';
import Loader from '../../../../Loader';
import '../style.css';
import EmptySearchResult from '../../../common/EmptySearchResult';
import ResponseCard from './ResponseCard';
import { getMyPurchaseResponsesBadges } from '../../../../../storeGetters';
import { useTranslate } from '../../../../../TranslateProvider';
import * as storeGetters from '../../../../../storeGetters';
import * as responseChatUC from '../../../../../modules/response-chat/useCases';
import { actions } from '../../../MyRequests/reducer';

const ResponsesTable = ({
  isUploaded = true,
  responses,
  getMyResponses,
  direction,
  orderBy,
  search = '',
  openSupportChat,
  openResponseChat,
  changeRespondStatus,
  limit = 20,
  offset = 0,
  selectedStatus,
  selectedResponseStatus,
  filters,
  size,
  checkedList,
  deleteResponse,
  isArchive,
  permissions
}) => {
  const t = useTranslate();

  const badges = useSelector(getMyPurchaseResponsesBadges).toJS();

  const selectNextPagination = (newOffset, newLimit) => {
    getMyResponses({
      limit: newLimit,
      offset: newOffset,
      filters,
      order: orderBy,
      direction,
      search,
      selectedStatus,
      selectedResponseStatus,
      isArchive
    });
  };

  const showNewRequestPage = () => {
    history.push('/requests/my/create', { noRestoreData: true });
  };

  if (!isUploaded) {
    return <Loader theme="page" />;
  }

  const isFilterApplied =
    !isEmpty(selectedStatus) ||
    !isEmpty(selectedResponseStatus) ||
    !isEmpty(search);

  if (responses.length === 0 && !isFilterApplied) {
    return (
      <Interpolate
        i18nKey="My requests empty text"
        feedback={
          <a style={{ fontWeight: 500 }} onClick={openSupportChat}>
            {t('byFeedback')}
          </a>
        }
        create_request={
          <a onClick={showNewRequestPage} style={{ fontWeight: 500 }}>
            {t('create_request')}
          </a>
        }
      />
    );
  }

  const isChecked = (id) => checkedList[id] === true;

  const findBadge = (id) => badges.find(({ typeId }) => typeId === id);

  const onOpenResponseChat = useCallback((response) => {
    const { request } = response;

    openResponseChat({ requestId: request.id, response, isWidget: true }).then(
      (chat) => {
        if (chat?.responseStatus) {
          changeRespondStatus({
            requestId: request.id,
            responseId: response.id,
            status: chat.responseStatus
          });
        }
      }
    );
  }, []);

  return (
    <>
      {responses.length !== 0 ? (
        <div>
          {responses.map((response) => (
            <ResponseCard
              key={response.id}
              response={response}
              isChecked={isChecked}
              onOpenResponseChat={onOpenResponseChat}
              deleteResponse={deleteResponse}
              badge={findBadge(response.id)}
              permissions={permissions}
            />
          ))}
          <div style={{ marginTop: 12 }} />
          <Pagination
            totalSize={size}
            limit={limit}
            offset={offset}
            nextPage={selectNextPagination}
            isShowItemsOnPage
          />
        </div>
      ) : (
        <EmptySearchResult tkey="Requests filters empty text" />
      )}
    </>
  );
};

export default connect(
  (state) => ({
    responses: getters.getAllResponses(state),
    size: getters.getSize(state),
    limit: getters.getLimit(state),
    offset: getters.getOffset(state),
    direction: getters.getDirection(state),
    orderBy: getters.getOrder(state),
    filters: getters.getFilters(state),
    search: getters.getSearch(state),
    permissions: storeGetters.getPurchaseRequestsPermission(state)
  }),
  (dispatch) =>
    bindActionCreators(
      {
        getMyResponses: storage.getMyResponses,
        changeRespondStatus: actions.changeResponseStatus,
        openResponseChat: responseChatUC.open
      },
      dispatch
    )
)(ResponsesTable);
