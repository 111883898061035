import React from 'react';
import {
  Modal,
  Button,
  FormGroup,
  FormControl,
  ControlLabel,
  HelpBlock
} from 'react-bootstrap';
import { translate } from 'react-i18next';
import { connect } from 'react-redux';
import ImageControl from '../ImageControl';

import * as modalActionCreators from '../../action-creators/modal';
import * as catalogActionCreators from '../../action-creators/catalog';
import * as categoryActionCreators from '../../action-creators/category';

class ModalEditCatalogCategory extends React.Component {
  constructor(props) {
    super(props);
    this.onSaveClick = this.onSaveClick.bind(this);
    this.onSetImg = this.onSetImg.bind(this);
    this.onDelImg = this.onDelImg.bind(this);
  }

  onSetImg() {
    const { showModal, updateCurrentCategoryField } = this.props;

    showModal('SAVE_IMG_URL', {
      submit: (url) => Promise.resolve(updateCurrentCategoryField('src', url)),
      onClosed: () => showModal('EDIT_CATALOG_CATEGORY')
    });
  }

  onDelImg() {
    const { updateCurrentCategoryField } = this.props;

    updateCurrentCategoryField('src', '');
  }

  onSaveClick() {
    const { updateCategory, getCategories, currentCategory, hideModalDialog } =
      this.props;

    const catalogId = currentCategory.getIn(['attributes', 'catalog']);
    const id = currentCategory.getIn(['attributes', 'id']);
    const name = currentCategory.getIn(['attributes', 'name']);
    const text = currentCategory.getIn(['attributes', 'text']);
    const src = currentCategory.hasIn(['attributes', 'src'])
      ? currentCategory.getIn(['attributes', 'src'])
      : currentCategory.getIn(['attributes', 'img', 'src']);

    updateCategory(catalogId, id, name, text, src).then(() => {
      getCategories(catalogId);
      hideModalDialog();
    });
  }

  getProperty(field) {
    return this.props.currentCategory
      ? this.props.currentCategory.getIn(['attributes', field])
      : '';
  }

  showHelpInfo(field) {
    const { t } = this.props;
    if (!this.getProperty(field)) {
      return (
        <HelpBlock id="invalidFieldMessage" style={{ color: 'red' }}>
          {t('Required fields empty')}
        </HelpBlock>
      );
    }
    return null;
  }

  inputProps(field, includeBlur = true) {
    const { updateCurrentCategoryField } = this.props;
    return {
      value: this.getProperty(field),
      onChange: (e) => {
        updateCurrentCategoryField(field, e.target.value);
      },
      onBlur: includeBlur ? undefined : undefined
    };
  }

  render() {
    const { t, hideModalDialog, currentCategory } = this.props;

    return (
      <Modal id="userModalWindow" enforceFocus show>
        <Modal.Header>
          <Modal.Title>
            {t('Edit product category')}
            <span
              className="modal-header-cross pull-right"
              onClick={() => hideModalDialog()}
            />
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ImageControl
            name="userImage"
            imgSrc={
              currentCategory
                ? currentCategory.getIn(['attributes', 'img', 'src'])
                : ''
            }
            onFileChoose={this.onSetImg}
            onImageRemove={this.onDelImg}
            style={{
              position: 'absolute',
              left: '15px',
              width: '110px',
              height: '110px',
              zIndex: 1
            }}
          />
          <div>
            <FormGroup
              style={{ marginLeft: '120px' }}
              controlId="name"
              validationState={
                currentCategory && currentCategory.getIn(['attributes', 'name'])
                  ? 'success'
                  : 'error'
              }>
              <ControlLabel
                style={{
                  color: '#999999',
                  fontWeight: 'normal',
                  fontSize: '14px'
                }}>
                {t('Category name')}
                <span style={{ color: 'red' }}>* </span>
              </ControlLabel>
              <FormControl
                type="text"
                maxLength="60"
                {...this.inputProps('name')}
              />
              {this.showHelpInfo('name')}
            </FormGroup>
            <FormGroup style={{ marginTop: 55 }} controlId="text">
              <FormControl
                componentClass="textarea"
                maxLength="690"
                rows="5"
                placeholder={t('Category description')}
                {...this.inputProps('text')}
              />
              <h6 className="pull-right">
                <small>
                  {currentCategory &&
                  currentCategory.hasIn(['attributes', 'text'])
                    ? currentCategory.getIn(['attributes', 'text']).length
                    : 0}
                  /690
                </small>
              </h6>
            </FormGroup>
          </div>
          <span style={{ color: 'red' }}>* </span>
          {t('Required fields')}
        </Modal.Body>
        <Modal.Footer>
          <Button
            id="cancel"
            className="pull-left"
            onClick={() => hideModalDialog()}>
            {t('Cancel')}
          </Button>
          <Button
            id="saveUserButton"
            bsStyle="primary"
            onClick={this.onSaveClick}>
            {t('Save')}
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => ({
  currentCatalog: state.getIn(['catalog', 'item']),
  currentCategory: state.getIn(['category', 'item']),
  table: state.getIn(['tables', 'products']),
  selected: state.getIn(['tables', 'products', 'selected']),
  priceCategories: state.getIn(['tables', 'priceCategories', 'items']),
  validator: state.get('validator'),
  windowHeight: state.getIn(['browserProps', 'windowHeight']),
  newCategory: state.getIn(['category', 'new'])
});

export default translate(['ui'], { wait: true })(
  connect(
    mapStateToProps,
    Object.assign(
      catalogActionCreators,
      modalActionCreators,
      categoryActionCreators
    )
  )(ModalEditCatalogCategory)
);
