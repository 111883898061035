import fetch from 'isomorphic-fetch';
import req from '../lib/request';
import { setSuccessMessage, setErrorMessage } from './message';

export const selectBlockingItem = (option, id) => ({
  type: 'SELECT_BLOCKING_ITEM',
  payload: { option, id }
});
export const clearSelectedBlockingItem = (option) => ({
  type: 'CLEAR_SELECT_BLOCKING_ITEM',
  payload: { option }
});

export const getBillingTariffs = () => (dispatch) =>
  fetch('/api/billing/tariffs', {
    credentials: 'include',
    method: 'GET'
  })
    .then((res) => res.json())
    .then((payload) => {
      dispatch({ type: 'SET_BILLING_TARIFFS_DATA', payload });
    });

export const getBillsHistory =
  (order, direction, offset, limit) => (dispatch) => {
    let url = '/api/billing/history';
    if ((order && direction) || offset) {
      url = `${url}?orderBy=${order || ''}&direction=${
        direction || ''
      }&offset=${offset || 0}&limit=${limit || 20}`;
    }
    return fetch(url, {
      credentials: 'include',
      method: 'GET'
    })
      .then((res) => res.json())
      .then((payload) => {
        dispatch({ type: 'COMPANY:SET_BILLING_HISTORY', payload });
      });
  };

export const getBlockingInfo = (tariff, additions) => async (dispatch) => {
  const res = await fetch('/api/billing/blocking', {
    credentials: 'include',
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ tariff, additions })
  });
  const payload = await res.json();
  await dispatch({ type: 'SET_BILLING_BLOCKING_DATA', payload });
  return payload;
};

export const setTariff =
  (tariff, additions, period, allowed, props = {}) =>
  async (dispatch) => {
    try {
      const res = await fetch('/api/billing/tariff', {
        credentials: 'include',
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          tariff,
          additions,
          period,
          allowed,
          name: props.name,
          email: props.email
        })
      });
      const payload = await res.json();
      const { billing } = payload;
      if (billing && billing.paid) {
        dispatch(setSuccessMessage({ key: 'Tariff was set successfully' }));
        dispatch({ type: 'COMPANY:SET_TARIFF:SUCCESS', payload });
      }
      return payload;
    } catch (e) {
      console.error(e);
      return {};
    }
  };

export const payTariff =
  (additions, allowed, props = {}) =>
  async (dispatch) => {
    try {
      const res = await fetch('/api/billing/tariff_pay', {
        credentials: 'include',
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          additions,
          allowed,
          name: props.name,
          email: props.email
        })
      });
      const payload = await res.json();
      const { billing } = payload;
      if (billing && billing.paid) {
        dispatch(setSuccessMessage({ key: 'Tariff was set successfully' }));
        dispatch({ type: 'COMPANY:SET_TARIFF:SUCCESS', payload });
      }
      return payload;
    } catch (e) {
      console.error(e);
      return {};
    }
  };

export const updateContact =
  ({ employee, email, phone }) =>
  (dispatch) =>
    fetch('/api/billing/contact', {
      credentials: 'include',
      method: 'PATCH',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ employee, email, phone })
    })
      .then((res) => res.json())
      .then((payload) => {
        dispatch({ type: 'COMPANY:SET_BILLING_CONTACT:SUCCESS', payload });
      })
      .catch((res) => console.error(res));

export const unblockItems = (section, ids) => async (dispatch) => {
  try {
    const res = await fetch('/api/billing/unblock', {
      credentials: 'include',
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ section, ids })
    });
    const resp = await res.json();
    if (res.status === 409) {
      return dispatch(setErrorMessage({ key: resp.errors[0].msg }));
    }
    dispatch(setSuccessMessage({ key: 'Items successfully unblocked' }));
    const payload = { ids };
    if (section === 'catalogs') {
      dispatch({ type: 'CATALOGS:UNBLOCK_ITEMS:SUCCESS', payload });
    }
    if (section === 'prices') {
      dispatch({ type: 'PRICES:UNBLOCK_ITEMS:SUCCESS', payload });
    }
    if (section === 'stocks') {
      dispatch({ type: 'STOCKS:UNBLOCK_ITEMS:SUCCESS', payload });
    }
    if (section === 'employees') {
      dispatch({ type: 'COMPANY:UNBLOCK_EMPLOYEES:SUCCESS', payload });
    }
    return undefined;
  } catch (e) {
    return console.error(e);
  }
};

export const cancelNextPeriod = () => (dispatch) =>
  fetch('/api/billing/cancel_next_period', {
    credentials: 'include',
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({})
  })
    .then((res) => res.json())
    .then((payload) => {
      dispatch(setSuccessMessage({ key: 'Next period was canceled' }));
      dispatch({ type: 'COMPANY:SET_TARIFF:SUCCESS', payload });
    })
    .catch((res) => console.error(res));

export const autoPayTurn = () => (dispatch) =>
  fetch('/api/billing/turn_autopay', {
    credentials: 'include',
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify()
  })
    .then((res) => res.json())
    .then((payload) => {
      dispatch({ type: 'COMPANY:SET_TARIFF:SUCCESS', payload });
    })
    .catch((res) => console.error(res));

export const removeAutopayBinding = () => (dispatch) =>
  fetch('/api/billing/autopay_remove_binding', {
    credentials: 'include',
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify()
  })
    .then((res) => res.json())
    .then((payload) => {
      dispatch({ type: 'COMPANY:SET_TARIFF:SUCCESS', payload });
    })
    .catch((res) => console.error(res));

export const getBill = () => () => window.open('/api/billing/bill', '_self');
export const getPaidBill = () => '/api/billing/paid_bill';

export const moveToPay =
  ({ orderId, sum }) =>
  async () => {
    alert(
      'Оплата доступна только для юридических лиц и производится только с корпоративной карты, привязанной к счету организации'
    );
    req
      .post('/api/billing/pay-form', { orderId, sum })
      .then(({ url }) => {
        window.open(url, '_self');
      })
      .catch(console.error.bind(console));
  };
