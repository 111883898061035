import React from 'react';
import { isEmpty } from 'ramda';
import { useSelector } from 'react-redux';
import TypingInfo from '../../../TypingInfo';
import ForwardPanel from '../../../chat-forward-panel';
import EditPanel from '../../../chat-edit-panel';
import ReplyPanel from '../../../chat-reply-panel';
import InputMsg from '../../../InputMsg';
import * as getters from '../../../../getter';
import * as forwardMessageGetters from '../../../../modules/forwardMessages/getters';
import { isCurrentMode } from '../../../../data-type/mode';
import * as chatType from '../../../../helpers/chatType';
import { useActions } from '../../../../../../hooks/useActions';
import * as useCase from '../../../../useCases';
import { useMessengerConfig } from '../../../../Messenger/hooks/useMessengerConfig';
import '../../style.css';

export const InputContainer = ({ mode }) => {
  const { inputMessage } = useMessengerConfig();

  const blockEdit = useSelector(getters.getEditMsg);
  const openedChat = useSelector(getters.getOpenedChat);
  const input = useSelector(getters.getChatInput);
  const dialogInfo = useSelector(getters.getDialogInfo);
  const isForwardMode = useSelector(forwardMessageGetters.isForwardMode);
  const isActive = useSelector(getters.checkCurrentMemberHaveActivedMS);
  const isReader = useSelector(getters.checkCurrentMemberHaveReaderRights);
  const haveFreeSpace = useSelector(
    (state) =>
      state.getIn(['storage', 'storageSize']) <
      state.getIn(['storage', 'maxStorageSize'])
  );

  const { handleInputMsg } = useActions(useCase);

  const checkHideInput = () => {
    const isDialog = chatType.isDialog(openedChat);
    const isChannel = chatType.isChannel(openedChat);
    const isTopic = chatType.isTopic(openedChat);
    const isPurchaseRequestsChannel =
      chatType.isPurchaseRequestsChannel(openedChat);
    const isNewsFeedChannel = chatType.isNewsFeedChannel(openedChat);

    return (
      !(
        isCurrentMode(mode, 'chat') ||
        isCurrentMode(mode, 'show-delayed-messages')
      ) ||
      Boolean(
        isDialog &&
          (dialogInfo.isBlockedMe ||
            dialogInfo.isBlockedContact ||
            dialogInfo.companyDeleted ||
            dialogInfo.isDeleted)
      ) ||
      input.hidden ||
      openedChat.archived ||
      openedChat.isClosed ||
      ((isChannel || isTopic) && !isActive) ||
      (isTopic && isReader) ||
      isPurchaseRequestsChannel ||
      isNewsFeedChannel
    );
  };

  const isHideInput = checkHideInput();
  const isEditMode = !isEmpty(blockEdit.message);

  return (
    <div className="cwm-wrapper">
      <TypingInfo />
      <ForwardPanel />
      <EditPanel message={blockEdit.message} />
      <ReplyPanel />
      {!isHideInput && (
        <InputMsg
          input={input}
          chat={openedChat}
          handler={handleInputMsg}
          editMode={isEditMode}
          isForwardMode={isForwardMode}
          isDisableAttach={!haveFreeSpace}
          config={inputMessage}
          blockEdit={blockEdit}
        />
      )}
    </div>
  );
};

export default React.memo(InputContainer);
