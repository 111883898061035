import React from 'react';
import {
  Modal,
  Button,
  FormGroup,
  Form,
  ControlLabel,
  HelpBlock
} from 'react-bootstrap';
import { translate } from 'react-i18next';
import { connect } from 'react-redux';
import { List, Map } from 'immutable';
import SetCategory from '../SetCategory/SetCategory';

import * as modalActionCreators from '../../action-creators/modal';
import * as catalogActionCreators from '../../action-creators/catalog';
import * as categoryActionCreators from '../../action-creators/category';
import * as servicesAction from '../../action-creators/services';

class ModalEditCatalogCategory extends React.Component {
  constructor(props) {
    super(props);
    this.onSaveClick = this.onSaveClick.bind(this);
    this.state = {
      parentId: null
    };
  }

  UNSAFE_componentWillMount() {
    const { selectedCategory, categories } = this.props;
    const id = selectedCategory.first();
    const category = categories.find((cat) => cat.get('id') === Number(id));
    const parentId = category.get('parentId');
    this.setState({ parentId });
  }

  async onSaveClick() {
    const {
      updateCategory,
      categories,
      getCatalog,
      selectedCategory,
      getCategories,
      hideModalDialog,
      catalog,
      callback,
      user,
      gaSend
    } = this.props;
    await gaSend({
      category: 'Supplier',
      action: 'move_category',
      label: user.get('email')
    });
    const catalogId = catalog.get('id');
    selectedCategory.map((id) => {
      const category = categories.find((cat) => cat.get('id') === Number(id));
      const name = category.get('name');
      const text = category.get('desc');
      const src = category.get('imgUrl');
      const { parentId } = this.state;

      return updateCategory(catalogId, id, name, text, src, parentId).then(
        () => {
          getCategories(catalogId);
          getCatalog(catalogId);
          hideModalDialog();
          callback();
        }
      );
    });
  }

  getProperty(field) {
    return this.props.currentCategory
      ? this.props.currentCategory.getIn(['attributes', field])
      : '';
  }

  showHelpInfo(field) {
    const { t } = this.props;
    if (!this.getProperty(field)) {
      return (
        <HelpBlock id="invalidFieldMessage" style={{ color: 'red' }}>
          {t('Required fields empty')}
        </HelpBlock>
      );
    }
    return null;
  }

  inputProps(field, includeBlur = true) {
    const { updateCurrentCategoryField } = this.props;
    return {
      value: this.getProperty(field),
      onChange: (e) => {
        updateCurrentCategoryField(field, e.target.value);
      },
      onBlur: includeBlur ? undefined : undefined
    };
  }

  render() {
    const { t, hideModalDialog, categories, selectedCategory } = this.props;
    const id = selectedCategory.first();
    const category = categories.find((cat) => cat.get('id') === Number(id));
    const parentId = category.get('parentId');
    const filteredCategories = categories
      .filter((cat) => {
        const isFind = selectedCategory.find(
          (itemId) => Number(itemId) === cat.get('id')
        );
        return !isFind;
      })
      .unshift(Map({ name: t('not selected'), parentId: null, id: null }));

    return (
      <Modal id="userModalWindow" enforceFocus show>
        <Modal.Header>
          <Modal.Title>
            {t('move_catalog_category_title')}
            <span
              className="modal-header-cross pull-right"
              onClick={() => hideModalDialog()}
            />
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <FormGroup
              style={{ display: 'flex', alignItems: 'center' }}
              controlId="category">
              <ControlLabel
                style={{
                  fontWeight: 'normal',
                  fontSize: '14px'
                }}>
                {t('move_to_category')}
              </ControlLabel>
              <SetCategory
                selectedId={this.state.parentId || null}
                style={{ width: '327px', marginLeft: 15 }}
                onChose={(value) => {
                  this.setState({ parentId: value });
                }}
                categories={filteredCategories || List()}
              />
            </FormGroup>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            id="cancel"
            className="pull-left"
            onClick={() => hideModalDialog()}>
            {t('Cancel')}
          </Button>
          <Button
            id="saveUserButton"
            bsStyle="primary"
            onClick={this.onSaveClick}
            disabled={this.state.parentId === parentId}>
            {t('Save')}
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.getIn(['user', 'user']),
  currentCatalog: state.getIn(['catalog', 'item']),
  currentCategory: state.getIn(['category', 'item']),
  table: state.getIn(['tables', 'products']),
  selected: state.getIn(['tables', 'products', 'selected']),
  priceCategories: state.getIn(['tables', 'priceCategories', 'items']),
  selectedCategory: state.getIn(['tables', 'common', 'selected']),
  validator: state.get('validator'),
  windowHeight: state.getIn(['browserProps', 'windowHeight']),
  newCategory: state.getIn(['category', 'new']),
  categories: state.getIn(['tables', 'common', 'items']),
  catalog: state.getIn(['catalogCombine', 'properties'])
});

export default translate(['ui'], { wait: true })(
  connect(
    mapStateToProps,
    Object.assign(
      catalogActionCreators,
      modalActionCreators,
      categoryActionCreators,
      { gaSend: servicesAction.gaSend }
    )
  )(ModalEditCatalogCategory)
);
