import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { List } from 'immutable';
import block from 'bem-cn-lite';
import { translate } from 'react-i18next';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  Modal,
  Button,
  FormGroup,
  FormControl,
  ControlLabel,
  HelpBlock,
  Col
} from 'react-bootstrap';
import './styles/modal_theme_default.styl';

import { ModalContainer } from './elements';
import * as modalActions from '../../action-creators/modal';
import * as contactsActions from '../../action-creators/contacts';

const b = block('modal-container');

class ModalEditContactList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      text: props.name,
      pristine: true
    };
    this.setSubmitText = this.setSubmitText.bind(this);
    this.getAsterisk = this.getAsterisk.bind(this);
  }

  getChildContext() {
    return { b };
  }

  setSubmitText(e) {
    this.setState({ text: e.target.value });
  }

  handleEditContactList = async () => {
    const { id, editContactList, hideModalDialog } = this.props;
    const currentName = this.props.name;
    const { text } = this.state;
    const name = text.trim();

    if (name !== currentName) {
      editContactList({ id, name })
        .then(() => {
          hideModalDialog();
        })
        .catch((err) => {
          const { type } = err;
          if (type === 'ERR_CONTACT_LIST_EXISTS') {
            this.setState({
              text: name
            });
          }
        });
    } else {
      this.setState({ text: name });
    }
  };

  getAsterisk = () => <span style={{ color: 'red' }}>*</span>;

  isNotValid = () => !this.state.text && !this.state.pristine;

  isValidNameField() {
    return (
      this.state.text.length === 0 ||
      /^\s+$/.test(this.state.text) ||
      this.state.text === this.props.name
    );
  }

  isNameAlreadyExists() {
    const { text, pristine } = this.state;
    const { currentLists } = this.props;
    const lists = currentLists.toJS();
    const userLists = lists.filter((list) => list.system === false);
    const userListsNames = userLists.map((list) => list.name);
    const userListsLowerCase = userListsNames.map((list) => list.toLowerCase());

    if (pristine) {
      return false;
    }

    return userListsLowerCase.includes(text.toLowerCase());
  }

  render() {
    const { t, hideModalDialog } = this.props;

    return (
      <Modal show onHide={() => hideModalDialog()}>
        <ModalContainer mods={{ type: 'default', theme: 'default' }}>
          <Modal.Header>
            <Modal.Title>
              {t('editContactList')}
              {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events */}
              <span
                className="modal-header-cross pull-right"
                onClick={() => hideModalDialog()}
              />
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <FormGroup
              controlId="txt"
              style={{ overflow: 'hidden' }}
              validationState={
                this.isNotValid() || this.isNameAlreadyExists() ? 'error' : null
              }>
              <ControlLabel>
                {t('Name')}
                {this.getAsterisk()}
              </ControlLabel>
              <h6 className="pull-right">
                <small>
                  {this.state.text.length}
                  /60
                </small>
              </h6>
              <FormControl
                type="text"
                placeholder=""
                value={this.state.text}
                maxLength="60"
                onChange={this.setSubmitText}
                onKeyDown={() => this.setState({ pristine: false })}
              />
              {this.isNotValid() && (
                <HelpBlock style={{ marginBottom: 0 }}>
                  {t('Required fields empty')}
                </HelpBlock>
              )}
              {this.isNameAlreadyExists() && (
                <HelpBlock style={{ marginBottom: 0 }}>
                  {t('Name already exist')}
                </HelpBlock>
              )}
            </FormGroup>
            <FormGroup style={{ marginBottom: 30 }}>
              <Col
                componentClass={ControlLabel}
                className="modal-create-group-chat__text-error"
                xs={5}>
                {this.getAsterisk()}
                <span>{t('Required fields')}</span>
              </Col>
            </FormGroup>
          </Modal.Body>
          <Modal.Footer>
            <Button
              id="cancel"
              className="pull-left"
              onClick={() => hideModalDialog()}>
              {t('Cancel')}
            </Button>
            <Button
              bsStyle="primary"
              disabled={this.isValidNameField() || this.isNameAlreadyExists()}
              onClick={this.handleEditContactList}>
              {t('Change')}
            </Button>
          </Modal.Footer>
        </ModalContainer>
      </Modal>
    );
  }
}

ModalEditContactList.propTypes = {
  t: PropTypes.func.isRequired,
  currentLists: PropTypes.instanceOf(List).isRequired,
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  name: PropTypes.string.isRequired,
  hideModalDialog: PropTypes.func.isRequired,
  editContactList: PropTypes.func.isRequired
};

ModalEditContactList.childContextTypes = {
  b: PropTypes.func
};

export default connect(
  (state) => ({
    currentLists: state.getIn(['contacts', 'lists']) || new List()
  }),
  (dispatch) =>
    bindActionCreators(
      {
        hideModalDialog: modalActions.hideModalDialog,
        editContactList: contactsActions.editContactList
      },
      dispatch
    )
)(translate()(ModalEditContactList));
