import React, { useEffect, useRef } from 'react';

import './style.css';

function PopupSmall({
  content,
  top = 20,
  isOpened,
  setIsOpened = () => {},
  style = {}
}) {
  const ref = useRef();

  function handlerClickOutside(event) {
    if (ref && !ref.current?.contains(event.target) && isOpened) {
      setIsOpened(false);
    }
  }

  useEffect(() => {
    document.addEventListener('click', handlerClickOutside);

    return () => document.removeEventListener('click', handlerClickOutside);
  }, [isOpened]);

  return (
    <div style={{ position: 'relative', padding: 0 }} ref={ref}>
      {isOpened && (
        <div style={{ ...style, top }} className="popup-small-content">
          {content}
        </div>
      )}
    </div>
  );
}

export default PopupSmall;
