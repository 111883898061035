import React from 'react';
import { connect } from 'react-redux';
import { translate } from 'react-i18next';
import { bindActionCreators } from 'redux';
import { getUserLang } from '../../ChatWidget/getter';
import * as useCase from '../../ChatWidget/useCases';
import './disclaimer.styl';

const Disclaimer = ({ t, handleDemoModals }) => (
  // eslint-disable-next-line react/jsx-filename-extension
  <p className="header-disclaimer">
    {t('The data in the Demo is stored for 24 hours')}
    <span
      id="register"
      className="header-disclaimer__register header-disclaimer__register_demo"
      onClick={() => handleDemoModals('go-to-registration')}>
      {t('Register_2')}
    </span>
    {t('to save stored data')}
  </p>
);

export default connect(
  (state) => ({
    language: getUserLang(state)
  }),
  (dispatch) => bindActionCreators(useCase, dispatch)
)(translate()(Disclaimer));
