import React from 'react';
import { Checkbox } from '@link/react-components';
import { useBrowserNotifications } from '../../../hooks';

export function BrowserSubscription({ size }) {
  const { subscribe, toggleSubscribe, disabled } = useBrowserNotifications();

  return (
    <Checkbox
      checked={subscribe}
      onChange={toggleSubscribe}
      size={size}
      disabled={disabled}
    />
  );
}
