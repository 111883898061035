import { curry } from 'ramda';
import {
  initUseStateDataType,
  getData,
  updateState
} from '../../../lib/data-hook';

/*
 * Hook for work with mode of view button to invite to edit form
 */

export const NONE = 'NONE';
export const INVITE_TO_EDIT = 'INVITE_TO_EDIT';
export const SHOWN_EDIT_FORM = 'SHOWN_EDIT_FORM';

const makeFormMode = (mode = NONE) => ({ type: 'FormMode', mode });
const setMode = curry((mode, formMode) => ({ ...formMode, mode }));
const getMode = (formMode) => formMode.mode;
const isMode = curry((mode, formMode) => mode === getMode(formMode));

export const useFormMode = initUseStateDataType(makeFormMode);
export const updateMode = updateState(setMode);

export const toShowEditForm = updateMode(SHOWN_EDIT_FORM);
export const toInviteToEdit = updateMode(INVITE_TO_EDIT);

export const getFromMode = getData(getMode);
export const isNoneMode = getData(isMode(NONE));
export const isInviteToEdit = getData(isMode(INVITE_TO_EDIT));
export const isShownEditForm = getData(isMode(SHOWN_EDIT_FORM));
