import React from 'react';
import block from 'bem-cn-lite';
import ListOfSections from './listOfSections';
import Header from './header';
import Footer from '../common/components/Footer';
import './style.css';

const b = block('snm');

const ShortView = ({
  navigationsList = [],
  footerMenu = [],
  activeIndex,
  toggleMode
}) => (
  <section className={b({ short: true })}>
    <div className={b('header')}>
      <Header onOpenFull={toggleMode} />
    </div>
    <div className={b('body')}>
      <ul className={b('sections', { short: true })}>
        <ListOfSections
          navigationsList={navigationsList}
          activeIndex={activeIndex}
        />
      </ul>
    </div>
    <div className={b('footer')}>
      <Footer list={footerMenu} onlyIcons />
    </div>
  </section>
);

export default ShortView;
