import React from 'react';
import { isEmpty } from 'ramda';
import block from 'bem-cn-lite';
import Section from '../../common/components/section';

const b = block('snm-full-nest-section');

const NestedList = ({ listOfSection = [], activeIndex }) => {
  if (isEmpty(listOfSection)) return null;

  return (
    <ul>
      {listOfSection.map((nestSection, key) => (
        <li className={b()} key={key}>
          <Section section={nestSection} isActive={activeIndex === key} />
        </li>
      ))}
    </ul>
  );
};

export default NestedList;
