import React, { useCallback, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';

import { Button, Typography, Loader } from '@link/react-components';
import { useActions, useNavigate } from '@hooks';
import { Snackbar } from '@components';
import styles from './Panel.module.css';
import * as getters from '../../../../../components/ChatWidget/modules/constructor/getters';
import { useTranslate } from '../../../../../TranslateProvider';

import * as constructorActions from '../../../../../components/ChatWidget/modules/constructor/actions';

import * as modalActions from '../../../../../action-creators/modal';
import iconsView from '../../../../../assets/images/constants';
import { RouteItem } from '../routeItem';
import { routeService } from '../../../../../api/routeService';

export const Panel = () => {
  const { list: routes, isLoading } = useSelector(getters.getConstructorState);
  const t = useTranslate();
  const { fetchCompanyRoutesList, deleteRouteFromList } =
    useActions(constructorActions);
  const snackbarRef = useRef(null);
  const { showModal } = useActions(modalActions);

  useEffect(() => {
    fetchCompanyRoutesList();
  }, []);

  const onDeleteRoute = useCallback(async (route) => {
    if (route.isCanBeDeleted) {
      showModal('SIMPLE_SUBMIT', {
        title: 'constructor.dataYouEnteredWillBeDeleted',
        text: 'constructor.deleteRouteModalText',
        textBtnCancel: 'modals.constructor.cancel',
        textBtnConfirm: 'modals.constructor.delete',
        submitAction: async () => {
          await routeService.deleteRoute(route.id);
          deleteRouteFromList(route.id);
        }
      });
      return;
    }
    snackbarRef.current.show();
  }, []);

  const navigate = useNavigate();

  if (isLoading) return <Loader />;

  if (routes.length === 0) {
    return (
      <div className={styles.content}>
        <Typography variant="body1Reg" className={styles.text}>
          {t('constructor.noPathExist')}
        </Typography>
        <Button
          onClick={() => navigate('/constructor/create')}
          bsStyle="primary">
          {t('constructor.newPathBtn')}
        </Button>
      </div>
    );
  }

  return (
    <div className={styles.constructorList}>
      {routes.map((route) => (
        <RouteItem key={route.id} route={route} onDeleteRoute={onDeleteRoute} />
      ))}
      <Snackbar
        ref={snackbarRef}
        iconName={iconsView.Information}
        iconSize="m"
        iconClassName={styles.snackbarIcon}
        text={t('constructor.unavailableRoute')}
      />
    </div>
  );
};
