import React, { useMemo } from 'react';
import { Tabs as UITabs } from '@link/react-components';
import { useTranslate } from '@hooks';
import { TabContent } from './TabContent';
import { AboutCompany } from '../AboutCompany';
import { PurchaseRequests } from '../PurchaseRequests';
import { Employees } from '../Employees';
import styles from './Tabs.module.css';

export function Tabs() {
  const t = useTranslate();

  function translateTabName(tKey) {
    return t(`companyCard.tabs.${tKey}.tabName`);
  }

  const tabContent = useMemo(
    () => [
      {
        title: translateTabName('aboutCompany'),
        content: (
          <TabContent fullWidth={false}>
            <AboutCompany />
          </TabContent>
        )
      },
      {
        title: translateTabName('purchaseRequests'),
        content: (
          <TabContent>
            <PurchaseRequests />
          </TabContent>
        )
      },
      {
        title: translateTabName('employees'),
        content: (
          <TabContent>
            <Employees />
          </TabContent>
        )
      }
    ],
    [t]
  );

  return <UITabs className={styles.tabs} tabContent={tabContent} />;
}
