/* eslint-disable react/no-unused-state */
import React from 'react';
import { bindActionCreators } from 'redux';
import {
  Modal,
  Col,
  Form,
  FormGroup,
  ControlLabel,
  Button,
  InputGroup
} from 'react-bootstrap';
import { connect } from 'react-redux';
import { translate } from 'react-i18next';
import { Map } from 'immutable';
// import block from 'bem-cn-lite';

import InputNumber from '../../InputNumber';

import * as modalActionCreators from '../../../action-creators/modal';
import * as priceActions from '../../../action-creators/prices';
import { Select } from '../../FormControls';
import { getProductUnits } from '../../../utils';

// const b = block('modal-container');

const ModalEditPriceQuantityCategory = translate(['ui'], { wait: true })(
  class extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        hasChanges: false,
        from: '',
        to: '',
        catId: ''
      };
      this.inputProps = this.inputProps.bind(this);
      this.setRoundUp = this.setRoundUp.bind(this);
      this.validateCategory = this.validateCategory.bind(this);
      this.handleClick = this.handleClick.bind(this);
    }

    UNSAFE_componentWillMount() {
      const { selectedCategories, priceCategories, setSelectedCategory } =
        this.props;
      let item = Map();
      priceCategories.forEach((category) => {
        if (category.get('id') === selectedCategories.first()) {
          item = category;
        }
      });
      this.setState({
        from: item.get('from'),
        to: item.get('to'),
        catId: item.get('id')
      });
      setSelectedCategory(item);
    }

    componentWillUnmount() {
      this.props.setSelectedCategory(Map());
    }

    getProperty(field) {
      return this.props.selectedCategory &&
        this.props.selectedCategory.has(field)
        ? this.props.selectedCategory.get(field)
        : '';
    }

    validationState(field) {
      const { selectedCategory } = this.props;
      return selectedCategory && selectedCategory.get(field) ? '' : 'error';
    }

    getDiscountValidationState() {
      const { selectedCategory } = this.props;
      return (selectedCategory &&
        selectedCategory.get('discount') >= 0 &&
        selectedCategory.get('discount') < 100) ||
        (selectedCategory && selectedCategory.get('discount') === '')
        ? ''
        : 'error';
    }

    validateCategory() {
      const { selectedCategory, priceCategories } = this.props;
      if (!selectedCategory) {
        return 'error';
      }

      const categories = priceCategories
        .filter(
          (i) =>
            i.get('type') === 2 &&
            i.get('unit') === selectedCategory.get('unit')
        )
        .toJS()
        .map((i) => {
          if (i.to === 0) {
            // eslint-disable-next-line no-param-reassign
            i.to = Infinity;
          }
          return i;
        });

      if (
        !categories.length ||
        (!selectedCategory.get('from') && !selectedCategory.get('to'))
      ) {
        return '';
      }
      let collise = false;
      categories.forEach((i) => {
        if (this.state.catId === i.id) {
          collise = collise || false;
          return;
        }
        collise =
          collise ||
          !(
            (selectedCategory.get('from') < i.from &&
              (selectedCategory.get('to')
                ? selectedCategory.get('to') < i.from
                : false)) ||
            (selectedCategory.get('from') > i.to &&
              (selectedCategory.get('to')
                ? selectedCategory.get('to') > selectedCategory.get('from')
                : true))
          );
      });
      return collise ? 'error' : '';
    }

    asterisk() {
      return <span style={{ color: 'red' }}>*</span>;
    }

    showHelpInfo(field) {
      const { t } = this.props;
      if (
        field === 'range' &&
        this.validateCategory() &&
        this.state.hasChanges
      ) {
        return (
          <div id="invalidFieldMessage" className="help-message">
            {t('Range must by unique')}
          </div>
        );
      }
      return null;
    }

    inputProps(field) {
      const { updatePriceCategory } = this.props;
      return {
        value: this.getProperty(field),
        onChange: (value) => {
          this.setState({ hasChanges: true });
          updatePriceCategory(field, +value);
        }
      };
    }

    setRoundUp(value) {
      const { updatePriceCategory } = this.props;
      if (this.getProperty('roundUp') === value) {
        updatePriceCategory('roundUp', null);
      } else {
        updatePriceCategory('roundUp', value);
      }
    }

    getProductUnits() {
      const { t, units, unitCodes, user } = this.props;
      return getProductUnits({ t, units, unitCodes, user, shortLabels: true });
    }

    handleClick() {
      const { hideModalDialog, savePriceCategory, price, getPriceCategories } =
        this.props;

      savePriceCategory().then(() => {
        getPriceCategories(price.get('id'));
        hideModalDialog();
      });
    }

    render() {
      const {
        t,
        captionKey,
        hideModalDialog,
        saveDisabled,
        updatePriceCategory,
        selectedCategory,
        setSelectedCategory
      } = this.props;
      console.log(this.props);

      if (!selectedCategory.size) return null;

      return (
        <Modal
          id="productCharacteristicsModalWindow"
          onHide={() => {
            hideModalDialog();
          }}
          show>
          <Modal.Header>
            <Modal.Title>
              {t(captionKey)}
              <span
                className="modal-header-cross pull-right"
                onClick={() => {
                  hideModalDialog();
                  setSelectedCategory(Map());
                }}
              />
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form horizontal>
              <FormGroup
                controlId="discount"
                validationState={this.validateCategory()}>
                <Col
                  componentClass={ControlLabel}
                  xs={8}
                  className="modal-label">
                  {`${t('Range quantity text')} `}
                  {this.asterisk()}
                </Col>
                <div className="col-modal xs-4" style={{ display: 'flex' }}>
                  <div style={{ padding: '5px 0 0 0' }}>
                    <div style={{ padding: '0 5px 0 0' }}>{t('minvalue')}</div>
                  </div>
                  <InputNumber
                    min={1}
                    defaultValue={selectedCategory.get('from')}
                    {...this.inputProps('from')}
                    width={70}
                  />
                  <div style={{ padding: '5px 0 0 0' }}>
                    <div style={{ padding: '0 5px' }}>{t('maxvalue')}</div>
                  </div>
                  <InputNumber
                    min={0}
                    defaultValue={selectedCategory.get('to')}
                    {...this.inputProps('to')}
                    width={70}
                  />
                </div>
                {this.showHelpInfo('range')}
              </FormGroup>
              <FormGroup controlId="units">
                <Col
                  componentClass={ControlLabel}
                  xs={8}
                  className="modal-label">
                  {t('Units')}
                </Col>
                <div className="col-modal xs-4">
                  <Select
                    value={selectedCategory && selectedCategory.get('unit')}
                    onChange={(e) =>
                      updatePriceCategory('unit', e.value ? `${e.value}` : null)
                    }
                    options={this.getProductUnits()}
                    placeholder={t('not selected')}
                    clearable={false}
                    searchable={false}
                  />
                </div>
              </FormGroup>
              <FormGroup
                controlId="discount"
                validationState={this.getDiscountValidationState()}>
                <Col
                  componentClass={ControlLabel}
                  xs={8}
                  className="modal-label">
                  {t('discount')}
                </Col>
                <div className="col-modal xs-4">
                  <InputGroup>
                    <InputNumber
                      defaultValue={this.getProperty('discount')}
                      value={this.getProperty('discount')}
                      min={0}
                      max={100}
                      onChange={(e) => {
                        this.setState({ hasChanges: true });
                        updatePriceCategory('discount', e);
                      }}
                      width={146}
                      className="catalog-basic-information"
                    />
                    <InputGroup.Addon>%</InputGroup.Addon>
                  </InputGroup>
                </div>
              </FormGroup>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button
              id="cancel"
              className="pull-left"
              onClick={() => hideModalDialog()}>
              {t('Cancel')}
            </Button>
            <Button
              id="saveCharacteristicButton"
              bsStyle="primary"
              disabled={saveDisabled || this.validateCategory()}
              onClick={this.handleClick}>
              {t('Save')}
            </Button>
          </Modal.Footer>
        </Modal>
      );
    }
  }
);

const mapStateToProps = (state) => {
  const selectedCategory = state.getIn(['prices', 'selectedCategory']);
  return {
    user: state.getIn(['user', 'user']),
    unitCodes: state.getIn(['stocks', 'unitCodes']),
    units: state.getIn(['prices', 'units']),
    language: state.getIn(['user', 'user', 'language']),
    price: state.getIn(['prices', 'price']),
    priceCategories: state.getIn(['prices', 'priceCategories']),
    selectedCategories: state.getIn(['prices', 'selectedCategories']),
    selectedCategory,
    saveDisabled: !(
      selectedCategory &&
      selectedCategory.get('from') &&
      (selectedCategory.get('to') >= selectedCategory.get('from') ||
        +selectedCategory.get('to') === 0) &&
      selectedCategory.get('discount') < 100 &&
      selectedCategory.get('discount') >= 0
    )
  };
};

export default connect(mapStateToProps, (dispatch) =>
  bindActionCreators(
    {
      updatePriceCategory: priceActions.updatePriceCategory,
      savePriceCategory: priceActions.savePriceCategory,
      setSelectedCategory: priceActions.setSelectedCategory,
      getPriceCategories: priceActions.getPriceCategories,
      hideModalDialog: modalActionCreators.hideModalDialog
    },
    dispatch
  )
)(ModalEditPriceQuantityCategory);
