import React from 'react';
import { useSelector } from 'react-redux';
import { useActions } from '@hooks';
import SidebarNavMenu from '../SidebarNavMenu';
import { NAV_PANEL_LS_KEY } from './constants';
import * as getters from './getters';
import * as actions from './actions';
import useNavigationsList from './logic';

const MessengerNavSidebar = () => {
  const { navListMenu } = useNavigationsList();

  const mode = useSelector((state) => getters.getNavSidebarMode(state));
  const { toggleNavSidebarMode } = useActions(actions);

  return (
    <SidebarNavMenu
      lsKey={NAV_PANEL_LS_KEY}
      mode={mode}
      toggleMode={toggleNavSidebarMode}
      navigationsList={navListMenu}
    />
  );
};

export default MessengerNavSidebar;
