import { curry, pipe } from 'ramda';
import { Map, List } from 'immutable';
import { NUMBER_OF_DIGITS, TYPES_OF_CATEGORY } from './constans';

const fractionalDigitsByNumber = (number) => number.toString().length - 1;
const ifZeroToInfinity = (number) => (number === 0 ? Infinity : number);

// TODO: go to store section
const adapterPurchaseCategories = (category) =>
  category
    .set('discount', Number(category.get('discount')))
    .set(
      'fractionalDigits',
      fractionalDigitsByNumber(
        category.get('roundUp') || NUMBER_OF_DIGITS.HUNDREDS
      )
    )
    .set('to', ifZeroToInfinity(category.get('to')));

export const emptyPriceCategory = Map({
  minOrder: 0,
  maxOrder: 0,
  discount: 0,
  name: '',
  id: -1,
  roundUp: 100,
  fractionalDigits: NUMBER_OF_DIGITS.HUNDREDS
});
export const isEmptyPriceCategory = (category) =>
  emptyPriceCategory.equals(category);

export const getPriceCategories = (state) => {
  if (state instanceof Map) {
    // TODO: remove this one, pass state with resource top
    return state
      .getIn(['publicPrices', 'purchaseCategories'])
      .map(adapterPurchaseCategories);
  }
  return state.publicPrices.purchaseCategories().map(adapterPurchaseCategories);
};

const getPriceCatsByType = curry((type, categories) =>
  categories.filter((category) => category.get('type') === type)
);

export const getPriceCatsTypeSumOfOrder = pipe(
  getPriceCategories,
  getPriceCatsByType(TYPES_OF_CATEGORY.SUM_OF_ORDER)
);

export const getPriceCatsTypeAmountOfProduct = pipe(
  getPriceCategories,
  getPriceCatsByType(TYPES_OF_CATEGORY.AMOUNT_OF_PRODUCT_ORDER)
);

const adapterSpectialConditions = (condition) =>
  condition.set('discount', +condition.get('discount'));

const adapterSpectialDraftConditions = (state, id) => {
  const condition = new Map();
  const category = state
    .getIn(['publicPrices', 'purchaseCategories'])
    .find((i) => i.get('id') === +id);
  return condition.set('discount', category ? +category.get('discount') : 0);
};

export const getPriceCatsTypeSectialConditions = (state) => {
  if (state instanceof Map) {
    // TODO: remove this one, pass state with resource top
    return state
      .getIn(['orders', 'order', 'priceCategoryConditions'])
      .map(adapterSpectialConditions);
  }
  return state.orders
    .order()
    .get('priceCategoryConditions')
    .map(adapterSpectialConditions);
};

export const getOrderCatsTypeSectialConditions = (state) => {
  if (
    !state.getIn(['ordersCombine', 'orderDraft', 'priceCategoryConditions'])
  ) {
    return List();
  }
  if (state instanceof Map) {
    // TODO: remove this one, pass state with resource top
    return state
      .getIn(['ordersCombine', 'orderDraft', 'priceCategoryConditions'])
      .map((i) => adapterSpectialDraftConditions(state, i));
  }
  return state.orderDraft
    .get('priceCategoryConditions')
    .map((i) => adapterSpectialDraftConditions(state, i));
};
