import React from 'react';
import classNames from 'classnames';
import config from '../../config';
import { convertUrl } from '../../../../components/ChatWidget/modules/files/utils';

const Background = ({ show = false, avatar }) => {
  const backgroundSrc = avatar ? convertUrl(avatar) : config.defaultAvatar;

  return (
    <img
      className={classNames({
        'video-chat__background': true,
        show
      })}
      src={backgroundSrc}
      alt="background"
    />
  );
};

export default Background;
