import React from 'react';
import {
  Modal,
  Col,
  Form,
  FormGroup,
  FormControl,
  ControlLabel,
  Button
} from 'react-bootstrap';
import { connect } from 'react-redux';
import { translate } from 'react-i18next';
import * as modalActionCreators from '../../action-creators/modal';
import * as featureActionCreators from '../../action-creators/product/feature';

const ModalAddFeature = translate(['ui'], { wait: true })(
  class extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        name: '',
        unit: '',
        value: ''
      };
      this.modalsProcess = this.modalsProcess.bind(this);
    }

    UNSAFE_componentWillMount() {
      const { name, unit = '' } = this.props;
      if (name) {
        this.setState({ name, unit });
      }
    }

    getProperty(field) {
      return this.props.feature.has(field) ? this.props.feature.get(field) : '';
    }

    validationState(field) {
      return this.props.validator &&
        this.props.validator.hasIn(['invalidFields', field])
        ? { validationState: 'error' }
        : {};
    }

    asterisk() {
      return <span style={{ color: 'red' }}>*</span>;
    }

    allowSave() {
      return this.state.value;
    }

    async modalsProcess() {
      const {
        selected,
        hideModalDialog,
        modalAddFeature,
        addExistedFeatures,
        clearSelectedFeatures,
        getProductFeatures,
        catalogId,
        categoryId,
        productId
      } = this.props;
      const feature = selected.find((i) => !i.get('value'));
      if (feature) {
        await hideModalDialog();
        modalAddFeature(
          feature.get('name'),
          feature.get('unit'),
          catalogId,
          categoryId,
          productId
        );
      } else {
        await addExistedFeatures(catalogId, productId, selected);
        await clearSelectedFeatures();
        await getProductFeatures(catalogId, categoryId, productId);
        hideModalDialog();
      }
    }

    render() {
      const {
        t,
        captionKey,
        hideModalDialog,
        updateSelectedFeatureValue,
        trimProductFeatureField,
        selectFeature
      } = this.props;

      const inputProps = (field) => ({
        value: this.state[field],
        onChange: (e) => {
          this.setState({ [field]: e.target.value });
          updateSelectedFeatureValue(
            this.state.name,
            this.state.unit,
            e.target.value
          );
        },
        onBlur: () => trimProductFeatureField(field)
      });

      return (
        <Modal
          id="productCharacteristicsModalWindow"
          onHide={() => hideModalDialog()}
          show>
          <Modal.Header>
            <Modal.Title>
              {t(captionKey)}
              <span
                className="modal-header-cross pull-right"
                onClick={async () => {
                  await selectFeature(this.state);
                  this.modalsProcess();
                }}
              />
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form horizontal>
              <FormGroup controlId="name">
                <Col
                  componentClass={ControlLabel}
                  xs={5}
                  style={{ textAlign: 'left' }}>
                  {`${t('Name')} `}
                  {this.asterisk()}
                </Col>
                <Col xs={7}>
                  <FormControl type="text" value={this.state.name} disabled />
                </Col>
              </FormGroup>
              <FormGroup controlId="value" {...this.validationState('value')}>
                <Col
                  componentClass={ControlLabel}
                  xs={5}
                  style={{ textAlign: 'left' }}>
                  {`${t('Value')} `}
                  {this.asterisk()}
                </Col>
                <Col xs={7}>
                  <FormControl type="text" {...inputProps('value')} />
                </Col>
              </FormGroup>
              <FormGroup controlId="unit">
                <Col
                  componentClass={ControlLabel}
                  xs={5}
                  style={{ textAlign: 'left' }}>
                  {t('Units')}
                </Col>
                <Col xs={7}>
                  <FormControl type="text" value={this.state.unit} disabled />
                </Col>
              </FormGroup>
              <FormGroup controlId="unit" style={{ marginBottom: 0 }}>
                <Col
                  componentClass={ControlLabel}
                  xs={5}
                  style={{
                    fontSize: '12px',
                    color: 'lightgrey',
                    textAlign: 'left'
                  }}>
                  {this.asterisk()}
                  {` ${t('Required fields')}`}
                </Col>
              </FormGroup>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button
              onClick={async () => {
                await selectFeature(this.state);
                this.modalsProcess();
              }}
              className="pull-left">
              {t('Cancel')}
            </Button>
            <Button
              id="saveCharacteristicButton"
              bsStyle="primary"
              disabled={!this.allowSave()}
              onClick={() => this.modalsProcess()}>
              {t('Save')}
            </Button>
          </Modal.Footer>
        </Modal>
      );
    }
  }
);

export default connect(
  (state) => ({
    catalog: state.getIn(['catalog', 'item']),
    product: state.getIn(['product', 'item']),
    validator: state.get('validator'),
    listOfFeatures: state.getIn(['tables', 'common', 'items']),
    selected: state.getIn(['catalog', 'selectedFeatures'])
  }),
  { ...modalActionCreators, ...featureActionCreators }
)(ModalAddFeature);
