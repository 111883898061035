import { useCallback, useEffect, useState } from 'react';

export function useSelectIdsWithCheckbox({
  initialCheckboxValue = false,
  initialSelectedList = []
}) {
  const [selectedList, setSelectedList] = useState(initialSelectedList);
  const [isChecked, setChecked] = useState(initialCheckboxValue);

  const onToggle = useCallback(() => {
    setChecked((prevState) => !prevState);
  }, []);

  useEffect(() => {
    if (isChecked) {
      setSelectedList([]);
    }
  }, [isChecked]);

  return {
    selectedList,
    setSelectedList,
    isChecked,
    onToggle
  };
}
