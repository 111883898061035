import React from 'react';
import { Typography } from '@link/react-components';
import cn from 'classnames';
import { useTranslate } from '../../../../TranslateProvider';
import styles from './Groups.module.css';

function Group({ name }) {
  return (
    <Typography variant="body1Med" className={styles.group}>
      {name}
    </Typography>
  );
}

export function Groups({ groups }) {
  const t = useTranslate();
  return (
    <div className={styles.container}>
      <div className={styles.groups}>
        <Typography variant="body1Med" className={styles.title}>
          {t('modules.company.card.groups')}:
        </Typography>
        <div
          className={cn(styles.list, {
            [styles.emptyGroups]: !groups.length
          })}>
          {groups.length ? (
            groups.map((group, index) => (
              <Group name={group.name} key={group.name + index} />
            ))
          ) : (
            <Typography className={styles.emptyGroupsText} variant="body1Reg">
              {' '}
              {t('modules.company.card.emptyGroups')}
            </Typography>
          )}
        </div>
      </div>
    </div>
  );
}
