import { useEffect, useRef, useState } from 'react';

export function useFetch(url, options) {
  const cache = useRef({});

  const cancelRequest = useRef(false);

  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    async function fetchData() {
      try {
        setLoading(true);
        setData(null);
        setError(null);

        const response = await fetch(url, options);

        if (!response.ok) {
          throw new Error(response.statusText);
        }

        const resData = await response.json();
        cache.current[url] = resData;

        if (cancelRequest.current) return;

        setData(resData);
      } catch (e) {
        console.error(e);
        setError(e);
      } finally {
        setLoading(false);
      }
    }

    if (!cache.current[url]) {
      fetchData();
    }

    return () => {
      cancelRequest.current = true;
    };
  }, [url]);

  return { data, loading, error };
}
