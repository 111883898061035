import React from 'react';
import './style.css';

const Message = ({ message }) => (
  <div className="email-message">
    <div>{`Дата: ${message.date}`}</div>
    <div>{`От: ${message.from}`}</div>
    <div>{`Кому: ${message.to}`}</div>
    <div>{`Тема письма: ${message.subject}`}</div>
    <div>{message.text}</div>
  </div>
);

export default Message;
