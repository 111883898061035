const { makePolicy } = require('../../data/policy');

// Section name
const STOCKS = 'STOCKS';

// Use cases
const VIEW_STOCKS = 'VIEW_STOCKS';
const VIEW_PRODUCTS = 'VIEW_PRODUCTS';
const IMPORT_PRODUCTS = 'IMPORT_PRODUCTS';
const EXPORT_PRODUCTS = 'EXPORT_PRODUCTS';
const CHANGE_INFORMATION = 'CHANGE_INFORMATION';
const UPDATE_PRODUCTS_ON_STOCK = 'UPDATE_PRODUCTS_ON_STOCK';
const ADD_STOCK = 'ADD_STOCK';
const REMOVE_STOCK = 'REMOVE_FROM_STOCK';
const ADD_PRODUCT = 'ADD_PRODUCT';
const REMOVE_PRODUCT = 'REMOVE_PRODUCT';

const settings = [
  makePolicy(STOCKS, VIEW_STOCKS, {
    reader: true,
    writer: true,
    advanced: true
  }),
  makePolicy(STOCKS, VIEW_PRODUCTS, {
    reader: true,
    writer: true,
    advanced: true
  }),
  makePolicy(STOCKS, IMPORT_PRODUCTS, {
    reader: false,
    writer: true,
    advanced: true
  }),
  makePolicy(STOCKS, EXPORT_PRODUCTS, {
    reader: false,
    writer: true,
    advanced: true
  }),
  makePolicy(STOCKS, CHANGE_INFORMATION, {
    reader: false,
    writer: true,
    advanced: true
  }),
  makePolicy(STOCKS, UPDATE_PRODUCTS_ON_STOCK, {
    reader: false,
    writer: true,
    advanced: true
  }),
  makePolicy(STOCKS, ADD_STOCK, {
    reader: false,
    writer: true,
    advanced: true
  }),
  makePolicy(STOCKS, REMOVE_STOCK, {
    reader: false,
    writer: false,
    advanced: true
  }),
  makePolicy(STOCKS, ADD_PRODUCT, {
    reader: false,
    writer: true,
    advanced: true
  }),
  makePolicy(STOCKS, REMOVE_PRODUCT, {
    reader: false,
    writer: true,
    advanced: true
  })
];

module.exports = {
  STOCKS,
  VIEW_STOCKS,
  VIEW_PRODUCTS,
  IMPORT_PRODUCTS,
  EXPORT_PRODUCTS,
  CHANGE_INFORMATION,
  UPDATE_PRODUCTS_ON_STOCK,
  ADD_STOCK,
  REMOVE_STOCK,
  ADD_PRODUCT,
  REMOVE_PRODUCT,
  settings
};
