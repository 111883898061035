const mainPath = (state) =>
  state.getIn(['ordersCombine', 'tableOrdersCompany']);

const serilizeData = (state) => {
  const data = mainPath(state).get('data');
  const selected = mainPath(state).get('selected');
  const badges = mainPath(state).get('badges');

  return (
    data &&
    data.map((item) => {
      const id = item.get('id');
      const hasId = selected.has(id);
      const badgeStatus =
        badges &&
        badges.find((badge) => badge.get('typeId') === item.get('id'));

      return item.set('isSelected', hasId).set('hasChangeBadge', badgeStatus);
    })
  );
};

const enabledFilter = (filters) =>
  filters.companies.length > 0 ||
  filters.currencies.length > 0 ||
  filters.orderDate.from !== '' ||
  filters.orderDate.to !== '' ||
  filters.shipmentDate.from !== '' ||
  filters.shipmentDate.to !== '' ||
  filters.statuses.length > 0 ||
  filters.responsibles.length > 0 ||
  filters.paid.from !== '' ||
  filters.paid.to !== '' ||
  filters.sumOrder.from !== '' ||
  filters.sumOrder.to !== '';

const getSelectedOrder = (state) => {
  const selected = mainPath(state).get('selected');
  const data = serilizeData(state);

  return (
    (data && data.find((order) => order.get('id') === selected.first())) ||
    Map()
  );
};

export default (state) => ({
  data: () => serilizeData(state),
  selected: () => mainPath(state).get('selected'),
  isEnabledFilterSelected: () => mainPath(state).get('isEnabledFilterSelected'),
  ids: () => mainPath(state).get('ids'),
  isSelectedAll: () =>
    mainPath(state)
      .get('ids')
      .every((orderId) => mainPath(state).get('selected').includes(orderId)),
  firstSelectedOrder: () => getSelectedOrder(state),
  badges: () => mainPath(state).get('badges'),
  filters: () => mainPath(state).get('filters'),
  enabledFilter: () => enabledFilter(mainPath(state).get('filters')),
  booted: () => mainPath(state).get('booted'),
  totalOrders: () => mainPath(state).get('totalOrders'),
  limit: () => mainPath(state).get('limit'),
  offset: () => mainPath(state).get('offset'),
  search: () => mainPath(state).get('search'),
  statuses: () => mainPath(state).get('statuses'),
  currencies: () => mainPath(state).get('currencies'),
  responsibles: () => mainPath(state).get('responsibles'),
  supplierNames: () => mainPath(state).get('supplierNames'),
  excluded: () => mainPath(state).get('excluded')
});
