import { Map, fromJS } from 'immutable';
import { getPrevMonthDate } from '../utils/date';

const newGraphData = () => new Map({ orders: [], customers: [] });
const newData = () =>
  new Map({
    orders: new Map([]),
    offset: 0,
    size: 0,
    limit: 20
  });
const newInfo = () =>
  new Map({
    orders: { count: {}, cost: {} },
    customerId: '',
    customerName: '',
    customerOrders: [],
    managers: [],
    previousOrderCustomers: []
  });
const newInterval = () => {
  const from = getPrevMonthDate();
  const to = new Date();

  return new Map({
    from: from.toISOString(),
    to: to.toISOString()
  });
};

const initState = () =>
  new Map({
    graph: newGraphData(),
    data: newData(),
    info: newInfo(),
    interval: newInterval(),
    currency: '',
    currencies: []
  });

const setOrderAnalyticsGraphData = (data, { ordersData }) =>
  data.setIn(['graph', 'orders'], ordersData);

const setOrderInfo = (data, { ordersInfo }) =>
  data.setIn(['info', 'orders'], ordersInfo);

const setCustomerAnalyticsGraphData = (data, { graph }) =>
  data.setIn(['graph', 'customers'], graph);

const setCustomerOrdersInfoData = (
  data,
  { orders, previousOrderCustomers, customerId, customerName }
) =>
  data
    .setIn(['info', 'customerOrders'], orders)
    .setIn(['info', 'previousOrderCustomers'], previousOrderCustomers)
    .setIn(['info', 'customerId'], customerId)
    .setIn(['info', 'customerName'], customerName);

const setManagersData = (data, { managers }) =>
  data.setIn(['info', 'managers'], managers);

const setCurrencies = (data, { currencies }) =>
  data.set('currencies', currencies);

const setOrderAnalyticsData = (data, { ordersData, size, limit, offset }) =>
  data
    .setIn(['data', 'orders'], fromJS(ordersData))
    .setIn(['data', 'size'], size)
    .setIn(['data', 'limit'], limit)
    .setIn(['data', 'offset'], offset);

const setDateIntervalFrom = (data, { from }) =>
  data.setIn(['interval', 'from'], from);

const setDateIntervalTo = (data, { to }) => data.setIn(['interval', 'to'], to);

const setCurrency = (data, { currency }) => data.set('currency', currency);

const clearData = (data) =>
  data.update('data', (old) =>
    old.merge(
      Map({
        orders: new Map([]),
        offset: 0,
        size: 0
      })
    )
  );

const analyticsReducer = (state = initState(), { type, payload }) => {
  switch (type) {
    case 'ANALYTICS:ORDER_GRAPH_DATA:SET':
      return setOrderAnalyticsGraphData(state, payload);
    case 'ANALYTICS:ORDER_DATA:SET':
      return setOrderAnalyticsData(state, payload);
    case 'ANALYTICS:ORDERS_INFO:SET':
      return setOrderInfo(state, payload);
    case 'ANALYTICS:CURRENCIES:SET':
      return setCurrencies(state, payload);
    case 'ANALYTICS:CUSTOMERS_GRAPH_DATA:SET':
      return setCustomerAnalyticsGraphData(state, payload);
    case 'ANALYTICS:CUSTOMERS_ORDERS_INFO_DATA:SET':
      return setCustomerOrdersInfoData(state, payload);
    case 'ANALYTICS:MANAGERS_DATA:SET':
      return setManagersData(state, payload);
    case 'ANALYTICS:DATE_INTERVAL_FROM:SET':
      return setDateIntervalFrom(state, payload);
    case 'ANALYTICS:DATE_INTERVAL_TO:SET':
      return setDateIntervalTo(state, payload);
    case 'ANALYTICS:CURRENCY:SET':
      return setCurrency(state, payload);
    case 'ANALYTICS:ORDER_DATA:CLEAR':
      return clearData(state, payload);
    default:
      return state;
  }
};

export default analyticsReducer;
