import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { fromJS } from 'immutable';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { translate } from 'react-i18next';

import { withRouter } from '../../hoc/withRouter';
import { isMessengerService } from '../../lib/goodwix';

import Table from '../../components/TableNew';
import ButtonIcon from '../../components/ChatWidget/ButtonIcon';

import * as modalActions from '../../action-creators/modal';
import * as companyActions from '../../action-creators/company';
import * as serviceActions from '../../action-creators/services';
import * as ChatWidgetUC from '../../components/ChatWidget/useCases';

class TableOfEmployees extends Component {
  constructor(props) {
    super(props);
    this.onSelect = this.onSelect.bind(this);
    this.getConfigColumns = this.getConfigColumns.bind(this);
    this.displayData = this.displayData.bind(this);
  }

  componentWillUnmount() {
    this.props.clearSelectedEmployee();
  }

  onSelect(id) {
    const { listOfUsers, onSelect } = this.props;
    const selectedUser = listOfUsers.find((user) => user.get('id') === id);
    onSelect(selectedUser);
  }

  getConfigColumns() {
    const {
      t,
      openDialog,
      ownerUserId,
      company,
      user,
      listOfUsers,
      havePermissionToUpdate,
      havePermissionToDelete
    } = this.props;

    const renderColumnEmployeeVisibility = ({ employeeVisibility }) => {
      if (employeeVisibility === 'hidden-from-other-companies') {
        return t('Hidden from all');
      }
      if (employeeVisibility === 'visible-to-all') {
        return t('Visible to all');
      }
      return '-';
    };

    const listColumns = [
      Table.ColText({
        accessor: (item) => {
          const { id, firstName, lastName, deletedAt, blocked } = item;
          if (!(firstName || lastName)) {
            return '-';
          }
          if (blocked) {
            return `${lastName} ${firstName} (${t('blocked')})`;
          }
          const isOwner = id === ownerUserId;

          return (
            <a onClick={() => this.showModalShowEmployee(item)}>
              {/* eslint-disable-line */}
              {`${lastName} ${firstName}${
                deletedAt
                  ? ` (${t('inactive')})`
                  : ` ${isOwner ? `(${t('Owner')})` : ''}`
              }`}
            </a>
          );
        },
        title: t('Full Name'),
        width: '30%'
      }),
      // login column
      Table.ColText({
        accessor: 'position',
        title: t('Position'),
        width: '15%'
      }),
      Table.ColText({
        accessor: 'department',
        title: t('department'),
        width: '15%'
      }),
      Table.ColText({
        accessor: 'email',
        title: t('email'),
        width: '15%'
      }),
      Table.ColText({
        accessor: 'phoneNumber',
        title: t('Phone number'),
        width: 140
      }),
      Table.ColText({
        accessor: renderColumnEmployeeVisibility,
        title: t('Employee visibility'),
        width: 168
      }),
      Table.ColText({
        showTooltip: false,
        accessor: (data) =>
          user.get('id') !== data.id && !data.deletedAt ? (
            <div style={{ margin: '-8px 0 -16px' }}>
              <ButtonIcon
                disabled={data.isBlockedMe || data.isBlockedContact}
                icon="message-1"
                onClick={() => openDialog(data.employeeId)}
              />
            </div>
          ) : (
            ' '
          ),
        width: 38,
        options: {
          noEllipsis: true
        }
      })
    ];

    if (!isMessengerService()) {
      listColumns.splice(
        1,
        0,
        Table.ColText({
          accessor: ({ nickname }) =>
            `${company.get('systemId')}.${nickname}` || '-',
          title: t('Name in company'),
          width: '30%'
        })
      );
    }

    if (havePermissionToUpdate || havePermissionToDelete) {
      listColumns.push(
        Table.ColBtnMenu({
          list: (item) => {
            const list = [];
            if (havePermissionToUpdate) {
              list.push({
                icon: '/img/edit.svg',
                name: t('Edit employee'),
                style: { minWidth: '160px' },
                onClick: () => this.showModalEditEmployee(item)
              });
            }

            const isOwner = item.id === ownerUserId;
            if (!isOwner && havePermissionToDelete) {
              list.push({
                icon: '/img/delete.svg',
                name: t('Delete employee'),
                disabled: listOfUsers.size === 1 || user.get('id') === item.id,
                onClick: () =>
                  confirm(t('Delete?')) &&
                  this.removeEmployee(item.id) /* eslint-disable-line */
              });
            }
            return list;
          }
        })
      );
    }

    return listColumns;
  }

  showModalEditEmployee(selectedEmployee) {
    const { showModal, hideModalDialog, editEmployee, getListOfUsers } =
      this.props;
    showModal('EDIT_EMPLOYEE', {
      title: 'Edit employee',
      hideModalDialog,
      editEmployee,
      getListOfUsers,
      selectedEmployee: fromJS(selectedEmployee)
    });
  }

  showModalShowEmployee(selectedEmployee) {
    const { showModal, hideModalDialog, editEmployee, getListOfUsers } =
      this.props;
    showModal('SHOW_EMPLOYEE', {
      title: 'Employee',
      hideModalDialog,
      editEmployee,
      getListOfUsers,
      selectedEmployee: fromJS(selectedEmployee)
    });
  }

  async removeEmployee(id) {
    await this.props.removeEmployee(id);
    await this.props.getListOfUsers();
    await this.props.clearSelectedEmployee();
  }

  displayData() {
    const { listOfUsers, selected } = this.props;

    return listOfUsers
      .filter((u) => u.get('deletedAt') === null)
      .map((user) => {
        const id = user.get('id');
        const hasId = !!selected.find((sid) => sid === id);
        return hasId
          ? user.set('isSelected', true)
          : user.set('isSelected', false);
      });
  }

  render() {
    const { havePermissionToUpdate } = this.props;

    return (
      <Table
        columns={this.getConfigColumns()}
        onSelect={havePermissionToUpdate && this.onSelect}
        data={this.displayData()}
      />
    );
  }
}

TableOfEmployees.propTypes = {
  t: PropTypes.func.isRequired,
  clearSelectedEmployee: PropTypes.func.isRequired,
  listOfUsers: PropTypes.any.isRequired,
  selected: PropTypes.any.isRequired,
  havePermissionToUpdate: PropTypes.bool,
  havePermissionToDelete: PropTypes.bool,
  user: PropTypes.object.isRequired,
  company: PropTypes.object.isRequired,
  showModal: PropTypes.func.isRequired,
  hideModalDialog: PropTypes.func.isRequired,
  editEmployee: PropTypes.func.isRequired,
  getListOfUsers: PropTypes.func.isRequired,
  onSelect: PropTypes.func.isRequired,
  ownerUserId: PropTypes.number,
  removeEmployee: PropTypes.func.isRequired
};

TableOfEmployees.defaultProps = {
  havePermissionToUpdate: false,
  havePermissionToDelete: false,
  ownerUserId: null
};

export default withRouter(
  connect(
    (state) => ({
      user: state.getIn(['user', 'user']),
      company: state.get('company'),
      listOfUsers: state.getIn(['company', 'listOfUsers']),
      ownerUserId: state.getIn(['company', 'ownerUserId']),
      selected: state.getIn(['company', 'selectedEmployee']),
      havePermissionToUpdate: state.getIn([
        'user',
        'user',
        'permissions',
        'employees',
        'update'
      ])
    }),
    (dispatch) =>
      bindActionCreators(
        {
          showModal: modalActions.showModal,
          hideModalDialog: modalActions.hideModalDialog,
          gaSend: serviceActions.gaSend,
          onSelect: companyActions.selectEmployee,
          getListOfUsers: companyActions.getListOfUsers,
          clearSelectedEmployee: companyActions.clearSelectedEmployee,
          removeEmployee: companyActions.removeEmployee,
          editEmployee: companyActions.editEmployee,
          openDialog: ChatWidgetUC.openDialogChatM
        },
        dispatch
      )
  )(translate(['ui'], { wait: true })(TableOfEmployees))
);
