import React from 'react';
import cn from 'classnames';
import styles from './Cell.module.css';

export function Cell({ className, center = false, children }) {
  return (
    <div
      className={cn(styles.cell, className, {
        [styles.center]: center
      })}>
      {children}
    </div>
  );
}
