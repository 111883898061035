import React, { useEffect, Fragment } from 'react';
import { connect } from 'react-redux';
import { translate } from 'react-i18next';
import { bindActionCreators } from 'redux';
import { withRouter } from '../hoc/withRouter';
import MessengerOther from '../components/Layouts/MessengerOther';
import WorkspaceHeader from '../components/WorkspaceHeader/WorkspaceHeader';
import FormRequisites from '../components/Forms/FormRequisites';

import * as companyActions from '../action-creators/company';

const MCompanyRequisites = ({ t, getCompany, history }) => {
  useEffect(() => {
    getCompany();
  }, []);
  const title = t('Data about Company');
  const breadcrumbs = [{ name: t('Data about Company') }];
  const navigationTabs = {
    activeKey: 'requisites',
    items: [
      { eventKey: 'edit', name: t('Company') },
      { eventKey: 'requisites', name: t('Requisites') },
      { eventKey: 'contacts', name: t('Contacts') }
    ],
    handleTabChange: (tab) => history.push(`/company/${tab}`)
  };

  return (
    <MessengerOther
      title={title}
      breadcrumbs={breadcrumbs}
      content={
        <>
          <WorkspaceHeader navigation={navigationTabs} />
          <div className="form-content-center">
            <FormRequisites />
          </div>
        </>
      }
    />
  );
};

export default connect(
  (state) => ({
    company: state.get('company')
  }),
  (dispatch) =>
    bindActionCreators(
      {
        getCompany: companyActions.getCompany
      },
      dispatch
    )
)(withRouter(translate()(MCompanyRequisites)));
