import React from 'react';
import PropTypes from 'prop-types';

const Sidebar = ({ children, mods, style }, { b }) => (
  <div className={b(mods)} style={style}>
    {children}
  </div>
);

Sidebar.contextTypes = {
  b: PropTypes.func
};

export default Sidebar;
