import request from '../lib/request';

class EmployeesService {
  employeesUrl = '/api/companies/employees';

  async getUsers() {
    return request.get(this.employeesUrl);
  }
}

export const employeesService = new EmployeesService();
