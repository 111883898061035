import React from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { Flex } from '../../../styledComponents/atoms';

import MessengerHeader from './MessengerHeader';
import MessengerSubHeader from './MessengerSubHeader';
import MessengerBody from './MessengerBody';
import MessengerFooter from './MessengerFooter';
import {
  getCurrentChatMode,
  maybeGetOpenedChatId
} from '../../ChatWidget/getter';

const Messenger = () => {
  const currentMode = useSelector(getCurrentChatMode);
  const maybeChatId = useSelector(maybeGetOpenedChatId);

  return (
    <Container>
      <MessengerHeader maybeChatId={maybeChatId} />
      <MessengerSubHeader currentMode={currentMode} />
      <MessengerBody currentMode={currentMode} />
      {currentMode === 'none' && <MessengerFooter />}
    </Container>
  );
};

export default React.memo(Messenger);

const Container = styled(Flex)`
  position: relative;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background: ${(p) => p.theme.colors.white};
  box-shadow: 0 6px 10px rgba(118, 123, 146, 0.2);
  border-radius: 8px;
`;
