import React, { useEffect, useRef, useState } from 'react';
import { translate } from 'react-i18next';
import DasboardCommon from '../../main/dashboard-common';
import Button from '../../ButtonIcon';
import PickDate from '../../Datepicker/datepicker';
import { makeDateRange } from '../../../../lib/query';

const getClassName = (config) =>
  `cwm-search-header cwm-search-header_theme_${config.theme}`;

const transformArrayDateRange = (arrayDateRange) =>
  makeDateRange({ from: arrayDateRange[0], to: arrayDateRange[1] });

const CWMSearchHeader = ({
  t,
  setSearchQuery,
  searchQuery,
  isActiveCalendar,
  setDateRange,
  onClose,
  onSearch,
  config
}) => {
  const searchInput = useRef(null);
  const [isShow, setShow] = useState(false);
  const onCalendar = () => setShow(!isShow);
  const inputValue = searchQuery;
  const onTyping = (e) => setSearchQuery(e.target.value);
  const onSetDateRange = (dateRange) => {
    setDateRange(transformArrayDateRange(dateRange));
    setShow(false);
  };

  useEffect(() => {
    if (searchInput.current) {
      searchInput.current.focus();
    }
  }, []);

  const onKeyDown = (e) => {
    if (e.key === 'Enter') {
      onSearch();
    }
  };

  return (
    <DasboardCommon theme={config.commonDashboardTheme}>
      <div className={getClassName(config)}>
        <div className="cwm-search-header__left">
          <img
            className="cwm-search-header__magnifier"
            alt="magnifier"
            src="/img/cw-magnifier.svg"
            onClick={() => onSearch()}
          />
          <input
            ref={searchInput}
            className="cwm-search-header__input"
            onChange={onTyping}
            value={inputValue}
            placeholder={t('chat-widget.section.search-mode.placeholder')}
            onKeyDown={onKeyDown}
          />
        </div>
        <div className="cwm-search-header__right">
          <Button
            icon="calendar"
            tooltip="select-date"
            active={isActiveCalendar}
            onClick={onCalendar}
          />
          <div className="cwm-search-header__datepicker">
            <PickDate
              isShow={isShow}
              setShow={setShow}
              setDateRange={onSetDateRange}
            />
          </div>
          <div className="cwm-search-header__btn-separator" />
          <div className="cwm-search-header__close-wrapper" onClick={onClose}>
            <Button icon="cross" tooltip="close" width={14} height={14} />
          </div>
        </div>
      </div>
    </DasboardCommon>
  );
};

export default translate()(CWMSearchHeader);
