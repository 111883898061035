import fetch from 'isomorphic-fetch';

const FILES_CONTENT_TYPES = ['application/octet-stream', 'application/zip'];

const downloadFileHandler = (response) => {
  let filename = decodeURIComponent(
    response.headers.get('content-disposition').split('filename=')[1]
  );

  if (filename[0] === '"' && filename[filename.length - 1] === '"') {
    filename = filename.slice(1, -1);
  }

  response.blob().then((blob) => {
    const url = window.URL.createObjectURL(blob);
    // window.location.assign(url);
    // TODO: позволяет выбрать имя архива
    const a = document.createElement('a');
    a.href = url;
    a.download = filename;
    document.body.appendChild(a);
    a.click();
    a.remove();
  });
};

const responseHandler = async (response) => {
  const contentType = response.headers.get('content-type');

  if (!contentType) return Promise.resolve();

  if (contentType.includes('text/html') || contentType.includes('text/plain')) {
    if (response.status >= 400) {
      return Promise.reject();
    }
    return Promise.resolve();
  }
  if (contentType.includes('application/json')) {
    if (response.status >= 400) {
      return Promise.reject(await response.json());
    }
    return response.json();
  }
  if (FILES_CONTENT_TYPES.includes(contentType)) {
    return downloadFileHandler(response);
  }

  return Promise.reject(
    new TypeError(`Oops, we haven't got JSON! Your type is: ${contentType}`)
  );
};

const commonOptions = {
  credentials: 'include',
  headers: { 'Content-Type': 'application/json' }
};

const constructorFetch =
  (method) =>
  async (url, body, options = {}) => {
    const init = { ...commonOptions, ...options, method };
    if (body) {
      init.body = JSON.stringify(body);
    }
    const response = await fetch(url, init);
    return responseHandler(response);
  };

const sendFile = async (url, body) => {
  const init = { credentials: 'include', method: 'POST', body };
  const response = await fetch(url, init);

  if (response.status >= 400) {
    return Promise.reject(await response.json());
  }
  const json = await response.json();
  return json;
};

export default {
  get: constructorFetch('GET'),
  post: constructorFetch('POST'),
  patch: constructorFetch('PATCH'),
  put: constructorFetch('PUT'),
  delete: constructorFetch('DELETE'),
  copy: constructorFetch('COPY'),
  sendFile
};
