import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { translate } from 'react-i18next';
import { bindActionCreators } from 'redux';
import { withRouter } from '../hoc/withRouter';
import * as requestsActions from '../action-creators/purchaseRequests';

import MessengerOther from '../components/Layouts/MessengerOther';

import ContainersDefault from '../components/Containers/ContainersDefault';
import NewRequest from '../components/PurchaseRequests/NewRequest';

import { formatRequestNumber } from '../utils/utils';
import Loader from '../components/Loader';

const EditPurchaseRequest = ({
  getMyRequest,
  request,
  t,
  history,
  match: {
    params: { requestId }
  }
}) => {
  useEffect(() => {
    getMyRequest(requestId);
  }, []);

  if (!request || !request.get('id')) {
    return <Loader />;
  }

  const breadcrumbs = [
    {
      name: t('Purchase requests'),
      element: (
        <a
          onClick={() =>
            history.push({
              pathname: '/requests/my',
              state: { myRequestsPage: true }
            })
          }>
          {t('Purchase requests')}
        </a>
      ),
      disabled: !requestId
    },
    { name: `${t('Request')} № ${formatRequestNumber(requestId)}` }
  ];

  return (
    <MessengerOther
      title={t('Purchase requests')}
      breadcrumbs={breadcrumbs}
      content={
        <ContainersDefault>
          <NewRequest request={request.toJS()} />
        </ContainersDefault>
      }
    />
  );
};

export default withRouter(
  connect(
    (state) => ({
      request: state.getIn(['purchaseRequests', 'request'])
    }),
    (dispatch) =>
      bindActionCreators(
        {
          getMyRequest: requestsActions.getMyRequest
        },
        dispatch
      )
  )(translate(['ui'], { wait: true })(EditPurchaseRequest))
);
