/* eslint-disable react/no-children-prop */
import React, { Suspense, useEffect, useState } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { Provider, useSelector } from 'react-redux';
import { I18nextProvider } from 'react-i18next';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { Loader } from '@link/react-components';
import 'react-select/dist/react-select.css';
import 'bootstrap/dist/css/bootstrap.css';
import 'antd/dist/antd.css';
import './css/common.styl';
import 'react-components/src/styles/components.css';
import { ThemeProvider } from 'styled-components';
// eslint-disable-next-line import/no-named-as-default
import theme from 'styledComponents/theme';
import GlobalStyles from 'styledComponents/globalStyles';
import { useLocation, useParams } from '@hooks';
import LocalProvider from './localProvider';
import CatchErrors from './CatchErrors';
import ConnectionsWrapper from './ConnectionsWrapper';
import {
  getIsUserUploaded,
  haveReadPurchaseRequestsPermission,
  haveUpdatePurchaseRequestsPermission,
  isUserNotHaveCompany
} from './storeGetters';
import PageLoader from './components/Loader/PageLoader';

import i18n from './i18n';
import MainFeedbackFrom from './components/Main/MainFeedbackForm';
import MainComingSoon from './components/Main/MainComingSoon';
import MainNotPermission from './components/Main/MainNotPermission';
import MCompanyEdit from './pages/MCompanyEdit';
import MCompanyRequisites from './pages/MCompanyRequisites';
import MCompanyContacts from './pages/MCompanyContacts';
import MessengerResetPass from './pages/MessengerResetPass';
import MessengerResetPassPerfrom from './pages/MessengerResetPassPerform';
import MessengerVerifyEmail from './pages/MessengerVerifyEmail';
import MessengerInvalidLink from './pages/MessengerInvalidLink';
import MessengerReg from './pages/MessengerReg';
import MEmployees from './pages/MEmployees';
import MProfileEdit from './pages/MProfileEdit';
import MFileStorage from './pages/MFileStorage';
import Verify from './pages/InviteTokenVerification';
import ClearDataPage from './pages/ClearData';
import PartnerInvite from './pages/PartnerInvite';
import MFileStorageFileInfo from './pages/MFileStorageFileInfo';
import MPurchaseRequest from './pages/MPurchaseRequest';
import MAllPurchaseRequests from './pages/MAllPurchaseRequests';
import MEditPurchaseRequest from './pages/MEditPurchaseRequest';
import MPurchaseRequestResponses from './pages/MPurchaseRequestResponses';
import MCreatePurchaseRequest from './pages/MCreatePurchaseRequest';
import MMyPurchaseRequests from './pages/MMyPurchaseRequests';
import MMyResponses from './pages/MMyResponses';
import MResponsePurchaseRequest from './pages/MResponsePurchaseRequest';
import MSubscribePurchaseRequests from './pages/MSubscribePurchaseRequests';
import MessengerPage from './pages/MessengerPage';
import MessengerAuth from './pages/MessengerAuth';
import RedirectPage from './components/ChatWidget/modules/redirectPage';
import MFileStorageUploads from './pages/MFileStorageUploads';
import MFileStorageChat from './pages/MFileStorageChat';
import { CompanyProfilePage as NewCompanyProfile } from './pages/messenger/companyProfile';
import MCompareResponses from './pages/messenger/compareResponses';
import TranslateProvider from './TranslateProvider';
import TaskRoutes from './tasks-manager/routes';
import MPurchaseRequestShare from './pages/MPurchaseRequestShare';
import VideoChatProvider from './modules/VideoChat/VideoChatProvider';
import { ProtectedRoute } from './components/PrivateRoute';
import { changePartOfPath } from './utils/utils';
import MPurchaseResponse from './pages/messenger/purchaseResponses/one';
import MEmailMessages from './pages/messenger/emailMessages';
import MExternalAPI from './pages/messenger/externalAPI';
import { ConstructorRouters } from './routes/constructorRouter';
import WithConfirmationRequestPage from './pages/withConfirmationRequest';
import { CompaniesRouter } from './routes/companiesRouter';
import { SettingsPage } from './pages/messenger/settings';
import { SubscriptionRefusal } from './pages/SubscriptionRefusal';

const Profile = () => (
  <div style={{ width: '100%', height: '100%' }}>
    <Routes>
      <Route path="edit" element={<MProfileEdit />} />
    </Routes>
  </div>
);

const Company = () => (
  <div style={{ width: '100%', height: '100%' }}>
    <Routes>
      <Route path="edit" element={<MCompanyEdit />} />
      <Route path="requisites" element={<MCompanyRequisites />} />
      <Route path="contacts" element={<MCompanyContacts />} />
    </Routes>
  </div>
);

const FileStorage = () => (
  <div style={{ width: '100%', height: '100%' }}>
    <Routes>
      <Route path="uploads" element={<MFileStorage section="uploads" />} />
      <Route
        path="uploads/purchaserequest/attach/:requestId"
        element={<MFileStorageUploads />}
      />
      <Route
        path="chat/purchaserequest/attach/:requestId"
        element={<MFileStorageChat />}
      />
      <Route path="chat" element={<MFileStorage section="chat" />} />
      <Route
        path="file/:section/:fileId"
        element={<MFileStorageFileInfo section="chat" />}
      />
    </Routes>
  </div>
);

const Share = () => (
  <Routes>
    <Route path="requests/:requestId" element={<MPurchaseRequestShare />} />
  </Routes>
);

const Requests = () => {
  const { pathname } = useLocation();
  const { requestId } = useParams();
  const haveUpdatePermission = useSelector(
    haveUpdatePurchaseRequestsPermission
  );

  return (
    <div style={{ width: '100%', height: '100%' }}>
      <Routes>
        <Route path="all/:requestId" element={<MPurchaseRequest />} />
        <Route path="all" element={<Navigate to="/" replace />} />
        <Route
          path="all/my-requests/create"
          element={<MCreatePurchaseRequest link="/requests/all" />}
        />
        <Route
          path="my/:requestId/responses/:companyId"
          element={
            <NewCompanyProfile
              name="Responses"
              link={`/requests/my/${requestId}/responses`}
            />
          }
        />
        <Route
          path="my/:requestId/edit"
          element={
            <ProtectedRoute
              isAllowed={haveUpdatePermission}
              redirectPath={changePartOfPath(pathname, 'edit', 'view')}
              children={<MEditPurchaseRequest />}
            />
          }
        />
        <Route
          path="my/:requestId/responses"
          element={<MPurchaseRequestResponses />}
        />
        <Route path="my/:requestId/view" element={<MPurchaseRequest />} />
        <Route
          path="my/:requestId/viewConfirmation"
          element={<WithConfirmationRequestPage />}
        />
        <Route
          path="my/create"
          element={<MCreatePurchaseRequest link="/requests/my" />}
        />
        <Route path="my" element={<MMyPurchaseRequests />} />
        <Route path="my-responses" element={<MMyResponses />} />
        <Route
          path="my-responses/request/:requestId"
          element={
            <MPurchaseRequest
              name="Purchase requests"
              link="/requests/my-responses"
            />
          }
        />
        <Route
          path="my-responses/request/:requestId/response/:responseId"
          element={
            <MPurchaseResponse
              name="Purchase requests"
              link="/requests/my-responses"
            />
          }
        />
        <Route
          path="my-responses/company/:companyId"
          element={
            <NewCompanyProfile
              title="Purchase requests"
              name="Purchase requests"
              link="/requests/my-responses"
            />
          }
        />
        <Route
          path="my-responses/my-requests/create"
          link="/requests/my-responses"
          element={<MCreatePurchaseRequest link="/requests/my-responses" />}
        />
        <Route
          path="responses-purchase-requests/:responseId/request/:requestId"
          element={<MResponsePurchaseRequest />}
        />
        <Route
          path="responses-purchase-requests/company/:companyId"
          element={
            <NewCompanyProfile
              title="Purchase requests"
              name="Purchase requests"
              link="/requests/my"
            />
          }
        />
        <Route
          path="responses-purchase-requests/my-requests/create"
          element={<MCreatePurchaseRequest link="/requests/my" />}
        />
        <Route
          path="customers/:companyId/view"
          element={
            <NewCompanyProfile name="Purchase requests" link="/requests/all" />
          }
        />
        <Route path="compare/:requestId" element={<MCompareResponses />} />
      </Routes>
    </div>
  );
};

const RoutesComponent = () => {
  const isUserUploaded = useSelector(getIsUserUploaded);
  const isUserWithoutCompany = useSelector(isUserNotHaveCompany);
  const haveReadPermissionPurchaseRequest = useSelector(
    haveReadPurchaseRequestsPermission
  );

  const [loading, setLoading] = useState(!isUserUploaded);

  useEffect(() => {
    setLoading(!isUserUploaded);
  }, [isUserUploaded]);

  if (loading) {
    return <Loader />;
  }

  if (isUserWithoutCompany) {
    return (
      <Routes>
        <Route path="/company/edit" element={<MCompanyEdit />} />
        <Route path="/share/*" element={<Share />} />
        <Route
          path="/requests/share/:requestId"
          element={<MPurchaseRequestShare />}
        />
        <Route path="/login" element={<MessengerAuth />} />
        <Route
          path="/reset-password/perform"
          element={<MessengerResetPassPerfrom />}
        />
        <Route path="/reset-password" element={<MessengerResetPass />} />
        <Route path="/verify-email" element={<MessengerVerifyEmail />} />
        <Route path="/invalid-link" element={<MessengerInvalidLink />} />
        <Route path="/registration" element={<MessengerReg />} />
        <Route path="/registration/:token" element={<MessengerReg />} />
        <Route path="/feedback" element={<MainFeedbackFrom />} />
        <Route path="/coming-soon" element={<MainComingSoon />} />
        <Route path="/not-permission" element={<MainNotPermission />} />
        <Route path="/clear-data" element={<ClearDataPage />} />
        <Route path="/unsubscribe/:token" element={<SubscriptionRefusal />} />
        <Route path="*" element={<Navigate to="/company/edit" replace />} />
      </Routes>
    );
  }

  return (
    <Routes>
      <Route path="/constructor/*" element={<ConstructorRouters />} />
      <Route path="/companies/*" element={<CompaniesRouter />} />
      <Route path="/settings" element={<SettingsPage />} />
      <Route
        path="/reset-password/perform"
        element={<MessengerResetPassPerfrom />}
      />
      <Route path="/reset-password" element={<MessengerResetPass />} />
      <Route path="/verify-email" element={<MessengerVerifyEmail />} />
      <Route path="/invalid-link" element={<MessengerInvalidLink />} />
      <Route path="/registration" element={<MessengerReg />} />
      <Route path="/registration/:token" element={<MessengerReg />} />
      <Route
        path="/welcome/registration/:tokenLink"
        element={<MessengerReg />}
      />
      <Route
        path="/welcome/partner/registration/:partnerToken"
        element={<MessengerReg />}
      />
      <Route path="/welcome/login/:tokenLink" element={<MessengerAuth />} />
      <Route
        path="/welcome/partner/login/:partnerToken"
        element={<MessengerAuth />}
      />
      <Route path="/login" element={<MessengerAuth />} />
      <Route path="/" element={<MAllPurchaseRequests />} />
      <Route path="/chat" element={<MessengerPage />} />
      <Route path="/chat/:chatId" element={<MessengerPage />} />
      <Route path="/chat/join/:employeeId" element={<MessengerPage />} />
      <Route path="/task/*" element={<TaskRoutes />} />
      <Route
        path="/employees"
        element={<Navigate to="/employees/list" replace />}
      />
      <Route path="/employees/*" element={<MEmployees />} />
      <Route path="/profile/*" element={<Profile />} />
      <Route path="/company/*" element={<Company />} />
      <Route path="/file-storage/*" element={<FileStorage />} />
      <Route
        path="/requests"
        element={<Navigate to="/requests/all" replace />}
      />
      <Route
        path="/requests/share/:requestId"
        element={<MPurchaseRequestShare />}
      />
      <Route path="requests/all/:requestId" element={<MPurchaseRequest />} />
      <Route
        path="requests/subscribe"
        element={<MSubscribePurchaseRequests />}
      />
      <Route
        path="/requests/*"
        element={
          <ProtectedRoute
            isAllowed={haveReadPermissionPurchaseRequest}
            redirectPath="/"
            children={<Requests />}
          />
        }
      />

      <Route path="/share/*" element={<Share />} />
      <Route path="/dialogs/welcome/:token" element={<PartnerInvite />} />
      <Route path="/welcome/:companyId/:tokenLink" element={<Verify />} />
      <Route path="/feedback" element={<MainFeedbackFrom />} />
      <Route path="/coming-soon" element={<MainComingSoon />} />
      <Route path="/not-permission" element={<MainNotPermission />} />
      <Route path="/clear-data" element={<ClearDataPage />} />
      <Route path="/test/email-messages" element={<MEmailMessages />} />
      <Route path="/external-api-settings" element={<MExternalAPI />} />
      <Route path="/redirect/:type/:id" element={<RedirectPage />} />
      <Route path="/unsubscribe/:token" element={<SubscriptionRefusal />} />
      <Route path="*" element={<Navigate to="/" replace />} />
    </Routes>
  );
};

export default (props) => (
  <I18nextProvider i18n={i18n}>
    <CatchErrors>
      <ThemeProvider theme={theme}>
        <Provider store={props.store}>
          <LocalProvider history={props.history}>
            <TranslateProvider>
              <DndProvider backend={HTML5Backend}>
                <Suspense fallback={<PageLoader />}>
                  <ConnectionsWrapper>
                    <div
                      style={{
                        width: '100%',
                        height: '100%',
                        overflowX: 'hidden',
                        position: 'relative'
                      }}>
                      <VideoChatProvider>
                        <RoutesComponent />
                      </VideoChatProvider>
                    </div>
                  </ConnectionsWrapper>
                </Suspense>
              </DndProvider>
              <GlobalStyles />
            </TranslateProvider>
          </LocalProvider>
        </Provider>
      </ThemeProvider>
    </CatchErrors>
  </I18nextProvider>
);
