import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import block from 'bem-cn-lite';
import { translate } from 'react-i18next';
import { connect } from 'react-redux';
import { GridPage, Header, Main, Navbar, Content, Modal } from '../elements';
import './grid-page_theme_default.styl';
// TODO: delete MainContentStyles.css or to move in common.styl
import '../../../css/MainContentStyles.styl';
import CompHeader from '../../Header/Header';
import Navigation from '../../Navigation/Navigation';
// import ChatWidget from '../../ChatWidget';
/* import CompSuggestionBtn from '../../SuggestionBtn'; */
import CompModalRoot from '../../ModalRoot';
import { getIsUserUploaded } from '../../../storeGetters';
import PageLoader from '../../Loader/PageLoader';
import { isCurrentPath } from '../../../utils';

const b = block('grid-page');

class GridPageThemeDefault extends Component {
  getChildContext() {
    return { b };
  }

  get pageDetails() {
    const { t, pageDetails } = this.props;

    if (!pageDetails) return {};

    return { ...pageDetails, title: t(pageDetails.title) };
  }

  render() {
    const {
      header,
      navbar,
      content,
      modal,
      contentStyle,
      withoutNavbar = false,
      isUploaded,
      clickableLogo
    } = this.props;

    if (!isUploaded && !isCurrentPath('/prices/:priceId/share/:token'))
      return <PageLoader {...this.pageDetails} />;

    return (
      <GridPage mods={{ theme: 'default' }}>
        <Helmet {...this.pageDetails} />
        <Header>
          {header || <CompHeader clickableLogo={clickableLogo} />}
        </Header>
        <Main>
          {!withoutNavbar && (
            <Navbar>{navbar || <Navigation path={location.pathname} />}</Navbar>
          )}
          <Content style={contentStyle}>{content}</Content>
        </Main>
        <Modal>{modal || <CompModalRoot />}</Modal>
        {/* <ChatWidget /> */}
      </GridPage>
    );
  }
}

GridPageThemeDefault.childContextTypes = {
  b: PropTypes.func
};

export default connect((state) => ({
  isUploaded: getIsUserUploaded(state)
}))(translate(['ui'], { wait: true })(GridPageThemeDefault));
