import React from 'react';
import Helmet from 'react-helmet';
import cn from 'classnames';

import styles from './Layout.module.css';
import { isFullMode } from '../../components/SidebarNavMenu/utils';
import { Sidebar } from './sidebar/Sidebar';
import { Header } from './header/Header';
import { useNavPanelMode } from '../../components/MessengerNavSidebar/hooks';
import ModalRoot from '../../components/ModalRoot';
import Message from '../../components/Message';

// Now for company panel

export const AppLayout = ({ children, title, theme = 'gray' }) => {
  const navPanelMode = useNavPanelMode();

  return (
    <>
      <Helmet defer={false} title={title} />
      <div className={styles.layout}>
        <Header />
        <div
          className={cn(
            styles.content,
            isFullMode(navPanelMode)
              ? 'layout-simple__content-full'
              : 'layout-simple__content-short'
          )}>
          <Sidebar />
          <div className={styles.mainContent}>
            <div className={cn(styles.sectionContent)}>
              <div
                className={cn(
                  styles.innerContent,
                  `layout-simple__inner-content_${theme}`
                )}>
                {children}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Message />
      <ModalRoot />
    </>
  );
};
