import { useEffect, useRef, useState } from 'react';
import { filterItems, toggleItem } from './helper';

const [UP, DOWN, ENTER] = [38, 40, 13];
const SHOW_LIMIT = 5;

const useSelect = ({
  t,
  list,
  onChoose,
  onClickByIcon,
  selectedList,
  disabled,
  showLimit = SHOW_LIMIT,
  noResultPlaceholder
}) => {
  const [value, setValue] = useState('');
  const [isOpened, setIsOpened] = useState(false);
  const [isFocused, setIsFocused] = useState(false);
  const [menuOptions, setMenuOptions] = useState([]);
  const [focusedId, setFocusedId] = useState(0);

  const inputRef = useRef();

  const onToggle = (itemId) => () => {
    if (disabled) return;

    onChoose(toggleItem({ selectedList, itemId }));
    setValue('');
    setIsOpened(false);
  };

  useEffect(() => {
    if (value.length === 0) {
      setMenuOptions([]);
      return;
    }

    const filteredOptions = filterItems({ list, value })
      .filter((option) => !selectedList.includes(option.value))
      .slice(0, showLimit)
      .map((option, index) => ({
        label: option.label,
        onClick: onToggle(option.value),
        isFocused: focusedId === index
      }));

    if (filteredOptions.length > 0) {
      setMenuOptions(filteredOptions);
    } else {
      setMenuOptions([{ label: noResultPlaceholder || t('noResultsText') }]);
    }

    setIsOpened(true);
  }, [value, focusedId]);

  const onChange = ({ target }) => !disabled && setValue(target.value);

  const onKeyDown = ({ which }) => {
    if (!isOpened || menuOptions.length === 0 || disabled) {
      return;
    }

    switch (which) {
      case UP:
        setFocusedId((5 + (focusedId - 1)) % 5);
        break;
      case DOWN:
        setFocusedId((focusedId + 1) % 5);
        break;
      case ENTER:
        menuOptions[focusedId].onClick();
        break;
      default:
        break;
    }
  };

  const onFocus = () => {
    if (inputRef.current?.focus) {
      inputRef.current.focus();
      setIsFocused(true);
    }
  };

  const onBlur = () => setIsFocused(false);

  const onOpenModal = () => {
    if (disabled) return;

    onClickByIcon({ chosenCompanies: selectedList, onChoose });
  };

  return {
    onChange,
    onOpenModal,
    value,
    isOpened,
    setIsOpened,
    menuOptions,
    onToggle,
    onFocus,
    isFocused,
    onBlur,
    inputRef,
    onKeyDown
  };
};

export default useSelect;
