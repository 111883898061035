import React, { useCallback } from 'react';
import { assocPath, path } from 'ramda';
import { Loader } from '@link/react-components';
import { useSettings } from './hooks';
import { PurchaseRequestsSection, ChatSection } from './components';
import { notificationSettingsService } from '../../api/notificationSettingsService';
import styles from './Content.module.css';

const PURCHASE_REQUESTS_SECTION_KEY = 'purchaseRequests';
const CHAT_SECTION_KEY = 'chat';

export function Content() {
  const { loading, settings, setSettings, error } = useSettings();

  const onChangeSettings = useCallback((pathList) => {
    setSettings((prevSettings) => {
      const newValue = !path(pathList, prevSettings);
      const newSettings = assocPath(pathList, newValue, prevSettings);

      notificationSettingsService.update(newSettings);

      return newSettings;
    });
  }, []);

  const onChangePurchaseRequestSettings = useCallback(
    (event) => {
      onChangeSettings([PURCHASE_REQUESTS_SECTION_KEY, event.target.id]);
    },
    [onChangeSettings]
  );

  const onChangeChatSettings = useCallback(
    (event) => {
      onChangeSettings([CHAT_SECTION_KEY, event.target.id]);
    },
    [onChangeSettings]
  );

  if (loading) {
    return <Loader />;
  }

  if (error) {
    console.error(error);
    // TODO нужен экран для случая возникновения ошибки
    return <Loader />;
  }

  return (
    <div className={styles.container}>
      <PurchaseRequestsSection
        settings={settings.purchaseRequests}
        onChange={onChangePurchaseRequestSettings}
      />
      <hr className={styles.divider} />
      <ChatSection settings={settings.chat} onChange={onChangeChatSettings} />
    </div>
  );
}
