const { makePolicy } = require('../../data/policy');

// Section name
const BUYERS = 'BUYERS';

// Use cases
const VIEW_CUSTOMERS_WITHOUT_RESTRICTIONS =
  'VIEW_CUSTOMERS_WITHOUT_RESTRICTIONS';
const ASSIGN_RESPONSIBLE = 'ASSIGN_RESPONSIBLE';
const VIEW_CUSTOMERS_WITH_DIFF_ACCESS = 'VIEW_CUSTOMERS_WITH_DIFF_ACCESS';
const VIEW_CUSTOMER_LIST = 'VIEW_CUSTOMER_LIST';
const VIEW_CUSTOMER_PROFILE = 'VIEW_CUSTOMER_PROFILE';
const INVITE_CUSTOMER = 'INVITE_CUSTOMER';
const EDIT_GROUPS = 'EDIT_GROUPS';
const ADD_GROUP = 'ADD_GROUP';
const EDIT_GROUP = 'EDIT_GROUP';
const DELETE_GROUP = 'DELETE_GROUP';
const REMOVE_BUYER = 'REMOVE_BUYER';

const settings = [
  makePolicy(BUYERS, VIEW_CUSTOMERS_WITHOUT_RESTRICTIONS, {
    reader: false,
    writer: false,
    advanced: true
  }),
  makePolicy(BUYERS, ASSIGN_RESPONSIBLE, {
    reader: false,
    writer: false,
    advanced: true
  }),
  makePolicy(BUYERS, VIEW_CUSTOMERS_WITH_DIFF_ACCESS, {
    reader: true,
    writer: true,
    advanced: true
  }),
  makePolicy(BUYERS, VIEW_CUSTOMER_LIST, {
    reader: true,
    writer: true,
    advanced: true
  }),
  makePolicy(BUYERS, VIEW_CUSTOMER_PROFILE, {
    reader: false,
    writer: true,
    advanced: true
  }),
  makePolicy(BUYERS, INVITE_CUSTOMER, {
    reader: false,
    writer: true,
    advanced: true
  }),
  makePolicy(BUYERS, EDIT_GROUPS, {
    reader: false,
    writer: true,
    advanced: true
  }),
  makePolicy(BUYERS, ADD_GROUP, {
    reader: false,
    writer: true,
    advanced: true
  }),
  makePolicy(BUYERS, EDIT_GROUP, {
    reader: false,
    writer: true,
    advanced: true
  }),
  makePolicy(BUYERS, DELETE_GROUP, {
    reader: false,
    writer: true,
    advanced: true
  }),
  makePolicy(BUYERS, REMOVE_BUYER, {
    reader: false,
    writer: false,
    advanced: true
  })
];

module.exports = {
  BUYERS,
  VIEW_CUSTOMERS_WITHOUT_RESTRICTIONS,
  ASSIGN_RESPONSIBLE,
  VIEW_CUSTOMERS_WITH_DIFF_ACCESS,
  VIEW_CUSTOMER_LIST,
  VIEW_CUSTOMER_PROFILE,
  INVITE_CUSTOMER,
  EDIT_GROUPS,
  ADD_GROUP,
  EDIT_GROUP,
  DELETE_GROUP,
  REMOVE_BUYER,
  settings
};
