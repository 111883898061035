/* eslint-disable max-len */
import React from 'react';

const TrashIcon = ({ className }) => (
  <svg
    className={className}
    width="14"
    height="18"
    viewBox="0 0 14 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 3H2V16C2 16.5523 2.44772 17 3 17H11C11.5523 17 12 16.5523 12 16V3ZM1 2V16C1 17.1046 1.89543 18 3 18H11C12.1046 18 13 17.1046 13 16V2H1Z"
      fill="#767B92"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14 3H0V2H14V3Z"
      fill="#767B92"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10 1H4V0H10V1Z"
      fill="#767B92"
    />
  </svg>
);

export default TrashIcon;
