.button {
  position: relative;
  display: inline-block;
  box-sizing: border-box;
  text-decoration: none;
  border: none;
  border-radius: var(--button-border-radius);
  margin: 0;
  padding: 0;
  user-select: none;
  max-width: 100%;
}

.loading {
  cursor: progress;
}

.stretched {
  display: block;
  width: 100%;
  flex-grow: 1;
  flex-basis: 0;
}

.in {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: inherit;
  text-align: center;
  box-sizing: border-box;
}

.button-size-large {
  height: 34px;
}

.button[disabled] {
  opacity: 0.5;
}

.content {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.button-size-large .content:first-child {
  padding-left: var(--indent-m);
}

.after,
.before {
  display: flex;
}

.button-size-large .content:last-child,
.button-size-large .after {
  padding-right: var(--indent-m);
}

.button-size-large .before {
  padding-left: var(--indent-m);
}

.button-size-small {
  height: 25px;
}

.button-size-small .content:first-child {
  padding-left: var(--indent-m);
}

.button-size-small .content:last-child,
.button-size-small .after {
  padding-right: var(--indent-m);
}

.button-size-small .before {
  padding-left: var(--indent-m);
}

.before:not(:last-child) + .content {
  margin-left: var(--indent-xs);
}

.after:not(:first-child) {
  margin-left: var(--indent-xs);
}

.single-icon .after,
.single-icon .before {
  margin-right: auto;
  margin-left: auto;
  padding-right: var(--indent-xs);
  padding-left: var(--indent-xs);
}

.button-size-small .before:not(:last-child) + .content {
  margin-left: var(--indent-xxs);
}

.button-size-small .after:not(:first-child) {
  margin-left: var(--indent-xxs);
}

/* Primary button */
.button-mode-primary {
  background-color: var(--bg-button-primary);
  color: var(--text-color-button-primary);
}

.button-mode-primary.hover {
  background-color: var(--bg-button-primary-hover);
  color: var(--text-color-button-primary-hover);
}

.button-mode-primary.hover svg path {
  fill: var(--text-color-button-primary-hover);
  stroke: var(--text-color-button-primary-hover);
}

.button-mode-primary.active {
  background-color: var(--bg-button-primary-active);
  color: var(--text-color-button-primary-active);
}

.button-mode-primary.active svg path {
  fill: var(--text-color-button-primary-active);
  stroke: var(--text-color-button-primary-active);
}

.button-mode-primary[disabled] {
  background-color: var(--bg-button-primary-disabled);
  color: var(--text-color-button-primary-disabled);
}

.button-mode-primary[disabled] svg path {
  fill: var(--text-color-button-primary-disabled);
  stroke: var(--text-color-button-primary-disabled);
}

/* Outline button */
.button-mode-outline {
  background-color: var(--bg-button-outline);
  color: var(--text-color-button-outline);
  border: 1px solid var(--border-color-button-outline);
}

.button-mode-outline.hover {
  background-color: var(--bg-button-outline-hover);
  color: var(--text-color-button-outline-hover);
  border: 1px solid var(--border-color-button-outline-hover);
}

.button-mode-outline.hover svg path {
  fill: var(--border-color-button-outline-hover);
  stroke: var(--border-color-button-outline-hover);
}

.button-mode-outline.active {
  background-color: var(--bg-button-outline-active);
  color: var(--text-color-button-outline-active);
  border: 1px solid var(--border-color-button-outline-active);
}

.button-mode-outline.active svg path {
  fill: var(--border-color-button-outline-active);
  stroke: var(--border-color-button-outline-active);
}

.button-mode-outline[disabled] {
  background-color: var(--bg-button-outline-disabled);
  color: var(--text-color-button-outline-disabled);
  border: 1px solid var(--border-color-button-outline-disabled);
}

.button-mode-outline[disabled] svg path {
  fill: var(--border-color-button-outline-disabled);
  stroke: var(--border-color-button-outline-disabled);
}

/* Text button */
.button-mode-text {
  background-color: inherit;
  color: var(--text-color-button-text);
}

.button-mode-text .content:first-child {
  padding-left: var(--indent-xs);
}

.button-mode-text .content:last-child,
.button-mode-text .after {
  padding-right: var(--indent-xs);
}

.button-mode-text .before {
  padding-left: var(--indent-xs);
}

.button-mode-text.hover {
  color: var(--text-color-button-text-hover);
}

.button-mode-text.hover svg path {
  fill: var(--text-color-button-text-hover);
  stroke: var(--text-color-button-text-hover);
}

.button-mode-text.active {
  color: var(--text-color-button-text-active);
}

.button-mode-text.active svg path {
  fill: var(--text-color-button-text-active);
  stroke: var(--text-color-button-text-active);
}

.button-mode-text[disabled] {
  color: var(--text-color-button-text-disabled);
}

.button-mode-text[disabled] svg path {
  fill: var(--text-color-button-text-disabled);
  stroke: var(--text-color-button-text-disabled);
}
