import React, { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import IconMessenger from './messenger';
import Tooltip from '../ChatWidget/Tooltip';
import './style.css';
import { getBadgeCount } from '../../lib/utils';
import { toggleChatWidget } from '../ChatWidget/useCases';
import { isIstockService } from '../../lib/goodwix';

const MessengerButton = () => {
  const dispatch = useDispatch();
  const showChatWidget = useCallback(
    () => dispatch(toggleChatWidget()),
    [dispatch]
  );
  const chatBadgeCount = useSelector((state) =>
    getBadgeCount(state.getIn(['chat', 'chatBadgeCount']))
  );

  return (
    <div
      className={`messenger-button ${
        isIstockService() ? 'messenger-button-down' : ''
      }`}
      onClick={showChatWidget}>
      <Tooltip text="Goodwix Messenger">
        <IconMessenger />
        {chatBadgeCount && (
          <span className="messenger-button__status">{chatBadgeCount}</span>
        )}
      </Tooltip>
    </div>
  );
};

export default MessengerButton;
