// eslint-disable-next-line max-classes-per-file
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { OverlayTrigger, Popover, Tooltip } from 'react-bootstrap';
import block from 'bem-cn-lite';
import { Link } from 'react-router-dom';
import shortid from 'shortid';
import { connect } from 'react-redux';
import { path } from 'ramda';
import hoistNonReactStatics from 'hoist-non-react-statics';

import { fromJS, List } from 'immutable';
import { Interpolate, translate } from 'react-i18next';
import {
  arrayMove,
  SortableContainer,
  SortableElement,
  SortableHandle
} from 'react-sortable-hoc';
import { formatDate } from '../../utils/date';
import { capitalize, isExternalUrl } from '../../utils';
import Checkbox from '../Checkbox';
import Status from '../Status';
import TableItemExport from '../TableItemExport';
import TableItemControl from '../TableItemControl';
import { testClass } from '../../lib';
import ArrowSVG from '../../icons/arrow';
import './style.styl';
import { logView } from '../../utils/logger';

export const SPECIAL_ROW_TYPE = 'special-row';
const b = block('table-new');

// noinspection JSAnnotator
class Column {
  constructor(config) {
    this.title = config.title;
    this.accessor = config.accessor;
    this.tooltipMsg = config.tooltipMsg;
    this.sort = config.sort === false ? config.sort : true;
    this.options =
      {
        noEllipsis: config.noEllipsis,
        ...config.options,
        defaultValue: config.defaultValue,
        showTooltip: config.showTooltip,
        width: config.width,
        color: config.color,
        textAlign: config.textAlign
      } || {};

    this.titleId = shortid.generate();
    this.index = config.index;
  }

  get index() {
    return this._index;
  }

  set index(index = null) {
    if (typeof this.accessor === 'string' && index === null) {
      this._index = this.accessor;
      return;
    }
    this._index = index;
  }

  get showBadge() {
    return this._showBadge;
  }

  set showBadge(show = false) {
    this._showBadge = show;
  }

  get titleId() {
    return this._title_id;
  }

  set titleId(id) {
    // eslint-disable-next-line camelcase
    this._title_id = id;
  }

  get title() {
    return this._title;
  }

  set title(title) {
    this._title = title;
  }

  get sort() {
    return this._sort;
  }

  set sort(sort) {
    this._sort = sort;
  }

  get accessor() {
    return this._accessor;
  }

  set accessor(accessor) {
    this._accessor = accessor;
  }

  get tooltipMsg() {
    return this._tooltipMsg;
  }

  set tooltipMsg(msg) {
    this._tooltipMsg = msg;
  }

  get options() {
    return this._options;
  }

  set options({
    // eslint-disable-next-line no-shadow
    formatDate,
    defaultValue = '-',
    showTooltip = true,
    width = null,
    border = false,
    color = false,
    textAlign = 'left',
    rowAlign = 'left',
    verticalAlign = 'top',
    centered = false,
    noEllipsis = false
  }) {
    this._options = {
      title: {
        mods: { border },
        style: { width, verticalAlign, textAlign }
      },
      value: {
        showTooltip,
        defaultValue,
        formatDate,
        mods: {
          border,
          color,
          centered,
          'no-ellipsis': noEllipsis,
          'text-align': rowAlign
        }
      }
    };
  }

  getTitle() {
    return this.title;
  }

  getValue(item, i) {
    let value = '';

    if (typeof this.accessor === 'function') {
      value = this.accessor(item.toJS(), i);
    } else if (typeof this.accessor === 'string') {
      value = item.getIn(this.accessor.split('.'));
    }

    if (value === undefined || value === null || value === '') {
      return this.options.value.defaultValue;
    }

    return value;
  }

  getTooltipMsg(item) {
    const msg = this.tooltipMsg && this.tooltipMsg(item.toJS());
    return msg === undefined ? this.getValue(item) : msg;
  }

  getReactTooltip(value, item, key) {
    return (
      <OverlayTrigger
        overlay={<Tooltip id={key}>{this.getTooltipMsg(item)}</Tooltip>}
        placement="bottom"
        delayShow={300}
        delayHide={100}>
        <span>{value}</span>
      </OverlayTrigger>
    );
  }

  getReactTitle({ isSorted, onSort, orderDirection, orderBy }) {
    return (
      <th
        key={this.titleId}
        className={b('title', {
          ...this.options.title.mods,
          clickable: isSorted && this.sort
        })}
        onClick={() => this.sort && onSort(this.index)}
        style={this.options.title.style}>
        {this.title === undefined && typeof this.accessor === 'string' ? (
          <Interpolate i18nKey={capitalize(this.accessor)} />
        ) : (
          this.getTitle()
        )}

        {isSorted && this.sort && (
          <span
            className={b('arrow', {
              flip: orderDirection === 'DESC',
              show: orderBy === this.index
            })}
          />
        )}
      </th>
    );
  }

  getReactWrapperValue(item, i) {
    return this.getValue(item, i);
  }

  getReactValue(
    item,
    columnNumber,
    rowNumber,
    badgeColor = 'red',
    totalRowCount
  ) {
    return (
      <td
        key={columnNumber}
        className={b('table-data', this.options.value.mods)}>
        {this.showBadge &&
          item.get('hasChangeBadge') &&
          badgeColor === 'red' && <span className="red-dot" />}
        {this.showBadge &&
          item.get('hasChangeBadge') &&
          badgeColor === 'blue' && <span className="blue-dot" />}
        {this.options.value.showTooltip
          ? this.getReactTooltip(
              this.getReactWrapperValue(item, rowNumber, totalRowCount),
              item,
              columnNumber
            )
          : this.getReactWrapperValue(item, rowNumber, totalRowCount)}
      </td>
    );
  }
}

class ColumnText extends Column {}

// noinspection JSAnnotator
class ColumnLink extends Column {
  constructor(config) {
    super(config);
    this.url = config.url;
    this.blank = config.blank;
    this.onClick = config.onClick;
    this.readOnly = config.readOnly || (() => {});
  }

  get readOnly() {
    return this._readOnly;
  }

  set readOnly(value) {
    this._readOnly = value;
  }

  get blank() {
    return this._blank;
  }

  set blank(blank) {
    this._blank = blank;
  }

  get onClick() {
    return this._onClick;
  }

  set onClick(cb = () => {}) {
    this._onClick = cb;
  }

  get url() {
    return this._url;
  }

  set url(url) {
    if (url === undefined) {
      logView('url must be define');
      return;
    }

    this._url = url;
  }

  getReactWrapperValue(item) {
    if (this.readOnly(item.toJS())) {
      return this.getValue(item);
    }

    const url = this.url(item.toJS());

    if (url === undefined || url === null || url === '') {
      return this.getValue(item);
    }

    if (isExternalUrl(url) || this.blank) {
      return (
        <a
          href={url}
          target="_blank"
          rel="noreferrer noopener"
          onClick={(e) => {
            e.stopPropagation();
            this.onClick(e, item.toJS());
          }}>
          {this.getValue(item)}
        </a>
      );
    }

    return (
      <Link
        to={url}
        target={this.blank ? '_blank' : ''}
        onClick={(e) => {
          this.onClick(e, item.toJS());
        }}>
        {this.getValue(item)}
      </Link>
    );
  }
}

class ColumnDate extends Column {
  getTooltipMsg(item) {
    return formatDate(this.getValue(item), this.options.value.formatDate);
  }

  getReactWrapperValue(item) {
    return formatDate(this.getValue(item), this.options.value.formatDate);
  }
}

class ColumnStatus extends Column {
  constructor(config) {
    super({ ...config });
    this.statusColors = config.statusColors;
  }

  hasText(item) {
    return this.getValue(item).text !== undefined;
  }

  getText(item) {
    if (this.hasText(item)) return this.getValue(item).text;
    return this.getValue(item);
  }

  getStatus(item) {
    if (this.hasText(item)) return this.getValue(item).status;
    return this.getValue(item);
  }

  getTooltipMsg(item) {
    if (this.hasText(item)) return this.getText(item);
    return <Interpolate i18nKey={`Status ${this.getValue(item)}`} />;
  }

  getReactWrapperValue(item) {
    if (this.hasText(item)) {
      return (
        <Status
          text={this.getText(item)}
          status={this.getStatus(item)}
          statusColors={this.statusColors}
        />
      );
    }

    return (
      <Status status={this.getValue(item)} statusColors={this.statusColors} />
    );
  }
}

class ColumnBtnExport extends Column {
  constructor(config) {
    super({
      width: 38,
      ...config,
      showTooltip: false,
      options: { noEllipsis: true, ...config.options }
    });
    this.list = config.list;
    this.isDisable = config.isDisable;
    this.checkEmpty = config.checkEmpty;
  }

  get list() {
    return this._list;
  }

  set list(list) {
    this._list = list;
  }

  get checkEmpty() {
    return this._checkEmpty;
  }

  set checkEmpty(checkEmpty) {
    this._checkEmpty = checkEmpty;
  }

  get isDisable() {
    return this._isDisable;
  }

  set isDisable(value) {
    if (value === undefined) {
      this._isDisable = () => true;
      return;
    }
    this._isDisable = value;
  }

  getReactWrapperValue(item) {
    const isEmpty = this.checkEmpty ? this.checkEmpty(item) : true;
    return this.isDisable(item) && isEmpty ? (
      <TableItemExport items={this.list(item.toJS())} />
    ) : null;
  }
}

class ColumnBtnMenu extends Column {
  constructor(config) {
    super({
      width: 38,
      textAlign: 'right',
      ...config,
      showTooltip: false,
      options: {
        noEllipsis: true,
        ...config.options
      }
    });

    this.isLastItemTopPosition = path(
      ['options', 'isLastItemTopPosition'],
      config
    );
    this.listStyle = path(['options', 'listStyle'], config) || {};

    this.list = config.list;
  }

  get list() {
    return this._list;
  }

  set list(list) {
    this._list = list;
  }

  getReactWrapperValue(item, rowNumber, totalRowCount) {
    let position = 'bottom';

    if (rowNumber !== null && totalRowCount !== null) {
      if (this.isLastItemTopPosition && rowNumber + 1 === totalRowCount) {
        position = 'top';
      }
    }

    return !item.get('disableMenu') ? (
      <TableItemControl
        position={position}
        className={testClass('context', 'menu')}
        listStyle={this.listStyle}
        items={this.list(item.toJS())}
      />
    ) : null;
  }
}

class ColumnCheckbox extends Column {
  constructor(config) {
    super({
      ...config,
      showTooltip: false,
      options: { noEllipsis: true, ...config.options }
    });
    this.onSelect = config.onSelect;
    this.readOnly = config.readOnly || (() => {});
    this.hide = config.hide || (() => {});
  }

  get readOnly() {
    return this._readOnly;
  }

  set readOnly(value) {
    this._readOnly = value;
  }

  get hide() {
    return this._hide;
  }

  set hide(value) {
    this._hide = value;
  }

  get onSelect() {
    return this._onSelect;
  }

  set onSelect(func) {
    this._onSelect = func;
  }

  getReactWrapperValue(item, i) {
    const value = this.getValue(item, i);
    if (this.hide(item.toJS())) {
      return null;
    }

    return (
      <div className="flex-center">
        <Checkbox
          withoutRightMargin
          set={value}
          readOnly={this.readOnly(item.toJS())}
          onClick={() => this.onSelect(item.toJS(), !value)}
        />
      </div>
    );
  }
}

class ColumnCheckboxSelectRow extends Column {
  constructor(config) {
    super({
      width: 36,
      ...config,
      showTooltip: false,
      options: { noEllipsis: true, ...config.options }
    });
    this.onSelect = config.onSelect;
    this.onSelectAll = config.onSelectAll;
    this.isSelectedAll = config.isSelectedAll;
    this.tableRef = React.createRef();
  }

  get onSelect() {
    return this._onSelect;
  }

  set onSelect(func) {
    this._onSelect = func;
  }

  get onSelectAll() {
    return this._onSelectAll;
  }

  set onSelectAll(func) {
    this._onSelectAll = func;
  }

  get isSelectedAll() {
    return this._isSelectedAll;
  }

  set isSelectedAll(all) {
    this._isSelectedAll = all;
  }

  getTitle() {
    if (this.onSelectAll) {
      return (
        <Checkbox
          set={this.isSelectedAll}
          onClick={() => this.onSelectAll()}
          withoutRightMargin
        />
      );
    }
    return null;
  }

  getReactWrapperValue(item) {
    if (item.get('withoutCheckbox')) return null;
    return (
      <Checkbox
        set={!!item.get('isSelected')}
        onClick={() => this.onSelect(item.get('id'), item.toJS())}
        withoutRightMargin
      />
    );
  }
}

class Table extends Component {
  constructor(props) {
    super(props);
    this.state = {
      orderBy: props.orderBy || '',
      orderDirection: props.direction || 'DESC'
    };
    this.onSort = this.onSort.bind(this);
  }

  get columns() {
    const { onSelect, onSelectAll } = this.props;
    const columns = this.props.columns.slice();

    if (onSelect) {
      columns.unshift(
        new ColumnCheckboxSelectRow({
          onSelect,
          onSelectAll,
          isSelectedAll: this.isSelectedAll()
        })
      );
    }

    columns[0].showBadge = true;

    return columns;
  }

  static ColText(config) {
    return new ColumnText(config);
  }

  static ColLink(config) {
    return new ColumnLink(config);
  }

  static ColDate(config) {
    return new ColumnDate(config);
  }

  static ColStatus(config) {
    return new ColumnStatus(config);
  }

  static ColBtnExport(config) {
    return new ColumnBtnExport(config);
  }

  static ColBtnMenu(config) {
    return new ColumnBtnMenu(config);
  }

  static ColCheckbox(config) {
    return new ColumnCheckbox(config);
  }

  UNSAFE_componentWillReceiveProps(props) {
    const { orderBy, direction } = props;
    if (orderBy && direction) {
      this.setState({
        orderBy: orderBy || '',
        orderDirection: direction || 'DESC'
      });
    }
  }

  async onSort(orderBy) {
    if (this.props.onSort && orderBy !== null) {
      await this.toggleDirection(orderBy);
      this.props.onSort(orderBy, this.state.orderDirection);
      this.setState({ orderBy });
    }
  }

  setDefaultDirection() {
    this.setState({ orderDirection: 'DESC' });
  }

  toggleDirection(newOrderBy) {
    return new Promise((resolve) => {
      if (newOrderBy === this.state.orderBy) {
        const orderDirection =
          this.state.orderDirection === 'DESC' ? 'ASC' : 'DESC';
        return this.setState({ orderDirection }, resolve);
      }

      return this.setState({ orderDirection: 'DESC' }, resolve);
    });
  }

  isSelectedAll() {
    const { data, isSelectedAll } = this.props;

    if (isSelectedAll !== undefined) return isSelectedAll;

    const selectedItems = data && data.filter((item) => item.get('isSelected'));

    return data && data.size === selectedItems.size;
  }

  haveCallBackSelect() {
    const { onSelect } = this.props;
    return !!onSelect;
  }

  wrapTooltip(msg, el) {
    return (
      <OverlayTrigger
        overlay={<Popover id={msg}>{msg}</Popover>}
        placement="top"
        delayShow={200}
        delayHide={100}>
        {el}
      </OverlayTrigger>
    );
  }

  renderEmptyTh = ({ width = '20px', borderBottom, ...style } = {}) => (
    <th style={{ width, borderBottom: 'unset', ...style }} /> // eslint-disable-line
  );

  renderTableWrapper = (tableBody) => {
    const {
      theme = 'default',
      ifEmpty,
      columns,
      data,
      onSort,
      mods,
      name = 'one',
      dnd = false
    } = this.props;
    const { orderBy, orderDirection } = this.state;

    if (columns === undefined) {
      console.error('Columns must be define');
    }

    if ((data && data.isEmpty()) || columns === undefined) {
      return ifEmpty || null;
    }

    return (
      <div
        className={b(
          { type: 'default', version: 'new', theme, ...mods },
          testClass('table', name)
        )}>
        {/* eslint-disable-next-line no-return-assign */}
        <table className="table" ref={(ref) => (this.tableRef = ref)}>
          <thead className={b('head')} style={{ cursor: 'default' }}>
            <tr>
              {dnd && this.renderEmptyTh()}
              {this.columns.map((col) =>
                col.getReactTitle({
                  isSorted: !!onSort,
                  onSort: this.onSort,
                  orderBy,
                  orderDirection
                })
              )}
            </tr>
          </thead>
          {tableBody}
        </table>
      </div>
    );
  };

  onSortStart = ({ node, helper }) => {
    this.tableRef.style.cursor = 'move';
    // need to save width for all td into row
    node.childNodes.forEach((td, idx) => {
      helper.childNodes[idx].style.width = `${td.offsetWidth}px`; // eslint-disable-line no-param-reassign
    });
  };

  onSortEnd = ({ oldIndex, newIndex }) => {
    this.tableRef.style.cursor = 'default';
    const { data, onSortDnd } = this.props;
    onSortDnd(List(fromJS(arrayMove(data.toJS(), oldIndex, newIndex))));
  };

  moveUp = (currIndex) => {
    const isFirst = (index) => index === 0;

    const { data } = this.props;
    let newIndex = currIndex - 1;

    if (isFirst(currIndex)) {
      newIndex = data.size - 1;
    }
    this.onSortEnd({ oldIndex: currIndex, newIndex });
  };

  moveDown = (currIndex) => {
    const isLast = (index, list) => index === list.size - 1;

    const { data } = this.props;
    let newIndex = currIndex + 1;

    if (isLast(currIndex, data)) {
      newIndex = 0;
    }
    this.onSortEnd({ oldIndex: currIndex, newIndex });
  };

  renderArrows = (index) => {
    const commonArrowProps = { width: '9', height: '9' };

    return (
      <td className="table-new__table-data table-new__dnd">
        <div className="table-new__dnd_handler">
          <div
            className="table-new__dnd_handler_arrow table-new__dnd_handler_arrow_top"
            onClick={() => this.moveUp(index)}>
            <ArrowSVG {...commonArrowProps} />
          </div>
          <div
            className="table-new__dnd_handler_arrow table-new__dnd_handler_arrow_bot"
            onClick={() => this.moveDown(index)}>
            <ArrowSVG direction="down" {...commonArrowProps} />
          </div>
        </div>
      </td>
    );
  };

  renderRow = (item, rowNumber) => {
    const {
      data,
      badgeColor,
      dnd = false,
      onRowClick = () => {},
      RenderSpecialRow
    } = this.props;

    const DragHandle = SortableHandle(() => this.renderArrows(rowNumber));

    const style = { backgroundColor: item.get('backgroundColor') || '' };

    if (dnd) {
      style.cursor = 'move';
    }

    if (item.get('type') === SPECIAL_ROW_TYPE) {
      return (
        <tr key={rowNumber} className="table-new__special-row">
          <td colSpan={this.columns.length}>
            <RenderSpecialRow content={item.get('content')} />
          </td>
        </tr>
      );
    }

    const row = (
      <tr
        key={rowNumber}
        name={item.get('name') || ''}
        style={style}
        data-id={item.get('id') || ''}
        className={`table-new__table-row ${
          item.get('isSelected') ? 'info' : ''
        }`}
        onClick={() => onRowClick(item.get('id'))}>
        {dnd && <DragHandle />}
        {this.columns.map((col, columnNumber) =>
          col.getReactValue(
            item,
            columnNumber,
            rowNumber,
            badgeColor,
            data.size
          )
        )}
      </tr>
    );
    if (item.get('rowTooltip')) {
      return this.wrapTooltip(item.get('rowTooltip'), row);
    }
    return row;
  };

  renderTable = () => this.renderTableWrapper(this.renderTableBody());

  renderTableBody = () => {
    const { dnd } = this.props;
    if (dnd) return this.renderDNDTableBody();
    return this.renderSimpleTableBody();
  };

  renderSimpleTableBody = () => {
    const {
      data,
      openedRowIds = [],
      renderOpenedRowContent = () => {}
    } = this.props;
    return (
      <tbody className={b('body')}>
        {data.map((item, index) => (
          <>
            {this.renderRow(item, index)}
            {openedRowIds.includes(item.get('id')) &&
              renderOpenedRowContent(item)}
          </>
        ))}
      </tbody>
    );
  };

  renderDNDTableBody = () => {
    const { data } = this.props;

    const TableBody = SortableContainer(({ children }) => (
      <tbody className={b('body')}>{children}</tbody>
    ));

    const TableRow = SortableElement(({ item, rowNumber }) =>
      this.renderRow(item, rowNumber)
    );

    return (
      <TableBody
        onSortStart={this.onSortStart}
        onSortEnd={this.onSortEnd}
        useDragHandle={false} // set true if need drag and drop by only arrow
        distance={2}
        lockAxis="y"
        helperClass={b('dnd_row')}>
        {data.map((item, rowNumber) => (
          <TableRow
            item={item}
            key={rowNumber}
            rowNumber={rowNumber}
            index={rowNumber}
          />
        ))}
      </TableBody>
    );
  };

  render() {
    return this.renderTable();
  }
}

Table.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.object).isRequired,
  data: PropTypes.oneOfType([ImmutablePropTypes.list, ImmutablePropTypes.map])
    .isRequired
};

export default connect(
  (state) => ({ language: state.getIn(['user', 'user', 'language']) }) // NOTE: need to change title column, when user change language
)(hoistNonReactStatics(translate(['ui'])(Table), Table));
