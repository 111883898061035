import fetch from 'isomorphic-fetch';
import customHistory from '../customHistory';
import req from '../utils/request';
import { uploadImage } from './media';
import { showModal } from './modal';
// import { setSuccessMessage, setWarningMessage, setErrorMessage } from './message';
import { setSuccessMessage, setErrorMessage } from './message';
import getBrowserLanguage from '../utils/languageDetector';
import { getCurrentLocation } from '../utils/location';
import { isCurrentPath } from '../utils';
import { isMainService, isMessengerService } from '../lib/goodwix';
import { finishLoader, startLoader } from './loaderList';

const updateUserCompany = (company) => (dispatch) =>
  dispatch({ type: 'USER_UPDATE_COMPANY', company });

export const toggleBlockMeOfCompInfo = ({ employeeId, isBlocked }) => ({
  type: 'COMPANY_INFO:TOGGLE_BLOCK_ME',
  payload: { employeeId, isBlocked }
});

export const toggleBlockMeOfEmployees = ({ employeeId, isBlocked }) => ({
  type: 'COMPANY_EMPLOYEES:TOGGLE_BLOCK_ME',
  payload: { employeeId, isBlocked }
});

export const setCompanyField = (field, value) => ({
  type: 'COMPANY_SET_FIELD',
  field,
  value
});

export const setNewCompanyField = (field, value) => ({
  type: 'COMPANY_NEW_SET_FIELD',
  field,
  value
});

export const setNewCompanyRole = (role, value) => ({
  type: 'COMPANY_NEW_SET_ROLE',
  role,
  value
});

const setCompany = (company) => ({
  type: 'SET_COMPANY',
  company
});

const setListOfUsers = (users) => ({
  type: 'SET_LIST_OF_USERS',
  users
});

export const clearNewCompany = () => ({
  type: 'COMPANY_NEW_CLEAR'
});

export const updateNewCompanyField = (field, value) => (dispatch) =>
  dispatch(setNewCompanyField(field, value));

export const updateNewCompanyRole = (role, value) => (dispatch) =>
  dispatch(setNewCompanyRole(role, value));

export const updateCompanyField = (field, value) => (dispatch) =>
  dispatch(setCompanyField(field, value));

export const trimCompanyField = (field) => ({
  type: 'COMPANY_TRIM_FIELD',
  field
});

const createCompanyBody = (company, type) => ({
  credentials: 'include',
  method: type === 'update' ? 'PATCH' : 'POST',
  headers: { 'Content-Type': 'application/json' },
  body: JSON.stringify({
    name: company.name,
    url: company.url,
    categoriesId: company.categoriesId,
    industry: company.industry,
    email: company.email,
    description: company.description || '',
    logo: company.logo || '',
    systemId: company.systemId,
    roles: company.roles,
    language: company.language
  })
});

export const saveCompany =
  (companyInfo, type = 'create') =>
  (dispatch) =>
    fetch('/api/companies', createCompanyBody(companyInfo, type))
      .then((response) => response.json())
      .then((company) => {
        dispatch(setCompany(company));
        updateUserCompany(company._id)(dispatch);
        return Promise.resolve();
      });

export const saveNewCompany =
  (addToList = true) =>
  (dispatch, getState) =>
    fetch(
      '/api/companies',
      createCompanyBody(getState().getIn(['company', 'newCompany']).toJS())
    )
      .then((response) => response.json())
      .then((company) => {
        if (addToList) {
          dispatch({
            type: 'COMPANY:CREATE:SUCCESS',
            payload: { company: Object.assign(company, { employeesCount: 1 }) }
          });
        }
        dispatch(setCompany(company));
        updateUserCompany(company.id)(dispatch);
      });

export const getListOfUsers = () => (dispatch) => {
  req.get('/api/companies/employees').then((users) => {
    dispatch(setListOfUsers(users));
  });
};

export const assignNewOwner = (newOwnerUserId) => (dispatch) => {
  dispatch({ type: 'COMPANY:ASSIGN_NEW_OWNER:REQUEST' });
  return req
    .post('/api/companies/change-owner', { newOwnerUserId })
    .then(() => {
      dispatch({ type: 'COMPANY:ASSIGN_NEW_OWNER:SUCCESS' });
    })
    .catch(() => {
      dispatch({ type: 'COMPANY:ASSIGN_NEW_OWNER:FAILURE' });
    });
};

export const uploadCompanyImage = (type, file) => (dispatch) => {
  uploadImage(file)
    .then(({ data: { attributes } }) => {
      dispatch(setCompanyField(type, attributes));
    })
    .catch((e) => console.error('FAILED UPDATE CATALOG', e));
};

export const getCompany = () => (dispatch) => {
  req
    .get('/api/companies/')
    .then((userCompany) => {
      if (isMainService()) {
        const nonRedirectLocations = [
          '/help',
          '/demo',
          '/profile/edit',
          '/profile/companies'
        ];
        const currentLocation = getCurrentLocation();
        if (!userCompany) {
          if (isMessengerService()) {
            console.log('redirect to /company/edit case 4');
            // customHistory.push('/company/edit');
            return;
          }
          if (
            !nonRedirectLocations.includes(currentLocation) &&
            !isCurrentPath('/prices/:priceId/share/:token')
          ) {
            customHistory.push('/profile/companies');
          }
        }
        const { billing } = userCompany;
        if (
          userCompany.billingAllowed &&
          billing &&
          billing.tariff !== 'unlim' &&
          !billing.notAllowed
        ) {
          if (
            (!billing.tariff || billing.blockOptions) &&
            !nonRedirectLocations.includes(currentLocation) &&
            !currentLocation.includes('/billing')
          ) {
            customHistory.push('/billing/control');
          }
        }
      }
      if (userCompany) dispatch(setCompany(userCompany));
    })
    .catch((e) => {
      console.error('Failed get user company', e);
    });
};

export const showUserEditForm = () => (dispatch) => {
  dispatch(showModal('EDIT_USER'));
};

export const getInvites = () => (dispatch) =>
  fetch('/api/companies/invited_employees', {
    credentials: 'include',
    method: 'GET'
  })
    .then((res) => res.json())
    .then(({ invites }) => {
      dispatch({ type: 'COMPANY:GET_INVITES:SUCCESS', payload: { invites } });
    })
    .catch((res) => {
      dispatch({
        type: 'COMPANY:GET_INVITES:FAILURE',
        payload: { error: res.error }
      });
      console.error(res);
    });

export const sendInvite =
  (
    email,
    nickname,
    position,
    permissions,
    employeeVisibility = 'hidden-from-other-companies',
    department = ''
  ) =>
  (dispatch) =>
    fetch('/api/companies/invite_employee', {
      credentials: 'include',
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        email,
        language: getBrowserLanguage(),
        nickname,
        permissions,
        position,
        employeeVisibility,
        department
      })
    })
      .then((res) => res.json())
      .then(({ id, added, inviteAdded }) => {
        if (added) {
          dispatch(
            setErrorMessage({ key: 'Emloyee already added', params: { email } })
          );
          return;
        }
        if (inviteAdded) {
          dispatch(
            setSuccessMessage({
              key: 'Invite already sent to email',
              params: { email }
            })
          );
          return;
        }
        dispatch(
          setSuccessMessage({
            key: 'Invite was sent to email',
            params: { email }
          })
        );
        dispatch({
          type: 'COMPANY:SEND_INVITE:SUCCESS',
          payload: { id, email, nickname }
        });
      })
      .catch((res) => {
        dispatch({
          type: 'COMPANY:SEND_INVITE:FAILURE',
          payload: { error: res.error }
        });
        console.error(res);
      });

export const resendInvite = (email) => (dispatch) =>
  fetch('/api/companies/resend_invite', {
    credentials: 'include',
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ email, language: getBrowserLanguage() })
  })
    .then(() => {
      dispatch(
        setSuccessMessage({
          key: 'Invite was sent to email',
          params: { email }
        })
      );
      dispatch({ type: 'COMPANY:RESEND_INVITE:SUCCESS', payload: { email } });
    })
    .catch((res) => {
      dispatch({
        type: 'COMPANY:RESEND_INVITE:FAILURE',
        payload: { error: res.error }
      });
      console.error(res);
    });

export const updateRequisites =
  ({
    checkingAccount,
    fullBankName,
    correspondentAccount,
    bik,
    legalAddress,
    actualAddress
  }) =>
  (dispatch) =>
    fetch('/api/companies/update_requisites', {
      credentials: 'include',
      method: 'PATCH',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        checkingAccount,
        fullBankName,
        correspondentAccount,
        bik,
        legalAddress,
        actualAddress
      })
    })
      .then(() => {
        dispatch(setSuccessMessage({ key: 'The data was saved' }));
        dispatch({
          type: 'COMPANY:UPDATE_REQUISITES:SUCCESS',
          payload: {
            checkingAccount,
            fullBankName,
            correspondentAccount,
            bik,
            legalAddress,
            actualAddress
          }
        });
      })
      .catch((res) => {
        dispatch({
          type: 'COMPANY:UPDATE_REQUISITES:FAILURE',
          payload: { error: res.error }
        });
        console.error(res);
      });

export const updateContacts = (contacts) => (dispatch) =>
  fetch('/api/companies/update_contacts', {
    credentials: 'include',
    method: 'PATCH',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ contacts })
  })
    .then(() => {
      dispatch(setSuccessMessage({ key: 'The data was saved' }));
      dispatch({
        type: 'COMPANY:UPDATE_CONTACTS:SUCCESS',
        payload: { contacts }
      });
    })
    .catch((res) => {
      dispatch({
        type: 'COMPANY:UPDATE_CONTACTS:FAILURE',
        payload: { error: res.error }
      });
      console.error(res);
    });

export const updateConditions =
  ({ delivery, payment, myConditions }) =>
  (dispatch) =>
    fetch('/api/companies/update_conditions', {
      credentials: 'include',
      method: 'PATCH',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ delivery, payment, myConditions })
    })
      .then(() => {
        dispatch(setSuccessMessage({ key: 'The data was saved' }));
        dispatch({
          type: 'COMPANY:UPDATE_CONDITIONS:SUCCESS',
          payload: { delivery, payment, myConditions }
        });
      })
      .catch((res) => {
        dispatch({
          type: 'COMPANY:UPDATE_CONDITIONS:FAILURE',
          payload: { error: res.error }
        });
        console.error(res);
      });

export const addContact = (contact) => async (dispatch) => {
  try {
    const res = await fetch('/api/companies/add_contact', {
      credentials: 'include',
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(contact)
    });
    const { key } = await res.json();
    dispatch({
      type: 'COMPANY:ADD_CONTACT:SUCCESS',
      payload: Object.assign(contact, { key })
    });
  } catch (err) {
    dispatch({ type: 'COMPANY:ADD_CONTACT:FAILURE', payload: { error: err } });
    console.error(err);
  }
};

export const addOption = (type) => async (dispatch) => {
  try {
    const res = await fetch('/api/companies/add_option', {
      credentials: 'include',
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ type })
    });
    const { option, key } = await res.json();
    dispatch({
      type: 'COMPANY:ADD_OPTION:SUCCESS',
      payload: { type, option, key }
    });
  } catch (err) {
    dispatch({ type: 'COMPANY:ADD_OPTION:FAILURE', payload: { error: err } });
    console.error(err);
  }
};

export const removeContact = (key) => (dispatch) =>
  fetch('/api/companies/remove_contact', {
    credentials: 'include',
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ key })
  })
    .then(() => {
      dispatch({
        type: 'COMPANY:REMOVE_CONTACT:SUCCESS',
        payload: { key }
      });
    })
    .catch((res) => {
      dispatch({
        type: 'COMPANY:REMOVE_CONTACT:FAILURE',
        payload: { error: res.error }
      });
      console.error(res);
    });

export const removeSelectedInvite = (listOfIds) => (dispatch) =>
  fetch('/api/companies/remove_invite', {
    credentials: 'include',
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ ids: listOfIds })
  })
    .then((res) => res.json())
    .then(({ ids }) => {
      dispatch({
        type: 'COMPANY:REMOVE_INVITE:SUCCESS',
        payload: { ids }
      });
    })
    .catch((res) => {
      dispatch({
        type: 'COMPANY:REMOVE_INVITE:FAILURE',
        payload: { error: res.error }
      });
      console.error(res);
    });

export const removeCondition = (type, key) => (dispatch) =>
  fetch('/api/companies/remove_condition', {
    credentials: 'include',
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ type, key })
  })
    .then(() => {
      dispatch({
        type: 'COMPANY:REMOVE_CONDITION:SUCCESS',
        payload: { type, key }
      });
    })
    .catch((res) => {
      dispatch({
        type: 'COMPANY:REMOVE_CONDITION:FAILURE',
        payload: { error: res.error }
      });
      console.error(res);
    });

export const getCompanyInfoById = (companyId) => (dispatch) => {
  dispatch({ type: 'COMPANY:GET_INFO:REQUEST', payload: { companyId } });

  fetch(`/api/companies/id/${companyId}/info`, { credentials: 'include' })
    .then((res) => res.json())
    .then((companyInfo) => {
      dispatch({
        type: 'COMPANY:GET_INFO:SUCCESS',
        payload: { ...companyInfo }
      });
    })
    .catch((res) => {
      dispatch({
        type: 'COMPANY:GET_INFO:FAILURE',
        payload: { companyId, error: res.error }
      });
      console.error(res);
    });
};

export const getCompanyInfoByLogin = (login) => async (dispatch) => {
  dispatch({ type: 'COMPANY:GET_INFO:REQUEST', payload: { login } });

  try {
    const res = await fetch(`/api/companies/login/${login}/info`, {
      credentials: 'include'
    });
    const companyInfo = await res.json();
    if (companyInfo.error && companyInfo.error === 'Not found') {
      return customHistory.push('/catalogs');
    }
    return dispatch({
      type: 'COMPANY:GET_INFO:SUCCESS',
      payload: { ...companyInfo }
    });
  } catch (err) {
    console.error(err);
    return dispatch({
      type: 'COMPANY:GET_INFO:FAILURE',
      payload: { login, error: err }
    });
  }
};

export const isEmailUnique = (email) => (dispatch) => {
  dispatch({ type: 'COMPANY:VALIDATE_EMAIL:REQUEST' });
  fetch('/api/companies/validate/email', {
    method: 'POST',
    credentials: 'include',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ email })
  })
    .then((res) => res.json())
    .then((res) => {
      const { isUnique } = res;
      dispatch({ type: 'COMPANY:VALIDATE_EMAIL:SUCCESS', payload: isUnique });
    })
    .catch((res) =>
      dispatch({
        type: 'COMPANY:VALIDATE_EMAIL:FAILURE',
        payload: { error: res.error }
      })
    );
};

export const changeEmailVerifyCode = (email) => async (dispatch) => {
  try {
    const res = await fetch('/api/companies/change_email_code', {
      credentials: 'include',
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ email })
    });
    const { error } = await res.json();
    dispatch({ type: 'COMPANY:CHANGE_EMAIL_CODE:SUCCESS', payload: { error } });
    return error;
  } catch (err) {
    console.error(err);
    return err;
  }
};

export const changeEmail = (newEmail, code) => async (dispatch) => {
  const res = await fetch('/api/companies/change_email', {
    credentials: 'include',
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ email: newEmail, code })
  });
  const { error, email } = await res.json();
  if (!error) {
    dispatch({ type: 'COMPANY:CHANGE_EMAIL:SUCCESS', payload: { email } });
  }
  return error;
};

export const isSystemIdUnique = (systemId) => async (dispatch) => {
  dispatch({ type: 'COMPANY:SYSTEM_ID_VALIDATE:REQUEST' });
  try {
    const res = await fetch('/api/companies/validate/systemid', {
      method: 'POST',
      credentials: 'include',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ systemId })
    });
    const json = await res.json();
    const { isUnique } = json;
    dispatch({ type: 'COMPANY:SYSTEM_ID_VALIDATE:SUCCESS', payload: isUnique });
  } catch (err) {
    dispatch({
      type: 'COMPANY:SYSTEM_ID_VALIDATE:FAILURE',
      payload: { error: err }
    });
  }
};

export const clearCompanyInfo = () => ({ type: 'COMPANY:GET_INFO:CLEAR' });

export const getBaidgesCount = () => (dispatch) => {
  dispatch({ type: 'COMPANY:GET_BADGES_COUNT:REQUEST' });
  fetch('/api/companies/baidges_count', {
    credentials: 'include',
    method: 'GET'
  })
    .then((res) => res.json())
    .then((json) =>
      res.status < 400
        ? Promise.resolve(json)
        : Promise.reject({ errors: json.errors, status: res.status })
    ) // eslint-disable-line
    .then((baidgesCount) =>
      dispatch({
        type: 'COMPANY:GET_BADGES_COUNT:SUCCESS',
        payload: { baidgesCount }
      })
    )
    .catch((e) => {
      dispatch({
        type: 'COMPANY:GET_BADGES_COUNT:FAILURE',
        payload: { error: e.error }
      });
      console.error('Failed get baidges count for company', e);
    });
};

export const selectEmployee = (item) => ({
  type: 'COMPANY:SELECT_EMPLOYEE',
  payload: { item }
});

export const clearSelectedEmployee = (item) => ({
  type: 'COMPANY:CLEAR_SELECTED_EMPLOYEE',
  payload: { item }
});

export const selectInvite = (id) => ({
  type: 'COMPANY:SELECT_INVITE',
  payload: { id }
});

export const clearSelectedInvite = (item) => ({
  type: 'COMPANY:CLEAR_SELECTED_INVITE',
  payload: { item }
});

export const removeEmployee = (usersId) => (dispatch) =>
  fetch('/api/companies/remove_employee', {
    credentials: 'include',
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ usersId })
  })
    .then((company) => {
      dispatch({
        type: 'COMPANY:REMOVE_EMPLOYEE:SUCCESS',
        payload: { company }
      });
    })
    .catch((res) => {
      dispatch({
        type: 'COMPANY:REMOVE_EMPLOYEE:FAILURE',
        payload: { error: res.error }
      });
      console.error(res);
    });

export const removeCompany = (companyId) => (dispatch) =>
  fetch('/api/companies/remove_company', {
    credentials: 'include',
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ companyId })
  })
    .then(() =>
      dispatch({ type: 'COMPANY:REMOVE:SUCCESS', payload: { companyId } })
    )
    .catch((res) => {
      dispatch({
        type: 'COMPANY:REMOVE:FAILURE',
        payload: { error: res.error }
      });
      console.error(res);
    });

export const editEmployee =
  (
    userId,
    nickname,
    position,
    permissions,
    employeeVisibility,
    email,
    department
  ) =>
  (dispatch) =>
    fetch('/api/companies/edit_employee', {
      credentials: 'include',
      method: 'PATCH',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        userId,
        nickname,
        position,
        department,
        permissions,
        employeeVisibility,
        email
      })
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.error) {
          dispatch(setErrorMessage({ key: 'Email is in use' }));
        }
      });

export const getACompanyName = (email) => (dispatch) => {
  dispatch({ type: 'COMPANY:GET_A_COMPANY_NAME:REQUEST' });
  return fetch('/api/companies/get_company_name', {
    credentials: 'include',
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ email })
  })
    .then((res) => ({ res, json: res.json() }))
    .then(({ res, json }) =>
      res.status < 400
        ? Promise.resolve(json)
        : Promise.reject({ errors: json.errors, status: res.status })
    ) // eslint-disable-line
    .then((data) => {
      dispatch({
        type: 'COMPANY:GET_A_COMPANY_NAME:SUCCESS',
        payload: { data }
      });
      return data;
    })
    .catch((e) => {
      dispatch({
        type: 'COMPANY:GET_A_COMPANY_NAME:FAILURE',
        payload: { error: e.error }
      });
    });
};

export const getInfoByEmail = (email, type) => (dispatch) => {
  dispatch(startLoader('getInfoByEmail'));
  return fetch('/api/companies/get_info', {
    credentials: 'include',
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ email, type })
  })
    .then((res) => ({ res, json: res.json() }))
    .then(({ res, json }) =>
      res.status < 400
        ? Promise.resolve(json)
        : Promise.reject({ errors: json.errors, status: res.status })
    ) // eslint-disable-line
    .then((data) => {
      dispatch(finishLoader('getInfoByEmail'));
      return data;
    })
    .catch((e) => {
      dispatch(finishLoader('getInfoByEmail'));
      console.error(e);
    });
};

export const uploadLogo = (file) => async () =>
  fetch(`/api/companies/uploadImage?name=${file.name}&type=${file.type}`, {
    credentials: 'include',
    method: 'POST',
    body: file
  })
    .then((res) =>
      res.json().then((json) =>
        // eslint-disable-next-line prefer-promise-reject-errors
        res.status < 400
          ? Promise.resolve(json)
          : Promise.reject({ errors: json.errors, status: res.status })
      )
    )
    .then((data) => data.src);

export const addEmployee = (body) => async (dispatch) => {
  const response = await req.post('/api/companies/add_employee', body);
  if (response.success) {
    dispatch(
      setSuccessMessage({
        key: 'Invite was sent to email',
        params: { email: body.email }
      })
    );
  }

  if (response.added) {
    dispatch(setErrorMessage({ key: 'Email already registered' }));
  }

  if (response.error) {
    dispatch({
      type: 'COMPANY:SEND_INVITE:FAILURE',
      payload: { error: response.error }
    });
  }

  return response;
};
