import React from 'react';
import { Link } from 'react-router-dom';
import { Typography } from '@link/react-components';
import { Row } from '../row';
import { Cell } from '../cell';
import styles from './CheckboxRow.module.css';

function Title({ title, url }) {
  const type = url ? 'link' : 'text';

  switch (type) {
    case 'link':
      return (
        <Link to={url}>
          <Typography className={styles.link} variant="body1Med">
            {title}
          </Typography>
        </Link>
      );
    case 'text':
    default:
      return (
        <Typography className={styles.text} variant="body1Reg">
          {title}
        </Typography>
      );
  }
}

export function CheckboxRow({ title, children, url }) {
  return (
    <Row>
      <Cell>
        <Title title={title} url={url} />
      </Cell>
      {children.map ? (
        children.map((checkbox, index) => (
          <Cell key={index} center>
            {checkbox}
          </Cell>
        ))
      ) : (
        <Cell center>{children}</Cell>
      )}
    </Row>
  );
}
