import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { connectionHandler } from './useCases/connection';

const ConnectionsWrapper = ({ children }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(connectionHandler());
  }, [dispatch]);

  return children;
};

export default ConnectionsWrapper;
