import React from 'react';
import { isNil } from 'ramda';
import ReadableViewSelect from 'react-components/inputs/dynamic-border/readableViewSelect';
import { useTranslate } from '../../../../TranslateProvider';
import { STATUSES } from '../../../pages/TasksList/constants';
import './style.css';

export function generateSelectOptionByStatus(t, status) {
  return { label: t(`taskManager.statuses.${status}`), value: status };
}

function generateStatusOptions(t) {
  return Object.values(STATUSES).map((status) =>
    generateSelectOptionByStatus(t, status)
  );
}

const IconArrow = <img src="/img/drop.svg" alt="" />;

const StatusSelect = ({ value, onChange }) => {
  const t = useTranslate();

  const renderReadView = (option) => {
    if (isNil(option))
      return <div>{t('taskManager.errors.statusNotDefined')}</div>;

    return (
      <div className="task-status-select_read-view">
        {t('taskManager.statusSelect.text')}
        <span className="task-status-select__current-status">
          {option.label}
        </span>
      </div>
    );
  };

  return (
    <ReadableViewSelect
      value={value}
      onChange={onChange}
      options={generateStatusOptions(t)}
      arrowRenderer={IconArrow}
      readableViewComponent={renderReadView}
    />
  );
};

export default StatusSelect;
