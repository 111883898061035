import { Map, List, Set, fromJS } from 'immutable';
import {
  ORDERS_TO_SUPPLIERS_SET,
  ORDERS_TO_SUPPLIERS_SET_FILTER,
  ORDERS_TO_SUPPLIERS_CLEAR,
  ORDERS_TO_SUPPLIERS_UNSELECT,
  ORDERS_TO_SUPPLIERS_SELECT,
  ORDERS_TO_SUPPLIERS_SELECT_TOGGLE,
  ORDERS_TO_SUPPLIERS_FILTER_SELECT_TOGGLE
} from '../../actionTypes/orders';

const initState = () =>
  Map({
    data: List(),
    booted: false,
    selected: Set(),
    ids: Set(),
    badges: List(),
    totalOrders: 0,
    currencies: List(),
    statuses: List(),
    responsibles: List(),
    supplierNames: List(),
    excluded: List(),
    isEnabledFilterSelected: false,

    direction: 'DESC',
    orderBy: '',
    limit: 20,
    offset: 0,
    search: '',

    filters: {
      currencies: '',
      companies: [],
      responsibles: [],
      statuses: [],
      sumOrder: { from: '', to: '' },
      orderDate: { from: '', to: '' },
      shipmentDate: { from: '', to: '' },
      paid: { from: '', to: '' }
    }
  });

const setFilter = (state, payload) => state.set('filters', payload);

const load = (
  data,
  {
    allOrderIds = [],
    orders = [],
    badges = [],
    totalNumberOrders,
    currencies = [],
    statuses = [],
    responsibles = [],
    supplierNames = [],
    limit,
    offset,
    orderBy,
    direction,
    search,
    excluded = []
  }
) =>
  data
    .set('data', fromJS(orders))
    .set('badges', fromJS(badges))
    .set('totalOrders', totalNumberOrders)
    .set('currencies', fromJS(currencies))
    .set('statuses', fromJS(statuses))
    .set('responsibles', fromJS(responsibles))
    .set('supplierNames', fromJS(supplierNames))
    .set(
      'direction',
      direction === undefined ? data.get('direction') : direction
    )
    .set('orderBy', orderBy === undefined ? data.get('orderBy') : orderBy)
    .set('limit', limit === undefined ? data.get('limit') : limit)
    .set('offset', offset === undefined ? data.get('offset') : offset)
    .set('search', search === undefined ? data.get('search') : search)
    .set('ids', fromJS(allOrderIds))
    .set('excluded', fromJS(excluded))
    .set('booted', true);

const setTableAttrs = (data, { excluded }) => data.set('excluded', excluded);

const toggleSelect = (data, { orderId }) =>
  data.update('selected', (selected) =>
    selected.includes(orderId)
      ? selected.remove(orderId)
      : selected.add(orderId)
  );

const removeOrder = (data, { selected }) =>
  data.update('data', (dataStorage) =>
    dataStorage.filter((i) => !selected.includes(i.get('id')))
  );

const deselect = (data) => data.set('selected', Set());

const select = (data, { ids }) =>
  data.update('selected', (selected) =>
    // eslint-disable-next-line no-shadow
    ids.reduce(
      (selected, id) => (!selected.includes(id) ? selected.add(id) : selected),
      selected
    )
  );
const unselect = (data, { ids }) =>
  data.update('selected', (selected) =>
    // eslint-disable-next-line no-shadow
    ids.reduce(
      (selected, id) =>
        selected.includes(id) ? selected.remove(id) : selected,
      selected
    )
  );

const toggleFilterSelected = (state) =>
  state.set('isEnabledFilterSelected', !state.get('isEnabledFilterSelected'));

const updateOrders = (state, { orders, badges, totalOrdersCount }) =>
  state
    .update('data', (data) => {
      const sliceLimit = state.get('limit') - orders.length;
      const offset = state.get('offset');
      let newData = sliceLimit <= 0 ? List() : data.slice(0, sliceLimit);
      orders.forEach((order) => {
        const { number, partNumber } = order;
        const orderNumber =
          partNumber > 0 ? `${number}_00${partNumber}` : `${number || '-'}`;
        // eslint-disable-next-line no-param-reassign
        order.number = orderNumber;
        const index = newData.findIndex((i) => i.get('id') === order.id);
        if (index !== -1) {
          newData = newData.setIn([index], fromJS(order));
        } else if (offset === 0) {
          newData = newData.unshift(fromJS(order));
        }
      });
      return newData;
    })
    .update('badges', (data) => {
      let newData = data;
      badges.forEach((badge) => {
        const index = newData.findIndex((i) => i.get('id') === badge.id);
        if (index !== -1) {
          newData = newData.setIn([index], fromJS(badge));
        } else {
          newData = newData.unshift(fromJS(badge));
        }
      });
      return newData;
    })
    .set('totalOrders', totalOrdersCount);

const tableOrdersCompany = (state = initState(), { type, payload }) => {
  switch (type) {
    case ORDERS_TO_SUPPLIERS_FILTER_SELECT_TOGGLE:
      return toggleFilterSelected(state);
    case 'ORDERS:TABLE_ORDERS_COMPANY:LOAD:SUCCESS':
    case ORDERS_TO_SUPPLIERS_SET:
      return load(state, payload);
    case 'ORDERS:TABLE_ORDERS_COMPANY:SELECT':
    case ORDERS_TO_SUPPLIERS_SELECT_TOGGLE:
      return toggleSelect(state, payload);
    case ORDERS_TO_SUPPLIERS_SELECT:
      return select(state, payload);
    case 'ORDERS:TABLE_ORDERS_COMPANY:CLEAR':
    case ORDERS_TO_SUPPLIERS_CLEAR:
      return initState();
    case 'ORDERS:TABLE_ORDERS_COMPANY:DESELECT':
      return deselect(state);
    case ORDERS_TO_SUPPLIERS_UNSELECT:
      return unselect(state, payload);
    case 'ORDERS:TABLE_ORDERS_COMPANY:REMOVE:SUCCESS':
      return removeOrder(state, payload);
    case 'ORDERS:SET_TABLE_ATTRS:SUCCESS':
      return setTableAttrs(state, payload);
    case ORDERS_TO_SUPPLIERS_SET_FILTER:
      return setFilter(state, payload);
    case 'ORDERS:UPDATE_SUPPLIERS_DATA':
      return updateOrders(state, payload);
    default:
      return state;
  }
};

export default tableOrdersCompany;
