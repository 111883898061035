import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { Interpolate, translate } from 'react-i18next';
import { Modal, Form } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { withRouter } from '../../hoc/withRouter';

import * as modalAction from '../../action-creators/modal';

class ModalAccountDeleteRestricted extends Component {
  onCancel() {
    this.props.hideModalDialog();
  }

  render() {
    const { t, history, hideModalDialog } = this.props;

    return (
      <Modal id="simpleSubmit" onHide={() => this.onCancel()} show>
        <div className="modal-container modal-container_type_default modal-container_theme_default">
          <Modal.Header>
            <Modal.Title>
              {t('Delete account')}
              <span
                className="modal-header-cross pull-right"
                onClick={() => {
                  this.onCancel();
                }}
              />
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form style={{ paddingBottom: 20 }}>
              <Interpolate
                i18nKey="Delete_account_owner_restricted"
                link={
                  <Link
                    to="/employees/list"
                    onClick={(e) => {
                      e.preventDefault();
                      history.push('/employees/list');
                      hideModalDialog();
                    }}>
                    {t('Go to employees')}
                  </Link>
                }
              />
            </Form>
          </Modal.Body>
        </div>
      </Modal>
    );
  }
}

ModalAccountDeleteRestricted.propTypes = {
  t: PropTypes.func.isRequired,
  hideModalDialog: PropTypes.func.isRequired
};

export default withRouter(
  connect(null, (dispatch) =>
    bindActionCreators(
      {
        hideModalDialog: modalAction.hideModalDialog
      },
      dispatch
    )
  )(translate(['ui'])(ModalAccountDeleteRestricted))
);
