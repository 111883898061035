import { Map, fromJS } from 'immutable';

const initState = () => Map();

const loadSuppliersPrices = (data, { prices }) =>
  data.clear().merge(fromJS(prices));

const clearSuppliersPrices = (data) => data.clear();

const savedUserPrices = (state = initState(), { type, payload }) => {
  switch (type) {
    case 'PRICES_SUPPLIERS:GET_SAVED_USER_PRICES:SUCCESS':
      return loadSuppliersPrices(state, payload);
    case 'PRICES_SUPPLIERS:CLEAR':
      return clearSuppliersPrices(state);
    default:
      return state;
  }
};

export default savedUserPrices;
