import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { translate } from 'react-i18next';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Button as BootstrapButton, Modal } from 'react-bootstrap';

import { DOMAIN } from '../../lib/goodwix';
import { ModalContainer } from './elements';
import Helper from '../Helper';

import * as modalActions from '../../action-creators/modal';
import * as partnersActions from '../../action-creators/partners';
import * as servicesAction from '../../action-creators/services';
import Input from '../Input/Input';
import { validateEmail } from '../../utils/validateField';
import CopyIcon from '../../icons/copy';
import Button from '../Buttons/Button';

class ModalAddPartner extends Component {
  constructor(props) {
    super(props);

    this.copyLink = this.copyLink.bind(this);
    this.state = { email: '', copied: false };
  }

  async UNSAFE_componentWillMount() {
    const { getPartnerToken } = this.props;
    await getPartnerToken();
  }

  copyLink = () => {
    const { userEmail, gaSend, fromMessenger } = this.props;
    if (fromMessenger) {
      gaSend({
        category: 'Messenger',
        action: 'сhat_add_partner_copy',
        label: userEmail
      });
    } else {
      gaSend({
        category: 'Common',
        action: `startpage_contactlist_copy_partner`,
        label: userEmail
      });
    }
    const inputElement = document.getElementById('modal-add-partner-input');
    inputElement.select();
    document.execCommand('copy');
    this.setState({ copied: true });
  };

  onChangeEmail = (email) => this.setState({ email });

  getLink = () => `${DOMAIN}/dialogs/welcome/${this.props.partnerToken}`;

  handleSendLinkToEmail = () => {
    const { sendLinkToEmail, hideModal } = this.props;
    sendLinkToEmail({ email: this.state.email, link: this.getLink() });
    hideModal();
  };

  validateEmail = () => !this.state.email || validateEmail(this.state.email);

  getButtonText = () =>
    this.props.t(this.state.copied ? 'copied_link' : 'copy_link');

  refreshPartnerToken = () => {
    this.props.refreshPartnerToken();
    this.setState({ copied: false });
  };

  render() {
    const { t, hideModal } = this.props;

    return (
      <ModalContainer mods={{ theme: 'default' }}>
        <Modal id="addContact" onHide={hideModal} show>
          <Modal.Header>
            <Modal.Title>
              {t('add_user')}
              <span
                className="modal-header-cross pull-right"
                onClick={hideModal}
              />
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div style={{ color: '#000000', marginBottom: 25 }}>
              {t('modal_add_partner_main_text')}
            </div>

            <Input
              hideCounter
              hideBtn
              value={this.state.email}
              onChange={this.onChangeEmail}
              placeholder="example@email.com"
            />

            <div style={{ marginTop: 9, marginBottom: 25 }}>
              {t('modal_add_partner_link')}
            </div>

            <div
              style={{
                display: 'flex',
                width: '100%',
                justifyContent: 'center'
              }}>
              <BootstrapButton
                bsStyle="primary"
                onClick={this.handleSendLinkToEmail}
                disabled={!validateEmail(this.state.email)}>
                {t('send_link_to_email')}
              </BootstrapButton>
            </div>
          </Modal.Body>
          <div className="modal-window">
            <Modal.Header>
              <Modal.Title>
                <CopyIcon style={{ position: 'absolute', marginLeft: -24 }} />
                {t('add_user_link')}
                <span style={{ marginLeft: 5 }}>
                  <Helper text="addContactHelpText" />
                </span>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="modal-add-partner__refresh-container">
                <div />
                <div className="modal-add-partner__link-container">
                  <button
                    type="button"
                    className="modal-add-partner__refresh-button"
                    onClick={this.refreshPartnerToken}>
                    {t('addContactRefreshLink')}
                  </button>

                  <span style={{ marginLeft: 1 }}>
                    <Helper text="add_user_refresh_link_help_text" />
                  </span>
                </div>
              </div>
              <div className="modal-add-partner__link-container">
                <input
                  id="modal-add-partner-input"
                  className="modal-add-partner__link-input"
                  value={this.getLink()}
                  readOnly
                />
              </div>
              <div
                style={{
                  display: 'flex',
                  width: '100%',
                  justifyContent: 'center'
                }}>
                <Button variant="outline-primary" onClick={this.copyLink}>
                  {this.getButtonText()}
                </Button>
              </div>
            </Modal.Body>
          </div>
        </Modal>
      </ModalContainer>
    );
  }
}

ModalAddPartner.propTypes = {
  t: PropTypes.func.isRequired,
  hideModal: PropTypes.func.isRequired,
  partnerToken: PropTypes.string.isRequired,
  getPartnerToken: PropTypes.func.isRequired,
  refreshPartnerToken: PropTypes.func.isRequired
};

const mapState = (state) => ({
  partnerToken: state.getIn(['partners', 'token']),
  userEmail: state.getIn(['user', 'user', 'email'])
});

const mapDispatch = (dispatch) =>
  bindActionCreators(
    {
      hideModal: modalActions.hideModal,
      getPartnerToken: partnersActions.getToken,
      refreshPartnerToken: partnersActions.refreshToken,
      sendLinkToEmail: partnersActions.sendLinkToEmail,
      gaSend: servicesAction.gaSend
    },
    dispatch
  );

export default connect(mapState, mapDispatch)(translate()(ModalAddPartner));
