import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';
import { translate } from 'react-i18next';
import { getCurrEmplId, getFavoritesMsgs } from '../../../getter';
import DasboardCommon from '../../../main/dashboard-common';
import Button from '../../../ButtonIcon';
import ButtonBack from '../../../ButtonBack';
import * as useCase from '../../../useCases';
import {
  makeViewModel,
  dashboardTheme,
  theme,
  total,
  title,
  initOnBack
} from './dataType';

const wrapper = ({ t, config }) => {
  const currentEmployeeId = useSelector(getCurrEmplId);
  const messages = useSelector(getFavoritesMsgs);
  const handler = bindActionCreators(useCase.handleFavorites, useDispatch());

  return makeViewModel({
    t,
    currentEmployeeId,
    messages,
    handler,
    config
  });
};

const BtnBack = ({ viewModel, onClick }) => {
  if (theme(viewModel) === 'widget') {
    return <ButtonBack onClick={onClick} />;
  }
  return <Button icon="arrow-left" tooltip="all-messages" onClick={onClick} />;
};

const CWFavoritesHeader = ({ t, config }) => {
  const viewModel = wrapper({ t, config });

  return (
    <DasboardCommon theme={dashboardTheme(viewModel)}>
      <div
        className={`cw-favorites-header cw-favorites-header_theme_${theme(
          viewModel
        )}`}>
        <BtnBack viewModel={viewModel} onClick={initOnBack(viewModel)} />
        <div className="cw-favorites-header__text">{`${title(
          viewModel
        )}: ${total(viewModel)}`}</div>
      </div>
    </DasboardCommon>
  );
};

export default translate()(CWFavoritesHeader);
