import cn from 'classnames';
import { useId } from '@/hooks';
import { Typography } from '@/components/Typography';
import { useCallback, ChangeEvent } from 'react';
import { InputCounterProps } from './InputCounter.types';
import classes from './InputCounter.module.scss';

export function InputCounter({
  id: idOverride,
  className,
  value = '',
  onChange,
  placeholder,
  label,
  total,
  typography,
  disabled,
  valid = true
}: InputCounterProps) {
  const id = useId(idOverride);
  const onChangeCallback = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const newValue = e.target.value;
      if (newValue.length <= total) {
        onChange(newValue);
      }
    },
    [onChange, total]
  );

  return (
    <div className={className} data-testid="input-counter">
      <label htmlFor={id} className={classes.label}>
        <Typography
          className={cn(
            classes.labelContent,
            Boolean(label) && classes.withLabel
          )}
          variant={typography}
          noWrap>
          {label}
          <div>{`${value.length}/${total}`}</div>
        </Typography>
      </label>
      <input
        className={cn(classes.input, !valid && classes.isError)}
        id={id}
        value={value}
        onChange={onChangeCallback}
        placeholder={placeholder}
        disabled={disabled}
      />
    </div>
  );
}
