import { Map } from 'immutable';
import { curry, compose } from 'ramda';
import { vatOfSum, discountPrice } from '../../lib/finance';
import { roundUpCost } from '../../utils';
import {
  discountPriceWithRoundUp,
  discountCategoryPriceWithRoundUp
} from './math';
// eslint-disable-next-line import/no-cycle
import {
  adapterInvidualOrCommonCategoryByAmoundOfProduct,
  adapterInvidualOrCommonCategoryBySumOfOrder,
  adapterInvidualOrCommonCategoryBySumOfOrderNotDraft,
  totalDiscountByConditions,
  totalDraftDiscountByConditions
} from './purchasePriceCategories';
// eslint-disable-next-line import/no-cycle
import orderDraftStore from '../../storeSections/orderDraft';
// eslint-disable-next-line import/no-cycle
import publicPriceStore from '../../storeSections/publicPrices';

const getProducts = (state) =>
  state instanceof Map // TODO: remove check instanceof, pass resource right away
    ? orderDraftStore(state).items()
    : state.orderDraft.items();

const getPublicPriceStore = (state) => {
  if (state instanceof Map) {
    // TODO: remove this one, pass state with resource top
    return publicPriceStore(state);
  }
  return state.publicPrices;
};

const roundProductsCosts = (products, roundUp) =>
  products.map((p) => p.set('cost', roundUpCost(p.get('cost'), roundUp)));

export const pricePerAmount = (price, product) => price * product.get('count');

export const calcPriceOfProductList = curry((calcPriceProduct, products) =>
  products.reduce(
    (total, product) =>
      total + pricePerAmount(calcPriceProduct(product), product),
    0
  )
);

const calcCountOfOrderedProducts = (products) =>
  products.reduce((total, product) => total + product.get('count'), 0);

export const totalOrderedProducts = (state) =>
  calcCountOfOrderedProducts(getProducts(state));

const discountPriceWithRoundUpByCategory = (category) =>
  discountPriceWithRoundUp(
    category.get('discount'),
    category.get('fractionalDigits')
  );

const discountPriceWithRoundUpBySumCategory = (category) =>
  discountCategoryPriceWithRoundUp(
    category.get('discount'),
    category.get('cost')
  );

export const discountPriceByOrderProduct = (product) =>
  discountPriceWithRoundUp(
    product.get('discount'),
    product.get('fractionalDigits'),
    product.get('cost')
  );

export const discountLinkPriceByOrderProduct = (roundUp) => (product) =>
  roundUpCost(
    discountPriceWithRoundUp(
      product.get('discount'),
      product.get('fractionalDigits'),
      product.get('cost')
    ),
    roundUp
  );

const calcPriceOfProduct = curry(
  (categoryByAmoundOfProduct, categoryBySumOfOrder, product) =>
    compose(
      discountPriceWithRoundUpByCategory(categoryByAmoundOfProduct),
      discountPriceWithRoundUpBySumCategory(categoryBySumOfOrder),
      discountPriceByOrderProduct
    )(product)
);

const calcLinkPriceOfProduct = curry(
  (categoryByAmoundOfProduct, categoryBySumOfOrder, product, roundUp) =>
    compose(
      discountPriceWithRoundUpByCategory(categoryByAmoundOfProduct),
      discountPriceWithRoundUpBySumCategory(categoryBySumOfOrder),
      discountLinkPriceByOrderProduct(roundUp)
    )(product)
);

export const adapterCalcPriceOfProduct = curry((state, product) => {
  const pubPriceStore = getPublicPriceStore(state);
  const roundUp = pubPriceStore.price().get('roundUp');
  return roundUpCost(
    calcPriceOfProduct(
      adapterInvidualOrCommonCategoryByAmoundOfProduct(state, product),
      adapterInvidualOrCommonCategoryBySumOfOrder(state, product),
      product
    ),
    roundUp
  );
});

export const adapterCalcLinkPriceOfProduct = curry((state, product) => {
  const pubPriceStore = getPublicPriceStore(state);
  const roundUp = pubPriceStore.price().get('roundUp');
  return roundUpCost(
    calcLinkPriceOfProduct(
      adapterInvidualOrCommonCategoryByAmoundOfProduct(state, product),
      adapterInvidualOrCommonCategoryBySumOfOrder(state, product),
      product,
      roundUp
    ),
    roundUp
  );
});

export const adapterCalcPriceOfProductNotDraft = curry((state, product) => {
  const pubPriceStore = getPublicPriceStore(state);
  const roundUp = pubPriceStore.roundUp();
  return roundUpCost(
    calcPriceOfProduct(
      adapterInvidualOrCommonCategoryByAmoundOfProduct(state, product),
      adapterInvidualOrCommonCategoryBySumOfOrderNotDraft(state, product),
      product
    ),
    roundUp
  );
});

export const calcTotalDiscount = curry((state, product) => {
  const pubPriceStore = getPublicPriceStore(state);

  const purchasePrice = adapterCalcPriceOfProductNotDraft(state, product);
  const roundUp = pubPriceStore.roundUp();
  const retailCost = roundUpCost(product.get('retailCost'), roundUp);
  const result = (1 - purchasePrice / retailCost) * 100;

  return result;
});

export const getVATOfSumNotDraft = curry((state, product) =>
  vatOfSum(
    adapterCalcPriceOfProductNotDraft(state, product),
    product.get('vat')
  )
);

export const getVATOfSum = curry((state, product) =>
  vatOfSum(adapterCalcPriceOfProduct(state, product), product.get('vat'))
);

export const getVATOfSumLinkPrice = curry((state, product) =>
  vatOfSum(adapterCalcLinkPriceOfProduct(state, product), product.get('vat'))
);

// --------------------------------------------

export const getSum = (state) => {
  const pubPriceStore = getPublicPriceStore(state);
  const roundUp = pubPriceStore.price().get('roundUp');

  const calcPriceOfProductFunc = (product) =>
    calcPriceOfProduct(
      pubPriceStore.getRangePriceCategoryForProduct(product),
      pubPriceStore.getSumOfProductPriceCategoryForProduct(product),
      product
    );
  const products = roundProductsCosts(getProducts(state), roundUp);
  return calcPriceOfProductList(calcPriceOfProductFunc, products);
};

// --------------------------------------------

export const getVat = (state) =>
  calcPriceOfProductList(getVATOfSum(state), getProducts(state));

export const getSumWithCatCondition = (state) =>
  discountPrice(
    totalDiscountByConditions(state),
    calcPriceOfProductList(adapterCalcPriceOfProduct(state), getProducts(state))
  );

export const getVatWithCatCondition = (state) =>
  discountPrice(
    totalDiscountByConditions(state),
    calcPriceOfProductList(getVATOfSum(state), getProducts(state))
  );

export const getDraftSumWithCatCondition = (state) =>
  discountPrice(
    totalDraftDiscountByConditions(state),
    calcPriceOfProductList(
      adapterCalcLinkPriceOfProduct(state),
      getProducts(state)
    )
  );

export const getDraftVatWithCatCondition = (state) =>
  discountPrice(
    totalDraftDiscountByConditions(state),
    calcPriceOfProductList(getVATOfSumLinkPrice(state), getProducts(state))
  );
