import { curry, compose } from 'ramda';
import { discountPrice, roundUpFrationalDigits } from './finance';

export const discountPriceWithRoundUp = curry(
  (percent, fractionalDigits, price) =>
    compose(
      roundUpFrationalDigits(fractionalDigits),
      discountPrice(percent)
    )(price)
);

export const isIncludeInRange = (from, to, number) =>
  number >= from && number <= to;

export const fractionalDigitsByNumber = (number) =>
  number.toString().length - 1;
export const ifZeroToInfinity = (number) => (number === 0 ? Infinity : number);
