import React from 'react';
import { translate } from 'react-i18next';
import DescriptionEditor from '../../../catalogs/categories/prodcuts/single/SingleProductDescriptionEditor';
import SectionOffset from '../../Request/SectionOffset';
import IconSimpleCross from '../../../../icons/cross';
import ErrorNotice from './ErrorNotice';

const RequestDescription = ({
  t,
  onChange,
  isOpened,
  setIsOpened,
  isError,
  productsList
}) => {
  if (!isOpened) {
    return null;
  }

  return (
    <>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        {t('request_content')}
        <div
          onClick={() => setIsOpened(false)}
          data-testid="request-description-opener">
          <IconSimpleCross />
        </div>
      </div>

      <SectionOffset offset={16} />
      <ErrorNotice
        isError={isError}
        data-testid="request-description-error-notice">
        <DescriptionEditor onChange={onChange} value={productsList} />
      </ErrorNotice>
      <SectionOffset offset={28} />
    </>
  );
};

export default translate(['ui'], { wait: true })(RequestDescription);
