import React, { useState } from 'react';
import Text from 'react-components/src/components/Typography';
import Icon from 'react-components/src/icons';
import iconsView from 'react-components/src/icons/constants';
import { useIsClickOutside } from '@hooks/useIsClickOutside';
import cn from 'classnames';
import styles from './UserCard.module.css';
import { ButtonIcon } from '../buttons/buttonIcon';
import { DropMenu } from '../dropMenu';
import { useTranslate } from '../../../../../TranslateProvider';
// import { Switcher } from '../switcher';
import { useIsCanBeDeleted } from '../../../../../contexts/RouteIsCanBeDeletedContext';
import { convertUrl } from '../../../../../components/ChatWidget/modules/files/utils';

export const UserCard = ({
  firstName,
  lastName,
  department,
  src,
  isTimer,
  removeUserFromCard,
  changeUserIsExecuteAutomatically,
  onExchangeUser
}) => {
  const { ref, isShow, setIsShow } = useIsClickOutside(false);
  const isCanBeDeleted = useIsCanBeDeleted();
  const onToggleDownMenuOpen = (e) => {
    e.stopPropagation();
    e.preventDefault();
    setIsShow((prevState) => !prevState);
  };

  const [avatarSrc, setAvatarSrc] = useState(
    convertUrl(src) || '/img/user.svg'
  );

  return (
    <div className={cn(styles.wrapper)}>
      <div
        className={cn(styles.container, {
          [styles.readonly]: !isCanBeDeleted
        })}
        onClick={onExchangeUser}>
        <div className={styles.avatar}>
          <img
            src={avatarSrc}
            onError={() => setAvatarSrc('/img/user.svg')}
            alt="avatar"
            width={40}
            height={40}
          />
        </div>
        <div className={styles.content}>
          <div className={styles.header}>
            {isTimer && <Icon icon={iconsView.AlarmDone} size={14} />}
            <Text className={styles.title}>{`${firstName} ${lastName}`}</Text>
          </div>
          <Text className={styles.department}>{department}</Text>
        </div>
        {isCanBeDeleted && (
          <div className={styles.dropdownWrapper} ref={ref}>
            <ButtonIcon
              icon={<Icon icon={iconsView.SlidersPath} size={18} />}
              isActive={isShow}
              onClick={onToggleDownMenuOpen}
            />
            {isShow && isCanBeDeleted && (
              <UserDropMenu
                setIsShow={setIsShow}
                isTimer={isTimer}
                removeUserFromCard={removeUserFromCard}
                changeUserIsExecuteAutomatically={
                  changeUserIsExecuteAutomatically
                }
              />
            )}
          </div>
        )}
      </div>
    </div>
  );
};
// TODO: закомментировано до момента необходимости включения данной функциональности
// function UserDropMenu({ isTimer, removeUserFromCard, changeUserIsExecuteAutomatically, setIsShow }) {
function UserDropMenu({ removeUserFromCard, setIsShow }) {
  const t = useTranslate();
  // TODO: закомментировано до момента необходимости включения данной функциональности
  // const onToggle = () => {
  // e.stopPropagation();
  // e.preventDefault();
  // changeUserIsExecuteAutomatically();
  // setIsShow(false);
  // };

  const onDelete = (e) => {
    e.stopPropagation();
    e.preventDefault();
    removeUserFromCard();
    setIsShow(false);
  };
  return (
    <DropMenu className={styles.menu}>
      {/* TODO: закомментировано до момента необходимости включения данной функциональности */}
      {/* <div className={styles.menuItem}> */}
      {/*  <Text variant="body2Reg" className={styles.defaultText}> */}
      {/*    {t('constructor.executeAutomatically')} */}
      {/*  </Text> */}
      {/*  <Switcher isActive={isTimer} onToggle={onToggle} /> */}
      {/* </div> */}
      {/* <div className={styles.line} /> */}

      <div className={styles.menuItem} onClick={onDelete}>
        <Text variant="body2Reg" className={styles.errorText}>
          {t('constructor.deleteUser')}
        </Text>
        <span className={styles.redTrash}>
          <Icon icon={iconsView.Trash} size={18} />
        </span>
      </div>
    </DropMenu>
  );
}
