import React, { Component } from 'react';
import PropTypes from 'prop-types';
import block from 'bem-cn-lite';
import { translate } from 'react-i18next';
import {
  Modal,
  Col,
  Row,
  Form,
  FormGroup,
  FormControl,
  ControlLabel,
  Button
} from 'react-bootstrap';
import '../styles/modal_theme_default.styl';

import { ModalContainer } from '../elements';

const b = block('modal-container');

class ModalEditCustomer extends Component {
  constructor(props) {
    super(props);
    this.inputProps = this.inputProps.bind(this);
    this.state = {
      group: ''
    };
  }

  getChildContext() {
    return { b };
  }

  UNSAFE_componentWillMount() {
    this.props.setSelectedItem(this.props.selectedItem);
  }

  inputProps(field) {
    return {
      value: this.props.selectedItem.get(field),
      onChange: (e) => {
        this.setState({ group: e.target.value });
        this.props.updateSelectedField(field, e.target.value);
      }
    };
  }

  handlerClick = () => {
    const {
      hideModalDialog,
      selectedItem,
      updateCustomer,
      getCustomersCategories,
      groupId
    } = this.props;

    updateCustomer(selectedItem.get('id'), this.state.group, groupId).then(
      () => {
        getCustomersCategories();
        hideModalDialog();
      }
    );
  };

  render() {
    const { t, captionKey, hideModalDialog, selectedItem, groups } = this.props;

    return (
      <ModalContainer mods={{ theme: 'default' }}>
        <Modal id="editCustomer" onHide={() => hideModalDialog()} show>
          <Modal.Header>
            <Modal.Title>
              {t(captionKey)}
              <span
                className="modal-header-cross pull-right"
                onClick={() => hideModalDialog()}
              />
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Row>
                <Col md={6}>
                  <ControlLabel>{t('Company name')}</ControlLabel>
                </Col>
                <Col md={6}>{selectedItem.get('name')}</Col>
              </Row>
              <Row>
                <Col md={6}>
                  <ControlLabel>{t('E-mail')}</ControlLabel>
                </Col>
                <Col md={6}>{selectedItem.get('email')}</Col>
              </Row>
              <Row>
                <Col md={6}>
                  <ControlLabel>{t('Buyers group')}</ControlLabel>
                </Col>
                <Col md={6}>
                  <FormGroup controlId="category">
                    <FormControl
                      componentClass="select"
                      {...this.inputProps('group')}>
                      {groups.map((group) => (
                        <option key={group.get('id')} value={group.get('id')}>
                          {t(group.get('name'))}
                        </option>
                      ))}
                    </FormControl>
                  </FormGroup>
                </Col>
              </Row>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button
              id="cancel"
              className="pull-left"
              onClick={() => hideModalDialog()}>
              {t('Cancel')}
            </Button>
            <Button
              id="saveCharacteristicButton"
              bsStyle="primary"
              onClick={this.handlerClick}>
              {t('Save')}
            </Button>
          </Modal.Footer>
        </Modal>
      </ModalContainer>
    );
  }
}

ModalEditCustomer.propTypes = {
  t: PropTypes.func.isRequired
};

ModalEditCustomer.childContextTypes = {
  b: PropTypes.func
};

export default translate(['ui'], { wait: true })(ModalEditCustomer);
