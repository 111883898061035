import React from 'react';
import PropTypes from 'prop-types';
import { translate } from 'react-i18next';
import CWSAvatar from '../../Avatar';
import EmptyContacts from './EmptyContacts';
import './style.css';

const Row = ({
  id = '',
  avatar = '',
  name = '',
  company = '',
  onDeleteRow
}) => (
  <div key={id} className="cw-modal-component__added-contacts-list_row">
    <div className="cw-modal-component__added-contacts-list_row-avatar">
      <CWSAvatar size={40} info={{ src: avatar, type: 'user', alt: '' }} />
    </div>
    <div className="cw-modal-component__added-contacts-list_row-titles">
      <div className="cw-modal-component__added-contacts-list_row-titles_user-name">
        {name}
      </div>
      <div className="cw-modal-component__added-contacts-list_row-titles_company-name">
        {company}
      </div>
    </div>
    <div
      className="cw-modal-component__added-contacts-list_row-cross"
      onClick={onDeleteRow}>
      <img src="/img/cross-in-row.svg" alt="cross" />
    </div>
  </div>
);

Row.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  avatar: PropTypes.string,
  name: PropTypes.string,
  company: PropTypes.string,
  onDeleteRow: PropTypes.func.isRequired
};

Row.defaultProps = {
  id: '',
  avatar: '',
  name: '',
  company: ''
};

const AddedContacts = ({ t, list, onAdd, onDeleteItem, onDeleteAll }) => (
  <div className="cw-modal-component__added-contacts-container">
    <div className="cw-modal-component__added-contacts-header">
      <div className="cw-modal-component__added-contacts-header_title">
        {t('added-contacts')}
      </div>
      <div className="cw-modal-component__added-contacts-header_buttons-container">
        <button
          className="cw-modal-component__added-contacts-header_buttons-container_button"
          type="button"
          onClick={onAdd}>
          {`+ ${t('Add')}`}
        </button>
        <button
          className="cw-modal-component__added-contacts-header_buttons-container_button"
          type="button"
          onClick={onDeleteAll}>
          {t('delete-all')}
        </button>
      </div>
    </div>
    <div className="cw-modal-component__added-contacts-list">
      {list && list.length ? (
        list.map((contact) => (
          <Row
            key={contact.id}
            {...contact}
            onDeleteRow={onDeleteItem(contact.id)}
          />
        ))
      ) : (
        <EmptyContacts t={t} />
      )}
    </div>
  </div>
);

AddedContacts.propTypes = {
  t: PropTypes.func.isRequired,
  list: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      avatar: PropTypes.string,
      name: PropTypes.string,
      company: PropTypes.string
    })
  ).isRequired,
  onAdd: PropTypes.func.isRequired,
  onDeleteItem: PropTypes.func.isRequired,
  onDeleteAll: PropTypes.func.isRequired
};

export default translate()(AddedContacts);
