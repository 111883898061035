import { EditorState, Modifier } from 'draft-js';
import { DEFAULT_MAX_CHAT_INPUT_LENGTH } from '../../../../../../constants';
import { getCountSymbols } from '../../functions';

function cutText(text, maxLength = DEFAULT_MAX_CHAT_INPUT_LENGTH) {
  return text.slice(0, maxLength);
}

function onPasteText(es, onChangeES, text) {
  // NOTE: need for set test with extra space and \n\n
  const content = es.getCurrentContent();
  const selection = es.getSelection();

  const newContent = Modifier.replaceText(content, selection, text);

  const newEditorState = EditorState.push(es, newContent, 'insert-characters');
  onChangeES(newEditorState);
}

export const initHandlePastedText =
  ({ currentText, onChangeES }) =>
  (text, html, es) => {
    const currentInputLength = getCountSymbols(currentText);

    if (currentInputLength >= DEFAULT_MAX_CHAT_INPUT_LENGTH) return 'handled';

    const preparedText = cutText(
      text,
      DEFAULT_MAX_CHAT_INPUT_LENGTH - currentInputLength
    );

    onPasteText(es, onChangeES, preparedText);
    return 'handled';
  };
