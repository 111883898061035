import React from 'react';

const OrganizationIcon = () => (
  <svg
    width={18}
    height={18}
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      className="snm-icon__path"
      d="M17.6275 8.85253L9.4491 0.197127C9.39021 0.134638 9.32027 0.085062 9.24327 0.0512365C9.16627 0.017411 9.08373 0 9.00037 0C8.91701 0 8.83447 0.017411 8.75747 0.0512365C8.68047 0.085062 8.61053 0.134638 8.55164 0.197127L0.373278 8.85253C0.135015 9.10487 0 9.44764 0 9.80513C0 10.5474 0.569845 11.151 1.27073 11.151H2.13245V17.3271C2.13245 17.6993 2.41638 18 2.76782 18H7.72964V13.2896H9.95342V18H15.2329C15.5844 18 15.8683 17.6993 15.8683 17.3271V11.151H16.73C17.0675 11.151 17.3912 11.0101 17.6294 10.7556C18.1238 10.2299 18.1238 9.37824 17.6275 8.85253Z"
      fill="#B9BFCF"
    />
  </svg>
);

export default OrganizationIcon;
