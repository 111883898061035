import React from 'react';
import { translate } from 'react-i18next';
import Loader from './index';
import './style.styl';

const LoaderWithMessage = ({ t, text, color = 'gray' }) => (
  <div className="loader-with-message__container">
    <Loader />
    <span
      className={`loader-with-message__text loader-with-message__text-${color}`}>
      {t(text)}
    </span>
  </div>
);

export default translate(['ui'], { wait: true })(LoaderWithMessage);
