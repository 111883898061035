import { isNil } from 'ramda';
import { FULL, FULL_WIDTH, SHORT, SHORT_WIDTH } from './constants';
import { setData } from '../../storage/lib/LS';
import { makeNavigationSidebar } from './dataType';

const isActive = (currentPath = '', url) => {
  const rootCurrentPath = currentPath.slice(1).split('/')[0];

  if (currentPath === url) {
    return true;
  }

  if (currentPath.length === 1) {
    return url === currentPath;
  }

  if (rootCurrentPath === 'requests') {
    return true;
  }

  const rootUrlPath = url.slice(1).split('/')[0];

  if (rootUrlPath === rootCurrentPath) {
    return true;
  }

  return url === rootCurrentPath;
};

export function getActiveIndex(navigationsList, currentPath) {
  for (let i = 0; i < navigationsList.length; i++) {
    const current = navigationsList[i];
    let activeNestedIndex = -1;

    if (isActive(currentPath, current.to)) {
      return {
        main: i,
        nest: -1
      };
    }

    if (!isNil(current.nestedList)) {
      activeNestedIndex = current.nestedList.findIndex((nestSection) =>
        isActive(currentPath, nestSection.to)
      );
    }

    if (activeNestedIndex !== -1) {
      return {
        main: i,
        nest: activeNestedIndex
      };
    }
  }

  return {
    main: -1,
    nest: -1
  };
}

function getSidebarWidth(mode) {
  if (isFullMode(mode)) return FULL_WIDTH;
  if (isShortMode(mode)) return SHORT_WIDTH;

  throw new Error(`No such handler for mode: ${mode}.`);
}

export function writeCurrentModeToLS(lsKey, mode) {
  setData(lsKey, makeNavigationSidebar({ mode, width: getSidebarWidth(mode) }));
}

export function getAllBadgesCountForSection(section) {
  let result = section.amountNotify || 0;

  if (!isNil(section.nestedList)) {
    result += section.nestedList.reduce(
      (acc, item) => acc + (item.amountNotify || 0),
      0
    );
  }

  return result;
}

export function isActiveMainSection(activeIndex, mainKey) {
  return activeIndex.nest === -1 && activeIndex.main === mainKey;
}

export function isActiveIcon(activeIndex, mainKey) {
  return activeIndex.main === mainKey;
}

export function getNestActiveIndex(activeIndex, mainKey) {
  return activeIndex.main === mainKey ? activeIndex.nest : -1;
}

export function isFullMode(mode) {
  return mode === FULL;
}

export function isShortMode(mode) {
  return mode === SHORT;
}
