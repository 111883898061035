import React from 'react';
import {
  Modal,
  Col,
  FormGroup,
  FormControl,
  ControlLabel,
  Button,
  HelpBlock
} from 'react-bootstrap';
import { connect } from 'react-redux';
import { translate } from 'react-i18next';

import { bindActionCreators } from 'redux';
import { TextArea } from '../../FormControls';
import * as additionalCategoryActions from '../../../action-creators/additionalCategory';
import * as modalActions from '../../../action-creators/modal';

const ModalAddAdditionalCategory = translate(['ui'], { wait: true })(
  class extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        namePristine: true,
        name: '',
        desc: ''
      };
    }

    validationState = (field) => {
      const { name, namePristine } = this.state;
      if (field === 'name') {
        return (name.trim() && this.isCategoryNameUnique(name)) || namePristine
          ? null
          : 'error';
      }
      return this.state[field] ? null : 'error';
    };

    asterisk() {
      return <span style={{ color: 'red' }}>*</span>;
    }

    getFieldValidationState = (field) => {
      const currField = this.state[field];
      return !(
        currField === '' ||
        currField === undefined ||
        currField === null ||
        !(currField && currField.trim())
      );
    };

    showHelpInfo = (field) => {
      const { t } = this.props;
      const { namePristine } = this.state;
      if (!this.getFieldValidationState(field) && !namePristine) {
        return (
          <HelpBlock id="invalidFieldMessage" style={{ color: 'red' }}>
            {t('Required fields empty')}
          </HelpBlock>
        );
      }
      return null;
    };

    inputProps = (field) => ({
      value: this.state[field],
      onChange: (e) => this.setState({ [field]: e.target.value })
    });

    normalizeName(name = '') {
      return name.toLowerCase().trim();
    }

    isCategoryNameUnique = (name) => {
      const { additionalCategories } = this.props;
      const normalizedName = this.normalizeName(name);
      const found = additionalCategories
        .filter((category) => category.get('name') !== null)
        .find(
          (category) =>
            normalizedName === this.normalizeName(category.get('name'))
        );
      const isUnique = !found;
      return isUnique;
    };

    isSubmitDisabled = () => {
      const { name } = this.state;
      return !name || !name.trim() || !this.isCategoryNameUnique(name);
    };

    render() {
      const { name, namePristine, desc } = this.state;
      const { t, createAdditionalCategory, hideModalDialog } = this.props;

      return (
        <Modal
          id="productCharacteristicsModalWindow"
          onHide={() => hideModalDialog()}
          show>
          <Modal.Header>
            <Modal.Title>
              {t('Create an additional product category')}
              <span
                className="modal-header-cross pull-right"
                onClick={() => hideModalDialog()}
              />
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <FormGroup
              controlId="name"
              validationState={this.validationState('name')}>
              <ControlLabel
                style={{
                  color: '#767B92',
                  fontWeight: 'normal',
                  fontSize: '12px',
                  lineHeight: '14px'
                }}>
                {`${t('Name additional category')} `}
                {this.asterisk()}
              </ControlLabel>
              <span
                className="input-col-length pull-right"
                style={{ marginBottom: 5 }}>
                {name.length}
                /60
              </span>
              <FormControl
                type="text"
                maxLength={60}
                {...this.inputProps('name')}
                onKeyDown={() => {
                  if (namePristine) {
                    this.setState({ namePristine: false });
                  }
                }}
              />
              {this.showHelpInfo('name')}
              {this.isCategoryNameUnique(name) ? null : (
                <HelpBlock
                  id="invalidFieldMessageUniqueName"
                  style={{ color: 'red' }}>
                  {t('The category name already exists')}
                </HelpBlock>
              )}
            </FormGroup>
            <FormGroup controlId="description">
              <ControlLabel
                style={{
                  color: '#767B92',
                  fontWeight: 'normal',
                  fontSize: '12px',
                  lineHeight: '14px'
                }}>
                {`${t('Category description')} `}
              </ControlLabel>
              <span
                className="input-col-length pull-right"
                style={{ marginBottom: 5 }}>
                {desc.length}
                /690
              </span>
              <TextArea
                {...this.inputProps('desc')}
                style={{ height: 144 }}
                maxLength={690}
              />
            </FormGroup>
            <FormGroup
              controlId="requiredFieldsLabel"
              style={{ marginBottom: 0, marginTop: 10, height: '20px' }}>
              <Col
                componentClass={ControlLabel}
                xs={5}
                style={{
                  fontSize: '12px',
                  lineHeight: '14px',
                  fontWeight: 'normal',
                  textAlign: 'left',
                  padding: 0
                }}>
                {this.asterisk()}
                {` ${t('Required fields')}`}
              </Col>
            </FormGroup>
          </Modal.Body>
          <Modal.Footer>
            <Button
              id="cancel"
              className="pull-left"
              onClick={() => hideModalDialog()}>
              {t('Cancel')}
            </Button>
            <Button
              id="saveCharacteristicButton"
              bsStyle="primary"
              disabled={this.isSubmitDisabled()}
              onClick={async () =>
                createAdditionalCategory(name, desc).then(() =>
                  hideModalDialog()
                )
              }>
              {t('Create')}
            </Button>
          </Modal.Footer>
        </Modal>
      );
    }
  }
);

export default connect(
  (state) => ({
    additionalCategories: state.getIn([
      'prices',
      'price',
      'additionalCategories'
    ])
  }),
  (dispatch) =>
    bindActionCreators(
      {
        hideModalDialog: modalActions.hideModalDialog,
        createAdditionalCategory:
          additionalCategoryActions.createAdditionalCategory
      },
      dispatch
    )
)(ModalAddAdditionalCategory);
