import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState
} from 'react';
import cn from 'classnames';
import { Icon, Typography } from '@components';
import styles from './Snackbar.module.css';
import iconsView from '../../../assets/images/constants';

const Snackbar = forwardRef(
  (
    {
      withAnimation = true,
      text,
      time = 3000,
      iconName,
      iconSize = 'm',
      iconClassName = '',
      textVariant = 'body1Reg',
      textClassName = styles.text
    },
    ref
  ) => {
    const [isShow, setIsShow] = useState(false);
    const timer = useRef(undefined);
    function handleTimeout() {
      timer.current = setTimeout(() => {
        setIsShow(false);
      }, time);
    }

    function handleClose() {
      clearTimeout(timer.current);
      setIsShow(false);
    }
    useEffect(() => {
      if (!withAnimation) return () => {};
      if (isShow) {
        handleTimeout();
      }

      return () => clearTimeout(timer.current);
    }, [isShow]);

    useImperativeHandle(ref, () => ({
      show() {
        setIsShow(true);
      }
    }));

    return (
      <>
        {isShow && (
          <div
            className={cn(styles.snackbar, {
              [styles.animation]: withAnimation
            })}>
            <Icon name={iconName} size={iconSize} className={iconClassName} />
            <Typography variant={textVariant} className={textClassName}>
              {text}
            </Typography>
            <Icon
              name={iconsView.CloseLarge}
              onClick={handleClose}
              size="xs"
              className={styles.closeIcon}
            />
          </div>
        )}
      </>
    );
  }
);

export default Snackbar;
