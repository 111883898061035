/* eslint-disable max-len */

import React from 'react';

const SortDownIcon = ({ onClick = () => {}, isActive }) => (
  <svg
    onClick={onClick}
    width="8"
    height="5"
    viewBox="0 0 8 5"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M4 5L3.49691e-07 7.94466e-08L8 9.53674e-07L4 5Z"
      fill={`${isActive ? '#767B92' : 'rgba(118, 123, 146, 0.15)'}`}
    />
  </svg>
);

export default SortDownIcon;
