import React from 'react';
import { useSelector } from 'react-redux';
import { useParams } from '@hooks';

import { Link } from 'react-router-dom';
import Request from '../components/PurchaseRequests/Request';

import MessengerOther from '../components/Layouts/MessengerOther';

import { MessengerWidgetWrapper } from '../components/Widgets/Messenger/MessengerWidget';
import { formatRequestNumber } from '../utils/utils';
import { useTranslate } from '../TranslateProvider';

const MPurchaseRequest = () => {
  const t = useTranslate();
  const { requestId } = useParams();

  const request = useSelector((state) =>
    state.getIn(['purchaseRequests', 'request'])
  );

  const breadcrumbs = [
    {
      name: t('Purchase requests'),
      element: (
        <>
          <Link
            to={{
              pathname: '/requests/my',
              state: { myRequestsPage: true }
            }}>
            {t('Purchase requests')}
          </Link>
        </>
      )
    },
    {
      name: `${t('request_view')} №${formatRequestNumber(requestId)} - ${
        request.get('number') || formatRequestNumber(requestId)
      }`
    }
  ];

  return (
    <>
      <MessengerOther
        theme="gray"
        title={t('Purchase requests')}
        breadcrumbs={breadcrumbs}
        content={<Request requestId={requestId} />}
      />
      <MessengerWidgetWrapper />
    </>
  );
};

export default MPurchaseRequest;
