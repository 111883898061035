import { createContext, useContext } from 'react';

export const CompanyProfileContext = createContext({});

export function useCompanyProfileContext() {
  const context = useContext(CompanyProfileContext);

  if (!context) {
    throw new Error(
      'useCompanyProfileContext must be used within a CompanyProfileContext'
    );
  }

  return context;
}
