import React from 'react';

export function useThrottle(callback, delay = 200) {
  const isThrottled = React.useRef(null);

  return React.useCallback(
    (...args) => {
      if (isThrottled.current) {
        return;
      }

      callback(...args);
      isThrottled.current = true;

      setTimeout(() => {
        isThrottled.current = false;
      }, delay);
    },
    [callback, delay]
  );
}
