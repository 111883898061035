import {
  ADD_CONTACTS,
  SET_CONTACTS,
  UNHIDE_DIALOG,
  UPDATE_CONTACT,
  SET_LOADING_CONTACTS,
  SET_FILTERED_CONTACTS
} from './constants';

export const setContacts = ({ contacts, size }) => ({
  type: SET_CONTACTS,
  payload: { list: contacts, size }
});

export const setFilteredContacts = ({ contacts, size }) => ({
  type: SET_FILTERED_CONTACTS,
  payload: { list: contacts, size }
});

export const addContacts = ({ contacts, size }) => ({
  type: ADD_CONTACTS,
  payload: { list: contacts, size }
});

export const updateContact = (contact) => ({
  type: UPDATE_CONTACT,
  payload: contact
});

export const unhideDialog = (uniqueId) => ({
  type: UNHIDE_DIALOG,
  payload: { uniqueId }
});

export const setLoadingContacts = (isLoading) => ({
  type: SET_LOADING_CONTACTS,
  payload: { isLoading }
});
