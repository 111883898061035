import React from 'react';
import cn from 'classnames';
import Icon from 'react-components/src/icons';
import iconsVies from 'react-components/src/icons/constants';
import styles from './ButtonSend.module.css';

export const ButtonSend = ({ isSendDisable, onSend }) => (
  <div
    className={cn(styles.send, {
      [styles.sendDisabled]: isSendDisable
    })}
    onClick={onSend}>
    <Icon
      icon={isSendDisable ? iconsVies.BtnSendDisabled : iconsVies.BtnSendEnable}
      size={18}
    />
  </div>
);
