import React, { useEffect, Fragment } from 'react';
import { connect } from 'react-redux';
import { translate } from 'react-i18next';
import { bindActionCreators } from 'redux';
import { withRouter } from '../hoc/withRouter';
import MessengerOther from '../components/Layouts/MessengerOther';
import WorkspaceHeader from '../components/WorkspaceHeader/WorkspaceHeader';
import FormEditCompany from '../components/Forms/FormEditCompany';

import * as modalActions from '../action-creators/modal';
import * as companyActions from '../action-creators/company';
import * as messageActions from '../action-creators/message';
import * as serviceActions from '../action-creators/services';
import * as actionUser from '../action-creators/user';
import * as dictionariesGetters from '../modules/dictionaries/getters';
import * as dictionariesStorage from '../modules/dictionaries/storage';

const getNavItems = (inCompany, t) => {
  if (inCompany) {
    return [
      { eventKey: 'edit', name: t('Company') },
      { eventKey: 'requisites', name: t('Requisites') },
      { eventKey: 'contacts', name: t('Contacts') }
    ];
  }
  return [{ eventKey: 'edit', name: t('Company') }];
};

const ECompanyEdit = ({
  t,
  getCompany,
  getListOfUsers,
  user,
  saveCompany,
  company,
  categoriesList,
  updateCompanyField,
  trimCompanyField,
  showModal,
  setSuccessMessage,
  isEmailUnique,
  isSystemIdUnique,
  gaSend,
  history,
  havePermissionToUpdate,
  uploadLogo,
  getCurrentUser,
  getCategories
}) => {
  useEffect(() => {
    getCompany();
    getListOfUsers();
    getCategories();
  }, []);

  const getPanelBtns = (inCompany) => {
    if (!inCompany) {
      return [
        {
          name: t('Create company'),
          onClick: () => {
            gaSend({
              category: 'Common',
              action: 'company_reg_open',
              label: user.get('email')
            });
            showModal('EDIT_COMPANY_DIALOG', {
              callback: () => getCurrentUser(),
              fromMessenger: true
            });
          }
        }
      ];
    }

    return [];
  };

  const title = t('Data about Company');
  const breadcrumbs = [{ name: t('Data about Company') }];
  const panelBtns = getPanelBtns(company.get('id'));

  const navigationTabs = {
    activeKey: 'edit',
    items: getNavItems(company.get('id'), t),
    handleTabChange: (tab) => history.push(`/company/${tab}`)
  };

  return (
    <MessengerOther
      title={title}
      breadcrumbs={breadcrumbs}
      panelBtns={panelBtns}
      content={
        <>
          <WorkspaceHeader navigation={navigationTabs} />
          <div className="form-content-center">
            <FormEditCompany
              t={t}
              user={user}
              company={company}
              getCurrentUser={getCurrentUser}
              fromMessenger
              updateCompanyField={updateCompanyField}
              trimCompanyField={trimCompanyField}
              saveCompany={saveCompany}
              showModal={showModal}
              setSuccessMessage={setSuccessMessage}
              isEmailUnique={isEmailUnique}
              isSystemIdUnique={isSystemIdUnique}
              havePermissionToUpdate={havePermissionToUpdate}
              uploadLogo={uploadLogo}
              gaSend={gaSend}
              categoriesList={categoriesList}
            />
          </div>
        </>
      }
    />
  );
};

export default connect(
  (state) => ({
    user: state.getIn(['user', 'user']),
    company: state.get('company'),
    havePermissionToUpdate: state.getIn([
      'user',
      'user',
      'permissions',
      'company',
      'update'
    ]),
    categoriesList: dictionariesGetters.getPurchaseRequestsCategories(state)
  }),
  (dispatch) =>
    bindActionCreators(
      {
        showModal: modalActions.showModal,
        setSuccessMessage: messageActions.setSuccessMessage,
        gaSend: serviceActions.gaSend,
        ...companyActions,
        getCurrentUser: actionUser.getCurrentUser,
        getCategories: dictionariesStorage.getPurchaseRequestsCategories
      },
      dispatch
    )
)(withRouter(translate()(ECompanyEdit)));
