import React from 'react';
import styled from 'styled-components';

import Avatar from '../../Avatar';
import Tooltip from '../../Tooltip';

const Avatars = ({ avatarLists, offsetRight = 0, onClick }) => {
  if (avatarLists === null) return null;

  return (
    <MainAvatars onClick={onClick} offsetRight={offsetRight}>
      <Tooltip text="list-participants">
        <AvatarLists>
          {/* eslint-disable-next-line */}
          {avatarLists.avatars.map(({ avatarSrc, employeeId }) => (
            <AvatarWrapper key={employeeId}>
              <Avatar
                size={18}
                info={{ type: 'user', src: avatarSrc, employeeId }}
              />
            </AvatarWrapper>
          ))}
        </AvatarLists>
        <TotalMembers>{avatarLists.total}</TotalMembers>
      </Tooltip>
    </MainAvatars>
  );
};
export default Avatars;

const MainAvatars = styled.div`
  display: flex;
  padding-top: 1px;
  border-bottom: 1px solid transparent;
  cursor: pointer;

  && * {
    cursor: pointer;
  }

  &:hover {
    border-color: #c6cae3;
  }
`;

const AvatarLists = styled.div`
  display: flex;
  padding-left: 4px;
  align-items: center;
`;

const AvatarWrapper = styled.div`
  margin-left: -4px;
  display: inline-flex;
`;

const TotalMembers = styled.p`
  font-weight: 500;
  font-size: 14px;
  color: #aeb2d0;
  margin: 0 var(--padding-s) 0 var(--padding-xs);
`;
