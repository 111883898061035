import React, { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import Button from '../../../components/Buttons/Button';
import { useTranslate } from '../../../TranslateProvider';
import { getTranslate } from './helpers';
import * as emailMessagesCases from '../../../components/ChatWidget/modules/emailMessages/useCases';
import * as emailMessagesGetters from '../../../components/ChatWidget/modules/emailMessages/getters';
import * as modalActions from '../../../action-creators/modal';
import MessagesContainer from './components/MessagesContainer';
import { useActions } from '../../../hooks/useActions';
import { useParams } from '../../../hooks/history';
import { matchAllLinksFromString } from '../../../utils/utils';
import ConnectedEmailsContainer from './components/ConnectedEmailsContainer';

const Content = () => {
  const t = useTranslate();
  const messages = useSelector(emailMessagesGetters.getMessages);
  const loading = useSelector(emailMessagesGetters.getLoading);

  const { showModal } = useActions(modalActions);
  const { fetchAllMessages } = emailMessagesCases.useFetchAllMessages();

  const queryParams = useParams();

  useEffect(() => {
    if (queryParams.code && queryParams.scope) {
      const scope = matchAllLinksFromString(queryParams.scope);
      console.log('Auth params', scope, queryParams.code);
      emailMessagesCases.verifyEmail(queryParams.code, scope);
    }
  }, [queryParams]);

  const connectGmail = useCallback(() => {
    emailMessagesCases.connectEmail('gmail');
  }, []);

  const showSendEmailModal = useCallback(() => {
    showModal('MODAL_SEND_EMAIL', {});
  }, []);

  return (
    <div>
      <div>
        <Button onClick={connectGmail}>
          {getTranslate(t, 'buttons.connectWithGmail')}
        </Button>
        <Button onClick={fetchAllMessages} disabled={loading}>
          {getTranslate(t, 'buttons.fetchAllMessages')}
        </Button>
        <Button onClick={showSendEmailModal}>
          {getTranslate(t, 'buttons.sendEmailMessage')}
        </Button>
      </div>
      {loading && <div>Идет загрузка, пожалуйста подождите...</div>}

      <ConnectedEmailsContainer />

      {messages.map((messagesByEmail) => (
        <React.Fragment key={messagesByEmail.email}>
          <h1>{`Ящик: ${messagesByEmail.email}`}</h1>
          <MessagesContainer messages={messagesByEmail.messages} />
        </React.Fragment>
      ))}
    </div>
  );
};

export default Content;
