import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import block from 'bem-cn-lite';
import { Interpolate, translate } from 'react-i18next';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import * as actionUser from '../../action-creators/user';
import * as actionCompany from '../../action-creators/company';
import * as actionModal from '../../action-creators/modal';

import { getCurrentLocation } from '../../utils/location';

import './styles/header.styl';
import './styles/header_theme_one.styl';
import Logo from '../Logo';
import LanguageSwitcher from '../LanguageSwitcher';
import ProfileMenu from '../ProfileMenu';

import userStorage from '../../storage/user.storage';
import { clearAllCatalogOptions } from '../../utils/utils';
import * as ChatWidgetUC from '../ChatWidget/useCases';
import * as pushesUC from '../ChatWidget/modules/pushes/useCases';

const b = block('header');

/* eslint-disable react/prop-types */
class Header extends Component {
  constructor(props) {
    super(props);
    this.confirmExitDemo = this.confirmExitDemo.bind(this);
    this.confirmGoToRegistration = this.confirmGoToRegistration.bind(this);
    this.confirmGoToLogin = this.confirmGoToLogin.bind(this);
    this.isUploadedUser = this.isUploadedUser.bind(this);
    this.showBillingNotify = this.showBillingNotify.bind(this);
  }

  async UNSAFE_componentWillMount() {
    const { getCurrentUser, getCompany } = this.props;
    await getCurrentUser();
    getCompany();
  }

  isUploadedUser() {
    const { user } = this.props;
    return user.get('isUploaded');
  }

  isDemo() {
    return this.props.user.get('type') === 'demo';
  }

  confirmExitDemo() {
    const { t, showModal } = this.props;

    showModal('SIMPLE_SUBMIT', {
      captionKey: 'Exit from demo',
      text: t('Exit demo text'),
      textBtnConfirm: t('Exit'),
      submitAction: () => {
        window.location = '/logout/demo';
      }
    });
  }

  confirmGoToRegistration() {
    const { t, showModal } = this.props;

    showModal('SIMPLE_SUBMIT', {
      captionKey: 'go_to_registration',
      text: t('text_go_to_registration'),
      textBtnConfirm: t('go_to'),
      submitAction: () => {
        window.location = '/logout/demo/register';
      }
    });
  }

  confirmGoToLogin() {
    const { t, showModal } = this.props;

    showModal('SIMPLE_SUBMIT', {
      captionKey: 'go_to_login',
      text: t('text_go_to_login'),
      textBtnConfirm: t('go_to'),
      submitAction: () => {
        window.location = process.env.UNICAT_ACCOUNTS_SERVICE;
      }
    });
  }

  showHelp() {
    const { showModal } = this.props;

    showModal('INITIAL_HELP');
  }

  showBillingNotify() {
    const { billing, company, billingReadPermission } = this.props;
    const currentLocation = getCurrentLocation();
    return (
      billing &&
      !billing.get('standartUsed') &&
      billing.get('tariff') !== 'unlim' &&
      company.get('billingAllowed') &&
      billingReadPermission &&
      currentLocation === '/startpage'
    );
  }

  render() {
    const {
      t,
      hideChatWidget,
      setPushNotificationsMuted,
      clickableLogo = true
    } = this.props;

    const listMenu = [
      {
        type: 'link',
        to: '/profile/edit',
        onClick: hideChatWidget,
        iconName: 'profile',
        name: 'Profile'
      },
      {
        type: 'button',
        onClick: async () => {
          hideChatWidget();
          await setPushNotificationsMuted();
          await clearAllCatalogOptions();
          await userStorage.logout();
        },
        iconName: 'exit',
        name: 'Logout'
      }
    ];

    return (
      <div className={b({ theme: 'one' })}>
        <div className={b('column', { left: true })} id="header-col">
          <div className={b('controls')}>
            <div className={b('logo')} id="header-logo">
              <Logo withLink={clickableLogo} />
            </div>
          </div>
        </div>
        {this.showBillingNotify() && (
          <div style={{ margin: 'auto', paddingRight: '20px' }}>
            <Interpolate
              i18nKey="tariff_standart_allowed"
              detail={<Link to="/billing/control">{t('detail')}</Link>}
            />
          </div>
        )}
        <div className={b('column')}>
          {this.isUploadedUser() && this.isDemo() && (
            <div className={b('controls')}>
              <div>
                <img
                  onClick={() => this.showHelp()}
                  role="presentation"
                  src="/img/button_help.svg"
                  alt="help"
                />
              </div>
              <div style={{ width: 20 }} />
              <LanguageSwitcher />

              <div style={{ width: 20 }} />

              <span
                id="logoutDemoButton"
                className="breadcrumbs-buttons__item breadcrumbs-buttons__item_active"
                onClick={this.confirmExitDemo}>
                {t('Logout from demo')}
              </span>

              <div style={{ width: 10 }} />

              <span
                id="register"
                className="breadcrumbs-buttons__register breadcrumbs-buttons__register_active"
                onClick={this.confirmGoToRegistration}>
                {t('Register')}
              </span>

              <div style={{ width: 15 }} />
            </div>
          )}
          {!this.isUploadedUser() && (
            <div className={b('controls')}>
              <LanguageSwitcher />
              <div style={{ width: 15 }} />
            </div>
          )}
          {this.isUploadedUser() && !this.isDemo() && (
            <div className={b('controls')}>
              <div>
                <img
                  style={{ cursor: 'pointer' }}
                  onClick={() => this.showHelp()}
                  role="presentation"
                  src="/img/button_help.svg"
                  alt="help"
                />
              </div>
              <div style={{ width: 16 }} />
              <LanguageSwitcher />

              <div style={{ width: 11 }} />

              <ProfileMenu theme="blue" listMenu={listMenu} />

              <div style={{ width: 14 }} />
            </div>
          )}
        </div>
      </div>
    );
  }
}

Header.contextTypes = {
  chatSocket: PropTypes.object
};

/* eslint-enable react/prop-types */
export default connect(
  (state) => ({
    user: state.getIn(['user', 'user']),
    company: state.get('company'),
    billing: state.getIn(['company', 'billing']),
    billingReadPermission: state.getIn([
      'user',
      'user',
      'permissions',
      'billing',
      'read'
    ])
  }),
  (dispatch) =>
    bindActionCreators(
      {
        getCurrentUser: actionUser.getCurrentUser,
        getCompany: actionCompany.getCompany,
        showModal: actionModal.showModal,
        hideChatWidget: ChatWidgetUC.hideChatWidget,
        setPushNotificationsMuted: pushesUC.setPushNotificationsMuted
      },
      dispatch
    )
)(translate(['ui'], { wait: true })(Header));
