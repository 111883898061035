import { Map, List, Set, fromJS } from 'immutable';
import { assoc, append, assocPath, hasPath, path, propEq, reject } from 'ramda';
import { transformOrderHistory } from '../modules/orderHistory/data-type/order-history';

const newItems = () => new List();
const newSelected = () => new Set();
const newOrder = () =>
  new Map({
    items: List(),
    priceCategoryConditions: List(),
    stocks: List(),
    status: '',
    stocksInfo: Map()
  });
const newPostions = () => new Map();
const newPriceCategories = () => new List();
const newStatuses = () => new Map();
const newPrice = () =>
  new Map({ positions: newPostions(), priceCategories: newPriceCategories() });

export const initState = () =>
  new Map({
    items: newItems(),
    order: newOrder(),
    price: newPrice(),
    firstUploaded: false,
    selected: newSelected(),
    additionalSelected: newSelected(),
    availiableStatuses: newStatuses(),
    statuses: new Map(),
    comments: new List(),
    unsentComments:
      fromJS(JSON.parse(localStorage.getItem('unsentOrdersComments'))) ||
      new Map(),
    size: 0,
    offset: 0,
    limit: 20,
    attaches: new List(),
    category: new Map(),
    errors: new Map(),
    excluded: List()
  });

export const setOrder = (data, { order }) =>
  data
    .remove('order')
    .set('order', fromJS(order))
    .setIn(['order', 'stocks'], order.stocks ? fromJS(order.stocks) : List())
    .setIn(['order', 'status'], order.status ? order.status : '')
    .set('firstUploaded', true);

export const setOrderInfo = (
  data,
  {
    items,
    priceCategoryConditions,
    category,
    size,
    offset,
    limit,
    partsInfo,
    excluded
  }
) => {
  const normilizedItems = items
    ? fromJS(items.map((item) => ({ ...item, offers: item.offers || [] })))
    : newItems();
  return data
    .set('items', normilizedItems)
    .set('firstUploaded', true)
    .remove('category')
    .set('category', fromJS(category))
    .set('size', size)
    .set('offset', offset)
    .set('limit', limit)
    .set('partsInfo', partsInfo)
    .set('excluded', excluded)
    .setIn(
      ['order', 'priceCategoryConditions'],
      fromJS(priceCategoryConditions)
    );
};

export const setOrderAndPrice = (data, { order, availiableStatuses }) =>
  data
    .remove('order')
    .set('order', fromJS(order))
    .remove('availiableStatuses')
    .set('availiableStatuses', fromJS(availiableStatuses))
    .setIn(['order', 'stocks'], order.stocks ? fromJS(order.stocks) : List())
    .setIn(
      ['order', 'stocksInfo'],
      order.stocksInfo ? fromJS(order.stocksInfo) : Map()
    )
    .setIn(['order', 'status'], order.status ? order.status : '')
    .set('firstUploaded', true);

export const selectItem = (data, { item }) =>
  data.update('selected', newSelected(), (selected) =>
    selected.includes(item.get('id'))
      ? selected.remove(item.get('id'))
      : selected.add(item.get('id'))
  );

export const selectOrderItem = (data, { item }) =>
  data.update('selected', newSelected(), (selected) =>
    selected.includes(item.get('productId'))
      ? selected.remove(item.get('productId'))
      : selected.add(item.get('productId'))
  );

export const selectOrderSplitItem = (data, { item }) =>
  data.update('selected', newSelected(), (selected) =>
    selected.includes(`${item.get('productId')}-${item.get('orderNumber')}`)
      ? selected.remove(`${item.get('productId')}-${item.get('orderNumber')}`)
      : selected.add(`${item.get('productId')}-${item.get('orderNumber')}`)
  );

export const selectOrderAdditionalItem = (data, { id, stockId }) => {
  if (stockId) {
    return data.update('additionalSelected', newSelected(), (selected) =>
      selected.includes(`${id}-${stockId}`)
        ? selected.remove(`${id}-${stockId}`)
        : selected.add(`${id}-${stockId}`)
    );
  }
  return data.update('additionalSelected', newSelected(), (selected) =>
    selected.includes(id) ? selected.remove(id) : selected.add(id)
  );
};

export const selectOrderDraftSplitItem = (data, { item }) =>
  data.update('selected', newSelected(), (selected) =>
    selected.includes(`${item.get('productId')}-${item.get('stockId')}`)
      ? selected.remove(`${item.get('productId')}-${item.get('stockId')}`)
      : selected.add(`${item.get('productId')}-${item.get('stockId')}`)
  );

export const selectOrderDraftSplitItemSharedPrice = (data, { item }) =>
  data.update('selected', newSelected(), (selected) =>
    selected.includes(`${item.get('id')}-${item.get('stockId')}`)
      ? selected.remove(`${item.get('id')}-${item.get('stockId')}`)
      : selected.add(`${item.get('id')}-${item.get('stockId')}`)
  );

export const updateItemCount = (data, { itemId, count }) => {
  const items = data.get('items');
  const index = items.findIndex((i) => i.get('id') === itemId);
  const dr = data.getIn(['items', index, 'draftReserved']);
  const c = data.getIn(['items', index, 'count']);
  if (dr < 0) {
    const j = c - count;
    return data
      .setIn(['items', index, 'draftReserved'], dr + j)
      .setIn(['items', index, 'count'], count);
  }
  return data.setIn(['items', index, 'count'], count);
};

export const setItem = (data, { item }) =>
  data
    .setIn(['items', item.id], fromJS(item))
    .setIn(['order', 'items', item.id], fromJS(item));

export const setOrderStatus = (data, { status }) =>
  data.updateIn(['order'], (order) => order.set('status', status));

export const setOrderHistory = (data, { orderHistory }) =>
  data.updateIn(['order'], (order) =>
    order.set('orderHistory', transformOrderHistory(orderHistory))
  );

export const removeItems = (data, { items }) =>
  data.update('items', newItems(), (products) =>
    products.filter((item) => {
      const itm = items.find(
        (i) =>
          i.productId === item.get('productId') &&
          i.stockId === item.get('stockId')
      );
      return !itm;
    })
  );

export const setOrdersStatuses = (data, { statuses }) =>
  data.remove('statuses').set('statuses', fromJS(statuses));

export const addOrderComment = (data, { comment }) =>
  data.update('comments', (comments) => comments.push(fromJS(comment)));

const getList = (unsentComments, orderId) =>
  path([orderId, 'list'], unsentComments);
const hasList = (unsentComments, orderId) =>
  hasPath([orderId, 'list'], unsentComments);
const setList = (unsentComments, orderId, newComments) =>
  assocPath([orderId, 'list'], newComments, unsentComments);
const removeFrom = (key, deliveryId, data) =>
  reject(propEq(key, deliveryId))(data);

export const addOrUpdateUnsentOrdersComments = (
  data,
  { employeeId, comment }
) => {
  const { orderId } = comment;
  const allUnsentComments = data.get('unsentComments').toJS();
  const unsentComments = allUnsentComments[employeeId];

  if (hasList(unsentComments, orderId)) {
    const list = getList(unsentComments, orderId);
    const newList = append(comment, list);
    const newUnsentComments = setList(unsentComments, orderId, newList);
    const updatedAllUnsentComments = assoc(
      employeeId,
      newUnsentComments,
      allUnsentComments
    );
    return data.set('unsentComments', fromJS(updatedAllUnsentComments));
  }
  const newComments = {
    [orderId]: {
      list: [comment]
    }
  };

  const updatedAllUnsentComments = assoc(
    employeeId,
    newComments,
    allUnsentComments
  );

  return data.set('unsentComments', fromJS(updatedAllUnsentComments));
};

export const removeUnsentOrderComment = (
  data,
  { employeeId, orderId, deliveryId }
) => {
  const allUnsentComments = data.get('unsentComments').toJS();
  const unsentComments = allUnsentComments[employeeId];

  if (!hasList(unsentComments, orderId)) return data;
  const list = getList(unsentComments, orderId);
  const newList = removeFrom('deliveryId', deliveryId, list);
  const newComments = fromJS(setList(unsentComments, orderId, newList));

  const updatedAllUnsentComments = assoc(
    employeeId,
    newComments,
    allUnsentComments
  );

  return data.set('unsentComments', fromJS(updatedAllUnsentComments));
};

export const setComments = (data, { comments }) =>
  data.set('comments', fromJS(comments));

export const clearComments = (data) => data.set('comments', new Set());

export const changeOrderProductCount = (data, { id, count }) => {
  const items = data.get('items');
  const index = items.findIndex((i) => i.get('id') === id);
  return data.setIn(['items', index, 'count'], count);
};

export const changeOrderProductVat = (data, { id, vat }) => {
  const items = data.get('items');
  const index = items.findIndex((i) => i.get('id') === id);
  return data.setIn(['items', index, 'vat'], +vat);
};

export const changeOrderProductCost = (data, { id, cost }) => {
  const items = data.get('items');
  const index = items.findIndex((i) => i.get('id') === id);
  return data.setIn(['items', index, 'supplierCost'], cost);
};

export const clearSelected = (data) =>
  data.set('selected', newSelected()).set('additionalSelected', newSelected());
