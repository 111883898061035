import React from 'react';
import block from 'bem-cn-lite';
import { Link } from 'react-router-dom';
import Button from 'react-bootstrap/lib/Button';
import { useNavigate } from '@hooks';
import { useSelector } from 'react-redux';
import { Typography } from '@link/react-components';
import { formatRequestNumber } from '../../../../utils/utils';

import {
  convertStatus,
  getLocation,
  getStatusTKey,
  isFromThatCompany,
  isResponded,
  isResponseAllowed
} from './methods';

import { useTranslate } from '../../../../TranslateProvider';

import Status from '../../../Status';
import './style.css';
import * as storeGetters from '../../../../storeGetters';

const b = block('cr-content');

const Content = ({ request, haveUpdatePermission = true }) => {
  const t = useTranslate();
  const navigate = useNavigate();

  const currentUserCompany = useSelector(storeGetters.getCompany).toJS();

  const isDisabledBtn = () => {
    if (
      isFromThatCompany(request, currentUserCompany) ||
      !isResponseAllowed(request, currentUserCompany.id)
    ) {
      return true;
    }

    return !haveUpdatePermission && !isResponded(request);
  };

  return (
    <div className={b()}>
      <div className={b('header')}>
        <div className={b('header-left')}>
          <Typography variant="body1Med" className={b('request-number')}>
            {`№ ${formatRequestNumber(request.id)} - ${
              request.number || formatRequestNumber(request.id)
            }`}
          </Typography>
        </div>
        <div className={b('header-right')}>
          <div className={b('status')}>
            <Status
              status={convertStatus(request)}
              text={getStatusTKey({ status: convertStatus(request) })}
              statusColors={{ viewed: null }}
            />
          </div>
        </div>
      </div>
      <div className={b('body')}>
        <Link className={b('title')} to={`/requests/all/${request.id}`}>
          {request.title}
        </Link>
        <Typography className={b('categories')} variant="body2Reg">
          {request.categories.map((cat) => cat.name).join(' / ')}
        </Typography>
      </div>
      <div className={b('footer')}>
        <Typography className={b('location')} variant="body2Reg">
          {getLocation(request)}
        </Typography>
        <div className={b('footer-btn')}>
          <Button
            onClick={() =>
              navigate(`/requests/all/${request.id}?response=true`)
            }
            disabled={isDisabledBtn()}>
            {t(
              isResponded(request)
                ? 'purchaseRequest.card.btns.viewRespond'
                : 'purchaseRequest.card.btns.respond'
            )}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default Content;
