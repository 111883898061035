import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { translate } from 'react-i18next';

import ModalSettingsAccess from '../../components/Modal/ModalSettingsAccess';
import * as modalAction from '../../action-creators/modal';
import * as accessActions from '../../action-creators/access';
import * as serviceActions from '../../action-creators/services';

class ContModalGrantAccess extends Component {
  constructor(props, context) {
    super(props, context);
    this.submit = this.submit.bind(this);
  }

  UNSAFE_componentWillMount() {
    const { getEmployees, resourceType, id } = this.props;
    getEmployees({ resourceType, id });
  }

  componentWillUnmount() {
    this.props.cleanAccess();
  }

  submit(body) {
    const { updateAccess, resourceType, id } = this.props;
    return updateAccess({ resourceType, id }, body);
  }

  render() {
    const {
      captionKey,
      hideModalDialog,
      employees,
      accessType,
      currentUserId,
      gaSend,
      user,
      resourceType
    } = this.props;

    return (
      <ModalSettingsAccess
        title={captionKey}
        hideModalDialog={hideModalDialog}
        employees={employees}
        accessType={accessType}
        currentUserId={currentUserId}
        submit={this.submit}
        resourceType={resourceType}
        gaSend={gaSend}
        user={user}
      />
    );
  }
}

export default connect(
  (state) => ({
    user: state.getIn(['user', 'user']),
    employees: state.getIn(['access', 'employees']),
    accessType: state.getIn(['access', 'accessType']),
    currentUserId: state.getIn(['user', 'user', 'id'])
  }),
  (dispatch) =>
    bindActionCreators(
      {
        hideModalDialog: modalAction.hideModalDialog,
        getEmployees: accessActions.getEmployees,
        updateAccess: accessActions.updateAccess,
        cleanAccess: accessActions.cleanAccess,
        gaSend: serviceActions.gaSend
      },
      dispatch
    )
)(translate(['ui'], { wait: true })(ContModalGrantAccess));
