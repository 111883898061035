import React, { useEffect, useMemo, useState } from 'react';
import { isEmpty } from 'ramda';
import { connect, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import { LineBadge } from 'react-components/badges';
import { useNavigate } from '@hooks';
import {
  getStatusByBuyerForColor,
  getTransStatusByBuyer,
  isDisabledAcceptOrReject,
  reworkCompleted,
  sent
} from '../../../../presenters/sidebars/PurchaseRequestResponsesSidebar/status';
import * as getters from '../getters';
import Arrow from '../../../Buttons/Arrow';
import Status from '../../../Status';
import * as modalActions from '../../../../action-creators/modal';
import * as storage from '../storage';
import * as responseChatUC from '../../../../modules/response-chat/useCases';
import { actions } from '../reducer';

import './styles.css';

import EditIcon from '../../../../icons/edit';
import { colorList } from '../../Request/respondColor';
import RoundSmallButton from '../../../Buttons/RoundSmallButton';
import PreviewIcon from '../../../../icons/preview';
import CompareIcon from '../../../../icons/compare';
import AcceptIcon from '../../../../icons/accept';
import ProductsTable from '../../common/ProductsTable';
import FilesBlock from '../../Request/FilesBlock';
import LinksListBlock from '../../Request/LinksListBlock';
import { makeFileVM } from '../../Request/filesVM';
import { showFilesViewer } from '../../../../utils/filesViewerInterfaceCreator';
import { downloadFile } from '../../Request/files';
import ChatIcon from '../../../../icons/chat';
import CancelIcon from '../../../../icons/cancel';
import { formatDate } from '../../../../utils/date';
import { useTranslate } from '../../../../TranslateProvider';
import * as storeGetters from '../../../../storeGetters';
import { getCurrentUser } from '../../../../storeGetters';
import ExtraButton from '../../common/ExtraButton';
import { haveUpdatePermission } from '../../utils';
import { ResponseExtraOptions } from '../../Request/components/ResponseExtraOptions';

const countSum = (products) =>
  products.reduce((acc, prev) => acc + (prev.price * prev.count || 0), 0);

const ResponseCard = ({
  response,
  request,
  showModal,
  compareResponses,
  addToCompare,
  openResponseChat,
  refuseRespond,
  acceptRespond,
  sendToReworkRespond,
  changeRespondStatus,
  removeFromCompare,
  setViewedRespond,
  permissions
}) => {
  const t = useTranslate();
  const navigate = useNavigate();
  const [isResponseOpened, setIsResponseOpened] = useState(false);

  const user = useSelector(getCurrentUser);

  const extraOptions = useMemo(
    () => ({
      delivery: response.deliveryCost,
      deadline: response.deliveryDate ? new Date(response.deliveryDate) : null,
      payment: response.prepayment
    }),
    [response.deliveryCost, response.deliveryDate, response.prepayment]
  );

  const compareList =
    compareResponses[response.attrs.purchaseRequest.id]?.list || [];

  const { products, text, files, links } = response.attrs.purchaseRequest;
  const isCanAddToCompare = () =>
    user.get('currentCompany') === request.companyId &&
    !isEmpty(request.products);

  useEffect(() => {
    if ([sent, reworkCompleted].includes(response.status) && isResponseOpened) {
      setViewedRespond({ requestId: request.id, responseId: response.id });
    }
  }, [isResponseOpened]);

  const decline = (requestId, responseId) => () => {
    showModal('DECLINE_PURCHASE_RESPONSE', {
      requestId,
      responseId,
      submit: async (rejectParams) => {
        await refuseRespond({ requestId, responseId, text: rejectParams.text });
      }
    });
  };

  const toRework = () =>
    showModal('SEND_TO_REWORK_PURCHASE_RESPONSE', {
      companyName: response.attrs.fromName,
      requestId: request.id,
      responseId: response.id,
      onSuccessedSubmit: () =>
        sendToReworkRespond({ requestId: request.id, responseId: response.id })
    });

  const accept = () =>
    showModal('SIMPLE_SUBMIT', {
      title: t('accept_to_respond'),
      text: t('request_response_accept_modal_text'),
      textBtnConfirm: t('Confirm'),
      submitAction: async () => {
        try {
          await acceptRespond({
            requestId: request.id,
            responseId: response.id
          });
        } catch (e) {
          console.log(e);
        }
      }
    });

  const menuOptions = [
    {
      icon: <ChatIcon />,
      name: t('go_to_chat'),
      onClick: () => {
        openResponseChat({
          requestId: request.id,
          response,
          isWidget: true
        }).then((chat) => {
          if (chat?.responseStatus) {
            changeRespondStatus({
              requestId: request.id,
              responseId: response.id,
              status: chat.responseStatus
            });
          }
        });
      },
      // disabled: isDisableChatWithSupplier(response.status)
      disabled: false
    },
    {
      icon: <EditIcon />,
      name: t('to_rework'),
      onClick: toRework,
      disabled: isDisabledAcceptOrReject(response.status)
    },
    {
      icon: <CancelIcon />,
      name: t('reject_offer'),
      onClick: decline(request.id, response.id),
      disabled: isDisabledAcceptOrReject(response.status)
    }
  ];

  const handleToggleCompare = () => {
    if (compareList && compareList.includes(response.id)) {
      removeFromCompare({
        responseId: response.id,
        requestId: response.attrs.purchaseRequest.id
      });
    } else {
      addToCompare({
        responseId: response.id,
        requestId: response.attrs.purchaseRequest.id,
        requestName: request.title
      });
    }
  };

  const getLinks = () =>
    links.map((link) => ({ ...link, key: `${link.id}l`, buttons: [] }));

  const getFiles = () => {
    const buttonForFile = ({ file }) => [
      {
        name: 'download',
        icon: 'download-1',
        onClick: () => downloadFile({ ...file, url: file.url })
      },
      {
        name: 'preview',
        icon: 'view',
        onClick: () => showFilesViewer({ showModal, files, file })
      }
    ];

    return files.map((file) =>
      makeFileVM({
        key: file.id,
        createdAt: file.createdAt,
        name: file.name,
        size: file.size,
        buttons: buttonForFile({ file })
      })
    );
  };

  const formatSum = new Intl.NumberFormat('ru-RU', {
    maximumFractionDigits: 2
  }).format(countSum(products || []) || 0);

  return (
    <div
      className="request-card-wrapper"
      style={{ backgroundColor: isResponseOpened ? '#FFF' : '#FAFBFC' }}>
      <div className="request-card-row">
        <div className="request-card-arrow">
          {[sent, reworkCompleted].includes(response.status) && <LineBadge />}
          <Arrow
            isOpened={isResponseOpened}
            rotateDegree={0}
            initialDegree={180}
            onClick={() => setIsResponseOpened(!isResponseOpened)}
          />
        </div>
        <div className="request-card-col" style={{ width: 400 }}>
          <Status
            status={getStatusByBuyerForColor(response.status)}
            text={t(getTransStatusByBuyer(response.status))}
            statusColors={colorList}
          />
          <div className="request-card-title">{response.attrs.fromName}</div>
        </div>
        <div className="request-card-col" style={{ marginLeft: 'auto' }}>
          <div>
            <span className="request-card-gray">{`${t(
              'response_date'
            )}: `}</span>
            <span>{formatDate(response.createdAt)}</span>
          </div>
          <div>
            <span className="request-card-gray">{`${t(
              'response-sum'
            )}: `}</span>
            <span>{formatSum}</span>
          </div>
        </div>
        <div className="request-card-buttons">
          {haveUpdatePermission(permissions) && (
            <RoundSmallButton
              svg={<AcceptIcon />}
              onClick={!isDisabledAcceptOrReject(response.status) && accept}
              disabled={isDisabledAcceptOrReject(response.status)}
            />
          )}
          <RoundSmallButton
            svg={<PreviewIcon />}
            onClick={() =>
              navigate(
                `/requests/responses-purchase-requests/${response.id}/request/${request.id}`
              )
            }
          />
          {isCanAddToCompare() && (
            <RoundSmallButton
              svg={<CompareIcon />}
              onClick={handleToggleCompare}
              isActive={compareList.includes(response.id)}
            />
          )}
          {haveUpdatePermission(permissions) && (
            <ExtraButton options={menuOptions} />
          )}
        </div>
      </div>
      {isResponseOpened && (
        <>
          {!!products?.length && (
            <div
              className="request-card-col"
              style={{
                margin: '32px 16px 16px 50px',
                width: 'calc(100% - 66px)'
              }}>
              <ProductsTable
                products={products}
                editPriceAndCount={false}
                editMode={false}
                show={!!products?.length}
              />
            </div>
          )}
          <div className="request-card-response-content">
            {text && (
              <>
                <div className="request-card-title" style={{ fontSize: 14 }}>
                  {t('response-text')}
                </div>
                <div
                  style={{
                    margin: '16px 56px 14px',
                    paddingRight: 50,
                    wordBreak: 'break-word'
                  }}
                  dangerouslySetInnerHTML={{ __html: text }}
                />
              </>
            )}
            <ResponseExtraOptions mode="view" extraOptions={extraOptions} />
            <FilesBlock
              style={{ margin: '14px 0', width: 'calc(100% - 66px)' }}
              show={files.length !== 0}
              total={files.length}
              files={getFiles()}
            />
            <LinksListBlock
              style={{ margin: '14px 0', width: 'calc(100% - 66px)' }}
              show={links.length !== 0}
              total={links.length}
              links={getLinks()}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default connect(
  (state) => ({
    compareResponses: getters.getCompareResponses(state),
    permissions: storeGetters.getPurchaseRequestsPermission(state)
  }),
  (dispatch) =>
    bindActionCreators(
      {
        showModal: modalActions.showModal,
        addToCompare: storage.addToCompare,
        setViewedRespond: storage.setViewedRespond,
        openResponseChat: responseChatUC.open,
        refuseRespond: storage.refuseRespond,
        acceptRespond: storage.acceptRespond,
        sendToReworkRespond: storage.sendToReworkRespond,
        changeRespondStatus: actions.changeResponseStatus,
        removeFromCompare: storage.removeFromCompare
      },
      dispatch
    )
)(ResponseCard);
