import React from 'react';
import { translate } from 'react-i18next';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import * as contactActions from '../../../../../action-creators/contacts';
import { getContacts, getLimit, getPage, getSize } from './getters';
import ListOfItemsModal from '../../Templates/ListOfItemsModal';
import Rows from './Rows';

const CWModalContactsList = ({
  title,
  mode,
  search,
  contacts,
  limit,
  page,
  size,
  onClick,
  onCancel,
  loadContacts,
  loadContactsChunk
}) => (
  <ListOfItemsModal
    title={title}
    mode={mode}
    search={search}
    size={size}
    page={page}
    limit={limit}
    items={contacts}
    loadItems={loadContacts}
    loadItemsChunk={loadContactsChunk}
    onCancel={onCancel}>
    <Rows contacts={contacts} onClick={onClick} />
  </ListOfItemsModal>
);

const mapState = (state) => ({
  contacts: getContacts(state),
  limit: getLimit(state),
  page: getPage(state),
  size: getSize(state)
});

const mapDispatch = (dispatch) =>
  bindActionCreators(
    {
      loadContacts: contactActions.loadContacts,
      loadContactsChunk: contactActions.loadContactsChunk
    },
    dispatch
  );

export default connect(mapState, mapDispatch)(translate()(CWModalContactsList));
