import React, { useEffect, useState } from 'react';
import { useDragLayer } from 'react-dnd';
import { isNil } from 'ramda';
import Task from '../Task';
import { TASK } from '../../../../../constants';
import './style.css';

function getItemStyles(initialOffset, currentOffset) {
  if (!initialOffset || !currentOffset) {
    return {
      display: 'none'
    };
  }
  const { x, y } = currentOffset;

  const transform = `translate(${x}px, ${y}px)`;
  return {
    transform,
    WebkitTransform: transform
  };
}

const emptyList = [];
const emptyFn = () => {};

const CustomDragLayer = () => {
  const { itemType, item, initialOffset, currentOffset, isDragging } =
    useDragLayer((monitor) => ({
      item: monitor.getItem(),
      itemType: monitor.getItemType(),
      initialOffset: monitor.getInitialSourceClientOffset(),
      currentOffset: monitor.getSourceClientOffset(),
      isDragging: monitor.isDragging()
    }));

  const [width, setWidth] = useState('auto');

  useEffect(() => {
    if (!isNil(item)) {
      switch (itemType) {
        case TASK:
          defineTaskWidth();
          break;
        default:
          break;
      }
    }

    function defineTaskWidth() {
      const $taskContainer = document.getElementById('task-list-container');

      if ($taskContainer) {
        const taskContainerRect = $taskContainer.getBoundingClientRect();
        setWidth(taskContainerRect.width);
      }
    }
  }, [item && item.id]);

  function renderItem() {
    switch (itemType) {
      case TASK:
        return (
          <Task
            task={item.data}
            extraOptions={emptyList}
            handler={emptyFn}
            onClick={emptyFn}
          />
        );
      default:
        return null;
    }
  }

  if (!isDragging) return null;

  return (
    <div className="task-item-preview" style={{ width }}>
      <div style={getItemStyles(initialOffset, currentOffset)}>
        {renderItem()}
      </div>
    </div>
  );
};

export default CustomDragLayer;
