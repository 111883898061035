import {
  draft,
  sent,
  reworkCompleted
} from '../../../../presenters/sidebars/PurchaseRequestResponsesSidebar/status';
import { Executor } from '../../domain/entities';

export const getResponses = ({ responses = [] }) =>
  responses
    .filter((res) => res.status !== draft)
    .map((res, index) => ({ ...res, index }));

export const countNewResponses = (responses) =>
  responses.filter((response) =>
    [sent, reworkCompleted].includes(response.status)
  ).length;

export const mapExecutors = (executors) =>
  executors.map((executor) => new Executor({ ...executor, ...executor.user }));
