import React, { useEffect } from 'react';
import { useActions, useParams } from '@hooks';

import { useSelector } from 'react-redux';
import { Loader } from '@components';
import { Header } from './components/header';
import { Workspace } from './components/workspace';
import * as constructorActions from '../../../components/ChatWidget/modules/constructor/actions';
import * as constructorGetters from '../../../components/ChatWidget/modules/constructor/getters';
import { IsCanBeDeletedProvider } from '../../../contexts/RouteIsCanBeDeletedContext';
import ModalRoot from '../../../components/ModalRoot';
import styles from './builder.module.css';
import { routeService } from '../../../api/routeService';

export const BuilderPage = () => {
  const { id } = useParams();
  const { fetchUsersList, fetchConstructor, clearConstructor, initRouteName } =
    useActions(constructorActions);
  const { isLoading } = useSelector(constructorGetters.getBuilderState);

  useEffect(() => {
    const root = document.getElementById('app');

    root.classList.add(styles.root);

    return () => {
      root.classList.remove(styles.root);
    };
  }, []);

  useEffect(() => {
    if (id) {
      fetchConstructor(id);
    } else {
      (async () => {
        const count = await routeService
          .getCompanyRoutesCount()
          .then((res) => res.result);

        initRouteName(count + 1);
      })();
    }
  }, [id]);

  useEffect(() => {
    fetchUsersList();
  }, []);

  useEffect(() => () => clearConstructor(), []);

  if (isLoading) return <Loader />;

  return (
    <IsCanBeDeletedProvider>
      <Header />
      <Workspace />
      <ModalRoot />
    </IsCanBeDeletedProvider>
  );
};
