import fetch from 'isomorphic-fetch';
import customHistory from '../customHistory';
// import keyDebounce from '../utils/debounce';
import { uploadImage } from './media';
import {
  setSuccessMessage,
  setWarningMessage,
  setErrorMessage
} from './message';
import { isCurrentPath } from '../utils';
import { getCurrentLocation } from '../utils/location';
import { publishCatalog } from './catalog/publish';

export const clearProps = () => ({ type: 'CATALOG:PROPERTIES:CLEAR' });

/* eslint-disable */

export const updateCatalogProps = (catalogId, catalogProps) => (dispatch) => {
  dispatch({
    type: 'CATALOG:PROPERTIES:UPDATE:REQUEST',
    payload: { catalogId, catalogProps }
  });

  return fetch(`/api/catalogs/${catalogId}`, {
    credentials: 'include',
    method: 'PATCH',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ ...catalogProps })
  })
    .then((resp) => resp.json())
    .then(({ error }) => {
      if (error) {
        return error;
      }
      dispatch({
        type: 'CATALOG:PROPERTIES:UPDATE:SUCCESS',
        payload: {
          catalogProps: Object.assign(catalogProps, { id: catalogId })
        }
      });

      return Promise.resolve();
    })
    .catch((res) => {
      dispatch({
        type: 'CATALOG:PROPERTIES:UPDATE:FAILURE',
        payload: { error: res.error, catalogId, catalogProps }
      });
      console.error(res);

      return Promise.reject();
    });
};

export const patchCatalogField = (field, value, catalogId) => {
  const attributes = {};
  attributes[field] = value;
  return fetch(`/api/catalogs/${catalogId}`, {
    credentials: 'include',
    method: 'PATCH',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ ...attributes })
  }).then(() => {
    console.log(`Successfully patched catalog ${catalogId}`);
  });
};

// const debouncedCatalogPatchFetch = keyDebounce(patchCatalogField);

// VALIDATION rules
// const validationRules = {
//   name: 'required',
//   shopUrl: 'url'
// };

/**
 * @param item
 */
export function loadCatlog(item) {
  return {
    type: 'CATALOG_LOAD',
    item
  };
}

/**
 *
 */
export function clearCatalog() {
  return {
    type: 'CATALOG_CLEAR'
  };
}

export const getCatalog = (catalogId) => (dispatch) => {
  dispatch({ type: 'CATALOG:PROPERTIES:LOAD:REQUEST', payload: { catalogId } });

  return fetch(`/api/catalogs/${catalogId}`, { credentials: 'include' })
    .then((res) => res.json())
    .then((catalogProps) => {
      if (catalogProps.blocked) {
        const currLoc = getCurrentLocation();
        if (!currLoc.includes('/stocks')) {
          customHistory.push('/stocks');
        } else {
          customHistory.push('/catalogs');
        }
      }
      dispatch({
        type: 'CATALOG:PROPERTIES:LOAD:SUCCESS',
        payload: { catalogProps }
      });
    })
    .catch((res) => {
      dispatch({
        type: 'CATALOG:PROPERTIES:LOAD:FAILURE',
        payload: { error: res.error }
      });
      console.error(res);
    });
};

/**
 *
 */
export function showDeleteConfirmation() {
  return {
    type: 'CATALOG_DELETE_CONFIRMATION',
    flag: true
  };
}

/**
 *
 */
export function hideDeleteConfirmation() {
  return {
    type: 'CATALOG_DELETE_CONFIRMATION',
    flag: false
  };
}

/**
 * @param field
 * @param value
 */
export function setCatalogField(field, value) {
  return {
    type: 'CATALOG_SET_FIELD',
    field,
    value
  };
}

export const setCatalogPublicationComplete = (data) => (dispatch) => {
  dispatch(
    setSuccessMessage({
      key: 'Catalogue published'
    })
  );
  if (isCurrentPath('/catalogs/:id/edit')) {
    dispatch({ type: 'CATALOG_SET_STATUS', payload: { status: 'published' } });
    return;
  }
  if (isCurrentPath('/catalogs')) {
    dispatch({
      type: 'CATALOG_PUBLICATION_COMPLETE_NOTIFY',
      payload: data
    });
  }
};

const updateStatus = (status) => ({
  type: 'CATALOG_IMPORT_CHANGE_STATUS',
  status
});

const updateCatalogListStatus = (catalogId, status) => ({
  type: 'CATALOG_LIST_CHANGE_STATUS',
  payload: { catalogId, status }
});

export const setImportCatalogId = (id) => ({
  type: 'CATALOG_IMPORT_SET_ID',
  id
});

const setCritical = (errors) => updateStatus({ name: 'critical', errors });
const setWarning = (warnings) => updateStatus({ name: 'warning', warnings });
const setSuccess = () => updateStatus({ name: 'success' });

export const setStatus = (status) => ({
  type: 'CATALOG_SET_STATUS',
  payload: { status }
});

export const setCatalogImportComplete =
  ({ catalogId, errors, critical }) =>
  (dispatch) => {
    dispatch(setImportCatalogId(catalogId));
    const showMessage =
      !isCurrentPath('/catalogs/import') &&
      !isCurrentPath('/catalogs/import/:id');
    const setCatalogStatus = isCurrentPath('/catalogs');
    if (critical) {
      dispatch(setCritical(errors));
      if (showMessage) {
        dispatch(
          setErrorMessage({
            key: 'Catalog cant be upload',
            linkUrl: '/catalogs/import',
            linkKey: 'Move to finish'
          })
        );
      }
      if (setCatalogStatus) {
        dispatch(updateCatalogListStatus(catalogId, 'draft'));
      }
      return;
    }
    if (errors && errors.length) {
      dispatch(setWarning(errors));
      if (showMessage) {
        dispatch(
          setWarningMessage({
            key: 'Catalog upload finished with warnings',
            linkUrl: '/catalogs/import',
            linkKey: 'Move to finish'
          })
        );
      }
      if (setCatalogStatus) {
        dispatch(updateCatalogListStatus(catalogId, 'imported'));
      }
    } else {
      dispatch(setSuccess());
      if (showMessage) {
        dispatch(
          setSuccessMessage({
            key: 'Catalog upload finished',
            linkUrl: '/catalogs/import',
            linkKey: 'Move to finish'
          })
        );
      }
      if (setCatalogStatus) {
        dispatch(updateCatalogListStatus(catalogId, 'imported'));
      }
    }
  };

/**
 * @param field
 */
export function trimCatalogField(field) {
  return {
    type: 'CATALOG_TRIM_FIELD',
    field
  };
}

export const updateCatalogFieldNow = (field, value) => (dispatch, getState) => {
  dispatch(setCatalogField(field, value));
  patchCatalogField(field, value, getState().getIn(['catalog', 'item', 'id']));
};

export const deleteCatalog = (id) => (dispatch) =>
  fetch(`/api/catalogs/${id}`, {
    credentials: 'include',
    method: 'DELETE'
  }).then(() => customHistory.push('/'));

export const uploadCatalogImage = (type, file) => (dispatch, getState) => {
  uploadImage(file)
    .then(({ data: { attributes } }) => {
      dispatch(setCatalogField(type, attributes));
      return patchCatalogField(
        type,
        attributes,
        getState().getIn(['catalog', 'item', 'id'])
      );
    })
    .catch((e) => console.error('FAILED UPDATE CATALOG', e));
};

export const setCatalogImage = (id, type, src) => (dispatch) => {
  dispatch({ type: 'CATALOG:SET_IMG:REQUEST' });
  const attributes = { [type]: { src } };
  return fetch(`/api/catalogs/${id}`, {
    credentials: 'include',
    method: 'PATCH',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ data: { type: 'catalog', attributes } })
  })
    .then((res) => res.json())
    .then(() => {
      dispatch({ type: 'CATALOG:SET_IMG:SUCCESS' });
      return Promise.resolve();
    })
    .catch((errors) => {
      dispatch({ type: 'CATALOG:SET_IMG:FAILURE', payload: { errors } });
      console.error(errors);
      return Promise.reject(errors);
    });
};

export const getCatalogIdByLang = (languageCatalog) => (dispatch) => {
  dispatch({ type: 'CATALOG:GET_CATALOG_BY_LANG_ID:REQUEST' });
  return fetch(`/api/catalogs/by-lang/${languageCatalog}`, {
    credentials: 'include'
  })
    .then((res) => res.json())
    .then(({ id }) => {
      dispatch({ type: 'CATALOG:GET_CATALOG_BY_LANG_ID:SUCCESS' });
      return Promise.resolve(id);
    })
    .catch((errors) => {
      dispatch({
        type: 'CATALOG:GET_CATALOG_BY_LANG_ID:FAILURE',
        payload: { errors }
      });
      console.error(errors);
      return Promise.reject(errors);
    });
};

export const createCatalog =
  ({ name, currency, language = 'ru', isIntegrated = false }) =>
  (dispatch) => {
    dispatch({ type: 'CATALOG:CREATE_CATALOG:REQUEST' });
    return fetch('/api/catalogs', {
      credentials: 'include',
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ name, currency, language, isIntegrated })
    })
      .then((res) => res.json())
      .then(({ id, error }) => {
        if (error) {
          return error;
        }
        customHistory.push(`/catalogs/${id}/categories`);
      })
      .catch((res) => {
        dispatch({
          type: 'CATALOG:CREATE_CATALOG:FAILURE',
          payload: { error: res.error }
        });
        console.error(res);
      });
  };

export const setAdditionAttrsFilter = (catalogId, attrsToSet) => (dispatch) => {
  dispatch({ type: 'CATALOG:ATTRS_EDIT:REQUEST', payload: { catalogId } });
  return fetch(`/api/catalogs/${catalogId}/filter`, {
    credentials: 'include',
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ attrs: attrsToSet })
  })
    .then((res) => res.json())
    .then(({ attrs }) => {
      dispatch({
        type: 'CATALOG:ATTRS_EDIT:SUCCESS',
        payload: { attrs }
      });
      return Promise.resolve();
    })
    .catch((res) => {
      dispatch({
        type: 'CATALOG:ATTRS_EDIT:FAILURE',
        payload: { error: res.error }
      });
      return Promise.reject();
    });
};

export const uploadImg = (file) => async (dispatch) =>
  fetch(`/api/catalogs/uploadImage?name=${file.name}&type=${file.type}`, {
    credentials: 'include',
    method: 'POST',
    body: file
  })
    .then((res) =>
      res
        .json()
        .then((json) =>
          res.status < 400
            ? Promise.resolve(json)
            : Promise.reject({ errors: json.errors, status: res.status })
        )
    )
    .then((data) => data.src);

export const getRBKRate = (language, currency) => (dispatch) => {
  dispatch({ type: 'PRICE:GET_RBK_RATE:REQUEST' });

  return fetch(`/api/catalogs/${language}/rbk_rate?currency=${currency}`, {
    credentials: 'include',
    method: 'GET'
  })
    .then((res) => res.json())
    .then((rate) => {
      dispatch({ type: 'PRICE:GET_RBK_RATE:SUCCESS', payload: rate });
      return rate.rate;
    })
    .catch((res) => {
      dispatch({
        type: 'PRICE:GET_RBK_RATE:FAILURE',
        payload: { error: res.error }
      });
      console.error(res);
    });
};

export const createUnit =
  (catalogId, { name, code = '' }) =>
  async (dispatch) => {
    try {
      const res = await fetch(`/api/catalogs/${catalogId}/unit`, {
        credentials: 'include',
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ name, code })
      });
      const resp = await res.json();
      if (res.status > 200) {
        dispatch(setErrorMessage({ key: resp.msg }));
      } else {
        dispatch({ type: 'CATALOG:CREATE_UNIT:SUCCESS', payload: resp });
        dispatch(
          setSuccessMessage({
            key: 'The data was saved',
            linkKey: 'Publish catalog',
            linkClickHandler: (t) =>
              confirm(t('Catalogue will published in a few minutes')) &&
              dispatch(publishCatalog(catalogId))
          })
        );
      }
      return Promise.resolve();
    } catch (e) {
      console.error(e);
    }
  };

export const saveUnits = (catalogId, units) => (dispatch) =>
  fetch(`/api/catalogs/${catalogId}/units`, {
    credentials: 'include',
    method: 'PATCH',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ units })
  })
    .then((res) => res.json())
    .then(() => {
      dispatch({
        type: 'CATALOG:SAVE_UNITS:SUCCESS',
        payload: { units }
      });
      dispatch(
        setSuccessMessage({
          key: 'The data was saved',
          linkKey: 'Publish catalog',
          linkClickHandler: (t) =>
            confirm(t('Catalogue will published in a few minutes')) &&
            dispatch(publishCatalog(catalogId))
        })
      );
      return Promise.resolve();
    })
    .catch((res) => {
      dispatch({
        type: 'CATALOG:SAVE_UNITS:FAILURE',
        payload: { error: res.error }
      });
      return Promise.reject();
    });

export const getUnits = (catalogId) => (dispatch) => {
  return fetch(`/api/catalogs/${catalogId}/units`, {
    credentials: 'include',
    method: 'GET'
  })
    .then((res) => res.json())
    .then(({ units, publishedUnits }) => {
      dispatch({
        type: 'CATALOG:GET_UNITS:SUCCESS',
        payload: { units, publishedUnits }
      });
    })
    .catch((res) => {
      dispatch({
        type: 'CATALOG:GET_UNITS:FAILURE',
        payload: { error: res.error }
      });
      console.error(res);
    });
};

/* eslint-enable */

export const deleteUnit = ({ name, code, id }) => ({
  type: 'CATALOG:REMOVE_UNIT',
  payload: { name, code, id }
});
export const updateUnit = ({ name, code, id }) => ({
  type: 'CATALOG:EDIT_UNIT',
  payload: { name, code, id }
});
export const selectUnit = (name, code) => ({
  type: 'TABLE_UNITS_SELECT',
  payload: { name, code }
});
