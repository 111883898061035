import React from 'react';
import FilterIcon from '../../../icons/filter';
import './style.css';

const Filter = ({ onClick = () => {}, isFilterActive }) => (
  <button
    type="button"
    onClick={onClick}
    className={`filter-button ${isFilterActive ? 'filter-button-active' : ''}`}>
    <FilterIcon className="icon-filter" />
  </button>
);

export default Filter;
