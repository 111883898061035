import { useCallback } from 'react';
import * as externalAPIStorage from '../storage';
import { actions } from '../redux';
import { useActions } from '../../../../../hooks/useActions';

const useSetNewsAPIUrl = () => {
  const { setNewsAPIUrl } = useActions(actions);

  return useCallback(async (url) => {
    try {
      const response = await externalAPIStorage.postNewsAPIUrl({ url });
      setNewsAPIUrl(response.url);
    } catch (e) {
      console.log(e);
    }
  }, []);
};

export default useSetNewsAPIUrl;
