import React, { useState } from 'react';
import { useActions, useNavigate, useLocation, useAsync } from '@hooks';

import { Button, Modal, Typography } from '@link/react-components';
import { useTranslate } from '../../../TranslateProvider';
import * as modalActions from '../../../action-creators/modal';

import styles from './ModalCreatePurchaseRequest.module.css';
import { purchaseRequestTypes } from '../../PurchaseRequests/domain/entities';
import { routeService } from '../../../api/routeService';
import { Loader } from '../../../shared/components';

const paths = Object.freeze({
  regular: '/requests/all/my-requests/create',
  withConfirmation: '/requests/all/my-requests/create',
  createRoute: '/constructor/create'
});

const SECTION_HEIGHT = 132;

function WithConfirmationOrCreateRouteBlock({ action }) {
  const t = useTranslate();
  const { loading, value: routesCount } = useAsync(async () => {
    const { result } = await routeService.getCompanyRoutesCount();

    return result;
  }, []);

  if (loading) return <SectionLoader />;

  if (routesCount)
    return (
      <InfoBlock
        title={t('purchaseRequest.createModal.withConfirmation.title')}
        content={t('purchaseRequest.createModal.withConfirmation.content')}
        buttonText={t('purchaseRequest.createModal.withConfirmation.button')}
        action={() =>
          action(paths.withConfirmation, purchaseRequestTypes.withConfirmation)
        }
      />
    );

  return (
    <InfoBlock
      title={t('purchaseRequest.createModal.createRoute.title')}
      content={t('purchaseRequest.createModal.createRoute.content')}
      buttonText={t('purchaseRequest.createModal.createRoute.button')}
      action={() => action(paths.createRoute)}
    />
  );
}

function SectionLoader({ height = SECTION_HEIGHT }) {
  return (
    <section className={styles.section} style={{ height }}>
      <Loader />
    </section>
  );
}

function InfoBlock({ action, disabled, title, content, buttonText }) {
  const [isPrimaryButton, setIsPrimaryButton] = useState(false);

  return (
    <section className={styles.section}>
      <Typography variant="h2" className={styles.sectionTitle}>
        {title}
      </Typography>
      <Typography variant="body1Reg" className={styles.sectionContent}>
        {content}
      </Typography>
      <Button
        stretched
        disabled={disabled}
        onMouseLeave={() => setIsPrimaryButton(false)}
        onMouseEnter={() => setIsPrimaryButton(true)}
        mode={isPrimaryButton ? 'primary' : 'outline'}
        onClick={action}>
        {buttonText}
      </Button>
    </section>
  );
}

const ModalCreatePurchaseRequest = () => {
  const t = useTranslate();
  const { hideModalDialog } = useActions(modalActions);
  const navigate = useNavigate();
  const location = useLocation();

  const goToPage = (path, type) => {
    navigate(path, {
      state: { noRestoreData: true, type, from: location.pathname }
    });
    hideModalDialog();
  };

  return (
    <Modal onClose={hideModalDialog} open className={styles.container}>
      <Modal.Header
        typographyClassName={styles.title}
        className={styles.header}>
        {t('purchaseRequest.createModal.title')}
      </Modal.Header>
      <Modal.Content className={styles.content}>
        <InfoBlock
          title={t('purchaseRequest.createModal.regular.title')}
          content={t('purchaseRequest.createModal.regular.content')}
          buttonText={t('purchaseRequest.createModal.regular.button')}
          action={() => goToPage(paths.regular, purchaseRequestTypes.regular)}
        />
        <WithConfirmationOrCreateRouteBlock action={goToPage} />
      </Modal.Content>
    </Modal>
  );
};

export default ModalCreatePurchaseRequest;
