import React from 'react';
import cn from 'classnames';
import styles from './TabContent.module.css';

export function TabContent({ fullWidth = true, children }) {
  return (
    <div className={cn(styles.tabContent, fullWidth && styles.fullWidth)}>
      {children}
    </div>
  );
}
