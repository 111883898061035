import Peerjs from 'peerjs';
import { isEmpty } from 'ramda';
import { noop } from '../../../utils/utils';
// NOTE: Удалить после проверки на тесте отключение/включение камеры
// import { hasStreamVideoTrack } from '../utils';
import config from '../config';

export class Peer {
  constructor(props) {
    this.myPeer = null;
    this.myVAstream = null;
    this.connectedCallList = [];
    this.props = props;

    // NOTE need for fix issue https://github.com/peers/peerjs/issues/609
    this._connectedMediaIds = new Map();

    this._initialize(props);
    this._initializeCallbacks(props);

    this.setMyVAStream = this.setMyVAStream.bind(this);
    this.replaceVATrack = this.replaceVATrack.bind(this);
    this.addCallToList = this.addCallToList.bind(this);
    this.removeCallFromList = this.removeCallFromList.bind(this);
    this.callMany = this.callMany.bind(this);
    this.callOne = this.callOne.bind(this);
    this.onIncomingCall = this.onIncomingCall.bind(this);
    this.onChangeVideoState = this.onChangeVideoState.bind(this);
    this.onChangeAudioState = this.onChangeAudioState.bind(this);
    this.onCloseConnection = this.onCloseConnection.bind(this);
    this.reconnect = this.reconnect.bind(this);
  }

  get id() {
    return this.myPeer.id;
  }

  _initialize({
    config: pConfig,
    currentMember = null,
    onInited = noop,
    onClose = noop,
    onError = noop
  }) {
    return new Promise((resolve, reject) => {
      this.myPeer = new Peerjs(pConfig || config.peerConfig);
      const { myPeer } = this;

      function failureAttemptToInitialize(reason) {
        myPeer.off('error', failureAttemptToInitialize);
        myPeer.off('open', successInitialize);
        reject(reason);
      }

      function successInitialize() {
        myPeer.off('error', failureAttemptToInitialize);
        myPeer.off('open', successInitialize);
        resolve(myPeer);
      }

      this.myPeer.on('open', successInitialize);
      this.myPeer.on('error', failureAttemptToInitialize);

      this.currentMember = currentMember;
      this.myPeer.on('open', onInited);
      this.myPeer.on('close', onClose);
      this.myPeer.on('error', (reason) => {
        onError(reason);
        console.error('Peer error: ', reason);
      });
      this.myPeer.on('disconnected', (peerId) => {
        console.log('Disconnected peer with id = ', peerId);
      });
    });
  }

  _initializeCallbacks({
    onStartRemoteStream = noop,
    onUpdateRemoteStream = noop,
    onMuteVideo = noop,
    onUnmuteVideo = noop,
    onMuteAudio = noop,
    onUnmuteAudio = noop,
    onLeaveMember = noop,
    onCloseCall = noop,
    onUpdateMember = noop
  }) {
    this.onStartRemoteStream = onStartRemoteStream;
    this.onUpdateRemoteStream = onUpdateRemoteStream;
    this.onMuteVideo = onMuteVideo;
    this.onUnmuteVideo = onUnmuteVideo;
    this.onMuteAudio = onMuteAudio;
    this.onUnmuteAudio = onUnmuteAudio;
    this.onLeaveMember = onLeaveMember;
    this.onCloseCall = onCloseCall;
    this.onUpdateMember = onUpdateMember;
  }

  setMyVAStream(stream) {
    this.myVAstream = stream;
  }

  replaceVATrack(newTrack) {
    this.connectedCallList.forEach((call) => {
      const sender = call.peerConnection
        .getSenders()
        .find((s) => s.track.kind === newTrack.kind);
      sender.replaceTrack(newTrack);
    });
  }

  addCallToList(call) {
    this.connectedCallList.push(call);
  }

  removeCallFromList(peerId) {
    this.connectedCallList = this.connectedCallList.filter(
      (p) => p.id !== peerId
    );
  }

  async reconnect() {
    const { myPeer } = this;

    try {
      return new Promise((resolve, reject) => {
        function successReconnected() {
          console.log('Peer was reconnected');

          resolve();
          myPeer.off('open', successReconnected);
        }

        function failureAttemptToReconnect(reason) {
          console.log('Failure attempt to reconnect peer', reason);

          reject(reason);
          myPeer.off('error', failureAttemptToReconnect);
        }

        myPeer.on('open', successReconnected);
        this.myPeer.on('error', failureAttemptToReconnect);

        myPeer.reconnect();
      });
    } catch (e) {
      try {
        console.log('Try re-initialize peer');
        await this._initialize(this.props);
        return Promise.resolve();
      } catch (reason) {
        console.log('Re-initialize peer failure', reason);
        return Promise.reject(reason);
      }
    }
  }

  callMany(membersIds) {
    if (isEmpty(membersIds)) return;

    membersIds.forEach(this.callOne);
  }

  callOne(id) {
    // setup media connection
    const call = this.myPeer.call(id, this.myVAstream, {
      metadata: { initiator: this.currentMember }
    });

    call.on('error', (reason) => {
      console.error(`Error call while call to = ${id} `, reason);
    });

    call.on('stream', (remoteVAStream) => {
      console.log(
        'callOne remoteStream',
        remoteVAStream.getVideoTracks().length !== 0
          ? remoteVAStream.getVideoTracks()[0].toString()
          : 'Стрима нет'
      );
      // if (!this._connectedMediaIds.has(remoteVAStream.id)) {
      //   this.onStartRemoteStream(remoteVAStream, id, call);
      //   // NOTE: Удалить после проверки на тесте отключение/включение камеры
      //   // onUpdateStream(remoteVAStream, currentRs => this.onUpdateRemoteStream(remoteVAStream, id, currentRs));
      //   this._connectedMediaIds.set(remoteVAStream.id, true);
      // }

      this.onStartRemoteStream(remoteVAStream, id, call);
      // NOTE: Удалить после проверки на тесте отключение/включение камеры
      // onUpdateStream(remoteVAStream, currentRs => this.onUpdateRemoteStream(remoteVAStream, id, currentRs));
      this._connectedMediaIds.set(remoteVAStream.id, true);
    });

    call.on('close', () => {
      this.removeCallFromList(call.peer);
    });

    this.onChangeVideoState(call);
    this.onChangeAudioState(call);

    // setup data connection
    const connection = this.myPeer.connect(id);

    connection.on('open', () => {
      connection.on('data', (data) => {
        this.addCallToList({ ...call, member: data.member });
        this.onUpdateMember({ peerId: call.peer, ...data.member });
      });
    });

    connection.on('error', (reason) => {
      console.error(`Error connection while call to = ${id} `, reason);
    });

    this.onCloseConnection(connection);
  }

  onIncomingCall() {
    this.myPeer.on('connection', (incomingConnection) => {
      const connection = incomingConnection;

      connection.on('open', () => {
        connection.send({ member: this.currentMember });
      });

      this.onCloseConnection(connection);

      connection.on('error', (reason) => {
        console.error(
          `Error connection while set incoming call = ${connection.peer} `,
          reason
        );
      });
    });

    this.myPeer.on('call', (incomingCall) => {
      const call = incomingCall;

      call.answer(this.myVAstream); // correct

      this.addCallToList({ ...call, member: call.metadata.member });

      call.on('stream', (remoteStream) => {
        // if (!this._connectedMediaIds.has(remoteStream.id)) {
        //   this.onStartRemoteStream(remoteStream, call.peer, call);
        //   this._connectedMediaIds.set(remoteStream.id, true);
        // }
        this.onStartRemoteStream(remoteStream, call.peer, call);
        this._connectedMediaIds.set(remoteStream.id, true);
      });

      call.on('error', (reason) => {
        console.error(
          `Error call while set incoming call = ${call.peer} `,
          reason
        );
      });

      call.on('close', () => {
        this.removeCallFromList(call.peer);
      });

      this.onChangeVideoState(call);
      this.onChangeAudioState(call);
    });
  }

  onChangeVideoState(call) {
    call.peerConnection
      .getReceivers()
      .filter((r) => r.track.kind === 'video')
      .forEach(({ track }) => {
        /* eslint-disable no-param-reassign */
        track.onmute = () => this.onMuteVideo(call.peer);
        track.onunmute = () => this.onUnmuteVideo(call.peer);
        /* eslint-enable no-param-reassign */
      });
  }

  onChangeAudioState(call) {
    call.peerConnection
      .getReceivers()
      .filter((r) => r.track.kind === 'audio')
      .forEach(({ track }) => {
        /* eslint-disable no-param-reassign */
        track.onmute = () => this.onMuteAudio(call.peer);
        track.onunmute = () => this.onUnmuteAudio(call.peer);
        /* eslint-enable no-param-reassign */
      });
  }

  onCloseConnection(connection) {
    connection.on('close', () => {
      this.onLeaveMember(connection.peer);
      this.removeCallFromList(connection.peer);
    });
  }

  endCall() {
    this.onCloseCall(this.myPeer.id);
    this._connectedMediaIds = new Map();
    this.connectedCallList = [];
    this.myPeer.destroy();
  }
}

// NOTE: Удалить после проверки на тесте отключение/включение камеры
// function onUpdateStream(stream, cb) {
//   if (!hasStreamVideoTrack(stream)) return;
//
//   let prevReadyState = stream.getVideoTracks()[0].readyState;
//
//   const interval = setInterval(() => {
//     const currentRS = stream.getVideoTracks()[0].readyState;
//     if (prevReadyState !== currentRS) {
//       console.log('updated remote stream', stream.getVideoTracks()[0]);
//       prevReadyState = currentRS;
//       cb(currentRS);
//     }
//     // FIXME If user deny microphone permission and click by off camera then stream set as not active
//     if (!stream.active) clearInterval(interval);
//   }, 200);
// }
