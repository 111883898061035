import { Map, fromJS } from 'immutable';

const initState = () => Map();

const clearPrice = (data) => data.clear();

const setPublicPrice = (data, { price }) => data.clear().merge(fromJS(price));

const publicPricesCategories = (state = initState(), { type, payload }) => {
  switch (type) {
    case 'PRICE:GET_PUBLISHED_PRICE:SUCCESS':
      return setPublicPrice(state, payload);
    case 'PRICES:CLEAR_PUBLIC':
      return clearPrice(state);
    default:
      return state;
  }
};

export default publicPricesCategories;
