import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
// import block from 'bem-cn-lite';
import { translate } from 'react-i18next';
import { List } from 'immutable';
import { Modal, Button, Form } from 'react-bootstrap';

import './styles/modal_theme_default.styl';

import Table from '../TableNew';
import SearchBlockDefault from '../SearchBlock/SearchBlockDefault';
import Pagination from '../Pagination';
import { ModalContainer } from './elements';

import * as modalActions from '../../action-creators/modal';
import * as featureActions from '../../action-creators/product/feature';
import * as servicesAction from '../../action-creators/services';

import { capitalize } from '../../utils';

// const b = block('modal-container');

class ModalAddExistedFeature extends Component {
  constructor(props) {
    super(props);
    this.hideModal = this.hideModal.bind(this);
    this.getData = this.getData.bind(this);
    this.onSearch = this.onSearch.bind(this);
    this.isShowPagination = this.isShowPagination.bind(this);
    this.selectNextPagination = this.selectNextPagination.bind(this);
  }

  UNSAFE_componentWillMount() {
    const { getAllProductsFeatures, catalogId } = this.props;
    getAllProductsFeatures(catalogId);
  }

  onSearch(search = '') {
    const { catalogId, getAllProductsFeatures } = this.props;
    getAllProductsFeatures(catalogId, search);
  }

  getData() {
    const { features, selected, items } = this.props;

    if (!features) return List();

    return features.map((item) => {
      const name = item.get('name').toLowerCase();
      const unit = item.get('unit') ? item.get('unit').toLowerCase() : '';
      const hasId = selected.find(
        (i) =>
          i.get('name').toLowerCase() === name &&
          (i.get('unit') ? i.get('unit').toLowerCase() : '') === unit
      );
      const i = items.find(
        (it) =>
          it.get('name').toLowerCase() === item.get('name').toLowerCase() &&
          (it.get('unit') ? it.get('unit').toLowerCase() : '') ===
            (item.get('unit') ? item.get('unit').toLowerCase() : '')
      );
      return item
        .set('isSelected', hasId)
        .set('backgroundColor', i ? '#eee' : '')
        .set('withoutCheckbox', !!i);
    });
  }

  hideModal() {
    this.props.hideModalDialog();
  }

  isShowPagination() {
    const { features, size, limit } = this.props;
    return (
      features &&
      features.size > 0 &&
      size > limit &&
      Math.ceil(size / limit) !== 1
    );
  }

  selectNextPagination(value) {
    const { catalogId, search, getAllProductsFeatures, limit } = this.props;
    getAllProductsFeatures(catalogId, search, limit, value);
  }

  render() {
    const {
      t,
      hideModalDialog,
      selectFeature,
      selected,
      size,
      limit,
      offset,
      modalAddFeature,
      catalogId,
      categoryId,
      productId,
      clearSelectedFeatures,
      gaSend,
      user
    } = this.props;

    return (
      <Modal id="orderStatusHistory" onHide={this.hideModal} show>
        <ModalContainer mods={{ type: 'default', theme: 'default' }}>
          <Modal.Header>
            <Modal.Title>
              {t('Add feature')}
              <span
                className="modal-header-cross pull-right"
                onClick={() => {
                  clearSelectedFeatures();
                  hideModalDialog();
                }}
              />
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <p>{t('add_feature_text_1')}</p>
              <div style={{ marginBottom: 25 }}>
                <SearchBlockDefault
                  placeholder={t('Feature name')}
                  onSubmit={this.onSearch}
                  onEmpty={this.onSearch}
                />
              </div>
              <Table
                newVersion
                columns={[
                  Table.ColText({
                    accessor: ({ name }) => capitalize(name),
                    title: t('Feature name'),
                    width: '75%'
                  }),
                  Table.ColText({
                    accessor: 'unit',
                    title: t('Unit'),
                    width: '20%'
                  })
                ]}
                data={this.getData()}
                onSelect={(id, item) => selectFeature(item)}
              />
            </Form>
            {this.isShowPagination() && (
              <Pagination
                totalSize={size}
                limit={limit}
                offset={offset || 0}
                nextPage={this.selectNextPagination}
              />
            )}
          </Modal.Body>
          <Modal.Footer>
            <Button
              id="cancel"
              className="pull-left"
              onClick={() => {
                clearSelectedFeatures();
                hideModalDialog();
              }}>
              {t('Cancel')}
            </Button>
            <Button
              id="saveCharacteristicButton"
              bsStyle="primary"
              disabled={!selected.size}
              onClick={async () => {
                await gaSend({
                  category: 'Supplier',
                  action: 'existing_add_feature_success',
                  label: user.get('email')
                });
                hideModalDialog();
                const item = selected.first();
                modalAddFeature(
                  item.get('name'),
                  item.get('unit'),
                  catalogId,
                  categoryId,
                  productId
                );
              }}>
              {t('Add')}
            </Button>
          </Modal.Footer>
        </ModalContainer>
      </Modal>
    );
  }
}

ModalAddExistedFeature.propTypes = {
  t: PropTypes.func.isRequired
};

ModalAddExistedFeature.childContextTypes = {
  b: PropTypes.func
};

export default connect(
  (state) => ({
    user: state.getIn(['user', 'user']),
    features: state.getIn(['catalog', 'features']),
    items: state.getIn(['tables', 'features', 'items']),
    size: state.getIn(['catalog', 'size']),
    limit: state.getIn(['catalog', 'limit']),
    offset: state.getIn(['catalog', 'offset']),
    selected: state.getIn(['catalog', 'selectedFeatures'])
  }),
  (dispatch) =>
    bindActionCreators(
      {
        showModal: modalActions.showModal,
        hideModalDialog: modalActions.hideModalDialog,
        selectFeature: featureActions.selectFeature,
        getProductFeatures: featureActions.getProductFeatures,
        addExistedFeatures: featureActions.addExistedFeatures,
        updateSelectedFeatureValue: featureActions.updateSelectedFeatureValue,
        addProductCharacteristic: featureActions.addProductCharacteristic,
        getAllProductsFeatures: featureActions.getAllProductsFeatures,
        modalAddFeature: featureActions.modalAddFeature,
        clearSelectedFeatures: featureActions.clearSelectedFeatures,
        gaSend: servicesAction.gaSend
      },
      dispatch
    )
)(translate(['ui'], { wait: true })(ModalAddExistedFeature));
