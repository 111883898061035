import fetch from 'isomorphic-fetch';
import customHistory from '../customHistory';
import req from '../utils/request';

const updateStatus = (status) => ({
  type: 'CATALOG_IMPORT_CHANGE_STATUS',
  status
});

const setImporting = () => updateStatus({ name: 'importing' });

const setCritical = (errors) => updateStatus({ name: 'critical', errors });

export const setWrongFileType = () =>
  setCritical([{ messageKey: 'Wrong file format' }]);

export const setImportType = (importType) => ({
  type: 'CATALOG_IMPORT_CHANGE_TYPE',
  importType
});

export const setCurrency = (currency) => ({
  type: 'CATALOG_IMPORT_CHANGE_CURRENCY',
  currency
});

export const setImportCatalogId = (id) => ({
  type: 'CATALOG_IMPORT_SET_ID',
  id
});

export const updateImportCatalogLanguage = (language) => ({
  type: 'CATALOG_IMPORT_CHANGE_LANGUAGE',
  language
});
export const cleanImportStatus = () => ({ type: 'CATALOG_IMPORT_CLEAN' });

const checkFile = (excelFile, dispatch) => {
  if (excelFile.size / 1024 / 1024 > 10) {
    dispatch(
      setCritical([{ message: { messageKey: 'Maximum file size is 10 Mb' } }])
    );
    return true;
  }
  return false;
};

export const startUploadExcel = (file) => (dispatch, getState) => {
  const importCatalog = getState().get('import');
  const type = importCatalog.get('type');
  const catalogId = importCatalog.get('language');
  if (checkFile(file, dispatch)) {
    return;
  }
  dispatch(setImporting());
  req.sendFile(`/api/import/catalog/${catalogId}/${type}`, file).catch((e) => {
    const reason = (e && e.message) || 'Failed to upload the excel file';
    dispatch(setCritical([{ message: { messageKey: reason } }]));
  });
};

export const finishImportCatalog = (catalogId) => (dispatch, getState) => {
  const id = getState().getIn(['import', 'id']);
  dispatch(cleanImportStatus());
  customHistory.push(`/catalogs/${catalogId || id}/products`);
};

export const getImportInfo = () => (dispatch) => {
  dispatch({ type: 'CATALOG:IMPORT_INFO:REQUEST' });
  return fetch('/api/catalogs/import_info', {
    credentials: 'include',
    method: 'GET'
  })
    .then((res) => res.json())
    .then((data) => {
      dispatch({ type: 'CATALOG:IMPORT_INFO:SUCCESS', payload: data });
    })
    .catch((res) => {
      dispatch({
        type: 'CATALOG:IMPORT_INFO:FAILURE',
        payload: { error: res.error }
      });
      console.error(res);
    });
};
