import { Map, fromJS } from 'immutable';

const setPriceCategory = (state, priceCategory) =>
  state.merge(fromJS(priceCategory));

const updatePriceCategoryField = (state, field, value) =>
  state.set(field, value);

const trimCatalogPriceCategoryField = (state, field) =>
  state.update(field, (value) => (value || '').trim());

const clearState = (state) => state.clear();

const priceCategory = (state = new Map(), action) => {
  switch (action.type) {
    case 'SET_PRICE_CATEGORY':
      return setPriceCategory(state, action.priceCategory);
    case 'UPDATE_PRICE_CATEGORY_FIELD':
      return updatePriceCategoryField(state, action.field, action.value);
    case 'TRIM_PRICE_CATEGORY_FIELD':
      return trimCatalogPriceCategoryField(state, action.field);
    case 'CLEAR_PRICE_CATEGORY_STATE':
      return clearState(state);
    default:
      return state;
  }
};

export default priceCategory;
