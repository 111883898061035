import fetch from 'isomorphic-fetch';
import { loadTable, sortBy, removeFromTable } from '../table';
import { showModal } from '../modal';
import { validate } from '../../validator';
import { getCountNewAvailable } from './available';

export const getShares = (catalogId) => (dispatch) =>
  fetch(`/api/share/${catalogId}`, { credentials: 'include' })
    .then((response) => response.json())
    .then((json) => {
      dispatch(loadTable(json.data, 'shares'));
      dispatch(sortBy('shareDate', 'shares'));
    });

export const showGrantAccessModal = () => (dispatch) => {
  dispatch(showModal('ACCESS_CATALOG'));
};

const updateCatalogAccessField = (field, value) => (dispatch) =>
  dispatch({
    type: 'CATALOG_ACCESS_UPDATE_FIELD',
    field,
    value
  });

export const grantAccess = (company, catalogId) => (dispatch) =>
  fetch(`/api/share/${catalogId}`, { credentials: 'include' })
    .then((response) => response.json())
    .then((json) => {
      const shareExists = json.data.some((share) => share.company === company);
      return new Promise((resolve, reject) => {
        if (shareExists) {
          const errorMessage = 'This email already has access to the catalog';
          dispatch(updateCatalogAccessField('errorMessage', errorMessage));
          reject(errorMessage);
        } else {
          fetch(`/api/share/${catalogId}`, {
            credentials: 'include',
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ data: { attributes: { company } } })
          })
            .then(() => {
              dispatch(getCountNewAvailable());
              dispatch(getShares(catalogId));
              resolve();
            })
            .catch((e) => {
              console.error('CREATE SHARE FAILED', e);
              // eslint-disable-next-line prefer-promise-reject-errors
              reject('Share creation failed');
            });
        }
      });
    });

export const deleteShare = (shareId) =>
  fetch(`/api/share/${shareId}`, {
    credentials: 'include',
    method: 'DELETE'
  });

export const deleteSelectedShares = () => (dispatch, getState) => {
  const selected = getState().getIn(['tables', 'shares', 'selected']).toArray();

  Promise.all(
    selected.map(
      (shareId) =>
        new Promise((resolve) =>
          deleteShare(shareId).then(({ ok, status, statusText }) =>
            (ok
              ? resolve(dispatch(removeFromTable(shareId, 'shares')))
              : resolve(
                  console.log('ERROR', 'SHARE DELETE', status, statusText)
                )
            ).catch(resolve)
          )
        )
    )
  );
};

export const updateCatalogAccessEmail = (value) => (dispatch) => {
  const trimmedValue = value.trim();
  validate(trimmedValue, 'email', dispatch, 'email');

  dispatch(updateCatalogAccessField('email', trimmedValue));
};

export const updateCatalogAccessErrorMessage = (value) => (dispatch) =>
  dispatch(updateCatalogAccessField('errorMessage', value));
