import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { CompaniesPage } from '../pages/companies';
import { CompanyProfilePage } from '../pages/messenger/companyProfile';

export const CompaniesRouter = () => (
  <Routes>
    <Route path="/" element={<CompaniesPage />} />
    <Route
      path="/:companyId/view"
      element={
        <CompanyProfilePage
          title="modules.company.title"
          name="modules.company.title"
          link="/companies"
        />
      }
    />
  </Routes>
);
