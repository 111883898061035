import React from 'react'; // eslint-disable-line
import { connect } from 'react-redux'; // eslint-disable-line
import { bindActionCreators } from 'redux'; // eslint-disable-line
import * as servicesActions from '../action-creators/services';

import { ModalAddPrice } from '../components/Modal';

import * as pricesAction from '../action-creators/prices';
import * as catalogsAction from '../action-creators/catalog';
import * as modalAction from '../action-creators/modal';

const ContModalAddPriceLists = (props) => <ModalAddPrice {...props} />;

export default connect(
  (state) => ({
    fields: state.getIn(['prices', 'formCreate']),
    moreInfo: state.getIn(['prices', 'moreInfo']),
    errors: state.getIn(['prices', 'errors']),
    currencyList: state.get('currencyList'),
    company: state.get('company'),
    user: state.getIn(['user', 'user'])
  }),
  (dispatch) =>
    bindActionCreators(
      {
        removeErrorByField: pricesAction.removeErrorByField,
        updateField: pricesAction.updateField,
        updateStocksField: pricesAction.updateStocksField,
        appendNewStock: pricesAction.appendNewStock,
        removeStock: pricesAction.removeStock,
        createPrice: pricesAction.createPrice,
        getAllPrices: pricesAction.getAllPrices,
        getMoreInfo: pricesAction.getMoreInfo,
        clearForm: pricesAction.clearForm,
        hideModalDialog: modalAction.hideModalDialog,
        gaPageView: servicesActions.gaPageView,
        gaSend: servicesActions.gaSend,
        getRBKRate: catalogsAction.getRBKRate
      },
      dispatch
    )
)(ContModalAddPriceLists);
