import { List } from 'immutable';
import { discountPrice } from '../lib/finance';
// eslint-disable-next-line import/no-cycle
import { totalDiscountByConditions } from '../viewModels/sum/purchasePriceCategories';
// eslint-disable-next-line import/no-cycle
import {
  adapterCalcPriceOfProductNotDraft,
  getVATOfSumNotDraft,
  pricePerAmount,
  calcPriceOfProductList,
  calcTotalDiscount
} from '../viewModels/sum/orderDraft';

const mainPath = (state) => state.get('orders');

const serilizeOrderProductWithoutCalc = (product) =>
  product
    .set('offers', product.get('offers') || List())
    .set('cost', Number(product.get('cost')) || 0)
    .set('vat', Number(product.get('vat')) || 0)
    .set('discount', Number(product.get('discount')) || 0)
    .set('draftReserved', Number(product.get('draftReserved')) || 0)
    .set('retailCost', Number(product.get('retailCost')) || 0)
    .set('count', Number(product.get('count')) || 0);

const serilizeOrderProduct = (state) => (product) =>
  product
    .set('offers', product.get('offers') || List())
    .set('cost', Number(product.get('cost')) || 0)
    .set('vat', Number(product.get('vat')) || 0)
    .set('discount', Number(product.get('discount')) || 0)
    .set('draftReserved', Number(product.get('draftReserved')) || 0)
    .set('retailCost', Number(product.get('retailCost')) || 0)
    .set('count', Number(product.get('count')) || 0)
    .set('vatSum', pricePerAmount(getVATOfSumNotDraft(state, product), product))
    .set('purchasePrice', adapterCalcPriceOfProductNotDraft(state, product))
    .set('totalDiscount', calcTotalDiscount(state, product))
    .set(
      'totalPrice',
      pricePerAmount(adapterCalcPriceOfProductNotDraft(state, product), product)
    );

export const getProducts = (state) =>
  mainPath(state).get('items').map(serilizeOrderProduct(state));
export const getSimpleProducts = (state) =>
  mainPath(state).get('items').map(serilizeOrderProductWithoutCalc);

const getOrderSum = (state) =>
  getProducts(state).reduce(
    (acc, product) => acc + product.get('totalPrice'),
    0
  );

const getOrderVATOfSum = (state) =>
  getProducts(state).reduce((acc, product) => acc + product.get('vatSum'), 0);

const getSumWithCatCondition = (state) =>
  discountPrice(
    totalDiscountByConditions(state),
    calcPriceOfProductList(
      adapterCalcPriceOfProductNotDraft(state),
      getProducts(state)
    )
  );

const getVatWithCatCondition = (state) =>
  discountPrice(
    totalDiscountByConditions(state),
    calcPriceOfProductList(getVATOfSumNotDraft(state), getProducts(state))
  );

export default (state) => ({
  items: () => getProducts(state),
  selected: () => mainPath(state).get('selected'),
  errors: () => mainPath(state).get('errors'),
  limit: () => mainPath(state).get('limit'),
  offset: () => mainPath(state).get('offset'),
  order: () => mainPath(state).get('order'),
  sum: () => getOrderSum(state),
  vat: () => getOrderVATOfSum(state),
  sumWithConditions: () => getSumWithCatCondition(state),
  vatWithConditions: () => getVatWithCatCondition(state),
  totalProducts: () =>
    getProducts(state).reduce((acc, product) => acc + product.get('count'), 0)
});
