import React, { useMemo } from 'react';
import { useTranslate } from '@hooks';
import { Tabs } from '@link/react-components';
import { CompaniesList, PartnersList } from './components';
import MessengerOther from '../../components/Layouts/MessengerOther';
import classes from './Companies.module.css';

export function CompaniesPage() {
  const t = useTranslate();

  const tabContent = useMemo(
    () => [
      { title: t('modules.companies.tabName'), content: <CompaniesList /> },
      { title: t('modules.partners.tabName'), content: <PartnersList /> }
    ],
    [t]
  );

  return (
    <MessengerOther
      theme="gray"
      title={t('modules.company.title')}
      breadcrumbs={[]}
      panelBtns={[]}
      content={<Tabs tabContent={tabContent} className={classes.companies} />}
      noShowPanel
    />
  );
}
