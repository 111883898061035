import { isEmpty, isNil } from 'ramda';
import { formatRequestNumber, getFullName } from '../../../../utils/utils';
import { formatDate } from '../../../../utils/date';
import { makeLinkVM } from '../../Request/linksVM';
import { makeFileVM } from '../../Request/filesVM';
import { downloadFile } from '../../Request/files';
import { makeBtnSmallIcon } from '../../Request/btnSmall';
import { showFilesViewer } from '../../../../utils/filesViewerInterfaceCreator';
import {
  accepted,
  clarification,
  rejected,
  rework,
  reworkCompleted,
  sent,
  viewed
} from '../../../../presenters/sidebars/PurchaseRequestResponsesSidebar/status';

export function getResponseId(response) {
  return response.id;
}

export function getRequestId(response) {
  return response.requestId || +response.attrs.purchaseRequest.id;
}

export function getRespondedUserName(response) {
  if (isNil(response.respondedUser)) return '-';

  return getFullName(response.respondedUser);
}

export function getHeaderText(t, response) {
  return `${t('response_to_request')} №${formatRequestNumber(
    getRequestId(response)
  )} - ${response.number}`;
}

export function getStatusText(t) {
  return `${t('response_status')}:`;
}

export function getTitle(response) {
  return response.title;
}

export function isShowLoader(response) {
  return isEmpty(response);
}

export function getStatus(response) {
  return response.status;
}

export function isConfirmed(response) {
  return getStatus(response) === accepted;
}

const isCanConfirm = (response) =>
  [sent, viewed, clarification, reworkCompleted].includes(getStatus(response));

export function isRejected(response) {
  return getStatus(response) === rejected;
}

export function isOnRework(response) {
  return getStatus(response) === rework;
}

export function isShowRejectButton(response) {
  return isCanConfirm(response);
}

export function isRejectButtonDisable(response) {
  return isRejected(response) || isOnRework(response);
}

export function getRejectButtonText(t, response) {
  return isRejected(response) ? t('rejected_offer') : t('reject_offer');
}

export function isShowConfirmButton(response) {
  return isCanConfirm(response) || isConfirmed(response);
}

export function isShowCancelButton(response) {
  return isConfirmed(response);
}

export function isShowConfirmTooltip(response) {
  return !isConfirmed(response);
}

export function isConfirmButtonDisable(response) {
  return isConfirmed(response) || isOnRework(response);
}

export function getAcceptButtonText(t, response) {
  return isConfirmed(response) ? t('accepted_offer') : t('accept_offer');
}

export function getRequestText(response) {
  return response.attrs.purchaseRequest.text;
}

export function getRequestProducts(response) {
  return response.attrs.purchaseRequest.products;
}

export function showLinksBlock(response) {
  return getRespondTotalLinks(response) > 0;
}

export function getRespondTotalLinks(response) {
  return hasRespondLinks(response)
    ? response.attrs.purchaseRequest.links.length
    : 0;
}

export function getRespondLinks(response) {
  return response.attrs.purchaseRequest.links.map((link) =>
    makeLinkVM({ key: link.id, url: link.url, name: link.name, buttons: [] })
  );
}

export function hasRespondLinks(response) {
  return !isNil(response.attrs.purchaseRequest.links);
}

export function hasRespondFiles(response) {
  return !isNil(response.attrs.purchaseRequest.files);
}

export function getRespondTotalFiles(response) {
  return hasRespondFiles(response)
    ? response.attrs.purchaseRequest.files.length
    : 0;
}

export function showFilesBlock(response) {
  return getRespondTotalFiles(response) > 0;
}

export function getRespondFiles(response, showModal) {
  const { files } = response.attrs.purchaseRequest;

  return files.map((file) =>
    makeFileVM({
      key: file.id,
      url: file.url,
      name: file.name,
      size: file.size,
      createdAt: file.createdAt,
      buttons: [
        makeBtnSmallIcon({
          name: 'download',
          icon: 'download-1',
          onClick: () => downloadFile(file)
        }),
        makeBtnSmallIcon({
          name: 'preview',
          icon: 'view',
          onClick: () => showFilesViewer({ file, files, showModal })
        })
      ]
    })
  );
}

export function getSupplierCompanyName(response) {
  return response.attrs.fromName;
}

export function getResponseCreatedDate(response) {
  return formatDate(response.createdAt, 'withOutTime');
}

export function isResponseFromCompany(response, companyId) {
  return response?.from === companyId;
}

export function isCurrentCompanyBuyer(response, companyId) {
  return response?.from !== companyId;
}
