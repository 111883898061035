import React from 'react';
import cn from 'classnames';
import styles from './GroupCell.module.css';

const GroupCell = ({ className, noHover, children }) => (
  <div className={cn(styles.container, noHover && styles.noHover, className)}>
    {children}
  </div>
);

export default GroupCell;
