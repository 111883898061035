import React from 'react';
import block from 'bem-cn-lite';
import ArrowLeftIcon from '../common/assets/icons/arrow-left';

const b = block('snm-header');

const Header = ({ onOpenFull }) => (
  <div className={b()} onClick={onOpenFull}>
    <div className={b('arrow', { right: true })}>
      <ArrowLeftIcon />
    </div>
  </div>
);

export default Header;
