const getDialog = (state, id) => {
  const index = state
    .getIn(['chat', 'dialogs', 'items'])
    .findIndex((dialog) => dialog.get('dialogId') === id);
  return state.getIn(['chat', 'dialogs', 'items', index]);
};

export const getEmployeeId = (state, dialogId) => {
  const dialog = getDialog(state, dialogId);

  if (!dialog) return undefined;

  return dialog.get('employeeId');
};

export const isBlockedContact = (state, dialogId) => {
  const dialog = getDialog(state, dialogId);

  if (!dialog) return undefined;

  return dialog.get('isBlockedContact');
};

export const getName = (state, dialogId) => {
  const dialog = getDialog(state, dialogId);

  if (!dialog) return undefined;

  const lastName = dialog.get('lastName');
  const firstName = dialog.get('firstName');

  return `${lastName} ${firstName}`;
};
