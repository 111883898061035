import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import cn from 'classnames';
import { useNavigate, useParams } from '@hooks';
import { useActions } from '@hooks/useActions';
import { Button, Icon, Typography } from '@components';
import styles from './Header.module.css';
import * as getters from '../../../../../components/PurchaseRequests/MyRequests/getters';
import * as PRGetters from '../../../../../components/PurchaseRequests/getters';
import * as compareStorage from '../../../../../components/PurchaseRequests/MyRequests/storage';
import iconsView from '../../../../../assets/images/constants';
import PopupSmall from '../../../../../components/PopupSmall';
import PopupContent from '../../PopupContent';
import { useTranslate } from '../../../../../TranslateProvider';

function LeftSide() {
  const [isOpened, setIsOpened] = useState(false);

  const navigate = useNavigate();

  const request = useSelector(PRGetters.getRequest);
  const compareResponses = useSelector(getters.getCompareResponses);
  const { requestId } = useParams();

  const goOnPage = (pathname) => () => navigate(pathname, { replace: true });

  const t = useTranslate();
  return (
    <div className={styles.rightSideContent}>
      <Typography variant="h1">
        <Link to="/requests/my" className={styles.allPurchaseRequestsLink}>
          {t('Purchase requests')}
          &nbsp;/&nbsp;
        </Link>
      </Typography>
      <div
        onClick={() => setIsOpened(true)}
        className={styles.currentPurchaseRequest}>
        <Typography variant="h1">{`${t(
          'comparing_responses'
        )} №${requestId} ${request.get('title')}`}</Typography>
        <Icon
          name={iconsView.ArrowDown}
          size="m"
          className={cn({ [styles.arrowDown]: isOpened })}
        />
        <PopupSmall
          content={
            <PopupContent
              goOnPage={goOnPage}
              compareResponses={compareResponses}
            />
          }
          isOpened={isOpened}
          setIsOpened={setIsOpened}
        />
      </div>
    </div>
  );
}

function RightSide({ deletedList, setDeletedList }) {
  const t = useTranslate();

  const { deleteCompareList, restoreCompareList } = useActions({
    deleteCompareList: compareStorage.deleteCompareList,
    restoreCompareList: compareStorage.restoreCompareList
  });

  const responses = useSelector(PRGetters.getResponses);
  const compareResponses = useSelector(getters.getCompareResponses);
  const { requestId = 1000 } = useParams();
  const toggleDelete = (isDelete) => () => {
    if (isDelete) {
      deleteCompareList({ requestId });
      setDeletedList(compareResponses[requestId].list);
    } else {
      restoreCompareList({ requestId, responseList: deletedList });
      setDeletedList([]);
    }
  };

  const compareResponsesFromRequest = responses
    .toJS()
    .filter((response) =>
      compareResponses[requestId]?.list.includes(response.id)
    );

  if (deletedList.length === 0) {
    return (
      <Button
        onClick={toggleDelete(true)}
        disabled={compareResponsesFromRequest.length === 0}
        className={cn(styles.btn, styles.btnText)}>
        <Icon name={iconsView.Trash} size="m" />
        {t('delete_list')}
      </Button>
    );
  }
  return (
    <Button
      onClick={toggleDelete(false)}
      className={cn(styles.btn, styles.btnText)}>
      <Icon name={iconsView.Reset} size="m" />
      {t('restore_list')}
    </Button>
  );
}

export const Header = ({ deletedList, setDeletedList }) => (
  <div className={styles.header}>
    <LeftSide />
    <RightSide deletedList={deletedList} setDeletedList={setDeletedList} />
  </div>
);
