import { Map, fromJS } from 'immutable';

import categoryNewReducer from './category/new';

const loadItem = (state, item) => state.set('item', fromJS(item));

// const clearCategory = state => {
//   return state.clear();
// };

const showHideDeleteConfirmation = (state, flag) =>
  state.set('deleteConfirmation', flag);

const setCategoryField = (state, field, value) =>
  state.update('item', (item) =>
    item.setIn(['attributes', field], fromJS(value))
  );

const trimCategoryField = (state, field) =>
  state.updateIn(['item', 'attributes', field], (value) =>
    (value || '').trim()
  );

const setCategoryError = (state, errorMessage) =>
  state.set('errorMessage', errorMessage);

const setNewCategoryField = (state, field, value) =>
  state.update('new', (item) => item.set(field, fromJS(value)));

const initNewCat = { name: '', text: '', src: '' };

const initState = () =>
  new Map({
    new: new Map(initNewCat)
  });

const newCatClear = (state) => state.set('new', Map(initNewCat));

const categoryReducer = (state = initState(), action) => {
  switch (action.type) {
    case 'CATEGORY_LOAD':
      return loadItem(state, action.item);
    case 'CATEGORY_CLEAR':
      return initState();
    case 'NEW_CATEGORY_CLEAR':
      return newCatClear(state);
    case 'CATEGORY_DELETE_CONFIRMATION':
      return showHideDeleteConfirmation(state, action.flag);
    case 'CATEGORY_SET_FIELD':
      return setCategoryField(state, action.field, action.value);
    case 'NEW_CATEGORY_SET_FIELD':
      return setNewCategoryField(state, action.field, action.value);
    case 'CATEGORY_TRIM_FIELD':
      return trimCategoryField(state, action.field);
    case 'CATEGORY_ERROR':
      return setCategoryError(state, action.errorMessage);
    default:
      return state.update('new', (newState) =>
        categoryNewReducer(newState, action)
      );
  }
};

export default categoryReducer;
