import React, { useLayoutEffect, useRef, useState } from 'react';
import Icon from 'react-components/src/icons';
import iconsView from 'react-components/src/icons/constants';
import Text from 'react-components/src/components/Typography';
import { useSelector } from 'react-redux';
import { useActions } from '@hooks/useActions';
import styles from './HeaderInput.module.css';
import * as getters from '../../../../../components/ChatWidget/modules/constructor/getters';
import * as actions from '../../../../../components/ChatWidget/modules/constructor/actions';
import { ActionButton } from '../buttons/actionButton';
import useOutsideClick from '../../../../../components/ChatWidget/hooks/outsideClick';
import { useIsCanBeDeleted } from '../../../../../contexts/RouteIsCanBeDeletedContext';

const inputModes = {
  focus: 'focus',
  default: 'default'
};

const eventCodes = {
  enter: 13,
  escape: 27
};

const MAX_TITLE_LENGTH = 100;
const MIN_TITLE_LENGTH = 1;

export const HeaderInput = () => {
  const name = useSelector(getters.getConstructorName);
  const isCanBeDeleted = useIsCanBeDeleted();
  const [inputMode, setInputMode] = useState(inputModes.default);

  const { changeBuilderName } = useActions(actions);
  const [builderName, setBuilderName] = useState('');

  const ref = useRef(null);
  const inputRef = useRef(null);
  const wrapperInputRef = useRef(null);

  const onFocusEnd = () => {
    setBuilderName(name);
    setInputMode(inputModes.default);
  };

  const onChangeInputStatus = (fn, arg) => {
    fn(arg);
    setInputMode(inputModes.default);
  };

  useOutsideClick(wrapperInputRef, onFocusEnd);

  const onDiscardNameChanges = (e) => {
    e.stopPropagation();
  };

  const onAcceptNameChanges = (e) => {
    e.stopPropagation();
    if (builderName.trim().length === 0) {
      onChangeInputStatus(setBuilderName, name);
      return;
    }
    onChangeInputStatus(changeBuilderName, builderName);
  };

  const onClickByWrapper = (e) => {
    if (!isCanBeDeleted) return;
    e.stopPropagation();

    setInputMode(inputModes.focus);
    if (name !== builderName) {
      setBuilderName(name);
    }
  };

  useLayoutEffect(() => {
    if (inputMode === inputModes.focus) {
      inputRef.current.focus();
    }
  }, [inputMode]);

  const onMetaKeyPressed = (e) => {
    switch (e.keyCode) {
      case eventCodes.enter:
        if (builderName.trim().length === 0) {
          onChangeInputStatus(setBuilderName, name);
          return;
        }
        onChangeInputStatus(changeBuilderName, builderName);
        return;
      case eventCodes.escape:
        onChangeInputStatus(setBuilderName, name);
        break;
      default:
        break;
    }
  };

  return (
    <div className={styles.wrapper} ref={ref} onClick={onClickByWrapper}>
      {inputMode === inputModes.default && (
        <div className={styles.staticContent}>
          <Icon icon={iconsView.Pen} size={16} />
          <Text variant="h2" className={styles.title}>
            {name}
          </Text>
        </div>
      )}

      {inputMode === inputModes.focus && (
        <div ref={wrapperInputRef} className={styles.editableWrapper}>
          <input
            onKeyDown={onMetaKeyPressed}
            className={styles.editableInput}
            ref={inputRef}
            value={builderName}
            onChange={(e) => {
              setBuilderName(e.target.value);
            }}
            maxLength={MAX_TITLE_LENGTH}
            minLength={MIN_TITLE_LENGTH}
          />
          <div className={styles.droppedBtns}>
            <ActionButton
              icon={iconsView.Ok}
              size={14}
              stylesIconClassName={styles.acceptButton}
              onClick={onAcceptNameChanges}
            />
            <ActionButton
              icon={iconsView.CloseLarge}
              size={14}
              stylesIconClassName={styles.discardButton}
              onClick={onDiscardNameChanges}
            />
          </div>
        </div>
      )}
    </div>
  );
};
