import React, { Component } from 'react';
import { translate } from 'react-i18next';
import { ControlLabel } from 'react-bootstrap';
import { isEmpty, map, pipe, prop, sortBy, toLower } from 'ramda';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { ExpandableText } from '@link/react-components';
import Tags from '../../Tags';
import FilesBlock from './FilesBlock';
import LinksListBlock from './LinksListBlock';
import { makeLinkVM } from './linksVM';
import SectionOffset from './SectionOffset';
import { makeFileVM } from './filesVM';
import ProductsTable from '../common/ProductsTable';
import { makeBtnSmallIcon } from './btnSmall';
import { downloadFile } from './files';
import * as contactActions from '../../../action-creators/contacts';
import * as modalActions from '../../../action-creators/modal';
import * as storeGetters from '../../../storeGetters';
import * as storage from '../MyRequests/storage';
import { showFilesViewer } from '../../../utils/filesViewerInterfaceCreator';
import {
  convertFileUrl,
  convertFileUrls,
  convertUrlToFile
} from '../../ChatWidget/modules/files/utils';
import ResponsesTable from '../MyRequests/RequestTable/ResponsesTable';
import CompareChosenButton from '../common/CompareChosenButton';
import OpenResponseChatButton from '../../OpenResponseChat';
import './RequestContentView.css';
import { getLocationTitle } from '../utils';
import { isNilOrEmpty } from '../../../utils/utils';
import { purchaseRequestService } from '../../../api';
import { ResponseEndDate } from './components/RespondEndDate';
import * as PRActions from '../../../action-creators/purchaseRequests';

const ONLY_AUTHORIZED_FILES_BUTTONS = ['preview', 'download'];
const DEFAULT_LANGUAGE = 'ru';

class RequestContent extends Component {
  get links() {
    return this.props.request
      .get('links')
      .toJS()
      .map((link) =>
        makeLinkVM({
          ...link,
          key: `${link.id}l`
        })
      );
  }

  get files() {
    const { showModal, request, isAuth } = this.props;
    const files = request.get('attachments').toJS();
    const fileInfoArray = convertFileUrls(
      convertFileUrls(files.map((f) => f.fileInfo)),
      'originalUrl'
    );

    return files.map((file) =>
      makeFileVM({
        key: file.fileInfo.id,
        createdAt: file.fileInfo.createdAt,
        name: file.fileInfo.name,
        size: file.fileInfo.size,
        buttons: [
          makeBtnSmallIcon({
            name: 'download',
            icon: 'download-1',
            onClick: () =>
              downloadFile({
                ...file,
                url: convertUrlToFile(file.fileInfo.originalUrl)
              })
          }),
          makeBtnSmallIcon({
            name: 'preview',
            icon: 'view',
            onClick: () => {
              showFilesViewer({
                showModal,
                files: fileInfoArray,
                file: convertFileUrl(file.fileInfo, 'originalUrl')
              });
            }
          })
        ].filter((btn) => {
          if (isAuth) return btn;

          return btn && !ONLY_AUTHORIZED_FILES_BUTTONS.includes(btn.name);
        })
      })
    );
  }

  get products() {
    const { request } = this.props;

    return request
      .get('products')
      .toJS()
      .map((product) => (!product.price ? { ...product, price: 0 } : product));
  }

  get request() {
    const { request } = this.props;
    return request.toJS();
  }

  get responses() {
    const { request, purchaseRequestsPermissions } = this.props;
    return request.has('responses') && purchaseRequestsPermissions.get('read')
      ? request.get('responses').toJS()
      : null;
  }

  get exportToExcelBtn() {
    const { request } = this.props;
    return {
      icon: {
        name: 'excel',
        size: 18
      },
      tkeyText: 'respondForm.form.btn-export-to-excel',
      onClick: async () =>
        purchaseRequestService.exportRequestToExcel(request.get('id'))
    };
  }

  componentDidMount() {
    this.props.loadContacts();
    this.props.setInitialCompareList();
  }

  getImage(i) {
    return i.get('companyInfo.logo') || '/img/tmp_img_big.svg';
  }

  getRequestAddress(request) {
    const { language = DEFAULT_LANGUAGE } = this.props;

    const address = [
      getLocationTitle(request.country, language),
      getLocationTitle(request.region, language),
      getLocationTitle(request.city, language)
    ].filter(Boolean);

    return address.join(', ');
  }

  getCategories = () => this.props.categories;

  getDisplayCategories = () => {
    const { t } = this.props;

    return pipe(
      map((cat) => ({
        ...cat,
        name: t(`purchaseRequestsCategories.${cat.name}`)
      })),
      sortBy(pipe(prop('name'), toLower))
    )(this.getCategories());
  };

  getSelectedDisplayCategories = () =>
    this.getDisplayCategories().filter((category) => category.selected);

  getKpp = () =>
    this.props.request.getIn(['companyInfo', 'requisites', 'kpp', 'value']);

  getAddress = () =>
    this.props.request.getIn([
      'companyInfo',
      'requisites',
      'legalAddress',
      'value'
    ]);

  formatDate = (date) => date.slice(0, 10).split('-').reverse().join('.');

  isUserInContactList = (userId, contacts) =>
    !!contacts.toJS().find((contact) => contact.userId === userId);

  isShowChatButton = () => {
    const { request, user } = this.props;
    return (
      user.get('id') && request.get('companyId') !== user.get('currentCompany')
    );
  };

  isCanAddToCompare() {
    const { request, user } = this.props;
    return (
      user.get('currentCompany') === request.toJS().companyId &&
      !isEmpty(request.toJS().products)
    );
  }

  isAuthorizedCompaniesSectionVisible() {
    const { request, user } = this.props;
    return user.get('currentCompany') === request.toJS().companyId;
  }

  getAuthorizedCompanies() {
    const { t, request } = this.props;

    const { authorizedCompanies } = request.toJS();

    if (isNilOrEmpty(authorizedCompanies)) {
      return t('requestContentView.authorizedCompanies.availableForEveryone');
    }

    const authorizedCompaniesList = Array.from(
      this.props.request.get('authorizedCompanies')
    );
    return authorizedCompaniesList.length === 0
      ? t('requestContentView.authorizedCompanies.availableForEveryone')
      : authorizedCompaniesList.map((value) => value.get('name')).join(', ');
  }

  render() {
    const {
      t,
      request,
      onClickCompany,
      isShowExportToExcelBtn,
      openChatCb,
      updateRequest
    } = this.props;
    const company = request.get('companyInfo');
    const responsibleUser = request.get('responsibleUser');

    if (!company || !responsibleUser) {
      return null;
    }
    return (
      <div>
        <div className="purchase-grid">
          <div className="purchase-grid__title">
            {t('purchase_request_title')}
          </div>
          <div
            className="purchase-grid__text"
            data-testid="request-purchase-title">
            {request.get('title')}
          </div>
          {request.get('lastPublishedDate') && (
            <>
              <div className="purchase-grid__title">
                {t('Publication date')}
              </div>
              <div
                className="purchase-grid__text"
                data-testid="request-purchase-updatedAt">
                {this.formatDate(request.get('lastPublishedDate'))}
              </div>
            </>
          )}
          {request.get('responseEndDate') && (
            <>
              <div className="purchase-grid__title">
                {t('date_responses_end')}
              </div>
              <ResponseEndDate
                request={request.toJS()}
                updateRequest={updateRequest}
              />
            </>
          )}
          <div className="purchase-grid__title">{t('Categories')}</div>
          <Tags
            testId="request-purchase-date-responses-end"
            plainText
            categories={this.getSelectedDisplayCategories()}
          />
          <div className="purchase-grid__title">{t('Delivery region')}</div>
          <div data-testid="request-purchase-delivery-region">
            {this.getRequestAddress(request.toJS())}
          </div>
        </div>

        {this.isAuthorizedCompaniesSectionVisible() && (
          <>
            <hr style={{ opacity: '0.3' }} />
            <div className="purchase-grid">
              <div className="purchase-grid__title">
                {t(
                  'requestContentView.authorizedCompanies.authorizedCompaniesList'
                )}
              </div>
              <div
                className="purchase-grid__text"
                data-testid="request-purchase-authorized-companies">
                <ExpandableText
                  textContent={this.getAuthorizedCompanies()}
                  showLessText={t('shared.expandableText.showLess')}
                  showMoreText={t('shared.expandableText.showMore')}
                />
              </div>
            </div>
          </>
        )}

        <hr style={{ opacity: '0.3' }} />
        <div className="purchase-grid">
          <div className="purchase-grid__title">{t('companyName')}</div>
          <div
            className="purchase-grid__text"
            data-testid="request-purchase-name">
            {onClickCompany ? (
              <a onClick={onClickCompany}>{company.get('name')}</a>
            ) : (
              company.get('name')
            )}
          </div>

          {company.get('inn') && (
            <>
              <div className="purchase-grid__title">{t('inn')}</div>
              <div data-testid="request-purchase-inn">{company.get('inn')}</div>
            </>
          )}

          {this.getKpp() && (
            <>
              <div className="purchase-grid__title">{t('kpp')}</div>
              <div
                className="purchase-grid__text"
                data-testid="request-purchase-kpp">
                {this.getKpp()}
              </div>
            </>
          )}
          {company.get('ogrn') && (
            <>
              <div className="purchase-grid__title">{t('ogrn')}</div>
              <div
                className="purchase-grid__text"
                data-testid="request-purchase-orgn">
                {company.get('ogrn')}
              </div>
            </>
          )}

          {this.getAddress() && (
            <>
              <div className="purchase-grid__title">{t('legalAddress')}</div>
              <div
                className="purchase-grid__text"
                data-testid="request-purchase-address">
                {this.getAddress()}
              </div>
            </>
          )}
          <div className="purchase-grid__title">{t('responsibleEmployee')}</div>
          <div className="request-view-row ">
            <div data-testid="request-purchase-personal-info">
              {responsibleUser.get('lastName')}
              &nbsp;
              {responsibleUser.get('firstName')}
              &nbsp;
              {responsibleUser.get('middleName')}
            </div>
            {this.isShowChatButton() && (
              <OpenResponseChatButton
                requestId={request.get('id')}
                response={request.get('responded')?.toJS()}
                onOpened={openChatCb}
                isWidget
              />
            )}
          </div>
        </div>

        <hr style={{ opacity: '0.3' }} />

        <ControlLabel
          data-testid="request-purchase-content-label"
          className="control-label-row-request">
          {t('content')}
        </ControlLabel>
        <SectionOffset offset={20} />
        <section
          className="request-row-content description"
          data-testid="request-purchase-products-list"
          dangerouslySetInnerHTML={{ __html: request.get('productsList') }}
        />

        <SectionOffset offset={20} />

        {request.get('products')?.size > 0 && (
          <ProductsTable
            products={this.products}
            editMode={false}
            exportToExcelBtn={this.exportToExcelBtn}
            isShowExportToExcelBtn={isShowExportToExcelBtn}
          />
        )}

        {!request.get('products')?.size > 0 && request.get('sum') && (
          <>
            <SectionOffset offset={20} />
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              {t('Purchase price')}
              &nbsp;
              {request.get('sum')}
            </div>
          </>
        )}

        {request.get('supplierRequirements') && (
          <div>
            <div className="row-delimiter" />
            <ControlLabel
              className="control-label-row-request"
              data-testid="request-purchase-supplier-label">
              {t('Supplier requirements')}
            </ControlLabel>
            <div
              className="request-row-content"
              data-testid="request-purchase-supplier-requirements">
              {request.get('supplierRequirements')}
            </div>
          </div>
        )}

        <SectionOffset offset={20} />

        {request.get('attachments').size > 0 && (
          <FilesBlock
            total={request.get('attachments').size}
            files={this.files}
          />
        )}
        {request.get('attachments').size > 0 &&
          request.get('links').size > 0 && <SectionOffset offset={28} />}
        {request.get('links').size > 0 && (
          <LinksListBlock
            total={request.get('links').size}
            links={this.links}
          />
        )}

        {this.responses && (
          <div className="request-content__responses">
            <div className="control-label-row-request">
              {t('Responses')}
              <span
                style={{
                  color: '#767B92'
                }}>{` (${this.responses.length})`}</span>
              {this.isCanAddToCompare() && (
                <CompareChosenButton display={this.responses.length > 0} />
              )}
            </div>
            <SectionOffset offset={16} />
            <ResponsesTable
              isOpened
              request={this.request}
              responses={this.responses}
            />
          </div>
        )}
      </div>
    );
  }
}

const mapDispatch = (dispatch) =>
  bindActionCreators(
    {
      loadContacts: contactActions.loadContacts,
      showModal: modalActions.showModal,
      setInitialCompareList: storage.setInitialCompareList,
      updateRequest: PRActions.updateRequest
    },
    dispatch
  );

export default connect(
  (state) => ({
    isAuth: storeGetters.isAuth(state),
    user: state.getIn(['user', 'user']),
    currentCompany: storeGetters.getCompany(state),
    language: storeGetters.getCurrentUserLanguage(state),
    purchaseRequestsPermissions:
      storeGetters.getPurchaseRequestsPermission(state)
  }),
  mapDispatch
)(translate()(RequestContent));
