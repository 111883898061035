import React, { Component } from 'react';
import PropTypes from 'prop-types';
import block from 'bem-cn-lite';
import { translate } from 'react-i18next';
import {
  Modal,
  Col,
  Row,
  Form,
  FormGroup,
  ControlLabel,
  Button
} from 'react-bootstrap';

import '../styles/modal_theme_default.styl';
import InputNumber from '../../InputNumber';

import { ModalContainer } from '../elements';

const b = block('modal-container');

class ModalAddDiscount extends Component {
  constructor(props) {
    super(props);
    this.state = {
      percent: 1,
      cost: 0,
      up: true,
      manual: true
    };
    this.inputProps = this.inputProps.bind(this);
    this.setPercent = this.setPercent.bind(this);
    this.setCost = this.setCost.bind(this);
  }

  getChildContext() {
    return { b };
  }

  getProperty(field) {
    return this.props.newPriceCategory && this.props.newPriceCategory.has(field)
      ? this.props.newPriceCategory.get(field)
      : '';
  }

  setPercent(percent) {
    this.setState({ percent });
  }

  setCost(cost) {
    this.setState({ cost });
  }

  getErrorInfo(fieldName) {
    return this.props.errors.get(fieldName);
  }

  inputProps(field) {
    return {
      value: this.props.fields.get(field),
      onChange: (e) => {
        if (this.getErrorInfo(field)) {
          this.props.removeErrorByField(field);
        }
        return this.props.updateFieldStock(field, e.target.value);
      }
    };
  }

  render() {
    const {
      t,
      captionKey,
      hideModalDialog,
      submit,
      priceId,
      getProducts,
      search,
      limit,
      offset,
      gaSend,
      user
    } = this.props;

    return (
      <ModalContainer mods={{ theme: 'default' }}>
        <Modal id="createStock" onHide={() => hideModalDialog()} show>
          <Modal.Header>
            <Modal.Title>
              {t(captionKey || 'Edit baseprice of products')}
              <span
                className="modal-header-cross pull-right"
                onClick={() => hideModalDialog()}
              />
            </Modal.Title>
          </Modal.Header>
          <Modal.Body style={{ overflow: 'hidden' }}>
            <div style={{ display: 'grid' }}>
              <Col xs={12}>
                <Form horizontal onSubmit={(e) => e.preventDefault()}>
                  <Row style={{ paddingBottom: 10 }}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <span
                        className={b('radio-btn', {
                          set: this.state.up && !this.state.manual
                        })}
                        onClick={() =>
                          this.setState({ up: true, manual: false })
                        }
                      />
                      <span className="modal-checkbox-label">
                        {t('Add extra charge')}
                      </span>
                    </div>
                  </Row>
                  <Row>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <span
                        className={b('radio-btn', {
                          set: !this.state.up && !this.state.manual
                        })}
                        onClick={() =>
                          this.setState({ up: false, manual: false, cost: 0 })
                        }
                      />
                      <span className="modal-checkbox-label">
                        {t('Decrease price')}
                      </span>
                    </div>
                  </Row>
                  <br />
                  <FormGroup
                    controlId="discount"
                    style={{ display: 'flex', alignItems: 'center' }}>
                    <ControlLabel style={{ fontWeight: 'normal' }}>
                      {t('Percent of recalculation')}
                    </ControlLabel>
                    <InputNumber
                      style={{ marginLeft: 'auto' }}
                      value={this.state.percent}
                      defaultValue={1}
                      min={1}
                      max={this.state.up ? 99999 : 99}
                      onChange={this.setPercent}
                      disabled={this.state.manual}
                    />
                  </FormGroup>
                </Form>
              </Col>
              <div style={{ borderTop: '1px solid #ccc', marginBottom: 15 }} />
              <Col xs={12}>
                <Form horizontal onSubmit={(e) => e.preventDefault()}>
                  <Row style={{ paddingBottom: 10, display: 'flex' }}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <span
                        className={b('radio-btn', { set: this.state.manual })}
                        onClick={() =>
                          this.setState({ manual: true, up: false, percent: 1 })
                        }
                      />
                      <span className="modal-checkbox-label">
                        {t('Manual cost input')}
                      </span>
                    </div>
                    <InputNumber
                      style={{ marginLeft: 'auto' }}
                      value={this.state.cost}
                      defaultValue={0}
                      min={0}
                      max={99999999}
                      onChange={this.setCost}
                      disabled={!this.state.manual}
                    />
                  </Row>
                </Form>
              </Col>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              id="cancel"
              className="pull-left"
              onClick={() => {
                hideModalDialog();
              }}>
              {t('Cancel')}
            </Button>
            <Button
              id="saveCharacteristicButton"
              bsStyle="primary"
              onClick={async () => {
                await gaSend({
                  category: 'Supplier',
                  action: 'base_price_change',
                  label: user.get('email')
                });
                return submit(priceId, this.state)
                  .then(hideModalDialog)
                  .then(() => getProducts(priceId, search, offset, limit));
              }}>
              {t('Change')}
            </Button>
          </Modal.Footer>
        </Modal>
      </ModalContainer>
    );
  }
}

ModalAddDiscount.propTypes = {
  t: PropTypes.func.isRequired
};

ModalAddDiscount.childContextTypes = {
  b: PropTypes.func
};

export default translate(['ui'], { wait: true })(ModalAddDiscount);
