/* eslint-disable max-len */

import React from 'react';

const SortUpIcon = ({ onClick = () => {}, isActive }) => (
  <svg
    onClick={onClick}
    width="8"
    height="5"
    viewBox="0 0 8 5"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M4 0L8 5H0L4 0Z"
      fill={`${isActive ? '#767B92' : 'rgba(118, 123, 146, 0.15)'}`}
    />
  </svg>
);

export default SortUpIcon;
