import i18n from 'i18next';

export function formatSum(
  value,
  { totalMaximumDigits = 11, maxFractionDigits = 2, divider = '.' } = {}
) {
  return new Intl.NumberFormat('ru-RU', {
    maximumFractionDigits: maxFractionDigits,
    maximumSignificantDigits: totalMaximumDigits
  })
    .format(value)
    .replace(',', divider);
}

export function getTranslate(t, tKey) {
  return t(`purchaseRequestComparison.table.${tKey}`);
}

const isEveryOptionChecked = (options) =>
  options.every((option) => option.checked);

export const isEveryOptionNoChecked = (options) =>
  options.every((option) => !option.checked);

const selected = (label) =>
  `${i18n.t('purchaseRequestComparison.filter.selected', { label })}`;

export const filterText = (options) => {
  if (isEveryOptionChecked(options))
    return `${selected(i18n.t('purchaseRequestComparison.filter.all'))}`;

  const checkedOptions = options.filter((option) => option.checked);
  if (checkedOptions.length > 1) return `${selected(checkedOptions.length)}`;

  if (checkedOptions.length === 1)
    return `${selected(checkedOptions[0].label)}`;

  return i18n.t('purchaseRequestComparison.filter.noSelected');
};
