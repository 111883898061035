import { curry } from 'ramda';
import {
  initUseStateDataType,
  getData,
  updateState
} from '../../../lib/data-hook';

/*
 * Hook for work with Text Editor change mode andd set the text
 */

export const EDIT = 'EDIT';
export const READ = 'READ';

const makeEditText = ({ text, mode }) => ({
  mode,
  text
});
const getMode = (editText) => editText.mode;
const getText = (editText) => editText.text;
const isEmptyText = (editText) => getText(editText) === '';
const isMode = curry((mode, editText) => mode === getMode(editText));
const setText = (text, editText) => ({ ...editText, text });
const setMode = (mode, editText) => ({ ...editText, mode });

export const useEditorText = initUseStateDataType(makeEditText);
export const isReadMode = getData(isMode(READ));
export const isEditMode = getData(isMode(EDIT));
export const getTextET = getData(getText);
export const isEmptyTextET = getData(isEmptyText);
export const updateText = updateState(setText);
export const clearText = updateState(setText, '');
export const updateETMode = updateState(setMode);
export const toReadMode = updateETMode(READ);
export const toEditMode = updateETMode(EDIT);
