import React, { Fragment, useEffect, useMemo, useState } from 'react';
import { translate } from 'react-i18next';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { bindActionCreators } from 'redux';
import { withRouter } from '../../../hoc/withRouter';
import { Select } from '../../FormControls';
import * as storage from './storage';
import { actions } from './reducer';
import * as getters from './getters';
import './styles.css';

import RequestTable from './RequestTable';
import Checkbox from '../../Checkbox';
import SearchBlockDefault from '../../SearchBlock/SearchBlockDefault';
import PopupSmall from '../../PopupSmall';
import Filter from '../../Buttons/Filter';
import ButtonIcon from '../../ChatWidget/ButtonIcon';
import CompareChosenButton from '../common/CompareChosenButton';

const DEFAULT_SORT_DIRECTION = {
  id: 'ASC',
  status: 'ASC',
  updatedAt: 'DESC'
};

const toggleDirection = (currentDirection) =>
  currentDirection === 'DESC' ? 'ASC' : 'DESC';

const isFilterActive = (filterCheckbox) =>
  Object.values(filterCheckbox).some((isChecked) => isChecked);

const MyPurchaseRequestsTable = ({
  t,
  setSearch,
  getMyRequests,
  limit,
  offset,
  search,
  orderBy,
  direction,
  isUploaded,
  setInitialCompareList
}) => {
  const sortOptions = [
    { value: 'updatedAt', label: t('by_new') },
    { value: 'id', label: t('by_request_number') },
    { value: 'status', label: t('by_status') },
    { value: 'responsibleUser', label: t('by_responsible') }
  ];

  const [isArchive, setIsArchive] = useState(false);
  const [directionList, setDirectionList] = useState(DEFAULT_SORT_DIRECTION);
  const [isFilterOpened, setIsFilterOpened] = useState(false);
  const [filterResponseCheckbox, setFilterResponseCheckbox] = useState({});
  const [filterRequestCheckbox, setFilterRequestCheckbox] = useState({});
  const [sortValue, setSortValue] = useState(sortOptions[0]);

  useEffect(() => {
    setInitialCompareList();
  }, []);

  const onSearchChange = (value) => setSearch(value);

  const getSelectedStatus = (statuses) =>
    Object.keys(statuses).filter((key) => statuses[key]);

  const onChangeSort = ({ value }) => {
    setDirectionList((prevDirection) => ({
      ...prevDirection,
      [value]: toggleDirection(prevDirection[value])
    }));
    getMyRequests({
      limit,
      offset,
      search,
      order: value,
      direction: toggleDirection(directionList[value]),
      selectedStatus: getSelectedStatus(filterRequestCheckbox),
      selectedResponseStatus: getSelectedStatus(filterResponseCheckbox),
      isArchive
    });
    setSortValue(value);
  };

  const onSearch = (currSearch = '') => {
    getMyRequests({
      limit,
      search: currSearch,
      order: orderBy,
      direction,
      selectedStatus: getSelectedStatus(filterRequestCheckbox),
      selectedResponseStatus: getSelectedStatus(filterResponseCheckbox),
      isArchive
    });
  };

  const toggleCheckbox = (name, setFilterCheckbox) => () => {
    setFilterCheckbox((filterCheckbox) => ({
      ...filterCheckbox,
      [name]: !filterCheckbox[name]
    }));
  };

  const toggleIsArchive = () => setIsArchive(!isArchive);

  useEffect(() => {
    getMyRequests({
      limit,
      search,
      order: orderBy,
      direction,
      selectedStatus: getSelectedStatus(filterRequestCheckbox),
      selectedResponseStatus: getSelectedStatus(filterResponseCheckbox),
      isArchive
    });
  }, [filterRequestCheckbox, filterResponseCheckbox, isArchive]);

  const requestStatuses = useMemo(
    () => [
      { id: 'draft', name: t('Status draft') },
      { id: 'receiving_responses', name: t('Status receiving_responses') }
    ],
    [t]
  );

  const responseStatuses = useMemo(
    () => [
      { id: 'sent', name: t(`respondStatus.sent-as-buyer`) },
      { id: 'viewed', name: t(`respondStatus.viewed`) },
      { id: 'rejected', name: t(`respondStatus.rejected`) },
      { id: 'clarification', name: t(`respondStatus.clarification`) },
      { id: 'accepted', name: t(`respondStatus.accepted`) },
      { id: 'on-rework', name: t(`respondStatus.on-rework`) },
      { id: 'rework-completed', name: t(`respondStatus.rework-completed`) }
    ],
    [t]
  );

  const popupFilterContent = (
    <div className="compare-popup">
      <div className="compare-popup-item" style={{ marginTop: 8 }}>
        {t('request_status')}
      </div>

      {requestStatuses.map(({ id, name }) => (
        <div className="compare-popup-item" key={`${id}req`}>
          <Checkbox
            onClick={toggleCheckbox(id, setFilterRequestCheckbox)}
            set={filterRequestCheckbox[id]}
          />
          <span>{name}</span>
        </div>
      ))}

      <hr style={{ width: '100%' }} />

      <div className="compare-popup-item">{t('response_status')}</div>
      {responseStatuses.map(({ id, name }) => (
        <div className="compare-popup-item" key={`${id}res`}>
          <Checkbox
            onClick={toggleCheckbox(id, setFilterResponseCheckbox)}
            set={filterResponseCheckbox[id]}
          />
          <span>{name}</span>
        </div>
      ))}
    </div>
  );
  return (
    <div className="my-purchase-request-table">
      <>
        <ControlPanel>
          <SearchBlockDefault
            theme="main"
            query={search}
            placeholder={`${t('purchase_request_title')}/${t(
              'request_number'
            )}`}
            onSubmit={onSearch}
            onChange={onSearchChange}
            onEmpty={onSearch}
            width={365}
          />
          <SortBlock>
            <span>{t('Sort by')}</span>
            <Select
              id="sort-select"
              className="sort-select"
              value={sortValue}
              onChange={onChangeSort}
              options={sortOptions}
              clearable={false}
              searchable={false}
              style={{ width: 174 }}
            />
          </SortBlock>
          <ButtonIcon
            icon="archive"
            tooltip="btn-archive"
            active={isArchive}
            onClick={toggleIsArchive}
          />
          {!isArchive && (
            <div className="my-purchase-request-table__status-filter">
              <Filter
                onClick={() => setIsFilterOpened(!isFilterOpened)}
                isFilterActive={isFilterActive({
                  ...filterResponseCheckbox,
                  ...filterRequestCheckbox
                })}
              />
              <PopupSmall
                content={popupFilterContent}
                isOpened={isFilterOpened}
                top={10}
                setIsOpened={setIsFilterOpened}
              />
            </div>
          )}
          <CompareChosenButton isUploaded={isUploaded} />
        </ControlPanel>
        <RequestTable
          selectedStatus={getSelectedStatus(filterRequestCheckbox)}
          selectedResponseStatus={getSelectedStatus(filterResponseCheckbox)}
          isArchive={isArchive}
        />
      </>
    </div>
  );
};

const ControlPanel = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 17px;
  margin-right: 12px;
  line-height: 14px;
  column-gap: 28px;
  row-gap: 16px;
`;

const SortBlock = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`;

export default withRouter(
  connect(
    (state) => ({
      limit: getters.getLimit(state),
      offset: getters.getOffset(state),
      direction: getters.getDirection(state),
      search: getters.getSearch(state),
      isUploaded: getters.getIsUploaded(state)
    }),
    (dispatch) =>
      bindActionCreators(
        {
          getMyRequests: storage.getMyRequests,
          publishRequest: storage.publishRequest,
          unpublishRequest: storage.unpublishRequest,
          setInitialCompareList: storage.setInitialCompareList,
          setSearch: actions.setSearch
        },
        dispatch
      )
  )(translate()(MyPurchaseRequestsTable))
);
