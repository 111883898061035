import React, { Component } from 'react';
import { Button } from 'react-bootstrap';
import * as Sentry from '@sentry/browser';
import { translate } from 'react-i18next';
import ErrorLogo from './components/MessengerLogo';
import { clearData, confirmClearData } from './storage/lib/LS';

// Sentry.init({
//  dsn: "https://81883cd93ee6481ca2ebf4141bc19447@sentry.goodwix.com/2"
// });
// should have been called before using it here
// ideally before even rendering your react app

class CatchErrors extends Component {
  constructor(props) {
    super(props);
    this.state = { error: null };
  }

  componentDidCatch(error, errorInfo) {
    this.setState({ error });
    Sentry.withScope((scope) => {
      Object.keys(errorInfo).forEach((key) => {
        scope.setExtra(key, errorInfo[key]);
      });
      Sentry.captureException(error);
    });
  }

  handleClearAndRefresh() {
    confirmClearData(clearData);
    document.location.reload();
  }

  render() {
    const { t } = this.props;
    if (this.state.error) {
      // render fallback UI
      return (
        <div
          style={{
            display: 'flex',
            flexFlow: 'column',
            alignItems: 'center',
            paddingTop: 101
          }}>
          <ErrorLogo width={297} height={50} />
          <div style={{ marginTop: 56 }}>{t('something_went_wrong')}</div>
          <div style={{ margin: '10px 0 56px 0' }}>
            <a onClick={this.handleClearAndRefresh}>{t('Refresh the page')}</a>
          </div>
          <div>
            <Button
              bsStyle="primary"
              className="button-report"
              onClick={() => Sentry.showReportDialog()}>
              {t('report_feedback')}
            </Button>
          </div>
          <div style={{ marginTop: 14 }}>
            <img style={{ width: 300 }} alt="robot" src="/img/404.png" />
          </div>
        </div>
      );
    }

    // when there's not an error, render children untouched
    return this.props.children;
  }
}

export default translate(['ui'], { wait: true })(CatchErrors);
