const initState = () => null;

const setType = ({ accessType }) => accessType;

const list = (state = initState(), { type, payload }) => {
  switch (type) {
    case 'ACCESS:SET_TYPE:SUCCESS':
      return setType(payload);
    case 'ACCESS:CLEAN':
      return initState();
    default:
      return state;
  }
};

export default list;
