import React from 'react';
import {
  Modal,
  Col,
  Form,
  FormGroup,
  FormControl,
  ControlLabel,
  Button,
  InputGroup,
  HelpBlock
} from 'react-bootstrap';
import { connect } from 'react-redux';
import { translate } from 'react-i18next';
import { Map } from 'immutable';
// import block from 'bem-cn-lite';

import * as modalActionCreators from '../../../action-creators/modal';
import * as priceActions from '../../../action-creators/prices';

import InputNumber from '../../InputNumber';

// const b = block('modal-container');

const ModalEditPriceCategory = translate(['ui'], { wait: true })(
  class extends React.Component {
    constructor(props) {
      super(props);
      this.isPriceCategoryNameUnique =
        this.isPriceCategoryNameUnique.bind(this);
      this.state = { name: '' };
    }

    UNSAFE_componentWillMount() {
      this.props.setSelectedCategory(this.props.selectedCat);
      this.setState({ name: this.props.selectedCat.get('name') });
    }

    componentWillUnmount() {
      this.props.setSelectedCategory(Map());
    }

    getProperty(field) {
      return this.props.selectedCategory &&
        this.props.selectedCategory.has(field)
        ? this.props.selectedCategory.get(field)
        : '';
    }

    validationState(field) {
      const { selectedCategory } = this.props;
      if (field === 'name') {
        return selectedCategory &&
          selectedCategory.get(field) &&
          this.isPriceCategoryNameUnique(this.getProperty('name'))
          ? ''
          : 'error';
      }
      return selectedCategory && selectedCategory.get(field) ? '' : 'error';
    }

    validationMinOrderState() {
      const { selectedCategory } = this.props;
      return selectedCategory &&
        selectedCategory.get('minOrder') !== undefined &&
        selectedCategory.get('minOrder') >= 0 &&
        selectedCategory.get('minOrder') !== ''
        ? ''
        : 'error';
    }

    getDiscountValidationState() {
      const { selectedCategory } = this.props;
      return (selectedCategory &&
        selectedCategory.get('discount') !== undefined &&
        selectedCategory.get('discount') >= 0 &&
        selectedCategory.get('discount') < 100) ||
        (selectedCategory &&
          (selectedCategory.get('discount') === '' ||
            selectedCategory.get('discount') === null))
        ? ''
        : 'error';
    }

    saveDisabled(selectedCategory) {
      return (
        !(selectedCategory && selectedCategory.get('name')) ||
        !(
          selectedCategory &&
          selectedCategory.get('minOrder') &&
          selectedCategory.get('minOrder') >= 0 &&
          selectedCategory.get('minOrder') !== '' &&
          selectedCategory.get('minOrder') !== null
        ) ||
        !(
          (selectedCategory &&
            selectedCategory.get('discount') &&
            selectedCategory.get('discount') >= 0 &&
            selectedCategory.get('discount') < 100) ||
          (selectedCategory &&
            (selectedCategory.get('discount') === '' ||
              selectedCategory.get('discount') === null))
        )
      );
    }

    asterisk() {
      return <span style={{ color: 'red' }}>*</span>;
    }

    getFieldValidationState(field) {
      return !!this.getProperty(field);
    }

    showHelpInfo(field) {
      const { t } = this.props;
      if (!this.getFieldValidationState(field)) {
        return (
          <HelpBlock id="invalidFieldMessage" style={{ color: 'red' }}>
            {t('Required fields empty')}
          </HelpBlock>
        );
      }
      return null;
    }

    normalizeName(name = '') {
      return name.toLowerCase().trim();
    }

    isPriceCategoryNameUnique(name) {
      if (name === this.state.name) {
        return true;
      }
      const { priceCategories } = this.props;
      const normalizedName = this.normalizeName(name);
      const found = priceCategories
        .filter((category) => category.get('name') !== null)
        .find(
          (category) =>
            normalizedName === this.normalizeName(category.get('name'))
        );
      const isUnique = !found;
      return isUnique;
    }

    isPriceCategoryMinOrderUnique(minOrder) {
      const { priceCategories, selectedCategory } = this.props;
      const find = priceCategories.find(
        (category) =>
          +minOrder === category.get('minOrder') &&
          category.get('id') !== selectedCategory.get('id')
      );
      const isUnique = !find;
      return isUnique;
    }

    handlerClick = () => {
      const { price, hideModalDialog, savePriceCategory, getPriceCategories } =
        this.props;

      savePriceCategory().then(() => {
        getPriceCategories(price.get('id'));
        hideModalDialog();
      });
    };

    render() {
      const {
        t,
        price,
        captionKey,
        hideModalDialog,
        updatePriceCategory,
        selectedCategory
      } = this.props;

      const inputProps = (field) => ({
        value: this.getProperty(field),
        onChange: (e) => updatePriceCategory(field, e.target.value)
      });

      return (
        <Modal
          id="productCharacteristicsModalWindow"
          onHide={() => {
            hideModalDialog();
          }}
          show>
          <Modal.Header>
            <Modal.Title>
              {t(captionKey)}
              <span
                className="modal-header-cross pull-right"
                onClick={() => hideModalDialog()}
              />
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form horizontal>
              <FormGroup
                controlId="name"
                validationState={this.validationState('name')}>
                <Col
                  componentClass={ControlLabel}
                  xs={5}
                  style={{ textAlign: 'left', marginTop: 15 }}>
                  {`${t('Name')} `}
                  {this.asterisk()}
                </Col>
                <Col xs={7}>
                  <span
                    className="input-col-length pull-right"
                    style={{ marginBottom: 5 }}>
                    {this.getProperty('name').length}
                    /20
                  </span>
                  <FormControl
                    type="text"
                    maxLength={20}
                    {...inputProps('name')}
                  />
                  {this.showHelpInfo('name')}
                  {this.isPriceCategoryNameUnique(
                    this.getProperty('name')
                  ) ? null : (
                    <HelpBlock
                      id="invalidFieldMessageUniqueName"
                      style={{ color: 'red' }}>
                      {t('Name already exist')}
                    </HelpBlock>
                  )}
                </Col>
              </FormGroup>
              <FormGroup
                controlId="discount"
                validationState={this.getDiscountValidationState()}>
                <Col
                  componentClass={ControlLabel}
                  xs={5}
                  style={{ textAlign: 'left' }}>
                  {t('discount')}
                </Col>
                <Col xs={7}>
                  <InputGroup>
                    <InputNumber
                      defaultValue={this.getProperty('discount')}
                      value={this.getProperty('discount')}
                      min={0}
                      max={100}
                      onChange={(e) => updatePriceCategory('discount', e)}
                      width="100%"
                      className="catalog-basic-information"
                    />
                    <InputGroup.Addon>%</InputGroup.Addon>
                  </InputGroup>
                </Col>
              </FormGroup>
              <FormGroup
                controlId="minOrder"
                validationState={this.validationMinOrderState()}>
                <Col
                  componentClass={ControlLabel}
                  xs={5}
                  style={{ textAlign: 'left' }}>
                  {`${t('Minimal order')} `}
                  {this.asterisk()}
                </Col>
                <Col xs={7}>
                  <InputGroup>
                    <div
                      style={
                        this.validationMinOrderState() === 'error'
                          ? {
                              border: '1px solid',
                              borderRadius: '4px 0px 0px 4px',
                              borderShadow:
                                'inset 0 1px 1px rgba(0, 0, 0, .075)',
                              borderColor: '#fd4f53'
                            }
                          : {
                              border: '1px solid',
                              borderRadius: '4px 0px 0px 4px',
                              borderShadow:
                                'inset 0 1px 1px rgba(0, 0, 0, .075)',
                              borderColor: '#ccc'
                            }
                      }>
                      <InputNumber
                        defaultValue={this.getProperty('minOrder')}
                        value={this.getProperty('minOrder')}
                        min={0}
                        onChange={(e) => updatePriceCategory('minOrder', e)}
                        width="100%"
                        className="catalog-basic-information"
                        style={{ border: 'none' }}
                        onKeyDown={() => {
                          if (this.state.namePristine) {
                            this.setState({ minOrderPristine: false });
                          }
                        }}
                      />
                    </div>
                    <InputGroup.Addon>{price.get('currency')}</InputGroup.Addon>
                  </InputGroup>
                  {this.showHelpInfo('minOrder')}
                  {!this.isPriceCategoryMinOrderUnique(
                    this.getProperty('minOrder')
                  ) && (
                    <HelpBlock
                      id="invalidFieldMessageMinOrder"
                      style={{ color: 'red' }}>
                      {t('MinOrder already exist')}
                    </HelpBlock>
                  )}
                </Col>
              </FormGroup>
              <FormGroup
                controlId="requiredFieldsLabel"
                style={{ marginBottom: 0, marginTop: 10 }}>
                <Col
                  componentClass={ControlLabel}
                  xs={5}
                  style={{
                    fontSize: '12px',
                    color: 'lightgrey',
                    textAlign: 'left'
                  }}>
                  {this.asterisk()}
                  {` ${t('Required fields')}`}
                </Col>
              </FormGroup>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button
              id="cancel"
              className="pull-left"
              onClick={() => hideModalDialog()}>
              {t('Cancel')}
            </Button>
            <Button
              id="saveCharacteristicButton"
              bsStyle="primary"
              disabled={
                this.saveDisabled(selectedCategory) ||
                !this.isPriceCategoryNameUnique(this.getProperty('name')) ||
                !this.isPriceCategoryMinOrderUnique(
                  this.getProperty('minOrder')
                )
              }
              onClick={this.handlerClick}>
              {t('Save')}
            </Button>
          </Modal.Footer>
        </Modal>
      );
    }
  }
);

const mapStateToProps = (state) => ({
  selectedCategory: state.getIn(['prices', 'selectedCategory']),
  priceCategories: state.getIn(['prices', 'priceCategories']),
  price: state.getIn(['prices', 'price'])
});

export default connect(mapStateToProps, {
  ...modalActionCreators,
  ...priceActions
})(ModalEditPriceCategory);
