import React, { useState, useRef } from 'react';
import block from 'bem-cn-lite';
import { translate } from 'react-i18next';
import { startOfDay, endOfDay } from 'date-fns';
import PickDate from '../../../Datepicker/datepicker';

const bc = block('cwshf-btn-calendar');

const prepareDateForSend = (dateRange) => {
  let [from, to] = dateRange;
  if (from) {
    from = startOfDay(from);
  }
  if (to) {
    to = endOfDay(to);
  }

  return { from, to };
};

const BtnCalendar = ({ onSelectedDate }) => {
  const [show, setShow] = useState(false);
  const datepickerRef = useRef(null);

  const togglePicker = () => setShow((v) => !v);

  const onSetDateRange = (dateRange) => {
    setShow(false);
    const preparedDateRange = prepareDateForSend(dateRange);
    onSelectedDate(preparedDateRange);
  };

  return (
    <div className={bc()} ref={datepickerRef}>
      <button type="button" className={bc('btn')} onClick={togglePicker}>
        <img
          src="/img/chat-widget/calendar.svg"
          role="presentation"
          alt="calendar"
        />
      </button>
      <div className={bc('datepicker')}>
        <PickDate
          isShow={show}
          setShow={setShow}
          setDateRange={onSetDateRange}
          datepickerRef={datepickerRef}
        />
      </div>
    </div>
  );
};

const bd = block('cwshf-btn-sort-direction');

// direction = up | down
const BtnDir = ({ onClick, direction }) => (
  <button type="button" className={bd()} onClick={() => onClick(direction)}>
    <img
      src={`/img/chat-widget/sorting_${direction}.svg`}
      role="presentation"
      alt="sort-direction"
    />
  </button>
);

const b = block('cwshf-date');

const CWSHFDate = ({ t, onSelectedDate, onClickChangeSortDir, sortDir }) => (
  <div className={b()}>
    <div className={b('place-calendar')}>
      <div className={b('title')}>
        {t('chat-widget.advanced-search.date.title')}
      </div>
      <div className={b('place-button')}>
        <BtnCalendar onSelectedDate={onSelectedDate} />
      </div>
    </div>
    <div className={b('place-sort')}>
      <BtnDir onClick={onClickChangeSortDir} direction={sortDir} />
    </div>
  </div>
);

export default translate()(CWSHFDate);
