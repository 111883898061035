import { Map, List, fromJS } from 'immutable';

const initState = () =>
  Map({
    priceId: '',
    orderId: '',
    status: '',
    errors: List(),
    warnings: List()
  });

const setImportStatus = (data, status) => data.set('status', status);

export const setImportStatusError = (data, { priceId, errors, warnings }) => {
  let newData = setImportStatus(data, 'error');
  if (errors) {
    newData = newData.set('errors', fromJS(errors));
  }
  if (warnings) {
    newData = newData.set('warnings', fromJS(warnings));
  }
  return newData.set('priceId', priceId);
};

export const setImportStatusLoading = (data, { priceId }) =>
  setImportStatus(data, 'loading').set('priceId', priceId);

export const setImportStatusSuccess = (data, { priceId, warnings, orderId }) =>
  setImportStatus(data, 'success')
    .set('warnings', fromJS(warnings))
    .set('priceId', priceId)
    .set('orderId', orderId);

export const clearImport = () => initState();

const priceImport = (state = initState(), { type, payload }) => {
  switch (type) {
    case 'ORDER:IMPORT_EXCEL:REQUEST':
      return setImportStatusLoading(state, payload);
    case 'ORDER:IMPORT_EXCEL:FAILURE':
      return setImportStatusError(state, payload);
    case 'ORDER:IMPORT_EXCEL:SUCCESS':
      return setImportStatusSuccess(state, payload);
    case 'ORDER:CLEAR_IMPORT':
      return clearImport(state);
    default:
      return state;
  }
};

export default priceImport;
