import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { translate } from 'react-i18next';
import { Glyphicon, PanelGroup } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import styled from 'styled-components';

import Table from '../../components/TableNew';
import Tooltip from '../../components/ChatWidget/Tooltip';
import customHistory from '../../customHistory';

import * as modalActions from '../../action-creators/modal';
import { convertUrlToFile } from '../../components/ChatWidget/modules/files/utils';

class TableFileStorage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isUploaded: false
    };
  }

  async UNSAFE_componentWillMount() {
    const {
      getFiles,
      match: {
        params: { fileId, section }
      }
    } = this.props;
    await getFiles(fileId, section);
    this.setState({ isUploaded: true });
  }

  getColumns() {
    const { t, removeLink, getFiles, section, showModal } = this.props;
    let list = [];

    if (section === 'catalogs') {
      list = [
        Table.ColText({ accessor: 'productName', title: t('Product') }),
        Table.ColText({ accessor: 'categoryName', title: t('Category') }),
        Table.ColText({ accessor: 'catalogName', title: t('Catalog') }),
        Table.ColDate({
          accessor: 'createdAt',
          title: t('Added'),
          width: '18%'
        }),
        Table.ColText({
          accessor: (item) => (
            <ActionsWrapper>
              <Tooltip text="download-file" isFlow={false}>
                <div className="glypth-save-pright">
                  <a
                    href={item['fileInfo.originalUrl'] || item['fileInfo.url']}>
                    <Glyphicon glyph="save" />
                  </a>
                </div>
              </Tooltip>
              <Tooltip text="delete-file" isFlow={false}>
                <div
                  className="glypth-remove-pleft pointer"
                  onClick={async () => {
                    showModal('SIMPLE_SUBMIT', {
                      captionKey: 'confirm_action',
                      text: 'Delete?',
                      textBtnConfirm: 'OK',
                      submitAction: async () => {
                        await removeLink([item.id]);
                        this.setState({ isUploaded: false });
                        await getFiles(item.fileId, section);
                        this.setState({ isUploaded: true });
                      }
                    });
                  }}>
                  <Glyphicon glyph="remove" />
                </div>
              </Tooltip>
            </ActionsWrapper>
          ),
          showTooltip: false
        })
      ];
    } else if (
      section === 'suppliers-orders' ||
      section === 'customers-orders'
    ) {
      list = [
        Table.ColText({
          accessor: 'companyName',
          title: t('Supplier/Customer')
        }),
        Table.ColText({
          accessor: (a) => a.orderNumber,
          index: 'orderInfo.number',
          title: t('Order number')
        }),
        Table.ColDate({
          accessor: 'createdAt',
          title: t('Added'),
          width: '18%'
        }),
        Table.ColText({
          accessor: (item) => (
            <ActionsWrapper>
              <Tooltip text="download-file" isFlow={false}>
                <div className="glypth-save-pright">
                  <a href={item['fileInfo.url']}>
                    <Glyphicon glyph="save" />
                  </a>
                </div>
              </Tooltip>
              <Tooltip text="delete-file" isFlow={false}>
                <div
                  className="glypth-remove-pleft pointer"
                  onClick={async () => {
                    showModal('SIMPLE_SUBMIT', {
                      captionKey: 'confirm_action',
                      text: 'Delete?',
                      textBtnConfirm: 'OK',
                      submitAction: async () => {
                        await removeLink([item.id]);
                        this.setState({ isUploaded: false });
                        await getFiles([item.fileId], section);
                        this.setState({ isUploaded: true });
                      }
                    });
                  }}>
                  <Glyphicon glyph="remove" />
                </div>
              </Tooltip>
            </ActionsWrapper>
          ),
          showTooltip: false
        })
      ];
    } else if (section === 'chat') {
      list = [
        Table.ColText({
          accessor: 'chatName',
          index: 'chatName',
          title: t('ChatName')
        }),
        Table.ColText({
          accessor: 'senderUserName',
          index: 'senderUserName',
          title: t('Transmitter')
        }),
        Table.ColText({
          accessor: 'senderCompanyName',
          index: 'senderCompanyName',
          title: t('Company')
        }),
        Table.ColDate({
          accessor: 'createdAt',
          title: t('Added'),
          width: '18%'
        }),
        Table.ColText({
          accessor: (item) => (
            <ActionsWrapper>
              <Tooltip text="download-file" isFlow={false}>
                <div className="glypth-save-pright">
                  <a
                    href={`${convertUrlToFile(
                      item['fileInfo.originalUrl'] || item['fileInfo.url']
                    )}?name=${item['fileInfo.name']}`}>
                    <Glyphicon glyph="save" />
                  </a>
                </div>
              </Tooltip>
              <Tooltip text="delete-file" isFlow={false}>
                <div
                  className="glypth-remove-pleft pointer"
                  onClick={async () => {
                    await removeLink([item.id]);
                    this.setState({ isUploaded: false });
                    await getFiles([item.fileId], section);
                    this.setState({ isUploaded: true });
                  }}>
                  <Glyphicon glyph="remove" />
                </div>
              </Tooltip>
            </ActionsWrapper>
          ),
          showTooltip: false
        })
      ];
    }

    return list;
  }

  toggleOrderDirection() {
    const orderDirection =
      this.state.orderDirection === 'DESC' ? 'ASC' : 'DESC';
    this.setState({ orderDirection });
    return orderDirection;
  }

  displayData() {
    const { files, selected } = this.props;

    return (
      files &&
      files.map((item) => {
        const id = item.get('id');
        const hasId = selected.has(id);

        return hasId
          ? item.set('isSelected', true)
          : item.set('isSelected', false);
      })
    );
  }

  render() {
    const { t, getFiles, section, selectRow, fileId } = this.props;
    const data = this.displayData();
    if (data && data.size === 0 && this.state.isUploaded) {
      customHistory.push(`/file-storage/${section}`);
      return null;
    }
    return (
      <div>
        <Link
          style={{ marginBottom: 15, display: 'inline-block' }}
          to={`/file-storage/${section}`}>
          {t('Return to storage')}
        </Link>
        <h4 style={{ marginBottom: 20 }}>
          {data && data.first() && data.first().get('name')}
        </h4>
        <PanelGroup>
          {data && data.size > 0 && (
            <div>
              <Table
                columns={this.getColumns()}
                data={data}
                onSort={(orderBy, direction) => {
                  this.setState({ isUploaded: false });
                  getFiles(fileId, section, orderBy, direction);
                  this.setState({ isUploaded: true });
                }}
                onSelect={selectRow}
              />
            </div>
          )}
        </PanelGroup>
      </div>
    );
  }
}

const ActionsWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 10px;
`;

TableFileStorage.contextTypes = {
  badgeSocket: PropTypes.object
};

export default connect(
  (state) => ({
    user: state.getIn(['user', 'user']),
    company: state.get('company'),
    supportUser: state.getIn(['chat', 'supportUser']),
    selected: state.getIn(['storage', 'selected'])
  }),
  (dispatch) =>
    bindActionCreators(
      {
        showModal: modalActions.showModal,
        hideModal: modalActions.hideModalDialog
      },
      dispatch
    )
)(translate(['ui'], { wait: true })(TableFileStorage));
