import React, { Fragment } from 'react';
import { translate } from 'react-i18next';
import CWSAvatar from '../../Avatar';
import './style.css';

const renderAvatar = (props) => {
  const { type } = props;

  if (type === 'Dialog') {
    const { avatar } = props;
    return (
      <CWSAvatar size={40} info={{ src: avatar, type: 'user', alt: '' }} />
    );
  }

  if (type === 'Channel') {
    const { name, color } = props;
    return (
      <CWSAvatar size={40} info={{ type: 'color-char', text: name, color }} />
    );
  }

  if (type === 'Topic') {
    const { name, color } = props;
    return (
      <CWSAvatar size={40} info={{ type: 'color-char', text: name, color }} />
    );
  }

  if (type === 'PurchaseRequestsChannel') {
    return (
      <CWSAvatar
        size={34}
        info={{ type: 'color-avatar', subType: 'request-channel' }}
        isCursorPointer
      />
    );
  }

  return <span />;
};

const renderTitles = (props) => {
  const { type } = props;

  if (type === 'Dialog') {
    const { name = '', companyName = '' } = props;
    return (
      <>
        <div className="cw-modal-component__added-dialogs-list_row-titles_user-name">
          {name}
        </div>
        <div className="cw-modal-component__added-dialogs-list_row-titles_company-name">
          {companyName}
        </div>
      </>
    );
  }

  if (type === 'Channel') {
    const { t, name, membersCount } = props;
    return (
      <>
        <div className="cw-modal-component__added-dialogs-list_row-titles_channel-name">
          <img src="/img/chat-widget/channel.svg" alt="channel" />
          <span>{name}</span>
        </div>
        <div className="cw-modal-component__added-dialogs-list_row-titles_channel-members-count">
          {`${t('channel.amount_members')}: ${membersCount}`}
        </div>
      </>
    );
  }

  if (type === 'Topic') {
    const { t, name, membersCount } = props;
    return (
      <>
        <div className="cw-modal-component__added-dialogs-list_row-titles_channel-name">
          <img src="/img/chat-widget/topic.svg" alt="channel" />
          <span>{name}</span>
        </div>
        <div className="cw-modal-component__added-dialogs-list_row-titles_channel-members-count">
          {`${t('channel.amount_members')}: ${membersCount}`}
        </div>
      </>
    );
  }

  if (type === 'PurchaseRequestsChannel') {
    const { t, name } = props;
    return (
      <div className="cw-modal-component__added-dialogs-list_row-titles_channel-name">
        <img src="/img/chat-widget/channel.svg" alt="channel" />
        <span>{t(name)}</span>
      </div>
    );
  }

  return <span />;
};

const Row = (props) => (
  <div className="cw-modal-component__added-dialogs-list_row">
    <div className="cw-modal-component__added-dialogs-list_row-avatar">
      {renderAvatar(props)}
    </div>
    <div className="cw-modal-component__added-dialogs-list_row-titles">
      {renderTitles(props)}
    </div>
    <div
      className="cw-modal-component__added-dialogs-list_row-cross"
      onClick={props.onDeleteRow}>
      <img src="/img/cross-in-row.svg" alt="cross" />
    </div>
  </div>
);

export default translate()(Row);
