import React from 'react';
import { Link } from 'react-router-dom';
import { Typography, ButtonIcon } from '@link/react-components';
import { useTranslate } from '@hooks';
import styles from './RouteItem.module.css';
import { getFullName } from '../../../../../utils/utils';

function generateUserInfoText(employeeInfo) {
  const personInfoTitle = getFullName(employeeInfo);
  if (!employeeInfo?.department) return personInfoTitle;
  const personDepartment = `Отдел: ${employeeInfo.department}`;
  return `${personInfoTitle}. ${personDepartment}`;
}

export const RouteItem = ({ route, onDeleteRoute }) => {
  const t = useTranslate();

  return (
    <div className={styles.wrapper}>
      <div className={styles.content}>
        <Typography variant="body1Sb" className={styles.title}>
          <Link to={`/constructor/edit/${route.id}`} className={styles.title}>
            {route.name}
          </Link>
        </Typography>
        <Typography variant="body1Reg" className={styles.description}>
          {t('constructor.authorPath')}
          :&nbsp;
          {generateUserInfoText(route.employeeInfo)}
        </Typography>
      </div>
      <ButtonIcon iconName="trash" onClick={() => onDeleteRoute(route)} />
    </div>
  );
};
