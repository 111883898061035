import React, { Component } from 'react';
import { translate } from 'react-i18next';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import DatePicker from 'react-bootstrap-date-picker';
import {
  Modal,
  Col,
  Form,
  FormGroup,
  ControlLabel,
  Button
} from 'react-bootstrap';
import { ModalContainer } from './elements';

import { validDate } from '../../utils/date';

import * as modalActions from '../../action-creators/modal';
import * as stockActions from '../../action-creators/stocks';
import * as serviceActions from '../../action-creators/services';

class ModalAddContact extends Component {
  constructor(props) {
    super(props);
    this.state = {
      date: null,
      dateError: false
    };
  }

  render() {
    const {
      t,
      gaSend,
      user,
      hideModalDialog,
      updateReceiptDateOfFewProductInStock,
      stockId,
      products
    } = this.props;

    const currentDate = new Date();

    return (
      <ModalContainer mods={{ theme: 'default' }}>
        <Modal id="addContact" onHide={() => hideModalDialog()} show>
          <Modal.Header>
            <Modal.Title>
              {t('change_product_receipt_date')}
              <span
                className="modal-header-cross pull-right"
                onClick={() => hideModalDialog()}
              />
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form
              horizontal
              style={{
                whiteSpace: 'nowrap'
              }}>
              <FormGroup
                controlId="date"
                validationState={this.state.dateError ? 'error' : ''}>
                <Col
                  componentClass={ControlLabel}
                  className="form-modal-label-col"
                  sm={8}>
                  {t('receipt_date_of_selected_products')}
                </Col>
                <Col sm={4} className="form-modal-input-col">
                  <DatePicker
                    value={this.state.date}
                    onChange={(date) => {
                      const setDate = new Date(date);
                      if (!validDate(currentDate, setDate)) {
                        // eslint-disable-next-line no-param-reassign
                        date = currentDate.toISOString();
                      }
                      this.setState({ dateError: false });
                      this.setState({ date });
                    }}
                    onBlur={(e) => {
                      if (
                        !e.target.value &&
                        e.target.parentElement.children[0].value !== ''
                      ) {
                        this.setState({ dateError: true });
                      } else {
                        this.setState({ dateError: false });
                      }
                    }}
                    calendarContainer={document.body}
                    calendarPlacement="bottom"
                    minDate={new Date().toISOString()}
                  />
                </Col>
              </FormGroup>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <div>
              <Button
                id="cancel"
                className="pull-left"
                onClick={() => hideModalDialog()}>
                {t('Cancel')}
              </Button>
              <Button
                id="saveButton"
                bsStyle="primary"
                onClick={async () => {
                  await gaSend({
                    category: 'Supplier',
                    action: 'stock_change_data_product',
                    label: user.get('email')
                  });
                  await updateReceiptDateOfFewProductInStock(
                    stockId,
                    products,
                    this.state.date
                  );
                  hideModalDialog();
                }}
                disabled={!this.state.date}>
                {t('Change')}
              </Button>
            </div>
          </Modal.Footer>
        </Modal>
      </ModalContainer>
    );
  }
}

export default connect(
  (state) => ({
    user: state.getIn(['user', 'user']),
    company: state.get('company'),
    stockProducts: state.getIn(['stocks', 'products'])
  }),
  (dispatch) =>
    bindActionCreators(
      {
        hideModalDialog: modalActions.hideModalDialog,
        updateReceiptDateOfFewProductInStock:
          stockActions.updateReceiptDateOfFewProductInStock,
        fetchProducts: stockActions.loadProductsOfStock,
        selectProduct: stockActions.selectProduct,
        getCategories: stockActions.loadCategoriesOfStock,
        gaSend: serviceActions.gaSend
      },
      dispatch
    )
)(translate(['ui'], { wait: true })(ModalAddContact));
