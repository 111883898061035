import { List } from 'immutable';

const loadCurrencyList = (state, currencyList) =>
  state.clear().merge(currencyList);

const currencyListReducer = (state = List(), action) => {
  switch (action.type) {
    case 'CURRENCY_LIST_LOAD':
      return loadCurrencyList(state, action.currencyList);
    default:
      return state;
  }
};

export default currencyListReducer;
