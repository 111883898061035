export const MY_ORDERS = 'myOrders';
export const CHANNEL = 'CHANNEL';

export const NUMBER_OF_DIGITS = {
  UNITS: 0,
  DECIMAL: 1,
  HUNDREDS: 2
};

export const TYPES_OF_CATEGORY = {
  SUM_OF_ORDER: 1,
  AMOUNT_OF_PRODUCT_ORDER: 2,
  SPECIAL: 3
};

export const DEFAULT_MAX_MSG_LENGTH = +process.env.MSG_MAX_LENGTH || 5000;
export const DEFAULT_TIME_MSG_EDIT = 15;
export const ALLOW_TYPE_MSG_FOR_EDIT = ['MsgUser', 'MsgReply'];
export const DEFAULT_MAX_CHAT_INPUT_LENGTH = 100000;

export const CATEGORIES_PREFIX = {
  MAIN: 'main-',
  ADDITIONAL: 'additional-'
};
