import React from 'react';
import { useDefineSpeaker } from '../../hooks/useDefineSpeaker';

const Container = ({ id, stream, children }) => {
  const containerRef = React.useRef(null);

  useDefineSpeaker(containerRef, stream);

  return (
    <div id={id} ref={containerRef} className="video-chat__video-container">
      {children}
    </div>
  );
};

export default Container;
