import React from 'react';
import { isEmpty } from 'ramda';
import block from 'bem-cn-lite';
import styled from 'styled-components';

import { ControlLabel, Button } from 'react-bootstrap';

import { Select } from '../../../components/FormControls';
import CustomOverlay from '../../../components/Overlay';
import ErrorHint from '../../../components/Hints/ErrorHint';

import RangeNumbers from '../../../components/Inputs/RangeNumbers';
import CategoriesSelect from '../../../components/CategoriesSelect';
import { useTranslate } from '../../../TranslateProvider';
import './style.css';

const b = block('prfs');

const PurchaseRequestsFilterSidebarView = (props) => {
  const {
    titleSelectCountry,
    countrySelect,
    regionSelect,
    citySelect,
    titleSelectRegion,
    titleSelectCity,
    onFilter,
    isFilterChange,
    isUseNoRegionsHint,
    sum,
    partner,
    categories
  } = props;

  const t = useTranslate();

  return (
    <div className={b()}>
      <div className={b('header')}>
        <div className={b('title')}>{t('Purchase_requests_filter')}</div>
      </div>
      <div className={b('body')}>
        <div>
          <ControlLabel className="control-label-row">
            {titleSelectCountry}
          </ControlLabel>
          <Select
            id="country-select"
            arrowRenderer={null}
            {...countrySelect}
            placeholder={t('purchaseRequest.filter.placeholders.default')}
            noResultsText={t('noResultsText')}
            clearable={false}
            removeSelected
          />
        </div>
        <MultiSelectWrapper>
          <ControlLabel className="control-label-row">
            {titleSelectRegion}
          </ControlLabel>
          <CustomOverlay
            isUseOverlay={isUseNoRegionsHint}
            popoverId="no_regions_for_selected_country_hint"
            hint={<ErrorHint hint="no_regions_for_selected_country" />}>
            <Select
              id="region-select"
              arrowRenderer={null}
              {...regionSelect}
              multi
              placeholder={t('purchaseRequest.filter.placeholders.default')}
              noResultsText={t('noResultsText')}
              clearable
              removeSelected
            />
          </CustomOverlay>
        </MultiSelectWrapper>
        {!isEmpty(regionSelect.value) && (
          <MultiSelectWrapper>
            <ControlLabel className="control-label-row">
              {titleSelectCity}
            </ControlLabel>
            <Select
              id="city-select"
              arrowRenderer={null}
              {...citySelect}
              placeholder={t('purchaseRequest.filter.placeholders.default')}
              noResultsText={t('noResultsText')}
              multi
              clearable
              removeSelected
            />
          </MultiSelectWrapper>
        )}
        <div>
          <ControlLabel className="control-label-row">
            {categories.title}
          </ControlLabel>
          <CategoriesSelect
            selectedList={categories.selectedIds}
            list={categories.categoriesList}
            onChoose={categories.onChoose}
          />
        </div>
        <div className={b('sum')}>
          <ControlLabel className="control-label-row">{sum.title}</ControlLabel>
          <div className={b('sum-list')}>
            {sum.list.map((sumInput, key) => (
              <RangeNumbers
                value={sumInput}
                onChange={(value) => sum.onChange(key, value)}
              />
            ))}
          </div>
          <Button
            bsStyle="link"
            className={b('btn-sum-add')}
            onClick={sum.onAddInput}>
            Добавить
          </Button>
        </div>
        <MultiSelectWrapper>
          <ControlLabel className="control-label-row">
            {partner.title}
          </ControlLabel>
          <Select
            id="suppliers-select"
            {...partner.select}
            arrowRenderer={null}
            placeholder={t('not selected')}
            noResultsText={t('noResultsText')}
            multi
            clearable={false}
            removeSelected
          />
        </MultiSelectWrapper>
      </div>
      <div className={b('footer')}>
        <Button
          id="apply-filter"
          className={b('btn')}
          onClick={onFilter}
          bsStyle="primary">
          {t('apply_filter')}
        </Button>
        <Button
          id="clear-filter"
          className={b('btn')}
          onClick={(e) => onFilter(e, true)}
          disabled={isFilterChange()}>
          {t('clear_filter')}
        </Button>
      </div>
    </div>
  );
};

const MultiSelectWrapper = styled.div`
  .Select--multi .Select-input {
    display: inline-block !important;
  }

  .Select--multi .Select-multi-value-wrapper {
    padding-bottom: 0;
  }

  .Select--multi .Select-clear-zone {
    padding-right: 8px;
  }
`;

export default PurchaseRequestsFilterSidebarView;
