import React from 'react';
import { useSelector } from 'react-redux';
import Loader from '../../../Loader';
import * as commonGetters from '../../getter';
import { formatTypingText } from '../../utils';
import { useTranslate } from '../../../../TranslateProvider';
import './style.css';

const CWTypingInfo = () => {
  const t = useTranslate();
  const list = useSelector(commonGetters.getTypingFromOpenedChat);

  if (!list || list.length === 0) return null;

  return (
    <div className="cw-typing">
      <Loader theme="intext" />
      <div className="cw-typing_text">{formatTypingText(t, list)}</div>
    </div>
  );
};

export default React.memo(CWTypingInfo);
