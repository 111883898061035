import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useTranslate } from '@hooks';
import MessengerOther from '../components/Layouts/MessengerOther';
import PurchaseRequestsTabs from '../config/pages/requests';
import * as storeGetters from '../storeGetters';
import ContainersDefault from '../components/Containers/ContainersDefault';
import PageSubscribeToPurchaseRequests from '../components/ChatWidget/PurchaseRequestsFilter/page';

const MSubscribePurchaseRequests = () => {
  const t = useTranslate();

  const haveReadPermission = useSelector(
    storeGetters.haveReadPurchaseRequestsPermission
  );

  const breadcrumbs = useMemo(
    () => [{ name: t('Purchase requests'), link: '/' }],
    [t]
  );

  return (
    <MessengerOther
      title={t('Purchase requests')}
      breadcrumbs={breadcrumbs}
      content={
        <ContainersDefault>
          {haveReadPermission && <PurchaseRequestsTabs activeKey="all" />}
          <PageSubscribeToPurchaseRequests />
        </ContainersDefault>
      }
    />
  );
};

export default MSubscribePurchaseRequests;
