import React from 'react';
import Text from 'react-components/src/components/Typography';
import IconComponent, { Icons } from 'react-components/src/icons';
import { useTranslate } from '../../../TranslateProvider';
import {
  isSupport as checkIsSupport,
  isAdmin as checkIsAdmin
} from '../../../lib/goodwix';
import * as chatTypeDefiner from '../helpers/chatType';
import './style.css';

const NoticeOff = () => (
  <img
    className="cw-chat-title__notice-off"
    src="/img/chat-widget/notice-off.svg"
    alt="notice off"
  />
);

const Icon = ({ chatType }) => {
  if (chatTypeDefiner.isDialog(chatType)) {
    return null;
  }

  let icon;
  if (chatTypeDefiner.isChannel(chatType)) {
    icon = Icons.Group;
  }

  if (chatTypeDefiner.isTopic(chatType)) {
    icon = Icons.Topic;
  }

  if (chatTypeDefiner.isResponseChat(chatType)) {
    icon = Icons.Purchase;
  }

  if (!icon) return null;

  return (
    <div className="cw-chat-title__title-icon">
      <IconComponent icon={icon} size={16} />
    </div>
  );
};

const getName = (t, chatType, chatName, interlocutor) => {
  if (chatTypeDefiner.isDialog(chatType)) return interlocutor.userName;

  if (
    chatTypeDefiner.isChannel(chatType) ||
    chatTypeDefiner.isTopic(chatType) ||
    chatTypeDefiner.isResponseChat(chatType) ||
    chatTypeDefiner.isNewsFeedChannel(chatType)
  ) {
    return chatName;
  }

  if (chatTypeDefiner.isPurchaseRequestsChannel(chatType)) return t(chatName);
  return '';
};

const CWChatTitle = ({
  chatType,
  isShowNotify = false,
  interlocutor,
  notificationOff,
  chatName
}) => {
  const t = useTranslate();

  const isAdminForAvatar = () => {
    if (chatTypeDefiner.isDialog(chatType)) {
      return (
        checkIsAdmin(+interlocutor.employeeId) ||
        checkIsSupport(+interlocutor.employeeId)
      );
    }
    return false;
  };

  const color = isAdminForAvatar() ? 'blue' : '';

  return (
    <div className="cw-chat-title">
      {isShowNotify && notificationOff && <NoticeOff />}
      <Icon chatType={chatType} />
      <Text
        className={`cw-chat-title__name${
          color ? ` cw-chat-title__name_color_${color}` : ''
        }`}
        variant="body1Med">
        {getName(t, chatType, chatName, interlocutor)}
      </Text>
    </div>
  );
};

export default CWChatTitle;
