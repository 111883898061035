import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { taskQRY } from 'core/messages/task';
import { isNil } from 'ramda';
import { TaskManagerLayout } from '../../components/Layouts/main';
import CreateTaskButton from '../../components/Buttons/CreateTaskButton';
import TaskListContent from './content';
import Sidebar from './sidebar';
import { emitToServer } from '../../../storage/message';
import { useTranslate } from '../../../TranslateProvider';
import * as storeGetters from '../../../storeGetters';

const renderPanelButtons = ({ t, onCreateNewTask }) => [
  {
    element: <CreateTaskButton t={t} onClick={onCreateNewTask} />
  }
];

const TasksList = () => {
  const t = useTranslate();
  const currentCompanyId = useSelector(storeGetters.getCurrentCompanyId);

  useEffect(() => {
    subscribeToUpdates();

    return () => {
      unsubscribeFromUpdates();
    };

    function subscribeToUpdates() {
      if (isNil(currentCompanyId)) return;

      emitToServer(taskQRY.sync);
    }

    function unsubscribeFromUpdates() {
      emitToServer(taskQRY.syncOff);
    }
  }, [currentCompanyId]);

  return (
    <TaskManagerLayout
      theme="gray"
      title={t('taskManager.title')}
      breadcrumbs={[{ name: t('taskManager.title') }]}
      panelBtns={renderPanelButtons({ t })}
      content={<TaskListContent t={t} />}
      sidebar={<Sidebar />}
    />
  );
};

export default TasksList;
