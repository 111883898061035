import { Icon } from '@/components/Icon';
import cn from 'classnames';
import styles from './ButtonIcon.module.scss';
import { ButtonIconProps } from './ButtonIcon.types';

export function ButtonIcon({
  iconName,
  variant = 'large',
  onClick,
  className = '',
  disabled = false,
  size = 18
}: ButtonIconProps) {
  return (
    <button
      type="button"
      onClick={onClick}
      disabled={disabled}
      className={cn(
        styles.button,
        styles[variant],
        className,
        disabled && styles.disabled
      )}
      data-testid="button-icon">
      <Icon iconName={iconName} height={size} width={size} />
    </button>
  );
}
