/* eslint-disable max-len */
import React from 'react';

const CompareIcon = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3 11H1V15H3V11ZM0 10V16H4V10H0Z"
      fill="#767B92"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9 1H7V15H9V1ZM6 0V16H10V0H6Z"
      fill="#767B92"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15 6H13V15H15V6ZM12 5V16H16V5H12Z"
      fill="#767B92"
    />
  </svg>
);

export default CompareIcon;
