import { useState, useCallback } from 'react';

export const useInput = (defaultValue) => {
  const [value, setValue] = useState(defaultValue);

  const setter = useCallback((event) => {
    setValue(event.target.value);
  }, []);

  return [value, setter];
};
