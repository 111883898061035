import { Route, Routes } from 'react-router-dom';
import React from 'react';
import { ConstructorView } from '../pages/constructor/view';
import { BuilderPage } from '../pages/constructor/builder';

export const ConstructorRouters = () => (
  <Routes>
    <Route path="/list" element={<ConstructorView />} />
    <Route path="/create" element={<BuilderPage />} />
    <Route path="/edit/:id" element={<BuilderPage />} />
  </Routes>
);
