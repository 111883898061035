import React from 'react';
import styles from './CompanyCard.module.css';
import { Preview } from '../Preview';
import { Content } from '../Content';

export const CompanyCard = React.memo(({ company, updateCompany }) => (
  <div className={styles.container}>
    <Preview
      img={company.logo}
      inn={company.inn}
      ogrn={company.ogrn}
      kpp={company.requisites.kpp.value}
    />

    <Content
      companyId={company.id}
      name={company.name}
      isRNP={company.isRNP}
      categories={company.categories}
      groups={company.groups}
      phone={
        Object.values(company.contacts).find(
          (contact) => contact.label === 'Phone'
        )?.value
      }
      address={company.requisites?.legalAddress?.value}
      note={company.note}
      updateCompany={updateCompany}
    />
  </div>
));
