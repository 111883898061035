import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import block from 'bem-cn-lite';
import './styles/breadcrumbs_theme_default.styl';

const b = block('breadcrumbs');

const Crumb = ({ type, link, name, element }) => {
  switch (type) {
    case 'link':
      return <Link to={link}>{name}</Link>;
    case 'custom':
      return element;
    case 'text':
      return <span>{name}</span>;
    default:
      return <span>{name}</span>;
  }
};

Crumb.propTypes = {
  type: PropTypes.string.isRequired,
  name: PropTypes.string,
  link: PropTypes.string,
  element: PropTypes.any
};

Crumb.defaultProps = {
  link: '',
  element: null,
  name: ''
};

const getType = ({ isDisabledLink, link, element }) => {
  if (isDisabledLink) return 'text';
  if (element) return 'custom';
  if (link) return 'link';
  return 'text';
};

const BreadcrumbsDefault = ({ items, mods, theme = 'default' }) => (
  <ul className={`${b({ type: 'default', theme, ...mods })} breadcrumb`}>
    {items.map(({ active, link, name: nameItem, element, disabled }, i) => (
      <li key={i} className={b('item', { active })}>
        {' '}
        {/* eslint-disable-line */}
        <Crumb
          type={getType({ isDisabledLink: disabled, element, link })}
          link={link}
          element={element}
          name={nameItem}
        />
      </li>
    ))}
  </ul>
);

BreadcrumbsDefault.propTypes = {
  items: PropTypes.array.isRequired,
  mods: PropTypes.object
};

BreadcrumbsDefault.defaultProps = {
  mods: {}
};

export default BreadcrumbsDefault;
