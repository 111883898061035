import React from 'react';
import ResponseHeaderCell from '../components/ResponseHeaderCell/ResponseHeaderCell';
import FilterCell from '../components/FilterCell/FilterCell';
import { CellType } from '../domain/cell';
import {
  DATA_CELL_WIDTH,
  LABELS_CELL_WIDTH,
  NUMBER_CELL_WIDTH,
  PRODUCT_NAME_CELL_WIDTH
} from '../constants';

function generateColumn(data) {
  return {
    colSpan: data.colSpan,
    fixed: data.fixed,
    title: data.title,
    dataIndex: data.dataIndex,
    key: data.key,
    width: data.width,
    minWidth: data.minWidth,
    render: data.render,
    onCell: data.onCell
  };
}

function generateResponseColumn(
  response,
  index,
  onDeleteColumn,
  responsesLength
) {
  const dataIndex = response.id.toString();

  return generateColumn({
    dataIndex,
    key: response.id,
    title: (
      <ResponseHeaderCell
        responseId={response.id}
        onDelete={onDeleteColumn}
        disableDeleteColumn={responsesLength < 2}>
        {response.companyName}
      </ResponseHeaderCell>
    ),
    width: DATA_CELL_WIDTH,
    onCell: (record) => ({
      record,
      type: CellType.Data,
      rowNumber: index + 1,
      dataIndex
    })
  });
}

export function generateColumns(
  responses = [],
  { onDeleteColumn, filterComponent } = {}
) {
  const defaultColumns = [
    generateColumn({
      width: NUMBER_CELL_WIDTH,
      dataIndex: CellType.RowNumber,
      key: CellType.RowNumber,
      fixed: 'left',
      colSpan: 3,
      title: <FilterCell component={filterComponent} />,
      onCell: (record) => ({ record, type: CellType.RowNumber })
    }),
    generateColumn({
      colSpan: 0,
      fixed: 'left',
      dataIndex: CellType.ProductName,
      key: CellType.ProductName,
      width: PRODUCT_NAME_CELL_WIDTH,
      onCell: (record) => ({ record, type: CellType.ProductName })
    }),
    generateColumn({
      colSpan: 0,
      fixed: 'left',
      dataIndex: CellType.Labels,
      key: CellType.Labels,
      width: LABELS_CELL_WIDTH,
      onCell: (record) => ({ record, type: CellType.Labels })
    })
  ];

  const responsesColumns = responses.map((response, index) =>
    generateResponseColumn(response, index, onDeleteColumn, responses.length)
  );

  return defaultColumns.concat(responsesColumns);
}

export function generateRows(request, responses) {
  const rows = [];

  request.products.forEach((product, index) => {
    rows.push({
      key: index,
      rowNumber: index + 1,
      productName: product.name,
      requestProduct: product
    });
  });

  responses.forEach((response, responseIndex) => {
    response.products.forEach((product, index) => {
      rows[index] = {
        ...rows[index],
        dataIndex: index,
        [responseIndex + 1]: product
      };
    });
  });

  return rows;
}
