import React from 'react';

const PopupContent = ({ menuOptions, style = {} }) => (
  <>
    {menuOptions
      .filter(({ hide }) => !hide)
      .map(
        ({
          onClick = undefined,
          disabled = false,
          name,
          icon,
          isFocused = false
        }) => (
          <div
            style={style}
            key={name}
            className={`compare-popup-item ${
              disabled ? 'compare-popup-disabled' : 'compare-popup-item-hover'
            } ${isFocused && 'compare-popup-item-hovered'}`}
            onClick={disabled ? undefined : onClick}>
            {icon}
            <div style={{ width: 8 }} />
            {name}
          </div>
        )
      )}
  </>
);

export default PopupContent;
