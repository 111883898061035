import React, { Fragment } from 'react';
import { translate } from 'react-i18next';

const EmptySearch = ({ t, tkey }) => (
  <>
    <p style={{ marginTop: 28, color: '#767B92' }}>{t(tkey)}</p>
  </>
);

export default translate()(EmptySearch);
