import React from 'react';
import ButtonBack from '../../../../../../frontend-web-publisher/src/components/ChatWidget/ButtonBack';
import ChatAvatar from '../../../../../../frontend-web-publisher/src/components/ChatWidget/main/ChatAvatar';
import ChatTitle from '../../../../../../frontend-web-publisher/src/components/ChatWidget/ChatTitle';
import Avatars from '../../../../../../frontend-web-publisher/src/components/ChatWidget/main/Avatars';
import CWMBtns from '../../../../../../frontend-web-publisher/src/components/ChatWidget/modules/dashboard/Buttons';
import DashboardCommon from '../../../../../../frontend-web-publisher/src/components/ChatWidget/main/dashboard-common';
import { isDialog } from '../../../../../../frontend-web-publisher/src/components/ChatWidget/helpers/chatType';
import './style.css';

function CWDashboard({
  user,
  chat,
  config,
  isActive,
  isCursorPointer,
  onBackClick,
  onClickAvatar,
  onClickAvatars,
  interlocutor,
  status,
  avatarLists,
  onHandleClick
}) {
  return (
    <DashboardCommon theme={config.theme}>
      <div className="cwm-dashboard">
        <div className="cwm-dashboard__side-left">
          {config.buttonBack && (
            <div className="cwm-dashboard__back-btn">
              <ButtonBack onClick={onBackClick} />
            </div>
          )}
          {config.avatar && (
            <div>
              <ChatAvatar
                chat={chat}
                isCursorPointer={isCursorPointer}
                onClick={onClickAvatar}
                size={30}
              />
              <div className="cwm-dashboard__separator" />
            </div>
          )}
          {config.title && (
            <div className="cwm-dashboard__title">
              <ChatTitle
                chatName={chat.name}
                chatType={chat.type}
                notificationOff={chat.notificationOff}
                interlocutor={interlocutor}
              />
              {isDialog(chat) && (
                <div className="cwm-dashboard__status">{status}</div>
              )}
            </div>
          )}
        </div>
        <div className="cwm-dashboard__side-right">
          {config.avatarsList && (
            <Avatars
              avatarLists={avatarLists}
              onClick={onClickAvatars}
              offsetRight={8}
            />
          )}
          <CWMBtns
            chat={chat}
            user={user}
            isActive={isActive}
            handler={onHandleClick}
            config={config}
            type={chat.type}
            size={18}
          />
        </div>
      </div>
    </DashboardCommon>
  );
}

export default CWDashboard;
