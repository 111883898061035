import React from 'react';
import { checkMask } from './masks';

function MaskInput({ mask, value, onChange: _onChange, ...restProps }) {
  const onChange = ({ target: { value: newValue } }) => {
    if (mask && checkMask[mask]) {
      if (checkMask[mask](newValue)) {
        _onChange(newValue);
      }
      return;
    }

    _onChange(newValue);
  };

  return <input {...restProps} value={value} onChange={onChange} />;
}

export default MaskInput;
