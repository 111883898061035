const mainPath = (state) => state.get('prices');

export default (state) => ({
  data: () => mainPath(state),
  stocks: () => mainPath(state).get('stocks'),
  units: () => mainPath(state).get('units'),
  statuses: () => mainPath(state).get('statuses'),
  attrsFilter: () => mainPath(state).get('attrsFilter'),
  mode: () => mainPath(state).get('mode'),
  categoriesFilter: () => mainPath(state).get('categoriesFilter'),
  complexAttrsFilter: () => mainPath(state).get('complexAttrsFilter')
});
