import React from 'react';
import PropTypes from 'prop-types';
import block from 'bem-cn-lite';
import { translate } from 'react-i18next';

const b = block('containers');

const ContainersDefault = ({ children, className = '', style = {} }) => (
  <div className={`${b({ type: 'default' })} ${className}`} style={style}>
    {children}
  </div>
);

ContainersDefault.propTypes = {
  children: PropTypes.any
};

ContainersDefault.defaultProps = {
  children: ''
};

export default translate(['ui'], { wait: true })(ContainersDefault);
