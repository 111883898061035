import React from 'react';
import classNames from 'classnames';
import Badge, { BADGES_TYPES } from '../Badge';
import './Avatar.css';

const AVATAR_DEFAULT_SIZE = 40;
const AVATAR_DEFAULT_BORDER_RADIUS = '50%';

function Avatar({
  src,
  badge,
  height,
  width,
  padding,
  loading,
  size = AVATAR_DEFAULT_SIZE,
  className,
  onClick,
  mode,
  children,
  getRootRef,
  getRef,
  isCursorPointer,
  ...restProps
}) {
  const [isFailedLoadImage, setIsFailedLoadImage] = React.useState(false);

  const onImageLoadError = (err) => {
    setIsFailedLoadImage(true);
  };

  const onImageLoadSuccess = () => {
    setIsFailedLoadImage(false);
  };

  return (
    <div
      {...restProps}
      ref={getRootRef}
      className={classNames('Avatar', `Avatar-size-${size}`, {
        [`Avatar-type-${mode}`]: Boolean(mode),
        'Avatar-failed': isFailedLoadImage,
        [className]: Boolean(className)
      })}
      role="img"
      onClick={onClick}
      style={{
        width: size,
        height: size,
        padding,
        borderRadius: AVATAR_DEFAULT_BORDER_RADIUS,
        cursor: isCursorPointer ? 'pointer' : 'default'
      }}>
      {src && (
        <img
          className="Avatar__img"
          height={height}
          width={width}
          loading={loading}
          src={src}
          ref={getRef}
          onLoad={onImageLoadSuccess}
          onError={onImageLoadError}
          alt=""
        />
      )}
      {children && <div className="Avatar__children">{children}</div>}
      {badge && (
        <div className="Avatar__badge">
          {badge === BADGES_TYPES.Online ? (
            <Badge type={BADGES_TYPES.Online} />
          ) : (
            badge
          )}
        </div>
      )}
    </div>
  );
}

export default Avatar;
