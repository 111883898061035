import React, { useLayoutEffect, useRef } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import { getEmptyImage } from 'react-dnd-html5-backend';
import { TASK } from '../../../../constants';
import Task from './Task';

function generateTaskListItemCN({ isDragging = false }) {
  return `task-list-item-wrapper ${isDragging ? 'dragging' : ''}`.trim();
}

const DNDTask = ({
  data,
  path,
  index,
  onDrop,
  moveTask,
  extraOptions,
  handler,
  onClick
}) => {
  const ref = useRef(null);

  const [{ isDragging }, drag, preview] = useDrag({
    item: { type: TASK, id: data.id, data, path, status: data.status, index },
    collect: (monitor) => ({
      isDragging: monitor.isDragging()
    })
  });

  const [, drop] = useDrop({
    accept: TASK,
    drop: (item) => {
      onDrop({
        id: item.id,
        fromSection: item.status,
        atSection: data.status,
        task: item.data,
        atIndex: index
      });
    },
    hover: (item) => {
      if (item.id === data.id) return;

      moveTask({ id: item.id, atSection: data.status, atIndex: index });
    }
  });

  // Disable default browser's draggable preview is need for use custom preview
  useLayoutEffect(() => {
    preview(getEmptyImage(), { captureDraggingState: true });
  }, []);

  useLayoutEffect(() => {
    drag(drop(ref));

    return () => {
      drag(null);
      drop(null);
    };
  }, [drag, drop, ref]);

  return (
    <div ref={ref} className={generateTaskListItemCN({ isDragging })}>
      <Task
        task={data}
        extraOptions={extraOptions}
        handler={handler}
        onClick={onClick}
      />
    </div>
  );
};

export default DNDTask;
