import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import ModalSimpleSubmit from '../components/Modal/ModalSimpleSubmit';
import * as modalAction from '../action-creators/modal';

const ContModalSimpleSubmit = ({
  captionKey,
  title,
  onCancel = () => {},
  onSubmited = () => {},
  hideModalDialog,
  submitAction,
  submitArguments,
  text,
  textBtnCancel,
  textBtnConfirm,
  isCloseAfterClickBackground = true,
  isCallCancelCbOnlyByClickCancelBtn = false
}) => (
  <ModalSimpleSubmit
    title={captionKey || title}
    hideModalDialog={hideModalDialog}
    submit={submitAction}
    submitArguments={submitArguments}
    onCancel={onCancel}
    onSubmited={onSubmited}
    text={text}
    textBtnConfirm={textBtnConfirm}
    textBtnCancel={textBtnCancel}
    isCloseAfterClickBackground={isCloseAfterClickBackground}
    isCallCancelCbOnlyByClickCancelBtn={isCallCancelCbOnlyByClickCancelBtn}
  />
);

export default connect(null, (dispatch) =>
  bindActionCreators(
    {
      hideModalDialog: modalAction.hideModalDialog
    },
    dispatch
  )
)(ContModalSimpleSubmit);
