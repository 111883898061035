const getMyPurchaseRequests = (state) => state.get('myPurchaseRequests');

export const getMyRequests = (state) => getMyPurchaseRequests(state).requests;
export const getSize = (state) => getMyPurchaseRequests(state).size;
export const getLimit = (state) => getMyPurchaseRequests(state).limit;
export const getOffset = (state) => getMyPurchaseRequests(state).offset;
export const getOrder = (state) => getMyPurchaseRequests(state).orderBy;
export const getDirection = (state) => getMyPurchaseRequests(state).direction;
export const getSelectedCategoriesId = (state) =>
  getMyPurchaseRequests(state).selectedCategoriesId;
export const getSearch = (state) => getMyPurchaseRequests(state).search;
export const getStatus = (state) => getMyPurchaseRequests(state).selectedStatus;
export const getIsUploaded = (state) => getMyPurchaseRequests(state).isUploaded;
export const getCompareResponses = (state) =>
  getMyPurchaseRequests(state).compareResponses;
export const getCompareRequest = (state) =>
  getMyPurchaseRequests(state).compareRequest;
