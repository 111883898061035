import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { addDays } from 'date-fns';
import {
  Button,
  DateTimePicker,
  Modal,
  Typography
} from '@link/react-components';
import { useActions, useAsyncFn } from '@hooks';
import { useSelector } from 'react-redux';
import { useTranslate } from '../../../TranslateProvider';
import * as modalActions from '../../../action-creators/modal';

import { Switcher } from './components/Switcher';
import styles from './ModalExtendResponseEndDate.module.css';
import { purchaseRequestService } from '../../../api';
import * as storeGetters from '../../../storeGetters';
import * as notificationMessageActions from '../../../action-creators/message';
import {
  compareTwoDates,
  createTomorrow,
  generateDefaultDate,
  isCanUpdate,
  translate
} from './lib';

const minDate = createTomorrow();
const Content = React.memo(
  ({ currentResponseEndDate, onChangeDataPicker, children }) => {
    const lang = useSelector(storeGetters.getCurrentUserLanguage);
    return (
      <section className={styles.content}>
        <DateTimePicker
          minDate={minDate}
          locale={lang}
          date={currentResponseEndDate}
          onDateChange={onChangeDataPicker}
        />
        {children}
      </section>
    );
  }
);

const SwitchersBlock = React.memo(({ days, onChangeActiveDay, activeDay }) => {
  const t = useTranslate();
  return (
    <>
      <Typography variant="body1Sb" className={styles.subtitle}>
        {translate(t, 'content.quickSelection')}
      </Typography>
      <div className={styles.switcherContainer}>
        {days.map((day) => (
          <Switcher
            isActive={day.value === activeDay}
            day={day}
            onClick={onChangeActiveDay}
          />
        ))}
      </div>
    </>
  );
});
const FooterButtons = ({ update, loading, disabled }) => {
  const t = useTranslate();
  const { setSuccessMessage, hideModalDialog } = useActions({
    ...notificationMessageActions,
    ...modalActions
  });

  const submit = async () => {
    await update();
    setSuccessMessage({
      key: 'notifications.successUpdatedResponseEndDate'
    });
    hideModalDialog();
  };

  return (
    <Modal.Footer align="right">
      <Modal.Actions>
        <Button onClick={hideModalDialog} mode="text">
          {translate(t, 'footer.cancel')}
        </Button>
        <Button onClick={submit} loading={loading} disabled={disabled}>
          {translate(t, 'footer.prolong')}
        </Button>
      </Modal.Actions>
    </Modal.Footer>
  );
};

export const ModalExtendResponseEndDate = ({ request, updateRequest }) => {
  const t = useTranslate();
  const { hideModalDialog } = useActions(modalActions);

  const [datePickerState, setDatePickerState] = useState(() =>
    addDays(compareTwoDates(request.responseEndDate), +1)
  );
  const [defaultDataPickerState, _] = useState(() =>
    addDays(compareTwoDates(request.responseEndDate), +1)
  );
  const [activeDay, setActiveDay] = useState(null);
  const [responseEndDate, setResponseEndDate] = useState(() =>
    addDays(compareTwoDates(request.responseEndDate), +1)
  );

  const onChangeDataPicker = useCallback((date) => {
    setDatePickerState(date);
    setResponseEndDate(date);
  }, []);

  useEffect(() => {
    if (activeDay) {
      setResponseEndDate(activeDay);
    }
  }, [activeDay]);

  const onChangeActiveDay = useCallback(
    (day) => {
      if (day.value === activeDay) {
        setActiveDay(new Date(datePickerState));
      } else {
        setActiveDay(day.value);
      }
    },
    [activeDay, datePickerState]
  );

  const days = useMemo(
    () => [
      {
        text: translate(t, 'content.days3'),
        value: addDays(generateDefaultDate(datePickerState), 3)
      },
      {
        text: translate(t, 'content.days', { number: 5 }),
        value: addDays(generateDefaultDate(datePickerState), 5)
      },
      {
        text: translate(t, 'content.days', { number: 7 }),
        value: addDays(generateDefaultDate(datePickerState), 7)
      },
      {
        text: translate(t, 'content.days', { number: 14 }),
        value: addDays(generateDefaultDate(datePickerState), 14)
      }
    ],
    [datePickerState]
  );
  //
  const [{ loading }, update] = useAsyncFn(async () => {
    const { updated } = await purchaseRequestService.extendResponseEndDate({
      requestId: request.id,
      responseEndDate
    });
    updateRequest({
      request: {
        ...request,
        ...updated
      }
    });
  }, [responseEndDate]);

  return (
    <Modal open onClose={hideModalDialog}>
      <Modal.Header>{translate(t, 'title')}</Modal.Header>
      <Modal.Content>
        <Content
          minDate={minDate}
          onChangeDataPicker={onChangeDataPicker}
          currentResponseEndDate={activeDay || defaultDataPickerState}>
          <div className={styles.switchers}>
            <SwitchersBlock
              days={days}
              activeDay={activeDay}
              onChangeActiveDay={onChangeActiveDay}
            />
          </div>
        </Content>
      </Modal.Content>

      <FooterButtons
        loading={loading}
        update={update}
        disabled={!isCanUpdate(activeDay || datePickerState, responseEndDate)}
      />
    </Modal>
  );
};
