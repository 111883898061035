import React from 'react';
import { Link } from 'react-router-dom';
import cn from 'classnames';
import styles from './Popover.module.css';

const POPOVER_TYPES = {
  DEFAULT: 'DEFAULT',
  RESPONSIBLE: 'RESPONSIBLE',
  INVITE_LINK: 'INVITE_LINK'
};

function createPopover({ type = 'DEFAULT', text, link }) {
  switch (type) {
    case POPOVER_TYPES.DEFAULT:
      return <div>{text}</div>;
    case POPOVER_TYPES.RESPONSIBLE:
      return (
        <>
          <div className={styles.popoverResponsible}>{text}</div>
          <Link to="/employees/list" target="_blank">
            {link}
          </Link>
        </>
      );
    case POPOVER_TYPES.INVITE_LINK:
      return (
        <>
          <div>{text}</div>
          <Link
            to="https://goodwix.com/knopki-vhoda-v-goodwix-na-saite/"
            target="_blank">
            {link}
          </Link>
        </>
      );
    default:
      return null;
  }
}

export const Popover = ({ classNameWrapper, ...props }) => (
  <div className={cn(styles.popoverWrapper, classNameWrapper)}>
    {createPopover(props)}
  </div>
);
