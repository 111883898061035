import React, { useContext } from 'react';
import { translate } from 'react-i18next';

export const TransContext = React.createContext(null);

export const useTranslate = () => useContext(TransContext);

const TranslateProvider = ({ t, children }) => (
  <TransContext.Provider value={t}>{children}</TransContext.Provider>
);

export default translate()(TranslateProvider);
