import React from 'react';
import DOMPurify from 'dompurify';
import ReactSummernote from 'react-summernote';
import 'react-summernote/dist/react-summernote.css';
import 'react-summernote/lang/summernote-ru-RU';
import 'bootstrap/js/modal';
import 'bootstrap/js/dropdown';
import 'bootstrap/js/tooltip';
import 'bootstrap/dist/css/bootstrap.css';
import getBrowserLanguage from '../../../../../utils/languageDetector';

// NOTE: value set once at the start
const DescriptionEditor = ({ value, onChange, disabled = false }) => (
  <ReactSummernote
    id="description-editor"
    className="product-description"
    data-testid="description-editor"
    options={{
      lang: `${getBrowserLanguage()}-${getBrowserLanguage().toUpperCase()}`,
      height: 133,
      dialogsInBody: true,
      toolbar: [
        ['style', ['style']],
        ['font', ['bold', 'underline', 'italic']],
        ['para', ['ul', 'ol', 'paragraph']],
        ['view', ['codeview']]
      ]
    }}
    value={value}
    onChange={(text) => onChange({ target: { value: text } })}
    disabled={disabled}
    onInit={({ summernote }) =>
      value && summernote('code', DOMPurify.sanitize(value))
    }
  />
);

export default DescriptionEditor;
