import React, { Component } from 'react';
import PropTypes from 'prop-types';
import block from 'bem-cn-lite';
import { translate } from 'react-i18next';
import { Modal, Form, Button } from 'react-bootstrap';
import SetCategory from '../SetCategory/SetCategory';
import './styles/modal_theme_default.styl';
import './styles/modal_type_grant-access.styl';

import { ModalContainer } from './elements';

const b = block('modal-container');

class ModalMoveCategory extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectValue: props.defaultValue
    };
  }

  getChildContext() {
    return { b };
  }

  setSelectValue = (value) => {
    this.setState({ selectValue: +value });
  };

  handleClick = () => {
    const { hideModalDialog, submit, getCatalog, catalogId } = this.props;

    submit(this.state.selectValue).then(() => {
      getCatalog(catalogId);
      hideModalDialog();
    });
  };

  render() {
    const { t, title, hideModalDialog, listOfCategory } = this.props;

    return (
      <Modal id="grandAccess" onHide={() => hideModalDialog()} show>
        <ModalContainer mods={{ type: 'grant-access', theme: 'default' }}>
          <Modal.Header>
            <Modal.Title>
              {t(title)}
              <span
                className="modal-header-cross pull-right"
                onClick={() => hideModalDialog()}
              />
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <div>
                <p className={b('row', { 'with-input': true })}>
                  <div>{t('Move products to category')}</div>
                  <div style={{ width: 200, marginRight: 15 }}>
                    <SetCategory
                      selectedId={this.state.selectValue}
                      style={{ width: '275px', marginLeft: 15 }}
                      onChose={this.setSelectValue}
                      categories={listOfCategory}
                      disableOptionNotSelected
                    />
                  </div>
                </p>
              </div>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button
              id="cancel"
              className="pull-left"
              onClick={() => {
                hideModalDialog();
              }}>
              {t('Cancel')}
            </Button>
            <Button id="submit" bsStyle="primary" onClick={this.handleClick}>
              {t('Move')}
            </Button>
          </Modal.Footer>
        </ModalContainer>
      </Modal>
    );
  }
}

ModalMoveCategory.contextTypes = {
  badgeSocket: PropTypes.object
};

ModalMoveCategory.propTypes = {
  t: PropTypes.func.isRequired
};

ModalMoveCategory.childContextTypes = {
  b: PropTypes.func
};

export default translate(['ui'], { wait: true })(ModalMoveCategory);
