import React from 'react';
import { useVideoChatContext } from './useVideoChatContext';
import { createVideoRoomCMD } from '../commandsAndEvents';
import { VIDEO_CHAT_MODS } from './useVideoChat';

export const useClickByVideoChatIcon = () => {
  const context = useVideoChatContext();

  return React.useCallback(
    (chatId) => {
      if (context.mode === VIDEO_CHAT_MODS.Hide) {
        if (context.chatId === chatId) {
          context.showWindow();
        } else {
          context.endCall({
            onSubmited: () => {
              createVideoRoomCMD.emit({ chatId });
            }
          });
        }

        return;
      }

      createVideoRoomCMD.emit({ chatId });
    },
    [context.mode]
  );
};
