import React, { useState } from 'react';
import { translate } from 'react-i18next';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Modal } from 'react-bootstrap';
import block from 'bem-cn-lite';
import PropTypes from 'prop-types';

import { Container, Header, Body, Footer } from '../Components';
import * as modalActions from '../../../../action-creators/modal';

const b = block('modal-container');

const CWModalStorageOversize = ({ t, hideModalDialog, onSubmit }) => {
  const [activeBtn, setBtn] = useState('');

  return (
    <Modal id="storageOversize" onHide={() => hideModalDialog()} show>
      <Container>
        <Header title={t('storage_control')} onClose={hideModalDialog} />
        <Body>
          <div style={{ marginBottom: 27 }}>{t('storage_oversize_txt')}</div>
          <div
            style={{ display: 'flex', alignItems: 'center', marginBottom: 12 }}>
            <span
              className={b('radio-btn', { set: activeBtn === 'increase' })}
              onClick={() => setBtn('increase')}
            />
            <span className="modal-checkbox-label">
              {t('increase_storage_size')}
            </span>
          </div>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <span
              className={b('radio-btn', { set: activeBtn === 'clear' })}
              onClick={() => setBtn('clear')}
            />
            <span className="modal-checkbox-label">{t('clear_storage')}</span>
          </div>
        </Body>
        <Footer
          lbTitle={t('Cancel')}
          lbOnClick={hideModalDialog}
          rbTitle={t('Go to')}
          rbOnClick={() => onSubmit(activeBtn)}
        />
      </Container>
    </Modal>
  );
};

CWModalStorageOversize.contextTypes = {
  b: PropTypes.func
};

export default connect(
  () => ({}),
  (dispatch) =>
    bindActionCreators(
      {
        hideModalDialog: modalActions.hideModalDialog
      },
      dispatch
    )
)(translate(['ui'], { wait: true })(CWModalStorageOversize));
