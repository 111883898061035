import React from 'react';
import {
  Modal,
  Col,
  Form,
  FormGroup,
  FormControl,
  ControlLabel,
  Button,
  Alert,
  HelpBlock
} from 'react-bootstrap';
import { connect } from 'react-redux';
import { translate } from 'react-i18next';
import * as modalActionCreators from '../../../action-creators/modal';
import * as accessActionCreators from '../../../action-creators/catalog/access';
import * as servicesAction from '../../../action-creators/services';

const CatalogAccessModal = translate(['ui'], { wait: true })(
  class extends React.Component {
    constructor(props) {
      super(props);
      this.submit = this.submit.bind(this);
    }

    UNSAFE_componentWillMount() {
      const { user, gaSend } = this.props;
      gaSend({
        category: 'Supplier',
        action: 'catalog_add_access_open',
        label: user.get('email')
      });
    }

    getEmail() {
      return this.props.catalogAccess.get('email') || '';
    }

    isInvalid() {
      return this.props.errors && this.props.errors.has('email');
    }

    validationState() {
      // eslint-disable-next-line no-nested-ternary
      return this.isInvalid()
        ? { validationState: 'error' }
        : this.hasErrorMessage()
        ? { validationState: 'warning' }
        : {};
    }

    getError() {
      const { t, errors } = this.props;
      const error = errors.get('email');
      return t(error.get('messageKey'), error.get('messageParams'));
    }

    submit(e) {
      e.preventDefault();
      const {
        t,
        grantAccessDisabled,
        grantAccess,
        catalogAccess,
        current,
        hideModalDialog
      } = this.props;
      const company = catalogAccess.get('email');
      const catalogId = current.get('_id');

      if (!grantAccessDisabled) {
        grantAccess(company, catalogId)
          .then(() => {
            alert(t('The email with a link to access the catalogue was sent'));
            hideModalDialog();
          })
          .catch((err) => console.error(err));
      }
    }

    getInputProps() {
      const { updateCatalogAccessErrorMessage, updateCatalogAccessEmail } =
        this.props;
      return {
        value: this.getEmail(),
        onChange: (e) => {
          if (this.hasErrorMessage()) {
            updateCatalogAccessErrorMessage(null);
          }
          updateCatalogAccessEmail(e.target.value);
        }
      };
    }

    hasErrorMessage() {
      const { catalogAccess } = this.props;
      return (
        catalogAccess.has('errorMessage') &&
        catalogAccess.get('errorMessage') != null
      );
    }

    render() {
      const {
        t,
        hideModalDialog,
        grantAccessDisabled,
        catalogAccess,
        gaPageView,
        gaSend,
        user
      } = this.props;

      return (
        <Modal
          id="accessModalWindow"
          show
          onHide={() => {
            gaPageView('catalog_add_access_cancel');
            gaSend({
              category: 'Supplier',
              action: 'catalog_add_access_cancel',
              label: user.get('email')
            });
            hideModalDialog();
          }}>
          <Modal.Header>
            <Modal.Title>
              {t('Share catalogue')}
              <span
                className="modal-header-cross pull-right"
                onClick={() => hideModalDialog()}
              />
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form horizontal>
              <FormGroup controlId="email" {...this.validationState()}>
                <Col xs={12}>
                  <FormControl
                    type="text"
                    placeholder={t('Enter email address')}
                    {...this.getInputProps()}
                  />
                  {this.isInvalid() ? (
                    <HelpBlock>{this.getError()}</HelpBlock>
                  ) : null}
                </Col>
              </FormGroup>

              <FormGroup controlId="accessAlert" style={{ marginBottom: 0 }}>
                <Col
                  componentClass={ControlLabel}
                  xs={12}
                  style={{
                    fontSize: '12px',
                    color: 'lightgrey',
                    textAlign: 'left'
                  }}>
                  <Alert bsStyle={this.hasErrorMessage() ? 'warning' : 'info'}>
                    {t(
                      this.hasErrorMessage()
                        ? catalogAccess.get('errorMessage')
                        : 'Please note that you grant access to the latest published version of the catalogue'
                    )}
                  </Alert>
                </Col>
              </FormGroup>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button
              id="shareCatalogButton"
              bsStyle="primary"
              type="submit"
              disabled={grantAccessDisabled}
              onClick={(e) => {
                this.submit(e);
              }}>
              {t('Share catalogue')}
            </Button>
          </Modal.Footer>
        </Modal>
      );
    }
  }
);

const mapStateToProps = (state) => {
  const validator = state.get('validator');
  const errors = validator && validator.get('invalidFields');
  const hasError = errors && errors.has('email');
  const catalogAccess = state.getIn(['catalog', 'access']);
  const grantAccessDisabled =
    hasError || !(catalogAccess && catalogAccess.get('email'));
  return {
    errors,
    catalogAccess,
    grantAccessDisabled,
    current: state.getIn(['catalog', 'item']),
    company: state.get('company'),
    user: state.getIn(['user', 'user'])
  };
};

export default connect(mapStateToProps, {
  ...modalActionCreators,
  ...accessActionCreators,
  ...servicesAction
})(CatalogAccessModal);
