import React from 'react';
import { VideoChatContext } from './videoChatContext';
import VideoChat from './VideoChat';
import { useVideoChat } from './hooks/useVideoChat';
import config from './config';

const VideoChatProvider = ({ children }) => {
  const {
    mode,
    chatId,
    roomId,
    showWindow,
    hideWindow,
    videoChats,
    getMyPeer,
    myPeerId,
    joinInChat,
    startCall,
    endCall,
    toggleVideo,
    toggleAudio,
    onLeftMember,
    addRoomChatConnection,
    transcript,
    sidebarMode,
    changeSidebarMode,
    closeSidebar,
    addAdmin,
    currentMember,
    isCurrentMemberAdmin
  } = useVideoChat(config);

  return (
    <VideoChatContext.Provider
      value={{
        mode,
        showWindow,
        hideWindow,
        videoChatMembers: videoChats,
        myPeerId,
        myPeer: getMyPeer(),
        joinInChat,
        startCall,
        endCall,
        toggleVideo,
        toggleAudio,
        chatId,
        roomId,
        onLeftMember,
        addRoomChatConnection,
        transcript,
        sidebarMode,
        changeSidebarMode,
        closeSidebar,
        addAdmin,
        currentMember,
        isCurrentMemberAdmin
      }}>
      {children}
      <VideoChat />
    </VideoChatContext.Provider>
  );
};

export default VideoChatProvider;
