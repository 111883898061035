import React from 'react';
import { useToggleTranscriptChat } from '../../hooks/useToggleTranscriptChat';
import { isProduction } from '../../../../utils/utils';

const ButtonControlTranscript = () => {
  const onClick = useToggleTranscriptChat();

  window.startTranscript = onClick;

  if (isProduction()) {
    return null;
  }

  return (
    <button
      type="button"
      className="video-chat__btn video-chat__btn-transcript"
      onClick={onClick}>
      <img
        className="video-chat__btn-img"
        src="/img/chat-widget/message-envelop.svg"
        draggable={false}
        alt=""
      />
    </button>
  );
};

export default ButtonControlTranscript;
