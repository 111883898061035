import fetch from 'isomorphic-fetch';
import { showModal } from '../modal';

export const setField = (field, value) => ({
  type: 'TRANSLATE_CATALOG_SET_FIELD',
  field,
  value
});

export const translateCatalog = () => (dispatch) => {
  dispatch(showModal('TRANSLATE_CATALOG'));
};

export const sendNotification =
  (email, catalogId, language, browserLanguage, jsonUrl) => () =>
    fetch('/api/catalogs/send-translate-notification', {
      credentials: 'include',
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        data: {
          attributes: { email, catalogId, language, browserLanguage, jsonUrl }
        }
      })
    })
      .then((res) => Promise.all([res.ok, res.json()]))
      .then(([ok, json]) =>
        ok
          ? 'Your catalog has been sent for translate'
          : `Error: ${json.errors[0].detail}`
      )
      .catch((err) => console.log('FAILED SEND NOTIFICATION', err));
