import React from 'react';
import cn from 'classnames';
import Icon from 'react-components/src/icons';
import iconsView from 'react-components/src/icons/constants';
import Text from 'react-components/src/components/Typography';
import styles from './ButtonConstructor.module.css';

export const ButtonConstructor = ({
  isBig = false,
  className,
  text,
  isError = false,
  isDisabled = false,
  ...props
}) => (
  <button
    type="button"
    className={cn(styles.btn, className, {
      [styles.big]: isBig,
      [styles.isError]: isError,
      [styles.disabled]: isDisabled
    })}
    disabled={isDisabled}
    {...props}>
    <Text
      variant="body1Med"
      className={cn(styles.text, {
        [styles.textError]: isError
      })}>
      {text}
    </Text>

    <span
      className={cn(styles.icon, {
        [styles.iconError]: isError
      })}>
      <Icon icon={iconsView.Add} size={16} />
    </span>
  </button>
);
