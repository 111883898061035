import request from '../../../lib/request';
import { setIsSubscribed, setPurchaseRequestFilterData } from './actions';
import { getCurrentUserLanguage } from '../../../storeGetters';
import updateChatInList from '../../../modules/chats/useCases/updateChatInList';

export const changeSubscribe = (params) => async (dispatch) => {
  const chat = await request.post(
    '/api/purchase-request-channel/subscribe',
    params
  );
  dispatch(updateChatInList(chat));
  dispatch(setIsSubscribed(true));
};

export const unsubscribe = () => async (dispatch) => {
  const chat = await request.get(`/api/purchase-request-channel/unsubscribe`);
  dispatch(updateChatInList(chat));
  dispatch(setIsSubscribed(false));
};

export const getPurchaseRequestFilterData =
  () => async (dispatch, getState) => {
    const language = getCurrentUserLanguage(getState());
    const { active, ...data } = await request.get(
      `/api/purchase-request-channel/filter-data?lang=${language}`
    );
    dispatch(setIsSubscribed(active));
    dispatch(setPurchaseRequestFilterData(data));
  };

export const checkSubscribe = () => async (dispatch) => {
  const isSubscribed = await request.get(
    '/api/purchase-request-channel/check-subscribe'
  );
  dispatch(setIsSubscribed(isSubscribed));
};

export const subscribeToCompany = (companyId) => async (dispatch) => {
  const chat = await request.post(
    `/api/purchase-request-channel/subscribe/company/${companyId}`
  );
  dispatch(updateChatInList(chat));
  dispatch(setIsSubscribed(true));
};

export const unsubscribeFromCompany = (companyId) => async (dispatch) => {
  const chat = await request.post(
    `/api/purchase-request-channel/unsubscribe/company/${companyId}`
  );
  dispatch(updateChatInList(chat));
  dispatch(setIsSubscribed(true));
};
