import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { ModalBulkAddPricelistAndSetBaseprice } from '../components/Modal';

import * as pricesActions from '../action-creators/prices';
import * as modalAction from '../action-creators/modal';

const ContModalBulkAddPricelistAndSetBaseprice = (props) => (
  <ModalBulkAddPricelistAndSetBaseprice {...props} />
);

export default connect(
  (state) => ({
    user: state.getIn(['user', 'user'])
  }),
  (dispatch) =>
    bindActionCreators(
      {
        hideModalDialog: modalAction.hideModalDialog,
        bulkUpdateCost: pricesActions.bulkUpdateCost
      },
      dispatch
    )
)(ContModalBulkAddPricelistAndSetBaseprice);
