import { Map, List, fromJS } from 'immutable';

const initState = () =>
  new Map({
    productPositions: new List()
  });

const setPublicPricePositions = (data, { item }) =>
  data.clear().merge(fromJS(item));

const publicPricesPositions = (state = initState(), { type, payload }) => {
  switch (type) {
    case 'PUBLIC_PRICE:GET_POSITIONS:SUCCESS':
      return setPublicPricePositions(state, payload);
    default:
      return state;
  }
};

export default publicPricesPositions;
