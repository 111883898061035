import React from 'react';
import { useTranslate } from '@hooks';
import MessengerOther from '../../../components/Layouts/MessengerOther';
import { Settings } from '../../../components/Settings';

export function SettingsPage() {
  const t = useTranslate();

  return (
    <MessengerOther
      theme="gray"
      title={t('modules.settings.helmetTitle')}
      content={<Settings />}
      noShowPanel
    />
  );
}
