import React, { Component } from 'react';
import PropTypes from 'prop-types';
import block from 'bem-cn-lite';
import { translate } from 'react-i18next';
import { Modal, Form, FormControl, Button, InputGroup } from 'react-bootstrap';
import './styles/modal_theme_default.styl';
import './styles/modal_type_add-products-to-price.styl';

import { ModalContainer } from './elements';

const b = block('modal-container');

class ModalAddProductsToPrice extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cost: props.product.cost || 0
    };
    this.setCount = this.setCount.bind(this);
  }

  getChildContext() {
    return { b };
  }

  setCount(e) {
    this.setState({ cost: e.target.value });
  }

  handlerClick = () => {
    const {
      hideModalDialog,
      updateSearchProductOfPrice,
      submit,
      priceId,
      product: { id },
      query,
      limit,
      offset,
      categoryId,
      getPrice
    } = this.props;

    submit({ priceId, productId: id, cost: this.state.cost, categoryId }).then(
      () => {
        updateSearchProductOfPrice({ priceId, query, limit, offset });
        getPrice(priceId);
        hideModalDialog();
      }
    );
  };

  render() {
    const {
      t,
      captionKey,
      hideModalDialog,
      product: { name, sku },
      currency
    } = this.props;

    return (
      <Modal id="addProductsToStock" onHide={() => hideModalDialog()} show>
        <ModalContainer
          mods={{ type: 'add-products-to-price', theme: 'default' }}>
          <Modal.Header>
            <Modal.Title>
              {t(captionKey)}
              <span
                className="modal-header-cross pull-right"
                onClick={() => hideModalDialog()}
              />
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <div className={b('row')}>
                <div className={b('title')}>{t('SKU')}</div>
                <div className={b('value')}>{sku}</div>
              </div>
              <div className={b('row')}>
                <div className={b('title')}>{t('Name')}</div>
                <div className={b('value')}>{name}</div>
              </div>
              <div className={b('row', { 'with-input': true })}>
                <div className={b('title')}>{t('Price')}</div>
                <div className={b('input', { cost: true })}>
                  <InputGroup>
                    <FormControl
                      value={this.state.cost}
                      type="number"
                      onChange={this.setCount}
                      onKeyDown={(e) => e.keyCode === 13 && e.preventDefault()}
                      min={0}
                    />
                    <InputGroup.Addon>{currency}</InputGroup.Addon>
                  </InputGroup>
                </div>
              </div>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button
              id="cancel"
              className="pull-left"
              onClick={() => hideModalDialog()}>
              {t('Cancel')}
            </Button>
            <Button
              id="saveCharacteristicButton"
              bsStyle="primary"
              disabled={!this.state.cost}
              onClick={this.handlerClick}>
              {t('Add products')}
            </Button>
          </Modal.Footer>
        </ModalContainer>
      </Modal>
    );
  }
}

ModalAddProductsToPrice.propTypes = {
  t: PropTypes.func.isRequired
};

ModalAddProductsToPrice.childContextTypes = {
  b: PropTypes.func
};

export default translate(['ui'], { wait: true })(ModalAddProductsToPrice);
