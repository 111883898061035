import React from 'react';
import { translate } from 'react-i18next';
import { Overlay, Popover } from 'react-bootstrap';

const HelperClose = ({ t, text, link, show, toggleShow, target }) => (
  <Overlay
    trigger={['click']}
    placement="bottom"
    rootClose
    onHide={toggleShow}
    show={show}
    target={target}>
    <Popover id="popover-helper">
      <div
        className="modal-header-cross pull-right"
        style={{ width: 9, height: 9 }}
        onClick={toggleShow}
      />
      <div className="help-text-popover">{t(text)}</div>

      {link && (
        <div className="help-link-more">
          <a target="_blank" href={link} rel="noopener noreferrer">
            {t('more')}
          </a>
        </div>
      )}
    </Popover>
  </Overlay>
);

export default translate(['ui'], { wait: true })(HelperClose);
