import { curry, compose } from 'ramda';
import { discountPrice, roundUpFrationalDigits } from '../../lib/finance';

export const discountPriceWithRoundUp = curry(
  (percent, fractionalDigits, price) =>
    compose(
      roundUpFrationalDigits(fractionalDigits),
      discountPrice(percent)
    )(price)
);

export const discountCategoryPriceWithRoundUp = curry((percent, cost, price) =>
  compose(discountPrice(percent))(percent ? price : cost || price)
);
