import React, { Component } from 'react';
import PropTypes from 'prop-types';
import block from 'bem-cn-lite';
import { translate } from 'react-i18next';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  Modal,
  Form,
  FormGroup,
  FormControl,
  ControlLabel,
  Button,
  HelpBlock
} from 'react-bootstrap';

import './styles/modal_theme_default.styl';
import { ModalContainer } from './elements';

import * as modalActions from '../../action-creators/modal';
import * as priceActions from '../../action-creators/prices';
import * as servicesAction from '../../action-creators/services';

const b = block('modal-container');

class ModalCopyPrice extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: '',
      pristine: true
    };
    this.inputProps = this.inputProps.bind(this);
    this.isValidNameField = this.isValidNameField.bind(this);
  }

  getChildContext() {
    return { b };
  }

  UNSAFE_componentWillMount() {
    const { gaSend, user } = this.props;
    gaSend({
      category: 'Supplier',
      action: 'price_copy_open',
      label: user.get('email')
    });
  }

  isValidNameField(priceNameExist) {
    return this.state.name.length < 1 || priceNameExist;
  }

  asterisk() {
    // eslint-disable-line
    return <span style={{ color: 'red' }}>*</span>;
  }

  inputProps(field) {
    return {
      value: this.state[field],
      onChange: (e) => {
        if (field === 'name') {
          if (this.state.error && this.state.error.type === 'name') {
            this.setState({ error: '' });
          }
          return this.setState({
            [field]: e.target.value.replace(/^\s+|\s(?=\s)/g, '')
          });
        }
        return this.setState({ [field]: e.target.value });
      }
    };
  }

  showHelpInfo(field, priceNameExist) {
    const { t } = this.props;
    if (!this.state[field] && !this.state.pristine) {
      return (
        <HelpBlock
          id="invalidFieldMessage"
          style={{
            color: 'red',
            fontSize: 12,
            marginTop: 10,
            marginBottom: 0
          }}>
          {t('Required fields empty')}
        </HelpBlock>
      );
    }
    if (field === 'name' && priceNameExist) {
      return (
        <HelpBlock
          id="invalidFieldMessage"
          style={{
            color: 'red',
            fontSize: 12,
            marginTop: 10,
            marginBottom: 0
          }}>
          {t('Name alredy exist')}
        </HelpBlock>
      );
    }
    return null;
  }

  render() {
    const {
      t,
      hideModalDialog,
      gaSend,
      copyPrice,
      user,
      priceId,
      prices,
      priceName
    } = this.props;

    const priceNameExist = prices.find(
      (i) => i.get('name') === this.state.name
    );

    return (
      <ModalContainer mods={{ theme: 'default' }}>
        <Modal
          className="test_modal_create-catalog"
          onHide={() => hideModalDialog()}
          show>
          <Modal.Header>
            <Modal.Title>
              {`${t('Copy to')} ${priceName}`}
              <span
                className="modal-header-cross pull-right"
                onClick={() => hideModalDialog()}
              />
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <FormGroup
                controlId="input_name"
                style={{ overflow: 'hidden' }}
                validationState={
                  (this.state.name || this.state.pristine) && !priceNameExist
                    ? null
                    : 'error'
                }>
                <ControlLabel>
                  {`${t('Price name')} `}
                  {this.asterisk()}
                </ControlLabel>
                <h6 className="pull-right" style={{ marginTop: 5 }}>
                  <small>
                    {this.state.name.length}
                    /60
                  </small>
                </h6>
                <FormControl
                  className="test_modal-input_name"
                  type="text"
                  {...this.inputProps('name')}
                  maxLength="60"
                  onKeyDown={(e) => {
                    if (this.state.pristine) {
                      this.setState({ pristine: false });
                    }
                    if (e.keyCode === 13) {
                      e.preventDefault();
                    }
                  }}
                />
                {this.showHelpInfo('name', priceNameExist)}
              </FormGroup>
            </Form>
            {this.asterisk()}
            {` ${t('Required fields')}`}
          </Modal.Body>
          <Modal.Footer>
            <Button
              id="cancel"
              className="pull-left"
              onClick={() => {
                gaSend({
                  category: 'Supplier',
                  action: 'copy_price_cancel',
                  label: user.get('email')
                });
                hideModalDialog();
              }}>
              {t('Cancel')}
            </Button>
            <Button
              id="saveButton"
              bsStyle="primary"
              className="test_modal-btn_create"
              disabled={this.isValidNameField(priceNameExist)}
              onClick={async () => {
                await gaSend({
                  category: 'Supplier',
                  action: 'copy_price_success',
                  label: user.get('email')
                });
                await copyPrice(priceId, this.state.name);
                hideModalDialog();
              }}>
              {t('Copy')}
            </Button>
          </Modal.Footer>
        </Modal>
      </ModalContainer>
    );
  }
}

ModalCopyPrice.propTypes = {
  t: PropTypes.func.isRequired
};

ModalCopyPrice.childContextTypes = {
  b: PropTypes.func
};

export default connect(
  (state) => ({
    company: state.get('company'),
    prices: state.getIn(['prices', 'items']),
    user: state.getIn(['user', 'user'])
  }),
  (dispatch) =>
    bindActionCreators(
      {
        copyPrice: priceActions.copyPrice,
        gaSend: servicesAction.gaSend,
        hideModalDialog: modalActions.hideModalDialog
      },
      dispatch
    )
)(translate(['ui'], { wait: true })(ModalCopyPrice));
