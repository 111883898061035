import { Map } from 'immutable';

const setField = (state, field, value) => state.set(field, value);

const translate = (state = Map(), action) => {
  switch (action.type) {
    case 'TRANSLATE_CATALOG_SET_FIELD':
      return setField(state, action.field, action.value);
    default:
      return state;
  }
};

export default translate;
