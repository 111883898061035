import React from 'react';
import Icon from 'react-components/src/icons';
import styles from './ActionButton.module.css';

export const ActionButton = ({
  icon,
  size,
  stylesIconClassName = '',
  ...props
}) => (
  <button type="button" className={styles.btn} {...props}>
    <span className={stylesIconClassName}>
      <Icon icon={icon} size={size} />
    </span>
  </button>
);
