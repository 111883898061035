import React from 'react';
import cn from 'classnames';
import { Typography } from '@components';
import Cell from '../Cell/Cell';
import styles from './LabelCell.module.css';

const LabelCell = ({ className, children }) => (
  <Cell className={cn(styles.cell, className)}>
    <Typography variant="body1Reg" className={styles.text} title={children}>
      {children}
    </Typography>
  </Cell>
);

export default LabelCell;
