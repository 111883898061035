import React from 'react';
import Label from './Label';
import Container from './Container';
import Background from './Background';
import MediaContent from './MediaContent';

const MemberCard = ({
  member: {
    type,
    peerId,
    stream,
    label,
    avatarSrc,
    muted = false,
    isMicrophoneMuted = muted,
    isCameraMuted = false
  }
}) => (
  <Container id={peerId} stream={stream}>
    <Background show={isCameraMuted} avatar={avatarSrc} />
    <Label text={label} />
    <MediaContent
      id={peerId}
      type={type}
      stream={stream}
      isMicrophoneMuted={isMicrophoneMuted}
      isCameraMuted={isCameraMuted}
    />
  </Container>
);

export default React.memo(MemberCard);
