import React, { Component } from 'react';
import PropTypes from 'prop-types';
import block from 'bem-cn-lite';
import { translate, Interpolate } from 'react-i18next';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  Modal,
  Col,
  Row,
  Form,
  FormGroup,
  FormControl,
  ControlLabel,
  Button,
  HelpBlock
} from 'react-bootstrap';
import { withRouter } from '../../hoc/withRouter';

import { ModalContainer } from './elements';

import './styles/modal_theme_default.styl';

import { validateEmail } from '../../utils/validateField';
import getBrowserLanguage from '../../utils/languageDetector';

import * as modalActions from '../../action-creators/modal';
import * as serviceActions from '../../action-creators/services';
import * as orderActions from '../../action-creators/orders';
import * as userActions from '../../action-creators/user';

const b = block('modal-container');

class ModalSendOrderReg extends Component {
  constructor(props) {
    super(props);
    this.inputProps = this.inputProps.bind(this);
    this.openModalOrderLogin = this.openModalOrderLogin.bind(this);
    this.state = {
      pristine: true,
      companyNamePristine: true,
      namePristine: true,
      passwordPristine: true,
      emailPristine: true,
      repeatPasswordPristine: true,
      name: '',
      companyName: '',
      password: '',
      passwordRepeat: '',
      email: '',
      acceptCode: '',
      lang: getBrowserLanguage(),
      codeSent: false,
      codeAccepted: false,
      orderId: '',
      error: ''
    };
  }

  getChildContext() {
    return { b };
  }

  getErrorInfo(fieldName) {
    return this.props.errors.get(fieldName);
  }

  isValidFieldName() {
    return this.state.name.length < 1;
  }

  isValidFieldCompanyName() {
    return this.state.companyName.length < 1;
  }

  asterisk() {
    // eslint-disable-line
    return <span style={{ color: 'red' }}>*</span>;
  }

  inputProps(field) {
    const {
      verifyCode,
      priceId,
      token,
      items,
      priceCategoryConditions,
      getCurrentUser
    } = this.props;
    return {
      value: this.state[field],
      onChange: async (i) => {
        if (this.getErrorInfo(field)) {
          this.props.removeErrorByField(field);
        }
        if (
          field === 'acceptCode' &&
          i.target.value.length === 6 &&
          i.target.value !== this.state.acceptCode
        ) {
          await this.setState({ acceptCode: i.target.value, codeSent: true });
          const { email, acceptCode, companyName, name, password } = this.state;
          const resp = await verifyCode(
            email,
            acceptCode,
            priceId,
            token,
            items,
            priceCategoryConditions,
            companyName,
            name,
            password
          );
          if (resp.ok) {
            await this.setState({
              codeAccepted: true,
              orderId: resp.orderId,
              error: ''
            });
            await getCurrentUser(true);
          } else if (resp.error) {
            this.setState({ error: resp.error });
          }
          return undefined;
        }
        if (field === 'email') {
          return this.setState({ [field]: i.target.value.trim() });
        }
        return this.setState({ [field]: i.target.value });
      }
    };
  }

  verifyEmail(email) {
    if (email.length) {
      return validateEmail(email);
    }
    return true;
  }

  showEmailError() {
    const { t } = this.props;
    if (this.state.error && this.state.error.type === 'email') {
      return (
        <HelpBlock id="emailErrorMessage" style={{ color: 'red' }}>
          {t(this.state.error.message)}
        </HelpBlock>
      );
    }
    if (!this.verifyEmail(this.state.email)) {
      return (
        <HelpBlock id="emailErrorMessage" style={{ color: 'red' }}>
          {t('Wrong email format')}
        </HelpBlock>
      );
    }
    return null;
  }

  async sendOrder() {
    const {
      submitOrderToSupplier,
      splitOrder = false,
      history,
      hideModalDialog,
      deliveryDate,
      combineOrdersStocks
    } = this.props;
    await submitOrderToSupplier(
      this.state.orderId,
      '',
      splitOrder,
      combineOrdersStocks,
      deliveryDate
    );
    hideModalDialog();
    history.push('/orders-company');
  }

  canSandCode() {
    const { name, companyName, password, passwordRepeat, email } = this.state;
    return (
      name.length > 0 &&
      companyName.length > 0 &&
      password.length > 0 &&
      passwordRepeat.length > 0 &&
      password === passwordRepeat &&
      email.length > 0 &&
      this.verifyEmail(email)
    );
  }

  openModalOrderLogin() {
    const {
      showModal,
      match: {
        params: { priceId, token, splitOrder }
      },
      deliveryDate
    } = this.props;
    showModal('SEND_ORDER_LOGIN', { priceId, token, splitOrder, deliveryDate });
  }

  render() {
    const {
      t,
      hideModalDialog,
      sendCode,
      priceId,
      token,
      resendCode,
      language
    } = this.props;
    return (
      <ModalContainer mods={{ theme: 'default' }}>
        <Modal id="createOrderAndReg" show>
          <Modal.Header>
            <Modal.Title>
              {t('Send order')}
              <span
                className="modal-header-cross pull-right"
                onClick={() => hideModalDialog()}
              />
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div style={{ marginTop: 10, fontSize: 12 }}>
              <Interpolate
                i18nKey="sendOrderText"
                login={
                  <b>
                    <a
                      onClick={() => {
                        hideModalDialog();
                        this.openModalOrderLogin();
                      }}>
                      {t('Login').toLowerCase()}
                    </a>
                  </b>
                }
              />
            </div>
            <Form>
              <Row>
                <Col md={6}>
                  <FormGroup
                    controlId="companyName"
                    validationState={
                      this.isValidFieldCompanyName() &&
                      !this.state.companyNamePristine
                        ? 'error'
                        : null
                    }
                    style={{ overflow: 'hidden' }}>
                    <ControlLabel>
                      {`${t('Company name')} `}
                      {this.asterisk()}
                    </ControlLabel>
                    <h6 className="pull-right" style={{ marginTop: 5 }}>
                      <small>
                        {this.state.companyName.length}
                        /60
                      </small>
                    </h6>
                    <FormControl
                      type="text"
                      {...this.inputProps('companyName')}
                      maxLength="60"
                      disabled={this.state.codeAccepted}
                      onKeyDown={() => {
                        if (this.state.companyNamePristine) {
                          this.setState({ companyNamePristine: false });
                        }
                      }}
                    />
                    {this.isValidFieldCompanyName() &&
                      !this.state.companyNamePristine && (
                        <HelpBlock className="pull-left">
                          {t('Required fields empty')}
                        </HelpBlock>
                      )}
                  </FormGroup>
                </Col>
                <Col md={6}>
                  <FormGroup
                    controlId="name"
                    validationState={
                      this.isValidFieldName() && !this.state.namePristine
                        ? 'error'
                        : null
                    }
                    style={{ overflow: 'hidden' }}>
                    <ControlLabel>
                      {`${t('First Name')} `}
                      {this.asterisk()}
                    </ControlLabel>
                    <h6 className="pull-right" style={{ marginTop: 5 }}>
                      <small>
                        {this.state.name.length}
                        /60
                      </small>
                    </h6>
                    <FormControl
                      type="text"
                      {...this.inputProps('name')}
                      maxLength="60"
                      disabled={this.state.codeAccepted}
                      onKeyDown={() => {
                        if (this.state.namePristine) {
                          this.setState({ namePristine: false });
                        }
                      }}
                    />
                    {this.isValidFieldName() && !this.state.namePristine && (
                      <HelpBlock className="pull-left">
                        {t('Required fields empty')}
                      </HelpBlock>
                    )}
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  <FormGroup
                    controlId="password"
                    validationState={
                      !this.state.password.length &&
                      !this.state.passwordPristine
                        ? 'error'
                        : null
                    }
                    style={{ overflow: 'hidden' }}>
                    <ControlLabel>
                      {`${t('Password')} `}
                      {this.asterisk()}
                    </ControlLabel>
                    <FormControl
                      type="password"
                      {...this.inputProps('password')}
                      minLength="8"
                      placeholder={t('Min length 8 symbols')}
                      autoComplete="off"
                      disabled={this.state.codeAccepted}
                      onKeyDown={() => {
                        if (this.state.passwordPristine) {
                          this.setState({ passwordPristine: false });
                        }
                      }}
                    />
                    {!this.state.password && !this.state.passwordPristine && (
                      <HelpBlock className="pull-left">
                        {t('Required fields empty')}
                      </HelpBlock>
                    )}
                  </FormGroup>
                </Col>
                <Col md={6}>
                  <FormGroup
                    controlId="passwordRepeat"
                    style={{ overflow: 'hidden' }}>
                    <ControlLabel>
                      {`${t('Password repeat')} `}
                      {this.asterisk()}
                    </ControlLabel>
                    <FormControl
                      type="password"
                      {...this.inputProps('passwordRepeat')}
                      minLength="8"
                      autoComplete="off"
                      disabled={this.state.codeAccepted}
                      onKeyDown={() => {
                        if (this.state.repeatPasswordPristine) {
                          this.setState({ repeatPasswordPristine: false });
                        }
                      }}
                    />
                    {this.state.password &&
                      !this.state.passwordRepeat &&
                      !this.state.repeatPasswordPristine && (
                        <HelpBlock className="pull-left">
                          {t('Required fields empty')}
                        </HelpBlock>
                      )}
                    {this.state.password &&
                      this.state.passwordRepeat &&
                      this.state.password !== this.state.passwordRepeat && (
                        <HelpBlock className="pull-left">
                          {t('Passwords not match')}
                        </HelpBlock>
                      )}
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col md={12}>
                  <FormGroup
                    controlId="email"
                    validationState={
                      this.state.email.length
                        ? this.showEmailError()
                          ? 'error'
                          : null
                        : null
                    } // eslint-disable-line
                    style={{ overflow: 'hidden' }}>
                    <ControlLabel>
                      {`${t('Email')} `}
                      {this.asterisk()}
                    </ControlLabel>
                    <FormControl
                      type="text"
                      {...this.inputProps('email')}
                      maxLength="60"
                      disabled={this.state.codeAccepted}
                      onKeyDown={() => {
                        if (this.state.emailPristine) {
                          this.setState({ emailPristine: false });
                        }
                        if (
                          this.state.error &&
                          this.state.error.type === 'email'
                        ) {
                          this.setState({ error: '' });
                        }
                      }}
                    />
                    {this.showEmailError()}
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  <FormGroup
                    controlId="acceptCode"
                    validationState={
                      this.state.error && this.state.error.type === 'code'
                        ? 'error'
                        : null
                    }
                    style={{ overflow: 'hidden' }}>
                    <ControlLabel>
                      {`${t('Accept code')} `}
                      {this.asterisk()}
                    </ControlLabel>
                    <FormControl
                      type="text"
                      {...this.inputProps('acceptCode')}
                      maxLength="6"
                      disabled={
                        this.state.codeAccepted ||
                        !this.canSandCode() ||
                        !this.state.codeSent
                      }
                    />
                    {this.state.error && this.state.error.type === 'code' && (
                      <HelpBlock className="pull-left">
                        {t(this.state.error.message)}
                      </HelpBlock>
                    )}
                  </FormGroup>
                </Col>
                <Col md={6}>
                  <div style={{ marginTop: 32, marginLeft: 20 }}>
                    {this.canSandCode() && !this.state.codeAccepted && (
                      <a
                        style={{
                          textDecoration: 'none',
                          color: '#1c50de'
                        }}
                        onClick={async (e) => {
                          e.preventDefault();
                          this.setState({ codeSent: true, error: '' });
                          if (this.state.codeSent) {
                            resendCode(this.state.email, language);
                          } else {
                            const res = await sendCode(
                              Object.assign(this.state, {
                                priceId,
                                accessToken: token
                              })
                            );
                            if (res.error) {
                              this.setState({
                                codeSent: false,
                                error: res.error
                              });
                            }
                          }
                        }}>
                        {this.state.codeSent
                          ? t('Resend code')
                          : t('Send code')}
                      </a>
                    )}
                    {this.state.codeAccepted && (
                      <span style={{ color: '#219653' }}>
                        {t('Code accepted')}
                      </span>
                    )}
                  </div>
                </Col>
              </Row>
            </Form>
            {this.asterisk()}
            {` ${t('Required fields')}`}
            <div style={{ marginTop: 10, fontSize: 12 }}>
              <Interpolate
                i18nKey="sendOrderAgreement"
                style={{ whiteSpace: 'initial' }}
                userAgreement={
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://goodwix.com/soglashenie/"
                    style={{ display: 'inline-block' }}>
                    {t('terms of condition')}
                  </a>
                }
                confidentialPolitic={
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://goodwix.com/politika-konfidentsialnosti/"
                    style={{ display: 'inline-block' }}>
                    {t('terms of confidential')}
                  </a>
                }
              />
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              id="cancel"
              className="pull-left"
              onClick={() => {
                hideModalDialog();
              }}>
              {t('Cancel')}
            </Button>
            <Button
              id="saveCharacteristicButton"
              bsStyle="primary"
              disabled={!this.state.codeAccepted}
              onClick={() => this.sendOrder()}>
              {t('Send order')}
            </Button>
          </Modal.Footer>
        </Modal>
      </ModalContainer>
    );
  }
}

ModalSendOrderReg.propTypes = {
  t: PropTypes.func.isRequired
};

ModalSendOrderReg.childContextTypes = {
  b: PropTypes.func
};

export default withRouter(
  connect(
    (state) => ({
      price: state.getIn(['publicPrices', 'price']),
      items: state.getIn(['ordersCombine', 'orderDraft', 'items']),
      priceCategoryConditions: state.getIn([
        'ordersCombine',
        'orderDraft',
        'priceCategoryConditions'
      ]),
      combineOrdersStocks: state.getIn([
        'ordersCombine',
        'orderDraft',
        'combineOrdersStocks'
      ]),
      errors: state.getIn(['orders', 'errors'])
    }),
    (dispatch) =>
      bindActionCreators(
        {
          showModal: modalActions.showModal,
          hideModalDialog: modalActions.hideModalDialog,
          sendCode: serviceActions.sendCode,
          resendCode: serviceActions.resendCode,
          verifyCode: serviceActions.verifyCode,
          getCurrentUser: userActions.getCurrentUser,
          submitOrderToSupplier: orderActions.submitOrderToSupplier
        },
        dispatch
      )
  )(translate(['ui'], { wait: true })(ModalSendOrderReg))
);
