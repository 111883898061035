import { pipe } from 'ramda';
import orders from './orders';
import company from './company';
import user from './user';
import priceSuppliersTable from './priceSuppliersTable';
import ordersToSuppliers from './ordersToSuppliers';
import permissions from './permissions';
import chat from './chat';
import orderDraft from './orderDraft';
import publicPrices from './publicPrices';
import prices from './prices';
import stocks from './stocks';
import suppliers from './suppliers';

export const section = (state) => ({
  company: company(state),
  user: user(state),
  chat: chat(state),
  permissions: permissions(state),

  orders: orders(state),
  orderDraft: orderDraft(state),

  ordersToSuppliers: ordersToSuppliers(state),

  prices: prices(state),
  publicPrices: publicPrices(state),

  priceSuppliersTable: priceSuppliersTable(state),

  stocks: stocks(state),

  suppliers: suppliers(state),

  badges: state.get('badges'),

  errors: state.getIn(['errors', 'orders'])
});

export default (callback) => pipe(section, callback);
