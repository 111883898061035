import React, { useEffect, useState } from 'react';
import { __ } from 'ramda';
import { useTranslate } from '@hooks';
import { isValid, isPast, isToday } from 'date-fns';
import { useNavigate } from 'react-router-dom';
import { filesLoadHandler } from '../../../useCases/files';
import MainRespondButton from './MainRespondButton';
import FormRespondView from './FormRespondView';
import {
  useListLinks,
  addLink,
  totalLinks,
  getLinks,
  clearLinks,
  isEqualLinks,
  isNoLinks
} from './links';
import {
  useListFiles,
  clearFiles,
  addFile,
  totalFiles,
  getFiles,
  isEqualFiles,
  isNoFiles
} from './files';
import {
  useFormMode,
  updateMode,
  toShowEditForm,
  toInviteToEdit,
  isNoneMode,
  isInviteToEdit,
  isShownEditForm,
  NONE,
  INVITE_TO_EDIT,
  SHOWN_EDIT_FORM
} from './formMode';
import {
  makeRespondLS,
  getRespondText,
  setRespondText,
  clearRespond,
  isEmptyRespond
} from './respondLS';
import { convertLinksToVM } from './linksVM';
import { convertFilesToVM } from './filesVM';
import {
  getStatusForSupplier,
  isStatusDraft,
  isCanRespond,
  hasResponded,
  getResponseId,
  isFromThatCompany,
  getRespondTextByRequest,
  getLinksByRequest,
  getFilesByRequest,
  getProductsByRequest,
  getRespondComment,
  getInitProductsByRequest,
  isShowControlButtons
} from './requestMethods';
import {
  useEditorText,
  isReadMode,
  isEditMode,
  getTextET,
  isEmptyTextET,
  updateText,
  clearText,
  updateETMode,
  EDIT,
  READ,
  toReadMode,
  toEditMode
} from './editorText';
import {
  useCustomEditorMode,
  isModeNoSelected,
  isModeEditor,
  isModeViewer,
  toEditor,
  toViewer
} from './customEditorMode';
import { getColor } from './respondColor';
import './RequestStyle.css';
import {
  getProducts,
  isEqualProducts,
  isProductEmpty,
  isProductValid,
  onChangeProduct,
  onChangeProductImport,
  onFocusPrice,
  useListProducts
} from './products';
import {
  rejected,
  rework
} from '../../../presenters/sidebars/PurchaseRequestResponsesSidebar/status';
import { purchaseRequestService } from '../../../api';
import { noop } from '../../../utils/utils';

const determineEditorMode = (
  request,
  hookEditorText,
  hookCEM,
  respondLS,
  isResponsePage
) => {
  if (
    !(isResponsePage && isCanRespond(request)) &&
    (!isModeViewer(hookCEM) || isEmptyRespond(respondLS))
  )
    return READ;
  if (
    isResponsePage ||
    (isEmptyTextET(hookEditorText) && isModeNoSelected(hookCEM)) ||
    isModeNoSelected(hookCEM) ||
    isModeEditor(hookCEM)
  )
    return EDIT;
  throw new Error('No such case');
};

const determineFormMode = ({
  request,
  company,
  hookEditorText,
  isResponsePage
}) => {
  if (isFromThatCompany(request, company)) return NONE;
  if (isResponsePage || !isEmptyTextET(hookEditorText) || hasResponded(request))
    return SHOWN_EDIT_FORM;
  return INVITE_TO_EDIT;
};

const RespondView = ({
  formView,
  mainRespondButton,
  collapse,
  dragAndDrop,
  form
}) => {
  if (formView.isNoneMode) return null;

  if (formView.isInviteToEdit) {
    return <MainRespondButton onClick={mainRespondButton.onClick} />;
  }

  if (formView.isShownEditForm) {
    return (
      <FormRespondView
        collapse={collapse}
        form={form}
        dragAndDrop={dragAndDrop}
      />
    );
  }

  throw new Error(`No such case`);
};

const getTextForEditorText = (request, respondLS) => {
  if (isEmptyRespond(respondLS)) return getRespondTextByRequest(request);
  return getRespondText(respondLS);
};

const showModalAddLink = (showModalExec, onSubmit) =>
  showModalExec('ADD_LINK', { onSubmit });

const initOnDeleteResponse =
  ({
    goToRequestPage,
    request,
    toExpand = noop,
    deleteResponse,
    respondLS,
    hookEditorText,
    hookLinks,
    hookFiles,
    hookProducts,
    hookFormMode,
    showModal
  }) =>
  () => {
    if (isStatusDraft(request)) {
      showModal('SIMPLE_SUBMIT', {
        title: 'respondForm.modals.delete.title',
        text: 'respondForm.modals.delete.text',
        textBtnConfirm: 'respondForm.modals.delete.confirm',
        submitAction: () => {
          deleteResponse({ responseId: getResponseId(request) });

          clearText(hookEditorText);
          clearLinks(hookLinks);
          clearRespond(respondLS);
          clearFiles(hookFiles);
          hookProducts.setState((prevState) => ({
            ...prevState,
            items: prevState.items.map((el) => ({
              ...el,
              count: '',
              price: ''
            }))
          }));
          toInviteToEdit(hookFormMode);
          toExpand();
          goToRequestPage();
        }
      });
    } else {
      clearText(hookEditorText);
      clearLinks(hookLinks);
      clearRespond(respondLS);
      clearFiles(hookFiles);
      hookProducts.setState((prevState) => ({
        ...prevState,
        items: prevState.items.map((el) => ({ ...el, count: '', price: '' }))
      }));
      toInviteToEdit(hookFormMode);
      toExpand();
      goToRequestPage();
    }
  };

const initOnSaveRespond =
  ({
    requestId,
    saveDraftRespond,
    respondLS,
    hookEditorText,
    hookLinks,
    hookFiles,
    hookProducts,
    extraOptions
  }) =>
  () => {
    saveDraftRespond({
      requestId,
      text: getTextET(hookEditorText),
      links: getLinks(hookLinks),
      products: getProducts(hookProducts),
      files: getFiles(hookFiles),
      deliveryDate: extraOptions.deadline,
      deliveryCost: extraOptions.delivery,
      prepayment: extraOptions.payment
    }).then(() => {
      clearRespond(respondLS);
      toReadMode(hookEditorText);
    });
  };

const initOnSendRespond =
  ({
    user,
    requestId,
    gaSend,
    sendRespondToRequest,
    respondLS,
    hookEditorText,
    hookLinks,
    hookProducts,
    hookFiles,
    showModal,
    extraOptions
  }) =>
  () => {
    showModal('SIMPLE_SUBMIT', {
      title: 'respondForm.modals.send.title',
      text: 'respondForm.modals.send.text',
      submitAction: () => {
        gaSend({
          category: 'Common',
          action: 'respond_request',
          label: user.get('email')
        });
        sendRespondToRequest({
          requestId,
          text: getTextET(hookEditorText),
          links: getLinks(hookLinks),
          products: getProducts(hookProducts),
          files: getFiles(hookFiles),
          deliveryDate: extraOptions.deadline,
          deliveryCost: extraOptions.delivery,
          prepayment: extraOptions.payment
        }).then(() => {
          clearRespond(respondLS);
          toReadMode(hookEditorText);
        });
      }
    });
  };

const haveChanges = ({ request, hookLinks, hookFiles, hookProducts }) => {
  const linksFromRequest = getLinksByRequest(request);
  const filesFromRequest = getFilesByRequest(request);
  const productsFromRequest = getProductsByRequest(request);
  return (
    !isEqualLinks(linksFromRequest, hookLinks) ||
    !isEqualFiles(filesFromRequest, hookFiles) ||
    !isEqualProducts(productsFromRequest, hookProducts)
  );
};

const RespondMain = ({
  request,
  company,
  sendRespondToRequest,
  saveDraftRespond,
  deleteResponse,
  uploadFile,
  removeFileLinkBySection,
  setInfoMessage,
  setErrorMessage,
  gaSend,
  user,
  requestId,
  showModal,
  toCollapse,
  isResponsePage,
  toExpand,
  havePermissionToUpdate
}) => {
  const t = useTranslate();
  const navigate = useNavigate();
  const respondLS = makeRespondLS(user, requestId);
  const hookCEM = useCustomEditorMode();
  const hookFormMode = useFormMode();
  const hookEditorText = useEditorText({
    text: getTextForEditorText(request, respondLS),
    placeholder: t('respondForm.form.editor.placeholder'),
    mode: EDIT
  });
  const hookLinks = useListLinks(getLinksByRequest(request));
  const hookFiles = useListFiles(getFilesByRequest(request));
  const hookProducts = useListProducts(getProductsByRequest(request));

  const preparedRequest = request?.toJS();

  const [extraOptions, setExtraOptions] = useState({
    delivery: preparedRequest?.responded?.deliveryCost,
    deadline: preparedRequest?.responded?.deliveryDate
      ? new Date(preparedRequest?.responded?.deliveryDate)
      : null,
    payment: preparedRequest?.responded?.prepayment
  });

  useEffect(() => {
    updateETMode(
      determineEditorMode(
        request,
        hookEditorText,
        hookCEM,
        respondLS,
        isResponsePage
      ),
      hookEditorText
    );
  }, []);

  useEffect(() => {
    const paramsForDetermineFormMode = {
      request,
      company,
      hookEditorText,
      isResponsePage
    };
    const nextMode = determineFormMode(paramsForDetermineFormMode);
    updateMode(nextMode, hookFormMode);
  }, []);

  const formView = {
    isNoneMode:
      isNoneMode(hookFormMode) ||
      (isInviteToEdit(hookFormMode) &&
        !isShowControlButtons(request, havePermissionToUpdate)),
    isInviteToEdit: isInviteToEdit(hookFormMode),
    isShownEditForm: isShownEditForm(hookFormMode)
  };

  const mainRespondButton = {
    onClick: () => {
      toCollapse();
      toEditMode(hookEditorText);
      toShowEditForm(hookFormMode);
    }
  };

  const onDeleteResponse = initOnDeleteResponse({
    goToRequestPage: () => navigate(`/requests/all/${preparedRequest?.id}`),
    request,
    deleteResponse,
    toExpand,
    respondLS,
    hookEditorText,
    hookLinks,
    hookFiles,
    hookProducts,
    hookFormMode,
    showModal
  });

  const onSaveRespond = initOnSaveRespond({
    requestId,
    saveDraftRespond,
    respondLS,
    hookEditorText,
    hookLinks,
    hookFiles,
    hookProducts,
    hookCEM,
    extraOptions
  });

  const onSendRespond = initOnSendRespond({
    user,
    requestId,
    gaSend,
    sendRespondToRequest,
    respondLS,
    hookEditorText,
    hookLinks,
    hookProducts,
    hookFiles,
    showModal,
    extraOptions
  });

  const maxFiles = 10;
  const disableLoadFiles = totalFiles(hookFiles) === maxFiles;

  function checkValidDeliveryDate() {
    const { deadline } = extraOptions;

    if (!deadline) return true;

    if (!isValid(deadline)) return false;

    return !isPast(deadline) || isToday(deadline);
  }

  const isDeliveryDateValid = checkValidDeliveryDate();

  const isProductsEmpty = isProductEmpty(hookProducts)
    ? isEmptyTextET(hookEditorText)
    : !isProductValid(hookProducts, request.get('partResponse'));

  const onAttachFiles = async (data) => {
    const files = Array.from(data).map((rawFile) => ({ file: rawFile }));

    await filesLoadHandler({
      files,
      attachedFiles: getFiles(hookFiles),
      maxFiles,
      loadFile: async ({ file }) => {
        const uploadedFile = await uploadFile(file);
        addFile(uploadedFile, hookFiles);
      },
      beforeLoading: () => setInfoMessage({ key: 'File upload started' }),
      onError: (key, params) => setErrorMessage({ key, params }),
      onLoaded: () => {}
    });
  };
  const onImportRequestClick = () => {
    showModal('MODAL_IMPORT_RESPONSE', {
      onChangeProductImport: onChangeProductImport(hookProducts),
      requestId,
      onExportToExcel: async () =>
        purchaseRequestService.exportRequestToExcel(requestId)
    });
  };

  const form = {
    titleColor: 'black',
    respondStatus: {
      show: true,
      figureColor: getColor(getStatusForSupplier(request)),
      statusName: getStatusForSupplier(request)
    },
    comment: {
      show:
        [rejected, rework].includes(getStatusForSupplier(request)) &&
        getRespondComment(request),
      text: getRespondComment(request),
      title: t(
        getStatusForSupplier(request) === rejected
          ? 'reject_reason'
          : 'response_sent_to_rework'
      )
    },

    importButton: {
      show: isEditMode(hookEditorText) && !!getProducts(hookProducts).length,
      text: t('respondForm.form.importButton'),
      onClick: onImportRequestClick,
      iconProps: {
        iconName: 'excel',
        width: 18,
        height: 18
      }
    },

    editorText: {
      text: {
        show: isReadMode(hookEditorText),
        html: getTextET(hookEditorText),
        isClickable: false
      },
      editor: {
        show: isEditMode(hookEditorText),
        text: getTextET(hookEditorText),
        onChange: (e) => {
          const { value } = e.target;
          setRespondText(value, respondLS);
          updateText(value, hookEditorText);
        },
        btnUndo: {
          onClick: () => {
            updateText(getRespondTextByRequest(request), hookEditorText);
            clearRespond(respondLS);
          },
          disabled: isEmptyRespond(respondLS)
        }
      },
      buttons: {
        show: false,
        lookBtn: {
          onClick: () => {
            if (isEditMode(hookEditorText)) {
              toViewer(hookCEM);
            } else {
              toEditor(hookCEM);
            }
          },
          disabled: isEmptyTextET(hookEditorText),
          tkeyText: isEditMode(hookEditorText)
            ? 'respondForm.form.editor.btn-preview'
            : 'respondForm.form.editor.btn-edit'
        }
      }
    },
    productsList: {
      products: getProducts(hookProducts),
      defaultProducts: isEditMode(hookEditorText)
        ? getInitProductsByRequest(request)
        : [],
      editMode: isEditMode(hookEditorText),
      onChange: onChangeProduct(hookProducts),
      onFocusPrice: onFocusPrice(
        hookProducts,
        getInitProductsByRequest(request)
      )
    },
    fileList: {
      show: isEditMode(hookEditorText) || !isNoLinks(hookFiles),
      total: totalFiles(hookFiles),
      files: convertFilesToVM({
        hookFiles,
        removeFileLinkBySection,
        showModal,
        showEditButtons: isEditMode(hookEditorText) && isCanRespond(request)
      }),
      showAddBtn:
        isEditMode(hookEditorText) &&
        !disableLoadFiles &&
        isCanRespond(request),
      onClickAdd: onAttachFiles
    },
    linksList: {
      show: isEditMode(hookEditorText) || !isNoFiles(hookLinks),
      total: totalLinks(hookLinks),
      links: convertLinksToVM(request, hookLinks),
      showAddBtn: isEditMode(hookEditorText) && isCanRespond(request),
      onClickAdd: () => showModalAddLink(showModal, addLink(__, hookLinks))
    },
    buttons: {
      show: isShowControlButtons(request, havePermissionToUpdate),
      deleteBtn: {
        show: isCanRespond(request),
        onClick: onDeleteResponse,
        text: isStatusDraft(request)
          ? t('respondForm.form.bottomButtons.delete')
          : t('respondForm.form.bottomButtons.cancel'),
        disabled: false
      },
      saveBtn: {
        show: isCanRespond(request) && havePermissionToUpdate,
        onClick: isReadMode(hookEditorText)
          ? () => toEditMode(hookEditorText)
          : onSaveRespond,
        text: (() => {
          if (isReadMode(hookEditorText)) {
            return t('respondForm.form.bottomButtons.edit');
          }
          if (
            !haveChanges({ request, hookLinks, hookFiles, hookProducts }) &&
            isEmptyRespond(respondLS)
          ) {
            return t('respondForm.form.bottomButtons.saved');
          }
          return t('respondForm.form.bottomButtons.save');
        })(),
        disabled:
          !isReadMode(hookEditorText) &&
          (isProductsEmpty ||
            (!haveChanges({ request, hookLinks, hookFiles, hookProducts }) &&
              isEmptyRespond(respondLS)) ||
            !isCanRespond(request) ||
            !isDeliveryDateValid)
      },
      sendBtn: {
        show: havePermissionToUpdate,
        onClick: onSendRespond,
        text: (() => {
          if (!isCanRespond(request)) {
            return t('respondForm.form.bottomButtons.you-responded');
          }
          if (getStatusForSupplier(request) === rework) {
            return t('respondForm.form.bottomButtons.resend');
          }
          return t('respondForm.form.bottomButtons.send');
        })(),
        disabled:
          !isCanRespond(request) || isProductsEmpty || !isDeliveryDateValid
      }
    },
    notice: {
      show: false
    },
    extraOptions: {
      mode: isEditMode(hookEditorText) ? 'edit' : 'view',
      options: extraOptions,
      setter: setExtraOptions
    }
  };

  const dragAndDrop = {
    tkeyText: 'respondForm.dnd.main-text',
    tkeySubText: 'respondForm.dnd.sub-text',
    onDrop: onAttachFiles,
    disabled: disableLoadFiles || !isCanRespond(request)
  };

  return (
    <RespondView
      form={form}
      formView={formView}
      mainRespondButton={mainRespondButton}
      dragAndDrop={dragAndDrop}
    />
  );
};

export default RespondMain;
