import { Map, List, Set, fromJS } from 'immutable'; // eslint-disable-line
import getBrowserLanguage from '../utils/languageDetector';
import { consFoundProducts } from './prices/foundProducts';

export const setSelectedPrice = (state) => state; // NOTE: not be here

const newItems = () => new List();
const newSelect = () => new Set();
const newFormCreate = () =>
  new Map({
    name: '',
    language: getBrowserLanguage(),
    vat: '',
    stocks: List(),
    description: '',
    currency: '',
    additionAttributes: Map(),
    isUploaded: false,
    rate: '',
    autoConfirm: false,
    additionalCategories: List(),
    positionsCategories: List()
  });
const newMoreInfo = () =>
  new Map({
    languages: new List(),
    code: '',
    stocks: new List(),
    langCurrency: new Map(),
    isPublished: new List()
  });
const newErrorsInfo = () => new Map();
const newPriceCategories = () => new List();
const newSelectedCategory = () => new Map();
const newSelectedCategories = () => new Set();
const newPriceCategory = () =>
  new Map({
    name: '',
    discount: 0,
    minOrder: 0,
    from: '',
    to: '',
    unit: '',
    roundUp: false
  });

export const initState = () =>
  new Map({
    items: newItems(),
    price: newFormCreate(),
    firstUploaded: false,
    selected: newSelect(),
    listOfBaseCost: new Map(),
    formCreate: newFormCreate(),
    moreInfo: newMoreInfo(),
    errors: newErrorsInfo(),
    priceCategories: newPriceCategories(),
    selectedCategory: newSelectedCategory(),
    selectedCategories: newSelectedCategories(),
    newPriceCategory: newPriceCategory(),
    foundProducts: consFoundProducts(),
    accessGroups: new List(),
    accessList: new List(),
    invites: new List(),
    isPublished: '',
    customersNotified: '',
    statuses: new Map(),
    selectedGroups: new Set(),
    attrsFilter: List(),
    complexAttrsFilter: List(),
    categoriesFilter: List(),
    stocks: List()
  });

export const setIsPublished = (data, { isPublished }) =>
  data.set('isPublished', isPublished);

export const setFoundProducts = (data, rest) =>
  data.set('foundProducts', consFoundProducts(data, rest));

export const selectFoundProducts = (data, { productId }) => {
  const isSelected = data.getIn([
    'foundProducts',
    'products',
    productId,
    'isSelected'
  ]);
  return data
    .setIn(['foundProducts', 'products', productId, 'isSelected'], !isSelected)
    .updateIn(['foundProducts', 'selected'], (selected) =>
      selected.includes(productId)
        ? selected.remove(productId)
        : selected.add(productId)
    );
};

export const selectProducts = (data, { ids }) =>
  data.updateIn(['foundProducts', 'selected'], (selected) =>
    selected.union(Set(ids))
  );
export const unselectProducts = (data, { ids }) =>
  data.updateIn(['foundProducts', 'selected'], (selected) =>
    selected.subtract(Set(ids))
  );

export const toggleFilterSelected = (data) =>
  data.setIn(
    ['foundProducts', 'isEnabledFilterSelected'],
    !data.getIn(['foundProducts', 'isEnabledFilterSelected'])
  );

const setSelectProducts = (product, select = true) =>
  product.set('isSelected', select);

export const selectAllFoundProducts = (data) => {
  const foundProducts = data.getIn(['foundProducts', 'products']);
  const selectedProducts = foundProducts.filter((product) =>
    product.get('isSelected')
  );

  if (selectedProducts.size === foundProducts.size) {
    return data.updateIn(['foundProducts', 'products'], (products) =>
      products.map((product) => setSelectProducts(product, false))
    );
  }

  return data.updateIn(['foundProducts', 'products'], (products) =>
    products.map((product) => setSelectProducts(product))
  );
};

export const updateCostFoundProducts = (data, { productId, cost }) =>
  data.setIn(['listOfBaseCost', productId], Number(cost));

export const resetListOfBaseCost = (data, { publishedProductsId }) =>
  data.update('listOfBaseCost', (list) =>
    list.filter((value, key) => !publishedProductsId.has(key))
  );

export const clearSelected = (data) =>
  data.remove('selected').set('selected', newSelect());

export const deselectAll = (data) =>
  data
    .remove('selectedCategories')
    .set('selectedCategories', newSelectedCategories());

export const setSelectedCategory = (data, { item }) =>
  data.remove('selectedCategory').set('selectedCategory', item);

export const deselectCategory = (data) =>
  data
    .remove('selectedCategory')
    .set('selectedCategory', newSelectedCategory());

export const selectCategory = (data, { id }) =>
  // const priceCategory = data.get('priceCategories').find(pr => pr.get('id') === id);

  data.update('selectedCategories', newSelectedCategories(), (category) =>
    category.includes(id) ? category.remove(id) : category.add(id)
  );
// .set('selectedCategory', priceCategory || Map());

export const deletePrices = (data, { prices }) =>
  data
    .update('items', newItems(), (items) =>
      items.filter((item) => !prices.includes(item.get('id')))
    )
    .remove('selected')
    .set('selected', newSelect());

export const setAccessList = (
  data,
  { accessList, size, offset, limit, addedSize, invites }
) =>
  data
    .remove('accessList')
    .set('accessList', fromJS(accessList))
    .set('invites', fromJS(invites))
    .set('size', size)
    .set('offset', offset)
    .set('limit', limit)
    .set('addedSize', addedSize);

export const setPriceCategories = (data, { categories }) =>
  data.remove('priceCategories').set('priceCategories', fromJS(categories));

export const updateNewCategory = (data, { field, value }) =>
  data.updateIn(['newPriceCategory', field], () => value);

export const updatePriceCategory = (data, { field, value }) =>
  data.updateIn(['selectedCategory', field], () => value);

export const clearNewCategory = (data) =>
  data.remove('newPriceCategory').set('newPriceCategory', newPriceCategory());

export const addCategory = (data, category) =>
  data.update('priceCategories', newPriceCategories(), (priceCategories) =>
    priceCategories.push(new Map(category))
  );

export const updateCategories = (data, { categories }) =>
  data
    .update('priceCategories', newPriceCategories(), (priceCategories) =>
      priceCategories.filterNot((category) =>
        new List(categories).includes(category.get('id'))
      )
    )
    .remove('selectedCategories')
    .set('selectedCategories', newSelectedCategories());

export const updateField = (data, { field, value }) =>
  data.updateIn(['formCreate', field], () => value);

export const updateStocksField = (data, { index, value }) =>
  // const index = data.getIn(['formCreate', 'stocks']).findIndex(i => i === currentStockId);
  data.setIn(['formCreate', 'stocks', index], value);

export const updateAdditionAttributeField = (data, { field, value }) =>
  data.updateIn(['formCreate', 'additionAttributes', field], () => value);

export const setPrices = (data, { items }) =>
  data.remove('items').set('items', fromJS(items)).set('firstUploaded', true);

export const setDefaultPriceForm = (data) => {
  if (!data.getIn(['price', 'stocks'])) {
    return data
      .set('formCreate', data.get('price'))
      .setIn(['formCreate', 'isUploaded'], true)
      .setIn(['formCreate', 'stocks'], List());
  }
  return data
    .set('formCreate', data.get('price'))
    .setIn(['formCreate', 'isUploaded'], true);
};

export const setPrice = (data, { item }) => {
  let newData = setDefaultPriceForm(
    data.remove('price').set('price', fromJS(item))
  );
  if (newData.get('items').size) {
    const index = newData
      .get('items')
      .findIndex((i) => i.get('id') === item.id);
    newData = newData.setIn(['items', index], fromJS(item));
  }
  return newData;
};
export const setPriceFeatures = (
  data,
  { item: { additionProductsAttributes = null } = {} }
) => {
  if (additionProductsAttributes) {
    return updateField(data, {
      field: 'additionProductsAttributes',
      value: fromJS(additionProductsAttributes)
    });
  }
  return data;
};

export const setPriceFailure = (data) => setDefaultPriceForm(data);

export const clearAllPrices = () => initState();

export const clearForm = (data) => data.set('formCreate', newFormCreate());

export const setMoreInfo = (
  data,
  { languages, stocks, langCurrency, isPublished }
) => {
  stocks.unshift({ id: '', language: 'all' });
  const stock = data.getIn(['price', 'stock']);
  const newData = stock
    ? updateField(data, { field: 'stock', value: stock })
    : data;
  return newData
    .setIn(['moreInfo', 'languages'], fromJS(languages))
    .setIn(['moreInfo', 'stocks'], fromJS(stocks))
    .setIn(['moreInfo', 'langCurrency'], fromJS(langCurrency))
    .setIn(['moreInfo', 'isPublished'], fromJS(isPublished));
};

export const updateDefaultField = (
  data,
  { languages, stocks, langCurrency, isPublished }
) => {
  const newData = setMoreInfo(data, {
    languages,
    stocks,
    langCurrency,
    isPublished
  });
  const browserLang = getBrowserLanguage();
  const findLang = (codeLang) =>
    languages.find((item) => item.code === codeLang);
  return updateField(newData, {
    field: 'language',
    value: findLang(browserLang) ? browserLang : languages[0].code
  });
};

export const selectPrice = (data, { id }) =>
  data.update('selected', newSelect(), (selected) =>
    selected.includes(id) ? selected.remove(id) : selected.add(id)
  );

export const setPricesStatuses = (data, { statuses }) =>
  data.remove('statuses').set('statuses', fromJS(statuses));

export const setPriceStatus = (data) =>
  data.setIn(['price', 'status'], 'draft');

export const setPriceStatusPublished = (data) =>
  data.setIn(['price', 'status'], 'publish');

export const setStatusPublishing = (data, { priceId }) => {
  const index = data.get('items').findIndex((i) => i.get('id') === priceId);
  return data.setIn(['items', index, 'status'], 'publishing');
};

export const setPriceBaidgeStatus = (data, { priceId, status }) =>
  data.setIn(['statuses', priceId], status);

export const setPriceCustomersNotifiedStatus = (data, { status }) =>
  data.set('customersNotified', status);

export const addAdditionalCategory = (data, { category }) =>
  data.updateIn(['price', 'additionalCategories'], (additionalCategories) =>
    additionalCategories.push(fromJS(category))
  );

export const updateAdditionalCategory = (data, { updatedCategory }) =>
  data
    .updateIn(['price', 'additionalCategories'], (addAdditionalCategories) =>
      addAdditionalCategories.map((category) =>
        category.get('id') === updatedCategory.id
          ? fromJS(updatedCategory)
          : category
      )
    )
    .updateIn(['price', 'positionsCategories'], (positionsCategories) =>
      positionsCategories.map((category) =>
        category.get('type') === 'additional' &&
        category.get('categoryId') === updatedCategory.id
          ? fromJS(updatedCategory)
          : category
      )
    );

export const removeAdditionalCategory = (data, { id }) =>
  data
    .updateIn(['price', 'additionalCategories'], (additionalCategories) =>
      additionalCategories.filter((category) => category.get('id') !== id)
    )
    .updateIn(['price', 'positionsCategories'], (positionsCategories) =>
      positionsCategories.filter(
        (category) =>
          !(
            category.get('type') === 'additional' &&
            category.get('categoryId') === id
          )
      )
    );

export const addProductsToAdditionalCategory = (
  data,
  { products, categoryId }
) => {
  const categories = data.getIn(['price', 'additionalCategories']);
  const index = categories.findIndex(
    (category) => +category.get('id') === +categoryId
  );
  const prevProducts = data.getIn([
    'price',
    'additionalCategories',
    index,
    'products'
  ]);
  const newProducts = [...prevProducts, ...products];
  return data.setIn(
    ['price', 'additionalCategories', index, 'products'],
    newProducts
  );
};

export const removeProductsToAdditionalCategory = (
  data,
  { products, categoryId }
) => {
  const categories = data.getIn(['price', 'additionalCategories']);
  const index = categories.findIndex(
    (category) => +category.get('id') === +categoryId
  );
  const prevProducts = data.getIn([
    'price',
    'additionalCategories',
    index,
    'products'
  ]);
  const newProducts = prevProducts.filter(
    (productId) => !products.includes(productId)
  );
  return data.setIn(
    ['price', 'additionalCategories', index, 'products'],
    newProducts
  );
};

export const setPositionsCategories = (data, { positionsCategories }) =>
  data.setIn(['price', 'positionsCategories'], fromJS(positionsCategories));
