import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useActions, useThrottle } from '@hooks';
import * as getters from '../getters';
import * as storage from '../storage';

const THROTTLE_DELAY = 200;

export default function useSearchCompanies({
  search = '',
  includesGroups = false
} = {}) {
  const companiesList = useSelector(getters.getCompanies);

  const actions = useActions(storage);

  const getCompanies = useThrottle(actions.getCompanies, THROTTLE_DELAY);

  useEffect(() => {
    getCompanies(search, { includesGroups });
  }, [search]);

  return companiesList;
}
