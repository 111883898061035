import { useActions } from '@hooks';
import * as modalActions from '../../../../../action-creators/modal';
import * as useCases from '../../../../ChatWidget/useCases';
import * as notificationMessageActions from '../../../../../action-creators/message';
import * as clipboard from '../../../../../lib/clipboard';

const useShareDropdownHandler = (requestId) => {
  const { showModal, setSuccessMessage, hideModalDialog, setMsgToChannel } =
    useActions({ ...notificationMessageActions, ...modalActions, ...useCases });
  const link = `${process.env.UNICAT_MESSENGER_SERVICE}/share/requests/${requestId}`;

  function handler(command) {
    switch (command) {
      case 'send-to-chat':
        sendToChat();
        break;
      case 'ask-RFP-by-email':
        sendToEmail();
        break;
      case 'copy-link':
        copyLink();
        break;
      default:
        throw new Error(`No such handler for command: ${command}`);
    }
  }

  function sendToChat() {
    showModal('CHATS_LIST', {
      onClick,
      onCancel: () => {},
      mode: 'all',
      title: 'modalSendPRToChat.title',
      placeholder: 'modalSendPRToChat.placeholder'
    });

    function onClick({ id: chatId }) {
      setMsgToChannel(chatId, link);
      hideModalDialog();
    }
  }

  function sendToEmail() {
    showModal('SEND_TO_EMAIL', { requestId });
  }

  function copyLink() {
    clipboard.copy(link).then(showSuccessCLNotification);

    function showSuccessCLNotification() {
      setSuccessMessage({
        key: 'notifications.successCopyLink'
      });
    }
  }

  return { handler };
};

export default useShareDropdownHandler;
