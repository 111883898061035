/* eslint-disable max-len */

import React from 'react';

const AcceptIcon = () => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.4947 13.7877L1.74361 9.03659L1.0365 9.7437L6.4947 15.2019L17.6965 4.00008L16.9894 3.29297L6.4947 13.7877Z"
      fill="#767B92"
    />
  </svg>
);

export default AcceptIcon;
