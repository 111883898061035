import React from 'react';
import { useVideoChatContext } from '../../hooks/useVideoChatContext';

const ButtonCallEnd = () => {
  const { endCall } = useVideoChatContext();

  return (
    <button
      type="button"
      className="video-chat__btn video-chat__btn-call-end"
      onClick={endCall}>
      <img
        className="video-chat__btn-img video-chat__btn-img_call_end"
        src="/img/videochat/call_end.svg"
        draggable={false}
        alt=""
      />
    </button>
  );
};

export default ButtonCallEnd;
