import React, { useCallback, useMemo } from 'react';
import { DynamicList } from '@link/react-components';
import { companyService } from '../../../../api';
import { useCompanyProfileContext } from '../../CompanyProfile.context';
import { calcOffset } from '../../../../pages/companies/utils';
import { RequestCard } from '../RequestCard';
import styles from './PurchaseRequests.module.css';
import { NoSearchResult } from './NoSearchResult';

const ITEMS_PER_PAGE = 20;
const GAP_BETWEEN_ITEMS = 12;

export function PurchaseRequests() {
  const { company } = useCompanyProfileContext();

  const onLoad = useCallback(
    async (page, limit) =>
      companyService.getPurchaseRequests(company.id, {
        limit,
        offset: calcOffset(page, limit)
      }),
    [company]
  );

  const provider = useMemo(
    () => ({
      load: onLoad
    }),
    [onLoad]
  );

  return (
    <div className={styles.container}>
      <DynamicList
        provider={provider}
        renderItem={(request) => <RequestCard request={request} />}
        noResultsScreen={<NoSearchResult />}
        batchSize={ITEMS_PER_PAGE}
        gap={GAP_BETWEEN_ITEMS}
      />
    </div>
  );
}
