import React from 'react';
import './style.css';

const CommentBlock = ({ title, text, show }) => {
  if (!show) {
    return null;
  }

  return (
    <div className="comment-block-wrap">
      <div className="comment-block-title">{title}</div>
      <div>{text}</div>
    </div>
  );
};

export default CommentBlock;
