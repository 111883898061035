import React, { memo, useEffect, useState } from 'react';
import PopupSmall from '../../PopupSmall';
import PopupContent from '../../PopupSmall/PopupContent';
import SearchBlockDefault from '../../SearchBlock/SearchBlockDefault';

const SEARCH_HISTORY_KEY = 'search-history';

const getLSItems = () => localStorage.getItem(SEARCH_HISTORY_KEY) || '';

function areEqual(prevProps, nextProps) {
  if (prevProps.value === nextProps.value) {
    return false;
  }
  return true;
}

const SearchBlockWithMemory = memo(
  ({ value, placeholder, onSearch, width }) => {
    const [menuOptions, setMenuOptions] = useState([]);
    const [isOpened, setIsOpened] = useState(false);
    const [queryString, setQueryString] = useState(value || '');

    useEffect(() => {
      setQueryString(value);
    }, [value]);

    const onClickHistory = (query) => () => {
      setQueryString(query);
      onSearch(query);
      setIsOpened(false);
    };

    const generateOptions = (list) =>
      list.map((query) => ({ name: query, onClick: onClickHistory(query) }));

    useEffect(() => {
      const searchHistory = getLSItems();
      if (searchHistory) {
        setMenuOptions(generateOptions(searchHistory.split(';')));
      }
    }, []);

    const saveSearchResult = (query) => {
      const searchHistory = getLSItems();
      const previousResults = searchHistory
        .split(';')
        .filter((result) => result !== query);
      const result = [query, ...previousResults.slice(0, 4)].join(';');
      localStorage.setItem(SEARCH_HISTORY_KEY, result);
    };

    const onSubmit = (query) => {
      if (query.trim().length === 0) {
        return;
      }
      onSearch(query);
      saveSearchResult(query);
    };

    const onFocus = () =>
      menuOptions.length > 0 ? setIsOpened(true) : undefined;

    return (
      <div>
        <SearchBlockDefault
          onClick={onFocus}
          query={queryString}
          theme="main"
          placeholder={placeholder}
          onSubmit={onSubmit}
          onEmpty={onSearch}
          width={width}
        />
        <PopupSmall
          isOpened={isOpened}
          setIsOpened={setIsOpened}
          content={
            <PopupContent
              style={{ minWidth: 'auto' }}
              menuOptions={menuOptions}
            />
          }
          style={{ width: '100%' }}
          top={5}
        />
      </div>
    );
  },
  areEqual
);

export default SearchBlockWithMemory;
