.autocomplete {
  width: 100%;
}

:global(.MuiAutocomplete-root) {
  background-color: var(--color-white);
  padding: 0;
  text-transform: none;
  border: 1px solid #ebeef4;
  border-radius: 4px;

  :global(.MuiInputBase-root) {
    font-size: inherit;
    font-weight: inherit;
    font-family: inherit;
  }

  :global(.MuiAutocomplete-inputRoot) {
    text-transform: none;
    padding: 0;

    :global(.MuiAutocomplete-input) {
      padding: 8px 10px;
      font-family: var(--font);
      border: none;
      color: var(--main-dark-gray);
      height: unset;

      &::placeholder {
        color: var(--color-gray-500);
      }
    }

    :global(.MuiOutlinedInput-notchedOutline) {
      border: none;
    }
  }
}

.popper {
  border-radius: 4px;
  border: 1px solid #ebeef4;
  box-shadow: 0 1px 4px rgb(118 123 146 / 30%);
  padding: 0;
  margin: 0;
}

.option {
  color: var(--main-dark-gray);
}
