import { isNil, isEmpty } from 'ramda';

export const hasResponded = (request) => Boolean(request.get('hasRespond'));
export const isFromThatCompany = (request, company) =>
  request.get('companyId') === company.get('id');

export const getStatus = (request) => request.get('status');

export const getStatusTKey = (request) =>
  !isNil(getStatus(request))
    ? `purchaseRequest.card.statuses.${getStatus(request)}`
    : '';

export const isResponded = (request) => request.get('status') === 'responded';

export function getLocation(request) {
  let result = `${request.get('country')}, ${request.get('region')}`;

  if (!isNil(request.get('city')) && !isEmpty(request.get('city'))) {
    result += `, г. ${request.get('city')}`;
  }

  return result;
}
export const getCustomerName = (request) =>
  request.getIn(['companyInfo', 'name']);
