import React from 'react';
import { translate } from 'react-i18next';
import styled from 'styled-components';

const ErrorHint = ({ t, hint }) => <Container>{t(hint)}</Container>;

const Container = styled.div`
  color: ${(p) => p.theme.colors.danger};
`;

export default translate()(ErrorHint);
