import { Map, List, fromJS } from 'immutable';

const initState = () =>
  Map({
    initItems: List(),
    orderId: null,
    items: List(),
    selectedItems: List(),
    products: List(),
    priceCategory: Map(),
    hasChanges: false,
    combineOrdersStocks: Map(),
    excluded: List()
  });

const setTableAttrs = (data, { excluded }) => data.set('excluded', excluded);

const toggleSelectItem = (
  data,
  { id, productId, cost, discount, offers, unit, stockId, vat }
) =>
  data.update('selectedItems', (selectedItems) => {
    const index = stockId
      ? selectedItems.findIndex(
          (i) => i.get('id') === id && i.get('stockId') === stockId
        )
      : selectedItems.findIndex((i) => i.get('id') === id);

    if (index !== -1) return selectedItems.delete(index);

    return selectedItems.push(
      Map({
        id,
        productId,
        cost,
        discount,
        offers: fromJS(offers || []),
        unit,
        stockId,
        vat
      })
    );
  });

const clearSelectedItems = (data) => data.set('selectedItems', List());

const setCountForSelectedItems = (data, { count }) => {
  const items = data.get('items');
  const selectedItems = data.get('selectedItems');

  let newItems = items;

  selectedItems.forEach((selectedItem) => {
    const { stockId } = selectedItem;

    const index = stockId
      ? items.findIndex(
          (item) =>
            item.get('id') === selectedItem.get('id') &&
            item.get('stockId') === stockId
        )
      : items.findIndex((item) => item.get('id') === selectedItem.get('id'));

    if (index !== -1) {
      newItems = newItems.setIn([index, 'count'], count);
    } else {
      const newItem = selectedItem.set('count', count).delete('productId');
      newItems = newItems.push(newItem);
    }
  });

  return data
    .set('items', newItems)
    .set('selectedItems', List())
    .set('hasChanges', true);
};

const addItem = (
  data,
  { id, count, cost, discount, offers, unit, stockId, vat }
) => {
  const newData = data.update('items', (items) => {
    const index = stockId
      ? items.findIndex(
          (i) => i.get('id') === id && i.get('stockId') === stockId
        )
      : items.findIndex((i) => i.get('id') === id);
    if (index !== -1) {
      if (!count) {
        return items.delete(index);
      }
      return items
        .setIn([index, 'count'], count)
        .setIn([index, 'stockId'], stockId)
        .setIn([index, 'unit'], unit)
        .setIn([index, 'cost'], cost)
        .setIn([index, 'offers'], fromJS(offers || []))
        .setIn([index, 'discount'], discount)
        .setIn([index, 'vat'], vat);
    }
    if (count) {
      return items.push(
        Map({
          id,
          count,
          cost,
          discount,
          offers: fromJS(offers || []),
          unit,
          stockId,
          vat
        })
      );
    }
    return items;
  });

  return newData.set(
    'hasChanges',
    !newData.get('items').equals(newData.get('initItems'))
  );
};

const addPriceShareItem = (data, item) =>
  data
    .update('items', (items) => {
      const index = item.stockId
        ? items.findIndex(
            (i) => i.get('id') === item.id && i.get('stockId') === item.stockId
          )
        : items.findIndex((i) => i.get('id') === item.id);
      if (index !== -1) {
        return items.setIn([index], fromJS(item));
      }
      return items.push(fromJS(item));
    })
    .set('hasChanges', true);

const setPriceCategory = (data, { priceCategory = Map() }) =>
  data.set('priceCategory', priceCategory);

const clear = (data) =>
  data
    .set('items', List())
    .set('hasChanges', false)
    .set('priceCategory', Map())
    .delete('consignees');

const setOrderDraftItems = (data, { products, excluded, consignees }) => {
  const items = products.map(
    ({
      productId: id,
      count,
      cost,
      offers,
      retailCost,
      discount,
      unit,
      stockId,
      vat
    }) => ({
      id,
      count,
      cost,
      offers: offers || [],
      retailCost,
      discount,
      unit,
      stockId,
      vat
    })
  );
  let newData = data
    .set('initItems', fromJS(items))
    .set('items', fromJS(items))
    .set('excluded', excluded)
    .set('hasChanges', false);

  if (consignees) {
    newData = newData.set('consignees', fromJS(consignees));
  }
  return newData;
};

const setHasChanges = (data, { hasChanges }) =>
  data.set('hasChanges', hasChanges);

const setOrderId = (data, { orderId }) => data.set('orderId', orderId);

const setCombineOrderStock = (data, { stockId }) =>
  data.setIn(
    ['combineOrdersStocks', stockId],
    !data.getIn(['combineOrdersStocks', stockId])
  );

const removeOrdersSelectedItems = (data, selected) =>
  data.update('items', (items) =>
    items.filter(
      (i) => !selected.includes(`${i.get('id')}-${i.get('stockId')}`)
    )
  );

const cleanOrderItems = (data) =>
  data.update('items', (items) => items.filter((i) => +i.get('count')));

const setOrderCategoryConditions = (data, { categoryId }) =>
  data.update('priceCategoryConditions', (priceCategoryConditions) => {
    if (!priceCategoryConditions) {
      return fromJS([categoryId]);
    }
    if (priceCategoryConditions.includes(categoryId)) {
      return priceCategoryConditions.filter((id) => id !== categoryId);
    }
    return priceCategoryConditions.push(categoryId);
  });

const selectPriceCategory = (state = initState(), { type, payload }) => {
  switch (type) {
    case 'ORDERS:ORDER_DRAFT:ADDITEM':
      return addItem(state, payload);
    case 'ORDERS:ORDER_DRAFT_PRICE_SHARE:ADDITEM':
      return addPriceShareItem(state, payload);
    case 'ORDERS:ORDER_DRAFT:CLEAR':
      return clear(state);
    case 'ORDERS:PRICE_CATEGORY:SET':
      return setPriceCategory(state, payload);
    case 'ORDER:ORDER_DRAFT_INFO:SUCCESS':
      return setOrderDraftItems(state, payload);
    case 'ORDER:ORDER_DRAFT:SET_HAS_CHANGES':
      return setHasChanges(state, payload);
    case 'ORDER:ORDER_DRAFT:SET_ORDER_ID':
      return setOrderId(state, payload);
    case 'ORDER:SET_SPLIT_STOCK':
      return setCombineOrderStock(state, payload);
    case 'ORDERS:REMOVE_ITEMS:DRAFT':
      return removeOrdersSelectedItems(state, payload);
    case 'ORDER:CLEAN':
      return cleanOrderItems(state);
    case 'ORDERS:SET_ORDERS_ATTRS:SUCCESS':
      return setTableAttrs(state, payload);
    case 'ORDERS:ADD_CATEGORY_CONDITION':
      return setOrderCategoryConditions(state, payload);
    case 'ORDERS:ORDER_DRAFT:TOGGLE_SELECT':
      return toggleSelectItem(state, payload);
    case 'ORDERS:ORDER_DRAFT:SET_COUNT_FOR_SELECTED':
      return setCountForSelectedItems(state, payload);
    case 'ORDERS:ORDER_DRAFT:CLEAR_SELECTED_ITEMS':
      return clearSelectedItems(state);
    default:
      return state;
  }
};

export default selectPriceCategory;
