/* eslint-disable */

import React from 'react';
import './style.css';

const VerticalDots = ({ onClick, isActive }) => (
  <button
    type="button"
    onClick={onClick}
    className={`vertical-dots-button ${
      isActive ? 'vertical-dots-button-active' : ''
    }`}>
    <svg
      width="4"
      height="14"
      viewBox="0 0 4 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M2 3C2.82843 3 3.5 2.32843 3.5 1.5C3.5 0.671573 2.82843 -1.01779e-07 2 -6.55671e-08C1.17157 -2.93554e-08 0.5 0.671573 0.5 1.5C0.5 2.32843 1.17157 3 2 3Z"
        fill="#767B92"
      />
      <path
        d="M2 8.5C2.82843 8.5 3.5 7.82843 3.5 7C3.5 6.17157 2.82843 5.5 2 5.5C1.17157 5.5 0.5 6.17157 0.5 7C0.5 7.82843 1.17157 8.5 2 8.5Z"
        fill="#767B92"
      />
      <path
        d="M2 14C2.82843 14 3.5 13.3284 3.5 12.5C3.5 11.6716 2.82843 11 2 11C1.17157 11 0.5 11.6716 0.5 12.5C0.5 13.3284 1.17157 14 2 14Z"
        fill="#767B92"
      />
    </svg>
  </button>
);

export default VerticalDots;
