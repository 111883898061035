import queryString from 'query-string';
import { setSuccessMessage, setInfoMessage } from './message';
import { CATEGORIES_PREFIX } from '../constants';

const loadTableProducts = ({
  data,
  size,
  limit,
  offset,
  search,
  productsId = [],
  orderBy,
  direction,
  filterValues,
  showReceiptDate,
  showVat,
  showDiscount,
  showDiscountPrice
}) => ({
  type: 'TABLE_PRICE_PRODUCT_LOADED',
  payload: {
    data,
    size,
    offset,
    limit,
    search,
    productsId,
    orderBy,
    direction,
    filterValues,
    showReceiptDate,
    showVat,
    showDiscount,
    showDiscountPrice
  }
});

export const selectProduct = (id) => ({
  type: 'TABLE_PRICE_PRODUCT_SELECT',
  payload: { id }
});
export const selectAllProducts = () => ({
  type: 'TABLE_PRICE_PRODUCT_SELECT_ALL',
  payload: {}
});

export const toggleFilterSelected = () => ({
  type: 'TABLE_PRICE_FILTER_SELECT_TOGGLE'
});

export const selectProducts = (ids) => ({
  type: 'TABLE_PRICE_SELECT_PRODUCTS',
  payload: { ids }
});

export const unselectProducts = (ids) => ({
  type: 'TABLE_PRICE_UNSELECT_PRODUCTS',
  payload: { ids }
});

export const clearTableProducts = () => ({
  type: 'CLEAR_TABLE_PRICE_PRODUCT'
});

export const divideCategoriesIds = (categoriesIds) => {
  const { MAIN, ADDITIONAL } = CATEGORIES_PREFIX;
  const mainCatIds = [];
  const additionalCatIds = [];
  let splitByMain;
  let splitByAdditional;

  categoriesIds.forEach((id) => {
    splitByMain = id.split(MAIN);
    if (splitByMain.length === 2) {
      return mainCatIds.push(+splitByMain[1]);
    }

    splitByAdditional = id.split(ADDITIONAL);
    if (splitByAdditional.length === 2) {
      return additionalCatIds.push(+splitByAdditional[1]);
    }

    return undefined;
  });

  return { mainCatIds, additionalCatIds };
};

export const getProducts =
  (
    priceId,
    search = '',
    offset = 0,
    limit = 20,
    orderBy = '',
    direction = '',
    params
  ) =>
  (dispatch, getState) => {
    const state = getState();
    const isEnabledFilterSelected = state.getIn([
      'tablesNew',
      'priceProducts',
      'isEnabledFilterSelected'
    ]);
    const selectedIdsArray = state
      .getIn(['tablesNew', 'priceProducts', 'selectedList'])
      .toArray();

    const filter = state.getIn([
      'tablesNew',
      'priceProducts',
      'appliedFilterValues'
    ]);
    const ids = isEnabledFilterSelected ? selectedIdsArray : [];

    let allCategoriesId = state.getIn([
      'productPositions',
      'selectedCategories'
    ]);
    allCategoriesId =
      allCategoriesId && allCategoriesId.size > 0
        ? allCategoriesId.toArray()
        : [];
    const {
      mainCatIds: categoriesId,
      additionalCatIds: additionalCategoriesId
    } = divideCategoriesIds(allCategoriesId);

    if (params && params.categoryId) {
      categoriesId.push(+params.categoryId);
    }

    if (params && params.additionalCategoryId) {
      additionalCategoriesId.push(+params.additionalCategoryId);
    }

    const orderByArg =
      orderBy || state.getIn(['tablesNew', 'priceProducts', 'orderBy']);
    const directionArg =
      direction || state.getIn(['tablesNew', 'priceProducts', 'direction']);

    const url =
      orderByArg && directionArg
        ? `/api/prices/${priceId}/products/main-search?orderBy=${orderByArg}&direction=${directionArg}`
        : `/api/prices/${priceId}/products/main-search`;

    return fetch(url, {
      credentials: 'include',
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        offset,
        limit,
        search,
        categoriesId,
        additionalCategoriesId,
        stockId: params && params.stockId,
        ids,
        filter
      })
    })
      .then((response) => response.json())
      .then(
        ({
          data,
          size,
          productsId,
          filterValues,
          showReceiptDate,
          showVat,
          showDiscount,
          showDiscountPrice
        }) => {
          dispatch(
            loadTableProducts({
              data,
              size,
              limit: limit || 20,
              offset,
              search,
              productsId,
              orderBy: orderByArg,
              direction: directionArg,
              filterValues,
              showReceiptDate,
              showVat,
              showDiscount,
              showDiscountPrice
            })
          );
        }
      );
  };

export const getProductsAdapter = (options = {}) => {
  const { priceId, id, search, offset, limit, orderBy, direction } = options;
  return getProducts(
    priceId || id,
    search,
    offset,
    limit,
    orderBy,
    direction,
    options
  );
};

const fetchDeleteProduct = (priceId, categoryId, productId) =>
  fetch(
    `/api/prices/${priceId}/categories/${categoryId}/products/${productId}`,
    {
      credentials: 'include',
      method: 'DELETE'
    }
  );

export const deleteSelectedProducts = (priceId) => (dispatch, getState) => {
  const selected = getState()
    .getIn(['tablesNew', 'products', 'selectedList'])
    .toArray();
  const items = getState().getIn(['tablesNew', 'products', 'list']);
  const filtredSelected = items.filter((item) =>
    selected.some((productId) => productId === item.get('id'))
  );

  Promise.all(
    filtredSelected.map(async (product) =>
      fetchDeleteProduct(priceId, product.get('categoryId'), product.get('id'))
    )
  ).then(() => {
    const limit = getState().getIn(['tablesNew', 'products', 'limit']);
    const offset = getState().getIn(['tablesNew', 'products', 'offset']);
    const search = getState().getIn(['tablesNew', 'products', 'search']);

    getProducts(priceId, search, offset, limit)(dispatch, getState);
  });
};

export const updateCost =
  (id, priceId, cost, categoryId, price) => (dispatch) => {
    dispatch({ type: 'TABLE_PRICE_PRODUCT:UPDATE_COST:REQUEST' });
    fetch(
      `/api/prices/${priceId}/categories/${categoryId}/products/${id}/update_cost`,
      {
        credentials: 'include',
        method: 'PATCH',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ cost })
      }
    )
      .then(() => {
        dispatch({
          type: 'TABLE_PRICE_PRODUCT:UPDATE_COST:SUCCESS',
          payload: { id, priceId, cost, categoryId, price }
        });
        dispatch({
          type: 'PRICE:SET_PRICE_STATUS_DRAFT',
          payload: { price }
        });
      })
      .catch((err) => {
        console.error(err);
        dispatch({
          type: 'TABLE_PRICE_PRODUCT:UPDATE_COST:FAILURE',
          payload: { errors: err }
        });
      });
  };

export const updateVat =
  (id, priceId, vat, categoryId, price) => (dispatch) => {
    dispatch({ type: 'TABLE_PRICE_PRODUCT:UPDATE_VAT:REQUEST' });

    fetch(
      `/api/prices/${priceId}/categories/${categoryId}/products/${id}/update_vat`,
      {
        credentials: 'include',
        method: 'PATCH',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ vat })
      }
    )
      .then(() => {
        dispatch({
          type: 'TABLE_PRICE_PRODUCT:UPDATE_VAT:SUCCESS',
          payload: { id, priceId, vat, categoryId, price }
        });
        dispatch({
          type: 'PRICE:SET_PRICE_STATUS_DRAFT',
          payload: { price }
        });
      })
      .catch((err) => {
        console.error(err);
        dispatch({
          type: 'TABLE_PRICE_PRODUCT:UPDATE_VAT:FAILURE',
          payload: { errors: err }
        });
      });
  };

export const setDiscountToProduct = (priceId, id, discount) => (dispatch) => {
  dispatch({
    type: 'TABLE_PRICE_PRODUCT:UPDATE_DISCOST:REQUEST',
    payload: { priceId, id, discount }
  });

  return fetch(`/api/prices/${priceId}/products/discount`, {
    credentials: 'include',
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ products: [{ id, discount }] })
  })
    .then(() => {
      dispatch({
        type: 'TABLE_PRICE_PRODUCT:UPDATE_DISCOST:SUCCESS',
        payload: { priceId, id, discount }
      });
      dispatch({ type: 'PRICE:SET_PRICE_STATUS_DRAFT' });
    })
    .catch((err) => {
      console.error(err);
      dispatch({
        type: 'TABLE_PRICE_PRODUCT:UPDATE_DISCOST:FAILURE',
        payload: { errors: err }
      });
    });
};

// const getSumOfPercentage = (cost, percentage, isRound = false) => {
//   const result = cost - cost * (percentage / 100);
//   return isRound ? Math.round(result) : result;
// };

export const setDiscountToProducts =
  (priceId, discount) => (dispatch, getState) => {
    dispatch({
      type: 'TABLE_PRICE_PRODUCTS_SELECT:UPDATE_DISCOST:REQUEST',
      payload: { priceId, discount }
    });
    dispatch(setInfoMessage({ key: 'change_process_in_progress' }));

    const state = getState();
    const price = state.getIn(['prices', 'price']);
    const selectedProducts = state.getIn([
      'tablesNew',
      'priceProducts',
      'selectedList'
    ]);
    const products = selectedProducts.map((id) => ({
      id,
      discount: discount === null ? null : +discount
    }));

    return fetch(`/api/prices/${priceId}/products/discount`, {
      credentials: 'include',
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ products })
    })
      .then(() => {
        dispatch({
          type: 'TABLE_PRICE_PRODUCTS_SELECT:UPDATE_DISCOST:SUCCESS'
        });
        dispatch(setSuccessMessage());
        dispatch({
          type: 'PRICE:SET_PRICE_STATUS_DRAFT',
          payload: { price }
        });
      })
      .catch((err) => {
        console.error(err);
        dispatch({
          type: 'TABLE_PRICE_PRODUCTS_SELECT:UPDATE_DISCOST:FAILURE',
          payload: { errors: err }
        });
      });
  };

export const editBasePriceForProducts =
  (priceId, { percent, up, cost, manual }) =>
  (dispatch, getState) => {
    dispatch(setInfoMessage({ key: 'change_process_in_progress' }));

    const state = getState();
    const price = state.getIn(['prices', 'price']);
    const selected = state.getIn([
      'tablesNew',
      'priceProducts',
      'selectedList'
    ]);

    return fetch(`/api/prices/${priceId}/products/baseprice`, {
      credentials: 'include',
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ selected, percent, up, cost, manual })
    })
      .then(() => {
        dispatch({
          type: 'TABLE_PRICE_PRODUCTS_SELECT:UPDATE_BASEPRICE:SUCCESS'
        });
        dispatch({
          type: 'PRICE:SET_PRICE_STATUS_DRAFT',
          payload: { price }
        });
        dispatch(setSuccessMessage({ key: 'base_price_changed_succesfully' }));
      })
      .catch((err) => {
        console.error(err);
        dispatch({
          type: 'TABLE_PRICE_PRODUCTS_SELECT:UPDATE_DISCOST:FAILURE',
          payload: { errors: err }
        });
      });
  };

export const updateDiscount =
  (id, priceId, discount, categoryId, price, purchaseCategoryId) =>
  (dispatch) => {
    dispatch({ type: 'TABLE_PRICE_PRODUCT:UPDATE_DISCOUNT:REQUEST' });

    fetch(
      `/api/prices/${priceId}/categories/${categoryId}/products/${id}/update_purchase_discount`,
      {
        credentials: 'include',
        method: 'PATCH',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ discount, purchaseCategoryId })
      }
    )
      .then((res) => res.json())
      .then(() => {
        dispatch({
          type: 'TABLE_PRICE_PRODUCT:UPDATE_DISCOUNT:SUCCESS',
          payload: {
            id,
            priceId,
            discount,
            categoryId,
            price,
            purchaseCategoryId
          }
        });
        dispatch({
          type: 'PRICE:SET_PRICE_STATUS_DRAFT',
          payload: { price }
        });
      })
      .catch((err) => {
        console.error(err);
        dispatch({
          type: 'TABLE_PRICE_PRODUCT:UPDATE_DISCOUNT:FAILURE',
          payload: { errors: err }
        });
      });
  };

export const updatePurchaseCost =
  (id, priceId, cost, categoryId, price, purchaseCategoryId) => (dispatch) => {
    dispatch({ type: 'TABLE_PRICE_PRODUCT:UPDATE_PURCHASE_COST:REQUEST' });

    fetch(
      `/api/prices/${priceId}/categories/${categoryId}/products/${id}/update_purchase_cost`,
      {
        credentials: 'include',
        method: 'PATCH',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ cost, purchaseCategoryId })
      }
    )
      .then((res) => res.json())
      .then(() => {
        dispatch({
          type: 'TABLE_PRICE_PRODUCT:UPDATE_PURCHASE_COST:SUCCESS',
          payload: { id, categoryId, cost, purchaseCategoryId }
        });
        dispatch({
          type: 'PRICE:SET_PRICE_STATUS_DRAFT',
          payload: { price }
        });
      })
      .catch((err) => {
        console.error(err);
        dispatch({
          type: 'TABLE_PRICE_PRODUCT:UPDATE_PURCHASE_COST:FAILURE',
          payload: { errors: err }
        });
      });
  };

export const deleteProductsFromPrice =
  (priceId, productPositions) => (dispatch) => {
    dispatch({ type: 'TABLE_PRICE_PRODUCT:PRODUCT_DELETE:REQUEST' });
    dispatch(setInfoMessage({ key: 'change_process_in_progress' }));

    return fetch(
      `/api/prices/${priceId}/categories/undefined/products/remove`,
      {
        credentials: 'include',
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ productPositions })
      }
    )
      .then(() => {
        dispatch(setInfoMessage());
        dispatch({
          type: 'TABLE_PRICE_PRODUCT:PRODUCT_DELETE:SUCCESSS',
          payload: { productPositions }
        });
      })
      .catch((err) => {
        dispatch({
          type: 'TABLE_PRICE_PRODUCT:PRODUCT_DELETE:FAILURE',
          payload: { err }
        });
        console.error(err);

        return Promise.reject();
      });
  };

export const deleteProductsFromPriceByPubProductsId =
  (priceId, publishedProductsId) => (dispatch) => {
    dispatch({
      type: 'TABLE_PRICE_PRODUCT:PRODUCT_DELETE_BY_PUB_PROD_ID:REQUEST'
    });
    dispatch(setInfoMessage({ key: 'change_process_in_progress' }));

    return fetch(
      `/api/prices/${priceId}/categories/undefined/products/remove_by_published_products_id`,
      {
        credentials: 'include',
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ publishedProductsId })
      }
    )
      .then(() => {
        dispatch(setInfoMessage());
        dispatch({
          type: 'TABLE_PRICE_PRODUCT:PRODUCT_DELETE_BY_PUB_PROD_ID:SUCCESSS',
          payload: { publishedProductsId }
        });
      })
      .catch((err) => {
        dispatch({
          type: 'TABLE_PRICE_PRODUCT:PRODUCT_DELETE_BY_PUB_PROD_ID:FAILURE',
          payload: { err }
        });
        console.error(err);

        return Promise.reject();
      });
  };

export const customExport =
  (priceId, list = [], purchasePriceCategories = []) =>
  (dispatch) => {
    dispatch({
      type: 'TABLE_PRICE_PRODUCT:CUSTOM_EXPORT:REQUEST',
      payload: { priceId, list }
    });
    const params = queryString.stringify(
      { list, purchasePriceCategories },
      { arrayFormat: 'bracket' }
    );
    window.open(
      `/api/prices/${priceId}/products/custom_export?${params}`,
      '_self'
    );
    return Promise.resolve();
  };
