import React, { Component } from 'react';
import PropTypes from 'prop-types';
import block from 'bem-cn-lite';
import { translate } from 'react-i18next';
import { Map } from 'immutable';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  Modal,
  Col,
  Row,
  Form,
  FormGroup,
  FormControl,
  ControlLabel,
  Button,
  HelpBlock,
  Popover,
  OverlayTrigger
} from 'react-bootstrap';
import Checkbox from '../Checkbox';
import './styles/modal_theme_default.styl';
import { ModalContainer } from './elements';
// import getBrowserLanguage from '../../utils/languageDetector';

import { Select } from '../FormControls';
import HelperClose from '../HelperClose';
import Helper from '../Helper';

import * as modalActions from '../../action-creators/modal';
import * as catalogActions from '../../action-creators/catalog';
import * as servicesAction from '../../action-creators/services';
import * as importActions from '../../action-creators/import';

const b = block('modal-container');

class ModalCreateCatalog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: '',
      language: '',
      isIntegrated: false,
      pristine: true,
      integrationClicked: false,
      error: '',
      showPopover: false
    };
    this.inputProps = this.inputProps.bind(this);
    this.isValidNameField = this.isValidNameField.bind(this);
    this.onChangeValue = this.onChangeValue.bind(this);
    this.toggleShow = this.toggleShow.bind(this);
  }

  getChildContext() {
    return { b };
  }

  UNSAFE_componentWillMount() {
    const { infoLoaded, languageList, langs, gaSend, getImportInfo, user } =
      this.props;
    gaSend({
      category: 'Supplier',
      action: 'catalog_create_open',
      label: user.get('email')
    });
    getImportInfo();
    if (infoLoaded && languageList.size && langs) {
      const filteredList = languageList.filter(
        (lang) => !langs.includes(lang.get('code'))
      );
      this.setState({
        language: filteredList.size
          ? filteredList.first().get('code')
          : user.get('language')
      });
    }
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    const { infoLoaded, langs, user } = newProps;
    const { languageList } = this.props;
    if (
      !this.state.language &&
      infoLoaded &&
      languageList &&
      languageList.size &&
      langs
    ) {
      const filteredList = languageList.filter(
        (lang) => !langs.includes(lang.get('code'))
      );
      this.setState({
        language: filteredList.size
          ? filteredList.first().get('code')
          : user.get('language')
      });
    }
  }

  getProperty(field) {
    return this.state[field];
  }

  toggleShow() {
    this.setState({ showPopover: !this.state.showPopover });
  }

  get language() {
    const { languageList, langs } = this.props;

    return languageList.filter(
      (lang) => langs && !langs.includes(lang.get('code'))
    );
  }

  isValidNameField() {
    return this.state.name.length < 1;
  }

  isValidLangField() {
    const { languageList, langs } = this.props;

    let isValid = false;
    languageList.forEach((lang) => {
      if (langs && !langs.includes(lang.get('code'))) {
        isValid = true;
      }
    });
    return isValid;
  }

  asterisk() {
    // eslint-disable-line
    return <span style={{ color: 'red' }}>*</span>;
  }

  inputProps(field) {
    return {
      value: this.state[field],
      onChange: (e) => {
        if (field === 'name') {
          if (this.state.error && this.state.error.type === 'name') {
            this.setState({ error: '' });
          }
          return this.setState({
            [field]: e.target.value.replace(/^\s+|\s(?=\s)/g, '')
          });
        }
        return this.setState({ [field]: e.target.value });
      }
    };
  }

  showHelpInfo(field) {
    const { t } = this.props;
    if (!this.state[field] && !this.state.pristine) {
      return (
        <HelpBlock id="invalidFieldMessage" style={{ color: 'red' }}>
          {t('Required fields empty')}
        </HelpBlock>
      );
    }
    return null;
  }

  onChangeValue(field, e) {
    return this.setState({ [field]: e.value });
  }

  render() {
    const { t, hideModalDialog, gaPageView, gaSend, createCatalog, user } =
      this.props;

    const languageSelectList = this.language.map((lang) => ({
      value: lang.get('code'),
      label: t(lang.get('view'))
    }));

    return (
      <ModalContainer mods={{ theme: 'default' }}>
        <Modal
          className="test_modal_create-catalog"
          onHide={() => hideModalDialog()}
          show>
          <Modal.Header>
            <Modal.Title>
              {t('Create catalog')}
              <span
                className="modal-header-cross pull-right"
                onClick={() => hideModalDialog()}
              />
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <FormGroup
                controlId="input_name"
                style={{ overflow: 'hidden' }}
                validationState={
                  (this.state.name || this.state.pristine) &&
                  !(this.state.error && this.state.error.type === 'name')
                    ? null
                    : 'error'
                }>
                <ControlLabel>
                  {`${t('Catalog name')} `}
                  {this.asterisk()}
                </ControlLabel>
                <FormControl
                  className="test_modal-input_name"
                  type="text"
                  {...this.inputProps('name')}
                  maxLength="60"
                  onKeyDown={(e) => {
                    if (this.state.pristine) {
                      this.setState({ pristine: false });
                    }
                    if (e.keyCode === 13) {
                      e.preventDefault();
                    }
                  }}
                />
                <h6 className="pull-right">
                  <small>{`${this.state.name.length}/60`}</small>
                </h6>
                {this.showHelpInfo('name')}
                {this.state.error && this.state.error.type === 'name' && (
                  <HelpBlock className="pull-left">
                    {t(this.state.error.text)}
                  </HelpBlock>
                )}
                <Row>
                  <Col md={12}>
                    <span
                      className="link"
                      onClick={() =>
                        this.setState({ integrationClicked: true })
                      }>
                      {t('integration_modal_text')}
                    </span>
                    &nbsp;
                    <img
                      onClick={() => this.toggleShow()}
                      role="presentation"
                      src="/img/helper.svg"
                      id="help-catalog-integration"
                      alt=""
                    />
                    <HelperClose
                      t={t}
                      text="intergration_help"
                      link="https://goodwix.com/pdf/API%20Goodwix%20%D0%B7%D0%B0%D0%B3%D1%80%D1%83%D0%B7%D0%BA%D0%B0%20%D0%B7%D0%B0%D0%BA%D0%B0%D0%B7%D0%BE%D0%B2,%20%D1%82%D0%BE%D0%B2%D0%B0%D1%80%D0%BE%D0%B2,%20%D0%BE%D1%81%D1%82%D0%B0%D1%82%D0%BA%D0%BE%D0%B2,%20%D1%86%D0%B5%D0%BD.pdf" // eslint-disable-line
                      show={this.state.showPopover}
                      toggleShow={() => this.toggleShow()}
                      target={document.getElementById(
                        'help-catalog-integration'
                      )}
                    />
                  </Col>
                </Row>
                {this.state.integrationClicked && (
                  <Row>
                    <Col md={12}>
                      <Checkbox
                        set={this.state.isIntegrated}
                        onClick={async () => {
                          if (!this.state.isIntegrated) {
                            await gaSend({
                              category: 'Supplier',
                              action: 'integration_catalog',
                              label: user.get('email')
                            });
                          }
                          this.setState({
                            isIntegrated: !this.state.isIntegrated
                          });
                        }}
                      />
                      <span style={{ display: 'inline-block' }}>
                        {t('isIntegrate')}
                      </span>
                    </Col>
                  </Row>
                )}
              </FormGroup>
              <Row>
                <Col md={6}>
                  <FormGroup controlId="langs">
                    <ControlLabel>
                      {`${t('Language')} `}
                      <Helper text="create_catalog_language_help" />
                    </ControlLabel>
                    <Select
                      id="language-select"
                      value={this.state.language}
                      onChange={(e) => this.onChangeValue('language', e)}
                      options={languageSelectList.toJS()}
                      placeholder={t('not selected')}
                      clearable={false}
                      searchable={false}
                    />
                  </FormGroup>
                </Col>
              </Row>
            </Form>
            {this.asterisk()}
            {` ${t('Required fields')}`}
          </Modal.Body>
          <Modal.Footer>
            <Button
              id="cancel"
              className="pull-left"
              onClick={() => {
                gaPageView('catalog_create_cancel');
                gaSend({
                  category: 'Supplier',
                  action: 'catalog_create_cancel',
                  label: user.get('email')
                });
                hideModalDialog();
              }}>
              {t('Cancel')}
            </Button>
            <OverlayTrigger
              trigger={['focus', 'hover']}
              overlay={
                !this.isValidLangField() ? (
                  <Popover id="popover-is-price-list-published">
                    {t('catalogs_alredy_created_in_all_lang')}
                  </Popover>
                ) : (
                  <div />
                )
              }>
              {this.isValidLangField() ? (
                <Button
                  id="saveButton"
                  bsStyle="primary"
                  className="test_modal-btn_create"
                  disabled={this.isValidNameField()}
                  onClick={async () => {
                    await gaSend({
                      category: 'Supplier',
                      action: 'create_catalog_success',
                      label: user.get('email')
                    });
                    const error = await createCatalog(this.state);
                    if (!error) {
                      hideModalDialog();
                    } else {
                      this.setState({ error });
                    }
                  }}>
                  {t('Create')}
                </Button>
              ) : (
                <div>
                  <a
                    id="savebutton"
                    className="btn btn-primary test_modal-btn_create"
                    disabled>
                    {t('Create')}
                  </a>
                </div>
              )}
            </OverlayTrigger>
          </Modal.Footer>
        </Modal>
      </ModalContainer>
    );
  }
}

ModalCreateCatalog.propTypes = {
  t: PropTypes.func.isRequired
};

ModalCreateCatalog.childContextTypes = {
  b: PropTypes.func
};

export default connect(
  (state) => ({
    languageList: state.get('languageList'),
    langs: state.hasIn(['catalog', 'catalogs'])
      ? state
          .getIn(['catalog', 'catalogs'])
          .map((catalog) => catalog.get('language'))
      : Map(),
    infoLoaded: state.getIn(['catalog', 'infoLoaded']),
    company: state.get('company'),
    catalogs: state.getIn(['tables', 'catalogs', 'items']),
    user: state.getIn(['user', 'user'])
  }),
  (dispatch) =>
    bindActionCreators(
      {
        createCatalog: catalogActions.createCatalog,
        getImportInfo: importActions.getImportInfo,
        gaPageView: servicesAction.gaPageView,
        gaSend: servicesAction.gaSend,
        hideModalDialog: modalActions.hideModalDialog
      },
      dispatch
    )
)(translate(['ui'], { wait: true })(ModalCreateCatalog));
