import React from 'react';
import { isNil } from 'ramda';
import { usePreviousDistinct } from 'react-use';
import { useVideoChatContext } from '../../hooks/useVideoChatContext';
import { VIDEO_CHAT_MODS } from '../../hooks/useVideoChat';

const MODS = {
  On: 'on',
  Off: 'off'
};

async function hasMicPermission() {
  try {
    await navigator.mediaDevices.getUserMedia({ audio: true });
    return true;
  } catch (e) {
    return false;
  }
}

const ButtonMicrophone = () => {
  const { mode, toggleAudio, currentMember } = useVideoChatContext();
  const previousVideoChatMode = usePreviousDistinct(mode);

  const [micMode, setMicMode] = React.useState(MODS.On);
  const [disabled, setDisabled] = React.useState(false);

  React.useEffect(() => {
    if (previousVideoChatMode === VIDEO_CHAT_MODS.Close) {
      setMicMode(MODS.On);
    }
  }, [mode === VIDEO_CHAT_MODS.Open]);

  React.useEffect(() => {
    if (isNil(currentMember)) return;

    hasMicPermission().then((micPermission) => setDisabled(!micPermission));

    setMicMode(currentMember.isMicrophoneMuted ? MODS.Off : MODS.On);
  }, [currentMember?.isMicrophoneMuted]);

  const toggleMicMode = () =>
    setMicMode((prev) => {
      const result = prev === MODS.On ? MODS.Off : MODS.On;
      toggleAudio(result === MODS.On);
      return result;
    });

  return (
    <button
      type="button"
      className={`video-chat__btn video-chat__btn-mic video-chat__btn-mic-${micMode}`}
      onClick={toggleMicMode}
      disabled={disabled}>
      <img
        className="video-chat__btn-img video-chat__btn-img_mic_on"
        src="/img/videochat/mic_on.svg"
        draggable={false}
        alt=""
      />
      <img
        className="video-chat__btn-img video-chat__btn-img_mic_off"
        src="/img/videochat/mic_off.svg"
        draggable={false}
        alt=""
      />
    </button>
  );
};

export default ButtonMicrophone;
