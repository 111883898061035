import React from 'react';
import cn from 'classnames';

import { Typography } from '@components';
import { useTranslate } from '@hooks';
import styles from './UserCard.module.css';
import { convertUrl } from '../../../../ChatWidget/modules/files/utils';

export const UserCard = ({
  avatar,
  firstName,
  lastName,
  isActive = false,
  onSelect,
  isDisabled
}) => {
  const t = useTranslate();
  return (
    <div className={styles.wrapper} onClick={onSelect}>
      <div
        className={cn(styles.content, {
          [styles.active]: isActive
        })}>
        <div
          className={cn(styles.avatar, {
            [styles.avatarDisabled]: isDisabled
          })}>
          <img src={convertUrl(avatar) || '/img/user.svg'} alt="avatar" />
        </div>
        <Typography
          variant="body1Med"
          className={cn(styles.title, {
            [styles.disabledText]: isDisabled
          })}>
          {`${firstName} ${lastName}`}
        </Typography>
        {isDisabled && (
          <Typography
            variant="body2Med"
            className={cn(styles.disabledTextBlock, styles.disabledText)}>
            {t('constructor.alreadyAssigned')}
          </Typography>
        )}
      </div>
      {isActive && <div className={styles.badge} />}
    </div>
  );
};
