import React from 'react';
import MessengerOther from '../../../components/Layouts/MessengerOther';
import ContainersDefault from '../../../components/Containers/ContainersDefault';
import Content from './Content';
import { useTranslate } from '../../../TranslateProvider';

const EMPTY_LIST = [];

const ExternalAPI = () => {
  const t = useTranslate();

  return (
    <MessengerOther
      theme="gray"
      title="External API settings"
      breadcrumbs={EMPTY_LIST}
      panelBtns={EMPTY_LIST}
      content={
        <ContainersDefault>
          <Content t={t} />
        </ContainersDefault>
      }
    />
  );
};

export default ExternalAPI;
