import { Map } from 'immutable';

const updateCatalogAccessField = (state, field, value) =>
  state.set(field, value);

const setCountNewAvailable = (state, value) =>
  state.set('countNewAvailable', value);

const access = (state = Map(), action) => {
  switch (action.type) {
    case 'CATALOG_ACCESS_UPDATE_FIELD':
      return updateCatalogAccessField(state, action.field, action.value);
    case 'CATALOG_SET_COUNT_NEW_AVAILABLE':
      return setCountNewAvailable(state, action.value);
    default:
      return state;
  }
};

export default access;
