/* eslint-disable max-len */

import React from 'react';

const ArchiveIcon = () => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1 4V15.5C1 16.3284 1.67157 17 2.5 17H15.5C16.3284 17 17 16.3284 17 15.5V4H18V15.5C18 16.8807 16.8807 18 15.5 18H2.5C1.11929 18 0 16.8807 0 15.5V4H1Z"
      fill="#767B92"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.4676 1H3.53238C3.18112 1 2.85561 1.1843 2.67489 1.4855L1.76619 3H16.2338L15.3251 1.4855C15.1444 1.1843 14.8189 1 14.4676 1ZM16.1826 0.971009C15.8212 0.368598 15.1701 0 14.4676 0H3.53238C2.82986 0 2.17884 0.368597 1.8174 0.971008L0 4H18L16.1826 0.971009Z"
      fill="#767B92"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.8536 11.1464C12.0488 11.3417 12.0488 11.6583 11.8536 11.8536L9.35354 14.3536C9.15828 14.5489 8.84169 14.5489 8.64643 14.3536L6.14642 11.8536C5.95115 11.6583 5.95115 11.3417 6.14642 11.1465C6.34168 10.9512 6.65826 10.9512 6.85352 11.1465L8.99998 13.2929L11.1465 11.1464C11.3417 10.9512 11.6583 10.9512 11.8536 11.1464Z"
      fill="#767B92"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.5 14V7H9.5V14H8.5Z"
      fill="#767B92"
    />
  </svg>
);

export default ArchiveIcon;
