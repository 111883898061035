import React from 'react';
import DatePicker from 'react-bootstrap-date-picker';

const DAYS = {
  ru: ['ВС', 'ПН', 'ВТ', 'СР', 'ЧТ', 'ПТ', 'СБ'],
  en: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']
};
const MONTHS = {
  ru: [
    'Январь',
    'Февраль',
    'Март',
    'Апрель',
    'Май',
    'Июнь',
    'Июль',
    'Август',
    'Сентябрь',
    'Октябрь',
    'Ноябрь',
    'Декабрь'
  ],
  en: [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December'
  ]
};

export const MainFormDatePicker = ({
  lang = 'ru',
  weekStartsOn,
  value,
  dataTestId,
  onChange,
  calendarPlacement,
  dateFormat,
  minDate,
  disabled = false
}) => (
  <>
    <DatePicker
      disabled={disabled}
      dayLabels={DAYS[lang]}
      monthLabels={MONTHS[lang]}
      weekStartsOn={weekStartsOn}
      data-testid={dataTestId}
      value={value}
      onChange={onChange}
      calendarPlacement={calendarPlacement}
      dateFormat={dateFormat}
      minDate={minDate}
    />
  </>
);
