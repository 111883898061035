.searchInput {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  padding: 8px 16px;
  background-color: var(--color-white);
  border: 1px solid var(--color-border-chat);
  border-radius: 18px;
}

.disabled {
  background: var(--color-gray-300);
  border-color: var(--color-border-chat);
}

.isError {
  border-color: var(--color-red-error);
}

.isFocused {
  border: 1px solid var(--color-blue-500);
  box-shadow: 0 0 5px rgb(35 105 241 / 31%);
  border-radius: 31px;
}

.isHovered {
  border-color: var(--color-blue-200);
}

.inputField {
  flex-grow: 1;
  border: none;
  outline: none;
  color: var(--color-gray-900);

  &::placeholder {
    color: var(--color-gray-600);
  }

  &:disabled {
    background: inherit;
  }
}

.iconBtn {
  max-width: 18px;
  max-height: 18px;
  min-width: 18px;
  min-height: 18px;
  padding: 0;
  border: none;
  font: inherit;
  color: inherit;
  background-color: transparent;
  cursor: pointer;
}

.testInput {
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: 10px;
}

.input {
  border: 3px solid black;
  outline: none;
  padding: 10px;
}
