import { isEmpty } from 'ramda';
import request from '../../../../lib/request';
import { actions } from './reducer';

export const getRequestResponse = (responseId) => async (dispatch) => {
  dispatch(actions.setIsUploaded(false));

  try {
    const response = await request.get(`/api/requests/responses/${responseId}`);
    dispatch(actions.getResponseById({ response }));
  } catch (e) {
    console.error(e);
    dispatch(actions.setIsUploaded(true));
  }
};

export const acceptRespond =
  ({ requestId, responseId }) =>
  async (dispatch) => {
    await request.post(
      `/api/requests/${requestId}/respond/${responseId}/accept`
    );
    dispatch(actions.acceptResponse({ requestId, responseId }));
  };

export const refuseRespond =
  ({ requestId, responseId, text = '' }) =>
  async (dispatch) => {
    await request.post(
      `/api/requests/${requestId}/respond/${responseId}/reject`,
      { text }
    );
    dispatch(actions.rejectResponse({ responseId }));
  };

export const archiveRespond =
  ({ responseId, isArchived }) =>
  async (dispatch) => {
    await request.post(`/api/requests/respond/${responseId}/archive`, {
      isArchived
    });
    dispatch(actions.removeResponse(responseId));
  };

export const getMyResponses =
  ({
    search = '',
    limit = 20,
    offset = 0,
    order,
    direction = 'ASC',
    selectedCategoriesId = [],
    filters = [],
    selectedStatus = [],
    isArchive = false
  }) =>
  async (dispatch) => {
    let baseUrl = `/api/responses/my?limit=${limit}&offset=${offset}`;

    if (search) {
      baseUrl += `&search=${search}`;
    }

    if (order) {
      baseUrl += `&orderBy=${order}&direction=${direction}`;
    }

    if (selectedCategoriesId.length > 0) {
      baseUrl += selectedCategoriesId.reduce(
        (str, id) => `${str}&selCatsId[]=${id}`,
        ''
      );
    }

    const data = await request.post(baseUrl, {
      filters,
      selectedStatus,
      isArchived: isArchive
    });
    dispatch(actions.getAllResponses(data));
  };

export const getAllBuyersOfMyResponses = () => async (dispatch) => {
  const buyers = await request.get('/api/requests/responses/all-buyers');
  dispatch(actions.getAllBuyersOfMyResponses({ buyers }));
};

export const getCountries =
  (search = '', countryId, lang) =>
  async (dispatch) => {
    let baseUrl = `/api/requests/countries`;
    if (search) {
      baseUrl += `?search=${search}`;
    }
    if (countryId) {
      baseUrl += `${search ? '&' : '?'}countryId=${countryId}`;
    }
    if (lang) {
      baseUrl += `${search || countryId ? '&' : '?'}lang=${lang}`;
    }

    const countries = await request.get(baseUrl);
    dispatch(actions.getCountries({ countries }));
  };

export const getRegionsCount = (countryId, lang) => async (dispatch) => {
  let baseUrl = `/api/requests/regions/total?countryId=${countryId}`;
  if (lang) {
    baseUrl += `&lang=${lang}`;
  }

  const regionsCount = await request.get(baseUrl);
  dispatch(actions.getRegionsCount({ regionsCount }));
};

export const getRegions =
  (countryId, search = '', regionId, lang) =>
  async (dispatch) => {
    let baseUrl = `/api/requests/regions?countryId=${countryId}`;
    if (search) {
      baseUrl += `&search=${search}`;
    }
    if (regionId) {
      baseUrl += `&regionId=${regionId}`;
    }
    if (lang) {
      baseUrl += `&lang=${lang}`;
    }

    const regions = await request.get(baseUrl);
    dispatch(actions.getRegions({ regions }));
  };

export const getCities =
  (countryId, regionId, search = '', cityId, lang) =>
  async (dispatch) => {
    let baseUrl = `/api/requests/cities?countryId=${countryId}`;
    if (search) {
      baseUrl += `&search=${search}`;
    }
    if (cityId) {
      baseUrl += `&cityId=${cityId}`;
    }
    if (lang) {
      baseUrl += `&lang=${lang}`;
    }

    if (Array.isArray(regionId) && !isEmpty(regionId)) {
      baseUrl += regionId.reduce(
        (str, region) => `${str}&regionId[]=${region.value}`,
        ''
      );
    }

    const cities = await request.get(baseUrl);
    dispatch(actions.getCities({ cities }));
  };
