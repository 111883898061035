import React from 'react';
import OpenChatButton from '../OpenChat';
import { useOpenResponseChat } from './useOpenResponseChat';
import { noop } from '../../utils/utils';

const OpenResponseChatButton = ({
  icon = 'message-1',
  response,
  requestId = response?.attrs?.purchaseRequest?.id,
  onOpened = noop,
  isWidget = false,
  dataTestId,
  tooltip,
  disabled
}) => {
  const { getChat, onOpened: handleOpened } = useOpenResponseChat({
    response,
    requestId,
    onOpened
  });

  return (
    <OpenChatButton
      icon={icon}
      getChat={getChat}
      onOpened={handleOpened}
      isWidget={isWidget}
      dataTestId={dataTestId}
      tooltip={tooltip}
      disabled={disabled}
    />
  );
};

export default OpenResponseChatButton;
