import React from 'react';
import cs from 'classnames';

const ErrorNotice = ({ isError, children, className = '', ...props }) => (
  <div className={cs(className, isError && 'error-border')} {...props}>
    {children}
  </div>
);

export default ErrorNotice;
