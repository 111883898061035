import React, { useState, useCallback, useMemo } from 'react';
import cn from 'classnames';
import {
  Typography,
  Icon,
  ButtonIcon,
  DynamicList
} from '@link/react-components';
import { useTranslate, useActions } from '@hooks';
import { connect, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import { companyService } from '../../../../api';
import { useCompanyProfileContext } from '../../CompanyProfile.context';
import * as storeGetters from '../../../../storeGetters';
import { useOpenChat } from '../../../OpenChat/useOpenChat';
import styles from './Employees.module.css';
import api from '../../../../lib/request';
import { withRouter } from '../../../../hoc/withRouter';
import * as ContactsUC from '../../../ChatWidget/modules/contacts';
import * as responseDrawerActions from '../../../ChatWidget/modules/purchaseRequestWithResponseCompany/redux-storage';
import { calcOffset } from '../../../../pages/companies/utils';

const ITEMS_PER_PAGE = 20;
const GAP_BETWEEN_ITEMS = 0;

const ContactAreHidden = ({ message }) => (
  <div className={styles.contactsAreHidden}>{message}</div>
);

const EmployeeRow = ({ employee, unhideContactGeneral }) => {
  const [employeeInfo, setEmployeeInfo] = useState(employee);
  const { hideDrawer } = useActions({ hideDrawer: responseDrawerActions.hide });

  const currentUserId = useSelector(storeGetters.getCurrentUserId);

  const disabled = currentUserId === employeeInfo.id;

  const getChat = useCallback(
    () =>
      api
        .get(`/api/dialog/by-employeeId/${employeeInfo.id}`)
        .then((dialogId) => api.get(`/api/channel/${dialogId}`))
        .catch((e) => console.log(`Employees' tab error: ${e}`)),
    [employeeInfo]
  );

  const onOpened = useCallback(() => {
    hideDrawer();
  }, [hideDrawer]);

  const handleOpenChat = useOpenChat({ getChat, onOpened, isWidget: false });

  const handleAddToContacts = () => {
    unhideContactGeneral(employeeInfo.id)
      .then(() => setEmployeeInfo({ ...employeeInfo, added: true }))
      .catch((e) => console.log(`Employees' tab error: ${e}`));
  };

  return (
    <>
      <div className={styles.row}>
        <Typography
          variant="body1Med"
          className={cn(styles.name, styles.headerRow)}>
          {getFullEmployeeName(employeeInfo)}
        </Typography>
        <Typography
          variant="body1Reg"
          className={cn(styles.title, styles.headerRow)}
          Component="div">
          {employeeInfo.title || '-'}
        </Typography>
        <div className={styles.headerRow}>
          <Typography variant="body1Reg" Component="div" className={styles.tel}>
            {employeeInfo.phoneNumber || '-'}
          </Typography>
          <div className={styles.actions}>
            {employeeInfo.added ? (
              <Icon
                className={cn(styles.contactAdded, disabled && styles.disabled)}
                iconName={disabled ? 'contact-add' : 'contact-added'}
                height={18}
                width={18}
              />
            ) : (
              <ButtonIcon
                onClick={handleAddToContacts}
                iconName="contact-add"
                variant="medium"
                size={18}
                disabled={disabled}
              />
            )}
            <ButtonIcon
              iconName="chat"
              variant="medium"
              size={18}
              disabled={disabled}
              onClick={handleOpenChat}
            />
          </div>
        </div>
      </div>
    </>
  );
};

function getFullEmployeeName(employee) {
  const fullName = [employee.lastName, employee.firstName, employee.middleName];
  return fullName.join(' ');
}

const Header = () => {
  const t = useTranslate();

  function getTranslate(tKey) {
    return t(`companyCard.tabs.employees.${tKey}`);
  }
  return (
    <Typography
      variant="body1Med"
      Component="div"
      className={cn(styles.row, styles.header)}>
      <span className={styles.headerRow}>{getTranslate('fullName')}</span>
      <span className={styles.headerRow}>{getTranslate('title')}</span>
      <span className={styles.headerRow}>{getTranslate('tel')}</span>
    </Typography>
  );
};

function Employees({ unhideContactGeneral }) {
  const { company } = useCompanyProfileContext();
  const t = useTranslate();

  function getTranslate(tKey) {
    return t(`companyCard.tabs.employees.${tKey}`);
  }

  const onLoad = useCallback(
    async (page, limit) =>
      companyService.getEmployees(company.id, {
        limit,
        offset: calcOffset(page, limit)
      }),
    [company]
  );

  const provider = useMemo(
    () => ({
      load: onLoad
    }),
    [onLoad]
  );

  return (
    <div className={styles.employees}>
      <DynamicList
        provider={provider}
        renderItem={(employee) => (
          <EmployeeRow
            employee={employee}
            unhideContactGeneral={unhideContactGeneral}
          />
        )}
        noResultsScreen={
          <ContactAreHidden message={getTranslate('contactsAreHidden')} />
        }
        batchSize={ITEMS_PER_PAGE}
        gap={GAP_BETWEEN_ITEMS}
        header={<Header />}
      />
    </div>
  );
}

export default withRouter(
  connect(null, (dispatch) =>
    bindActionCreators(
      {
        unhideContactGeneral: ContactsUC.unhideContactGeneral
      },
      dispatch
    )
  )(Employees)
);
