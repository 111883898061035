import React from 'react';
import { Nav, NavItem } from 'react-bootstrap';
import { translate } from 'react-i18next';
import { Link } from 'react-router-dom';
import { withRouter } from '../../hoc/withRouter';

import { getCurrentPath } from '../../utils';

import './style.styl';

const getActiveTab = (items) => {
  const currentPath = getCurrentPath();
  const item = items.find(({ url }) => currentPath === url);

  return item.eventKey;
};

const getItems = (items) =>
  items.map((item, i) => {
    const urlArray = item.url.split('/');
    const lastPath = urlArray[urlArray.length - 1];

    return { ...item, eventKey: `${lastPath}-${i}` };
  });

const navItem = ({ eventKey, name, disabled, hidden = 'block' }) => (
  <NavItem
    eventKey={eventKey}
    disabled={!!disabled}
    key={eventKey}
    style={{ display: hidden }}>
    {name}
  </NavItem>
);

const WorkspaceHeader = ({
  t,
  navigation,
  items,
  history,
  style,
  showLinkPublished,
  urlToPublished
}) => {
  const navItems = (navigation && navigation.items) || getItems(items);
  const navStyle = (navigation && navigation.style) || 'tabs';
  const activeKey =
    (navigation && navigation.activeKey) || getActiveTab(getItems(items));
  const handleTabChange =
    (navigation && navigation.handleTabChange) ||
    ((eventKey) =>
      history.push(navItems.find((tab) => tab.eventKey === eventKey).url));
  const justified = !!(navigation && navigation.justified);

  return (
    <section className="workspace-header" style={style}>
      {showLinkPublished && (
        <Link
          to={urlToPublished}
          style={{
            float: 'right',
            marginTop: 8,
            fontSize: 12,
            fontWeight: 500,
            color: '#1c50de'
          }}>
          {t('Show the published version')}
        </Link>
      )}

      <Nav
        bsStyle={navStyle}
        activeKey={activeKey}
        justified={justified}
        onSelect={handleTabChange}>
        {navItems.map(navItem)}
      </Nav>
    </section>
  );
};

export default withRouter(translate(['ui'], { wait: true })(WorkspaceHeader));
