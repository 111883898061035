import React from 'react';
import { SidebarMods } from './mods';
import TranscriptChat from '../TranscriptChat';

const Content = ({ mode, transcript }) => {
  switch (mode) {
    case SidebarMods.Transcript:
      return <TranscriptChat messages={transcript.messages} />;
    default:
      return null;
  }
};

export default Content;
