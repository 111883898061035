import * as emailMessagesStorage from '../storage';

const revokeEmail = async (email) => {
  try {
    await emailMessagesStorage.revokeEmail(email);
  } catch (error) {
    console.log(error);
  }
};

export default revokeEmail;
