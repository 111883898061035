import React from 'react';
import { Button } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { withRouter } from '../../../hoc/withRouter';
import CompareIcon from '../../../icons/compare';
import { useTranslate } from '../../../TranslateProvider';
import { getCompareRequest } from '../MyRequests/getters';

const CompareChosenButton = ({
  history,
  display = true,
  isUploaded = true
}) => {
  if (!display) {
    return null;
  }

  const compareRequest = useSelector(getCompareRequest);

  const t = useTranslate();

  const handleCompare = () =>
    history.push(`/requests/compare/${compareRequest}`);

  return (
    <Button
      style={{
        marginLeft: 'auto',
        marginRight: isUploaded ? 0 : 5,
        display: 'flex'
      }}
      disabled={!compareRequest}
      onClick={handleCompare}>
      <div style={{ margin: '-2px 10px -12px 0' }}>
        <CompareIcon />
      </div>
      {t('compare_chosen')}
    </Button>
  );
};

export default withRouter(CompareChosenButton);
