import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { ModalEditCustomer } from '../components/Modal';
import * as customersAction from '../action-creators/customers';
import * as modalAction from '../action-creators/modal';

const ContModalEditCustomer = (props) => <ModalEditCustomer {...props} />;

const getCustomer = (state) => {
  const customerId = state.getIn(['customers', 'selected']).first();
  return state.getIn(['customers', 'items', customerId]);
};
export default connect(
  (state) => ({
    groups: state.getIn(['customers', 'groups']),
    selectedItem: getCustomer(state),
    groupId: state.getIn(['customers', 'currentCategory']).get('id')
  }),
  (dispatch) =>
    bindActionCreators(
      {
        updateSelectedField: customersAction.updateSelectedField,
        getGroups: customersAction.getGroups,
        setSelectedItem: customersAction.setSelectedItem,
        getCustomers: customersAction.getCustomers,
        hideModalDialog: modalAction.hideModalDialog
      },
      dispatch
    )
)(ContModalEditCustomer);
