import request from '../lib/request';
import { SaveDraftPurchaseRequestDto } from '../DTOs';

// convertProductsToJson errors example:
//
// Incorrect requestId:
// {
//   "message": "Sorry you past incorrect request id"
// }
//
// Fields parsing errors:
// {
//   "name": "FieldsParseError",
//   "fields": [
//     {
//       "number": 1, - row number (start with 1)
//       "name": "Янтарь", - product name
//       "keys": [
//         "summary" - fields with error (for this row)
//       ]
//     },
//  ]
// }
//
// File parsing errors:
// {
//   "name": "FileParseError",
//   "message": "Incorrect data was passed"
// }
//
// File size error:
// { message: 'File size must be less then 10 MB' }
//
// Unknown error:
// {
//     "message": "Something broke"
// }

class PurchaseRequestsService {
  #baseUrl = '/api/requests';

  async convertProductsToJson(requestId, file) {
    return request.sendFile(
      `${this.#baseUrl}/${requestId}/table-products/convert`,
      file
    );
  }

  async exportRequestToExcel(requestId) {
    return request.post(`${this.#baseUrl}/${requestId}/export-to-excel`);
  }

  async extendResponseEndDate({ requestId, responseEndDate }) {
    return request.patch(
      `${this.#baseUrl}/${requestId}/extend/responseEndDate`,
      {
        responseEndDate
      }
    );
  }

  async saveDraft(payload) {
    const saveDraftDto = new SaveDraftPurchaseRequestDto(payload);

    return request.post(`${this.#baseUrl}/save`, saveDraftDto);
  }

  async sendToEmails({ emails, requestId }) {
    return request.post(`${this.#baseUrl}/${requestId}/email/`, { emails });
  }
}

export const purchaseRequestService = new PurchaseRequestsService();
