import React from 'react';
import { Typography } from '@link/react-components';
import { useTranslate } from '@hooks';
import styles from './Categories.module.css';

function Category({ name }) {
  return (
    <div className={styles.category}>
      <Typography variant="body2Reg" className={styles.categoryText}>
        {name}
      </Typography>
    </div>
  );
}

export function Categories({ categories }) {
  const t = useTranslate();

  return (
    <div className={styles.categories}>
      {categories.length ? (
        categories.map((category, index) => (
          <Category key={category.name + index} name={category.name} />
        ))
      ) : (
        <Typography variant="body1Reg" className={styles.emptyCategories}>
          {t('modules.company.card.emptyCategories')}
        </Typography>
      )}
    </div>
  );
}
