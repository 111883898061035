import {
  allPass,
  curry,
  flatten,
  includes,
  isEmpty,
  isNil,
  keys,
  last,
  length,
  lt,
  not,
  nth,
  omit,
  pipe,
  prop,
  values
} from 'ramda';
import {
  ACCEPTABLE_AGREEMENT_CARDS,
  STEP_TYPES,
  THRESHOLD_FOR_PARALLEL_STEP
} from './constants';

const getLastStepFromPath = last;

export const getKeysFromStep = keys;

const curriedOmit = curry(omit);
const curriedLt = curry(lt);

const excludeIdFromStep = curriedOmit(['id']);
const getValuesListFromStepTypes = values;
const makeFlattenValuesList = flatten;
const getLengthOfList = length;
const isListLengthMoreThenThreshold = curriedLt(THRESHOLD_FOR_PARALLEL_STEP);

export const isParallelStep = pipe(
  excludeIdFromStep,
  getValuesListFromStepTypes,
  makeFlattenValuesList,
  getLengthOfList,
  isListLengthMoreThenThreshold
);

export const isPathExist = (path) => !isEmpty(path);

const isStepKeysHasPublication = (lastStepKeys) =>
  includes(STEP_TYPES.publication, lastStepKeys);

// const isNotPathAchieveLimit = (path) => length(path) < MAX_STEPS_IN_PATH

export const isLastStepHasNotPublication = pipe(
  getLastStepFromPath,
  getKeysFromStep,
  isStepKeysHasPublication,
  not
);
export const isLastStepHasPublication = pipe(
  getLastStepFromPath,
  getKeysFromStep,
  isStepKeysHasPublication
);

export const isStepHasPublication = pipe(
  getKeysFromStep,
  isStepKeysHasPublication
);

export const isCanDrawLineToCreateButton = allPass([
  isPathExist,
  isLastStepHasNotPublication
  // isNotPathAchieveLimit
]);

const curriedPropId = curry(prop);

export const getPropId = curriedPropId('id');

export const getLastStepId = pipe(getLastStepFromPath, getPropId);

export const getLengthOfPath = length;

export const isCardsExist = (cards) => !isNil(cards) && !isEmpty(cards);

const curriedNth = curry(nth);

export const isTypeBegin = (type) => type === 'begin';

const getPreLastStepFromSteps = curriedNth(-2);

export const getPreLastStepIdFromSteps = pipe(
  getPreLastStepFromSteps,
  getPropId
);

export const numberOfAgreementCards = (cards) => length(cards);

export const isCanAddAgreementCard = (cards) =>
  !isCardsExist(cards) ||
  numberOfAgreementCards(cards) < ACCEPTABLE_AGREEMENT_CARDS;

export const isCanAddIntroductionCard = (cards) => !isCardsExist(cards);

export const isPublicationType = (type) => STEP_TYPES.publication === type;

/* eslint-disable */
export const isErrorOnSave = (path) => {
  for (let step of path) {
    if (!isNil(step[STEP_TYPES.publication])) {
      const publications = step[STEP_TYPES.publication];
      for (let publication of publications) {
        if (isEmpty(publication.members)) return true;
      }
    }
    if (!isNil(step[STEP_TYPES.agreement])) {
      const agreements = step[STEP_TYPES.agreement];
      for (let agreement of agreements) {
        if (isEmpty(agreement.members)) return true;
      }
    }
    if (!isNil(step[STEP_TYPES.introduction])) {
      const introductions = step[STEP_TYPES.introduction];
      for (let introduction of introductions) {
        if (length(introduction.members) < 1) return true;
      }
    }
  }
  return false;
};
/* eslint-enable  */
export const getAllUsersInTheBuilder = (builder) => {
  const users = builder.path.map((step) => {
    const subUsers = [];
    if (!isNil(step[STEP_TYPES.publication])) {
      subUsers.push(...step[STEP_TYPES.publication]);
    }
    if (!isNil(step[STEP_TYPES.agreement])) {
      subUsers.push(...step[STEP_TYPES.agreement]);
    }
    if (!isNil(step[STEP_TYPES.introduction])) {
      subUsers.push(...step[STEP_TYPES.introduction]);
    }
    return subUsers.map((card) => card.members);
  });
  return [...flatten(users)].map((user) => user.id);
};
