import React from 'react';
import { useDetectedSpeech } from './useDetectedSpeech';

function createNode(elementNode) {
  const wrapperNode = document.createElement('div');
  elementNode.parentNode.insertBefore(wrapperNode, elementNode);
  return wrapperNode;
}

export function useDefineSpeaker(ref = React.useRef(null), stream) {
  const wrapperRef = React.useRef();
  const isSpeaking = useDetectedSpeech(stream, ref.current);

  React.useEffect(() => {
    wrapperRef.current = createNode(ref.current);

    wrapperRef.current.id = stream?.id;
    wrapperRef.current.style.border = '4px solid #669df6';
    wrapperRef.current.style.transition =
      'opacity 200ms cubic-bezier(0.4,0,1,1)';
    wrapperRef.current.style.borderRadius = '8px';
    wrapperRef.current.style.opacity = '0';
    wrapperRef.current.style.position = 'absolute';
    wrapperRef.current.style.top = '0';
    wrapperRef.current.style.height = '100%';
    wrapperRef.current.style.width = '100%';
    wrapperRef.current.style.zIndex = '3';

    ref.current.appendChild(wrapperRef.current);
  }, []);

  React.useEffect(() => {
    if (wrapperRef.current) {
      // eslint-disable-next-line no-param-reassign
      wrapperRef.current.style.opacity = isSpeaking ? 1 : 0;
    }
  }, [isSpeaking]);
}
