import {
  initUseStateDataType,
  getData,
  updateState,
  getState
} from '../../../lib/data-hook';
import { makeList, getItems, clear, isEqualItems } from './list';

export const useListProducts = initUseStateDataType(makeList);
export const getProducts = getData(getItems);
export const clearProducts = updateState((none, list) => clear(list), null);
export const isEqualProducts = (files, hook) =>
  isEqualItems(files, getState(hook));
export const isProductEmpty = (hookProducts) =>
  getProducts(hookProducts).length === 0;

export const onChangeProduct =
  (hookProducts) =>
  (product, index, name) =>
  ({ target: { value } }) => {
    const updatedProduct = { ...product, [name]: value };

    const newProducts = [...getProducts(hookProducts)];
    newProducts[index] = updatedProduct;

    hookProducts.setState({ items: newProducts });
  };

export const onChangeProductImport = (hookProducts) => (newProducts) => {
  const products = getProducts(hookProducts).map((product, index) => {
    const { count, price } = newProducts[index];

    return { ...product, count, price };
  });

  hookProducts.setState({ items: products });
};

export const isProductValid = (hookProducts, isPartResponseAllowed) => {
  //  At least one product valid if partResponse allowed
  if (isPartResponseAllowed) {
    return getProducts(hookProducts).reduce(
      (acc, item) => (item.count && item.price ? true : acc),
      false
    );
  }

  //  All products must be valid
  return getProducts(hookProducts).reduce(
    (acc, item) => (item.count && item.price ? acc : false),
    true
  );
};

export const getValidProducts = (hookProducts) =>
  getProducts(hookProducts).filter((product) => product.price && product.count);

export const onFocusPrice =
  (hookProducts, defaultProducts) => (product, index) => () => {
    if (product.count) {
      return;
    }

    const updatedProduct = { ...product, count: defaultProducts[index].count };

    const newProducts = [...getProducts(hookProducts)];
    newProducts[index] = updatedProduct;

    hookProducts.setState({ items: newProducts });
  };
