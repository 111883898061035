import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { assoc, isEmpty, move, update } from 'ramda';
import * as storage from '../../../../storage';
import { actions } from '../../../../reducers/task';
import TasksColumn from './components/block/TasksColumn';
import { EXTRA_TASK_OPTIONS, TASK_COMMANDS } from '../../constants';
import { useTranslate } from '../../../../../TranslateProvider';
import CustomDragLayer from './components/block/preview/CustomDragLayer';
import history from '../../../../../customHistory';
import * as getters from '../../../../getters';
import { removeURLParameter } from '../../../../../utils/utils';
import './style.css';

function calcCountTaskByStatuses(list) {
  return list.reduce(
    (acc, i) => ({ ...acc, [i.status]: acc[i.status] ? acc[i.status] + 1 : 1 }),
    {}
  );
}

function calcIndexOffset({ countTasks, statusList, status }) {
  let result = 0;
  for (let i = 0; i < statusList.length; i++) {
    const current = statusList[i];

    if (current === status) break;

    result += countTasks[current] || 0;
  }

  return result;
}

function prepareTaskList(tasks = [], badges = []) {
  if (isEmpty(badges)) return tasks;

  return tasks.map((task) =>
    badges.includes(task.id) ? { ...task, isShowBadge: true } : task
  );
}

const List = ({ statusList = [], taskList = [], badges = [] }) => {
  const dispatch = useDispatch();
  const { search: searchQuery } = window.location;
  const taskId = +new URLSearchParams(searchQuery).get('taskid');

  const t = useTranslate();

  const [tasks, setTasks] = useState(taskList);
  const [countTasks, setCountTasks] = useState(
    calcCountTaskByStatuses(taskList)
  );
  const selectedTask = useSelector(getters.getCurrentTask);

  useEffect(() => {
    setTasks(prepareTaskList(taskList, badges));
  }, [taskList, badges]);

  function getTasksByStatus(status) {
    return tasks.filter((task) => task.status === status);
  }

  function setTaskAsViewed(task) {
    if (task.isShowBadge) {
      dispatch(storage.setTaskAsViewed({ taskId: task.id }));
    }
  }

  const moveTask = ({ id, atIndex, atSection }) => {
    let updatedList = tasks;

    const currentTaskIdx = tasks.findIndex((task) => task.id === id);
    const currentTask = tasks[currentTaskIdx];

    if (atSection !== currentTask.status) {
      const updatedTask = assoc('status', atSection, currentTask);
      updatedList = update(currentTaskIdx, updatedTask, updatedList);
    }

    updatedList = move(currentTaskIdx, atIndex, updatedList);
    setTasks(updatedList);
  };

  const removeTask = ({ id, status }) => {
    if (confirm(t('taskManager.confirmRemoveTask'))) {
      dispatch(storage.removeTask({ id, status }));
    }
  };

  const handleDrop = ({ id, atIndex, fromSection, atSection, task }) => {
    const position =
      atIndex - calcIndexOffset({ countTasks, statusList, status: atSection });
    dispatch(
      storage.moveTask({
        id,
        fromStatus: fromSection,
        atStatus: atSection,
        position
      })
    );
    setTaskAsViewed(task);
  };

  const handleDropzone = (dropZone, item) => {
    const [newStatus, newIndex] = dropZone.path.split('-');
    moveTask({ id: item.id, atIndex: newIndex, atSection: newStatus });
    handleDrop({
      id: item.id,
      atIndex: newIndex,
      fromSection: item.status,
      atSection: newStatus,
      task: item.data
    });
  };

  const loadChunkSection = ({ limit, section }) => {
    dispatch(
      storage.loadCompanyTasksChunk({
        limit,
        offset: getTasksByStatus(section).length,
        sections: [section]
      })
    );
  };

  const collapseChunkSection = (section) => {
    dispatch(storage.collapseCompanyTasksSections([section]));
  };

  const taskHandler = (task) => (command) => {
    switch (command) {
      case TASK_COMMANDS.removeTask:
        removeTask(task);
        break;
      default:
        throw new Error(`No such handler for command: ${command}`);
    }
  };

  const selectTask = (task) => {
    dispatch(actions.setCurrentTask({ task }));

    setTaskAsViewed(task);

    const forceOpenEVT = new CustomEvent(
      'edit-task:FORCE_CHANGE_SIDEBAR_STATE',
      { detail: { isOpen: true } }
    );
    document.dispatchEvent(forceOpenEVT);
    history.push({
      search: `?taskid=${task.id}`
    });
  };

  const onClickByTask = (task) => () => selectTask(task);

  useEffect(() => {
    setCountTasks(calcCountTaskByStatuses(tasks));
    if (!selectedTask && taskId && tasks?.length > 0) {
      const currentTask = tasks.filter((task) => task.id === taskId)[0];

      if (currentTask) {
        selectTask(currentTask);
      } else {
        history.push({
          search: removeURLParameter(history.location.search, 'taskid')
        });
      }
    }
  }, [tasks]);

  return (
    <div className="task-list" id="task-list-container">
      <CustomDragLayer />
      {statusList.map((status) => (
        <TasksColumn
          key={status}
          title={status}
          status={status}
          tasks={getTasksByStatus(status)}
          handleDrop={handleDrop}
          handleDropzone={handleDropzone}
          moveTask={moveTask}
          loadChunkSection={loadChunkSection}
          collapseChunkSection={collapseChunkSection}
          extraOptions={EXTRA_TASK_OPTIONS}
          handler={taskHandler}
          onClick={onClickByTask}
          idxOffset={calcIndexOffset({ countTasks, statusList, status })}
        />
      ))}
    </div>
  );
};

export default List;
