import React, { Component } from 'react';
import {
  Button,
  Col,
  ControlLabel,
  Form,
  FormGroup,
  HelpBlock,
  Row
} from 'react-bootstrap';
import { fromJS } from 'immutable';
import Select from 'react-select';

import './styles/forms_type_edit-price.styl';
import './styles/forms_theme_default.styl';

import { Interpolate } from 'react-i18next';
import ImageControl from '../ImageControl';
import Helper from '../Helper';
import Checkbox from '../Checkbox';
import { InputText, TextArea } from '../FormControls';
import {
  validateEmail,
  validateSystemId,
  validateUrl
} from '../../utils/validateField';
import keyDebounce from '../../utils/debounce';
import CategoriesSelect from '../CategoriesSelect';

class FormEditCompany extends Component {
  constructor(props) {
    super(props);
    this.state = { isSetDefaultProps: false };
    this.onSave = this.onSave.bind(this);
    this.onChange = this.onChange.bind(this);
    this.onSetImg = this.onSetImg.bind(this);
    this.onDelImg = this.onDelImg.bind(this);
    this.onKeyPress = keyDebounce(this.onKeyPress.bind(this), 1000);
    this.setRole = this.setRole.bind(this);
    this.showChangeEmailModal = this.showChangeEmailModal.bind(this);
    this.roleChanged = this.roleChanged.bind(this);
    this.confirmRoleModal = this.confirmRoleModal.bind(this);
    this.setCategoriesId = this.setCategoriesId.bind(this);
  }

  static isEmailValid(value) {
    if (value && value.length) {
      return validateEmail(value);
    }
    return true;
  }

  static isSystemIdValid(value) {
    if (value && value.length) {
      return validateSystemId(value);
    }
    return true;
  }

  static isUrlValid(value) {
    if (value && value.length) {
      return validateUrl(value);
    }
    return true;
  }

  UNSAFE_componentWillMount() {
    this.setDefaultPropsToState();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.isUploaded(nextProps) && !this.state.isSetDefaultProps) {
      this.setDefaultPropsToState(nextProps);
      this.setState({ isSetDefaultProps: true });
    }
  }

  componentWillUnmount() {
    this.props.setSuccessMessage();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      prevProps?.company.get('updatedAt') !==
      this.props.company.get('updatedAt')
    ) {
      this.setDefaultPropsToState();
    }
  }

  onSetImg() {
    const { showModal, uploadLogo } = this.props;
    showModal('SAVE_IMG_URL', {
      submit: (url) => {
        this.setState({ logo: url });
        return Promise.resolve();
      },
      upload: async ({ file }) => {
        const src = await uploadLogo(file);
        this.setState({ logo: src });
      }
    });
  }

  onDelImg() {
    this.setState({ logo: null });
  }

  async onSave() {
    const { saveCompany, setSuccessMessage, user, gaSend } = this.props;
    const {
      categoriesId,
      name,
      url,
      description,
      logo,
      systemId,
      roles,
      language
    } = this.state;

    if (this.getShopValidationState()) {
      await gaSend({
        category: 'Company',
        action: 'about_company',
        label: user.get('email')
      });
      saveCompany(
        {
          categoriesId,
          name,
          url,
          description,
          logo,
          systemId,
          roles,
          language
        },
        'update'
      ).then(() => {
        setSuccessMessage({ key: 'The data was saved' });
        this.setDefaultPropsToState();
      });
    }
  }

  onChange(e, field) {
    this.setState({ [field]: e.target.value });
  }

  onKeyPress(field) {
    if (field === 'systemId') {
      this.validateSystemIdUnique(this.state.systemId);
    }
  }

  getShopValidationState() {
    if (!this.isUploaded()) return true;

    const { name, email, systemId } = this.state;
    return !!(name && email && systemId);
  }

  getProperty(field) {
    return this.state[field] || '';
  }

  setDefaultPropsToState(props) {
    const properties = props || this.props;
    const {
      name,
      email,
      url,
      description,
      logo,
      systemId,
      roles,
      type,
      language,
      categoriesId
    } = properties.company.toJS();
    this.setState({
      categoriesId,
      language,
      name: name || '',
      email: type === 'demo' ? 'demo_goodwix@goodwix.com' : email,
      url: url || '',
      description: description || '',
      logo,
      systemId,
      roles
    });
  }

  getFieldValidationState(field) {
    if (!this.isUploaded()) return true;

    return this.state[field];
  }

  validateEmailUnique(email) {
    const { isEmailUnique } = this.props;
    return isEmailUnique(email);
  }

  validateSystemIdUnique(systemId) {
    const { isSystemIdUnique } = this.props;
    return isSystemIdUnique(systemId);
  }

  inputProps(field, readOnly) {
    return {
      value: this.state[field],
      onChange: (e) => this.onChange(e, field),
      onBlur: () => this.trimField(field),
      onKeyUp: () => this.onKeyPress(field),
      onPaste: () => this.onKeyPress(field),
      viewRead: readOnly || !this.props.havePermissionToUpdate
    };
  }

  setCategoriesId(categoriesId) {
    this.setState({ categoriesId });
  }

  showHelpInfo(field) {
    const { t } = this.props;
    if (!this.getFieldValidationState(field)) {
      return (
        <HelpBlock id="invalidFieldMessage" style={{ color: 'red' }}>
          {t('Required fields empty')}
        </HelpBlock>
      );
    }
    return null;
  }

  showEmailUnique() {
    const { t, company } = this.props;
    if (
      !company.getIn(['newCompany', 'uniqueEmail']) &&
      company.get('email') !== this.state.email &&
      this.state.email !== ''
    ) {
      return (
        <HelpBlock id="invalidFieldMessage" style={{ color: 'red' }}>
          {t('Email already in use by another company')}
        </HelpBlock>
      );
    }
    return null;
  }

  showSystemIdUnique() {
    const { t, company } = this.props;
    if (
      !company.getIn(['newCompany', 'uniqueSystemId']) &&
      company.get('systemId') !== this.state.systemId
    ) {
      return (
        <HelpBlock id="invalidFieldMessage" style={{ color: 'red' }}>
          {t('Name already in use by another company')}
        </HelpBlock>
      );
    }
    return null;
  }

  showValidMailInfo() {
    if (!this.isUploaded()) return null;

    const { t } = this.props;
    if (!FormEditCompany.isEmailValid(this.state.email)) {
      return (
        <HelpBlock id="invalidFieldMessage" style={{ color: 'red' }}>
          {t('Wrong email format')}
        </HelpBlock>
      );
    }
    return null;
  }

  showValidUrlInfo() {
    if (!this.isUploaded()) return null;

    const { t } = this.props;
    if (!FormEditCompany.isUrlValid(this.state.url)) {
      return (
        <HelpBlock id="invalidFieldMessage" style={{ color: 'red' }}>
          {t('Wrong url format')}
        </HelpBlock>
      );
    }
    return null;
  }

  showValidSystemIdInfo() {
    if (!this.isUploaded()) return null;

    const { t } = this.props;
    if (!FormEditCompany.isSystemIdValid(this.state.systemId)) {
      return (
        <HelpBlock id="invalidFieldMessage" style={{ color: 'red' }}>
          {t('use_only_latin_or_numbers')}
        </HelpBlock>
      );
    }
    return null;
  }

  isUploaded(props) {
    const propsItem = props || this.props;
    return propsItem.company.get('isUploaded');
  }

  haveChanges() {
    if (!this.isUploaded()) return false;

    const companyProps = this.props.company.toJS();
    const mapState = fromJS({
      categoriesId: this.state.categoriesId,
      name: this.state.name,
      url: this.state.url,
      description: this.state.description,
      logo: this.state.logo,
      systemId: this.state.systemId,
      roles: this.state.roles,
      language: this.state.language
    });
    const mapProps = fromJS({
      categoriesId: companyProps.categoriesId,
      name: companyProps.name,
      url: companyProps.url || '',
      description: companyProps.description,
      logo: companyProps.logo,
      systemId: companyProps.systemId,
      roles: companyProps.roles,
      language: companyProps.language
    });
    return !mapState.equals(mapProps);
  }

  roleChanged() {
    const companyProps = this.props.company.toJS();
    const mapState = fromJS({
      roles: this.state.roles
    });
    const mapProps = fromJS({
      roles: companyProps.roles
    });
    return (
      !mapState.equals(mapProps) &&
      !(this.state.roles.customer && this.state.roles.supplier)
    );
  }

  trimField(field) {
    this.setState({ [field]: this.state[field].trim() });
  }

  allowSave() {
    const { company } = this.props;

    const { url } = this.state;
    const systemId = company.get('systemId');
    const isUniqueSystemId = company.getIn(['newCompany', 'uniqueSystemId']);

    return (
      !this.haveChanges() ||
      !(
        this.getShopValidationState() &&
        FormEditCompany.isSystemIdValid(systemId) &&
        isUniqueSystemId &&
        FormEditCompany.isUrlValid(url)
      ) ||
      !(this.state.roles.customer || this.state.roles.supplier)
    );
  }

  setRole(role) {
    const { roles } = this.state;
    roles[role] = !roles[role];
    this.setState({ roles });
  }

  showChangeEmailModal() {
    const { showModal } = this.props;
    showModal('CHANGE_COMPANY_EMAIL');
  }

  confirmRoleModal() {
    const { t, showModal } = this.props;

    showModal('SIMPLE_SUBMIT', {
      captionKey: 'Role change confirm',
      text: t('Role change warning'),
      textBtnConfirm: t('Confirm'),
      submitAction: () => this.onSave()
    });
  }

  render() {
    const {
      t,
      company,
      categoriesList,
      havePermissionToUpdate,
      showModal,
      getCurrentUser,
      fromMessenger
    } = this.props;
    const { categoriesId } = this.state;

    const companyRoles =
      company.get('roles') &&
      company.get('roles').size > 0 &&
      Object.keys(company.get('roles').toJS()).filter(
        (key) =>
          havePermissionToUpdate ||
          (!havePermissionToUpdate && this.state.roles[key])
      );

    if (!company.get('id')) {
      return (
        <div className="form-content-center">
          <Interpolate
            i18nKey="empty_company_list"
            create={
              <a
                onClick={() => {
                  showModal('EDIT_COMPANY_DIALOG', {
                    callback: () => getCurrentUser(),
                    fromMessenger
                  });
                }}>
                {t('toCreate')}
              </a>
            }
          />
        </div>
      );
    }

    return (
      <div className="form-content-center">
        <Form style={{ paddingRight: '10px', width: 800 }}>
          <Row style={{ height: '195px', position: 'relative' }}>
            <div className="profile-image-wrap">
              <ControlLabel className="label-profile control-label-row">
                {t('Main image')}
              </ControlLabel>
              <ImageControl
                name="cover"
                imgSrc={this.state.logo}
                onFileChoose={this.onSetImg}
                onImageRemove={this.onDelImg}
                className="img-profile"
                readOnly={!havePermissionToUpdate}
              />
            </div>
            <Col xs={12}>
              <Row>
                <Col xs={7}>
                  <ControlLabel className="control-label-row">{`${t(
                    'Company name'
                  )} `}</ControlLabel>

                  <FormGroup
                    controlId="name"
                    validationState={
                      this.getFieldValidationState('name') ? null : 'error'
                    }>
                    <InputText {...this.inputProps('name', true)} />
                    {!this.getFieldValidationState('name') && (
                      <HelpBlock className="pull-left">
                        {t('Required fields empty')}
                      </HelpBlock>
                    )}
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col xs={5}>
                  <ControlLabel className="control-label-row">
                    {`${t('Name in system')} (${t('only latin')}) `}
                    <span style={{ color: 'red' }}> * </span>
                    <Helper text="unique_name_company" />
                  </ControlLabel>
                  <InputText
                    maxLength="60"
                    value={company.get('systemId')}
                    {...this.inputProps('systemId')}
                  />
                  {this.showValidSystemIdInfo()}
                  {this.showHelpInfo('systemId')}
                  {this.showSystemIdUnique(this.state.systemId)}
                </Col>
                <Col xs={2}>
                  <ControlLabel
                    className="control-label-row"
                    style={{ marginBottom: 4 }}>
                    {`${t('Language')} `}
                    <Helper text="helper_language_message" />
                  </ControlLabel>
                  {!havePermissionToUpdate ? (
                    <div>{t(this.state.language)}</div>
                  ) : (
                    <Select
                      className="lang-select"
                      value={this.state.language}
                      onChange={(e) => this.setState({ language: e.value })}
                      options={[
                        { value: 'ru', label: 'Русский' },
                        { value: 'en', label: 'English' }
                      ]}
                      searchable={false}
                      clearable={false}
                    />
                  )}
                </Col>
              </Row>
              <Row className="pd-t-10">
                <Col xs={7}>
                  <ControlLabel className="control-label-row">
                    {t('Company email address')}
                  </ControlLabel>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between'
                    }}>
                    <div>
                      {company.get('type') === 'demo'
                        ? 'demo_goodwix@goodwix.com'
                        : company.get('email') ?? t('emailNotSet')}
                    </div>
                    <div>
                      {company.get('type') !== 'demo' &&
                      havePermissionToUpdate ? (
                        <a
                          href="#"
                          onClick={(e) => {
                            e.preventDefault();
                            this.showChangeEmailModal();
                          }}>
                          {t('Change email')}
                        </a>
                      ) : null}
                    </div>
                  </div>
                </Col>
              </Row>
              <Row className="pd-t-10">
                <Col xs={7}>
                  <ControlLabel className="control-label-row">
                    {t('Company URL')}
                  </ControlLabel>
                  <InputText
                    value={company.get('url')}
                    {...this.inputProps('url')}
                  />
                  {this.showValidUrlInfo()}
                </Col>
              </Row>
              {companyRoles && companyRoles.length > 0 && (
                <Row className="pd-t-10">
                  <Col xs={7}>
                    <ControlLabel className="control-label-row">
                      {t('Roles')}
                      <span style={{ color: 'red' }}> * </span>
                    </ControlLabel>
                    <div>
                      {companyRoles.map((key) => (
                        <div
                          style={{
                            display: 'inline-block',
                            paddingRight: '10px'
                          }}
                          key={key}>
                          <Checkbox
                            set={this.state.roles[key]}
                            onClick={() => this.setRole(key)}
                            readOnly={!havePermissionToUpdate}
                          />
                          <span className="profile-checkbox-label">
                            {t(key)}
                          </span>
                        </div>
                      ))}
                    </div>
                  </Col>
                </Row>
              )}
              <Row className="pd-t-10">
                <Col xs={12}>
                  <ControlLabel className="control-label-row">
                    {`${t('categories_of_goods_and_services')} `}
                    <Helper text="companyEdit.form.categoryHelper" />
                  </ControlLabel>
                  <CategoriesSelect
                    selectedList={categoriesId}
                    list={categoriesList}
                    onChoose={this.setCategoriesId}
                    disabled={!havePermissionToUpdate}
                  />
                </Col>
              </Row>
              <Row className="pd-t-10">
                <Col xs={12}>
                  <FormGroup controlId="descriptionText">
                    <ControlLabel className="control-label-row">
                      {t('Company description')}
                    </ControlLabel>
                    {havePermissionToUpdate && (
                      <h6 className="pull-right m-b-0" style={{ marginTop: 5 }}>
                        <small>
                          {this.state.description.length}
                          /1600
                        </small>
                      </h6>
                    )}
                    <TextArea
                      maxLength="1600"
                      rows="4"
                      placeholder={t('Company description')}
                      {...this.inputProps('description')}
                      style={{ height: 128 }}
                    />
                  </FormGroup>
                </Col>
              </Row>
              {havePermissionToUpdate && (
                <Row className="pd-t-10">
                  <span style={{ color: 'red', marginLeft: 15 }}>* </span>
                  <span style={{ fontSize: '12px' }}>
                    {t('Required fields')}
                  </span>
                </Row>
              )}
              {havePermissionToUpdate && (
                <Row>
                  <div style={{ marginTop: 35 }} className="form-button-center">
                    <Button
                      id="saveCatPropsBtn"
                      bsStyle="primary"
                      disabled={
                        this.allowSave() ||
                        !FormEditCompany.isSystemIdValid(this.state.systemId)
                      }
                      onClick={() => {
                        if (this.roleChanged()) {
                          return this.confirmRoleModal();
                        }
                        return this.onSave();
                      }}>
                      {t('Save changes')}
                    </Button>
                    <Button
                      id="cancelCatPropsBtn"
                      bsStyle="default"
                      style={{ marginLeft: '15px' }}
                      disabled={!this.haveChanges()}
                      onClick={() => this.setDefaultPropsToState()}>
                      {t('Cancel')}
                    </Button>
                  </div>
                </Row>
              )}
            </Col>
          </Row>
        </Form>
      </div>
    );
  }
}

export default FormEditCompany;
