import { List, fromJS } from 'immutable';

const initState = () => List();

const getNextMixOrder = (minOrder, categories) => {
  const minOrderOfCategories = categories.map((category) =>
    category.get('minOrder')
  );
  const sorted = minOrderOfCategories.sort((a, b) => a - b);

  const indexOfMinOrder = sorted.indexOf(minOrder);
  const lastIndex = minOrderOfCategories.size - 1;

  if (lastIndex === indexOfMinOrder) {
    return Infinity;
  }

  const nextOrder = sorted.get(indexOfMinOrder + 1);
  return nextOrder - 1;
};

const setMaxOrder = (categories) =>
  categories.map((category) => {
    if (category.get('type') === 1) {
      const maxOrder = getNextMixOrder(category.get('minOrder'), categories);
      return category.set('maxOrder', maxOrder);
    }
    return category;
  });

const setPublicPriceCategories = (data, { item = [] }) =>
  data.clear().merge(setMaxOrder(fromJS(item)));

const clearCategories = (data) => data.clear().merge(List());

const publicPurchaseCategories = (state = initState(), { type, payload }) => {
  switch (type) {
    case 'PUBLIC_PRICE:GET_PRICE_PURCHASE_CATEGORIES:SUCCESS':
      return setPublicPriceCategories(state, payload);
    case 'PUBLIC_PRICE:CATEGORIES':
      return clearCategories(state, payload);
    default:
      return state;
  }
};

export default publicPurchaseCategories;
