import { Map, Set, fromJS, List } from 'immutable';

// TODO: add jsdoc! for arguments

export const consProduct = (product) =>
  fromJS({ ...product, isSelected: false, count: 0, unit: null });

export const consListOfProducts = (listOfProducts = []) =>
  listOfProducts.reduce(
    (acc, product) => acc.set(product.id, consProduct(product)),
    Map()
  );

export const getRawProducts = (products = []) =>
  products.map((product) => ({
    ...product,
    isSelected: false,
    count: 0,
    unit: null
  }));

export const consFoundProducts = (data, newData) =>
  newData && data
    ? Map({
        products: consListOfProducts(newData.products),
        rawProducts: getRawProducts(newData.products),
        selected: data.get('selected') || Set(),
        counts: data.get('counts') || List(),
        productIds: Set(newData.ids),
        size: newData.size,
        limit: newData.limit,
        offset: newData.offset,
        search: newData.search,
        productsCount: newData.productsCount,
        categoriesToAdd: newData.categoriesToAdd,
        isEnabledFilterSelected: data.get('isEnabledFilterSelected'),
        orderBy: newData.orderBy,
        direction: newData.direction
      })
    : Map({
        products: consListOfProducts(),
        rawProducts: getRawProducts(),
        selected: Set(),
        counts: List(),
        productIds: Set(),
        size: 0,
        offset: 0,
        limit: 20,
        search: '',
        productsCount: 0,
        isEnabledFilterSelected: false,
        orderBy: 'name',
        direction: 'ASC'
      });

export const getProductsOfFoundProducts = (foundProducts) =>
  foundProducts.get('products');

export const getProductOfFoundProducts = (foundProducts, productId) =>
  foundProducts.getIn(['products', productId]);

export const getTotalProducts = (foundProducts) => foundProducts.get('size');

export const getLimit = (foundProducts) => foundProducts.get('limit');

export const getOffset = (foundProducts) => foundProducts.get('offset');

export const getQuery = (foundProducts) => foundProducts.get('search');

export const getPropOfItem = (product, prop) => product.get(prop);
