import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Interpolate, translate } from 'react-i18next';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { withRouter } from '../hoc/withRouter';

import MessengerOther from '../components/Layouts/MessengerOther';

import WorkspaceHeader from '../components/WorkspaceHeader/WorkspaceHeader';
import ContainersDefault from '../components/Containers/ContainersDefault';
import Table from '../components/TableNew';

import * as modalActions from '../action-creators/modal';
import * as messageActions from '../action-creators/message';
import * as requestsActions from '../action-creators/purchaseRequests';
import * as serviceActions from '../action-creators/services';
import * as suppliersActions from '../action-creators/suppliers';

import { formatRequestNumber } from '../utils/utils';
import { InteractiveHelper } from '../components/InteractiveHelper';
import { Popover } from '../components/PurchaseRequests/NewRequest/components/Popover';

class MPurchaseRequestResponses extends Component {
  get breadcrumbs() {
    const {
      t,
      match: {
        params: { requestId }
      },
      history
    } = this.props;
    const items = [
      {
        name: t('Purchase requests'),
        element: (
          <a
            onClick={() =>
              history.push({
                pathname: '/requests/my',
                state: { myRequestsPage: true }
              })
            }>
            {t('Purchase requests')}
          </a>
        ),
        disabled: !requestId
      },
      { name: `${t('Request')} № ${formatRequestNumber(requestId)}` }
    ];
    return items;
  }

  UNSAFE_componentWillMount() {
    const {
      getRequestResponses,
      match: {
        params: { requestId }
      }
    } = this.props;
    getRequestResponses(requestId);
  }

  getCompanyName(attrs = {}) {
    const {
      t,
      havePermissionToRead,
      match: { url }
    } = this.props;
    const { fromInfo, fromDeleted, from, purchaseRequest } = attrs;
    const companyName = `${fromInfo.name}${
      fromDeleted ? ` (${t('inactive')})` : ''
    }`;
    if (!havePermissionToRead) {
      return <span>{companyName}</span>;
    }
    if (purchaseRequest) {
      return (
        <div style={{ display: 'flex' }}>
          <div style={{ marginTop: 1 }}>
            <Link
              to={`${url}/${from}`}
              style={{
                verticalAlign: 'middle',
                color: `${fromDeleted ? '#808080' : ''}`,
                textDecoration: 'none',
                fontWeight: 500
              }}>
              {companyName}
            </Link>
          </div>
          <div style={{ marginTop: 2, marginLeft: 20 }}>
            <InteractiveHelper
              imgSrc="/img/message.svg"
              iconWidth={18}
              placement="right">
              <Popover text={purchaseRequest.text} />
            </InteractiveHelper>
          </div>
        </div>
      );
    }
    return companyName;
  }

  getColumns() {
    const { badgeSocket: socket } = this.context;
    const {
      t,
      havePermissionToUpdate,
      confirmAddSupplier,
      rejectAddSupplier,
      user,
      history
    } = this.props;
    const userName = `${user.get('currentCompany')}.${user.get('id')}`;

    const columns = [
      Table.ColText({
        title: t('Name'),
        showTooltip: false,
        accessor: ({ attrs, from, fromInfo }) =>
          this.getCompanyName({ ...attrs, from, fromInfo })
      }),
      Table.ColDate({ title: t('Response date'), accessor: 'responseDate' }),
      Table.ColStatus({
        accessor: 'status',
        statusColors: { pending: '#FD4F53' }
      })
    ];

    if (havePermissionToUpdate) {
      columns.push(
        Table.ColText({
          showTooltip: false,
          accessor: ({ id, attrs, status }) =>
            status !== 'pending' ? (
              ' '
            ) : (
              <button
                id="addRequest"
                type="button"
                className="btn btn-primary"
                disabled={attrs.fromDeleted}
                style={{ fontSize: 12 }}
                onClick={(e) => {
                  e.stopPropagation();
                  if (attrs.fromDeleted) return;
                  confirmAddSupplier(id, true).then((res) => {
                    socket.emit('getBadges', userName);
                    if (res.supplierSystemId) {
                      history.push(`/suppliers/${res.supplierSystemId}`);
                    }
                  });
                }}>
                {t('Add supplier')}
              </button>
            ),
          width: 200
        }),
        Table.ColText({
          showTooltip: false,
          accessor: ({ id, status }) =>
            status !== 'pending' ? (
              ' '
            ) : (
              <button
                id="rejectRequest"
                type="button"
                className="btn btn-default"
                style={{ fontSize: 12 }}
                onClick={(e) => {
                  e.stopPropagation();
                  rejectAddSupplier(id, true).then(() =>
                    socket.emit('getBadges', userName)
                  );
                }}>
                {t('Reject request')}
              </button>
            ),
          width: 200
        })
      );
    }

    return columns;
  }

  checkConfirmedResponses(responses = []) {
    return responses.some((i) => i.get('status') === 'confirmed');
  }

  render() {
    const {
      t,
      history,
      responses,
      match: {
        params: { requestId }
      }
    } = this.props;

    return (
      <MessengerOther
        title={t('Purchase requests')}
        breadcrumbs={this.breadcrumbs}
        content={
          <ContainersDefault>
            <WorkspaceHeader
              navigation={{
                activeKey: 'responses',
                items: [
                  { eventKey: 'view', name: t('My purchase request') },
                  { eventKey: 'responses', name: t('Responses') }
                ],
                handleTabChange: (tab) =>
                  history.push(`/requests/my/${requestId}/${tab}`)
              }}
            />
            <div>
              <div style={{ marginBottom: 20 }}>
                <p className="text-description text-description-in-tab">
                  {t('no_responses')}
                </p>
                {this.checkConfirmedResponses(responses) && (
                  <p className="text-description text-description-in-tab">
                    <Interpolate
                      i18nKey="suppliers_request_offer"
                      clickHere={
                        <Link to={`/suppliers/list?requestId=${requestId}`}>
                          {t('click here')}
                        </Link>
                      }
                    />
                  </p>
                )}
              </div>
              {responses && responses.size > 0 && (
                <Table columns={this.getColumns()} data={responses} />
              )}
            </div>
          </ContainersDefault>
        }
      />
    );
  }
}

MPurchaseRequestResponses.contextTypes = {
  badgeSocket: PropTypes.object
};

export default withRouter(
  connect(
    (state) => ({
      user: state.getIn(['user', 'user']),
      company: state.get('company'),
      responses: state.getIn(['purchaseRequests', 'responses']),
      havePermissionToUpdate: state.getIn([
        'user',
        'user',
        'permissions',
        'purchaseRequests',
        'update'
      ]),
      havePermissionToRead: state.getIn([
        'user',
        'user',
        'permissions',
        'purchaseRequests',
        'read'
      ])
    }),
    (dispatch) =>
      bindActionCreators(
        {
          showModal: modalActions.showModal,
          setSuccessMessage: messageActions.setSuccessMessage,
          hideModalDialog: modalActions.hideModalDialog,
          gaSend: serviceActions.gaSend,
          getRequestResponses: requestsActions.getRequestResponses,
          confirmAddSupplier: suppliersActions.confirmAddSupplier,
          rejectAddSupplier: suppliersActions.rejectAddSupplier
        },
        dispatch
      )
  )(translate(['ui'], { wait: true })(MPurchaseRequestResponses))
);
