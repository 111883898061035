import { isNil, isEmpty } from 'ramda';
import { isType } from 'core/lib';
import { PURCHASE_REQUESTS_MESSAGE_TYPE } from 'core/data/light/purchaseRequestChannel';
import { isNewsMessage } from 'core/data/light/message/msg-news';
import { isMsgSystem } from '../../../../../data-type/core/isMsgSystem';
import getMsgSystemText from '../../../../../SystemMsgTranslates';
import { convertUrl } from '../../../../../modules/files/utils';

const getAvatarSrcOrDefault = (avatarable) =>
  avatarable.avatarSrc ? convertUrl(avatarable.avatarSrc) : '/img/user.svg';

const isOwnMsg = (lastMessage, employeeId) =>
  (isType('MsgUser', lastMessage) ||
    isType('MsgUnsent', lastMessage) ||
    isType('MsgReply', lastMessage) ||
    isType('MsgVideoRoomWasCreated', lastMessage) ||
    isType('MsgForwarded', lastMessage)) &&
  lastMessage.employeeId === employeeId;

const isMessageEmpty = (message) => !message.msg && !message.files.length;

// Suggestion take out all types in object such as enum and all manipulations with name organized like switch case in reducer

const getUserName = (t, lastMessage, employeeId) => {
  if (isType('MsgReply', lastMessage) || isType('MsgForwarded', lastMessage)) {
    const { userName } = lastMessage;
    const firstName = (userName || '').split(' ')[1];
    return isOwnMsg(lastMessage, employeeId)
      ? `${t('chat-widget.chat.you')}: `
      : `${firstName}: `;
  }
  if (!isType('MsgUser', lastMessage)) return '';

  const { userName } = lastMessage;
  const firstName = (userName || '').split(' ')[1];
  return isOwnMsg(lastMessage, employeeId)
    ? `${t('chat-widget.chat.you')}: `
    : `${firstName}: `;
};

const getAvatarSrcForSystemMsg = (lastMessage, channelMember) => {
  const message = lastMessage;
  let employeeId;
  if (
    isType('MsgCreatedChannel', message) ||
    isType('MsgLeftFromChannel', message) ||
    isType('MsgRenamedChannel', message) ||
    isType('MsgRegainedStatus', message) ||
    isType('MsgVideoRoomWasCreated', message)
  ) {
    employeeId = message.employeeId;
  }
  if (
    isType('MsgAddedToChannel', message) ||
    isType('MsgExcludedFromChannel', message)
  ) {
    employeeId = message.whoEmployeeId;
  }

  if (!employeeId) return '';

  return getAvatarSrcOrDefault(channelMember);
};

function isShowAvatar(message) {
  return (
    !isType('MsgSubscribeToChannel', message) &&
    !isType('MsgUpdateSubscribeToChannel', message) &&
    !isType('MsgUnsubscribeFromChannel', message) &&
    !isType('MsgAcceptResponse', message) &&
    !isType('MsgRejectResponse', message) &&
    !isType('MsgVideoRoomWasCreated', message) &&
    !isType('MsgSendToReworkResponse', message) &&
    !isType(PURCHASE_REQUESTS_MESSAGE_TYPE, message)
  );
}

function getLastMsgText(t, chat, message) {
  if (
    isType('MsgForwarded', message) &&
    message.originMsgType === PURCHASE_REQUESTS_MESSAGE_TYPE
  ) {
    return message.originMsgData.title;
  }

  if (
    isType('MsgVideoRoomWasCreated', message) ||
    (isType('MsgForwarded', message) &&
      message.originMsgType === 'MsgVideoRoomWasCreated')
  ) {
    return `${t(
      `chat-widget.system-msg.${chat.type}.video-room-was-created`
    )}${t('chat-widget.system-msg.join')}`;
  }

  return message.msg;
}

const getMsg = (
  t,
  lastMessage,
  chatType,
  containsUnreadMsg,
  employeeId,
  channelMember,
  chat
) => {
  const message = lastMessage;
  if (isEmpty(message) || isNil(message)) return null;

  if (
    isType('MsgUser', message) ||
    isType('MsgForwarded', message) ||
    isType('MsgVideoRoomWasCreated', message) ||
    (isType('MsgUnsent', message) &&
      (chatType === 'Channel' || chatType === 'Topic' || chatType === 'Dialog'))
  ) {
    const isOwn = isOwnMsg(lastMessage, employeeId);

    return {
      type: 'User',
      isUnread: containsUnreadMsg,
      isOwnMsg: isOwn,
      avatar: {
        show: isOwn,
        src: getAvatarSrcOrDefault(lastMessage),
        alt: lastMessage.userName
      },
      userName: getUserName(t, lastMessage, employeeId),
      msg: getLastMsgText(t, chat, message, employeeId),
      unsentIcon: isType('MsgUnsent', message),
      hidden: lastMessage.hidden
    };
  }

  if (
    isType('MsgReply', message) &&
    (chatType === 'Dialog' || chatType === 'Channel' || chatType === 'Topic')
  ) {
    const originLastMessage = isMessageEmpty(message)
      ? lastMessage.targetMsg
      : lastMessage;
    const isOwn = isOwnMsg(originLastMessage, employeeId);

    return {
      type: 'User',
      isUnread: containsUnreadMsg,
      isOwnMsg: isOwn,
      avatar: {
        show: isOwn,
        src: getAvatarSrcOrDefault(originLastMessage),
        alt: originLastMessage.userName
      },
      userName: getUserName(t, originLastMessage, employeeId),
      msg: originLastMessage.msg,
      unsentIcon: isType('MsgUnsent', message),
      hidden: lastMessage.hidden
    };
  }

  if (isMsgSystem(message)) {
    return {
      type: 'System',
      isUnread: false,
      avatar: {
        show: isShowAvatar(message),
        src: getAvatarSrcForSystemMsg(lastMessage, channelMember),
        alt: 'system msg'
      },
      userName: '',
      msg: getMsgSystemText(t, chat, message, employeeId),
      hidden: lastMessage.hidden
    };
  }

  if (isType(PURCHASE_REQUESTS_MESSAGE_TYPE, message)) {
    return {
      type: 'Bot',
      isUnread: containsUnreadMsg,
      avatar: {
        show: isShowAvatar(message),
        src: message.customerLogo,
        alt: 'customer avatar'
      },
      userName: 'Bot: ',
      msg: message.title,
      hidden: lastMessage.hidden
    };
  }

  if (isNewsMessage(message)) {
    return {
      type: 'Bot',
      isUnread: containsUnreadMsg,
      avatar: {
        show: false
      },
      userName: '',
      msg: message.data.summary
    };
  }

  return null;
};

export default getMsg;
