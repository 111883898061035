import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { translate } from 'react-i18next';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Button } from 'react-bootstrap';
import { isEmpty } from 'ramda';

import { withRouter } from '../../hoc/withRouter';
import Pagination from '../Pagination';
import { Select } from '../FormControls';
import Loader from '../Loader';

import * as serviceActions from '../../action-creators/services';
import * as requestsActions from '../../action-creators/purchaseRequests';
import * as messageActions from '../../action-creators/message';
import * as modalActions from '../../action-creators/modal';
import * as prfStorage from '../ChatWidget/PurchaseRequestsFilter/storage';
import * as prfGetters from '../ChatWidget/PurchaseRequestsFilter/getters';
import * as prGetters from './getters';

import { makeFilters } from '../../utils/utils';
import { isMessengerService } from '../../lib/goodwix';
import RequestCard from './Cards/Request';
import * as dictionariesGetters from '../../modules/dictionaries/getters';
import * as dictionariesStorage from '../../modules/dictionaries/storage';
import Icons from './common/Icons';
import * as localStorageLib from '../../storage/lib/LS';

import './style.css';
import SearchBlockWithMemory from './common/SearchBlockWithMemory';

const FILTERS_KEY = 'purchase-requests-filter';
const SEARCH_PARAM_KEY = 'all-purchase-requests-search';

function filterTranslate(t, tKey) {
  return t(`allPurchaseRequests.sortFilter.${tKey}`);
}

class AllRequests extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loaded: false,
      lastLanguage: ''
    };
    this.translateBranches = this.translateBranches.bind(this);
    this.selectNextPagination = this.selectNextPagination.bind(this);
    this.onSearch = this.onSearch.bind(this);
    this.goToSubscribePage = this.goToSubscribePage.bind(this);
    this.toggleShowOnlyBookmarked = this.toggleShowOnlyBookmarked.bind(this);
  }

  UNSAFE_componentWillMount() {
    const { getAllRequests, checkSubscribeToPRChannel, getCategories, user } =
      this.props;
    getCategories();
    const filter = localStorageLib.getData(
      `${FILTERS_KEY}--u-${user.get('id')}`
    );
    const search = localStorageLib.getData(this.LSSearchParamKey) || '';
    const filters = filter ? makeFilters(filter) : [];
    getAllRequests({ initialRequest: true, filters, search });
    checkSubscribeToPRChannel();
  }

  UNSAFE_componentWillReceiveProps(props) {
    const { size, user } = props;
    const {
      getAllRequests,
      limit,
      offset,
      search,
      order,
      direction,
      showMode
    } = this.props;
    if (size >= 0 && !this.state.loaded) {
      this.setState({ loaded: true });
    }
    if (user.get('language') && !this.state.lastLanguage) {
      this.setState({ lastLanguage: user.get('language') });
    }
    if (
      this.state.lastLanguage &&
      user.get('language') !== this.state.lastLanguage
    ) {
      this.setState({ lastLanguage: user.get('language') });
      const filter = localStorageLib.getData(FILTERS_KEY);
      const filters = filter ? makeFilters(filter) : [];
      getAllRequests({
        limit,
        offset,
        search,
        filters,
        order,
        direction,
        selectedCategoriesId: this.getSelectedCategoriesId(),
        showMode,
        lang: user.get('language')
      });
    }
  }

  componentWillUnmount() {
    const { reset } = this.props;
    reset();
  }

  get userPermission() {
    const { user } = this.props;

    const permissions = user.getIn(['permissions', 'purchaseRequests']);

    if (!permissions) {
      return {
        read: false,
        update: false,
        delete: false
      };
    }

    return permissions.toJS();
  }

  incrementCommentCount(requestId) {
    const { incrementRequestCommentCount } = this.props;
    if (requestId) {
      incrementRequestCommentCount(requestId);
    }
  }

  translateBranches(industry) {
    const { t } = this.props;
    if (!industry) return '';
    const branches = industry.split('| ');
    return branches.map((i) => t(i)).join(', ');
  }

  selectNextPagination(offset, limit) {
    const { getAllRequests, order, direction, showMode, search } = this.props;

    const filter = localStorageLib.getData(FILTERS_KEY);
    const filters = filter ? makeFilters(filter) : [];

    getAllRequests({
      limit,
      offset,
      order,
      search,
      direction,
      selectedCategoriesId: this.getSelectedCategoriesId(),
      showMode,
      filters
    });
  }

  get LSSearchParamKey() {
    const { user } = this.props;

    if (user.size > 0) {
      return `${SEARCH_PARAM_KEY}--u-${user.get('id')}`;
    }

    return SEARCH_PARAM_KEY;
  }

  onSearch(search = '') {
    const { getAllRequests, limit, order, direction, showMode } = this.props;
    const filter = localStorageLib.getData(FILTERS_KEY);
    const filters = filter ? makeFilters(filter) : [];

    localStorageLib.setData(this.LSSearchParamKey, search);

    getAllRequests({
      limit,
      search,
      filters,
      order,
      direction,
      selectedCategoriesId: this.getSelectedCategoriesId(),
      showMode
    });
  }

  checkResponse({ r, company, t }) {
    if (r.getIn(['requestInfo', 'suppliers'])) {
      const supplierInfo = r
        .getIn(['requestInfo', 'suppliers'])
        .find((i) => i.get('companyId') === company.get('id'));

      if (supplierInfo) {
        return (
          <div
            style={{
              display: 'table-cell',
              verticalAlign: 'top',
              width: '50px'
            }}>
            <div>{`${t('You responded')}: ${new Date(
              supplierInfo.get('date')
            ).toLocaleDateString()}`}</div>
          </div>
        );
      }

      return null;
    }

    return null;
  }

  getSelectedCategoriesId() {
    return this.props.selectedCategoriesId.toJS();
  }

  getAllRequest = ({ value }) => {
    const { limit, offset, search, direction, getAllRequests, showMode } =
      this.props;

    getAllRequests({
      limit,
      offset,
      search,
      order: value,
      selectedCategoriesId: this.getSelectedCategoriesId(),
      direction: direction === 'DESC' ? 'ASC' : 'DESC',
      showMode
    });
  };

  goToSubscribePage() {
    const { navigate } = this.props;

    navigate('/requests/subscribe');
  }

  toggleShowOnlyBookmarked() {
    const {
      limit,
      order,
      offset,
      search,
      direction,
      getAllRequests,
      showMode
    } = this.props;
    const filter = localStorageLib.getData(FILTERS_KEY);
    const filters = filter ? makeFilters(filter) : [];

    const updatedShowMode = new Set(showMode);
    if (!updatedShowMode.delete('bookmark')) updatedShowMode.add('bookmark');

    getAllRequests({
      limit,
      offset,
      search,
      order,
      selectedCategoriesId: this.getSelectedCategoriesId(),
      direction: direction === 'DESC' ? 'ASC' : 'DESC',
      showMode: Array.from(updatedShowMode),
      filters
    });
  }

  get subscribeBtnText() {
    const { t, isSubscribedToPRChannel } = this.props;

    return isSubscribedToPRChannel
      ? t('change_subscribe')
      : t('subscribe_to_update');
  }

  get isShowSubscribeBtn() {
    return isMessengerService();
  }

  get icons() {
    const { showMode = [] } = this.props;

    return [
      {
        icon: 'favorite',
        size: 'lg',
        isSelect: new Set(showMode).has('bookmark'),
        onClick: this.toggleShowOnlyBookmarked
      }
    ];
  }

  render() {
    const {
      t,
      requests,
      size,
      limit,
      offset,
      selectedCategoriesId,
      search,
      order,
      filterApplied,
      categoriesList,
      toggleBookmarkRequest,
      showMode,
      company
    } = this.props;

    if (!this.state.loaded) return <Loader theme="page" />;

    const isNoRequests =
      selectedCategoriesId.isEmpty() &&
      search === '' &&
      isEmpty(showMode) &&
      !filterApplied;

    return (
      <div>
        <>
          <div
            style={{
              marginBottom: 25,
              display: 'flex',
              flexWrap: 'wrap',
              justifyContent: 'space-between',
              rowGap: '12px'
            }}>
            <div style={{ display: 'flex', marginRight: 12 }}>
              <SearchBlockWithMemory
                value={search}
                placeholder={`${t('Company')}/${t(
                  'Purchase request number'
                )}/${t('key_words')}`}
                onSearch={this.onSearch}
                width={365}
              />
              <div style={{ display: 'flex', marginLeft: 10, marginRight: 28 }}>
                <span
                  style={{
                    marginTop: 8,
                    marginRight: 10,
                    whiteSpace: 'nowrap'
                  }}>
                  {t('Sort by')}
                </span>
                <Select
                  id="sort-select"
                  className="sort-select"
                  value={
                    [
                      'default',
                      'lastPublishedDate',
                      'updatedAt'
                      // 'date'
                    ].includes(order)
                      ? order
                      : null
                  }
                  onChange={this.getAllRequest}
                  options={[
                    { value: 'default', label: filterTranslate(t, 'default') },
                    {
                      value: 'lastPublishedDate',
                      label: filterTranslate(t, 'publicationDate')
                    },
                    {
                      value: 'updatedAt',
                      label: filterTranslate(t, 'updateDate')
                    }
                    // {
                    //   value: 'date',
                    //   label: filterTranslate(t, 'deliveryDate')
                    // }
                  ]}
                  placeholder={filterTranslate(t, 'default')}
                  clearable={false}
                  searchable={false}
                  style={{ width: 200 }}
                />
              </div>
              <Icons buttons={this.icons} />
            </div>
            <Button
              className="btn-go-pr-subscribe"
              onClick={this.goToSubscribePage}>
              {this.subscribeBtnText}
            </Button>
          </div>
          {requests && size ? (
            <>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '8px'
                }}>
                {requests.map((request) => (
                  <RequestCard
                    request={request}
                    categories={categoriesList}
                    company={company}
                    onToggleBookmark={toggleBookmarkRequest}
                    key={request.get('id')}
                    haveUpdatePermission={this.userPermission.update}
                  />
                ))}
              </div>
              <div style={{ marginTop: 12 }} />
              <Pagination
                totalSize={size}
                limit={limit || 20}
                offset={offset || 0}
                nextPage={this.selectNextPagination}
                isShowItemsOnPage
              />
            </>
          ) : (
            <>
              <div style={{ marginTop: 12 }} />
              <p>
                {t(
                  isNoRequests
                    ? 'Requests empty text'
                    : 'Requests filters empty text'
                )}
              </p>
            </>
          )}
        </>
      </div>
    );
  }
}

AllRequests.contextTypes = {
  badgeSocket: PropTypes.object
};

export default withRouter(
  connect(
    (state) => ({
      user: state.getIn(['user', 'user']),
      company: state.get('company'),
      requests: state.getIn(['purchaseRequests', 'publishedRequests']),
      size: state.getIn(['purchaseRequests', 'size']),
      limit: state.getIn(['purchaseRequests', 'limit']),
      offset: state.getIn(['purchaseRequests', 'offset']),
      search: state.getIn(['purchaseRequests', 'search']),
      order: state.getIn(['purchaseRequests', 'order']),
      direction: state.getIn(['purchaseRequests', 'direction']),
      selectedCategoriesId: state.getIn([
        'purchaseRequests',
        'selectedCategoriesId'
      ]),
      supportUser: state.getIn(['chat', 'supportUser']),
      filterApplied: state.getIn(['purchaseRequests', 'filterApplied']),
      showMode: prGetters.getShowMode(state),
      categoriesList: dictionariesGetters.getPurchaseRequestsCategories(state),
      isSubscribedToPRChannel: prfGetters.isSubscribeToPRChannel(state)
    }),
    (dispatch) =>
      bindActionCreators(
        {
          getCategories: dictionariesStorage.getPurchaseRequestsCategories,
          showModal: modalActions.showModal,
          setSuccessMessage: messageActions.setSuccessMessage,
          hideModalDialog: modalActions.hideModalDialog,
          gaSend: serviceActions.gaSend,
          getRequest: requestsActions.getRequest,
          getAllRequests: requestsActions.getAllRequests,
          toggleBookmarkRequest: requestsActions.toggleBookmarkRequest,
          turnRequestNotification: requestsActions.turnRequestNotification,
          reset: requestsActions.reset,
          checkSubscribeToPRChannel: prfStorage.checkSubscribe
        },
        dispatch
      )
  )(translate(['ui'], { wait: true })(AllRequests))
);
