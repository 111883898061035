import React from 'react';
import ReactDOM from 'react-dom';
import ReactGA from 'react-ga';
import ReactGTM from 'react-gtm-module';
import { AppContainer } from 'react-hot-loader';
import * as Sentry from '@sentry/browser';
import { unstable_HistoryRouter as BrowserRouter } from 'react-router-dom';

import configureStore from './store';
import customHistory from './customHistory';

import App from './app-chat';
// NOTE: if you change file ./app-chat
// don't forget to change bottom
// module.hot.accept('./app-chat', () => {
//   const NextApp = require('./app-chat').default;
// otherwise hot reload will break.
//
// Don't create varialbe because show warning
//   WARNING in ./src/root-chat.jsx
//   70:18-36 Critical dependency: the request of a dependency is an expression

import pasteImageReader from './pasteImageReader';
import { registerPdfWorker } from './lib/PDFViewer/Worker';

if (process.env.NODE_ENV !== 'development') {
  Sentry.init({
    dsn: 'https://81883cd93ee6481ca2ebf4141bc19447@sentry.goodwix.com/2',
    environment: process.env.NODE_ENV
  });
}

pasteImageReader(jQuery); // eslint-disable-line
registerPdfWorker();

const store = configureStore();

const app = document.createElement('div');
app.setAttribute('id', 'app');
app.setAttribute('class', 'root');
document.body.insertBefore(app, document.body.firstChild);

const modalContainer = document.createElement('div');
modalContainer.setAttribute('id', 'modal-container');

document.body.insertBefore(modalContainer, document.body.lastChild);

if (process.env.GA_ENABLED) {
  ReactGA.initialize(process.env.GA_TRACK_MESSENGER);
}

if (process.env.GTM_ENABLED) {
  ReactGTM.initialize({ gtmId: process.env.GTM_TRACK_MESSENGER });
}

const render = (Component) => {
  ReactDOM.render(
    <AppContainer>
      <BrowserRouter history={customHistory}>
        <Component store={store} history={customHistory} />
      </BrowserRouter>
    </AppContainer>,
    app
  );
};

render(App);

if (module.hot) {
  module.hot.accept('./app-chat', () => {
    const NextApp = require('./app-chat').default;
    render(NextApp);
  });
}
