import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { translate } from 'react-i18next';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { checkInvites } from '../storage/invites.storage';
import * as service from '../action-creators/services';
import { isMessengerService, getPageRegToken } from '../lib/goodwix';

import ErrorPartnerToken from '../components/ErrorPartnerToken';
import Loader from '../components/Loader';
import { logModule } from '../utils/logger';

const log = logModule.extend('InviteTokenVerification');
class Validate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isValidatingToken: true
    };
  }

  async UNSAFE_componentWillMount() {
    const {
      match: {
        params: { companyId, tokenLink }
      },
      validateInviteToken
    } = this.props;
    await validateInviteToken(companyId, tokenLink);
    this.setState({ isValidatingToken: false });
  }

  render() {
    const {
      history,
      match: {
        params: { companyId, tokenLink }
      },
      isTokenValid,
      userId
    } = this.props;
    const { badgeSocket: socket } = this.context;

    if (!this.state.isValidatingToken) {
      log('!this.state.isValidatingToken');
      if (isTokenValid && !userId) {
        log('isTokenValid && !userId');
        location.replace(getPageRegToken(tokenLink, 'link'));
        return <div />;
      }

      if (isTokenValid && userId) {
        log('isTokenValid && userId');
        checkInvites(tokenLink).then(() => {
          log('checkInvites success');
          socket.emit('updateCompanyBadges', JSON.stringify({ companyId }));
          if (isMessengerService()) {
            log('isMessengerService()');
            history.replace('/');
          } else {
            log('else isMessengerService()');
            history.replace('/startpage');
          }
        });
        return <div />;
      }

      if (!isTokenValid) {
        log('!isTokenValid');
        return <ErrorPartnerToken />;
      }
    }
    log('else main render()');
    return <Loader theme="page" />;
  }
}

Validate.contextTypes = {
  badgeSocket: PropTypes.object
};

export default connect(
  (state) => ({
    isTokenValid: state.getIn(['service', 'isTokenValid']),
    userId: state.getIn(['service', 'userId'])
  }),
  (dispatch) =>
    bindActionCreators(
      {
        validateInviteToken: service.validateInviteToken
      },
      dispatch
    )
)(translate(['ui'], { wait: true })(Validate));
