import React from 'react';
import cn from 'classnames';
import styles from './ButtonIcon.module.css';

export const ButtonIcon = ({ icon, isActive = false, className, ...props }) => (
  <button
    type="button"
    className={cn(styles.btn, className, {
      [styles.active]: isActive
    })}
    {...props}>
    {icon}
  </button>
);
