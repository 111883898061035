import { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import fetch from 'isomorphic-fetch';
import { isNil } from 'ramda';
import { badgesQRY } from 'core/messages/taskBadges';
import { chatSocket } from './storage/chat.storage';
import { channelSocket } from './storage/channel.storage';
import { notificationSocket } from './storage/notifications.storage';
import { badgeSocket } from './storage/badge.storage';
import { dataSocket } from './storage/data.storage';
import { monitoringSocket } from './storage/monitoring.storage';
import * as blockedContact from './components/ChatWidget/modules/blockedContact';
import allSubscribes from './subscribes';
import {
  subscribeToDeleteContactBlock,
  unSubscribeToDeleteContactBlock,
  unSubscribeToUpdateContactListByInvite
} from './components/ChatWidget/modules/contacts';
import * as online from './modules/online/useCases';
import { emitToServer } from './storage/message';

import {
  getCurrentPath,
  getPermissionNameByPathname,
  isCurrentPath
} from './utils';

import {
  setDialogId,
  setBadgeCount,
  addMessage,
  updateMessage,
  setMessages,
  setSupportUser
} from './action-creators/chat';
import {
  setCatalogPublicationComplete,
  setCatalogImportComplete
} from './action-creators/catalog';
import { setDeleteCompanyByAdminComplete } from './action-creators/admin';
import {
  setAllBadgeCount,
  setCertainBadge,
  setCertainBadgeCount
} from './action-creators/badge';
import {
  clearOrderDraft,
  updateCustomersOrders,
  updateSuppliersOrders,
  addOrUpdateOrder
} from './action-creators/orders';
import * as companyActions from './action-creators/company';
import { updateSuppliersPrices } from './action-creators/prices';
import { updateCustomers } from './action-creators/customers';
import { updateSuppliers } from './action-creators/suppliers';
import { showModal } from './action-creators/modal';
import { updateStorageSize } from './action-creators/storage';
import {
  disableInitialHelp,
  disablePartnerInfoModal
} from './action-creators/user';
import { loadAccessRoles } from './useCases/userAccessRoles';
import * as chatWidgetUC from './components/ChatWidget/useCases';

import urlBase64ToUint8Array from './utils/urlBase64ToUint8Array';
import { getCurrentLocation } from './utils/location';
import { isMessengerService } from './lib/goodwix';
import * as actionUser from './action-creators/user';
import * as prActions from './action-creators/purchaseRequests';
import { getChatUnsent, getOpenedChat } from './components/ChatWidget/getter';
import { reloadChatList } from './components/ChatWidget/modules/chats/useCases';
import { getAllBadges } from './components/ChatWidget/modules/badges';
import { subscribeToUpdateContactByInvite } from './modules/contact/useCases';
import * as pushesUC from './components/ChatWidget/modules/pushes/useCases';
import { setBadgesElectron } from './lib/setBadgesElectron';
import updateChatInList from './modules/chats/useCases/updateChatInList';
import * as chatActions from './components/ChatWidget/modules/chats/actions';
import { actions as myPRActions } from './components/PurchaseRequests/MyRequests/reducer';
import { actions as responsesActions } from './components/PurchaseRequests/Responses/MyResponses/reducer';
import { actions as purchaseRequestResponsesActions } from './components/PurchaseRequests/Responses/reducer';
import * as handlers from './handlers';
import * as storeGetters from './storeGetters';
import { setUserIdToLS } from './modules/setUserIdToLS';
import * as emailNotificationActions from './action-creators/emailNotification';
import { withRouter } from './hoc/withRouter';

class LocalProvider extends Component {
  // eslint-disable-next-line react/static-property-placement
  static childContextTypes = {
    dataSocket: PropTypes.object,
    chatSocket: PropTypes.object,
    badgeSocket: PropTypes.object,
    notificationSocket: PropTypes.object,
    monitoringSocket: PropTypes.object,
    chatBadgeCount: PropTypes.object
  };

  constructor(props) {
    super(props);
    this.state = {
      badgeCountLoaded: false,
      connected: false
    };
    this.initialBadgeUpload = this.initialBadgeUpload.bind(this);
    this.setChatBadgeCount = this.setChatBadgeCount.bind(this);
    this.setChatDialogId = this.setChatDialogId.bind(this);
    this.onSetMessages = this.onSetMessages.bind(this);
    this.onUpdateMessage = this.onUpdateMessage.bind(this);
    this.onMsg = this.onMsg.bind(this);
    this.onSetBadgeCount = this.onSetBadgeCount.bind(this);
    this.onSupportUser = this.onSupportUser.bind(this);
    this.setCatalogPublicationComplete =
      this.setCatalogPublicationComplete.bind(this);
    this.setCatalogImportComplete = this.setCatalogImportComplete.bind(this);
    this.setDeleteCompanyByAdminComplete =
      this.setDeleteCompanyByAdminComplete.bind(this);
    this.updateSomething = this.updateSomething.bind(this);
    this.showPartnerInfoModal = this.showPartnerInfoModal.bind(this);
    this.resendMessages = this.resendMessages.bind(this);
    this.isSameChatOpened = this.isSameChatOpened.bind(this);
    this.gaHandler = this.gaHandler.bind(this);
  }

  getChildContext() {
    return {
      dataSocket,
      chatSocket,
      badgeSocket,
      notificationSocket,
      monitoringSocket
    };
  }

  async UNSAFE_componentWillMount() {
    const {
      getCompany,
      user,
      blockedContactSubscribeToUpdate,
      contactDeleteSubscribe,
      contactUpdateByInvite,
      onlineSubscribeToUpdate,
      openChannelById,
      getCurrentUser,
      getNewMessages,
      updateChatList,
      updateAllBadges,
      subscribePushNotifications,
      toggleSoundNotification,
      setPushNotificationsActivated,
      initChatHandlers,
      subscribes
    } = this.props;

    initChatHandlers();

    // NOTE: remove if fixed button back in browser
    const { state } = window.history;
    if (state && state.mobilePrevious) {
      const { origin, pathname, href } = window.location;

      // window.history.replaceState({}, 'Goodwix', `${origin}${pathname}`);
      window.location = `${origin}${pathname}?view=desktop`;
      window.location.assign(
        `${process.env.UNICAT_DEFAULT_SERVICE}${window.location.pathname}?view=desktop`
      );
      window.history.replaceState({ mobilePrevious: false }, 'Goodwix', href);
    }

    if (user && user.get('isUploaded') && user.get('currentCompany')) {
      const userName = `${user.get('companyId')}.${user.get('id')}`;
      this.setState({ badgeCountLoaded: true });
      chatSocket.emit('getLastMessageCount', userName);
    }
    await getCurrentUser();

    getCompany();
    onlineSubscribeToUpdate();
    blockedContactSubscribeToUpdate();
    contactDeleteSubscribe();
    contactUpdateByInvite();
    subscribes(this.props.history);

    dataSocket.on('update', this.updateSomething);

    chatSocket.on('lastMessageCount', this.setChatBadgeCount);
    channelSocket.on('lastMessageCount', this.setChatBadgeCount);
    chatSocket.on('msg', this.onMsg);
    chatSocket.on('messages-list', this.onSetMessages);
    chatSocket.on('update-message', this.onUpdateMessage);
    chatSocket.on('setDialogId', this.setChatDialogId);
    badgeSocket.on('badge-count', this.onSetBadgeCount);
    chatSocket.on('supportUser', this.onSupportUser);
    notificationSocket.on(
      'catalogPublicationComplete',
      this.setCatalogPublicationComplete
    );
    notificationSocket.on(
      'catalogImportComplete',
      this.setCatalogImportComplete
    );
    notificationSocket.on(
      'adminDeleteComplete',
      this.setDeleteCompanyByAdminComplete
    );
    monitoringSocket.on('connect', async () => {
      const { openedChat, user: currentUser } = this.props;
      this.emmitConnectedToSocket(currentUser);

      onlineSubscribeToUpdate();

      if (openedChat) {
        chatWidgetUC.joinChat(openedChat.id);
        await getNewMessages(openedChat);
        await updateChatList();
        await updateAllBadges();
      }
    });
    monitoringSocket.on('disconnect', (reason) =>
      console.log(`disconnect because ${reason}`)
    );
    this.onSupportUser = this.onSupportUser.bind(this);
    if (
      'Notification' in window &&
      navigator.serviceWorker &&
      isMessengerService()
    ) {
      await navigator.serviceWorker.register(
        `${process.env.UNICAT_MESSENGER_SERVICE}/sw.js`
      );

      navigator.serviceWorker.onmessage = ({ data: { type, payload } }) => {
        if (type === 'open-chat-by-channel-id') {
          openChannelById(payload.channelId);
        }
      };
    }

    if (
      sessionStorage.getItem('afterRegister') &&
      +sessionStorage.getItem('afterRegister')
    ) {
      await subscribePushNotifications({ onSucceed: toggleSoundNotification });
      sessionStorage.removeItem('afterRegister');
    }
    this.resendMessages();

    await this.props.loadAccessRoles();
    await setPushNotificationsActivated();
  }

  async UNSAFE_componentWillReceiveProps(newProps, prevProps) {
    // eslint-disable-next-line no-shadow,no-unused-vars
    const { disableInitialHelp, disablePartnerInfoModal, getCurrentUser } =
      this.props;
    const { user, isAuth } = newProps;
    const { pathname } = newProps.location;
    const permName = getPermissionNameByPathname(pathname);

    if (!isAuth) {
      await getCurrentUser();
    }

    if (
      user.get('partnerRequestInfo') &&
      !user.get('partnerRequestModalShowed')
    ) {
      this.showPartnerInfoModal(user.get('partnerRequestInfo'));
      disablePartnerInfoModal();
    }

    if (user.get('new') && !user.get('partnerRequestInfo')) {
      disableInitialHelp();
    }

    if (
      user.get('permissions') &&
      !user.get('permissions').isEmpty() &&
      !isCurrentPath('/not-permission')
    ) {
      const havePermissionToRead = user.getIn([
        'permissions',
        permName,
        'read'
      ]);
      const havePermissionToUpdate = user.getIn([
        'permissions',
        permName,
        'update'
      ]);
      if (isCurrentPath('/partners/list') && !havePermissionToRead) {
        console.log('redirect to /company/edit case 1');
        // this.props.history.replace('/company/edit');
        return;
      }

      if (havePermissionToRead === false) {
        this.props.history.replace('/not-permission');
      }
      if (
        isCurrentPath('/prices-suppliers/*') &&
        !isCurrentPath('/prices-suppliers/:id/products') &&
        !havePermissionToUpdate
      ) {
        this.props.history.replace('/not-permission');
      }
      if (
        isCurrentPath('/orders-company/*') &&
        !isCurrentPath('/orders-company/new') &&
        !isCurrentPath(
          '/orders-company/:id/(details-supplier|details-attache|products)'
        ) &&
        !havePermissionToUpdate
      ) {
        this.props.history.replace('/not-permission');
      }
      if (
        (isCurrentPath('/prices/:id/share') ||
          isCurrentPath('/prices/:id/share/*')) &&
        !user.getIn(['permissions', 'customers', 'read'])
      ) {
        this.props.history.replace('/not-permission');
      }
      if (isCurrentPath('/prices/:id/share/edit') && !havePermissionToUpdate) {
        this.props.history.replace('/not-permission');
      }
    }

    if (user && user.get('id') && !user.get('currentCompany')) {
      if (isMessengerService()) {
        if (!isCurrentPath('/company/edit')) {
          console.log('redirect to /company/edit case 2');
          // this.props.history.push('/company/edit');
        }
        return;
      }
      const currentLocation = getCurrentLocation();
      const nonRedirectLocations = [
        '/help',
        '/demo',
        '/profile/edit',
        '/profile/companies'
      ];
      if (
        !nonRedirectLocations.includes(currentLocation) &&
        !isCurrentPath('/prices/:priceId/share/:token')
      ) {
        this.props.history.push('/profile/companies');
      }
    }

    if (!this.state.connected) {
      this.emmitConnectedToSocket(user);
      this.setState({ connected: true });
    }

    if (!prevProps.user && newProps.user && newProps.user.get('employeeId')) {
      emitToServer(badgesQRY.sync);
    }

    this.gaHandler(prevProps, newProps);
    this.initialBadgeUpload(user);
  }

  componentWillUnmount() {
    const { user } = this.props;

    chatSocket.disconnect();
    this.props.blockedContactUnsubscribeToUpdate();
    this.props.contactDeleteUnsubscribe();
    this.props.contactUpdateByInviteUnsubscribe();

    if (user && user.get('employeeId')) {
      emitToServer(badgesQRY.syncOff);
    }
  }

  emmitConnectedToSocket = (user) => {
    if (user && user.get('isUploaded') && user.get('currentCompany')) {
      const userName = `${user.get('currentCompany')}.${user.get('id')}`;
      dataSocket.emit('connected', userName);
      chatSocket.emit('connected', userName);
      badgeSocket.emit('connected', userName);
      notificationSocket.emit('connected', userName);
      monitoringSocket.emit('connected', userName);
    }
  };

  gaHandler(prevProps, newProps) {
    if (isLogin()) {
      const userId = newProps.user.get('id');

      setUserIdToLS(userId);
    }

    if (isLogout()) {
      setUserIdToLS(null);
    }

    function isLogin() {
      return !prevProps.isAuth && newProps.isAuth;
    }

    function isLogout() {
      return prevProps.isAuth && !newProps.isAuth;
    }
  }

  resendMessages = () => {
    const { blockUnsent, handleInputMsg } = this.props;

    if (!isNil(blockUnsent) && !isNil(blockUnsent.list)) {
      const blockUnsentList = blockUnsent.list;
      Object.entries(blockUnsentList).forEach((item) =>
        handleInputMsg('resend', item)
      );
    }
  };

  onMsg(msg) {
    this.props.addMessage(msg);
  }

  onSetMessages(msgs) {
    this.props.setMessages(msgs);
  }

  onUpdateMessage(message) {
    this.props.updateMessage(message);
  }

  onSetBadgeCount(badges) {
    if (badges !== null) {
      this.props.setAllBadgeCount(badges);
    }
  }

  onSupportUser(payload) {
    this.props.setSupportUser(payload.supportUser);
  }

  setChatBadgeCount(count = {}) {
    this.props.setBadgeCount(count);

    setBadgesElectron(count.unMuted || 0);
  }

  setChatDialogId(dialogId) {
    this.props.setDialogId(dialogId);
  }

  setCatalogPublicationComplete(msg) {
    this.props.setCatalogPublicationComplete(msg);
  }

  setCatalogImportComplete(msg) {
    this.props.setCatalogImportComplete(msg);
  }

  setDeleteCompanyByAdminComplete(msg) {
    this.props.setDeleteCompanyByAdminComplete(msg);
  }

  // eslint-disable-next-line class-methods-use-this
  async subscribe() {
    const registration = await navigator.serviceWorker.ready;
    const vapidPublicKey = process.env.VAPID_PUBLIC_KEY;
    const convertedVapidKey = urlBase64ToUint8Array(vapidPublicKey);
    const subscription = await registration.pushManager.subscribe({
      userVisibleOnly: true,
      applicationServerKey: convertedVapidKey
    });

    return fetch('/api/push/subscribe', {
      method: 'post',
      headers: { 'Content-type': 'application/json' },
      credentials: 'include',
      body: JSON.stringify({ subscription, init: true })
    });
  }

  showPartnerInfoModal(partnerRequestInfo) {
    // eslint-disable-next-line no-shadow
    const { showModal, user } = this.props;
    const newUser = user.get('new');
    showModal('PARTNER_INFO', {
      partnerRequestInfo,
      onHide: newUser ? () => this.showHelp() : null
    });
  }

  showHelp() {
    // eslint-disable-next-line no-shadow
    const { showModal, disableInitialHelp } = this.props;
    disableInitialHelp();
    showModal('INITIAL_HELP');
  }

  initialBadgeUpload(user) {
    if (
      !this.state.badgeCountLoaded &&
      user &&
      user.get('isUploaded') &&
      user.get('currentCompany')
    ) {
      const userName = `${user.get('currentCompany')}.${user.get('id')}`;
      this.setState({ badgeCountLoaded: true });
      chatSocket.emit('getLastMessageCount', userName);
      badgeSocket.emit('getBadges', userName);
    }
  }

  isSameChatOpened(chat) {
    const { openedChat } = this.props;

    return !isNil(openedChat) && openedChat.id === chat.id;
  }

  // eslint-disable-next-line consistent-return
  updateSomething(msg) {
    const { type, data } = msg;
    const currentLocation = getCurrentLocation();
    switch (type) {
      case 'storageCapacity': {
        return this.props.updateStorageSize(data.storageSize);
      }
      case 'customersOrders': {
        this.props.setCertainBadgeCount('ordersCustomers', data.badgesCount);
        if (currentLocation === '/orders-customers') {
          this.props.updateCustomersOrders(data);
        }
        break;
      }
      case 'suppliersOrders': {
        this.props.setCertainBadgeCount('ordersSuppliers', data.badgesCount);
        if (currentLocation === '/orders-company') {
          this.props.updateSuppliersOrders(data);
        }
        if (currentLocation === '/orders-company/new') {
          this.props.addOrUpdateOrder(data);
        }
        break;
      }
      case 'suppliersPrices': {
        this.props.setCertainBadgeCount('suppliersPrices', data.badgesCount);
        if (currentLocation === '/prices-suppliers') {
          this.props.updateSuppliersPrices(data);
        }
        break;
      }
      case 'customers': {
        return this.props.setCertainBadgeCount(
          'inviteCustomer',
          data.badgesCount
        );
      }
      case 'suppliers': {
        return this.props.setCertainBadgeCount(
          'inviteSupplier',
          data.badgesCount
        );
      }
      case 'partners': {
        return this.props.setCertainBadgeCount(
          'invitePartner',
          data.badgesCount
        );
      }
      case 'update-response':
        if (currentLocation === '/requests/my-responses') {
          this.props.updateMyPurchaseRequestsResponses(data);
        } else {
          this.props.updatePurchaseRequestsResponse(data);
          this.props.updateRequestRespond(data);
        }
        break;
      case 'allPurchaseRequests':
      case 'myPurchaseRequests':
      case 'myPurchaseResponses':
      case 'responsePurchaseRequest':
        return this.props.setCertainBadge(type, data);
      case 'channel':
        if (this.isSameChatOpened(data.channel)) {
          this.props.updateOpenedChat(data.channel);
        }
        return this.props.updateChat(data.channel);
      case 'update-request':
        if (currentLocation === '/requests/my') {
          this.props.updateMyPurchaseRequests(data);
        }
        break;
      default:
    }
  }

  render() {
    return this.props.children;
  }
}

export default withRouter(
  connect(
    (state) => ({
      user: state.getIn(['user', 'user']),
      openedChat: getOpenedChat(state),
      blockUnsent: getChatUnsent(state),
      isAuth: storeGetters.isAuth(state)
    }),
    (dispatch) =>
      bindActionCreators(
        {
          setBadgeCount,
          addMessage,
          updateMessage,
          setMessages,
          setAllBadgeCount,
          setCertainBadgeCount,
          setCertainBadge,
          setDialogId,
          setSupportUser,
          setCatalogPublicationComplete,
          setCatalogImportComplete,
          setDeleteCompanyByAdminComplete,
          clearOrderDraft,
          updateStorageSize,
          showModal,
          disableInitialHelp,
          disablePartnerInfoModal,
          updateCustomersOrders,
          updateSuppliersOrders,
          updateSuppliersPrices,
          updateCustomers,
          updateSuppliers,
          loadAccessRoles,
          addOrUpdateOrder,
          blockedContactSubscribeToUpdate:
            blockedContact.subscribeToUpdateContactBlockInfo,
          blockedContactUnsubscribeToUpdate:
            blockedContact.unsubscribeFromUpdateContactBlockInfo,
          contactDeleteSubscribe: subscribeToDeleteContactBlock,
          contactDeleteUnsubscribe: unSubscribeToDeleteContactBlock,
          contactUpdateByInvite: subscribeToUpdateContactByInvite,
          contactUpdateByInviteUnsubscribe:
            unSubscribeToUpdateContactListByInvite,
          onlineSubscribeToUpdate: online.subscribeToUpdate,
          hideChatWidget: chatWidgetUC.hideChatWidget,
          showChatWidget: chatWidgetUC.showChatWidget,
          getCompany: companyActions.getCompany,
          openChannelById: chatWidgetUC.openChannelById,
          handleInputMsg: chatWidgetUC.handleInputMsg,
          getCurrentUser: actionUser.getCurrentUser,
          getNewMessages: chatWidgetUC.uploadMessages,
          updateChatList: reloadChatList,
          updateAllBadges: getAllBadges,
          subscribePushNotifications: pushesUC.subscribe,
          toggleSoundNotification:
            emailNotificationActions.toggleSoundNotification,
          setPushNotificationsActivated: pushesUC.setPushNotificationsActivated,
          updateChat: updateChatInList,
          updateOpenedChat: chatActions.setOpenChat,
          updateRequestRespond: prActions.updateRequestRespond,
          updateMyPurchaseRequests: myPRActions.updateRequest,
          updateMyPurchaseRequestsResponses: responsesActions.updateResponse,
          updatePurchaseRequestsResponse:
            purchaseRequestResponsesActions.updateResponse,
          initChatHandlers: handlers.initChatHandlers,
          subscribes: allSubscribes
        },
        dispatch
      )
  )(LocalProvider)
);
