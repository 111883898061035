import React, { Component } from 'react';
import PropTypes from 'prop-types';
import block from 'bem-cn-lite';
import { translate } from 'react-i18next';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Modal } from 'react-bootstrap';
import { List } from 'immutable';
import { withRouter } from '../../hoc/withRouter';

import './styles/modal_theme_default.styl';

import { ModalContainer } from './elements';
import ForwardDialogCards from '../ForwardDialogCards';
import SearchBlockDefault from '../SearchBlock/SearchBlockDefault';

import * as modalActions from '../../action-creators/modal';
import * as channelUC from '../../useCases/channel';
import * as dialogsActions from '../../action-creators/dialogs';

const b = block('modal-container');

class ModalDialogForwardMessage extends Component {
  getChildContext() {
    return { b };
  }

  constructor(props) {
    super(props);
    this.state = {
      search: ''
    };
    this.forwardMessage = this.forwardMessage.bind(this);
    this.setSearch = this.setSearch.bind(this);
    this.onEmpty = this.onEmpty.bind(this);
  }

  UNSAFE_componentWillMount() {
    const { loadDialogs, mode = '' } = this.props;
    loadDialogs({ mode, settingsOff: true });
  }

  async forwardMessage(dialog, toType) {
    const { id, linkParams } = dialog;
    const {
      history,
      location,
      hideModalDialog,
      fromType,
      getChannelMessagesForForward,
      channelId,
      selectedMsgs,
      clearSelectedMsgs = () => {},
      reloadData = () => {}
    } = this.props;

    if (
      ['topic', 'channel', 'dialog'].includes(fromType) &&
      ['topic', 'channel', 'dialog'].includes(toType)
    ) {
      const messages = await getChannelMessagesForForward(
        channelId,
        selectedMsgs
      );
      clearSelectedMsgs();
      history.replace({
        pathname: linkParams.pathname,
        state: { ...location.state, forwarded: true, messages }
      });
      reloadData(id, channelId);
      hideModalDialog();
    }
  }

  onEmpty() {
    this.setState({ search: '' });
  }

  setSearch(search) {
    this.setState({ search });
  }

  render() {
    const {
      t,
      hideModalDialog,
      user,
      company,
      supportUser,
      dialogs = List(),
      mode
    } = this.props;

    const from = `${user.get('currentCompany')}.${user.get('id')}`;

    return (
      <Modal id="messageForward" show>
        <ModalContainer mods={{ type: 'default', theme: 'default' }}>
          <Modal.Header>
            <Modal.Title>
              {mode === 'topic'
                ? t('message_forward_topic')
                : t('message_forward_dialog')}
              <span
                className="modal-header-cross pull-right"
                onClick={() => hideModalDialog()}
              />
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <SearchBlockDefault
              placeholder={mode === 'topic' ? t('find_topic') : t('find_chat')}
              onSubmit={() => {}}
              onChange={this.setSearch}
              onEmpty={this.onEmpty}
              width={480}
            />
            <div style={{ height: 512, overflowY: 'auto', marginTop: 10 }}>
              <div>
                <ForwardDialogCards
                  users={dialogs}
                  t={t}
                  search={this.state.search}
                  company={company}
                  from={from}
                  currentUser={user}
                  supportUser={supportUser}
                  forwardMessage={this.forwardMessage}
                />
              </div>
            </div>
          </Modal.Body>
        </ModalContainer>
      </Modal>
    );
  }
}

ModalDialogForwardMessage.propTypes = {
  t: PropTypes.func.isRequired,
  hideModalDialog: PropTypes.func.isRequired,
  loadDialogs: PropTypes.func.isRequired,
  dialogs: PropTypes.array.isRequired
};

ModalDialogForwardMessage.childContextTypes = {
  b: PropTypes.func
};

export default withRouter(
  connect(
    (state) => ({
      user: state.getIn(['user', 'user']),
      company: state.get('company'),
      supportUser: state.getIn(['chat', 'supportUser']),
      dialogs: state.getIn(['chat', 'dialogs', 'items'])
    }),
    (dispatch) =>
      bindActionCreators(
        {
          hideModalDialog: modalActions.hideModalDialog,
          loadDialogs: dialogsActions.loadDialogs,
          getChannelMessagesForForward: channelUC.getMessagesForForward,
          getDialogMessagesForForward: channelUC.getMessagesForForward
        },
        dispatch
      )
  )(translate(['ui'], { wait: true })(ModalDialogForwardMessage))
);
