import React, { useRef } from 'react';
import { isNil } from 'ramda';
import { convertBytes, getFileName, getFileUrl } from './utils';
import AttachSVG from '../../images/attach';
import ButtonIcon from '../../buttons/ButtonIcon';

function FileLoadProgress({ progress }) {
  if (isNil(progress)) return null;

  const loadingPercent = Math.floor((progress.loaded / progress.total) * 100);

  return (
    <div
      className="files-input-load-progress"
      style={{ right: `${100 - loadingPercent}%` }}
    />
  );
}

function getFileRowCN(file) {
  let cn = 'files-input-row';

  if (file.status === 'loaded') cn += ` ${cn}_loaded`;
  if (file.status === 'error') cn += ` ${cn}_error`;

  return cn;
}

function FileInfo({ t, file }) {
  if (file.status === 'loading') {
    return (
      <div className="files-input-file">
        <FileLoadProgress progress={file.progress} />
        <div className="files-input-file__name">{file.data.name}</div>
      </div>
    );
  }

  if (file.status === 'loaded') {
    return (
      <div className="files-input-file">
        <div className="files-input-file__name">{file.data.name}</div>
        <div className="files-input-file__size">{`${convertBytes(
          file.data.fileInfo.size, file.data.fileInfo.size > 1e6 ?
          'mb' : 'kb'
        )}${file.data.fileInfo.size > 1e6 ? 'Mb' : 'Kb'}`}</div>
      </div>
    );
  }

  if (file.status === 'error') {
    return (
      <div>
        <div className="files-input-file">
          <div className="files-input-file__name">{file.data.name}</div>
        </div>
        <div className="files-input-file-error">{t(file.errorMessage)}</div>
      </div>
    );
  }

  throw new Error('No handler for file');
}

function FileRow({ t, file, onDelete }) {
  const url = getFileUrl(file);
  const name = getFileName(file);

  const isShowDownloadButton = !isNil(url) && !isNil(name);

  return (
    <div className={getFileRowCN(file)}>
      <FileInfo t={t} file={file} />
      <div className="files-input-row-actions">
        {isShowDownloadButton ? (
          <a target="_blank" href={`${url}?name=${name}`} rel="noreferrer">
            <ButtonIcon t={t} icon="download" tooltip="download" />
          </a>
        ) : null}
        <ButtonIcon
          t={t}
          icon="remove-2"
          tooltip="delete"
          onClick={() => onDelete(file._innerId)}
        />
        {/* <div className="files-input-row-icon" onClick={() => onDelete(file._innerId)}> */}
        {/*  <TrashSVG /> */}
        {/* </div> */}
      </div>
    </div>
  );
}

function View({
  t,
  title,
  btnAddFile,
  files,
  onUploadFiles,
  onDeleteFile,
  disabled,
  isShowFilesCount = true
}) {
  const inputRef = useRef();

  const onClickUploadFile = () => {
    if (!disabled && inputRef.current && inputRef.current.click) {
      inputRef.current.click();
    }
  };

  return (
    <div className="files-input-wrapper">
      <div className="files-input-header">
        <div className="files-input-header__icon">
          <AttachSVG />
        </div>
        <div className="files-input-title">
          {title}
          {isShowFilesCount && (
            <span className="files-input-title-subtext">{` (${files.length})`}</span>
          )}
        </div>
      </div>
      <div className="files-input-content">
        {files.map((f) => (
          <FileRow t={t} key={f._innerId} file={f} onDelete={onDeleteFile} />
        ))}
        <div
          className={`files-input-add-file ${
            disabled ? 'disabled' : ''
          }`.trim()}
          onClick={onClickUploadFile}>
          {btnAddFile}
        </div>
      </div>
      <input
        className="files-input-hidden-element"
        ref={inputRef}
        type="file"
        onChange={onUploadFiles}
        multiple
        disabled={disabled}
      />
    </div>
  );
}

export default View;
