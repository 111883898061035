import React from 'react';
import { useDispatch } from 'react-redux';
import * as storageActions from '../../../action-creators/storage';

export function useFiles({ initialFiles = [], section = 'uploads' }) {
  const dispatch = useDispatch();
  const [filesList, setFilesList] = React.useState(initialFiles);

  const upload = React.useCallback(async (file) => {
    const uploadedFile = await dispatch(storageActions.uploadFile(file));
    setFilesList((prevState) => [...prevState, uploadedFile]);
  }, []);

  const removeById = React.useCallback((fileId) => {
    setFilesList((prevState) => prevState.filter((file) => file.id !== fileId));
    dispatch(storageActions.removeFileLinkBySection(fileId, section));
  }, []);

  return [filesList, { upload, removeById }];
}
