import { PREFIX } from '../constants';

export const REDUX_KEY = 'constructor';
export const FETCH_CONSTRUCTOR_LIST = {
  START: `${PREFIX}:FETCH_CONSTRUCTOR_LIST:START`,
  SUCCESS: `${PREFIX}:FETCH_CONSTRUCTOR_LIST:SUCCESS`,
  ERROR: `${PREFIX}:FETCH_CONSTRUCTOR_LIST:ERROR`
};

export const DELETE_ROUTE = `${PREFIX}:DELETE_ROUTE`;

export const FETCH_CONSTRUCTOR = {
  START: `${PREFIX}:FETCH_CONSTRUCTOR:START`,
  SUCCESS: `${PREFIX}:FETCH_CONSTRUCTOR:SUCCESS`,
  ERROR: `${PREFIX}:FETCH_CONSTRUCTOR:ERROR`
};

export const FETCH_USERS_LIST = {
  START: `${PREFIX}:FETCH_USERS_LIST:START`,
  SUCCESS: `${PREFIX}:FETCH_USERS_LIST:SUCCESS`,
  ERROR: `${PREFIX}:FETCH_USERS_LIST:ERROR`
};

export const SAVE_BUILDER_PATH = `${PREFIX}:SAVE_BUILDER_PATH`;

export const CREATE_STEP = `${PREFIX}:CREATE_STEP`;
export const DELETE_STEP = `${PREFIX}:DELETE_STEP`;

export const ADD_CARD_TO_STEP = `${PREFIX}:ADD_CARD_TO_STEP`;
export const DELETE_CARD_FROM_STEP = `${PREFIX}:DELETE_CARD_FROM_STEP`;

export const ADD_USER_TO_CARD = `${PREFIX}:ADD_USER_TO_CARD`;
export const REMOVE_USER_FROM_CARD = `${PREFIX}:REMOVE_USER_FROM_CARD`;

export const CHANGE_USER_IS_EXECUTE_AUTOMATICALLY = `${PREFIX}:CHANGE_USER_IS_EXECUTE_AUTOMATICALLY`;
export const CHANGE_BUILDER_NAME = `${PREFIX}:CHANGE_BUILDER_NAME`;

export const INIT_CONSTRUCTOR = `${PREFIX}:INIT_CONSTRUCTOR`;

export const CLEAR_CONSTRUCTOR = `${PREFIX}:CLEAR_CONSTRUCTOR`;

export const ERROR_CHECKED = `${PREFIX}:ERROR_CHECKED`;

export const INIT_ROUTE_NAME = `${PREFIX}:INIT_ROUTE_NAME`;

export const EXCHANGE_USER = `${PREFIX}:EXCHANGE_USER`;
