import { Map, List, fromJS } from 'immutable';

const newState = (state) => Map(state);

const initState = () =>
  newState({
    name: '',
    desc: '',
    currency: '',
    language: '',
    imgUrl: '',
    descImgUrl: '',
    isUploaded: false,
    attrsFilter: List(),
    additionAttrs: List()
  });

const load = (data, { catalogProps }) =>
  newState(
    fromJS(
      Object.assign(
        catalogProps,
        {
          desc: catalogProps.desc || '',
          imgUrl: catalogProps.imgUrl || '',
          descImgUrl: catalogProps.descImgUrl || ''
        },
        {
          isUploaded: true
        }
      )
    )
  );

const setAttrsFilter = (data, { attrs }) =>
  data.set('attrsFilter', fromJS(attrs));

const setStatus = (data, { status }) => data.set('status', status);

const selectUnit = (data, { name = '', code = '' }) =>
  data.update('units', (units) => {
    const index = units.findIndex(
      (i) => (i.get('name') || '') === name && +(i.get('code') || '') === +code
    );
    if (index < 0) {
      return units.push(fromJS({ name, code, active: true }));
    }
    return units.setIn([index, 'active'], !units.getIn([index, 'active']));
  });

const addUnit = (data, { name, code, id }) =>
  data
    .update('units', (units) => units.push(fromJS({ code, name, id })))
    .set('status', 'draft');

const removeUnit = (data, { name = '', code = '', id }) =>
  data.update('units', (units) => {
    let index;
    if (id) {
      index = units.findIndex((i) => i.get('id') === id);
    } else {
      index = units.findIndex(
        (i) => (i.get('name') || '') === name && (i.get('code') || '') === code
      );
    }
    return units.setIn([index, 'deleted'], true);
  });

const editUnit = (data, { name = '', code = '', id }) =>
  data.update('units', (units) => {
    const index = units.findIndex((i) => i.get('id') === id);
    return units.setIn([index, 'name'], name).setIn([index, 'code'], code);
  });

const saveUnits = (data) => data.set('status', 'draft');

const setUnits = (data, { units, publishedUnits }) =>
  data
    .set('units', fromJS(units))
    .set('publishedUnits', fromJS(publishedUnits));

const properties = (state = initState(), { type, payload }) => {
  switch (type) {
    case 'CATALOG:PROPERTIES:LOAD:SUCCESS':
    case 'CATALOG:PROPERTIES:UPDATE:SUCCESS':
      return load(state, payload);
    case 'CATALOG:PROPERTIES:CLEAR':
      return initState();
    case 'CATALOG:ATTRS_EDIT:SUCCESS':
      return setAttrsFilter(state, payload);
    case 'CATALOG_SET_STATUS':
      return setStatus(state, payload);
    case 'TABLE_UNITS_SELECT':
      return selectUnit(state, payload);
    case 'CATALOG:CREATE_UNIT:SUCCESS':
      return addUnit(state, payload);
    case 'CATALOG:REMOVE_UNIT':
      return removeUnit(state, payload);
    case 'CATALOG:EDIT_UNIT':
      return editUnit(state, payload);
    case 'CATALOG:SAVE_UNITS:SUCCESS':
      return saveUnits(state, payload);
    case 'CATALOG:GET_UNITS:SUCCESS':
      return setUnits(state, payload);
    default:
      return state;
  }
};

export default properties;
