import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import block from 'bem-cn-lite';
import { translate } from 'react-i18next';
import {
  Modal,
  Button,
  FormGroup,
  FormControl,
  ControlLabel,
  Form
} from 'react-bootstrap';
import './styles/modal_theme_default.styl';

import { ModalContainer } from './elements';
import * as modalActions from '../../action-creators/modal';
import * as orderActions from '../../action-creators/orders';

const b = block('modal-container');

class ModalChangeOrderStatus extends Component {
  constructor(props) {
    super(props);
    this.state = {
      comment: '',
      submit: false
    };
    this.hideModal = this.hideModal.bind(this);
    this.setComment = this.setComment.bind(this);
    this.submit = this.submit.bind(this);
  }

  setComment(e) {
    this.setState({ comment: e.target.value });
  }

  hideModal() {
    this.props.hideModalDialog();
    this.props.onClosed();
  }

  submit() {
    const {
      order,
      hideModalDialog,
      onSubmited,
      addOrderComment,
      submitAction,
      callback = true,
      submitArguments,
      getOrder,
      getOrderInfo
    } = this.props;
    this.setState({ submit: true });
    if (!callback) {
      submitAction(submitArguments);
      return hideModalDialog();
    }
    submitAction(submitArguments).then(() => {
      hideModalDialog();
      onSubmited();
      getOrder(order.get('id'));
      getOrderInfo(order.get('id'));
    });
    if (this.state.comment) {
      addOrderComment(order.get('id'), this.state.comment);
    }
    return '';
  }

  render() {
    const {
      t,
      title = 'Confirm status change',
      status,
      prevStatus,
      desc = 'You can add comment to your order status change',
      saveButtonText = 'Change status',
      commentArea = true
    } = this.props;

    return (
      <Modal id="changeOrderStatus" onHide={this.hideModal} show>
        <ModalContainer mods={{ type: 'default', theme: 'default' }}>
          <Modal.Header>
            <Modal.Title>
              {t(title)}
              <span
                className="modal-header-cross pull-right"
                onClick={() => this.hideModal()}
              />
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <FormGroup controlId="comment">
                <ControlLabel
                  style={{
                    fontWeight: 'normal',
                    fontSize: '14px'
                  }}>
                  {t('Change order status from')}
                  <b>{` ${t(prevStatus)}`}</b>
                  {` ${t('to')} `}
                  <b>{t(status)}</b>
                  <br />
                  <br />
                  {t(desc)}
                </ControlLabel>
                {commentArea && (
                  <FormControl
                    componentClass="textarea"
                    rows="5"
                    maxLength="1600"
                    value={this.state.comment}
                    onChange={this.setComment}
                  />
                )}
              </FormGroup>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button id="cancel" className="pull-left" onClick={this.hideModal}>
              {t('Cancel')}
            </Button>
            <Button
              id="saveOrderComment"
              bsStyle="primary"
              onClick={this.state.submit ? () => {} : this.submit}>
              {t(saveButtonText)}
            </Button>
          </Modal.Footer>
        </ModalContainer>
      </Modal>
    );
  }
}

ModalChangeOrderStatus.childContextTypes = {
  b: PropTypes.func
};

ModalChangeOrderStatus.defaultProps = {
  onClosed: () => {}
};

export default connect(null, (dispatch) =>
  bindActionCreators(
    {
      showModal: modalActions.showModal,
      hideModalDialog: modalActions.hideModalDialog,
      addOrderComment: orderActions.addOrderComment,
      getOrder: orderActions.getOrder,
      getOrderInfo: orderActions.getOrderInfo
    },
    dispatch
  )
)(translate(['ui'], { wait: true })(ModalChangeOrderStatus));
