import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import mammoth from 'mammoth';
import { translate } from 'react-i18next';
import Loader from '../../Loader';
import DocxViewerDocument from './DocxViewerDocument';
import * as modalActions from '../../../action-creators/modal';
import * as messageActions from '../../../action-creators/message';
import DocxViewerHeader from './DocxViewerHeader';
import DocxViewerFooter from './DocxViewerFooter';
import ZoomDocx from './ZoomDocx';
import { resendFile } from '../../ChatWidget/useCases';
import './ModalDocxViewer.css';

class ModalDocxViewer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoaded: false,
      isConverted: false,
      docxHTML: null,
      isError: false
    };

    this.onDownload = this.onDownload.bind(this);
    this.onDelete = this.onDelete.bind(this);
    this.renderDocx = this.renderDocx.bind(this);
    this.resendFile = this.resendFile.bind(this);
  }

  UNSAFE_componentWillMount() {
    const { clearMessages } = this.props;
    // Clear all notifications before open
    clearMessages();
  }

  componentDidMount() {
    const { src } = this.props;

    const onLoaded = (response) => {
      this.setState({ isLoaded: true });
      return response;
    };

    const onConverted = ({ value }) => {
      this.setState({ isConverted: true, docxHTML: value });
    };

    const onError = (err) => {
      this.setState({ isError: true });
      console.error(err);
    };

    fetch(src, { mode: 'cors', credentials: 'include' })
      .then(onLoaded)
      .then((response) => response.blob())
      .then((response) => response.arrayBuffer())
      .then((arrayBuffer) => mammoth.convertToHtml({ arrayBuffer }))
      .then(onConverted)
      .catch(onError);
  }

  onDownload = () => {
    const { src, name } = this.props;
    window.open(`${src}?name=${name}`, '_blank');
  };

  onDelete = async () => {
    const { t, onDeleteDocument, hideModalDialog } = this.props;
    if (window.confirm(t('confirm_document_delete'))) {
      await onDeleteDocument();
      hideModalDialog();
    }
  };

  renderDocx = () => {
    const { t } = this.props;
    const { isLoaded, isConverted, docxHTML, isError } = this.state;
    if (isError) {
      return <span>ERROR!</span>;
    }

    if (!isLoaded) {
      return (
        <div>
          <Loader />
          <h5>{t('loading_document')}</h5>
        </div>
      );
    }

    if (isLoaded && !isConverted) {
      return (
        <div>
          <Loader />
          <h5>{t('processing_document')}</h5>
        </div>
      );
    }

    if (isLoaded && isConverted && docxHTML) {
      return <ZoomDocx docxHTML={docxHTML} />;
    }

    return <span />;
  };

  resendFile() {
    const { file } = this.props;
    this.props.resendFile(file);
  }

  render() {
    const {
      t,
      name,
      addedAt,
      senderName,
      onCopyToStorageDocument,
      isDeleteEnabled,
      isDownloadEnabled,
      isCopyToStorageEnable,
      hideModalDialog
    } = this.props;

    return (
      <div className="modal-docx-viewer__container">
        <DocxViewerHeader hideModalDialog={hideModalDialog} />
        <DocxViewerDocument>{this.renderDocx()}</DocxViewerDocument>
        <DocxViewerFooter
          t={t}
          name={name}
          date={addedAt}
          senderName={senderName}
          isShowAddToStorage={isCopyToStorageEnable}
          isDeleteEnabled={isDeleteEnabled}
          isDownloadEnabled={isDownloadEnabled}
          onDownload={this.onDownload}
          onDelete={this.onDelete}
          onCopyToStorage={onCopyToStorageDocument}
          handleResend={this.resendFile}
        />
      </div>
    );
  }
}

ModalDocxViewer.propTypes = {
  t: PropTypes.func.isRequired,
  src: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  addedAt: PropTypes.string,
  senderName: PropTypes.string,
  isDeleteEnabled: PropTypes.bool.isRequired,
  isDownloadEnabled: PropTypes.bool,
  onDeleteDocument: PropTypes.func.isRequired,
  isCopyToStorageEnable: PropTypes.bool,
  onCopyToStorageDocument: PropTypes.func,
  hideModalDialog: PropTypes.func.isRequired,
  clearMessages: PropTypes.func.isRequired,
  resendFile: PropTypes.func.isRequired
};

ModalDocxViewer.defaultProps = {
  isDownloadEnabled: true,
  isCopyToStorageEnable: false,
  onCopyToStorageDocument: () =>
    console.error('not pass func onCopyToStorageDocument'),
  addedAt: '',
  senderName: ''
};

const mapDispatch = (dispatch) =>
  bindActionCreators(
    {
      hideModalDialog: modalActions.hideModalDialog,
      clearMessages: messageActions.clearMessages,
      resendFile
    },
    dispatch
  );

export default connect(null, mapDispatch)(translate()(ModalDocxViewer));
