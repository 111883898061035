import React, { useEffect, useRef, useState } from 'react';
import { connect, useSelector } from 'react-redux';
import { isSameDay } from 'date-fns';

import { useActions, useLocation, useNavigate } from '@hooks';

import { bindActionCreators } from 'redux';
import { equals, isEmpty, isNil, dissoc } from 'ramda';
import { Form, HelpBlock, Row } from 'react-bootstrap';
import { Button } from '@link/react-components';
import { useParams } from 'react-router-dom';
import { withRouter } from '../../../hoc/withRouter';

import * as serviceActions from '../../../action-creators/services';
import * as requestsActions from '../../../action-creators/purchaseRequests';
import * as storageActions from '../../../action-creators/storage';
import * as messageActions from '../../../action-creators/message';
import * as modalActions from '../../../action-creators/modal';

import FilesBlock from '../Request/FilesBlock';
import LinksBlock from '../Request/LinksListBlock';
import { makeFileVM } from '../Request/filesVM';
import { makeLinkVM } from '../Request/linksVM';
import { downloadFile } from '../Request/files';
import { tranformHLink } from '../Request/links';
import './style.css';

import { round } from '../../../utils';

import { filesLoadHandler } from '../../../useCases/files';
import RequestDescription from './components/RequestDescription';
import SectionOffset from '../Request/SectionOffset';
import RequestTable from './components/RequestTable';
import RequestSum from './components/RequestSum';
import ComponentButton from './components/ComponentButton';
import { getInvalidProducts, getValidProducts, initProduct } from './utils';
import GoBack from '../../Buttons/GoBack';
import { useFiles } from '../../ChatWidget/hooks/useFiles';
import { useTranslate } from '../../../TranslateProvider';
import { useSelectIdsWithCheckbox } from './hooks/useSelectIdsWithCheckbox';
import { InteractiveHelper } from '../../InteractiveHelper';
import { Popover } from './components/Popover';
import { PurchaseRequestMapper } from '../infrastructure';
import GeneralInfo from '../common/GeneralInfo/GeneralInfo';
import { purchaseRequestTypes } from '../domain/entities';
import * as storeGetters from '../../../storeGetters';
import { formatRequestNumber, isNilOrEmpty, noop } from '../../../utils/utils';
import { purchaseRequestService } from '../../../api';

const MIN_TITLE_LENGTH = 4;
const DEFAULT_CURRENCY = 'RUB';

const MAX_REQUEST_SUM = 100000000000000;
const MAX_UPLOAD_FILES = 50;

// TODO: переписать с использованием useReducer, вынести дублирующуюся логику по предзаполнению ЗЗ при создании из дочерних компонентов

const ACTION_TYPES = {
  SAVE: 'SAVE',
  PUBLISH: 'PUBLISH'
};

const initialErrorState = {
  save: false,
  publish: false
};

// ToDo Need refactoring decompose logic do it with useReducer choose one mapper makeRequest or new PurchaseRequest
const makeRequest = ({
  categoriesId = [],
  title = '',
  date = '',
  country = '',
  region = '',
  city = '',
  productsList = '',
  products = [{ ...initProduct }],
  supplierRequirements = '',
  attachments = [],
  links = [],
  partResponse = true,
  responseEndDate = null,
  responsibleUser = null,
  sum = null,
  number = null,
  route = null,
  authorId = null
} = {}) => ({
  categoriesId,
  title,
  date,
  country,
  region,
  city,
  productsList,
  products,
  supplierRequirements,
  attachments,
  links,
  partResponse,
  responseEndDate,
  responsibleUser,
  sum,
  number,
  route,
  authorId
});

const NewRequest = ({
  request = {},
  setErrorMessage,
  setSuccessMessage,
  showModal,
  gaSend,
  storageSize,
  maxStorageSize
}) => {
  const t = useTranslate();
  const company = useSelector(storeGetters.getCompany).toJS();
  const user = useSelector(storeGetters.getCurrentUser).toJS();
  const { requestId } = useParams();
  const {
    editRequest,
    publishRequest,
    updateRequest,
    manipulateRequestFailed
  } = useActions(requestsActions);

  const reqProducts = request.products || [];
  const [categoriesId, setCategoriesId] = useState(request.categoriesId || []);
  const [title, setTitle] = useState(request.title || '');
  const [date, setDate] = useState(request.date || '');
  const [country, setCountry] = useState(request.country || '');
  const [region, setRegion] = useState(request.region || '');
  const [city, setCity] = useState(request.city || '');
  const [productsList, setProductsList] = useState(request.productsList || '');
  const [products, setProducts] = useState(
    reqProducts.length > 0 ? request.products : [{ ...initProduct }]
  );
  const [supplierRequirements, setSupplierRequirements] = useState(
    request.supplierRequirements || ''
  );
  const [attachments, attachmentsMethods] = useFiles({
    initialFiles: request.attachments,
    section: 'uploads'
  });
  const [links, setLinks] = useState(request.links || []);
  const [partResponse, setPartResponse] = useState(
    request.partResponse ?? true
  );
  const [responseEndDate, setResponseEndDate] = useState(
    request.responseEndDate || null
  );
  const [responsibleUser, setResponsibleUser] = useState(
    request.responsibleUser || null
  );
  const [sum, setSum] = useState(request.sum || null);
  const [number, setNumber] = useState(request.number || null);
  const [routeId, setRouteId] = useState(request.routeId || null);
  const [route, setRoute] = useState(request.route || null);
  const [authorId, setAuthorId] = useState(request.authorId || null);

  const [initialData, setInitialData] = useState(null);

  useEffect(() => {
    if (request.id) {
      setNumber(request.number || null);
      setSum(request.sum || null);
    }
  }, [request]);

  useEffect(() => {
    if (!isNilOrEmpty(request)) {
      setInitialData(makeRequest(request));
    } else {
      const categories = company?.categoriesId;
      if (categories) {
        getInitialData().then(setInitialData);
      }
    }
  }, [request?.id, request?.updatedAt, company?.categoriesId]);

  async function getInitialData() {
    const requestId = await requestsActions.getRequestNumber();
    const formatNumber = formatRequestNumber(requestId);

    if (requestType === purchaseRequestTypes.withConfirmation) {
      return makeRequest({
        categoriesId: company?.categoriesId,
        authorId: user?.id,
        number: formatNumber
      });
    }

    const defaultLocation = (localStorage.getItem('pr-location') || '').split(
      ';'
    );
    const defaultCountry = country || defaultLocation[0];
    const defaultRegion = region || defaultLocation[1];
    const defaultCity = city || defaultLocation[2];

    return makeRequest({
      categoriesId: company?.categoriesId,
      responsibleUser: user?.id,
      authorId: user?.id,
      number: formatNumber,
      country: defaultCountry,
      region: defaultRegion,
      city: defaultCity,
      products: openedComponents.table ? getValidProducts(products) : []
    });
  }

  const typeaheadRef = useRef(null);
  const typeaheadTitleKey = 'purchase-request-title';

  useEffect(() => {
    const categories = company?.categoriesId;
    if (isEmpty(categoriesId) && categories) {
      setCategoriesId(categories);
    }
  }, [company?.id]);

  const {
    selectedList: accessCompanyList,
    setSelectedList: setAccessCompanyList,
    isChecked: isShowForAllCompanies,
    onToggle: onToggleShowForAllCompanies
  } = useSelectIdsWithCheckbox({
    initialCheckboxValue:
      isNil(request.companyId) || isEmpty(request.accessCompanyList),
    initialSelectedList: request.accessCompanyList
  });

  const [isErrorShown, setIsErrorShown] = useState(initialErrorState);
  const [openedComponents, setOpenedComponents] = useState({
    table: reqProducts.length > 0,
    description: !!productsList,
    sum: !!sum
  });

  const body = {
    categoriesId,
    title,
    date,
    country,
    region,
    city,
    supplierRequirements,
    responseEndDate,
    responsibleUser,
    number,
    accessCompanyList,
    isShowForAllCompanies,
    routeId,
    route,
    authorId
  };

  const setBody = {
    setCategoriesId,
    setTitle,
    setDate,
    setCountry,
    setRegion,
    setCity,
    setSupplierRequirements,
    setResponseEndDate,
    setResponsibleUser,
    setNumber,
    setAccessCompanyList,
    onToggleShowForAllCompanies,
    setRouteId,
    setRoute,
    setAuthorId
  };

  const navigate = useNavigate();
  const { state } = useLocation();
  const requestType = state?.type || request.type;

  const isTitleValid = () => title.trim().length >= MIN_TITLE_LENGTH;

  const fileSelectHandler = async (data) => {
    await filesLoadHandler({
      maxFiles: MAX_UPLOAD_FILES,
      loadFile: async ({ file }) => attachmentsMethods.upload(file),
      files: convertFiles(data),
      attachedFiles: attachments,
      onError: (key, params) => setErrorMessage({ key, params })
    });

    function convertFiles(rawFiles) {
      const files = [];
      for (let i = 0; i < rawFiles.length; i++) {
        files.push({ file: rawFiles[i] });
      }
      return files;
    }
  };

  const onAddLink = (value) => setLinks(links.concat(tranformHLink(value)));

  const handleAddLink = () => showModal('ADD_LINK', { onSubmit: onAddLink });

  const deleteItemFromList = (id) =>
    setLinks(links.filter((item) => item.id !== id));

  const getLinks = () => {
    const buttonForFile = ({ id }) => [
      {
        name: 'delete',
        icon: 'remove-2',
        onClick: () => deleteItemFromList(id)
      }
    ];

    return links.map((link) =>
      makeLinkVM({
        ...link,
        key: `${link.id}l`,
        buttons: buttonForFile(link)
      })
    );
  };

  const getFiles = () => {
    const buttonForFile = ({ file }) => [
      {
        name: 'download',
        icon: 'download-1',
        onClick: () =>
          downloadFile({ ...file.fileInfo, url: file.fileInfo.originalUrl })
      },
      {
        name: 'delete',
        icon: 'remove-2',
        onClick: () => attachmentsMethods.removeById(file.id)
      }
    ];

    return attachments.map((file) =>
      makeFileVM({
        key: file.fileInfo.id,
        createdAt: file.fileInfo.createdAt,
        name: file.fileInfo.name,
        size: file.fileInfo.size,
        buttons: buttonForFile({ file })
      })
    );
  };

  const showSimpleSubmitModal = () =>
    showModal('SIMPLE_SUBMIT', {
      captionKey: 'cancel_request',
      text: t('cancel_request_text'),
      textBtnConfirm: t('Confirm'),
      onSubmited: goBack,
      submitAction: noop
    });

  const isEditMode = () => Boolean(request.id);

  const onChange =
    (setState) =>
    ({ target: { value } }) =>
      setState(value);

  const hasSuppliers = () => {
    if (!isEditMode()) {
      return false;
    }

    return !isEmpty(request.suppliers);
  };

  const isPublished = () => {
    if (!isEditMode()) {
      return false;
    }

    const unpublishStatuses = ['draft', 'removed_publication'];

    return !unpublishStatuses.includes(request.status);
  };

  const isProductsValid = () => {
    if (!(openedComponents.description || openedComponents.table)) {
      return false;
    }
    if (
      openedComponents.description &&
      productsList.replace(/(<.*?>)|(&nbsp;)|(\s)/g, '').length === 0
    ) {
      return false;
    }
    if (openedComponents.table && getInvalidProducts(products).length > 0) {
      return false;
    }
    return true;
  };

  const countProductsSum = () =>
    openedComponents.table
      ? round(
          getValidProducts(products).reduce(
            (prev, curr) => prev + curr.count * curr.price,
            0
          )
        )
      : 0;

  const isSumValid = () => Number(countProductsSum() ?? sum) >= 0;

  const isTableProductsSumValid = () => {
    if (!openedComponents.table) {
      return true;
    }

    const _sum = countProductsSum();

    return _sum >= 0 && _sum < MAX_REQUEST_SUM;
  };

  const isFieldsValid = () =>
    isProductsValid() &&
    isSumValid() &&
    isTableProductsSumValid() &&
    number.trim() &&
    isTitleValid() &&
    (requestType === purchaseRequestTypes.regular
      ? country &&
        region &&
        categoriesId.length > 0 &&
        responsibleUser &&
        responseEndDate
      : true);

  const isSaveFieldsValid = () =>
    isProductsValid() &&
    isSumValid() &&
    isTableProductsSumValid() &&
    isTitleValid();

  const isDisabledPublication = () => {
    const allowPublicationStatuses = ['draft', 'removed_publication'];

    return (
      (!allowPublicationStatuses.includes(request.status) &&
        !isEmpty(request)) ||
      (request.responseEndDate &&
        new Date() > new Date(new Date(request.responseEndDate)))
    );
  };

  const getNotEmptyProducts = () =>
    products.reduce(
      (acc, curr) =>
        curr.name || curr.price || curr.count ? [...acc, curr] : acc,
      []
    );

  const generatePurchaseRequest = () =>
    PurchaseRequestMapper.toDomain({
      ...request,
      ...body,
      type: requestType,
      productsList: openedComponents.description ? productsList : '',
      attachments,
      links,
      partResponse,
      products: openedComponents.table ? getValidProducts(products) : [],
      sum: countProductsSum() || sum,
      currency: DEFAULT_CURRENCY,
      companyId: company.id,
      accessCompanyList
    });

  function runSaveValidation() {
    if (!isSaveFieldsValid()) {
      setProducts(
        getNotEmptyProducts().length > 0
          ? getNotEmptyProducts()
          : [{ ...initProduct }]
      );
      setIsErrorShown({ ...initialErrorState, save: true });
      document.getElementById('title-form').scrollIntoView();
      return false;
    }
    setIsErrorShown(initialErrorState);
    return true;
  }

  function runPublishValidation() {
    if (!isFieldsValid()) {
      setProducts(
        getNotEmptyProducts().length > 0
          ? getNotEmptyProducts()
          : [{ ...initProduct }]
      );
      setIsErrorShown({ ...initialErrorState, publish: true });
      document.getElementById('title-form').scrollIntoView();
      return false;
    }
    setIsErrorShown(initialErrorState);
    return true;
  }

  function runValidation(type) {
    if (!openedComponents.table) {
      setProducts([{ ...initProduct }]);
      setPartResponse(false);
    }

    switch (type) {
      case ACTION_TYPES.PUBLISH:
        return runPublishValidation();
      case ACTION_TYPES.SAVE:
        return runSaveValidation();
      default:
        console.error('Unknown action type');
    }
  }

  const handleCreateOrSaveRequest = async ({ isNoticeOn = true } = {}) => {
    const purchaseRequest = generatePurchaseRequest();
    const isValid = purchaseRequest.validate();
    localStorage.setItem('pr-location', [country, region, city].join(';'));

    if (!isValid) {
      // TODO обработать кейс
      console.error('Validation failed');
      return;
    }

    try {
      if (request.id) {
        await editRequest(request.id, purchaseRequest, isNoticeOn);
      } else {
        const request = await purchaseRequestService.saveDraft(
          purchaseRequest,
          isNoticeOn
        );
        updateRequest(request);
        setSuccessMessage({ key: 'Request create success' });
        if (!requestId) {
          navigate(`/requests/my/${request.id}/edit`);
        }
        gaSend('complete_request_creation');
      }
    } catch (e) {
      manipulateRequestFailed({ error: e });
    }
  };

  const onCreateOrSaveRequest = async () => {
    const isValid = runValidation(ACTION_TYPES.SAVE);

    if (!isValid) {
      return;
    }

    typeaheadRef.current.save();

    await handleCreateOrSaveRequest({ isNoticeOn: true });
  };

  const onPublish = async () => {
    const isValid = runValidation(ACTION_TYPES.PUBLISH);

    if (!isValid) {
      return;
    }

    typeaheadRef.current.save();

    if (request.id) {
      await handleCreateOrSaveRequest({ isNoticeOn: false });
    }

    const translateKey =
      purchaseRequestTypes.regular === requestType
        ? 'modal_publish_request'
        : 'modal_confirmation_request';
    showModal('SIMPLE_SUBMIT', {
      title: t(translateKey),
      text: t(`${translateKey}_text`),
      textBtnCancel: t(`${translateKey}_cancel`),
      textBtnConfirm: t(`${translateKey}_confirm`),
      submitAction: async () => {
        const purchaseRequest = generatePurchaseRequest();
        if (!purchaseRequest.validate()) {
          // TODO обработать кейс
          console.error('Validation failed');
          return;
        }

        await publishRequest(request.id, purchaseRequest);

        if (purchaseRequestTypes.regular !== requestType) {
          setSuccessMessage({
            key: 'purchaseRequest.notifications.sendToAgreement'
          });
        }

        gaSend('publish_request');
        navigate('/requests/my');
      }
    });
  };

  const toggleComponent = (key) => (value) =>
    setOpenedComponents({ ...openedComponents, [key]: value });

  const showButtonsError =
    (isErrorShown.publish || isErrorShown.save) &&
    ((!openedComponents.table && !openedComponents.description) ||
      !isProductsValid());

  function hasChanges() {
    const currentData = makeRequest({
      categoriesId,
      title,
      date,
      country,
      region,
      city,
      productsList,
      products: openedComponents.table ? getValidProducts(products) : [],
      supplierRequirements,
      attachments,
      links,
      partResponse,
      responseEndDate,
      responsibleUser,
      sum,
      number,
      route,
      authorId
    });

    function isSameResponseEndDate() {
      if (
        isNil(initialData.responseEndDate) &&
        isNil(currentData.responseEndDate)
      ) {
        return true;
      }

      if (
        isNil(initialData.responseEndDate) ||
        isNil(currentData.responseEndDate)
      ) {
        return false;
      }

      return isSameDay(
        new Date(initialData.responseEndDate),
        new Date(currentData.responseEndDate)
      );
    }

    const initialDataForCompare = dissoc('responseEndDate', initialData);
    const currentDataForCompare = dissoc('responseEndDate', currentData);

    return (
      !equals(initialDataForCompare, currentDataForCompare) ||
      !isSameResponseEndDate()
    );
  }

  const canSave = () => {
    if (isEditMode()) {
      return !isPublished() && !hasSuppliers() && hasChanges();
    }
    return !isPublished() && !hasSuppliers();
  };

  function goBack() {
    let to = '/requests/all';

    if (request.id) {
      to = `/requests/my/${request.id}/view`;
    } else if (state.from) {
      to = state.from;
    }

    navigate(to);
  }

  const goBackClick = () => {
    if (hasChanges()) {
      showSimpleSubmitModal();
    } else {
      navigate(-1);
    }
  };

  const onCancel = async () => {
    if (hasChanges()) {
      showSimpleSubmitModal();
    } else {
      goBack();
    }
  };

  return (
    <div className="form-content-center">
      <div style={{ marginRight: 16 }}>
        <GoBack
          data-testid="new-request-go-back-button"
          onClick={goBackClick}
        />
      </div>
      <div style={{ width: '100%', minWidth: 830, maxWidth: 1200 }}>
        <GeneralInfo
          requestType={requestType}
          request={request}
          body={body}
          setBody={setBody}
          isErrorShown={isErrorShown}
          typeaheadRef={typeaheadRef}
          typeaheadTitleKey={typeaheadTitleKey}
        />

        <SectionOffset offset={16} />

        <Form className="new-request-form" data-testid="new-request-form">
          <div className="new-request-title">{t('request_form')}</div>
          <SectionOffset offset={12} />
          <div style={{ fontSize: 14 }} className="new-request-title">
            {t('components')}
            <span style={{ color: 'red', margin: '0 4px' }}>*</span>
            <InteractiveHelper>
              <Popover text={t('table_notice')} />
            </InteractiveHelper>
          </div>
          <RequestTable
            products={products}
            setProducts={setProducts}
            isErrorShown={isErrorShown}
            partResponse={partResponse}
            setPartResponse={setPartResponse}
            isOpened={openedComponents.table}
            setIsOpened={toggleComponent('table')}
          />

          <RequestDescription
            productsList={productsList}
            isOpened={openedComponents.description}
            setIsOpened={toggleComponent('description')}
            onChange={onChange(setProductsList)}
            isError={
              (isErrorShown.save || isErrorShown.publish) && !isProductsValid()
            }
          />

          <RequestSum
            isOpened={openedComponents.sum}
            setIsOpened={toggleComponent('sum')}
            onChange={onChange(setSum)}
            sum={sum}
            productsSum={countProductsSum()}
            isError={isErrorShown.publish && !isSumValid()}
          />

          <div>
            <ComponentButton
              testId="new-request-table-btn"
              onClick={() => toggleComponent('table')(true)}
              isOpened={openedComponents.table}
              name={t('table')}
              isError={showButtonsError}
            />
            <ComponentButton
              testId="new-request-content-btn"
              onClick={() => toggleComponent('description')(true)}
              isOpened={openedComponents.description}
              name={t('request_content')}
              isError={showButtonsError}
            />
            <ComponentButton
              testId="new-request-sum-btn"
              onClick={() => toggleComponent('sum')(true)}
              isOpened={openedComponents.sum}
              name={t('sum_request')}
              isError={isErrorShown.publish && !isSumValid()}
            />
          </div>

          {showButtonsError && (
            <HelpBlock
              data-testid="new-request-table-error-notice"
              style={{ marginBottom: 0 }}>
              {t('table_error_notice')}
            </HelpBlock>
          )}

          <SectionOffset offset={28} />

          <FilesBlock
            total={attachments.length}
            files={getFiles()}
            showAddBtn={
              !(
                storageSize >= maxStorageSize ||
                attachments.length >= MAX_UPLOAD_FILES
              )
            }
            onClickAdd={fileSelectHandler}
            classNames="fill-white"
          />
          <SectionOffset offset={28} />

          <LinksBlock
            total={links.length}
            links={getLinks()}
            showAddBtn="true"
            onClickAdd={handleAddLink}
            classNames="fill-white"
          />
        </Form>

        <Row style={{ marginTop: 10 }}>
          <span style={{ color: 'red', marginLeft: 15 }}>* </span>
          <span style={{ fontSize: '12px', color: '#767B92' }}>
            {t('Required fields')}
          </span>
        </Row>
        <Row>
          <div
            style={{ marginTop: 35, display: 'flex', gap: '8px' }}
            className="form-button-center">
            <Button
              mode="text"
              data-testid="new-request-cancel-btn"
              id="cancelCatPropsBtn"
              onClick={onCancel}>
              {t('Cancel')}
            </Button>

            {requestType === purchaseRequestTypes.regular && (
              <Button
                mode="outline"
                data-testid="new-request-create-or-save-btn"
                id="createOrSaveRequest"
                disabled={!canSave()}
                onClick={onCreateOrSaveRequest}>
                {t('Save')}
              </Button>
            )}

            <Button
              data-testid="new-request-publish-btn"
              disabled={isDisabledPublication()}
              onClick={onPublish}>
              {requestType !== purchaseRequestTypes.regular
                ? t('Send for Review')
                : t('Publish')}
            </Button>
          </div>
        </Row>
      </div>
    </div>
  );
};

export default withRouter(
  connect(
    (state) => ({
      storageSize: state.getIn(['storage', 'storageSize']),
      maxStorageSize: state.getIn(['storage', 'maxStorageSize'])
    }),
    (dispatch) =>
      bindActionCreators(
        {
          getStorageSize: storageActions.getStorageSize,
          gaSend: serviceActions.gaSendServiceAction,
          setErrorMessage: messageActions.setErrorMessage,
          setSuccessMessage: messageActions.setSuccessMessage,
          showModal: modalActions.showModal
        },
        dispatch
      )
  )(NewRequest)
);
