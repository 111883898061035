import React, { Component } from 'react';
import { isEmpty } from 'ramda';
import PropTypes from 'prop-types';
import block from 'bem-cn-lite';
import { translate } from 'react-i18next';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  Modal,
  FormGroup,
  FormControl,
  Button,
  HelpBlock,
  ControlLabel
} from 'react-bootstrap';
import { Typeahead } from '@link/react-components';
import Helper from '../Helper';
import {
  validateEmail,
  validateCyrLatDigitSpaceDash
} from '../../utils/validateField';
import { logModule } from '../../utils/logger';
import TablePermissions from '../../containers/TablesNew/TablePermissions';
import './styles/modal_theme_default.styl';
import Select from '../Select/Universal';

import { ModalContainer } from './elements';
import * as companyActions from '../../action-creators/company';

import * as servicesActions from '../../action-creators/services';
import * as modalAction from '../../action-creators/modal';

const b = block('modal-container');

const getTKeyTitle = (section) => `tips.permissions.${section}.title`;
const getTKeyDesc = (section) => `tips.permissions.${section}.desc`;

const log = logModule.extend('ModalEditEmployee');
class ModalEditEmployee extends Component {
  static isPositionInCompanyValid(value) {
    if (value !== '' && value !== undefined) {
      return validateCyrLatDigitSpaceDash(value);
    }
    return true;
  }

  constructor(props) {
    super(props);
    this.state = {
      email: props.selectedEmployee.get('email') || '',
      department: props.selectedEmployee.get('department') || '',
      position: props.selectedEmployee.get('position') || '',
      permissions:
        props.permissions || props.selectedEmployee.get('permissions'),
      employeeVisibility: props.selectedEmployee.get('employeeVisibility'),
      requestComplete: false
    };
    this.setPositionInCompany = this.setPositionInCompany.bind(this);
    this.onChangePermissions = this.onChangePermissions.bind(this);
    this.confirmChangePermissions = this.confirmChangePermissions.bind(this);
    this.setDepartment = this.setDepartment.bind(this);
    this.action = this.action.bind(this);
    this.typeaheadRef = React.createRef();
    this.typeaheadTitleKey = 'department';
  }

  getChildContext() {
    return { b };
  }

  onChange(field, value) {
    this.setState({ [field]: value });
  }

  onChangePermissions(permissions) {
    this.setState({ permissions });
  }

  setPositionInCompany(e) {
    this.setState({ position: e.target.value.toString() });
  }

  setEmail(e) {
    this.setState({ email: e.target.value.toString() });
  }

  setDepartment(value) {
    this.setState({ department: value });
  }

  get permissions() {
    return this.state.permissions;
  }

  get isSelectedOwner() {
    const { selectedEmployee, ownerId } = this.props;

    if (!selectedEmployee) return null;

    return selectedEmployee.toJS().userId === ownerId;
  }

  get isCurrentUserOwner() {
    const { userId, ownerId } = this.props;

    return userId === ownerId;
  }

  get isChangeEmailDisabled() {
    return this.isSelectedOwner && !this.isCurrentUserOwner;
  }

  get isEmptyEmail() {
    return isEmpty(this.state.email);
  }

  async validateNameInCompanyUnique(name) {
    const { isNameInCompanyUnique, selectedEmployee } = this.props;
    await isNameInCompanyUnique(name, selectedEmployee.get('id'));
    this.setState({ requestComplete: true });
  }

  isNameInCompanyEmpty() {
    return this.state.nickname.length === 0;
  }

  isEmptyPosition() {
    return this.state.position.trim().length === 0;
  }

  isChangePermissions() {
    const { selectedEmployee } = this.props;
    return !selectedEmployee.get('permissions').equals(this.state.permissions);
  }

  haveChange(key) {
    return this.state[key] !== this.props.selectedEmployee.get(key);
  }

  isValidPosition() {
    return (
      !this.isEmptyPosition() &&
      ModalEditEmployee.isPositionInCompanyValid(this.state.position)
    );
  }

  isSaveDisabled() {
    log(
      'isChangePermissions',
      this.isChangePermissions(),
      'haveChange(position)',
      this.haveChange('position'),
      'haveChange(employeeVisibility)',
      this.haveChange('employeeVisibility'),
      'isValidPosition',
      this.isValidPosition()
    );
    if (this.isEmptyPosition() || !this.isValidPosition()) return true;
    if (!validateEmail(this.state.email)) return true;

    const result =
      this.isChangePermissions() ||
      this.haveChange('position') ||
      this.haveChange('email') ||
      this.haveChange('department') ||
      this.haveChange('employeeVisibility');
    return !result;
  }

  asterisk() {
    return <span style={{ color: 'red' }}> * </span>;
  }

  inputProps(field) {
    return {
      onChange: (e) => this.onChange(field, e.target.value)
    };
  }

  confirmChangePermissions() {
    const {
      t,
      showModal,
      hideModalDialog,
      getListOfUsers,
      editEmployee,
      selectedEmployee
    } = this.props;

    showModal('SIMPLE_SUBMIT', {
      captionKey: 'change_permissions_title',
      text: t('change_permissions_text'),
      textBtnConfirm: t('Confirm'),
      submitAction: () => this.action(),
      onCancel: () =>
        showModal('EDIT_EMPLOYEE', {
          title: 'Edit employee',
          hideModalDialog,
          editEmployee,
          getListOfUsers,
          selectedEmployee,
          permissions: this.state.permissions
        })
    });
  }

  checkPermissionsLower(origin, edited) {
    const { delete: deleted, update, read } = origin;
    if (deleted) {
      return !edited.delete;
    }
    if (update) {
      return !edited.update;
    }
    if (read) {
      return !edited.read;
    }
    return false;
  }

  isPermissionsLower() {
    const { selectedEmployee } = this.props;
    const permissions = selectedEmployee.get('permissions').toJS();

    const { customers, suppliers, customerOrders, supplierOrders } =
      this.state.permissions.toJS();
    if (this.checkPermissionsLower(permissions.customers, customers)) {
      return true;
    }
    if (this.checkPermissionsLower(permissions.suppliers, suppliers)) {
      return true;
    }
    if (
      this.checkPermissionsLower(permissions.customerOrders, customerOrders)
    ) {
      return true;
    }
    if (
      this.checkPermissionsLower(permissions.supplierOrders, supplierOrders)
    ) {
      return true;
    }
    return false;
  }

  async action() {
    const {
      gaSend,
      user,
      editEmployee,
      hideModalDialog,
      getListOfUsers,
      selectedEmployee
    } = this.props;
    if (this.isChangePermissions()) {
      await gaSend({
        category: 'Customer',
        action: 'company_access_rights',
        label: user.get('email')
      });
    }
    await editEmployee(
      selectedEmployee.get('id'),
      this.state.nickname,
      this.state.position,
      this.permissions.toJS(),
      this.state.employeeVisibility,
      this.state.email,
      this.state.department.trim()
    );
    await hideModalDialog();
    await getListOfUsers();
  }

  render() {
    const {
      t,
      title,
      hideModalDialog,
      company,
      selectedEmployee,
      userId,
      ownerId,
      editPermissions,
      full
    } = this.props;

    return (
      <Modal id="inviteEmployee" onHide={() => hideModalDialog()} show>
        <ModalContainer mods={{ theme: 'default' }}>
          <Modal.Header>
            <Modal.Title>
              {t(title)}
              <span
                className="modal-header-cross pull-right"
                onClick={() => hideModalDialog()}
              />
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <FormGroup
              controlId="email"
              style={{ overflow: 'hidden' }}
              validationState={
                validateEmail(this.state.email) ? 'success' : 'error'
              }>
              <ControlLabel
                style={{
                  color: '#999999',
                  fontWeight: 'normal',
                  fontSize: '14px'
                }}>
                {`${t('Employee email')}`}
              </ControlLabel>
              <br />

              <FormControl
                type="text"
                placeholder={t('email')}
                defaultValue={selectedEmployee.get('email')}
                onChange={this.setPositionInCompany}
                disabled={this.isChangeEmailDisabled}
                {...this.inputProps('email')}
              />
              {this.isEmptyEmail && (
                <HelpBlock style={{ marginBottom: 0 }}>
                  {t('Required fields empty')}
                </HelpBlock>
              )}
              {!validateEmail(this.state.email) && !this.isEmptyEmail && (
                <HelpBlock style={{ marginBottom: 0 }}>
                  {t('Wrong email format')}
                </HelpBlock>
              )}
            </FormGroup>
            <FormGroup
              controlId="position"
              validationState={this.isEmptyPosition() ? 'error' : null}
              style={{ overflow: 'hidden' }}>
              <ControlLabel
                style={{
                  color: '#999999',
                  fontWeight: 'normal',
                  fontSize: '14px'
                }}>
                {t('Position')}
                {this.asterisk()}
              </ControlLabel>
              <FormControl
                type="text"
                placeholder={t('example goodwix')}
                defaultValue={selectedEmployee.get('position')}
                onChange={this.setPositionInCompany}
                {...this.inputProps('position')}
              />
              {this.isEmptyPosition() && (
                <HelpBlock style={{ marginBottom: 0 }}>
                  {t('Required fields empty')}
                </HelpBlock>
              )}
              {!ModalEditEmployee.isPositionInCompanyValid(
                this.state.position
              ) &&
                !this.isEmptyPosition() && (
                  <HelpBlock style={{ marginBottom: 0 }}>
                    {t('wrong_position_in_company')}
                  </HelpBlock>
                )}
            </FormGroup>
            <FormGroup controlId="department" style={{ overflow: 'hidden' }}>
              <ControlLabel
                style={{
                  color: '#999999',
                  fontWeight: 'normal',
                  fontSize: '14px'
                }}>
                {t('department')}
              </ControlLabel>
              <Typeahead
                initialValue={selectedEmployee.get('department')}
                onChange={this.setDepartment}
                store={this.typeaheadTitleKey}
                ref={this.typeaheadRef}
              />
            </FormGroup>
            <FormGroup controlId="visability">
              <ControlLabel
                style={{
                  color: '#999999',
                  fontWeight: 'normal',
                  fontSize: '14px'
                }}>
                {`${t('visibility-of-employee')}`}
              </ControlLabel>
              <Select
                value={this.state.employeeVisibility}
                onChange={(value) =>
                  this.setState({ employeeVisibility: value })
                }
                options={['hidden-from-other-companies', 'visible-to-all']}
                translateGroup="employee-visibility"
              />
            </FormGroup>
            <div style={{ margin: '25px 0' }}>
              <h5 style={{ display: 'inline' }}>
                {t('Access right to sections')}
              </h5>
              &nbsp;
              <Helper>
                {[
                  'no-access',
                  'view-access',
                  'change-access',
                  'full-access'
                ].map((name) => (
                  <>
                    <h5 style={{ marginBottom: 3 }}>{t(getTKeyTitle(name))}</h5>
                    <p style={{ marginBottom: 15 }}>{t(getTKeyDesc(name))}</p>
                  </>
                ))}
              </Helper>
            </div>
            <TablePermissions
              permissions={this.permissions}
              billingAllowed={company.get('billingAllowed')}
              onChange={this.onChangePermissions}
              readOnly={
                userId === selectedEmployee.get('id') ||
                selectedEmployee.get('id') === ownerId ||
                !(editPermissions && full)
              }
            />
          </Modal.Body>
          <Modal.Footer>
            <Button
              id="cancel"
              className="pull-left"
              onClick={() => {
                hideModalDialog();
              }}>
              {t('Cancel')}
            </Button>
            <Button
              id="editEmployeerButton"
              bsStyle="primary"
              disabled={this.isSaveDisabled()}
              onClick={async () => {
                if (this.isPermissionsLower()) {
                  this.confirmChangePermissions();
                } else {
                  this.typeaheadRef.current.save();
                  this.action();
                }
              }}>
              {t('Save changes')}
            </Button>
          </Modal.Footer>
        </ModalContainer>
      </Modal>
    );
  }
}

ModalEditEmployee.propTypes = {
  t: PropTypes.func.isRequired
};

ModalEditEmployee.childContextTypes = {
  b: PropTypes.func
};

export default connect(
  (state) => ({
    company: state.get('company'),
    user: state.getIn(['user', 'user']),
    ownerId: state.getIn(['company', 'ownerUserId']),
    userId: state.getIn(['user', 'user', 'id']),
    full: state.getIn(['user', 'user', 'permissions', 'employees', 'delete']),
    editPermissions: state.getIn([
      'user',
      'user',
      'permissions',
      'employees',
      'editPermissions'
    ])
  }),
  (dispatch) =>
    bindActionCreators(
      {
        showModal: modalAction.showModal,
        clearSelectedEmployee: companyActions.clearSelectedEmployee,
        gaSend: servicesActions.gaSend
      },
      dispatch
    )
)(translate(['ui'], { wait: true })(ModalEditEmployee));
