import React from 'react';
import { Popover } from 'react-bootstrap';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { translate } from 'react-i18next';
import block from 'bem-cn-lite';
import TreeView from 'react-treeview';
import { withRouter } from '../../hoc/withRouter';

import './styles/listOfCategories.styl';
import 'react-treeview/react-treeview.css';
import Checkbox from '../Checkbox';

import * as productActionCreators from '../../action-creators/product';
import * as tableActionCreators from '../../action-creators/table';
import * as categoryAction from '../../action-creators/category';

const b = block('list-of-categories');

const catNameFontSize = 13;
const catNameMarginLeft = 15;

function TreeChildren({
  treeChildren,
  list,
  clickOnPanel,
  selectedCategories
}) {
  if (treeChildren.isEmpty()) {
    return null;
  }
  return (
    <div>
      {treeChildren.map((category, i) => {
        const children = list.filter(
          (c) => c.get('parentId') === category.get('id')
        );
        return (
          <div className={b('tree-view')} key={category.get('id')}>
            {children.isEmpty() ? (
              <div className={b('tree-view', { children: true })}>
                <Checkbox
                  key={category.get('id')}
                  set={selectedCategories.includes(category.get('id'))}
                  onClick={() => clickOnPanel(category)}
                  style={{ margin: 0 }}
                />
                <span
                  style={{
                    marginLeft: catNameMarginLeft,
                    fontSize: catNameFontSize
                  }}>
                  {category.get('name')}
                </span>
              </div>
            ) : (
              <TreeView
                defaultCollapsed
                style={{ display: 'flex' }}
                key={`${category.get('name')}|${i}|${children.toJS()}`}
                nodeLabel={
                  <div className={b('tree-view', { label: true })}>
                    <Checkbox
                      key={category.get('id')}
                      set={selectedCategories.includes(category.get('id'))}
                      onClick={() => clickOnPanel(category.get('id'))}
                      style={{ margin: 0 }}
                    />
                    <span
                      style={{
                        marginLeft: catNameMarginLeft,
                        fontSize: catNameFontSize
                      }}>
                      {category.get('name')}
                    </span>
                  </div>
                }>
                <TreeChildren
                  clickOnPanel={clickOnPanel}
                  treeChildren={children}
                  list={list}
                  selectedCategories={selectedCategories}
                />
              </TreeView>
            )}
          </div>
        );
      })}
    </div>
  );
}

class ListOfCategory extends React.Component {
  constructor(props) {
    super(props);
    this.clickOnPanel = this.clickOnPanel.bind(this);
    this.popoverHoverFocus = this.popoverHoverFocus.bind(this);
    this.checkInclude = this.checkInclude.bind(this);
    this.selectAll = this.selectAll.bind(this);
    this.selectAllChildrenCategories =
      this.selectAllChildrenCategories.bind(this);
    this.deselectAllChildrenCategories =
      this.deselectAllChildrenCategories.bind(this);
    this.allChildrenCategoriesSelected =
      this.allChildrenCategoriesSelected.bind(this);
    this.someChildrenCategoriesSelected =
      this.someChildrenCategoriesSelected.bind(this);
  }

  UNSAFE_componentWillMount() {
    const { list } = this.props;
    list.forEach((item) => this.setState({ [item.get('id')]: false }));
  }

  popoverHoverFocus(item) {
    if (item.get('text')) {
      return (
        <Popover
          id="popover-trigger-hover-focus"
          style={{ maxWidth: 840, wordBreak: 'break-all' }}
          title={this.props.t('Category description')}>
          {item.get('text')}
        </Popover>
      );
    }
    return <div />;
  }

  async clickOnPanel(category) {
    const { list, selectCategory, deselectCategory } = this.props;

    const categoryId = category.get('id');
    const parentCategoryId = category.get('parentId');

    if (parentCategoryId === null) {
      if (this.checkInclude(categoryId)) {
        await this.deselectAllChildrenCategories(categoryId);
      } else {
        await this.selectAllChildrenCategories(categoryId);
      }
      return selectCategory(categoryId);
    }
    await selectCategory(categoryId);
    if (
      list
        .filter((i) => i.get('parentId') === parentCategoryId)
        .every((i) => this.checkInclude(i.get('id')))
    ) {
      if (!this.checkInclude(parentCategoryId)) {
        await selectCategory(parentCategoryId);
      }
    } else {
      await deselectCategory(parentCategoryId);
    }
    return undefined;
  }

  checkInclude(id) {
    const { selectedCategories } = this.props;
    return selectedCategories.includes(id);
  }

  get allCategoriesSelected() {
    const { list = [] } = this.props;
    return list.every((i) => this.checkInclude(i.get('id')));
  }

  allChildrenCategoriesSelected(parentId) {
    const { list = [] } = this.props;
    return list
      .filter((i) => i.get('parentId') === parentId)
      .every((i) => this.checkInclude(i.get('id')));
  }

  someChildrenCategoriesSelected(parentId) {
    const { list = [] } = this.props;
    return list
      .filter((i) => i.get('parentId') === parentId)
      .some((i) => this.checkInclude(i.get('id')));
  }

  selectAllChildrenCategories(parentId) {
    const { list, selectCategory } = this.props;
    list.forEach((i) => {
      if (i.get('parentId') === parentId) {
        selectCategory(i.get('id'));
      }
    });
  }

  deselectAllChildrenCategories(parentId) {
    const { list, deselectCategories } = this.props;
    deselectCategories(
      list.filter((i) => i.get('parentId') === parentId).map((i) => i.get('id'))
    );
  }

  selectAll() {
    const { selectCategories, deselectAllCategories, list } = this.props;
    if (this.allCategoriesSelected) {
      deselectAllCategories();
    } else {
      selectCategories(list.map((i) => i.get('id')));
    }
  }

  render() {
    const { t, list, selectedCategories } = this.props;

    return (
      <div className={b()}>
        <div className={b('panel-title')} style={{ justifyContent: 'left' }}>
          <Checkbox
            key="all"
            set={this.allCategoriesSelected}
            onClick={() => this.selectAll()}
            className={b('checkbox')}
            style={{ marginLeft: 20 }}
          />
          <div className={b('name')} style={{ marginLeft: 15 }}>
            {t('Category name')}
          </div>
        </div>
        <div style={{ overflowY: 'auto', height: 315 }}>
          {list.map((category, i) => {
            if (
              !category.get('parentId') ||
              !list.find((cat) => cat.get('id') === category.get('parentId'))
            ) {
              const children = list.filter(
                (c) => c.get('parentId') === category.get('id')
              );
              return (
                <div key={category.get('id')}>
                  {children.isEmpty() ? (
                    <div className={b('children-panel')}>
                      <Checkbox
                        key={category.get('id')}
                        set={selectedCategories.includes(category.get('id'))}
                        onClick={() => this.clickOnPanel(category)}
                        className={b('checkbox')}
                      />
                      <span
                        style={{ marginLeft: 10, fontSize: catNameFontSize }}>
                        {category.get('name')}
                      </span>
                    </div>
                  ) : (
                    <TreeView
                      defaultCollapsed={
                        !this.someChildrenCategoriesSelected(category.get('id'))
                      }
                      key={`${category.get('name')}|${i}|${children.toJS()}`}
                      nodeLabel={
                        <div className={b('tree-view', { label: true })}>
                          <Checkbox
                            key={category.get('id')}
                            set={selectedCategories.includes(
                              category.get('id')
                            )}
                            c
                            onClick={() => this.clickOnPanel(category)}
                            className={b('checkbox')}
                          />
                          <span
                            style={{
                              marginLeft: 10,
                              fontSize: catNameFontSize
                            }}>
                            {category.get('name')}
                          </span>
                        </div>
                      }
                      style={{ display: 'flex', marginBottom: 4 }}>
                      <TreeChildren
                        clickOnPanel={this.clickOnPanel}
                        treeChildren={children}
                        list={list}
                        selectedCategories={selectedCategories}
                      />
                    </TreeView>
                  )}
                </div>
              );
            }
            return null;
          })}
        </div>
      </div>
    );
  }
}

export default withRouter(
  connect(
    (state) => ({
      windowHeight: state.getIn(['browserProps', 'windowHeight'])
    }),
    (dispatch) =>
      bindActionCreators(
        {
          getProducts: productActionCreators.getProducts,
          clearTable: tableActionCreators.clearTable,
          sortBy: tableActionCreators.sortBy,
          touchCategory: tableActionCreators.touchRow,
          touchRow: tableActionCreators.touchRowProducts,
          touchSingleRow: tableActionCreators.touchSingleRow,
          getCategories: categoryAction.getCategories,
          deselectAll: tableActionCreators.deselectAll
        },
        dispatch
      )
  )(translate(['ui'], { wait: true })(ListOfCategory))
);
