import { useState, useCallback } from 'react';
import { nanoid } from 'nanoid';

export const useRemountByKey = (keyLength = 7) => {
  // NOTE: if you need to change length of key
  // see https://zelark.github.io/nano-id-cc/
  const genKey = () => nanoid(keyLength);
  const [key, setKey] = useState(genKey());
  const updateKey = useCallback(() => setKey(genKey()), []);

  return {
    key,
    remount: updateKey
  };
};
