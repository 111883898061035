import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { translate } from 'react-i18next';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Modal } from 'react-bootstrap';
import { Container, Header, Body, Footer } from '../Components';
import ContactsList from './ContactsList';
import * as modalActions from '../../../../action-creators/modal';
import './style.css';

const CWAddToContactList = ({
  t,
  contactsList,
  hideModalDialog = () => {},
  onCancel,
  onSave
}) => {
  const [checked, setChecked] = useState([]);

  const checkContact = (contactId) => setChecked([...checked, contactId]);
  const uncheckContact = (contactId) =>
    setChecked(checked.filter((id) => id !== contactId));
  const onCheckToggle = (id, value) => {
    if (value) {
      checkContact(id);
    } else {
      uncheckContact(id);
    }
  };

  const lbOnClick = () => {
    hideModalDialog();
    onCancel();
  };

  const rbOnClick = () => {
    hideModalDialog();
    onSave(checked);
  };

  return (
    <Modal show onHide={() => hideModalDialog()}>
      <Container>
        <Header title={t('add-contact-to-list')} onClose={hideModalDialog} />
        <Body>
          <ContactsList
            contactList={contactsList}
            checkedList={checked}
            onCheckToggle={onCheckToggle}
          />
        </Body>
        <Footer
          lbTitle={t('Cancel')}
          lbOnClick={lbOnClick}
          rbTitle={t('Save')}
          rbOnClick={rbOnClick}
          rbDisabled={false}
        />
      </Container>
    </Modal>
  );
};

CWAddToContactList.propTypes = {
  t: PropTypes.func.isRequired,
  hideModalDialog: PropTypes.func.isRequired,
  contactsList: PropTypes.array.isRequired,
  onCancel: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired
};

const mapDispatch = (dispatch) =>
  bindActionCreators(
    {
      hideModalDialog: modalActions.hideModalDialog
    },
    dispatch
  );

export default connect(null, mapDispatch)(translate()(CWAddToContactList));
