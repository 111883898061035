import { useEffect, useState } from 'react';
import { companyService } from '../../../api';

export function useCompany(companyId) {
  const [loading, setLoading] = useState(true);
  const [company, setCompany] = useState(null);

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const result = await companyService.getCompanyInfo(companyId);

        setCompany(result);
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    })();
  }, []);

  return { loading, company, setCompany };
}
