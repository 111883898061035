import React, { useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Interpolate, translate } from 'react-i18next';
import { isEmpty } from 'ramda';
import { withRouter } from '../../../../hoc/withRouter';
import * as serviceActions from '../../../../action-creators/services';
import * as storage from '../storage';
import * as chatWidgetUC from '../../../ChatWidget/useCases';
import * as getters from '../getters';
import * as storeGetters from '../../../../storeGetters';
import Pagination from '../../../Pagination';
import Loader from '../../../Loader';
import './styles.css';
import RequestCard from './RequestCard';
import EmptySearchResult from '../../common/EmptySearchResult';
import * as modalActions from '../../../../action-creators/modal';

const RequestTable = ({
  t,
  showModal,
  isUploaded = true,
  isArchive = false,
  requests,
  getMyRequests,
  direction,
  orderBy,
  search = '',
  openSupportChat,
  limit = 20,
  offset = 0,
  selectedStatus,
  selectedResponseStatus,
  gaSend,
  size,
  history,
  permissions
}) => {
  const [openedId, setOpenedId] = useState();

  const selectNextPagination = (newOffset, newLimit) => {
    getMyRequests({
      limit: newLimit,
      offset: newOffset,
      order: orderBy,
      direction,
      search,
      selectedStatus,
      selectedResponseStatus,
      isArchive
    });
  };

  const showNewRequestPage = () => {
    gaSend('create_request');
    if (true) {
      showModal('MODAL_CREATE_PURCHASE_REQUEST');
      return;
    }
    history.push('/requests/my/create', { noRestoreData: true });
  };

  if (!isUploaded) {
    return <Loader theme="page" />;
  }

  const isFilterApplied =
    !isEmpty(selectedStatus) ||
    !isEmpty(selectedResponseStatus) ||
    !isEmpty(search);

  if (requests.length === 0 && !isFilterApplied) {
    return (
      <Interpolate
        i18nKey="My requests empty text"
        feedback={
          <a style={{ fontWeight: 500 }} onClick={openSupportChat}>
            {t('byFeedback')}
          </a>
        }
        create_request={
          <a onClick={showNewRequestPage} style={{ fontWeight: 500 }}>
            {t('create_request')}
          </a>
        }
      />
    );
  }

  return (
    <>
      {requests.length !== 0 ? (
        <div>
          {requests.map((card) => (
            <RequestCard
              key={card.id}
              request={card}
              openedId={openedId}
              setOpenedId={setOpenedId}
              permissions={permissions}
            />
          ))}
          <div style={{ marginTop: 12 }} />
          <Pagination
            totalSize={size}
            limit={limit}
            offset={offset}
            nextPage={selectNextPagination}
            isShowItemsOnPage
          />
        </div>
      ) : (
        <EmptySearchResult tkey="Requests filters empty text" />
      )}
    </>
  );
};

export default withRouter(
  connect(
    (state) => ({
      size: getters.getSize(state),
      requests: getters.getMyRequests(state),
      limit: getters.getLimit(state),
      offset: getters.getOffset(state),
      orderBy: getters.getOrder(state),
      direction: getters.getDirection(state),
      search: getters.getSearch(state),
      isUploaded: getters.getIsUploaded(state),
      permissions: storeGetters.getPurchaseRequestsPermission(state)
    }),
    (dispatch) =>
      bindActionCreators(
        {
          showModal: modalActions.showModal,
          gaSend: serviceActions.gaSendServiceAction,
          getMyRequests: storage.getMyRequests,
          openSupportChat: chatWidgetUC.openSupportChat
        },
        dispatch
      )
  )(translate()(RequestTable))
);
