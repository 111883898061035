import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { SearchInput, DynamicList } from '@link/react-components';
import { useTranslate, useDebounce } from '@hooks';
import { partnersCompanyService } from '../../../../api';
import { CompanyCard } from '../CompanyCard';
import { NoSearchResult } from '../NoSearchResult';
import { NoResult } from '../NoResult';
import { InvitePartnerButton } from '../InvitePartnerButton';
import { calcOffset } from '../../utils';
import classes from './PartnersList.module.css';

const ITEMS_PER_PAGE = 20;
const GAP_BETWEEN_ITEMS = 12;
const SEARCH_TYPING_DEBOUNCE = 500;

export function PartnersList() {
  const t = useTranslate();
  const [search, setSearch] = useState('');
  const [loading, setLoading] = useState(false);
  const [partnersCount, setPartnersCount] = useState(0);

  const onSearchTyping = useDebounce((searchValue) => {
    setSearch(searchValue);
  }, SEARCH_TYPING_DEBOUNCE);

  useEffect(() => {
    setPartnersCount(0);
  }, [search]);

  const onLoad = useCallback(
    async (page, limit) => {
      try {
        setLoading(true);

        const partners = await partnersCompanyService.getAll({
          limit,
          offset: calcOffset(page, limit),
          search
        });

        setPartnersCount((prevState) => prevState + partners.length);

        return partners;
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    },
    [search, setPartnersCount]
  );

  const provider = useMemo(
    () => ({
      load: onLoad
    }),
    [onLoad]
  );

  const searchDisabled = !loading && !search && !partnersCount;

  return (
    <div className={classes.container}>
      <div className={classes.controlPanel}>
        <div className={classes.searchInput}>
          <SearchInput
            defaultValue={search}
            onSearch={setSearch}
            onCleanUp={() => setSearch('')}
            onChange={onSearchTyping}
            placeholder={t('modules.partners.inputs.search.placeholder')}
            disabled={searchDisabled}
          />
        </div>
        <div className={classes.actions}>
          <InvitePartnerButton />
        </div>
      </div>
      <div className={classes.list}>
        <DynamicList
          provider={provider}
          renderItem={(company, updateCompany) => (
            <CompanyCard company={company} updateCompany={updateCompany} />
          )}
          noResultsScreen={search ? <NoSearchResult /> : <NoResult />}
          batchSize={ITEMS_PER_PAGE}
          gap={GAP_BETWEEN_ITEMS}
        />
      </div>
    </div>
  );
}
