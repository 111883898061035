import React from 'react';
import { sendJoinRequest } from '../commandsAndEvents';
import { useVideoChatContext } from './useVideoChatContext';
import { VIDEO_CHAT_MODS } from './useVideoChat';

export function useClickByVideoChatLink() {
  const context = useVideoChatContext();

  return React.useCallback(
    async (roomId, chatId) => {
      if (context.mode === VIDEO_CHAT_MODS.Hide) {
        if (context.chatId === chatId && context.roomId === roomId) {
          context.showWindow();
        } else {
          context.endCall({
            onSubmited: () => openNewVideoChat(roomId, chatId)
          });
        }
        return;
      }

      await openNewVideoChat(roomId, chatId);
    },
    [context.mode, context.roomId, context.addRoomChatConnection]
  );

  async function openNewVideoChat(roomId, chatId) {
    context.addRoomChatConnection({ roomId, chatId });

    sendJoinRequest(roomId, context.peerId);
  }
}
