import React from 'react';
import cn from 'classnames';
import { Typography } from '@components';
import Cell from '../Cell/Cell';
import styles from './ProductDataCell.module.css';

const ProductDataCell = ({ containerClassName, className, children }) => (
  <Cell className={cn(styles.cell, containerClassName)}>
    <Typography
      variant="body1Reg"
      className={cn(styles.text, className)}
      title={children}>
      {children}
    </Typography>
  </Cell>
);

export default ProductDataCell;
