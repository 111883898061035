import fetch from 'isomorphic-fetch';
import { assocPath, hasPath, merge } from 'ramda';
import { nanoid } from 'nanoid';
import customHistory from '../customHistory';
import { showModal, hideModal } from './modal';
import langBrowser from '../utils/languageDetector';
import ordersStorage, {
  writeUnsentOrderCommentsToLS
} from '../storage/orders.storage';
import {
  deleteOrderBadges,
  orderCustomerViewed
} from '../storage/badge.storage';
import { actionCreator } from '../lib';
import {
  ORDERS_TO_SUPPLIERS_SET,
  ORDERS_TO_SUPPLIERS_GET,
  ORDERS_TO_SUPPLIERS_UNSELECT,
  // ORDERS_TO_SUPPLIERS_SELECT,
  ORDERS_TO_SUPPLIERS_CUSTOMER_VIEWED_ORDER,
  ORDERS_TO_SUPPLIERS_FILTER_SELECT_TOGGLE,
  ORDERS_ADD,
  ORDERS_CLEAR,
  ORDERS_TOGGLE_ALL,
  ORDERS_TOGGLE_ONE,
  ORDERS_CLEAR_SELECT_LIST
} from '../actionTypes/orders';

import { setSuccessMessage, setInfoMessage, setErrorMessage } from './message';
import { LISTS_ADD_OR_UPDATE } from '../actionTypes/lists';
import { addOrdersError } from './errors';
import { api as apiHistoryOrder } from '../modules/orderHistory/api';
import { actions as actionsHistoryOrder } from '../modules/orderHistory/actions';
import { getCurrEmplId } from '../components/ChatWidget/getter';
import { isMessengerServiceText } from '../components/ChatWidget/useCases/utils';

export const clearOrder = () => ({ type: 'ORDER:CLEAR' });

export const cleanOrder = () => ({ type: 'ORDER:CLEAN' });

export const addOrderDraftItem = (
  id,
  count,
  cost,
  offers,
  discount,
  unit,
  stockId,
  vat
) => ({
  type: 'ORDERS:ORDER_DRAFT:ADDITEM',
  payload: { id, count, cost, discount, offers, unit, stockId, vat }
});

export const setHasChanges = (hasChanges) => ({
  type: 'ORDER:ORDER_DRAFT:SET_HAS_CHANGES',
  payload: { hasChanges }
});

export const setOrderId = (orderId = null) => ({
  type: 'ORDER:ORDER_DRAFT:SET_ORDER_ID',
  payload: { orderId }
});

export const toggleSelectItemOrderDraft = ({
  id,
  productId,
  cost,
  count,
  offers,
  discount,
  unit,
  stockId,
  vat
}) => ({
  type: 'ORDERS:ORDER_DRAFT:TOGGLE_SELECT',
  payload: { id, productId, cost, count, discount, offers, unit, stockId, vat }
});

export const setCountForSelectedItemsOrderDraft = (count) => ({
  type: 'ORDERS:ORDER_DRAFT:SET_COUNT_FOR_SELECTED',
  payload: { count }
});

export const clearSelectedItemsOrderDraft = () => ({
  type: 'ORDERS:ORDER_DRAFT:CLEAR_SELECTED_ITEMS'
});

export const updateCountOfSelectedOrderProducts =
  ({ orderId, priceId, stockId, count }) =>
  async (dispatch, getState) => {
    const selectedProducts = getState().getIn([
      'ordersCombine',
      'orderDraft',
      'selectedItems'
    ]);
    const publishedProductsIds = selectedProducts.map((selectedProduct) =>
      selectedProduct.get('id')
    );

    try {
      await fetch(`/api/orders/${orderId}/products/count`, {
        credentials: 'include',
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ count, stockId, priceId, publishedProductsIds })
      });
      dispatch(setCountForSelectedItemsOrderDraft(count));
      return Promise.resolve();
    } catch (e) {
      console.error(e);
      return Promise.reject(e);
    }
  };

export const getOrderHistory =
  ({ orderId, historyId }) =>
  async (dispatch) => {
    const orderHistory = await apiHistoryOrder.fetchOrderHistory({
      orderId,
      historyId
    });
    dispatch(actionsHistoryOrder.getOrderHistory(orderHistory));
  };

export const updateCountOfOrderProduct =
  (id, count, cost, offers, discount, unit, stockId, vat, orderId, priceId) =>
  async (dispatch) => {
    try {
      await fetch(`/api/orders/${orderId}/product/${id}/count`, {
        credentials: 'include',
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ id, count, stockId, priceId })
      });
      // const resp = await res.json();
      dispatch({
        type: 'ORDERS:ORDER_DRAFT:ADDITEM',
        payload: { id, count, cost, discount, offers, unit, stockId, vat }
      });
    } catch (e) {
      dispatch({ type: 'ORDERS:ORDER_DRAFT:ADDITEM:ERROR' });
    }
  };

export const addOrderDraftPriceShareItem = (item) => ({
  type: 'ORDERS:ORDER_DRAFT_PRICE_SHARE:ADDITEM',
  payload: item
});

export const clearOrderDraft = () => ({ type: 'ORDERS:ORDER_DRAFT:CLEAR' });

export const setCombineOrderStock = (stockId) => ({
  type: 'ORDER:SET_SPLIT_STOCK',
  payload: { stockId }
});

export const getOrder = (orderId) => (dispatch) => {
  dispatch({ type: 'ORDER:GET_ORDER:REQUEST' });

  return (
    fetch(`/api/orders/${orderId}`, {
      credentials: 'include',
      method: 'GET'
    })
      // eslint-disable-next-line prefer-promise-reject-errors
      .then((res) =>
        res.status < 400
          ? res.json()
          : Promise.reject({ errors: res.errors, status: res.status })
      )
      .then((result) => {
        dispatch({ type: 'ORDER:GET_ORDER:SUCCESS', payload: result });
        dispatch({
          type: 'ORDER:PRICE_CATEGORY:SELECT:LOAD',
          payload: { priceCategory: result.order.сategory }
        });
      })
      .catch(({ status, errors }) => {
        if (status === 403) {
          customHistory.push('/orders-company');
        }
        dispatch({
          type: 'ORDER:GET_ORDER:FAILURE',
          payload: { error: errors }
        });
      })
  );
};

export const getOrderProduct = (orderId, id) => (dispatch) => {
  dispatch({ type: 'ORDER:GET_ORDER_PRODUCT:REQUEST' });

  return fetch(`/api/orders/${orderId}/products/${id}`, {
    credentials: 'include',
    method: 'GET'
  })
    .then((res) => res.json())
    .then((product) => {
      dispatch({
        type: 'ORDER:GET_ORDER_PRODUCT:SUCCESS',
        payload: { product }
      });
    })
    .catch((res) => {
      dispatch({
        type: 'ORDER:GET_ORDER_PRODUCT:FAILURE',
        payload: { error: res.error }
      });
      console.error(res);
    });
};

export const exportOrderToExcel = (orderId) => (dispatch) => {
  dispatch({ type: 'ORDER:ORDER_EXCEL:REQUEST' });
  window.open(`/api/orders/${orderId}/excel`, '_self');
};

export const getOrderInfo =
  (orderId, offset = 0, limit = 20, orderBy = '', direction = '') =>
  (dispatch) => {
    dispatch({ type: 'ORDER:ORDER_INFO:REQUEST' });

    let url = `/api/orders/${orderId}/info?offset=${offset}&limit=${limit}`;
    if (orderBy && direction) {
      url = `/api/orders/${orderId}/info?offset=${offset}&limit=${limit}&orderBy=${orderBy}&direction=${direction}`;
    }
    return fetch(url, {
      credentials: 'include',
      method: 'GET'
    })
      .then((res) => res.json())
      .then(
        ({
          products: items,
          priceCategoryConditions,
          category,
          availiableStatuses,
          // eslint-disable-next-line no-shadow
          offset,
          size,
          // eslint-disable-next-line no-shadow
          limit,
          partsInfo,
          excluded
        }) => {
          dispatch({
            type: 'ORDER:ORDER_INFO:SUCCESS',
            payload: {
              items,
              priceCategoryConditions,
              category,
              availiableStatuses,
              size,
              offset,
              limit,
              partsInfo,
              excluded
            }
          });
        }
      )
      .catch((res) => {
        dispatch({
          type: 'ORDER:ORDER_INFO:FAILURE',
          payload: { error: res.error }
        });
        console.error(res);
      });
  };

export const getOrderStatusHistory = (orderId) => (dispatch) => {
  dispatch({ type: 'ORDER:ORDER_STATUS_HISTORY:REQUEST' });

  return fetch(`/api/orders/${orderId}/status_history`, {
    credentials: 'include',
    method: 'GET'
  })
    .then((res) => res.json())
    .then(({ statusHistory }) => {
      dispatch({
        type: 'ORDER:ORDER_STATUS_HISTORY:SUCCESS',
        payload: { statusHistory }
      });
    })
    .catch((res) => {
      dispatch({
        type: 'ORDER:ORDER_STATUS_HISTORY:FAILURE',
        payload: { error: res.error }
      });
      console.error(res);
    });
};

export const getOrderDraftInfo = (orderId) => (dispatch) => {
  dispatch({ type: 'ORDER:ORDER_DRAFT_INFO:REQUEST' });

  return fetch(`/api/orders/${orderId}/draft`, {
    credentials: 'include',
    method: 'GET'
  })
    .then((res) => res.json())
    .then(({ products, excluded, consignees }) => {
      dispatch({
        type: 'ORDER:ORDER_DRAFT_INFO:SUCCESS',
        payload: { products, excluded, consignees }
      });
    })
    .catch((res) => {
      dispatch({
        type: 'ORDER:ORDER_INFO:FAILURE',
        payload: { error: res.error }
      });
      console.error(res);
    });
};

export const selectItem = (item) => ({
  type: 'ORDERS:SELECT_ITEM',
  payload: { item }
});

export const selectOrderItem = (item) => ({
  type: 'ORDERS:SELECT_ORDER_ITEM',
  payload: { item }
});

export const selectOrderSplitItem = (item) => ({
  type: 'ORDERS:SELECT_ORDER_SPLIT_ITEM',
  payload: { item }
});

export const selectAdditionalItem = (id, stockId) => ({
  type: 'ORDERS:SELECT_ORDER_ADDITIONAL_ITEM',
  payload: { id, stockId }
});

export const selectOrderDraftSplitItem = (item) => ({
  type: 'ORDERS:SELECT_ORDER_DRAFT_SPLIT_ITEM',
  payload: { item }
});

export const selectOrderDraftSplitItemSharedPrice = (item) => ({
  type: 'ORDERS:SELECT_ORDER_DRAFT_SPLIT_ITEM_SHARED_PRICE',
  payload: { item }
});

const addUnsentSubmitCommentToLS = (employeeId, comment, orderId) => {
  const allUnsentComments = JSON.parse(
    localStorage.getItem('unsentOrdersSubmitComments')
  );
  const unsentComments = assocPath(
    [employeeId.toString(), orderId.toString()],
    comment,
    allUnsentComments || {}
  );
  return localStorage.setItem(
    'unsentOrdersSubmitComments',
    JSON.stringify(unsentComments)
  );
};

const removeUnsentSubmitCommentFromLS = (employeeId, orderId) => {
  const unsentComments = JSON.parse(
    localStorage.getItem('unsentOrdersSubmitComments')
  );
  if (!hasPath([employeeId, orderId], unsentComments)) return null;
  delete unsentComments[employeeId][orderId];
  return localStorage.setItem(
    'unsentOrdersSubmitComments',
    JSON.stringify(unsentComments)
  );
};

export const submitOrderToSupplier =
  (
    orderId,
    comment,
    splitOrder,
    combineOrdersStocks,
    deliveryDate,
    consignee
  ) =>
  (dispatch, getState) => {
    dispatch({ type: 'ORDER:SET_STATUS:REQUEST' });

    return fetch('/api/orders/set_status', {
      credentials: 'include',
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        orderId,
        status: 'sent',
        language: langBrowser(),
        comment,
        splitOrder,
        combineOrdersStocks,
        deliveryDate,
        consignee
      })
    })
      .then((res) => res.json())
      .then(() => {
        const currentEmployeeId = getCurrEmplId(getState());
        removeUnsentSubmitCommentFromLS(currentEmployeeId, orderId);
        dispatch({ type: 'ORDER:SET_STATUS:SUCCESS', payload: { orderId } });
      })
      .catch((res) => {
        const currentEmployeeId = getCurrEmplId(getState());
        addUnsentSubmitCommentToLS(currentEmployeeId, comment, orderId);
        dispatch(addOrdersError('order-has-not-send'));
        console.error(res);
      });
  };

export const updatePaidSum = (orderId, value) => (dispatch) => {
  dispatch({ type: 'ORDER:UPDATE_PAID:REQUEST' });

  return fetch('/api/orders/update_paid', {
    credentials: 'include',
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ orderId, value })
  })
    .then((res) => res.json())
    .then(() => {
      dispatch({
        type: 'ORDER:UPDATE_PAID:SUCCESS',
        payload: { orderId, value }
      });
    })
    .catch((res) => {
      dispatch({
        type: 'ORDER:UPDATE_PAID:FAILURE',
        payload: { error: res.error }
      });
      console.error(res);
    });
};

export const updateDeliveryDate = (orderId, date) => (dispatch) => {
  dispatch({ type: 'ORDER:UPDATE_DATE:REQUEST' });

  return fetch('/api/orders/update_deliverydate', {
    credentials: 'include',
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ orderId, date })
  })
    .then((res) => res.json())
    .then(() => {
      dispatch({
        type: 'ORDER:UPDATE_DATE:SUCCESS',
        payload: { orderId, date }
      });
    })
    .catch((res) => {
      dispatch({
        type: 'ORDER:UPDATE_DATE:FAILURE',
        payload: { error: res.error }
      });
      console.error(res);
    });
};

export const setResponsibleForCustomer = (orderId, userId) => (dispatch) => {
  dispatch({ type: 'ORDER:UPDATE_RESPONSIBLE_FOR_CUSTOMER:REQUEST' });

  return fetch('/api/orders/responsible_for_customer', {
    credentials: 'include',
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ orderId, userId })
  })
    .then((res) => res.json())
    .then(() => {
      dispatch({
        type: 'ORDER:UPDATE_RESPONSIBLE_FOR_CUSTOMER:SUCCESS',
        payload: { orderId, userId }
      });
    })
    .catch((res) => {
      dispatch({
        type: 'ORDER:UPDATE_RESPONSIBLE_FOR_CUSTOMER:FAILURE',
        payload: { error: res.error }
      });
      console.error(res);
    });
};

export const setResponsibleForSupplier = (orderId, userId) => (dispatch) => {
  dispatch({ type: 'ORDER:UPDATE_RESPONSIBLE_FOR_SUPPLIER:REQUEST' });

  return fetch('/api/orders/responsible_for_supplier', {
    credentials: 'include',
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ orderId, userId })
  })
    .then((res) => res.json())
    .then(() => {
      dispatch({
        type: 'ORDER:UPDATE_RESPONSIBLE_FOR_SUPPLIER:SUCCESS',
        payload: { orderId, userId }
      });
    })
    .catch((res) => {
      dispatch({
        type: 'ORDER:UPDATE_RESPONSIBLE_FOR_SUPPLIER:FAILURE',
        payload: { error: res.error }
      });
      console.error(res);
    });
};

export const saveDraft =
  (
    { priceId, orderId, items, categoryId },
    { showSuccessMessage = true } = {}
  ) =>
  async (dispatch) => {
    dispatch({ type: 'ORDER:CREATE_DRAFT:REQUEST' });

    try {
      const res = await fetch('/api/orders/save_draft', {
        credentials: 'include',
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ priceId, orderId, items, categoryId })
      });
      const resp = await res.json();
      if (res.status === 409) {
        customHistory.push('/prices-suppliers');
        return dispatch(setErrorMessage({ key: resp.msg }));
      }
      dispatch({
        type: 'ORDER:CREATE_DRAFT:SUCCESS',
        payload: { priceId, items, categoryId }
      });
      if (showSuccessMessage) {
        dispatch(setSuccessMessage({ key: 'order_draft_saved' }));
      }
      dispatch(setOrderId(resp.orderId));
      return Promise.resolve(resp.orderId);
    } catch (e) {
      console.error(e);
      dispatch(setErrorMessage({ key: 'Draft save failed' }));
      return Promise.reject(e);
    }
  };

export const receivedOrder =
  ({ orderId, supplier, customer, price }) =>
  (dispatch) => {
    dispatch({ type: 'ORDER:SET_STATUS:REQUEST' });

    return fetch('/api/orders/set_status', {
      credentials: 'include',
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        orderId,
        supplier,
        customer,
        price,
        status: 'received',
        language: langBrowser()
      })
    })
      .then((res) => res.json())
      .then(() => {
        dispatch({
          type: 'ORDER:SET_STATUS:SUCCESS',
          payload: { status: 'received' }
        });
      })
      .catch((res) => {
        dispatch({
          type: 'ORDER:SET_STATUS:FAILURE',
          payload: { error: res.error }
        });
        console.error(res);
      });
  };

export const cancelOrder =
  ({ orderId, supplier, customer, price }) =>
  (dispatch) => {
    dispatch({ type: 'ORDER:SET_STATUS:REQUEST' });

    return fetch('/api/orders/set_status', {
      credentials: 'include',
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        orderId,
        supplier,
        customer,
        status: 'canceled',
        price,
        language: langBrowser()
      })
    })
      .then((res) => res.json())
      .then(() => {
        dispatch({
          type: 'ORDER:SET_STATUS:SUCCESS',
          payload: { status: 'canceled' }
        });
      })
      .catch((res) => {
        dispatch({
          type: 'ORDER:SET_STATUS:FAILURE',
          payload: { error: res.error }
        });
        console.error(res);
      });
  };

export const rejectOrder =
  ({ orderId, supplier, customer, price }) =>
  (dispatch) => {
    dispatch({ type: 'ORDER:SET_STATUS:REQUEST' });

    return fetch('/api/orders/set_status', {
      credentials: 'include',
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        orderId,
        supplier,
        customer,
        status: 'rejected',
        price,
        language: langBrowser()
      })
    })
      .then((res) => res.json())
      .then(() => {
        dispatch({
          type: 'ORDER:SET_STATUS:SUCCESS',
          payload: { status: 'rejected' }
        });
      })
      .catch((res) => {
        dispatch({
          type: 'ORDER:SET_STATUS:FAILURE',
          payload: { error: res.error }
        });
        console.error(res);
      });
  };

export const confirmOrder =
  ({ orderId, supplier, customer, price }) =>
  (dispatch) => {
    dispatch({ type: 'ORDER:SET_STATUS:REQUEST' });

    return fetch('/api/orders/set_status', {
      credentials: 'include',
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        orderId,
        supplier,
        customer,
        price,
        status: 'confirmed',
        language: langBrowser()
      })
    })
      .then((res) => res.json())
      .then(() => {
        dispatch({
          type: 'ORDER:SET_STATUS:SUCCESS',
          payload: { status: 'confirmed' }
        });
      })
      .catch((res) => {
        dispatch({
          type: 'ORDER:SET_STATUS:FAILURE',
          payload: { error: res.error }
        });
        console.error(res);
      });
  };

export const editingOrder =
  ({ orderId, supplier, customer, price }) =>
  (dispatch) => {
    dispatch({ type: 'ORDER:SET_STATUS:REQUEST' });

    return fetch('/api/orders/set_status', {
      credentials: 'include',
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        orderId,
        supplier,
        customer,
        price,
        status: 'editing',
        language: langBrowser()
      })
    })
      .then((res) => res.json())
      .then(() => {
        dispatch({
          type: 'ORDER:SET_STATUS:SUCCESS',
          payload: { status: 'editing' }
        });
      })
      .catch((res) => {
        dispatch({
          type: 'ORDER:SET_STATUS:FAILURE',
          payload: { error: res.error }
        });
        console.error(res);
      });
  };

export const waitingAppovalOrder =
  ({ orderId, supplier, customer, price }) =>
  (dispatch) => {
    dispatch({ type: 'ORDER:SET_STATUS:REQUEST' });

    return fetch('/api/orders/set_status', {
      credentials: 'include',
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        orderId,
        supplier,
        customer,
        price,
        status: 'waiting_for_approval',
        language: langBrowser()
      })
    })
      .then((res) => res.json())
      .then(() => {
        dispatch({
          type: 'ORDER:SET_STATUS:SUCCESS',
          payload: { status: 'waiting_for_approval' }
        });
      })
      .catch((res) => {
        dispatch({
          type: 'ORDER:SET_STATUS:FAILURE',
          payload: { error: res.error }
        });
        console.error(res);
      });
  };

export const approveOrder =
  ({ orderId, supplier, customer, price }) =>
  (dispatch) => {
    dispatch({ type: 'ORDER:SET_STATUS:REQUEST' });

    return fetch('/api/orders/set_status', {
      credentials: 'include',
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        orderId,
        supplier,
        customer,
        price,
        status: 'approved',
        language: langBrowser()
      })
    })
      .then((res) => res.json())
      .then(() => {
        dispatch({
          type: 'ORDER:SET_STATUS:SUCCESS',
          payload: { status: 'approved' }
        });
      })
      .catch((res) => {
        dispatch({
          type: 'ORDER:SET_STATUS:FAILURE',
          payload: { error: res.error }
        });
        console.error(res);
      });
  };

export const shipOrder =
  ({ orderId, supplier, customer, price }) =>
  (dispatch) => {
    dispatch({ type: 'ORDER:SET_STATUS:REQUEST' });

    return fetch('/api/orders/set_status', {
      credentials: 'include',
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        orderId,
        supplier,
        customer,
        price,
        status: 'shipped',
        language: langBrowser()
      })
    })
      .then((res) => res.json())
      .then(() => {
        dispatch({
          type: 'ORDER:SET_STATUS:SUCCESS',
          payload: { status: 'shipped' }
        });
      })
      .catch((res) => {
        dispatch({
          type: 'ORDER:SET_STATUS:FAILURE',
          payload: { error: res.error }
        });
        console.error(res);
      });
  };

export const updateItemCount = (orderId, itemId, count) => (dispatch) => {
  dispatch({ type: 'ORDER:UPDATE_ITEM_COUNT:REQUEST' });

  return fetch(`/api/orders/${orderId}/count`, {
    credentials: 'include',
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ itemId, count })
  })
    .then((res) => res.json())
    .then(() => {
      dispatch({
        type: 'ORDER:UPDATE_ITEM_COUNT:SUCCESS',
        payload: { itemId, count }
      });
    })
    .catch((res) => {
      dispatch({
        type: 'ORDER:UPDATE_ITEM_COUNT:FAILURE',
        payload: { error: res.error }
      });
      console.error(res);
    });
};

export const updatePriceCategoryCondition =
  (categoryId) => (dispatch, getState) => {
    const orderId = getState().getIn(['orders', 'order', 'id']);

    return fetch(`/api/orders/${orderId}/price_category_conditions`, {
      credentials: 'include',
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ categoryId })
    })
      .then((res) => res.json())
      .then(({ priceCategoryConditions }) => {
        dispatch({
          type: 'ORDER:SET_PRICE_CATEGORY_CONDITION:SUCCESS',
          payload: { priceCategoryConditions }
        });
      })
      .catch((res) => {
        dispatch({
          type: 'ORDER:SET_PRICE_CATEGORY_CONDITION:FAILURE',
          payload: { error: res.error }
        });
        console.error(res);
      });
  };

export const updateOrderCategory = (orderId, categoryId) => (dispatch) => {
  dispatch({ type: 'ORDER:UPDATE_ORDER_CATEGORY:REQUEST' });

  return fetch(`/api/orders/${orderId}/category`, {
    credentials: 'include',
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ categoryId })
  })
    .then((res) => res.json())
    .then((category) => {
      dispatch({
        type: 'ORDER:UPDATE_ORDER_CATEGORY:SUCCESS',
        payload: { category }
      });
    })
    .catch((res) => {
      dispatch({
        type: 'ORDER:UPDATE_ORDER_CATEGORY:FAILURE',
        payload: { error: res.error }
      });
      console.error(res);
    });
};

export const deleteEmptyItems = (orderId) => (dispatch) => {
  dispatch({ type: 'ORDER:DELETE_EMPTY:REQUEST' });

  return fetch(`/api/orders/${orderId}/remove_empty`, {
    credentials: 'include',
    method: 'DELETE',
    headers: { 'Content-Type': 'application/json' }
  })
    .then((res) => res.json())
    .then(() => {
      dispatch({ type: 'ORDER:DELETE_EMPTY:SUCCESS', payload: { orderId } });
    })
    .catch((res) => {
      dispatch({
        type: 'ORDER:DELETE_EMPTY:FAILURE',
        payload: { error: res.error }
      });
      console.error(res);
    });
};

export const removeItem = (orderId, itemId, supplier, price) => (dispatch) => {
  dispatch({ type: 'ORDER:DELETE_ITEM:REQUEST' });

  return fetch(`/api/orders/${orderId}/remove_item`, {
    credentials: 'include',
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ itemId, supplier, price })
  })
    .then((res) => res.json())
    .then(() => {
      dispatch({
        type: 'ORDER:DELETE_ITEM:SUCCESS',
        payload: { itemId, count: 0 }
      });
    })
    .catch((res) => {
      dispatch({
        type: 'ORDER:DELETE_ITEM:FAILURE',
        payload: { error: res.error }
      });
      console.error(res);
    });
};

export const removeManyItems = (orderId, itemIds) => (dispatch) => {
  dispatch({ type: 'ORDER:REMOVE_ITEMS:REQUEST' });

  const items = itemIds.map((product) => {
    const [productId, stockId] = product.split('-');
    return { productId: +productId, stockId: +stockId || null };
  });

  return fetch(`/api/orders/${orderId}/remove_items`, {
    credentials: 'include',
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ items })
  })
    .then((res) => res.json())
    .then(() => {
      dispatch({ type: 'ORDER:REMOVE_ITEMS:SUCCESS', payload: { items } });
    })
    .catch((res) => {
      dispatch({
        type: 'ORDER:REMOVE_ITEMS:FAILURE',
        payload: { error: res.error }
      });
      console.error(res);
    });
};

export const addItem = (orderId, item, supplier, price) => (dispatch) => {
  dispatch({ type: 'ORDER:ADD_ITEM:REQUEST' });

  return fetch(`/api/orders/${orderId}/add_item`, {
    credentials: 'include',
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ item, supplier, price })
  })
    .then((res) => res.json())
    .then(() => {
      dispatch({ type: 'ORDER:ADD_ITEM:SUCCESS', payload: { item } });
    })
    .catch((res) => {
      dispatch({
        type: 'ORDER:ADD_ITEM:FAILURE',
        payload: { error: res.error }
      });
      console.error(res);
    });
};

export const getBaidgeStatuses = (type) => (dispatch) => {
  dispatch({ type: 'ORDER:GET_BAIDGE_STATUSES:REQUEST' });

  return fetch(`/api/orders/statuses/${type}`, { credentials: 'include' })
    .then((res) => res.json())
    .then(({ statuses }) =>
      dispatch({
        type: 'ORDER:GET_BAIDGE_STATUSES:SUCCESS',
        payload: { statuses }
      })
    )
    .catch(({ errors }) => {
      dispatch({
        type: 'ORDER:GET_BAIDGE_STATUSES:FAILURE',
        payload: { errors }
      });
      console.error(errors);
      return Promise.reject();
    });
};

export const setStatusViewed = (orderId, type) => (dispatch) => {
  dispatch({ type: 'ORDER:SET_BAIDGE_STATUS:REQUEST' });

  return fetch('/api/orders/baidge_status', {
    credentials: 'include',
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ order: orderId, type, status: 'viewed' })
  })
    .then((res) =>
      res.json().then((json) =>
        // eslint-disable-next-line prefer-promise-reject-errors
        res.status < 400
          ? Promise.resolve(json)
          : Promise.reject({ errors: json.errors, status: res.status })
      )
    )
    .then(() =>
      dispatch({
        type: 'ORDER:SET_BAIDGE_STATUS:SUCCESS',
        payload: { orderId }
      })
    )
    .catch(({ errors }) => {
      dispatch({
        type: 'ORDER:SET_BAIDGE_STATUS:FAILURE',
        payload: { errors }
      });
      console.error(errors);
      return Promise.reject();
    });
};

const makeUnsentComment = ({ orderId, uniqId, senderData, text }) => {
  const { id, currentCompany, firstName, lastName } = senderData;

  return {
    id: uniqId,
    deliveryId: uniqId,
    text,
    language: langBrowser(),
    userId: id,
    orderId,
    companyId: currentCompany,
    'userInfo.firstName': firstName,
    'userInfo.lastName': lastName,
    'userInfo.deletedAt': null,
    createdAt: new Date(),
    updatedAt: new Date()
  };
};

const removeUnsentOrderCommentAC = (employeeId, orderId, deliveryId) => ({
  type: 'ORDER:REMOVE_UNSENT_COMMENT',
  payload: { employeeId, orderId, deliveryId }
});

const addUnsentComment = (employeeId, unsentComment) => ({
  type: 'ORDER:ADD_UNSENT_COMMENT',
  payload: { employeeId, comment: unsentComment }
});

export const removeUnsentOrderComment =
  (orderId, deliveryId) => (dispatch, getState) => {
    const currentEmployeeId = getCurrEmplId(getState());
    dispatch(
      removeUnsentOrderCommentAC(currentEmployeeId, orderId, deliveryId)
    );
    dispatch(writeUnsentOrderCommentsToLS());
  };

const setNotificationErrorInternetConnection = () => (dispatch) => {
  const onCloseCallback = () =>
    dispatch(
      setInfoMessage(
        {
          key: `${isMessengerServiceText()} is trying to connect to the Internet`
        },
        false
      )
    );
  dispatch(
    setErrorMessage({ key: 'no-internet-comment-not-sent', onCloseCallback })
  );
};

export const addOrderComment =
  (orderId, text, deliveryId) => (dispatch, getState) => {
    dispatch({ type: 'ORDER:ADD_COMMENT:REQUEST' });

    const uniqId = deliveryId || nanoid();

    return fetch(`/api/orders/${orderId}/comment`, {
      credentials: 'include',
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ text, language: langBrowser() })
    })
      .then((res) =>
        res.json().then((json) =>
          // eslint-disable-next-line prefer-promise-reject-errors
          res.status < 400
            ? Promise.resolve(json)
            : Promise.reject({ errors: json.errors, status: res.status })
        )
      )
      .then(({ comment }) => {
        dispatch(removeUnsentOrderComment(orderId, uniqId));
        dispatch({
          type: 'ORDER:ADD_COMMENT:SUCCESS',
          payload: { comment }
        });
      })
      .catch(({ errors }) => {
        const state = getState();
        const currentEmployeeId = getCurrEmplId(state);
        const haveInternetConnections = state.getIn([
          'connections',
          'internet'
        ]);
        const { id, currentCompany, firstName, lastName } = state
          .getIn(['user', 'user'])
          .toJS();
        const senderData = { id, currentCompany, firstName, lastName };

        const unsentComment = makeUnsentComment({
          orderId,
          uniqId,
          senderData,
          text
        });

        if (!haveInternetConnections) {
          dispatch(setNotificationErrorInternetConnection());
        }

        dispatch(addUnsentComment(currentEmployeeId, unsentComment));
        dispatch(writeUnsentOrderCommentsToLS());

        dispatch({ type: 'ORDER:ADD_COMMENT:FAILURE', payload: { errors } });
        console.error(errors);
        return Promise.reject();
      });
  };

export const deleteAttache = (orderId, name, type, url) => (dispatch) => {
  dispatch({ type: 'ORDER:DELETE_ATTACHE:REQUEST' });

  return fetch(`/api/orders/${orderId}/delete_attache`, {
    credentials: 'include',
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ name, type, url })
  })
    .then((res) => res.json())
    .then((json) => {
      dispatch({ type: 'ORDER:DELETE_ATTACHE:SUCCESS', payload: json });
      return Promise.resolve();
    })
    .catch((res) => {
      dispatch({
        type: 'ORDER:DELETE_ATTACHE:FAILURE',
        payload: { error: res.error }
      });
      console.error(res);
    });
};

export const setAttacheStatus = (orderId, attache) => (dispatch) => {
  dispatch({ type: 'ORDER:ATTACHE_SET_STATUS:REQUEST' });

  return fetch(`/api/orders/${orderId}/attache_status`, {
    credentials: 'include',
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      orderId,
      url: attache.get('url'),
      status: 'viewed'
    })
  })
    .then((res) => res.json())
    .then((json) => {
      dispatch({ type: 'ORDER:ATTACHE_SET_STATUS:SUCCESS', payload: json });
      return Promise.resolve();
    })
    .catch((res) => {
      dispatch({
        type: 'ORDER:ATTACHE_SET_STATUS:FAILURE',
        payload: { error: res.error }
      });
      console.error(res);
    });
};

export const attacheFile = (orderId, body) => (dispatch) => {
  dispatch({ type: 'ORDER:FILE_ATTACHE:REQUEST', payload: { orderId } });
  dispatch(setInfoMessage({ key: 'File upload started' }));
  return fetch(
    `/api/orders/${orderId}/attache/${body.name}?name=${body.name}&type=${body.type}&size=${body.size}`,
    {
      credentials: 'include',
      method: 'POST',
      body
    }
  )
    .then((res) =>
      res.json().then((json) =>
        res.status < 400
          ? Promise.resolve(json)
          : // eslint-disable-next-line prefer-promise-reject-errors
            Promise.reject({
              errors: json.errors.map((error) => error.message) || [],
              status: res.status
            })
      )
    )
    .then((response) => {
      dispatch({
        type: 'ORDER:FILE_ATTACHE:SUCCESS',
        payload: response
      });
      dispatch(setSuccessMessage({ key: 'File upload finished' }));
    })
    .catch(({ errors = [] }) => {
      dispatch({
        type: 'ORDER:FILE_ATTACHE:FAILURE',
        payload: { orderId, errors }
      });
      dispatch(
        setErrorMessage({ key: errors[0] || 'Error while uploading files' })
      );
    });
};

export const getAttaches =
  (orderId, offset = 0, limit = 20) =>
  (dispatch) => {
    const haveQuery = offset !== undefined && limit !== undefined;

    const url = haveQuery
      ? `/api/orders/${orderId}/attaches?offset=${offset}&limit=${limit}`
      : `/api/orders/${orderId}/attaches`;

    dispatch({ type: 'ORDER:GET_ATTACHES:REQUEST', payload: { orderId } });
    return fetch(url, {
      credentials: 'include',
      method: 'GET'
    })
      .then((res) =>
        res
          .json()
          .then((json) =>
            res.status < 400 ? Promise.resolve(json) : Promise.reject(json)
          )
      )
      .then(({ attaches, badges, size }) =>
        dispatch({
          type: 'ORDER:GET_ATTACHES:SUCCESS',
          payload: { attaches, badges, size, offset, limit }
        })
      )
      .catch((error) =>
        dispatch({ type: 'ORDER:GET_ATTACHES:FAILURE', payload: { error } })
      );
  };

export const copyProducts =
  (items, language, companyName, selectedPrice, orderId) => (dispatch) => {
    dispatch({ type: 'ORDER:COPY_PRODUCT:REQUEST' });

    const products = items.map((product) => {
      const [productId] = product.split('-');
      return +productId;
    });

    return fetch('/api/orders/copy_products', {
      credentials: 'include',
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        products,
        language,
        companyName,
        selectedPrice,
        orderId
      })
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.error) {
          return dispatch(setErrorMessage({ key: data.error.text }));
        }
        dispatch({
          type: 'ORDER:COPY_PRODUCT:SUCCESS',
          payload: { products, language }
        });
        if (data.products.length > 0) {
          return dispatch(showModal('CREATE_OR_REPLACE', data));
        }
        dispatch(
          setSuccessMessage({
            key: 'order_product_success_copy',
            linkKey: 'order_product_copy_link',
            linkUrl: `/catalogs/${data.catalogId}/products`
          })
        );
        return hideModal();
      })
      .catch((res) => {
        dispatch({
          type: 'ORDER:COPY_PRODUCT:FAILURE',
          payload: { error: res.error }
        });
        console.error(res);
      });
  };

export const createOrReplace =
  (replace, products, catalogId, categoryId, supplierCompanyId) =>
  (dispatch) => {
    dispatch({ type: 'ORDER:CREATE_OR_REPLACE_PRODUCT:REQUEST' });

    return fetch('/api/orders/copy_products/create_or_replace', {
      credentials: 'include',
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        replace,
        products,
        catalogId,
        categoryId,
        supplierCompanyId
      })
    })
      .then((res) => res.json())
      .then(() => {
        dispatch({
          type: 'ORDER:CREATE_OR_REPLACE_PRODUCT:SUCCESS',
          payload: { replace, products, catalogId, categoryId }
        });
        dispatch(
          setSuccessMessage({
            key: 'order_product_success_copy',
            linkKey: 'order_product_copy_link',
            linkUrl: `/catalogs/${catalogId}/products`
          })
        );
      })
      .catch((res) => {
        dispatch({
          type: 'ORDER:CREATE_OR_REPLACE_PRODUCT:FAILURE',
          payload: { error: res.error }
        });
        console.error(res);
      });
  };

export const moveOrderProductNumber =
  (productsIdNumber, toOrder, orderId) => (dispatch) => {
    dispatch({ type: 'ORDER:MOVE_PRODUCT_ORDER_NUMBER:REQUEST' });

    const products = productsIdNumber.map((product) => {
      const [productId, orderNumber] = product.split('-');
      return { productId, orderNumber };
    });

    return fetch(`/api/orders/${orderId}/products/move_products`, {
      credentials: 'include',
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ products, toOrder, orderId })
    })
      .then((res) => res.json())
      .then(() => {
        dispatch({ type: 'ORDER:MOVE_PRODUCT_ORDER_NUMBER:SUCCESS' });
        dispatch(setSuccessMessage({ key: 'order_product_success_move' }));
        dispatch({
          type: 'ORDER:SET_STATUS:SUCCESS',
          payload: { status: 'editing' }
        });
        dispatch({ type: 'ORDER:CLEAR_SELECTED' });
      })
      .catch((res) => {
        dispatch({
          type: 'ORDER:MOVE_PRODUCT_ORDER_NUMBER:FAILURE',
          payload: { error: res.error }
        });
        console.error(res);
      });
  };

export const copyOrderProductNumber =
  (productsIdNumber, toOrder, orderId) => (dispatch) => {
    dispatch({ type: 'ORDER:COPY_PRODUCT_ORDER_NUMBER:REQUEST' });

    const products = productsIdNumber.map((product) => {
      const [productId, orderNumber] = product.split('-');
      return { productId, orderNumber: +orderNumber };
    });

    return fetch(`/api/orders/${orderId}/products/copy_products`, {
      credentials: 'include',
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ products, toOrder, orderId })
    })
      .then((res) => res.json())
      .then((res) => {
        dispatch({ type: 'ORDER:COPY_PRODUCT_ORDER_NUMBER:SUCCESS' });
        if (res.alreadyExist) {
          dispatch(
            setSuccessMessage({ key: 'order_split_product_already_exist_copy' })
          );
        } else {
          dispatch(
            setSuccessMessage({ key: 'order_split_product_success_copy' })
          );
        }
        dispatch({
          type: 'ORDER:SET_STATUS:SUCCESS',
          payload: { status: 'editing' }
        });
        dispatch({ type: 'ORDER:CLEAR_SELECTED' });
      })
      .catch((res) => {
        dispatch({
          type: 'ORDER:COPY_PRODUCT_ORDER_NUMBER:FAILURE',
          payload: { error: res.error }
        });
        console.error(res);
      });
  };

export const changeOrderProductCount =
  (count, productId, orderNumber, orderId) => (dispatch) => {
    dispatch({ type: 'ORDER:CHANGE_ORDER_PRODUCT_COUNT:REQUEST' });

    // mark
    return fetch(`/api/orders/${orderId}/change_count`, {
      credentials: 'include',
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ count, productId, orderNumber, orderId })
    })
      .then((res) => res.json())
      .then(({ id }) => {
        dispatch({
          type: 'ORDER:CHANGE_ORDER_PRODUCT_COUNT:SUCCESS',
          payload: {
            count,
            productId,
            orderNumber,
            orderId,
            id
          }
        });
        dispatch(getOrder(orderId));
        dispatch({
          type: 'ORDER:SET_STATUS:SUCCESS',
          payload: { status: 'editing' }
        });
      })
      .catch((res) => {
        dispatch({
          type: 'ORDER:CHANGE_ORDER_PRODUCT_COUNT:FAILURE',
          payload: { error: res.error }
        });
        console.error(res);
      });
  };

export const setSubOrderDate =
  (orderId, date, orderNumber) => async (dispatch) => {
    try {
      console.log(orderId, date, orderNumber);
      await dispatch({ type: 'ORDER:SET_ORDER_SPLIT_DATE:REQUEST' });
      const res = await fetch(`/api/orders/${orderId}/change_sub_date`, {
        credentials: 'include',
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ orderId, date, orderNumber })
      });
      await res.json();
      await dispatch({
        type: 'ORDER:SET_ORDER_SPLIT_DATE:SUCCESS',
        payload: { orderId, date, orderNumber }
      });
      // await dispatch({ type: 'ORDER:SET_STATUS:SUCCESS', payload: { status: 'editing' } });
    } catch (error) {
      await dispatch({
        type: 'ORDER:SET_ORDER_SPLIT_DATE:FAILURE',
        payload: { error }
      });
    }
  };

export const removeSelected =
  (selected, orderId, additionalSelected) => (dispatch) => {
    const products = selected.map((product) => {
      const [productId, orderNumber] = product.split('-');
      return { productId, orderNumber };
    });
    const additionalProducts = additionalSelected.map((product) => {
      if (typeof product === 'string') {
        const [productId, stockId] = product.split('-');
        return { productId, stockId };
      }
      return { productId: product };
    });
    return fetch(`/api/orders/${orderId}/remove_order_product`, {
      credentials: 'include',
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ products, additionalProducts })
    })
      .then((res) => res.json())
      .then(() => {
        dispatch({
          type: 'ORDER:DELETE_ORDER_PRODUCT:SUCCESS',
          payload: { orderId, products }
        });
        dispatch({ type: 'ORDER:CLEAR_SELECTED' });
        dispatch({
          type: 'ORDER:SET_STATUS:SUCCESS',
          payload: { status: 'editing' }
        });
      })
      .catch((res) => {
        dispatch({
          type: 'ORDER:DELETE_ORDER_PRODUCT:FAILURE',
          payload: { error: res.error }
        });
        console.error(res);
      });
  };

export const clearSelected = () => (dispatch) =>
  dispatch({ type: 'ORDER:CLEAR_SELECTED' });

export const getPublishedPriceProductsForOrder =
  (
    orderId,
    search,
    offset = 0,
    lim = 20,
    orderBy = '',
    direction = '',
    params
  ) =>
  (dispatch, getState) => {
    let categoriesId = getState()
      .getIn(['publicPrices', 'categories'])
      .filter((category) => category.get('selected'))
      .map((category) => category.get('id'));
    const limit =
      lim || getState().getIn(['publicPrices', 'productsList', 'limit']);
    categoriesId =
      categoriesId && categoriesId.size > 0 ? categoriesId.toArray() : [];

    let url = `/api/orders/${orderId}/products/search`;
    if (orderBy && direction) {
      url = `/api/orders/${orderId}/products/search?orderBy=${orderBy}&direction=${direction}`;
    }

    return fetch(url, {
      credentials: 'include',
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        categoriesId,
        search,
        offset,
        limit,
        stockId: params && params.stockId
      })
    })
      .then((res) => res.json())
      .then(({ products, totalSize, currency }) => {
        dispatch({
          type: 'PUBLIC_PRICE:GET_PRODUCTS:SUCCESS',
          payload: { products, offset, limit, search, totalSize, currency }
        });
      })
      .catch((res) => {
        dispatch({
          type: 'PUBLIC_PRICE:GET_PRODUCTS:FAILURE',
          payload: { error: res.error }
        });
        console.error(res);
      });
  };

export const addAdditionalProducts = (orderId, items) => (dispatch) => {
  dispatch({ type: 'ORDER:ADD_ADDITIONAL_PRODUCTS:REQUEST' });
  return fetch(`/api/orders/${orderId}/additional_products`, {
    credentials: 'include',
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ items })
  })
    .then((res) => res.json())
    .then(() => {
      dispatch({
        type: 'ORDER:ADD_ADDITIONAL_PRODUCTS:SUCCESS',
        payload: { items }
      });
    })
    .catch((res) => {
      dispatch({
        type: 'ORDER:SET_ORDER_SPLIT_DATE:FAILURE',
        payload: { error: res.error }
      });
      console.error(res);
    });
};

export const setOrderAdditionalProductsDate =
  (orderId, date, stockId = '') =>
  (dispatch) => {
    dispatch({ type: 'ORDER:SET_ORDER_ADDITIONAL_DATE:REQUEST' });
    return fetch(`/api/orders/${orderId}/change_additional_date`, {
      credentials: 'include',
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ date, stockId })
    })
      .then((res) => res.json())
      .then(() => {
        dispatch({
          type: 'ORDER:SET_ORDER_ADDITIONAL_DATE:SUCCESS',
          payload: { orderId, date, stockId }
        });
        // dispatch({ type: 'ORDER:SET_STATUS:SUCCESS', payload: { status: 'editing' } });
      })
      .catch((res) => {
        dispatch({
          type: 'ORDER:SET_ORDER_ADDITIONAL_DATE:FAILURE',
          payload: { error: res.error }
        });
        console.error(res);
      });
  };

export const changeOrderAdditionalProductCount =
  (count, productId, stockId, orderId) => (dispatch) => {
    dispatch({ type: 'ORDER:CHANGE_ORDER_ADDITIONAL_PRODUCT_COUNT:REQUEST' });

    // mark
    return fetch(`/api/orders/${orderId}/change_additional_count`, {
      credentials: 'include',
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ count, productId, stockId, orderId })
    })
      .then((res) => res.json())
      .then(() => {
        dispatch({
          type: 'ORDER:CHANGE_ORDER_ADDITIONAL_PRODUCT_COUNT:SUCCESS',
          payload: { count, productId, stockId, orderId }
        });
        dispatch(getOrder(orderId));
        dispatch({
          type: 'ORDER:SET_STATUS:SUCCESS',
          payload: { status: 'editing' }
        });
      })
      .catch((res) => {
        dispatch({
          type: 'ORDER:CHANGE_ORDER_PRODUCT_COUNT:FAILURE',
          payload: { error: res.error }
        });
        console.error(res);
      });
  };

export const setTableAttrs = (exclude, table) => (dispatch) => {
  dispatch({ type: 'ORDERS:SET_ORDERS_ATTRS:REQUEST' });
  return fetch(`/api/orders/attrs?table=${table}`, {
    credentials: 'include',
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ exclude })
  })
    .then((res) => res.json())
    .then(() =>
      dispatch({
        type: 'ORDERS:SET_ORDERS_ATTRS:SUCCESS',
        payload: { excluded: exclude }
      })
    )
    .catch((res) => {
      console.log(res);
      dispatch({
        type: 'ORDERS:SET_ORDERS_ATTRS:FAILURE',
        payload: { error: res.error }
      });
    });
};

export const setTableLinkPriceAttrs = (exclude) => (dispatch) => {
  dispatch({
    type: 'ORDERS:SET_ORDERS_ATTRS:SUCCESS',
    payload: { excluded: exclude }
  });
};

export const setTableAttrsOrderDetails = (exclude, table) => (dispatch) => {
  dispatch({ type: 'ORDERS:SET_ORDERS_DETAILS_ATTRS:REQUEST' });
  return fetch(`/api/orders/attrs?table=${table}`, {
    credentials: 'include',
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ exclude })
  })
    .then((res) => res.json())
    .then(() =>
      dispatch({
        type: 'ORDERS:SET_ORDERS_DETAILS_ATTRS:SUCCESS',
        payload: { excluded: exclude }
      })
    )
    .catch((res) => {
      console.log(res);
      dispatch({
        type: 'ORDERS:SET_ORDERS_DETAILS_ATTRS:FAILURE',
        payload: { error: res.error }
      });
    });
};

const getBody = (state) => ({
  direction: state.getIn(['ordersCombine', 'tableOrdersCompany', 'direction']),
  ids: state.getIn([
    'ordersCombine',
    'tableOrdersCompany',
    'isEnabledFilterSelected'
  ])
    ? state.getIn(['ordersCombine', 'tableOrdersCompany', 'selected']).toArray()
    : [],
  orderBy: state.getIn(['ordersCombine', 'tableOrdersCompany', 'orderBy']),
  limit: state.getIn(['ordersCombine', 'tableOrdersCompany', 'limit']),
  offset: state.getIn(['ordersCombine', 'tableOrdersCompany', 'offset']),
  search: state.getIn(['ordersCombine', 'tableOrdersCompany', 'search']),
  filters: state.getIn(['ordersCombine', 'tableOrdersCompany', 'filters'])
});

export const getOrdersToSuppliers = (payload) => (dispatch, getState) => {
  const data = getBody(getState());
  const bodyRequiest = merge(data, payload);
  dispatch(actionCreator(ORDERS_TO_SUPPLIERS_GET, bodyRequiest));

  return ordersStorage.getOrdersToSuppliers(bodyRequiest).then((bodyResponse) =>
    dispatch(
      actionCreator(ORDERS_TO_SUPPLIERS_SET, {
        ...bodyRequiest,
        ...bodyResponse
      })
    )
  );
};

const getData = (state) => ({
  userId: state.getIn(['user', 'user', 'id']),
  companyId: state.getIn(['company', 'id'])
});

export const removeOrdersToSuppliers =
  ({ ids }) =>
  async (dispatch, getState) => {
    const { companyId, userId } = getData(getState());

    ordersStorage
      .removeOrdersToSuppliers({ ids })
      .then(() => {
        dispatch(actionCreator(ORDERS_TO_SUPPLIERS_UNSELECT, { ids }));
        deleteOrderBadges({ ordersId: ids, companyId, userId });
        getOrdersToSuppliers({ offset: 0 })(dispatch, getState);
      })
      .catch(() => {
        dispatch(setErrorMessage({ key: 'cant_delete_order' }));
      });
  };

export const copyOrderProduct =
  ({ id: orderId }) =>
  async (dispatch, getState) => {
    try {
      await ordersStorage.copyOrderToSupplier(orderId);
    } catch (e) {
      return dispatch(setErrorMessage({ key: e.msg }));
    }
    dispatch(actionCreator(ORDERS_TO_SUPPLIERS_UNSELECT, { ids: [orderId] }));
    return getOrdersToSuppliers()(dispatch, getState);
  };

export const userViewedOrder =
  ({ orderId }) =>
  async (dispatch, getState) => {
    const userId = getState().getIn(['user', 'user', 'id']);
    const args = { orderId, userId };

    dispatch(actionCreator(ORDERS_TO_SUPPLIERS_CUSTOMER_VIEWED_ORDER, args));

    await orderCustomerViewed(args);
  };

export const getUnits = (orderId) => (dispatch) =>
  fetch(`/api/orders/${orderId}/units`, {
    credentials: 'include',
    method: 'GET'
  })
    .then((res) => res.json())
    .then((units) => {
      dispatch({ type: 'PRICE:GET_UNITS:SUCCESS', payload: { units } });
    })
    .catch((res) => {
      dispatch({
        type: 'PRICE:GET_UNITS:FAILURE',
        payload: { error: res.error }
      });
      console.error(res);
    });

export const updateOrderCategoryCondition = (categoryId) => ({
  type: 'ORDERS:ADD_CATEGORY_CONDITION',
  payload: { categoryId }
});

export const toggleFilterSelectOrdersTOSuppliers = () => ({
  type: ORDERS_TO_SUPPLIERS_FILTER_SELECT_TOGGLE
});

export const getOrderImportData = () => (dispatch) =>
  fetch('/api/orders/import', {
    credentials: 'include',
    method: 'GET'
  })
    .then((res) => res.json())
    .then(({ prices, customers }) => {
      dispatch({
        type: 'PRICES_SUPPLIERS:GET_PRICES:SUCCESS',
        payload: { prices }
      });
      dispatch({
        type: 'CUSTOMERS:GET_CUSTOMERS:SUCCESS',
        payload: { customers }
      });
    })
    .catch((err) => console.error(err));

export const changeOrderProductVat =
  (vat, productId, orderNumber, orderId) => (dispatch) =>
    fetch(`/api/orders/${orderId}/change_vat`, {
      credentials: 'include',
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        vat: Number(vat),
        productId,
        orderNumber,
        orderId
      })
    })
      .then((res) => res.json())
      .then(({ id }) => {
        dispatch({
          type: 'ORDER:CHANGE_ORDER_PRODUCT_VAT:SUCCESS',
          payload: { vat, productId, orderNumber, orderId, id }
        });
        dispatch(getOrder(orderId));
        dispatch({
          type: 'ORDER:SET_STATUS:SUCCESS',
          payload: { status: 'editing' }
        });
      })
      .catch((err) => console.error(err));

export const changeOrderAdditionalProductVat =
  (vat, productId, stockId, orderId) => (dispatch) =>
    fetch(`/api/orders/${orderId}/change_additional_vat`, {
      credentials: 'include',
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ vat, productId, stockId, orderId })
    })
      .then((res) => res.json())
      .then(() => {
        dispatch({
          type: 'ORDER:CHANGE_ORDER_ADDITIONAL_PRODUCT_VAT:SUCCESS',
          // eslint-disable-next-line no-undef
          payload: { productId, stockId, orderId }
        });
        dispatch(getOrder(orderId));
        dispatch({
          type: 'ORDER:SET_STATUS:SUCCESS',
          payload: { status: 'editing' }
        });
      })
      .catch((err) => console.error(err));

export const changeOrderProductCost =
  (productId, cost, orderNumber, orderId) => (dispatch) =>
    fetch(`/api/orders/${orderId}/change_cost`, {
      credentials: 'include',
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ cost, productId, orderNumber, orderId })
    })
      .then((res) => res.json())
      .then(({ id }) => {
        dispatch({
          type: 'ORDER:CHANGE_ORDER_PRODUCT_COST:SUCCESS',
          payload: { cost, productId, orderNumber, orderId, id }
        });
        dispatch(getOrder(orderId));
        dispatch({
          type: 'ORDER:SET_STATUS:SUCCESS',
          payload: { status: 'editing' }
        });
      })
      .catch((err) => console.error(err));

export const changeOrderAdditionalProductCost =
  (productId, cost, stockId, orderId) => (dispatch) =>
    fetch(`/api/orders/${orderId}/change_additional_cost`, {
      credentials: 'include',
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ cost, productId, stockId, orderId })
    })
      .then((res) => res.json())
      .then(() => {
        dispatch({
          type: 'ORDER:CHANGE_ORDER_ADDITIONAL_PRODUCT_COST:SUCCESS',
          payload: { cost, productId, stockId, orderId }
        });
        dispatch(getOrder(orderId));
        dispatch({
          type: 'ORDER:SET_STATUS:SUCCESS',
          payload: { status: 'editing' }
        });
      })
      .catch((err) => console.error(err));

export const set = (payload) => ({ type: ORDERS_ADD, payload });
export const toggleAll = () => ({ type: ORDERS_TOGGLE_ALL, payload: {} });
export const toggleOne = (id) => ({ type: ORDERS_TOGGLE_ONE, payload: { id } });
export const clear = () => ({ type: ORDERS_CLEAR, payload: {} });
export const clearSelect = () => ({
  type: ORDERS_CLEAR_SELECT_LIST,
  payload: {}
});

export const addOrUpdateOrder = (payload) => ({
  type: LISTS_ADD_OR_UPDATE,
  name: 'myOrders',
  payload
});

export const updateCustomersOrders = (payload) => (dispatch) =>
  dispatch({ type: 'ORDERS:UPDATE_CUSTOMERS_DATA', payload });
export const updateSuppliersOrders = (payload) => (dispatch) =>
  dispatch({ type: 'ORDERS:UPDATE_SUPPLIERS_DATA', payload });
