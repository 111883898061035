import React, { Fragment } from 'react';
import Helmet from 'react-helmet';
import LanguageSwitcher from '../LanguageSwitcher';
import Logo from '../MessengerLogo';
import { PanelThemeDefault } from '../Panel';
import BreadcrumbsDefault from '../Breadcrumbs/BreadcrumbsDefault';
import BreadcrumbsButtons from '../BreadcrumbsButtons';
import ModalRoot from '../ModalRoot';
import Notification from '../Message';
import './MessengerOther.css';
import '../Customers/customers.styl';

const HeaderSeparator = () => (
  <div className="layout-simple__header-separator" />
);

const LayoutSimple = ({
  theme,
  title = 'Goodwix',
  breadcrumbs = [],
  panelBtns = [],
  sectionContent = null,
  sidebar = null,
  extraBtn
}) => (
  <>
    <Helmet title={title} />
    <div className="layout-simple">
      <div className="layout-simple__header">
        <div className="layout-simple__header-column-left">
          <div className="layout-simple__header-logo-section">
            <Logo />
          </div>
        </div>
        <div className="layout-simple__header-column-right">
          <LanguageSwitcher />
          <HeaderSeparator />
          {extraBtn}
        </div>
      </div>
      <div className="layout-simple__content">
        <div className="layout-simple__main-content">
          <div className={`layout-simple__panel layout-simple__panel_${theme}`}>
            <PanelThemeDefault
              theme={theme}
              left={<BreadcrumbsDefault items={breadcrumbs} theme={theme} />}
              right={<BreadcrumbsButtons items={panelBtns} theme={theme} />}
            />
          </div>
          <div className="layout-simple__section-content">
            <div
              className={`layout-simple__inner-content layout-simple__inner-content_${theme}`}>
              {sectionContent}
            </div>
            {sidebar && (
              <aside className="layout-simple__sidebar">{sidebar}</aside>
            )}
          </div>
        </div>
      </div>
    </div>
    <Notification />
    <ModalRoot />
  </>
);

const MessengerShare = ({
  theme = 'gray',
  title = 'Goodwix',
  breadcrumbs,
  panelBtns,
  content,
  sidebar
}) => (
  <LayoutSimple
    theme={theme}
    title={title}
    breadcrumbs={breadcrumbs}
    panelBtns={panelBtns}
    sectionContent={content}
    sidebar={sidebar}
  />
);

export default MessengerShare;
