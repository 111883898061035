import { format } from 'date-fns';
import { getFiles, removeFile, downloadFile } from './files';
import { makeBtnSmallIcon } from './btnSmall';
import { formatFileSize } from '../../../utils';
import { showFilesViewer } from '../../../utils/filesViewerInterfaceCreator';

/*
 * Data type for render links with buttons
 */

const iconsList = {
  delete: '/img/purchase-requests/trash.svg',
  preview: '/img/purchase-requests/preview.svg',
  download: '/img/purchase-requests/download.svg'
};

export const getIconSrc = (name) => {
  if (iconsList[name]) return iconsList[name];
  throw new Error(`Not icon for name "${name}`);
};

export const makeFileVM = ({ key, name, size, createdAt, buttons = [] }) => ({
  key,
  name,
  size: formatFileSize(size),
  createdAt: format(new Date(createdAt), 'dd.MM.yyyy'),
  buttons
});

export const buttonForFile = ({
  showEditButtons,
  hookFiles,
  file,
  removeFileLinkBySection,
  files,
  showModal
}) => {
  const btnDownload = makeBtnSmallIcon({
    name: 'download',
    icon: 'download-1',
    iconSrc: getIconSrc('download'),
    onClick: () => downloadFile(file, hookFiles)
  });

  if (!showEditButtons) {
    return [
      btnDownload,
      makeBtnSmallIcon({
        name: 'preview',
        icon: 'view',
        iconSrc: getIconSrc('preview'),
        onClick: () => showFilesViewer({ file, files, showModal })
      })
    ];
  }

  return [
    btnDownload,
    makeBtnSmallIcon({
      name: 'delete',
      icon: 'remove-2',
      iconSrc: getIconSrc('delete'),
      onClick: () => {
        removeFile(file.id, hookFiles);
        removeFileLinkBySection(file.id, 'uploads');
      }
    })
  ];
};

export const convertFilesToVM = ({
  showEditButtons,
  hookFiles,
  removeFileLinkBySection,
  showModal
}) =>
  getFiles(hookFiles).map((file) =>
    makeFileVM({
      key: file.id,
      name: file.name,
      size: file.size,
      createdAt: file.createdAt,
      buttons: buttonForFile({
        showEditButtons,
        hookFiles,
        file,
        removeFileLinkBySection,
        files: getFiles(hookFiles),
        showModal
      })
    })
  );
