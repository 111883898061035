import request from '../lib/request';
import { doNotUseGetOrderUnsentComments } from '../storeGetters/index';
import { saveToLSByState } from './lib/LS';

export const writeUnsentOrderCommentsToLS = saveToLSByState(
  'unsentOrdersComments',
  doNotUseGetOrderUnsentComments
);

export default {
  getOrdersToSuppliers: ({
    ids,
    offset,
    limit,
    orderBy,
    direction,
    filters,
    search,
    sentFirst
  }) =>
    request.post('/api/orders/company', {
      ids,
      offset,
      limit,
      orderBy,
      direction,
      filters,
      search,
      sentFirst
    }),
  removeOrdersToSuppliers: ({ ids }) =>
    request.post('/api/orders/remove', { selected: ids }),
  copyOrderToSupplier: (orderId) => request.copy(`/api/orders/${orderId}`),
  findMyOrders: async (requestMany) =>
    request.post('/api/orders/my', requestMany),
  getFiltersOfMyOrders: async (requestMany) =>
    request.post('/api/orders/filters', requestMany),
  removeMany: async (requestMany) =>
    request.post('/api/orders/remove-many', requestMany)
};
