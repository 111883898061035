import React, { useEffect, useState } from 'react';
import cn from 'classnames';
import { config } from './config';
import styles from './CompanyAvatar.module.css';
import { getCompanyAvatarLogo } from './utils';

export function CompanyAvatar({ src, className, alt = 'companyLogo' }) {
  const [logeSrc, setLogoSrc] = useState(getCompanyAvatarLogo(src));

  useEffect(() => {
    setLogoSrc(getCompanyAvatarLogo(src));
  }, [src]);

  function onError() {
    setLogoSrc(config.logoSrc.error);
  }

  return (
    <div className={cn(styles.avatar, className)}>
      <img onError={onError} src={logeSrc} alt={alt} />
    </div>
  );
}
