const { isNil } = require('ramda');
const { defStruct } = require('../../lib');

/**
 * @typedef {(Date|null)} DateOrNull
 */
const { makeDateOrNull, getDate, isDateOrNull } = defStruct('DateOrNull', [
  'date'
]);

/**
 * Create a date
 *
 * @param {(Date|null)} date
 * @returns {DateOrNull}
 */
exports.makeDateOrNull = (date) =>
  makeDateOrNull(isNil(date) ? null : new Date(date));

/**
 * Gives a date
 *
 * @param {DateOrNull} dateOrNull
 * @returns {[number]}
 */
exports.getDate = (dateOrNull) => getDate(dateOrNull);

/**
 * Checks if is DateOrNull
 *
 * @param {*} a
 * @returns {boolean}
 */
exports.isDateOrNull = (a) => isDateOrNull(a);

/**
 * Checks date is null
 *
 * @param {DateOrNull} dateOrNull
 * @returns {boolean}
 */
exports.isDateNull = (dateOrNull) => getDate(dateOrNull) === null;
