import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { ModalAddCustomerCategory } from '../components/Modal';
import * as customersAction from '../action-creators/customers';
import * as modalAction from '../action-creators/modal';

const ContModalAddCustomerCategory = (props) => (
  <ModalAddCustomerCategory {...props} />
);

export default connect(
  (state) => ({
    newCategory: state.getIn(['customers', 'newCategory'])
  }),
  (dispatch) =>
    bindActionCreators(
      {
        updateNewCategoryName: customersAction.updateNewCategoryName,
        addCategory: customersAction.createCustomersCategory,
        getGroups: customersAction.getGroups,
        hideModalDialog: modalAction.hideModalDialog
      },
      dispatch
    )
)(ContModalAddCustomerCategory);
