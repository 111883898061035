import React from 'react';
import {
  Modal,
  Form,
  FormGroup,
  FormControl,
  Button,
  HelpBlock,
  ControlLabel
} from 'react-bootstrap';
import { translate } from 'react-i18next';

class SingleProductMediaModal extends React.Component {
  constructor(props) {
    super(props);
    this.submit = this.submit.bind(this);
  }

  getProperty(field) {
    return this.props.media.get(field) || '';
  }

  isInvalid(field) {
    return this.props.errors && this.props.errors.has(field);
  }

  validationState(field) {
    return this.isInvalid(field) ? { validationState: 'error' } : {};
  }

  getError(field) {
    const { t, errors } = this.props;
    const error = errors.get(field);
    return t(error.get('messageKey'), error.get('messageParams'));
  }

  async submit(e) {
    e.preventDefault();
    const {
      hideModal,
      uploadDisabled,
      media,
      uploadProductMedia,
      productId,
      getProduct,
      setSuccessMessage,
      publishCatalog,
      catalogId,
      gaSend,
      user
    } = this.props;
    await gaSend({
      category: 'Supplier',
      action: 'media_success',
      label: user.get('email')
    });
    return (
      !uploadDisabled &&
      uploadProductMedia(productId, media.get('src'))
        .then(() => hideModal())
        .then(() => getProduct(null, null, productId))
        .then(() =>
          setSuccessMessage({
            key: 'The data was saved',
            linkKey: 'Publish catalog',
            linkClickHandler: (t) =>
              confirm(t('Catalogue will published in a few minutes')) &&
              publishCatalog(catalogId)
          })
        )
    );
  }

  getInputProps(field) {
    const { type, updateProductMediaField, trimProductMediaField } = this.props;
    return {
      value: this.getProperty(field),
      onChange: (e) => updateProductMediaField(type, field, e.target.value),
      onBlur: () => trimProductMediaField(type, field)
    };
  }

  render() {
    const {
      t,
      hideModal,
      uploadDisabled,
      titleKey,
      placeholderKey,
      formGroupStyle,
      text
    } = this.props;

    return (
      <Modal id="mediaModalWindow" show onHide={() => hideModal()}>
        <Modal.Header>
          <Modal.Title>
            {t(titleKey)}
            <span
              className="modal-header-cross pull-right"
              onClick={() => hideModal()}
            />
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={this.submit}>
            <div style={{ whiteSpace: 'nowrap' }}>{t(text)}</div>
            <br />
            <FormGroup
              style={formGroupStyle}
              controlId="src"
              {...this.validationState('src')}>
              <ControlLabel className="control-label-row">
                {t('URL')}
              </ControlLabel>
              <FormControl
                type="text"
                placeholder={placeholderKey}
                {...this.getInputProps('src')}
              />
              {this.isInvalid('src') ? (
                <HelpBlock>{this.getError('src')}</HelpBlock>
              ) : null}
            </FormGroup>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={() => hideModal()} className="pull-left">
            {t('Cancel')}
          </Button>
          <Button
            id="saveMediaButton"
            bsStyle="primary"
            type="submit"
            disabled={uploadDisabled}
            onClick={this.submit}>
            {t('Add')}
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default translate(['ui'], { wait: true })(SingleProductMediaModal);
