import React from 'react';
import FileSelectWrap from '../../FileSelectWrap';
import BtnIcon from '../../ChatWidget/ButtonIcon';
import './FilesBlock.css';
import { useTranslate } from '../../../TranslateProvider';

const BtnSmallIcon = ({ name, icon, onClick }) => (
  <BtnIcon icon={icon} onClick={onClick} alt={name} />
);

const BtnsSmallIcons = ({ list, name }) => (
  <div className="btns-small-icons">
    {list.map((btn) => (
      <BtnSmallIcon {...btn} key={name + btn.name} />
    ))}
  </div>
);

const FileItem = ({ name, size, createdAt, buttons = [] }) => (
  <div className="form-respond-file-item">
    <img
      className="form-respond-file-item__icon"
      src="/img/purchase-requests/attach.svg"
      alt="icon-link"
    />
    <div className="form-respond-file-item__name">{name}</div>
    <div className="form-respond-file-item__size">{size}</div>
    <div className="form-respond-file-item__date">{createdAt}</div>
    <div className="form-respond-file-item__buttons">
      <BtnsSmallIcons list={buttons} name={name} />
    </div>
  </div>
);

const FilesHeader = ({ t }) => (
  <div className="form-respond-file-header">
    <div className="form-respond-file-header__column-1">
      {t('filesBlock.column-1.title')}
    </div>
    <div className="form-respond-file-header__column-2">
      {t('filesBlock.column-2.title')}
    </div>
    <div className="form-respond-file-header__column-3">
      {t('filesBlock.column-3.title')}
    </div>
  </div>
);
const FilesList = ({ files = [] }) => (
  <div className="form-respond-files-list">
    {files.map((file, index) => (
      <FileItem {...file} key={file.name + index} />
    ))}
  </div>
);

const FilesBlock = ({
  show = true,
  files = [],
  showAddBtn = false,
  onClickAdd,
  total = 0,
  classNames = '',
  style = {}
}) => {
  const t = useTranslate();

  if (!show) return null;

  return (
    <div className="form-respond-files-list-block" style={style}>
      <div className="form-respond-files-list-block__header">
        <div className="form-respond-files-list-block__title-wrapper">
          <div className="form-respond-files-list-block__title-text">
            {t('filesBlock.title')}
          </div>
          <div className="form-respond-files-list-block__title-count">{`(${total})`}</div>
        </div>
      </div>
      {files.length > 0 && (
        <div className={`form-respond-files-list-block__content ${classNames}`}>
          <FilesHeader t={t} />
          <FilesList files={files} />
        </div>
      )}
      {showAddBtn && (
        <FileSelectWrap
          multiple
          accept=""
          onChange={onClickAdd}
          component={({ onClick }) => (
            <button
              type="button"
              className="form-respond-files-list-block__btn-add"
              onClick={onClick}>
              <img
                className="form-respond-files-list-block__btn-add-icon"
                alt="add-file"
                src="/img/purchase-requests/attach-small.svg"
              />
              <span className="form-respond-files-list-block__btn-add-text">
                {t('chat-widget.tooltips.attach-file')}
              </span>
            </button>
          )}
        />
      )}
    </div>
  );
};

export default FilesBlock;
