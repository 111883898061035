import React, { useEffect, useState } from 'react';
import Textarea from 'react-textarea-autosize';
import ButtonIcon from '../../../buttons/ButtonIcon';
import './style.css';

const MODS = {
  read: 'read',
  edit: 'edit'
};

function getCN(mode) {
  let cn = 'confirm-edit-textarea-wrapper';

  if (mode === MODS.edit) cn += ' confirm-edit-textarea_edit-mode';
  if (mode === MODS.read) cn += ' confirm-edit-textarea_read-mode';

  return cn;
}

function ConfirmEditTextarea({
  t,
  value: defaultValue = '',
  onConfirm: confirmCb,
  placeholder,
  maxLength,
  minRows,
  maxRows,
  disabled = false,
  onChange: changeCb,
  onFocus: focusCb,
  onBlur: blurCb
}) {
  const [mode, setMode] = useState(MODS.read);
  const [value, setValue] = useState(defaultValue);

  useEffect(() => {
    setValue(defaultValue);
  }, [defaultValue]);

  const onChange = (event) => {
    setValue(event.target.value);

    if (typeof changeCb === 'function') {
      changeCb(event);
    }
  };

  const onFocus = (event) => {
    changeMode(MODS.edit);

    if (typeof focusCb === 'function') {
      focusCb(event);
    }
  };

  const onBlur = (event) => {
    if (!hasChanges()) {
      changeMode(MODS.read);
    }

    if (typeof blurCb === 'function') {
      blurCb(event);
    }
  };

  const onConfirm = () => {
    changeMode(MODS.read);

    if (typeof confirmCb === 'function') {
      confirmCb(value);
    }
  };

  const onCancel = () => {
    setValue(defaultValue);
    changeMode(MODS.read);
  };

  function changeMode(newMode) {
    if (disabled) return;

    setMode(newMode);
  }

  function hasChanges() {
    return value !== defaultValue;
  }

  function isConfirmDisabled() {
    return disabled || value === defaultValue;
  }

  return (
    <div className={getCN(mode)}>
      <Textarea
        onFocus={onFocus}
        onBlur={onBlur}
        className="confirm-edit-textarea"
        value={value}
        onChange={onChange}
        disabled={disabled}
        placeholder={placeholder}
        maxLength={maxLength}
        minRows={minRows}
        maxRows={maxRows}
      />
      <div className="confirm-edit-textarea-actions">
        <ButtonIcon
          t={t}
          icon="ok-circle"
          onClick={onConfirm}
          disabled={isConfirmDisabled()}
        />
        <ButtonIcon t={t} icon="cross-circle" onClick={onCancel} />
      </div>
    </div>
  );
}

export default ConfirmEditTextarea;
