import React from 'react';
import block from 'bem-cn-lite';
import { Link } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import { formatRequestNumber } from '../../../../utils/utils';
import CategoriesList from './CategoriesList';
import {
  getCustomerName,
  getLocation,
  getStatus,
  getStatusTKey,
  isFromThatCompany,
  isResponded
} from './methods';
import Icons from '../../common/Icons';
import { useTranslate } from '../../../../TranslateProvider';
import { isResponseAllowed } from '../../utils';
import Status from '../../../Status';
import './style.css';

const b = block('cr-content');

const Content = ({
  request,
  categories = [],
  icons = [],
  company,
  onRespond,
  haveUpdatePermission = true
}) => {
  const t = useTranslate();

  const isDisabledBtn = () => {
    if (isFromThatCompany(request, company) || !isResponseAllowed(request)) {
      return true;
    }

    return !haveUpdatePermission && !isResponded(request);
  };

  return (
    <div className={b()}>
      <div className={b('header')}>
        <div className={b('header-left')}>
          <div className={b('request-number')}>
            {`№ ${formatRequestNumber(request.get('id'))} - ${
              request.get('number') || formatRequestNumber(request.get('id'))
            }`}
          </div>
          <Icons buttons={icons} />
        </div>
        <div className={b('header-right')}>
          <div className={b('status')}>
            <Status
              status={getStatus(request)}
              text={getStatusTKey(request)}
              statusColors={{ viewed: null }}
            />
          </div>
        </div>
      </div>
      <div className={b('body')}>
        <Link className={b('title')} to={`/requests/all/${request.get('id')}`}>
          {request.get('title')}
        </Link>
        <div className={b('categories')}>
          <CategoriesList
            categories={categories}
            categoriesIds={request.get('categoriesId')}
          />
        </div>
      </div>
      <div className={b('footer')}>
        <div>
          <Link
            className={b('customer')}
            to={`/companies/${request.get('companyId')}/view`}>
            {getCustomerName(request)}
          </Link>
          <div className={b('location')}>{getLocation(request)}</div>
        </div>
        <div className={b('footer-btn')}>
          <Button onClick={onRespond} disabled={isDisabledBtn()}>
            {t(
              isResponded(request)
                ? 'purchaseRequest.card.btns.viewRespond'
                : 'purchaseRequest.card.btns.respond'
            )}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default Content;
