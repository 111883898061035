import React from 'react';
import { useTranslate } from '@hooks';
import RenderHTML from './RenderHTML';
import Editor from './Editor';

const EditorButton = ({ show = true, lookBtn }) => {
  const t = useTranslate();

  if (!show) return null;

  return (
    <div className="form-respond-editor__buttons">
      <button
        type="button"
        className="form-respond-editor__button form-respond-editor__button_color_blue"
        onClick={lookBtn.onClick}
        disabled={lookBtn.disabled}>
        {t(lookBtn.tkeyText)}
      </button>
    </div>
  );
};

const EditorBlock = ({ text, editor, buttons }) => (
  <div className="form-respond-editor">
    <div className="form-respond-editor__editor">
      <RenderHTML
        show={text.show}
        html={text.html}
        isClickable={text.isClickable}
        onClick={text.onClick}
      />
      <Editor
        show={editor.show}
        value={editor.text}
        placeholder={editor.placeholder}
        onChange={editor.onChange}
        btnUndo={editor.btnUndo}
      />
    </div>
    <EditorButton show={buttons.show} lookBtn={buttons.lookBtn} />
  </div>
);

export default EditorBlock;
