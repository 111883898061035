import React, { useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { translate } from 'react-i18next';
import { Modal, Button } from 'react-bootstrap';
import styled from 'styled-components';

import InputNumber from '../InputNumber';
import { ModalContainer } from './elements';

import * as modalActions from '../../action-creators/modal';
import * as orderActions from '../../action-creators/orders';
import * as messageActions from '../../action-creators/message';

import { Flex, Text } from '../../styledComponents/atoms/index';
import store from '../../storeSections';

import './styles/modal_theme_default.styl';

const ModalChangeMassPriceProducts = ({
  t,
  orderId,
  priceId,
  stockId,
  selectedOrderItems,
  selectedPriceCategory,
  saveDraft,
  hideModalDialog,
  updateCountOfSelectedOrderProducts,
  setCountForSelectedItemsOrderDraft,
  setSuccessMessage
}) => {
  const [count, setCount] = useState(0);

  const handleSetCount = (value) => {
    if (/^[0-9]+$/.test(value)) {
      setCount(value);
    }
  };

  const prepareItems = () =>
    selectedOrderItems.map((selectedItem) =>
      selectedItem.set('count', count).delete('productId')
    );

  const createOrder = async () => {
    const categoryId = selectedPriceCategory && selectedPriceCategory.get('id');
    const items = prepareItems();

    const newOrderId = await saveDraft(
      {
        priceId,
        items,
        categoryId
      },
      { showSuccessMessage: false }
    );

    if (newOrderId) {
      window.history.replaceState(
        null,
        null,
        `/orders-company/${priceId}/creation/${newOrderId}`
      );
    }
    setCountForSelectedItemsOrderDraft(count);
  };

  const updateCounts = async () => {
    await updateCountOfSelectedOrderProducts({
      orderId,
      priceId,
      count,
      stockId
    });
  };

  const onSubmit = async () => {
    try {
      if (orderId) {
        await updateCounts();
      } else {
        await createOrder();
      }
      setSuccessMessage({ key: 'The data was saved' });
      hideModalDialog();
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <Modal onHide={hideModalDialog} show>
      <ModalContainer mods={{ type: 'default', theme: 'default' }}>
        <Modal.Header>
          <Modal.Title>
            {t('change-quantity-order')}
            <span
              className="modal-header-cross pull-right"
              onClick={hideModalDialog}
            />
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ContentWrapper>
            <Text noMargin>{t('enter-quantity-product-for-select')}</Text>
            <WrapperInputNumber>
              <InputNumber
                defaultValue={0}
                value={count}
                min={0}
                max={999999}
                onChange={handleSetCount}
              />
            </WrapperInputNumber>
          </ContentWrapper>
        </Modal.Body>
        <Modal.Footer>
          <Button id="cancel" className="pull-left" onClick={hideModalDialog}>
            {t('Cancel')}
          </Button>
          <Button id="saveButton" bsStyle="primary" onClick={onSubmit}>
            {t('Change')}
          </Button>
        </Modal.Footer>
      </ModalContainer>
    </Modal>
  );
};

const ContentWrapper = styled(Flex)`
  align-items: center;
`;

const WrapperInputNumber = styled.div`
  margin-left: 32px;
  max-width: 82px;
  height: 25px;

  .input-number {
    width: auto;
  }
`;

export default connect(
  store((state) => ({
    selectedOrderItems: state.orderDraft.selectedItems(),
    orderId: state.orderDraft.orderId(),
    selectedPriceCategory: state.orderDraft.priceCategory()
  })),
  (dispatch) =>
    bindActionCreators(
      {
        hideModalDialog: modalActions.hideModalDialog,
        saveDraft: orderActions.saveDraft,
        setCountForSelectedItemsOrderDraft:
          orderActions.setCountForSelectedItemsOrderDraft,
        updateCountOfSelectedOrderProducts:
          orderActions.updateCountOfSelectedOrderProducts,
        setSuccessMessage: messageActions.setSuccessMessage
      },
      dispatch
    )
)(translate()(ModalChangeMassPriceProducts));
