import { Map, List, fromJS } from 'immutable';

const initState = Map({
  offset: 0,
  totalSize: 0,
  limit: 20,
  products: List(),
  currency: null,
  search: '',
  filterValues: [],
  appliedFilterValues: []
});

const setProducts = (
  data,
  {
    products,
    search,
    offset,
    limit,
    totalSize,
    currency,
    filterValues,
    showReceiptDate,
    showVat,
    showDiscount,
    showDiscountPrice
  }
) =>
  data
    .set('offset', Number(offset))
    .set('limit', Number(limit))
    .set('totalSize', Number(totalSize))
    .set('products', fromJS(products))
    .set('search', search)
    .set('currency', currency)
    .set('filterValues', fromJS(filterValues))
    .set('showReceiptDate', showReceiptDate)
    .set('showVat', showVat)
    .set('showDiscount', showDiscount)
    .set('showDiscountPrice', showDiscountPrice);

const setFilterField = (data, { name, value }) => {
  const index = data
    .get('filterValues')
    .findIndex((filter) => filter.get('fieldName') === name);
  const filter = data.getIn(['filterValues', index]).toJS();
  return data.setIn(['filterValues', index], fromJS({ ...filter, ...value }));
};

const applyFilter = (data, { filters }) =>
  data.set('appliedFilterValues', filters);

const clearFilter = (data) =>
  data.set('filterValues', []).set('appliedFilterValues', []);

const publicPricesProducts = (state = initState, { type, payload }) => {
  switch (type) {
    case 'PUBLIC_PRICE:GET_PRODUCTS:SUCCESS':
      return setProducts(state, payload);
    case 'PUBLIC_PRICE:SET_FILTER_FIELD':
      return setFilterField(state, payload);
    case 'PUBLIC_PRICE:APPLY_FILTER':
      return applyFilter(state, payload);
    case 'PUBLIC_PRICE:CLEAR_FILTER':
      return clearFilter(state, payload);
    default:
      return state;
  }
};

export default publicPricesProducts;
