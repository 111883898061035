import React from 'react';
import { useTranslate } from '../../../../../../../TranslateProvider';
import RowNumberCell from '../RowNumberCell/RowNumberCell';
import ProductNameCell from '../ProductNameCell/ProductNameCell';
import GroupCell from '../GroupCell/GroupCell';
import LabelCell from '../LabelCell/LabelCell';
import ProductDataCell from '../ProductDataCell/ProductDataCell';
import { formatSum, getTranslate } from '../../utils';
import { CellType } from '../../domain/cell';
import style from './CustomCell.module.css';

function checkShow(key, hiddenKeys = []) {
  return !hiddenKeys.includes(key);
}

export function isEmptyRecord(record) {
  return !record?.count || !record?.price;
}

const CustomCell = ({
  type,
  record,
  rowNumber,
  dataIndex,
  hiddenCells,
  children,
  ...restProps
}) => {
  const t = useTranslate();

  const isShowCountCell = checkShow('count', hiddenCells);
  const isShowPriceCell = checkShow('price', hiddenCells);
  const isShowTotalCell = checkShow('total', hiddenCells);

  if (type === CellType.RowNumber) {
    return (
      <td {...restProps}>
        <RowNumberCell>{children}</RowNumberCell>
      </td>
    );
  }

  if (type === CellType.ProductName) {
    return (
      <td {...restProps}>
        <ProductNameCell>{children}</ProductNameCell>
      </td>
    );
  }

  if (type === CellType.Labels) {
    return (
      <td {...restProps}>
        <GroupCell noHover>
          {isShowCountCell && <LabelCell>{getTranslate(t, 'count')}</LabelCell>}
          {isShowPriceCell && <LabelCell>{getTranslate(t, 'price')}</LabelCell>}
          {isShowTotalCell && <LabelCell>{getTranslate(t, 'total')}</LabelCell>}
        </GroupCell>
      </td>
    );
  }

  if (type === CellType.Data) {
    const currentRecord = record[rowNumber];

    if (isEmptyRecord(currentRecord)) {
      return (
        <td {...restProps}>
          <GroupCell>
            {isShowCountCell && <ProductDataCell>-</ProductDataCell>}
            {isShowPriceCell && <ProductDataCell>-</ProductDataCell>}
            {isShowTotalCell && (
              <ProductDataCell className={style.totalPrice}>-</ProductDataCell>
            )}
          </GroupCell>
        </td>
      );
    }

    const countData = `${currentRecord.count}/${
      record.requestProduct?.count || 0
    }`;
    const priceData = currentRecord.price;
    const totalData = formatSum(currentRecord.price * currentRecord.count, {
      totalMaximumDigits: 11,
      maxFractionDigits: 2,
      divider: '.'
    });

    return (
      <td {...restProps}>
        <GroupCell>
          {isShowCountCell && <ProductDataCell>{countData}</ProductDataCell>}
          {isShowPriceCell && <ProductDataCell>{priceData}</ProductDataCell>}
          {isShowTotalCell && (
            <ProductDataCell className={style.totalPrice}>
              {totalData}
            </ProductDataCell>
          )}
        </GroupCell>
      </td>
    );
  }

  return <td {...restProps}>{children}</td>;
};

export default CustomCell;
