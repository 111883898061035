import React from 'react';
import * as useCases from '../../../../ChatWidget/useCases';
import { useActions } from '../../../../../hooks/useActions';
import EmptyChat from '../../../../ChatWidget/sidebar/list/chats/empty';
import ChatList from '../../../../ChatWidget/sidebar/list/chat-list-item';
import {
  useChatList,
  useShowMode
} from '../../../../ChatWidget/sidebar/list/chat-list-item/generator';

const Chats = ({ config }) => {
  const showMode = useShowMode();
  const viewModel = useChatList({ config });
  const { handleChatList } = useActions(useCases);

  if (showMode === 'empty') return <EmptyChat />;

  return <ChatList viewModel={viewModel} handler={handleChatList} />;
};

export default Chats;
