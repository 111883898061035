/* eslint-disable max-len */
import React from 'react';

const ProfileIcon = () => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      className="snm-icon__path"
      d="M13 4C13 6.20914 11.2092 8 9.00002 8C6.79088 8 5.00002 6.20914 5.00002 4C5.00002 1.79086 6.79088 0 9.00002 0C11.2092 0 13 1.79086 13 4Z"
      fill="#C6CAE3"
    />
    <path
      className="snm-icon__path"
      d="M1.53282 11.8688C1.8074 10.7705 2.79424 10 3.92636 10H14.0737C15.2058 10 16.1926 10.7705 16.4672 11.8688C17.2458 14.9832 14.8903 18 11.6801 18H6.3199C3.10971 18 0.754232 14.9832 1.53282 11.8688Z"
      fill="#C6CAE3"
    />
  </svg>
);

export default ProfileIcon;
