import React from 'react';
import Text from 'react-components/src/components/Typography';
import { useTranslate } from '../../../../../../TranslateProvider';
import { MAX_USERS_IN_INTRODUCTION_CARD, STEP_TYPES } from '../../constants';
import { UserCard } from '../../userCard';
import { ButtonConstructor } from '../../buttons/buttonConstructor';
import styles from './CardContent.module.css';
import { useIsCanBeDeleted } from '../../../../../../contexts/RouteIsCanBeDeletedContext';

function AddUserButtonOrUserCard({
  users,
  onChangeExecute,
  onRemoveUserFromCard,
  isError,
  onShowModalAddUser,
  onExchangeUser
}) {
  const t = useTranslate();
  const isCanBeDeleted = useIsCanBeDeleted();
  if (users.length) {
    return (
      <>
        {users.map((user, userIndex) => (
          <UserCard
            onExchangeUser={() => onExchangeUser(user.id)}
            key={`${user.id}-user-${userIndex}`}
            changeUserIsExecuteAutomatically={() => onChangeExecute(user.id)}
            removeUserFromCard={() => onRemoveUserFromCard(user.id)}
            src={user.avatar}
            isTimer={user.isExecuteAutomatically}
            department={user.department}
            lastName={user.lastName}
            firstName={user.firstName}
          />
        ))}
      </>
    );
  }

  return (
    <>
      {isCanBeDeleted && (
        <ButtonConstructor
          isError={isError}
          onClick={onShowModalAddUser}
          isBig
          text={t('constructor.responsible')}
        />
      )}
    </>
  );
}

export const CardContent = ({
  type,
  users,
  isError,
  onChangeExecute,
  onRemoveUserFromCard,
  onShowModalAddUser,
  onExchangeUser
}) => {
  const t = useTranslate();
  const isCanBeDeleted = useIsCanBeDeleted();
  if (type === STEP_TYPES.publication) {
    return (
      <AddUserButtonOrUserCard
        onExchangeUser={onExchangeUser}
        users={users}
        onRemoveUserFromCard={onRemoveUserFromCard}
        onShowModalAddUser={onShowModalAddUser}
        isError={isError}
        onChangeExecute={onChangeExecute}
      />
    );
  }
  if (type === STEP_TYPES.introduction) {
    return (
      <>
        <div className={styles.introductionCard}>
          {users.map((user, userIndex) => (
            <UserCard
              onExchangeUser={onExchangeUser}
              onShowModalAddUser={onShowModalAddUser}
              key={`${user.id}-user-${userIndex}`}
              changeUserIsExecuteAutomatically={() => onChangeExecute(user.id)}
              removeUserFromCard={() => onRemoveUserFromCard(user.id)}
              src={user.avatar}
              isTimer={user.isExecuteAutomatically}
              department={user.department}
              lastName={user.lastName}
              firstName={user.firstName}
            />
          ))}

          {users.length < MAX_USERS_IN_INTRODUCTION_CARD && isCanBeDeleted && (
            <ButtonConstructor
              onClick={onShowModalAddUser}
              isError={isError}
              isBig
              text={t('constructor.responsible')}
            />
          )}
        </div>
      </>
    );
  }
  if (type === STEP_TYPES.agreement) {
    return (
      <AddUserButtonOrUserCard
        onExchangeUser={onExchangeUser}
        users={users}
        onRemoveUserFromCard={onRemoveUserFromCard}
        onShowModalAddUser={onShowModalAddUser}
        isError={isError}
        onChangeExecute={onChangeExecute}
      />
    );
  }
  if (type === STEP_TYPES.begin) {
    return (
      <Text variant="body1Med" className={styles.textStart}>
        {t('constructor.processStart')}
      </Text>
    );
  }

  return null;
};
