import React from 'react';
import { translate } from 'react-i18next';

const Title = ({ t, color = 'black' }) => (
  <div className={`form-respond-title form-respond-title_color_${color}`}>
    {t('respondForm.form.title')}
  </div>
);

export default translate()(Title);
