import React from 'react';
import MessengerOther from '../../../components/Layouts/MessengerOther';
import ContainersDefault from '../../../components/Containers/ContainersDefault';
import Content from './Content';
import { useTranslate } from '../../../TranslateProvider';
import { isProduction } from '../../../utils/utils';
import { getTranslate } from './helpers';

const EMPTY_LIST = [];

const EmailMessages = () => {
  const t = useTranslate();

  if (isProduction()) {
    return null;
  }

  return (
    <MessengerOther
      theme="gray"
      title={getTranslate(t, 'mainPageTitle')}
      breadcrumbs={EMPTY_LIST}
      panelBtns={EMPTY_LIST}
      content={
        <ContainersDefault>
          <Content />
        </ContainersDefault>
      }
    />
  );
};

export default EmailMessages;
