import React from 'react';
import GoodwixIcon from '../../assets/icons/goodwix';
import CompanyIcon from '../../assets/icons/company';
import MessengerIcon from '../../assets/icons/messenger';
import ProfileIcon from '../../assets/icons/profile';
import RequestIcon from '../../assets/icons/request';
import QuestionsIcon from '../../assets/icons/questions';
import ExitIcon from '../../assets/icons/exit';
import TaskManagerIcon from '../../assets/icons/taskManager';
import OrganizationIcon from '../../assets/icons/organization';
import SettingsIcon from '../../assets/icons/settings';

export const SidebarIcons = {
  organization: <OrganizationIcon />,
  goodwix: <GoodwixIcon />,
  company: <CompanyIcon />,
  messenger: <MessengerIcon />,
  profile: <ProfileIcon />,
  request: <RequestIcon />,
  questions: <QuestionsIcon />,
  exit: <ExitIcon />,
  'task-manager': <TaskManagerIcon />,
  settings: <SettingsIcon />
};
