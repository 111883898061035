import { assoc } from 'ramda';
import { makeTypeMethods } from 'core/data/poly';

/**
 * @typedef BlockInput
 * @property {string} text
 * @property {[File]} list
 * @property {[number]} mentions
 */

/**
 * Creates a block input data type
 *
 * @param {object} params
 * @param {string} params.text
 * @param {[File]} params.files
 * @param {[string]} params.loadingFilesIds
 * @param params.maxFiles
 * @param params.hidden
 * @returns {BlockInput}
 */
export const makeBlockInput = ({
  text = '',
  files = [],
  maxFiles = 5,
  hidden = false,
  loadingFilesIds = []
} = {}) => ({
  type: 'BlockInput',
  text,
  files,
  loadingFilesIds,
  maxFiles,
  hidden
});

/**
 * Sets a text
 *
 * @param {BlockInput} blockInput
 * @param {string} text
 * @returns {BlockInput}
 */
export const setText = (blockInput, text) => assoc('text', text, blockInput);

/**
 * Sets a files
 *
 * @param {BlockInput} blockInput
 * @param {[File]} files
 * @returns {BlockInput}
 */
export const setFiles = (blockInput, files) =>
  assoc('files', files, blockInput);

/**
 * Determines if files is max
 *
 * @param {BlockInput} blockInput
 * @returns {boolean}
 */
export const isMaxFiles = (blockInput) =>
  blockInput.files.length >= blockInput.maxFiles;

/**
 * Return maxFiles count
 *
 * @param {BlockInput} blockInput
 * @returns {boolean}
 */
export const getMaxFilesCount = (blockInput) => blockInput.maxFiles;

/**
 * Return files
 *
 * @param {BlockInput} blockInput
 * @returns {boolean}
 */
export const getFiles = (blockInput) => blockInput.files;

/**
 * Hide input
 *
 * @param {BlockInput} blockInput
 * @returns {BlockInput}
 */
export const hide = (blockInput) => assoc('hidden', true, blockInput);

/**
 * Show input
 *
 * @param {BlockInput} blockInput
 * @returns {BlockInput}
 */
export const show = (blockInput) => assoc('hidden', false, blockInput);

/**
 * Set a loading portion files ids
 *
 * @param {BlockInput} blockInput
 * @param {[string]} ids
 * @returns {BlockInput}
 */
export const setLoadingFilesIds = (blockInput, ids) =>
  assoc('loadingFilesIds', ids, blockInput);

/**
 * Add a loading portion files id
 *
 * @param {BlockInput} blockInput
 * @param {string} id
 * @returns {BlockInput}
 */
export const addLoadingFileId = (blockInput, id) => ({
  ...blockInput,
  loadingFilesIds: [...blockInput.loadingFilesIds, id]
});

/**
 * Remove fileId form loading portion files ids
 *
 * @param {BlockInput} blockInput
 * @param {string} id
 * @returns {BlockInput}
 */
export const removeLoadingFileId = (blockInput, id) => ({
  ...blockInput,
  loadingFilesIds: blockInput.loadingFilesIds.filter((fileId) => fileId !== id)
});

const fileIncludes = (file, input) =>
  input.files.map((f) => f.id).includes(file.id);

export const inputMethods = makeTypeMethods('BlockInput', { fileIncludes });
