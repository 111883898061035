import React from 'react';
import { translate } from 'react-i18next';
import BtnIcon from '../../ChatWidget/ButtonIcon';
import './LinksListBlock.css';

const BtnSmallIcon = ({ name, icon, onClick }) => (
  <BtnIcon icon={icon} onClick={onClick} alt={name} />
);

const BtnsSmallIcons = ({ list }) => (
  <div className="btns-small-icons">{list.map(BtnSmallIcon)}</div>
);

const LinkItem = ({ name, url, buttons = [] }) => (
  <div className="form-respond-link-item">
    <img
      className="form-respond-link-item__icon"
      src="/img/purchase-requests/link.svg"
      alt="icon-link"
    />
    <a
      className="form-respond-link-item__name"
      href={url}
      target="_blank"
      rel="noreferrer">
      {name}
    </a>
    <div className="form-respond-link-item__buttons">
      <BtnsSmallIcons list={buttons} />
    </div>
  </div>
);

export const LinksList = ({ links = [] }) => (
  <div className="form-respond-links-list">{links.map(LinkItem)}</div>
);

const LinksListBlock = ({
  t,
  show = true,
  links = [],
  showAddBtn = false,
  onClickAdd,
  total = 0,
  classNames = '',
  style = {}
}) => {
  if (!show) return null;

  return (
    <div className="form-respond-links-list-block" style={style}>
      <div className="form-respond-links-list-block__header">
        <div className="form-respond-links-list-block__title-wrapper">
          <div className="form-respond-links-list-block__title-text">
            {t('linksListBlock.title')}
          </div>
          <div className="form-respond-links-list-block__title-count">{`(${total})`}</div>
        </div>
      </div>
      {links.length > 0 && (
        <div className={`form-respond-links-list-block__content ${classNames}`}>
          <LinksList links={links} />
        </div>
      )}
      {showAddBtn && (
        <button
          type="button"
          className="form-respond-links-list-block__btn-add"
          onClick={onClickAdd}>
          <img alt="add-link" src="/img/purchase-requests/link-small.svg" />
          <span className="form-respond-links-list-block__btn-add-text">
            {t('modals.add-link.title')}
          </span>
        </button>
      )}
    </div>
  );
};

export default translate()(LinksListBlock);
