const { makePolicy } = require('../../data/policy');

// Section name
const COMPANY = 'COMPANY';

// Use cases
const VIEW_COMPANY_DETAILS = 'VIEW_COMPANY_DETAILS';
const CHANGE_COMPANY_INFORMATION = 'CHANGE_COMPANY_INFORMATION';
const DELETE_COMPANY = 'DELETE_COMPANY';

const settings = [
  makePolicy(COMPANY, VIEW_COMPANY_DETAILS, {
    reader: true,
    writer: true,
    advanced: true
  }),
  makePolicy(COMPANY, CHANGE_COMPANY_INFORMATION, {
    reader: false,
    writer: true,
    advanced: true
  }),
  makePolicy(COMPANY, DELETE_COMPANY, {
    reader: false,
    writer: false,
    advanced: true
  })
];

module.exports = {
  COMPANY,
  VIEW_COMPANY_DETAILS,
  CHANGE_COMPANY_INFORMATION,
  DELETE_COMPANY,
  settings
};
