import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import ButtonIcon from 'react-components/src/components/ButtonIcon';
import IfButton from '../../../ButtonIcon/IfButton';
import CWSIconDropdown from '../../../Dropdown/Icon';
import {
  getExtraMenuList,
  getExtraMenuListConditions,
  getVideoChatValue,
  hasPermissionToAddPeople,
  initHandler,
  innerVideoCallList,
  innerVideoCallListDisabled,
  isNotificationBtnChoosen,
  makeDashboardVM,
  notificationBtnList
} from './dataType';
import { getDialogInfo } from '../../dialogInfo/getters';
import { useVideoChatContext } from '../../../../../modules/VideoChat/hooks/useVideoChatContext';
import { useTranslate } from '../../../../../TranslateProvider';
import { getDelayedMessages } from '../../delayedMessages/useCases';
import { getOpenedChat } from '../../../getter';

const mapConfigToBtns = (viewModel) => {
  const chatId = useSelector(getOpenedChat).id;
  const [delayedMessagesAmount, setDelayedMessagesAmount] = useState(0);

  useEffect(() => {
    if (chatId) {
      getDelayedMessages(chatId).then((result) => {
        console.log(result);
        setDelayedMessagesAmount(result?.list?.length);
      });
    }
  }, [chatId]);

  // console.log(delayedMessagesAmount);
  const { size, t, userTo } = viewModel;
  const buttonsSet = {
    viewProfile: (vm) => (
      <ButtonIcon
        icon="person"
        size={size}
        key="CWDB-viewProfile"
        onClick={initHandler(vm, 'view-profile-widget')}
      />
    ),
    search: (vm) => (
      <ButtonIcon
        icon="search"
        size={size}
        t={t}
        tooltip="search-by-history"
        key="CWDB-search"
        onClick={initHandler(vm, 'search')}
      />
    ),
    addMembers: (vm) =>
      hasPermissionToAddPeople(vm) && (
        <ButtonIcon
          icon="contact-add"
          size={size}
          t={t}
          key="CWDB-addMembers"
          tooltip="add-member"
          onClick={initHandler(vm, 'add-members')}
        />
      ),
    favorite: (vm) => (
      <ButtonIcon
        icon="favorite"
        tooltip="favorite"
        size={size}
        t={t}
        key="CWDB-favorite"
        onClick={initHandler(vm, 'favorite')}
      />
    ),
    delayed: (vm) =>
      delayedMessagesAmount > 0 ? (
        <ButtonIcon
          icon="delayed-message"
          tooltip="delayed-messages"
          size={size}
          t={t}
          key="CWDB-delayed"
          onClick={initHandler(vm, 'show-delayed-messages')}
        />
      ) : null,
    notification: (vm) => (
      <IfButton
        t={t}
        key="CWDB-notification"
        size={size}
        chosen={isNotificationBtnChoosen(vm)}
        list={notificationBtnList}
        onClick={initHandler(vm)}
      />
    ),
    videoCall: (vm) => (
      <IfButton
        t={t}
        size={size}
        key="CWDB-videoCall"
        chosen={getVideoChatValue(vm)}
        list={
          userTo?.isBlockedContact
            ? innerVideoCallListDisabled
            : innerVideoCallList
        }
        onClick={initHandler(vm)}
      />
    ),
    purchaseFilter: (vm) => (
      <ButtonIcon
        icon="settings"
        tooltip="change_subscribe_params"
        size={size}
        t={t}
        key="CWDB-purchase-filter"
        onClick={initHandler(vm, 'purchase-filter')}
      />
    ),
    extra: (vm) => (
      <CWSIconDropdown
        title={() => <ButtonIcon icon="submenu" size={size} t={t} />}
        onSelect={initHandler(vm)}
        list={getExtraMenuList(vm)}
        tooltip="menu"
        conditions={getExtraMenuListConditions(vm)}
        position="bottom-end"
        key="CWDB-extra"
      />
    )
  };

  const { config } = viewModel;
  return config.map((btn) => buttonsSet[btn](viewModel));
};

const wrapper = ({ chat, user, isActive, handler, config, type, size }) => {
  const isDemo = user && user.get('type') === 'demo';
  const userTo = useSelector(getDialogInfo);
  const videoChat = useVideoChatContext();
  const t = useTranslate();

  return makeDashboardVM({
    chat,
    videoChat,
    user,
    isActive,
    handler,
    userTo: chat.type !== 'Dialog' ? {} : userTo,
    isDemo,
    config: config[type.toLowerCase()],
    type,
    size,
    t
  });
};

const CWMBtns = ({ chat, user, isActive, handler, config, type, size }) => {
  const viewModel = wrapper({
    chat,
    user,
    isActive,
    handler,
    config,
    type,
    size
  });

  return mapConfigToBtns(viewModel);
};

export default CWMBtns;
