import { isEmpty } from 'ramda';
import { defineOS } from '../../../../lib/goodwix';

export function isMainMode(mode) {
  return mode === 'main';
}

export function isEditMode(mode) {
  return mode === 'edit';
}

export function isExistEditMessage(blockEdit) {
  return Boolean(blockEdit && blockEdit.message);
}

export function hasChange(originalMessage, text, files = []) {
  return (
    isTextChanged(originalMessage.msg, text) ||
    isFilesCountChanged(originalMessage.files.length, files.length)
  );
}

export function isTextChanged(originText, text) {
  return originText !== text;
}

export function isFilesCountChanged(originFilesCount, filesLength) {
  return originFilesCount !== filesLength;
}

export function isTextEmpty(text) {
  return text.trim() === '';
}

export function isFilesEmpty(files) {
  return isEmpty(files);
}

export function isMessageEmpty(text, files) {
  return isTextEmpty(text) && isFilesEmpty(files);
}

export function isOverLimit(text, maxMsgLength) {
  return getCountSymbols(text) > maxMsgLength;
}

export function isShowFileAttachments(files) {
  return !isFilesEmpty(files);
}

export function getCountSymbols(text) {
  return text.length;
}

export function isAllowToSend({
  mode,
  text,
  files,
  blockEdit,
  allowSendEmpty,
  maxMsgLength
}) {
  if (isOverLimit(text, maxMsgLength)) return false;
  if (isMainMode(mode)) return allowSendEmpty || !isMessageEmpty(text, files);
  if (isEditMode(mode)) {
    return (
      !isMessageEmpty(text, files) &&
      isExistEditMessage(blockEdit) &&
      hasChange(blockEdit.message, text, files)
    );
  }

  throw new Error(`Unknown mode ${mode}`);
}

const isShowPlaceholder = (editorState) => {
  const contentState = editorState.getCurrentContent();
  if (!contentState.hasText()) {
    if (contentState.getBlockMap().first().getType() !== 'unstyled') {
      return false;
    }
  }
  return true;
};

export function generateWrapperEditorCN(editorState) {
  return `cw-wrapper-editor-input ${defineOS().toLowerCase()} placeholder_${
    isShowPlaceholder(editorState) ? 'shown' : 'hidden'
  }`;
}
