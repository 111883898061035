import { combineReducers } from 'redux-immutable';
import positions from './public-prices-positions';
import products from './public-prices-products';
import categories from './public-prices-categories';
import purchaseCategories from './public-prices-purchase-categories';
import price from './public-prices-price';
import productsList from './products';

export default combineReducers({
  productsList,
  positions,
  categories,
  products,
  purchaseCategories,
  price
});
