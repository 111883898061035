import React, { useCallback } from 'react';
import { Loader, Typography } from '@link/react-components';
import { useCompanyProfileContext } from '../../CompanyProfile.context';
import { CompanyAvatar } from '../../../CompanyAvatar';
import { Categories } from '../../../Categories';
import { RNPIcon } from '../../../RNPIcon';
import { SubscribeRequestsButton } from '../../../SubscribeRequestsButton';
import styles from './GeneralInfo.module.css';

export function GeneralInfo() {
  const { loading, company, setCompany } = useCompanyProfileContext();

  const toggleSubscribe = useCallback((isSubscribed) => {
    setCompany((prevState) => ({ ...prevState, isSubscribed }));
  }, []);

  if (loading) {
    return (
      <div className={styles.container}>
        <Loader />
      </div>
    );
  }

  return (
    <div className={styles.container}>
      <div className={styles.infoBlock}>
        <CompanyAvatar src={company.logo} />
        <div className={styles.info}>
          <div className={styles.between}>
            <div className={styles.titleRow}>
              <Typography className={styles.title} variant="h1">
                {company.name}
              </Typography>
              <RNPIcon isRNP={company.isRNP} />
            </div>
            <SubscribeRequestsButton
              companyId={company.id}
              isSubscribed={company.isSubscribed}
              onClick={toggleSubscribe}
            />
          </div>
          <Typography className={styles.fullName} variant="body1Reg">
            {company.fullName}
          </Typography>
        </div>
      </div>
      <Categories categories={company.categories} />
    </div>
  );
}
