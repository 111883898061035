// @flow
import { Map, fromJS } from 'immutable';

const changeStatus = (state, status) => state.set('status', fromJS(status));

const changeImportType = (state, value) => state.set('type', value);

const changeCurrency = (state, value) => state.set('currency', value);

const changeLanguage = (state, language) => state.set('language', language);

const cleanImportStatus = (state) => state.set('status', new Map());

const changeCatalogId = (state, id) => state.set('id', id);

const importReducer = (state = new Map(), action) => {
  switch (action.type) {
    case 'CATALOG_IMPORT_CHANGE_STATUS':
      return changeStatus(state, action.status);

    case 'CATALOG_IMPORT_CHANGE_ID':
      return changeCatalogId(state, action.id);

    case 'CATALOG_IMPORT_SET_ID':
      return changeCatalogId(state, action.id);

    case 'CATALOG_IMPORT_CHANGE_LANGUAGE':
      return changeLanguage(state, action.language);

    case 'CATALOG_IMPORT_CLEAN':
      return cleanImportStatus(state);

    case 'CATALOG_IMPORT_CHANGE_TYPE':
      return changeImportType(state, action.importType);

    case 'CATALOG_IMPORT_CHANGE_CURRENCY':
      return changeCurrency(state, action.currency);

    default:
      return state;
  }
};

export default importReducer;
