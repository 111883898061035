import * as storage from './storage';
import customHistory from '../../customHistory';
import * as chatWidgetUC from '../../components/ChatWidget/useCases/index.js';
import { setViewedRespond } from '../../components/PurchaseRequests/MyRequests/storage';

export const open =
  ({
    response,
    requestId = response?.attrs?.purchaseRequest?.id,
    isWidget = false
  }) =>
  async (dispatch) => {
    const channel = await storage.open(
      requestId,
      response.id,
      response.from,
      response.attrs.purchaseRequest.respondedUser
    );

    if (isWidget) {
      dispatch(setViewedRespond({ requestId, responseId: response.id }));
      dispatch(chatWidgetUC.toggleChatWidget());
      dispatch(chatWidgetUC.handleChatList('open-chat', channel));
    } else {
      customHistory.push(`/chat/${channel.id}`);
    }

    return channel;
  };
