import React from 'react';
import { Icon, Typography } from '@link/react-components';
import { useActions } from '@hooks';
import styles from './Preview.module.css';
import { useTranslate } from '../../../../TranslateProvider';
import { copy } from '../../../../lib/clipboard';
import * as notificationMessageActions from '../../../../action-creators/message';
import { CompanyAvatar } from '../../../../components/CompanyAvatar';

function RequisiteRow({ label, value, onCopy }) {
  return (
    <div className={styles.requisiteRow} onClick={onCopy}>
      <Typography className={styles.requisiteRowLabel}>{label}:</Typography>
      <Typography className={styles.requisiteRowValue}>{value}</Typography>
      <Icon iconName="copy" height={12} width={12} />
    </div>
  );
}

export function Preview({ img, inn, ogrn, kpp }) {
  const t = useTranslate();
  const { setSuccessMessage, setErrorMessage } = useActions(
    notificationMessageActions
  );

  const requisites = [
    {
      label: t('modules.company.card.inn'),
      value: inn
    },
    {
      label: t('modules.company.card.kpp'),
      value: kpp
    },
    {
      label: t('modules.company.card.ogrn'),
      value: ogrn
    }
  ];

  const onCopy = async (value) => {
    try {
      await copy(value);
      setSuccessMessage({
        key: 'notifications.successCopy'
      });
    } catch (e) {
      // Todo if need
      setErrorMessage({
        key: 'не получилось скопировать'
      });
    }
  };

  return (
    <div className={styles.preview}>
      <CompanyAvatar src={img} />
      <div className={styles.requisites}>
        {requisites.map((requisite, index) => (
          <RequisiteRow
            key={index}
            value={requisite.value}
            label={requisite.label}
            onCopy={() => onCopy(requisite.value)}
          />
        ))}
      </div>
    </div>
  );
}
