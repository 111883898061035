import React from 'react';
import block from 'bem-cn-lite';
import Icon from '../../common/components/Icon';
import TooltipList from './TooltipList';
import { getAllBadgesCountForSection, isActiveIcon } from '../../utils';
import { ICON_TYPE_LINK } from '../../constants';

const b = block('snm-short-section');

const ListOfSections = ({ navigationsList = [], activeIndex }) =>
  navigationsList.map((section, key) => (
    <li className={b()} key={key}>
      <Icon
        type={ICON_TYPE_LINK}
        icon={section.icon}
        to={section.to}
        target={section.target}
        isActive={isActiveIcon(activeIndex, key)}
        isShowBadge={getAllBadgesCountForSection(section) > 0}
        amountBadges={getAllBadgesCountForSection(section)}
      />
      <TooltipList section={section} index={key} activeIndex={activeIndex} />
    </li>
  ));

export default ListOfSections;
