import React from 'react';
import { isNil } from 'ramda';
import { usePreviousDistinct } from 'react-use';
import { useVideoChatContext } from '../../hooks/useVideoChatContext';
import { VIDEO_CHAT_MODS } from '../../hooks/useVideoChat';

const MODS = {
  On: 'on',
  Off: 'off'
};

async function hasCamPermission() {
  try {
    await navigator.mediaDevices.getUserMedia({ video: true, audio: false });
    return true;
  } catch (e) {
    return false;
  }
}

const ButtonCamera = () => {
  const { mode, toggleVideo, currentMember } = useVideoChatContext();
  const previousVideoChatMode = usePreviousDistinct(mode);

  const [camMode, setCamMode] = React.useState(MODS.On);
  const [disabled, setDisabled] = React.useState(false);

  React.useEffect(() => {
    if (previousVideoChatMode === VIDEO_CHAT_MODS.Close) {
      setCamMode(MODS.On);
    }
  }, [mode === VIDEO_CHAT_MODS.Open]);

  React.useEffect(() => {
    if (isNil(currentMember)) return;

    setCamMode(currentMember.isCameraMuted ? MODS.Off : MODS.On);
  }, [currentMember?.isCameraMuted]);

  React.useEffect(() => {
    if (mode === VIDEO_CHAT_MODS.Close) return;

    hasCamPermission().then((camPermission) => setDisabled(!camPermission));
  }, [currentMember?.isCameraMuted]);

  const toggleCamMode = () =>
    setCamMode((prev) => {
      const result = prev === MODS.On ? MODS.Off : MODS.On;
      toggleVideo(result === MODS.On);
      return result;
    });

  return (
    <button
      type="button"
      className={`video-chat__btn video-chat__btn-videocam video-chat__btn-videocam-${camMode}`}
      onClick={toggleCamMode}
      disabled={disabled}>
      <img
        className="video-chat__btn-img video-chat__btn-img_videocam_on"
        src="/img/videochat/videocam_on.svg"
        draggable={false}
        alt=""
      />
      <img
        className="video-chat__btn-img video-chat__btn-img_videocam_off"
        src="/img/videochat/videocam_off.svg"
        draggable={false}
        alt=""
      />
    </button>
  );
};

export default ButtonCamera;
