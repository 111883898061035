import React from 'react';
import block from 'bem-cn-lite';
import Section from '../../common/components/section';
import NestedList from './nestedList';
import {
  getNestActiveIndex,
  isActiveIcon,
  isActiveMainSection
} from '../../utils';

const b = block('snm-full-section');

const ListOfSections = ({ navigationsList = [], activeIndex }) =>
  navigationsList.map((section, key) => (
    <li className={b()} key={key}>
      <Section
        section={section}
        isMain
        isShowIcon
        isActive={isActiveMainSection(activeIndex, key)}
        isActiveIcon={isActiveIcon(activeIndex, key)}
      />
      <NestedList
        listOfSection={section.nestedList}
        activeIndex={getNestActiveIndex(activeIndex, key)}
      />
    </li>
  ));

export default ListOfSections;
