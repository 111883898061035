const PURCHASE_REQUESTS_CHANNEL = 'PurchaseRequestsChannel';
const PURCHASE_REQUESTS_MESSAGE_TYPE = 'MsgPurchaseRequest';

exports.PURCHASE_REQUESTS_CHANNEL = PURCHASE_REQUESTS_CHANNEL;
exports.PURCHASE_REQUESTS_MESSAGE_TYPE = PURCHASE_REQUESTS_MESSAGE_TYPE;

exports.isPurchaseRequestChannel = (channel = {}) => {
  return PURCHASE_REQUESTS_CHANNEL === channel.type;
};

exports.isPurchaseRequestMessage = (message = {}) => {
  return PURCHASE_REQUESTS_MESSAGE_TYPE === message.type;
};
