import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import UserSelect, { Creatable } from 'react-select';
import { fromJS, List } from 'immutable';

import {
  Col,
  ControlLabel,
  Form,
  FormControl,
  FormGroup,
  HelpBlock,
  Row
} from 'react-bootstrap';
import ComplexMultiSelect from 'react-components/src/components/ComplexMultiSelect';
import { isEmpty } from 'ramda';
import { Typeahead } from '@link/react-components';
import { withRouter } from '../../../../hoc/withRouter';
import { useTranslate } from '../../../../TranslateProvider';
import { InputText } from '../../../FormControls';

import * as requestsActions from '../../../../action-creators/purchaseRequests';

import { validDate } from '../../../../utils/date';
import keyDebounce from '../../../../utils/debounce';
import * as dictionariesGetters from '../../../../modules/dictionaries/getters';
import * as dictionariesStorage from '../../../../modules/dictionaries/storage';
import ErrorNotice from './ErrorNotice';
import SectionOffset from '../../Request/SectionOffset';
import CategoriesSelect from '../../../CategoriesSelect';
import { formatRequestNumber } from '../../../../utils/utils';
import CheckboxWithLabel from '../../../CheckboxWithLabel';
import { initTranslateComponent } from '../../../../utils/translateComponent';
import { useSearchCompanies } from '../../../../modules/dictionaries/hooks';
import '../style.css';
import { Popover } from './Popover';
import { MainFormDatePicker } from './MainFormDatePicker';
import { InteractiveHelper } from '../../../InteractiveHelper';
import { useChooseSupplierWizard } from '../../../Wizards';

const MIN_TITLE_LENGTH = 4;
const MAX_POSSIBLE_SUPPLIERS_COUNT = 20;
const MAX_VISIBLE_SUPPLIERS_COUNT_IN_SELECT = 9;

const MainForm = ({
  request = {},
  responsibles,
  cleanRegion,
  cleanCities,
  getCountries,
  getRegions,
  getCities,
  countries,
  getRequestResponsibles,
  getCategories,
  user,
  regions,
  cities,
  categoriesList,
  isErrorShown,
  body,
  setBody,
  typeaheadRef,
  typeaheadTitleKey
}) => {
  const {
    categoriesId,
    title,
    country,
    region,
    city,
    supplierRequirements,
    responseEndDate,
    responsibleUser,
    number,
    accessCompanyList,
    isShowForAllCompanies
  } = body;

  const {
    setCategoriesId,
    setTitle,
    setCountry,
    setRegion,
    setCity,
    setSupplierRequirements,
    setResponseEndDate,
    setResponsibleUser,
    setNumber,
    setAccessCompanyList,
    onToggleShowForAllCompanies,
    setAuthorId
  } = setBody;

  const t = useTranslate();
  const translate = initTranslateComponent(t, 'purchaseRequestForm');
  const [id, setId] = useState(formatRequestNumber(request.id));

  const companies = useSearchCompanies({ includesGroups: true });

  const { open } = useChooseSupplierWizard();

  const openSelectCompanyModal = open;

  const today = new Date();
  const TOMORROW = new Date(today.setDate(today.getDate() + 1)).toISOString();

  const getChosenRegions = (currentRegion) =>
    region ? [{ value: currentRegion || region }] : [];

  useEffect(() => {
    (async function getNumber() {
      if (!request.id) {
        const requestId = await requestsActions.getRequestNumber();
        const formatNumber = formatRequestNumber(requestId);

        if (!number) {
          setNumber(formatNumber);
        }
        setId(formatNumber);
      }
    })();
    setAuthorId(user.toJS().id);
  }, []);
  useEffect(() => {
    getCountries();
    getRequestResponsibles();
    getCategories();

    const defaultLocation = (localStorage.getItem('pr-location') || '').split(
      ';'
    );
    const defaultCountry = country || defaultLocation[0];
    const defaultRegion = region || defaultLocation[1];
    const defaultCity = city || defaultLocation[2];

    if (defaultCountry) {
      setCountry(defaultCountry);
      if (defaultRegion) {
        getRegions(defaultCountry, null, defaultRegion, user.get('language'));
        setRegion(defaultRegion);

        if (defaultCity) {
          getCities(
            defaultCountry,
            getChosenRegions(defaultRegion),
            null,
            defaultCity,
            user.get('language')
          );
          setCity(defaultCity);
        }
      }
    }
  }, [country]);

  useEffect(() => {
    const defaultLocation = localStorage.getItem('pr-location');
    if (
      !request.reqId &&
      countries &&
      countries.size > 0 &&
      !country &&
      !defaultLocation
    ) {
      const defaultCountry = countries.find((i) => i.get('default'));

      if (defaultCountry) {
        setCountry(defaultCountry.get('id'));
      }
    }
  }, [countries]);

  useEffect(() => {
    if (!request.id && responsibles && responsibles.size > 0) {
      const currentUser = responsibles.find(
        (i) => i.get('id') === user.get('id')
      );
      if (currentUser) {
        setResponsibleUser(Number(currentUser.get('id')));
      }
    }
  }, [responsibles]);

  const isTitleValid = () => title.trim().length >= MIN_TITLE_LENGTH;

  const onChangeCountry = (val) => {
    if (!val) {
      return;
    }

    const value = typeof val === 'string' ? val : val.value;
    const newCountry = value === 'null' ? null : value;

    setCountry(newCountry);
    setCity(null);
    setRegion(null);

    cleanRegion();
    cleanCities();
  };

  const onChangeRegion = (val) => {
    if (!val) {
      return;
    }

    const value = typeof val === 'string' ? val : val.value;
    const newRegion = value === 'null' ? null : value;

    setRegion(newRegion);
    setCity(null);

    if (!newRegion) {
      cleanRegion();
      cleanCities();
    }
  };

  const onChangeCity = (val) => {
    if (!val) {
      return;
    }

    const value = typeof val === 'string' ? val : val.value;
    const newCity = value === 'null' ? null : value;

    setCity(newCity);

    if (!newCity) {
      cleanCities();
    }
  };

  const onCountrySearch = keyDebounce((value) => {
    if (value) {
      getCountries(value);
    }
  }, 500);

  const onRegionSearch = keyDebounce((value) => {
    if (value) {
      getRegions(country, value);
    }
  }, 500);

  const onCitySearch = keyDebounce((value) => {
    if (value) {
      getCities(country, getChosenRegions(), value);
    }
  }, 500);

  const getLocationsSelectList = (items, type) => {
    let itemsList = items;

    if (type !== 'country') {
      itemsList = itemsList.unshift(
        fromJS({ id: null, name: t('Not_selected') })
      );
    }
    return itemsList
      .map((i) => ({
        value: `${i.get('id')}`,
        label: i.get('name')
      }))
      .toJS();
  };

  const onChange =
    (setState) =>
    ({ target: { value } }) =>
      setState(value);

  const handleChangeDate = (seState) => (value) => {
    const tomorrow = new Date(today.setDate(today.getDate() + 1));
    const newDate = new Date(value);

    if (!validDate(tomorrow, newDate)) {
      seState(TOMORROW);
    } else {
      seState(value);
    }
  };

  const respn = responsibles || List();

  const employeesSelectList = respn
    .map((i) => ({
      value: i.get('id'),
      label: `${i.get('lastName')} ${i.get('firstName')}`
    }))
    .toJS();

  const requiredField = (
    <HelpBlock style={{ marginBottom: 0 }}>{t('Required fields')}</HelpBlock>
  );

  const onKeyDownCountry = (event) => {
    if (event.keyCode === 13) {
      onChangeCountry(event.target.value);
    }
  };

  const onKeyDownRegion = (event) => {
    if (region && region !== event.target.value && event.keyCode !== 9) {
      setRegion(null);
      cleanRegion();
      cleanCities();
    }
    if (event.keyCode === 13) {
      onChangeRegion(event.target.value);
    }
  };

  const onKeyDownCity = (event) => {
    if (city && city !== event.target.value && event.keyCode !== 9) {
      setCity(null);
      cleanCities();
    }
    if (event.keyCode === 13) {
      onChangeCity(event.target.value);
    }
  };

  const onBlur = (value, callback) => () => {
    if (!value) {
      callback();
    }
  };

  return (
    <div className="form-content-center">
      <div style={{ width: '100%', minWidth: 830, maxWidth: 1200 }}>
        <Form className="new-request-form" data-testid="new-request-form">
          <div className="new-request-title">{t('main_information')}</div>
          <ControlLabel
            className="control-label-row"
            id="title-form"
            data-testid="new-request-number-label">
            {t('request_number')}
            <span style={{ color: 'red', paddingLeft: 1 }}>*</span>
            &nbsp;
            <InteractiveHelper>
              <Popover text={t('purchase_request_number_helper')} />
            </InteractiveHelper>
          </ControlLabel>
          <FormGroup
            controlId="title"
            data-testid="new-request-number-from-to-group"
            validationState={
              isErrorShown.publish && number.trim().length === 0
                ? 'error'
                : null
            }>
            <Row>
              <Col xs={3}>
                <FormControl
                  data-testid="new-request-number-from"
                  placeholder={t('enter_value')}
                  maxLength="8"
                  value={id}
                  readOnly
                />
              </Col>
              <Col
                xs={3}
                style={{ width: 10, padding: '7px 0 0 0', color: '#767B92' }}>
                <div>—</div>
              </Col>

              <Col xs={3}>
                <FormControl
                  type="text"
                  data-testid="new-request-number-to"
                  placeholder={t('enter_value')}
                  maxLength="12"
                  value={number}
                  onChange={onChange(setNumber)}
                />
              </Col>
            </Row>
            {isErrorShown.publish &&
              number.trim().length === 0 &&
              requiredField}
          </FormGroup>
          <SectionOffset offset={13} />
          <ControlLabel
            data-testid="new-request-name-label"
            className="control-label-row"
            id="title-form">
            {`${t('purchase_request_title')} `}
            <span style={{ color: 'red', paddingLeft: 1 }}>*</span>
            &nbsp;
            <InteractiveHelper>
              <Popover text={t('purchase_request_title_helper')} />
            </InteractiveHelper>
          </ControlLabel>
          <h6 className="pull-right" style={{ marginTop: 5 }}>
            <small data-testid="new-request-title-length">
              {title.length}
              /100
            </small>
          </h6>
          <FormGroup
            controlId="title"
            data-testid="new-request-name-input-group"
            validationState={
              (isErrorShown.save || isErrorShown.publish) && !isTitleValid()
                ? 'error'
                : null
            }>
            <Typeahead
              className="new-request-name"
              store={typeaheadTitleKey}
              ref={typeaheadRef}
              placeholder={t('purchase_request_title_placeholder')}
              maxLength={100}
              data-testid="new-request-name-input"
              onChange={setTitle}
              initialValue={title}
            />
            {(isErrorShown.save || isErrorShown.publish) && !isTitleValid() && (
              <HelpBlock
                data-testid="new-request-name-input-help-block"
                style={{ marginBottom: 0 }}>
                {t('min_length_x_symbols2', { min: MIN_TITLE_LENGTH })}
              </HelpBlock>
            )}
          </FormGroup>

          <SectionOffset offset={13} />

          <ControlLabel
            className="control-label-row"
            data-testid="new-request-category-label">
            {`${t('categories_of_goods_and_services')} `}
            <span style={{ color: 'red' }}>*</span>
          </ControlLabel>
          <ErrorNotice
            isError={isErrorShown.publish && !categoriesId.length}
            data-testid="new-request-category-label-error-notice">
            <CategoriesSelect
              selectedList={categoriesId}
              list={categoriesList}
              onChoose={setCategoriesId}
            />
          </ErrorNotice>
          {isErrorShown.publish && !categoriesId.length && requiredField}

          <SectionOffset offset={28} />

          <Row>
            <Col xs={3} style={{ marginRight: 10 }}>
              <ControlLabel
                className="control-label-row"
                data-testid="new-request-delivery-end-date-label">
                {`${t('Purchase response end date')} `}
                <span style={{ color: 'red', marginRight: 4 }}>*</span>
                <InteractiveHelper>
                  <Popover text={t('req_end_date_helper')} />
                </InteractiveHelper>
              </ControlLabel>
              <ErrorNotice
                data-testid="new-request-delivery-end-date-error"
                isError={isErrorShown.publish && !responseEndDate}>
                <MainFormDatePicker
                  lang={user.get('language')}
                  weekStartsOn={user.get('language') === 'en' ? 0 : 1}
                  value={responseEndDate}
                  onChange={handleChangeDate(setResponseEndDate)}
                  calendarPlacement="bottom"
                  dateFormat="DD/MM/YYYY"
                  minDate={TOMORROW}
                  dataTestId="new-request-delivery-end-date-picker"
                />
              </ErrorNotice>
              {isErrorShown.publish && !responseEndDate && requiredField}
            </Col>
          </Row>

          <SectionOffset offset={28} />

          <ControlLabel
            className="control-label-row"
            data-testid="new-request-responsible">
            {`${t('Responsible for request')} `}
            <span style={{ color: 'red' }}>*</span>
            &nbsp;
            <InteractiveHelper>
              <Popover
                type="RESPONSIBLE"
                text={t('req_helper')}
                link={t('req_helper_link')}
              />
            </InteractiveHelper>
          </ControlLabel>
          <UserSelect
            data-testid="new-request-responsible-user-select"
            id="responsible-select"
            value={responsibleUser}
            onChange={({ value }) => setResponsibleUser(Number(value))}
            options={employeesSelectList}
            placeholder={t('Not_selected')}
            clearable={false}
            searchable={false}
          />

          <SectionOffset offset={28} />
          <ControlLabel
            className="control-label-row"
            data-testid="new-request-delivery-country-label">
            {`${t('Delivery country')} `}
            <span style={{ color: 'red' }}>*</span>
          </ControlLabel>
          <ErrorNotice
            data-testid="new-request-error-delivery-country"
            isError={isErrorShown.publish && !country}>
            <Creatable
              data-testid="new-request-choose-delivery-country"
              id="country-select"
              value={country}
              onChange={(value) => onChangeCountry(value)}
              onInputChange={(value) => onCountrySearch(value)}
              onInputKeyDown={onKeyDownCountry}
              options={getLocationsSelectList(countries, country)}
              clearable={false}
              promptTextCreator={(input) => t('Create option', { input })}
              placeholder={t('add_country')}
              noResultsText={t('noResultsText')}
              style={{ color: 'grey' }}
            />
          </ErrorNotice>
          {isErrorShown.publish && !country && requiredField}
          <SectionOffset offset={28} />
          {country && (
            <>
              <ControlLabel
                className="control-label-row"
                data-testid="new-request-region-label">
                {`${t('Delivery region')} `}
                <span style={{ color: 'red' }}>*</span>
              </ControlLabel>
              <ErrorNotice
                data-testid="new-request-region-error"
                isError={isErrorShown.publish && !region}>
                <Creatable
                  data-testid="new-request-choose-region"
                  id="region-select"
                  value={region || null}
                  onChange={(value) => onChangeRegion(value)}
                  onInputChange={(value) => onRegionSearch(value)}
                  onInputKeyDown={onKeyDownRegion}
                  onBlur={onBlur(region, cleanRegion)}
                  options={getLocationsSelectList(regions, region)}
                  clearable={false}
                  promptTextCreator={(input) => t('Create option', { input })}
                  placeholder={t('add_region')}
                  noResultsText={t('noResultsText')}
                  style={{ color: 'grey' }}
                />
              </ErrorNotice>
              {isErrorShown.publish && !region && requiredField}
              <SectionOffset offset={28} />
            </>
          )}
          {country && region && (
            <>
              <Row>
                <Col xs={12}>
                  <ControlLabel
                    data-testid="new-request-choose-city"
                    className="control-label-row">
                    {t('Delivery city/locality')}
                  </ControlLabel>
                  <Creatable
                    data-testid="new-request-choose-city"
                    id="city-select"
                    value={city || null}
                    onChange={(value) => onChangeCity(value)}
                    onInputChange={(value) => onCitySearch(value)}
                    onInputKeyDown={onKeyDownCity}
                    onBlur={onBlur(city, cleanCities)}
                    options={getLocationsSelectList(cities, city)}
                    clearable={false}
                    promptTextCreator={(input) => t('Create option', { input })}
                    placeholder={t('add_city')}
                    noResultsText={t('noResultsText')}
                    style={{ color: 'grey' }}
                    autoComplete="new-password"
                  />
                </Col>
              </Row>
              <SectionOffset offset={28} />
            </>
          )}
          <ControlLabel
            className="control-label-row"
            data-testid="new-request-requirements-label">
            {t('Supplier requirements')}
          </ControlLabel>
          <h6 className="pull-right" style={{ marginTop: 5 }}>
            <small data-testid="new-request-requirements-length">
              {supplierRequirements.length}
              /255
            </small>
          </h6>
          <InputText
            data-testid="new-request-fill-requirements-input"
            placeholder={t('fill_requirements')}
            value={supplierRequirements}
            maxLength="255"
            onChange={onChange(setSupplierRequirements)}
          />
          <SectionOffset offset={28} />

          <CheckboxWithLabel
            dataTestid="new-request-access-company-checkbox-test"
            value={isShowForAllCompanies}
            onClick={onToggleShowForAllCompanies}
            label={translate('accessCompanyCheckboxLabel')}
            labelWeight={3}
          />
          <SectionOffset offset={16} />

          <ControlLabel
            data-testid="new-request-access-company-list-label"
            className="control-label-row">
            {`${translate('accessCompanyListSelect.label')} `}
          </ControlLabel>
          <ComplexMultiSelect
            dataTestid="new-request-complex-multi-select-test"
            t={t}
            selectedList={accessCompanyList}
            list={companies}
            onChoose={setAccessCompanyList}
            maxCount={MAX_POSSIBLE_SUPPLIERS_COUNT}
            showLimit={MAX_VISIBLE_SUPPLIERS_COUNT_IN_SELECT}
            onClickByIcon={openSelectCompanyModal}
            disabled={isShowForAllCompanies}
            noResultPlaceholder={translate('accessCompanyListSelect.noResults')}
            inputPlaceholder={
              isEmpty(accessCompanyList)
                ? translate('accessCompanyListSelect.emptySelectPlaceholder')
                : translate('accessCompanyListSelect.selectPlaceholder')
            }
          />
          {isErrorShown.publish && !categoriesId.length && requiredField}
        </Form>
      </div>
    </div>
  );
};

export default withRouter(
  connect(
    (state) => ({
      user: state.getIn(['user', 'user']),
      responsibles: state.getIn(['purchaseRequests', 'responsibles']),
      countries: state.getIn(['purchaseRequests', 'countries']),
      regions: state.getIn(['purchaseRequests', 'regions']),
      cities: state.getIn(['purchaseRequests', 'cities']),
      categoriesList: dictionariesGetters.getPurchaseRequestsCategories(state)
    }),
    (dispatch) =>
      bindActionCreators(
        {
          getRequestResponsibles: requestsActions.getRequestResponsibles,
          getCountries: requestsActions.getCountries,
          getRegions: requestsActions.getRegions,
          getCities: requestsActions.getCities,
          cleanRegion: requestsActions.cleanRegion,
          cleanCities: requestsActions.cleanCities,
          getCategories: dictionariesStorage.getPurchaseRequestsCategories
        },
        dispatch
      )
  )(MainForm)
);
