import React from 'react';
import CustomAvatar from '../../../../Avatar';
import {
  isChannel,
  isDialog,
  isNewsFeedChannel,
  isPurchaseRequestsChannel,
  isResponseChat,
  isTopic
} from '../../../../helpers/chatType';

const Avatar = ({ chat, color, name, size, interlocutor }) => {
  if (isDialog(chat)) {
    return (
      <CustomAvatar
        size={size}
        info={{
          type: 'user',
          src: interlocutor.avatarSrc,
          alt: interlocutor.userName,
          employeeId: interlocutor.employeeId
        }}
        isCursorPointer
      />
    );
  }
  if (isResponseChat(chat)) {
    return (
      <CustomAvatar
        size={size}
        info={{ type: 'color-icon', color: chat.color, icon: 'response' }}
        isCursorPointer
      />
    );
  }
  if (isChannel(chat) || isTopic(chat)) {
    return (
      <CustomAvatar
        size={size}
        info={{ type: 'color-char', color, text: name }}
        isCursorPointer
      />
    );
  }
  if (isPurchaseRequestsChannel(chat)) {
    return (
      <CustomAvatar
        size={size}
        info={{ type: 'color-avatar', subType: 'request-channel' }}
        isCursorPointer
      />
    );
  }
  if (isNewsFeedChannel(chat)) {
    return (
      <CustomAvatar
        size={size}
        info={{ type: 'color-avatar', subType: 'news-feed-channel' }}
        isCursorPointer
      />
    );
  }
  return <div />;
};

export default Avatar;
