import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
// import block from 'bem-cn-lite';
import { translate } from 'react-i18next';
import { Modal, Form } from 'react-bootstrap';
import './styles/modal_theme_default.styl';
import Table from '../TableNew';

import { ModalContainer } from './elements';
import * as modalActions from '../../action-creators/modal';
import * as orderActions from '../../action-creators/orders';
import { formatDate } from '../../utils/date';
import { generateUrlToOrderHistory } from '../../modules/orderHistory/uitls';

// const b = block('modal-container');

class ModalOrderStatusHistory extends Component {
  constructor(props) {
    super(props);
    this.hideModal = this.hideModal.bind(this);
  }

  UNSAFE_componentWillMount() {
    const { order, getOrderStatusHistory } = this.props;
    getOrderStatusHistory(order.get('id'));
  }

  get permissionToView() {
    const { from, havePermissionSupplier, havePermissionCustomer } = this.props;

    const havePermission = {
      supplier: havePermissionSupplier,
      customer: havePermissionCustomer
    };

    return havePermission[from];
  }

  hideModal() {
    this.props.hideModalDialog();
  }

  render() {
    const { t, statusHistory, from } = this.props;

    return (
      <Modal
        id="orderStatusHistory"
        onHide={this.hideModal}
        show
        className="wide-modal">
        <ModalContainer mods={{ type: 'default', theme: 'default' }}>
          <Modal.Header>
            <Modal.Title>
              {t('Order status history')}
              <span
                className="modal-header-cross pull-right"
                onClick={() => this.hideModal()}
              />
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {statusHistory && (
              <Form>
                <Table
                  newVersion
                  columns={[
                    Table.ColStatus({
                      accessor: 'status',
                      title: t('Catalogue status'),
                      width: '15%'
                    }),
                    Table.ColLink({
                      accessor: ({ createdAt }) => formatDate(createdAt),
                      url: generateUrlToOrderHistory(
                        from,
                        this.permissionToView
                      ),
                      title: t('Date'),
                      width: '15%'
                    }),
                    Table.ColText({
                      accessor: 'companyInfo.name',
                      title: t('Company'),
                      width: '15%'
                    }),
                    Table.ColText({
                      accessor: ({ userInfo: { firstName, lastName } }) =>
                        `${firstName} ${lastName}`,
                      title: t('Employee'),
                      width: '15%'
                    })
                  ]}
                  data={statusHistory}
                />
              </Form>
            )}
          </Modal.Body>
        </ModalContainer>
      </Modal>
    );
  }
}

ModalOrderStatusHistory.propTypes = {
  t: PropTypes.func.isRequired
};

ModalOrderStatusHistory.childContextTypes = {
  b: PropTypes.func
};

export default connect(
  (state) => ({
    statusHistory: state.getIn(['orders', 'order', 'statusHistory']),
    havePermissionSupplier: state.getIn([
      'user',
      'user',
      'permissions',
      'supplierOrders',
      'update'
    ]),
    havePermissionCustomer: state.getIn([
      'user',
      'user',
      'permissions',
      'customerOrders',
      'update'
    ])
  }),
  (dispatch) =>
    bindActionCreators(
      {
        showModal: modalActions.showModal,
        hideModalDialog: modalActions.hideModalDialog,
        getOrderStatusHistory: orderActions.getOrderStatusHistory
      },
      dispatch
    )
)(translate(['ui'], { wait: true })(ModalOrderStatusHistory));
