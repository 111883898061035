import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useIsomorphicLayoutEffect } from 'react-use';
import { update, append } from 'ramda';
import * as videoRoomPeerMSG from 'unicat-api/src/services/videoRoom/peer/message';
import * as modalActions from '../../../action-creators/modal';
import { emitToServer } from '../../../storage/message';
import { Peer } from '../lib/peer';
import { Media } from '../lib/media';
import { getFullName } from '../../../utils/utils';
import { sendJoinedToRoom, sendLeaveRoom } from '../commandsAndEvents';
import { showTooltip } from '../../../components/ChatWidget/Tooltip/message';
import * as storeGetters from '../../../storeGetters';
import { isAudioTrackMuted, isVideoTrackMuted } from '../utils';
import { useTranscript } from './useTranscript';
import { SidebarMods } from '../components/Sidebar/mods';

export const VIDEO_CHAT_MODS = {
  Open: 'open',
  Close: 'close',
  Hide: 'hide'
};

export function useVideoChat(config = {}) {
  const dispatch = useDispatch();
  const [mode, setMode] = React.useState(VIDEO_CHAT_MODS.Close);
  const [chatId, setChatId] = React.useState(null);
  const [roomId, setRoomId] = React.useState(null);
  const [roomChatConnectionsMap, setRoomChatConnectionsMap] = React.useState(
    new Map()
  );
  const [sidebarMode, setSidebarMode] = React.useState(SidebarMods.None);
  const [adminsList, setAdminsList] = React.useState(new Set());

  const currentUser = useSelector(storeGetters.getCurrentUser);

  const myPeerRef = React.useRef(null);
  const myMediaRef = React.useRef(null);
  const [videoChats, setVideoChats] = React.useState([]);
  const myPeerId = myPeerRef.current?.id;

  const transcript = useTranscript(
    roomId,
    myPeerRef.current?.currentMember,
    getCurrentMember()?.isMicrophoneMuted
  );

  useIsomorphicLayoutEffect(() => {
    if (!myMediaRef.current) {
      myMediaRef.current = new Media({ config: config.video });
    }

    if (!myPeerRef.current) {
      myPeerRef.current = initializePeer();
    }

    return () => {
      if (myMediaRef.current) {
        myMediaRef.current.endStream();
      }

      if (myPeerRef.current) {
        myPeerRef.current.endCall();
      }
    };
  }, []);

  const showWindow = React.useCallback(() => {
    setMode(VIDEO_CHAT_MODS.Open);
  }, []);

  useIsomorphicLayoutEffect(() => {
    if (!myPeerRef.current) return;

    myPeerRef.current.currentMember = makeMember(currentUser);
  }, [currentUser, myPeerRef.current?.id]);

  function initializePeer() {
    return new Peer({
      config: config.peerConfig,
      onMuteVideo,
      onUnmuteVideo,
      onMuteAudio,
      onUnmuteAudio,
      currentMember: makeMember(currentUser)
    });

    function onMuteVideo(peerId) {
      setVideoChats((prevState) =>
        updateListByPeerId(prevState, peerId, { isCameraMuted: true })
      );
    }

    function onUnmuteVideo(peerId) {
      setVideoChats((prevState) =>
        updateListByPeerId(prevState, peerId, { isCameraMuted: false })
      );
    }

    function onMuteAudio(peerId) {
      setVideoChats((prevState) =>
        updateListByPeerId(prevState, peerId, { isMicrophoneMuted: true })
      );
    }

    function onUnmuteAudio(peerId) {
      setVideoChats((prevState) =>
        updateListByPeerId(prevState, peerId, { isMicrophoneMuted: false })
      );
    }
  }

  const addRoomChatConnection = React.useCallback(
    ({ roomId: _roomId, chatId: _chatId }) => {
      setRoomChatConnectionsMap((prevState) => prevState.set(_roomId, _chatId));
    },
    []
  );

  const hideWindow = React.useCallback(() => {
    setMode(VIDEO_CHAT_MODS.Hide);
    showTooltip('return-to-video-call');
  }, []);

  const closeWindow = React.useCallback(() => {
    setMode(VIDEO_CHAT_MODS.Close);
  }, []);

  const getMyPeer = React.useCallback(() => myPeerRef.current, [myPeerRef]);

  const joinInChat = React.useCallback(
    async ({ roomId: _roomId }) => {
      if (myPeerRef.current?.myPeer?.disconnected) {
        try {
          await myPeerRef.current.myPeer.reconnect();
        } catch (reason) {
          console.log('Reconnect to peer failure', reason);
          return;
        }
      }

      subscribeMembers({ roomId: _roomId, peerId: myPeerRef.current.id });

      myPeerRef.current.onIncomingCall();
      myPeerRef.current.onStartRemoteStream = (
        remoteVAStream,
        remotePeerId,
        call
      ) => {
        const newMember = call.metadata.initiator;
        const label = newMember ? getFullName(newMember) : '';
        const avatarSrc = newMember ? newMember.avatarSrc : '';

        setVideoChats((prevState) =>
          addOrUpdateListByPeerId(prevState, remotePeerId, {
            peerId: remotePeerId,
            stream: remoteVAStream,
            isCameraMuted: isVideoTrackMuted(remoteVAStream),
            isMicrophoneMuted: isAudioTrackMuted(remoteVAStream),
            label,
            avatarSrc
          })
        );
      };
      // NOTE: Удалить после проверки на тесте отключение/включение камеры
      myPeerRef.current.onUpdateRemoteStream = (
        remoteVAStream,
        id,
        currentRs
      ) => {
        console.log(
          `remote stream with id = ${id} was updated, currentRS is ${currentRs}`
        );
      };
      myPeerRef.current.onUpdateMember = (member) => {
        setVideoChats((prevState) =>
          addOrUpdateListByPeerId(prevState, member.peerId, {
            ...member,
            label: getFullName(member)
          })
        );
      };
      myPeerRef.current.onLeaveMember = (peerId) => {
        setVideoChats((prevState) => removeFromListByPeerId(prevState, peerId));
      };
      myPeerRef.current.onCloseCall = (peerId) => {
        unsubscribeMembers({ roomId: _roomId, peerId });
        closeWindow();
        sendLeaveRoom(_roomId);
        myMediaRef.current.endStream();
      };
      myMediaRef.current.onUpdateTrack = myPeerRef.current.replaceVATrack;
    },
    [videoChats, myMediaRef]
  );

  const startCall = React.useCallback(
    async ({ currentEmployeeId, roomId: _roomId, members: membersIds }) => {
      if (myPeerRef.current?.myPeer?.disconnected) {
        try {
          await myPeerRef.current.myPeer.reconnect();
        } catch (reason) {
          console.log('Reconnect to peer failure', reason);
          return;
        }
      }

      const peerId = myPeerRef.current.id;
      setRoomId(_roomId);
      setChatId(roomChatConnectionsMap.get(_roomId));
      showWindow();
      const stream = await myMediaRef.current.startStream();
      const currentMember = makeMember(currentUser);

      setVideoChats((prevState) =>
        addOrUpdateListByPeerId(prevState, peerId, {
          type: 'my-stream',
          peerId,
          stream,
          employeeId: currentEmployeeId,
          isCameraMuted: isVideoTrackMuted(stream),
          isMicrophoneMuted: isAudioTrackMuted(stream),
          label: getFullName(currentMember),
          avatarSrc: currentMember.avatarSrc
        })
      );

      myPeerRef.current.setMyVAStream(stream);
      myPeerRef.current.callMany(membersIds);

      sendJoinedToRoom({
        roomId: _roomId,
        employeeId: currentEmployeeId,
        peerId
      });
    },
    [videoChats, currentUser, roomChatConnectionsMap]
  );

  const endCall = React.useCallback(
    ({ onSubmited } = {}) => {
      dispatch(
        modalActions.showModal('SIMPLE_SUBMIT', {
          title: 'videoChat.modals.confirmCloseVideoChat.text',
          textBtnConfirm: 'videoChat.modals.confirmCloseVideoChat.confirmBtn',
          textBtnCancel: 'videoChat.modals.confirmCloseVideoChat.cancelBtn',
          submitAction: () => {
            myMediaRef.current.endStream();
            myPeerRef.current.endCall();
            setVideoChats([]);
            setChatId(null);
            setRoomId(null);
            setRoomChatConnectionsMap(new Map());
            setAdminsList(new Set());
            setSidebarMode(SidebarMods.None);
            closeWindow();
            myPeerRef.current = initializePeer();
          },
          onSubmited
        })
      );
    },
    [videoChats]
  );

  const toggleVideo = React.useCallback(
    (isEnabled) => {
      if (!myMediaRef.current) {
        console.error(`Toggle video failed because ref not found`);
        return;
      }

      myMediaRef.current.toggleVideo(isEnabled);

      setVideoChats((prevState) =>
        updateListByPeerId(prevState, myPeerRef.current.id, {
          isCameraMuted: !isEnabled
        })
      );
    },
    [myPeerRef, myMediaRef]
  );

  const toggleAudio = React.useCallback(
    (isEnabled) => {
      if (!myMediaRef.current) {
        console.error(`Toggle audio failed because ref not found`);
        return;
      }

      myMediaRef.current.toggleAudio(isEnabled);

      setVideoChats((prevState) =>
        updateListByPeerId(prevState, myPeerRef.current.id, {
          isMicrophoneMuted: !isEnabled
        })
      );
    },
    [myPeerRef, myMediaRef]
  );

  const onLeftMember = React.useCallback(
    ({ member }) => {
      setVideoChats((prevState) =>
        removeFromListByPeerId(prevState, member.peerId)
      );
    },
    [videoChats]
  );

  const closeSidebar = React.useCallback(() => {
    setSidebarMode(SidebarMods.None);
  }, []);

  const changeSidebarMode = React.useCallback(
    (nextMode) => {
      if (nextMode === sidebarMode) {
        closeSidebar();
        return;
      }

      setSidebarMode(nextMode);
    },
    [sidebarMode]
  );

  const addAdmin = React.useCallback((member) => {
    if (member.isOwner) {
      setAdminsList((prevAdminsList) => prevAdminsList.add(member.employeeId));
    }
  }, []);

  function checkIsCurrentMemberAdmin() {
    return adminsList.has(currentUser.get('employeeId'));
  }

  function getCurrentMember() {
    return videoChats.find(
      (member) => member.employeeId === currentUser.get('employeeId')
    );
  }

  return {
    mode,
    chatId,
    roomId,
    roomChatConnectionsMap,
    addRoomChatConnection,
    showWindow,
    hideWindow,
    videoChats,
    getMyPeer,
    myPeerId,
    joinInChat,
    startCall,
    endCall,
    toggleVideo,
    toggleAudio,
    onLeftMember,
    transcript,
    sidebarMode,
    changeSidebarMode,
    closeSidebar,
    addAdmin,
    currentMember: getCurrentMember(),
    isCurrentMemberAdmin: checkIsCurrentMemberAdmin()
  };
}

function subscribeMembers({ roomId, peerId }) {
  emitToServer(videoRoomPeerMSG.videoRoomPeerQRY.sync, { roomId, peerId });
}

function unsubscribeMembers({ roomId, peerId }) {
  emitToServer(videoRoomPeerMSG.videoRoomPeerQRY.syncOff, { roomId, peerId });
}

function makeMember(memberData) {
  return {
    employeeId: memberData.get('employeeId'),
    firstName: memberData.get('firstName'),
    lastName: memberData.get('lastName'),
    middleName: memberData.get('middleName'),
    avatarSrc: memberData.get('avatar')
  };
}

function addOrUpdateListByPeerId(list = [], peerId, newData) {
  const index = list.findIndex((i) => i.peerId === peerId);

  if (index !== -1) {
    return update(index, { ...list[index], ...newData }, list);
  }

  return append(newData, list);
}

function updateListByPeerId(list = [], peerId, newData) {
  const index = list.findIndex((i) => i.peerId === peerId);

  if (index !== -1) {
    return update(index, { ...list[index], ...newData }, list);
  }

  return list;
}

function removeFromListByPeerId(list = [], peerId) {
  return list.filter((i) => i.peerId !== peerId);
}
