import URLSearchParams from 'url-search-params';
import request from '../lib/request';
import {
  ResponsePartnersCompanyDto,
  ResponseInvitePartnersCompanyDto
} from '../DTOs';

class PartnersCompanyService {
  #baseUrl = '/api/company-partners';

  async getAll({ offset = 0, limit = 20, search = '' } = {}) {
    const urlSearchParams = new URLSearchParams({ offset, limit, search });

    const result = await request.get(
      `${this.#baseUrl}/list?${urlSearchParams}`
    );

    return result.map((partner) => new ResponsePartnersCompanyDto(partner));
  }

  async invitePartner(email) {
    const result = await request.post(`${this.#baseUrl}/invite`, { email });

    return new ResponseInvitePartnersCompanyDto(result);
  }
}

export const partnersCompanyService = new PartnersCompanyService();
