import React from 'react';
import PropTypes from 'prop-types';
import { translate } from 'react-i18next';

const ErrorPartnerToken = ({ t, tkey = 'partnerLinkInvalid' }) => (
  <div style={{ marginTop: 100 }}>
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
      }}>
      <img src="/img/logo.svg" alt="Logo" style={{ width: 297, height: 70 }} />
    </div>
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginTop: 56
      }}>
      <div
        style={{
          fontSize: 18,
          color: '#252B49',
          textAlign: 'center',
          wordWrap: 'break-word',
          whiteSpace: 'pre'
        }}>
        {t(tkey)}
      </div>
    </div>
  </div>
);

ErrorPartnerToken.propTypes = {
  t: PropTypes.func.isRequired
};

export default translate()(ErrorPartnerToken);
