import { useEffect, useState } from 'react';
import { notificationSettingsService } from '../../../api/notificationSettingsService';

export function useSettings() {
  const [loading, setLoading] = useState(true);
  const [settings, setSettings] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const result = await notificationSettingsService.get();

        setSettings(result);
      } catch (error) {
        console.error(error);
        setError(error);
      } finally {
        setLoading(false);
      }
    })();
  }, []);

  return { loading, settings, setSettings, error };
}
