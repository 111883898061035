import {
  forwardRef,
  SyntheticEvent,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
  useMemo,
  useCallback,
  HTMLAttributes
} from 'react';
import cn from 'classnames';
import {
  Autocomplete,
  AutocompleteRenderInputParams,
  TextField
} from '@mui/material';
import { StyledEngineProvider } from '@mui/material/styles';
import { Typography } from '@/components/Typography';
import styles from './Typeahead.module.scss';
import { LocalStorageProvider } from './localStorageProvider';

export interface TypeaheadActions {
  save: () => void;
}
export interface TypeaheadProps {
  className?: string;
  store: string;
  initialValue?: string;
  placeholder?: string;
  maxLength?: number;
  onChange?: (str: string) => void;
}

function hasItemsToShow(items: string[], query: string) {
  const regex = new RegExp(query, 'i');
  return items.filter((item) => regex.test(item)).length > 0;
}

export const Typeahead = forwardRef<TypeaheadActions, TypeaheadProps>(
  (
    {
      className = '',
      store,
      initialValue = '',
      placeholder = '',
      maxLength = 100,
      onChange
    },
    ref
  ) => {
    const dataProviderRef = useRef<LocalStorageProvider | null>(null);
    const [suggestions, setSuggestions] = useState<string[]>([]);
    const [currentValue, setCurrentValue] = useState<string>(initialValue);
    const [valueUpdated, setValueUpdated] = useState(true);
    const [open, setOpen] = useState(false);

    useEffect(() => {
      setCurrentValue(initialValue);
    }, [initialValue]);

    useEffect(() => {
      dataProviderRef.current = new LocalStorageProvider(store);
      setSuggestions([]);
    }, [store]);
    useImperativeHandle(
      ref,
      () => ({
        save: () => {
          const updatedValue = currentValue.trim();
          if (!suggestions.includes(updatedValue)) {
            dataProviderRef.current
              ?.add(updatedValue)
              .catch((err) => console.log(err));
            setValueUpdated(true);
          }
        }
      }),
      [currentValue, suggestions]
    );

    useEffect(() => {
      if (valueUpdated) {
        const loadSuggestions = async () => {
          const items = (await dataProviderRef.current?.get()) || [];
          setSuggestions(items);
        };
        loadSuggestions().catch((err) => console.log(err));
        setValueUpdated(false);
      }
    }, [valueUpdated]);

    const onInputChange = useCallback(
      (_event: SyntheticEvent, value: string, _reason: string) => {
        if (value.length > maxLength) return;
        setCurrentValue(value);

        if (typeof onChange === 'function') {
          onChange(value);
        }
        setOpen(hasItemsToShow(suggestions, value));
      },
      [maxLength, onChange, suggestions]
    );

    const renderInputCallback = useCallback(
      (params: AutocompleteRenderInputParams) => (
        <Typography variant="body1Reg">
          <TextField {...params} placeholder={placeholder} />
        </Typography>
      ),
      [placeholder]
    );

    const renderOptionCallback = useCallback(
      (props: HTMLAttributes<HTMLLIElement>, option: string) => (
        <li {...props}>
          <Typography
            className={styles.option}
            Component="span"
            variant="body1Reg">
            {option}
          </Typography>
        </li>
      ),
      []
    );

    const classes = useMemo(
      () => ({ popper: styles.popper, input: styles.input }),
      []
    );

    const onOpen = useCallback(
      (_event: SyntheticEvent) => {
        setOpen(hasItemsToShow(suggestions, currentValue));
      },
      [currentValue, suggestions]
    );

    const onClose = useCallback(
      (_event: SyntheticEvent, reason: string) => {
        if (reason === 'blur' || reason === 'selectOption') {
          setOpen(false);
        } else {
          setOpen(hasItemsToShow(suggestions, currentValue));
        }
      },
      [currentValue, suggestions]
    );

    return (
      <StyledEngineProvider injectFirst>
        <Autocomplete
          freeSolo
          classes={classes}
          onOpen={onOpen}
          onClose={onClose}
          className={cn(styles.autocomplete, className)}
          renderInput={renderInputCallback}
          open={open}
          options={suggestions}
          onInputChange={onInputChange}
          fullWidth
          value={currentValue}
          inputValue={currentValue}
          noOptionsText={null}
          clearIcon={null}
          popupIcon={null}
          clearOnBlur={false}
          renderOption={renderOptionCallback}
        />
      </StyledEngineProvider>
    );
  }
);
