/* eslint-disable max-len */
import React from 'react';

const TaskManagerIcon = () => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <rect
      className="snm-icon__path"
      x="3"
      width="14"
      height="5"
      rx="2"
      fill="#C6CAE3"
    />
    <rect
      className="snm-icon__path"
      x="1"
      y="6"
      width="14"
      height="6"
      rx="2"
      fill="#C6CAE3"
    />
    <rect
      className="snm-icon__path"
      x="3"
      y="13"
      width="14"
      height="5"
      rx="2"
      fill="#C6CAE3"
    />
  </svg>
);

export default TaskManagerIcon;
