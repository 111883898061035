import React, { Component } from 'react';
import PropTypes from 'prop-types';
import block from 'bem-cn-lite';
import { translate } from 'react-i18next';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { fromJS } from 'immutable';
import {
  Modal,
  FormGroup,
  FormControl,
  Button,
  HelpBlock,
  ControlLabel
} from 'react-bootstrap';
import { Typeahead } from '@link/react-components';
import Select from '../Select/Universal';
import { logModule } from '../../utils/logger';

import Helper from '../Helper';
import {
  validateCyrLatDigitSpaceDash,
  validateSystemId
} from '../../utils/validateField';
import { isMessengerService } from '../../lib/goodwix';
import TablePermissions from '../../containers/TablesNew/TablePermissions';
import './styles/modal_theme_default.styl';

import { ModalContainer } from './elements';

import * as servicesAction from '../../action-creators/services';
import * as usersAction from '../../action-creators/user';
import * as companyActions from '../../action-creators/company';
import * as modalActions from '../../action-creators/modal';

import keyDebounce from '../../utils/debounce';

const b = block('modal-container');

const log = logModule.extend('ModalInviteEmployee');
class ModalInviteEmployee extends Component {
  static isNameInCompanyValid(value) {
    if (value !== '' && value !== undefined) {
      return validateSystemId(value);
    }
    return true;
  }

  static isPositionInCompanyValid(value) {
    if (value !== '' && value !== undefined) {
      return validateCyrLatDigitSpaceDash(value);
    }
    return true;
  }

  static validateEmail(value) {
    // regex from http://stackoverflow.com/questions/46155/validate-email-address-in-javascript
    // eslint-disable-next-line max-len,no-useless-escape
    const re =
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(value);
  }

  static defaultPermissions(fromMessenger = false) {
    if (fromMessenger) {
      return fromJS({
        company: {
          read: true,
          update: true,
          delete: false
        },
        employees: {
          read: true,
          update: true,
          delete: true,
          editPermissions: true
        },
        catalogs: {
          read: false,
          update: false,
          delete: false
        },
        stocks: {
          read: false,
          update: false,
          delete: false
        },
        prices: {
          read: false,
          update: false,
          delete: false
        },
        customers: {
          read: true,
          update: true,
          delete: true
        },
        customerOrders: {
          read: false,
          update: false,
          delete: false
        },
        suppliers: {
          read: true,
          update: true,
          delete: true
        },
        supplierOrders: {
          read: false,
          update: false,
          delete: false
        },
        billing: {
          read: false,
          update: false,
          delete: false
        },
        purchaseRequests: {
          read: true,
          update: false,
          delete: false
        }
      });
    }

    return fromJS({
      company: {
        read: true,
        update: true,
        delete: false
      },
      employees: {
        read: true,
        update: true,
        delete: true,
        editPermissions: true
      },
      catalogs: {
        read: true,
        update: true,
        delete: true
      },
      stocks: {
        read: true,
        update: true,
        delete: true
      },
      prices: {
        read: true,
        update: true,
        delete: true
      },
      customers: {
        read: true,
        update: true,
        delete: true
      },
      customerOrders: {
        read: true,
        update: true,
        delete: true
      },
      suppliers: {
        read: true,
        update: true,
        delete: true
      },
      supplierOrders: {
        read: true,
        update: true,
        delete: true
      },
      billing: {
        read: false,
        update: false,
        delete: false
      },
      purchaseRequests: {
        read: true,
        update: false,
        delete: false
      }
    });
  }

  constructor(props) {
    super(props);
    this.state = {
      invite: props.invite || '',
      nickname: props.nickname || '',
      position: '',
      department: '',
      employeeVisibility: 'hidden-from-other-companies',
      permissions: ModalInviteEmployee.defaultPermissions(props.fromMessenger),
      requestComplete: isMessengerService(),
      pristineEmailField: true,
      pristineNameField: true,
      pristinePositionField: true
    };
    this.setEmail = this.setEmail.bind(this);
    this.setNameInCompany = this.setNameInCompany.bind(this);
    this.validateEmail = this.validateEmail.bind(this);
    this.onKeyPress = keyDebounce(this.onKeyPress.bind(this), 500);
    this.onChangePermissions = this.onChangePermissions.bind(this);
    this.setDepartment = this.setDepartment.bind(this);

    this.typeaheadRef = React.createRef();
    this.typeaheadTitleKey = 'department';
  }

  getChildContext() {
    return { b };
  }

  UNSAFE_componentWillMount() {
    const { gaPageView } = this.props;
    gaPageView('employee_add_open');
  }

  setEmail(e) {
    this.setState({ invite: e.target.value.trim() });
  }

  setNameInCompany(e) {
    this.setState({ nickname: e.target.value });
  }

  setPositionEmployee(e) {
    this.setState({ position: e.target.value });
  }

  setDepartment(value) {
    this.setState({ department: value });
  }

  get permissions() {
    return this.state.permissions;
  }

  validateEmail() {
    return ModalInviteEmployee.validateEmail(this.state.invite);
  }

  async validateNameInCompanyUnique(name) {
    const { isNameInCompanyUnique } = this.props;
    await isNameInCompanyUnique(name);
    this.setState({ requestComplete: true });
  }

  onKeyPress(field) {
    if (field === 'nickname') {
      this.validateNameInCompanyUnique(this.state.nickname);
    }
  }

  onChange(field, value) {
    if (field === 'nickname') {
      this.setState({ requestComplete: false });
    }
    this.setState({ [field]: value });
  }

  inputProps(field) {
    return {
      onChange: (e) => this.onChange(field, e.target.value),
      onKeyUp: () => this.onKeyPress(field),
      onPaste: () => this.onKeyPress(field)
    };
  }

  isEmailEmpty() {
    return this.state.invite.length === 0;
  }

  isNameInCompanyEmpty() {
    return this.state.nickname.length === 0;
  }

  isValidNameInCompany() {
    if (isMessengerService()) {
      return true;
    }

    return (
      !this.isNameInCompanyEmpty() &&
      ModalInviteEmployee.isNameInCompanyValid(this.state.nickname) &&
      this.props.company.get('uniqueNameInCompany')
    );
  }

  isValidPosition() {
    return (
      !this.isPositionEmpty() &&
      ModalInviteEmployee.isPositionInCompanyValid(this.state.position)
    );
  }

  isValidEmail() {
    const sameEmailOfCurrentUser =
      this.props.user.get('email') === this.state.invite;
    return (
      !this.isEmailEmpty() && this.validateEmail() && !sameEmailOfCurrentUser
    );
  }

  isPositionEmpty() {
    return this.state.position.trim().length === 0;
  }

  onChangePermissions(permissions) {
    this.setState({ permissions });
  }

  asterisk() {
    return <span style={{ color: 'red' }}>*</span>;
  }

  render() {
    const {
      t,
      text,
      // needTranslate,
      title,
      sendInvite,
      hideModalDialog,
      gaPageView,
      gaSend,
      company,
      user,
      fromMessenger
    } = this.props;

    const {
      invite,
      nickname,
      position,
      department,
      employeeVisibility,
      pristineNameField,
      pristineEmailField,
      pristinePositionField,
      requestComplete
    } = this.state;

    log(
      'isValidPosition:',
      this.isValidPosition(),
      'isValidEmail:',
      this.isValidEmail(),
      'requestComplete',
      requestComplete,
      'isValidNameInCompany',
      this.isValidNameInCompany()
    );

    return (
      <Modal id="inviteEmployee" onHide={() => hideModalDialog()} show>
        <ModalContainer mods={{ theme: 'default' }}>
          <Modal.Header>
            <Modal.Title>
              {t(title)}
              <span
                className="modal-header-cross pull-right"
                onClick={() => hideModalDialog()}
              />
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>{t(text)}</div>
            <br />
            <FormGroup
              controlId="email"
              validationState={
                this.validateEmail() || pristineEmailField ? null : 'error'
              }
              style={{ overflow: 'hidden' }}>
              <ControlLabel
                style={{
                  color: '#999999',
                  fontWeight: 'normal',
                  fontSize: '14px'
                }}>
                {`${t('Enter email')} (${t('only latin')})`}
                {this.asterisk()}
              </ControlLabel>
              <FormControl
                type="text"
                placeholder="example@email.com"
                value={invite}
                onChange={this.setEmail}
                onKeyDown={() => {
                  if (pristineEmailField) {
                    this.setState({ pristineEmailField: false });
                  }
                }}
              />
              {this.isEmailEmpty() && !pristineEmailField && (
                <HelpBlock style={{ marginBottom: 0 }}>
                  {t('Required fields empty')}
                </HelpBlock>
              )}
              {!this.validateEmail() && !this.isEmailEmpty() && (
                <HelpBlock style={{ marginBottom: 0 }}>
                  {t('Wrong email format')}
                </HelpBlock>
              )}
              {user.get('email') === invite && (
                <HelpBlock style={{ marginBottom: 0 }}>
                  {t('Can not invite yourself')}
                </HelpBlock>
              )}
            </FormGroup>
            <FormGroup controlId="department" style={{ overflow: 'hidden' }}>
              <ControlLabel
                style={{
                  color: '#999999',
                  fontWeight: 'normal',
                  fontSize: '14px'
                }}>
                {t('department')}
              </ControlLabel>
              <Typeahead
                initialValue={this.department}
                onChange={this.setDepartment}
                store={this.typeaheadTitleKey}
                ref={this.typeaheadRef}
              />
            </FormGroup>
            {!isMessengerService() && (
              <FormGroup
                controlId="nameInCompany"
                validationState={
                  (this.isNameInCompanyEmpty() ||
                    !ModalInviteEmployee.isNameInCompanyValid(nickname) ||
                    !company.get('uniqueNameInCompany')) &&
                  !pristineNameField
                    ? 'error'
                    : null
                }
                style={{ overflow: 'hidden' }}>
                <ControlLabel
                  style={{
                    color: '#999999',
                    fontWeight: 'normal',
                    fontSize: '14px'
                  }}>
                  {`${t('Name in company')} (${t('only latin')}) `}
                  {this.asterisk()}
                  &nbsp;
                  <Helper text="unique_name_employee" />
                </ControlLabel>
                <FormControl
                  type="text"
                  placeholder={t('Employee1')}
                  onChange={this.setNameInCompany}
                  {...this.inputProps('nickname')}
                  onKeyDown={() => {
                    if (pristineNameField) {
                      this.setState({ pristineNameField: false });
                    }
                  }}
                />
                {this.isNameInCompanyEmpty() && !pristineNameField && (
                  <HelpBlock style={{ marginBottom: 0 }}>
                    {t('Required fields empty')}
                  </HelpBlock>
                )}
                {!ModalInviteEmployee.isNameInCompanyValid(nickname) &&
                  !this.isNameInCompanyEmpty() && (
                    <HelpBlock style={{ marginBottom: 0 }}>
                      {t('wrong_login_in_company')}
                    </HelpBlock>
                  )}
                {!company.get('uniqueNameInCompany') &&
                  nickname.length !== 0 && (
                    <HelpBlock style={{ marginBottom: 0 }}>
                      {t('Name in used')}
                    </HelpBlock>
                  )}
              </FormGroup>
            )}
            <FormGroup
              controlId="position"
              validationState={
                this.isPositionEmpty() && !pristinePositionField
                  ? 'error'
                  : null
              }
              style={{ overflow: 'hidden' }}>
              <ControlLabel
                style={{
                  color: '#999999',
                  fontWeight: 'normal',
                  fontSize: '14px'
                }}>
                {`${t('position')} (${t('cyrillic latin')}) `}
                {this.asterisk()}
              </ControlLabel>
              <FormControl
                type="text"
                placeholder={t('enter_employee_position_placeholder')}
                onChange={this.setPositionEmployee}
                {...this.inputProps('position')}
                onKeyDown={() => {
                  if (pristinePositionField) {
                    this.setState({ pristinePositionField: false });
                  }
                }}
              />
              {this.isPositionEmpty() && !pristinePositionField && (
                <HelpBlock style={{ marginBottom: 0 }}>
                  {t('Required fields empty')}
                </HelpBlock>
              )}
              {!ModalInviteEmployee.isPositionInCompanyValid(position) &&
                !this.isPositionEmpty() && (
                  <HelpBlock style={{ marginBottom: 0 }}>
                    {t('wrong_position_in_company')}
                  </HelpBlock>
                )}
            </FormGroup>
            <FormGroup controlId="visibility">
              <ControlLabel
                style={{
                  color: '#999999',
                  fontWeight: 'normal',
                  fontSize: '14px'
                }}>
                {`${t('visibility-of-employee')}`}
              </ControlLabel>
              <Select
                value={employeeVisibility}
                onChange={(value) =>
                  this.setState({ employeeVisibility: value })
                }
                options={['hidden-from-other-companies', 'visible-to-all']}
                translateGroup="employee-visibility"
              />
            </FormGroup>
            <h5 style={{ margin: '25px 0' }}>
              {t('Access right to sections')}
            </h5>
            <TablePermissions
              permissions={this.permissions}
              billingAllowed={company.get('billingAllowed')}
              onChange={this.onChangePermissions}
            />
            <div>
              {this.asterisk()}
              &nbsp;
              {t('Required fields')}
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              id="cancel"
              className="pull-left"
              onClick={() => {
                gaPageView('employee_add_cancel');
                gaSend({
                  category: 'Common',
                  action: 'employee_add_cancel',
                  label: user.get('email')
                });
                hideModalDialog();
              }}>
              {t('Cancel')}
            </Button>
            <Button
              id="saveCharacteristicButton"
              bsStyle="primary"
              disabled={
                !this.isValidPosition() ||
                !this.isValidEmail() ||
                !requestComplete ||
                !this.isValidNameInCompany()
              }
              onClick={
                () => {
                  this.typeaheadRef.current.save();
                  sendInvite(
                    invite,
                    nickname,
                    position,
                    this.permissions.toJS(),
                    employeeVisibility,
                    department
                  ).then(() => hideModalDialog());
                  if (fromMessenger) {
                    gaSend({
                      category: 'Messenger',
                      action: 'chat_add_employee_sent',
                      label: user.get('email')
                    });
                  }
                }
                // eslint-disable-next-line react/jsx-curly-newline
              }>
              {t('Send invite')}
            </Button>
          </Modal.Footer>
        </ModalContainer>
      </Modal>
    );
  }
}

ModalInviteEmployee.propTypes = {
  t: PropTypes.func.isRequired
};

ModalInviteEmployee.childContextTypes = {
  b: PropTypes.func
};

export default connect(
  (state) => ({
    company: state.get('company'),
    user: state.getIn(['user', 'user'])
  }),
  (dispatch) =>
    bindActionCreators(
      {
        gaPageView: servicesAction.gaPageView,
        gaSend: servicesAction.gaSend,
        sendInvite: companyActions.sendInvite,
        isNameInCompanyUnique: usersAction.isNameInCompanyUnique,
        hideModalDialog: modalActions.hideModalDialog
      },
      dispatch
    )
)(translate(['ui'], { wait: true })(ModalInviteEmployee));
