import React, { useContext } from 'react';
import { useSelector } from 'react-redux';
import * as constructorGetters from '../components/ChatWidget/modules/constructor/getters';

const IsCanBeDeletedContext = React.createContext();

const generateIsCanBeDeleted = (flag) => {
  if (typeof flag === 'undefined') return true;
  return flag;
};
export const IsCanBeDeletedProvider = ({ children }) => {
  const isCanBeDeleted = useSelector(constructorGetters.getIsCanBeDeleted);

  return (
    <IsCanBeDeletedContext.Provider
      value={generateIsCanBeDeleted(isCanBeDeleted)}>
      {children}
    </IsCanBeDeletedContext.Provider>
  );
};

export const useIsCanBeDeleted = () => useContext(IsCanBeDeletedContext);
