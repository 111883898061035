import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { translate } from 'react-i18next';
import {
  Col,
  Row,
  Form,
  FormGroup,
  Button,
  ControlLabel
} from 'react-bootstrap';
import InputMask from 'react-input-mask';
import { isEmpty, isNil } from 'ramda';
import { InputText, Select } from '../FormControls';

import * as modalActions from '../../action-creators/modal';
import * as companyActions from '../../action-creators/company';
import * as serviceActions from '../../action-creators/services';
import { actions as dictionariesActions } from '../../modules/dictionaries/slice';
import * as dictionariesGetters from '../../modules/dictionaries/getters';
import * as dictionariesStorage from '../../modules/dictionaries/storage';
import * as storageGetters from '../../storeGetters';
import CustomOverlay from '../Overlay';
import ErrorHint from '../Hints/ErrorHint';
import keyDebounce from '../../utils/debounce';

const DEFAULT_COUNTRY_ID = '3159'; // Россия
class FormContacts extends Component {
  static isDataSet = false;

  static haveChanges = false;

  constructor(props) {
    super(props);
    this.state = {};
    this.showModalAddContact = this.showModalAddContact.bind(this);
    this.dropState = this.dropState.bind(this);
    this.addContact = this.addContact.bind(this);
    this.getLocationsSelectList = this.getLocationsSelectList.bind(this);
    this.onChangeCountry = this.onChangeCountry.bind(this);
    this.onChangeRegion = this.onChangeRegion.bind(this);
    this.onChangeCity = this.onChangeCity.bind(this);
    this.onChangeInput = this.onChangeInput.bind(this);
    this.onTrimInput = this.onTrimInput.bind(this);
    this.onCountrySearch = keyDebounce(this.onCountrySearch.bind(this), 500);
    this.onRegionSearch = keyDebounce(this.onRegionSearch.bind(this), 500);
    this.onCitySearch = keyDebounce(this.onCitySearch.bind(this), 500);
  }

  UNSAFE_componentWillMount() {
    const { contacts } = this.props;

    if (contacts) {
      contacts.forEach((contact, key) => {
        this.setState({ [key]: contact });
      });

      this.setCompanyLocation();

      FormContacts.isDataSet = true;
    }
  }

  async UNSAFE_componentWillReceiveProps(props) {
    const { contacts } = props;

    if (!FormContacts.isDataSet && contacts) {
      contacts.forEach((contact, key) => {
        this.setState({ [key]: contact });
      });

      this.setCompanyLocation();
      FormContacts.isDataSet = true;
    }
    if (contacts) {
      const stateLength = Object.keys(this.state).length - 1;
      const propsLength = contacts.size;
      if (stateLength !== propsLength) {
        contacts.forEach((contact, key) => {
          this.setState({ [key]: contact });
        });
      }
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { country, region, city, street, house } = this.state;
    const { language, getCountries, getRegionsCount, getRegions, getCities } =
      this.props;

    if (
      prevState.country !== country ||
      prevState.region !== region ||
      prevState.city !== city ||
      prevState.street !== street ||
      prevState.house !== house
    ) {
      FormContacts.haveChanges = true;
    }

    if (country && prevState.country !== country) {
      getRegionsCount(country);
    }

    if (prevProps.language && prevProps.language !== language) {
      getCountries({ countryId: country, lang: language });
      if (this.hasRegion) {
        getRegions({ countryId: country, regionId: region, lang: language });
      }
      if (this.hasCity) {
        getCities({
          countryId: country,
          regionId: region,
          cityId: city,
          lang: language
        });
      }
    }
  }

  setCompanyLocation() {
    const { company, getCountries, getRegions, getCities } = this.props;

    if (isNil(company)) return;

    if (company.get('country')) {
      getCountries({ countryId: company.get('country') });
    }
    if (company.get('region')) {
      getRegions({
        countryId: company.get('country'),
        regionId: company.get('region')
      });
    }
    if (company.get('city')) {
      getCities({
        countryId: company.get('country'),
        regionId: company.get('region'),
        cityId: company.get('city')
      });
    }

    this.setState({
      country: company.get('country'),
      region: company.get('region'),
      city: company.get('city'),
      street: company.get('street'),
      house: company.get('house')
    });
  }

  onChange(e, field) {
    const contact = this.state[field];
    contact.value = e.target.value;
    this.setState({ [field]: contact });
    FormContacts.haveChanges = true;
  }

  trimField(field) {
    const contact = this.state[field];
    contact.value = contact.value.trim();
    this.setState({ [field]: contact });
  }

  inputProps(field) {
    return {
      value: this.state[field].value,
      onChange: (e) => this.onChange(e, field),
      onBlur: () => this.trimField(field),
      viewRead: !this.props.havePermissionToUpdate
    };
  }

  async dropState() {
    const { getCompany } = this.props;
    FormContacts.isDataSet = false;
    FormContacts.haveChanges = false;
    getCompany();
  }

  addContact(label, value) {
    let freeKey = Object.keys(this.state).length;
    for (let i = 0; i < freeKey; i++) {
      if (!this.state[i]) {
        freeKey = i;
      }
    }
    this.setState({ [freeKey]: { label, value, removable: true, new: true } });
    FormContacts.haveChanges = true;
  }

  removeContact(key) {
    delete this.state[key];
    this.setState(this.state);
    FormContacts.haveChanges = true;
  }

  showModalAddContact() {
    const { showModal } = this.props;
    showModal('ADD_NEW_CONTACT', {
      captionKey: 'Add contact',
      addContact: this.addContact
    });
  }

  get isUseNoRegionsHint() {
    const { regionsCount, havePermissionToUpdate } = this.props;
    const { country } = this.state;

    return havePermissionToUpdate && country && regionsCount === 0;
  }

  get hasRegion() {
    const { region } = this.state;

    return !isNil(region) && !isEmpty(region);
  }

  get hasCity() {
    const { city } = this.state;

    return !isNil(city) && !isEmpty(city);
  }

  get hasStreet() {
    const { street } = this.state;

    return !isNil(street) && !isEmpty(street);
  }

  getLocationsSelectList(items) {
    const { t } = this.props;

    return [{ value: null, label: t('Not_selected') }, ...items];
  }

  onChangeInput(event) {
    const { name, value } = event.target;

    this.setState({ [name]: value });
  }

  onTrimInput(event) {
    const { name } = event.target;

    this.setState({ [name]: this.state[name].trim() });
  }

  onChangeCountry(val) {
    if (!val) return;
    const value = typeof val === 'string' ? val : val.value;
    const { cleanRegion, cleanCities } = this.props;
    const country = value === 'null' ? null : value;
    this.setState({ country, region: '', city: '' }, () => {
      FormContacts.haveChanges = true;
    });
    cleanRegion();
    cleanCities();
  }

  setDefaultCountry() {
    const { cleanCities, getCountries } = this.props;
    this.setState({ country: DEFAULT_COUNTRY_ID, city: '' }, () => {
      FormContacts.haveChanges = true;
    });
    getCountries({ countryId: DEFAULT_COUNTRY_ID });
    cleanCities();
  }

  onChangeRegion(val) {
    if (!val) return;
    const value = typeof val === 'string' ? val : val.value;
    if (!this.state.country) this.setDefaultCountry();

    const { cleanCities } = this.props;

    this.setState({ region: value, city: '', street: '', house: '' }, () => {
      FormContacts.haveChanges = true;
    });
    cleanCities();
  }

  onChangeCity(val) {
    if (!val) return;
    const value = typeof val === 'string' ? val : val.value;

    const { cleanCities } = this.props;

    this.setState({ city: value }, () => {
      FormContacts.haveChanges = true;
    });

    if (typeof val === 'string') {
      this.setState({ street: '', house: '' }, () => {
        FormContacts.haveChanges = true;
      });
      cleanCities();
    }
  }

  onCountrySearch(value) {
    const { getCountries } = this.props;
    if (value) {
      getCountries({ search: value });
    }
  }

  onRegionSearch(value) {
    const { getRegions } = this.props;
    const { country } = this.state;
    if (value) {
      if (!country) this.setDefaultCountry();

      getRegions({ countryId: country || DEFAULT_COUNTRY_ID, search: value });
    }
  }

  onCitySearch(value) {
    const { getCities } = this.props;
    const { country, region } = this.state;
    if (value) {
      getCities({ countryId: country, regionId: region, search: value });
    }
  }

  onChangePhone = (key) => (event) => this.onChange(event, key);

  onSave = async () => {
    const { updateContacts, getCompany, gaSend, user } = this.props;

    const hasErrors = this.isFormErrored(0)() || this.isFormErrored(1)();

    if (hasErrors) {
      return;
    }

    await updateContacts(this.state);
    await gaSend({
      category: 'Company',
      action: 'company_contacts',
      label: user.get('email')
    });
    FormContacts.issDataSet = false;
    FormContacts.haveChanges = false;
    await getCompany();
  };

  onDelete = (key) => () =>
    confirm(this.props.t('Delete?')) && this.removeContact(key);

  onPressEnter = (callback) => (event) => {
    if (event.keyCode === 13) {
      callback(event.target.value);
    }
  };

  isFormErrored = (key) => () => {
    const fieldLength = this.state[key]?.value.length;
    return fieldLength < 2 || fieldLength === 17 ? '' : 'error';
  };

  render() {
    const { t, contacts, countries, regions, cities, havePermissionToUpdate } =
      this.props;
    const { country, region, city, street, house } = this.state;

    const filteredKeys = Object.keys(this.state).filter((key) => !isNaN(key));

    const savedContacts =
      (contacts && filteredKeys.filter((key) => !this.state[key].new)) || [];
    const newContacts =
      (contacts && filteredKeys.filter((key) => this.state[key].new)) || [];
    const removable =
      contacts && filteredKeys.find((key) => this.state[key].removable);

    return (
      <div className="form-content-center">
        <Form>
          <Row>
            <Row xs={12}>
              <div className="form-group-input-col dp-inline m-t-5">
                <ControlLabel className="control-label-row">
                  {t('form_contacts.country')}
                </ControlLabel>
                <Select
                  id="country-select"
                  arrowRenderer={null}
                  options={this.getLocationsSelectList(countries, 'country')}
                  value={country}
                  onChange={this.onChangeCountry}
                  onInputChange={this.onCountrySearch}
                  onInputKeyDown={this.onPressEnter(this.onChangeCountry)}
                  placeholder=""
                  noResultsText={t('noResultsText')}
                  clearable={false}
                  removeSelected
                  disabled={!havePermissionToUpdate}
                />
              </div>
            </Row>
            <Row xs={12}>
              <div className="form-group-input-col dp-inline m-t-5">
                <ControlLabel className="control-label-row">
                  {t('form_contacts.region')}
                </ControlLabel>
                <CustomOverlay
                  isUseOverlay={this.isUseNoRegionsHint}
                  popoverId="no_regions_for_selected_country_hint"
                  hint={<ErrorHint hint="no_regions_for_selected_country" />}>
                  <Select
                    id="region-select"
                    arrowRenderer={null}
                    options={this.getLocationsSelectList(regions, 'region')}
                    value={region}
                    onChange={this.onChangeRegion}
                    onInputChange={this.onRegionSearch}
                    onInputKeyDown={this.onPressEnter(this.onChangeRegion)}
                    placeholder=""
                    noResultsText={t('noResultsText')}
                    clearable={false}
                    removeSelected
                    disabled={!havePermissionToUpdate}
                  />
                </CustomOverlay>
              </div>
            </Row>
            {this.hasRegion && (
              <Row xs={12}>
                <div className="form-group-input-col dp-inline m-t-5">
                  <ControlLabel className="control-label-row">
                    {t('form_contacts.city')}
                  </ControlLabel>
                  <Select
                    id="city-select"
                    arrowRenderer={null}
                    options={this.getLocationsSelectList(cities, 'city')}
                    value={city}
                    onChange={this.onChangeCity}
                    onInputChange={this.onCitySearch}
                    onInputKeyDown={this.onPressEnter(this.onCitySearch)}
                    placeholder=""
                    noResultsText={t('noResultsText')}
                    clearable={false}
                    removeSelected
                    disabled={!havePermissionToUpdate}
                  />
                </div>
              </Row>
            )}
            {this.hasCity && (
              <Row xs={12}>
                <div className="form-group-input-col dp-inline m-t-5">
                  <ControlLabel className="control-label-row">
                    {t('form_contacts.street')}
                  </ControlLabel>
                  <InputText
                    name="street"
                    value={street}
                    onChange={this.onChangeInput}
                    onBlur={this.onTrimInput}
                    viewRead={!havePermissionToUpdate}
                  />
                </div>
              </Row>
            )}
            {this.hasStreet && (
              <Row xs={12}>
                <div className="form-group-input-col dp-inline m-t-5">
                  <ControlLabel className="control-label-row">
                    {t('form_contacts.house')}
                  </ControlLabel>
                  <InputText
                    name="house"
                    value={house}
                    onChange={this.onChangeInput}
                    onBlur={this.onTrimInput}
                    viewRead={!havePermissionToUpdate}
                  />
                </div>
              </Row>
            )}
            {savedContacts.map((key) => (
              <Row xs={12} key={key}>
                <div className="form-group-input-col dp-inline m-t-5">
                  <ControlLabel className="control-label-row">
                    {t(this.state[key].label)}
                  </ControlLabel>
                  <h6 className="pull-right m-t-5 m-b-0">
                    {Number(key) > 1 && (
                      <small>{`${this.state[key].value.length}/${
                        this.state[key].maxLength || 255
                      }`}</small>
                    )}
                  </h6>
                  {Number(key) < 2 &&
                    (havePermissionToUpdate ? (
                      <FormGroup
                        className="m-b-0"
                        controlId={this.state[key].label}
                        validationState={this.isFormErrored(key)()}>
                        <InputMask
                          className="form-control"
                          type="text"
                          value={this.state[key].value}
                          onChange={this.onChangePhone(key)}
                          mask="+9 (999) 999-9999"
                          maskChar=""
                        />
                        {this.isFormErrored(key)() && (
                          <span style={{ color: '#fd4f53', marginTop: '2px' }}>
                            {t('form_contacts.phone_error')}
                          </span>
                        )}
                      </FormGroup>
                    ) : (
                      <div style={{ padding: 7 }}>
                        {this.state[key].value || '-'}
                      </div>
                    ))}
                  {Number(key) > 1 && (
                    <InputText
                      maxLength={this.state[key].maxLength || 255}
                      {...this.inputProps(key)}
                    />
                  )}
                </div>
                {this.state[key].removable && havePermissionToUpdate && (
                  <div
                    className="glypth-contacts"
                    onClick={() =>
                      confirm(t('Delete?')) && this.removeContact(key)
                    }>
                    <img
                      role="presentation"
                      className="contacts-sign"
                      src="/img/remove.svg"
                      alt=""
                    />
                  </div>
                )}
              </Row>
            ))}

            {havePermissionToUpdate && (
              <>
                <hr
                  style={{
                    borderTop: '1px solid #ccc',
                    marginLeft: '-15px',
                    ...(newContacts.length || removable
                      ? { marginRight: '6px' }
                      : { marginRight: '-15px' })
                  }}
                />
                {newContacts.map((key) => (
                  <Row xs={12}>
                    <div className="form-group-input-col dp-inline m-t-5">
                      <ControlLabel className="control-label-row">
                        {t(this.state[key].label)}
                      </ControlLabel>
                      <h6 className="pull-right m-t-5 m-b-0">
                        <small>{`${this.state[key].value.length}/${
                          this.state[key].maxLength || 255
                        }`}</small>
                      </h6>
                      <InputText
                        maxLength={this.state[key].maxLength || 255}
                        {...this.inputProps(key)}
                      />
                    </div>
                    {this.state[key].removable && (
                      <div
                        className="glypth-contacts"
                        onClick={this.onDelete(key)}>
                        <img
                          role="presentation"
                          className="contacts-sign"
                          src="/img/remove.svg"
                          alt=""
                        />
                      </div>
                    )}
                  </Row>
                ))}
                <Row>
                  <Col sm={8} className="form-group-input-col m-t-5">
                    <div
                      className="glypth-conditions m-t-10"
                      style={{ paddingTop: 0 }}
                      onClick={this.showModalAddContact}>
                      <img
                        role="presentation"
                        src="/img/plus_in_circle.svg"
                        className="add-option-glypth"
                        alt=""
                      />
                      <span className="add-option-text">
                        {t('Add another contact')}
                      </span>
                    </div>
                  </Col>
                </Row>
                <hr
                  style={{
                    borderTop: '1px solid #ccc',
                    marginLeft: '-15px',
                    ...(newContacts.length || removable
                      ? { marginRight: '6px' }
                      : { marginRight: '-15px' })
                  }}
                />
                <FormGroup>
                  <Col>
                    <div className="form-button-center">
                      <Button
                        id="saveCompanyButton"
                        bsStyle="primary"
                        style={{ marginLeft: 15 }}
                        disabled={
                          !FormContacts.haveChanges ||
                          this.isFormErrored(0)() ||
                          this.isFormErrored(1)()
                        }
                        onClick={this.onSave}>
                        {t('Save changes')}
                      </Button>
                      <Button
                        id="cancelCompanyBtn"
                        bsStyle="default"
                        style={{ marginLeft: '15px' }}
                        disabled={!FormContacts.haveChanges}
                        onClick={this.dropState}>
                        {t('Cancel')}
                      </Button>
                    </div>
                  </Col>
                </FormGroup>
              </>
            )}
          </Row>
        </Form>
      </div>
    );
  }
}

export default connect(
  (state) => ({
    company: state.get('company'),
    user: state.getIn(['user', 'user']),
    contacts: state.getIn(['company', 'contacts']),
    havePermissionToUpdate: state.getIn([
      'user',
      'user',
      'permissions',
      'company',
      'update'
    ]),
    language: storageGetters.getCurrentUserLanguage(state),
    countries: dictionariesGetters.getCountries(state),
    regions: dictionariesGetters.getRegions(state),
    cities: dictionariesGetters.getCities(state),
    regionsCount: dictionariesGetters.getRegionsCount(state)
  }),
  (dispatch) =>
    bindActionCreators(
      {
        showModal: modalActions.showModal,
        gaSend: serviceActions.gaSend,
        getCompany: companyActions.getCompany,
        updateContacts: companyActions.updateContacts,
        removeContact: companyActions.removeContact,
        getCountries: dictionariesStorage.getCountries,
        getRegionsCount: dictionariesStorage.getRegionsCount,
        getRegions: dictionariesStorage.getRegions,
        getCities: dictionariesStorage.getCities,
        cleanRegion: dictionariesActions.purgeRegions,
        cleanCities: dictionariesActions.purgeCities
      },
      dispatch
    )
)(translate(['ui'], { wait: true })(FormContacts));
