import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { translate } from 'react-i18next';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Modal, FormGroup, ControlLabel, HelpBlock } from 'react-bootstrap';
import { Container, Header, Body, Footer } from '../Components';
import InputOrGoTo from '../Components/InputOrGoTo';
import * as modalActions from '../../../../action-creators/modal';
import * as chatWidgetUC from '../../useCases';
import { getGroupChats } from '../../getter';

const groupToOptions = (group) =>
  group.list.map((g) => ({ label: g.name, value: g.id }));

const CWModalCreateDialogSubList = ({
  t,
  hideModal,
  group,
  parentId,
  handleGroupChats
}) => {
  const [name, setName] = useState('');
  const [isPristine, setPristine] = useState(true);
  const options = name ? groupToOptions(group) : [];

  const onChangeName = (value) => {
    const reducedName = value.substr(0, 60);
    setName(reducedName);
    setPristine(false);
    return reducedName;
  };

  const onCreateChatList = () => {
    hideModal();
    handleGroupChats('new-subgroup', { parentId, name });
  };

  const onEditChatList = (item) => {
    const { label, value } = item;
    hideModal();
    handleGroupChats('edit-group', { id: value, name: label });
  };

  const isNameAlreadyExists = () => {
    const names = options.map((list) => list.label.toLowerCase());
    return names.includes(name.toLowerCase().trim());
  };

  const isNameValid = () => name.trim().length > 0 && name.trim().length <= 60;

  const isNameEmpty = () => name.trim().length === 0;

  return (
    <Modal show onHide={() => hideModal()}>
      <Container>
        <Header
          title={t('chat-widget.menu-items.create-subgroup')}
          onClose={hideModal}
        />
        <Body>
          <FormGroup
            controlId="txt"
            validationState={
              isNameAlreadyExists() || (!isNameValid() && !isPristine)
                ? 'error'
                : null
            }>
            <ControlLabel style={{ fontSize: 12 }}>{t('Name')}</ControlLabel>
            <h6 className="pull-right">
              <small>{`${name.length}/60`}</small>
            </h6>
            <InputOrGoTo
              existLists={options}
              onChangeInput={onChangeName}
              onGoTo={onEditChatList}
              showGoToList
            />
            {((isNameEmpty() && !isPristine) ||
              (!isNameValid() && !isPristine)) && (
              <HelpBlock style={{ marginBottom: 0 }}>
                {t('Required fields empty')}
              </HelpBlock>
            )}
            {isNameAlreadyExists() && (
              <HelpBlock style={{ marginBottom: 0 }}>
                {t('Name already exist')}
              </HelpBlock>
            )}
          </FormGroup>
        </Body>
        <Footer
          lbTitle={t('Cancel')}
          lbOnClick={hideModal}
          rbTitle={t('Create')}
          rbOnClick={onCreateChatList}
          rbDisabled={!isNameValid() || isNameAlreadyExists()}
        />
      </Container>
    </Modal>
  );
};

CWModalCreateDialogSubList.propTypes = {
  t: PropTypes.func.isRequired,
  group: PropTypes.object.isRequired,
  parentId: PropTypes.number.isRequired,
  hideModal: PropTypes.func.isRequired
};

export default connect(
  (state) => ({
    group: getGroupChats(state)
  }),
  (dispatch) =>
    bindActionCreators(
      {
        hideModal: modalActions.hideModalDialog,
        ...chatWidgetUC
      },
      dispatch
    )
)(translate()(CWModalCreateDialogSubList));
