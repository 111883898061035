import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { translate } from 'react-i18next';
import { Glyphicon } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import Pagination from '../../components/Pagination';
import Table from '../../components/TableNew';
import SearchBlockDefault from '../../components/SearchBlock/SearchBlockDefault';

import * as modalActions from '../../action-creators/modal';
import * as ordersAction from '../../action-creators/orders';

class TableFileStorage extends Component {
  constructor(props) {
    super(props);
    this.setOrderBy = this.setOrderBy.bind(this);
    this.toggleOrderDirection = this.toggleOrderDirection.bind(this);
    this.setSearch = this.setSearch.bind(this);
    this.selectNextPagination = this.selectNextPagination.bind(this);
    this.modalEmployeesStorageStatistic =
      this.modalEmployeesStorageStatistic.bind(this);
    this.state = {
      search: '',
      orderBy: '',
      direction: ''
    };
  }

  convertSection = () => {
    const { section } = this.props;

    switch (section) {
      case 'customersorders':
        return 'customers-orders';
      case 'suppliersorders':
        return 'suppliers-orders';
      case 'catalogs':
        return 'catalogs';
      case 'chat':
        return 'chat';
      case 'uploads':
        return 'uploads';
      default:
        throw new Error(`no such type ${section}`);
    }
  };

  getColumns() {
    const {
      t,
      getFiles,
      offset,
      limit,
      orderId,
      removeFileLinkBySection,
      link,
      productId,
      getStorageSize,
      showModal
    } = this.props;
    const { search } = this.state;

    const list = [
      Table.ColLink({
        accessor: (item) => item['fileInfo.name'],
        url: (ti) => `/file-storage/file/${this.convertSection()}/${ti.id}`,
        title: t('Name'),
        width: '18%',
        index: 'name'
      }),
      Table.ColDate({
        accessor: (item) => item['fileInfo.createdAt'],
        title: t('Added'),
        width: '18%',
        index: 'createdAt'
      }),
      Table.ColText({
        accessor: (item) => item.type,
        title: t('Type'),
        width: '8%',
        index: 'type'
      }),
      Table.ColText({
        accessor: ({ size }) => {
          if (+size >= 1024 * 1024) {
            return `${(+size / (1024 * 1024)).toFixed(1)}mb`;
          }
          return `${(+size / 1024).toFixed(1)}kb`;
        },
        title: t('Size'),
        index: 'size'
      }),
      Table.ColText({
        accessor: (item) => {
          const originalUrl = item['fileInfo.originalUrl'];
          const url = item['fileInfo.url'];
          const name = item['fileInfo.name'];
          return (
            <div className="t-a-r">
              <div className="glypth-save-pright">
                <a
                  href={
                    originalUrl
                      ? `${originalUrl}?name=${name}`
                      : `${url}?name=${name}`
                  }>
                  <Glyphicon glyph="save" />
                </a>
              </div>
              <div
                className="glypth-remove-pleft pointer"
                onClick={async () => {
                  showModal('SIMPLE_SUBMIT', {
                    captionKey: 'confirm_action',
                    text: 'Delete?',
                    textBtnConfirm: 'OK',
                    submitAction: async () => {
                      await removeFileLinkBySection(
                        [item.fileId],
                        this.convertSection()
                      );
                      await getStorageSize();
                      await getFiles(
                        {
                          search,
                          orderDirection: this.state.orderDirection,
                          orderBy: this.state.orderBy,
                          limit,
                          offset
                        },
                        orderId,
                        productId
                      );
                    }
                  });
                }}>
                <Glyphicon glyph="remove" />
              </div>
            </div>
          );
        },
        showTooltip: false
      })
    ];
    if (link) {
      list[0] = Table.ColText({
        accessor: 'name',
        title: t('Name'),
        width: '18%'
      });
    }

    return list;
  }

  setOrderBy(orderBy) {
    this.setState({ orderBy });
  }

  toggleOrderDirection() {
    const orderDirection =
      this.state.orderDirection === 'DESC' ? 'ASC' : 'DESC';
    this.setState({ orderDirection });
    return orderDirection;
  }

  setSearch(search) {
    const { getFiles, limit, orderId, productId } = this.props;
    this.setState({ search });
    getFiles(
      {
        search,
        orderDirection: this.state.orderDirection,
        orderBy: this.state.orderBy,
        limit,
        offset: 0
      },
      orderId,
      productId
    );
  }

  selectNextPagination(value) {
    const { getFiles, limit, orderId, productId } = this.props;
    const { search, orderBy, direction } = this.state;
    getFiles(
      { search, limit, offset: value, orderBy, direction },
      orderId,
      productId
    );
  }

  displayData() {
    const { files, selected } = this.props;

    return (
      files &&
      files.map((item) => {
        const id = item.get('id');
        const hasId = selected.has(id);

        return hasId
          ? item.set('isSelected', true)
          : item.set('isSelected', false);
      })
    );
  }

  convertTo(size) {
    if (size > 1024 * 1024 * 1024) {
      return `${(size / (1024 * 1024 * 1024)).toFixed(2)} GB`;
    }
    if (size > 1024 * 1024) {
      return `${(size / (1024 * 1024)).toFixed(2)} MB`;
    }
    return `${(size / 1024).toFixed(2)} KB`;
  }

  modalEmployeesStorageStatistic() {
    const { showModal } = this.props;
    showModal('STORAGE_STATISTIC');
  }

  render() {
    const {
      t,
      channelId,
      topicId,
      dialogId,
      getFiles,
      size,
      offset,
      limit,
      selectRow,
      orderId,
      link,
      productId,
      maxStorageSize,
      storageSize,
      requestId,
      onBack
    } = this.props;
    const data = this.displayData();

    return (
      <div>
        {orderId && link && (
          <Link
            onClick={onBack}
            style={{ marginBottom: 15, display: 'inline-block' }}
            to={link}>
            {t('Return to order')}
          </Link>
        )}
        {productId && link && (
          <Link
            onClick={onBack}
            style={{ marginBottom: 15, display: 'inline-block' }}
            to={link}>
            {t('Return to product')}
          </Link>
        )}
        {dialogId && link && (
          <Link
            onClick={onBack}
            style={{ marginBottom: 15, display: 'inline-block' }}
            to={link}>
            {t('Return to chat')}
          </Link>
        )}
        {channelId && link && (
          <Link
            onClick={onBack}
            style={{ marginBottom: 15, display: 'inline-block' }}
            to={link}>
            {t('Return to channel')}
          </Link>
        )}
        {topicId && link && (
          <Link
            onClick={onBack}
            style={{ marginBottom: 15, display: 'inline-block' }}
            to={link}>
            {t('Return to topic')}
          </Link>
        )}
        {requestId && link && (
          <Link
            onClick={onBack}
            style={{ marginBottom: 15, display: 'inline-block' }}
            to={link}>
            {t('Return to purchase request')}
          </Link>
        )}
        <div style={{ marginBottom: 25 }}>
          <SearchBlockDefault
            placeholder={t('Name')}
            onSubmit={this.setSearch}
            onEmpty={this.setSearch}
            allowNull
          />
          <a
            style={{ position: 'relative', float: 'right', marginTop: -25 }}
            onClick={this.modalEmployeesStorageStatistic}>
            {`${t('Abailable storage space')} ${this.convertTo(
              storageSize
            )}/${this.convertTo(maxStorageSize)}`}
          </a>
        </div>
        <div style={{ marginBottom: 20 }}>
          {data && data.size > 0 && (
            <div>
              <div
                style={{
                  padding: '0 0 0 5px',
                  color: '#7e7e7e',
                  fontSize: '12px'
                }}
              />
              <Table
                columns={this.getColumns()}
                data={data}
                onSort={(orderBy, direction) => {
                  this.setState({ orderBy, direction });
                  getFiles(
                    {
                      search: this.state.search,
                      orderBy,
                      direction,
                      offset,
                      limit
                    },
                    orderId,
                    productId
                  );
                }}
                onSelect={selectRow}
              />
            </div>
          )}
          {data && data.size === 0 && (
            <div style={{ paddingTop: '10px' }}>{t('no files found')}</div>
          )}
        </div>
        <Pagination
          totalSize={size}
          limit={limit}
          offset={offset}
          nextPage={this.selectNextPagination}
        />
      </div>
    );
  }
}

TableFileStorage.contextTypes = {
  badgeSocket: PropTypes.object
};

export default connect(
  (state, props) => ({
    user: state.getIn(['user', 'user']),
    company: state.get('company'),
    supportUser: state.getIn(['chat', 'supportUser']),
    selected: state.getIn(['storage', 'selected']),
    offset: state.getIn(['storage', props.section, 'offset']),
    limit: state.getIn(['storage', props.section, 'limit']),
    size: state.getIn(['storage', props.section, 'size'])
  }),
  (dispatch) =>
    bindActionCreators(
      {
        showModal: modalActions.showModal,
        hideModal: modalActions.hideModalDialog,
        deleteAttach: ordersAction.deleteAttache
      },
      dispatch
    )
)(translate(['ui'], { wait: true })(TableFileStorage));
