import React, { useCallback, useEffect, useMemo, useState } from 'react';
import cn from 'classnames';
import { Table } from '@components';
import { useParams, useActions, useTranslate } from '@hooks';
import { Filter } from '@link/react-components';
import CustomCell from './components/CustomCell/CustomCell';
import { generateColumns, generateRows } from './services/table';
import { useFilter } from './hooks/useFilter';
import { filterText, isEveryOptionNoChecked } from './utils';
import './global.css';
import styles from './ComparisonTable.module.css';
import * as compareStorage from '../../../../../components/PurchaseRequests/MyRequests/storage';

const ComparisonTable = ({ request, responses: _responses, loading }) => {
  const t = useTranslate();
  const { requestId } = useParams();
  const { removeFromCompare } = useActions(compareStorage);

  const [responses, setResponses] = useState(_responses);

  useEffect(() => {
    setResponses(_responses);
  }, [JSON.stringify(_responses)]);

  const { options, onCheck, clearOptionsChecked } = useFilter([
    {
      checked: false,
      value: 'count',
      label: t('purchaseRequestComparison.filter.count')
    },
    {
      checked: false,
      value: 'price',
      label: t('purchaseRequestComparison.filter.price')
    },
    {
      checked: false,
      value: 'total',
      label: t('purchaseRequestComparison.filter.total')
    }
  ]);

  const [hiddenCells, setHiddenCells] = useState([]);

  useEffect(() => {
    if (isEveryOptionNoChecked(options)) {
      setHiddenCells([]);
      return;
    }
    setHiddenCells(
      options.filter(({ checked }) => !checked).map(({ value }) => value)
    );
  }, [options]);

  const onDeleteColumn = useCallback((responseId) => {
    removeFromCompare({ requestId, responseId });
    setResponses((prevState) =>
      prevState.filter((response) => response.id !== responseId)
    );
  }, []);

  const columns = useMemo(
    () =>
      generateColumns(responses, {
        onDeleteColumn,
        filterComponent: (
          <Filter
            onCheck={onCheck}
            options={options}
            clearOptionsChecked={clearOptionsChecked}
            headerTitle={filterText(options)}
          />
        )
      }),
    [
      JSON.stringify(responses),
      options,
      onCheck,
      clearOptionsChecked,
      onDeleteColumn
    ]
  );

  const rows = useMemo(
    () => generateRows(request, responses),
    [JSON.stringify(request), JSON.stringify(responses)]
  );

  const components = useMemo(
    () => ({
      body: {
        cell: (props) => <CustomCell {...props} hiddenCells={hiddenCells} />
      }
    }),
    [hiddenCells]
  );

  return (
    <Table
      className={cn('comparison-purchase-response-table', styles.table)}
      loading={loading}
      rowClassName={styles.row}
      columns={columns}
      rows={rows}
      pagination={false}
      components={components}
    />
  );
};

export default ComparisonTable;
