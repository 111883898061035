import React from 'react';
import {
  Modal,
  Col,
  Form,
  FormGroup,
  FormControl,
  ControlLabel,
  Button,
  InputGroup
} from 'react-bootstrap';
import { connect } from 'react-redux';
import { translate } from 'react-i18next';
import * as modalActionCreators from '../../../action-creators/modal';
import * as priceCategoriesActionCreators from '../../../action-creators/catalog/priceCategories';

const SingleCatalogPriceCategoriesModal = translate(['ui'], { wait: true })(
  class extends React.Component {
    getProperty(field) {
      return this.props.priceCategory && this.props.priceCategory.has(field)
        ? this.props.priceCategory.get(field)
        : '';
    }

    validationState(field) {
      const { priceCategory } = this.props;
      return priceCategory && priceCategory.get(field) ? '' : 'error';
    }

    getDiscountValidationState() {
      const { priceCategory } = this.props;
      return priceCategory &&
        priceCategory.get('discount') &&
        priceCategory.get('discount') >= 0 &&
        priceCategory.get('discount') < 100
        ? ''
        : 'error';
    }

    asterisk() {
      return <span style={{ color: 'red' }}>*</span>;
    }

    render() {
      const {
        t,
        catalog,
        captionKey,
        savePriceCategory,
        hideModalDialog,
        saveDisabled,
        updatePriceCategoryField,
        trimPriceCategoryField,
        clearState
      } = this.props;

      const inputProps = (field) => ({
        value: this.getProperty(field),
        onChange: (e) => updatePriceCategoryField(field, e.target.value),
        onBlur: () => trimPriceCategoryField(field)
      });

      return (
        <Modal
          id="productCharacteristicsModalWindow"
          onHide={() => {
            hideModalDialog();
            clearState();
          }}
          show>
          <Modal.Header>
            <Modal.Title>
              {t(captionKey)}
              <span
                className="modal-header-cross pull-right"
                onClick={() => {
                  hideModalDialog();
                  clearState();
                }}
              />
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form horizontal>
              <FormGroup
                controlId="name"
                validationState={this.validationState('name')}>
                <Col
                  componentClass={ControlLabel}
                  xs={5}
                  style={{ textAlign: 'left' }}>
                  {`${t('Name')} `}
                  {this.asterisk()}
                </Col>
                <Col xs={7}>
                  <FormControl type="text" {...inputProps('name')} />
                </Col>
              </FormGroup>
              <FormGroup
                controlId="discount"
                validationState={this.getDiscountValidationState()}>
                <Col
                  componentClass={ControlLabel}
                  xs={5}
                  style={{ textAlign: 'left' }}>
                  {`${t('discount')} `}
                  {this.asterisk()}
                </Col>
                <Col xs={7}>
                  <InputGroup>
                    <FormControl type="number" {...inputProps('discount')} />
                    <InputGroup.Addon>%</InputGroup.Addon>
                  </InputGroup>
                </Col>
              </FormGroup>
              <FormGroup
                controlId="minOrder"
                validationState={this.validationState('minOrder')}>
                <Col
                  componentClass={ControlLabel}
                  xs={5}
                  style={{ textAlign: 'left' }}>
                  {`${t('Minimal order')} `}
                  {this.asterisk()}
                </Col>
                <Col xs={7}>
                  <InputGroup>
                    <FormControl type="number" {...inputProps('minOrder')} />
                    <InputGroup.Addon>
                      {catalog.get('currency')}
                    </InputGroup.Addon>
                  </InputGroup>
                </Col>
              </FormGroup>
              <FormGroup
                controlId="requiredFieldsLabel"
                style={{ marginBottom: 0 }}>
                <Col
                  componentClass={ControlLabel}
                  xs={5}
                  style={{
                    fontSize: '12px',
                    color: 'lightgrey',
                    textAlign: 'left'
                  }}>
                  {this.asterisk()}
                  {` ${t('Required fields')}`}
                </Col>
              </FormGroup>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button
              id="saveCharacteristicButton"
              bsStyle="primary"
              disabled={saveDisabled}
              onClick={() => savePriceCategory()}>
              {t('Save')}
            </Button>
          </Modal.Footer>
        </Modal>
      );
    }
  }
);

const mapStateToProps = (state) => {
  const priceCategory = state.getIn(['catalog', 'priceCategory']);
  return {
    catalog: state.getIn(['catalog', 'item']),
    validator: state.get('validator'),
    priceCategory,
    saveDisabled: !(
      priceCategory &&
      priceCategory.get('name') &&
      priceCategory.get('minOrder') &&
      priceCategory.get('discount') &&
      priceCategory.get('discount') < 100 &&
      priceCategory.get('discount') >= 0
    )
  };
};

export default connect(mapStateToProps, {
  ...modalActionCreators,
  ...priceCategoriesActionCreators
})(SingleCatalogPriceCategoriesModal);
