import React from 'react';
import { isEmpty } from 'ramda';
import block from 'bem-cn-lite';
import Section from '../../common/components/section';

const b = block('snm-short-nest-section');

const NestedList = ({
  listOfSection = [],
  activeIndex,
  isShowBadge = true
}) => {
  if (isEmpty(listOfSection)) return null;

  return listOfSection.map((nestSection, key) => (
    <li className={b()} key={key}>
      <Section
        section={nestSection}
        isActive={activeIndex === key}
        isShowBadge={isShowBadge}
      />
    </li>
  ));
};

export default NestedList;
