import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
// import block from 'bem-cn-lite';
import { translate } from 'react-i18next';
import {
  Modal,
  Button,
  FormGroup,
  FormControl,
  ControlLabel,
  Form
} from 'react-bootstrap';
import './styles/modal_theme_default.styl';

import { ModalContainer } from './elements';
import * as modalActions from '../../action-creators/modal';
import * as orderActions from '../../action-creators/orders';

// const b = block('modal-container');

class ModalAddOrderComment extends Component {
  constructor(props) {
    super(props);
    this.state = {
      comment: ''
    };
    this.hideModal = this.hideModal.bind(this);
    this.setComment = this.setComment.bind(this);
    this.submit = this.submit.bind(this);
  }

  setComment(e) {
    this.setState({ comment: e.target.value });
  }

  hideModal() {
    this.props.hideModalDialog();
    this.props.onClosed();
  }

  async submit() {
    const { badgeSocket: socket } = this.context;
    const { order, addOrderComment, hideModalDialog, company } = this.props;
    await addOrderComment(order.get('id'), this.state.comment);
    const companyId =
      company.get('id') === order.get('supplier')
        ? order.get('customer')
        : order.get('supplier');
    socket.emit('updateCompanyBadges', JSON.stringify({ companyId }));
    return hideModalDialog();
  }

  render() {
    const {
      t,
      title = 'Add comment to order',
      order,
      saveButtonText = 'Send'
    } = this.props;

    const orderNumber = order.get('number');
    const desc = 'Input comment to order #';

    return (
      <Modal id="addOrderComment" onHide={this.hideModal} show>
        <ModalContainer mods={{ type: 'default', theme: 'default' }}>
          <Modal.Header>
            <Modal.Title>
              {t(title)}
              <span
                className="modal-header-cross pull-right"
                onClick={() => this.hideModal()}
              />
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <FormGroup controlId="comment">
                <ControlLabel
                  style={{
                    fontWeight: 'normal',
                    fontSize: '14px'
                  }}>
                  {t(desc)}
                  {orderNumber}
                </ControlLabel>
                <FormControl
                  componentClass="textarea"
                  rows="5"
                  maxLength="500"
                  value={this.state.comment}
                  onChange={this.setComment}
                />
                <h6 className="pull-right">
                  <small>
                    {this.state.comment.length}
                    /500
                  </small>
                </h6>
              </FormGroup>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button id="cancel" className="pull-left" onClick={this.hideModal}>
              {t('Cancel')}
            </Button>
            <Button
              id="saveOrderComment"
              bsStyle="primary"
              onClick={this.submit}
              disabled={!this.state.comment}>
              {t(saveButtonText)}
            </Button>
          </Modal.Footer>
        </ModalContainer>
      </Modal>
    );
  }
}

ModalAddOrderComment.propTypes = {
  t: PropTypes.func.isRequired,
  hideModalDialog: PropTypes.func.isRequired,
  onClosed: PropTypes.func
};

ModalAddOrderComment.childContextTypes = {
  b: PropTypes.func
};

ModalAddOrderComment.defaultProps = {
  onClosed: () => {}
};

ModalAddOrderComment.contextTypes = {
  badgeSocket: PropTypes.object
};

export default connect(
  (state) => ({
    company: state.get('company')
  }),
  (dispatch) =>
    bindActionCreators(
      {
        showModal: modalActions.showModal,
        hideModalDialog: modalActions.hideModalDialog,
        addOrderComment: orderActions.addOrderComment
      },
      dispatch
    )
)(translate(['ui'], { wait: true })(ModalAddOrderComment));
