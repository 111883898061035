import React from 'react';
import {
  makeDashboardVM,
  getTooltipText,
  initOnTabClick,
  isIconHasUnreadShown,
  getCurrentTab,
  isActiveTab,
  mapList,
  getKey
} from '../../../../../../frontend-web-publisher/src/components/ChatWidget/modules/tabs/dataType';
import Tooltip from '../../../../../../frontend-web-publisher/src/components/ChatWidget/Tooltip';
import Icon from '../../../../icons';

const wrapper = ({ config, activeTab, chatUnreadMsgsCount, handler }) =>
  makeDashboardVM({
    activeTab,
    chatUnreadMsgsCount,
    handler,
    config
  });

const getIcon = (icon) => {
  if (icon === 'contacts') return <Icon icon="users" size={18} />;
  if (icon === 'chats') return <Icon icon="chat" size={18} />;
  if (icon === 'archives') return <Icon icon="archive-download" size={18} />;
  throw new Error('no such icon');
};

const getCNTabWrapper = (tabItem) => {
  let className = `cws-tabs__tab cws-tooltip_outside-hover`;
  if (isActiveTab(tabItem)) className += ' active';
  return className;
};

function Tab({ tabItem }) {
  return (
    <div
      className={`${getCNTabWrapper(tabItem)}`}
      onClick={initOnTabClick(tabItem)}>
      <Tooltip text={getTooltipText(tabItem)}>
        {getIcon(getCurrentTab(tabItem))}
        {isIconHasUnreadShown(tabItem) && (
          <span className="cws-tabs__tab-haveunread" />
        )}
      </Tooltip>
    </div>
  );
}

const CWTabsList = ({ config, ...props }) => {
  const viewModel = wrapper({ config, ...props });
  return mapList(
    (i) => <Tab key={`cws-tabs-item-${getKey(i)}`} tabItem={i} />,
    viewModel
  );
};

export default CWTabsList;
