import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import useChunkLoaderByScroll from '../../../hooks/chunkLoadBlockListByScroll';
import CWSContactListItem from './item';
import Scrollbox from '../../../../Scrollbox';

const CWSContactList = ({ viewModel, handler }) => {
  const listRef = useRef(null);

  useChunkLoaderByScroll({
    ref: listRef,
    blockList: viewModel,
    onLoad: () => handler('load-more')
  });

  return (
    <Scrollbox className="cws-contact-list" ref={listRef}>
      {viewModel.list.map((contact) => (
        <CWSContactListItem
          key={contact.key}
          employeeId={contact.employeeId}
          active={contact.active}
          avatarSrc={contact.avatarSrc}
          userName={contact.userName}
          companyName={contact.companyName}
          isAdmin={contact.isAdmin}
          isBlocked={contact.isBlocked}
          config={contact.config}
          handler={(itemMenu) => handler(itemMenu, contact)}
        />
      ))}
    </Scrollbox>
  );
};

CWSContactList.propTypes = {
  viewModel: PropTypes.object.isRequired,
  handler: PropTypes.func.isRequired
};

export default CWSContactList;
