import React, { useEffect, useState } from 'react';
import { nanoid } from 'nanoid';
import { useUploadFiles } from './hooks';
import View from './view';
import './style.css';

function transformFiles(files = []) {
  return files.map((f) => ({ _innerId: nanoid(), status: 'loaded', data: f }));
}

function FilesInput({
  t,
  title,
  btnAddFile = '+',
  url = '/api/storage/upload/:filename',
  list = [],
  maxFiles = 5,
  onUploadFile,
  onDeleteFile,
  onFilesChanged,
  onMaxFilesError,
  isShowFilesCount
}) {
  const [files, setFiles] = useState([]);

  useEffect(() => {
    setFiles(transformFiles(list));
  }, [list]);

  const { disabled, uploadFiles, deleteFile } = useUploadFiles({
    url,
    files,
    setFiles,
    maxFiles,
    onMaxFilesError,
    onUploadFile,
    onDeleteFile
  });

  useEffect(() => {
    if (typeof onFilesChanged === 'function') {
      onFilesChanged(files);
    }
  }, [files]);

  return (
    <View
      t={t}
      title={title}
      btnAddFile={btnAddFile}
      files={files}
      disabled={disabled}
      onUploadFiles={uploadFiles}
      onDeleteFile={deleteFile}
      isShowFilesCount={isShowFilesCount}
    />
  );
}

export default FilesInput;
