import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { translate } from 'react-i18next';
import {
  Modal,
  Col,
  Form,
  FormGroup,
  ControlLabel,
  Button
} from 'react-bootstrap';

import '../styles/modal_theme_default.styl';
import InputNumber from '../../InputNumber';

import { ModalContainer } from '../elements';

class ModalAddDiscount extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cost: 1
    };
    this.setCost = this.setCost.bind(this);
  }

  setCost(cost) {
    this.setState({ cost: Number(cost) });
  }

  render() {
    const { t, captionKey, hideModalDialog, bulkUpdateCost } = this.props;

    return (
      <ModalContainer mods={{ theme: 'default' }}>
        <Modal onHide={hideModalDialog} show>
          <Modal.Header>
            <Modal.Title>
              {t(captionKey || 'Edit baseprice of products')}
              <span
                className="modal-header-cross pull-right"
                onClick={hideModalDialog}
              />
            </Modal.Title>
          </Modal.Header>
          <Modal.Body style={{ overflow: 'hidden' }}>
            <Form horizontal onSubmit={(e) => e.preventDefault()}>
              <Col xs={12}>
                <FormGroup
                  controlId="discount"
                  style={{ display: 'flex', alignItems: 'center' }}>
                  <ControlLabel style={{ fontWeight: 'normal' }}>
                    {t('For selected items of goods enter the price')}
                  </ControlLabel>
                  <InputNumber
                    style={{ marginLeft: 'auto' }}
                    value={this.state.cost}
                    defaultValue={1}
                    min={1}
                    onChange={this.setCost}
                  />
                </FormGroup>
              </Col>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button id="cancel" className="pull-left" onClick={hideModalDialog}>
              {t('Cancel')}
            </Button>
            <Button
              bsStyle="primary"
              onClick={async () => {
                await bulkUpdateCost(this.state.cost);
                hideModalDialog();
              }}>
              {t('Change')}
            </Button>
          </Modal.Footer>
        </Modal>
      </ModalContainer>
    );
  }
}

ModalAddDiscount.propTypes = {
  t: PropTypes.func.isRequired
};

export default translate(['ui'], { wait: true })(ModalAddDiscount);
