import React from 'react';
import cn from 'classnames';
import { Icon, Typography } from '@components';
import { useTranslate } from '../../../../../../../TranslateProvider';
import styles from './ResponseHeaderCell.module.css';
import Button from '../Button/Button';
import { getTranslate } from '../../utils';
import iconsView from '../../../../../../../assets/images/constants';

const ResponseHeaderCell = ({
  containerClassName,
  className,
  onDelete,
  responseId,
  disableDeleteColumn,
  children
}) => {
  const t = useTranslate();

  return (
    <div
      className={cn(
        styles.container,
        disableDeleteColumn && styles.disableDelete,
        containerClassName
      )}>
      <div className={styles.buttonWrapper}>
        <Button
          before={<Icon name={iconsView.Trash} size="xs" />}
          onClick={(event) =>
            !disableDeleteColumn && onDelete(responseId, event)
          }>
          {getTranslate(t, 'delete')}
        </Button>
      </div>
      <Typography
        variant="body2Med"
        className={cn(styles.text, className)}
        title={children}>
        {children}
      </Typography>
    </div>
  );
};

export default ResponseHeaderCell;
