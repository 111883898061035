/* eslint-disable max-len */
import React from 'react';

const RequestIcon = () => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      className="snm-icon__path"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4 0C2.89543 0 2 0.89543 2 2V16C2 17.1046 2.89543 18 4 18H11L16 13V2C16 0.895431 15.1046 0 14 0H4ZM5 4H13V6H5V4ZM15 12L10 17V14C10 12.8954 10.8954 12 12 12H15ZM10 8H5V10H10V8Z"
      fill="#C6CAE3"
    />
  </svg>
);

export default RequestIcon;
