import React from 'react';
import { useVideoChatContext } from '../../hooks/useVideoChatContext';
import IconSimpleSearch from '../../../../components/ChatWidget/ButtonIcon/icons/simple-search';

const ButtonHideVideoChat = () => {
  const { hideWindow } = useVideoChatContext();

  return (
    <button
      type="button"
      className="video-chat__btn video-chat__btn-hide"
      onClick={hideWindow}>
      <IconSimpleSearch />
    </button>
  );
};

export default ButtonHideVideoChat;
