import { Map } from 'immutable';

const initState = () => Map();

const set = (data, { priceCategory }) => data.clear().merge(priceCategory);

const clear = (data) => data.clear();

const selectPriceCategory = (state = initState(), { type, payload }) => {
  switch (type) {
    case 'ORDER:PRICE_CATEGORY:SELECT:LOAD':
    case 'ORDERS:PRICE_CATEGORY:SELECT:SET:SUCCESS':
      return set(state, payload);
    case 'ORDERS:PRICE_CATEGORY:SELECT:CLEAR':
      return clear(state);
    default:
      return state;
  }
};

export default selectPriceCategory;
