import { useState, useEffect, useCallback, useRef } from 'react';
import { useActions } from '@hooks';
import { isDesktopApp } from '../../../lib/goodwix';
import * as useCases from '../../ChatWidget/useCases';

export function useBrowserNotifications() {
  const [loading, setLoading] = useState(true);
  const [subscribe, setSubscribe] = useState(false);
  const [error, setError] = useState(null);

  const subscriptionBlockRef = useRef(true);

  const { handleNotificationSettings } = useActions({
    handleNotificationSettings: useCases.handleNotificationSettings
  });

  useEffect(() => {
    async function init() {
      return new Promise((resolve, reject) => {
        if ('Notification' in window && navigator.serviceWorker) {
          navigator.serviceWorker
            .register(`${process.env.UNICAT_MESSENGER_SERVICE}/sw.js`)
            .then((serviceWorkerRegistration) =>
              serviceWorkerRegistration.pushManager.getSubscription()
            )
            .then(resolve)
            .catch(reject);
        } else {
          reject('Service workers do not support!');
        }
      });
    }

    setLoading(true);

    init()
      .then((subscription) => setSubscribe(Boolean(subscription)))
      .catch(setError)
      .finally(() => {
        setLoading(false);
        subscriptionBlockRef.current = false;
      });
  }, []);

  const toggleSubscribe = useCallback(() => {
    if (subscriptionBlockRef.current) {
      console.log('Subscription processed...');
      return;
    }

    subscriptionBlockRef.current = true;

    setSubscribe((prevSubscribe) => {
      const actionType = prevSubscribe ? 'unsubscribe' : 'subscribe';

      handleNotificationSettings(actionType).finally(
        () => (subscriptionBlockRef.current = false)
      );

      return !prevSubscribe;
    });
  }, []);

  return {
    loading,
    error,
    subscribe,
    toggleSubscribe,
    disabled: isDesktopApp()
  };
}
