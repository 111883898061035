export const haveUpdatePermission = (permissions) =>
  permissions && permissions.get('update');

export const haveDeletePermission = (permissions) =>
  permissions && permissions.get('delete');

export const isResponseAllowed = (request) => {
  if (typeof request.get('isResponseAllowed') !== 'boolean') {
    console.error('Invalid prop [isResponseAllowed] was passed');
    return false;
  }

  return request.get('isResponseAllowed');
};

export function getLocationTitle(location, language) {
  if (!location) return '';

  return location.translate[language] || '';
}
