import React from 'react';
import { Button } from 'react-bootstrap';
import { useTranslate } from '../../../TranslateProvider';

const MainRespondButton = ({ onClick }) => {
  const t = useTranslate();

  return (
    <div className="form-respond-button-respond-block">
      <Button bsStyle="primary" onClick={onClick}>
        {t('respondForm.mainRespondButton')}
      </Button>
    </div>
  );
};

export default MainRespondButton;
