import React from 'react';
import { Icon } from '@link/react-components';
import Tooltip from '../ChatWidget/Tooltip';
import styles from './RNPIcon.module.css';

export function RNPIcon({ isRNP }) {
  if (isRNP) {
    return (
      <Tooltip text="company.rnp">
        <div className={styles.icon}>
          <Icon
            iconName="rnp"
            width={32}
            height={22}
            className={styles.isRNP}
          />
        </div>
      </Tooltip>
    );
  }

  return (
    <Tooltip text="company.noRnp">
      <div className={styles.icon}>
        <Icon iconName="no-rnp" width={32} height={22} />
      </div>
    </Tooltip>
  );
}
