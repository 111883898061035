import React from 'react';
import { connect } from 'react-redux';
import { translate } from 'react-i18next';
import { bindActionCreators } from 'redux';
import { getUserLang } from '../../ChatWidget/getter';
import * as useCase from '../../ChatWidget/useCases';
import './demoLogoutBtn.styl';

const DemoLogoutBtn = ({ t, handleDemoModals }) => (
  // eslint-disable-next-line react/jsx-filename-extension
  <span
    id="logoutDemoButton"
    className="demo-logout-btn demo-logout-btn_active"
    onClick={() => handleDemoModals('exit-from-demo')}>
    {t('Logout from demo')}
  </span>
);

export default connect(
  (state) => ({
    language: getUserLang(state)
  }),
  (dispatch) => bindActionCreators(useCase, dispatch)
)(translate()(DemoLogoutBtn));
