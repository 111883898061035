import { PurchaseRequest } from '../../domain/entities';
import { PurchaseRequestDTO } from '../dto';

export class PurchaseRequestMapper {
  static toDomain(props) {
    return new PurchaseRequest(props);
  }

  static toDTO(data) {
    return new PurchaseRequestDTO(data);
  }
}
