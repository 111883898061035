import React from 'react';
import PropTypes from 'prop-types';
import { translate } from 'react-i18next';

const ChannelNoAccess = ({
  t,
  chat,
  currentMemberIsChannelOwner,
  currentMemberLeavedChat,
  handleChatNotifications
}) => (
  <div className="cwm-notify-container" style={{ display: 'block' }}>
    <span>{`${t(`${chat.type.toLowerCase()}.help-cant-send-msg`)} `}</span>
    {(currentMemberIsChannelOwner || currentMemberLeavedChat) && (
      <button
        type="button"
        className="channel__btn-restore-send"
        onClick={() => handleChatNotifications('restore-access', chat)}>
        {t(`${chat.type.toLowerCase()}.help-restore-access`)}
      </button>
    )}
  </div>
);

ChannelNoAccess.propTypes = {
  t: PropTypes.func.isRequired,
  chat: PropTypes.object.isRequired,
  currentMemberIsChannelOwner: PropTypes.bool.isRequired,
  currentMemberLeavedChat: PropTypes.bool.isRequired,
  handleChatNotifications: PropTypes.func.isRequired
};

export default translate()(ChannelNoAccess);
