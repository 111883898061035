import { fromJS } from 'immutable'; // eslint-disable-line

import {
  initState,
  setOrderAndPrice,
  selectItem,
  updateItemCount,
  setOrderStatus,
  setItem,
  setOrderInfo,
  removeItems,
  setOrdersStatuses,
  addOrderComment,
  setComments,
  clearComments,
  selectOrderItem,
  selectOrderSplitItem,
  changeOrderProductCount,
  clearSelected,
  selectOrderDraftSplitItem,
  selectOrderDraftSplitItemSharedPrice,
  selectOrderAdditionalItem,
  changeOrderProductVat,
  changeOrderProductCost,
  addOrUpdateUnsentOrdersComments,
  removeUnsentOrderComment,
  setOrderHistory
} from '../work-with-data/orders';

const setAttaches = (data, { attaches, badges, size, offset, limit }) =>
  data
    .set('attaches', fromJS(attaches))
    .set('size', size)
    .set('offset', offset)
    .set('limit', limit)
    .set('badges', fromJS(badges));

const addAttache = (data, attacheData) => {
  const index = data
    .get('attaches')
    .findIndex((file) => file.get('url') === attacheData.url);
  if (index === -1) {
    return data
      .update('attaches', (attaches) => attaches.push(fromJS(attacheData)))
      .update('size', (size) => size + 1);
  }
  return data.updateIn(['attaches', index], (file) =>
    file.set('updatedAt', attacheData.updatedAt).set('size', attacheData.size)
  );
};

const deleteAttache = (data, { url }) =>
  data
    .update('attaches', (attaches) =>
      attaches.filter((file) => !(file.get('url') === url))
    )
    .update('size', (size) => size - 1);

const setAttacheStatus = (data, { url, status }) => {
  const index = data
    .getIn(['order', 'attache'])
    .findIndex((file) => file.get('url') === url);
  return data.updateIn(['order', 'attache', index], (file) =>
    file.set('status', status)
  );
};

const setDeliveryDate = (data, { date }) =>
  data.setIn(['order', 'deliveryDate'], date);

const setPaid = (data, { value }) => data.setIn(['order', 'paid'], value);

const setPriceCategoryCondition = (data, { id, isSelect }) => {
  const conditions = data.getIn(['order', 'priceCategoryConditions']);
  const index = conditions.findIndex((cond) => cond.get('id') === id);
  return data.setIn(
    ['order', 'priceCategoryConditions', index, 'isSelect'],
    isSelect
  );
};

const setOrderStatusHistory = (data, { statusHistory }) =>
  data.setIn(['order', 'statusHistory'], fromJS(statusHistory));

const setResponsibleForCustomer = (data, { userId }) =>
  data.setIn(['order', 'responsibleForCustomer'], userId);
const setResponsibleForSupplier = (data, { userId }) =>
  data.setIn(['order', 'responsibleForSupplier'], userId);

const setTableAttrs = (data, { excluded }) => data.set('excluded', excluded);

const setPriceCategoryConditions = (data, { priceCategoryConditions }) =>
  data.setIn(
    ['order', 'priceCategoryConditions'],
    fromJS(priceCategoryConditions)
  );

const ordersReducer = (state = initState(), { type, payload }) => {
  switch (type) {
    case 'ORDER:CLEAR':
      return initState();
    case 'ORDER:GET_ORDER:SUCCESS':
      return setOrderAndPrice(state, payload);
    case 'ORDERS:SELECT_ITEM':
      return selectItem(state, payload);
    case 'ORDERS:SELECT_ORDER_ITEM':
      return selectOrderItem(state, payload);
    case 'ORDERS:SELECT_ORDER_SPLIT_ITEM':
      return selectOrderSplitItem(state, payload);
    case 'ORDERS:SELECT_ORDER_ADDITIONAL_ITEM':
      return selectOrderAdditionalItem(state, payload);
    case 'ORDERS:SELECT_ORDER_DRAFT_SPLIT_ITEM':
      return selectOrderDraftSplitItem(state, payload);
    case 'ORDERS:SELECT_ORDER_DRAFT_SPLIT_ITEM_SHARED_PRICE':
      return selectOrderDraftSplitItemSharedPrice(state, payload);
    case 'ORDER:ORDER_DETAILS:SUCCESS':
      return setOrderAndPrice(state, payload);
    case 'ORDER:DELETE_ITEM:SUCCESS':
      return updateItemCount(state, payload);
    case 'ORDER:REMOVE_ITEMS:SUCCESS':
      return removeItems(state, payload);
    case 'ORDER:ADD_ITEM:SUCCESS':
      return setItem(state, payload);
    case 'ORDER:UPDATE_ITEM_COUNT:SUCCESS':
      return updateItemCount(state, payload);
    case 'ORDER:SET_STATUS:SUCCESS':
      return setOrderStatus(state, payload);
    case 'ORDER:ORDER_INFO:SUCCESS':
      return setOrderInfo(state, payload);
    case 'ORDER:ORDER_STATUS_HISTORY:SUCCESS':
      return setOrderStatusHistory(state, payload);
    case 'ORDER:GET_BAIDGE_STATUSES:SUCCESS':
      return setOrdersStatuses(state, payload);
    case 'ORDER:ADD_COMMENT:SUCCESS':
      return addOrderComment(state, payload);
    case 'ORDER:GET_COMMENTS:SUCCESS':
      return setComments(state, payload);
    case 'ORDER:CLEAR_COMMENTS':
      return clearComments(state, payload);
    case 'ORDER:GET_ATTACHES:SUCCESS':
      return setAttaches(state, payload);
    case 'ORDER:FILE_ATTACHE:SUCCESS':
      return addAttache(state, payload);
    case 'ORDER:DELETE_ATTACHE:SUCCESS':
      return deleteAttache(state, payload);
    case 'ORDER:ATTACHE_SET_STATUS:SUCCESS':
      return setAttacheStatus(state, payload);
    case 'ORDER:UPDATE_DATE:SUCCESS':
      return setDeliveryDate(state, payload);
    case 'ORDER:SET_PRICE_CATEGORY_CONDITION:REQUEST':
      return setPriceCategoryCondition(state, payload);
    case 'ORDER:CHANGE_ORDER_PRODUCT_COUNT:SUCCESS':
      return changeOrderProductCount(state, payload);
    case 'ORDER:CHANGE_ORDER_PRODUCT_COST:SUCCESS':
      return changeOrderProductCost(state, payload);
    case 'ORDER:UPDATE_RESPONSIBLE_FOR_CUSTOMER:SUCCESS':
      return setResponsibleForCustomer(state, payload);
    case 'ORDER:UPDATE_RESPONSIBLE_FOR_SUPPLIER:SUCCESS':
      return setResponsibleForSupplier(state, payload);
    case 'ORDERS:SET_ORDERS_DETAILS_ATTRS:SUCCESS':
      return setTableAttrs(state, payload);
    case 'ORDER:CLEAR_SELECTED':
      return clearSelected(state);
    case 'ORDER:SET_PRICE_CATEGORY_CONDITION:SUCCESS':
      return setPriceCategoryConditions(state, payload);
    case 'ORDER:UPDATE_PAID:SUCCESS':
      return setPaid(state, payload);
    case 'ORDER:CHANGE_ORDER_PRODUCT_VAT:SUCCESS':
      return changeOrderProductVat(state, payload);
    case 'ORDER:ADD_UNSENT_COMMENT':
      return addOrUpdateUnsentOrdersComments(state, payload);
    case 'ORDER:REMOVE_UNSENT_COMMENT':
      return removeUnsentOrderComment(state, payload);
    case 'ORDER:GET_ORDER_HISTORY:SUCCESS':
      return setOrderHistory(state, payload);
    default:
      return state;
  }
};

export default ordersReducer;
