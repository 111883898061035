/* eslint-disable max-len */
import React from 'react';

const EditIcon = () => (
  <svg
    width="17"
    height="18"
    viewBox="0 0 17 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M5.37607 16.5816V17.0816H5.58144L5.72752 16.9373L5.37607 16.5816ZM1 16.5816H0.5V17.0816H1V16.5816ZM1 12.2056L0.644361 11.8541L0.5 12.0002V12.2056H1ZM15.569 6.50867L15.9205 6.86431L15.9205 6.86431L15.569 6.50867ZM15.5774 3.67192L15.931 3.31837L15.931 3.31837L15.5774 3.67192ZM13.9098 2.00426L14.2634 1.65071L14.2634 1.65071L13.9098 2.00426ZM11.073 2.01263L10.7174 1.66118L10.7174 1.66118L11.073 2.01263ZM5.37607 16.0816H1V17.0816H5.37607V16.0816ZM1.5 16.5816V12.2056H0.5V16.5816H1.5ZM5.72752 16.9373L15.9205 6.86431L15.2176 6.15303L5.02461 16.226L5.72752 16.9373ZM15.931 3.31837L14.2634 1.65071L13.5563 2.35781L15.2239 4.02547L15.931 3.31837ZM10.7174 1.66118L0.644361 11.8541L1.35564 12.557L11.4287 2.36409L10.7174 1.66118ZM14.2634 1.65071C13.283 0.670294 11.692 0.674989 10.7174 1.66118L11.4287 2.36409C12.0134 1.77238 12.968 1.76956 13.5563 2.35781L14.2634 1.65071ZM15.9205 6.86431C16.9066 5.88974 16.9114 4.29877 15.931 3.31837L15.2239 4.02546C15.8121 4.61371 15.8093 5.56829 15.2176 6.15303L15.9205 6.86431ZM10 3.58163L14 7.58163L14.7071 6.87453L10.7071 2.87453L10 3.58163Z"
      fill="#767B92"
    />
  </svg>
);

export default EditIcon;
