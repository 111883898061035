import React from 'react';
import Icon from 'react-components/src/icons';
import Text from 'react-components/src/components/Typography';
import styles from './dropMenuItem.module.css';

export const DropMenuItem = ({
  iconSize = 18,
  iconName,
  textContent,
  onClick
}) => (
  <div className={styles.item} onClick={onClick}>
    <Icon size={iconSize} icon={iconName} />
    <Text variant="body1Reg">{textContent}</Text>
  </div>
);
