export const ORDER_SINGLE = 'ORDER_SINGLE';
export const ORDER_PARTIAL = 'ORDER_PARTIAL';

function getOrderType({ parts, additionalProducts }) {
  const partsLength = parts.length;
  const hasAdditionalProducts = Boolean(additionalProducts.length);

  if (partsLength === 1 && !hasAdditionalProducts) return ORDER_SINGLE;
  return ORDER_PARTIAL;
}

const makeOrderInfo = ({ id, number, status, createdAt }) => ({
  id,
  number,
  status,
  createdAt
});

const makeOrderData = ({ summary, products }) => ({
  type: 'SINGLE',
  summary,
  products
});

const makePartOfOrder = ({ summary, products }) => ({
  type: 'PARTIAL_OF_ORDER',
  summary,
  products
});

const makeAdditionalPart = ({ summary, products }) => ({
  type: 'ADDITIONAL',
  summary,
  products
});

const makeSingleOrder = ({ orderInfo, companyInfo, units, order }) => ({
  type: ORDER_SINGLE,
  orderInfo,
  companyInfo,
  units,
  order
});

const transformSingleOrder = ({
  orderId,
  number,
  createdAt,
  status,
  companyInfo,
  parts,
  units
}) => {
  const { summary, products } = parts[0];

  return makeSingleOrder({
    orderInfo: makeOrderInfo({ id: orderId, number, status, createdAt }),
    companyInfo,
    units,
    order: makeOrderData({ summary, products })
  });
};

const makeOrderPartial = ({ orderInfo, companyInfo, units, parts }) => ({
  type: ORDER_PARTIAL,
  orderInfo,
  companyInfo,
  units,
  parts
});

const transformPartialOrder = ({
  orderId,
  number,
  createdAt,
  status,
  companyInfo,
  parts,
  additionalProducts,
  units
}) => {
  const orderInfo = makeOrderInfo({ id: orderId, number, status, createdAt });
  const mainParts = parts.map(makePartOfOrder);
  const additionalParts = additionalProducts.map(makeAdditionalPart);
  const allParts = mainParts.concat(additionalParts);

  return makeOrderPartial({
    orderInfo,
    companyInfo,
    units,
    parts: allParts,
    additionalProducts
  });
};

export const transformOrderHistory = (order) => {
  const {
    orderId,
    status,
    createdAt,
    orderData: {
      orderNumber: number,
      companyInfo,
      units,
      parts,
      additionalProducts
    }
  } = order;

  const type = getOrderType({ parts, additionalProducts });

  if (type === ORDER_SINGLE) {
    return transformSingleOrder({
      orderId,
      number,
      createdAt,
      status,
      companyInfo,
      units,
      parts
    });
  }

  return transformPartialOrder({
    orderId,
    number,
    createdAt,
    status,
    companyInfo,
    units,
    parts,
    additionalProducts
  });
};

export {
  getOrderType,
  makeOrderData,
  makeOrderInfo,
  makeSingleOrder,
  makeAdditionalPart,
  makeOrderPartial,
  makePartOfOrder,
  transformPartialOrder,
  transformSingleOrder
};
