import React, { Component } from 'react';
import PropTypes from 'prop-types';
import block from 'bem-cn-lite';
import { translate } from 'react-i18next';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  Modal,
  Col,
  Form,
  FormGroup,
  FormControl,
  ControlLabel,
  Button,
  HelpBlock
} from 'react-bootstrap';

import './styles/modal_theme_default.styl';
import { ModalContainer } from './elements';

import * as modalActions from '../../action-creators/modal';
import * as catalogActions from '../../action-creators/catalog';

block('modal-container');

class ModalEditUnit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      code: '',
      name: '',
      id: '',
      error: ''
    };
    this.inputProps = this.inputProps.bind(this);
    this.isValidNameField = this.isValidNameField.bind(this);
    this.onChangeValue = this.onChangeValue.bind(this);
    this.checkUnitCorrelation = this.checkUnitCorrelation.bind(this);
  }

  UNSAFE_componentWillMount() {
    const { unit } = this.props;
    this.setState({ name: unit.name, code: unit.code, id: unit.id });
  }

  getProperty(field) {
    return this.state[field] || '';
  }

  isValidNameField() {
    return this.state.name.length < 1;
  }

  asterisk() {
    // eslint-disable-line
    return <span style={{ color: 'red' }}>*</span>;
  }

  inputProps(field) {
    return {
      value: this.state[field],
      onChange: (e) => {
        if (this.state.error) {
          this.setState({ error: '' });
        }
        if (field === 'name') {
          return this.setState({
            [field]: e.target.value.replace(/^\s+|\s(?=\s)/g, '')
          });
        }
        return this.setState({ [field]: e.target.value });
      },
      onKeyDown: (e) => {
        if (
          field === 'code' &&
          !['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'].includes(e.key) &&
          e.keyCode !== 8
        ) {
          e.preventDefault();
        }
      }
    };
  }

  showHelpInfo(field) {
    const { t } = this.props;
    if (!this.state[field] && !this.state.pristine) {
      return (
        <HelpBlock id="invalidFieldMessage" style={{ color: 'red' }}>
          {t('Required fields empty')}
        </HelpBlock>
      );
    }
    return null;
  }

  onChangeValue(field, e) {
    return this.setState({ [field]: e.value });
  }

  checkUnitCorrelation({ name, code, id }) {
    const { units, unitCodes } = this.props;
    const unitName = name.trim().toLowerCase();
    const unitExist = !!units.find(
      (i) =>
        ((i.get('name') || '').toLowerCase() === unitName ||
          (code ? +i.get('code') === +code : false)) &&
        i.get('id') !== id
    );
    const baseUnitCorrelation = !!unitCodes.find(
      (i) =>
        [
          i.getIn(['condDesign', 'nat']),
          i.getIn(['condDesign', 'int']),
          i.getIn(['codeDesign', 'nat']),
          i.getIn(['codeDesign', 'int'])
        ].includes(name) || (code ? +i.get('code') === +code : false)
    );
    return unitExist || baseUnitCorrelation;
  }

  render() {
    const { t, hideModalDialog, updateUnit, callback } = this.props;

    return (
      <ModalContainer mods={{ theme: 'default' }}>
        <Modal
          className="test_modal_create-catalog"
          onHide={() => hideModalDialog()}
          show>
          <Modal.Header>
            <Modal.Title>
              {t('Edit unit')}
              <span
                className="modal-header-cross pull-right"
                onClick={() => hideModalDialog()}
              />
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form horizontal>
              <FormGroup controlId="code">
                <Col
                  componentClass={ControlLabel}
                  xs={4}
                  style={{ textAlign: 'left', marginTop: 15 }}>
                  {t('Code okei')}
                </Col>
                <Col xs={8}>
                  <span
                    className="input-col-length pull-right"
                    style={{ marginBottom: 5 }}>
                    {`${this.getProperty('code').length}/3`}
                  </span>
                  <FormControl
                    type="text"
                    maxLength={3}
                    {...this.inputProps('code')}
                  />
                </Col>
              </FormGroup>
              <FormGroup controlId="name">
                <Col
                  componentClass={ControlLabel}
                  xs={4}
                  style={{ textAlign: 'left', marginTop: 15 }}>
                  {`${t('Name')} `}
                  {this.asterisk()}
                </Col>
                <Col xs={8}>
                  <span
                    className="input-col-length pull-right"
                    style={{ marginBottom: 5 }}>
                    {`${this.getProperty('name').length}/100`}
                  </span>
                  <FormControl
                    type="text"
                    maxLength={100}
                    {...this.inputProps('name')}
                  />
                </Col>
              </FormGroup>
              <FormGroup
                controlId="requiredFieldsLabel"
                style={{ marginBottom: 0, marginTop: 10 }}>
                <Col
                  componentClass={ControlLabel}
                  xs={5}
                  style={{
                    fontSize: '12px',
                    color: 'lightgrey',
                    textAlign: 'left'
                  }}>
                  {this.asterisk()}
                  {` ${t('Required fields')}`}
                </Col>
              </FormGroup>
              {this.state.error && (
                <HelpBlock style={{ marginBottom: 0, fontSize: 14 }}>
                  {t(this.state.error)}
                </HelpBlock>
              )}
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button
              id="cancel"
              className="pull-left"
              onClick={() => hideModalDialog()}>
              {t('Cancel')}
            </Button>
            <Button
              id="saveButton"
              bsStyle="primary"
              className="test_modal-btn_create"
              disabled={this.isValidNameField() || this.state.error}
              onClick={async () => {
                if (!this.checkUnitCorrelation(this.state)) {
                  await updateUnit(this.state);
                  if (callback) {
                    await callback();
                  }
                  hideModalDialog();
                } else {
                  this.setState({ error: 'unit name or code already exist' });
                }
              }}>
              {t('Save')}
            </Button>
          </Modal.Footer>
        </Modal>
      </ModalContainer>
    );
  }
}

ModalEditUnit.propTypes = {
  t: PropTypes.func.isRequired
};

ModalEditUnit.childContextTypes = {
  b: PropTypes.func
};

export default connect(
  (state) => ({
    catalogs: state.getIn(['tables', 'catalogs', 'items']),
    currencyList: state.get('currencyList'),
    user: state.getIn(['user', 'user']),
    unitCodes: state.getIn(['stocks', 'unitCodes']),
    units: state.getIn(['catalogCombine', 'properties', 'units'])
  }),
  (dispatch) =>
    bindActionCreators(
      {
        updateUnit: catalogActions.updateUnit,
        hideModalDialog: modalActions.hideModalDialog
      },
      dispatch
    )
)(translate(['ui'], { wait: true })(ModalEditUnit));
