import React, { useRef } from 'react';
import { Button } from '@link/react-components';
import { useSelector } from 'react-redux';
import { useActions, useNavigate } from '@hooks';
import { Snackbar } from '@components';
import { useAsyncFn } from 'react-use';
import styles from './Header.module.css';
import { useTranslate } from '../../../../../TranslateProvider';
import { HeaderInput } from '../headerInput';
import * as modalActions from '../../../../../action-creators/modal';
import { isErrorOnSave, isPathExist } from '../helpers';
import * as constructorGetters from '../../../../../components/ChatWidget/modules/constructor/getters';
import * as constructorActions from '../../../../../components/ChatWidget/modules/constructor/actions';
import { mapBuilderToDTO } from '../../../../../components/ChatWidget/modules/constructor/mappers';
import iconsView from '../../../../../assets/images/constants';
import { useIsCanBeDeleted } from '../../../../../contexts/RouteIsCanBeDeletedContext';

export const Header = () => {
  const t = useTranslate();
  const navigate = useNavigate();
  const isCanBeDeleted = useIsCanBeDeleted();
  const snackbarRef = useRef(null);
  const { builder, isSaved } = useSelector(constructorGetters.getBuilderState);

  const { errorChecked, saveBuilderPath } = useActions(constructorActions);

  const [state, onSave] = useAsyncFn(async () => {
    const isError = isErrorOnSave(builder.path);
    if (isError) {
      errorChecked();
      return;
    }

    const routeDTO = mapBuilderToDTO(builder);

    await saveBuilderPath(routeDTO);
    snackbarRef.current.show();
  }, [builder]);

  const onSaveClick = () => {
    onSave();
  };

  const { showModal } = useActions(modalActions);

  const isCanExitWithOutSave =
    state.loading || isSaved || !isCanBeDeleted || !isPathExist(builder.path);

  const onExitClick = () => {
    if (isCanExitWithOutSave) {
      navigate('/constructor/list');
      return;
    }

    showModal('SIMPLE_SUBMIT', {
      title: 'constructor.changesNotSavedWillBeDeleted',
      text: 'constructor.exitWithoutSaving',
      textBtnConfirm: 'modals.add-link.btn-exit',
      textBtnCancel: 'modals.add-link.btn-cancel',
      submitAction: () => {
        navigate('/constructor/list');
      }
    });
  };

  return (
    <header className={styles.wrapper}>
      <HeaderInput />
      <div className={styles.rightButtons}>
        <Button
          id="saveButton"
          onClick={onSaveClick}
          disabled={isCanExitWithOutSave}>
          {t(`constructor.savePath`)}
        </Button>
        <Button onClick={onExitClick} mode="outline">
          {t('constructor.exitFromPath')}
        </Button>
      </div>
      <Snackbar
        ref={snackbarRef}
        iconName={iconsView.Select}
        iconSize="m"
        iconClassName={styles.snackbarIcon}
        text={t('constructor.pathSaved')}
      />
    </header>
  );
};
