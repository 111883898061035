import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { translate } from 'react-i18next';
import { Link } from 'react-router-dom';
import block from 'bem-cn-lite';
import Tooltip from '../ChatWidget/Tooltip';

import './style.styl';

const b = block('profile-menu');

const IconName = ({ t, name, iconName }) => (
  <>
    <img
      className={b('icon')}
      width="13px"
      src={`/img/profile-menu/${iconName}.svg`}
      alt="icon"
    />
    {t(name)}
  </>
);

const LinkBtn = ({
  type,
  target = null,
  to = '/',
  children,
  onClick: onClickFn
}) => {
  const onClick = async () => {
    if (onClickFn) {
      await onClickFn();
    }
  };
  if (type === 'link') {
    return (
      <Link to={to} target={target} className={b('link')} onClick={onClick}>
        {children}
      </Link>
    );
  }
  if (type === 'button') {
    return (
      <button type="button" className={b('link')} onClick={onClick}>
        {children}
      </button>
    );
  }
  return null;
};

const renderItem = (t, data) => {
  const { type, name, iconName, to, target, onClick } = data;

  return (
    <LinkBtn type={type} to={to} target={target} onClick={onClick}>
      <IconName t={t} name={name} iconName={iconName} />
    </LinkBtn>
  );
};

class ProfileMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isShow: false
    };
    this.showList = this.showList.bind(this);
    this.hideList = this.hideList.bind(this);
    this.toggleList = this.toggleList.bind(this);
    this.handlerClickOutside = this.handlerClickOutside.bind(this);
  }

  showList() {
    this.setState({ isShow: true });
    document.addEventListener('mousedown', this.handlerClickOutside);
  }

  hideList() {
    this.setState({ isShow: false });
    document.removeEventListener('mousedown', this.handlerClickOutside);
  }

  toggleList() {
    if (this.state.isShow) {
      this.hideList();
    } else {
      this.showList();
    }
  }

  handlerClickOutside(e) {
    if (this.component && !this.component.contains(e.target)) {
      this.hideList();
    }
  }

  getTitle() {
    const { t, user } = this.props;

    if (!user || !user.get('firstName')) {
      return t('Profile');
    }

    if (user && user.get('type') === 'demo') {
      return t('Demo user');
    }

    return `${user.get('lastName')} ${user.get('firstName')}`;
  }

  render() {
    const { t, theme = 'blue', listMenu = [], lastButton = null } = this.props;

    // NOTE: Example of lastButton
    // { name: 'go-to-goodwix', to: '/', target: null }

    // NOTE: Example of listMenu
    // [
    //   {
    //     type: 'link',
    //     to: '/profile/edit',
    //     target: '_blank',
    //     onClick: hideChatWidget,
    //     iconName: 'person-2',
    //     name: 'Profile'
    //   },
    //   {
    //     type: 'button',
    //     onClick: async () => {
    //       hideChatWidget();
    //       await clearAllCatalogOptions();
    //       await userStorage.logout();
    //     },
    //     iconName: 'exit-2',
    //     name: 'Logout'
    //   }
    // ];

    return (
      <div
        className={b({ theme })}
        ref={(component) => {
          this.component = component;
        }}>
        <div className={b('title')} onClick={() => this.toggleList()}>
          {this.getTitle()}
          <Tooltip text="menu">
            <img
              className={b('title-icon')}
              src="/img/profile-menu/3-dots-2.svg"
              alt="profile"
            />
          </Tooltip>
        </div>
        <div
          className={b('list', { show: this.state.isShow })}
          onClick={this.hideList}>
          {listMenu.map((data) => (
            <div key={data.name} className={b('item')}>
              {renderItem(t, data)}
            </div>
          ))}
          {lastButton && (
            <Tooltip
              text={
                lastButton.name === 'go-to-goodwix' ? 'goodwix-description' : ''
              }>
              <Link
                className={b('last-button')}
                target={lastButton.target}
                to={lastButton.to}>
                {t(lastButton.name)}
              </Link>
            </Tooltip>
          )}
        </div>
      </div>
    );
  }
}

export default connect((state) => ({
  user: state.getIn(['user', 'user'])
}))(translate(['ui'], { wait: true })(ProfileMenu));
