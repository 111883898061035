import React from 'react';
import { connect } from 'react-redux';
import { isShow } from 'core/data/light/modalManager';
import PropTypes from 'prop-types';
import { getModalManager } from '../storeGetters';

import SimpleModal from './SimpleModal';
import SingleProductCharacteristicsModal from './catalogs/categories/prodcuts/single/SingleProductCharacteristicsModal';
import TranslateCatalogModal from './catalogs/local/CatalogsModal';
import CatalogAccessModal from './catalogs/single/CatalogAccessModal';
import SingleProductVideoModal from './catalogs/categories/prodcuts/single/SingleProductVideoModal';
import SingleProductAudioModal from './catalogs/categories/prodcuts/single/SingleProductAudioModal';
import SingleCatalogPriceCategoriesModal from './catalogs/single/SingleCatalogPriceCategoriesModal';
import ContModalCreateStock from '../containers/ContModalCreateStock';
import ContModalAddDiscount from '../containers/ContModalAddDiscount';
import ContModalEditBasePrice from '../containers/ContModalEditBasePrice';
import ContModalBulkAddPricelistAndSetBaseprice from '../containers/ContModalBulkAddPricelistAndSetBaseprice';
import ContModalExportPrice from '../containers/ContModalExportPrice';
import ContModalAddPrices from '../containers/ContModalAddPrices';
import ContModalAddCustomerCategory from '../containers/ContModalAddCustomerCategory';
import ContModalAddCustomer from '../containers/ContModalAddCustomer';
// import ContModalInviteCustomer from '../containers/ContModalInviteCustomer';
import ContModalEditCustomer from '../containers/ContModalEditCustomer';
import ContModalEditCustomerCategory from '../containers/ContModalEditCustomerCategory';
import ContModalAddPriceCategory from '../containers/ContModalAddPriceCategory';
import ContModalAddPriceCategoryByCondition from '../containers/ContModalAddPriceCategoryByCondition';
import ContModalEditPriceCategoryByCondition from '../containers/ContModalEditPriceCategoryByCondition';
import ContModalEditPriceCategory from '../containers/ConstModalEditPriceCategory';
import ModalAddProductsToStock from '../containers/Modals/ModalAddProductsToStock';
import ModalAddProductsToPrice from '../containers/Modals/ModalAddProductsToPrice';
import ModalSettingsAccess from '../containers/Modals/ModalSettingsAccess';
import ModalGrandAccess from '../containers/Modals/ModalGrantToAccess';
import ModalMoveCategory from '../containers/Modals/ModalMoveCategory';
import CompanyEditor from './CompanyEditor';
import ModalSendOrderToSupplier from '../containers/ContModalSendOrderToSupplier';
import ModalSimpleSubmit from '../containers/ContModalSimpleSubmit';
import ModalConfirmDeleteOrdersToSuppliers from '../containers/ContModalConfirmDeleteOrdersToSuppliers';
import ModalConfirmCopyOrdersToSuppliers from '../containers/ContModalConfirmCopyOrderToSupplier';
import ModalUpdateUser from './UserEditor';
import ModalInviteEmployee from './Modal/ModalInviteEmployee';
import ModalAddEmployee from './Modal/ModalAddEmployee';
import ModalEditEmployee from './Modal/ModalEditEmployee';
import ModalShowEmployee from './Modal/ModalShowEmployee';
import ModalChangePassword from './Modal/ModalChangePassword';
import ModalSaveImgUrl from './Modal/ModalSaveImgUrl';
import ModalCreateCatalogCategory from './Modal/ModalCreateCatalogCategory';
import ModalEditCatalogCategory from './Modal/ModalEditCatalogCategory';
import ModalInputSubmit from './Modal/ModalInputSubmit';
import ModalAddSupplier from './Modal/ModalAddSupplier';
// import ModalInviteSupplier from './Modal/ModalInviteSupplier';
import ModalMoveSupplier from './Modal/ModalMoveSupplier';
import ModalEditSupplierGroup from './Modal/ModalEditSupplierGroup';
import ModalAddOrderComment from './Modal/ModalAddOrderComment';
import ModalChangeOrderStatus from './Modal/ModalChangeOrderStatus';
import ModalCreateCatalog from './Modal/ModalCreateCatalog';
import ModalMoveCustomer from './Modal/ModalMoveCustomer';
import ModalAddContact from './Modal/ModalAddContact';
import ModalMoveCatalogCategory from './Modal/ModalMoveCatalogCategory';
import ModalChangeReceiptDate from './Modal/ModalChangeReceiptDate';
import {
  ModalAddPriceQuantityCategory,
  ModalEditPriceQuantityCategory
} from './Modal';
import ModalChangeStockCount from './Modal/ModalChangeStockCount';
import ModalCopyProduct from './Modal/ModalCopyProduct';
import ModalCreateOrReplace from './Modal/ModalCreateOrReplace';
import ModalOrderStatusHistory from './Modal/ModalOrderStatusHistory';
import ModalResponsibleUser from './Modal/ModalResponsibleUser';
import ModalAddExistedFeature from './Modal/ModalAddExistedFeature';
import ModalAddPriceFeature from './Modal/ModalAddPriceFeature';
import ModalAddFeature from './Modal/ModalAddFeature';
import ModalSendOrderReg from './Modal/ModalSendOrderReg';
import ModalSendOrderLogin from './Modal/ModalSendOrderLogin';
import ModalAddAllProductsToPrice from './Modal/ModalAddAllProductsToPrice';
import ModalAddAllProductsToStock from './Modal/ModalAddAllProductsToStock';
import ModalAddProductToPrice from './Modal/ModalAddProductToPrice';
import ModalPriceCurrencyUpdateConfirmation from './Modal/ModalPriceCurrencyUpdateConfirmation';
import ModalAdminConfirm from './Modal/ModalAdminConfirm';
import ModalAdminChangeOwner from './Modal/ModalAdminChangeOwner';
import ModalChangeTariff from './Modal/ModalChangeTariff';
import ModalChangeTariffAdditions from './Modal/ModalChangeTariffAdditions';
import ModalBillingPayment from './Modal/ModalBillingPayment';
import ModalChangeCompanyEmail from './Modal/ModalChangeCompanyEmail';
import ModalHelp from './Modal/ModalHelp';
import ModalCreateUnit from './Modal/ModalCreateUnit';
import ModalEditUnit from './Modal/ModalEditUnit';
import ModalRespondToRequest from './Modal/ModalRespondToRequest';
import ModalCopyPrice from './Modal/ModalCopyPrice';
import ModalNotificationCategories from './Modal/ModalNotificationCategories';
import ModalPartnerInfo from './Modal/ModalPartnerInfo';
import ModalSendPriceChanges from './Modal/ModalSendPriceChanges';
import ModalCreateGroupChat from './Modal/ModalCreateGroupChat';
import ModalCreateTopicChat from './Modal/ModalCreateTopicChat';
import ModalEditTopic from './Modal/ModalEditTopic';
import ModalAddToGroupChat from './Modal/ModalAddToGroupChat/ModalAddToGroupChat';
import ModalCreateContactList from './Modal/ModalCreateContactList';
import ModalCreateDialogList from './Modal/ModalCreateDialogList';
import ModalEditContactList from './Modal/ModalEditContactList';
import ModalInvitePartner from './Modal/ModalInvitePartner/ModalInvitePartner';
import ModalAddPartner from './Modal/ModalAddPartner';
import ModalAddHiddenEmployeeByLink from './Modal/ModalAddHiddenEmployeeByLink';
import ModalEditDialogList from './Modal/ModalEditDialogList';
import EditChannel from './Modal/EditChannel';
import EditMembers from './Modal/EditMembers';
import ModalEmployeesStorageStatistic from './Modal/ModalEmployeesStorageStatistic';
import ModalAccountDeleteRestricted from './Modal/ModalAccountDeleteRestricted';
import ModalArchiveDialog from './Modal/ModalArchiveDialog';
import ModalContactForwardMessage from './Modal/ModalContactForwardMessage';
import ModalDialogForwardMessage from './Modal/ModalDialogForwardMessage';
import ModalViewForwardMessage from './Modal/ModalViewForwardMessage';
import ModalEditChannelUserRole from './Modal/ModalEditChannelUserRole/ModalEditChannelUserRole';
import ModalViewChannelInfo from './Modal/ModalViewChannelInfo/ModalViewChannelInfo';
import ModalImageViewer from './Modal/ModalImageViewer/ModalImageViewer';
import ModalPDFViewer from './Modal/ModalPDFViewer/ModalPDFViewer';
import ModalDocxViewer from './Modal/ModalDocxViewer/ModalDocxViewer';
import ModalOtherViewer from './Modal/ModalOtherFileViewer';

import CWModalCreateContactList from './ChatWidget/Modal/CreateContactList';
import CWModalCreateContactSubList from './ChatWidget/Modal/CreateContactSubList';
import CWModalCreateDialogList from './ChatWidget/Modal/CreateDialogList';
import CWModalCreateDialogSubList from './ChatWidget/Modal/CreateDialogSubList';
import CWModalEditContactList from './ChatWidget/Modal/EditContactList';
import CWModalEditDialogList from './ChatWidget/Modal/EditDialogList';
import CWAddToContactList from './ChatWidget/Modal/AddToContactList';
import CWAddToDialogList from './ChatWidget/Modal/AddToDialogList';
import CWModalEditUserContactLists from './ChatWidget/Modal/EditUserContactLists';
import CWModalEditDialogDialogLists from './ChatWidget/Modal/EditDialogDialogLists';

import CWModalStorageFileAttache from './ChatWidget/Modal/StorageFileAttache';
import CWModalCreateGroupChat from './ChatWidget/Modal/CreateGroupChat';
import CWModalEditGroupChat from './ChatWidget/Modal/EditGroupChat';
import CWModalAddToGroupChat from './ChatWidget/Modal/AddToGroupChat';
import CWModalDeleteContact from './ChatWidget/Modal/DeleteContact';
import CWModalBlockContact from './ChatWidget/Modal/BlockContact';

import CWModalConfirmSubmit from './ChatWidget/Modal/ConfirmSubmit';

import CWModalContactsList from './ChatWidget/Modal/ListModals/Contacts';
import CWModalChatsList from './ChatWidget/Modal/ListModals/Chats';
import CWModalMessagesList from './ChatWidget/Modal/ListModals/Messages';

import CWModalChooseHistoryDownload from './ChatWidget/Modal/ChooseHistoryDownload';
import CWModalConfirmActionWithPassword from './ChatWidget/Modal/ConfirmActionWithPassword';
import CWModalStorageOversize from './ChatWidget/Modal/StorageOversize';
import ModalAddAdditionalCategory from './Modal/template/modal_add_additional_category';
import ModalAddLink from './Modal/template/modal_add-link';
import ModalNotificationNoInternetConnection from './Modal/ModalNotificationNoInternetConnection';
import ModalChangeMassPriceProducts from './Modal/ModalChangeMassPriceProducts';
import ModalSubmitWithComment from './Modal/ModalSubmitWithComment';
import ModalRefuseRespond from './Modal/PurchaseRequests/ModalRefuseRespond';
import ModalSendToReworkRespond from './Modal/PurchaseRequests/ModalSendToReworkRespond';
import { ModalInvitePartnerCompany } from './Modal/ModalInvitePartnerCompany';
import { TASK_MANAGER_MODALS } from '../tasks-manager/components/Modals';
import RequestKPByEmail from './Modal/RequestKPByEmail';
import ModalCategorySelect from './Modal/ModalCategorySelect';
import ModalItemsSelect from './Modal/ModalItemsSelect';
import ModalSendEmail from './Modal/ModalSendEmail';
import ModalAddUserToConstructor from './Modal/ModalAddUserToConstructor';
import {
  SuppliersActivitiesModal,
  SUPPLIERS_ACTIVITIES_MODAL
} from './SuppliersActivities';
import ModalCreatePurchaseRequest from './Modal/ModalCreatePurchaseRequest';
import ModalImportResponse from './Modal/ModalImportResponse';
import { wizards } from './Wizards';
import { ModalExtendResponseEndDate } from './Modal/ModalExtendRespondEndDate';

const MODAL_COMPONENTS = {
  ADD_CHARACTERISTIC: SingleProductCharacteristicsModal,
  EDIT_CHARACTERISTIC: SingleProductCharacteristicsModal,
  TRANSLATE_CATALOG: TranslateCatalogModal,
  ACCESS_CATALOG: CatalogAccessModal,
  LOAD_PRODUCT_VIDEO: SingleProductVideoModal,
  LOAD_PRODUCT_AUDIO: SingleProductAudioModal,
  ADD_PRICE_CATEGORY: SingleCatalogPriceCategoriesModal,
  EDIT_COMPANY_DIALOG: CompanyEditor,
  CREATE_NEW_STOCK: ContModalCreateStock,
  ADD_DISCOUNT: ContModalAddDiscount,
  EDIT_BASEPRICE: ContModalEditBasePrice,
  BULK_ADD_IN_PRICELIST_AND_SET_BASEPRICE:
    ContModalBulkAddPricelistAndSetBaseprice,
  EXPORT_PRICE: ContModalExportPrice,
  ADD_CUSTOMER_CATEGORY: ContModalAddCustomerCategory,
  ADD_CUSTOMER: ContModalAddCustomer,
  // INVITE_CUSTOMER: ContModalInviteCustomer,
  INVITE_CUSTOMER: ContModalAddCustomer,
  ADD_LINK: ModalAddLink,
  EDIT_CUSTOMER: ContModalEditCustomer,
  EDIT_CUSTOMER_CATEGORY: ContModalEditCustomerCategory,
  CREATE_NEW_PRICE: ContModalAddPrices,
  ADD_ADDITIONAL_CATEGORY: ModalAddAdditionalCategory,
  ADD_PRICES_CATEGORY: ContModalAddPriceCategory,
  ADD_PRICES_CATEGORY_BY_CONDITION: ContModalAddPriceCategoryByCondition,
  ADD_PRICES_QUANTITY_CATEGORY: ModalAddPriceQuantityCategory,
  EDIT_PRICE_CATEGORY: ContModalEditPriceCategory,
  EDIT_PRICE_CATEGORY_BY_CONDITION: ContModalEditPriceCategoryByCondition,
  EDIT_PRICE_QUANTITY_CATEGORY: ModalEditPriceQuantityCategory,
  UPDATE_COUNT_PRODUCT_AT_STOCK: ModalAddProductsToStock,
  UPDATE_OFFER_PRODUCT_AT_PRICE: ModalAddProductsToPrice,
  SETTINGS_ACCESS_AT_SECTION: ModalSettingsAccess,
  GRANT_ACCESS_AT_PRICE: ModalGrandAccess,
  MOVE_CATEGORY: ModalMoveCategory,
  SEND_ORDER_TO_SUPPLIER: ModalSendOrderToSupplier,
  SIMPLE_SUBMIT: ModalSimpleSubmit,
  SUBMIT_WITH_COMMENT: ModalSubmitWithComment,
  DECLINE_PURCHASE_RESPONSE: ModalRefuseRespond,
  SEND_TO_REWORK_PURCHASE_RESPONSE: ModalSendToReworkRespond,
  EDIT_USER: ModalUpdateUser,
  INVITE_EMPLOYEE: ModalInviteEmployee,
  ADD_EMPLOYEE: ModalAddEmployee,
  EDIT_EMPLOYEE: ModalEditEmployee,
  SHOW_EMPLOYEE: ModalShowEmployee,
  CHANGE_USER_PASSWORD: ModalChangePassword,
  SAVE_IMG_URL: ModalSaveImgUrl,
  CREATE_CATALOG_CATEGORY: ModalCreateCatalogCategory,
  MOVE_CATALOG_CATEGORY: ModalMoveCatalogCategory,
  EDIT_CATALOG_CATEGORY: ModalEditCatalogCategory,
  INPUT_SUBMIT: ModalInputSubmit,
  ADD_SUPPLIER: ModalAddSupplier,
  // INVITE_SUPPLIER: ModalInviteSupplier,
  INVITE_SUPPLIER: ModalAddSupplier,
  MOVE_SUPPLIER: ModalMoveSupplier,
  MOVE_CUSTOMER: ModalMoveCustomer,
  EDIT_SUPPLIER_GROUP: ModalEditSupplierGroup,
  ADD_ORDER_COMMENT: ModalAddOrderComment,
  CHANGE_ORDER_STATUS: ModalChangeOrderStatus,
  CREATE_CATALOG: ModalCreateCatalog,
  ADD_NEW_CONTACT: ModalAddContact,
  CHANGE_RECEIPT_DATE: ModalChangeReceiptDate,
  CHANGE_STOCK_COUNT: ModalChangeStockCount,
  COPY_PRODUCT: ModalCopyProduct,
  CREATE_OR_REPLACE: ModalCreateOrReplace,
  ORDER_STATUS_HISTORY: ModalOrderStatusHistory,
  RESPONSIBLE_USER: ModalResponsibleUser,
  ADD_EXISTED_CHARACTERISTIC: ModalAddExistedFeature,
  ADD_PRICE_FEATURE: ModalAddPriceFeature,
  ADD_FEATURE: ModalAddFeature,
  SEND_ORDER_REG: ModalSendOrderReg,
  SEND_ORDER_LOGIN: ModalSendOrderLogin,
  ADD_ALL_PRODUCTS_TO_PRICE: ModalAddAllProductsToPrice,
  ADD_ALL_PRODUCTS_TO_STOCK: ModalAddAllProductsToStock,
  ADD_PRODUCTS_TO_PRICE: ModalAddProductToPrice,
  CURRENCY_UPDATE_CONFIRMATION: ModalPriceCurrencyUpdateConfirmation,
  ADMIN_CONFIRM: ModalAdminConfirm,
  ADMIN_CHANGE_OWNER: ModalAdminChangeOwner,
  CHANGE_TARIFF: ModalChangeTariff,
  CHANGE_TARIFF_ADDITIONS: ModalChangeTariffAdditions,
  BILLING_PAYMENT: ModalBillingPayment,
  CONFIRM_DELETE_ORDERS_TO_SUPPLIERS: ModalConfirmDeleteOrdersToSuppliers,
  CONFIRM_COPY_ORDER_TO_SUPPLIER: ModalConfirmCopyOrdersToSuppliers,
  CHANGE_COMPANY_EMAIL: ModalChangeCompanyEmail,
  INITIAL_HELP: ModalHelp,
  CREATE_UNIT: ModalCreateUnit,
  EDIT_UNIT: ModalEditUnit,
  RESPOND_CONFIRM: ModalRespondToRequest,
  COPY_PRICE: ModalCopyPrice,
  NOTIFICATIONS_CATEGORIES: ModalNotificationCategories,
  CONFIRM: SimpleModal,
  SEND_PRICE_CHANGES: ModalSendPriceChanges,
  PARTNER_INFO: ModalPartnerInfo,
  CREATE_GROUP_CHAT: ModalCreateGroupChat,
  CREATE_TOPIC_CHAT: ModalCreateTopicChat,
  EDIT_TOPIC: ModalEditTopic,
  ADD_TO_GROUP_CHAT: ModalAddToGroupChat,
  CREATE_CONTACT_LIST: ModalCreateContactList,
  CW_CREATE_CONTACT_LIST: CWModalCreateContactList,
  CW_CREATE_CONTACT_SUBLIST: CWModalCreateContactSubList,
  CW_CREATE_DIALOG_LIST: CWModalCreateDialogList,
  CW_CREATE_DIALOG_SUBLIST: CWModalCreateDialogSubList,
  CW_EDIT_CONTACT_LIST: CWModalEditContactList,
  CW_EDIT_DIALOG_LIST: CWModalEditDialogList,
  CW_ADD_TO_CONTACT_LIST: CWAddToContactList,
  CW_ADD_TO_DIALOG_LIST: CWAddToDialogList,
  CW_EDIT_USER_CONTACT_LISTS: CWModalEditUserContactLists,
  CW_EDIT_DIALOG_DIALOG_LISTS: CWModalEditDialogDialogLists,
  CW_GROUP_CHAT_CREATE: CWModalCreateGroupChat,
  CW_GROUP_CHAT_EDIT: CWModalEditGroupChat,
  CW_GROUP_CHAT_ADD_MEMBERS: CWModalAddToGroupChat,
  CW_DELETE_CONTACT: CWModalDeleteContact,
  CW_BLOCK_CONTACT: CWModalBlockContact,
  CW_CHOOSE_HISTORY_DOWNLOAD: CWModalChooseHistoryDownload,
  CW_CONFIRM_ACTION_WITH_PASSWORD: CWModalConfirmActionWithPassword,
  CREATE_DIALOG_LIST: ModalCreateDialogList,
  EDIT_DIALOG_LIST: ModalEditDialogList,
  EDIT_CONTACT_LIST: ModalEditContactList,
  INVITE_PARTNER: ModalInvitePartner,
  INVITE_COMPANY_PARTNER: ModalInvitePartnerCompany,
  EDIT_CHANNEL: EditChannel,
  EDIT_MEMBERS: EditMembers,
  ADD_PARTNER: ModalAddPartner,
  ADD_HIDDEN_EMPLOYEE_BY_LINK: ModalAddHiddenEmployeeByLink,
  STORAGE_STATISTIC: ModalEmployeesStorageStatistic,
  ACCOUNT_DELETE_RESTRICTED: ModalAccountDeleteRestricted,
  ARCHIVE_DIALOG: ModalArchiveDialog,
  MSG_FORWARD_CONTACT: ModalContactForwardMessage,
  MSG_FORWARD_DIALOG: ModalDialogForwardMessage,
  MSG_FORWARD_VIEW: ModalViewForwardMessage,
  EDIT_CHANNEL_USER_ROLE: ModalEditChannelUserRole,
  VIEW_CHANNEL_INFORMATION: ModalViewChannelInfo,
  IMAGE_VIEWER: ModalImageViewer,
  PDF_VIEWER: ModalPDFViewer,
  DOCX_VIEWER: ModalDocxViewer,
  OTHER_VIEWER: ModalOtherViewer,
  STORAGE_FILE_ATTACHE: CWModalStorageFileAttache,
  CONFIRM_SUBMIT: CWModalConfirmSubmit,
  CONTACTS_LIST: CWModalContactsList,
  CHATS_LIST: CWModalChatsList,
  MESSAGES_LIST: CWModalMessagesList,
  CW_STORAGE_OVERSIZE: CWModalStorageOversize,
  NOTIFICATION_NO_INTERNET_CONNECTION: ModalNotificationNoInternetConnection,
  CHANGE_MASS_PRICE_PRODUCTS: ModalChangeMassPriceProducts,
  SEND_TO_EMAIL: RequestKPByEmail,
  CATEGORY_SELECT: ModalCategorySelect,
  ITEMS_SELECT: ModalItemsSelect,
  MODAL_SEND_EMAIL: ModalSendEmail,
  MODAL_ADD_USER_TO_CONSTRUCTOR: ModalAddUserToConstructor,
  MODAL_CREATE_PURCHASE_REQUEST: ModalCreatePurchaseRequest,
  MODAL_IMPORT_RESPONSE: ModalImportResponse,
  ...TASK_MANAGER_MODALS,
  [SUPPLIERS_ACTIVITIES_MODAL]: SuppliersActivitiesModal,
  MODAL_EXTEND_RESPONSE_END_DATE: ModalExtendResponseEndDate,
  ...wizards
};

const ModalRoot = ({ modalManager }) => {
  if (!isShow(modalManager)) return <div />;

  const SpecificModal = MODAL_COMPONENTS[modalManager.current.name];

  if (!SpecificModal) {
    console.error(
      `Not found modalManager component name: ${modalManager.current.name}`
    );
    return <div />;
  }

  return <SpecificModal {...modalManager.current.props} />;
};

ModalRoot.propTypes = {
  modalManager: PropTypes.object.isRequired
};

/**
 * Map state func
 *
 * @param {object} state
 * @returns {object}
 */
function mapStateToProps(state) {
  return {
    modalManager: getModalManager(state)
  };
}

export default connect(mapStateToProps)(ModalRoot);
