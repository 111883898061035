import React from 'react';

/* eslint-disable max-len */
const RoundPlusSVG = () => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      className="cws-icon-main-fill"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9 17C13.4183 17 17 13.4183 17 9C17 4.58172 13.4183 1 9 1C4.58172 1 1 4.58172 1 9C1 13.4183 4.58172 17 9 17ZM9 18C13.9706 18 18 13.9706 18 9C18 4.02944 13.9706 0 9 0C4.02944 0 0 4.02944 0 9C0 13.9706 4.02944 18 9 18Z"
      fill="#767B92"
    />
    <path
      className="cws-icon-main-fill"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4 9C4 8.72386 4.22386 8.5 4.5 8.5H13.5C13.7761 8.5 14 8.72386 14 9C14 9.27614 13.7761 9.5 13.5 9.5H4.5C4.22386 9.5 4 9.27614 4 9Z"
      fill="#767B92"
    />
    <path
      className="cws-icon-main-fill"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9 4C9.27614 4 9.5 4.22386 9.5 4.5V13.5C9.5 13.7761 9.27614 14 9 14C8.72386 14 8.5 13.7761 8.5 13.5V4.5C8.5 4.22386 8.72386 4 9 4Z"
      fill="#767B92"
    />
  </svg>
);
/* eslint-enable max-len */

export default RoundPlusSVG;
