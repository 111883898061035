import React, { Component } from 'react';
import PropTypes from 'prop-types';
import block from 'bem-cn-lite';
import { translate } from 'react-i18next';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  Modal,
  Col,
  Row,
  Form,
  FormGroup,
  OverlayTrigger,
  Popover,
  FormControl,
  ControlLabel,
  Button,
  HelpBlock
} from 'react-bootstrap';
import Select from 'react-select';

import '../styles/modal_theme_default.styl';
import Helper from '../../Helper';

import * as servicesActions from '../../../action-creators/services';
import * as actionCompany from '../../../action-creators/company';
import { ModalContainer } from '../elements';

const b = block('modal-container');

class ModalCreateStock extends Component {
  constructor(props) {
    super(props);
    this.inputProps = this.inputProps.bind(this);
    this.state = {
      pristine: true,
      error: ''
    };
    this.isCatalogPublished = this.isCatalogPublished.bind(this);
  }

  getChildContext() {
    return { b };
  }

  UNSAFE_componentWillMount() {
    const { getMoreInfo, setDefaultValuesToForm, clearForm } = this.props;
    clearForm();
    getMoreInfo().then(() => setDefaultValuesToForm());
  }

  getProperty(field) {
    return this.props.feature.has(field) ? this.props.feature.get(field) : '';
  }

  getErrorInfo(fieldName) {
    return this.props.errors.get(fieldName);
  }

  isValidFieldName() {
    return this.props.fields.get('name').length < 1;
  }

  isValidFieldCode() {
    return this.props.fields.get('number').length < 1;
  }

  isUniqStockNumber() {
    const { fields, stocks } = this.props;
    const number = fields.get('number');
    if (!number || stocks.size === 0) {
      return true;
    }
    return stocks.findIndex((stock) => stock.get('number') === +number) === -1;
  }

  isCatalogPublished() {
    const { moreInfo, fields } = this.props;
    const lang = moreInfo
      .get('languages')
      .find((i) => i.get('id') === fields.get('catalogId'));
    return lang && lang.get('publishDate');
  }

  asterisk() {
    // eslint-disable-line
    return <span style={{ color: 'red' }}>*</span>;
  }

  inputProps(field) {
    return {
      value: this.props.fields.get(field),
      onChange: (i) => {
        if (this.getErrorInfo(field)) {
          this.props.removeErrorByField(field);
        }
        let value = '';
        if (field === 'name' || field === 'address') {
          value = i.target.value;
          if (this.state.error && this.state.error.type === 'name') {
            this.setState({ error: '' });
          }
        } else {
          value = i.value;
        }
        if (field === 'name') {
          return this.props.updateFieldStock(
            field,
            value.replace(/^\s+|\s(?=\s)/g, '')
          );
        }
        return this.props.updateFieldStock(field, value);
      }
    };
  }

  handlerClick = () => {
    const { hideModalDialog, submit, getAllStocks, clearForm, getCompany } =
      this.props;

    submit().then((error) => {
      if (!error) {
        getAllStocks();
        getCompany();
        hideModalDialog();
        clearForm();
      } else {
        this.setState({ error });
      }
    });
  };

  render() {
    const {
      t,
      captionKey,
      hideModalDialog,
      fields,
      moreInfo,
      gaPageView,
      gaSend,
      user
    } = this.props;

    const languages = moreInfo.get('languages').map((language) => ({
      value: language.get('id'),
      label: `${language.get('view')} ${
        language.get('publishDate') ? '' : `(${t('not published')})`
      }`,
      disabled: !language.get('publishDate')
    }));
    languages.unshift({ value: null, label: t('not selected') });

    return (
      <ModalContainer mods={{ theme: 'default' }}>
        <Modal id="createStock" onHide={() => hideModalDialog()} show>
          <Modal.Header>
            <Modal.Title>
              {t(captionKey)}
              <span
                className="modal-header-cross pull-right"
                onClick={() => hideModalDialog()}
              />
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <FormGroup
                controlId="name"
                validationState={
                  (this.isValidFieldName() && !this.state.pristine) ||
                  (this.state.error && this.state.error.type === 'name')
                    ? 'error'
                    : null
                }
                style={{ overflow: 'hidden' }}>
                <ControlLabel>
                  {`${t('Name')} `}
                  {this.asterisk()}
                </ControlLabel>
                <FormControl
                  type="text"
                  {...this.inputProps('name')}
                  maxLength="60"
                  onKeyDown={() => {
                    if (this.state.pristine) {
                      this.setState({ pristine: false });
                    }
                  }}
                />
                {this.isValidFieldName() && !this.state.pristine && (
                  <HelpBlock className="pull-left">
                    {t('Required fields empty')}
                  </HelpBlock>
                )}
                {this.state.error && this.state.error.type === 'name' && (
                  <HelpBlock className="pull-left">
                    {t(this.state.error.text)}
                  </HelpBlock>
                )}
                <h6 className="pull-right">
                  <small>
                    {fields.get('name').length}
                    /60
                  </small>
                </h6>
              </FormGroup>
              <Row>
                <Col md={6}>
                  <FormGroup controlId="language">
                    <ControlLabel>
                      {`${t('Products language')} `}
                      <Helper text="create_stock_language_help" />
                    </ControlLabel>
                    <Select
                      id="language-select"
                      {...this.inputProps('catalogId')}
                      options={languages.toJS()}
                      clearable={false}
                      searchable={false}
                    />
                  </FormGroup>
                </Col>
                <Col md={6}>
                  <FormGroup
                    controlId="stockCode"
                    validationState={
                      this.getErrorInfo('number') ||
                      this.isValidFieldCode() ||
                      !this.isUniqStockNumber()
                        ? 'error'
                        : null
                    }>
                    <ControlLabel>{t('Code')}</ControlLabel>
                    <FormControl
                      type="number"
                      {...this.inputProps('number')}
                      onChange={(e) => {
                        if (e.target.value.length > 9) {
                          return e.preventDefault();
                        }
                        if (!/^[0-9]+$/.test(e.target.value)) {
                          return e.preventDefault();
                        }
                        if (this.getErrorInfo('number')) {
                          this.props.removeErrorByField('number');
                        }
                        return this.props.updateFieldStock(
                          'number',
                          e.target.value
                        );
                      }}
                    />
                    {this.getErrorInfo('number') && (
                      <HelpBlock className="pull-left">
                        {t(this.getErrorInfo('number').get('msg'))}
                      </HelpBlock>
                    )}
                    {this.isValidFieldCode() && (
                      <HelpBlock className="pull-left">
                        {t('Required fields empty')}
                      </HelpBlock>
                    )}
                    {!this.isUniqStockNumber() && (
                      <HelpBlock className="pull-left">
                        {t('already exist')}
                      </HelpBlock>
                    )}
                    <h6 className="pull-right">
                      <small>
                        {String(fields.get('number')).length}
                        /9
                      </small>
                    </h6>
                  </FormGroup>
                </Col>
              </Row>
              <FormGroup controlId="stockAddress">
                <ControlLabel>{t('Stock address')}</ControlLabel>
                <FormControl
                  componentClass="textarea"
                  {...this.inputProps('address')}
                  maxLength="1000"
                />
                <h6 className="pull-right">
                  <small>
                    {(fields.get('address') && fields.get('address').length) ||
                      0}
                    /1000
                  </small>
                </h6>
              </FormGroup>
            </Form>
            {this.asterisk()}
            {` ${t('Required fields')}`}
          </Modal.Body>
          <Modal.Footer>
            <Button
              id="cancel"
              className="pull-left"
              onClick={() => {
                gaPageView('stock_create_cancel');
                gaSend({
                  category: 'Supplier',
                  action: 'stock_create_cancel',
                  label: user.get('email')
                });
                hideModalDialog();
              }}>
              {t('Cancel')}
            </Button>
            {!this.isCatalogPublished() ? (
              <OverlayTrigger
                trigger={['focus', 'hover']}
                overlay={
                  <Popover id="popover-is-price-list-published">
                    {t('please_publish_text_stock')}
                  </Popover>
                }>
                <div>
                  <a id="savebutton" className="btn btn-primary" disabled>
                    {t('New stock')}
                  </a>
                </div>
              </OverlayTrigger>
            ) : (
              <Button
                id="saveCharacteristicButton"
                bsStyle="primary"
                disabled={
                  this.isValidFieldName() ||
                  this.isValidFieldCode() ||
                  !this.isUniqStockNumber()
                }
                onClick={this.handlerClick}>
                {t('New stock')}
              </Button>
            )}
          </Modal.Footer>
        </Modal>
      </ModalContainer>
    );
  }
}

ModalCreateStock.propTypes = {
  t: PropTypes.func.isRequired
};

ModalCreateStock.childContextTypes = {
  b: PropTypes.func
};

export default connect(
  (state) => ({
    user: state.getIn(['user', 'user']),
    stocks: state.getIn(['stocks', 'items'])
  }),
  (dispatch) =>
    bindActionCreators(
      {
        gaPageView: servicesActions.gaPageView,
        gaSend: servicesActions.gaSend,
        getCompany: actionCompany.getCompany
      },
      dispatch
    )
)(translate(['ui'], { wait: true })(ModalCreateStock));
