.popper {
  :global(.MuiTooltip-tooltip) {
    padding: 6px 8px;
    background: var(--color-gray-800);
    border-radius: 2px;
  }

  :global(.MuiTooltip-arrow)::before {
    background: var(--color-gray-800);
  }

  &:global(.MuiTooltip-popper[data-popper-placement*='top'] .MuiTooltip-arrow),
  &:global(.MuiTooltip-popper[data-popper-placement*='bottom'] .MuiTooltip-arrow) {
    left: 0;
    right: 0;
    width: 100%;
  }

  &:global(.MuiTooltip-popper[data-popper-placement*='top'] .MuiTooltip-arrow) {
    transform: translate3d(calc(-50% + 5px), 0, 0) !important;
  }

  &:global(.MuiTooltip-popper[data-popper-placement*='bottom'] .MuiTooltip-arrow) {
    transform: translate3d(calc(50% - 5px), 0, 0) !important;
  }
}

.trigger {
  line-height: 0;
}
