import useResizeObserver from 'use-resize-observer';

export function useScroll(ref, columns) {
  const headerHeight =
    ref.current?.querySelector('.ant-table-header')?.offsetHeight ?? 0;

  const { height: containerHeight = 0, width: containerWidth = 0 } =
    useResizeObserver({
      ref: ref.current?.parentNode
    });

  const tableWidth = columns.reduce(
    (width, column) => width + (column.width ?? column.minWidth ?? 0),
    0
  );

  return {
    containerWidth,
    containerHeight:
      containerHeight > headerHeight
        ? containerHeight - headerHeight
        : containerHeight,
    tableWidth: Math.min(tableWidth, containerWidth)
  };
}
