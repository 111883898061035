import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useActions } from '@hooks/useActions';
import { useParams } from '@hooks';
import { isEmpty } from 'ramda';
import styles from './Main.module.css';
import ComparisonTable from '../../components/ComparisonTable/ComparisonTable';
import * as PRGetters from '../../../../../components/PurchaseRequests/getters';
import * as requestsActions from '../../../../../action-creators/purchaseRequests';
import * as getters from '../../../../../components/PurchaseRequests/MyRequests/getters';
import {
  requestMapper,
  responseMapper
} from '../../components/ComparisonTable/mappers';

export const Main = ({ deletedList, setDeletedList }) => {
  const { requestId } = useParams();

  const compareResponses = useSelector(getters.getCompareResponses);

  const { getMyPublishedRequest, getRequestResponses } = useActions({
    getMyPublishedRequest: requestsActions.getMyPublishedRequest,
    getRequestResponses: requestsActions.getRequestResponses
  });

  useEffect(() => {
    getMyPublishedRequest(requestId);
    getRequestResponses(requestId);

    setDeletedList([]);
  }, [requestId]);

  const request = useSelector(PRGetters.getRequest);
  const responses = useSelector(PRGetters.getResponses);

  if (isEmpty(request.toJS())) {
    return null;
  }

  if (isEmpty(request.toJS()) || isEmpty(responses.toJS())) {
    return null;
  }

  const compareResponsesFromRequest = responses
    .toJS()
    .filter((response) =>
      compareResponses[requestId]?.list.includes(response.id)
    );

  const getResponses = () =>
    deletedList.length === 0 ? compareResponsesFromRequest : [];

  return (
    <main className={styles.main}>
      <ComparisonTable
        request={requestMapper(request.toJS())}
        responses={getResponses().map(responseMapper)}
      />
    </main>
  );
};
