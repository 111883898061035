import { setSuccessMessage, setInfoMessage } from './message';

const loadTableProducts = ({
  data,
  size,
  limit,
  offset,
  search,
  allSize,
  productsId,
  orderBy,
  direction,
  filterValues
}) => ({
  type: 'TABLE_PRODUCT_LOADED',
  payload: {
    data,
    size,
    offset,
    limit,
    search,
    allSize,
    productsId,
    orderBy,
    direction,
    filterValues
  }
});

export const toggleFilterSelected = () => ({
  type: 'CATALOG_FILTER_SELECT_TOGGLE'
});

export const selectProducts = (ids) => ({
  type: 'CATALOG_SELECT_PRODUCTS',
  payload: { ids }
});

export const unselectProducts = (ids) => ({
  type: 'CATALOG_UNSELECT_PRODUCTS',
  payload: { ids }
});

export const clearTableProducts = () => ({
  type: 'CLEAR_PRODUCT_TABLE'
});

export const selectProduct = (id) => ({
  type: 'TABLE_PRODUCT_SELECT',
  payload: { id }
});
export const selectAllProducts = () => ({
  type: 'TABLE_PRODUCT_SELECT_ALL',
  payload: {}
});

export const getProducts =
  (catalogId, search = '', offset = 0, limit = 20, options = {}) =>
  (dispatch, getState) => {
    const state = getState();
    const filter =
      state.getIn(['tablesNew', 'products', 'appliedFilterValues']) || [];
    const orderBy =
      options.orderBy || state.getIn(['tablesNew', 'products', 'orderBy']);
    const direction =
      options.direction || state.getIn(['tablesNew', 'products', 'direction']);
    const isEnabledFilterSelected = state.getIn([
      'tablesNew',
      'products',
      'isEnabledFilterSelected'
    ]);
    const selectedIdsArray = state
      .getIn(['tablesNew', 'products', 'selectedList'])
      .toArray();

    const selected = selectedIdsArray.length === 0 ? [] : selectedIdsArray;
    const ids = isEnabledFilterSelected ? selected : [];

    const categoriesId = state
      .getIn(['tables', 'common', 'selected'])
      .toArray();
    const url = `/api/catalogs/${catalogId}/products/search?orderBy=${orderBy}&direction=${direction}`;
    return fetch(url, {
      credentials: 'include',
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        offset,
        limit,
        search,
        categoriesId,
        ids,
        filter
      })
    })
      .then((response) => response.json())
      .then(({ data, size, allSize, productsId, filterValues }) => {
        dispatch(setSuccessMessage());
        dispatch(
          loadTableProducts({
            data,
            size,
            limit,
            offset,
            search,
            allSize,
            productsId,
            orderBy,
            direction,
            filterValues
          })
        );
      });
  };

export const getProductsAdapter = (options = {}) => {
  const { catalogId, id, search, offset, limit } = options;
  return getProducts(catalogId || id, search, offset, limit, options);
};

export const getPublishedProducts =
  (
    catalogId,
    search = '',
    offset = 0,
    limit = 20,
    direction = '',
    orderBy = ''
  ) =>
  (dispatch, getState) => {
    let categoriesId = getState().getIn(['tables', 'common', 'selected']);
    categoriesId =
      categoriesId && categoriesId.size > 0 ? categoriesId.toArray() : [];

    return fetch(`/api/catalogs/${catalogId}/publishedproducts/search`, {
      credentials: 'include',
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        offset,
        limit,
        search,
        categoriesId,
        direction,
        orderBy
      })
    })
      .then((response) => response.json())
      .then(({ data, size }) => {
        dispatch(
          loadTableProducts({
            data,
            size,
            limit,
            offset,
            search,
            direction,
            orderBy
          })
        );
      });
  };

const fetchDeleteProduct = (catalogId, productsId) =>
  fetch(`/api/catalogs/${catalogId}/destroy_products`, {
    credentials: 'include',
    headers: { 'Content-Type': 'application/json' },
    method: 'POST',
    body: JSON.stringify({ productsId })
  });

export const deleteSelectedProducts =
  (catalogId, options) => (dispatch, getState) => {
    const selected = getState()
      .getIn(['tablesNew', 'products', 'selectedList'])
      .toJS();

    return fetchDeleteProduct(catalogId, selected).then(() => {
      const limit = getState().getIn(['tablesNew', 'products', 'limit']);
      const offset = getState().getIn(['tablesNew', 'products', 'offset']);
      const search = getState().getIn(['tablesNew', 'products', 'search']);

      return getProducts(
        catalogId,
        search,
        offset,
        limit,
        options
      )(dispatch, getState);
    });
  };

const fetchChangeCategory = (catalogId, productsIds, categoryId) =>
  fetch(`/api/catalogs/${catalogId}/products/change-category`, {
    credentials: 'include',
    method: 'PATCH',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      productsIds,
      categoryId
    })
  });

export const changeCategory =
  (catalogId, categoryId, currentCategory) => (dispatch, getState) => {
    const selected = getState()
      .getIn(['tablesNew', 'products', 'selectedList'])
      .toArray();
    dispatch(setInfoMessage({ key: 'change_process_in_progress' }));
    return fetchChangeCategory(catalogId, selected, categoryId).then(() => {
      const limit = getState().getIn(['tablesNew', 'products', 'limit']);
      const offset = getState().getIn(['tablesNew', 'products', 'offset']);
      const search = getState().getIn(['tablesNew', 'products', 'search']);

      getProducts(catalogId, search, offset, limit, {
        categoryId: currentCategory
      })(dispatch, getState);
    });
  };

export const setFilterField = (name, value) => ({
  type: 'TABLE_PRODUCTS:SET_FILTER_FIELD',
  payload: { name, value }
});

export const applyFilter = (filters) => ({
  type: 'TABLE_PRODUCTS:APPLY_FILTER',
  payload: { filters }
});
