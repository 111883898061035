/* eslint-disable max-len */
import React from 'react';

const MessengerIcon = () => (
  <svg
    width="18"
    height="19"
    viewBox="0 0 18 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      className="snm-icon__path"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2 0C0.895431 0 0 0.895431 0 2V14C0 15.1046 0.89543 16 2 16H6L8.35982 17.9665C8.73066 18.2756 9.26934 18.2756 9.64018 17.9665L12 16H16C17.1046 16 18 15.1046 18 14V2C18 0.895431 17.1046 0 16 0H2ZM6 10C7.10457 10 8 9.10457 8 8C8 6.89543 7.10457 6 6 6C4.89543 6 4 6.89543 4 8C4 9.10457 4.89543 10 6 10ZM12 10C13.1046 10 14 9.10457 14 8C14 6.89543 13.1046 6 12 6C10.8954 6 10 6.89543 10 8C10 9.10457 10.8954 10 12 10Z"
      fill="#C6CAE3"
    />
  </svg>
);

export default MessengerIcon;
