import React, { useCallback } from 'react';
import { withRouter } from '../../../../hoc/withRouter';
import FormChangeSubscribePurchaseRequests from '../Form';
import GoBack from '../../../Buttons/GoBack';
import './style.css';

const PageSubscribeToPurchaseRequests = ({ history }) => {
  const onCancel = useCallback((event) => {
    const closeAfterClickBy = ['cancel'];
    if (closeAfterClickBy.includes(event.target.name)) {
      history.push('/');
    }
  }, []);

  return (
    <div className="subscribe-pr-wrapper">
      <div className="subscribe-pr-wrapper__go-back">
        <GoBack />
      </div>
      <FormChangeSubscribePurchaseRequests onClose={onCancel} />
    </div>
  );
};

export default withRouter(PageSubscribeToPurchaseRequests);
