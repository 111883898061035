import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { translate } from 'react-i18next';
import {
  Modal,
  Form,
  Button,
  ControlLabel,
  FormControl,
  FormGroup
} from 'react-bootstrap';
import { haveActivatedMS } from 'core/data/light/channelMember';
import {
  getChannelName,
  getAllChannelMembers,
  getCurrentMember
} from '../../../storeGetters';
import * as modalAction from '../../../action-creators/modal';
import Member from './member';
import './styles.css';

class ModalViewChannelInfo extends Component {
  constructor(props) {
    super(props);

    this.onClose = this.onClose.bind(this);
    this.getMembers = this.getMembers.bind(this);
  }

  onClose() {
    const { closeModal } = this.props;
    closeModal();
  }

  getMembers() {
    const { members } = this.props;
    return members.filter(haveActivatedMS);
  }

  render() {
    const { t, currentMember, channelName } = this.props;

    return (
      <Modal id="simpleSubmit" onHide={this.onClose} show>
        <div className="modal-container modal-container_type_default modal-container_theme_default">
          <Modal.Header>
            <Modal.Title>
              {t('channel.view-channel')}
              <span
                className="modal-header-cross pull-right"
                onClick={this.onClose}
              />
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <FormGroup controlId="name">
                <ControlLabel className="control-label-row">
                  {t('Name')}
                </ControlLabel>
                <FormControl type="text" value={channelName} disabled />
              </FormGroup>
              <ControlLabel className="control-label-row">
                {t('channel.members')}
              </ControlLabel>
              <div className="members">
                {this.getMembers().map((member) => (
                  <Member
                    key={member.employeeId}
                    member={member}
                    isCurrentMember={
                      member.employeeId === currentMember.employeeId
                    }
                  />
                ))}
              </div>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button id="submit" bsStyle="primary" onClick={this.onClose}>
              {t('Close')}
            </Button>
          </Modal.Footer>
        </div>
      </Modal>
    );
  }
}

ModalViewChannelInfo.propTypes = {
  t: PropTypes.func.isRequired,
  currentMember: PropTypes.object.isRequired,
  channelName: PropTypes.string.isRequired,
  members: PropTypes.array.isRequired,
  closeModal: PropTypes.func.isRequired
};

export default connect(
  (state) => ({
    currentMember: getCurrentMember(state),
    channelName: getChannelName(state),
    members: getAllChannelMembers(state)
  }),
  (dispatch) =>
    bindActionCreators(
      {
        closeModal: modalAction.hideModalDialog
      },
      dispatch
    )
)(translate(['ui'])(ModalViewChannelInfo));
