import { round } from '../../../utils';

export const countSum = (products) =>
  round(
    products.reduce((acc, prev) => acc + (prev.price * prev.count || 0), 0),
    2
  );

export const getTranslate = (t, tKey) =>
  t(`purchaseRequest.information.${tKey}`);
