import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { translate } from 'react-i18next';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  Modal,
  FormGroup,
  FormControl,
  ControlLabel,
  HelpBlock
} from 'react-bootstrap';
import { Container, Header, Body, Footer } from '../Components';
import AddedDialogs from './AddedDialogs';
import * as modalActions from '../../../../action-creators/modal';
import * as chatWidgetUC from '../../useCases';
import { getGroupChats, getGroupEdit } from '../../getter';
import { logView } from '../../../../utils/logger';

const getAlreadyAdded = (list) => list.filter((item) => item.alreadyAdded);

const log = logView.extend('modalEditDialogList');
const CWModalEditDialogList = ({
  t,
  id,
  name = '',
  newName: newNameProp = '',
  blockGroupChatsEdit,
  blockGroupChats,
  showModal,
  hideModal,
  handleGroupChats
}) => {
  const [newName, setNewName] = useState(newNameProp || name);
  const dialogsList = getAlreadyAdded(blockGroupChatsEdit.list);
  const groupsList = blockGroupChats.list;

  const isNameNotChanged = () => name === newName.trim();

  const checkNewNameAlreadyExists = () => {
    const names = groupsList.map((group) => group.name.toLowerCase());
    return names.includes(newName.toLowerCase().trim());
  };

  const isNewNameValid = () =>
    newName.trim().length > 0 && newName.trim().length <= 60;

  const isNewNameEmpty = () => newName.trim().length === 0;

  const isNameChanged = () => name !== newName.trim();

  const isNewNameAlreadyExists = isNameChanged() && checkNewNameAlreadyExists();

  const onAddToDialogList = () => {
    const onCancel = () => {
      showModal('CW_EDIT_DIALOG_LIST', { id, name, newName });
    };
    const onSave = (checked) => {
      handleGroupChats('add-chats-to-list', checked);
      showModal('CW_EDIT_DIALOG_LIST', { id, name, newName });
    };

    showModal('CW_ADD_TO_DIALOG_LIST', {
      chatsList: blockGroupChatsEdit.list,
      onCancel,
      onSave
    });
  };

  const onDeleteAllDialogs = () => {
    if (window.confirm(t('confirm-delete-all-dialogs'))) {
      handleGroupChats('delete-all-from-list', id);
    }
  };

  const onDeleteItem = (dialogId) => () => {
    handleGroupChats('delete-from-list', dialogId);
  };

  const onHideModal = () => {
    handleGroupChats('cancel-change-group');
    hideModal();
  };

  const onEditDialogList = () => {
    if (isNameChanged()) {
      handleGroupChats('update-group', { id, name: newName });
    } else {
      handleGroupChats('update-group', { id });
    }
    onHideModal();
  };

  const checkSaveDisabled = () => {
    if (!isNewNameValid() || isNewNameAlreadyExists) {
      return true;
    }

    if (isNameChanged()) {
      return false;
    }

    return !(
      blockGroupChatsEdit.includeIds.length ||
      blockGroupChatsEdit.excludeIds.length
    );
  };

  const saveDisabled = checkSaveDisabled();

  log('dialogsList', dialogsList);

  return (
    <Modal show onHide={onHideModal}>
      <Container>
        <Header title={t('editContactList')} onClose={onHideModal} />
        <Body>
          <FormGroup
            controlId="txt"
            style={{ overflow: 'hidden' }}
            validationState={
              isNewNameAlreadyExists ||
              (!isNewNameValid() && !isNameNotChanged())
                ? 'error'
                : null
            }>
            <ControlLabel style={{ fontSize: 12 }}>{t('Name')}</ControlLabel>
            <h6 className="pull-right">
              <small>{`${newName.length}/60`}</small>
            </h6>
            <FormControl
              type="text"
              placeholder=""
              value={newName}
              maxLength="60"
              onChange={(e) =>
                setNewName((e && e.target && e.target.value) || '')
              }
            />
            {isNewNameEmpty() && (
              <HelpBlock style={{ marginBottom: 0 }}>
                {t('Required fields empty')}
              </HelpBlock>
            )}
            {isNewNameAlreadyExists && (
              <HelpBlock style={{ marginBottom: 0 }}>
                {t('Name already exist')}
              </HelpBlock>
            )}
          </FormGroup>
          <AddedDialogs
            list={dialogsList}
            onAdd={onAddToDialogList}
            onDeleteAll={onDeleteAllDialogs}
            onDeleteItem={onDeleteItem}
          />
        </Body>
        <Footer
          lbTitle={t('Cancel')}
          lbOnClick={onHideModal}
          rbTitle={t('Save')}
          rbOnClick={onEditDialogList}
          rbDisabled={saveDisabled}
        />
      </Container>
    </Modal>
  );
};

CWModalEditDialogList.propTypes = {
  t: PropTypes.func.isRequired,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  name: PropTypes.string.isRequired,
  blockGroupChatsEdit: PropTypes.object.isRequired,
  blockGroupChats: PropTypes.object.isRequired,
  showModal: PropTypes.func.isRequired,
  hideModal: PropTypes.func.isRequired
};

const mapState = (state) => ({
  blockGroupChatsEdit: getGroupEdit(state),
  blockGroupChats: getGroupChats(state)
});

const mapDispatch = (dispatch) =>
  bindActionCreators(
    {
      showModal: modalActions.showModal,
      hideModal: modalActions.hideModalDialog,
      ...chatWidgetUC
    },
    dispatch
  );

export default connect(
  mapState,
  mapDispatch
)(translate()(CWModalEditDialogList));
