import {
  useState,
  useRef,
  useEffect,
  useCallback,
  createContext,
  useMemo,
  useContext,
  ReactNode,
  CSSProperties
} from 'react';
import InfiniteLoader from 'react-window-infinite-loader';
import AutoSizer from 'react-virtualized-auto-sizer';
import { Loader } from '@/components/Loader';
import { VariableSizeList as List } from 'react-window';
import cn from 'classnames';
import useResizeObserver from 'use-resize-observer';
import { DynamicListProps, UpdateItem } from './DynamicList.types';
import styles from './DynamicList.module.scss';

const DEFAULT_BUTCH_SIZE = 20;
const DEFAULT_ITEM_WIDTH = 100;
const THRESHOLD_MULTIPLIER = 1.3;

interface ItemProps {
  index: number;
  style: CSSProperties;
}

interface KeyValuePair<TValue> {
  [key: string]: TValue;
}

interface Context {
  getItem: <TEntity>(index: number) => TEntity;
  updateItem: <TEntity>(index: number) => UpdateItem<TEntity>;
  renderItem: <TEntity>(
    item: TEntity,
    updateItem: UpdateItem<TEntity>
  ) => ReactNode;
  isItemLoaded: (index: number) => boolean;
  setSize: (index: number, size: number) => void;
  gap: number;
}

interface ResizeCallback {
  height?: number;
}

const DynamicListContext = createContext(null as unknown as Context);

function Item({ index, style }: ItemProps) {
  const { getItem, updateItem, renderItem, isItemLoaded, setSize, gap } =
    useContext(DynamicListContext);
  const root = useRef<HTMLDivElement | null>(null);
  useEffect(() => {
    if (root.current) {
      setSize(index, root.current.getBoundingClientRect().height + gap);
    }
  }, [root, index, setSize, gap]);

  const setCurrentItem = useMemo(() => updateItem(index), [index, updateItem]);

  const onResize = useCallback(
    ({ height }: ResizeCallback) => {
      if (height) {
        setSize(index, height + gap);
      }
    },
    [setSize, index, gap]
  );

  useResizeObserver({ ref: root, onResize });

  if (!isItemLoaded(index)) {
    return null;
  }

  const currentItem = getItem(index);
  if (currentItem == null) {
    return null;
  }
  return (
    <div key={index} style={style}>
      <div ref={root}>{renderItem(currentItem, setCurrentItem)}</div>
    </div>
  );
}

export function DynamicList<TEntity>({
  provider,
  renderItem,
  batchSize = DEFAULT_BUTCH_SIZE,
  noResultsScreen,
  className = '',
  gap = 8,
  header
}: DynamicListProps<TEntity>) {
  const threshold = batchSize * THRESHOLD_MULTIPLIER;

  const listRef = useRef<List | null>(null);

  function copyRef(node: List<any> | null, cb: (ref: any) => void) {
    listRef.current = node;
    cb(node);
  }

  const [hasNextPage, setHasNextPage] = useState(true);
  const [items, setItems] = useState<TEntity[]>([]);
  const [totalItems, setTotalItems] = useState(batchSize);
  const [showLoader, setShowLoader] = useState(false);

  const resetRef = useRef(false);
  const sizeMap = useRef<KeyValuePair<number>>({});

  const setSize = useCallback((index: number, size: number) => {
    sizeMap.current = { ...sizeMap.current, [index]: size };
    if (listRef && listRef.current) {
      listRef.current.resetAfterIndex(index);
    }
  }, []);

  const getSize = useCallback(
    (index: number) => sizeMap.current[index] || DEFAULT_ITEM_WIDTH,
    []
  );

  useEffect(() => {
    if (resetRef.current) {
      setItems([]);
      sizeMap.current = {};
      setHasNextPage(true);
      setTotalItems(batchSize);
    } else {
      resetRef.current = true;
    }
  }, [provider, batchSize]);

  const loadData = useCallback(
    async (startIndex: number, _endIndex: number) => {
      setShowLoader(!items.length);
      const data = await provider.load(startIndex / batchSize + 1, batchSize);

      setItems((prev) => prev.concat(data));
      if (data.length < batchSize) {
        setTotalItems(items.length + data.length);
        setHasNextPage(false);
      } else {
        setTotalItems((prev) => prev + batchSize);
        setHasNextPage(true);
      }

      setShowLoader(false);
    },
    [batchSize, items, provider]
  );

  const isItemLoaded = useCallback(
    (index: number) => !hasNextPage || index < items.length,
    [hasNextPage, items.length]
  );

  // @ts-ignore
  const context: Context = useMemo(
    () => ({
      renderItem,
      setSize,
      isItemLoaded,
      gap,
      getItem(index: number) {
        return items[index] as TEntity;
      },
      updateItem(index) {
        return (payload) => {
          setItems((prevItems) =>
            prevItems.map((current, currentIndex) =>
              currentIndex === index ? { ...current, ...payload } : current
            )
          );
        };
      }
    }),
    [setSize, isItemLoaded, gap, renderItem, items]
  );

  return (
    <div className={cn(className, styles.dynamicList)}>
      {showLoader && <Loader />}
      {totalItems === 0 ? (
        !showLoader && noResultsScreen
      ) : (
        <>
          {header}
          <DynamicListContext.Provider value={context}>
            <AutoSizer
              className={cn(styles.list, showLoader && styles.loading)}>
              {({ height, width }) => (
                <InfiniteLoader
                  isItemLoaded={isItemLoaded}
                  itemCount={totalItems}
                  loadMoreItems={loadData}
                  minimumBatchSize={batchSize}
                  threshold={threshold}>
                  {({ onItemsRendered, ref }) => (
                    <List
                      height={height}
                      itemCount={totalItems}
                      width={width}
                      itemSize={getSize}
                      onItemsRendered={onItemsRendered}
                      ref={(node) => copyRef(node, ref)}>
                      {({ index, style }) => (
                        <Item index={index} style={style} />
                      )}
                    </List>
                  )}
                </InfiniteLoader>
              )}
            </AutoSizer>
          </DynamicListContext.Provider>
        </>
      )}
    </div>
  );
}
