import { Map, List, fromJS } from 'immutable';

import featureReducer from './product/feature';
import offersReducer from './product/offers';
import physicalDimensionsReducer from './product/physicalDimensions';
import mediaReducer from './product/media';
import picturesReducer from './product/pictures';

const loadProduct = (state, item) =>
  state
    .set('item', fromJS(item))
    .setIn(['item', 'attributes', 'isUploaded'], true);

const setProductProps = (state, { productProps }) =>
  state.mergeIn(['item', 'attributes'], fromJS(productProps));

const setProductField = (state, field, value) =>
  state.update('item', (item) =>
    item.setIn(['attributes', field], fromJS(value))
  );

const trimProductField = (state, field) =>
  state.updateIn(['item', 'attributes', field], (value) =>
    (value || '').trim()
  );

const initState = () =>
  Map({
    item: fromJS({
      attributes: {
        name: '',
        sku: '',
        brand: '',
        mpn: '',
        ean: '',
        offer: { price: '' },
        url: '',
        description: '',
        defaultPicture: { src: '' },
        wd: {
          dimensions: {
            depth: '',
            height: '',
            unit: '',
            width: ''
          },
          weight: {
            value: '',
            unit: ''
          }
        },
        isUploaded: false
      }
    }),
    errors: List()
  });

const setErrors = (data, { errors }) => data.set('errors', fromJS(errors));
const clearErrors = (data) => data.set('errors', List());

const setNewSku = (data, { id }) =>
  data
    .setIn(['item', 'attributes', 'sku'], id)
    .setIn(['item', 'attributes', 'isUploaded'], true);

// const setProductAttributes = (data, { product }) =>
//   data.setIn(['item', 'attributes'], fromJS(product)).setIn(['item', 'attributes', 'isUploaded'], true);

const productReducer = (state = initState(), action) => {
  switch (action.type) {
    case 'PRODUCT_LOAD':
      return loadProduct(state, action.item);
    case 'PRODUCT:PROPERTIES:UPDATE:SUCCESS':
      return setProductProps(state, action.payload);
    case 'PRODUCT_CLEAR':
      return initState();
    case 'PRODUCT_SET_FIELD':
      return setProductField(state, action.field, action.value);
    case 'PRODUCT_TRIM_FIELD':
      return trimProductField(state, action.field);
    case 'PRODUCT:GET_NEW_ID:SUCCESS':
      return setNewSku(state, action.payload);
    case 'PRODUCT:CREATE:FAILED':
      return setErrors(state, action.payload);
    case 'PRODUCT:CLEAR_ERRORS':
      return clearErrors(state);
    default:
      return state
        .updateIn(['item', 'feature'], (featureState) =>
          featureReducer(featureState, action)
        )
        .updateIn(['item', 'offer'], (offerState) =>
          offersReducer(offerState, action)
        )
        .updateIn(['item', 'wd'], (physicalDimensionsState) =>
          physicalDimensionsReducer(physicalDimensionsState, action)
        )
        .updateIn(['item', 'media'], (mediaState) =>
          mediaReducer(mediaState, action)
        )
        .updateIn(['item', 'pictures'], (picturesState) =>
          picturesReducer(picturesState, action)
        );
  }
};

export default productReducer;
