import React from 'react';
import { Col, HelpBlock, Row } from 'react-bootstrap';
import ProductsTable from '../../common/ProductsTable';
import getDataFromClipboard from '../../../../lib/getDataFromClipboard';
import SectionOffset from '../../Request/SectionOffset';
import IconSimpleCross from '../../../../icons/cross';
import {
  getInvalidProducts,
  initProduct,
  parseProductsFromClipboard
} from '../utils';
import Checkbox from '../../../CheckboxNew';
import { useTranslate } from '../../../../TranslateProvider';

const RequestTable = ({
  products,
  setProducts,
  isErrorShown,
  partResponse,
  setPartResponse,
  isOpened,
  setIsOpened
}) => {
  const t = useTranslate();

  if (!isOpened) {
    return null;
  }

  const handlePasteTable = (index) => (event) => {
    const clipBoardData = getDataFromClipboard(event);
    const newProducts = parseProductsFromClipboard(clipBoardData);
    if (newProducts) {
      event.preventDefault();
      setProducts([...products.slice(0, index), ...newProducts]);
    }
  };

  const handleChangeProduct =
    (product, index, name) =>
    ({ target: { value } }) => {
      const updatedProduct = { ...product, [name]: value };

      const newProducts = [...products];
      newProducts[index] = updatedProduct;

      setProducts(newProducts);
    };

  const handleRemoveProductRow = (index) => () => {
    if (products.length === 1) {
      setProducts([{ ...initProduct }]);
      return;
    }
    setProducts([...products.slice(0, index), ...products.slice(index + 1)]);
  };

  const handleAddProductRow = () =>
    setProducts([...products, { ...initProduct }]);

  const isProductsErrorShowed =
    (isErrorShown.save || isErrorShown.publish) &&
    getInvalidProducts(products).length > 0;

  return (
    <>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        {t('table')}
        <div
          onClick={() => setIsOpened(false)}
          data-testid="close-product-table-icon-test">
          <IconSimpleCross />
        </div>
      </div>

      <SectionOffset offset={16} />
      <Row className="pd-t-10">
        <Col xs={12}>
          <ProductsTable
            products={products}
            handlePasteTable={handlePasteTable}
            handleChangeProduct={handleChangeProduct}
            handleRemoveProductRow={handleRemoveProductRow}
            handleAddProductRow={handleAddProductRow}
            isErrorShown={isErrorShown}
          />
          {isProductsErrorShowed && (
            <HelpBlock
              data-testid="new-request-form-products-label-error"
              style={{ marginBottom: 0 }}>
              {t('enter_value_or_delete')}
            </HelpBlock>
          )}

          <div
            className="custom-checkbox"
            data-testid="new-request-form-products-custom-checkbox">
            <Checkbox
              data-testid="request-part-response-allow-checkbox-test"
              id="part-select"
              value={partResponse}
              onClick={() => setPartResponse(!partResponse)}
            />
            <div style={{ color: '#252B49' }}>
              {t('Request part response allow')}
            </div>
          </div>
        </Col>
      </Row>
      <SectionOffset offset={28} />
    </>
  );
};

export default RequestTable;
