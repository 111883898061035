import * as Assets from './Icon.assets';
import { IconName, ReactSvgType } from './Icon.types';

export function findIconByName(iconName: IconName): ReactSvgType | null {
  switch (iconName) {
    case 'arrow-down': {
      return Assets.ArrowDownIcon;
    }
    case 'arrow-up': {
      return Assets.ArrowUpIcon;
    }
    case 'excel': {
      return Assets.Excel;
    }
    case 'info': {
      return Assets.Info;
    }
    case 'close': {
      return Assets.Close;
    }
    case 'arrow-left': {
      return Assets.ArrowLeftIcon;
    }
    case 'arrow-right': {
      return Assets.ArrowRightIcon;
    }
    case 'spinner': {
      return Assets.Spinner;
    }
    case 'restore': {
      return Assets.Restore;
    }
    case 'input-file': {
      return Assets.InputFile;
    }
    case 'help': {
      return Assets.Help;
    }
    case 'search': {
      return Assets.Search;
    }
    case 'chat': {
      return Assets.Chat;
    }
    case 'select': {
      return Assets.Select;
    }
    case 'filter': {
      return Assets.Filter;
    }
    case 'copy': {
      return Assets.Copy;
    }
    case 'call-end': {
      return Assets.CallEnd;
    }
    case 'marker-pin': {
      return Assets.MarkerPin;
    }
    case 'rnp': {
      return Assets.RNP;
    }
    case 'no-rnp': {
      return Assets.NoRNP;
    }
    case 'plus': {
      return Assets.Plus;
    }
    case 'edit': {
      return Assets.Edit;
    }
    case 'trash': {
      return Assets.Trash;
    }
    case 'calendar': {
      return Assets.Calendar;
    }
    case 'clock-fast-forward': {
      return Assets.ClockFastForward;
    }
    case 'globe': {
      return Assets.Globe;
    }
    case 'mail': {
      return Assets.Mail;
    }
    case 'contact-add': {
      return Assets.ContactAdd;
    }
    case 'contact-added': {
      return Assets.ContactAdded;
    }
    case 'ok': {
      return Assets.Ok;
    }
    case 'mute': {
      return Assets.Mute;
    }
    case 'unmute': {
      return Assets.Unmute;
    }
    case 'ETA': {
      return Assets.ETA;
    }
    case 'prepayment': {
      return Assets.Prepayment;
    }
    case 'delivery': {
      return Assets.Delivery;
    }
    default: {
      return null;
    }
  }
}
