import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as storage from '../storage';
import * as responsesStorage from '../../Responses/storage';
import './styles.css';

import ResponseCard from './ResponseCard';
import { countNewResponses } from './utils';
import { useTranslate } from '../../../../TranslateProvider';

const ResponsesTable = ({
  isOpened,
  responses = [],
  request,
  viewRequestResponse,
  viewAllResponses
}) => {
  const t = useTranslate();

  if (!isOpened || !responses) {
    return null;
  }

  const handleMarkAsRead = () => {
    responses.map((response) => viewRequestResponse(response.id));
    viewAllResponses(request.id);
  };

  const getLinkClassName = () =>
    `request-card-link${countNewResponses(responses) === 0 ? '-disabled' : ''}`;

  return (
    <div className="request-card-response">
      <hr />
      <div
        style={{ justifyContent: 'space-between', alignItems: 'baseline' }}
        className="request-card-row">
        <div
          className="request-card-title"
          style={{ fontSize: 14, marginBottom: 16 }}>
          {t('Responses')}
        </div>
        <div className={getLinkClassName()} onClick={handleMarkAsRead}>
          {t('mark-as-read')}
        </div>
      </div>
      {responses.map((response) => (
        <ResponseCard request={request} key={response.id} response={response} />
      ))}
      {responses.length === 0 && (
        <div style={{ marginBottom: 16 }} className="request-card-gray">
          {t('no-responses')}
        </div>
      )}
    </div>
  );
};

export default connect(null, (dispatch) =>
  bindActionCreators(
    {
      viewRequestResponse: responsesStorage.viewRequestResponse,
      viewAllResponses: storage.viewAllResponses
    },
    dispatch
  )
)(ResponsesTable);
