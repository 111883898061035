import fetch from 'isomorphic-fetch';

const loadLanguageList = (languageList) => ({
  type: 'LANGUAGE_LIST_LOAD',
  languageList
});

const loadAvailableLanguages = (languages) => ({
  type: 'AVAILABLE_LANGUAGES_LOAD',
  languages
});

export const getLanguageList = () => (dispatch) =>
  fetch('/api/languages', { credentials: 'include' })
    .then((response) => response.json())
    .then((json) => dispatch(loadLanguageList(json)));

export const getAvailableLanguages = (catalog) => (dispatch) =>
  fetch(`/api/languages/${catalog}`, { credentials: 'include' })
    .then((response) => response.json())
    .then((json) => dispatch(loadAvailableLanguages(json)));
