import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { translate } from 'react-i18next';
import { withRouter } from '../hoc/withRouter';
import MessengerOther from '../components/Layouts/MessengerOther';

import ContainersDefault from '../components/Containers/ContainersDefault';
import * as serviceActions from '../action-creators/services';
import { MessengerWidgetWrapper } from '../components/Widgets/Messenger/MessengerWidget';
import PurchaseRequestsTabs from '../config/pages/requests';
import MyResponsesContent from '../components/PurchaseRequests/Responses/MyResponses';
import { SidebarThemeDefault } from '../components/Sidebar';
import MyResponsesSidebar from '../presenters/sidebars/PurchaseRequestResponsesSidebar/MyResponses';
import Button from '../components/Buttons/Button';
import * as modalActions from '../action-creators/modal';

class MResponsesPurchaseRequests extends Component {
  constructor(props) {
    super(props);

    this.showNewRequestPage = this.showNewRequestPage.bind(this);
  }

  get userPermission() {
    const { user } = this.props;

    const permissions = user.getIn(['permissions', 'purchaseRequests']);

    if (!permissions) {
      return {
        read: false,
        update: false,
        delete: false
      };
    }

    return permissions.toJS();
  }

  get breadcrumbs() {
    const { t } = this.props;
    return [{ name: t('Purchase requests') }];
  }

  get getBreadcrumbsButtons() {
    const { t } = this.props;

    return [
      {
        element: (
          <Button
            variant="outline-primary"
            onClick={this.showNewRequestPage}
            disabled={!this.userPermission.update}>
            {`+ ${t('Create purchase request')}`}
          </Button>
        )
      }
    ];
  }

  showNewRequestPage() {
    const { gaSend, navigate, showModal } = this.props;
    gaSend('create_request');
    // TODO Тут будет проверка на соответствие прав у пользователя сейчас я СПЕЦИАЛЬНО хардкожу чтобы модалка ВСЕГДА открывалась, когда будет ролевая модель, нужно будет это исправить
    if (true) {
      showModal('MODAL_CREATE_PURCHASE_REQUEST');
      return;
    }
    navigate('/requests/all/my-requests/create', {
      state: { noRestoreData: true }
    });
  }

  render() {
    const { t } = this.props;

    return (
      <>
        <MessengerOther
          theme="gray"
          title={t('Purchase requests')}
          breadcrumbs={this.breadcrumbs}
          panelBtns={this.getBreadcrumbsButtons}
          content={
            <ContainersDefault>
              <PurchaseRequestsTabs activeKey="my-responses" />
              <MyResponsesContent />
            </ContainersDefault>
          }
          sidebar={
            <SidebarThemeDefault insideState enableToggle id="my-responses">
              <MyResponsesSidebar />
            </SidebarThemeDefault>
          }
        />
        <MessengerWidgetWrapper />
      </>
    );
  }
}

export default withRouter(
  connect(
    (state) => ({
      user: state.getIn(['user', 'user'])
    }),
    (dispatch) =>
      bindActionCreators(
        {
          showModal: modalActions.showModal,
          gaSend: serviceActions.gaSendServiceAction
        },
        dispatch
      )
  )(translate(['ui'], { wait: true })(MResponsesPurchaseRequests))
);
