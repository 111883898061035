/* eslint-disable max-len */
import React from 'react';

const ArrowLeftIcon = () => (
  <svg
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.1214 5.99989L9.24271 1.87857L7.82849 0.464355L2.29297 5.99989L7.82849 11.5354L9.2427 10.1212L5.1214 5.99989Z"
      fill="#C6CAE3"
    />
  </svg>
);

export default ArrowLeftIcon;
