import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { translate } from 'react-i18next';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Modal, Button } from 'react-bootstrap';
import { ModalContainer } from '../elements';
import * as modalActions from '../../../action-creators/modal';
import './styles.css';

class ModalEditChannelUserRole extends Component {
  constructor(props) {
    super(props);

    this.state = {
      role: this.props.initialRole
    };
  }

  onSubmit = () => {
    const { hideModalDialog, onSubmit } = this.props;
    hideModalDialog();
    onSubmit(this.state.role);
  };

  onCancel = () => {
    const { hideModalDialog, onCancel } = this.props;
    hideModalDialog();
    onCancel();
  };

  onChange = (role) => {
    this.setState({ role });
  };

  renderOptions = () => {
    const { mode, rolesOptions } = this.props;
    const options = rolesOptions.filter((option) => !option.disabled);
    return options.map((option) => this.createOption(mode, option));
  };

  createOption = (mode, option) => {
    const { t } = this.props;
    const { role } = this.state;
    const roleText = t(`channel-roles-${option.value}`);
    const roleCaption = t(
      `${mode.toLowerCase()}-roles-caption-${option.value}`
    );

    return (
      <div key={option.value} className="modal-edit-channel-role__container">
        <div className="modal-edit-channel-role__role-value">
          <input
            type="radio"
            checked={role === option.value}
            value={option.value}
            onChange={(e) => this.onChange(e.target.value)}
          />
          <span>{roleText}</span>
        </div>
        <div className="modal-edit-channel-role__role-caption">
          {roleCaption}
        </div>
      </div>
    );
  };

  render() {
    const { t } = this.props;

    return (
      <Modal onHide={this.onCancel} show>
        <ModalContainer mods={{ type: 'default', theme: 'default' }}>
          <Modal.Header>
            <Modal.Title>
              {t('assign_role')}
              <span
                className="modal-header-cross pull-right"
                onClick={this.onCancel}
              />
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="modal-edit-channel-role__caption">
              {t('assign_role_caption')}
            </div>
            {this.renderOptions()}
          </Modal.Body>
          <Modal.Footer>
            <Button id="cancel" className="pull-left" onClick={this.onCancel}>
              {t('Cancel')}
            </Button>
            <Button bsStyle="primary" onClick={this.onSubmit}>
              {t('Save')}
            </Button>
          </Modal.Footer>
        </ModalContainer>
      </Modal>
    );
  }
}

ModalEditChannelUserRole.propTypes = {
  t: PropTypes.func.isRequired,
  hideModalDialog: PropTypes.func.isRequired,

  // Options
  mode: PropTypes.string.isRequired,
  rolesOptions: PropTypes.array.isRequired,
  initialRole: PropTypes.string.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired
};

const mapDispatch = (dispatch) =>
  bindActionCreators(
    {
      hideModalDialog: modalActions.hideModalDialog
    },
    dispatch
  );

export default connect(
  null,
  mapDispatch
)(translate()(ModalEditChannelUserRole));
