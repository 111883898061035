import React from 'react';
import block from 'bem-cn-lite';
import { isNil } from 'ramda';
import Chip from 'react-components/chips/simple';
import { LineBadge } from 'react-components/badges';
import { formatDate } from '../../../../../../../utils/date';
import { getFullName } from '../../../../../../../utils/utils';
import { useTranslate } from '../../../../../../../TranslateProvider';
import CWSIconDropdown from '../../../../../../../components/ChatWidget/Dropdown/Icon';
import '../../style.css';

const b = block('task-list-item');

function translate(t, tkey) {
  return t(`taskManager.listPage.card.${tkey}`);
}

const Task = ({ task, extraOptions, handler, onClick }) => {
  const t = useTranslate();

  return (
    <div className={b({ expired: task.isExpired })} onClick={onClick(task)}>
      {task.isShowBadge && <LineBadge />}
      <div className={b('left')}>
        <div className={b('title')}>{task.title}</div>
        {!isNil(task.endDate) && (
          <div className={b('end-date')}>{`${translate(
            t,
            'endDate'
          )}: ${formatDate(task.endDate)}`}</div>
        )}
      </div>
      <div className={b('right')}>
        <div className={b('responsible')}>
          <span className={b('responsible-label')}>{`${translate(
            t,
            'responsible'
          )}: `}</span>
          <span className={b('responsible-value')}>
            {getFullName(task.executors[0])}
          </span>
        </div>
        <div className={b('tags')}>
          {task.tags.map((tag) => (
            <Chip key={tag.id} value={tag.title} color={tag.color} />
          ))}
        </div>
      </div>
      <div
        className={b('buttons')}
        data-id="extra"
        onClick={(event) => event.stopPropagation()}>
        <CWSIconDropdown
          icon="vertical-extra"
          list={extraOptions}
          onSelect={handler(task)}
        />
      </div>
    </div>
  );
};

export default React.memo(Task);
