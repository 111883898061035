import { makeResponse } from '../domain/response';
import { makeProduct } from '../domain/product';

function responseMapper(response) {
  return makeResponse({
    id: response.id,
    companyName: response.fromInfo.name,
    products: response.attrs.purchaseRequest.products.map(makeProduct)
  });
}

export default responseMapper;
