import {
  USER_CONFIGS_SET_ITEM,
  USER_CONFIGS_SET_ITEM_LIST,
  USER_CONFIGS_TO_DEFAULT_STATE
} from '../actionTypes/userConfigs';

export const setItem = (configItem) => ({
  type: USER_CONFIGS_SET_ITEM,
  payload: configItem
});
export const setItems = (configItems) => ({
  type: USER_CONFIGS_SET_ITEM_LIST,
  payload: configItems
});
export const clear = () => ({ type: USER_CONFIGS_TO_DEFAULT_STATE });
