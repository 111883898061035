import React, { Component } from 'react';

import PropTypes from 'prop-types';
import { translate } from 'react-i18next';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Modal } from 'react-bootstrap';

import { Container, Header, Body } from '../../Components';
import Message from './msg';

import * as modalActions from '../../../../../action-creators/modal';
import * as contactActions from '../../../../../action-creators/contacts';
import * as useCase from '../../../useCases';

import './styles.css';
import { getOnline } from '../../../../../modules/online/getter';

class CWModalMessagesList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      list: []
    };
    this.getList = this.getList.bind(this);
    this.getMessages = this.getMessages.bind(this);
    this.onCancel = this.onCancel.bind(this);
    this.onRemove = this.onRemove.bind(this);
  }

  componentDidMount() {
    const list = this.getList();
    this.setState({ list });
  }

  onCancel() {
    const { onCancel, hideModalDialog } = this.props;
    hideModalDialog();
    onCancel();
  }

  onRemove(id) {
    const { onRemove } = this.props;
    const { list } = this.state;
    const newList = list.filter((i) => i.id !== id);
    this.setState({ list: newList });
    onRemove(id);
    if (newList.length === 0) {
      this.onCancel();
    }
  }

  getMessages() {
    const { online, showProfile } = this.props;
    const { list } = this.state;

    if (list.length > 0) {
      return list.map((message) => (
        <Message
          message={message}
          onRemove={this.onRemove}
          online={online}
          showProfile={showProfile}
        />
      ));
    }
    return null;
  }

  getList() {
    const { list = [] } = this.props;
    return Array.isArray(list) ? list : list.toJS();
  }

  render() {
    const { t, title } = this.props;

    return (
      <Modal onHide={this.onCancel} show>
        <Container>
          <Header title={t(title)} onClose={this.onCancel} />
          <Body>
            <div className="cw-modal-list__container">{this.getMessages()}</div>
          </Body>
        </Container>
      </Modal>
    );
  }
}

CWModalMessagesList.propTypes = {
  t: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
  onCancel: PropTypes.func,
  showProfile: PropTypes.func.isRequired,
  hideModalDialog: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  list: PropTypes.array.isRequired
};

CWModalMessagesList.defaultProps = {
  onCancel: () => {}
};

const mapDispatch = (dispatch) =>
  bindActionCreators(
    {
      showModal: modalActions.showModal,
      hideModalDialog: modalActions.hideModalDialog,
      loadContacts: contactActions.loadContacts,
      ...useCase
    },
    dispatch
  );

export default connect(
  (state) => ({
    online: getOnline(state)
  }),
  mapDispatch
)(translate()(CWModalMessagesList));
