import React, { Component } from 'react';
import { translate } from 'react-i18next';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Form, Row, Button as RButton } from 'react-bootstrap';
import { isNil } from 'ramda';
import { withRouter } from '../../../hoc/withRouter';

import * as serviceActions from '../../../action-creators/services';
import * as requestsActions from '../../../action-creators/purchaseRequests';
import * as modalActions from '../../../action-creators/modal';
import * as storageActions from '../../../action-creators/storage';
import * as messageActions from '../../../action-creators/message';
import * as storeGetters from '../../../storeGetters';
import { formatRequestNumber } from '../../../utils/utils';
import { requestCategories } from '../../../storage/purchaseRequest.storage';
import RespondMain from './RespondMain';
import SectionOffset from './SectionOffset';
import RequestContent from './RequestContentView';
import BtnIcon from '../../ChatWidget/ButtonIcon';
import * as chatWidgetUC from '../../ChatWidget/useCases';
import './style.css';
import Button from '../../Buttons/Button';
import * as storage from '../MyRequests/storage';
import ShareDropdown from './components/ShareDropdown';
import GoBack from '../../Buttons/GoBack';
import { hasResponded } from './requestMethods';
import { getPurchaseRequest } from '../message';
import { PurchaseRequestStatuses } from '../entities/PurchaseRequestStatuses';
import SuppliersActivities from '../../SuppliersActivities';
import {
  countNewResponses,
  getResponses
} from '../MyRequests/RequestTable/utils';
import ApprovalLog from '../ApprovalLog';
import { executorActionTypes, purchaseRequestTypes } from '../domain/entities';

const MODS = {
  owner: 'owner',
  'simple-user': 'simple-user',
  unauthorized: 'unauthorized',
  'request-unpublished': 'request-unpublished'
};

const BtnCollapse = ({ isCollapsed, onClick }) => {
  const icon = isCollapsed ? 'arrow-down-circle' : 'arrow-up-circle';
  return <BtnIcon icon={icon} width={34} height={34} onClick={onClick} />;
};

function isRequestPublished(request) {
  return request.get('isPublished');
}

function hasResponses(request) {
  return request.get('hasResponses');
}

const RequestButtons = ({
  t,
  request,
  havePermissionToUpdate,
  onEditClick,
  onClickByUnpublish
}) => {
  if (isShowUnpublishButton()) {
    return (
      <Button variant="outline-primary" onClick={onClickByUnpublish}>
        {t('Unpublish')}
      </Button>
    );
  }

  if (isShowEditButton()) {
    return <Button onClick={onEditClick}>{t('Edit')}</Button>;
  }

  return null;

  function isShowEditButton() {
    return (
      !isRequestPublished(request) &&
      !hasResponses(request) &&
      havePermissionToUpdate &&
      request.type === purchaseRequestTypes.regular
    );
  }

  function isShowUnpublishButton() {
    return (
      request.get('status') === PurchaseRequestStatuses.receivingResponses &&
      havePermissionToUpdate
    );
  }
};

const UnauthorizedButtons = ({ t, onClickByRegister }) => (
  <RButton bsStyle="primary" onClick={onClickByRegister}>
    {t('Register')}
  </RButton>
);

const Switcher = ({ mode, props }) => {
  switch (mode) {
    case MODS.owner:
      return (
        <div className="form-request-bottom-buttons">
          <RequestButtons {...props} />
        </div>
      );
    case MODS['simple-user']:
      return <RespondMain {...props} />;
    case MODS.unauthorized:
      return (
        <div className="form-request-bottom-buttons">
          <UnauthorizedButtons {...props} />
        </div>
      );
    default:
      throw new Error(`No such handler for mode = ${mode}`);
  }
};

const getIsResponsePage = (props) => props.location.search.includes('response');
const checkIsRequestPage = (location) =>
  /^\/requests\/all\/\d*$/.test(location.pathname) && !location.search;
const checkIsMyRequestViewPage = (location) =>
  /^\/requests\/my\/\d*\/view$/.test(location.pathname);

class Request extends Component {
  constructor(props) {
    super(props);
    this.state = {
      categories: [],
      lastLanguage: '',
      isCollapsed: getIsResponsePage(this.props),
      isLoading: true
    };

    this.setCategories = this.setCategories.bind(this);
    this.setCollapsed = this.setCollapsed.bind(this);
    this.goBack = this.goBack.bind(this);
    this.toCollapse = this.toCollapse.bind(this);
    this.toExpand = this.toExpand.bind(this);
    this.toggleCollapse = this.toggleCollapse.bind(this);
    this.goToEdit = this.goToEdit.bind(this);
    this.unpublishRequest = this.unpublishRequest.bind(this);
    this.redirectToRegister = this.redirectToRegister.bind(this);
  }

  async componentDidMount() {
    const { handleGroupChats, requestId, getChatGroups, isAuth } = this.props;
    if (isAuth) {
      await getChatGroups();
      await handleGroupChats('set-filter-if-exist', requestId);
    }
  }

  UNSAFE_componentWillMount() {
    const { requestId, user } = this.props;
    (async () => {
      getPurchaseRequest({
        requestId,
        language: user.get('language'),
        userId: user.get('id'),
        currentCompany: user.get('currentCompany')
      });

      await requestCategories().then(this.setCategories);
      this.setState({ isLoading: false });
    })();
  }

  async UNSAFE_componentWillReceiveProps(props) {
    const { request, user } = props;

    if (request) {
      const newState = request.toJS();
      this.selectCategoriesByIds(newState.categoriesId);
      if (user.get('language') && !this.state.lastLanguage) {
        this.setState({ lastLanguage: user.get('language') });
      }
    }
  }

  async componentDidUpdate(prevProps) {
    const { isAuth, handleGroupChats, requestId, getChatGroups } = this.props;

    if (prevProps.isAuth !== isAuth && isAuth) {
      await getChatGroups();
      await handleGroupChats('set-filter-if-exist', requestId);
    }
  }

  componentWillUnmount() {
    const { cleanRequestState, handleGroupChats } = this.props;
    cleanRequestState();
    handleGroupChats('clear-filter');
  }

  get isOwner() {
    const { company, request } = this.props;

    return company.get('id') === request.get('companyId');
  }

  get isRequestUnpublished() {
    const { request } = this.props;

    return request.get('type') === 'unpublished';
  }

  get mode() {
    const { isAuth } = this.props;

    if (!isAuth) {
      return MODS.unauthorized;
    }

    if (this.isOwner) return MODS.owner;

    if (this.isRequestUnpublished) {
      return MODS['request-unpublished'];
    }

    return MODS['simple-user'];
  }

  get isShowApprovalLog() {
    const { request } = this.props;

    return !isNil(request?.toJS()?.executors);
  }

  get hasRespondFromCurrentCompany() {
    const { request } = this.props;

    return hasResponded(request);
  }

  get switcherProps() {
    const {
      t,
      request,
      company,
      fullPermissionToCustomers,
      fullPermissionToCustomersOrders,
      havePermissionToUpdate,
      updatePurchaseRequestPermission,
      sendRespondToRequest,
      saveDraftRespond,
      deleteResponse,
      uploadFile,
      removeFileLinkBySection,
      setInfoMessage,
      setErrorMessage,
      setSuccessMessage,
      requestId,
      gaSend,
      user,
      showModal,
      isShowExportToExcelBtn
    } = this.props;

    switch (this.mode) {
      case MODS.owner:
        return {
          t,
          request,
          havePermissionToUpdate:
            havePermissionToUpdate && updatePurchaseRequestPermission,
          onEditClick: this.goToEdit,
          onClickByUnpublish: this.unpublishRequest,
          isShowExportToExcelBtn
        };
      case MODS['simple-user']:
        return {
          fullPermissionToCustomers,
          fullPermissionToCustomersOrders,
          havePermissionToUpdate: updatePurchaseRequestPermission,
          request,
          requestId,
          company,
          sendRespondToRequest,
          saveDraftRespond,
          deleteResponse,
          setInfoMessage,
          setErrorMessage,
          setSuccessMessage,
          uploadFile,
          removeFileLinkBySection,
          gaSend,
          user,
          t,
          showModal,
          toCollapse: this.toCollapse,
          toExpand: this.toExpand,
          isResponsePage: getIsResponsePage(this.props),
          isShowExportToExcelBtn
        };
      case MODS.unauthorized:
        return {
          t,
          onClickByRegister: this.redirectToRegister
        };
      case MODS['request-unpublished']:
        return {
          fullPermissionToCustomers,
          fullPermissionToCustomersOrders,
          havePermissionToUpdate: updatePurchaseRequestPermission,
          request,
          requestId,
          company,
          sendRespondToRequest,
          saveDraftRespond,
          deleteResponse,
          setInfoMessage,
          setErrorMessage,
          setSuccessMessage,
          uploadFile,
          removeFileLinkBySection,
          gaSend,
          user,
          t,
          showModal,
          toCollapse: this.toCollapse,
          toExpand: this.toExpand,
          isResponsePage: getIsResponsePage(this.props)
        };
      default:
        throw new Error(`No such handler for mode = ${this.mode}`);
    }
  }

  get isShowShare() {
    return this.mode !== MODS.unauthorized;
  }

  get isRequestFromCurrentCompany() {
    const { request, company } = this.props;
    return request.size > 0 && request.get('companyId') === company.get('id');
  }

  get isShowEnrichSuppliersActivities() {
    const request = this.props.request.toJS();
    const user = this.props.user.toJS();

    if (
      request?.type === purchaseRequestTypes.withConfirmation &&
      request?.authorId === user?.id
    ) {
      return false;
    }

    if (request.executorType === executorActionTypes.publication) {
      return Boolean(request.lastPublishedDate);
    }

    return !request.executorType && this.isRequestFromCurrentCompany;
  }

  get isShowCollapseBtn() {
    const { location } = this.props;

    return !checkIsRequestPage(location) && !checkIsMyRequestViewPage(location);
  }

  redirectToRegister() {
    const { history } = this.props;

    history.push('/registration');
  }

  setCategories(categories) {
    this.setState({ categories });
  }

  setCollapsed(isCollapsed) {
    this.setState({ isCollapsed });
  }

  toggleCollapse() {
    this.setState((prevState) => ({ isCollapsed: !prevState.isCollapsed }));
  }

  toCollapse() {
    this.setCollapsed(true);
  }

  toExpand() {
    this.setCollapsed(false);
  }

  getCollapse() {
    return this.state.isCollapsed;
  }

  getCategories = () => this.state.categories;

  selectCategoryOr(category, isSelect) {
    return isSelect ? { ...category, selected: true } : category;
  }

  selectCategoriesByIds = (ids = []) => {
    const categories = this.getCategories().map((category) =>
      this.selectCategoryOr(category, ids.includes(category.id))
    );
    this.setCategories(categories);
  };

  goBack() {
    this.props.history.goBack();
  }

  goToEdit() {
    const { history, requestId } = this.props;

    history.push(`/requests/my/${requestId}/edit`);
  }

  unpublishRequest() {
    const { t, requestId, showModal, unpublishRequest, setSuccessMessage } =
      this.props;

    showModal('SIMPLE_SUBMIT', {
      title: `${t('Unpublish')}?`,
      textBtnConfirm: 'Confirm',
      submitAction: async () => {
        unpublishRequest(+requestId).then(() => {
          setSuccessMessage({ key: 'Request unpublished success' });
        });
      }
    });
  }

  render() {
    const {
      t,
      request,
      requestId,
      isShowBackBtn = true,
      isShowExportToExcelBtn = true
    } = this.props;

    if (this.mode === MODS['request-unpublished']) {
      return (
        <div className="form-content-center form-request">
          <div className="form-request-htmltag">
            <div style={{ position: 'absolute', top: 4, left: -60 }}>
              <GoBack data-testid="request-go-back-button" />
            </div>
            {this.hasRespondFromCurrentCompany ? (
              <RespondMain {...this.switcherProps} />
            ) : (
              t('purchaseRequest.requestUnpublished')
            )}
          </div>
        </div>
      );
    }

    if (!request || !request.get('id') || this.state.isLoading) return null;

    return (
      <div className="form-content-center form-request">
        <Form className="form-request-htmltag">
          {isShowBackBtn && (
            <div style={{ position: 'absolute', top: 4, left: -60 }}>
              <GoBack dataTestid="request-go-back-button" />
            </div>
          )}
          <div className="request-title">
            <div style={{ width: '100%' }}>
              <div className="request-title-row">
                <div
                  className="request-title-row__title"
                  data-testid="request-purchase-request">
                  {t('Purchase request')}
                  {` № ${formatRequestNumber(request.get('id'))} - ${
                    request.get('number') ||
                    formatRequestNumber(request.get('id'))
                  }`}
                </div>
                {this.isShowShare && <ShareDropdown requestId={requestId} />}
                <div
                  className="request-title-row__status"
                  data-testid="request-purchase-status">
                  <SuppliersActivities
                    requestId={request.get('id')}
                    requestStatus={request.get('status')}
                    responsesCount={
                      getResponses({
                        responses: request.get('responses') || []
                      }).length
                    }
                    newResponsesCount={countNewResponses(
                      request.get('responses') || []
                    )}
                    isCustomerView={this.isShowEnrichSuppliersActivities}
                    noHover
                  />
                </div>
                {this.isShowCollapseBtn && (
                  <BtnCollapse
                    isCollapsed={this.state.isCollapsed}
                    onClick={this.toggleCollapse}
                  />
                )}
              </div>
              <span style={{ color: '#767B92' }}>
                {request.get('sum') && <div>{`${request.get('sum')} p.`}</div>}
              </span>
            </div>
          </div>
          <hr style={{ opacity: '0.3' }} />

          {this.isShowApprovalLog && (
            <>
              <ApprovalLog
                isCollapsed={false}
                className="request-card-approval-log"
                request={request.toJS()}
              />
              <hr style={{ opacity: '0.3' }} />
            </>
          )}

          {!this.state.isCollapsed && (
            <>
              <RequestContent
                t={t}
                request={request}
                categories={this.getCategories()}
                isShowExportToExcelBtn={isShowExportToExcelBtn}
              />
              <SectionOffset offset={20} />
            </>
          )}
          <Row>
            <Switcher mode={this.mode} props={this.switcherProps} />
          </Row>
        </Form>
      </div>
    );
  }
}

export default connect(
  (state) => ({
    isAuth: storeGetters.isAuth(state),
    user: state.getIn(['user', 'user']),
    company: state.get('company'),
    request: state.getIn(['purchaseRequests', 'request']),
    error: storeGetters.getPurchaseRequestError(state),
    havePermissionToUpdate: state.getIn([
      'user',
      'user',
      'permissions',
      'company',
      'update'
    ]),
    fullPermissionToCustomers: state.getIn([
      'user',
      'user',
      'permissions',
      'customers',
      'delete'
    ]),
    fullPermissionToCustomersOrders: state.getIn([
      'user',
      'user',
      'permissions',
      'customerOrders',
      'delete'
    ]),
    updatePurchaseRequestPermission:
      storeGetters.haveUpdatePurchaseRequestsPermission(state)
  }),
  (dispatch) =>
    bindActionCreators(
      {
        gaSend: serviceActions.gaSend,
        showModal: modalActions.showModal,
        sendRespondToRequest: requestsActions.sendRespondToRequest,
        saveDraftRespond: requestsActions.saveDraftRespond,
        deleteResponse: requestsActions.deleteResponse,
        cleanRequestState: requestsActions.cleanRequestState,
        uploadFile: storageActions.uploadFile,
        removeFileLinkBySection: storageActions.removeFileLinkBySection,
        setInfoMessage: messageActions.setInfoMessage,
        setErrorMessage: messageActions.setErrorMessage,
        setSuccessMessage: messageActions.setSuccessMessage,
        unpublishRequest: storage.unpublishRequest,
        handleGroupChats: chatWidgetUC.handleGroupChats,
        getChatGroups: chatWidgetUC.getChatGroups
      },
      dispatch
    )
)(withRouter(translate(['ui'], { wait: true })(Request)));
