import fetch from 'isomorphic-fetch';
import customHistory from '../customHistory';
import {
  loadTableProducts,
  removeFromTable,
  sortBy,
  removeFromTableProducts,
  loadTable
} from './table';
import { validate, updateServerValidation } from '../validator';
import keyDebounce from '../utils/debounce';
// import { uploadImage } from './media';
import { setErrorMessage } from './message';

const updateValidation = (status, json, dispatch, field) => {
  if (status === 500 && json.data.error) {
    return dispatch(updateServerValidation(field, false, json.data.error));
  }
  console.log('Product was updated successfully');
  return dispatch(updateServerValidation(field, true));
};

export const patchProductField = (
  field,
  value,
  catalogId,
  categoryId,
  id,
  dispatch
) => {
  const attributes = {};
  attributes[field] = value;
  return fetch(
    `/api/catalogs/${catalogId}/categories/${categoryId}/products/${id}`,
    {
      credentials: 'include',
      method: 'PATCH',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ ...attributes })
    }
  )
    .then((response) =>
      response
        .json()
        .then((json) =>
          updateValidation(response.status, json, dispatch, field)
        )
    )
    .catch((e) => console.error('Cant patch product', e));
};

export const updateProductProps =
  (catalogId, categoryId, productId, productProps) => (dispatch) => {
    dispatch({
      type: 'PRODUCT:PROPERTIES:UPDATE:REQUEST',
      payload: { catalogId, productProps }
    });

    return fetch(
      `/api/catalogs/${catalogId}/categories/${categoryId}/products/${productId}`,
      {
        credentials: 'include',
        method: 'PATCH',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ ...productProps })
      }
    )
      .then((res) =>
        res.json().then((json) =>
          // eslint-disable-next-line prefer-promise-reject-errors
          res.status < 400
            ? Promise.resolve(json)
            : Promise.reject({ errors: json.errors, status: res.status })
        )
      )
      .then(() => {
        dispatch({
          type: 'PRODUCT:PROPERTIES:UPDATE:SUCCESS',
          payload: { productProps }
        });
        return Promise.resolve();
      })
      .catch((res) => {
        dispatch({
          type: 'PRODUCT:PROPERTIES:UPDATE:FAILURE',
          payload: { errors: res.errors }
        });
        return Promise.reject(res.errors);
      });
  };

export const updateProductMeasurements =
  (productId, measurements) => (dispatch) => {
    dispatch({
      type: 'PRODUCT_MEASUREMENTS:UPDATE:REQUEST',
      payload: { productId, measurements }
    });

    return fetch(
      `/api/catalogs/undefined/categories/undefined/products/${productId}/measurements`,
      {
        credentials: 'include',
        method: 'PATCH',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(measurements)
      }
    )
      .then((res) =>
        res.json().then((json) =>
          // eslint-disable-next-line prefer-promise-reject-errors
          res.status < 400
            ? Promise.resolve(json)
            : Promise.reject({ errors: json.errors, status: res.status })
        )
      )
      .then(() => {
        dispatch({
          type: 'PRODUCT_MEASUREMENTS:UPDATE:SUCCESS',
          payload: { measurements }
        });

        return Promise.resolve();
      })
      .catch((res) => {
        dispatch({
          type: 'PRODUCT_MEASUREMENTS:UPDATE:FAILURE',
          payload: { errors: res.errors }
        });
        return Promise.reject(res.errors);
      });
  };

export const updateProductPackages =
  (catalogId, categoryId, productId, packages) => (dispatch) => {
    dispatch({
      type: 'PRODUCT_MEASUREMENTS:UPDATE:REQUEST',
      payload: { productId, packages }
    });

    return fetch(`/api/catalogs/${catalogId}/products/${productId}/packages`, {
      credentials: 'include',
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ packages })
    })
      .then((res) =>
        res.json().then((json) =>
          // eslint-disable-next-line prefer-promise-reject-errors
          res.status < 400
            ? Promise.resolve(json)
            : Promise.reject({ errors: json.errors, status: res.status })
        )
      )
      .then(() => {
        dispatch({
          type: 'PRODUCT_PACKAGES:UPDATE:SUCCESS',
          payload: { packages }
        });

        return Promise.resolve();
      })
      .catch((res) => {
        dispatch({
          type: 'PRODUCT_PACKAGES:UPDATE:FAILURE',
          payload: { errors: res.errors }
        });
        return Promise.reject(res.errors);
      });
  };

export const getNewProductId = (catalogId, categoryId) => (dispatch) => {
  dispatch({
    type: 'PRODUCT:GET_NEW_ID:REQUEST',
    payload: { catalogId, categoryId }
  });
  return fetch(
    `/api/catalogs/${catalogId}/categories/${categoryId}/products/newId`,
    {
      credentials: 'include',
      method: 'GET',
      headers: { 'Content-Type': 'application/json' }
    }
  )
    .then((response) => response.json())
    .then(({ id }) =>
      dispatch({
        type: 'PRODUCT:GET_NEW_ID:SUCCESS',
        payload: { catalogId, categoryId, id }
      })
    )
    .catch((e) => console.error('CREATE PRODUCT FAILED', e));
};

export const loadProduct = (item) => ({
  type: 'PRODUCT_LOAD',
  item
});

const getMediaByType = (data, type) =>
  data.filter((media) => media.type === type);

export const createNewProduct =
  (catalogId, categoryId, productProps) => (dispatch) => {
    dispatch({
      type: 'PRODUCT:CREATE:REQUEST',
      payload: { catalogId, categoryId, productProps }
    });
    return fetch(
      `/api/catalogs/${catalogId}/categories/${categoryId}/products`,
      {
        credentials: 'include',
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(productProps)
      }
    )
      .then((res) =>
        res.json().then((json) => {
          if (res.status > 200) {
            return Promise.reject(json.errors);
          }
          return fetch(
            `/api/catalogs/${catalogId}/categories/${categoryId}/products/${json.id}`,
            {
              credentials: 'include'
            }
          )
            .then((response) => response.json())
            .then((data) => {
              dispatch(loadProduct({ attributes: data, id: data.id }));
              dispatch(
                loadTable(getMediaByType(data.Media, 'video'), 'productVideo')
              );
              dispatch(
                loadTable(getMediaByType(data.Media, 'audio'), 'productAudio')
              );
              dispatch(loadTable(getMediaByType(data.Media, 'doc'), 'docs'));
              return json.id;
            });
        })
      )
      .catch((errors) => {
        dispatch({ type: 'PRODUCT:CREATE:FAILED', payload: { errors } });
        const uniqueError = errors.filter(
          (error) =>
            error.message === 'sku must be unique' ||
            error.message === 'catalogId must be unique'
        );
        if (uniqueError.length > 0) {
          dispatch(setErrorMessage({ key: 'Sku already exists' }));
        } else {
          dispatch(setErrorMessage({ key: 'Error' }));
        }
        return Promise.reject(errors);
      });
  };

export const debouncedProductPatchFetch = keyDebounce(patchProductField);

// VALIDATION rules
const validationRules = {
  name: 'required',
  sku: 'required',
  url: 'url'
};

export const setProductField = (field, value) => ({
  type: 'PRODUCT_SET_FIELD',
  field,
  value
});

export const updateProductField = (field, value) => (dispatch, getState) => {
  dispatch(setProductField(field, value));

  // validate
  if (!validate(value, validationRules[field], dispatch, field)) return;

  // asynch
  debouncedProductPatchFetch(
    field,
    value.trim(),
    getState().getIn(['catalogCombine', 'properties', 'id']),
    getState().getIn(['category', 'item', 'id']),
    getState().getIn(['product', 'item', 'id']),
    dispatch
  );
};

export const updateProductFieldNow = (field, value) => (dispatch, getState) => {
  dispatch(setProductField(field, value));
  patchProductField(
    field,
    value,
    getState().getIn(['catalog', 'item', 'id']),
    getState().getIn(['category', 'item', 'id']),
    getState().getIn(['product', 'item', 'id']),
    dispatch
  );
};

export const trimProductField = (field) => ({
  type: 'PRODUCT_TRIM_FIELD',
  field
});

export const clearProduct = () => ({
  type: 'PRODUCT_CLEAR'
});

export const getProduct = (catalogId, categoryId, productId) => (dispatch) => {
  // clear state
  dispatch(clearProduct());

  fetch(
    `/api/catalogs/${catalogId}/categories/${categoryId}/products/${productId}`,
    { credentials: 'include' }
  )
    .then((response) => response.json())
    .then((data) => {
      dispatch(loadProduct({ attributes: data, id: data.id }));
      dispatch(loadTable(getMediaByType(data.Media, 'video'), 'productVideo'));
      dispatch(loadTable(getMediaByType(data.Media, 'audio'), 'productAudio'));
      dispatch(loadTable(getMediaByType(data.Media, 'doc'), 'docs'));
    });
};

export const getPublishedProduct = (catalogId, productId) => (dispatch) => {
  // clear state
  dispatch(clearProduct());

  fetch(`/api/catalogs/${catalogId}/publishedproducts/${productId}`, {
    credentials: 'include'
  })
    .then((response) => response.json())
    .then((product) => {
      dispatch({
        type: 'PRODUCT:GET_PUBLISHED_PRODUCT:SUCCESS',
        payload: { product }
      });
    });
};

export const getProducts =
  (catalogId, categoryId, offset = 0, limit = 20) =>
  (dispatch) => {
    fetch(
      `/api/catalogs/${catalogId}/categories/${categoryId}/products?offset=${offset}&limit=${limit}`,
      {
        credentials: 'include'
      }
    )
      .then((response) => response.json())
      .then(({ data, size }) => {
        dispatch(loadTableProducts(data, categoryId, size, limit, offset));
        dispatch(sortBy('name', 'products'));
      });
  };

export const deleteProduct = (catalogId, categoryId, productId) =>
  fetch(
    `/api/catalogs/${catalogId}/categories/${categoryId}/products/${productId}`,
    {
      credentials: 'include',
      method: 'DELETE'
    }
  );

export const deleteSelectedProducts = () => (dispatch, getState) => {
  const catalogId = getState().getIn(['catalog', 'item', 'id']);
  const categoryId = getState().getIn(['category', 'item', 'id']);
  const selected = getState().getIn(['tables', 'common', 'selected']).toArray();
  console.log('select delete');

  Promise.all(
    selected.map(
      (productId) =>
        new Promise((resolve) =>
          deleteProduct(catalogId, categoryId, productId)
            .then(({ ok, status, statusText }) =>
              ok
                ? resolve(dispatch(removeFromTable(productId)))
                : resolve(
                    console.log('ERROR', 'PRODUCT DELETE', status, statusText)
                  )
            )
            .catch(resolve)
        )
    )
  );
};

export const newDeleteSelectedProducts = () => (dispatch, getState) => {
  const selected = getState()
    .getIn(['tables', 'products', 'selected'])
    .toArray();
  const items = getState()
    .getIn(['tables', 'products', 'items'])
    .reduce((acc, item) => acc.concat(item));
  const filtredSelected = items.filter((item) =>
    selected.some((productId) => productId === item.get('id'))
  );

  return Promise.all(
    filtredSelected.map(
      (product) =>
        new Promise((resolve) =>
          deleteProduct(
            product.get('catalog'),
            product.get('category'),
            product.get('id')
          )
            .then(({ ok, status, statusText }) =>
              ok
                ? resolve(dispatch(removeFromTableProducts(product.toJS())))
                : resolve(
                    console.log('ERROR', 'PRODUCT DELETE', status, statusText)
                  )
            )
            .catch(resolve)
        )
    )
  );
};

export const createProduct = (inputCategoryId) => (dispatch, getState) => {
  const catalogId = getState().getIn(['catalogCombine', 'properties', 'id']);
  const categoryId =
    inputCategoryId || getState().getIn(['category', 'item', 'id']);

  fetch(`/api/catalogs/${catalogId}/categories/${categoryId}/products`, {
    credentials: 'include',
    method: 'POST',
    headers: { 'Content-Type': 'application/json' }
  })
    .then((response) => response.json())
    .then((jsonResponse) =>
      customHistory.push(
        `/catalogs/${catalogId}/categories/${categoryId}/products/${jsonResponse.data.id}/basic-information`
      )
    )
    .catch((e) => console.error('CREATE PRODUCT FAILED', e));
};

// export const uploadProductImage = (file) =>
// (dispatch, getState) => {
// uploadImage(file)
// .then(({ data: { attributes } }) => {
// dispatch(setProductField('defaultPicture', attributes));
// return patchProductField(
// 'defaultPicture',
// attributes,
// getState().getIn(['catalog', 'item', 'id']),
// getState().getIn(['category', 'item', 'id']),
// getState().getIn(['product', 'item', 'id']),
// dispatch);
// })
// .catch(e => console.error('FAILED UPDATE CATEGORY', e));
// };

export const setProductImage =
  (catalogId, categoryId, productId, src) => (dispatch) => {
    const attributes = { src, type: 'image' };
    dispatch({ type: 'PRODUCT:SET_IMG:REQUEST', payload: attributes });
    return fetch(
      `/api/catalogs/${catalogId}/categories/${categoryId}/products/${productId}/media`,
      {
        credentials: 'include',
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ ...attributes })
      }
    )
      .then((res) => res.json())
      .then(() => {
        dispatch({ type: 'PRODUCT:SET_IMG:SUCCESS' });
        return Promise.resolve();
      })
      .catch((errors) => {
        dispatch({ type: 'PRODUCT:SET_IMG:FAILURE', payload: { errors } });
        console.error(errors);
        return Promise.reject(errors);
      });
  };

export const addProductMedia = (productId, type, src) => (dispatch) => {
  const attributes = { src, type };
  dispatch({ type: 'PRODUCT:SET_IMG:REQUEST', payload: attributes });
  return fetch(
    `/api/catalogs/undefined/categories/undefined/products/${productId}/media`,
    {
      credentials: 'include',
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ ...attributes })
    }
  )
    .then((res) => res.json())
    .then(() => {
      dispatch({ type: 'PRODUCT:SET_IMG:SUCCESS' });
      return Promise.resolve();
    })
    .catch((errors) => {
      dispatch({ type: 'PRODUCT:SET_IMG:FAILURE', payload: { errors } });
      console.error(errors);
      return Promise.reject(errors);
    });
};

export const deleteProductImage = (productId, id) => (dispatch) => {
  dispatch({ type: 'PRODUCT:DEL_IMG:REQUEST' });

  return fetch(
    `/api/catalogs/undefined/categories/undefined/products/${productId}/media/${id}/type/image`,
    {
      credentials: 'include',
      method: 'DELETE'
    }
  )
    .then((res) =>
      res.json().then((json) =>
        // eslint-disable-next-line prefer-promise-reject-errors
        res.status < 400
          ? Promise.resolve(json)
          : Promise.reject({ errors: json.msg, status: res.status })
      )
    )
    .then(() => {
      dispatch({ type: 'PRODUCT:DEL_IMG:SUCCESS' });
      return Promise.resolve();
    })
    .catch((errors) => {
      dispatch({ type: 'PRODUCT:DEL_IMG:FAILURE', payload: { errors } });
      console.error(errors);
      return Promise.reject(errors);
    });
};

export const multyDeleteMedia = (productId, ids, type) => (dispatch) => {
  dispatch({ type: 'PRODUCT:MULTY_DELETE_MEDIA:REQUEST' });

  return fetch(
    `/api/catalogs/undefined/categories/undefined/products/${productId}/media/delete/type/${type}`,
    {
      credentials: 'include',
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ ids })
    }
  )
    .then((res) =>
      res.json().then((json) =>
        // eslint-disable-next-line prefer-promise-reject-errors
        res.status < 400
          ? Promise.resolve(json)
          : Promise.reject({ errors: json.msg, status: res.status })
      )
    )
    .then(() => {
      dispatch({ type: 'PRODUCT:MULTY_DELETE_MEDIA:SUCCESS' });
      return Promise.resolve();
    })
    .catch((errors) => {
      dispatch({
        type: 'PRODUCT:MULTY_DELETE_MEDIA:FAILURE',
        payload: { errors }
      });
      console.error(errors);
      return Promise.reject(errors);
    });
};

export const deleteProductMedia = (productId, id, type) => (dispatch) => {
  dispatch({ type: 'PRODUCT:DEL_MEDIA:REQUEST' });

  return fetch(
    `/api/catalogs/undefined/categories/undefined/products/${productId}/media/${id}/type/${type}`,
    {
      credentials: 'include',
      method: 'DELETE'
    }
  )
    .then((res) =>
      res.json().then((json) =>
        // eslint-disable-next-line prefer-promise-reject-errors
        res.status < 400
          ? Promise.resolve(json)
          : Promise.reject({ errors: json.msg, status: res.status })
      )
    )
    .then(() => {
      dispatch({ type: 'PRODUCT:DEL_MEDIA:SUCCESS' });
      return Promise.resolve();
    })
    .catch((errors) => {
      dispatch({ type: 'PRODUCT:DEL_MEDIA:FAILURE', payload: { errors } });
      console.error(errors);
      return Promise.reject(errors);
    });
};

export const generateHtml = (catalogId) => (dispatch) => {
  dispatch({ type: 'PRODUCTS:GENERATE_HTML:REQUEST' });
  return fetch(`/api/json/export/${catalogId}`, {
    method: 'POST',
    credentials: 'include'
  })
    .then(() => {
      dispatch({ type: 'PRODUCTS:GENERATE_HTML:SUCCESS' });
      return Promise.resolve();
    })
    .catch((errors) => {
      dispatch({ type: 'PRODUCTS:GENERATE_HTML:FAILURE', payload: { errors } });
      console.error(errors);
      return Promise.reject();
    });
};

export const clearErrors = () => ({ type: 'PRODUCT:CLEAR_ERRORS' });

export const uploadImage = ({
  file,
  compress = false,
  catalogId,
  categoryId,
  productId
}) => {
  const url =
    '/api' +
    `/catalogs/${catalogId}` +
    `/categories/${categoryId}` +
    `/products/${productId}` +
    '/uploadImage?' +
    `name=${file.name}` +
    `&type=${file.type}` +
    `&size=${file.size}` +
    `&compress=${compress}`;

  return () =>
    fetch(url, {
      credentials: 'include',
      method: 'POST',
      body: file
    })
      .then((res) =>
        res.json().then((json) =>
          // eslint-disable-next-line prefer-promise-reject-errors
          res.status < 400
            ? Promise.resolve(json)
            : Promise.reject({ errors: json.errors, status: res.status })
        )
      )
      .then((data) => data.src);
};

export const uploadMediaImage =
  (file, catalogId, categoryId, productId) => async () =>
    fetch(
      // eslint-disable-next-line max-len
      `/api/catalogs/${catalogId}/categories/${categoryId}/products/${productId}/uploadMediaImage?name=${file.name}&type=${file.type}&size=${file.size}`,
      {
        credentials: 'include',
        method: 'POST',
        body: file
      }
    )
      .then((res) =>
        res.json().then((json) =>
          // eslint-disable-next-line prefer-promise-reject-errors
          res.status < 400
            ? Promise.resolve(json)
            : Promise.reject({ errors: json.errors, status: res.status })
        )
      )
      .then((data) => data.src);
