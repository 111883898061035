import {
  makeRequestMany,
  setPaginationQuery,
  setWhereParams
} from 'core/data/requestMany';
import { makePaginationQuery } from 'core/data/paginationQuery';
import { makeRequestOne } from 'core/data/requestOne';
import {
  makeWhereParams,
  toggleFilterById,
  contains,
  merge,
  omitByFields,
  removeParam,
  addParam
} from 'core/data/whereParams';
import {
  makeEqualToOneOf,
  makeOr,
  makeILike,
  makeEqualOne
} from 'core/data/whereParamItem';
import { makeConfigItem } from 'core/data/configItem';
import { getIds } from 'core/data/selectedList';
import { makeSortBy } from 'core/data/sortBy';

import { setErrorMessage } from '../action-creators/message';
import { generateActions } from '../action-creators/lists';
import {
  setFilterOptions,
  clearFilterOptions
} from '../action-creators/filterOptions';
import {
  setItem,
  setItems,
  clear as clearUserConfig
} from '../action-creators/userConfigs';
import { startLoader, finishLoader } from '../action-creators/loaderList';
import serviceStorage from '../storage/service.storage';
import ordersStorage from '../storage/orders.storage';
import userStorage from '../storage/user.storage';
import {
  getRequestMany,
  getSelectedList,
  getWhereParams,
  getPaginationQuery,
  getSortBy
} from '../storeGetters';
import { MY_ORDERS } from '../constants';

const { setList, toggleAllList, toggleOneList, clearList, clearSelectList } =
  generateActions(MY_ORDERS);

export const toggleAll = toggleAllList;
export const toggleOne = toggleOneList;

const firstPagePaginationQuery = () =>
  makePaginationQuery({ offset: 0, limit: 20 });

const findMyOrders = (requestMany) => async (dispatch) => {
  const [dataPart, filterOptions] = await Promise.all([
    ordersStorage.findMyOrders(requestMany),
    ordersStorage.getFiltersOfMyOrders(requestMany)
  ]);

  dispatch(setList({ dataPart }));
  dispatch(setFilterOptions(MY_ORDERS, filterOptions));
  dispatch(setItem(makeConfigItem(MY_ORDERS, 'requestMany', requestMany)));
};

export const loadMyOrders = () => async (dispatch, getState) => {
  const requestMany = getRequestMany(MY_ORDERS, getState());

  dispatch(startLoader(MY_ORDERS));

  await findMyOrders(requestMany)(dispatch, getState);
  const excludedAttrs = await userStorage.getTableExcludedAttributes();
  dispatch(setItems(excludedAttrs));

  dispatch(finishLoader(MY_ORDERS));
};

const searcher = (fieldNames, searchQuery, whereParams) => {
  if (searchQuery === '') {
    return fieldNames.reduce(
      (acc, fieldName) => removeParam(fieldName, acc),
      whereParams
    );
  }

  return addParam(
    makeOr(
      makeWhereParams(
        fieldNames.reduce((acc, fieldName) => {
          if (fieldName === 'number' && !Number.isNaN(+searchQuery)) {
            acc.push(makeEqualOne(fieldName, +searchQuery));
          } else if (fieldName !== 'number') {
            acc.push(makeILike(fieldName, searchQuery));
          }
          return acc;
        }, [])
      )
    ),
    whereParams
  );
};

export const search =
  (fields) => (searchQuery) => async (dispatch, getState) => {
    const state = getState();
    const whereParams = getWhereParams(MY_ORDERS, state);
    const updatedWhereParams = searcher(fields, searchQuery, whereParams);
    const requestMany = makeRequestMany({
      whereParams: updatedWhereParams,
      paginationQuery: firstPagePaginationQuery(),
      sortBy: getSortBy(MY_ORDERS, state)
    });

    await findMyOrders(requestMany)(dispatch, getState);
  };

export const changePage = (paginationQuery) => async (dispatch, getState) => {
  const requestMany = setPaginationQuery(
    paginationQuery,
    getRequestMany(MY_ORDERS, getState())
  );

  await findMyOrders(requestMany)(dispatch, getState);
};

export const filters =
  (filterNames, whereParams) => async (dispatch, getState) => {
    const storeWhereParams = getWhereParams(MY_ORDERS, getState());

    const colsExcluded = filterNames.filter(
      (name) => !contains(name, whereParams)
    );
    const updatedWhereParams = omitByFields(
      colsExcluded,
      merge(storeWhereParams, whereParams)
    );

    const requestMany = setPaginationQuery(
      firstPagePaginationQuery(),
      setWhereParams(updatedWhereParams, getRequestMany(MY_ORDERS, getState()))
    );

    await findMyOrders(requestMany)(dispatch, getState);
  };

export const toggleFilteringBySelectedItems =
  () => async (dispatch, getState) => {
    const state = getState();
    const whereParams = getWhereParams(MY_ORDERS, state);
    const selectedList = getSelectedList(MY_ORDERS, state);
    const updatedWhereParams = toggleFilterById(
      getIds(selectedList),
      whereParams
    );
    const requestMany = makeRequestMany({
      whereParams: updatedWhereParams,
      paginationQuery: firstPagePaginationQuery(),
      sortBy: getSortBy(MY_ORDERS, state)
    });

    await findMyOrders(requestMany)(dispatch, getState);
  };

export const removeSelectedOrders = () => async (dispatch, getState) => {
  const requestMany = getRequestMany(MY_ORDERS, getState());
  const ids = getIds(getSelectedList(MY_ORDERS, getState()));
  const requestOne = makeRequestOne({
    whereParams: makeWhereParams([makeEqualToOneOf('id', ids)])
  });
  try {
    await ordersStorage.removeMany(requestOne);
    await findMyOrders(requestMany)(dispatch, getState);
    dispatch(clearSelectList());
  } catch (e) {
    console.warn(e);
    dispatch(setErrorMessage({ key: 'cant_delete_order' }));
  }
};

export const removeOrder = (id) => async (dispatch, getState) => {
  const requestMany = getRequestMany(MY_ORDERS, getState());
  const requestOne = makeRequestOne({
    whereParams: makeWhereParams([makeEqualToOneOf('id', [id])])
  });
  try {
    await ordersStorage.removeMany(requestOne);
    await findMyOrders(requestMany)(dispatch, getState);
    dispatch(clearSelectList());
  } catch (e) {
    console.error(e);
    dispatch(setErrorMessage({ key: 'cant_delete_order' }));
  }
};

export const clearMyOrders = () => async (dispatch) => {
  dispatch(clearList());
  dispatch(clearUserConfig());
  dispatch(clearFilterOptions(MY_ORDERS));
};

export const copyOrder = (orderId) => async (dispatch, getState) => {
  const userEmail = getState().getIn(['user', 'user', 'email']);
  await serviceStorage.gaSend({
    category: 'Customer',
    action: 'create_copy_order',
    label: userEmail
  });
  return ordersStorage.copyOrderToSupplier(orderId);
};

export const setSortBy = (field, direction) => async (dispatch, getState) => {
  const state = getState();
  const paginationQuery = getPaginationQuery(MY_ORDERS, state);
  const whereParams = getWhereParams(MY_ORDERS, state);
  const sortBy = makeSortBy({ field, direction });
  const requestMany = makeRequestMany({ paginationQuery, whereParams, sortBy });

  dispatch(setItem(makeConfigItem('myOrders', 'requestMany', requestMany)));
  await findMyOrders(requestMany)(dispatch, getState);
};
