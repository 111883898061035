import React from 'react';
import PropTypes from 'prop-types';
import ReactSelect from 'react-select';
import { Link } from 'react-router-dom';
import { translate } from 'react-i18next';
import { ADMIN, PARTICIPANT, EDITOR, READER } from 'core/data/light/memberRole';
import { haveAdminMR, haveEditorMR } from 'core/data/light/channelMember';
import { isNil } from 'ramda';
import { withRouter } from '../../../hoc/withRouter';
import './style.css';

const memberName = (member, pathname, isCurrentMember) =>
  isCurrentMember ? (
    <div className="member__name" style={{ color: '#000000' }}>
      {member.userName}
    </div>
  ) : (
    <Link
      className="member__name"
      to={`${pathname}/contacts/${member.employeeId}`}>
      {member.userName}
    </Link>
  );

const createOption = (t, value) => ({
  value,
  label: t(`channel-roles-${value}`)
});

const getOptions = (t, mode, member) => {
  let options = [];

  if (mode === 'Channel') {
    options = [ADMIN, PARTICIPANT];
  }

  if (mode === 'Topic') {
    if (haveAdminMR(member)) {
      options = [ADMIN, PARTICIPANT, EDITOR, READER];
    }
    if (haveEditorMR(member)) {
      options = [PARTICIPANT, EDITOR, READER];
    }
  }

  return options.map((value) => createOption(t, value));
};

const getValue = (t, memberRole) => createOption(t, memberRole);

const isDisabled = (currentMember, member) => {
  if (currentMember.employeeId === member.employeeId) {
    return true;
  }
  if (!haveAdminMR(currentMember) && haveAdminMR(member)) {
    return true;
  }
  return false;
};

const Member = ({
  t,
  mode,
  readOnly,
  member,
  currentMember,
  memberRole,
  location,
  isCurrentMember,
  isAllowDelete,
  onChangeMemberRole,
  onDelete,
  showModalChangeMemberRole
}) => {
  const imgSrc =
    isNil(member.avatarSrc) || !member.avatarSrc
      ? '/img/user.svg'
      : member.avatarSrc;
  const { pathname } = location;
  const isEditDisabled = isDisabled(currentMember, member);

  return (
    <div className="member">
      <img className="member__img" src={imgSrc} alt="avatar" />
      <div className="member__desc">
        {memberName(member, pathname, isCurrentMember)}
        <div className="member__company-name">{member.companyName}</div>
      </div>
      {readOnly && (
        <div className="member__role-text">{getValue(t, memberRole).label}</div>
      )}
      {!readOnly && (
        <div className="member__role">
          <ReactSelect
            className="member__role-select"
            value={getValue(t, memberRole)}
            options={getOptions(t, mode, currentMember)}
            onChange={({ value }) => onChangeMemberRole(member, value)}
            multi={false}
            searchable={false}
            clearable={false}
            disabled={isEditDisabled}
          />
        </div>
      )}
      {!readOnly && (
        <div
          className={`modal__role-row_ask ${
            isEditDisabled ? 'modal__role-row_ask__disabled' : ''
          }`}>
          <img
            src="/img/ask.svg"
            alt="ask"
            onClick={() => !isEditDisabled && showModalChangeMemberRole(member)}
          />
        </div>
      )}
      {!readOnly && (
        <div
          className={`member__delete ${isAllowDelete ? '' : 'disabled'}`}
          onClick={() => isAllowDelete && onDelete()}
        />
      )}
    </div>
  );
};

Member.propTypes = {
  t: PropTypes.func.isRequired,
  mode: PropTypes.string,
  member: PropTypes.object.isRequired,
  currentMember: PropTypes.object.isRequired,
  memberRole: PropTypes.string.isRequired,
  isCurrentMember: PropTypes.bool.isRequired,
  isAllowDelete: PropTypes.bool,
  location: PropTypes.object.isRequired,
  onChangeMemberRole: PropTypes.func.isRequired,
  onDelete: PropTypes.func,
  showModalChangeMemberRole: PropTypes.func.isRequired,
  readOnly: PropTypes.bool
};

Member.defaultProps = {
  isAllowDelete: false,
  onDelete: () => {},
  mode: 'Channel',
  readOnly: false
};

export default withRouter(translate()(Member));
