import { equals } from 'ramda';
import { setEmployeeData, getEmployeeData } from '../../../storage/lib/LS';

/*
 * Data type for save respond data to local storage
 */

const makeRespondLSData = ({ text = '', links = [] } = {}) => ({
  type: 'RespondLSData',
  text,
  links
});
const getText = (lsRespond) => lsRespond.text;
const setText = (text, lsRespond) => ({ ...lsRespond, text });

const LS_SECTION = 'responds';

export const makeRespondLS = (user, requestId) => {
  const employeeId = user.get('employeeId');
  const setRespond = setEmployeeData(LS_SECTION, employeeId, requestId);
  const getRespond = () => getEmployeeData(LS_SECTION, employeeId, requestId);

  return {
    type: 'RespondLS',
    setRespond,
    getRespond
  };
};

const getRespond = (respondLS) => respondLS.getRespond() || makeRespondLSData();
export const isEmptyRespond = (respondLS) =>
  equals(getRespond(respondLS), makeRespondLSData());

const setRespond = (respondLSData, respondLS) =>
  respondLS.setRespond(respondLSData);
export const clearRespond = (respondLS) =>
  setRespond(makeRespondLSData(), respondLS);
export const setRespondText = (text, respondLS) =>
  setRespond(setText(text, getRespond(respondLS)), respondLS);
export const getRespondText = (respondLS) => getText(getRespond(respondLS));
