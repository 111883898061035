const { makePolicy } = require('../../data/policy');

// Section name
const SUPPLIERS = 'SUPPLIERS';

// Use cases
const VIEW_SUPPLIERS_WITHOUT_RESTRICTIONS =
  'VIEW_SUPPLIERS_WITHOUT_RESTRICTIONS';
const ASSIGN_RESPONSIBLE = 'ASSIGN_RESPONSIBLE';
const VIEW_SUPPLIERS_WITH_DIFF_ACCESS = 'VIEW_SUPPLIERS_WITH_DIFF_ACCESS';
const VIEW_SUPPLIER_LIST = 'VIEW_SUPPLIER_LIST';
const VIEW_SUPPLIER_PROFILE = 'VIEW_SUPPLIER_PROFILE';
const INVITE_SUPPLIER = 'INVITE_SUPPLIER';
const EDIT_SUPPLIER_GROUPS = 'EDIT_SUPPLIER_GROUPS';
const ADD_GROUP = 'ADD_GROUP';
const EDIT_GROUP = 'EDIT_GROUP';
const DELETE_GROUP = 'DELETE_GROUP';
const REMOVE_BUYER = 'REMOVE_BUYER';

const settings = [
  makePolicy(SUPPLIERS, VIEW_SUPPLIERS_WITHOUT_RESTRICTIONS, {
    reader: false,
    writer: false,
    advanced: true
  }),
  makePolicy(SUPPLIERS, ASSIGN_RESPONSIBLE, {
    reader: false,
    writer: false,
    advanced: true
  }),
  makePolicy(SUPPLIERS, VIEW_SUPPLIERS_WITH_DIFF_ACCESS, {
    reader: true,
    writer: true,
    advanced: true
  }),
  makePolicy(SUPPLIERS, VIEW_SUPPLIER_LIST, {
    reader: true,
    writer: true,
    advanced: true
  }),
  makePolicy(SUPPLIERS, VIEW_SUPPLIER_PROFILE, {
    reader: true,
    writer: true,
    advanced: true
  }),
  makePolicy(SUPPLIERS, INVITE_SUPPLIER, {
    reader: false,
    writer: true,
    advanced: true
  }),
  makePolicy(SUPPLIERS, EDIT_SUPPLIER_GROUPS, {
    reader: false,
    writer: true,
    advanced: true
  }),
  makePolicy(SUPPLIERS, ADD_GROUP, {
    reader: false,
    writer: true,
    advanced: true
  }),
  makePolicy(SUPPLIERS, EDIT_GROUP, {
    reader: false,
    writer: true,
    advanced: true
  }),
  makePolicy(SUPPLIERS, DELETE_GROUP, {
    reader: false,
    writer: true,
    advanced: true
  }),
  makePolicy(SUPPLIERS, REMOVE_BUYER, {
    reader: false,
    writer: false,
    advanced: true
  })
];

module.exports = {
  SUPPLIERS,
  VIEW_SUPPLIERS_WITHOUT_RESTRICTIONS,
  ASSIGN_RESPONSIBLE,
  VIEW_SUPPLIERS_WITH_DIFF_ACCESS,
  VIEW_SUPPLIER_LIST,
  VIEW_SUPPLIER_PROFILE,
  INVITE_SUPPLIER,
  EDIT_SUPPLIER_GROUPS,
  ADD_GROUP,
  EDIT_GROUP,
  DELETE_GROUP,
  REMOVE_BUYER,
  settings
};
