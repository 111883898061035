const { defStruct } = require('../../lib');
const { makeWhereParams } = require('../whereParams');

/**
 * @typedef RequestOne
 * @typedef {import('../whereParams')} WhereParams
 */
const { makeRequestOne, getWhereParams, isRequestOne } = defStruct(
  'RequestOne',
  ['whereParams']
);

/**
 * Creates a request one data type
 *
 * @param {WhereParams} whereParams
 * @returns {RequestOne}
 */
exports.makeRequestOne = ({ whereParams = makeWhereParams() } = {}) =>
  makeRequestOne(whereParams);

/**
 * Gives where params data type from request one
 *
 * @param {RequestOne} requestOne
 * @returns {WhereParams}
 */
exports.getWhereParams = (requestOne) => getWhereParams(requestOne);

/**
 * Checks if is request one
 *
 * @param {*} a
 * @returns {boolean}
 */
exports.isRequestOne = (a) => isRequestOne(a);
