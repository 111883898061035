export const baseUrls = {
  customer: 'orders-customers',
  supplier: 'orders-company'
};

export const accessFrom = ['supplier', 'customer'];

const generateUrlToOrderHistory =
  (from, havePermission) =>
  ({ id, orderId, isShowMore }) => {
    if (!isShowMore || !accessFrom.includes(from) || !havePermission)
      return null;

    return `/${baseUrls[from]}/${orderId}/history/${id}`;
  };

export default generateUrlToOrderHistory;
