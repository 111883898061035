import { useState, useEffect } from 'react';
import { useWizard } from '@link/react-components';
import { useAsyncFn, useList } from '@hooks';
import { companiesGroupService } from '../../../../api';

export function useInitializeCompaniesGroup({ initialCompanies = [] }) {
  const { getState, setState } = useWizard();
  const sharedState = getState();

  const [{ loading, error }, doFetch] = useAsyncFn(fetchCompaniesAndGroups, []);

  const [initialized, setInitialized] = useState(
    sharedState.initialized || false
  );
  const [companies, companiesActions] = useList(sharedState.companies || []);
  const [groups, groupsActions] = useList(sharedState.groups || []);
  const [selectedCompanies, selectedCompaniesActions] = useList(
    sharedState.chosenCompanies || []
  );

  useEffect(() => {
    async function initialize() {
      await doFetch();

      setState({
        initialized: true,
        chosenCompanies: initialCompanies
      });
      selectedCompaniesActions.set(initialCompanies);
      setInitialized(true);
    }

    if (!initialized) {
      initialize();
    }
  }, [initialized]);

  async function fetchCompaniesAndGroups() {
    const result = await companiesGroupService.getAll();

    const resultGroups = result.groups.sort((a, b) =>
      a.name.localeCompare(b.name)
    );
    const resultCompanies = result.list.sort((a, b) =>
      a.label.localeCompare(b.label)
    );

    groupsActions.set(resultGroups);
    companiesActions.set(resultCompanies);

    setState({ groups: resultGroups, companies: resultCompanies });
  }

  return {
    loading,
    companies,
    groups,
    selectedCompanies,
    selectedCompaniesActions,
    error
  };
}
