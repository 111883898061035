import React from 'react';
import Tooltip from '../../../tooltip';
import './style.css';

function DBSimpleInput({
  t,
  value,
  onChange,
  onBlur,
  onKeyDown,
  placeholder,
  tooltipId,
  tooltip,
  maxLength,
  disabled = false
}) {
  return (
    <div className="db-simple-input-wrapper">
      <Tooltip t={t} tooltipId={tooltipId} text={tooltip} isWide>
        <input
          className="db-simple-input"
          value={value}
          onChange={onChange}
          placeholder={placeholder}
          onBlur={onBlur}
          onKeyDown={onKeyDown}
          maxLength={maxLength}
          disabled={disabled}
        />
      </Tooltip>
    </div>
  );
}

export default DBSimpleInput;
