/* eslint-disable max-len */

import React from 'react';

const PreviewIcon = () => (
  <svg
    width="16"
    height="13"
    viewBox="0 0 16 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 6.57983C0 6.57983 1.33333 12.5798 8 12.5798C14.6667 12.5798 16 6.57983 16 6.57983C16 6.57983 14.6667 0.579834 8 0.579834C1.33333 0.579834 0 6.57983 0 6.57983ZM1.03715 6.57983C1.07928 6.71651 1.14454 6.90919 1.23859 7.14004C1.44231 7.64007 1.7753 8.30496 2.28935 8.96589C3.29669 10.261 5.02122 11.5798 8 11.5798C10.9788 11.5798 12.7033 10.261 13.7106 8.96589C14.2247 8.30496 14.5577 7.64007 14.7614 7.14004C14.8555 6.90919 14.9207 6.71651 14.9628 6.57983C14.9207 6.44315 14.8555 6.25047 14.7614 6.01963C14.5577 5.5196 14.2247 4.8547 13.7106 4.19377C12.7033 2.89862 10.9788 1.57983 8 1.57983C5.02122 1.57983 3.29669 2.89862 2.28935 4.19377C1.7753 4.85471 1.44231 5.5196 1.23859 6.01963C1.14454 6.25047 1.07928 6.44315 1.03715 6.57983Z"
      fill="#767B92"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8 9.57983C9.65685 9.57983 11 8.23669 11 6.57983C11 4.92298 9.65685 3.57983 8 3.57983C6.34315 3.57983 5 4.92298 5 6.57983C5 8.23669 6.34315 9.57983 8 9.57983ZM8 10.5798C10.2091 10.5798 12 8.78897 12 6.57983C12 4.3707 10.2091 2.57983 8 2.57983C5.79086 2.57983 4 4.3707 4 6.57983C4 8.78897 5.79086 10.5798 8 10.5798Z"
      fill="#767B92"
    />
  </svg>
);

export default PreviewIcon;
