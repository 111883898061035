import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { translate } from 'react-i18next';
import { withRouter } from '../hoc/withRouter';

import MessengerOther from '../components/Layouts/MessengerOther';
import Button from '../components/Buttons/Button';

import ContainersDefault from '../components/Containers/ContainersDefault';
import AllRequests from '../components/PurchaseRequests/AllRequests';
import { SidebarThemeDefault } from '../components/Sidebar';
import PurchaseRequestsFilterSidebar from '../presenters/sidebars/PurchaseRequestFilterSidebar';

import * as modalActions from '../action-creators/modal';
import * as messageActions from '../action-creators/message';
import * as requestsActions from '../action-creators/purchaseRequests';
import * as serviceActions from '../action-creators/services';

import { formatRequestNumber } from '../utils/utils';
import PurchaseRequestsTabs from '../config/pages/requests';
import { MessengerWidgetWrapper } from '../components/Widgets/Messenger/MessengerWidget';
import * as storeGetters from '../storeGetters';

class MAllPurchaseRequests extends Component {
  constructor(props) {
    super(props);

    this.showNewRequestPage = this.showNewRequestPage.bind(this);
  }

  get breadcrumbs() {
    const {
      t,
      match: {
        params: { requestId }
      }
    } = this.props;
    const items = [
      {
        name: t('Purchase requests'),
        link: '/requests/all',
        disabled: !requestId
      }
    ];
    if (requestId) {
      items.push({
        name: `${t('Request')} № ${formatRequestNumber(requestId)}`
      });
    }
    return items;
  }

  get userPermission() {
    const { user } = this.props;

    const permissions = user.getIn(['permissions', 'purchaseRequests']);

    if (!permissions) {
      return {
        read: false,
        update: false,
        delete: false
      };
    }

    return permissions.toJS();
  }

  get getBreadcrumbsButtons() {
    const { t } = this.props;

    return this.isShowCreateRequestButton()
      ? [
          {
            element: (
              <Button
                variant="outline-primary"
                onClick={this.showNewRequestPage}
                disabled={!this.userPermission.update}>
                {`+ ${t('Create purchase request')}`}
              </Button>
            )
          }
        ]
      : [];
  }

  showNewRequestPage() {
    const { gaSend, navigate, showModal } = this.props;
    gaSend('create_request');
    // TODO Тут будет проверка на соответствие прав у пользователя сейчас я СПЕЦИАЛЬНО хардкожу чтобы модалка ВСЕГДА открывалась, когда будет ролевая модель, нужно будет это исправить
    if (true) {
      showModal('MODAL_CREATE_PURCHASE_REQUEST');
      return;
    }
    navigate('/requests/all/my-requests/create', {
      state: { noRestoreData: true }
    });
  }

  isShowCreateRequestButton() {
    const { user } = this.props;

    const permissions = user.getIn(['permissions', 'purchaseRequests']);

    return Boolean(permissions);
  }

  isShowPurchaseRequestsTabs() {
    return this.userPermission.read;
  }

  render() {
    const { t } = this.props;

    return (
      <>
        <MessengerOther
          theme="gray"
          title={t('Purchase requests')}
          breadcrumbs={this.breadcrumbs}
          panelBtns={this.getBreadcrumbsButtons}
          content={
            <ContainersDefault>
              {this.isShowPurchaseRequestsTabs() && (
                <PurchaseRequestsTabs activeKey="all" />
              )}
              {/* {false && <PurchaseRequestsTabs activeKey="all" />} */}

              <AllRequests />
            </ContainersDefault>
          }
          sidebar={
            <SidebarThemeDefault insideState enableToggle id="all-requests">
              <PurchaseRequestsFilterSidebar />
            </SidebarThemeDefault>
          }
        />
        <MessengerWidgetWrapper />
      </>
    );
  }
}

export default withRouter(
  connect(
    (state) => ({
      user: state.getIn(['user', 'user']),
      company: state.get('company'),
      requests: state.getIn(['purchaseRequests', 'publishedRequests']),
      size: state.getIn(['purchaseRequests', 'size']),
      limit: state.getIn(['purchaseRequests', 'limit']),
      offset: state.getIn(['purchaseRequests', 'offset']),
      isShowPurchaseRequestsTabs:
        storeGetters.haveReadPurchaseRequestsPermission
    }),
    (dispatch) =>
      bindActionCreators(
        {
          showModal: modalActions.showModal,
          setSuccessMessage: messageActions.setSuccessMessage,
          hideModalDialog: modalActions.hideModalDialog,
          gaSend: serviceActions.gaSend,
          getRequest: requestsActions.getRequest
        },
        dispatch
      )
  )(translate(['ui'], { wait: true })(MAllPurchaseRequests))
);
