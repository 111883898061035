import React from 'react';
import { useVideoChatContext } from '../../hooks/useVideoChatContext';
import MemberCard from '../MemberCard';

const Theatre = () => {
  const { videoChatMembers } = useVideoChatContext();

  return (
    <div className="video-chat__section-video">
      {videoChatMembers.map((member) => (
        <MemberCard key={member.peerId} member={member} />
      ))}
    </div>
  );
};

export default Theatre;
