import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { translate } from 'react-i18next';
import { Glyphicon } from 'react-bootstrap';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { Link } from 'react-router-dom';
import { convertUrlToFile } from '../../components/ChatWidget/modules/files/utils';
import Pagination from '../../components/Pagination';
import Table from '../../components/TableNew';
import SearchBlockDefault from '../../components/SearchBlock/SearchBlockDefault';

import * as modalActions from '../../action-creators/modal';

class TableFileStorage extends Component {
  constructor(props) {
    super(props);
    this.setOrderBy = this.setOrderBy.bind(this);
    this.toggleOrderDirection = this.toggleOrderDirection.bind(this);
    this.setSearch = this.setSearch.bind(this);
    this.selectNextPagination = this.selectNextPagination.bind(this);
    this.modalEmployeesStorageStatistic =
      this.modalEmployeesStorageStatistic.bind(this);
    this.state = {
      search: '',
      orderBy: '',
      direction: '',
      hasFiles: Boolean(props.files?.length)
    };
  }

  componentDidUpdate(prevProps) {
    if (
      !prevProps.files?.length &&
      this.props.files.size &&
      !this.state.hasFiles
    ) {
      this.setHasFiles(true);
    }
  }

  setHasFiles(hasFiles = false) {
    this.setState({ hasFiles });
  }

  getColumns() {
    const { t, removeFileLinkBySection, getStorageSize, showModal } =
      this.props;

    const urlToFile = (item) => {
      const url = item.originalUrl
        ? `${item.originalUrl}?name=${item.name}`
        : `${item.fileInfo.originalUrl}?name=${item.name}`;
      return convertUrlToFile(url);
    };

    const list = [
      Table.ColText({ accessor: 'name', title: t('Name'), width: '18%' }),
      Table.ColDate({ accessor: 'createdAt', title: t('Added'), width: '18%' }),
      Table.ColText({
        accessor: (item) => item.type,
        title: t('Type'),
        index: 'type',
        width: '8%'
      }),
      Table.ColText({
        accessor: (item) => {
          const fileSize = +item.size;
          if (fileSize >= 1024 * 1024) {
            return `${(fileSize / (1024 * 1024)).toFixed(1)}mb`;
          }
          return `${(fileSize / 1024).toFixed(1)}kb`;
        },
        title: t('Size'),
        index: 'size'
      }),
      Table.ColText({
        accessor: (item) => (
          <div className="t-a-r">
            <div className="glypth-save-pright">
              <a href={urlToFile(item)}>
                <Glyphicon glyph="save" />
              </a>
            </div>
            <div
              className="glypth-remove-pleft pointer"
              onClick={async () => {
                showModal('SIMPLE_SUBMIT', {
                  captionKey: 'confirm_action',
                  text: 'Delete?',
                  textBtnConfirm: 'OK',
                  submitAction: async () => {
                    await removeFileLinkBySection([item.fileId], 'uploads');
                    await getStorageSize();
                  }
                });
              }}>
              <Glyphicon glyph="remove" />
            </div>
          </div>
        ),
        showTooltip: false
      })
    ];

    return list;
  }

  setOrderBy(orderBy) {
    this.setState({ orderBy });
  }

  setSearch(search) {
    const { getFiles, limit, orderId } = this.props;
    this.setState({ search });
    getFiles(
      {
        search,
        orderDirection: this.state.orderDirection,
        orderBy: this.state.orderBy,
        limit,
        offset: 0
      },
      orderId
    );
  }

  toggleOrderDirection() {
    // eslint-disable-next-line react/no-access-state-in-setstate
    const orderDirection =
      this.state.orderDirection === 'DESC' ? 'ASC' : 'DESC';
    this.setState({ orderDirection });
    return orderDirection;
  }

  selectNextPagination(value) {
    const { getFiles, limit, orderId } = this.props;
    const { search, orderBy, direction } = this.state;
    getFiles({ search, limit, offset: value, orderBy, direction }, orderId);
  }

  displayData() {
    const { files, selected } = this.props;
    return (
      files &&
      files.map((item) => {
        const id = item.get('fileId');
        const hasId = selected.has(id);
        return hasId
          ? item.set('isSelected', true)
          : item.set('isSelected', false);
      })
    );
  }

  // eslint-disable-next-line class-methods-use-this
  convertTo(size) {
    if (size > 1024 * 1024 * 1024) {
      return `${(size / (1024 * 1024 * 1024)).toFixed(2)} GB`;
    }
    if (size > 1024 * 1024) {
      return `${(size / (1024 * 1024)).toFixed(2)} MB`;
    }
    return `${(size / 1024).toFixed(2)} KB`;
  }

  modalEmployeesStorageStatistic() {
    const { showModal } = this.props;
    showModal('STORAGE_STATISTIC');
  }

  render() {
    const {
      t,
      getFiles,
      size,
      offset,
      limit,
      selectRow,
      maxStorageSize,
      storageSize,
      orderId,
      productId,
      dialogId,
      requestId,
      channelId,
      topicId,
      link
    } = this.props;
    const data = this.displayData();

    return (
      <div>
        {orderId && link && (
          <Link style={{ marginBottom: 15, display: 'inline-block' }} to={link}>
            {t('Return to order')}
          </Link>
        )}
        {productId && link && (
          <Link style={{ marginBottom: 15, display: 'inline-block' }} to={link}>
            {t('Return to product')}
          </Link>
        )}
        {dialogId && link && (
          <Link style={{ marginBottom: 15, display: 'inline-block' }} to={link}>
            {t('Return to chat')}
          </Link>
        )}
        {channelId && link && (
          <Link style={{ marginBottom: 15, display: 'inline-block' }} to={link}>
            {t('Return to channel')}
          </Link>
        )}
        {topicId && link && (
          <Link style={{ marginBottom: 15, display: 'inline-block' }} to={link}>
            {t('Return to topic')}
          </Link>
        )}
        {requestId && link && (
          <Link style={{ marginBottom: 15, display: 'inline-block' }} to={link}>
            {t('Return to purchase request')}
          </Link>
        )}
        <div style={{ marginBottom: 25 }}>
          <SearchBlockDefault
            placeholder={t('Name')}
            onSubmit={this.setSearch}
            onEmpty={this.setSearch}
            allowNull
          />
          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
          <a
            style={{ position: 'relative', float: 'right', marginTop: -25 }}
            onClick={this.modalEmployeesStorageStatistic}>
            {`${t('Abailable storage space')} ${this.convertTo(
              storageSize
            )}/${this.convertTo(maxStorageSize)}`}
          </a>
        </div>
        <div style={{ marginBottom: 20 }}>
          {data && data.size > 0 && (
            <div>
              <div className="text-content-left" style={{ marginBottom: 25 }}>
                <h3 className="text-description">{t('attache_tab_text')}</h3>
              </div>
              <Table
                columns={this.getColumns()}
                data={data}
                onSort={(orderBy, direction) => {
                  this.setState({ orderBy, direction });
                  getFiles({
                    search: this.state.search,
                    orderBy,
                    direction,
                    offset,
                    limit
                  });
                }}
                onSelect={selectRow}
              />
            </div>
          )}
          {data && data.size === 0 && !this.state.hasFiles && (
            <div>
              <div style={{ paddingTop: '10px' }}>{t('no files found')}</div>
              <div className="text-content-left" style={{ marginBottom: 25 }}>
                <h3 className="text-description">{t('attache_tab_text')}</h3>
              </div>
            </div>
          )}
        </div>
        <Pagination
          totalSize={size}
          limit={limit}
          offset={offset}
          nextPage={this.selectNextPagination}
        />
      </div>
    );
  }
}

TableFileStorage.contextTypes = {
  badgeSocket: PropTypes.object
};

export default connect(
  (state) => ({
    user: state.getIn(['user', 'user']),
    company: state.get('company'),
    selected: state.getIn(['storage', 'selected']),
    offset: state.getIn(['storage', 'uploads', 'offset']),
    limit: state.getIn(['storage', 'uploads', 'limit']),
    size: state.getIn(['storage', 'uploads', 'size'])
  }),
  (dispatch) =>
    bindActionCreators(
      {
        showModal: modalActions.showModal,
        hideModal: modalActions.hideModalDialog
      },
      dispatch
    )
)(translate(['ui'], { wait: true })(TableFileStorage));
