import React, { useMemo, useState } from 'react';
import { isNil } from 'ramda';
import {
  DateTimePicker,
  Icon,
  NumericInput,
  Switch,
  Typography
} from '@link/react-components';
import { useTranslate } from '@hooks';
import cn from 'classnames';
import styles from './ResponseExtraOptions.module.css';
import { formatDate } from '../../../../../utils/date';

const currency = '₽';
const maxCostOfDeliverySum = 999999;

const now = new Date();

function handleSwitch(value, setSwitch, setValue, prevValue, option) {
  setValue({ ...prevValue, [option]: value ? prevValue[option] ?? 0 : null });
  setSwitch(value);
}

function ViewExtraOption({ iconName, title, value, extension }) {
  const displayValue =
    typeof value === 'number' ? `${value} ${extension}` : value;
  return (
    <div className={styles.viewModeOption}>
      <Icon iconName={iconName} width={18} height={18} />
      <Typography variant="body1Med" className={styles.viewModeTitle}>
        {title}
      </Typography>
      <Typography variant="body1Reg" className={styles.viewModeValue}>
        {displayValue}
      </Typography>
    </div>
  );
}

function ExtraOption({ iconName, title, toggle, children }) {
  return (
    <div className={styles.extraOption}>
      <div className={styles.header}>
        <div className={styles.title}>
          <Icon iconName={iconName} width={18} height={18} />
          <Typography className={styles.titleText} variant="body1Med">
            {title}
          </Typography>
        </div>
        {toggle}
      </div>
      {children}
    </div>
  );
}

export function ResponseExtraOptions({
  mode = 'edit',
  extraOptions,
  setExtraOptions,
  marginOff = false
}) {
  const t = useTranslate();

  function getTranslate(tKey) {
    return t(`responseExtraOptions.${tKey}`);
  }

  const defaultDeadlineDate = useMemo(
    () => extraOptions?.deadline || null,
    [mode]
  );

  const [disabledCostOfDelivery, setDisabledCostOfDelivery] = useState(
    typeof extraOptions?.delivery === 'number' || false
  );
  const [disabledPrepayment, setDisabledPrepayment] = useState(
    typeof extraOptions?.payment === 'number' || false
  );

  function prepaymentValue() {
    if (isNil(extraOptions?.payment)) {
      return getTranslate('prepaymentIsntRequired');
    }

    if (extraOptions.payment === 0) {
      return getTranslate('prepaymentIsRequired');
    }

    return extraOptions.payment;
  }

  function deliveryValue() {
    if (isNil(extraOptions?.delivery)) {
      return getTranslate('deliveryCostIsIncluded');
    }

    if (extraOptions.delivery === 0) {
      return getTranslate('deliveryCostIsntIncluded');
    }

    return extraOptions.delivery;
  }

  return (
    <div className={cn(styles.extraOptions, marginOff && styles.marginOff)}>
      <Typography variant="h2" className={styles.mainTextColor}>
        {getTranslate('extraOptions')}
      </Typography>
      {mode === 'edit' ? (
        <div className={styles.extraOptionsBlock}>
          <ExtraOption title={getTranslate('ETATitle')} iconName="ETA">
            <DateTimePicker
              date={defaultDeadlineDate}
              onDateChange={(value) =>
                setExtraOptions({
                  ...extraOptions,
                  deadline: value
                })
              }
              className={styles.ETA}
              minDate={now}
            />
          </ExtraOption>
          <ExtraOption
            title={getTranslate('deliveryIncludedTitle')}
            iconName="delivery"
            toggle={
              <Switch
                onSwitch={(value) =>
                  handleSwitch(
                    value,
                    setDisabledCostOfDelivery,
                    setExtraOptions,
                    extraOptions,
                    'delivery'
                  )
                }
                checked={disabledCostOfDelivery}
              />
            }>
            <div className={styles.optionExtension}>
              <NumericInput
                showSpin={false}
                value={extraOptions?.delivery || null}
                maxValue={maxCostOfDeliverySum}
                minValue={0}
                onChange={(value) =>
                  setExtraOptions({ ...extraOptions, delivery: value })
                }
                className={styles.costOfDelivery}
                disabled={!disabledCostOfDelivery}
                placeholder="0"
              />
              <Typography variant="body1Med" className={styles.mainTextColor}>
                {currency}
              </Typography>
            </div>
          </ExtraOption>
          <ExtraOption
            title={getTranslate('prepaymentIsRequiredTitle')}
            iconName="prepayment"
            toggle={
              <Switch
                onSwitch={(value) =>
                  handleSwitch(
                    value,
                    setDisabledPrepayment,
                    setExtraOptions,
                    extraOptions,
                    'payment'
                  )
                }
                checked={disabledPrepayment}
              />
            }>
            <NumericInput
              showSpin
              value={extraOptions?.payment || null}
              maxValue={100}
              minValue={0}
              units="%"
              onChange={(value) =>
                setExtraOptions({ ...extraOptions, payment: value })
              }
              className={styles.prepayment}
              disabled={!disabledPrepayment}
              placeholder="0"
            />
          </ExtraOption>
        </div>
      ) : (
        <div className={styles.extraOptionViewMode}>
          <ViewExtraOption
            iconName="ETA"
            title={getTranslate('ETATitle')}
            value={
              extraOptions?.deadline
                ? formatDate(extraOptions.deadline, 'withOutTime')
                : getTranslate('ETANotSet')
            }
          />
          <ViewExtraOption
            iconName="delivery"
            title={getTranslate('deliveryTitle')}
            extension={currency}
            value={deliveryValue()}
          />
          <ViewExtraOption
            iconName="prepayment"
            title={getTranslate('prepaymentTitle')}
            extension="%"
            value={prepaymentValue()}
          />
        </div>
      )}
    </div>
  );
}
