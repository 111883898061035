export const optionSelect = (value, label, attrs = {}) => ({
  value,
  label,
  attrs
});

export const selectProps = (options, value, onChange) => ({
  value,
  onChange,
  options
});

export const creatableProps = (
  options,
  value,
  onChange = () => {},
  onInputChange = () => {},
  onInputKeyDown = () => {},
  onBlur = () => {}
) => ({ value, options, onChange, onInputChange, onInputKeyDown, onBlur });

export const inputNumberProps = (value, onChange) => ({ value, onChange });

export const datePickerProps = (value, onChange) => ({ value, onChange });
