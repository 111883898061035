import { isNil } from 'ramda';
import { useSelector } from 'react-redux';
import * as storeGetters from '../../storeGetters';
import * as tasksGetters from '../../tasks-manager/getters';

function calcPurchaseRequestNotify(
  allPurchaseRequestsNotify = 0,
  myPurchaseRequestsNotify = 0,
  myPurchaseResponsesNotify = 0,
  responsePurchaseRequestNotify = 0
) {
  return (
    allPurchaseRequestsNotify +
    myPurchaseRequestsNotify +
    myPurchaseResponsesNotify +
    responsePurchaseRequestNotify
  );
}

export default function useNavigationsList() {
  const partnerNotify = useSelector(storeGetters.getPartnerNotify);
  const allPurchaseRequestsNotify = useSelector(
    storeGetters.getAllPurchaseRequestsNotify
  );
  const myPurchaseRequestsNotify = useSelector(
    storeGetters.getMyPurchaseRequestsNotify
  );
  const myPurchaseResponsesNotify = useSelector(
    storeGetters.getMyPurchaseResponsesNotify
  );
  const responsePurchaseRequestNotify = useSelector(
    storeGetters.getResponsePurchaseRequestNotify
  );
  const chatNotify = useSelector(storeGetters.getChatUnmutedBadgeCount);
  const taskNotify = useSelector(tasksGetters.getTaskBadgesCount);

  return {
    navListMenu: getNavListMenu({
      chatNotify,
      partnerNotify,
      taskNotify,
      purchaseRequestNotify: calcPurchaseRequestNotify(
        allPurchaseRequestsNotify,
        myPurchaseRequestsNotify,
        myPurchaseResponsesNotify,
        responsePurchaseRequestNotify
      )
    })
  };
}

function getNavListMenu({
  chatNotify = 0,
  taskNotify = 0,
  purchaseRequestNotify = 0
}) {
  const onlyExist = (list = []) => list.filter((item) => !isNil(item));

  return onlyExist([
    {
      name: 'Purchase requests',
      icon: 'request',
      isShowBadge: purchaseRequestNotify > 0,
      amountNotify: purchaseRequestNotify,
      to: '/',
      nestedList: [{ name: 'constructor.path', to: '/constructor/list' }]
    },
    {
      name: 'messenger',
      icon: 'messenger',
      isShowBadge: chatNotify > 0,
      amountNotify: chatNotify,
      to: '/chat'
    },
    {
      name: 'My profile',
      icon: 'profile',
      to: '/profile/edit',
      nestedList: [{ name: 'File storage', to: '/file-storage/chat' }]
    },
    {
      name: 'my_organization',
      icon: 'organization',
      to: '/company/edit',
      nestedList: onlyExist([
        {
          name: 'employees',
          to: '/employees/list'
        }
      ])
    },
    {
      name: 'modules.company.sidebarMenu',
      icon: 'company',
      to: '/companies'
    },
    {
      name: 'taskManager.menuKey',
      icon: 'task-manager',
      isShowBadge: taskNotify > 0,
      amountNotify: taskNotify,
      to: '/task/list'
    },
    {
      name: 'modules.settings.sidebarMenu',
      icon: 'settings',
      to: '/settings'
    }
  ]);
}
