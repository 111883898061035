import React from 'react';
import { useActions, useNavigate } from '@hooks';
import {
  draft,
  getStatusBySupplierForColor,
  getTransStatusBySupplier
} from '../../../../../presenters/sidebars/PurchaseRequestResponsesSidebar/status';
import Status from '../../../../Status';
import * as modalActions from '../../../../../action-creators/modal';

import { colorList } from '../../../Request/respondColor';
import RoundSmallButton from '../../../../Buttons/RoundSmallButton';
import PreviewIcon from '../../../../../icons/preview';
import { formatDate } from '../../../../../utils/date';
import { formatRequestNumber } from '../../../../../utils/utils';
import Tooltip from '../../../../ChatWidget/Tooltip';
import TrashIcon from '../../../../../icons/trash';
import { useTranslate } from '../../../../../TranslateProvider';
import ArchiveIcon from '../../../../../icons/archive';
import * as storageActions from '../storage';
import ExtraButton from '../../../common/ExtraButton';
import { haveDeletePermission, haveUpdatePermission } from '../../../utils';
import ChatIcon from '../../../../../icons/chat';

const ResponseCard = ({
  response,
  onOpenResponseChat,
  isChecked,
  deleteResponse,
  badge,
  permissions
}) => {
  const t = useTranslate();

  const navigate = useNavigate();
  const { request } = response;
  const { showModal, archiveRespond } = useActions({
    ...modalActions,
    ...storageActions
  });
  if (!request) {
    return null;
  }

  const isDeletingAllowed =
    response.status === draft && haveDeletePermission(permissions);

  const menuOptions = [
    {
      icon: <ChatIcon />,
      name: t('go_to_chat'),
      onClick: () => onOpenResponseChat(response),
      disabled: false
    },
    {
      icon: <ArchiveIcon />,
      name: t(response.isArchived ? 'unarchive' : 'archive'),
      onClick: () =>
        archiveRespond({
          responseId: response.id,
          isArchived: !response.isArchived
        })
    },
    {
      icon: <TrashIcon />,
      name: t('Delete'),
      disabled: !isDeletingAllowed,
      onClick: async () =>
        showModal('SIMPLE_SUBMIT', {
          title: `${t('Delete?')}`,
          textBtnConfirm: 'Confirm',
          submitAction: deleteResponse(response.id)
        })
    }
  ];

  const isRequestCanceled = () => response.status === 'request-canceled';

  const goToMyResponse = () =>
    navigate(
      `/requests/my-responses/request/${request.id}/response/${response.id}`
    );
  const goToMyRequest = () =>
    navigate(`/requests/my-responses/request/${request.id}`);
  const goToCompany = () =>
    navigate(`/requests/my-responses/company/${response.to}`);

  const formatSum = new Intl.NumberFormat('ru-RU', {
    maximumFractionDigits: 2
  }).format(request.sum || 0);

  return (
    <div
      className={`my-responses-wrapper ${
        isChecked(response.id) ? 'my-responses-border' : ''
      }`}>
      <div
        className="my-responses-new-response"
        style={{ opacity: badge ? 1 : 0 }}
      />
      <div className="my-responses-col" style={{ marginRight: 16 }} />
      <div
        className="my-responses-col"
        style={{ marginRight: 16, maxWidth: '62%' }}>
        <div className="request-card-number">
          {`№ ${formatRequestNumber(request.id)} - ${
            request.number || formatRequestNumber(request.id)
          }`}
        </div>
        <div
          className="request-card-title"
          style={{
            cursor: isRequestCanceled() ? 'default' : 'pointer',
            marginTop: 6
          }}
          onClick={!isRequestCanceled() ? goToMyRequest : undefined}>
          {request.title}
        </div>
        <a onClick={goToCompany}>{response.attrs.toName}</a>
      </div>
      <div
        className="my-responses-col"
        style={{ marginLeft: 'auto', alignItems: 'flex-end' }}>
        <Status
          status={getStatusBySupplierForColor(response.status)}
          text={t(getTransStatusBySupplier(response.status))}
          statusColors={colorList}
        />
        <div style={{ marginTop: 8 }}>
          <span className="request-card-gray">{`${t('Response date')}: `}</span>
          <span>{formatDate(response.createdAt, 'withOutTime')}</span>
        </div>
        <span className="request-card-gray">
          {`${request.locations.country}`}
          {Boolean(request.locations.city) && `, г. ${request.locations.city}`}
        </span>
      </div>
      <div className="my-responses-divider" />
      <div
        className="my-responses-col"
        style={{ justifyContent: 'flex-end', maxWidth: '7.8%' }}>
        <span className="request-card-gray" style={{ marginBottom: 8 }}>{`${t(
          'request_sum'
        )}`}</span>
        <span className="request-card-title">{`${formatSum} ${t('r')}.`}</span>
      </div>

      <div className="my-responses-col" style={{ marginLeft: 40 }}>
        <Tooltip text="view_my_response">
          <RoundSmallButton svg={<PreviewIcon />} onClick={goToMyResponse} />
        </Tooltip>
      </div>
      {haveUpdatePermission(permissions) && (
        <div className="my-responses-col" style={{ overflow: 'visible' }}>
          <ExtraButton options={menuOptions} top={5} />
        </div>
      )}
    </div>
  );
};

export default ResponseCard;
