import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { ModalExportPrice } from '../components/Modal';

import * as priceProductsActions from '../action-creators/price-products-list';
import * as pricesActions from '../action-creators/prices';
import * as modalAction from '../action-creators/modal';
import * as actionsStocks from '../action-creators/stocks';

class ContModalExportPrice extends Component {
  UNSAFE_componentWillMount() {
    const { priceId, loadUnitsCodes, getUnits, getPriceCategories } =
      this.props;
    loadUnitsCodes();
    getUnits(priceId);
    getPriceCategories(priceId);
  }

  render() {
    return <ModalExportPrice {...this.props} />;
  }
}

export default connect(
  (state) => ({
    purchasePriceCategories: state.getIn(['prices', 'priceCategories']),
    user: state.getIn(['user', 'user', 'language']),
    unitCodes: state.getIn(['stocks', 'unitCodes']),
    units: state.getIn(['prices', 'units'])
  }),
  (dispatch) =>
    bindActionCreators(
      {
        submit: priceProductsActions.customExport,
        hideModalDialog: modalAction.hideModalDialog,
        getPriceCategories: pricesActions.getPriceCategories,
        loadUnitsCodes: actionsStocks.loadUnitsCodes,
        getUnits: pricesActions.getUnits
      },
      dispatch
    )
)(ContModalExportPrice);
