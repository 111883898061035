import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { translate } from 'react-i18next';
import { bindActionCreators } from 'redux';
import { Route, Routes } from 'react-router-dom';
import { withRouter } from '../hoc/withRouter';
import MessengerOther from '../components/Layouts/MessengerOther';
import WorkspaceHeader from '../components/WorkspaceHeader/WorkspaceHeader';
import TableOfEmployees from '../containers/Tables/TableOfEmployees';
import TableOfInviteEmployees from '../containers/Tables/TableOfInviteEmployees';
import PopupSmall from '../components/PopupSmall';
import PopupContent from '../components/PopupSmall/PopupContent';

import * as modalActions from '../action-creators/modal';
import * as companyActions from '../action-creators/company';
import * as serviceActions from '../action-creators/services';
import * as userActions from '../action-creators/user';
import * as billingActions from '../action-creators/billing';
import ButtonSmallArrow from '../components/Buttons/ButtonSmallArrow';

import { isCurrentPath } from '../utils';

const MEmployees = ({
  t,
  getCompany,
  getInvites,
  getListOfUsers,
  user,
  selectedEmployee,
  selectedInvites,
  gaSend,
  isOwner,
  isSelectOwner,
  removeEmployee,
  clearSelectedEmployee,
  showModal,
  hideModalDialog,
  sendInvite,
  assignNewOwner,
  getCurrentUser,
  removeInvite,
  clearSelectedInvite
}) => {
  const [isActive, setIsActive] = useState(false);
  const isInvite = isCurrentPath('/employees/invites');

  useEffect(() => {
    getCompany();
    getInvites();
    getListOfUsers();
  }, []);

  const removeEmployeeExec = async () => {
    await removeEmployee(selectedEmployee);
    await clearSelectedEmployee();
    await getListOfUsers();
  };

  const removeInviteExec = async () => {
    await removeInvite(selectedInvites);
    await clearSelectedInvite();
    await getInvites();
  };

  const showModalAddEmployee = () => {
    showModal('ADD_EMPLOYEE', {
      title: 'add_employee_to_company',
      fromMessenger: true
    });
  };

  const showModalInviteEmployee = () => {
    showModal('INVITE_EMPLOYEE', {
      title: 'Invite',
      text: t('Invite employee text'),
      hideModalDialog,
      sendInvite,
      fromMessenger: true
    });
  };

  const showModalNewOwner = () => {
    showModal('SIMPLE_SUBMIT', {
      captionKey: 'confirm_action',
      text: 'confirm_assign_new_owner',
      textBtnConfirm: 'Confirm',
      submitAction: () =>
        assignNewOwner(selectedEmployee.first())
          .then(getCompany)
          .then(getListOfUsers)
          .then(getCurrentUser)
          .then(async () => {
            await gaSend({
              category: 'Company',
              action: 'change_owner_company',
              label: user.get('email')
            });
          })
    });
  };

  const title = t('Employees');

  const tabs = [
    { name: t('Employees'), url: '/employees/list' },
    { name: t('Invites'), url: '/employees/invites' }
  ];

  const breadcrumbs = [{ name: t('Employees') }];

  const menuOptions = [
    {
      onClick: showModalAddEmployee,
      name: t('Add employee'),
      disabled: user.get('type') === 'demo'
    },
    {
      onClick: showModalInviteEmployee,
      name: t('invite_employee'),
      disabled: user.get('type') === 'demo'
    }
  ];

  const panelButtons = [
    {
      permissionGroup: 'update',
      element: (
        <>
          <ButtonSmallArrow
            isActive={isActive}
            onClick={() => setIsActive(!isActive)}>
            {t('Add employee')}
          </ButtonSmallArrow>
          <PopupSmall
            setIsOpened={setIsActive}
            top={0}
            isOpened={isActive}
            content={<PopupContent menuOptions={menuOptions} />}
          />
        </>
      )
    }
  ];

  if (isInvite) {
    panelButtons.push({
      permissionGroup: 'update',
      name: t('delete_invite'),
      onClick: () => removeInviteExec(selectedInvites.toJS()),
      disabled: !selectedInvites || selectedInvites.size === 0
    });
  } else {
    const isManyEmployees = selectedEmployee.size > 1;
    panelButtons.push({
      permissionGroup: 'delete',
      name: t('Delete employee'),
      disabled:
        !selectedEmployee.size ||
        selectedEmployee.includes(user.get('id')) ||
        isSelectOwner,
      onClick: () =>
        showModal('SIMPLE_SUBMIT', {
          title: 'delete_employee_title',
          text: `delete_employee_text_${isManyEmployees ? 'many' : 'one'}`,
          textBtnConfirm: 'Confirm',
          submitAction: () => {
            removeEmployeeExec();
          }
        })
    });

    if (isOwner) {
      panelButtons.push({
        name: t('Assign owner'),
        disabled:
          selectedEmployee.size === 0 ||
          isSelectOwner ||
          selectedEmployee.size > 1,
        onClick: showModalNewOwner
      });
    }
  }

  return (
    <MessengerOther
      title={title}
      panelBtns={panelButtons}
      breadcrumbs={breadcrumbs}
      content={
        <>
          <WorkspaceHeader items={tabs} />
          <Routes>
            <Route path="list" element={<TableOfEmployees />} />
            <Route path="invites" element={<TableOfInviteEmployees />} />
          </Routes>
        </>
      }
    />
  );
};

export default connect(
  (state) => ({
    user: state.getIn(['user', 'user']),
    selectedEmployee: state.getIn(['company', 'selectedEmployee']),

    selectedInvites: state.getIn(['company', 'selectedInvites']),
    invites: state.getIn(['company', 'invites']),

    isSelectOwner: state
      .getIn(['company', 'selectedEmployee'])
      .includes(state.getIn(['company', 'ownerUserId'])),
    isOwner:
      state.getIn(['company', 'ownerUserId']) ===
      state.getIn(['user', 'user', 'id'])
  }),
  (dispatch) =>
    bindActionCreators(
      {
        showModal: modalActions.showModal,
        hideModalDialog: modalActions.hideModalDialog,

        getInvites: companyActions.getInvites,
        sendInvite: companyActions.sendInvite,
        selectInvite: companyActions.selectInvite,
        removeInvite: companyActions.removeSelectedInvite,
        clearSelectedInvite: companyActions.clearSelectedInvite,

        assignNewOwner: companyActions.assignNewOwner,
        getCurrentUser: userActions.getCurrentUser,

        gaSend: serviceActions.gaSend,
        ...companyActions,
        unblockItems: billingActions.unblockItems
      },
      dispatch
    )
)(withRouter(translate()(MEmployees)));
