import React from 'react';
import { Link } from 'react-router-dom';
import block from 'bem-cn-lite';
import Badge from '../Badge';
import { ICON_TYPE_DEFAULT, ICON_TYPE_LINK } from '../../../constants';
import { SidebarIcons } from './SidebarIcons';

const b = block('snm-icon');

const Wrapper = ({ type, className, to, target = null, children }) => {
  if (type === ICON_TYPE_LINK) {
    return (
      <Link className={className} to={to} target={target}>
        {children}
      </Link>
    );
  }

  return <div className={className}>{children}</div>;
};

const Icon = ({
  type = ICON_TYPE_DEFAULT,
  icon,
  amountBadges = 0,
  to,
  target,
  isActive = false,
  isShowBadge = false
}) => (
  <Wrapper
    type={type}
    className={b({ active: isActive, badge: isShowBadge })}
    to={to}
    target={target}>
    {SidebarIcons[icon]}
    {isShowBadge && (
      <div className={b('badges')}>
        <Badge amount={amountBadges} />
      </div>
    )}
  </Wrapper>
);

export default Icon;
