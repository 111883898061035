import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { translate } from 'react-i18next';
import {
  Col,
  Row,
  Form,
  FormGroup,
  Button,
  ControlLabel
} from 'react-bootstrap';
import { fromJS } from 'immutable';

import { InputText, InputNumber } from '../FormControls';

import * as messageActions from '../../action-creators/message';
import * as companyActions from '../../action-creators/company';
import * as serviceActions from '../../action-creators/services';

class FormRequisites extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fullName: '',
      shortName: '',
      inn: '',
      kpp: '',
      ogrn: '',
      checkingAccount: '',
      fullBankName: '',
      correspondentAccount: '',
      bik: '',
      legalAddress: '',
      actualAddress: '',
      isDataSet: false
    };
  }

  UNSAFE_componentWillMount() {
    const { requisites } = this.props;
    if (requisites) {
      this.setState({
        fullName: requisites.getIn(['fullName', 'value']),
        shortName: requisites.getIn(['shortName', 'value']),
        inn: requisites.getIn(['inn', 'value']),
        kpp: requisites.getIn(['kpp', 'value']),
        ogrn: requisites.getIn(['ogrn', 'value']),
        checkingAccount: requisites.getIn(['checkingAccount', 'value']),
        fullBankName: requisites.getIn(['fullBankName', 'value']),
        correspondentAccount: requisites.getIn([
          'correspondentAccount',
          'value'
        ]),
        bik: requisites.getIn(['bik', 'value']),
        legalAddress: requisites.getIn(['legalAddress', 'value']),
        actualAddress: requisites.getIn(['actualAddress', 'value']),
        isDataSet: true
      });
    }
  }

  UNSAFE_componentWillReceiveProps(props) {
    const { requisites } = props;
    if (!this.state.isDataSet && requisites) {
      return this.setState({
        fullName: requisites.getIn(['fullName', 'value']),
        shortName: requisites.getIn(['shortName', 'value']),
        inn: requisites.getIn(['inn', 'value']),
        kpp: requisites.getIn(['kpp', 'value']),
        ogrn: requisites.getIn(['ogrn', 'value']),
        checkingAccount: requisites.getIn(['checkingAccount', 'value']),
        fullBankName: requisites.getIn(['fullBankName', 'value']),
        correspondentAccount: requisites.getIn([
          'correspondentAccount',
          'value'
        ]),
        bik: requisites.getIn(['bik', 'value']),
        legalAddress: requisites.getIn(['legalAddress', 'value']),
        actualAddress: requisites.getIn(['actualAddress', 'value']),
        isDataSet: true
      });
    }
    return undefined;
  }

  componentWillUnmount() {
    this.props.setSuccessMessage();
  }

  onChange(e, field) {
    this.setState({ [field]: e.target.value });
  }

  trimField(field) {
    this.setState({ [field]: this.state[field].trim() });
  }

  inputProps(field) {
    return {
      value: this.state[field],
      onChange: (e) => this.onChange(e, field),
      onBlur: () => this.trimField(field),
      viewRead: !this.props.havePermissionToUpdate
    };
  }

  dropState() {
    const { requisites } = this.props;
    this.setState({
      fullName: requisites.getIn(['fullName', 'value']),
      shortName: requisites.getIn(['shortName', 'value']),
      inn: requisites.getIn(['inn', 'value']),
      kpp: requisites.getIn(['kpp', 'value']),
      ogrn: requisites.getIn(['ogrn', 'value']),
      checkingAccount: requisites.getIn(['checkingAccount', 'value']),
      fullBankName: requisites.getIn(['fullBankName', 'value']),
      correspondentAccount: requisites.getIn(['correspondentAccount', 'value']),
      bik: requisites.getIn(['bik', 'value']),
      legalAddress: requisites.getIn(['legalAddress', 'value']),
      actualAddress: requisites.getIn(['actualAddress', 'value'])
    });
  }

  haveChanges() {
    const { company, requisites } = this.props;
    if (!company.get('isUploaded')) return false;

    const requisitesProps = requisites.toJS();
    const {
      checkingAccount,
      fullBankName,
      correspondentAccount,
      bik,
      actualAddress
    } = this.state;
    const mapState = fromJS({
      checkingAccount,
      fullBankName,
      correspondentAccount,
      bik,
      actualAddress
    });
    const mapProps = fromJS({
      checkingAccount: requisitesProps.checkingAccount.value,
      fullBankName: requisitesProps.fullBankName.value,
      correspondentAccount: requisitesProps.correspondentAccount.value,
      bik: requisitesProps.bik.value,
      actualAddress: requisitesProps.actualAddress.value
    });
    return !mapState.equals(mapProps);
  }

  validatePaste = (event) => {
    const reg = new RegExp('^[0-9]+$');
    const copiedText = event.clipboardData.getData('text');
    if (!reg.test(copiedText)) {
      event.preventDefault();
    }
    return undefined;
  };

  render() {
    const { t, updateRequisites, havePermissionToUpdate, gaSend, user } =
      this.props;

    return (
      <div className="form-content-center">
        <Form>
          <Row>
            <Row xs={12}>
              <div className="form-group-input-col">
                <ControlLabel className="control-label-row">
                  {t('fullName')}
                </ControlLabel>

                <InputText
                  maxLength="255"
                  value={this.state.fullName || '-'}
                  readOnly
                />
              </div>
            </Row>
            <Row xs={12}>
              <div className="form-group-input-col m-t-5">
                <ControlLabel className="control-label-row">
                  {t('shortName')}
                </ControlLabel>

                <InputText
                  maxLength="60"
                  value={this.state.shortName || '-'}
                  readOnly
                />
              </div>
            </Row>
            <Row xs={12}>
              <div className="form-group-input-col m-t-5">
                <ControlLabel className="control-label-row">
                  {t('inn')}
                </ControlLabel>

                <InputText
                  maxLength={12}
                  value={this.state.inn || '-'}
                  readOnly
                />
              </div>
            </Row>
            <Row xs={12}>
              <div className="form-group-input-col m-t-5">
                <ControlLabel className="control-label-row">
                  {t('kpp')}
                </ControlLabel>

                <InputText
                  maxLength={9}
                  value={this.state.kpp || '-'}
                  readOnly
                />
              </div>
            </Row>
            <Row xs={12}>
              <div className="form-group-input-col m-t-5">
                <ControlLabel className="control-label-row">
                  {t('ogrn')}
                </ControlLabel>

                <InputText
                  maxLength={15}
                  value={this.state.ogrn || '-'}
                  readOnly
                />
              </div>
            </Row>
            <Row xs={12}>
              <div className="form-group-input-col m-t-5">
                <ControlLabel className="control-label-row">
                  {t('legalAddress')}
                </ControlLabel>
                <InputText
                  maxLength="255"
                  value={this.state.legalAddress || '-'}
                  readOnly
                />
              </div>
            </Row>
            <Row xs={12}>
              <div className="form-group-input-col m-t-5">
                <ControlLabel className="control-label-row">
                  {t('checkingAccount')}
                </ControlLabel>
                <h6 className="pull-right m-t-5 m-b-0">
                  <small>{`${this.state.checkingAccount.length}/32`}</small>
                </h6>
                <InputText
                  maxLength="32"
                  {...this.inputProps('checkingAccount')}
                />
              </div>
            </Row>
            <Row xs={12}>
              <div className="form-group-input-col m-t-5">
                <ControlLabel className="control-label-row">
                  {t('fullBankName')}
                </ControlLabel>
                <h6 className="pull-right m-t-5 m-b-0">
                  <small>{`${this.state.fullBankName.length}/255`}</small>
                </h6>
                <InputText
                  maxLength="255"
                  {...this.inputProps('fullBankName')}
                />
              </div>
            </Row>
            <Row xs={12}>
              <div className="form-group-input-col m-t-5">
                <ControlLabel className="control-label-row">
                  {t('correspondentAccount')}
                </ControlLabel>
                <h6 className="pull-right m-t-5 m-b-0">
                  <small>{`${this.state.correspondentAccount.length}/32`}</small>
                </h6>
                <InputText
                  maxLength="32"
                  {...this.inputProps('correspondentAccount')}
                />
              </div>
            </Row>
            <Row xs={12}>
              <div className="form-group-input-col m-t-5">
                <ControlLabel className="control-label-row">
                  {t('bik')}
                </ControlLabel>
                <h6 className="pull-right m-t-5 m-b-0">
                  <small>{`${this.state.bik.length}/9`}</small>
                </h6>
                <InputNumber
                  maxLength={9}
                  {...this.inputProps('bik')}
                  onPaste={this.validatePaste}
                />
              </div>
            </Row>
            <Row xs={12}>
              <div className="form-group-input-col m-t-5">
                <ControlLabel className="control-label-row">
                  {t('actualAddress')}
                </ControlLabel>
                <h6 className="pull-right m-t-5 m-b-0">
                  <small>{`${this.state.actualAddress.length}/255`}</small>
                </h6>
                <InputText
                  maxLength="255"
                  {...this.inputProps('actualAddress')}
                />
              </div>
            </Row>
            {havePermissionToUpdate && (
              <FormGroup>
                <Col>
                  <div className="form-button-center m-t-15">
                    <Button
                      id="saveCompanyButton"
                      bsStyle="primary"
                      style={{
                        marginLeft: 15
                      }}
                      disabled={!this.haveChanges()}
                      onClick={async () => {
                        await updateRequisites(this.state);
                        await gaSend({
                          category: 'Company',
                          action: 'company_details',
                          label: user.get('email')
                        });
                      }}>
                      {t('Save changes')}
                    </Button>
                    <Button
                      id="cancelCompanyBtn"
                      bsStyle="default"
                      style={{ marginLeft: '15px' }}
                      disabled={!this.haveChanges()}
                      onClick={() => this.dropState()}>
                      {t('Cancel')}
                    </Button>
                  </div>
                </Col>
              </FormGroup>
            )}
          </Row>
        </Form>
      </div>
    );
  }
}

export default connect(
  (state) => ({
    company: state.get('company'),
    user: state.getIn(['user', 'user']),
    requisites: state.getIn(['company', 'requisites']),
    havePermissionToUpdate: state.getIn([
      'user',
      'user',
      'permissions',
      'company',
      'update'
    ])
  }),
  (dispatch) =>
    bindActionCreators(
      {
        setSuccessMessage: messageActions.setSuccessMessage,
        gaSend: serviceActions.gaSend,
        getCompany: companyActions.getCompany,
        updateRequisites: companyActions.updateRequisites
      },
      dispatch
    )
)(translate(['ui'], { wait: true })(FormRequisites));
