import React from 'react';
import { useDrop } from 'react-dnd';

function generateDropzoneCN(cn, { isActive = false, isLast = false }) {
  return `${cn || ''} dropZone ${isActive ? 'active' : ''} ${
    isLast ? 'isLast' : ''
  }`.trim();
}

const DropZone = ({
  data,
  onDrop,
  isLast,
  className,
  accept = [],
  children
}) => {
  const [{ isOver, canDrop }, drop] = useDrop({
    accept,
    drop: (item) => onDrop(data, item),
    canDrop: (item) => {
      const dropZonePath = data.path;
      const splitDropZonePath = dropZonePath.split('-');
      const dropZoneSection = splitDropZonePath[0];
      const itemPath = item.path;
      const splitItemPath = itemPath.split('-');
      const itemSection = splitItemPath[0];

      // Item can't possibly move to it's own section
      if (dropZoneSection === itemSection) return false;

      // Item can't possibly move to it's own location
      if (dropZonePath === itemPath) return false;

      if (splitItemPath.length === splitDropZonePath.length) {
        const pathToItem = splitItemPath.slice(0, -1).join('-');
        const currentItemIndex = Number(splitItemPath.slice(-1)[0]);

        const pathToDropZone = splitDropZonePath.slice(0, -1).join('-');
        const currentDropZoneIndex = Number(splitDropZonePath.slice(-1)[0]);

        if (pathToItem === pathToDropZone) {
          const nextDropZoneIndex = currentItemIndex + 1;
          if (nextDropZoneIndex === currentDropZoneIndex) return false;
        }
      }

      return true;
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop()
    })
  });

  const isActive = isOver && canDrop;

  return (
    <div
      ref={drop}
      className={generateDropzoneCN(className, { isActive, isLast })}>
      {children}
    </div>
  );
};

export default DropZone;
