export const updateValidatorState = (
  fieldName,
  isValid,
  messageKey,
  messageParams
) => ({
  type: 'VALIDATOR_UPDATE_STATE',
  fieldName,
  isValid,
  messageKey,
  messageParams
});

export const validate = (value, rule, dispatch, fieldName) => {
  const urlPattern =
    /^(https:\/\/)([^\s/$?#.]+[-.])+([^\s/$?#.]{2,6})([^\s]*)\/?$/;

  // eslint-disable-next-line max-len
  const emailPattern =
    /^([0-9a-zA-Z]([-_\\.]*[0-9a-zA-Z]+)*)@([0-9a-zA-Z]([-_\\.]*[0-9a-zA-Z]+)*)[\\.]([a-zA-Z]{2,9})$/;

  const trimmedValue = value.trim();
  const rules = rule instanceof Array ? rule : [rule];
  let isValid = true;
  let messageKey;

  if (rules.includes('url')) {
    isValid = !value || urlPattern.test(trimmedValue);
    messageKey = 'Wrong url format';
  }

  if (rules.includes('email')) {
    isValid = !value || emailPattern.test(trimmedValue);
    messageKey = 'Wrong email format';
  }

  if (rules.includes('required')) {
    isValid = !!trimmedValue;
    messageKey = 'Field is required';
  }

  if (dispatch instanceof Function) {
    dispatch(updateValidatorState(fieldName, isValid, messageKey));
  }

  return isValid;
};

export const updateServerValidation = (
  fieldName,
  isValid,
  messageKey,
  messageParams
) => ({
  type: 'SERVER_UPDATE_VALIDATOR',
  fieldName,
  isValid,
  messageKey,
  messageParams
});

export const clearValidatorState = (dispatch) => {
  if (dispatch instanceof Function) {
    dispatch({
      type: 'VALIDATOR_CLEAR_STATE'
    });
  }
};
