import { assoc, omit } from 'ramda';
import { nanoid } from 'nanoid';

const excludeFieldsFromStep = (step) => omit(['id'], step);
const addUniqueIdToEntity = (entity) => assoc('id', nanoid(), entity);

export const mapBuilderToDTO = (builder) => {
  const { path } = builder;

  const pathToSend = path.map((step) => {
    const excludeIdFromStep = excludeFieldsFromStep(step);

    const entriesStep = Object.entries(excludeIdFromStep).map(
      ([key, value]) => {
        const excludedCards = value.map(omit(['isError']));
        return [key, excludedCards];
      }
    );

    return Object.fromEntries(entriesStep);
  });
  return {
    ...builder,
    path: pathToSend
  };
};

export const mapDTOToFrontEntity = (dto) => {
  const { path } = dto;

  const pathToDto = path.map((step) => {
    const entriesStep = Object.entries(step).map(([key, value]) => {
      const excludedCards = value.map(assoc('isError', false));
      return [key, excludedCards];
    });
    const entityStep = Object.fromEntries(entriesStep);
    return addUniqueIdToEntity(entityStep);
  });
  return {
    ...dto,
    path: pathToDto
  };
};
