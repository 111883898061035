import React from 'react';
import { translate } from 'react-i18next';
import { HelpBlock } from 'react-bootstrap';
import { InputNumber } from '../../../FormControls';
import IconSimpleCross from '../../../../icons/cross';
import SectionOffset from '../../Request/SectionOffset';
import ErrorNotice from './ErrorNotice';

const RequestSum = ({
  t,
  onChange,
  sum,
  productsSum,
  isOpened,
  setIsOpened,
  isError
}) => {
  if (!isOpened) {
    return null;
  }

  return (
    <>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        {t('Purchase price')}
        <div
          onClick={() => setIsOpened(false)}
          data-testid="request-sum-opener">
          <IconSimpleCross />
        </div>
      </div>

      <SectionOffset offset={16} />
      <ErrorNotice isError={isError} data-testid="request-sum-error-notice">
        <InputNumber
          type="number"
          min="1"
          step="1"
          placeholder={t('enter_sum')}
          value={productsSum || sum || ''}
          onChange={onChange}
          disabled={!!productsSum}
        />
      </ErrorNotice>
      {isError && (
        <HelpBlock style={{ marginBottom: 0 }} data-testid="request-sum-help">
          {t('Required fields')}
        </HelpBlock>
      )}

      <SectionOffset offset={14} />
    </>
  );
};

export default translate(['ui'], { wait: true })(RequestSum);
