import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useInput } from 'react-components/src/hooks';
import {
  useFetchNewsAPIUrl,
  useSetNewsAPIUrl
} from '../../../components/ChatWidget/modules/externalAPI/useCases';
import * as getters from '../../../components/ChatWidget/modules/externalAPI/redux/getters';
import Button from '../../../components/Buttons/Button';

const Content = () => {
  const newsAPIUrl = useSelector(getters.getNewsAPIUrl);
  const [newsAPIValue, setNewsAPIValue] = useInput('');

  const fetchNewsUrl = useFetchNewsAPIUrl();
  const setNewsUrl = useSetNewsAPIUrl();

  useEffect(() => {
    fetchNewsUrl();
  }, []);

  return (
    <div>
      <div>Источник новостей:</div>
      <div>{newsAPIUrl || `Источник новостей не задан`}</div>
      <br />
      <div>Введите ссылку на источник</div>
      <input
        defaultValue={newsAPIUrl}
        value={newsAPIValue}
        onChange={setNewsAPIValue}
        style={{ width: '100%' }}
      />
      <Button onClick={() => setNewsUrl(newsAPIValue)}>Сохранить</Button>
    </div>
  );
};

export default Content;
