import { Map, List } from 'immutable';
// eslint-disable-next-line import/no-cycle
import {
  getSum,
  getVat,
  getSumWithCatCondition,
  getVatWithCatCondition,
  adapterCalcPriceOfProduct,
  adapterCalcLinkPriceOfProduct,
  getVATOfSum,
  pricePerAmount,
  totalOrderedProducts,
  getDraftSumWithCatCondition,
  getDraftVatWithCatCondition
} from '../viewModels/sum/orderDraft';

const mainPath = (state) => state.getIn(['ordersCombine', 'orderDraft']);
const mainPathNotDraft = (state) => state.getIn(['orders']);

const serializeOrderProduct = (state) => (product) =>
  product
    .set('discount', +product.get('discount'))
    .set('cost', +product.get('cost'))
    .set('count', +product.get('count'))
    .set('offers', product.get('offers') || List())
    // .set('fractionalDigits', product.get('isRound') ? NUMBER_OF_DIGITS.UNITS : NUMBER_OF_DIGITS.HUNDREDS)
    .set(
      'vatSum',
      pricePerAmount(
        getVATOfSum(
          state,
          product.set('offers', product.get('offers') || List())
        ),
        product
      )
    )
    .set('purchasePrice', adapterCalcPriceOfProduct(state, product))
    .set(
      'totalDiscount',
      (1 -
        adapterCalcPriceOfProduct(state, product) / product.get('retailCost')) *
        100 || 0
    )
    .set(
      'totalPrice',
      pricePerAmount(adapterCalcPriceOfProduct(state, product), product)
    );

const serializeLinkPriceOrderProduct = (state) => (product) =>
  product
    .set('discount', +product.get('discount'))
    .set('cost', +product.get('cost'))
    .set('count', +product.get('count'))
    .set('offers', product.get('offers') || List())
    // .set('fractionalDigits', product.get('isRound') ? NUMBER_OF_DIGITS.UNITS : NUMBER_OF_DIGITS.HUNDREDS)
    .set(
      'vatSum',
      pricePerAmount(
        getVATOfSum(
          state,
          product.set('offers', product.get('offers') || List())
        ),
        product
      )
    )
    .set('purchasePrice', adapterCalcLinkPriceOfProduct(state, product))
    .set(
      'totalDiscount',
      (1 -
        adapterCalcLinkPriceOfProduct(state, product) /
          product.get('retailCost')) *
        100 || 0
    )
    .set(
      'totalPrice',
      pricePerAmount(adapterCalcLinkPriceOfProduct(state, product), product)
    );

export const getProducts = (state) =>
  mainPath(state).get('items').map(serializeOrderProduct(state));
export const getProductsNotDraft = (state) =>
  mainPathNotDraft(state).get('items').map(serializeOrderProduct(state));
export const getLinkPriceProducts = (state) =>
  mainPath(state).get('items').map(serializeLinkPriceOrderProduct(state));
export const getSelectedProducts = (state) =>
  mainPath(state).get('selectedItems').map(serializeOrderProduct(state));

export default (state) => ({
  data: () => mainPath(state),
  orderId: () => mainPath(state).get('orderId'),
  hasChanges: () => mainPath(state).get('hasChanges'),
  priceCategory: () => mainPath(state).get('priceCategory'),
  priceCategoryConditions: () => mainPath(state).get('priceCategoryConditions'),
  items: () => getProducts(state),
  selectedItems: () => getSelectedProducts(state),
  linkPriceItems: () => getLinkPriceProducts(state),
  sum: () => getSum(state),
  vat: () => getVat(state),
  draftSumWithConditions: () => getDraftSumWithCatCondition(state),
  draftVatWithConditions: () => getDraftVatWithCatCondition(state),
  sumWithConditions: () => getSumWithCatCondition(state),
  vatWithConditions: () => getVatWithCatCondition(state),
  calcPriceProduct: () => adapterCalcPriceOfProduct(state),
  totalOrderedProducts: () => totalOrderedProducts(state),
  haveOrderProducts: () => !mainPath(state).get('items').isEmpty(),
  combineOrdersStocks: () =>
    mainPath(state).get('combineOrdersStocks') || Map(),
  excluded: () => mainPath(state).get('excluded') || List(),
  consignees: () => mainPath(state).get('consignees') || List()
});
