import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { ModalAddCustomer } from '../components/Modal';
import * as customersAction from '../action-creators/customers';
import * as modalAction from '../action-creators/modal';

const ContModalAddCustomer = (props) => <ModalAddCustomer {...props} />;

export default connect(
  (state) => ({
    newCustomer: state.getIn(['customers', 'newCustomer']),
    groups: state.getIn(['customers', 'groups'])
  }),
  (dispatch) =>
    bindActionCreators(
      {
        getGroups: customersAction.getGroups,
        updateCustomerField: customersAction.updateCustomerField,
        addCustomer: customersAction.addCustomer,
        clearForm: customersAction.clearForm,
        hideModalDialog: modalAction.hideModalDialog
      },
      dispatch
    )
)(ContModalAddCustomer);
