import React from 'react';
import PropTypes from 'prop-types';
import { translate } from 'react-i18next';
import { differenceInDays, format } from 'date-fns';
import DOMPurify from 'dompurify';

import Avatar from '../../../../Avatar';
import Attachments from '../../../../../Channel/attachments/Attachments';
import { getTime } from '../../../../../../utils/date';
import ButtonIcon from '../../../../ButtonIcon';
import { linkToProfileHandler } from '../../../../utils';

const Msg = ({ t, data, isForwarded, isOnline, onRemove, showProfile }) => {
  const renderDate = () => {
    const time = data.type === 'MsgForwarded' ? data.originTime : data.time;
    if (differenceInDays(new Date(), new Date(time)) !== 0) {
      return format(new Date(time), 'dd.MM.yyyy');
    }
    return getTime(time);
  };

  return (
    <div className="cwm-message">
      <div className="cwm-message__inner">
        <div className="cwm-message__avatar">
          <Avatar
            size={34}
            isOnline={isOnline}
            info={{ type: 'user', src: data.avatarSrc, alt: data.userName }}
          />
        </div>
        <div
          className={`cwm-message__block-msg ${
            isForwarded ? 'forwarded' : ''
          }`}>
          {!isForwarded && (
            <div className="cwm-message__username">{data.userName}</div>
          )}
          {isForwarded && (
            <div className="cwm-message__autorname">{`${t('forwarded_from')} ${
              data.authorUserName
            }`}</div>
          )}
          <div>
            <div
              className="cwm-um-msg"
              dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(data.msg) }}
              onClick={(event) => linkToProfileHandler(event, showProfile)}
            />
            <Attachments
              getAvatar={() =>
                !data.avatarSrc ? '/img/user.svg' : data.avatarSrc
              }
              messageId={data.id}
              files={data.files}
              allowDelete={false}
              mode="disable-menu"
              showSingleImage={data.files.length === 1}
            />
          </div>
          <div className="cwm-um-footer">
            <div className="cwm-um-footer__time">{renderDate()}</div>
            {data.edited && (
              <div className="cwm-um-footer__edited">
                {t('chat-widget.section.main.edited')}
              </div>
            )}
          </div>
        </div>
        <div className="cwm-message__btn">
          <span className="cwm-message__btn-wrap">
            <ButtonIcon
              icon="remove-1"
              tooltip="delete"
              onClick={() => onRemove(data.id)}
            />
          </span>
        </div>
      </div>
    </div>
  );
};

Msg.propTypes = {
  t: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired,
  isForwarded: PropTypes.bool.isRequired,
  isOnline: PropTypes.bool.isRequired,
  onRemove: PropTypes.func.isRequired
};

export default translate()(Msg);
