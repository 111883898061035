import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { ModalEditBasePrice } from '../components/Modal';

import * as priceProductsActions from '../action-creators/price-products-list';
import * as modalAction from '../action-creators/modal';
import * as servicesAction from '../action-creators/services';

const ContModalEditBasePrice = (props) => <ModalEditBasePrice {...props} />;

export default connect(
  (state) => ({
    user: state.getIn(['user', 'user']),
    search: state.getIn(['tablesNew', 'priceProducts', 'search']),
    limit: state.getIn(['tablesNew', 'priceProducts', 'limit']),
    offset: state.getIn(['tablesNew', 'priceProducts', 'offset'])
  }),
  (dispatch) =>
    bindActionCreators(
      {
        submit: priceProductsActions.editBasePriceForProducts,
        hideModalDialog: modalAction.hideModalDialog,
        getProducts: priceProductsActions.getProducts,
        gaSend: servicesAction.gaSend
      },
      dispatch
    )
)(ContModalEditBasePrice);
