import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import block from 'bem-cn-lite';
import './sidebar_theme_default.styl';

import { Sidebar } from '../elements';
import { getIsShown } from '../../ChatWidget/getter';

const b = block('sidebar');

class SidebarThemeDefault extends Component {
  static setClassToHtml(isOpen) {
    if (isOpen) {
      $('html').addClass('sidebar-is-open').removeClass('sidebar-is-close');
    } else {
      $('html').addClass('sidebar-is-close').removeClass('sidebar-is-open');
    }
  }

  getChildContext() {
    return { b };
  }

  constructor(props) {
    super(props);

    this.state = {
      isOpen: this.initializeIsOpen()
    };

    this.onClick = this.onClick.bind(this);
    this.toggle = this.toggle.bind(this);
    this.forceChangeIsOpen = this.forceChangeIsOpen.bind(this);
    this.initializeIsOpen = this.initializeIsOpen.bind(this);
  }

  changeClassForOpenedSidebar = () => {
    const { isOpen } = this.props;
    if (isOpen) {
      $('html').addClass('sidebar-is-open').removeClass('sidebar-is-close');
    }
  };

  UNSAFE_componentWillMount() {
    this.changeClassForOpenedSidebar();
  }

  UNSAFE_componentWillUpdate(nextProps, nextState) {
    if (this.props.insideState) {
      SidebarThemeDefault.setClassToHtml(nextState.isOpen);
    } else {
      SidebarThemeDefault.setClassToHtml(nextProps.isOpen);
    }
  }

  componentDidMount() {
    const { id } = this.props;
    document.addEventListener(
      `${id}:FORCE_CHANGE_SIDEBAR_STATE`,
      this.forceChangeIsOpen
    );
  }

  componentWillUnmount() {
    $('html').removeClass('sidebar-is-open').removeClass('sidebar-is-close');

    const { id } = this.props;
    document.removeEventListener(
      `${id}:FORCE_CHANGE_SIDEBAR_STATE`,
      this.forceChangeIsOpen
    );
  }

  changeClassesAfterToggleChat = (isChatOpen) => {
    if (isChatOpen) {
      $('html').removeClass('sidebar-is-open').removeClass('sidebar-is-close');
    } else {
      this.changeClassForOpenedSidebar();
    }
  };

  componentDidUpdate(prevProps) {
    const { isChatOpen } = this.props;
    if (prevProps.isChatOpen !== isChatOpen) {
      this.changeClassesAfterToggleChat(isChatOpen);
    }
  }

  initializeIsOpen() {
    const { id, isDefaulClose = false, isSaveToLS = true } = this.props;

    if (!isSaveToLS) return !isDefaulClose;

    return !(
      isDefaulClose || localStorage.getItem(`is-filter-opened${id}`) === 'false'
    );
  }

  forceChangeIsOpen(event) {
    const { isOpen } = event.detail;
    this.setState({ isOpen });
  }

  onClick() {
    const { insideState, enableToggle, onClick } = this.props;
    if (!insideState && !!onClick) {
      onClick();
    } else if (enableToggle) {
      this.toggle();
    }
  }

  toggle() {
    if (this.props.id) {
      localStorage.setItem(
        `is-filter-opened${this.props.id}`,
        !this.state.isOpen
      );
    }
    this.setState({ isOpen: !this.state.isOpen });
  }

  get isOpen() {
    const { insideState, isOpen } = this.props;
    if (insideState) {
      return this.state.isOpen;
    }
    return isOpen;
  }

  showBtnOpen() {
    const { insideState, enableToggle, onClick } = this.props;
    if (insideState) {
      return enableToggle;
    }
    return !!onClick;
  }

  render() {
    const { children, style, theme = 'default' } = this.props;
    return (
      <div style={{ height: '100%', display: 'flex', backgroundColor: '#fff' }}>
        {this.showBtnOpen() ? (
          <div
            className={
              this.isOpen
                ? 'button_open_arrow_sidebar'
                : 'button_close_arrow_sidebar'
            }
            onClick={this.onClick}
          />
        ) : (
          <div />
        )}
        <Sidebar mods={{ theme, show: this.isOpen }} style={style}>
          {children}
        </Sidebar>
      </div>
    );
  }
}

SidebarThemeDefault.propTypes = {
  children: PropTypes.element.isRequired
};

SidebarThemeDefault.childContextTypes = {
  b: PropTypes.func
};

export default connect(
  (state) => ({ isChatOpen: getIsShown(state) }),
  null
)(SidebarThemeDefault);
