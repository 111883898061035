import React, { Component } from 'react';
import PropTypes from 'prop-types';
import block from 'bem-cn-lite';
import { translate } from 'react-i18next';
import {
  Modal,
  Form,
  Button,
  ControlLabel,
  FormControl,
  FormGroup,
  HelpBlock
} from 'react-bootstrap';
import './styles/modal_theme_default.styl';

import { ModalContainer } from './elements';
import Helper from '../Helper';

const b = block('modal-container');

class ModalSendPriceChanges extends Component {
  constructor(props) {
    super(props);

    const { t, companyName, priceName } = props;

    this.state = {
      title: t('notification_of_changes_price_list_update'),
      text: t('notification_of_changes_mail_text_placeholder', {
        companyName: this.removeQuotesFromString(companyName),
        priceName: this.removeQuotesFromString(priceName)
      })
    };
  }

  getChildContext() {
    return { b };
  }

  translate(text) {
    return typeof text === 'string' ? this.props.t(text) : text;
  }

  asterisk = () => <span style={{ color: 'red' }}>*</span>;

  removeQuotesFromString = (str) => str.replace(/['"]+/g, '');

  isTitleValid = () => {
    const { title } = this.state;
    const isOnlySpaces = !title.replace(/\s/g, '').length;

    return title.length && !isOnlySpaces;
  };

  isTextValid = () => {
    const { text } = this.state;
    const isOnlySpaces = !text.replace(/\s/g, '').length;

    return text.length && !isOnlySpaces;
  };

  handleTitleChange = (e) => {
    if (this.state.title.length <= 60) {
      this.setState({ title: e.target.value });
    }
  };

  handleTextChange = (e) => {
    if (this.state.text.length <= 600) {
      this.setState({ text: e.target.value });
    }
  };

  render() {
    const {
      t,
      onCancel = () => {},
      companyName,
      submit,
      type,
      hideModalDialog,
      onSubmited
    } = this.props;

    const { title, text } = this.state;

    return (
      <Modal
        id="sendPriceChanges"
        className="wide-modal"
        onHide={() => hideModalDialog()}
        show>
        <ModalContainer mods={{ type: type || 'default', theme: 'default' }}>
          <Modal.Header>
            <Modal.Title>
              {t('create_distribution_of_changes')}
              <span
                className="modal-header-cross pull-right"
                onClick={() => {
                  onCancel();
                  hideModalDialog();
                }}
              />
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <p className={b('row')}>
                {t('notifications_of_changes_will_be_sent_to_customers')}
              </p>
              <FormGroup
                controlId="input_mail_title"
                validationState={this.isTitleValid() ? null : 'error'}
                style={{ overflow: 'hidden' }}>
                <ControlLabel
                  style={{
                    color: this.isTitleValid() ? '#767B92' : '#FD4F53',
                    fontWeight: 'normal',
                    fontSize: '14px',
                    margin: '15px 0'
                  }}>
                  {t('notification_of_changes_mail_title')}
                  {this.asterisk()}
                  {'\u00a0'}
                  <Helper
                    text="notification_of_changes_mail_title_helper"
                    textParams={{
                      companyName: this.removeQuotesFromString(companyName)
                    }}
                  />
                </ControlLabel>
                <h6 className="pull-right" style={{ marginTop: 30 }}>
                  <small>
                    {title.length}
                    /60
                  </small>
                </h6>
                <FormControl
                  type="text"
                  maxLength={60}
                  value={title}
                  onChange={this.handleTitleChange}
                  placeholder={t('notification_of_changes_price_list_update')}
                />
                {!this.isTitleValid() && (
                  <HelpBlock style={{ marginBottom: 0 }}>
                    {t('Required fields empty')}
                  </HelpBlock>
                )}
              </FormGroup>
              <FormGroup
                controlId="input_mail_text"
                validationState={this.isTextValid() ? null : 'error'}
                style={{ overflow: 'hidden' }}>
                <ControlLabel
                  style={{
                    color: this.isTextValid() ? '#767B92' : '#FD4F53',
                    fontWeight: 'normal',
                    fontSize: '14px',
                    margin: '15px 0'
                  }}>
                  {`${t('notification_of_changes_mail_text_title')} `}
                  {this.asterisk()}
                </ControlLabel>
                <h6 className="pull-right" style={{ marginTop: 30 }}>
                  <small>
                    {text.length}
                    /600
                  </small>
                </h6>
                <FormControl
                  type="text"
                  componentClass="textarea"
                  maxLength={600}
                  value={text}
                  rows={6}
                  onChange={this.handleTextChange}
                />
                {!this.isTextValid() && (
                  <HelpBlock style={{ marginBottom: 0 }}>
                    {t('Required fields empty')}
                  </HelpBlock>
                )}
              </FormGroup>
            </Form>
            <div style={{ marginTop: 25 }}>
              {this.asterisk()}
              {` ${t('Required fields')}`}
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              id="cancel"
              className="pull-left"
              onClick={() => {
                hideModalDialog();
                onCancel();
              }}>
              {t('Cancel')}
            </Button>
            <Button
              id="saveButton"
              bsStyle="primary"
              disabled={!this.isTitleValid() || !this.isTextValid()}
              onClick={() => {
                submit({ title, text })
                  .then(() => {
                    hideModalDialog();
                    onSubmited();
                  })
                  .catch((errors) => {
                    console.error(errors);
                    hideModalDialog();
                    onSubmited();
                  });
              }}>
              {t('Create')}
            </Button>
          </Modal.Footer>
        </ModalContainer>
      </Modal>
    );
  }
}

ModalSendPriceChanges.propTypes = {
  t: PropTypes.func.isRequired
};

ModalSendPriceChanges.childContextTypes = {
  b: PropTypes.func
};

export default translate(['ui'], { wait: true })(ModalSendPriceChanges);
