import React, { Component } from 'react';
import PropTypes from 'prop-types';
import block from 'bem-cn-lite';
import { translate } from 'react-i18next';
import {
  Modal,
  Col,
  Row,
  Form,
  FormGroup,
  FormControl,
  ControlLabel,
  Button,
  HelpBlock,
  OverlayTrigger,
  Popover
} from 'react-bootstrap';
import Select from 'react-select';
import { List } from 'immutable';

import '../styles/modal_theme_default.styl';

import { ModalContainer } from '../elements';
import { testClass } from '../../../lib';
import Helper from '../../Helper';

const b = block('modal-container');

class ModalAddPrice extends Component {
  constructor(props) {
    super(props);
    this.inputProps = this.inputProps.bind(this);
    this.state = {
      pristine: true,
      name: '',
      currency: 'RUB',
      rate: 0,
      stocks: List(),
      description: '',
      language: ''
    };
    this.addStock = this.addStock.bind(this);
    this.removeStock = this.removeStock.bind(this);
    this.showError = this.showError.bind(this);
  }

  getChildContext() {
    return { b };
  }

  UNSAFE_componentWillMount() {
    const { getMoreInfo, gaSend, user } = this.props;
    gaSend({
      category: 'Supplier',
      action: 'price_create_open',
      label: user.get('email')
    });
    getMoreInfo();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { fields } = nextProps;
    if (!this.state.language) {
      this.setState({ language: fields.get('language') });
    }
  }

  getProperty(field) {
    return this.state[field] ? this.state[field] : '';
  }

  getErrorInfo(fieldName) {
    return (
      this.props.errors &&
      this.props.errors.find((i) => i.get('type') === fieldName)
    );
  }

  isValidFieldName() {
    return this.state.name.length < 1;
  }

  isPublished() {
    const lang = this.getProperty('language');
    const moreInfo = this.props.moreInfo
      .get('isPublished')
      .find((pub) => pub.get('language') === lang);
    return !!(moreInfo && moreInfo.get('publishDate'));
  }

  asterisk() {
    // eslint-disable-line
    return <span style={{ color: 'red' }}>*</span>;
  }

  inputProps(field) {
    return {
      value: this.state[field],
      onChange: (e) => {
        if (this.getErrorInfo(field)) {
          this.props.removeErrorByField(field);
        }
        if (field === 'name') {
          return this.setState({
            [field]: e.target.value.replace(/^\s+|\s(?=\s)/g, '')
          });
        }
        return this.setState({ [field]: e.target.value });
      }
    };
  }

  updateField(field, value) {
    this.setState({ [field]: value });
  }

  addStock() {
    const stateStocks = this.state.stocks;
    this.setState({ stocks: stateStocks.push(null) });
  }

  removeStock(n) {
    const stateStocks = this.state.stocks.filter((i, index) => index !== n);
    this.setState({ stocks: stateStocks });
  }

  showError(type) {
    const { t, errors } = this.props;
    if (errors && errors.size > 0) {
      const error = errors.find((i) => i.get('type') === type);
      return error ? (
        <HelpBlock className="pull-left">{t(error.get('text'))}</HelpBlock>
      ) : null;
    }
    return null;
  }

  render() {
    const {
      t,
      captionKey,
      hideModalDialog,
      createPrice,
      getAllPrices,
      moreInfo,
      clearForm,
      gaPageView,
      gaSend,
      currencyList,
      user,
      removeErrorByField,
      errors
    } = this.props;

    const languages = moreInfo.get('languages').map((language) => ({
      value: language.get('code'),
      label: `${language.get('view')} ${
        moreInfo
          .get('isPublished')
          .find((pub) => pub.get('language') === language.get('code'))
          .get('publishDate')
          ? ''
          : `(${t('not published')})`
      }`
    }));

    const stocks = moreInfo
      .get('stocks')
      .filter(
        (stock) =>
          stock.get('language') === this.state.language ||
          stock.get('language') === 'all'
      )
      .map((stock) => ({
        value: stock.get('id'),
        label: stock.get('stockName') && stock.get('stockName'),
        disabled:
          this.state.stocks && this.state.stocks.includes(stock.get('id'))
      }))
      .filter((stock) => stock.value !== '')
      .toJS();
    stocks.unshift({ value: null, label: t('not selected') });

    const currency = currencyList
      .map((cur) => ({ value: cur.get('name'), label: cur.get('name') }))
      .toJS();

    const popover = (text) => (
      <Popover id="popover-is-price-list-published">{text}</Popover>
    );

    return (
      <ModalContainer mods={{ theme: 'default' }}>
        <Modal
          id="createPriceList"
          className="modal-dialog-wrap"
          onHide={() => hideModalDialog()}
          show>
          <Modal.Header>
            <Modal.Title>
              {t(captionKey)}
              <span
                className="modal-header-cross pull-right"
                onClick={() => hideModalDialog()}
              />
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Row style={{ marginBottom: 5 }}>
                <Col md={7} style={{ paddingRight: 20 }}>
                  <FormGroup
                    controlId="name"
                    validationState={
                      (this.isValidFieldName() && !this.state.pristine) ||
                      this.showError('name')
                        ? 'error'
                        : null
                    }
                    style={{ overflow: 'hidden' }}>
                    <ControlLabel>
                      {`${t('Name')} `}
                      {this.asterisk()}
                    </ControlLabel>
                    <h6 className="pull-right" style={{ marginTop: 5 }}>
                      <small>
                        {this.state.name.length}
                        /60
                      </small>
                    </h6>
                    <FormControl
                      type="text"
                      {...this.inputProps('name')}
                      className={testClass('modal-input', 'name')}
                      maxLength="60"
                      onKeyDown={(e) => {
                        if (this.state.pristine) {
                          this.setState({ pristine: false });
                        }
                        if (e.keyCode === 13) {
                          e.preventDefault();
                        }
                      }}
                    />
                    {this.isValidFieldName() && !this.state.pristine && (
                      <HelpBlock className="pull-left">
                        {t('Required fields empty')}
                      </HelpBlock>
                    )}
                    {this.showError('name')}
                  </FormGroup>
                </Col>
                <Col md={5} style={{ paddingLeft: 5 }}>
                  <FormGroup controlId="language">
                    <ControlLabel style={{ marginBottom: 7 }}>
                      {`${t('Products language')} `}
                      {this.asterisk()}
                      &nbsp;
                      <Helper text="create_price_list_language_help" />
                    </ControlLabel>
                    <Select
                      id="language-select"
                      value={this.state.language}
                      onChange={(e) => {
                        if (this.getErrorInfo('language')) {
                          removeErrorByField('language');
                        }
                        this.updateField('language', e.value);
                      }}
                      options={languages.toJS()}
                      placeholder={t('not selected')}
                      clearable={false}
                      searchable={false}
                      style={{ height: 34 }}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row style={{ marginBottom: 5 }}>
                <Col md={12} style={{ paddingRight: 0 }}>
                  <FormGroup controlId="stocks">
                    <ControlLabel>
                      {`${t('Stock')} `}
                      <Helper text="create_price_list_stock_help" />
                    </ControlLabel>
                    {this.state.stocks &&
                      this.state.stocks.map((stockId, n) => (
                        <div style={{ marginBottom: 10 }}>
                          <div
                            className="price-stock-select-modal"
                            style={{ width: '95%' }}>
                            <Select
                              id={`stock-select${n}`}
                              value={stockId || null}
                              onChange={(e) => {
                                const stateStocks = this.state.stocks;
                                this.setState({
                                  stocks: stateStocks.setIn([n], e.value)
                                });
                              }}
                              options={stocks}
                              placeholder={t('not selected')}
                              clearable={false}
                              searchable={false}
                            />
                          </div>
                          {n === 0 && (
                            <img
                              role="presentation"
                              onClick={this.addStock}
                              className="price-stocks-sign"
                              src="/img/add.svg"
                              alt=""
                            />
                          )}
                          {n > 0 && (
                            <img
                              role="presentation"
                              onClick={() => this.removeStock(n)}
                              className="price-stocks-sign"
                              src="/img/remove.svg"
                              alt=""
                            />
                          )}
                        </div>
                      ))}
                    {(!this.state.stocks || this.state.stocks.size === 0) && (
                      <div style={{ marginBottom: 10 }}>
                        <div
                          className="price-stock-select-modal"
                          style={{ width: '95%' }}>
                          <Select
                            id="stock-select0"
                            value={null}
                            onChange={(e) => {
                              const stateStocks = this.state.stocks;
                              this.setState({
                                stocks: stateStocks.setIn([0], e.value)
                              });
                            }}
                            options={stocks}
                            placeholder={t('not selected')}
                            clearable={false}
                            searchable={false}
                          />
                        </div>
                        <img
                          role="presentation"
                          onClick={this.addStock}
                          className="price-stocks-sign"
                          src="/img/add.svg"
                          alt=""
                        />
                      </div>
                    )}
                  </FormGroup>
                </Col>
              </Row>
              <Row style={{ marginBottom: 5 }}>
                <Col md={3}>
                  <FormGroup controlId="currency">
                    <ControlLabel>{t('Currency')}</ControlLabel>
                    <Select
                      id="currency-select"
                      value={this.state.currency}
                      onChange={(e) => {
                        if (this.getErrorInfo('currency')) {
                          removeErrorByField('currency');
                        }
                        this.updateField('currency', e.value);
                      }}
                      options={currency}
                      placeholder={t('not selected')}
                      clearable={false}
                      searchable={false}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <FormGroup controlId="description">
                <ControlLabel>{t('Description')}</ControlLabel>
                <FormControl
                  componentClass="textarea"
                  {...this.inputProps('description')}
                  maxLength="1600"
                />
                <h6 className="pull-right">
                  <small>
                    {this.state.description.length}
                    /1600
                  </small>
                </h6>
              </FormGroup>
            </Form>
            {this.asterisk()}
            {` ${t('Required fields')}`}
          </Modal.Body>
          <Modal.Footer>
            <Button
              id="cancel"
              className={`pull-left ${testClass('modal-btn', 'create')}`}
              onClick={() => {
                gaPageView('price_create_cancel');
                gaSend({
                  category: 'Supplier',
                  action: 'price_create_cancel',
                  label: user.get('email')
                });
                hideModalDialog();
                clearForm();
              }}>
              {t('Cancel')}
            </Button>
            {this.isPublished() ? (
              <Button
                id="saveCharacteristicButton"
                bsStyle="primary"
                className={testClass('modal-btn', 'create')}
                disabled={
                  this.isValidFieldName() ||
                  !this.isPublished() ||
                  !this.state.language
                }
                onClick={async () => {
                  await createPrice(this.state);
                  if (!errors || errors.size === 0) {
                    getAllPrices();
                    hideModalDialog();
                    clearForm();
                  }
                }}>
                {t('Create price')}
              </Button>
            ) : (
              <OverlayTrigger
                trigger={['focus', 'hover']}
                overlay={popover(t('please_publish_text'))}>
                <div>
                  <a
                    id="savebutton"
                    className={`btn btn-primary ${testClass(
                      'modal-btn',
                      'create'
                    )}`}
                    disabled>
                    {t('Create price')}
                  </a>
                </div>
              </OverlayTrigger>
            )}
          </Modal.Footer>
        </Modal>
      </ModalContainer>
    );
  }
}

ModalAddPrice.propTypes = {
  t: PropTypes.func.isRequired
};

ModalAddPrice.childContextTypes = {
  b: PropTypes.func
};

export default translate(['ui'], { wait: true })(ModalAddPrice);
