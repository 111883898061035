import React, { useEffect, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import FullView from './full';
import './style.css';
import ShortView from './short';
import { getActiveIndex, isFullMode, writeCurrentModeToLS } from './utils';
import { FULL } from './constants';

// EXAMPLE
//
// const navigationsList = [
//   {
//     name: 'Мессенджер',
//     icon: 'messenger',
//     helper: 'Подсказка',
//     isShowBadge: false,
//     amountNotify: 0,
//     to: '/',
//     nestedList: []
//   },
//   {
//     name: 'Мой профиль',
//     icon: 'profile',
//     helper: 'Подсказка',
//     isShowBadge: false,
//     amountNotify: 0,
//     to: '/profile/edit',
//     nestedList: [
//       {
//         name: 'Хранилище файлов',
//         helper: 'Подсказка',
//         isShowBadge: false,
//         amountNotify: 0,
//         to: '/file-storage/chat'
//       }
//     ]
//   },
//   {
//     name: 'Моя организация',
//     icon: 'company',
//     helper: 'Подсказка',
//     isShowBadge: false,
//     amountNotify: 0,
//     to: '/company/edit',
//     nestedList: [
//       {
//         name: 'Сотрудники',
//         helper: 'Подсказка',
//         isShowBadge: false,
//         amountNotify: 0,
//         to: '/employees/list'
//       },
//       {
//         name: 'Покупатели',
//         helper: 'menu_customers_helper',
//         isShowBadge: false,
//         amountNotify: 0,
//         to: '/customers/list'
//       },
//       {
//         name: 'Поставщики',
//         helper: 'menu_supplier_helper',
//         isShowBadge: false,
//         amountNotify: 0,
//         to: '/suppliers/list'
//       }
//     ]
//   },
//   {
//     name: 'Заявка на закупку',
//     icon: 'request',
//     helper: 'Подсказка',
//     isShowBadge: true,
//     amountNotify: 120,
//     to: '/requests/all',
//     nestedList: []
//   },
//   {
//     name: 'Перейти в Goodwix',
//     icon: 'goodwix',
//     helper: 'Подсказка',
//     isShowBadge: false,
//     amountNotify: 0,
//     to: process.env.UNICAT_DEFAULT_SERVICE,
//     target: '_blank',
//     nestedList: []
//   }
// ];
//
// const footerMenu = [
//   {
//     type: 'button',
//     icon: 'exit',
//     name: 'Logout',
//     onClick: () => console.log('1')
//   }
// ];
//
// const directories = {
//   company: 'company/edit',
//   billing: 'billing',
//   employees: 'employees/list',
//   customers: 'customers/list',
//   'file-storage': 'file-storage/chat',
//   suppliers: 'suppliers/list',
//   requests: 'requests/all',
//   profile: 'profile/edit'
// };

const SidebarNavMenu = ({
  lsKey,
  mode = FULL,
  toggleMode,
  navigationsList = []
}) => {
  useEffect(() => {
    writeCurrentModeToLS(lsKey, mode);
  }, [mode]);

  const { pathname } = useLocation();

  const activeIndex = useMemo(
    () => getActiveIndex(navigationsList, pathname),
    [navigationsList, pathname]
  );

  if (isFullMode(mode)) {
    return (
      <FullView
        navigationsList={navigationsList}
        activeIndex={activeIndex}
        toggleMode={toggleMode}
      />
    );
  }

  return (
    <ShortView
      navigationsList={navigationsList}
      activeIndex={activeIndex}
      toggleMode={toggleMode}
    />
  );
};

export default SidebarNavMenu;
