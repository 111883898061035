import React from 'react';
import { connect, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import { LineBadge } from 'react-components/badges';
import { useActions, useNavigate } from '@hooks';
import { isEmpty, isNil } from 'ramda';
import {
  formatRequestNumber,
  getFullName,
  isNilOrEmpty
} from '../../../../utils/utils';
import * as getters from '../getters';
import Arrow from '../../../Buttons/Arrow';
import * as modalActions from '../../../../action-creators/modal';
import * as messageActions from '../../../../action-creators/message';
import * as requestsActions from '../../../../action-creators/purchaseRequests';
import * as storage from '../storage';
import './styles.css';
import TrashIcon from '../../../../icons/trash';
import UnpublishIcon from '../../../../icons/unpublish';
import EditIcon from '../../../../icons/edit';
import ResponsesTable from './ResponsesTable';
import { formatDate } from '../../../../utils/date';
import { countNewResponses, getResponses } from './utils';
import ArchiveIcon from '../../../../icons/archive';
import { useTranslate } from '../../../../TranslateProvider';
import ExtraButton from '../../common/ExtraButton';
import { haveDeletePermission, haveUpdatePermission } from '../../utils';
import { PurchaseRequestStatuses } from '../../entities/PurchaseRequestStatuses';
import SuppliersActivities from '../../../SuppliersActivities';
import ApprovalLog from '../../ApprovalLog';
import {
  CAN_BE_ARCHIVED_STATUSES,
  CONFIRMED_STATUSES,
  executorActionTypes,
  purchaseRequestTypes
} from '../../domain/entities';
import * as storeGetters from '../../../../storeGetters';
import { ResponseEndDate } from '../../Request/components/RespondEndDate';
import { actions } from '../reducer';

const handleClickArrow = (currentId, newId, setOpenedId) => () =>
  setOpenedId(currentId === newId ? -1 : newId);

const RequestCard = ({
  request,
  openedId,
  setOpenedId,
  size,
  limit = 20,
  offset = 0,
  getMyRequests,
  setSuccessMessage,
  showModal,
  unpublishRequest,
  deleteRequest,
  toggleArchiveRequest,
  permissions
}) => {
  const t = useTranslate();
  const navigate = useNavigate();

  const user = useSelector(storeGetters.getCurrentUser).toJS();

  const { updateRequest } = useActions(actions);
  const isRequestPublished = () =>
    ![PurchaseRequestStatuses.draft, PurchaseRequestStatuses.archived].includes(
      request.status
    );

  const isCanBeArchived = () =>
    CAN_BE_ARCHIVED_STATUSES.includes(request.status);

  const isRequestArchived = () => request.isArchived;

  function checkShowEnrichSuppliersActivities() {
    if (
      request.type === purchaseRequestTypes.withConfirmation &&
      request.authorId === user.id
    ) {
      return false;
    }

    if (request.executorType === executorActionTypes.publication) {
      return Boolean(request.lastPublishedDate);
    }

    return !request.executorType;
  }

  const isShowApprovalLog = !isNilOrEmpty(request?.executors);

  const isShowResponses = !isNil(request.responses);

  const isEndResponseDate = () =>
    request.responseEndDate === null
      ? false
      : new Date() > new Date(request.responseEndDate);

  const isCanUnpublish = () =>
    request.status === PurchaseRequestStatuses.receivingResponses;

  const getResponsedCount = () => request.suppliers?.length;

  const isUnauthorizedPerson = () =>
    request.type === purchaseRequestTypes.withConfirmation &&
    request.status === PurchaseRequestStatuses.draft &&
    request.responsibleUser !== user.id;

  const isEditRequestDisable = () =>
    isRequestPublished() ||
    getResponsedCount() > 0 ||
    isRequestArchived() ||
    isUnauthorizedPerson();

  const calcNextOffset = () => {
    if (offset === Number(size)) {
      return offset;
    }
    return offset === 0 ? 0 : offset - 1;
  };

  const menuOptions = [
    {
      icon: <EditIcon />,
      name: t('Edit'),
      onClick: () => {
        const baseUrl = `/requests/my/${request.id}`;

        if (
          request.type === purchaseRequestTypes.withConfirmation &&
          !request.lastPublishedDate &&
          Boolean(request.executorType)
        ) {
          navigate(`${baseUrl}/viewConfirmation`);
          return;
        }

        navigate(`${baseUrl}/edit`);
      },
      hide: isEditRequestDisable()
    },
    {
      icon: <UnpublishIcon />,
      name: t('Unpublish'),
      onClick: () =>
        showModal('SIMPLE_SUBMIT', {
          title: `${t('Unpublish')}`,
          text: `${t('Unpublish_text')}`,
          textBtnConfirm: 'Confirm',
          submitAction: async () => {
            unpublishRequest(request.id).then(() => {
              setSuccessMessage({ key: 'Request unpublished success' });
            });
          }
        }),
      hide: !isCanUnpublish()
    },
    {
      icon: <ArchiveIcon />,
      name: t(isRequestArchived() ? 'unarchive' : 'archive'),
      onClick: () => toggleArchiveRequest(request.id, !isRequestArchived()),
      hide: !isCanBeArchived()
    },
    {
      icon: <TrashIcon />,
      name: t('Delete'),
      onClick: async () =>
        showModal('SIMPLE_SUBMIT', {
          title: `delete_request_title`,
          text: `delete_request_text`,
          textBtnConfirm: 'Confirm',
          submitAction: async () => {
            const isArchive = isRequestArchived();
            await deleteRequest(request.id);
            await getMyRequests({ limit, offset: calcNextOffset(), isArchive });
          }
        }),
      hide: isRequestPublished(request) || !haveDeletePermission(permissions)
    }
  ];

  const viewResponseEndDate = [
    PurchaseRequestStatuses.receivingResponses,
    PurchaseRequestStatuses.receptionCompleted
  ];

  const filteredOptions = menuOptions.filter((option) => !option.hide);

  const isShowExtraOptions =
    !isEmpty(filteredOptions) && haveUpdatePermission(permissions);

  const isArrowOpened = openedId === request.id;

  const hasNewResponses = () => countNewResponses(getResponses(request)) !== 0;

  const navigateToRequestPage = () => {
    const baseUrl = `/requests/my/${request.id}`;

    if (
      request.type === purchaseRequestTypes.withConfirmation &&
      !request.lastPublishedDate &&
      Boolean(request.executorType)
    ) {
      navigate(`${baseUrl}/viewConfirmation`);
      return;
    }

    if (
      [
        executorActionTypes.agreement,
        executorActionTypes.introduction
      ].includes(request.executorType)
    ) {
      navigate(`${baseUrl}/viewConfirmation`);
      return;
    }

    if (request.authorId === user.id) {
      const url = CONFIRMED_STATUSES.includes(request.status)
        ? 'view'
        : 'viewConfirmation';
      navigate(`${baseUrl}/${url}`);
      return;
    }

    navigate(`${baseUrl}/view`);
  };

  return (
    <div className="request-card-wrapper">
      <div className="request-card-row">
        {hasNewResponses() && (
          <div style={{ height: 85, position: 'absolute', left: 0 }}>
            <LineBadge />
          </div>
        )}
        <div
          className="request-card-arrow request-card-flex-arrow"
          onClick={handleClickArrow(openedId, request.id, setOpenedId)}>
          <Arrow
            isOpened={isArrowOpened}
            rotateDegree={0}
            initialDegree={180}
          />
        </div>
        <div className="request-card-col request-card-flex-title">
          <div className="request-card-number">
            {`№ ${formatRequestNumber(request.id)} - ${
              request.number || formatRequestNumber(request.id)
            }`}
          </div>
          <div
            className="request-card-title"
            style={{ cursor: 'pointer' }}
            onClick={navigateToRequestPage}>
            {request.title}
          </div>
        </div>
        <div className="request-card-col request-card-right-col request-card-flex-info">
          <div>
            {viewResponseEndDate.includes(request.status) ? (
              <>
                <span className="request-card-gray">
                  {`${t('PRresponse_end_date')}: `}
                </span>
                {formatDate(request.lastPublishedDate, 'dayAndMonth')} -{' '}
                <ResponseEndDate
                  request={request}
                  updateRequest={updateRequest}
                />
              </>
            ) : (
              <>
                <span className="request-card-gray">{`${t(
                  'Date of update'
                )}: `}</span>
                <span>{formatDate(request.updatedAt)}</span>
              </>
            )}
          </div>
          {request?.responsibleUserInfo ? (
            <div>
              <span className="request-card-gray">{`${t(
                'Responsible'
              )}: `}</span>
              <span>{getFullName(request.responsibleUserInfo)}</span>
            </div>
          ) : (
            <div>
              <span className="request-card-gray">{`${t(
                'purchaseRequest.card.approvalLog.author'
              )}: `}</span>
              <span>{getFullName(request.authorInfo)}</span>
            </div>
          )}
        </div>
        <div className="request-card-col request-card-flex-response">
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <SuppliersActivities
              requestId={request.id}
              requestStatus={request.status}
              responsesCount={getResponses(request).length}
              newResponsesCount={countNewResponses(getResponses(request))}
              isCustomerView={checkShowEnrichSuppliersActivities()}
            />
          </div>
        </div>
        <div className="request-card-flex-dots">
          {isShowExtraOptions && <ExtraButton options={filteredOptions} />}
        </div>
      </div>

      {isArrowOpened && isShowApprovalLog && (
        <ApprovalLog
          isCollapsed={false}
          className="purchase-grid-approval-log"
          request={request}
          isShowDisclaimer={false}
        />
      )}

      {isShowResponses && (
        <ResponsesTable
          request={request}
          isOpened={isArrowOpened}
          responses={getResponses(request)}
        />
      )}
    </div>
  );
};

export default connect(
  (state) => ({
    size: getters.getSize(state),
    limit: getters.getLimit(state),
    offset: getters.getOffset(state)
  }),
  (dispatch) =>
    bindActionCreators(
      {
        showModal: modalActions.showModal,
        setSuccessMessage: messageActions.setSuccessMessage,
        getMyRequests: storage.getMyRequests,
        publishRequest: storage.publishRequest,
        unpublishRequest: storage.unpublishRequest,
        toggleArchiveRequest: storage.toggleArchiveRequest,
        deleteRequest: requestsActions.deleteRequest
      },
      dispatch
    )
)(RequestCard);
