import { isNil, isEmpty } from 'ramda';
import { allowedResponseStatuses } from '../../../PurchaseRequests/domain/entities';

export const getStatus = (request) => request.status;

export const getStatusTKey = (request) =>
  !isNil(getStatus(request))
    ? `purchaseRequest.card.statuses.${getStatus(request)}`
    : '';
export const convertStatus = (request) => {
  if (request.responded) {
    return 'responded';
  }
  if (request.viewed) {
    return 'viewed';
  }
  return request.status;
};

export const isResponded = (request) => request.responded;

export function getLocation(request) {
  let result = `${request.country}, ${request.region}`;

  if (!isNil(request.city) && !isEmpty(request.city)) {
    result += `, г. ${request.city}`;
  }

  return result;
}
export const isFromThatCompany = (request, company) =>
  request.companyId === company.id;

export const isResponseAllowed = (request, userCompanyId) =>
  userCompanyId !== request.companyId &&
  allowedResponseStatuses.includes(request.status);
