import React from 'react';

const Arrow = ({
  onClick = () => {},
  isOpened,
  rotateDegree = -90,
  initialDegree = 0
}) => (
  <img
    onClick={onClick}
    src="/img/select-arrow-down.svg"
    alt=""
    style={{
      transform: `rotate(${isOpened ? initialDegree : rotateDegree}deg)`
    }}
  />
);

export default Arrow;
