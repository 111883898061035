import { Map } from 'immutable';

const startCreation = (state) => state.setIn(['draft', 'name'], '');

const updateField = (state, field, value) =>
  state.setIn(['draft', field], value);

const newReducer = (state = Map(), action) => {
  switch (action.type) {
    case 'CATEGORY_NEW_START':
      return startCreation(state);
    case 'CATEGORY_NEW_UPDATE_FIELD':
      return updateField(state, action.field, action.value);
    default:
      return state;
  }
};

export default newReducer;
