import React, { Component } from 'react';
import PropTypes from 'prop-types';
import block from 'bem-cn-lite';
import { translate } from 'react-i18next';
import { set, lensProp, compose } from 'ramda';
import { Modal, Col, Row, Form, Button } from 'react-bootstrap';
import '../styles/modal_theme_default.styl';
import Checkbox from '../../Checkbox';
import { getPublishedUnitName, trimmer } from '../../../utils';

import { ModalContainer } from '../elements';

const b = block('modal-container');

class ModalExportPrice extends Component {
  constructor(props) {
    super(props);
    this.state = {
      list: {
        sku: {
          title: 'SKU',
          value: false
        },
        productName: {
          title: 'Product name',
          value: false
        },
        url: {
          title: 'Product URL',
          value: false
        },
        categoryName: {
          title: 'Category',
          value: false
        },
        brand: {
          title: 'Brand',
          value: false
        },
        model: {
          title: 'Model',
          value: false
        },
        dimensions: {
          title: 'Dimensions',
          value: false
        },
        available: {
          title: 'Available',
          value: false
        },
        weightBrutto: {
          title: 'Brutto',
          value: false
        },
        weightNetto: {
          title: 'Netto',
          value: false
        },
        cost: {
          title: 'Base\n price',
          value: false
        },
        vat: {
          title: 'VAT',
          value: false
        },
        discount: {
          title: 'Discount',
          value: false
        }
      },
      purchasePriceCategories: {}
    };
    this.setToList = this.setToList.bind(this);
    this.getUnit = this.getUnit.bind(this);
  }

  getChildContext() {
    return { b };
  }

  getUnit(unit, showAll) {
    const { units, unitCodes, language } = this.props;
    if (!unit) {
      return '';
    }
    return getPublishedUnitName(unit, units, unitCodes, language, showAll);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const purchasePriceCategories = nextProps.purchasePriceCategories
      .toJS()
      .reduce((acc, category) => {
        const { id, name, type, from, to, unit } = category;
        const unitName = this.getUnit(unit);
        const copyAcc = { ...acc, [id]: { value: false } };
        if (type === 1) {
          copyAcc[id].title = name;
        } else if (type === 2) {
          copyAcc[id].title = `${from} - ${to || '∞'} ${unitName}`;
        } else if (type === 3) {
          copyAcc[id].title = name;
        }
        return copyAcc;
      }, {});

    this.setState({ purchasePriceCategories });
  }

  getFromList(key) {
    return this.state.list[key];
  }

  getFromPurchasePriceCategories(key) {
    return this.state.purchasePriceCategories[key];
  }

  getListKeys() {
    return Object.keys(this.state.list);
  }

  getPurchasePriceCategoriesKeys() {
    return Object.keys(this.state.purchasePriceCategories);
  }

  setToList(key, value) {
    const lensList = lensProp('list');
    const lensElem = lensProp(key);
    const lensElemValue = lensProp('value');

    const lensToElem = compose(lensList, lensElem, lensElemValue);
    const newState = set(lensToElem, value, this.state);
    this.setState(newState);
  }

  setToPurchasePriceCategories(key, value) {
    return new Promise((resolve) => {
      const lensList = lensProp('purchasePriceCategories');
      const lensElem = lensProp(key);
      const lensElemValue = lensProp('value');

      const lensToElem = compose(lensList, lensElem, lensElemValue);
      const newState = set(lensToElem, value, this.state);
      this.setState(newState, () => resolve());
    });
  }

  getListToServer() {
    return this.getListKeys().filter(
      (key) => this.getFromList(key).value === true
    );
  }

  getPurchasePriceCategoriesToServer() {
    return this.getPurchasePriceCategoriesKeys().filter(
      (key) => this.getFromPurchasePriceCategories(key).value === true
    );
  }

  isValid() {
    const array = this.getListToServer().concat(
      this.getPurchasePriceCategoriesToServer()
    );
    return array.length > 0;
  }

  render() {
    const { t, captionKey, hideModalDialog, submit, priceId } = this.props;

    return (
      <ModalContainer mods={{ theme: 'default' }}>
        <Modal id="createStock" onHide={() => hideModalDialog()} show>
          <Modal.Header>
            <Modal.Title>
              {t(captionKey || 'Custome export price')}
              <span
                className="modal-header-cross pull-right"
                onClick={() => hideModalDialog()}
              />
            </Modal.Title>
          </Modal.Header>
          <Modal.Body style={{ overflow: 'hidden' }}>
            <Col xs={12}>
              <Form horizontal onSubmit={(e) => e.preventDefault()}>
                <Row>
                  <div className="two-columns" style={{ lineHeight: '1.5' }}>
                    {this.getListKeys().map((key) => (
                      <div>
                        <Checkbox
                          set={this.getFromList(key).value}
                          onClick={() =>
                            this.setToList(key, !this.getFromList(key).value)
                          }
                        />
                        <span
                          className="modal-checkbox-label"
                          title={t(this.getFromList(key).title)}>
                          {trimmer(t(this.getFromList(key).title), 20)}
                        </span>
                      </div>
                    ))}
                    {this.getPurchasePriceCategoriesKeys().map((key) => (
                      <div>
                        <Checkbox
                          set={this.getFromPurchasePriceCategories(key).value}
                          onClick={() =>
                            this.setToPurchasePriceCategories(
                              key,
                              !this.getFromPurchasePriceCategories(key).value
                            )
                          } // eslint-disable-line
                        />
                        <span
                          title={t(
                            this.getFromPurchasePriceCategories(key).title
                          )}
                          className="modal-checkbox-label">
                          {trimmer(
                            t(this.getFromPurchasePriceCategories(key).title)
                          )}
                        </span>
                      </div>
                    ))}
                  </div>
                </Row>
              </Form>
            </Col>
          </Modal.Body>
          <Modal.Footer>
            <Button
              id="cancel"
              className="pull-left"
              onClick={() => {
                hideModalDialog();
              }}>
              {t('Cancel')}
            </Button>
            <Button
              id="saveCharacteristicButton"
              bsStyle="primary"
              disabled={!this.isValid()}
              onClick={() =>
                submit(
                  priceId,
                  this.getListToServer(),
                  this.getPurchasePriceCategoriesToServer()
                ).then(hideModalDialog)
              } // eslint-disable-line
            >
              {t('Export')}
            </Button>
          </Modal.Footer>
        </Modal>
      </ModalContainer>
    );
  }
}

ModalExportPrice.propTypes = {
  t: PropTypes.func.isRequired
};

ModalExportPrice.childContextTypes = {
  b: PropTypes.func
};

export default translate(['ui'], { wait: true })(ModalExportPrice);
