import React from 'react';
import { isEmpty } from 'ramda';
import { useSelector } from 'react-redux';
import block from 'bem-cn-lite';
import * as getters from '../../../../../../getters';
import { useTranslate } from '../../../../../../../TranslateProvider';
import DropZone from '../DropZone';
import { TASK } from '../../../../constants';
import DNDTask from './DNDTask';
import '../../style.css';

const b = block('task-list-column');

function translate(t, tkey) {
  return t(`taskManager.listPage.column.${tkey}`);
}

function showLoadChunkBtn(amountLeft = 0) {
  return amountLeft > 0;
}

function showCollapseBtn({ tasks = [], amountLeft = 0, limit = 10 }) {
  return tasks.length > limit && amountLeft === 0;
}

const LoadChunkButtons = ({
  t,
  tasks,
  section,
  amountLeft,
  limit,
  onLoad,
  onCollapse
}) => {
  if (showCollapseBtn({ tasks, amountLeft, limit })) {
    return (
      <div className={b('link')} onClick={() => onCollapse(section)}>
        {translate(t, 'collapse')}
      </div>
    );
  }

  if (showLoadChunkBtn(amountLeft)) {
    return (
      <div className={b('link')} onClick={() => onLoad({ limit, section })}>
        {translate(t, 'showMore')}
      </div>
    );
  }

  return null;
};

const ACCEPT = [TASK];
const TasksColumn = ({
  title = '',
  status = '',
  tasks,
  handleDrop,
  handleDropzone,
  moveTask,
  loadChunkSection,
  collapseChunkSection,
  extraOptions,
  handler,
  onClick,
  idxOffset = 0
}) => {
  const t = useTranslate();
  const sectionInfo = useSelector(getters.getTasksSection(status));

  return (
    <div className={b({ status })}>
      <div className={b('title')}>
        {t(`taskManager.listPage.sections.${title}`)}
      </div>
      {isEmpty(tasks) ? (
        <DropZone
          data={{ path: `${status}-${idxOffset}` }}
          onDrop={handleDropzone}
          accept={ACCEPT}
        />
      ) : (
        <>
          {tasks.map((task, index) => (
            <DNDTask
              key={task.id}
              data={task}
              path={`${status}-${idxOffset + index}`}
              index={idxOffset + index}
              onDrop={handleDrop}
              moveTask={moveTask}
              onClick={onClick}
              extraOptions={extraOptions}
              handler={handler}
            />
          ))}
          <LoadChunkButtons
            t={t}
            tasks={tasks}
            section={status}
            amountLeft={sectionInfo.amountLeft}
            limit={sectionInfo.limit}
            onLoad={loadChunkSection}
            onCollapse={collapseChunkSection}
          />
        </>
      )}
    </div>
  );
};

export default TasksColumn;
