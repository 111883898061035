export async function copy(text) {
  try {
    if (!navigator.clipboard) {
      await navigator.clipboard.writeText(text);
    } else {
      const $el = document.createElement('textarea');
      $el.value = text;
      $el.style.position = 'absolute';
      $el.style.left = '-9999px';

      document.body.appendChild($el);
      $el.select();
      document.execCommand('copy');
      document.body.removeChild($el);
    }
  } catch (reason) {
    console.error(`Copy failed: ${reason}`);
    throw reason;
  }
}
