export const getAllResponses = (state) => state.get('myResponses').responses;
export const getSize = (state) => state.get('myResponses').size;
export const getCurrentResponse = (state) => state.get('myResponses').response;
export const getSelectedCategoriesId = (state) =>
  state.get('myResponses').selectedCategoriesId;
export const getCurrencyList = (state) => state.get('currencyList').toJS();
export const getCountries = (state) => state.get('myResponses').countries;
export const getRegions = (state) => state.get('myResponses').regions;
export const getRegionsCount = (state) => state.get('myResponses').regionsCount;
export const getCities = (state) => state.get('myResponses').cities;
export const getLimit = (state) => state.get('myResponses').limit;
export const getOffset = (state) => state.get('myResponses').offset;
export const getSearch = (state) => state.get('myResponses').search;
export const getOrder = (state) => state.get('myResponses').orderBy;
export const getDirection = (state) => state.get('myResponses').direction;
export const getStatus = (state) => state.get('myResponses').selectedStatus;
export const getFilters = (state) => state.get('myResponses').filters;
export const getFilterApplied = (state) =>
  state.get('myResponses').filterApplied;
export const getAllBuyersOfMyResponses = (state) =>
  state.get('myResponses').buyers;
export const getIsUploaded = (state) => state.get('myResponses').isUploaded;
