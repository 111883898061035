import fetch from 'isomorphic-fetch';
import { loadTable, sortBy } from '../table';
import { setMessage, setSuccessMessage } from '../message';

export const setCountNewAvailable = (value) => ({
  type: 'CATALOG_SET_COUNT_NEW_AVAILABLE',
  value
});

export const getCountNewAvailable = () => (dispatch) =>
  fetch('/api/share/count-new', { credentials: 'include' })
    .then((response) => response.json())
    .then((json) => dispatch(setCountNewAvailable(json.data)));

export const getAvailableCatalogs =
  (hideMessage = true) =>
  (dispatch) => {
    fetch('/api/share', { credentials: 'include' })
      .then((response) => response.json())
      .then((json) => {
        if (hideMessage) {
          dispatch(setMessage(true));
        }
        dispatch(getCountNewAvailable());
        dispatch(loadTable(json.data, 'availableCatalogs'));
        dispatch(sortBy('insertDate', 'availableCatalogs'));
      });
  };

export const setAvailableCatalogStatus =
  (shareId, status, hideMessage = true) =>
  (dispatch) =>
    fetch(`/api/share/${shareId}/status`, {
      credentials: 'include',
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ data: { attributes: { status } } })
    })
      .then(() => {
        dispatch(getAvailableCatalogs(hideMessage));
      })
      .catch((e) => console.error('UPDATE STATUS FAILED', e));

export const keepCatalogToItself = (shareId, name) => (dispatch) =>
  fetch(`/api/json/import/shared/${shareId}`, {
    credentials: 'include',
    method: 'POST',
    headers: { 'Content-Type': 'application/json' }
  })
    .then((response) => response.json())
    .then(() => {
      dispatch(setAvailableCatalogStatus(shareId, 'saved', false));
      dispatch(
        setSuccessMessage({
          key: 'Catalog saved',
          params: { name },
          linkUrl: '/catalogs',
          linkKey: 'My catalogues'
        })
      );
    })
    .catch((e) => console.error('KEEP CATALOG FAILED', e));
