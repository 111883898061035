import React, { useCallback } from 'react';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import { Flex } from '../../../styledComponents/atoms';

import MessengerWidgetOpenNewWindow from './assets/icons/MessengerWidgetOpenNewWindow';
import MessengerWidgetCross from './assets/icons/MessengerWidgetCross';
import MessengerWidgetResizer from './assets/icons/MessengerWidgetResizer';
import { toggleChatWidget } from '../../ChatWidget/useCases';
import { redirectToMessenger } from './lib';

const MessengerHeader = ({ maybeChatId }) => {
  const dispatch = useDispatch();
  const hideWidget = useCallback(
    () => dispatch(toggleChatWidget()),
    [dispatch]
  );

  return (
    <Container>
      <MessengerLogo>istock.link</MessengerLogo>
      <MessengerHeaderButtonsCont>
        <MessengerHeaderBtn onClick={redirectToMessenger(maybeChatId)}>
          <MessengerWidgetOpenNewWindow />
        </MessengerHeaderBtn>
        <MessengerHeaderBtn hoverRoundedCorner onClick={hideWidget}>
          <MessengerWidgetCross />
        </MessengerHeaderBtn>
      </MessengerHeaderButtonsCont>
      <ResizerContainer>
        <MessengerWidgetResizer />
      </ResizerContainer>
    </Container>
  );
};

export default MessengerHeader;

const Container = styled(Flex)`
  width: 100%;
  position: relative;
  align-items: center;
  background: ${(p) => p.theme.colors.gray.lighter};
  border-radius: 8px 8px 0 0;
`;

const MessengerLogo = styled.div`
  width: 160px;
  height: 16px;
  padding-left: 16px;
  color: #c6cae3;
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 3px;
  cursor: default;
`;

const MessengerHeaderButtonsCont = styled(Flex)`
  margin-left: auto;
  align-items: center;
  justify-content: space-between;
`;

const MessengerHeaderBtn = styled(Flex)`
  justify-content: center;
  align-items: center;
  cursor: pointer;
  width: 34px;
  height: 34px;
  transition: all 0.2s ease-in;
  &:hover {
    background: rgba(118, 123, 146, 0.15);
    border-top-right-radius: ${(p) => (p.hoverRoundedCorner ? '8px' : '0')};
  }
`;

const ResizerContainer = styled.div`
  position: absolute;
  left: 3px;
  top: 0;
`;
