export const getOptionByValue = (list, value) =>
  list.find((item) => item.value === value);

export const filterItems = ({ list, value = '' }) =>
  list.filter(
    (option) =>
      !value.length ||
      option.label
        .toString()
        .toLowerCase()
        .includes(value.toString().toLowerCase())
  );

export const toggleItem = ({ selectedList, itemId }) => {
  if (selectedList.includes(itemId)) {
    return selectedList.filter((item) => item !== itemId);
  }
  return [...selectedList, itemId];
};
