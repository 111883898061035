import React, { useMemo } from 'react';
import { useCompany } from './hooks';
import { CompanyProfileContext } from './CompanyProfile.context';
import { GeneralInfo, Tabs } from './components';
import { Container, TopPanel } from '../Layouts';
import styles from './CompanyProfile.module.css';

export function CompanyProfile({ companyId, noShowPanel = false }) {
  const { loading, company, setCompany } = useCompany(companyId);

  const contextValue = useMemo(
    () => ({
      loading,
      company,
      setCompany
    }),
    [loading, company]
  );

  const companyName = company?.name ?? '';

  return (
    <Container>
      <CompanyProfileContext.Provider value={contextValue}>
        {!noShowPanel && <TopPanel title={companyName} />}
        <GeneralInfo />
        <div className={styles.content}>
          <Tabs />
        </div>
      </CompanyProfileContext.Provider>
    </Container>
  );
}
