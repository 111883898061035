import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { translate } from 'react-i18next';
import block from 'bem-cn-lite';
import { testClass } from '../../lib';

import './style.styl';

const b = block('table-item-control');

class TableItemControl extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isShow: false
    };
    this.showList = this.showList.bind(this);
    this.hideList = this.hideList.bind(this);
    this.toggleList = this.toggleList.bind(this);
    this.handlerClickOutside = this.handlerClickOutside.bind(this);
  }

  showList() {
    this.setState({ isShow: true });
    document.addEventListener('mousedown', this.handlerClickOutside);
  }

  hideList() {
    this.setState({ isShow: false });
    document.removeEventListener('mousedown', this.handlerClickOutside);
  }

  toggleList() {
    if (this.state.isShow) {
      this.hideList();
    } else {
      this.showList();
    }
  }

  handlerClickOutside(e) {
    if (this.component && !this.component.contains(e.target)) {
      this.hideList();
    }
  }

  render() {
    const { items, position = 'bottom', listStyle = {} } = this.props;

    return (
      <div
        className={b(false, testClass('context', 'menu'))}
        ref={(component) => {
          this.component = component;
        }}>
        <div className={b('title')} onClick={() => this.toggleList()}>
          <img src="/img/3-dots.svg" alt="profile" />
        </div>
        <div
          className={b('list', { show: this.state.isShow, position })}
          style={listStyle}>
          {items &&
            items.map(
              (
                {
                  link,
                  download,
                  blank,
                  icon,
                  name,
                  element,
                  onClick,
                  style,
                  disabled,
                  preventHide = false,
                  index = 'btn'
                },
                i
              ) => (
                <div
                  key={i}
                  className={b('item', testClass('context-item', index))}
                  onClick={disabled || preventHide ? () => {} : this.hideList}>
                  {link && (
                    <Link
                      className={disabled ? b('disabled') : b('link')}
                      to={link}
                      style={style}
                      onClick={disabled ? (e) => e.preventDefault() : onClick}>
                      {icon && (
                        <img className={b('icon')} src={icon} alt="profile" />
                      )}
                      {name}
                    </Link>
                  )}
                  {download && (
                    <a
                      href={download}
                      className={disabled ? b('disabled') : b('link')}
                      download="">
                      {icon && (
                        <img className={b('icon')} src={icon} alt="profile" />
                      )}
                      {name}
                    </a>
                  )}
                  {blank && (
                    <a
                      href={blank}
                      className={disabled ? b('disabled') : b('link')}
                      target="_blank"
                      rel="noopener noreferrer">
                      {icon && (
                        <img className={b('icon')} src={icon} alt="profile" />
                      )}
                      {name}
                    </a>
                  )}
                  {!blank && !download && !link && !element && (
                    <div
                      className={disabled ? b('disabled') : b('link')}
                      onClick={disabled ? (e) => e.preventDefault() : onClick}
                      style={style}>
                      {icon && (
                        <img className={b('icon')} src={icon} alt="profile" />
                      )}
                      <span className={b('el')}>{name}</span>
                    </div>
                  )}
                  {!disabled && element}
                </div>
              )
            )}
        </div>
      </div>
    );
  }
}

export default translate(['ui'], { wait: true })(TableItemControl);
