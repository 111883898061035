import React, { Component } from 'react';
import { translate } from 'react-i18next';
import block from 'bem-cn-lite';

import './style.styl';

const b = block('table-item-export');

class TableItemExport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isShow: false
    };
    this.showList = this.showList.bind(this);
    this.hideList = this.hideList.bind(this);
    this.toggleList = this.toggleList.bind(this);
    this.handlerClickOutside = this.handlerClickOutside.bind(this);
  }

  showList() {
    this.setState({ isShow: true });
    document.addEventListener('mousedown', this.handlerClickOutside);
  }

  hideList() {
    this.setState({ isShow: false });
    document.removeEventListener('mousedown', this.handlerClickOutside);
  }

  toggleList() {
    if (this.state.isShow) {
      this.hideList();
    } else {
      this.showList();
    }
  }

  handlerClickOutside(e) {
    if (this.component && !this.component.contains(e.target)) {
      this.hideList();
    }
  }

  render() {
    const { items } = this.props;

    return (
      <div
        className={b()}
        ref={(component) => {
          this.component = component;
        }}>
        <div className={b('title')} onClick={() => this.toggleList()}>
          <img src="/img/download.svg" alt="profile" />
        </div>
        <div
          className={b('list', { show: this.state.isShow })}
          onClick={this.hideList}>
          {items &&
            items.map(({ name, onClick, link, style }, i) => (
              <div key={i} className={b('item')}>
                {link && (
                  <a
                    className={b('link')}
                    href={link}
                    target="_blank"
                    rel="noopener noreferrer"
                    style={style}>
                    {name}
                  </a>
                )}
                {!link && (
                  <div className={b('link')} onClick={onClick}>
                    <span className={b('el')} style={style}>
                      {name}
                    </span>
                  </div>
                )}
              </div>
            ))}
        </div>
      </div>
    );
  }
}

export default translate(['ui'], { wait: true })(TableItemExport);
