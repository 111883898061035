import React, { useState } from 'react';
import { Overlay, Popover } from 'react-bootstrap';
import { useIsClickOutside } from '@hooks/useIsClickOutside';
import styles from './InteractiveHelper.module.css';

export const InteractiveHelper = ({
  children,
  top = 3,
  placement = 'right',
  iconWidth = 15,
  width = 322,
  imgSrc = '/img/helper.svg'
}) => {
  const { ref, isShow, setIsShow } = useIsClickOutside(false);

  const [target, setTarget] = useState();
  const handleClick = (event) => {
    event.persist();
    setIsShow((prevState) => !prevState.show);
    setTarget(event.target);
  };

  return (
    <div className={styles.wrapper} style={{ top }} ref={ref}>
      <img
        role="presentation"
        src={imgSrc}
        style={{ width: iconWidth }}
        className={styles.img}
        onClick={handleClick}
        alt="?"
      />
      <Overlay
        show={isShow}
        target={target}
        container={ref.current}
        placement={placement}
        containerPadding={-15}>
        <Popover id="popover-positioned-left" style={{ minWidth: width }}>
          {children}
        </Popover>
      </Overlay>
    </div>
  );
};
