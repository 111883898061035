/* eslint-disable no-console */
import fetch from 'isomorphic-fetch';

export const uploadImage = (file) =>
  fetch('/api/media/pictures/upload', {
    credentials: 'include',
    method: 'POST',
    body: file
  })
    .then((response) => response.json())
    .catch((e) => console.error('FAILED UPLOAD IMAGE', e));

const uploadMedia = (api, url) =>
  fetch(api, {
    credentials: 'include',
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ data: { attributes: { url } } })
  })
    .then((res) => Promise.all([res.json(), res.ok]))
    .catch((e) => console.error('Failed upload media', e));

const uploadDocument = (api, file) =>
  fetch(`${api}?name=${file.name}&size=${file.size}`, {
    credentials: 'include',
    method: 'POST',
    body: file
  }).then((res) =>
    res.json().then((json) =>
      // eslint-disable-next-line prefer-promise-reject-errors
      res.status < 400
        ? Promise.resolve(json)
        : Promise.reject({ errors: json.errors, status: res.status })
    )
  );

export const uploadYoutube = (url) =>
  uploadMedia('/api/media/youtube/upload', url);
export const uploadSoundcloud = (url) =>
  uploadMedia('/api/media/soundcloud/upload', url);
export const uploadPdf = (file, id) =>
  uploadDocument(`/api/products/${id}/media/pdf/upload`, file);
