import { assoc, update, prepend, move, isEmpty, pipe, remove } from 'ramda';
import createSlice from '../../../lib/actionReducer';
import {
  reworkCompleted,
  sent,
  viewed
} from '../../../presenters/sidebars/PurchaseRequestResponsesSidebar/status';
import { PurchaseRequestStatuses } from '../entities/PurchaseRequestStatuses';

const initState = {
  isUploaded: false,
  requests: [],
  size: 0,
  limit: 20,
  offset: 0,
  search: '',
  orderBy: '',
  direction: [],
  selectedCategoriesId: [],
  selectedStatus: [],
  compareResponses: {},
  compareRequest: null
};

const slice = createSlice({
  name: 'MY_PURCHASE_REQUEST',
  initState,
  reducers: {
    updateRequest,
    setIsUploaded,
    getMyRequests,
    getResponseById: setPayloadToState,
    setSearch: (state, payload) => ({ ...state, search: payload }),
    changeRequestStatus: () => {},
    publishRequest: (state, payload) =>
      changeRequestStatus(
        state,
        payload.id,
        PurchaseRequestStatuses.receivingResponses
      ),
    archiveRequest,
    unpublishRequest,
    viewAllResponses,
    reset: () => initState,
    changeResponseStatus,
    setSelectedStatus,
    updateCompareList
  }
});

export const { actions } = slice;
export default slice.reducer;

function setPayloadToState(state, payload) {
  return { ...state, ...payload };
}

function setIsUploaded(state, isUploaded = false) {
  return { ...state, isUploaded };
}

function isFirstPage(offset = 0) {
  return offset === 0;
}

function hasAnySearchParams(state) {
  return (
    !isEmpty(state.search) ||
    !isEmpty(state.selectedStatus) ||
    !isEmpty(state.selectedCategoriesId)
  );
}

function archiveRequest(state, payload) {
  const index = state.requests.findIndex(
    (request) => request.id === payload.id
  );

  if (index === -1) {
    console.error(`Request with id = ${payload.id} is not found`);
    return state;
  }

  return pipe(
    (result) => assoc('requests', remove(index, 1, state.requests), result),
    (result) => assoc('size', result.size - 1, result)
  )(state);
}

function unpublishRequest(state, payload) {
  return changeRequestStatus(
    state,
    payload.id,
    PurchaseRequestStatuses.receptionCompleted
  );
}

function updateRequest(state, payload) {
  const { request } = payload;

  if (!isFirstPage(state.offset) || hasAnySearchParams(state)) return state;

  const requestIndex = state.requests.findIndex((r) => r.id === request.id);

  let updatedRequests;
  if (requestIndex === -1) {
    updatedRequests = prepend(request, state.requests);

    if (updatedRequests.length > state.limit) {
      updatedRequests = updatedRequests.slice(0, state.limit - 1);
    }
  } else {
    updatedRequests = update(requestIndex, request, state.requests);

    if (requestIndex !== 0) move(requestIndex, 0, updatedRequests);
  }

  return assoc('requests', updatedRequests, state);
}

function changeRequestStatus(state, id, status) {
  return {
    ...state,
    requests: state.requests.map((request) =>
      request.id === id ? { ...request, status } : request
    )
  };
}

function getMyRequests(
  state,
  {
    requests = [],
    size,
    limit = 20,
    offset = 0,
    search = '',
    direction,
    selectedCategoriesId = [],
    orderBy
  }
) {
  return {
    ...state,
    requests,
    size,
    search,
    limit,
    offset,
    orderBy,
    direction,
    selectedCategoriesId,
    isUploaded: true
  };
}

function setSelectedStatus(state, selectedStatus) {
  return { ...state, selectedStatus };
}

function changeResponseStatus(state, { requestId, responseId, status }) {
  return {
    ...state,
    requests: state.requests.map((request, index) =>
      request.id === requestId
        ? {
            ...request,
            responses: state.requests[index].responses.map((response) => ({
              ...response,
              status: response.id === responseId ? status : response.status
            }))
          }
        : request
    )
  };
}

function updateCompareList(state, { compareResponses, compareRequest }) {
  return { ...state, compareResponses, compareRequest };
}

function viewAllResponses(state, { requestId }) {
  return {
    ...state,
    requests: state.requests.map((request, index) =>
      request.id === requestId
        ? {
            ...request,
            responses: state.requests[index].responses.map((response) => ({
              ...response,
              status: [sent, reworkCompleted].includes(response.status)
                ? viewed
                : response.status
            }))
          }
        : request
    )
  };
}
