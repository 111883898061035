import React from 'react';
import cn from 'classnames';
import { Typography, Checkbox } from '@link/react-components';
import { useTranslate } from '@hooks';
import {
  Container,
  Header,
  Content,
  Row,
  Cell,
  CheckboxRow
} from '../components';
import { BrowserSubscription } from './BrowserSubscription';
import { Helper } from '../../helper';
import styles from './Chat.module.css';

const CHECKBOX_SIZE = 16;
const HELPER_ICON_SIZE = 18;
const HELPER_TOOLTIP_MAX_WIDTH = 207;

export function ChatSection({ settings, onChange }) {
  const t = useTranslate();

  function translateSection(tKey) {
    return t(`modules.settings.sections.${tKey}`);
  }

  function translateHint(tKey) {
    return t(`modules.settings.sections.chat.hints.${tKey}`);
  }

  function translateOption(tKey) {
    return t(`modules.settings.sections.chat.options.${tKey}`);
  }

  return (
    <Container>
      <Header>
        <Row>
          <Cell>
            <Typography className={styles.title} variant="h2">
              {translateSection('chat.title')}
            </Typography>
          </Cell>
          <Cell center>
            <Typography className={styles.columnTitle} variant="body1Med">
              {translateSection('columns.mail')}
            </Typography>
          </Cell>
          <Cell center>
            <div className={cn(styles.column, styles.withHelper)}>
              <Typography className={styles.columnTitle} variant="body1Med">
                {translateSection('columns.browser')}
              </Typography>
              <Helper
                tooltip={translateHint('browser')}
                iconSize={HELPER_ICON_SIZE}
                tooltipMaxWidth={HELPER_TOOLTIP_MAX_WIDTH}
                tooltipAlign="center"
              />
            </div>
          </Cell>
        </Row>
      </Header>
      <Content>
        <CheckboxRow title={translateOption('newMessage')}>
          <Checkbox
            id="newMessage"
            checked={settings.newMessage}
            onChange={onChange}
            size={CHECKBOX_SIZE}
          />
          <BrowserSubscription size={CHECKBOX_SIZE} />
        </CheckboxRow>
      </Content>
    </Container>
  );
}
