import React from 'react';
import './style.css';

const Button = ({ icon, isSelect, onClick, size = 'md' }) => (
  <div
    className={`request-icons__btn ${icon} ${
      isSelect ? 'is-select' : ''
    } request-icons__btn_${size}`}
    onClick={onClick}
  />
);

const Icons = ({ buttons = [] }) => (
  <div className="request-icons">{buttons.map(Button)}</div>
);

export default Icons;
