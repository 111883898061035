const { format, compareDesc } = require('date-fns');
const { isNil, curry, isEmpty } = require('ramda');
const asyncLib = require('async');
const { promisify } = require('util');

const { unitCodes } = require('../config/appconfig');

exports.mapLimit = promisify(asyncLib.mapLimit);
exports.mapSeries = promisify(asyncLib.mapSeries);
exports.mapValuesSeries = promisify(asyncLib.mapValuesSeries);

const optionsFormatDate = {
  full: {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
    hour: '2-digit',
    minute: '2-digit'
  },
  withOutTime: { day: '2-digit', month: '2-digit', year: 'numeric' }
};

const onlyUnique = (value, index, self) => self.indexOf(value) === index;

exports.formatDate = (date, formatDate = 'full') => {
  if (date === undefined || date === null || date === '-' || date === '') {
    return '-';
  }

  return new Date(date).toLocaleDateString(
    'ru-RU',
    optionsFormatDate[formatDate]
  );
};

exports.formatDateDefault = (date) => format(new Date(date), 'dd.MM.yyyy');

exports.formatDateToRu = (date) => {
  let dd = date.getDate();
  if (dd < 10) dd = `0${dd}`;

  let mm = date.getMonth() + 1;
  if (mm < 10) mm = `0${mm}`;

  const yy = date.getFullYear();

  return `${dd}/${mm}/${yy}`;
};

exports.addZeroToDate = (dateString, spliter = '.') => {
  const dateArr = dateString.split(spliter);
  if (+dateArr[1] < 10) {
    dateArr[1] = `0${dateArr[1]}`;
  }
  if (+dateArr[2] < 10) {
    dateArr[2] = `0${dateArr[2]}`;
  }
  return dateArr.join(spliter);
};

exports.t = (translation, key, lang = 'en') =>
  (translation[key] && translation[key][lang]) || key;

exports.getRandomInt = (min = 0, max = 10000000) =>
  Math.floor(Math.random() * (max - min)) + min;

const checkNumber = (numbers, nextNumber) =>
  numbers.some((number) => number === nextNumber);

exports.nextNumber = (listOfNumbers) => {
  let nextNumber = 1;
  while (checkNumber(listOfNumbers, nextNumber)) {
    nextNumber += 1;
  }
  return nextNumber;
};

exports.getImpementation = (type, list) => {
  if (list[type]) {
    return list[type];
  }

  throw Error(`not supported type: ${type}`);
};

exports.getUnitByName = (name, lang = 'ru') => {
  const la = lang === 'ru' ? 'nat' : 'int';
  if (!name) return '';
  const unit = unitCodes.find((u) => u.condDesign[la] === name);
  if (!unit) {
    // console.error(`not such unit with code: ${name}`);
    return name;
  }
  return unit.code;
};

exports.percentOfSum = (sum, discount) =>
  Number(((sum / 100) * (100 - discount)).toFixed(2));

exports.isURL = (str) => {
  /* eslint-disable */
  const urlRegex =
    '^(?!mailto:)(?:(?:http|https|ftp)://)(?:\\S+(?::\\S*)?@)?(?:(?:(?:[1-9]\\d?|1\\d\\d|2[01]\\d|22[0-3])(?:\\.(?:1?\\d{1,2}|2[0-4]\\d|25[0-5])){2}(?:\\.(?:[0-9]\\d?|1\\d\\d|2[0-4]\\d|25[0-4]))|(?:(?:[a-z\\u00a1-\\uffff0-9]+-?)*[a-z\\u00a1-\\uffff0-9]+)(?:\\.(?:[a-z\\u00a1-\\uffff0-9]+-?)*[a-z\\u00a1-\\uffff0-9]+)*(?:\\.(?:[a-z\\u00a1-\\uffff]{2,})))|localhost)(?::\\d{2,5})?(?:(/|\\?|#)[^\\s]*)?$';
  /* eslint-enable */
  const url = new RegExp(urlRegex, 'i');
  return str.length < 2083 && url.test(str);
};

exports.filterCustomersByPermission = (customers, userId, permissions) =>
  customers.filter((i) => {
    if (i.responsibleUser) {
      return i.responsibleUser === userId || permissions.customers.delete;
    }
    return permissions.customers.read;
  });

exports.filterSuppliersByPermission = (suppliers, userId, permissions) =>
  suppliers.filter((i) => {
    if (i.responsibleUser) {
      return i.responsibleUser === userId || permissions.suppliers.delete;
    }
    return permissions.suppliers.read;
  });

exports.isNotFoundOneInDB = (instanseOfModel) => instanseOfModel === null;

// eslint-disable-next-line
exports.removeSpecCharacters = (txt) =>
  txt.replace(/[`~!@#$%^&*|+\=?;:«»<>\{\}\[\]\\\/]/gi, '');

exports.filterArrayUniq = (array) => {
  if (!Array.isArray(array)) {
    return;
  }
  // eslint-disable-next-line
  return array.filter(onlyUnique);
};

const roundUpCost = (cost, roundUp = null) => {
  if (!cost) return cost;

  const c = +cost;

  if (!roundUp) return +c.toFixed(2);

  if (![1, 10, 100].includes(roundUp)) return cost;

  return Math.round(c / roundUp) * roundUp;
};

exports.roundUpCost = roundUpCost;

const onePercentOfNumber = (number) => number / 100;

const discountSum = (discount, price) => price * onePercentOfNumber(discount);

const discountPrice = curry(
  (discount, price) => price - discountSum(discount, price)
);

const getPriceWithDiscount = (item, orderCategory) => {
  const discount = orderCategory && orderCategory.discount;
  let cost = +item.cost;
  const { amountCategory } = item;

  if (amountCategory && Object.keys(amountCategory).length > 0) {
    cost = discountPrice(amountCategory.discount, cost);
  }
  if (discount) {
    return discountPrice(orderCategory.discount, cost);
  }
  return +cost;
};

const getPriceIndividualWithDiscount = (item, orderCategory) => {
  const discountPriceCategory = orderCategory && orderCategory.discount;
  let cost = +item.cost;
  const discount = +item.discount || discountPriceCategory;
  const { amountCategory } = item;

  if (amountCategory && Object.keys(amountCategory).length > 0) {
    cost = discountPrice(+amountCategory.discount, cost);
  }

  if (!discount) {
    return cost;
  }

  return discountPrice(discount, cost);
};

const getOfferPrice = (item, orderCategory, roundUp) => {
  const individualPriceWithDiscount = getPriceIndividualWithDiscount(
    item,
    orderCategory
  );
  const defaultPriceWithDiscount = getPriceWithDiscount(item, orderCategory);

  if (!isNil(individualPriceWithDiscount)) {
    return roundUpCost(individualPriceWithDiscount, roundUp);
  }
  if (isNil(individualPriceWithDiscount) && !isNil(defaultPriceWithDiscount)) {
    return roundUpCost(defaultPriceWithDiscount, roundUp);
  }

  return +item.cost;
};
exports.getOfferPrice = getOfferPrice;

const getConditionCategoriesDiscountSum = (categories) =>
  categories.reduce((acc, cat) => acc + cat.discount, 0);

const getResultCost = (order, item) => {
  const cost = getOfferPrice(item, order.category, order.roundUp);
  if (order.priceCategoryConditions.length > 0) {
    const conditionDiscount = getConditionCategoriesDiscountSum(
      order.priceCategoryConditions
    );
    return (cost / 100) * (100 - conditionDiscount);
  }
  return cost;
};

exports.getResultCost = getResultCost;

exports.getOrderProductsSum = (orderProducts, order) => {
  const statuses = [
    'draft',
    'sent',
    'viewed',
    'editing',
    'waiting_for_approval',
    'approved'
  ];
  let products = orderProducts;
  if (['sent', 'viewed', 'approved'].includes(order.status)) {
    products = products.concat(order.additionalProducts.products);
  }
  if (statuses.includes(order.status)) {
    return products
      .reduce((acc, item) => acc + item.count * getResultCost(order, item), 0)
      .toFixed(2);
  }
  return orderProducts
    .reduce((acc, item) => acc + item.count * item.cost, 0)
    .toFixed(2);
};

exports.howMuchSymbolsRound = (roundUp = null) => {
  switch (roundUp) {
    default:
      return 2;
    case null:
      return 2;
    case 1:
      return 0;
    case 10:
      return -1;
    case 100:
      return -2;
  }
};

exports.sortPrice = (products, { orderBy, direction, offset, limit }) => {
  let data = [];
  const orderList = [
    'available',
    'availableAll',
    'discountPrice',
    'discount',
    'weightBrutto',
    'weightNetto',
    'stocksBalance',
    'receiptDate'
  ];
  let orderByParam = orderBy;
  if (orderBy === 'stocksBalance') {
    orderByParam = 'availableAll';
  }
  if (orderList.includes(orderByParam) && direction === 'ASC') {
    data = products.sort(
      (a, b) => (b[orderByParam] || 0) - (a[orderByParam] || 0)
    );
    data = data.slice(+offset, +offset + +limit);
  } else if (orderList.includes(orderByParam) && direction === 'DESC') {
    data = products
      .sort((a, b) => (b[orderByParam] || 0) - (a[orderByParam] || 0))
      .reverse();
    data = data.slice(+offset, +offset + +limit);
  } else {
    data = products;
  }
  return data;
};

exports.getOrderProductUnit = (unit, units) => {
  if (`${unit}`.length === 3) {
    return unit;
  }
  const catalogUnit = units.find((i) => i.id === unit);
  return catalogUnit ? catalogUnit.name : '';
};

exports.updateProductUnits = async (
  product,
  activeUnits,
  transaction = null
) => {
  const unitTypes = ['productUnit', 'weightUnit', 'dimensionsUnit'];
  const packageUnitsTypes = [
    'packageUnit',
    'packageWeightUnit',
    'packageVolumeUnit',
    'packageDimensionsUnit'
  ];

  const { packages } = product;
  const updatedProps = {};
  /* eslint-disable no-restricted-syntax */
  for (const unitType of unitTypes) {
    if (product[unitType] && !activeUnits.includes(product[unitType])) {
      updatedProps[unitType] = '';
    }
  }
  let packUpdated = false;
  for (const pack of packages) {
    for (const unitType of packageUnitsTypes) {
      if (pack[unitType] && !activeUnits.includes(pack[unitType])) {
        pack[unitType] = '';
        packUpdated = true;
      }
    }
  }
  /* eslint-enable no-restricted-syntax */
  if (packUpdated) {
    updatedProps.packages = packages;
  }
  if (Object.keys(updatedProps).length > 0 || packUpdated) {
    return await product.update(updatedProps, { transaction });
  }
  return product;
};

exports.getUnit = (unit, units, language) => {
  if (!unit) {
    return { name: '', code: '' };
  }
  if (unit.length <= 3 && !Number.isNaN(+unit)) {
    const baseUnit = unitCodes.find((i) => +i.code === +unit);
    if (baseUnit) {
      return {
        name:
          language === 'ru'
            ? baseUnit.condDesign.nat
            : baseUnit.condDesign.int || baseUnit.codeDesign.int,
        code: baseUnit.code,
        base: true
      };
    }
  }
  const customUnits = units.filter((u) => u.id);
  const customUnit = customUnits.find((i) => i.id === unit);
  if (customUnit) {
    return { name: customUnit.name, code: customUnit.code || '' };
  }
  return { name: '', code: '' };
};

exports.getUnitName = (unit, units, language) => {
  if (!unit) {
    return '';
  }
  if (unit.length <= 3 && !Number.isNaN(+unit)) {
    const baseUnit = unitCodes.find((i) => +i.code === +unit);
    if (baseUnit) {
      return language === 'ru'
        ? baseUnit.condDesign.nat
        : baseUnit.condDesign.int || baseUnit.codeDesign.int;
    }
  }
  const customUnits = units.filter((u) => u.id);
  const customUnit = customUnits.find((i) => i.id === unit);
  if (customUnit) {
    return customUnit.name;
  }
  return '';
};

exports.getOrderNumber = (number, partNumber) =>
  partNumber > 0 ? `${number}_00${partNumber}` : `${number || '-'}`;

exports.setDialogAdditionalProps = (id, item = {}) => {
  const itemParam = { ...item };
  itemParam.archived = item.archivedBy && item.archivedBy.includes(id);
  itemParam.notificationOff =
    item.notificationOffBy && item.notificationOffBy.includes(id);
  delete itemParam.archivedBy;
  delete itemParam.notificationOffBy;
  return itemParam;
};

// use for string with special characters
const replaceAll = (string, search, replace) =>
  string.split(search).join(replace);

exports.prepareSearch = (search) => {
  const specCharacters = ['_', '%', '@'];
  const specCharacterRegexp = new RegExp(specCharacters.join('|'));
  if (specCharacterRegexp.test(search)) {
    let str = search;
    specCharacters.forEach((c) => {
      str = replaceAll(str, c, `\\${c}`);
    });
    return str.toLowerCase();
  }
  return search.toLowerCase();
};

exports.escapeText = (text) => text.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');

exports.isFirstDateGTE = (firstDate, nextDate) =>
  compareDesc(new Date(firstDate), new Date(nextDate)) < 1;
exports.isFirstDateGT = (firstDate, nextDate) =>
  compareDesc(new Date(firstDate), new Date(nextDate)) === -1;

exports.countSum = (list) =>
  list.reduce((acc, prev) => acc + (+prev.price * +prev.count || 0), 0);

exports.round = (value, precision = 2) =>
  Math.ceil(value * 10 ** precision) / 10 ** precision;

exports.calcVAT = (sum, vat) => sum * (vat / (100 + vat));

exports.isNilOrEmpty = (value) => isNil(value) || isEmpty(value);

exports.isNumber = (value) => !Number.isNaN(Number(value));
exports.maybeNumber = (value) => !isNil(value) && !Number.isNaN(Number(value));

const createMapBy = (key, list) =>
  list.reduce((acc, current) => ({ ...acc, [current[key]]: current }), {});

exports.createMapBy = createMapBy;
exports.createMapById = (list) => createMapBy('id', list);

exports.isValidDate = (dateObject) =>
  !!dateObject && new Date(dateObject).toString() !== 'Invalid Date';
