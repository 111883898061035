import React from 'react';
import block from 'bem-cn-lite';
import { useSelector } from 'react-redux';
import Info from './Info';
import Content from './Content';

import './style.css';
import * as storeGetters from '../../../../storeGetters';

const b = block('card-request');
export const RequestCard = React.memo(({ request }) => {
  const userPRPermissions = useSelector(
    storeGetters.getPurchaseRequestsPermission
  ).toJS();

  return (
    <div className={b()}>
      <div className={b('content')}>
        <Content
          request={request}
          haveUpdatePermission={Boolean(userPRPermissions?.update)}
        />
      </div>
      <div className={b('info')}>
        <Info request={request} />
      </div>
    </div>
  );
});
