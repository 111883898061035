import React, { Component } from 'react';
import { translate } from 'react-i18next';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  Modal,
  Col,
  Form,
  FormGroup,
  ControlLabel,
  Button
} from 'react-bootstrap';
import { ModalContainer } from './elements';
import InputNumber from '../InputNumber';

import * as modalActions from '../../action-creators/modal';
import * as stockActions from '../../action-creators/stocks';
import * as serviceActions from '../../action-creators/services';

class ModalAddContact extends Component {
  constructor(props) {
    super(props);
    this.state = { count: 0 };
    this.setCount = this.setCount.bind(this);
  }

  setCount(count) {
    this.setState({ count });
  }

  get count() {
    return this.state.count;
  }

  render() {
    const {
      t,
      gaSend,
      user,
      hideModalDialog,
      updateCountOfFewProductInStock,
      stockId,
      products
    } = this.props;

    return (
      <ModalContainer mods={{ theme: 'default' }}>
        <Modal id="addContact" onHide={hideModalDialog} show>
          <Modal.Header>
            <Modal.Title>
              {t('change_product_count')}
              <span
                className="modal-header-cross pull-right"
                onClick={hideModalDialog}
              />
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form horizontal style={{ whiteSpace: 'nowrap' }}>
              <FormGroup controlId="count">
                <Col
                  componentClass={ControlLabel}
                  className="form-modal-label-col"
                  sm={8}>
                  {t('count_of_selected_products')}
                </Col>
                <Col sm={4} className="form-modal-input-col">
                  <div style={{ float: 'right' }}>
                    <InputNumber
                      value={this.count}
                      onChange={this.setCount}
                      min={0}
                    />
                  </div>
                </Col>
              </FormGroup>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <div>
              <Button
                id="cancel"
                className="pull-left"
                onClick={() => hideModalDialog()}>
                {t('Cancel')}
              </Button>
              <Button
                id="saveButton"
                bsStyle="primary"
                onClick={async () => {
                  gaSend({
                    category: 'Supplier',
                    action: 'stock_change_product_count',
                    label: user.get('email')
                  });
                  await updateCountOfFewProductInStock(
                    stockId,
                    products,
                    this.state.count
                  );
                  hideModalDialog();
                }}>
                {t('Change')}
              </Button>
            </div>
          </Modal.Footer>
        </Modal>
      </ModalContainer>
    );
  }
}

export default connect(
  (state) => ({
    user: state.getIn(['user', 'user']),
    company: state.get('company'),
    stockProducts: state.getIn(['stocks', 'products'])
  }),
  (dispatch) =>
    bindActionCreators(
      {
        hideModalDialog: modalActions.hideModalDialog,
        updateCountOfFewProductInStock:
          stockActions.updateCountOfFewProductInStock,
        fetchProducts: stockActions.loadProductsOfStock,
        selectProduct: stockActions.selectProduct,
        getCategories: stockActions.loadCategoriesOfStock,
        gaSend: serviceActions.gaSend
      },
      dispatch
    )
)(translate(['ui'], { wait: true })(ModalAddContact));
