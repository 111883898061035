import React from 'react';
import { Typography } from '@link/react-components';
import GoBack from '../../../Buttons/GoBack';
import styles from './TopPanel.module.css';

export function TopPanel({ title }) {
  return (
    <div className={styles.container}>
      <div className={styles.backButton}>
        <GoBack />
      </div>
      <Typography className={styles.title} variant="h1">
        {title}
      </Typography>
    </div>
  );
}
