import React from 'react';
import { useWizard } from '@link/react-components';
import { Counter } from '../counter';
import classes from './TitleCounter.module.css';

export function TitleCounter() {
  const { getState } = useWizard();

  const state = getState();
  const { selectedCompanies, maxChosenCount } = state;

  return (
    <Counter
      className={classes.titleCounter}
      value={selectedCompanies.length}
      total={maxChosenCount}
      variant="body1Sb"
      leftClass={classes.currentCount}
      rightClass={classes.totalCount}
    />
  );
}
