import { combineReducers } from 'redux-immutable';
import selectPriceCategory from './selectPriceCategory';
import tableOrdersCompany from './tableOrdersCompany';
import tableOrdersCustomers from './tableOrdersCustomers';
import orderDraft from './orderDraft';
import importOrder from './import';

export default combineReducers({
  selectPriceCategory,
  tableOrdersCompany,
  tableOrdersCustomers,
  orderDraft,
  import: importOrder
});
