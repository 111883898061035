import React from 'react';
import { once } from 'ramda';
import pdfJsWorker from 'pdfjs-dist/build/pdf.worker.entry';
import { PdfJsApi } from './vendors/PdfJsApi';

export const registerPdfWorker = once((workerUrl = pdfJsWorker) => {
  try {
    PdfJsApi.GlobalWorkerOptions.workerSrc = workerUrl;
  } catch (e) {
    PdfJsApi.PDFJS.workerSrc = workerUrl;
  }
});

export const Worker = ({ children, workerUrl = pdfJsWorker }) => {
  registerPdfWorker(workerUrl);
  return <>{children}</>;
};
