import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import DynamicBorderEditableChipContainer from 'react-components/inputs/dynamic-border/editable-chip-container';
import * as storage from '../../../storage';
import * as getters from '../../../getters';
import { useTranslate } from '../../../../TranslateProvider';

export function transformTagOption(tag) {
  return {
    label: tag.title,
    value: tag.id,
    color: tag.color
  };
}

export function transformTagOptionList(tags = []) {
  return tags.map(transformTagOption);
}

const TagSelect = ({ value: selectedTag, onChange, placeholder, maxWidth }) => {
  const dispatch = useDispatch();
  const t = useTranslate();

  const tagsList = useSelector(getters.getTagsList);

  useEffect(() => {
    dispatch(storage.getAllUserTags());
  }, []);

  const onCreateTagOption = async (value) => {
    const tag = await dispatch(storage.createNewTag(value));
    return transformTagOption(tag);
  };

  const onChangeTagOption = async (value) => {
    dispatch(storage.updateTag(value));
  };

  return (
    <DynamicBorderEditableChipContainer
      t={t}
      placeholder={placeholder}
      value={selectedTag}
      options={transformTagOptionList(tagsList)}
      onChange={onChange}
      onChangeOption={onChangeTagOption}
      onCreateOption={onCreateTagOption}
      isMulti
      maxWidth={maxWidth}
    />
  );
};

export default TagSelect;
