import React from 'react';
import { translate } from 'react-i18next';
import block from 'bem-cn-lite';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { format } from '../../../../lib/date';

const b = block('cr-info');

function WithTooltip({ id, tooltip, children }) {
  return (
    <OverlayTrigger
      overlay={
        <Tooltip id={id} positionLeft={0}>
          {tooltip}
        </Tooltip>
      }
      placement="left"
      delayShow={300}
      delayHide={100}>
      {children}
    </OverlayTrigger>
  );
}

const Info = ({ t, request }) => {
  const sum = new Intl.NumberFormat('ru-RU', {
    style: 'currency',
    currency: 'RUB',
    maximumFractionDigits: 0
  }).format(request.get('sum') || 0);

  return (
    <div className={b()}>
      <div>
        <div className={b('label')}>{t('sum_request')}</div>
        <WithTooltip id={request.get('id')} tooltip={sum}>
          <div className={b('value', { big: true })}>{sum}</div>
        </WithTooltip>
      </div>
      <div>
        <div className={b('dates')}>
          <div className={b('label')}>{`${t('Publication date')}:`}</div>
          <div className={b('value')}>
            {format(request.get('lastPublishedDate'), 'dd.MM.yyyy')}
          </div>
        </div>
        <div className={b('dates')}>
          <div className={b('label')}>{`${t('date_responses_end_card')}:`}</div>
          <div className={b('value')}>
            {format(request.get('responseEndDate'), 'dd.MM.yyyy')}
          </div>
        </div>
      </div>
    </div>
  );
};

export default translate()(Info);
