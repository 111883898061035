import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Icon } from '@link/react-components';
import cn from 'classnames';
import { useActions } from '@hooks';
import * as storeGetters from '../../../../../storeGetters';
import styles from './ResponseEndDate.module.css';
import * as modalActions from '../../../../../action-creators/modal';
import Tooltip from '../../../../ChatWidget/Tooltip';
import { formatDate } from '../../../../../utils/date';

import {
  executorActionTypes,
  purchaseRequestStatuses,
  purchaseRequestTypes
} from '../../../domain/entities';

const CAN_BE_CHANGED_RESPONSE_DATE = Object.freeze({
  [purchaseRequestStatuses.receptionCompleted]:
    purchaseRequestStatuses.receptionCompleted,
  [purchaseRequestStatuses.receivingResponses]:
    purchaseRequestStatuses.receivingResponses
});

const canUpdatePR = (request, user) => {
  if (request.type === purchaseRequestTypes.regular) {
    return user.id === request.authorId;
  }
  return request.executorType === executorActionTypes.publication;
};

export const ResponseEndDate = ({ request, updateRequest }) => {
  const user = useSelector(storeGetters.getCurrentUser).toJS();
  const canUpdateResponseDate =
    !!CAN_BE_CHANGED_RESPONSE_DATE[request.status] &&
    canUpdatePR(request, user) &&
    !request.isArchived;

  if (canUpdateResponseDate) {
    return <CanProlong request={request} updateRequest={updateRequest} />;
  }

  return <>{formatDate(request.responseEndDate, 'withOutTime')}</>;
};

function CanProlong({ request, updateRequest }) {
  const [isActive, setIsActive] = useState(false);
  const { showModal } = useActions(modalActions);
  const onOpenModal = () => {
    showModal('MODAL_EXTEND_RESPONSE_END_DATE', {
      request,
      updateRequest
    });
  };
  return (
    <>
      <Tooltip text="responseEndDate">
        <div
          className={cn(styles.text, styles.canUpdate)}
          onMouseDown={() => setIsActive(true)}
          onMouseUp={() => setIsActive(false)}
          data-testid="request-purchase-date-responses-end"
          onClick={onOpenModal}>
          {formatDate(request.responseEndDate, 'withOutTime')}

          <Icon
            className={cn(styles.icon, {
              [styles.iconActive]: isActive
            })}
            iconName="clock-fast-forward"
            width={18}
            height={18}
          />
        </div>
      </Tooltip>
    </>
  );
}
