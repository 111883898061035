const { makeEvent, makeCommand } = require('core/data/messageBus/message');

const NS = 'purchaseRequest';

exports.unpublishedEVT = makeEvent(NS, 'unpublished');
exports.createCMD = makeCommand(NS, 'create');
exports.createdEVT = makeEvent(NS, 'created');

exports.viewCMD = makeEvent(NS, 'view');

const makeQuery = (name) => {
  return {
    get: { type: 'QUERY', name: `QUERY:${NS}:GET:${name}`, operation: 'get' },
    sync: {
      type: 'QUERY',
      name: `QUERY:${NS}:SYNC:${name}`,
      operation: 'sync'
    },
    syncOff: {
      type: 'QUERY',
      name: `QUERY:${NS}:SYNC-OFF:${name}`,
      operation: 'sync-off'
    },
    set: { type: 'QUERY', name: `QUERY:${NS}:SET:${name}`, operation: 'set' },
    update: {
      type: 'QUERY',
      name: `QUERY:${NS}:UPDATE:${name}`,
      operation: 'update'
    },
    remove: {
      type: 'QUERY',
      name: `QUERY:${NS}:REMOVE:${name}`,
      operation: 'remove'
    }
  };
};

exports.purchaseRequestQRY = makeQuery('purchase-request');

exports.roomName = ({ employeeId }) => `purchase-request-view.${employeeId}`;
