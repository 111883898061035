const { makeListOfPolicies } = require('../../data/listOfPolicies');
const billing = require('./billing');
const buyers = require('./buyers');
const company = require('./company');
const employees = require('./employees');
const nomenclature = require('./nomenclature');
const ordersFromBuyers = require('./orders-from-buyers');
const ordersToSuppliers = require('./orders-to-suppliers');
const priceLists = require('./priceLists');
const productsSuppliers = require('./products-suppliers');
const stocks = require('./stocks');
const suppliers = require('./suppliers');

const arrayOfPolicies = [].concat(
  billing.settings,
  buyers.settings,
  company.settings,
  employees.settings,
  nomenclature.settings,
  ordersFromBuyers.settings,
  ordersToSuppliers.settings,
  priceLists.settings,
  productsSuppliers.settings,
  stocks.settings,
  suppliers.settings
);
exports.settings = makeListOfPolicies(arrayOfPolicies);
