import { Map, List, OrderedMap } from 'immutable';

// TODO: add jsdoc! for arguments
export const consListOfCategory = (listOfCategory) => List(listOfCategory);

export const consListOfProducts = (listOfProducts) =>
  OrderedMap(listOfProducts);

export const consProduct = (product) => Map(product);

export const consProductsOfStock = (products = []) =>
  products.reduce(
    (acc, product) => acc.set(product.sku, consProduct(product)),
    consListOfProducts()
  );

export const getListOfCategory = (productOfStock) =>
  consListOfCategory(productOfStock.keys());

export const getListOfProductsFromCategory = (
  productsOfStock,
  nameOfCategory
) => productsOfStock.get(nameOfCategory);

export const getProductFromStock = (
  productsOfStock,
  nameOfCategory,
  nameOfProduct
) => productsOfStock.getIn([nameOfCategory, nameOfProduct]);

export const getProductFromListOfProducts = (listOfProducts, nameOfProduct) =>
  listOfProducts.get(nameOfProduct);

export const getPropsOfProduct = (product, property) => product.get(property);

export const setPropsOfProduct = (product, property, newValue) =>
  product.set(property, newValue);

export const updateCountProductOfStock = (
  productOfStock,
  categoryName,
  productName,
  newValue
) =>
  productOfStock.update(productName, (product) =>
    setPropsOfProduct(product, 'inStock', newValue)
  );

export const updateCountProductsOfStock = (productOfStock, ids, newValue) => {
  ids.forEach((id) => {
    const product = productOfStock.find((i) => i.get('id') === id);
    if (product) {
      // eslint-disable-next-line no-param-reassign,no-shadow
      productOfStock = productOfStock.update(product.get('sku'), (product) =>
        setPropsOfProduct(product, 'inStock', newValue)
      );
    }
  });
  return productOfStock;
};

export const updateReceiptDateProductsOfStock = (
  productOfStock,
  ids,
  newValue
) => {
  ids.forEach((id) => {
    const product = productOfStock.find((i) => i.get('id') === id);
    if (product) {
      // eslint-disable-next-line no-param-reassign,no-shadow
      productOfStock = productOfStock.update(product.get('sku'), (product) =>
        setPropsOfProduct(product, 'receiptDate', newValue)
      );
    }
  });
  return productOfStock;
};

export const updateReservedProductOfStock = (
  productOfStock,
  categoryName,
  productName,
  newValue
) =>
  productOfStock.update(productName, (product) =>
    setPropsOfProduct(product, 'reserved', newValue)
  );

export const updateHandReservedProductOfStock = (
  productOfStock,
  categoryName,
  productName,
  newValue
) =>
  productOfStock.update(productName, (product) =>
    setPropsOfProduct(product, 'handReserved', newValue)
  );

export const updateUnitsProductOfStock = (
  productOfStock,
  categoryName,
  productName,
  newValue
) =>
  productOfStock.update(productName, (product) =>
    setPropsOfProduct(product, 'units', newValue)
  );

export const updateReceiptDateProductOfStock = (
  productOfStock,
  categoryName,
  productName,
  newValue
) =>
  productOfStock.update(productName, (product) =>
    setPropsOfProduct(product, 'receiptDate', newValue)
  );
