import React, { Component } from 'react';
import PropTypes from 'prop-types';
import block from 'bem-cn-lite';
import { translate } from 'react-i18next';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  Modal,
  Form,
  Button,
  FormGroup,
  FormControl,
  ControlLabel,
  HelpBlock
} from 'react-bootstrap';
import './styles/modal_theme_default.styl';

import { ModalContainer } from './elements';
import * as modalActions from '../../action-creators/modal';

const b = block('modal-container');

class ModalInputSubmit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      text: '',
      pristine: true
    };
    this.setSubmitText = this.setSubmitText.bind(this);
  }

  getChildContext() {
    return { b };
  }

  UNSAFE_componentWillMount() {
    const { value } = this.props;
    this.setState({ text: value || '' });
  }

  setSubmitText(e) {
    this.setState({ text: e.target.value });
  }

  isValidNameField() {
    return this.state.text.length < 1 || /^\s+$/.test(this.state.text);
  }

  handleClick = () => {
    const { submit, hideModalDialog, onSubmited } = this.props;

    submit(this.state.text).then(() => {
      hideModalDialog();
      if (onSubmited) onSubmited();
    });
  };

  render() {
    const {
      t,
      title,
      text,
      type,
      textBtnConfirm,
      hideModalDialog,
      placeholder,
      inputText,
      textBtnCancel
    } = this.props;

    return (
      <Modal id="sendOrderToSupplier" onHide={() => hideModalDialog()} show>
        <ModalContainer mods={{ type: type || 'default', theme: 'default' }}>
          <Modal.Header>
            <Modal.Title>
              {t(title)}
              <span
                className="modal-header-cross pull-right"
                onClick={() => hideModalDialog()}
              />
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <FormGroup
              controlId="name"
              style={{ overflow: 'hidden' }}
              validationState={
                !this.state.text && !this.state.pristine ? 'error' : ''
              }>
              <ControlLabel>{t(inputText)}</ControlLabel>
              <FormControl
                type="text"
                placeholder={t(placeholder || '')}
                value={this.state.text}
                maxLength="60"
                onChange={this.setSubmitText}
                onKeyDown={() => this.setState({ pristine: false })}
              />
              <h6 className="pull-right">
                <small>
                  {this.state.text.length}
                  /60
                </small>
              </h6>
              {!this.state.text && !this.state.pristine && (
                <HelpBlock style={{ marginBottom: 0 }}>
                  {t('Required fields empty')}
                </HelpBlock>
              )}
            </FormGroup>
            <Form>
              <p className={b('row')}>{text}</p>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button
              id="cancel"
              className="pull-left"
              onClick={() => hideModalDialog()}>
              {textBtnCancel || t('Cancel')}
            </Button>
            <Button
              id="saveCharacteristicButton"
              bsStyle="primary"
              disabled={this.isValidNameField()}
              onClick={this.handleClick}>
              {textBtnConfirm || t('New group')}
            </Button>
          </Modal.Footer>
        </ModalContainer>
      </Modal>
    );
  }
}

ModalInputSubmit.propTypes = {
  t: PropTypes.func.isRequired
};

ModalInputSubmit.childContextTypes = {
  b: PropTypes.func
};

export default connect(null, (dispatch) =>
  bindActionCreators(
    {
      hideModalDialog: modalActions.hideModalDialog
    },
    dispatch
  )
)(translate(['ui'], { wait: true })(ModalInputSubmit));
