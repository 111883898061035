import React from 'react';
import { useDispatch } from 'react-redux';
import { useQueue } from './useQueue';
import { getFullName } from '../../../utils/utils';
import { sendAllowToJoin, sendRejectJoinRequest } from '../commandsAndEvents';
import { useTranslate } from '../../../TranslateProvider';
import * as modalActions from '../../../action-creators/modal';
import { useVideoChatContext } from './useVideoChatContext';

export function useConfirmForJoined() {
  const dispatch = useDispatch();
  const t = useTranslate();
  const context = useVideoChatContext();
  const incomingRequests = useQueue();

  const receivedNewIncomingRequest = React.useCallback(
    ({ roomId, targetEmployee }) => {
      if (hasSameRequests()) return;

      incomingRequests.add({ ...targetEmployee, roomId });

      function hasSameRequests() {
        return incomingRequests.all.some(
          (r) => r.employeeId === targetEmployee.employeeId
        );
      }
    },
    []
  );

  function showConfirmModal(roomId, employee) {
    const { employeeId, socketId } = employee;

    dispatch(
      modalActions.showModal('SIMPLE_SUBMIT', {
        title: t('videoChat.modals.confirmMember.text', {
          userName: getFullName(employee)
        }),
        textBtnConfirm: 'videoChat.modals.confirmMember.confirmBtn',
        textBtnCancel: 'videoChat.modals.confirmMember.cancelBtn',
        submitAction: () => {
          incomingRequests.remove();
          sendAllowToJoin({ roomId, employeeId, socketId });
        },
        onCancel: () => {
          incomingRequests.remove();
          sendRejectJoinRequest({ roomId, employeeId, socketId });
        },
        isCloseAfterClickBackground: false
      })
    );
  }

  React.useEffect(() => {
    if (incomingRequests.size) {
      if (incomingRequests.first.roomId === context.roomId) {
        showConfirmModal(context.roomId, incomingRequests.first);
      } else {
        incomingRequests.remove();
      }
    }
  }, [incomingRequests.size, context.roomId]);

  return receivedNewIncomingRequest;
}
