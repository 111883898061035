const { makeEvent, makeCommand } = require('core/data/messageBus/message');
const { makeQuery } = require('core/messages/utils');

const NS = 'VIDEO_ROOM';

const createdRoom = makeEvent(NS, 'ROOM_WAS_CREATED');

const allowJoinCMD = makeCommand(NS, 'ALLOW_JOIN');
const allowedJoinEVT = makeEvent(NS, 'ALLOWED_JOIN');

const joinedToRoom = makeEvent(NS, 'JOINED_TO_ROOM');

const leaveRoom = makeCommand(NS, 'LEAVE_ROOM');
const leftRoom = makeEvent(NS, 'LEFT_ROOM');

const joinRequestCMD = makeCommand(NS, 'REQUEST_JOIN_TO_ROOM');

const rejectJoinRequest = makeCommand(NS, 'REJECT_REQUEST_JOIN_TO_ROOM');
const rejectedJoinRequest = makeEvent(NS, 'REJECTED_REQUEST_JOIN_TO_ROOM');

const viewCMD = makeCommand(NS, 'view');
const videoCallQRY = makeQuery(NS, 'video-call');

module.exports = {
  createdRoom,
  allowJoinCMD,
  allowedJoinEVT,
  joinedToRoom,
  leaveRoom,
  leftRoom,
  joinRequestCMD,
  rejectedJoinRequest,
  rejectJoinRequest,
  viewCMD,
  videoCallQRY
};
