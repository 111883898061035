import { getResponseData } from '../utils';
import { open } from '../../../../../modules/response-chat/useCases';

export const redirectCases = (props) => async (dispatch) => {
  const { type } = props.match.params;

  switch (type) {
    case 'response-chat':
      await dispatch(open(getResponseData(props)));
      break;
    default:
      throw new Error(`No such handler for type = ${type}`);
  }
};
