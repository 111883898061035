import React, { useEffect, useReducer, useMemo, isValidElement } from 'react';
import { translate } from 'react-i18next';
import block from 'bem-cn-lite';
import { isNil } from 'ramda';
import './style.css';

const b = block('input-number-range');

const RangeNumbers = ({
  t,
  value = {},
  min,
  max,
  placeholders = {},
  onChange,
  delimiter
}) => {
  if (!onChange)
    throw new Error(`Props onChange should be passed, but got: ${onChange}`);
  if (typeof onChange !== 'function')
    throw new Error(`Props onChange is not a function`);
  if (delimiter && !isValidElement(delimiter))
    throw new Error('Delimiter should be a valid react component.');
  if (!isNil(min) && !isNil(max) && min > max) {
    throw new Error(
      `Min should be less or equal max, but got: Min=${min} and max=${max}`
    );
  }

  const getPlaceholders = initGetPlaceholder(t, placeholders);

  const [state, dispatch] = useReducer(reducer, {
    from: value.from,
    to: value.to,
    min,
    max
  });

  const actions = useMemo(
    () => ({
      setFrom: (event) =>
        dispatch({ type: 'set_from', payload: event.target.value }),
      setTo: (event) =>
        dispatch({ type: 'set_to', payload: event.target.value }),
      finishChange: (payload) => dispatch({ type: 'finish_change', payload })
    }),
    []
  );

  useEffect(() => {
    if (value.from !== state.from || value.to !== state.to) {
      actions.finishChange(value);
    }
  }, [value]);

  const onFinishChange = (event) => {
    let finishedValue;

    if (event.target.name === 'from') finishedValue = finishChangeFrom(state);
    if (event.target.name === 'to') finishedValue = finishChangeTo(state);

    actions.finishChange(finishedValue);

    onChange(finishedValue);
  };

  return (
    <div className={b()}>
      <input
        name="from"
        inputMode="numeric"
        className={b('input')}
        value={state.from}
        onChange={actions.setFrom}
        onBlur={onFinishChange}
        placeholder={getPlaceholders('from')}
      />
      {delimiter || <div className={b('delimiter')}>—</div>}
      <input
        name="to"
        inputMode="numeric"
        className={b('input')}
        value={state.to}
        onChange={actions.setTo}
        onBlur={onFinishChange}
        placeholder={getPlaceholders('to')}
      />
    </div>
  );
};

function reducer(state, action) {
  switch (action.type) {
    case 'set_from':
      if (!isCorrectValue(action.payload))
        return incorrectValueCase('from', state);

      return { ...state, from: normalizeValue(action.payload) };
    case 'set_to':
      if (!isCorrectValue(action.payload))
        return incorrectValueCase('to', state);

      return { ...state, to: normalizeValue(action.payload) };
    case 'finish_change':
      return { ...state, from: action.payload.from, to: action.payload.to };
    default:
      throw new Error(`No such handler for action type=${action.type}.`);
  }
}

function incorrectValueCase(type, state) {
  return { ...state, [type]: isNil(state[type]) ? 0 : state[type] };
}

function isCorrectValue(value) {
  if (value === '') return true;

  return /^\d+$/.test(value);
}

function normalizeValue(value) {
  if (value === '') return '';
  return +value.toString().replace(/^0+/, '');
}

function finishChangeFrom(state) {
  let value = state.from;

  if (!isNil(state.min)) value = Math.max(state.min, state.from);

  return { from: value, to: calcTo(value, state.to) };
}

function finishChangeTo(state) {
  let value = state.to;

  if (!isNil(state.max)) value = Math.min(state.max, state.to);

  return { from: calcFrom(state.from, value), to: value };
}

function calcFrom(from, to) {
  if (to === '') return from;
  if (from !== 0 && !from) return from;

  return Math.min(to, from);
}

function calcTo(from, to) {
  if (from === '') return to;
  if (to !== 0 && !to) return to;

  return Math.max(to, from);
}

function initGetPlaceholder(t, placeholders = {}) {
  return (type) => {
    if (type === 'from' || type === 'to')
      return placeholders[type] || t(`rangeInput.placeholders.${type}`);

    throw new Error(`No such handler for type=${type}.`);
  };
}

export default translate()(RangeNumbers);
