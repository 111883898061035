import React, { useCallback } from 'react';
import { useTranslate } from '@hooks';
import { useSelector } from 'react-redux';
import { CollapsibleArea } from '@link/react-components';
import { Typography } from '../../../shared/components';
import { SelectIcon } from './SelectIcon';
import { ChatIcon } from './ChatIcon';
import styles from './approvalLog.module.css';
import { useOpenChat } from '../../OpenChat/useOpenChat';
import api from '../../../lib/request';
import { getFullName } from '../../../utils/utils';

import { executorActionTypes } from '../domain/entities';
import * as storeGetters from '../../../storeGetters';
import { mapExecutors } from '../MyRequests/RequestTable/utils';
import { format } from '../../../lib/date';

const VisibleContent = () => {
  const t = useTranslate();
  return (
    <div className={styles.title}>
      <Typography variant="h2">
        {t('purchaseRequest.card.approvalLog.title')}
      </Typography>
    </div>
  );
};

const CollapsibleContent = ({ approvalLogInfo }) => {
  const t = useTranslate();
  return (
    <>
      <div className={styles.collapsibleContent}>
        <Typography variant="body1Med">
          <div className={styles.generalInfo}>
            <div>
              <span className={styles.generalInfoTitle}>
                {t('purchaseRequest.card.approvalLog.author')}
              </span>
              <span className={styles.generalInfoContent}>
                {getFullName(approvalLogInfo?.authorInfo)}
              </span>
            </div>
            <div>
              <span className={styles.generalInfoTitle}>
                {t('purchaseRequest.card.approvalLog.ETA')}
              </span>
              <span className={styles.generalInfoContent}>
                {format(approvalLogInfo.date, 'dd/MM/yyyy')}
              </span>
            </div>
          </div>
        </Typography>
        <hr className={styles.horizontalRule} />
        <Typography variant="body1Med">
          <div className={styles.logContent}>
            <div className={styles.logList}>
              <div className={styles.logListTitle}>
                {t('purchaseRequest.card.approvalLog.reviewed')}
              </div>
              <ApprovalLogContent
                type={executorActionTypes.agreement}
                data={approvalLogInfo.executors}
              />
            </div>
            <div className={styles.logList}>
              <div className={styles.logListTitle}>
                {t('purchaseRequest.card.approvalLog.notified')}
              </div>
              <ApprovalLogContent
                type={executorActionTypes.introduction}
                data={approvalLogInfo.executors}
              />
            </div>
          </div>
        </Typography>
        {approvalLogInfo.isShowDisclaimer && (
          <Typography variant="body1Med">
            <div className={styles.disclaimer}>
              {t('purchaseRequest.card.approvalLog.disclaimer')}
            </div>
          </Typography>
        )}
      </div>
    </>
  );
};

const ApprovalLogContent = ({ type, data }) =>
  data
    .filter((item) => item.actionType === type)
    .map((item) => <EmployeeContent key={item.id} employee={item} />);

const EmployeeContent = ({ employee }) => {
  const user = useSelector(storeGetters.getCurrentUser);

  const getChat = useCallback(
    () =>
      api
        .get(`/api/dialog/by-employeeId/${employee.id}`)
        .then((dialogId) => api.get(`/api/channel/${dialogId}`))
        .catch((e) => console.log(`Approval Log error: ${e}`)),
    [employee.id]
  );

  const handleChatClick = useOpenChat({ getChat, isWidget: true });
  return (
    <div className={styles.logListContent}>
      <SelectIcon status={employee.status} />
      <div className={styles.personName}>
        {getFullName({
          firstName: employee?.firstName,
          middleName: employee?.middleName,
          lastName: employee?.lastName
        })}
      </div>
      {employee?.department && (
        <div className={styles.personTitle}>{employee.department}</div>
      )}
      {user.toJS().employeeId !== employee.id && (
        <ChatIcon className={styles.iconShow} onCLick={handleChatClick} />
      )}
    </div>
  );
};

export default function ApprovalLog({
  isCollapsed,
  className,
  request,
  isShowDisclaimer = true
}) {
  const approvalLogInfo = {
    authorInfo: request?.authorInfo,
    date: request.date,
    executors: mapExecutors(request.executors),
    isShowDisclaimer
  };

  return (
    <CollapsibleArea
      isCollapsed={isCollapsed}
      className={className}
      visibleContent={<VisibleContent />}
      collapsibleContent={
        <CollapsibleContent approvalLogInfo={approvalLogInfo} />
      }
    />
  );
}
