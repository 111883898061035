import React from 'react';
import { bindActionCreators } from 'redux';
import {
  Modal,
  Col,
  Form,
  FormGroup,
  ControlLabel,
  Button,
  InputGroup
} from 'react-bootstrap';
import { connect } from 'react-redux';
import { translate } from 'react-i18next';
// import block from 'bem-cn-lite';

import InputNumber from '../../InputNumber';
import { Select } from '../../FormControls';
import Helper from '../../Helper';

import * as modalActionCreators from '../../../action-creators/modal';
import * as priceActions from '../../../action-creators/prices';
import * as servicesAction from '../../../action-creators/services';

import { getProductUnits } from '../../../utils';

// const b = block('modal-container');

const ModalAddPriceQuantityCategory = translate(['ui'], { wait: true })(
  class extends React.Component {
    constructor(props) {
      super(props);
      this.inputProps = this.inputProps.bind(this);
      this.validateInteger = this._validateInteger.bind(this);
      this.validateCategory = this.validateCategory.bind(this);
      this.setRoundUp = this.setRoundUp.bind(this);
    }

    UNSAFE_componentWillMount() {
      const { updateNewCategory } = this.props;
      updateNewCategory('type', 2);
    }

    componentWillUnmount() {
      const { clearNewCategory } = this.props;
      clearNewCategory();
    }

    getProperty(field) {
      return this.props.newPriceCategory &&
        this.props.newPriceCategory.has(field)
        ? this.props.newPriceCategory.get(field)
        : '';
    }

    validationState(field) {
      const { newPriceCategory } = this.props;
      return newPriceCategory && newPriceCategory.get(field) ? '' : 'error';
    }

    getDiscountValidationState() {
      const { newPriceCategory } = this.props;
      return (newPriceCategory &&
        newPriceCategory.get('discount') >= 0 &&
        newPriceCategory.get('discount') < 100) ||
        (newPriceCategory && newPriceCategory.get('discount') === '')
        ? ''
        : 'error';
    }

    validateCategory() {
      const { newPriceCategory, priceCategories } = this.props;
      if (!newPriceCategory) {
        return 'error';
      }

      const categories = priceCategories
        .filter(
          (i) =>
            i.get('type') === 2 &&
            i.get('unit') === newPriceCategory.get('unit')
        )
        .toJS()
        .map((i) => {
          if (i.to === 0) {
            // eslint-disable-next-line no-param-reassign
            i.to = Infinity;
          }
          return i;
        });

      if (
        !categories.length ||
        (!newPriceCategory.get('from') && !newPriceCategory.get('to'))
      ) {
        return '';
      }
      let collise = false;
      categories.forEach((i) => {
        collise =
          collise ||
          !(
            (newPriceCategory.get('from') < i.from &&
              (newPriceCategory.get('to')
                ? newPriceCategory.get('to') < i.from
                : false)) ||
            (newPriceCategory.get('from') > i.to &&
              (newPriceCategory.get('to')
                ? newPriceCategory.get('to') > newPriceCategory.get('from')
                : true))
          );
      });

      return collise ? 'error' : '';
    }

    asterisk() {
      return <span style={{ color: 'red' }}>*</span>;
    }

    showHelpInfo(field) {
      const { t } = this.props;
      if (field === 'range' && this.validateCategory()) {
        return (
          <div id="invalidFieldMessage" className="help-message">
            {t('Range must by unique')}
          </div>
        );
      }
      return null;
    }

    inputProps(field) {
      const { updateNewCategory } = this.props;
      return {
        value: this.getProperty(field),
        onChange: (value) => updateNewCategory(field, value)
      };
    }

    setRoundUp(value) {
      const { updateNewCategory } = this.props;
      if (this.getProperty('roundUp') === value) {
        updateNewCategory('roundUp', null);
      } else {
        updateNewCategory('roundUp', value);
      }
    }

    _validateInteger(e) {
      const isInteger = /[0-9]/g.test(e.key);
      if (!isInteger) {
        e.preventDefault();
      }
    }

    getProductUnits() {
      const { t, units, unitCodes, user } = this.props;
      return getProductUnits({ t, units, unitCodes, user, shortLabels: true });
    }

    render() {
      const {
        t,
        captionKey,
        hideModalDialog,
        createPriceCategory,
        saveDisabled,
        updateNewCategory,
        newPriceCategory,
        clearNewCategory,
        gaSend,
        user
      } = this.props;

      return (
        <Modal
          id="productCharacteristicsModalWindow"
          onHide={() => {
            hideModalDialog();
          }}
          show>
          <Modal.Header>
            <Modal.Title>
              {t(captionKey)}
              <span
                className="modal-header-cross pull-right"
                onClick={() => {
                  hideModalDialog();
                  clearNewCategory();
                }}
              />
            </Modal.Title>
          </Modal.Header>
          <div className="modal_header_text">
            {t('Range quantity header text')}
          </div>
          <Modal.Body>
            <Form horizontal>
              <FormGroup
                controlId="discount"
                validationState={this.validateCategory()}>
                <Col
                  componentClass={ControlLabel}
                  xs={8}
                  className="modal-label">
                  {`${t('Range quantity text')} `}
                  {this.asterisk()}
                </Col>
                <div className="col-modal xs-4" style={{ display: 'flex' }}>
                  <div style={{ padding: '5px 0 0 0' }}>
                    <div style={{ padding: '0 5px 0 0' }}>{t('minvalue')}</div>
                  </div>
                  <InputNumber
                    min={1}
                    defaultValue=""
                    placeholder={1}
                    {...this.inputProps('from')}
                    width={70}
                    className="pale-placeholder"
                  />
                  <div style={{ padding: '5px 0 0 0' }}>
                    <div style={{ padding: '0 5px' }}>{t('maxvalue')}</div>
                  </div>
                  <InputNumber
                    min={0}
                    defaultValue=""
                    placeholder={0}
                    {...this.inputProps('to')}
                    width={70}
                    className="pale-placeholder"
                  />
                </div>
                {this.showHelpInfo('range')}
              </FormGroup>
              <FormGroup controlId="units">
                <Col
                  componentClass={ControlLabel}
                  xs={8}
                  className="modal-label">
                  {`${t('Units')} `}
                  <Helper text="price_list_unit_help" />
                </Col>
                <div className="col-modal xs-4">
                  <Select
                    value={newPriceCategory.get('unit')}
                    onChange={(e) =>
                      updateNewCategory('unit', e.value ? `${e.value}` : null)
                    }
                    options={this.getProductUnits()}
                    placeholder={t('not selected')}
                    clearable={false}
                    searchable={false}
                  />
                </div>
              </FormGroup>
              <FormGroup
                controlId="discount"
                validationState={this.getDiscountValidationState()}>
                <Col
                  componentClass={ControlLabel}
                  xs={8}
                  className="modal-label">
                  {t('discount')}
                </Col>
                <div className="col-modal xs-4">
                  <InputGroup>
                    <InputNumber
                      defaultValue={this.getProperty('discount')}
                      value={this.getProperty('discount')}
                      min={0}
                      max={100}
                      onChange={(e) => updateNewCategory('discount', e)}
                      width={146}
                      className="catalog-basic-information"
                    />
                    <InputGroup.Addon>%</InputGroup.Addon>
                  </InputGroup>
                </div>
              </FormGroup>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <div
              style={{
                textAlign: 'left',
                paddingBottom: '10px',
                color: '#777'
              }}>
              <span>
                {this.asterisk()}
                {` ${t('Required fields')}`}
              </span>
            </div>
            <Button
              id="cancel"
              className="pull-left"
              onClick={() => hideModalDialog()}>
              {t('Cancel')}
            </Button>
            <Button
              id="saveCharacteristicButton"
              bsStyle="primary"
              disabled={saveDisabled || this.validateCategory()}
              onClick={async () => {
                await gaSend({
                  category: 'Supplier',
                  action: 'discount_batch_size_success',
                  label: user.get('email')
                });
                await createPriceCategory();
                return hideModalDialog();
              }}>
              {t('Save')}
            </Button>
          </Modal.Footer>
        </Modal>
      );
    }
  }
);

const mapStateToProps = (state) => {
  const newPriceCategory = state.getIn(['prices', 'newPriceCategory']);
  const serilizedPriceCategory = newPriceCategory
    .set('discount', +newPriceCategory.get('discount'))
    .set('to', +newPriceCategory.get('to'))
    .set('from', +newPriceCategory.get('from'))
    .set('unit', newPriceCategory.get('unit') || null);

  return {
    user: state.getIn(['user', 'user']),
    unitCodes: state.getIn(['stocks', 'unitCodes']),
    units: state.getIn(['prices', 'units']),
    language: state.getIn(['user', 'user', 'language']),
    price: state.getIn(['prices', 'price']),
    priceCategories: state.getIn(['prices', 'priceCategories']),
    newPriceCategory: serilizedPriceCategory,
    saveDisabled: !(
      serilizedPriceCategory &&
      serilizedPriceCategory.get('from') &&
      (serilizedPriceCategory.get('to') >= serilizedPriceCategory.get('from') ||
        serilizedPriceCategory.get('to') === 0) &&
      serilizedPriceCategory.get('discount') < 100 &&
      serilizedPriceCategory.get('discount') >= 0
    )
  };
};

export default connect(mapStateToProps, (dispatch) =>
  bindActionCreators(
    {
      updateNewCategory: priceActions.updateNewCategory,
      createPriceCategory: priceActions.createPriceCategory,
      clearNewCategory: priceActions.clearNewCategory,
      hideModalDialog: modalActionCreators.hideModalDialog,
      gaSend: servicesAction.gaSend
    },
    dispatch
  )
)(ModalAddPriceQuantityCategory);
