import React, { Component } from 'react';

import { translate } from 'react-i18next';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { fromJS } from 'immutable';
import { isEmpty, equals, adjust } from 'ramda';
import { withRouter } from '../../../hoc/withRouter';

import {
  optionSelect,
  creatableProps
} from '../../../viewModelAdapters/components/form';
import View from './View';

import * as serviceActions from '../../../action-creators/services';
import * as requestsActions from '../../../action-creators/purchaseRequests';

import keyDebounce from '../../../utils/debounce';
import { makeFilters } from '../../../utils/utils';
import * as dictionariesStorage from '../../../modules/dictionaries/storage';
import * as dictionariesGetters from '../../../modules/dictionaries/getters';
import * as prGetters from '../../../components/PurchaseRequests/getters';

const DEFAULT_COUNTRY_ID = '3159'; // Россия
const DEFAULT_FILTER = {
  country: '',
  region: [],
  city: [],
  sum: [{ from: '', to: '' }],
  buyers: [],
  categories: []
};

class PurchaseRequestsFilterSidebar extends Component {
  constructor(props) {
    super(props);
    this.getAllOrderStatusOptions = this.getAllOrderStatusOptions.bind(this);
    this.applyFilters = this.applyFilters.bind(this);
    this.viewModel = this.viewModel.bind(this);
    this.isFilterChange = this.isFilterChange.bind(this);
    this.getLocationsSelectList = this.getLocationsSelectList.bind(this);
    this.onChangeCountry = this.onChangeCountry.bind(this);
    this.onChangeRegion = this.onChangeRegion.bind(this);
    this.onChangeCity = this.onChangeCity.bind(this);
    this.onCountrySearch = keyDebounce(this.onCountrySearch.bind(this), 500);
    this.onRegionSearch = keyDebounce(this.onRegionSearch.bind(this), 500);
    this.onCitySearch = keyDebounce(this.onCitySearch.bind(this), 500);
    this.addSumInput = this.addSumInput.bind(this);
    this.onChangeCategories = this.onChangeCategories.bind(this);
    this.onChangeSum = this.onChangeSum.bind(this);
    this.onChangeBuyers = this.onChangeBuyers.bind(this);
    this.onSearchBuyers = this.onSearchBuyers.bind(this);
    this.onKeyDownBuyers = this.onKeyDownBuyers.bind(this);

    this.state = {
      ...DEFAULT_FILTER,
      lastLanguage: ''
    };
  }

  UNSAFE_componentWillMount() {
    const { getCountries, getRegions, getCities, getBuyers, getCategories } =
      this.props;
    getBuyers();
    getCategories();

    const filter = JSON.parse(localStorage.getItem(this.LSKey));
    if (!filter) {
      localStorage.setItem(this.LSKey, JSON.stringify(this.state));
    } else {
      this.setState({
        country: filter.country,
        region: filter.region,
        city: filter.city,
        sum: filter.sum,
        buyers: filter.buyers,
        categories: filter.categories
      });
      if (filter.country) {
        getCountries(null, filter.country);
        if (filter.region) {
          getRegions(filter.country, null, filter.region);
        }
        if (filter.city) {
          getCities(filter.country, null, null, filter.city);
        }
      }
    }
  }

  async UNSAFE_componentWillReceiveProps(props) {
    const { user } = props;
    const { getCountries, getRegions, getCities } = this.props;

    if (user.get('language') && !this.state.lastLanguage) {
      this.setState({ lastLanguage: user.get('language') });
    }
    if (
      this.state.lastLanguage &&
      user.get('language') !== this.state.lastLanguage
    ) {
      this.setState({ lastLanguage: user.get('language') });
      getCountries(null, this.state.country, user.get('language'));
      if (!isEmpty(this.state.region)) {
        getRegions(
          this.state.country,
          null,
          this.state.region,
          user.get('language')
        );
      }
      if (!isEmpty(this.state.city)) {
        getCities(
          this.state.country,
          this.state.region,
          null,
          this.state.city,
          user.get('language')
        );
      }
    }
  }

  get LSKey() {
    const { user } = this.props;

    const key = 'purchase-requests-filter';

    if (user.size > 0) {
      return `${key}--u-${user.get('id')}`;
    }

    return key;
  }

  isRemoveRegion(prevRegions) {
    const { region } = this.state;

    return prevRegions.length > region.length;
  }

  addSumInput() {
    this.setState((prevState) => ({
      sum: prevState.sum.concat({ from: '', to: '' })
    }));
  }

  onChangeCategories(value) {
    if (!value) return;

    if (Array.isArray(value)) {
      this.setState({ categories: value });
    }
  }

  onChangeBuyers(value) {
    if (!value) return;

    if (Array.isArray(value)) {
      this.setState({ buyers: value });

      if (isEmpty(value)) {
        this.props.getBuyers();
      }
    }
  }

  onSearchBuyers(value) {
    const { getBuyers } = this.props;

    if (value) {
      getBuyers(value);
    }
  }

  onKeyDownBuyers(event) {
    const { buyers } = this.state;

    if (event.keyCode === 13) {
      this.onChangeBuyers({ buyers: [...buyers, event.target.value] });
    }
  }

  async componentDidUpdate(prevProps, prevState) {
    const { user, getRegionsCount } = this.props;
    const { country } = this.state;
    if (country && prevState.country !== country) {
      getRegionsCount(country, user.get('language'));
    }

    if (this.isRemoveRegion(prevState.region)) {
      this.purgeCities();
    }
  }

  purgeCities() {
    const { cleanCities } = this.props;
    this.setState({ city: [] });
    cleanCities();
  }

  getLocationsSelectList(items, type) {
    const { t } = this.props;
    let itemsList = items;
    if (type !== 'region' && type !== 'city') {
      if (
        this.state[type] &&
        !items.find((o) => `${o.get('id')}` === this.state[type])
      ) {
        itemsList = itemsList.unshift(
          fromJS({ id: null, name: this.state[type] })
        );
      }
      itemsList = itemsList.unshift(
        fromJS({ id: null, name: t('Not_selected') })
      );
    }

    return itemsList
      .map((i) => ({
        value: `${i.get('id')}`,
        label: i.get('name')
      }))
      .toJS();
  }

  getAllOrderStatusOptions(value) {
    const { t, statuses } = this.props;

    // Add current value if it is not in options
    let s = [...new Set([...statuses.toJS(), ...value])];
    s = s.map((name) => optionSelect(name, t(name)));

    return s;
  }

  isFilterChange() {
    const DEFAULT = {
      country: '',
      region: [],
      city: [],
      currency: '',
      sum: { from: '', to: '' },
      completed: false,
      accepted: false,
      available: false
    };
    return equals(DEFAULT, this.state);
  }

  getSelectedCategoriesId() {
    return this.props.selectedCategoriesId.toJS();
  }

  applyFilters(e, clear = false) {
    const { limit, offset, search, order, direction, user, showMode, gaSend } =
      this.props;
    const { categories } = this.state;
    if (clear) {
      this.setState(DEFAULT_FILTER);
      localStorage.setItem(this.LSKey, JSON.stringify(DEFAULT_FILTER));
      return this.props.getAllRequests({
        limit,
        offset,
        search,
        order,
        selectedCategoriesId: [],
        direction,
        showMode
      });
    }

    gaSend({
      category: 'Common',
      action: 'filter_request',
      label: user.get('email')
    });
    const filters = makeFilters(this.state);
    localStorage.setItem(this.LSKey, JSON.stringify(this.state));
    return this.props.getAllRequests({
      limit,
      offset,
      search,
      order,
      selectedCategoriesId: categories,
      direction,
      showMode,
      filters
    });
  }

  onChangeCountry(val) {
    if (!val) return;
    const value = typeof val === 'string' ? val : val.value;
    const { cleanRegion, cleanCities } = this.props;
    const country = value === 'null' ? null : value;
    this.setState({ country, region: [], city: [] });
    cleanRegion();
    cleanCities();
  }

  setDefaultCountry() {
    const { cleanCities, getCountries } = this.props;
    this.setState({ country: DEFAULT_COUNTRY_ID, city: [] });
    getCountries(null, DEFAULT_COUNTRY_ID);
    cleanCities();
  }

  onChangeRegion(val) {
    if (!val) return;
    if (!this.state.country) this.setDefaultCountry();

    const { cleanRegion, cleanCities } = this.props;

    if (Array.isArray(val)) {
      this.setState({ region: val });
    }

    if (typeof val === 'string') {
      this.setState({ region: [], city: [] });
      cleanRegion();
      cleanCities();
    }
  }

  onChangeCity(val) {
    if (!val) return;

    const { cleanCities } = this.props;

    if (Array.isArray(val)) {
      this.setState({ city: val });
    }

    if (typeof val === 'string') {
      cleanCities();
    }
  }

  onCountrySearch(value) {
    const { getCountries } = this.props;
    if (value) {
      getCountries(value);
    }
  }

  onRegionSearch(value) {
    const { getRegions, cleanRegion } = this.props;
    const { country } = this.state;
    if (value) {
      if (!country) this.setDefaultCountry();

      getRegions(country || DEFAULT_COUNTRY_ID, value);
    } else {
      cleanRegion();
    }
  }

  onCitySearch(value) {
    const { getCities } = this.props;
    const { country, region } = this.state;
    if (value) {
      getCities(country, region, value);
    }
  }

  onChangeSum(index, value) {
    this.setState((prevState) => ({
      sum: adjust(index, () => value, prevState.sum)
    }));
  }

  get isUseNoRegionsHint() {
    const { regionsCount } = this.props;
    const { country } = this.state;

    return country && regionsCount === 0;
  }

  viewModel() {
    const {
      t,
      countries,
      regions,
      cities,
      cleanCities,
      cleanRegion,
      buyersList,
      categoriesList
    } = this.props;

    return {
      titleSelectCountry: t('Delivery country'),
      titleSelectRegion: t('Delivery region'),
      titleSelectCity: t('Delivery city/locality'),
      countrySelect: creatableProps(
        this.getLocationsSelectList(countries, 'country'),
        this.state.country,
        (value) => this.onChangeCountry(value),
        (value) => this.onCountrySearch(value),
        (e) => {
          if (e.keyCode === 13) {
            this.onChangeCountry(e.target.value);
          }
        }
      ),
      isUseNoRegionsHint: this.isUseNoRegionsHint,
      regionSelect: creatableProps(
        this.getLocationsSelectList(regions, 'region'),
        this.state.region,
        (value) => this.onChangeRegion(value),
        (value) => this.onRegionSearch(value),
        (e) => {
          if (e.keyCode === 13) {
            const region = [...this.state.region, e.target.value];
            this.onChangeRegion(region);
          }
        },
        () => {
          if (!this.state.region) {
            cleanRegion();
          }
        }
      ),
      citySelect: creatableProps(
        this.getLocationsSelectList(cities, 'city'),
        this.state.city,
        (value) => this.onChangeCity(value),
        (value) => this.onCitySearch(value),
        (e) => {
          if (e.keyCode === 13) {
            const city = [...this.state.city, e.target.value];
            this.onChangeCity(city);
          }
        },
        () => {
          if (!this.state.city) {
            cleanCities();
          }
        }
      ),
      onFilter: this.applyFilters,
      isFilterChange: this.isFilterChange,
      sum: {
        title: `${t('sum_request')}, ${t('rub')}.`,
        list: this.state.sum,
        onChange: this.onChangeSum,
        onAddInput: this.addSumInput
      },
      partner: {
        title: t('Customer'),
        select: {
          value: this.state.buyers,
          options: buyersList,
          onChange: this.onChangeBuyers,
          onInputChange: this.onSearchBuyers,
          onInputKeyDown: this.onKeyDownBuyers
        }
      },
      categories: {
        title: t('category_goods_and_services'),
        selectedIds: this.state.categories,
        onChoose: this.onChangeCategories,
        categoriesList,
        placeholder: t('purchaseRequest.filter.placeholders.default')
      }
    };
  }

  render() {
    return <View {...this.viewModel()} />;
  }
}

export default withRouter(
  connect(
    (state) => ({
      user: state.getIn(['user', 'user']),
      countries: state.getIn(['purchaseRequests', 'countries']),
      regionsCount: state.getIn(['purchaseRequests', 'regionsCount']),
      regions: state.getIn(['purchaseRequests', 'regions']),
      cities: state.getIn(['purchaseRequests', 'cities']),
      limit: state.getIn(['purchaseRequests', 'limit']),
      offset: state.getIn(['purchaseRequests', 'offset']),
      search: state.getIn(['purchaseRequests', 'search']),
      order: state.getIn(['purchaseRequests', 'order']),
      direction: state.getIn(['purchaseRequests', 'direction']),
      buyersList: dictionariesGetters.getCompanies(state),
      showMode: prGetters.getShowMode(state),
      categoriesList: dictionariesGetters.getPurchaseRequestsCategories(state)
    }),
    (dispatch) =>
      bindActionCreators(
        {
          getBuyers: dictionariesStorage.getCompanies,
          getCategories: dictionariesStorage.getPurchaseRequestsCategories,
          getAllRequests: requestsActions.getAllRequests,
          getCountries: requestsActions.getCountries,
          getRegionsCount: requestsActions.getRegionsCount,
          getRegions: requestsActions.getRegions,
          getCities: requestsActions.getCities,
          cleanCountries: requestsActions.cleanCountries,
          cleanRegion: requestsActions.cleanRegion,
          cleanCities: requestsActions.cleanCities,
          gaSend: serviceActions.gaSend
        },
        dispatch
      )
  )(translate(['ui'], { wait: true })(PurchaseRequestsFilterSidebar))
);
