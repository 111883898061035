import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useActions } from '../../../../hooks/useActions';
import * as storeGetters from '../../../../storeGetters';
import * as modalActions from '../../../../action-creators/modal';
import * as alertMessageActions from '../../../../action-creators/message';
import * as storageActions from '../../../../action-creators/storage';
import * as purchaseRequestActions from '../../../../action-creators/purchaseRequests';
import * as purchaseResponseStorage from '../../../../components/PurchaseRequests/Responses/storage';
import * as serviceActions from '../../../../action-creators/services';
import { actions as purchaseResponseActions } from '../../../../components/PurchaseRequests/Responses/reducer';
import RespondMain from '../../../../components/PurchaseRequests/Request/RespondMain';
import { getPurchaseRequest } from '../../../../components/PurchaseRequests/message';
import GoBack from '../../../../components/Buttons/GoBack';
import './style.css';

const PurchaseResponseContent = ({ requestId, responseId }) => {
  const company = useSelector(storeGetters.getCompany);
  const currentUser = useSelector(storeGetters.getCurrentUser);
  const request = useSelector((state) =>
    state.getIn(['purchaseRequests', 'request'])
  );
  const havePermissionToUpdate = useSelector(
    storeGetters.haveUpdatePurchaseRequestsPermission
  );

  const {
    showModal,
    setInfoMessage,
    setErrorMessage,
    uploadFile,
    removeFileLinkBySection,
    sendRespondToRequest,
    saveDraftRespond,
    deleteResponse,
    getRequestResponse,
    purgeResponse,
    cleanRequest,
    gaSend
  } = useActions({
    ...modalActions,
    ...alertMessageActions,
    ...storageActions,
    ...purchaseRequestActions,
    ...purchaseResponseStorage,
    ...purchaseResponseActions,
    ...serviceActions
  });

  useEffect(() => {
    getRequestResponse(responseId);

    getPurchaseRequest({
      requestId,
      language: currentUser.get('language'),
      userId: currentUser.get('id'),
      currentCompany: currentUser.get('currentCompany')
    });

    return () => {
      purgeResponse();
      cleanRequest();
    };
  }, []);

  if (!request.size) {
    return null;
  }

  return (
    <div className="form-content-center form-request">
      <div className="form-request-htmltag">
        <div className="form-request-btn-back">
          <GoBack />
        </div>
        <RespondMain
          request={request}
          requestId={requestId}
          company={company}
          user={currentUser}
          havePermissionToUpdate={havePermissionToUpdate}
          setInfoMessage={setInfoMessage}
          setErrorMessage={setErrorMessage}
          showModal={showModal}
          uploadFile={uploadFile}
          removeFileLinkBySection={removeFileLinkBySection}
          sendRespondToRequest={sendRespondToRequest}
          saveDraftRespond={saveDraftRespond}
          deleteResponse={deleteResponse}
          isResponsePage
          gaSend={gaSend}
        />
      </div>
    </div>
  );
};

export default PurchaseResponseContent;
