import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Col,
  Form,
  FormGroup,
  FormControl,
  Button,
  HelpBlock,
  ControlLabel,
  InputGroup
} from 'react-bootstrap';
import { fromJS } from 'immutable';
import InputMask from 'react-input-mask';
import './styles/forms_type_edit-price.styl';
import './styles/forms_theme_default.styl';
import ImageControl from '../ImageControl';
import { validateEmail } from '../../utils/validateField';
import { isMessengerService } from '../../lib/goodwix';
import { checkIsGoodwixCompanyById } from '../../modules/company/lib';

const LOGIN_ERRORS = ['loginMatch'];

class FormEditUser extends Component {
  static isEmailValid(value) {
    if (value !== '') {
      return validateEmail(value);
    }
    return true;
  }

  static checkError(type) {
    return this.state.errors.some((error) => error.type === type);
  }

  constructor(props) {
    super(props);
    this.onSave = this.onSave.bind(this);
    this.onChange = this.onChange.bind(this);
    this.showModalChangePassword = this.showModalChangePassword.bind(this);
    this.onSetImg = this.onSetImg.bind(this);
    this.onDelImg = this.onDelImg.bind(this);
    this.state = {
      login: '',
      errors: [],
      lastName: '',
      firstName: '',
      middleName: '',
      email: '',
      phoneNumber: '',
      avatar: '',
      position: '',
      department: ''
    };
    this.setStateError = this.setStateError.bind(this);
    this.showError = this.showError.bind(this);
    this.checkError = this.checkError.bind(this);
    this.showValidLoginInfo = this.showValidLoginInfo.bind(this);
    this.clearLoginErrors = this.clearLoginErrors.bind(this);
  }

  UNSAFE_componentWillMount() {
    this.setDefaultPropsToState();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.isUploaded(nextProps) && !this.state.isSetDefaultProps) {
      this.setDefaultPropsToState(nextProps);
      this.setState({ isSetDefaultProps: true });
    }
    if (this.props !== nextProps) {
      this.setDefaultPropsToState(nextProps);
    }
  }

  componentWillUnmount() {
    this.props.setSuccessMessage();
  }

  get isGoodwixMember() {
    const { user } = this.props;

    return Boolean(
      user &&
        user.toJS() &&
        checkIsGoodwixCompanyById(user.toJS().currentCompany)
    );
  }

  onSetImg() {
    const { showModal, uploadAvatar } = this.props;
    showModal('SAVE_IMG_URL', {
      submit: (url) => {
        this.setState({ avatar: url });
        return Promise.resolve();
      },
      upload: async ({ file }) => {
        const src = await uploadAvatar(file);
        this.setState({ avatar: src });
      }
    });
  }

  onDelImg() {
    this.setState({ avatar: '' });
  }

  onSave() {
    const { gaSend, user, saveUser, setSuccessMessage } = this.props;
    this.setStateError();
    if (
      this.getUserValidationState() &&
      FormEditUser.isEmailValid(this.state.email)
    ) {
      gaSend({
        category: 'Common',
        action: 'save_profile_data',
        label: user.get('email')
      });
      saveUser(this.state)
        .then(() => setSuccessMessage({ key: 'The data was saved' }))
        .catch((err) => this.setStateError(err.errors));
    }
  }

  onChange(e, field) {
    if (e.target.name === 'login') this.clearLoginErrors();
    this.setState({ [field]: e.target.value });
  }

  setDefaultPropsToState(props) {
    const properties = props || this.props;
    const {
      login,
      firstName,
      lastName,
      middleName,
      email,
      phoneNumber,
      avatar,
      type,
      position,
      department
    } = properties.user.toJS();
    this.setState({
      login,
      firstName,
      lastName,
      middleName,
      email: type === 'demo' ? 'demo_user@goodwix.com' : email,
      phoneNumber: phoneNumber || '',
      avatar,
      position,
      department
    });
  }

  setStateError(errors = []) {
    this.setState({ errors });
  }

  getProperty(field) {
    return this.state[field] || '';
  }

  getUserValidationState() {
    const { firstName, lastName, email, login } = this.state;
    return Boolean(firstName && lastName && email && login);
  }

  getFieldValidationState(field) {
    if (!this.isUploaded()) return true;

    return this.state[field];
  }

  showHelpInfo(field) {
    if (!this.isUploaded()) return null;

    const { t } = this.props;
    if (!this.getFieldValidationState(field)) {
      return (
        <HelpBlock id="invalidFieldMessage" style={{ color: 'red' }}>
          {t('Required fields empty')}
        </HelpBlock>
      );
    }
    return null;
  }

  showError(type) {
    if (!this.isUploaded()) return null;
    const { t } = this.props;
    return this.state.errors.map((error) =>
      error.type === type ? (
        <HelpBlock id="invalidFieldMessage" style={{ color: 'red' }}>
          {t(error.msg)}
        </HelpBlock>
      ) : null
    );
  }

  checkError(type) {
    const { errors } = this.state;
    const isInArray = (el) => el.type === type;
    return errors.some(isInArray);
  }

  showValidMailInfo() {
    if (!this.isUploaded()) return null;

    const { t } = this.props;
    if (!FormEditUser.isEmailValid(this.state.email)) {
      return (
        <HelpBlock id="invalidFieldMessage" style={{ color: 'red' }}>
          {t('Wrong email format')}
        </HelpBlock>
      );
    }
    return null;
  }

  showValidLoginInfo() {
    if (!this.isUploaded()) return null;

    const { t } = this.props;
    const { login } = this.state;
    if (login && FormEditUser.isEmailValid(login)) {
      return (
        <HelpBlock id="invalidFieldMessage" style={{ color: 'red' }}>
          {t('wrong_login_format')}
        </HelpBlock>
      );
    }
    return null;
  }

  clearLoginErrors() {
    const { errors = [] } = this.state;

    const hasLoginErrors = errors.some((error) =>
      LOGIN_ERRORS.includes(error.type)
    );

    if (hasLoginErrors) {
      const updatedErrors = errors.filter(
        (error) => !LOGIN_ERRORS.includes(error.type)
      );

      this.setState({ errors: updatedErrors });
    }
  }

  showModalChangePassword(e) {
    e.stopPropagation();
    e.preventDefault();
    const { showModal } = this.props;
    showModal('CHANGE_USER_PASSWORD', { title: 'Change password' });
  }

  isUploaded(props) {
    const propsItem = props || this.props;
    return propsItem.user.get('isUploaded');
  }

  haveChanges() {
    if (!this.isUploaded()) return false;

    const companyProps = this.props.user.toJS();
    const mapState = fromJS({
      login: this.state.login,
      firstName: this.state.firstName,
      lastName: this.state.lastName,
      middleName: this.state.middleName,
      email: this.state.email,
      phoneNumber: this.state.phoneNumber,
      avatar: this.state.avatar,
      position: this.state.position
    });
    const mapProps = fromJS({
      login: companyProps.login,
      firstName: companyProps.firstName,
      lastName: companyProps.lastName,
      middleName: companyProps.middleName,
      email: companyProps.email,
      phoneNumber: companyProps.phoneNumber,
      avatar: companyProps.avatar,
      position: this.state.position
    });

    return !mapState.equals(mapProps);
  }

  trimField(field) {
    this.setState({ [field]: this.state[field].trim() });
  }

  render() {
    const { t, user } = this.props;
    const inputProps = (field) => ({
      value: this.getProperty(field),
      onChange: (e) => this.onChange(e, field),
      onBlur: () => this.trimField(field)
    });

    return (
      <Form
        horizontal
        style={{
          whiteSpace: 'nowrap'
        }}>
        <div
          className="control-label-row"
          style={{ width: '100%', marginBottom: 17 }}>
          {t('profile_data_will_be_available_to_your_partners')}
        </div>
        <div
          style={{
            display: 'inline-block',
            width: 545
          }}>
          <FormGroup
            controlId="lastName"
            validationState={
              this.getFieldValidationState('lastName') ? null : 'error'
            }>
            <Col sm={12}>
              <ControlLabel className="control-label-row">
                {t('Last Name')}
                <span style={{ color: 'red' }}>* </span>
              </ControlLabel>
              <FormControl
                type="text"
                maxLength="60"
                {...inputProps('lastName')}
                onKeyDown={(e) =>
                  e.keyCode === 13 ? e.preventDefault() : null
                }
              />
              {this.showHelpInfo('lastName')}
            </Col>
          </FormGroup>
          <FormGroup
            controlId="firstName"
            validationState={
              this.getFieldValidationState('firstName') ? null : 'error'
            }>
            <Col sm={12}>
              <ControlLabel className="control-label-row">
                {t('First Name')}
                <span style={{ color: 'red' }}>* </span>
              </ControlLabel>
              <FormControl
                type="text"
                maxLength="60"
                {...inputProps('firstName')}
                onKeyDown={(e) =>
                  e.keyCode === 13 ? e.preventDefault() : null
                }
              />
              {this.showHelpInfo('firstName')}
            </Col>
          </FormGroup>
          <FormGroup controlId="middleName">
            <Col sm={12}>
              <ControlLabel className="control-label-row">
                {t('Middle Name')}
              </ControlLabel>
              <FormControl
                type="text"
                maxLength="60"
                {...inputProps('middleName')}
                onKeyDown={(e) =>
                  e.keyCode === 13 ? e.preventDefault() : null
                }
              />
            </Col>
          </FormGroup>
          <FormGroup controlId="position">
            <Col sm={12}>
              <ControlLabel className="control-label-row">
                {t('department')}
              </ControlLabel>
              <FormControl
                type="text"
                {...inputProps('department')}
                placeholder={t('not-filed')}
                disabled
                onKeyDown={(e) =>
                  e.keyCode === 13 ? e.preventDefault() : null
                }
              />
            </Col>
          </FormGroup>
          <FormGroup controlId="position">
            <Col sm={12}>
              <ControlLabel className="control-label-row">
                {t('Position')}
              </ControlLabel>
              <FormControl
                type="text"
                {...inputProps('position')}
                placeholder={t('not-filed')}
                disabled
                onKeyDown={(e) =>
                  e.keyCode === 13 ? e.preventDefault() : null
                }
              />
            </Col>
          </FormGroup>
          <FormGroup controlId="phoneNumber">
            <Col sm={12}>
              <ControlLabel className="control-label-row">
                {t('Phone number')}
              </ControlLabel>
              <InputMask
                className="form-control"
                type="text"
                value={
                  this.state.phoneNumber !== '+  (   )    -    '
                    ? this.state.phoneNumber
                    : ''
                }
                onChange={(e) => {
                  if (e.target.value !== '+  (   )    -    ') {
                    return this.onChange(e, 'phoneNumber');
                  }
                  e.target.value = '';
                  return this.onChange(e, 'phoneNumber');
                }}
                onKeyDown={(e) =>
                  e.keyCode === 13 || e.keyCode === 32
                    ? e.preventDefault()
                    : null
                }
                onBlur={() => this.trimField('phoneNumber')}
                mask="+9 (999) 999-9999"
                maskChar=" "
              />
            </Col>
          </FormGroup>
          {isMessengerService() && (
            <FormGroup
              controlId="login"
              aria-describedby="inputGroup-sizing-sm"
              validationState={
                !this.isUploaded() ||
                (!FormEditUser.isEmailValid(this.state.login) &&
                  !this.checkError('loginMatch') &&
                  this.state.login !== '')
                  ? null
                  : 'error'
              }>
              <Col sm={12}>
                <ControlLabel className="control-label-row">
                  {t('user_login')}
                </ControlLabel>
                <InputGroup>
                  <InputGroup.Addon>@</InputGroup.Addon>
                  <FormControl
                    name="login"
                    type="text"
                    {...inputProps('login')}
                    disabled={!this.isGoodwixMember}
                    onKeyDown={(e) =>
                      e.keyCode === 13 ? e.preventDefault() : null
                    }
                  />
                </InputGroup>
                {this.showValidLoginInfo()}
                {this.showHelpInfo('login')}
                {this.showError('loginMatch')}
                {this.showError('loginSameEmail')}
              </Col>
            </FormGroup>
          )}
          <FormGroup
            controlId="email"
            validationState={
              !this.isUploaded() ||
              (FormEditUser.isEmailValid(this.state.email) &&
                !this.checkError('emailMatch') &&
                this.state.email !== '')
                ? null
                : 'error'
            }>
            <Col sm={12}>
              <ControlLabel className="control-label-row">
                {t('E-mail')}
                <span style={{ color: 'red' }}>* </span>
              </ControlLabel>
              <FormControl
                type="mail"
                {...inputProps('email')}
                placeholder="example@email.com"
                disabled={user.get('type') === 'demo'}
                onKeyDown={(e) =>
                  e.keyCode === 13 ? e.preventDefault() : null
                }
              />
              {this.showValidMailInfo()}
              {this.showHelpInfo('email')}
              {this.showError('emailMatch')}
            </Col>
          </FormGroup>
          <FormGroup>
            <span style={{ color: 'red', marginLeft: 15 }}>* </span>
            <span>{t('Required fields')}</span>
          </FormGroup>
          <hr
            style={{
              borderTop: '1px solid #ccc'
            }}
          />
          {user && user.get('type') !== 'demo' && (
            <div style={{ marginTop: 15 }}>
              <button
                className="link"
                type="button"
                style={{ textDecoration: 'none' }}
                onClick={this.showModalChangePassword}>
                <img src="/img/lock.svg" alt="change-password" />
                &nbsp;
                <span
                  className="control-label-row"
                  style={{ fontSize: 14, marginLeft: 10 }}>
                  {t('Change password')}
                </span>
              </button>
            </div>
          )}
          <hr
            style={{
              borderTop: '1px solid #ccc'
            }}
          />
          <FormGroup>
            <Col>
              <div className="form-button-center">
                <Button
                  id="saveUserButton"
                  bsStyle="primary"
                  style={{
                    marginLeft: 15
                  }}
                  disabled={
                    !this.haveChanges() ||
                    !(
                      this.getUserValidationState() &&
                      FormEditUser.isEmailValid(this.state.email)
                    )
                  }
                  onClick={this.onSave}>
                  {t('Save changes')}
                </Button>

                <Button
                  id="cancelUserBtn"
                  bsStyle="default"
                  style={{ marginLeft: '15px' }}
                  disabled={!this.haveChanges()}
                  onClick={() => this.setDefaultPropsToState()}>
                  {t('Cancel')}
                </Button>
              </div>
            </Col>
          </FormGroup>
        </div>
        <div
          style={{
            display: 'inline-block',
            verticalAlign: 'top',
            marginLeft: 20
          }}>
          <ControlLabel
            className="control-label-row"
            style={{
              right: '233px',
              zIndex: 1,
              top: 3
            }}>
            {t('Main image')}
          </ControlLabel>
          <ImageControl
            name="userImage"
            imgSrc={this.state.avatar}
            onFileChoose={this.onSetImg}
            onImageRemove={this.onDelImg}
            style={{
              width: '281px',
              height: '281px',
              zIndex: 1
            }}
          />
        </div>
      </Form>
    );
  }
}

FormEditUser.propTypes = {
  t: PropTypes.func.isRequired
};

export default FormEditUser;
