import React, { useCallback } from 'react';
import { useActions, useTranslate } from '@hooks';
import { Button, Icon } from '@link/react-components';
import * as subscribeStorage from '../ChatWidget/PurchaseRequestsFilter/storage';
import { isFunction } from '../../utils/utils';

const ICON_SIZE = 18;

export function SubscribeRequestsButton({ companyId, isSubscribed, onClick }) {
  const t = useTranslate();
  const { subscribeToCompany, unsubscribeFromCompany } =
    useActions(subscribeStorage);

  function translate(tKey) {
    return t(`buttons.subscribePurchaseRequests.${tKey}`);
  }

  const onToggleSubscribe = useCallback(async () => {
    if (isSubscribed) {
      await unsubscribeFromCompany(companyId);
    } else {
      await subscribeToCompany(companyId);
    }

    if (isFunction(onClick)) {
      onClick(!isSubscribed);
    }
  }, [
    isSubscribed,
    companyId,
    subscribeToCompany,
    unsubscribeFromCompany,
    onClick
  ]);

  if (isSubscribed) {
    return (
      <Button size="small" mode="text" onClick={onToggleSubscribe}>
        {translate('unsubscribe')}
      </Button>
    );
  }

  return (
    <Button
      size="small"
      mode="text"
      before={<Icon iconName="plus" width={ICON_SIZE} height={ICON_SIZE} />}
      onClick={onToggleSubscribe}>
      {translate('subscribe')}
    </Button>
  );
}
