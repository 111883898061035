import React, { useState, useCallback } from 'react';
import { Rnd } from 'react-rnd';

const style = {
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'flex-end',
  backgroundColor: 'transparent',
  position: 'relative',
  zIndex: 998
};

const WindowResizer = ({ children, defaultSize, saveSize }) => {
  const [size, setSize] = useState(defaultSize);

  const config = {
    size,
    style,
    enableResizing: { topLeft: true },
    resizeHandleStyles: { topLeft: true },
    disableDragging: true,
    dragAxis: 'none',
    minWidth: '330px',
    maxWidth: '480px',
    minHeight: '480px',
    maxHeight: '640px'
  };

  const getWidgetSize = (ref) => ({
    width: ref.offsetWidth,
    height: ref.offsetHeight
  });

  const onResize = useCallback((_, __, ref) => {
    setSize(getWidgetSize(ref));
  }, []);

  const onResizeStop = useCallback((_, __, ref) => {
    saveSize(getWidgetSize(ref));
  }, []);

  return (
    <Rnd onResize={onResize} onResizeStop={onResizeStop} {...config}>
      {children}
    </Rnd>
  );
};

export default WindowResizer;
