import { Map, List, Set, fromJS } from 'immutable';
import { ORDERS_FROM_CUSTOMERS_FILTER_SELECT_TOGGLE } from '../../actionTypes/orders';

const initState = () =>
  Map({
    data: List(),
    booted: false,
    selected: Set(),
    ids: Set(),
    badges: List(),
    totalOrders: 0,
    currencies: List(),
    statuses: List(),
    responsibles: List(),
    customerNames: List(),
    excluded: List(),
    isEnabledFilterSelected: false,

    direction: 'DESC',
    orderBy: '',
    limit: 20,
    offset: 0,
    search: ''
  });

const load = (
  data,
  {
    allOrderIds = [],
    orders = [],
    badges = [],
    totalNumberOrders,
    currencies,
    statuses,
    responsibles,
    customerNames,
    limit,
    offset,
    orderBy,
    direction,
    search,
    excluded
  }
) =>
  data
    .set('data', fromJS(orders))
    .set('totalOrders', totalNumberOrders)
    .set('badges', fromJS(badges))
    .set('currencies', fromJS(currencies))
    .set('statuses', fromJS(statuses))
    .set('responsibles', fromJS(responsibles))
    .set('customerNames', fromJS(customerNames))
    .set('direction', direction)
    .set('excluded', fromJS(excluded))
    .set('orderBy', orderBy)
    .set('limit', limit)
    .set('offset', offset)
    .set('search', search)
    .set('ids', fromJS(allOrderIds))
    .set('booted', true);

const select = (data, { orderId }) =>
  data.update('selected', (selected) =>
    selected.includes(orderId)
      ? selected.remove(orderId)
      : selected.add(orderId)
  );

const removeOrder = (data, { selected }) =>
  data.update('data', (dataStorage) =>
    dataStorage.filter((i) => !selected.includes(i.get('id')))
  );

const deselect = (data) => data.set('selected', Set());

const setTableAttrs = (data, { excluded }) => data.set('excluded', excluded);

const toggleFilterSelected = (state) =>
  state.set('isEnabledFilterSelected', !state.get('isEnabledFilterSelected'));

const selectMany = (data, { ids }) =>
  data.update('selected', (selected) =>
    // eslint-disable-next-line no-shadow
    ids.reduce(
      (selected, id) => (!selected.includes(id) ? selected.add(id) : selected),
      selected
    )
  );
const unselectMany = (data, { ids }) =>
  data.update('selected', (selected) =>
    // eslint-disable-next-line no-shadow
    ids.reduce(
      (selected, id) =>
        selected.includes(id) ? selected.remove(id) : selected,
      selected
    )
  );

const updateOrders = (state, { orders, badges, totalOrdersCount }) =>
  state
    .update('data', (data) => {
      const sliceLimit = state.get('limit') - orders.length;
      const offset = state.get('offset');
      let newData = sliceLimit <= 0 ? List() : data.slice(0, sliceLimit);
      orders.forEach((order) => {
        const index = newData.findIndex((i) => i.get('id') === order.id);
        if (index !== -1) {
          newData = newData.setIn([index], fromJS(order));
        } else if (offset === 0) {
          newData = newData.unshift(fromJS(order));
        }
      });
      return newData;
    })
    .update('badges', (data) => {
      let newData = data;
      badges.forEach((badge) => {
        const index = newData.findIndex((i) => i.get('id') === badge.id);
        if (index !== -1) {
          newData = newData.setIn([index], fromJS(badge));
        } else {
          newData = newData.unshift(fromJS(badge));
        }
      });
      return newData;
    })
    .set('totalOrders', totalOrdersCount);

const tableOrdersCustomers = (state = initState(), { type, payload }) => {
  switch (type) {
    case ORDERS_FROM_CUSTOMERS_FILTER_SELECT_TOGGLE:
      return toggleFilterSelected(state);
    case 'ORDERS:TABLE_ORDERS_CUSTOMERS:LOAD:SUCCESS':
      return load(state, payload);
    case 'ORDERS:TABLE_ORDERS_CUSTOMERS:SELECT':
      return select(state, payload);
    case 'ORDERS:TABLE_ORDERS_CUSTOMERS:CLEAR':
      return initState();
    case 'ORDERS:TABLE_ORDERS_CUSTOMERS:DESELECT':
      return deselect(state);
    case 'ORDERS:TABLE_ORDERS_CUSTOMERS:REMOVE:SUCCESS':
      return removeOrder(state, payload);
    case 'ORDERS:SET_TABLE_ATTRS_CUSOMETRS_ORDERS:SUCCESS':
      return setTableAttrs(state, payload);
    case 'ORDERS:TABLE_ORDERS_CUSTOMERS:SELECT_MANY':
      return selectMany(state, payload);
    case 'ORDERS:TABLE_ORDERS_CUSTOMERS:UNSELECT_MANY':
      return unselectMany(state, payload);
    case 'ORDERS:UPDATE_CUSTOMERS_DATA':
      return updateOrders(state, payload);
    default:
      return state;
  }
};

export default tableOrdersCustomers;
