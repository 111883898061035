import fetch from 'isomorphic-fetch';
import { setInfoMessage, setSuccessMessage, setErrorMessage } from '../message';
import { updateTableItems } from '../table';
import { getCountNewAvailable } from './available';

// const startPublish = catalog => ({ type: 'START_PUBLISH', catalog });

export const publishCatalog =
  (id, bottomNotify = false) =>
  async (dispatch) => {
    try {
      if (bottomNotify) {
        dispatch(setInfoMessage({ key: 'Start publication' }));
      }
      dispatch({
        type: 'CATALOG_PUBLICATION_START',
        payload: { catalogId: id }
      });
      await fetch(`/api/catalogs/${id}/publish`, {
        method: 'POST',
        credentials: 'include'
      });
    } catch (e) {
      console.log(e);
      dispatch(
        setErrorMessage({
          key: 'Error while publishing catalog try again',
          params: { name: 'name' }
        })
      );
    }
  };

export const finishPublishCatalog = (id, success) => (dispatch, getState) =>
  fetch(`/api/catalogs/${id}`, { credentials: 'include' })
    .then((response) => response.json())
    .then((catalog) => {
      dispatch(updateTableItems([catalog], 'catalogs'));
      if (success) {
        dispatch(getCountNewAvailable());
        dispatch(
          setSuccessMessage({
            key: 'Catalogue published'
          })
        );
      } else {
        const catalogs = getState().getIn(['tables', 'catalogs', 'items']);
        catalogs.forEach((catalogItem) => {
          if (catalog.get('_id') === id) {
            dispatch(
              setErrorMessage({
                key: 'Error while publishing catalog try again',
                params: { name: catalogItem.get('name') }
              })
            );
          }
        });
      }
    });
