import React from 'react';
import Xarrow from 'react-xarrows';

const DefaultArrow = ({
  start,
  end,
  lineColor,
  headShape = 'circle',
  tailShape = 'circle',
  showTail = true,
  showHead = true,
  ...props
}) => (
  <Xarrow
    path="smooth"
    start={start}
    end={end}
    strokeWidth={3}
    showTail={showTail}
    showHead={showHead}
    headColor={lineColor}
    tailColor={lineColor}
    headSize={3}
    tailSize={3}
    curveness={0.5}
    headShape={headShape}
    tailShape={tailShape}
    lineColor={lineColor}
    {...props}
  />
);

export const Line = ({ start, end }) => (
  <DefaultArrow start={start} end={end} lineColor="#5B5F73" />
);

export const ParallelLine = ({ start, end }) => (
  <DefaultArrow
    start={start}
    end={end}
    lineColor="#212229"
    showTail={false}
    showHead={false}
  />
);

export const AcceptLine = ({ start, end }) => (
  <DefaultArrow
    start={start}
    end={end}
    lineColor="#5B5F73"
    showTail={false}
    showHead={false}
  />
);

export const DiscardLine = ({ start, end }) => (
  <DefaultArrow
    start={start}
    end={end}
    lineColor="#DD1E2A"
    showTail={false}
    showHead
  />
);
