/* eslint-disable max-len */
import React from 'react';

const QuestionsIcon = () => (
  <svg
    width="18"
    height="19"
    viewBox="0 0 18 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      className="snm-icon__path"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9 18.5C13.9706 18.5 18 14.4706 18 9.5C18 4.52944 13.9706 0.5 9 0.5C4.02944 0.5 0 4.52944 0 9.5C0 14.4706 4.02944 18.5 9 18.5ZM9 5.5C7.89543 5.5 7 6.39543 7 7.5H5C5 5.29086 6.79086 3.5 9 3.5C11.2091 3.5 13 5.29086 13 7.5C13 9.36384 11.7252 10.9299 10 11.374V12.5H8V9.5H9C10.1046 9.5 11 8.60457 11 7.5C11 6.39543 10.1046 5.5 9 5.5ZM8 13.5V15.5H10V13.5H8Z"
      fill="#C6CAE3"
    />
  </svg>
);

export default QuestionsIcon;
