import React from 'react';
import { useParams, useTranslate } from '@hooks';
import { CompanyProfile } from '../../../components/CompanyProfile';
import MessengerOther from '../../../components/Layouts/MessengerOther';

export function CompanyProfilePage({ title }) {
  const t = useTranslate();
  const { companyId } = useParams();

  return (
    <MessengerOther
      theme="gray"
      title={t(title)}
      breadcrumbs={[]}
      content={<CompanyProfile companyId={companyId} />}
      noShowPanel
    />
  );
}
