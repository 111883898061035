import React from 'react';
import { Link } from 'react-router-dom';
import { Icon, Typography } from '@link/react-components';
import styles from './Content.module.css';
import { Categories } from '../../../../components/Categories';
import { Groups } from '../Groups';
import { RNPIcon } from '../../../../components/RNPIcon';
import { CompanyNote } from '../CompanyNote';

function CardHead({ companyId, name, isRNP }) {
  return (
    <div className={styles.head}>
      <Typography variant="h2" className={styles.headText}>
        <Link to={`/companies/${companyId}/view`}>{name}</Link>
      </Typography>
      <RNPIcon isRNP={isRNP} />
    </div>
  );
}

function ContactRow({ iconName, info }) {
  return (
    <div className={styles.contactRow}>
      <Icon iconName={iconName} width={18} height={18} />
      <Typography variant="body1Reg" className={styles.contactRowText}>
        {info}
      </Typography>
    </div>
  );
}

function Contacts({ address, phone }) {
  return (
    <div className={styles.contacts}>
      {Boolean(address) && <ContactRow info={address} iconName="marker-pin" />}

      {Boolean(phone) && <ContactRow info={phone} iconName="call-end" />}
    </div>
  );
}

export function Content({
  companyId,
  name,
  isRNP,
  address,
  groups,
  categories,
  phone,
  note,
  updateCompany
}) {
  return (
    <div className={styles.content}>
      <div className={styles.mainInfo}>
        <CardHead companyId={companyId} name={name} isRNP={isRNP} />
        <Contacts address={address} phone={phone} />
        <Categories categories={categories} />
      </div>
      <div className={styles.infoBlock}>
        <div className={styles.custom}>
          <Groups groups={groups} />
        </div>
        <CompanyNote
          companyId={companyId}
          note={note}
          updateCompany={updateCompany}
        />
      </div>
    </div>
  );
}
