import { Map, fromJS } from 'immutable';

const loadProductFeature = (state, item) => fromJS(item);

const updateProductFeatureField = (state, field, value) =>
  state.set(field, value);

const trimProductFeatureField = (state, field) =>
  state.update(field, (value) => (value || '').trim());

const featureReducer = (state = Map(), action) => {
  switch (action.type) {
    case 'PRODUCT_FEATURE_LOAD':
      return loadProductFeature(state, action.item);
    case 'PRODUCT_FEATURE_UPDATE_FIELD':
      return updateProductFeatureField(state, action.field, action.value);
    case 'PRODUCT_FEATURE_TRIM_FIELD':
      return trimProductFeatureField(state, action.field);
    default:
      return state;
  }
};

export default featureReducer;
