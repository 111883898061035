import React, { useCallback, useRef, useState } from 'react';
import { isEmpty, isNil } from 'ramda';
import Select, { components } from 'react-select';
import { useUpdateEffect } from 'react-use';
import CreatableSelect from 'react-select/creatable';
import SmallCrossSVG from '../../../images/cross-small';
import './style.css';

function DBEditableChipContainer({
  t,
  id,
  value,
  options: selectOptions = [],
  onChange: changeCb,
  onChangeOption: changeOptionCb,
  onCreateOption: createOptionCb,
  onInputChange,
  onInputKeyDown,
  arrowRenderer,
  placeholder,
  noResultsText,
  isMulti = false,
  isClearable = false,
  removeSelected = false,
  isCreatable = true,
  maxWidth = 'auto'
}) {
  const Component = isCreatable ? CreatableSelect : Select;

  const [isFocused, setIsFocused] = useState(false);
  const [selected, setSelected] = useState(value);
  const [options, setOptions] = useState(selectOptions);

  const onFocus = useCallback(() => setIsFocused(true), []);
  const onBlur = useCallback(() => {
    setIsFocused(false);
  }, []);

  useUpdateEffect(() => {
    setSelected(value);
  }, [value]);

  useUpdateEffect(() => {
    if (typeof changeCb === 'function') {
      changeCb(selected);
    }
  }, [selected]);

  useUpdateEffect(() => {
    setOptions(selectOptions);
  }, [selectOptions]);

  // NOTE: Cb need to disable prevent default
  const onKeyDown = () => {};

  const onUnselect = (id) => {
    setSelected((prevState) =>
      prevState.filter((option) => option.value !== id)
    );
  };

  const onChange = (newSelectedList) => {
    setSelected(newSelectedList);
  };

  const onChangeOption = (id, label = '') => {
    if (isEmpty(label)) return;

    const prevOption = options.find((option) => option.value === id);
    if (isNil(prevOption)) return;

    const updatedOption = { ...prevOption, label };
    setOptions((prevState) =>
      prevState.map((option) => (option.value === id ? updatedOption : option))
    );
    setSelected((prevState) =>
      prevState.map((option) => (option.value === id ? updatedOption : option))
    );

    if (typeof changeOptionCb === 'function') {
      changeOptionCb(updatedOption);
    }
  };

  const onCreateOption = async (value) => {
    let newOption;

    if (typeof createOptionCb === 'function') {
      newOption = await createOptionCb(value);
    } else {
      newOption = {
        label: value,
        value: value.toLowerCase().replace(/\W/g, ''),
        isNew: true
      };
    }

    setOptions((prevState) => [...prevState, newOption]);
    setSelected((prevState) => [...prevState, newOption]);
  };

  const formatCreateLabel = (inputValue) =>
    `${t('select.create_label')} ${inputValue}`;

  return (
    <div
      className={`dynamic-border-select${
        isFocused ? ' dynamic-border-select_focused' : ''
      }`}
      style={{ maxWidth }}
      onFocus={onFocus}
      onBlur={onBlur}>
      <Component
        id={id}
        value={selected}
        options={options}
        onChange={onChange}
        onCreateOption={onCreateOption}
        onInputChange={onInputChange}
        onInputKeyDown={onInputKeyDown}
        arrowRenderer={arrowRenderer}
        placeholder={placeholder}
        noResultsText={noResultsText}
        isMulti={isMulti}
        isClearable={isClearable}
        removeSelected={removeSelected}
        onKeyDown={onKeyDown}
        classNamePrefix="dbs"
        formatCreateLabel={formatCreateLabel}
        noOptionsMessage={() => t('select.noOptions')}
        components={{
          MultiValue: (props) => (
            <MultiValueContainer
              {...props}
              onChangeOption={onChangeOption}
              onRemove={onUnselect}
            />
          ),
          DropdownIndicator: arrowRenderer
            ? (props) => <DropdownIcon {...props} icon={arrowRenderer} />
            : null,
          IndicatorSeparator: null
        }}
      />
    </div>
  );
}

function DropdownIcon(props) {
  return (
    <components.DropdownIndicator {...props}>
      {props.icon}
    </components.DropdownIndicator>
  );
}

function MultiValueContainer(props) {
  const { data, innerProps, onChangeOption, onRemove } = props;
  const ref = useRef();

  const onClick = () => {
    ref.current.focus();
  };

  const onKeyDown = (event) => {
    // NOTE: Hack for correct work keydown events
    event.defaultPrevented = true;

    if (event.key === 'Enter') {
      ref.current.blur();
      event.preventDefault();
    }
  };

  const onRemoveOption = (event) => {
    event.preventDefault();
    event.stopPropagation();
    onRemove(data.value);
  };

  const onBlur = (event) => {
    if (isEmpty(event.target.textContent)) {
      ref.current.textContent = data.label;
      return;
    }
    onChangeOption(data.value, event.target.textContent);
  };

  return (
    <div
      {...innerProps}
      id={generateId(data.value)}
      style={{ backgroundColor: data.color }}
      className="editable-chip"
      onClick={onClick}>
      <div
        ref={ref}
        className="editable-chip__editor"
        tabIndex={0}
        contentEditable
        suppressContentEditableWarning
        onKeyDown={onKeyDown}
        onBlur={onBlur}>
        {data.label}
      </div>
      <div className="editable-chip__remove-icon" onClick={onRemoveOption}>
        <SmallCrossSVG />
      </div>
    </div>
  );
}

export default DBEditableChipContainer;

function generateId(value) {
  return `editable-chip-${value}`;
}
