import React, { Component } from 'react';
import { translate } from 'react-i18next';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  Modal,
  Col,
  Form,
  FormGroup,
  FormControl,
  ControlLabel,
  Button
} from 'react-bootstrap';
import { ModalContainer } from './elements';

import * as modalActions from '../../action-creators/modal';

class ModalAddContact extends Component {
  constructor(props) {
    super(props);
    this.state = {
      label: '',
      value: ''
    };
    this.inputProps = this.inputProps.bind(this);
  }

  asterisk() {
    // eslint-disable-line
    return <span style={{ color: 'red' }}>*</span>;
  }

  inputProps(field) {
    return {
      value: this.state[field],
      onChange: (e) => this.setState({ [field]: e.target.value })
    };
  }

  render() {
    const { t, captionKey, addContact, hideModalDialog } = this.props;

    return (
      <ModalContainer mods={{ theme: 'default' }}>
        <Modal id="addContact" onHide={() => hideModalDialog()} show>
          <Modal.Header>
            <Modal.Title>
              {t(captionKey)}
              <span
                className="modal-header-cross pull-right"
                onClick={() => hideModalDialog()}
              />
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form
              horizontal
              style={{
                whiteSpace: 'nowrap'
              }}>
              <FormGroup controlId="label">
                <Col
                  componentClass={ControlLabel}
                  className="form-modal-label-col"
                  style={{ marginTop: 15 }}
                  sm={4}>
                  {t('label')}
                  {this.asterisk()}
                </Col>
                <Col sm={8} className="form-modal-input-col">
                  <span
                    className="input-col-length pull-right"
                    style={{ marginBottom: 5 }}>
                    {this.state.label.length}
                    /24
                  </span>
                  <FormControl
                    type="text"
                    maxLength={24}
                    {...this.inputProps('label')}
                  />
                </Col>
              </FormGroup>
              <FormGroup controlId="value" style={{ marginBottom: 0 }}>
                <Col
                  componentClass={ControlLabel}
                  className="form-modal-label-col"
                  style={{ marginTop: 15 }}
                  sm={4}>
                  {t('value')}
                  {this.asterisk()}
                </Col>
                <Col sm={8} className="form-modal-input-col">
                  <span
                    className="input-col-length pull-right"
                    style={{ marginBottom: 5 }}>
                    {this.state.value.length}
                    /255
                  </span>
                  <FormControl
                    type="text"
                    maxLength={255}
                    {...this.inputProps('value')}
                  />
                </Col>
              </FormGroup>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <div
              style={{
                textAlign: 'left',
                paddingBottom: '10px',
                color: '#777'
              }}>
              <span>
                {this.asterisk()}
                {` ${t('Required fields')}`}
              </span>
            </div>
            <div>
              <Button
                id="cancel"
                className="pull-left"
                onClick={() => hideModalDialog()}>
                {t('Cancel')}
              </Button>
              <Button
                id="saveButton"
                bsStyle="primary"
                disabled={!this.state.label.length || !this.state.value.length}
                onClick={() => {
                  addContact(this.state.label, this.state.value);
                  hideModalDialog();
                }}>
                {t('Add')}
              </Button>
            </div>
          </Modal.Footer>
        </Modal>
      </ModalContainer>
    );
  }
}

export default connect(
  (state) => ({
    company: state.get('company')
  }),
  (dispatch) =>
    bindActionCreators(
      {
        hideModalDialog: modalActions.hideModalDialog
      },
      dispatch
    )
)(translate(['ui'], { wait: true })(ModalAddContact));
