import React from 'react';
import PropTypes from 'prop-types';
import { translate } from 'react-i18next';
import Checkbox from '../../Checkbox';
import Avatar from '../../Avatar';
import './styles.css';
import Select from '../../Select';
import RolesHelper from '../../RolesHelper';

const getOptions = (t, rolesOptions) =>
  rolesOptions.map((option) => ({
    ...option,
    label: t(`channel-roles-${option.value}`)
  }));

const getValue = (t, rolesOptions, memberRole) => {
  const options = getOptions(t, rolesOptions);
  return options.find(({ value }) => value === memberRole);
};

const getClassNameModifier = (checked, alreadyMember, className) => {
  if (alreadyMember) return `${className} ${className}__member`;
  if (checked) return `${className} ${className}__checked`;
  return className;
};

const Row = ({
  t,
  mode,
  alreadyMember,
  uniqueId,
  employeeId,
  avatar,
  name,
  company,
  checked,
  onCheck,
  memberRole,
  rolesOptions,
  onChangeMemberRole
}) => (
  <div
    className={`cw-modal-add-to-group-chat-row ${getClassNameModifier(
      checked,
      alreadyMember,
      'modal-add-to-group-chat-row'
    )}`}>
    <div className="cw-modal-add-to-group-chat-row_container">
      <div className="cws-contact-item__avatar">
        <Avatar
          size={46}
          info={{ type: 'user', src: avatar, alt: 'logo', employeeId }}
        />
      </div>
      <div className="cw-modal-add-to-group-chat-row_info">
        <div className="cw-modal-add-to-group-chat-row_info-name">
          {name || '-'}
        </div>
        <div className="cw-modal-add-to-group-chat-row_info-company">
          {company || '-'}
        </div>
      </div>
    </div>
    <div className="cw-modal-add-to-group-chat-row_member-role">
      <Select
        value={getValue(t, rolesOptions, memberRole)}
        options={getOptions(t, rolesOptions)}
        onChange={({ value }) => onChangeMemberRole(uniqueId, value)}
        multi={false}
        searchable={false}
        clearable={false}
        disabled={alreadyMember}
        filterOptions={(options) =>
          options.filter((option) => !option.disabled)
        }
      />
    </div>
    <div className="cw-modal-add-to-group-chat-row_ask">
      <RolesHelper t={t} options={rolesOptions} type={mode} />
    </div>
    <div className="modal-add-to-group-chat-row_checkbox">
      <Checkbox
        checked={checked}
        onClick={() => onCheck(uniqueId)}
        disabled={alreadyMember}
      />
    </div>
  </div>
);

Row.propTypes = {
  t: PropTypes.func.isRequired,
  mode: PropTypes.string.isRequired,
  alreadyMember: PropTypes.bool.isRequired,
  uniqueId: PropTypes.number.isRequired,
  avatar: PropTypes.string,
  name: PropTypes.string.isRequired,
  company: PropTypes.string.isRequired,
  checked: PropTypes.bool.isRequired,
  onCheck: PropTypes.func.isRequired
};

Row.defaultProps = {
  avatar: null
};

export default translate()(Row);
