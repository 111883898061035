import React, { useCallback, useEffect, useState } from 'react';
import { isEmpty } from 'ramda';
import { Button } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useToggle } from 'react-use';
import DynamicBorderRequireInput from 'react-components/inputs/dynamic-border/require-input';
import DynamicBorderSelect from 'react-components/inputs/dynamic-border/select';
import DynamicBorderTextarea from 'react-components/inputs/dynamic-border/textarea';
import DynamicBorderFileInput from 'react-components/inputs/dynamic-border/file-input';
import RangeDateTimePicker from 'react-components/datapickers/range-datetime-input-picker';
import DynamicBorderEditableChipContainer from 'react-components/inputs/dynamic-border/editable-chip-container';
import ModalLayout from '../../Layouts/modal';
import { filesLoadHandler } from '../../../../useCases/files';
import * as storageActions from '../../../../action-creators/storage';
import * as messageActions from '../../../../action-creators/message';
import * as modalActions from '../../../../action-creators/modal';
import * as storage from '../../../storage';
import * as getters from '../../../getters';
import ProfileSVG from '../../../assets/icons/Profile';
import ScheduleSVG from '../../../assets/icons/Schedule';
import AttachSVG from '../../../assets/icons/Attach';
import TagSVG from '../../../assets/icons/Tag';
import { useTranslate } from '../../../../TranslateProvider';
import CheckboxWithLabel from '../../../../components/CheckboxWithLabel';
import Helper from '../../../../components/Helper';
import { noop } from '../../../../utils/utils';
import './style.css';

const MAX_LENGTH_TITLE = 255;
const MAX_LENGTH_DESCRIPTION = 5000;
const MAX_FILES_COUNT = 10;
const IconArrow = <img src="/img/drop.svg" alt="" />;

function transformTagOption(tag) {
  return {
    label: tag.title,
    value: tag.id,
    color: tag.color
  };
}

function transformTagOptionList(tags = []) {
  return tags.map(transformTagOption);
}

const Title = ({ t, title, setTitle }) => {
  const onChange = useCallback((event) => {
    setTitle(event.target.value);
  }, []);
  return (
    <div className="create-task-modal-header">
      <DynamicBorderRequireInput
        value={title}
        onChange={onChange}
        placeholder={t('taskManager.createTask.placeholders.title')}
        maxLength={MAX_LENGTH_TITLE}
        required
      />
    </div>
  );
};

const FilesInputField = ({ files, setFiles, placeholder }) => {
  const dispatch = useDispatch();
  const onAttachFiles = async (data) => {
    await filesLoadHandler({
      files: data,
      attachedFiles: files,
      maxFiles: MAX_FILES_COUNT,
      loadFile: async ({ file }) => {
        const uploadedFile = await dispatch(storageActions.uploadFile(file));
        setFiles((prevState) => [...prevState, uploadedFile]);
      },
      beforeLoading: () =>
        dispatch(messageActions.setInfoMessage({ key: 'File upload started' })),
      onError: (key, params) =>
        dispatch(messageActions.setErrorMessage({ key, params })),
      onLoaded: () =>
        dispatch(
          messageActions.setSuccessMessage({ key: 'File upload finished' })
        )
    });
  };

  const onRemoveFile = async (event, id) => {
    event.stopPropagation();

    const removedFile = files.find((file) => file.id === id);
    dispatch(storageActions.removeFileLinkBySection(removedFile.fileId));
    setFiles((prevState) => prevState.filter((file) => file.id !== id));
  };

  return (
    <DynamicBorderFileInput
      placeholder={placeholder}
      value={files}
      onAttach={onAttachFiles}
      onRemove={onRemoveFile}
      multiply
      disabled={files.length >= MAX_FILES_COUNT}
    />
  );
};

const Content = ({ initialDescription, onSubmit = noop }) => {
  const dispatch = useDispatch();
  const t = useTranslate();

  const tags = useSelector(getters.getTagsList);
  const executorsOptions = useSelector(getters.getExecutorsList);

  const [isPrivate, toggleIsPrivate] = useToggle(true);
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState(initialDescription ?? '');
  const [files, setFiles] = useState([]);
  const [executor, setExecutor] = useState(null);
  const [rangeDate, setRangeDate] = useState(null);
  const [selectedTags, setSelectedTags] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  function onHideModal() {
    dispatch(modalActions.hideModal());
  }

  const onChangeDescription = (event) => setDescription(event.target.value);

  const onChangeExecutor = (data) => {
    setExecutor(data);
  };

  const onChangeRangeDate = (data) => {
    setRangeDate(data);
  };

  const onChangeSelectedTags = (data) => {
    setSelectedTags(data);
  };

  const onCreateTagOption = async (value) => {
    const tag = await dispatch(storage.createNewTag(value));
    return transformTagOption(tag);
  };

  const onChangeTagOption = async (value) => {
    dispatch(storage.updateTag(value));
  };

  const onApply = async () => {
    try {
      setIsLoading(true);
      const data = {
        isPrivate,
        title,
        description,
        files,
        executor,
        rangeDate,
        selectedTags
      };
      const task = await dispatch(storage.createNewTask(data));
      onHideModal();
      onSubmit(task);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div>
      <Title t={t} title={title} setTitle={setTitle} />
      <div className="create-task-modal-content">
        <DynamicBorderTextarea
          placeholder={t('taskManager.createTask.placeholders.description')}
          value={description}
          onChange={onChangeDescription}
          maxLength={MAX_LENGTH_DESCRIPTION}
          minRows={2}
          maxRows={12}
        />
        <div className="create-task-modal-row">
          <ProfileSVG />
          <DynamicBorderSelect
            placeholder={t('taskManager.createTask.placeholders.set_executor')}
            arrowRenderer={IconArrow}
            value={executor}
            onChange={onChangeExecutor}
            options={executorsOptions}
          />
        </div>
        <div className="create-task-modal-row">
          <ScheduleSVG />
          <RangeDateTimePicker
            placeholder={t('taskManager.createTask.placeholders.set_date_time')}
            onApply={onChangeRangeDate}
            onReset={onChangeRangeDate}
          />
        </div>
        <div className="create-task-modal-row">
          <AttachSVG />
          <FilesInputField
            files={files}
            setFiles={setFiles}
            placeholder={t('taskManager.createTask.placeholders.attach_file')}
          />
        </div>
        <div className="create-task-modal-row">
          <TagSVG />
          <DynamicBorderEditableChipContainer
            t={t}
            placeholder={t('taskManager.createTask.placeholders.add_tag')}
            arrowRenderer={IconArrow}
            value={selectedTags}
            options={transformTagOptionList(tags)}
            onChange={onChangeSelectedTags}
            onChangeOption={onChangeTagOption}
            onCreateOption={onCreateTagOption}
            isMulti
            maxWidth={484}
          />
        </div>
        <div className="create-task-modal-row-checkbox">
          <CheckboxWithLabel
            value={isPrivate}
            onClick={toggleIsPrivate}
            label={t('taskManager.createTask.labels.private_task')}
          />
          <Helper
            placement="top"
            text={t('taskManager.createTask.hints.private_task')}
          />
        </div>
        <div className="create-task-modal-btns">
          <Button onClick={onHideModal}>
            {t('taskManager.createTask.btns.cancel')}
          </Button>
          <Button
            bsStyle="primary"
            onClick={onApply}
            disabled={isEmpty(title) || isLoading}>
            {t('taskManager.createTask.btns.create')}
          </Button>
        </div>
      </div>
    </div>
  );
};

const CreateNewTaskModal = (props) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(storage.getAllUserTags());
    dispatch(storage.getPossibleExecutors());
  }, []);

  return (
    <ModalLayout
      content={<Content {...props} />}
      enforceFocus={false} // NOTE: Need for correct work mask-input inside modal
    />
  );
};

export default CreateNewTaskModal;
