import React from 'react';
import cn from 'classnames';
import { Typography } from '@components';
import styles from './Button.module.css';

// TODO: Временная заглушка

const Button = ({ before, after, className, children, ...restProps }) => (
  <button type="button" className={cn(styles.button, className)} {...restProps}>
    {before}
    <Typography variant="body2Med">{children}</Typography>
    {after}
  </button>
);

export default Button;
