import React, { useRef, useState } from 'react';
import { isNil } from 'ramda';
import Select, { components } from 'react-select';
import { useClickAway, useUpdateEffect } from 'react-use';
import './style.css';

const MODS = {
  read: 'read',
  edit: 'edit'
};

function ReadableViewSelect({
  id,
  value,
  options,
  onChange,
  onInputChange,
  onInputKeyDown,
  arrowRenderer,
  placeholder,
  noResultsText,
  isMulti = false,
  isClearable = false,
  isRemoveSelected = false,
  readableViewComponent
}) {
  if (isNil(readableViewComponent)) {
    throw new Error('View component must be provided');
  }

  const ref = useRef();
  const selectRef = useRef();
  const [mode, setMode] = useState(MODS.read);

  function setEditMode() {
    setMode(MODS.edit);
  }

  function setReadMode() {
    setMode(MODS.read);
  }

  useUpdateEffect(() => {
    setMode(MODS.read);
  }, [value]);

  useUpdateEffect(() => {
    if (mode === MODS.edit) {
      selectRef.current.focus();
    }
  }, [mode]);

  useClickAway(ref, setReadMode);

  return (
    <div id={id} className="rv-select" ref={ref}>
      {mode === MODS.read && (
        <div onClick={setEditMode}>{readableViewComponent(value)}</div>
      )}
      {mode === MODS.edit && (
        <Select
          ref={selectRef}
          classNamePrefix="dbs"
          value={value}
          options={options}
          onChange={onChange}
          onInputChange={onInputChange}
          onInputKeyDown={onInputKeyDown}
          arrowRenderer={arrowRenderer}
          placeholder={placeholder}
          noResultsText={noResultsText}
          isMulti={isMulti}
          isClearable={isClearable}
          isRemoveSelected={isRemoveSelected}
          components={{
            DropdownIndicator: arrowRenderer
              ? (props) => <DropdownIcon {...props} icon={arrowRenderer} />
              : null,
            IndicatorSeparator: null
          }}
          openMenuOnFocus
        />
      )}
    </div>
  );
}

function DropdownIcon(props) {
  return (
    <components.DropdownIndicator {...props}>
      {props.icon}
    </components.DropdownIndicator>
  );
}

export default ReadableViewSelect;
