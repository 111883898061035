import { assoc } from 'ramda';
import { makeBlockGroup, setLoading } from '../../data-type/block-group';
import {
  makeBlockList,
  mergeBlockList,
  updateItem
} from '../../data-type/block-list';
import {
  ADD_CONTACTS,
  SET_CONTACTS,
  UPDATE_CONTACT,
  SET_LOADING_CONTACTS,
  SET_FILTERED_CONTACTS
} from './constants';

export const defaultState = () => ({
  blockContact: makeBlockList(),
  filteredBlockContact: makeBlockList(),
  blockGroupContacts: makeBlockGroup()
});

const setBlockContact = (chatWidget, value) =>
  assoc('blockContact', value, chatWidget);
const setFilteredBlockContact = (chatWidget, value) =>
  assoc('filteredBlockContact', value, chatWidget);

export const reducers = {
  [SET_CONTACTS]: (chatWidget, { list, size }) =>
    setBlockContact(chatWidget, makeBlockList({ list, size })),
  [SET_FILTERED_CONTACTS]: (chatWidget, { list, size }) =>
    setFilteredBlockContact(chatWidget, makeBlockList({ list, size })),
  [ADD_CONTACTS]: (chatWidget, { list, size }) =>
    setBlockContact(
      chatWidget,
      mergeBlockList(chatWidget.blockContact, 'employeeId', { list, size })
    ),
  [UPDATE_CONTACT]: (chatWidget, contact) =>
    setBlockContact(chatWidget, updateItem(chatWidget.blockContact, contact)),
  [SET_LOADING_CONTACTS]: (chatWidget, { isLoading }) =>
    setBlockContact(chatWidget, setLoading(chatWidget.blockContact, isLoading))
};
