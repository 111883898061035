import React, { Component } from 'react';
import { translate } from 'react-i18next';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import block from 'bem-cn-lite';
import { Modal, Form, Button } from 'react-bootstrap';

import './styles/modal_theme_default.styl';

import { ModalContainer } from './elements';

import * as modalActions from '../../action-creators/modal';
import * as pricesActions from '../../action-creators/prices';
import * as catalogsActions from '../../action-creators/catalog';
import * as messageActions from '../../action-creators/message';

const b = block('modal-container');

class ModalPriceCurrencyUpdateConfirmation extends Component {
  asterisk() {
    // eslint-disable-line
    return <span style={{ color: 'red' }}>*</span>;
  }

  render() {
    const { t, hideModalDialog, submitAction } = this.props;
    return (
      <Modal
        id="simpleSubmit"
        onHide={() => {
          if (!this.state.publihStarted) hideModalDialog();
        }}
        show>
        <ModalContainer mods={{ type: 'default', theme: 'default' }}>
          <Modal.Header>
            <Modal.Title>
              {t('confirmation_currency_update_title')}
              <span
                className="modal-header-cross pull-right"
                onClick={() => hideModalDialog()}
              />
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <p className={b('row')}>
                {t('confirmation_currency_update_text')}
              </p>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button
              id="cancel"
              className="pull-left"
              onClick={() => hideModalDialog()}>
              {t('Cancel')}
            </Button>
            <Button
              id="saveButton"
              bsStyle="primary"
              onClick={async () => {
                await submitAction();
                hideModalDialog();
              }}>
              {t('Add')}
            </Button>
          </Modal.Footer>
        </ModalContainer>
      </Modal>
    );
  }
}

export default connect(null, (dispatch) =>
  bindActionCreators(
    {
      hideModalDialog: modalActions.hideModalDialog,
      setInfoMessage: messageActions.setInfoMessage,
      setSuccessMessage: messageActions.setSuccessMessage,
      addAllProductsToPrice: pricesActions.addAllProductsToPrice,
      searchProductOfPrice: pricesActions.searchProductOfPrice,
      getRBKRate: catalogsActions.getRBKRate
    },
    dispatch
  )
)(translate(['ui'], { wait: true })(ModalPriceCurrencyUpdateConfirmation));
