import React, { Component } from 'react';
import { translate } from 'react-i18next';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import block from 'bem-cn-lite';
import { Modal, Form, Button } from 'react-bootstrap';

import './styles/modal_theme_default.styl';

import { ModalContainer } from './elements';

import * as modalActions from '../../action-creators/modal';
import * as stockActions from '../../action-creators/stocks';
import * as messageActions from '../../action-creators/message';
import * as tableActionCreators from '../../action-creators/table';
import * as serviceActions from '../../action-creators/services';

const b = block('modal-container');

class ModalAddAllProductsToStock extends Component {
  constructor(props) {
    super(props);
    this.state = {
      publihStarted: false
    };
  }

  render() {
    const {
      t,
      gaSend,
      user,
      hideModalDialog,
      addAllProductToStock,
      getProducts,
      stockId,
      setInfoMessage,
      setSuccessMessage,
      products
    } = this.props;
    return (
      <Modal
        id="simpleSubmit"
        onHide={() => {
          if (!this.state.publihStarted) hideModalDialog();
        }}
        show>
        <ModalContainer mods={{ type: 'default', theme: 'default' }}>
          <Modal.Header>
            <Modal.Title>
              {t('confirmation_added')}
              <span
                className="modal-header-cross pull-right"
                onClick={() => {
                  if (!this.state.publihStarted) hideModalDialog();
                }}
              />
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <p className={b('row')}>{t('add_all_products_to_stock')}</p>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button
              id="cancel"
              className="pull-left"
              disabled={this.state.publihStarted}
              onClick={() => hideModalDialog()}>
              {t('Cancel')}
            </Button>
            <Button
              id="saveButton"
              bsStyle="primary"
              disabled={this.state.publihStarted}
              onClick={async () => {
                gaSend({
                  category: 'Supplier',
                  action: 'stock_add_allproduct',
                  label: user.get('email')
                });
                await this.setState({ publihStarted: true });
                await setInfoMessage({ key: 'Adding products' });
                await addAllProductToStock(stockId, products);
                await getProducts(stockId);
                await setSuccessMessage({
                  key: 'Products successfully added to the stock'
                });
                hideModalDialog();
              }}>
              {t('Add')}
            </Button>
          </Modal.Footer>
        </ModalContainer>
      </Modal>
    );
  }
}

export default connect(
  (state) => ({
    user: state.getIn(['user', 'user'])
  }),
  (dispatch) =>
    bindActionCreators(
      {
        hideModalDialog: modalActions.hideModalDialog,
        setInfoMessage: messageActions.setInfoMessage,
        setSuccessMessage: messageActions.setSuccessMessage,
        addAllProductToStock: stockActions.addAllProductToStock,
        getProducts: stockActions.searchProductOfStock,
        clearTouchCategory: tableActionCreators.clearTouch,
        gaSend: serviceActions.gaSend
      },
      dispatch
    )
)(translate(['ui'], { wait: true })(ModalAddAllProductsToStock));
