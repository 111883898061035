import React from 'react';
import PropTypes from 'prop-types';
import { translate } from 'react-i18next';
import ReactSummernote from 'react-summernote';
import 'react-summernote/dist/react-summernote.css';
import 'react-summernote/lang/summernote-ru-RU';
import 'bootstrap/js/modal';
import 'bootstrap/js/dropdown';
import 'bootstrap/js/tooltip';
import 'bootstrap/dist/css/bootstrap.css';
import getBrowserLanguage from '../../../../utils/languageDetector';
import './style.css';

const Editor = ({ t, value, onChange }) => {
  let isInit = false;

  // For solve bug in react-summernote 2.0.2 https://github.com/summernote/react-summernote/issues/85
  const onInit = (note, val) => {
    note.reset();
    const regex = /(\w*)((\s\/)|(.*\/\w*))/i;
    if (val.match(regex) !== null) {
      note.replace(val);
    } else {
      note.insertText(val);
    }
    isInit = true;
  };

  const onChangeHandler = (val) => {
    if (isInit) onChange(val);
  };

  return (
    <div className="cw-create-topic__editor">
      <ReactSummernote
        id="description-editor"
        options={{
          lang: `${getBrowserLanguage()}-${getBrowserLanguage().toUpperCase()}`,
          height: 480,
          dialogsInBody: true,
          disableResizeEditor: true,
          placeholder: t('chat-widget.create-topic.editor-placeholder'),
          toolbar: [
            ['fontname', ['fontname']],
            ['fontsize', ['fontsize']],
            ['color', ['color']],
            ['font', ['bold']],
            ['font', ['italic']],
            ['font', ['underline']],
            ['para', ['paragraph']],
            ['para', ['ul']],
            ['height', ['height']],
            ['insert', ['link']]
          ]
        }}
        onInit={(note) => onInit(note, value)}
        onChange={onChangeHandler}
      />
    </div>
  );
};

Editor.propTypes = {
  t: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired
};

export default translate()(Editor);
