import React from 'react';
import Icon from 'react-components/src/icons';
import iconsView from 'react-components/src/icons/constants';
import styles from './EditorButtons.module.css';
import { EmojiSelect } from '../emojiSelect';
import { PlannedDatePicker } from '../../../modules/delayedMessages/plannedDatePicker';
import { ButtonSend } from '../buttonSend';
import { isEditMode } from '../functions';
import { ChatButton } from '../new-components/chat-button/ChatButton';

export const EditorButtons = ({
  EmojiPicker,
  onButtonSendClick,
  isSendDisable,
  mode,
  setIsFormattedOpen,
  isFormattedOpen
}) => (
  <div className={styles.fullButtonsWrapper}>
    <div className={styles.textButtons}>
      <ChatButton
        isActive={isFormattedOpen}
        icon={<Icon icon={iconsView.FormatText} size={18} />}
        onClick={() => setIsFormattedOpen((prevState) => !prevState)}
      />
      <EmojiSelect>
        <EmojiPicker />
      </EmojiSelect>
      <PlannedDatePicker />
    </div>
    <ButtonSend
      isEditMode={isEditMode(mode)}
      isSendDisable={isSendDisable}
      onSend={onButtonSendClick}
    />
  </div>
);
