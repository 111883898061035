import { Provider } from './provider.types';

const MAX_SUGGESTIONS_COUNT = 15;

function readDataFromLocalStorage(key: string): string[] {
  const storageItem = localStorage.getItem(key);
  if (storageItem) {
    return JSON.parse(storageItem) as string[];
  }

  return [];
}

function saveDataToLocalStorage(key: string, items: string[]): void {
  localStorage.setItem(key, JSON.stringify(items));
}

export class LocalStorageProvider implements Provider {
  store: string;

  constructor(store: string) {
    this.store = `${localStorage.getItem('userId')}-${store}`;
  }

  async add(item: string): Promise<boolean> {
    if (item) {
      const items = readDataFromLocalStorage(this.store);
      if (items.length === MAX_SUGGESTIONS_COUNT) {
        items.shift();
      }
      items.push(item);
      saveDataToLocalStorage(this.store, items);
      return true;
    }

    return false;
  }

  async get(): Promise<string[]> {
    let result: string[];
    const store = localStorage.getItem(this.store);
    if (!store) {
      result = [];
    } else {
      result = JSON.parse(store);
    }

    return result;
  }
}
