import React from 'react';

function AttachSVG() {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_25317_871)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M3.13665 7.58577C0.988762 9.73365 0.988762 13.2161 3.13665 15.3639C5.28453 17.5118 8.76694 17.5118 10.9148 15.3639L14.4825 11.7963L13.7754 11.0892L10.2077 14.6568C8.45035 16.4142 5.60111 16.4142 3.84375 14.6568C2.08639 12.8995 2.08639 10.0502 3.84375 8.29287L9.80365 2.33297C11.0031 1.13351 12.9478 1.13351 14.1473 2.33297C15.3468 3.53244 15.3468 5.47716 14.1473 6.67663L8.35271 12.4712C7.71113 13.1128 6.67093 13.1128 6.02936 12.4712C5.38778 11.8297 5.38778 10.7895 6.02936 10.1479L10.5245 5.6527L9.81743 4.9456L5.32225 9.44077C4.29015 10.4729 4.29015 12.1462 5.32225 13.1783C6.35435 14.2104 8.02771 14.2104 9.05981 13.1783L14.8544 7.38374C16.4444 5.79375 16.4444 3.21586 14.8544 1.62587C13.2644 0.035875 10.6865 0.0358753 9.09655 1.62587L3.13665 7.58577Z"
          fill="#767B92"
        />
      </g>
      <defs>
        <clipPath id="clip0_25317_871">
          <rect
            width="18"
            height="18"
            fill="white"
            transform="translate(0.000488281)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}

export default AttachSVG;
