import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { ModalCreateStock } from '../components/Modal';
import * as stocksAction from '../action-creators/stocks';
import * as modalAction from '../action-creators/modal';
import * as servicesActions from '../action-creators/services';

const ContModalCreateStock = (props) => <ModalCreateStock {...props} />;

export default connect(
  (state) => ({
    fields: state.getIn(['stocks', 'formCreate']),
    moreInfo: state.getIn(['stocks', 'moreInfo']),
    errors: state.getIn(['stocks', 'errors'])
  }),
  (dispatch) =>
    bindActionCreators(
      {
        updateFieldStock: stocksAction.updateFieldStock,
        submit: stocksAction.addStock,
        getAllStocks: stocksAction.getAllStocks,
        getMoreInfo: stocksAction.getMoreInfo,
        clearForm: stocksAction.clearForm,
        removeErrorByField: stocksAction.removeErrorByField,
        setDefaultValuesToForm: stocksAction.setDefaultValuesToForm,
        hideModalDialog: modalAction.hideModalDialog,
        gaPageView: servicesActions.gaPageView
      },
      dispatch
    )
)(ContModalCreateStock);
