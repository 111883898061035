import React, { Component } from 'react';
import { translate } from 'react-i18next';
import block from 'bem-cn-lite';

import ArrowFirst from '../../icons/arrow-first';
import ArrowLast from '../../icons/arrow-last';
import ArrowNext from '../../icons/arrow-next';
import ArrowPrev from '../../icons/arrow-prev';
import ItemsOnPage from '../../presenters/ItemsOnPageHundred';

import './style.styl';

const b = block('pagination-new');

class PaginationNew extends Component {
  constructor(props) {
    super(props);
    this.toFirst = this.toFirst.bind(this);
    this.toLast = this.toLast.bind(this);
    this.toPrev = this.toPrev.bind(this);
    this.toNext = this.toNext.bind(this);
    this.changeItemsOnPage = this.changeItemsOnPage.bind(this);
  }

  getCountOfProduct() {
    const { offset, limit, totalSize } = this.props;
    const sum = offset + limit;
    if (sum > totalSize) {
      return totalSize;
    }
    return sum;
  }

  getPrevOffset() {
    const { offset, limit } = this.props;
    return offset - limit;
  }

  getNextOffset() {
    const { offset, limit } = this.props;
    return offset + limit;
  }

  getLastOffset() {
    const { totalSize, limit } = this.props;
    const result = Math.floor(totalSize / limit) * limit;
    return result === totalSize ? totalSize - limit : result;
  }

  getNavText() {
    const { t, offset, totalSize } = this.props;
    return `${offset + 1}-${this.getCountOfProduct()} ${t(
      'from'
    )} ${totalSize}`;
  }

  isShow() {
    const { totalSize, limit } = this.props;
    return Math.ceil(totalSize / limit) > 1;
  }

  isDisabledPrev() {
    return this.getPrevOffset() < 0;
  }

  isDisabledNext() {
    const { totalSize } = this.props;
    return this.getNextOffset() >= totalSize;
  }

  activePage() {
    const { offset, limit } = this.props;
    return offset / limit + 1;
  }

  nextPage(offset, limitValue) {
    const { nextPage, limit } = this.props;
    nextPage(offset, limitValue || limit);
  }

  toFirst() {
    return !this.isDisabledPrev() && this.nextPage(0);
  }

  toPrev() {
    return !this.isDisabledPrev() && this.nextPage(this.getPrevOffset());
  }

  toNext() {
    return !this.isDisabledNext() && this.nextPage(this.getNextOffset());
  }

  toLast() {
    return !this.isDisabledNext() && this.nextPage(this.getLastOffset());
  }

  changeItemsOnPage(limit) {
    this.nextPage(0, limit);
  }

  render() {
    const { limit, isShowItemsOnPage = false } = this.props;

    return (
      <div className="text-center" style={{ position: 'relative' }}>
        {isShowItemsOnPage && (
          <ItemsOnPage
            onChange={this.changeItemsOnPage}
            value={limit}
            style={{ position: 'absolute', top: 4, right: 0 }}
          />
        )}
        {this.isShow() && (
          <div className={b()}>
            <button
              type="button"
              className={b('btn', {
                first: true,
                disabled: this.isDisabledPrev()
              })}
              onClick={this.toFirst}>
              <ArrowFirst />
            </button>
            <button
              type="button"
              className={b('btn', {
                prev: true,
                disabled: this.isDisabledPrev()
              })}
              onClick={this.toPrev}>
              <ArrowPrev />
            </button>
            <div type="button" className={b('nav-text')}>
              {this.getNavText()}
            </div>
            <button
              type="button"
              className={b('btn', {
                next: true,
                disabled: this.isDisabledNext()
              })}
              onClick={this.toNext}>
              <ArrowNext />
            </button>
            <button
              type="button"
              className={b('btn', {
                last: true,
                disabled: this.isDisabledNext()
              })}
              onClick={this.toLast}>
              <ArrowLast />
            </button>
          </div>
        )}
      </div>
    );
  }
}

export default translate(['ui'], { wait: true })(PaginationNew);
