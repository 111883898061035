import fetch from 'isomorphic-fetch'; // eslint-disable-line
import { setErrorMessage } from './message';

export const toggleChatEmailNotification = () => (dispatch) => {
  dispatch({ type: 'USER:NOTIFICATIONS:TOGGLE_CHAT:REQUEST' });

  const url = '/api/notification/chat';

  fetch(url, {
    credentials: 'include',
    method: 'POST',
    headers: { 'Content-Type': 'application/json' }
  })
    .then((res) => res.json())
    .then(() => {
      dispatch({ type: 'USER:NOTIFICATIONS:TOGGLE_CHAT:SUCCESS', payload: {} });
    })
    .catch((error) => {
      setErrorMessage({ key: 'something went wrong' });
      dispatch({
        type: 'USER:NOTIFICATIONS:TOGGLE_CHAT:FAILURE',
        payload: { error }
      });
    });
};

export const togglePriceChangeNotification =
  (supplierId, isEnabled) => (dispatch) => {
    dispatch({ type: 'USER:NOTIFICATIONS:TOGGLE_PRICE_CHANGE:REQUEST' });
    return fetch(`/api/notification/price/${supplierId}/toggle`, {
      credentials: 'include',
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ enabled: isEnabled })
    })
      .then((res) => res.json())
      .then(({ settings }) => {
        dispatch({
          type: 'USER:NOTIFICATIONS:TOGGLE_PRICE_CHANGE:SUCCESS',
          payload: { settings }
        });
      })
      .catch((err) => {
        setErrorMessage({ key: 'something went wrong' });
        console.error(err);
        dispatch({
          type: 'USER:NOTIFICATIONS:TOGGLE_PRICE_CHANGE:FAILURE',
          payload: { settings: [] }
        });
      });
  };

export const toggleSoundNotification = () => (dispatch, getState) => {
  const soundNotification = getState().getIn([
    'user',
    'user',
    'soundNotifications'
  ]);
  localStorage.setItem('sound-notification', !soundNotification ? 'on' : 'off');
  dispatch({
    type: 'USER:NOTIFICATIONS:TOGGLE_SOUND',
    payload: !soundNotification
  });
};
