import { useCallback, useEffect, useState } from 'react';

export function useFilter(_options) {
  const [options, setOptions] = useState(_options);

  useEffect(() => {
    setOptions(_options);
  }, [JSON.stringify(_options)]);

  const onCheck = useCallback((value) => {
    setOptions((prevState) =>
      prevState.map((option) =>
        option.value === value
          ? {
              ...option,
              checked: !option.checked
            }
          : option
      )
    );
  }, []);

  const clearOptionsChecked = useCallback(() => {
    setOptions((prevState) =>
      prevState.map((option) => ({ ...option, checked: false }))
    );
  }, []);

  return { options, onCheck, clearOptionsChecked };
}
