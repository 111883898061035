import React from 'react';
import { useVideoChatHandlers } from './hooks/useVideoChatHandlers';
import VideoChatWrapper from './components/VideoChatWrapper';
import ControlPanel from './components/ControlPanel';
import Theatre from './components/Theatre';
import Sidebar from './components/Sidebar';
import './style.css';

const VideoChat = () => {
  useVideoChatHandlers();

  return (
    <VideoChatWrapper>
      <Theatre />
      <Sidebar />
      <ControlPanel />
    </VideoChatWrapper>
  );
};

export default VideoChat;
