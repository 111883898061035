import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isNil } from 'ramda';
import { useToggle } from 'react-use';
import { useActions } from '@hooks';
import FilesInput from 'react-components/inputs/files-input';
import DBSimpleInput from 'react-components/inputs/dynamic-border/simple-input';
import DynamicBorderSelect from 'react-components/inputs/dynamic-border/select';
import ConfirmEditTextarea from 'react-components/inputs/dynamic-border/confirmEditTextarea';
import RangeDateTimePicker from 'react-components/datapickers/range-datetime-input-picker';
import ButtonIcon from 'react-components/buttons/ButtonIcon';
import Tooltip from 'react-components/tooltip';
import { useTranslate } from '../../../../TranslateProvider';
import TagSVG from '../../../assets/icons/Tag';
import * as storage from '../../../storage';
import * as storeGetters from '../../../../storeGetters';
import ProfileSVG from '../../../assets/icons/Profile';
import * as getters from '../../../getters';
import { actions } from '../../../reducers/task';
import ScheduleSVG from '../../../assets/icons/Schedule';
import TagSelect, { transformTagOptionList } from '../../Selects/TagSelect';
import StatusSelect, {
  generateSelectOptionByStatus
} from '../../Selects/StatusSelect';
import { getFullName } from '../../../../utils/utils';
import CheckboxWithLabel from '../../../../components/CheckboxWithLabel';
import Helper from '../../../../components/Helper';
import './style.css';
import * as storageActions from '../../../../action-creators/storage';
import customHistory from '../../../../customHistory';
import * as messageActions from '../../../../action-creators/message';

const MAX_LENGTH_TITLE = 255;
const MAX_LENGTH_DESCRIPTION = 3000;
const MAX_FILES_COUNT = 10;
const DEFAULT_RANGE_DATE = { start: null, end: null };

const IconArrow = <img src="/img/drop.svg" alt="" />;

const EditTaskSidebar = () => {
  const dispatch = useDispatch();
  const t = useTranslate();

  const task = useSelector(getters.getCurrentTask);
  const executorsOptions = useSelector(getters.getExecutorsList);
  const currentEmployeeId = useSelector(storeGetters.getCurrentEmployeeId);

  const [isPrivate, toggleIsPrivate] = useToggle(false);
  const [selectedTags, setSelectedTags] = useState([]);
  const [initFiles, setInitFiles] = useState([]);
  const [files, setFiles] = useState([]);
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [status, setStatus] = useState(null);
  const [rangeDate, setRangeDate] = useState(DEFAULT_RANGE_DATE);
  const [executor, setExecutor] = useState(null);

  const { removeFileLinkBySection } = useActions(storageActions);
  const showErrorAlert = useActions(messageActions.setErrorMessage);

  useEffect(() => {
    dispatch(storage.getAllUserTags());
    dispatch(storage.getPossibleExecutors());
  }, []);

  const checkShowPrivateToggle = () =>
    task?.owner && task?.owner === currentEmployeeId;

  const onCloseSidebar = () => {
    dispatch(actions.setCurrentTask({ task: null }));
    customHistory.push({
      search: null
    });
  };

  const onDeleteTask = () => {
    if (confirm(t('taskManager.confirmRemoveTask'))) {
      dispatch(storage.removeTask({ id: task.id, status: task.status }));
      onCloseSidebar();
    }
  };

  const onChangeTask = useCallback(
    (data) => {
      if (isNil(task)) return;
      dispatch(storage.editTask({ id: task.id, data }));
    },
    [task && task.id]
  );

  const getCurrentExecutorOption = () => {
    const currentExecutor = task.executors[0];

    if (isNil(currentExecutor)) return null;

    return { value: currentExecutor.id, label: getFullName(currentExecutor) };
  };

  const getCurrentRangeDate = () => {
    const { startDate, endDate } = task || {};

    if (!isNil(startDate) || !isNil(endDate)) {
      return {
        start: startDate,
        end: endDate
      };
    }

    return DEFAULT_RANGE_DATE;
  };

  useEffect(() => {
    if (!isNil(task)) {
      toggleIsPrivate(task.isPrivate);
      setTitle(task.title);
      setStatus(generateSelectOptionByStatus(t, task.status));
      setDescription(task.description);
      setExecutor(getCurrentExecutorOption());
      setSelectedTags(transformTagOptionList(task.tags));
      setRangeDate(getCurrentRangeDate());

      const initialFiles = task.attrs.files || [];
      setInitFiles(initialFiles);
      setFiles(initialFiles);
    }
  }, [task && task.id]);

  const onChangeSelectedTags = (data) => {
    setSelectedTags(data);

    const tagsIds = data.map((tag) => tag.value);

    onChangeTask({ tags: tagsIds });
  };

  const onChangeTaskStatus = (option) => {
    setStatus(option);
    onChangeTask({ status: option.value });
    dispatch(
      storage.moveTask({
        id: task.id,
        position: 0,
        fromStatus: task.status,
        atStatus: option.value
      })
    );
  };

  const onChangeExecutor = (data) => {
    setExecutor(data);
    onChangeTask({ executors: [data.value] });
  };

  const onChangeTitle = (event) => {
    const { value } = event.target;
    setTitle(value);
  };

  const onBlurTitle = () => {
    onChangeTask({ title });
  };

  const onTitleKeyDown = (event) => {
    if (event.key === 'Enter') {
      onChangeTask({ title });
    }
  };

  const onChangeDescription = (data) => {
    setDescription(data);
    onChangeTask({ description: data });
  };

  const onChangeRangeDate = (data) => {
    setRangeDate(data);
    onChangeTask({ rangeDate: data });
  };

  const onTogglePrivate = () => {
    onChangeTask({ isPrivate: !isPrivate });
    toggleIsPrivate(!isPrivate);
  };

  const onUploadFile = (file) => {
    const updatedFiles = [...files, file.data];

    onChangeTask({ files: updatedFiles });

    setFiles(updatedFiles);
  };
  const onDeleteFile = (fileId) => {
    const neededId = files.find((file) => file.id === fileId).fileId;
    const updatedFiles = files.filter((file) => file.id !== fileId);

    onChangeTask({ files: updatedFiles, removedFileId: fileId });
    removeFileLinkBySection([neededId], 'uploads');
    setFiles(updatedFiles);
  };
  const onMaxFilesError = (countFiles, maxFiles) => {
    showErrorAlert({
      key: 'error_file_upload_limit',
      params: { amount: maxFiles }
    });
  };

  return (
    <div className="edit-task-sidebar">
      <div className="edit-task-sidebar__block ml-8">
        <div className="edit-task-sidebar__row edit-task-sidebar__row_title">
          <DBSimpleInput
            t={t}
            value={title}
            tooltip="edit"
            placeholder={t('taskManager.editTask.placeholders.title')}
            onChange={onChangeTitle}
            onBlur={onBlurTitle}
            onKeyDown={onTitleKeyDown}
            maxLength={MAX_LENGTH_TITLE}
          />
          <div className="edit-task-sidebar__row-actions">
            <ButtonIcon
              t={t}
              icon="remove-2"
              tooltip="delete"
              onClick={onDeleteTask}
            />
            <ButtonIcon t={t} icon="cross-circle" onClick={onCloseSidebar} />
          </div>
        </div>
      </div>
      <hr className="edit-task-sidebar__hr" />
      <div className="edit-task-sidebar__block ml-8">
        {checkShowPrivateToggle() && (
          <div className="edit-task-sidebar__row">
            <CheckboxWithLabel
              className="edit-task-sidebar__checkbox"
              labelClassName="edit-task-sidebar__checkbox-label"
              value={isPrivate}
              onClick={onTogglePrivate}
              label={t('taskManager.editTask.labels.private_task')}
            />
            <Helper
              placement="top"
              text={t('taskManager.editTask.hints.private_task')}
            />
          </div>
        )}
        <StatusSelect value={status} onChange={onChangeTaskStatus} />
        <ConfirmEditTextarea
          t={t}
          placeholder={t('taskManager.editTask.placeholders.description')}
          value={description}
          onConfirm={onChangeDescription}
          maxLength={MAX_LENGTH_DESCRIPTION}
          minRows={1}
          maxRows={12}
        />
      </div>
      <hr className="edit-task-sidebar__hr" />
      <div className="edit-task-sidebar__block">
        <Tooltip t={t} text="edit" isWide>
          <div className="edit-task-sidebar__row edit-task-sidebar__row_icon">
            <div className="edit-task-sidebar__row-icon">
              <ProfileSVG />
            </div>
            <DynamicBorderSelect
              placeholder={t('taskManager.editTask.placeholders.set_executor')}
              arrowRenderer={IconArrow}
              value={executor}
              onChange={onChangeExecutor}
              options={executorsOptions}
            />
          </div>
        </Tooltip>

        <Tooltip t={t} text="edit" isWide>
          <div className="edit-task-sidebar__row edit-task-sidebar__row_icon">
            <div className="edit-task-sidebar__row-icon">
              <ScheduleSVG />
            </div>
            <RangeDateTimePicker
              startDate={rangeDate.start}
              endDate={rangeDate.end}
              placeholder={t('taskManager.editTask.placeholders.set_date_time')}
              onApply={onChangeRangeDate}
              onReset={onChangeRangeDate}
            />
          </div>
        </Tooltip>

        <Tooltip t={t} text="edit" isWide>
          <div className="edit-task-sidebar__row edit-task-sidebar__row_auto-height edit-task-sidebar__row_icon">
            <div className="edit-task-sidebar__row-icon">
              <TagSVG />
            </div>
            <TagSelect
              placeholder={t('taskManager.editTask.placeholders.add_tag')}
              value={selectedTags}
              onChange={onChangeSelectedTags}
              maxWidth={358}
            />
          </div>
        </Tooltip>
        <FilesInput
          t={t}
          title={t('taskManager.editTask.inputs.files')}
          btnAddFile={t('taskManager.editTask.inputs.add_file')}
          list={initFiles}
          onUploadFile={onUploadFile}
          onDeleteFile={onDeleteFile}
          maxFiles={MAX_FILES_COUNT}
          onMaxFilesError={onMaxFilesError}
        />
      </div>
    </div>
  );
};

export default EditTaskSidebar;
