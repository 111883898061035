import { List, Map, fromJS } from 'immutable';

const setEmail = (state, value) => state.set('email', fromJS(value));

const setWhitelist = (state, whitelist) =>
  state.set('data', fromJS(whitelist)).set('email', '');

const initState = () =>
  Map({
    email: '',
    data: List()
  });

export default (state = initState(), action) => {
  switch (action.type) {
    case 'WHITELIST:GET_WHITELIST:SUCCESS':
      return setWhitelist(state, action.payload);
    case 'WHITELIST:ADD_NEW_USER_TO_WHITELIST:SUCCESS':
      return setWhitelist(state, action.payload);
    case 'WHITELIST:SET_EMAIL':
      return setEmail(state, action.payload);
    case 'WHITELIST:REMOVE_USER_FROM_WHITELIST:SUCCESS':
      return setWhitelist(state, action.payload);
    case 'WHITELIST:CLEAR_WHITELIST':
      return initState();
    default:
      return state;
  }
};
