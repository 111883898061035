import React from 'react';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import { translate } from 'react-i18next';
import PropTypes from 'prop-types';

import './style.css';

const createOption = (type, option, t) => {
  const roleText = t(`channel-roles-${option.value}`);
  const roleCaption = t(`${type.toLowerCase()}-roles-caption-${option.value}`);

  return (
    <div key={option.value} className="roles-helper__container">
      <div className="roles-helper__role-value">
        <span>{roleText}</span>
      </div>
      <div className="roles-helper__role-caption">{roleCaption}</div>
    </div>
  );
};

const renderOptions = (type, options, t) =>
  options.map((option) => createOption(type, option, t));

const RolesHelper = ({ t, type, options }) => (
  <OverlayTrigger
    rootClose
    trigger={['click']}
    placement="right"
    overlay={
      <Popover className="roles-helper-popover" id="roles-popover">
        <div>{renderOptions(type, options, t)}</div>
      </Popover>
    }>
    <img
      alt="ask"
      role="presentation"
      src="/img/ask.svg"
      style={{ cursor: 'pointer' }}
    />
  </OverlayTrigger>
);

RolesHelper.propTypes = {
  t: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired
};

export default translate()(RolesHelper);
