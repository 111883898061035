import React from 'react';

/* eslint-disable max-len */
const MessengerWidgetResizer = () => (
  <svg
    width="9"
    height="9"
    viewBox="0 0 9 9"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M3 0.5C3 0.776142 2.77614 1 2.5 1C2.22386 1 2 0.776142 2 0.5C2 0.223858 2.22386 0 2.5 0C2.77614 0 3 0.223858 3 0.5Z"
      fill="#767B92"
    />
    <path
      d="M3 2.5C3 2.77614 2.77614 3 2.5 3C2.22386 3 2 2.77614 2 2.5C2 2.22386 2.22386 2 2.5 2C2.77614 2 3 2.22386 3 2.5Z"
      fill="#767B92"
    />
    <path
      d="M5 0.5C5 0.776142 4.77614 1 4.5 1C4.22386 1 4 0.776142 4 0.5C4 0.223858 4.22386 0 4.5 0C4.77614 0 5 0.223858 5 0.5Z"
      fill="#767B92"
    />
    <path
      d="M5 2.5C5 2.77614 4.77614 3 4.5 3C4.22386 3 4 2.77614 4 2.5C4 2.22386 4.22386 2 4.5 2C4.77614 2 5 2.22386 5 2.5Z"
      fill="#767B92"
    />
    <path
      d="M7 0.5C7 0.776142 6.77614 1 6.5 1C6.22386 1 6 0.776142 6 0.5C6 0.223858 6.22386 0 6.5 0C6.77614 0 7 0.223858 7 0.5Z"
      fill="#767B92"
    />
    <path
      d="M7 2.5C7 2.77614 6.77614 3 6.5 3C6.22386 3 6 2.77614 6 2.5C6 2.22386 6.22386 2 6.5 2C6.77614 2 7 2.22386 7 2.5Z"
      fill="#767B92"
    />
    <path
      d="M9 0.5C9 0.776142 8.77614 1 8.5 1C8.22386 1 8 0.776142 8 0.5C8 0.223858 8.22386 0 8.5 0C8.77614 0 9 0.223858 9 0.5Z"
      fill="#767B92"
    />
    <path
      d="M9 2.5C9 2.77614 8.77614 3 8.5 3C8.22386 3 8 2.77614 8 2.5C8 2.22386 8.22386 2 8.5 2C8.77614 2 9 2.22386 9 2.5Z"
      fill="#767B92"
    />
    <path
      d="M1 2.5C1 2.77614 0.776142 3 0.5 3C0.223858 3 0 2.77614 0 2.5C0 2.22386 0.223858 2 0.5 2C0.776142 2 1 2.22386 1 2.5Z"
      fill="#767B92"
    />
    <path
      d="M1 4.5C1 4.77614 0.776142 5 0.5 5C0.223858 5 0 4.77614 0 4.5C0 4.22386 0.223858 4 0.5 4C0.776142 4 1 4.22386 1 4.5Z"
      fill="#767B92"
    />
    <path
      d="M3 4.5C3 4.77614 2.77614 5 2.5 5C2.22386 5 2 4.77614 2 4.5C2 4.22386 2.22386 4 2.5 4C2.77614 4 3 4.22386 3 4.5Z"
      fill="#767B92"
    />
    <path
      d="M1 6.5C1 6.77614 0.776142 7 0.5 7C0.223858 7 0 6.77614 0 6.5C0 6.22386 0.223858 6 0.5 6C0.776142 6 1 6.22386 1 6.5Z"
      fill="#767B92"
    />
    <path
      d="M3 6.5C3 6.77614 2.77614 7 2.5 7C2.22386 7 2 6.77614 2 6.5C2 6.22386 2.22386 6 2.5 6C2.77614 6 3 6.22386 3 6.5Z"
      fill="#767B92"
    />
    <path
      d="M1 8.5C1 8.77614 0.776142 9 0.5 9C0.223858 9 0 8.77614 0 8.5C0 8.22386 0.223858 8 0.5 8C0.776142 8 1 8.22386 1 8.5Z"
      fill="#767B92"
    />
    <path
      d="M3 8.5C3 8.77614 2.77614 9 2.5 9C2.22386 9 2 8.77614 2 8.5C2 8.22386 2.22386 8 2.5 8C2.77614 8 3 8.22386 3 8.5Z"
      fill="#767B92"
    />
  </svg>
);
/* eslint-enable max-len */

export default MessengerWidgetResizer;
