const { makeCommand, makeEvent } = require('core/data/messageBus/message');
const { makeQuery } = require('core/messages/utils');

const NS = 'contact';

exports.toggleBlockCMD = makeCommand(NS, 'toggleBlock');
exports.blockedEVT = makeEvent(NS, 'blocked');
exports.unblockedEVT = makeEvent(NS, 'unblocked');
exports.blockedMeEVT = makeEvent(NS, 'blockedMe');
exports.unblockedMeEVT = makeEvent(NS, 'unblockedMe');

const roomName = ({ whoEmployeeId, targetEmployeeId }) =>
  `profile-view.${whoEmployeeId}.${targetEmployeeId}`;
exports.profileQRY = makeQuery(NS, 'profile', roomName);

exports.notPossibleEVT = makeEvent(NS, 'error:not-possible-to-service-account');

exports.createProfileCMD = makeCommand(NS, 'createProfile');
exports.errorTriedCreateExistingProfileEVT = makeEvent(
  NS,
  'error:tried-to-create-an-existing-profile'
);
exports.profileWasCreatedEVT = makeEvent(NS, 'profileWasCreated');
