import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { translate } from 'react-i18next';
import { withRouter } from '../hoc/withRouter';

import WorkspaceHeader from '../components/WorkspaceHeader/WorkspaceHeader';
import MessengerOther from '../components/Layouts/MessengerOther';
import FormEditUser from '../components/Forms/FormEditUser';

import * as modalActions from '../action-creators/modal';
import * as messageActions from '../action-creators/message';
import * as companyActions from '../action-creators/company';
import * as userActions from '../action-creators/user';
import * as servicesAction from '../action-creators/services';

class ProfileEdit extends Component {
  constructor(props) {
    super(props);
    this.showModalDeleteAccountHandler =
      this.showModalDeleteAccountHandler.bind(this);
    this.showModalDeleteAccount = this.showModalDeleteAccount.bind(this);
  }

  UNSAFE_componentWillMount() {
    const { getCompany, getListOfUsers } = this.props;
    getCompany();
    getListOfUsers();
  }

  showModalDeleteAccount() {
    const { t, gaSend, user, showModal, removeAccount } = this.props;
    return showModal('SIMPLE_SUBMIT', {
      captionKey: 'Delete account',
      text: t('Delete_account_text'),
      textBtnConfirm: t('Delete'),
      submitAction: () => removeAccount(),
      onSubmited: () => {
        gaSend({
          category: 'Common',
          action: 'delete_account',
          label: user.get('email')
        });
        window.location = `${process.env.UNICAT_MESSENGER_SERVICE}/login`;
      }
    });
  }

  showModalDeleteAccountHandler() {
    const { user, showModal, company } = this.props;

    if (
      company.get('listOfUsers').size > 1 &&
      company.get('ownerUserId') === user.get('id')
    ) {
      return showModal('ACCOUNT_DELETE_RESTRICTED');
    }
    return this.showModalDeleteAccount();
  }

  render() {
    const {
      t,
      history,
      showModal,
      company,
      user,
      gaSend,
      editUser,
      updateUserField,
      uploadUserImage,
      setSuccessMessage,
      uploadAvatar
    } = this.props;

    const title = t('My profile');
    const breadcrumbs = [{ name: t('My Profile') }];
    const panelBtns = [
      {
        name: t('Delete account'),
        disabled: user.get('type') === 'demo',
        onClick: this.showModalDeleteAccountHandler
      }
    ];
    const navigationTabs = {
      activeKey: 'edit',
      items: [{ eventKey: 'edit', name: t('My profile') }],
      handleTabChange: (tab) => history.push(`/profile/${tab}`)
    };

    return (
      <MessengerOther
        title={title}
        breadcrumbs={breadcrumbs}
        panelBtns={panelBtns}
        content={
          <>
            <WorkspaceHeader navigation={navigationTabs} />
            <div className="form-content-center">
              <FormEditUser
                t={t}
                user={user}
                company={company}
                gaSend={gaSend}
                updateUserField={updateUserField}
                saveUser={editUser}
                uploadUserImage={uploadUserImage}
                showModal={showModal}
                setSuccessMessage={setSuccessMessage}
                uploadAvatar={uploadAvatar}
              />
            </div>
          </>
        }
      />
    );
  }
}

export default withRouter(
  connect(
    (state) => ({
      user: state.getIn(['user', 'user']),
      company: state.get('company')
    }),
    (dispatch) =>
      bindActionCreators(
        {
          hideModalDialog: modalActions.hideModalDialog,
          showModal: modalActions.showModal,
          setSuccessMessage: messageActions.setSuccessMessage,
          gaSend: servicesAction.gaSend,
          ...companyActions,
          ...userActions
        },
        dispatch
      )
  )(translate(['ui'], { wait: true })(ProfileEdit))
);
