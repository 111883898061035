import { ContactDto } from './contact';
import { RequisiteDto } from './requisite';
import { CategoryDto } from './category';

export class CompanyInfoDto {
  constructor(props) {
    this.id = props?.id;
    this.fullName = props?.fullName || '';
    this.name = props?.name || '';
    this.email = props?.email || '';
    this.logo = props?.logo || '';
    this.description = props?.description || '';
    this.categories = Array.isArray(props?.categories)
      ? props.categories.map((category) => new CategoryDto(category))
      : [];
    this.requisites = Array.isArray(props?.requisites)
      ? props.requisites.map((requisite) => new RequisiteDto(requisite))
      : [];
    this.contacts = Array.isArray(props?.contacts)
      ? props.contacts.map((contact) => new ContactDto(contact))
      : [];
    this.isPartner = props?.isPartner || false;
    this.isRNP = props?.isRNP || false;
    this.isSubscribed = props?.isSubscribed || false;
  }
}
