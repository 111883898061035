import {
  PropsWithChildren,
  SyntheticEvent,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState
} from 'react';
import cn from 'classnames';
import {
  Accordion as MUIAccordion,
  AccordionDetails as MUIAccordionDetails,
  AccordionSummary as MUIAccordionSummary
} from '@mui/material';
import { useId } from '@/hooks';
import { Icon } from '@/components/Icon';
import { Typography } from '@/components/Typography';
import { useAccordionContext } from './AccordionContext';
import { AccordionItemProps, AccordionRegisterItem } from './Accordion.types';
import classes from './Accordion.module.scss';

const DEFAULT_ICON = 'arrow-down';
const DEFAULT_ICON_SIZE = 18;

type AccordionChangeCallback = (event: SyntheticEvent, opened: boolean) => void;

function useAccordionItem(
  itemId?: string,
  defaultExpanded?: boolean,
  onChange?: AccordionChangeCallback
): [boolean, AccordionChangeCallback] {
  const { register, closeOtherItems } = useAccordionContext();
  const [isOpen, setIsOpen] = useState(defaultExpanded || false);
  const ref = useRef<AccordionRegisterItem>({} as AccordionRegisterItem);
  const id = useId(itemId);

  useImperativeHandle(ref, () => ({
    isOpen,
    open: () => setIsOpen(true),
    close: () => setIsOpen(false)
  }));

  useEffect(() => {
    register(id!, ref.current);
  }, []);

  const toggle = useCallback(
    (event: SyntheticEvent, opened: boolean): void => {
      setIsOpen((prevState) => !prevState);
      closeOtherItems(id!);

      if (onChange) {
        onChange(event, opened);
      }
    },
    [id]
  );

  return [isOpen, toggle];
}

export function AccordionItem({
  id,
  className,
  containerClassName,
  titleClassName,
  detailsClassName,
  expanded,
  onChange,
  expandIcon = DEFAULT_ICON,
  iconSize = DEFAULT_ICON_SIZE,
  hideIcon = false,
  fontVariant,
  title,
  paddings = true,
  children,
  disabled,
  wrapTitle = false
}: PropsWithChildren<AccordionItemProps>) {
  const [isOpen, toggle] = useAccordionItem(id, expanded, onChange);

  return (
    <div
      id={id}
      data-testid="accordion-item-container"
      className={cn(classes.container, className)}>
      <MUIAccordion
        data-testid="accordion"
        expanded={isOpen}
        onChange={toggle}
        disabled={disabled}
        className={containerClassName}>
        <MUIAccordionSummary
          id={id}
          data-testid="accordion-item-summary"
          expandIcon={
            !hideIcon ? (
              <Icon iconName={expandIcon} width={iconSize} height={iconSize} />
            ) : null
          }
          className={cn(!wrapTitle && classes.titleNoWrap, titleClassName)}>
          <Typography variant={fontVariant} noWrap={!wrapTitle}>
            {title}
          </Typography>
        </MUIAccordionSummary>
        <MUIAccordionDetails
          id={id}
          data-testid="accordion-item-details"
          className={cn(
            Boolean(paddings) && classes.paddingDetails,
            detailsClassName
          )}>
          {children}
        </MUIAccordionDetails>
      </MUIAccordion>
    </div>
  );
}
