import { Map, List, Set, fromJS } from 'immutable';
import {
  consProductsOfStock,
  updateCountProductOfStock,
  updateUnitsProductOfStock,
  updateReservedProductOfStock,
  updateHandReservedProductOfStock,
  updateReceiptDateProductOfStock,
  updateCountProductsOfStock,
  updateReceiptDateProductsOfStock
} from './products';
import { consFoundProducts } from './foundProducts';
import getBrowserLanguage from '../../utils/languageDetector';

const newItems = () => List();
const newSelect = (data = []) => Set(data);
const newSelectedCategories = () => Set();
const newFormCreate = () =>
  Map({
    name: '',
    number: '',
    catalogId: '',
    address: ''
  });
const newMoreInfo = () =>
  Map({
    languages: List(),
    code: ''
  });
const newErrorsInfo = () => Map();

const newImport = () =>
  Map({
    stockId: '',
    status: '',
    errors: List(),
    warnings: List()
  });

const newCategories = (list) => (list ? fromJS(list) : List());
// TODO: move formCreate and to a separate store
export const initState = () =>
  Map({
    items: newItems(),
    firstUploaded: false,
    selected: newSelect(),
    formCreate: newFormCreate(),
    moreInfo: newMoreInfo(),
    errors: newErrorsInfo(),
    products: consProductsOfStock(),
    categories: newCategories(),
    firstUploadedProducts: false,
    foundProducts: consFoundProducts(),
    import: newImport(),
    unitCodes: List(),
    size: 0,
    limit: 20,
    offset: 0,
    isEnabledFilterSelected: false,
    productIds: Set(),
    totalProducts: 0,
    selectedProducts: Set(),
    selectedCategories: Set(),
    excludeAttrs: List(),
    orderBy: 'name',
    direction: 'ASC',
    filterValues: [],
    appliedFilterValues: []
  });

export const setProductIds = (data, { ids }) =>
  data.set('productIds', Set(ids));

const setImportStatus = (data, status) =>
  data.setIn(['import', 'status'], status);

export const setImportStatusError = (data, { errors, warnings, stockId }) => {
  let newData = setImportStatus(data, 'error').setIn(
    ['import', 'stockId'],
    stockId
  );
  if (errors) {
    newData = newData.setIn(['import', 'errors'], fromJS(errors));
  }
  if (warnings) {
    newData = newData.setIn(['import', 'warnings'], fromJS(warnings));
  }
  return newData;
};

export const clearImportStatusError = (data) => data.set('import', newImport());

export const setImportStatusLoading = (data, { stockId }) =>
  setImportStatus(data, 'loading').setIn(['import', 'stockId'], stockId);

export const setImportStatusSuccess = (data, { warnings }) =>
  setImportStatus(data, 'success').setIn(
    ['import', 'warnings'],
    fromJS(warnings)
  );

export const toggleFilterSelected = (data) =>
  data.set('isEnabledFilterSelected', !data.get('isEnabledFilterSelected'));
export const toggleFilterSelectedFound = (data) =>
  data.setIn(
    ['foundProducts', 'isEnabledFilterSelected'],
    !data.getIn(['foundProducts', 'isEnabledFilterSelected'])
  );

export const clearSelectFoundProducts = (data) =>
  data.setIn(['foundProducts', 'selected'], newSelect());

export const setProducts = (
  data,
  {
    products,
    allProductIds,
    search,
    size,
    totalProducts,
    limit,
    offset,
    orderBy,
    direction,
    filterValues
  }
) =>
  data
    .set('products', consProductsOfStock(products))
    .set('productIds', Set(allProductIds))
    .set('size', size)
    .set('limit', limit)
    .set('offset', offset)
    .set('search', search)
    .set('totalProducts', totalProducts)
    .set('orderBy', orderBy)
    .set('direction', direction)
    .set('firstUploadedProducts', true)
    .set('filterValues', fromJS(filterValues));

export const setCategories = (data, { categories }) =>
  data
    .set('categories', newCategories(categories))
    .set('uploadedCategories', true);

export const clearProducts = (data) =>
  data
    .set('products', consProductsOfStock())
    .set('firstUploadedProducts', false);

export const updateCountProduct = (data, { product, inStock }) =>
  data.update('products', (productsOfStock) =>
    updateCountProductOfStock(
      productsOfStock,
      product.category,
      product.sku,
      inStock
    )
  );

export const updateCountProducts = (data, { ids, inStock }) =>
  data.update('products', (productsOfStock) =>
    updateCountProductsOfStock(productsOfStock, ids, inStock)
  );

export const updateReservedProduct = (data, { product, reserved }) =>
  data.update('products', (productsOfStock) =>
    updateReservedProductOfStock(
      productsOfStock,
      product.category,
      product.sku,
      reserved
    )
  );

export const updateHandReservedProduct = (data, { product, reserved }) =>
  data.update('products', (productsOfStock) =>
    updateHandReservedProductOfStock(
      productsOfStock,
      product.category,
      product.sku,
      reserved
    )
  );

export const updateUnitsProduct = (data, { product, units }) =>
  data.update('products', (productsOfStock) =>
    updateUnitsProductOfStock(
      productsOfStock,
      product.category,
      product.sku,
      units
    )
  );

export const updateReceiptDateProduct = (data, { product, receiptDate }) =>
  data.update('products', (productsOfStock) =>
    updateReceiptDateProductOfStock(
      productsOfStock,
      product.category,
      product.sku,
      receiptDate
    )
  );

export const updateReceiptDateProducts = (data, { ids, receiptDate }) =>
  data.update('products', (productsOfStock) =>
    updateReceiptDateProductsOfStock(productsOfStock, ids, receiptDate)
  );

export const setFoundProducts = (data, ...rest) =>
  data.set(
    'foundProducts',
    consFoundProducts(data.get('foundProducts'), ...rest)
  );

export const selectFoundProducts = (data, { productId }) => {
  const product = data.getIn(['foundProducts', 'products', productId]);

  if (!product) {
    console.error('Not found product', productId);
    return data;
  }

  const isSelected = product.get('isSelected');
  const id = product.get('id');
  return data
    .setIn(['foundProducts', 'products', productId, 'isSelected'], !isSelected)
    .updateIn(['foundProducts', 'selected'], (selected) =>
      selected.includes(id) ? selected.remove(id) : selected.add(id)
    );
};

export const selectSearchProducts = (data, { ids }) =>
  data.updateIn(['foundProducts', 'selected'], (selected) =>
    selected.union(newSelect(ids))
  );
export const unselectSearchProducts = (data, { ids }) =>
  data.updateIn(['foundProducts', 'selected'], (selected) =>
    selected.subtract(newSelect(ids))
  );

const setSelectProducts = (product, select = true) =>
  product.set('isSelected', select);

export const selectAllFoundProducts = (data) => {
  const foundProducts = data.getIn(['foundProducts', 'products']);
  const selectedProducts = foundProducts.filter((product) =>
    product.get('isSelected')
  );

  if (selectedProducts.size === foundProducts.size) {
    return data.updateIn(['foundProducts', 'products'], (products) =>
      products.map((product) => setSelectProducts(product, false))
    );
  }

  return data.updateIn(['foundProducts', 'products'], (products) =>
    products.map((product) => setSelectProducts(product))
  );
};

export const setQueryFoundProduct = (data, { search }) =>
  data.setIn(['foundProducts', 'search'], search);

export const clearFoundProducts = (data) =>
  data.set('foundProducts', consFoundProducts(data.get('foundProducts')));

export const setPropsForFoundProducts = (data, { productId, props }) => {
  const updatedData = data
    .updateIn(['foundProducts', 'products', productId], (product) =>
      product.merge(props)
    )
    .updateIn(['foundProducts', 'rawProducts'], (products) =>
      products.map((product) =>
        product.id === productId ? { ...product, ...props } : product
      )
    );
  if (props.count || props.count === 0) {
    const foundProductIndex = updatedData
      .getIn(['foundProducts', 'counts'])
      .findIndex((product) => product.get('id') === productId);

    return foundProductIndex === -1
      ? updatedData.updateIn(['foundProducts', 'counts'], (products) =>
          products.push(Map({ id: productId, count: props.count }))
        )
      : updatedData.updateIn(
          ['foundProducts', 'counts', foundProductIndex],
          (product) => product.set('count', props.count)
        );
  }
  return updatedData;
};

export const setAllStocks = (data, { items }) =>
  data.remove('items').set('items', fromJS(items)).set('firstUploaded', true);

export const clearAllStocks = () => initState();

export const selectStock = (data, { id }) =>
  data.update('selected', newSelect(), (selected) =>
    selected.includes(id) ? selected.remove(id) : selected.add(id)
  );

export const deselectAllStock = (data) => data.set('selected', newSelect());

export const deselectStock = (data, { id }) =>
  data.update('selected', (items) =>
    items.filter((selectId) => selectId !== id)
  );

const getStatus = (item) => {
  const startPublish = item.get('startPublish');
  const publishDate = item.get('publishDate');

  if (startPublish && (!publishDate || startPublish > publishDate)) {
    return 'inPublication';
  }

  return !publishDate || item.get('lastUpdate') > publishDate
    ? 'draft'
    : 'publish';
};

const getComparator = (state, key) => {
  if (key === 'status') {
    return (a, b) => getStatus(a).localeCompare(getStatus(b));
  }
  return typeof (state.getIn(['items', 0, key]) === 'number')
    ? (a, b) => b.get(key) - a.get(key)
    : (a, b) =>
        (a.get(key) || '')
          .toLowerCase()
          .localeCompare((b.get(key) || '').toLowerCase()); // ^ sortedStrict;
};

export const sortStocks = (data, { key }) => {
  const wasSortedByThisKey = data.getIn(['sorted', 'key']) === key;
  return data
    .update('items', (items) =>
      wasSortedByThisKey
        ? items && items.reverse()
        : items && items.sort(getComparator(data, key))
    )
    .setIn(['sorted', 'key'], key)
    .updateIn(['sorted', 'strict'], (flag) => !wasSortedByThisKey || !flag);
};

export const deleteStock = (data, { id }) =>
  data.update('items', (items) =>
    items.filter((item) => item.get('id') !== id)
  );

export const updateField = (data, { field, value }) =>
  data.setIn(['formCreate', field], value);

export const setMoreInfo = (data, { languages, number }) =>
  data
    .setIn(['moreInfo', 'languages'], fromJS(languages))
    .setIn(['moreInfo', 'recommendCode'], number);

export const setDefaultValuesToForm = (data) => {
  const languages = data.getIn(['moreInfo', 'languages']);
  const browserLang = getBrowserLanguage();
  const foundLang = languages.find(
    (item) => item.get('code') === browserLang && item.get('publishDate')
  );
  const updateFieldLang = updateField(data, {
    field: 'catalogId',
    value: foundLang ? foundLang.get('id') : languages.first().get('id')
  });

  const recommendCode = data.getIn(['moreInfo', 'recommendCode']);

  return updateField(updateFieldLang, {
    field: 'number',
    value: recommendCode
  });
};

export const selectCategory = (data, { id }) =>
  data
    .update(
      'selectedCategories',
      newSelectedCategories(),
      (selectedCategories) =>
        selectedCategories.includes(id)
          ? selectedCategories.remove(id)
          : selectedCategories.add(id)
    )
    .remove('selected')
    .set('selected', newSelect());

export const setSelectCategory = (data, { ids }) =>
  data.set('selectedCategories', Set(ids));

export const clearSelectCategory = (data) =>
  data.set('selectedCategories', newSelectedCategories());

export const deleteCategories = (data) =>
  data.set('selectedCategories', newSelectedCategories());

export const selectProducts = (data, { id }) =>
  data
    .update('selectedProducts', newSelect(), (selectedProducts) =>
      selectedProducts.includes(id)
        ? selectedProducts.remove(id)
        : selectedProducts.add(id)
    )
    .remove('selected')
    .set('selected', newSelect());

export const selectProductList = (data, { ids }) =>
  data.update('selectedProducts', (selected) => selected.union(newSelect(ids)));
export const unselectProductList = (data, { ids }) =>
  data.update('selectedProducts', (selected) => selected.subtract(ids));

export const selectAllProducts = (state) => {
  if (state.get('selectedProducts').size === state.get('products').size) {
    return state.update('selectedProducts', (selected) => selected.clear());
  }

  const idsOfProducts = state
    .get('products')
    .map((product) => product.get('id'))
    .toList();
  return state.update('selectedProducts', () => Set(idsOfProducts));
};

export const deleteProducts = (data) =>
  data.set('selectedProducts', newSelect());

export const clearFormCreate = (data) =>
  data.remove('formCreate').set('formCreate', newFormCreate());

const clearMoreInfo = (data) => data.set('moreInfo', newMoreInfo());

export const clearForm = (data) => {
  const newData = clearFormCreate(data);
  return clearMoreInfo(newData);
};

export const setListOfErrors = (data, { errors }) => {
  const newListErrors = fromJS(errors).reduce(
    (acc, value) => acc.set(value.get('field'), value),
    newErrorsInfo()
  );
  return data.remove('errors').set('errors', newListErrors);
};

export const removeErrorByField = (data, { field }) =>
  data.deleteIn(['errors', field]);

export const setDataInForm = (data, { stockDetail }) =>
  data.remove('formCreate').set('formCreate', fromJS(stockDetail));
