import { connect } from 'react-redux';

import SingleProductMediaModal from './SingleProductMediaModal';
import * as modalActionCreators from '../../../../../action-creators/modal';
import * as mediaActionCreators from '../../../../../action-creators/product/media';
import * as productActionCreators from '../../../../../action-creators/product';
import * as messageActions from '../../../../../action-creators/message';
import * as catalogActions from '../../../../../action-creators/catalog/publish';
import * as servicesAction from '../../../../../action-creators/services';

const mapStateToProps = (state) => {
  const user = state.getIn(['user', 'user']);
  const media = state.getIn(['product', 'item', 'media', 'audio']);
  const productId = state.getIn(['product', 'item', 'id']);
  const catalogId = state.getIn(['product', 'item', 'attributes', 'catalogId']);
  const validator = state.get('validator');
  const errors = validator && validator.get('invalidFields');
  const hasError = errors && errors.has('src');
  const uploadDisabled = hasError || !(media && media.get('src'));
  return {
    user,
    productId,
    catalogId,
    media,
    errors,
    uploadDisabled,
    type: 'audio',
    titleKey: 'New audio',
    text: 'Enter URL to SoundCloud file',
    placeholderKey: 'https://soundcloud.com/example',
    tableStyle: { width: '100%', marginBottom: hasError ? 0 : '18px' },
    imageContainerStyle: { width: '70px' },
    imageStyle: { maxHeight: '34px', marginTop: 18, marginLeft: 18 }
  };
};

export default connect(mapStateToProps, {
  ...modalActionCreators,
  ...mediaActionCreators,
  getProduct: productActionCreators.getProduct,
  uploadProductMedia: mediaActionCreators.uploadProductAudio,
  setSuccessMessage: messageActions.setSuccessMessage,
  publishCatalog: catalogActions.publishCatalog,
  gaSend: servicesAction.gaSend
})(SingleProductMediaModal);
