import React from 'react';
import {
  Modal,
  Col,
  Form,
  FormGroup,
  FormControl,
  ControlLabel,
  Button,
  HelpBlock
} from 'react-bootstrap';
import { connect } from 'react-redux';
import { translate } from 'react-i18next';

import * as modalActionCreators from '../../../action-creators/modal';
import * as priceActions from '../../../action-creators/prices';
import InputNumber from '../../InputNumber';

const ModalAddPriceCategoryBeCondition = translate(['ui'], { wait: true })(
  class extends React.Component {
    constructor(props) {
      super(props);
      this.validateInteger = this._validateInteger.bind(this);
      this.inputProps = this._inputProps.bind(this);
      this.handleClick = this.handleClick.bind(this);
      this.state = { namePristine: true };
    }

    UNSAFE_componentWillMount() {
      this.props.setSelectedCategory(this.props.selectedCategory);
    }

    getProperty(field) {
      return this.props.editCategory && this.props.editCategory.has(field)
        ? this.props.editCategory.get(field)
        : '';
    }

    validationState(field) {
      const { editCategory } = this.props;
      if (field === 'name' && this.state.namePristine) {
        return '';
      }
      return editCategory && editCategory.get(field) ? '' : 'error';
    }

    getDiscountValidationState() {
      const { editCategory } = this.props;
      return editCategory &&
        editCategory.get('discount') &&
        editCategory.get('discount') >= 1 &&
        editCategory.get('discount') < 100
        ? null
        : 'error';
    }

    asterisk() {
      return <span style={{ color: 'red' }}>*</span>;
    }

    getFieldValidationState(field) {
      return !!this.getProperty(field);
    }

    showHelpInfo(field) {
      const { t } = this.props;
      if (!this.getFieldValidationState(field) && !this.state.namePristine) {
        return (
          <HelpBlock id="invalidFieldMessage" style={{ color: 'red' }}>
            {t('Required fields empty')}
          </HelpBlock>
        );
      }
      return null;
    }

    handleClick() {
      const { price, savePriceCategory, getPriceCategories, hideModalDialog } =
        this.props;

      savePriceCategory().then(() => {
        getPriceCategories(price.get('id'));
        hideModalDialog();
      });
    }

    _inputProps(field) {
      const { updatePriceCategory } = this.props;
      return {
        value: this.getProperty(field),
        onChange: (e) => updatePriceCategory(field, e.target.value)
      };
    }

    _validateInteger(e) {
      const isInteger = /[0-9]/g.test(e.key);
      if (!isInteger) {
        e.preventDefault();
      }
    }

    render() {
      const {
        t,
        captionKey,
        hideModalDialog,
        saveDisabled,
        updatePriceCategory,
        clearNewCategory
      } = this.props;

      return (
        <Modal
          id="productCharacteristicsModalWindow"
          onHide={() => {
            hideModalDialog();
            clearNewCategory();
          }}
          show>
          <Modal.Header>
            <Modal.Title>
              {t(captionKey)}
              <span
                className="modal-header-cross pull-right"
                onClick={() => {
                  hideModalDialog();
                  clearNewCategory();
                }}
              />
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p style={{ marginBottom: 20 }}>
              {t('edit_description_condition_discount')}
            </p>
            <Form horizontal>
              <FormGroup
                controlId="name"
                validationState={this.validationState('name')}>
                <Col xs={12}>
                  <span
                    className="input-col-length pull-right"
                    style={{ marginBottom: 5 }}>
                    {this.getProperty('name').length}
                    /60
                  </span>
                  <span
                    className="input-col-length pull-left"
                    style={{ marginBottom: 5 }}>
                    {t('Condition')}
                    {this.asterisk()}
                  </span>
                  <FormControl
                    type="text"
                    maxLength={60}
                    {...this.inputProps('name')}
                    placeholder={t('example_condition_discount')}
                    onKeyDown={() => {
                      if (this.state.namePristine) {
                        this.setState({ namePristine: false });
                      }
                    }}
                  />
                  {this.showHelpInfo('name')}
                </Col>
              </FormGroup>
              <FormGroup
                controlId="discount"
                validationState={this.getDiscountValidationState()}>
                <Col xs={12}>
                  {t('Discount for recalculation')}
                  <InputNumber
                    defaultValue={this.getProperty('discount')}
                    value={this.getProperty('discount')}
                    min={1}
                    max={99.9999}
                    size="l"
                    onChange={(e) => updatePriceCategory('discount', e)}
                    style={{ marginLeft: 20 }}
                  />
                </Col>
              </FormGroup>
              <FormGroup
                controlId="requiredFieldsLabel"
                style={{ marginBottom: 0, marginTop: 10 }}>
                <Col
                  componentClass={ControlLabel}
                  xs={12}
                  style={{
                    fontSize: '12px',
                    color: 'lightgrey',
                    textAlign: 'left'
                  }}>
                  {this.asterisk()}
                  {` ${t('Required fields')}`}
                </Col>
              </FormGroup>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button
              id="cancel"
              className="pull-left"
              onClick={() => hideModalDialog()}>
              {t('Cancel')}
            </Button>
            <Button
              bsStyle="primary"
              disabled={saveDisabled}
              onClick={this.handleClick}>
              {t('Save')}
            </Button>
          </Modal.Footer>
        </Modal>
      );
    }
  }
);

const mapStateToProps = (state) => {
  const selectedCategoryId = state
    .getIn(['prices', 'selectedCategories'])
    .first();
  const selectedCategory = state
    .getIn(['prices', 'priceCategories'])
    .find((item) => item.get('id') === selectedCategoryId);
  const editCategory = state.getIn(['prices', 'selectedCategory']);
  return {
    price: state.getIn(['prices', 'price']),
    priceCategories: state.getIn(['prices', 'priceCategories']),
    selectedCategory,
    editCategory,
    saveDisabled: !(
      editCategory &&
      editCategory.get('name') &&
      editCategory.get('discount') < 100 &&
      editCategory.get('discount') >= 1
    )
  };
};

export default connect(mapStateToProps, {
  ...modalActionCreators,
  ...priceActions
})(ModalAddPriceCategoryBeCondition);
