import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import Helmet from 'react-helmet';
import classNames from 'classnames';
import { useActions } from '@hooks';
import { Link } from 'react-router-dom';
import { Typography } from '@link/react-components';
import LanguageSwitcher from '../LanguageSwitcher';
import { MuteUnmuteSwitch } from '../MuteUnmuteSwitch';
import Logo from '../MessengerLogo';
import { PanelThemeDefault } from '../Panel';
import BreadcrumbsDefault from '../Breadcrumbs/BreadcrumbsDefault';
import BreadcrumbsButtons from '../BreadcrumbsButtons';
import ModalRoot from '../ModalRoot';
import Notification from '../Message';
import Disclaimer from './elements/Disclaimer';
import DemoLogoutBtn from './elements/DemoLogoutBtn';
import './MessengerOther.css';
import '../Customers/customers.styl';
import userStorage from '../../storage/user.storage';

import * as pushesUC from '../ChatWidget/modules/pushes/useCases';
import { getCurrentUser, getIsUserUploaded } from '../../storeGetters';
import PageLoader from '../Loader/PageLoader';
import MessengerNavSidebar from '../MessengerNavSidebar';
import LinkBtn from '../SidebarNavMenu/common/components/Footer/LinkBtn';
import Icon from '../SidebarNavMenu/common/components/Icon';
import { useNavPanelMode } from '../MessengerNavSidebar/hooks';
import { isFullMode, isShortMode } from '../SidebarNavMenu/utils';
import { useTranslate } from '../../TranslateProvider';

const HeaderSeparator = () => (
  <div className="layout-simple__header-separator" />
);

const LayoutSimple = ({
  userName,
  theme,
  title = 'Goodwix',
  breadcrumbs = [],
  panelBtns = [],
  sectionContent = null,
  sidebar = null,
  disclaimer,
  extraBtn,
  handleLogout,
  noShowPanel
}) => {
  const navPanelMode = useNavPanelMode();

  return (
    <>
      <Helmet defer={false} title={title} />
      <div
        className={classNames('layout-simple', noShowPanel && 'noShowPanel')}>
        <div className="layout-simple__header">
          <div className="layout-simple__header-column-left">
            <div className="layout-simple__header-logo-section">
              <Logo />
            </div>
          </div>
          {disclaimer && (
            <div className="layout-simple__header-column-item">
              {disclaimer}
            </div>
          )}
          <Typography
            className="layout-simple__header-column-right"
            variant="body1Med"
            Component="div">
            <MuteUnmuteSwitch />
            <HeaderSeparator />
            <LanguageSwitcher />
            <HeaderSeparator />
            {extraBtn}
            {extraBtn && <HeaderSeparator />}
            <Link
              className="layout-simple__header-user-name"
              to="/profile/edit">
              {userName}
            </Link>
            <LinkBtn type="button" onClick={handleLogout}>
              <Icon icon="exit" />
            </LinkBtn>
          </Typography>
        </div>
        <div
          className={classNames({
            'layout-simple__content': true,
            'layout-simple__content-full': isFullMode(navPanelMode),
            'layout-simple__content-short': isShortMode(navPanelMode)
          })}>
          <div className="layout-simple__navigation">
            <MessengerNavSidebar />
          </div>
          <div className="layout-simple__main-content">
            <div
              className={`layout-simple__panel layout-simple__panel_${theme}`}>
              <PanelThemeDefault
                theme={theme}
                left={<BreadcrumbsDefault items={breadcrumbs} theme={theme} />}
                right={<BreadcrumbsButtons items={panelBtns} theme={theme} />}
              />
            </div>
            <div className="layout-simple__section-content">
              <div
                className={`layout-simple__inner-content layout-simple__inner-content_${theme}`}>
                {sectionContent}
              </div>
              {sidebar && (
                <aside className="layout-simple__sidebar">{sidebar}</aside>
              )}
            </div>
          </div>
        </div>
      </div>
      <Notification />
      <ModalRoot />
    </>
  );
};

const MessengerOther = ({
  theme = 'gray',
  title = 'Goodwix',
  breadcrumbs,
  panelBtns,
  content,
  sidebar,
  noShowPanel = false
}) => {
  const t = useTranslate();

  const user = useSelector(getCurrentUser);
  const isUploaded = useSelector(getIsUserUploaded);

  const { setPushNotificationsMuted } = useActions(pushesUC);

  const handleLogout = useCallback(async () => {
    setPushNotificationsMuted();
    await userStorage.messengerLogout();
  }, []);

  if (!isUploaded || !user.get('id')) return <PageLoader title={title} />;

  const isDemo = user.get('type') && user.get('type') === 'demo';

  const getUserName = () => {
    if (!user || !user.get('firstName')) {
      return t('Profile');
    }

    if (user && user.get('type') === 'demo') {
      return t('Demo user');
    }

    return `${user.get('lastName')} ${user.get('firstName')}`;
  };

  return (
    <LayoutSimple
      theme={theme}
      userName={getUserName()}
      title={title}
      breadcrumbs={breadcrumbs}
      panelBtns={panelBtns}
      sectionContent={content}
      sidebar={sidebar}
      disclaimer={isDemo && <Disclaimer />}
      extraBtn={isDemo && <DemoLogoutBtn />}
      handleLogout={handleLogout}
      noShowPanel={noShowPanel}
    />
  );
};

export default MessengerOther;
