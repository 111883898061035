import React from 'react';

const FilterIcon = ({ className }) => (
  <svg
    width="14"
    height="15"
    viewBox="0 0 14 15"
    xmlns="http://www.w3.org/2000/svg">
    <path
      className={className}
      transform="translate(1 .5)"
      // eslint-disable-next-line max-len
      d="M.375.5c.276 0 .5-.224.5-.5S.65-.5.375-.5v1zM12 0h.5c0-.276-.224-.5-.5-.5V0zm0 1.556l.36.347c.09-.094.14-.218.14-.347H12zM8.25 5.444l-.36-.347c-.09.094-.14.218-.14.347h.5zm0 6.223l.23.444c.166-.085.27-.257.27-.443h-.5zM3.75 14h-.5c0 .175.09.336.24.427.15.09.335.097.49.017L3.75 14zm0-8.556h.5c0-.13-.05-.253-.14-.347l-.36.347zM0 1.556h-.5c0 .13.05.253.14.347L0 1.556zM0 0v-.5c-.276 0-.5.224-.5.5H0zm11.5 0v1.556h1V0h-1zm.14 1.208l-3.75 3.89.72.694 3.75-3.89-.72-.694zM7.75 5.444v6.223h1V5.444h-1zm.27 5.78l-4.5 2.332.46.888 4.5-2.334-.46-.887zM4.25 14V5.444h-1V14h1zm-.14-8.903L.36 1.207l-.72.696 3.75 3.89.72-.696zM.375-.5H0v1h.375v-1zM0 .5h12v-1H0v1zm.5 1.056V0h-1v1.556h1z"
    />
  </svg>
);

export default FilterIcon;
