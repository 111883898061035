import { isOneOfIstockService } from '../../../../lib/goodwix';

const isFileFromUnicatService = (url) =>
  url.slice(0, process.env.UNICAT_FILES_SERVICE.length) ===
  process.env.UNICAT_FILES_SERVICE;

const getUrlWithoutService = (url) =>
  url.slice(process.env.UNICAT_FILES_SERVICE.length);

export function convertUrl(url) {
  if (!url) return null;

  if (isOneOfIstockService() && isFileFromUnicatService(url)) {
    return `${process.env.ISTOCK_FILES_SERVICE}${getUrlWithoutService(url)}`;
  }
  return url;
}

export function convertUrlToHref(url) {
  if (!url) return null;

  if (isOneOfIstockService() && isFileFromUnicatService(url)) {
    return `${process.env.ISTOCK_FILES_SERVICE}${getUrlWithoutService(url)}`;
  }

  if (!url.startsWith('http://') && !url.startsWith('https://')) {
    return `//${url}`;
  }

  return url;
}

export const createFileUrlToIstock = (file, key = 'url') =>
  convertUrl(file[key]);

const setIstockUrl = (url) => {
  if (
    url.slice(0, process.env.UNICAT_FILES_SERVICE.length) ===
    process.env.UNICAT_FILES_SERVICE
  ) {
    return `${process.env.ISTOCK_FILES_SERVICE}${url.slice(
      process.env.UNICAT_FILES_SERVICE.length
    )}`;
  }
  return url;
};

export const convertUrlToFile = (url) =>
  isOneOfIstockService() ? setIstockUrl(url) : url;

export const convertFileUrl = (file, key = 'url') => {
  if (isFileFromUnicatService(file[key])) {
    return { ...file, [key]: createFileUrlToIstock(file, key) };
  }
  return file;
};

export const convertFileUrls = (files = [], key = 'url') => {
  if (isOneOfIstockService()) {
    return files.map((file) => convertFileUrl(file, key));
  }
  return files;
};
