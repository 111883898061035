import React from 'react';

/* eslint-disable max-len */
const MessengerWidgetOpenNewWindow = () => (
  <svg
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 2.5C0 1.11929 1.11929 0 2.5 0H7V1H2.5C1.67157 1 1 1.67157 1 2.5V11.5C1 12.3284 1.67157 13 2.5 13H11.5C12.3284 13 13 12.3284 13 11.5V7H14V11.5C14 12.8807 12.8807 14 11.5 14H2.5C1.11929 14 0 12.8807 0 11.5V2.5Z"
      fill="#767B92"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13 1H9V0H14V5H13V1Z"
      fill="#767B92"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.64645 6.64645L13.1464 0.146447L13.8536 0.853554L7.35355 7.35355L6.64645 6.64645Z"
      fill="#767B92"
    />
  </svg>
);
/* eslint-enable max-len */

export default MessengerWidgetOpenNewWindow;
