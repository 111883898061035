import { Map, Set, fromJS } from 'immutable';

const loadProductPictures = (state, items) => state.set('items', fromJS(items));

const touchProductPicture = (state, id) =>
  state.update('selected', Set(), (selected) =>
    selected.includes(id) ? selected.remove(id) : selected.add(id)
  );

const deselectProductPictures = (state) => state.remove('selected');

const picturesReducer = (state = Map(), action) => {
  switch (action.type) {
    case 'PRODUCT_PICTURES_LOAD':
      return loadProductPictures(state, action.items);
    case 'PRODUCT_PICTURES_TOUCH':
      return touchProductPicture(state, action.id);
    case 'PRODUCT_PICTURES_DESELECT':
      return deselectProductPictures(state);
    default:
      return state;
  }
};

export default picturesReducer;
