import fetch from 'isomorphic-fetch';
import { validate, clearValidatorState } from '../validator';
import { loadTable, removeFromTable } from './table';
import keyDebounce from '../utils/debounce';
import { uploadImage } from './media';

export const getCategories = (catalogId, name) => (dispatch) => {
  const url = name
    ? `/api/catalogs/${catalogId}/categories?name=${name}`
    : `/api/catalogs/${catalogId}/categories`;
  return fetch(url, { credentials: 'include' })
    .then((response) => response.json())
    .then(({ categories, size }) =>
      dispatch(loadTable(categories, 'common', size))
    );
};

export const getFilteredCategories = (catalogId) => (dispatch) =>
  fetch(`/api/catalogs/${catalogId}/filteredcategories`, {
    credentials: 'include'
  })
    .then((response) => response.json())
    .then((data) => dispatch(loadTable(data)));

export const getPublishedCategories = (catalogId) => (dispatch) =>
  fetch(`/api/catalogs/${catalogId}/publishedcategories`, {
    credentials: 'include'
  })
    .then((response) => response.json())
    .then((data) => {
      dispatch(loadTable(data));
    });

export const loadCategory = (item) => ({
  type: 'CATEGORY_LOAD',
  item
});

export const clearCategory = () => ({
  type: 'CATEGORY_CLEAR'
});

export const clearNewCategory = () => ({
  type: 'NEW_CATEGORY_CLEAR'
});

export const getCurrentCategory = (catalogId, id) => (dispatch) =>
  fetch(`/api/catalogs/${catalogId}/categories/${id}`, {
    credentials: 'include'
  })
    .then((response) => response.json())
    .then((data) => dispatch(loadCategory(data)));

export const getCategory = (catalogId, id) => (dispatch) => {
  // clear state
  dispatch(clearCategory());
  clearValidatorState(dispatch);

  fetch(`/api/catalogs/${catalogId}/categories/${id}`, {
    credentials: 'include'
  })
    .then((response) => response.json())
    .then((data) => dispatch(loadCategory(data)));
};

const patchCategoryField = (field, value, catalogId, id) => {
  const attributes = {};
  attributes[field] = value;
  return fetch(`/api/catalogs/${catalogId}/categories/${id}`, {
    credentials: 'include',
    method: 'PATCH',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ data: { attributes } })
  }).then(() => {
    console.log(
      `Successfully patched catalog:  ${catalogId}, for category: ${id}`
    );
  });
};

const debouncedCategoryPatchFetch = keyDebounce(patchCategoryField);

// VALIDATION rules
const validationRules = {
  name: 'required'
};

export const setCategoryField = (field, value) => ({
  type: 'CATEGORY_SET_FIELD',
  field,
  value
});

export const setNewCategoryField = (field, value) => ({
  type: 'NEW_CATEGORY_SET_FIELD',
  field,
  value
});

export const trimCategoryField = (field) => ({
  type: 'CATEGORY_TRIM_FIELD',
  field
});

export const updateCurrentCategoryField = (field, value) => (dispatch) => {
  dispatch(setCategoryField(field, value));
};

export const updateCategoryField = (field, value) => (dispatch, getState) => {
  dispatch(setCategoryField(field, value));

  // validate
  if (!validate(value, validationRules[field], dispatch, field)) return;

  // asynch
  debouncedCategoryPatchFetch(
    field,
    value.trim(),
    getState().getIn(['catalogCombine', 'properties', 'id']),
    getState().getIn(['category', 'item', 'id'])
  );
};

export const updateCategoryFieldNow =
  (field, value) => (dispatch, getState) => {
    dispatch(setCategoryField(field, value));
    patchCategoryField(
      field,
      value,
      getState().getIn(['catalogCombine', 'properties', 'id']),
      getState().getIn(['category', 'item', 'id'])
    );
  };

export const deleteCategory = (catalogId, categoryId) =>
  fetch(`/api/catalogs/${catalogId}/categories/${categoryId}`, {
    credentials: 'include',
    method: 'DELETE'
  });

export const deleteOneCategory = (catalogId, categoryId) => () =>
  fetch(`/api/catalogs/${catalogId}/categories/${categoryId}`, {
    credentials: 'include',
    method: 'DELETE'
  });

export const deleteSelectedCategories = (id) => (dispatch, getState) => {
  const catalogId = getState().getIn(['catalogCombine', 'properties', 'id']);
  let selected = getState().getIn(['tables', 'common', 'selected']).toArray();
  if (id) {
    selected = [id];
  }

  return Promise.all(
    selected.map((categoryId) =>
      deleteCategory(catalogId, categoryId)
        .then((res) => res.json())
        .then(({ ids }) => dispatch(removeFromTable(ids)))
        .catch((err) => console.log('ERROR', 'CATEGORY DELETE', err))
    )
  );
};

export const deleteCurrentCategory = (catalogId, categoryId, success) => () => {
  deleteCategory(catalogId, categoryId)
    .then(() => success())
    .catch((e) => {
      // TODO show error
      console.log(e);
    });
};

export const setCategoryError = (errorMessage) => ({
  type: 'CATEGORY_ERROR',
  errorMessage
});

export const createCategory =
  (id, name, desc, imgUrl, parentId) => (dispatch) => {
    dispatch({ type: 'CATEGORY:CREATE_CATEGORY:REQUEST' });
    return fetch(`/api/catalogs/${id}/categories`, {
      credentials: 'include',
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ name, desc, imgUrl, parentId })
    })
      .then((res) =>
        res.json().then((json) =>
          // eslint-disable-next-line prefer-promise-reject-errors
          res.status < 400
            ? Promise.resolve(json)
            : Promise.reject({ errors: json.errors, status: res.status })
        )
      )
      .then((json) => {
        dispatch({ type: 'CATEGORY:CREATE_CATEGORY:SUCCESS' });
        return json;
      })
      .catch((e) => {
        console.error('CREATE CATEGORY FAILED', e);
        return Promise.reject(e.errors);
      });
  };

export const updateCategory =
  (id, category, name, desc, imgUrl, parentId) => (dispatch) => {
    dispatch({ type: 'CATEGORY:UPDATE_CATEGORY:REQUEST' });
    return fetch(`/api/catalogs/${id}/categories/${category}`, {
      credentials: 'include',
      method: 'PATCH',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ name, desc, imgUrl, parentId })
    })
      .then((res) =>
        res.json().then((json) =>
          // eslint-disable-next-line prefer-promise-reject-errors
          res.status < 400
            ? Promise.resolve(json)
            : Promise.reject({ errors: json.errors, status: res.status })
        )
      )
      .then(() => {
        dispatch({ type: 'CATEGORY:UPDATE_CATEGORY:SUCCESS' });
      })
      .catch((e) => {
        console.error('UPDATE CATEGORY FAILED', e);
        return Promise.reject(e.errors);
      });
  };

export const setCategoryImage = (catalogId, categoryId, src) => (dispatch) => {
  dispatch({ type: 'CATEGORY:SET_IMG:REQUEST' });
  const attributes = { img: { src } };
  return fetch(`/api/catalogs/${catalogId}/categories/${categoryId}`, {
    credentials: 'include',
    method: 'PATCH',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ data: { attributes } })
  })
    .then((res) => res.json())
    .then(() => {
      dispatch({ type: 'CATEGORY:SET_IMG:SUCCESS' });
      return Promise.resolve();
    })
    .catch((errors) => {
      dispatch({ type: 'CATEGORY:SET_IMG:FAILURE', payload: { errors } });
      console.error(errors);
      return Promise.reject(errors);
    });
};

export const uploadCategoryImage = (file) => (dispatch, getState) => {
  uploadImage(file)
    .then(({ data: { attributes } }) => {
      dispatch(setCategoryField('img', attributes));
      return patchCategoryField(
        'img',
        attributes,
        getState().getIn(['catalogCombine', 'properties', 'id']),
        getState().getIn(['category', 'item', 'id'])
      );
    })
    .catch((e) => console.error('FAILED UPDATE CATEGORY', e));
};

export const saveSortCategories = (priceId, positions) => (dispatch) => {
  dispatch({ type: 'PRICE:SAVE_POSITIONS_CATEGORIES:REQUEST' });
  return fetch(`/api/prices/${priceId}/category-positions`, {
    credentials: 'include',
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ positions })
  })
    .then((res) => res.json())
    .then((positionsCategories) => {
      dispatch({
        type: 'PRICE:SET_POSITIONS_CATEGORIES',
        payload: { positionsCategories }
      });
      dispatch({
        type: 'PRICE:SAVE_POSITIONS_CATEGORIES:SUCCESS',
        payload: { positionsCategories }
      });
    })
    .catch((res) => {
      dispatch({ type: 'PRICE:SAVE_POSITIONS_CATEGORIES:FAILURE' });
      console.error(res);
    });
};

export const getSortedCategories = (priceId) => (dispatch) => {
  dispatch({ type: 'PRICE:GET_POSITIONS_CATEGORIES:REQUEST' });
  return fetch(`/api/prices/${priceId}/category-positions/categories`, {
    credentials: 'include',
    method: 'GET'
  })
    .then((res) => res.json())
    .then((positionsCategories) => {
      dispatch({
        type: 'PRICE:SET_POSITIONS_CATEGORIES',
        payload: { positionsCategories }
      });
      dispatch({ type: 'PRICE:GET_POSITIONS_CATEGORIES:SUCCESS' });
    })
    .catch((res) => {
      dispatch({ type: 'PRICE:GET_POSITIONS_CATEGORIES:FAILURE' });
      console.error(res);
    });
};
