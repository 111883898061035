import { useState } from 'react';
import { nanoid } from 'nanoid';
import { assoc, isNil, update } from 'ramda';
import { escapeText } from './utils';
import { noop } from '../../utils';

export const useUploadFiles = ({
  url,
  files: _files,
  setFiles: _setFiles,
  maxFiles = 5,
  onMaxFilesError = noop,
  onUploadFile,
  onDeleteFile
}) => {
  let files;
  let setFiles;
  if (_files && _setFiles) {
    files = _files;
    setFiles = _setFiles;
  } else {
    [files, setFiles] = useState([]);
  }

  const disabled = maxFiles === files.length;

  function getUrl(file) {
    return url.replace(':filename', escapeText(file.name));
  }

  const uploadFile = (file) => {
    const xhr = new XMLHttpRequest();

    const _innerId = nanoid();

    return new Promise((resolve, reject) => {
      xhr.open('POST', getUrl(file));

      xhr.onload = () => {
        if (xhr.status >= 200 && xhr.status <= 299) {
          const result = {
            _innerId,
            status: 'loaded',
            data: JSON.parse(xhr.response)
          };

          setFiles((prevState) => {
            const uploadedFileIndex = prevState.findIndex(
              (f) => f._innerId === _innerId
            );

            return update(uploadedFileIndex, result, prevState);
          });

          resolve(result);
        } else if (xhr.status >= 400) {
          console.error(`Error ${xhr.status}: ${xhr.statusText}`);
          errorHandler();
          reject();
        }
      };

      xhr.upload.onprogress = (event) => {
        // console.log(`Загружено: ${event.loaded} из ${event.total} байт`);
        const newProgress = { loaded: event.loaded, total: event.total };

        setFiles((prevState) => {
          const uploadedFileIndex = prevState.findIndex(
            (f) => f._innerId === _innerId
          );

          if (uploadedFileIndex === -1) return prevState;

          const fileInfo = prevState[uploadedFileIndex];

          const fileInfoWithProgress = assoc('progress', newProgress, fileInfo);
          return update(uploadedFileIndex, fileInfoWithProgress, prevState);
        });
      };

      xhr.upload.onloadstart = () => {
        setFiles((prevState) => [
          ...prevState,
          { _innerId, status: 'loading', data: file }
        ]);
      };

      xhr.onerror = () => {
        errorHandler();
        console.error(`Unknown error`);
        reject();
      };

      xhr.send(file);
    });

    function errorHandler() {
      const errorMessage = JSON.parse(xhr.responseText).errors[0].message;
      const uploadedFileIndex = files.findIndex((f) => f._innerId === _innerId);
      setFiles((prevState) =>
        update(
          uploadedFileIndex,
          { _innerId, status: 'error', data: file, errorMessage },
          prevState
        )
      );
    }
  };

  const uploadFiles = async (event) => {
    const newFiles = Array.from(event.target.files);
    const countFiles = files.concat(newFiles).length;

    if (countFiles > maxFiles) {
      console.error('Files limit exceeded');
      onMaxFilesError(countFiles, maxFiles);
      return;
    }

    const results = await Promise.allSettled(newFiles.map(uploadFile));

    results.forEach((r) => {
      if (r.status === 'fulfilled' && typeof onUploadFile === 'function')
        onUploadFile(r.value);
    });
  };

  const deleteFile = (_innerId) => {
    const candidate = files.find((f) => f._innerId === _innerId);

    if (isNil(candidate)) {
      console.error(`File not found`);
      throw new Error(`File not found`);
    }

    if (typeof onDeleteFile === 'function' && candidate.status === 'loaded') {
      onDeleteFile(candidate.data.id);
    }

    setFiles((prevState) => prevState.filter((f) => f._innerId !== _innerId));
  };

  return { files, disabled, uploadFiles, deleteFile };
};
