import React from 'react';
import Creatable from 'react-select/creatable';

const defaultFormatCreateLabel = (input) => input;

const INPUT_ACTIONS = {
  Change: 'input-change',
  Blur: 'input-blur'
};

const CreatableSelect = React.forwardRef(
  (
    {
      value,
      onChange,
      formatCreateLabel = defaultFormatCreateLabel,
      options: _options,
      isCreatedByBlur = false,
      ...restProps
    },
    ref
  ) => {
    const [inputValue, setInputValue] = React.useState('');
    const [options, setOptions] = React.useState(_options || []);

    const handleChange = (chosen) => {
      const label = inputValue?.trim() || '';

      if (!chosen && !label) {
        return;
      }

      const option = chosen || { label, value: label };

      const isNew = !options.find((o) => o.label === option.label);

      if (isNew) {
        setOptions((prevState) => [...prevState, option]);
      }

      onChange(option);
      setInputValue('');
    };

    const handleInputChange = (textInput, { action }) => {
      if (action === INPUT_ACTIONS.Change) {
        setInputValue(textInput);
      }

      if (action === INPUT_ACTIONS.Blur) {
        handleChange();
      }
    };

    return (
      <Creatable
        {...restProps}
        ref={ref}
        inputValue={inputValue}
        onInputChange={handleInputChange}
        value={value}
        onChange={handleChange}
        options={options}
        formatCreateLabel={formatCreateLabel}
        classNamePrefix="Select"
      />
    );
  }
);

export default CreatableSelect;
