import { assoc, assocPath, isEmpty, isNil, mergeRight, uniq } from 'ramda';
import { addUnreadMsg, clearUnreadMsg } from 'core/data/light/mainChannel';
import { getInterlocutor } from 'core/data/light/dialog';
import { nanoid } from 'nanoid';
import { isType } from 'core/lib';
import { makeCommand } from 'core/data/messageBus/message';
import { initMessage } from 'core/data/messageBus/frontend';
import { PURCHASE_REQUESTS_CHANNEL } from 'core/data/light/purchaseRequestChannel';
import { profileQRY } from 'backend/src/modules/contact/domain/messages';
import { isFuture } from 'date-fns';
import {
  isNewsFeedChannel,
  NEWS_FEED_CHANNEL
} from 'core/data/light/newsFeedChannel';
import { emitToServer, init } from '../../../storage/message';
import request from '../../../lib/request';
import * as modalActions from '../../../action-creators/modal';
import { hideModalDialog, showModal } from '../../../action-creators/modal';
import {
  clearMessages,
  setErrorMessage,
  setInfoMessage,
  setSuccessMessage
} from '../../../action-creators/message';
import { dispatcher, isMessengerServiceText } from './utils';
import { safetyServiceWorkerPostMessage } from '../utils';
import { logUseCase } from '../../../utils/logger';
import blinkMsg from '../main/chat-messages/blinkMsg';

import {
  ADD_ARCHIVES,
  ADD_CHATS,
  ADD_ERROR,
  ADD_LOADING_FILE_ID_INPUT_BLOCK,
  ADD_OR_UPDATE_LAST_UNREAD_MESSAGE,
  ADD_OR_UPDATE_MESSAGE,
  ADD_OR_UPDATE_TEMP_INPUT,
  ADD_REPLY_MSG,
  CLEAR_ERRORS,
  CLEAR_LAST_UNREAD_MESSAGE,
  CLEAR_REPLY_MSG,
  CLEAR_REPLY_MSG_BY_CHAT,
  CLEAR_SELECTED_MSGS,
  HIDE,
  HIDE_ADVANCED_SEARCH,
  HIDE_INPUT_BLOCK,
  REMOVE_INPUT_FROM_TEMP,
  REMOVE_LOADING_FILE_ID_INPUT_BLOCK,
  REMOVE_MESSAGE,
  REMOVE_UNSENT_MSG,
  SCROLL_TO_MSG,
  SCROLL_WHEN_LOAD_CHUNK,
  SELECT_MESSAGE,
  SET_ADVANCED_SEARCH,
  SET_ADVANCED_SEARCH_GLOBAL_SEARCH,
  SET_ADVANCED_SEARCH_PARAM,
  SET_ADVANCED_SEARCH_RESULT,
  SET_ARCHIVES,
  SET_CHATS,
  SET_EDIT_MSG,
  SET_ERRORS,
  SET_FAVOTIRE_MSGS,
  SET_FILES_INPUT_BLOCK,
  SET_FILTER,
  SET_GROUP_CONTACT,
  SET_GROUP_EDIT,
  SET_HEADER_VIEW,
  SET_INPUT_BLOCK,
  SET_LOADING_FILES_IDS_INPUT_BLOCK,
  SET_MESSAGES,
  SET_MESSAGES_MANY,
  SET_MESSAGES_NEXT,
  SET_MSG_TO_EDITOR,
  SET_OPEN_CHAT,
  SET_REPLY_MSGS,
  SET_SEARCH_MESSAGES,
  SET_TEMP_BLOCK,
  SET_TEXT_INPUT_BLOCK,
  SET_UNREAD_MSG_COUNT,
  SET_UNSENT_MSG,
  SHOW,
  SHOW_ADVANCED_SEARCH,
  SHOW_INPUT_BLOCK,
  UPDATE_ARCHIVE,
  UPDATE_CHAT,
  UPDATE_FAVOTIRE_MSG,
  UPDATE_LAST_MESSAGE,
  UPDATE_MESSAGE,
  UPDATE_TYPING_INFO
} from '../constants';
import {
  getAdvancedSearch,
  getBadges,
  getChatArchive,
  getChatBlock,
  getChatContact,
  getChatInput,
  getChatMode,
  getChatMsgs,
  getChatSearchMsgs,
  getChatTemp,
  getChatUnsent,
  getChatUnsentAllUsers,
  getChatWidget,
  getCurrEmplId,
  getDialogInfo,
  getEditMsg,
  getErrors,
  getGroupChats,
  getGroupContacts,
  getGroupEdit,
  getIsShown,
  getOpenedChat,
  getProfile,
  getReplyMessageId,
  getReplyMessages,
  getUserCurrCompanyId,
  maybeGetOpenedChatId
} from '../getter';
import {
  clearSelect,
  includesItem,
  isSelectedAll,
  selectAll,
  selectId,
  setList,
  unselectId
} from '../data-type/block-group';
import {
  getItem,
  includes,
  makeBlockList,
  remove,
  updateAndUnshift,
  updateItem
} from '../data-type/block-list';
import { getId } from '../data-type/showElement';
import { makeBlockInput } from '../data-type/block-input';
import {
  increateLoadCounter,
  makeQuery,
  nextPage,
  resetPage
} from '../data-type/query';
import { canLoadNext } from '../data-type/lists-kit';
import { getSelectedItem } from '../data-type/block-list-with-select';
import { initRequest } from '../data-type/request';
import * as contactActions from '../modules/contacts/actions';
import * as contactCategoriesActions from '../modules/categories/contacts/actions';
import * as dialogInfoActions from '../modules/dialogInfo/actions';
import * as profileActions from '../modules/profile/actions';
import * as chatCategoriesActions from '../modules/categories/chats/actions';
import * as forwardMessagesActions from '../modules/forwardMessages/actions';
import { prevChatMode, setChatMode } from '../modules/chatMode';

// eslint-disable-next-line import/no-cycle
import * as emailNotificationActions from '../../../action-creators/emailNotification';
import { getScktChl } from './service';
import { makeBlockEditMsg } from '../data-type/block-msg-edit';
import {
  excludeBGEId,
  excludeBGEIds,
  includeBGEIds,
  makeBlockGroupEdit,
  prepareBGEList,
  setBGEList
} from '../data-type/block-group-edit';
import { getTempInput } from '../data-type/block-temp';
import { makeBlockAdvancedSearch } from '../data-type/block-advanced-search';
import { finishLoader, startLoader } from '../../../action-creators/loaderList';
import { gaSend } from '../../../action-creators/services';
import {
  getList as getListUnsent,
  hasList as hasListUnsent
} from '../data-type/block-list-unsent';
import { setDraftMessage } from '../modules/draftMessages/useCases';
import {
  hideFavorites,
  loadMore as loadMoreFavoriteMsgs,
  toggleBookmark
} from '../modules/favoriteMessages/wrapper';
import updateChatInList from '../../../modules/chats/useCases/updateChatInList';
import { saveToLSByState } from '../../../storage/lib/LS';
import { reqUnhideContact } from '../modules/contacts/storage';
// eslint-disable-next-line import/no-cycle
import {
  filterContacts,
  getContactQueryData,
  hideContact,
  loadContactsByChunks,
  updateCountInGroupContacts,
  uploadContacts
} from '../modules/contacts';
import { addContactAsPartner } from '../../../modules/contact/storage';
// eslint-disable-next-line import/no-cycle
import { toggleMessengerCategories } from '../modules/categories/useCase';
import { getFilter, getQuery, updateQuery } from '../modules/query';
import { hideProfile, showProfile } from '../modules/profile';
import {
  updateDataInProfile,
  updateDialogInfoByEmployeeId
} from '../modules/dialogInfo';
// eslint-disable-next-line import/no-cycle
import {
  loadChatsByGroups,
  reloadChatList,
  sortChats
} from '../modules/chats/useCases';
// eslint-disable-next-line import/no-cycle
import { uploadChats } from '../modules/chats/services';
// eslint-disable-next-line import/no-cycle
import { toggleBlockModal } from '../modules/blockedContact';
import {
  clearBadge,
  getAllBadges,
  subscribeToUnreadMessageCount,
  subscribeToUpdateBadges,
  unsubscribeFromUnreadMessageCount,
  unsubscribeFromUpdateBadges
} from '../modules/badges';
import { activeChatNotification } from '../modules/sw/push/services';
import { setReadMessagesInChat } from '../modules/messages/useCases';
import {
  clearMessageDivider,
  subscribeToClearMessageDivider,
  unsubscribeFromClearMessageDivider
} from '../modules/messagesDivider';
// eslint-disable-next-line import/no-cycle
import { changeTab } from '../modules/tabs/wrapper';
import { subToupdateChannelListByCreateGroupChat } from '../modules/chats/storage';
import {
  setChats,
  updateArchiviesFilter,
  updateChatsFilter,
  updateGroupOfChats
} from '../modules/chats/actions';
import { subToupdateChannelListByCreateTopic } from '../modules/topics/storage';
import { sendForwardMessages } from '../modules/forwardMessages/useCases';
import { saveScrollData } from '../modules/scrollPosition/useCase';
import {
  getScrollPositionData,
  hasScrollPositionData
} from '../modules/scrollPosition/getters';
import { addMessageToWaiting } from '../modules/waitingMessages/useCases';
import { isChatInputLoadingFilesIdsEmpty } from '../modules/messageInput/getters';
import * as purchaseRequestsStorage from '../PurchaseRequestsFilter/storage';
import * as chatInputStorageCases from '../modules/chatInputStorage/useCases';
import * as dictionariesStorage from '../../../modules/dictionaries/storage';
import { escapeText } from '../../../utils/utils';
import { saveClientData } from '../modules/clientData';
import { isMessengerService, isWidget } from '../../../lib/goodwix';
import customHistory from '../../../customHistory';
import { getClientData } from '../modules/clientData/getters';
import * as messageInputCases from '../modules/messageInput/useCases';
import * as typingCases from '../modules/typing/useCases';
// eslint-disable-next-line import/no-cycle
import * as scheduleMsgCases from '../modules/scheduleMessages/useCases';
// eslint-disable-next-line import/no-cycle
import * as pinnedMsgsCases from '../modules/pinnedMessages/useCases';
import * as pushesCases from '../modules/pushes/useCases';
import * as delayedMessagesCases from '../modules/delayedMessages/useCases';
import * as delayedMessagesGetters from '../modules/delayedMessages/getters';
import { convertUrl } from '../modules/files/utils';
import { CREATE_NEW_TASK_MODAL } from '../../../tasks-manager/components/Modals/constants';
import { getCurrentEmployeeId } from '../../../storeGetters';

// eslint-disable-next-line import/no-cycle
export { default as resendFile } from './resendFile';

const openChatAtMsgCMD = makeCommand('CHAT', 'OPEN_CHAT_AT_MSG');
export const openChatAtMsg = initMessage(openChatAtMsgCMD);

init();

let typingTimeout = null;

const writeUnsentChatMessagesToLS = saveToLSByState(
  'unsentMessages',
  getChatUnsentAllUsers
);

export const ac = {
  showChatWidget: () => ({ type: SHOW, payload: {} }),
  hideChatWidget: () => ({ type: HIDE, payload: {} }),
  setOpenChat: (chat) => ({ type: SET_OPEN_CHAT, payload: chat }),
  scrollToMsg: (messageId, { align }) => ({
    type: SCROLL_TO_MSG,
    payload: { messageId, align }
  }),
  scrollWhenLoadChunk: (messageId) => ({
    type: SCROLL_WHEN_LOAD_CHUNK,
    payload: messageId
  }),
  setMsgToEditor: (msg) => ({ type: SET_MSG_TO_EDITOR, payload: msg }),
  changeFilter: (name, value) => ({
    type: SET_FILTER,
    payload: { name, value }
  }),
  groupBy: (value) => ({
    type: SET_GROUP_CONTACT,
    payload: { value }
  }),
  showProfile: profileActions.showProfile,
  setContacts: contactActions.setContacts,
  addContacts: contactActions.addContacts,
  updateContact: contactActions.updateContact,
  setDialogInfo: dialogInfoActions.setDialogInfo,
  setChats: (chats) => ({ type: SET_CHATS, payload: chats }),
  addChats: (chats) => ({ type: ADD_CHATS, payload: chats }),
  setMessages: (list, amountLeft, amountLeftNext) => ({
    type: SET_MESSAGES,
    payload: { list, amountLeft, amountLeftNext }
  }),
  setMessagesNext: (list, amountLeft) => ({
    type: SET_MESSAGES_NEXT,
    payload: { list, amountLeft }
  }),
  setManyMessages: ({
    list,
    amountLeftPrev,
    amountLeftNext,
    initTopItemIndex
  }) => ({
    type: SET_MESSAGES_MANY,
    payload: { list, amountLeftPrev, amountLeftNext, initTopItemIndex }
  }),
  setSearchMessages: (list, amountLeft) => ({
    type: SET_SEARCH_MESSAGES,
    payload: { list, amountLeft }
  }),
  addOrUpdateMsg: (msg) => ({ type: ADD_OR_UPDATE_MESSAGE, payload: { msg } }),
  updateMsg: (msg) => ({ type: UPDATE_MESSAGE, payload: { msg } }),
  removeMessage: (messageId) => ({
    type: REMOVE_MESSAGE,
    payload: { messageId }
  }),
  selectMessage: (messageId) => ({
    type: SELECT_MESSAGE,
    payload: { messageId }
  }),
  clearSelectedMessages: () => ({ type: CLEAR_SELECTED_MSGS }),
  addUnsentMessage: ({ chatId, employeeId, message }) => ({
    type: SET_UNSENT_MSG,
    payload: { chatId, employeeId, message }
  }),
  removeUnsentMessage: ({ chatId, employeeId, message, deliveryId }) => ({
    type: REMOVE_UNSENT_MSG,
    payload: { chatId, employeeId, message, deliveryId }
  }),
  updateChat: (chat) => ({ type: UPDATE_CHAT, payload: { chat } }),
  updateArchive: (chat) => ({ type: UPDATE_ARCHIVE, payload: { chat } }),
  setArchives: (chats) => ({ type: SET_ARCHIVES, payload: chats }),
  addArchives: (chats) => ({ type: ADD_ARCHIVES, payload: chats }),
  hideGroupContact: contactCategoriesActions.hideGroupContact,
  setGroupChats: chatCategoriesActions.setGroupChats,
  showGroupChat: chatCategoriesActions.showGroupChat,
  hideGroupChat: chatCategoriesActions.hideGroupChat,
  setInputBlock: (blockInput) => ({
    type: SET_INPUT_BLOCK,
    payload: blockInput
  }),
  setInputText: (text) => ({ type: SET_TEXT_INPUT_BLOCK, payload: { text } }),
  setInputFiles: (files) => ({
    type: SET_FILES_INPUT_BLOCK,
    payload: { files }
  }),
  setInputLoadingFilesIds: (ids) => ({
    type: SET_LOADING_FILES_IDS_INPUT_BLOCK,
    payload: { ids }
  }),
  addInputLoadingFileId: (id) => ({
    type: ADD_LOADING_FILE_ID_INPUT_BLOCK,
    payload: { id }
  }),
  removeInputLoadingFileId: (id) => ({
    type: REMOVE_LOADING_FILE_ID_INPUT_BLOCK,
    payload: { id }
  }),
  hideInputText: () => ({ type: HIDE_INPUT_BLOCK, payload: {} }),
  showInputText: () => ({ type: SHOW_INPUT_BLOCK, payload: {} }),
  setTempBlock: (blockTemp) => ({ type: SET_TEMP_BLOCK, payload: blockTemp }),
  addOrUpdateTempInput: (chatId, input) => ({
    type: ADD_OR_UPDATE_TEMP_INPUT,
    payload: { chatId, input }
  }),
  removeInputFromTemp: (chatId) => ({
    type: REMOVE_INPUT_FROM_TEMP,
    payload: { chatId }
  }),
  setFavotireMsgs: (fmsgs) => ({ type: SET_FAVOTIRE_MSGS, payload: fmsgs }),
  updateFavotireMsg: (message) => ({
    type: UPDATE_FAVOTIRE_MSG,
    payload: message
  }),
  setSidebarHeaderView: (view) => ({ type: SET_HEADER_VIEW, payload: view }),
  setEditingMessage: (data) => ({ type: SET_EDIT_MSG, payload: data }),
  setGroupChatsEdit: (blockGroups) => ({
    type: SET_GROUP_EDIT,
    payload: blockGroups
  }),
  updateUnreadMsgsCount: ({ chats, archives }) => ({
    type: SET_UNREAD_MSG_COUNT,
    payload: { chats, archives }
  }),
  updateTypingInfo: ({ id, userName, value, employeeId }) => ({
    type: UPDATE_TYPING_INFO,
    payload: { id, userName, value, employeeId }
  }),
  setAdvancedSearchBlock: (blockSearch) => ({
    type: SET_ADVANCED_SEARCH,
    payload: blockSearch
  }),
  setAdvancedSearchParam: (payload) => ({
    type: SET_ADVANCED_SEARCH_PARAM,
    payload
  }),
  setAdvancedSearchGlobalSearch: (payload) => ({
    type: SET_ADVANCED_SEARCH_GLOBAL_SEARCH,
    payload
  }),
  setAdvancedSearchResults: (results) => ({
    type: SET_ADVANCED_SEARCH_RESULT,
    payload: results
  }),
  showAdvancedSearch: () => ({ type: SHOW_ADVANCED_SEARCH, payload: {} }),
  hideAdvancedSearch: () => ({ type: HIDE_ADVANCED_SEARCH, payload: {} }),
  setErrors: (errors) => ({ type: SET_ERRORS, payload: errors }),
  addError: (error) => ({ type: ADD_ERROR, payload: error }),
  clearErrors: () => ({ type: CLEAR_ERRORS }),
  updateLastUnreadMsg: (item) => ({
    type: ADD_OR_UPDATE_LAST_UNREAD_MESSAGE,
    payload: item
  }),
  removeLastUnreadMsg: (channelId) => ({
    type: CLEAR_LAST_UNREAD_MESSAGE,
    payload: channelId
  }),
  setReplyMsgs: (messages) => ({ type: SET_REPLY_MSGS, payload: { messages } }),
  addReplyMsg: (message) => ({ type: ADD_REPLY_MSG, payload: message }),
  clearReplyMsg: (messageId) => ({ type: CLEAR_REPLY_MSG, payload: messageId }),
  clearReplyMsgByChat: (chatId) => ({
    type: CLEAR_REPLY_MSG_BY_CHAT,
    payload: chatId
  }),
  updateLastMessage: (block) => ({ type: UPDATE_LAST_MESSAGE, payload: block })
};

const req = {
  getChats: ({
    page = 0,
    limit = 20,
    search = '',
    user = [],
    mode = ''
  } = {}) =>
    request.post('/api/dialogs', {
      mode,
      user,
      system: ['inactive'],
      search,
      page,
      limit,
      settingsOff: false
    }),
  getArchiveChats: ({
    page = 0,
    limit = 20,
    search = '',
    user = [],
    mode = ''
  } = {}) =>
    request.post('/api/dialogs', {
      mode,
      system: ['archive', 'inactive'],
      user,
      search,
      page,
      limit,
      settingsOff: false
    }),
  getMessages: (chatId) => request.get(`/api/channel/${chatId}/messages`),
  getUnsentFiles: (chatId) =>
    request.get(`/api/channel/${chatId}/unsent-files`),
  getContacts: ({
    search = '',
    limit = 20,
    page = 0,
    filterBy = [],
    mode = 'all'
  } = {}) =>
    request.post('/api/contacts', {
      search,
      contactLists: filterBy,
      limit,
      page,
      mode
    }),
  getContactInfo: (employeeId) =>
    request.get(`/api/contacts/info/employeeid/${employeeId}`),
  notificationOnFromProfile: (dialogId, uniqueId) =>
    request.post(`/api/dialogs/notification_on`, { dialogId, uniqueId }),
  notificationOffFromProfile: (dialogId, uniqueId) =>
    request.post(`/api/dialogs/notification_off`, { dialogId, uniqueId }),
  notifyOn: (channelId) =>
    request.post(`/api/channel/${channelId}/notification_on`),
  notifyOff: (channelId) =>
    request.post(`/api/channel/${channelId}/notification_off`),
  archiveChat: (chatId) => request.post(`/api/channel/${chatId}/archive`),
  unarchiveChat: (chatId) => request.post(`/api/channel/${chatId}/unarchive`),
  attachUnsentFileFromStorage: (channelId, filesId) =>
    request.post(`/api/channel/${channelId}/unsent-files`, { filesId }),
  removeUnsentFile: (chatId, fileId) =>
    request.delete(`/api/channel/${chatId}/unsent-files/${fileId}`),
  attachUnsentFile: (channelId, fileName, file) =>
    request.sendFile(
      `/api/channel/${channelId}/unsent-files/${escapeText(fileName)}`,
      file
    ),
  copyFileToStorage: (chatId, fileId) =>
    request.post(`/api/channel/${chatId}/copy-file`, { fileId }),
  getTopicUnsaveInfoFiles: (topicId) =>
    request.get(`/api/topic/${topicId}/unsave-info-files`),
  attachTopicUnsaveInfoFile: (topicId, fileName, file) =>
    request.sendFile(
      `/api/topic/${topicId}/unsave-info-files/${escapeText(fileName)}`,
      file
    ),
  attachTopicUnsaveInfoFileFromStorage: (topicId, filesId) =>
    request.post(`/api/topic/${topicId}/unsave-info-files`, { filesId }),
  removeTopicUnsaveInfoFile: (topicId, fileId) =>
    request.delete(`/api/topic/${topicId}/unsave-info-files/${fileId}`),
  clearTopicUnsaveInfoFile: (topicId) =>
    request.delete(`/api/topic/${topicId}/unsave-info-files/all`)
};

const scktChl = getScktChl();
export const sckt = {
  joinToChat: (chatId) => scktChl.emit('join', { channelId: chatId }),
  subscribeToArchiveChat: (cb) => scktChl.on('archive:toArchive', cb),
  subscribeToUnarchiveChat: (cb) => scktChl.on('archive:toUnarchive', cb),
  subscribeToTypingInfo: (cb) => scktChl.on('typing', cb),
  subscribeToUpdate: (cb) => scktChl.on('update', cb),
  subscribeToNewMsg: (cb) => scktChl.on('message', cb),
  subscribeToRemoveUnsentMessage: (cb) =>
    scktChl.on('unsentMessage:remove', cb),
  subToAddUnreadMsg: (cb) => scktChl.on('unreadMessagesId:add', cb),
  subToClearUnreadMsg: (cb) => scktChl.on('unreadMessagesId:clear', cb),
  subscribeToUpdateMsg: (cb) => scktChl.on('message:update', cb),
  subscribeToRemoveMsg: (cb) => scktChl.on('message:remove', cb),
  subscribeToUpdateInList: (cb) =>
    scktChl.on('update-channel-in-dialog-list', cb),
  subscribeToUpdateInListWithoutSort: (cb) =>
    scktChl.on('update-channel-in-dialog-list:without-sort', cb),
  subscribeToReloadChatList: (cb) => scktChl.on('reload-channel-list', cb),
  subscribeToUpdateLstMsg: (cb) => scktChl.on('lastMsg', cb),
  subscribeToNeedUpdate: (cb) => scktChl.on('need-to-update', cb),
  unsubscribeToTypingInfo: () => scktChl.removeListener('typing'),
  unsubscribeFromNewMsg: () => scktChl.removeListener('message'),
  unsubscribeToUpdateChannel: () => scktChl.removeListener('update'),
  unsubToAddUnreadMsg: () => scktChl.removeListener('unreadMessagesId:add'),
  unsubToClearUnreadMsg: () => scktChl.removeListener('unreadMessagesId:clear'),
  unsubscribeToRemoveMsg: () => scktChl.removeListener('message:remove'),
  unsubscribeFromUpdateMsg: () => scktChl.removeListener('message:update'),
  leaveFromChannel: (chatId) => scktChl.emit('leave', { channelId: chatId }),
  quitFromChannel: (channelId) => scktChl.emit('quit', { channelId }),
  unsubscribeToUpdateInList: () =>
    scktChl.removeListener('update-channel-in-dialog-list'),
  unsubscribeFromUnreadMessageCount: () =>
    scktChl.removeListener('updateUnreadMsgsCount'),
  unsubscribeToUpdateInListWithoutSort: () =>
    scktChl.removeListener('update-channel-in-dialog-list:without-sort'),
  unsubscribeToReloadChatList: () => scktChl.removeListener('reload-chat-list'),
  unsubscribeFromUpdateLstMsg: () => scktChl.removeListener('lastMsg'),
  sendMsg: ({
    channelId,
    msg,
    html,
    files,
    mentions,
    forwardedMessages,
    replyByMessageId,
    deliveryId
  }) =>
    scktChl.emit('message', {
      channelId,
      msg,
      html,
      files,
      mentions,
      forwardedMessages,
      replyByMessageId,
      deliveryId
    }),
  updateMsg: ({
    chatId,
    messageId,
    html,
    msg,
    mentions,
    removedFiles,
    deliveryId
  }) =>
    scktChl.emit('message:update', {
      channelId: chatId,
      messageId,
      html,
      msg,
      mentions,
      removedFiles,
      deliveryId
    }),
  updateChannel: (channelId, config) =>
    scktChl.emit('update', { channelId, config }),
  restoreAccessToChat: (channelId) => scktChl.emit('restore', { channelId }),
  closeChat: (channelId, cb = () => {}) =>
    scktChl.emit('close:toggle', { channelId }, cb),
  removeFileFromMsg: (channelId, messageId, fileId) =>
    scktChl.emit('file:remove', { channelId, messageId, fileId }),
  removeMessage: (channelId, messageId, mainMessageId) =>
    scktChl.emit('message:remove', { channelId, messageId, mainMessageId }),
  getUnreadMsgsCount: (employeeId) =>
    scktChl.emit('getUnreadMsgsCount', employeeId),
  emitTyping: (channelId, value) =>
    scktChl.emit('typing', { channelId, value }),
  pinChat: (chatId) => scktChl.emit('pin-chat', { chatId }),
  unpinChat: (chatId) => scktChl.emit('unpin-chat', { chatId }),
  subscribeToUpdateLastUnreadMessages: (cb) =>
    scktChl.on('lastUnreadMessage', cb),
  deleteMessageFromBuffer: (messageId) => {
    const buffer = scktChl.sendBuffer;
    const filteredBuffer = buffer.filter((event) => {
      const { data } = event;
      const [eventType = '', payload = {}] = data;
      return !(eventType === 'message' && payload.deliveryId === messageId);
    });
    scktChl.sendBuffer = filteredBuffer;
  }
};

export const joinChat = (chatId) => sckt.joinToChat(chatId);

const setFocusOnInputMessage = () => {
  $('#message-input').click();
};

const fromChatTab = (chat, state) => includes(getChatBlock(state), chat.id);
const fromChatTabById = (id, state) => includes(getChatBlock(state), id);

const updateDialogInfo = () => async (dispatch, getState) => {
  const { employeeId } = getDialogInfo(getState());
  if (employeeId) {
    const info = await req.getContactInfo(employeeId);
    dispatch(ac.setDialogInfo(info));
  }
};

export const toggleChatWidget = () => (dispatch, getState) => {
  const isShownCW = getIsShown(getState());
  if (isShownCW) {
    dispatch(unsubscribeFromUpdateBadges());
    dispatch(ac.hideChatWidget());
  } else {
    dispatch(updateDialogInfo());
    dispatch(getAllBadges());
    dispatch(subscribeToUpdateBadges());
    dispatch(ac.showChatWidget());
  }
};

export const hideChatWidget = () => (dispatch, getState) => {
  const isShownCW = getIsShown(getState());
  if (isShownCW) {
    dispatch(toggleChatWidget());
  }
};

export const showChatWidget = () => (dispatch, getState) => {
  const isShownCW = getIsShown(getState());
  if (!isShownCW) {
    dispatch(toggleChatWidget());
  }
};

const addMsgToUnread = (chatId, messageId) => (dispatch, getState) => {
  const state = getState();
  const openedChat = getOpenedChat(state);
  // This fix broken read message after send (set read)
  // if (!isNil(openedChat) && openedChat.id === chatId) {
  //   return;
  // }
  const chatList = getChatBlock(state);
  const chat = getItem(chatList, chatId);
  const archivesList = getChatArchive(state);
  const archive = getItem(archivesList, chatId);

  if (chat) {
    const updatedChat = addUnreadMsg(chat, messageId);
    dispatch(ac.updateChat(updatedChat));
  } else if (archive) {
    const updatedArhive = addUnreadMsg(archive, messageId);
    const updateArchives = updateItem(archivesList, updatedArhive);
    dispatch(ac.setArchives(updateArchives));
  } else if (openedChat) {
    const updatedChat = addUnreadMsg(openedChat, messageId);
    dispatch(ac.setOpenChat(updatedChat));
  }
};

const uploadFiles = (chat) => async (dispatch) => {
  const files = await req.getUnsentFiles(chat.id);
  dispatch(ac.setInputFiles(files));
};

export const uploadMessages = (chat) => async (dispatch, getState, service) => {
  const { findChunkPrev } = service.chatMessage;
  dispatch(ac.setMessages([], 0, 0));

  async function calcLimit() {
    const defaultLimit = 40;
    const maxLimit = 100;
    const threshold = 3;

    let result = defaultLimit;

    const { badges } = getBadges(getState());
    const unreadCount =
      badges.find((badge) => badge.chatId === chat.id)?.count ?? 0;

    result =
      unreadCount > defaultLimit ? unreadCount + threshold : defaultLimit;
    if (result > maxLimit) {
      result = defaultLimit;
    }

    return result;
  }

  const limit = await calcLimit();

  const chunk = await findChunkPrev(chat.id, null, limit);

  const NOT_LOAD_FILES = [PURCHASE_REQUESTS_CHANNEL, NEWS_FEED_CHANNEL];

  if (!NOT_LOAD_FILES.includes(chat.type)) dispatch(uploadFiles(chat));

  dispatch(ac.setMessages(chunk.list, chunk.amountLeft, 0));
};

export const getChatGroups = () => async (dispatch, getState, service) => {
  const { getGroups } = service.chat;
  const groups = await getGroups();
  const groupChat = getGroupChats(getState());
  const blockGroups = setList(groupChat, groups.user.all);
  dispatch(ac.setGroupChats(blockGroups));
};

export const subscribeToUpdateChat = (chatId) => (dispatch, getState) => {
  sckt.joinToChat(chatId);
  sckt.subscribeToUpdate((chat) => dispatch(ac.updateChat(chat)));
  sckt.subscribeToNewMsg(({ message, channelId, unread, deliveryId }) => {
    const state = getState();
    const employeeId = getCurrEmplId(state);
    const msgs = getChatMsgs(state);

    dispatch(
      ac.removeUnsentMessage({
        chatId: channelId,
        employeeId,
        message,
        deliveryId
      })
    );
    dispatch(writeUnsentChatMessagesToLS());

    if (unread) {
      dispatch(addMsgToUnread(channelId, message.id));
    }

    // TODO: Временный фикс кейса, когда на сервере осталась подписка на один из посещенных ранее каналов
    //  и теперь сообщения из тех каналов могут попадать в текущий https://jira.istock.info/browse/UN-8306
    const openedChatId = maybeGetOpenedChatId(state);

    if (!canLoadNext(msgs) && openedChatId === channelId) {
      dispatch(ac.addOrUpdateMsg(message));
    }
  });
  sckt.subToAddUnreadMsg(({ channelId, messageId }) =>
    dispatch(addMsgToUnread(channelId, messageId))
  );
  sckt.subToClearUnreadMsg(({ channelId: id }) => {
    const state = getState();
    const chatList = getChatBlock(state);
    const chat = getItem(chatList, id);
    const archivesList = getChatArchive(state);
    const archive = getItem(archivesList, id);
    if (chat) {
      const updatedChat = clearUnreadMsg(chat);
      dispatch(ac.updateChat({ ...updatedChat, haveNewMessages: false }));
    } else if (archive) {
      const updatedArhive = clearUnreadMsg(archive);
      const updateArchives = updateItem(archivesList, updatedArhive);
      dispatch(ac.setArchives(updateArchives));
    }
  });
  sckt.subscribeToUpdateMsg(({ channelId, message }) => {
    const employeeId = getCurrEmplId(getState());

    dispatch(ac.updateMsg(message));
    dispatch(
      ac.removeUnsentMessage({
        chatId: channelId,
        employeeId,
        message,
        deliveryId: message.deliveryId
      })
    );
    dispatch(writeUnsentChatMessagesToLS());
    const mode = getChatMode(getState());
    if (mode.currentMode === 'favorites') {
      dispatch(ac.updateFavotireMsg(message));
    }
  });
  sckt.subscribeToRemoveMsg(({ messageId }) =>
    dispatch(ac.removeMessage(messageId))
  );
  sckt.subscribeToNeedUpdate(({ channelId, type }) => {
    switch (type) {
      case 'messages':
        dispatch(uploadMessages({ id: channelId }));
        break;
      case 'dialogsListCounts':
        dispatch(getChatGroups());
        break;
      default:
        break;
    }
  });
  sckt.subscribeToRemoveUnsentMessage(({ channelId, deliveryId }) => {
    const employeeId = getCurrEmplId(getState());

    dispatch(
      ac.removeUnsentMessage({
        chatId: channelId,
        employeeId,
        deliveryId
      })
    );
    dispatch(writeUnsentChatMessagesToLS());
  });
};

export const unsubscribeToUpdateChat = (chatId) => (dispatch) => {
  sckt.unsubscribeFromNewMsg();
  sckt.unsubscribeToUpdateChannel();
  sckt.unsubToAddUnreadMsg();
  sckt.unsubToClearUnreadMsg();
  sckt.unsubscribeToRemoveMsg();
  sckt.unsubscribeFromUpdateMsg();
  sckt.leaveFromChannel(chatId);
  dispatch(ac.removeLastUnreadMsg(chatId));
};

const loadPrevChunkMsgs = (chat) => async (dispatch, getState, service) => {
  const { findChunkPrev } = service.chatMessage;
  const blockMsgs = getChatMsgs(getState());
  if (blockMsgs.list.length > 0) {
    const firstMsg = blockMsgs.list[0];
    const prevChunk = await findChunkPrev(chat.id, firstMsg.time);
    const messages = prevChunk.list.concat(blockMsgs.list);
    dispatch(ac.setMessages(messages, prevChunk.amountLeft));
    dispatch(ac.scrollWhenLoadChunk(firstMsg.id));
  }
};

const loadNextChunkMsgs = (chat) => async (dispatch, getState, service) => {
  const { findChunkNext } = service.chatMessage;
  const blockMsgs = getChatMsgs(getState());
  if (blockMsgs.list.length > 0) {
    const lastMsg = blockMsgs.list[blockMsgs.list.length - 1];
    const nextChunk = await findChunkNext(chat.id, lastMsg.time);
    const messages = blockMsgs.list.concat(nextChunk.list);
    dispatch(ac.setMessagesNext(messages, nextChunk.amountLeft));
  }
};

export const jumpToMsg =
  (message, { align = 'center', showBlink = true } = {}) =>
  async (dispatch, getState, service) => {
    const { findChunkPrev, findChunkNext } = service.chatMessage;
    const blockMessages = getChatMsgs(getState());
    const index = blockMessages.list.findIndex((msg) => msg.id === message.id);
    if (index === -1) {
      const date = message.time;
      const amountPrevMsg = 20;
      const amountNextMsg = 19;
      const [prev, next] = await Promise.all([
        findChunkPrev(message.channelId, date, amountPrevMsg),
        findChunkNext(message.channelId, date, amountNextMsg)
      ]);

      const list = prev.list.concat([message], next.list);
      const indexOfMsg = list.findIndex((msg) => msg.id === message.id);
      dispatch(
        ac.setManyMessages({
          list,
          amountLeftNext: next.amountLeft,
          amountLeftPrev: prev.amountLeft,
          initTopItemIndex: indexOfMsg
        })
      );

      setTimeout(() => dispatch(ac.scrollToMsg(message.id, { align })), 500); // time to render msgs
      if (showBlink) blinkMsg(message.id);
    } else {
      dispatch(ac.scrollToMsg(message.id, { align }));
      if (showBlink) blinkMsg(message.id);
    }
  };

const openChatAtMessage = (message) => async (dispatch) => {
  await dispatch(openChannelById(message.channelId));
  dispatch(jumpToMsg(message));
};

export const showChat = (chat) => async (dispatch, getState) => {
  const addToTempInput = (chatId) => {
    const blockInput = getChatInput(getState());
    if (blockInput.text) {
      dispatch(
        ac.addOrUpdateTempInput(chatId, { id: chatId, text: blockInput.text })
      );
      dispatch(ac.setInputText(''));
    }
  };

  const setInput = () => {
    const blockTemp = getChatTemp(getState());
    const temp = getTempInput(blockTemp, chat.id);

    if (!isNil(temp)) {
      dispatch(ac.setInputText(temp.text));
      dispatch(ac.removeInputFromTemp(chat.id));
    }
  };

  const openedChat = getOpenedChat(getState());

  if (!isNil(openedChat) && openedChat.id !== chat.id) {
    dispatch(saveScrollData(openedChat.id));
    dispatch(ac.setMessages([], 0));
    unsubscribeToUpdateChat(openedChat.id, dispatch)(dispatch);
    addToTempInput(openedChat.id);
  }

  setInput();
  dispatch(ac.clearSelectedMessages());
  dispatch(ac.setOpenChat(chat));
  await dispatch(uploadMessages(chat));
  subscribeToUpdateChat(chat.id)(dispatch, getState);
  dispatch(setChatMode('chat'));
  dispatch(
    saveClientData(chat.id, { isActiveTab: true, isScrollBottom: true })
  );

  if (isType(PURCHASE_REQUESTS_CHANNEL, chat))
    await dispatch(dictionariesStorage.getPurchaseRequestsCategories());

  if (hasScrollPositionData(getState())) {
    const scrollData = getScrollPositionData(getState(), chat.id);

    await dispatch(
      jumpToMsg(scrollData.message, { align: 'start', showBlink: false })
    );
  }

  if (chat.type === 'Dialog') {
    const employeeId = getState().getIn(['user', 'user', 'employeeId']);
    const interlocutor = getInterlocutor(employeeId, chat);
    const info = await req.getContactInfo(interlocutor.employeeId);
    dispatch(ac.setDialogInfo(info));
  }
};

const transformToChatModels = ({ dialogs: list, total: size }) =>
  makeBlockList({ list, size });

const storageOversizeHandler = () => (dispatch, getState) => {
  const billingEditPermission = getState().getIn([
    'user',
    'user',
    'permissions',
    'billing',
    'update'
  ]);
  const openBrowserTab = (url) =>
    Object.assign(document.createElement('a'), {
      target: '_blank',
      href: url
    }).click();
  const onSubmit = (type) => {
    if (type === 'increase') {
      openBrowserTab('/billing/info');
      return;
    }
    if (type === 'clear') {
      openBrowserTab('/file-storage/chat');
    }
  };

  if (billingEditPermission) {
    dispatch(showModal('CW_STORAGE_OVERSIZE', { onSubmit }));
  } else {
    openBrowserTab('/file-storage/chat');
  }
};

const getFilterMode = (name) => {
  const values = {
    'all-chats': 'all',
    dialogs: 'dialog',
    channels: 'channel',
    topics: 'topic'
  };
  return values[name] || name;
};

export const viewTopic = (chat) => (dispatch) => {
  dispatch(
    showModal('CW_GROUP_CHAT_EDIT', {
      chat,
      mode: 'Topic',
      readOnly: true,
      btnTextConfirm: 'Close'
    })
  );
};

const reqGetChats = initRequest(
  (state) => ({
    query: getQuery('chats', state),
    blockGroup: getGroupChats(state),
    filter: getFilter('chat', state)
  }),
  (params) => ({
    search: params.query.search,
    page: params.query.page,
    limit: params.query.limit,
    user: params.blockGroup.selectIds,
    orderBy: params.query.orderBy,
    direction: params.query.direction,
    mode: getFilterMode(params.filter.value)
  }),
  req.getChats
);

const chunkUploadChats =
  (newParams = {}, withQueryUpdate = true) =>
  async (dispatch, getState) => {
    const chats = await reqGetChats(getState(), newParams);
    dispatch(ac.addChats(transformToChatModels(chats)));

    if (withQueryUpdate) {
      const query = newParams.query || getQuery('chats', getState());
      const increaseCounterQuery = increateLoadCounter(query);
      dispatch(updateQuery('chats', increaseCounterQuery));
    }
  };

const reqGetArchives = initRequest(
  (state) => ({
    query: getQuery('archives', state),
    blockGroup: getGroupChats(state),
    filter: getFilter('archive', state)
  }),
  (params) => ({
    search: params.query.search,
    page: params.query.page,
    limit: params.query.limit,
    user: params.blockGroup.selectIds,
    orderBy: params.query.orderBy,
    direction: params.query.direction,
    mode: getFilterMode(params.filter.value)
  }),
  req.getArchiveChats
);

const uploadArchives =
  (newParams = {}) =>
  async (dispatch, getState) => {
    const archives = await reqGetArchives(getState(), newParams);
    dispatch(ac.setArchives(transformToChatModels(archives)));
    dispatch(getChatGroups());

    const query = newParams.query || getQuery('archives', getState());
    const resetedPages = resetPage(query);
    const increaseCounterQuery = increateLoadCounter(resetedPages);
    dispatch(updateQuery('archives', increaseCounterQuery));
  };

const chunkUploadArhives =
  (newParams = {}) =>
  async (dispatch, getState) => {
    const archives = await reqGetArchives(getState(), newParams);
    dispatch(ac.addArchives(transformToChatModels(archives)));

    const query = newParams.query || getQuery('archives', getState());
    const increaseCounterQuery = increateLoadCounter(query);
    dispatch(updateQuery('archives', increaseCounterQuery));
  };

export const openDialogByEmployeeId =
  (employeeId) => async (dispatch, getState, service) => {
    const { getDialogIdByEmployeeId, findChannel } = service.chat;
    const dialogId = await getDialogIdByEmployeeId(employeeId);
    const dialog = await findChannel(dialogId);

    if (!isWidget(customHistory.location.pathname)) {
      customHistory.push(`/chat/${dialogId}`);
    }

    dispatch(showChat(dialog));
    setTimeout(() => {
      dispatch(clearBadge(dialogId));
    }, 0);
  };

export function openChannelById(channelId) {
  return async (dispatch, getState, service) => {
    const { findChannel } = service.chat;
    const chat = await findChannel(channelId);
    if (!isChatWithSameIdAlreadyOpened()) {
      dispatch(showChatWidget());
      dispatch(showChat(chat));
    }
    setTimeout(() => {
      dispatch(clearBadge(channelId));
    }, 0);

    return undefined;

    function isChatWithSameIdAlreadyOpened() {
      const openedChat = getOpenedChat(getState());
      return openedChat && openedChat.id === channelId;
    }
  };
}

export function setMsgToChannel(channelId, msg) {
  return async (dispatch, getState, service) => {
    const { findChannel } = service.chat;
    const chat = await findChannel(channelId);

    if (!isChatWithSameIdAlreadyOpened()) {
      dispatch(showChatWidget());
      await dispatch(showChat(chat));
      dispatch(ac.setMsgToEditor(msg));

      setTimeout(() => {
        dispatch(clearBadge(channelId));
      }, 0);
    } else {
      dispatch(ac.setMsgToEditor(msg));
    }

    function isChatWithSameIdAlreadyOpened() {
      const openedChat = getOpenedChat(getState());
      return openedChat && openedChat.id === channelId;
    }
  };
}

export const toggleNotify = (chat) => async (dispatch, getState) => {
  const state = getState();
  const isNotifyOn = !chat.notificationOff;
  const updatedChat = { ...chat, notificationOff: !chat.notificationOff };

  if (isNotifyOn) {
    await req.notifyOff(chat.id);
  } else {
    await req.notifyOn(chat.id);
  }

  if (fromChatTab(chat, state)) {
    const chatList = getChatBlock(state);
    dispatch(ac.setChats(updateItem(chatList, updatedChat)));
  } else {
    // from archive tab
    const archives = getChatArchive(state);
    dispatch(ac.setArchives(updateItem(archives, updatedChat)));
  }
};

const updateOpenedChat = (updatedChat) => (dispatch, getState) => {
  const openedChat = getOpenedChat(getState());

  if (getId(openedChat) === updatedChat.id) {
    showChat(updatedChat)(dispatch, getState);
  }
};

const updateListFromSocketByCreatingChat = (chat) => (dispatch, getState) => {
  const chatList = getChatBlock(getState());
  const updateChatList = updateAndUnshift(chatList, chat);
  dispatch(setChats(updateChatList));
};

const archiveChatCase =
  ({ chat }) =>
  (dispatch, getState) => {
    const updatedChat = { ...chat, archived: true };

    const removeChatFromChatList = () => {
      const chatList = getChatBlock(getState());
      const updatedChatList = remove(chatList, chat.id);
      dispatch(ac.setChats(updatedChatList));
    };

    const addChatToArchives = () => {
      const archivesList = getChatArchive(getState());
      const updatedArchivesList = updateAndUnshift(archivesList, updatedChat);
      dispatch(ac.setArchives(updatedArchivesList));
    };

    const prependToArchives = () => {
      dispatch(updateOpenedChat(updatedChat));
    };

    removeChatFromChatList();
    prependToArchives();
    addChatToArchives();
  };

const unArchiveChatCase =
  ({ chat }) =>
  (dispatch, getState) => {
    const updatedChat = { ...chat, archived: false };

    const removeArchiveFromArchiveList = () => {
      const archives = getChatArchive(getState());
      const updatedArchiveList = remove(archives, chat.id);
      dispatch(ac.setArchives(updatedArchiveList));
    };

    const addChatToChatList = () => {
      const chatList = getChatBlock(getState());
      const updatedChatList = updateAndUnshift(chatList, updatedChat);
      dispatch(ac.setChats(updatedChatList));
    };

    const prependToChats = () => {
      dispatch(updateOpenedChat(updatedChat));
    };

    removeArchiveFromArchiveList(); // delete from list archive
    prependToChats(); // put to first chat in list of chats
    addChatToChatList();
  };

export const toggleArchive = (chat) => async (dispatch, getState) => {
  const employeeId = getCurrentEmployeeId(getState());

  const showNotifyArhived = (isArchived) => {
    const status = isArchived ? 'archived' : 'unarchived';
    dispatch(
      setSuccessMessage({ key: `${chat.type.toLowerCase()}_${status}` })
    );
  };

  const unarchive = async () => {
    const confirm = (submitAction) => {
      if (
        chat.type === 'Topic' ||
        chat.type === 'Channel' ||
        isNewsFeedChannel(chat)
      ) {
        dispatch(
          showModal('SIMPLE_SUBMIT', {
            captionKey: `modal_unarchive_${chat.type.toLowerCase()}_title`,
            text: {
              tkey: `modal_unarchive_${chat.type.toLowerCase()}_text`,
              options: { chatName: chat.name }
            },
            textBtnConfirm: 'move',
            submitAction
          })
        );
      } else if (chat.type === 'Dialog') {
        const interlocutor = getInterlocutor(employeeId, chat);

        dispatch(
          showModal('SIMPLE_SUBMIT', {
            captionKey: 'modal_unarchive_title',
            text: {
              tkey: 'modal_unarchive_text',
              options: { userName: interlocutor.userName }
            },
            textBtnConfirm: 'move',
            submitAction
          })
        );
      }
    };

    confirm(async () => {
      await req.unarchiveChat(chat.id); // call api
      showNotifyArhived(false);
      sckt.getUnreadMsgsCount(employeeId);
    });
  };

  const archive = async () => {
    const confirm = (submitAction) => {
      if (
        chat.type === 'Topic' ||
        chat.type === 'Channel' ||
        isNewsFeedChannel(chat)
      ) {
        dispatch(
          showModal('SIMPLE_SUBMIT', {
            captionKey: `modal_archive_${chat.type.toLowerCase()}_title`,
            text: {
              tkey: `modal_archive_${chat.type.toLowerCase()}_text`,
              options: { chatName: chat.name }
            },
            textBtnConfirm: 'move',
            submitAction
          })
        );
      } else if (chat.type === 'Dialog') {
        const interlocutor = getInterlocutor(employeeId, chat);

        dispatch(
          showModal('SIMPLE_SUBMIT', {
            captionKey: 'modal_archive_title',
            text: {
              tkey: 'modal_archive_text',
              options: { userName: interlocutor.userName }
            },
            textBtnConfirm: 'move',
            submitAction
          })
        );
      }
    };

    confirm(async () => {
      console.log('call archive');
      await req.archiveChat(chat.id); // call api
      showNotifyArhived(true);
      sckt.getUnreadMsgsCount(employeeId);
    });
  };

  if (chat.archived) {
    await unarchive();
  } else {
    await archive();
  }
};

// const showModalEditContactLists = chat => async (dispatch, getState) => {
//   const meId = getCurrEmplId(getState());
//   const { employeeId } = getInterlocutor(meId, chat);
//   dispatch(showModal('CW_EDIT_USER_CONTACT_LISTS', { contactId: employeeId }));
// };

const showModalEditChatLists =
  (chat) => async (dispatch, getState, service) => {
    const { getGroups } = service.chat;
    const groups = await getGroups();
    dispatch(
      showModal('CW_EDIT_DIALOG_DIALOG_LISTS', {
        chatId: chat.id,
        lists: groups.user.all
      })
    );
  };

export const showEditChatIncludesGroups = (chat) => async (dispatch) => {
  dispatch(showModalEditChatLists(chat));
};

export const showChooseHistoryDownload =
  (chatId, minDate) => async (dispatch) => {
    dispatch(showModal('CW_CHOOSE_HISTORY_DOWNLOAD', { chatId, minDate }));
  };

const setMemberStatusInArchive =
  (chat, memberStatus) => (dispatch, getState) => {
    if (chat.archived) {
      const state = getState();
      const meId = getCurrEmplId(state);
      const archives = getChatArchive(state);

      const updatedChat = {
        ...chat,
        members: chat.members.map((member) =>
          member.employeeId === meId ? { ...member, memberStatus } : member
        )
      };

      const updateArchives = updateItem(archives, updatedChat);
      dispatch(ac.setArchives(updateArchives));
    }
  };

const toggleCloseInArchive = (chat) => (dispatch, getState) => {
  if (chat.archived) {
    const archives = getChatArchive(getState());
    const updateArchives = updateItem(archives, {
      ...chat,
      isClosed: !chat.isClosed
    });

    dispatch(ac.setArchives(updateArchives));
  }
};

export const leave = (type, chat) => (dispatch, getState) =>
  dispatch(
    showModal('CONFIRM_SUBMIT', {
      title: `${type}.leave-from-channel`,
      text: `${type}.confirm-leave`,
      confirmBtnText: 'Confirm',
      submit: async () => {
        const state = getState();
        const openedChat = getOpenedChat(state);
        if (!openedChat || openedChat.id !== chat.id) {
          await dispatch(showChat(chat));
        }
        dispatch(setMemberStatusInArchive(chat, 'LEAVE'));
        sckt.quitFromChannel(chat.id);
      }
    })
  );

export const closeTopic = (chat) => (dispatch, getState) => {
  const state = getState();
  const openedChat = getOpenedChat(state);
  if (!openedChat || openedChat.id !== chat.id) {
    dispatch(showChat(chat));
  }
  dispatch(toggleCloseInArchive(chat));
  sckt.closeChat(chat.id);
};

export const showProfileWidget = (employeeId) => {
  window.open(
    `${process.env.UNICAT_MESSENGER_SERVICE}/chat/?profile=${employeeId}`,
    '_blank'
  );
};

const createTopicWidget = () => {
  window.open(
    `${process.env.UNICAT_MESSENGER_SERVICE}/chat/?chatMode=topic-create`,
    '_blank'
  );
};

export const updateDateAfterToggleBlock =
  (employeeId, isBlockedContact) => async (dispatch, getState, service) => {
    // Func to upd contact in result search after toggle block
    const updateSearch = async () => {
      const state = getState();
      const search = getAdvancedSearch(state);
      const { params, hidden } = search;

      const isSearched = Object.keys(params).some(
        (paramKey) => params[paramKey]
      );

      if (!hidden && isSearched) {
        const { search: searchReq } = service.chat;
        const query = { ...search.params };
        const results = await searchReq(query);
        dispatch(ac.setAdvancedSearchResults(results));
      }
    };

    const state = getState();
    const profile = getProfile(state);
    const dialogInfo = getDialogInfo(state);
    const contact = getChatContact(state).list.find(
      (user) => user.employeeId === employeeId
    );

    dispatch(ac.updateContact({ ...contact, isBlockedContact }));
    if (dialogInfo && dialogInfo.employeeId === employeeId) {
      dispatch(ac.setDialogInfo({ ...dialogInfo, isBlockedContact }));
    }
    if (profile) {
      dispatch(ac.showProfile({ ...profile, isBlockedContact }));
    }
    await updateSearch();
  };

export const handleTopicDescription =
  (event, chat) => async (dispatch, getState) => {
    if (event === 'edit') {
      const userEmail = getState().getIn(['user', 'user', 'email']);
      dispatch(setChatMode('topic-edit'));
      dispatch(
        gaSend({
          category: 'Messenger',
          action: 'topic_content_edit',
          label: userEmail
        })
      );
    }
    if (event === 'edit-widget') {
      const userEmail = getState().getIn(['user', 'user', 'email']);
      window.open(
        `${process.env.UNICAT_MESSENGER_SERVICE}/chat/?chatMode=topic-edit&chatId=${chat.id}`,
        '_blank'
      );
      dispatch(
        gaSend({
          category: 'Messenger',
          action: 'topic_content_edit',
          label: userEmail
        })
      );
    }
  };

export const handleChatNotifications = (event, chat) => async (dispatch) => {
  const { id } = chat;
  if (event === 'restore-access') {
    dispatch(setMemberStatusInArchive(chat, 'ACTIVATED'));
    sckt.restoreAccessToChat(id);
  } else if (event === 'close') {
    sckt.closeChat(id, (isClosed) => {
      if (!isClosed) {
        dispatch(setSuccessMessage({ key: 'topic.notify-restored' }));
      }
      dispatch(toggleCloseInArchive(chat));
    });
  } else {
    console.log('handleChatNotifications', event, chat);
  }
};

export const handlerChatWidget =
  (event, { activeTab = 'chats' } = {}) =>
  async (dispatch, getState) => {
    const initApp = async () => {
      const subscribeToUpdateInList = () => {
        sckt.subscribeToNewMsg(({ message, channelId, deliveryId }) => {
          const searchedId = isType('MsgForwarded', message)
            ? message.originId
            : deliveryId;
          const currentEmployeeId = getCurrEmplId(getState());
          dispatch(
            ac.removeUnsentMessage({
              chatId: channelId,
              employeeId: currentEmployeeId,
              message,
              deliveryId: searchedId
            })
          );
          dispatch(writeUnsentChatMessagesToLS());
        });
        sckt.subscribeToUpdateMsg(({ message, channelId, deliveryId }) => {
          const currentEmployeeId = getCurrEmplId(getState());
          dispatch(
            ac.removeUnsentMessage({
              chatId: channelId,
              employeeId: currentEmployeeId,
              message,
              deliveryId
            })
          );
          dispatch(writeUnsentChatMessagesToLS());
        });
        sckt.subscribeToUpdateInList((chat) =>
          dispatch(updateChatInList(chat))
        );
        sckt.subscribeToUpdateLstMsg((chat) => {
          const state = getState();
          if (chat.archived) {
            const archives = getChatArchive(state);
            const updateArchives = updateItem(archives, chat);
            dispatch(ac.setArchives(updateArchives));
          } else {
            const chatList = getChatBlock(state);
            const chats = updateItem(chatList, chat);
            dispatch(ac.setChats(chats));
          }
        });
        dispatch(subscribeToUnreadMessageCount());
        sckt.subscribeToReloadChatList(() => dispatch(reloadChatList()));
        sckt.subscribeToArchiveChat(async ({ chat }) => {
          dispatch(archiveChatCase({ chat }));
        });
        sckt.subscribeToUnarchiveChat(async ({ chat }) => {
          dispatch(unArchiveChatCase({ chat }));
        });
        subToupdateChannelListByCreateGroupChat(({ chat }) => {
          dispatch(updateListFromSocketByCreatingChat(chat));
        });
        subToupdateChannelListByCreateTopic(({ topic }) => {
          dispatch(updateListFromSocketByCreatingChat(topic));
        });
        sckt.subscribeToTypingInfo(
          ({ channelId: id, userName, value, employeeId }) => {
            const state = getState();
            const currentEmployeeId = getCurrEmplId(state);
            const openedChat = getOpenedChat(state);
            const isOpenedChat = openedChat && openedChat.id === id;
            const isChatInList = fromChatTabById(id, state);
            if (
              currentEmployeeId !== employeeId &&
              (isOpenedChat || isChatInList)
            ) {
              dispatch(
                ac.updateTypingInfo({ id, userName, value, employeeId })
              );
            }
          }
        );
        sckt.subscribeToUpdateLastUnreadMessages((item) => {
          const clientData = getClientData(getState());

          if (
            clientData.chatId !== item.channelId ||
            !clientData.isScrollBottom ||
            !clientData.isActiveTab
          ) {
            dispatch(ac.updateLastUnreadMsg(item));
          }
        });
        dispatch(subscribeToClearMessageDivider());
      };

      if (activeTab === 'contacts') {
        await dispatch(getContactQueryData());
        dispatch(uploadContacts());
      }
      subscribeToUpdateInList();
      dispatch(getContactQueryData());
      dispatch(dictionariesStorage.getPurchaseRequestsCategories());
      dispatch(changeTab(activeTab));

      openChatAtMsg.handle((msg) => {
        dispatch(openChatAtMessage(msg));
      });
    };

    const quitApp = () => {
      const unsubscribeToUpdateInList = () => {
        sckt.unsubscribeFromNewMsg();
        sckt.unsubscribeFromUpdateMsg();
        sckt.unsubscribeToUpdateInList();
        sckt.unsubscribeFromUpdateLstMsg();
        dispatch(unsubscribeFromUnreadMessageCount());
        sckt.unsubscribeToReloadChatList();
        sckt.unsubscribeToTypingInfo();
      };
      unsubscribeToUpdateInList();
      unsubscribeFromClearMessageDivider();
    };

    if (event === 'quit') {
      quitApp();
    }

    if (event === 'init') {
      await initApp();
      const currentEmployeeId = getCurrEmplId(getState());
      sckt.getUnreadMsgsCount(currentEmployeeId);
    }

    if (event === 'push-off') {
      const chat = getOpenedChat(getState());
      if (chat) {
        safetyServiceWorkerPostMessage({
          type: 'SET_ACTIVE_CHAT_ID',
          chatId: chat.id
        });
      }
    }

    if (event === 'push-on') {
      safetyServiceWorkerPostMessage({
        type: 'SET_ACTIVE_CHAT_ID',
        chatId: null
      });
    }
  };

export const handlerContactList =
  (event, contact = {}) =>
  async (dispatch, getState) => {
    const { employeeId } = contact;
    const userEmail = getState().getIn(['user', 'user', 'email']);

    const addToGroupList = () => {
      dispatch(
        showModal('CW_EDIT_USER_CONTACT_LISTS', { contactId: employeeId })
      );
    };

    const handlers = {
      'add-to-list': () => addToGroupList(),
      'view-profile': async () => dispatch(showProfile(employeeId)),
      'view-profile-widget': () => showProfileWidget(employeeId),
      'load-more': async () => dispatch(loadContactsByChunks()),
      'open-dialog': async () => {
        dispatch(openDialogByEmployeeId(employeeId));
        dispatch(
          gaSend({
            category: 'Messenger',
            action: 'personal_chat',
            label: userEmail
          })
        );
      },
      block: async () => dispatch(toggleBlockModal(employeeId)),
      unblock: async () => dispatch(toggleBlockModal(employeeId)),
      delete: async () => dispatch(hideContact(employeeId))
    };

    await dispatcher('handlerContactList', handlers, event);
  };

export const handleSearchHeader =
  (event, value) => async (dispatch, getState) => {
    const state = getState();
    const chatWidget = getChatWidget(state);
    const { activeTab } = chatWidget;
    const userEmail = state.getIn(['user', 'user', 'email']);

    const search = () => {
      const query = makeQuery({ search: value.trim() });
      if (activeTab === 'chats') {
        dispatch(uploadChats({ query }));
      } else if (activeTab === 'archives') {
        dispatch(uploadArchives({ query }));
      } else if (activeTab === 'contacts') {
        dispatch(uploadContacts({ query }));
      }
    };

    const close = () => {
      dispatch(updateQuery('chats', makeQuery()));
      dispatch(updateQuery('archives', makeQuery()));
      dispatch(updateQuery('contacts', makeQuery()));
      if (activeTab === 'chats') {
        dispatch(uploadChats());
      } else if (activeTab === 'archives') {
        dispatch(uploadArchives());
      } else if (activeTab === 'contacts') {
        dispatch(uploadContacts());
      }
      dispatch(ac.setSidebarHeaderView('dashboard'));
    };

    const closeSearchInChat = () => {
      const mode = getChatMode(getState());
      if (mode.currentMode === 'search') {
        dispatch(prevChatMode());
      }
    };

    const subsearch = () => {
      close();
      dispatch(ac.hideGroupChat());
      dispatch(ac.hideGroupContact());
      closeSearchInChat();
      dispatch(ac.showAdvancedSearch());
      dispatch(ac.setSidebarHeaderView('dashboard'));
      dispatch(
        gaSend({
          category: 'Messenger',
          action: 'chat_adv_search',
          label: userEmail
        })
      );
    };

    const handlers = {
      search,
      subsearch,
      close
    };
    await dispatcher('handleSearchHeader', handlers, event);
  };

export const handleSortHeader =
  (event, direction = 'up') =>
  async (dispatch) => {
    const sort = (type) => {
      dispatch(sortChats(type, direction));
    };

    const close = () => {
      sort('default');
      dispatch(ac.setSidebarHeaderView('dashboard'));
    };

    const handlers = {
      'sort-by-chat-name': () => sort('sort-by-chat-name'),
      'sort-by-creation-date': () => sort('sort-by-creation-date'),
      'sort-by-author': () => sort('sort-by-author'),
      'sort-by-last-update': () => sort('sort-by-last-update'),
      close: () => close()
    };
    await dispatcher('handleSortHeader', handlers, event);
  };

export const handleProfileClicks =
  (contact, event) => async (dispatch, getState) => {
    const { employeeId, name, notificationOff } = contact;

    const userEmail = getState().getIn(['user', 'user', 'email']);
    const userEmployeeId = getState().getIn(['user', 'user', 'employeeId']);

    const toggleNotifyProfile = async () => {
      if (notificationOff) {
        await req.notificationOnFromProfile(null, employeeId);
      } else {
        await req.notificationOffFromProfile(null, employeeId);
      }
      dispatch(updateDataInProfile(employeeId));
    };

    const addUser = async () => {
      await reqUnhideContact(employeeId);
      dispatch(updateDialogInfoByEmployeeId(employeeId));
      dispatch(setSuccessMessage({ key: 'contact_was_added_to_list' }));
    };

    const addContactAsPartnerCase = async () => {
      await addContactAsPartner(employeeId);
      dispatch(setSuccessMessage({ key: 'contact_was_added_to_list' }));
    };

    const hideProfileView = () => {
      emitToServer(profileQRY.syncOff, {
        whoEmployeeId: userEmployeeId,
        targetEmployeeId: employeeId
      });
      dispatch(hideProfile());
    };

    const sendMsg = async () => {
      await dispatch(openDialogByEmployeeId(employeeId));
      hideProfileView();
      dispatch(
        gaSend({
          category: 'Messenger',
          action: 'personal_chat',
          label: userEmail
        })
      );
    };

    const handlers = {
      close: () => hideProfileView(),
      notify: toggleNotifyProfile,
      toggleblock: async () => dispatch(toggleBlockModal(employeeId)),
      delete: async () => dispatch(hideContact(employeeId, name)),
      'add-user': addUser,
      'add-contact-as-partner': addContactAsPartnerCase,
      'send-message': sendMsg
    };

    await dispatcher('handleProfileClicks', handlers, event);
  };

const toggleGroupList = (name) => async (dispatch) => {
  dispatch(toggleMessengerCategories(name));
};

export const handleArchiveBtnClick = (event, value) => async (dispatch) => {
  const changeFilter = () => {
    dispatch(ac.changeFilter('archive', value));
    dispatch(updateArchiviesFilter({ filterBy: value.value }));
    dispatch(uploadArchives());
  };

  const handlers = {
    folder: () => dispatch(toggleGroupList('chat-archive')),
    'change-filter': () => changeFilter(),
    search: () => dispatch(ac.setSidebarHeaderView('search')),
    sorting: () => {
      const actionType = value ? 'sort' : 'dashboard';
      dispatch(ac.setSidebarHeaderView(actionType));
    }
  };

  await dispatcher('handleArchiveBtnClick', handlers, event);
};

export const handleChatBtnClick =
  (event, value) => async (dispatch, getState) => {
    const changeFilter = () => {
      dispatch(ac.changeFilter('chat', value));
      dispatch(updateChatsFilter({ filterBy: value.value }));
      dispatch(uploadChats());
    };

    const userEmail = getState().getIn(['user', 'user', 'email']);

    const handlers = {
      folder: () => dispatch(toggleGroupList('chat')),
      channel: () => {
        dispatch(showModal('CW_GROUP_CHAT_CREATE'));
        dispatch(
          gaSend({
            category: 'Messenger',
            action: 'group_chat_plus',
            label: userEmail
          })
        );
      },
      'change-filter': () => changeFilter(),
      dialog: () => dispatch(changeTab('contacts')),
      topic: () => {
        dispatch(setChatMode('topic-create'));
        dispatch(
          gaSend({
            category: 'Messenger',
            action: 'topic_plus',
            label: userEmail
          })
        );
      },
      search: () => dispatch(ac.setSidebarHeaderView('search')),
      sorting: () => {
        const actionType = value ? 'sort' : 'dashboard';
        dispatch(ac.setSidebarHeaderView(actionType));
      },
      'topic-widget': () => createTopicWidget()
    };

    await dispatcher('handleChatBtnClick', handlers, event);
  };

export const handleContactBtnClick =
  (event, value) => async (dispatch, getState) => {
    const changeFilter = () => {
      dispatch(ac.changeFilter('contact', value));
      dispatch(uploadContacts());
    };

    const email = getState().getIn(['user', 'user', 'email']);

    const handlers = {
      folder: () => dispatch(toggleGroupList('contact')),
      'employee-other-company': () => {
        dispatch(
          gaSend({
            category: 'Messenger',
            action: 'сhat_add_agent',
            label: email
          })
        );
        dispatch(showModal('ADD_PARTNER', { fromMessenger: true }));
      },
      'employee-your-company': () => {
        dispatch(
          gaSend({
            category: 'Messenger',
            action: 'chat_add_employee',
            label: email
          })
        );
        dispatch(
          showModal('INVITE_EMPLOYEE', {
            title: 'Invite',
            text: 'Invite employee text',
            fromMessenger: true
          })
        );
      },
      'change-filter': changeFilter,
      search: () => dispatch(ac.setSidebarHeaderView('search')),
      groupBy: () => dispatch(ac.groupBy(value))
    };

    await dispatcher('handleContactBtnClick', handlers, event);
  };

const setSelectedMsgs = (toChatId, fromChatId) => (dispatch, getState) => {
  const state = getState();
  const messages = getSelectedItem(getChatMsgs(state));
  dispatch(ac.clearReplyMsgByChat(toChatId));
  dispatch(
    forwardMessagesActions.setForwardMessages({
      toChatId,
      fromChatId,
      messages
    })
  );
  dispatch(ac.clearSelectedMessages());
};

const forwardToContact = (chat) => (dispatch, getState, service) => {
  const { getDialogIdByEmployeeId, findChannel } = service.chat;

  const onClick = async ({ employeeId }) => {
    const dialogId = await getDialogIdByEmployeeId(employeeId);
    const dialog = await findChannel(dialogId);
    dispatch(setSelectedMsgs(dialog.id, chat.id));

    dispatch(openDialogByEmployeeId(employeeId));
    dispatch(hideModalDialog());
  };
  const onCancel = () => {
    dispatch(ac.clearSelectedMessages());
  };
  dispatch(
    showModal('CONTACTS_LIST', {
      onClick,
      onCancel,
      title: 'msg_forward_contact'
    })
  );
};

const forwardToChat = (mode, chat) => (dispatch, getState) => {
  const onClick = (selectedChat) => {
    dispatch(setSelectedMsgs(selectedChat.id, chat.id));
    showChat(selectedChat)(dispatch, getState);
    if (selectedChat.archived) {
      dispatch(changeTab('archives'));
    } else {
      dispatch(changeTab('chats'));
    }
    dispatch(hideModalDialog());
  };
  const onCancel = () => {
    dispatch(ac.clearSelectedMessages());
  };
  if (mode === 'dialog') {
    dispatch(
      showModal('CHATS_LIST', {
        onClick,
        onCancel,
        mode: 'dialog',
        title: 'msg_forward_dialog'
      })
    );
  }
  if (mode === 'channel') {
    dispatch(
      showModal('CHATS_LIST', {
        onClick,
        onCancel,
        mode: 'channel',
        title: 'msg_forward_group_chat'
      })
    );
  }
  if (mode === 'topic') {
    dispatch(
      showModal('CHATS_LIST', {
        onClick,
        onCancel,
        mode: 'topic',
        title: 'msg_forward_topic'
      })
    );
  }
  if (mode === 'topicout') {
    dispatch(
      showModal('CHATS_LIST', {
        onClick,
        onCancel,
        mode: 'topicout',
        title: 'msg_forward_chat'
      })
    );
  }
};

const replyByMessage = (message, channelId) => (dispatch) => {
  dispatch(forwardMessagesActions.setForwardMessages({ toChatId: channelId }));
  dispatch(ac.addReplyMsg({ ...message, channelId }));
  setFocusOnInputMessage();
};

const editMessage = (message, chat) => (dispatch) => {
  dispatch(ac.setMsgToEditor(message.html));
  dispatch(ac.setInputFiles(message.files));
  dispatch(ac.setEditingMessage({ message, chatId: chat.id }));
};

export const handleMessageClick =
  (event, chat, message, file, data) => async (dispatch, getState) => {
    const userEmail = getState().getIn(['user', 'user', 'email']);
    const currentEmployeeId = getCurrEmplId(getState());

    const copyFileToStorage = async () => {
      try {
        await req.copyFileToStorage(chat.id, file.id);
        dispatch(setSuccessMessage({ key: 'attach_copy_success' }));
      } catch (e) {
        if (e.msg === 'Storage space limit') {
          const linkKey = 'increase_storage';
          const linkClickHandler = () => dispatch(storageOversizeHandler());
          dispatch(setErrorMessage({ key: e.msg, linkClickHandler, linkKey }));
        } else {
          dispatch(
            setErrorMessage({ key: e.msg || 'Error while saving file' })
          );
        }
      }
    };

    const sendGaEvent = (c) => {
      if (c.type === 'Dialog') {
        dispatch(
          gaSend({
            category: 'Messenger',
            action: 'personal_chat_mes_send_on',
            label: userEmail
          })
        );
      } else if (c.type === 'Channel') {
        dispatch(
          gaSend({
            category: 'Messenger',
            action: 'group_chat_mes_send_on',
            label: userEmail
          })
        );
      } else if (c.type === 'Topic') {
        dispatch(
          gaSend({
            category: 'Messenger',
            action: 'topic_chat_mes_send_on',
            label: userEmail
          })
        );
      }
    };

    function getOriginalFileUrl() {
      return convertUrl(file.originalUrl || file.url);
    }

    function schedule() {
      switch (data.mode) {
        case 'default':
          if (isNil(data.date)) {
            dispatch(
              scheduleMsgCases.removeScheduleMessage(chat.id, message.id)
            );
          } else {
            dispatch(
              scheduleMsgCases.addScheduleMessage(
                chat.id,
                message.id,
                data.date
              )
            );
          }
          break;
        case 'clear-reminder':
          dispatch(
            showModal('SIMPLE_SUBMIT', {
              captionKey: 'scheduleMsg.clearModal.title',
              text: 'scheduleMsg.clearModal.text',
              textBtnConfirm: 'scheduleMsg.clearModal.btn_submit',
              textBtnCancel: 'scheduleMsg.clearModal.btn_cancel',
              submitAction: () => {
                // Todo make UN-8293
                dispatch(
                  scheduleMsgCases.removeScheduleMessage(
                    data.chatId,
                    data.message.id
                  )
                );
                document.dispatchEvent(
                  new CustomEvent('set-open-modal-select-delayed-time', {
                    detail: { messageId: message.id }
                  })
                );
              },
              onCancel: () => dispatch(jumpToMsg(data.message)),
              isCallCancelCbOnlyByClickCancelBtn: true
            })
          );
          break;
        default:
          throw new Error(`No such handler for mode = ${data.mode}`);
      }
    }

    function sendToChat() {
      dispatch(
        showModal('CHATS_LIST', {
          onClick,
          onCancel: () => {},
          mode: 'all',
          title: 'modalSendPRToChat.title',
          placeholder: 'modalSendPRToChat.placeholder'
        })
      );

      function onClick(selectedChat) {
        dispatch(ac.selectMessage(message.id));

        dispatch(setSelectedMsgs(selectedChat.id, chat.id));
        showChat(selectedChat)(dispatch, getState);

        if (selectedChat.archived) {
          dispatch(changeTab('archives'));
        } else {
          dispatch(changeTab('chats'));
        }

        dispatch(hideModalDialog());
      }
    }

    function createTask() {
      dispatch(
        modalActions.showModal(CREATE_NEW_TASK_MODAL, {
          initialDescription: message.msg,
          onSubmit: (task) => {
            dispatch(
              modalActions.showModal('SIMPLE_SUBMIT', {
                captionKey: 'taskCreatedModal.task-created',
                text: 'taskCreatedModal.navigate-to-task',
                textBtnConfirm: 'yes',
                textBtnCancel: 'no',
                submitAction: async () => {
                  customHistory.push(`/task/list?taskid=${task.id}`);
                }
              })
            );
          }
        })
      );
    }

    const handlers = {
      edit: () => dispatch(editMessage(message, chat)),
      'download-file': () =>
        window.open(`${getOriginalFileUrl()}?name=${file.name}`, '_blank'),
      'attach-storage': async () => copyFileToStorage(),
      'delete-file': () => {
        const messageFilesAfterDeletingFile = message.files.filter(
          (f) => f.id !== file.id
        );
        dispatch(
          ac.updateMsg({ ...message, files: messageFilesAfterDeletingFile })
        );

        return sckt.removeFileFromMsg(chat.id, message.id, file.id);
      },
      favorite: () => dispatch(toggleBookmark(message)),
      reply: () => dispatch(replyByMessage(message, chat.id)),
      'move-to-reply': async () => dispatch(jumpToMsg(message.targetMsg)),
      'forward-message': sendToChat,
      'forward-msg-contact': () => {
        dispatch(ac.selectMessage(message.id));
        dispatch(forwardToContact(chat));
        sendGaEvent(chat);
      },
      'forward-msg-channel': () => {
        dispatch(ac.selectMessage(message.id));
        dispatch(forwardToChat('channel', chat));
        sendGaEvent(chat);
      },
      'forward-msg-topic': () => {
        dispatch(ac.selectMessage(message.id));
        dispatch(forwardToChat('topic', chat));
        sendGaEvent(chat);
      },
      'highlight-message': () => {
        dispatch(ac.selectMessage(message.id));
        dispatch(setChatMode('select'));
      },
      'delete-message': () => {
        const haveInternetConnection = getState()
          .get('connections')
          .toJS().internet;
        if (haveInternetConnection) {
          sckt.removeMessage(chat.id, message.id, message.id);
        } else {
          dispatch(
            ac.removeUnsentMessage({
              chatId: chat.id,
              employeeId: currentEmployeeId,
              message,
              deliveryId: message.deliveryId
            })
          );
          dispatch(writeUnsentChatMessagesToLS());
          sckt.deleteMessageFromBuffer(message.deliveryId);
          const state = getState();
          const blockMessages = getChatMsgs(state);
          const unsentMessages = getChatUnsent(state);
          const unsentMsgs = hasListUnsent(unsentMessages, chat.id)
            ? getListUnsent(unsentMessages, chat.id).list
            : [];
          const currentMessages = [...blockMessages.list, ...unsentMsgs];
          const [lastMessage] = currentMessages.slice(-1);
          if (lastMessage) {
            const chatList = getChatBlock(state);
            const currentChat = getItem(chatList, chat.id);
            const updatedChat = assoc('lastMessage', lastMessage, currentChat);
            dispatch(ac.updateChat(updatedChat));
          }
        }
      },
      'delete-message-disabled': () => {},
      'view-profile': () => dispatch(showProfile(message.employeeId)),
      'move-by-link': () => dispatch(hideChatWidget()),
      'load-more': () => dispatch(loadPrevChunkMsgs(chat)),
      'load-more-next': () => dispatch(loadNextChunkMsgs(chat)),
      'view-specified-profile': () => dispatch(showProfile(data)),
      'pin-message': () =>
        dispatch(pinnedMsgsCases.pinMsg(chat.id, message.id)),
      'unpin-message': () =>
        dispatch(pinnedMsgsCases.unpinMsg(chat.id, message.id)),
      'create-task': createTask,
      schedule
    };

    await dispatcher('handleMessageClick', handlers, event);
  };

export const handleEditMsgPanel = (event) => async (dispatch) => {
  const cancelEdit = () => {
    dispatch(ac.setMsgToEditor(''));
    dispatch(ac.setEditingMessage(makeBlockEditMsg()));
    dispatch(ac.setInputBlock(makeBlockInput()));
  };

  const handlers = {
    cancel: () => cancelEdit()
  };

  await dispatcher('handleEditMsgPanel', handlers, event);
};

export const handleReplyMsgPanel = (event, messageId) => async (dispatch) => {
  const handlers = {
    cancel: () => dispatch(ac.clearReplyMsg(messageId))
  };

  await dispatcher('handleReplyMsgPanel', handlers, event);
};

export const handleDashboardSelectMode =
  (event) => async (dispatch, getState) => {
    const state = getState();
    const openedChat = getOpenedChat(state);
    const userEmail = state.getIn(['user', 'user', 'email']);

    const cancelSelection = () => {
      dispatch(prevChatMode());
      dispatch(ac.clearSelectedMessages());
    };

    function sendToChat() {
      dispatch(
        showModal('CHATS_LIST', {
          onClick,
          onCancel: () => {},
          mode: 'all',
          title: 'modalSendPRToChat.title',
          placeholder: 'modalSendPRToChat.placeholder'
        })
      );

      function onClick(selectedChat) {
        dispatch(setSelectedMsgs(selectedChat.id, openedChat.id));
        showChat(selectedChat)(dispatch, getState);

        if (selectedChat.archived) {
          dispatch(changeTab('archives'));
        } else {
          dispatch(changeTab('chats'));
        }

        dispatch(hideModalDialog());
      }
    }

    const sendGaEvent = (chat) => {
      if (chat.type === 'Dialog') {
        dispatch(
          gaSend({
            category: 'Messenger',
            action: 'personal_chat_mes_send_on',
            label: userEmail
          })
        );
      } else if (chat.type === 'Channel') {
        dispatch(
          gaSend({
            category: 'Messenger',
            action: 'group_chat_mes_send_on',
            label: userEmail
          })
        );
      } else if (chat.type === 'Topic') {
        dispatch(
          gaSend({
            category: 'Messenger',
            action: 'topic_chat_mes_send_on',
            label: userEmail
          })
        );
      }
    };

    const handlers = {
      'forward-message': () => {
        sendToChat();
        sendGaEvent(openedChat);
      },
      close: () => cancelSelection()
    };

    await dispatcher('handleDashboardSelectMode', handlers, event);
  };

const emitTypingEnd = (chatId) => {
  clearTimeout(typingTimeout);
  typingTimeout = null;
  sckt.emitTyping(chatId, false);
};

export const handleInputMsg = (event, value) => async (dispatch, getState) => {
  const getChatTypeAndId = (state) => {
    const openedChat = getOpenedChat(state);
    return isNil(openedChat)
      ? null
      : { chatId: openedChat.id, chatType: openedChat.type };
  };

  const state = getState();
  const { chatId } = getChatTypeAndId(state) || {};
  const deliveryId = nanoid();
  const haveInternetConnection = getState().get('connections').toJS().internet;

  const handleUnsentMessages = (type, removedFiles, updateId) => {
    const avatarSrc = getState().getIn(['user', 'user', 'avatar']);
    const employeeId = getState().getIn(['user', 'user', 'employeeId']);
    const firstName = getState().getIn(['user', 'user', 'firstName']);
    const lastName = getState().getIn(['user', 'user', 'lastName']);
    const isEmptyMsg = ({ msg, files = [] }) => Boolean(!msg && !files.length);
    const updateChatListWithoutConnection = (message) => {
      if (!haveInternetConnection) {
        const chatList = getChatBlock(state);
        const chat = getItem(chatList, chatId);
        const updatedChat = assoc('lastMessage', message, chat);
        dispatch(ac.updateChat(updatedChat));
      }
    };

    const createUnsentMsg = () => {
      const { html, msg, mentions } = value;
      const inputBlock = getChatInput(state);
      return {
        avatarSrc,
        bookmarkedBy: [],
        channelId: chatId,
        edited: false,
        employeeId,
        files: inputBlock.files,
        hidden: false,
        html,
        id: deliveryId,
        deliveryId,
        mentions,
        msg,
        time: new Date(),
        type: 'MsgUnsent',
        userName: `${lastName} ${firstName}`
      };
    };
    const createReplyUnsentMsg = () => {
      const unsentReplyMessage = createUnsentMsg();
      const replyMessage = getReplyMessages(state).messages[0];
      const preparedReplyMessage = assoc(
        'targetMsg',
        replyMessage,
        unsentReplyMessage
      );
      return preparedReplyMessage;
    };

    if (type === 'MsgUser') {
      const msg = createUnsentMsg();
      if (!isEmptyMsg(msg)) {
        dispatch(ac.addUnsentMessage({ chatId, employeeId, message: msg }));
        updateChatListWithoutConnection(msg);
        dispatch(writeUnsentChatMessagesToLS());
      }
      return msg;
    }
    if (type === 'MsgReply') {
      const msg = createReplyUnsentMsg();
      dispatch(ac.addUnsentMessage({ chatId, employeeId, message: msg }));
      updateChatListWithoutConnection(msg);
      dispatch(writeUnsentChatMessagesToLS());
      return msg;
    }
    if (type === 'MsgUpdate') {
      const msg = createReplyUnsentMsg();
      const updatedMsg = mergeRight(msg, {
        edited: true,
        removedFiles,
        id: updateId
      });
      dispatch(
        ac.addUnsentMessage({ chatId, employeeId, message: updatedMsg })
      );
      updateChatListWithoutConnection(updatedMsg);
      dispatch(writeUnsentChatMessagesToLS());
      return msg;
    }
    return undefined;
  };

  const resend = () => {
    const [chatSendId, blockListUnsent] = value;
    const { list } = blockListUnsent;
    if (list.length > 0) {
      list.forEach((unsentMsg) => {
        const {
          channelId,
          id,
          html,
          msg,
          mentions,
          removedFiles,
          files,
          forwardedMessages,
          replyByMessageId,
          // eslint-disable-next-line no-shadow
          deliveryId,
          edited,
          targetMsg,
          originId
        } = unsentMsg;

        if (originId) {
          sckt.sendMsg({
            channelId: chatSendId,
            msg: '',
            html: '<p></p>',
            mentions,
            files: [],
            forwardedMessages: { msgsIds: [originId], chatId: channelId },
            replyByMessageId: null,
            deliveryId: id
          });
          return;
        }
        if (edited) {
          sckt.updateMsg({
            chatId: channelId,
            messageId: id,
            html,
            msg,
            mentions,
            removedFiles,
            deliveryId
          });
          return;
        }
        if (targetMsg) {
          const replyOriginId = targetMsg.id;
          sckt.sendMsg({
            channelId,
            msg,
            html,
            mentions,
            files,
            forwardedMessages,
            replyByMessageId: replyOriginId,
            deliveryId
          });
          return;
        }
        sckt.sendMsg({
          channelId,
          msg,
          html,
          mentions,
          files,
          forwardedMessages,
          replyByMessageId,
          deliveryId
        });
      });
    }
  };

  const submit = () => {
    const editMsg = getEditMsg(state).message;
    dispatch(setReadMessagesInChat(chatId));

    const updateMsg = ({
      inputBlock,
      html,
      msg,
      mentions,
      isDelayedMsgMode
    }) => {
      const { id, files: msgFiles } = editMsg;
      const { files } = inputBlock;
      const removedFiles = msgFiles
        .filter((f) => !files.find((i) => i.id === f.id))
        .map((i) => i.id);

      if (isDelayedMsgMode) {
        dispatch(
          delayedMessagesCases.editDelayedMessage({
            messageId: id,
            data: { html, msg, mentions, removedFiles, deliveryId }
          })
        );
        return;
      }
      sckt.updateMsg({
        chatId,
        messageId: id,
        html,
        msg,
        mentions,
        removedFiles,
        deliveryId
      });
    };

    if (chatId !== null) {
      if (!isEmpty(editMsg)) {
        const { html, msg, mentions } = value;

        const inputBlock = getChatInput(state);
        const plannedDate = delayedMessagesGetters.getDelayMessagesPlannedDate(
          getState()
        );

        updateMsg({
          inputBlock,
          html,
          msg,
          mentions,
          isDelayedMsgMode: !isNil(plannedDate)
        });
        dispatch(ac.setEditingMessage(makeBlockEditMsg()));
        dispatch(ac.setInputBlock(makeBlockInput()));
      } else {
        const isDelayedMsgMode = checkIsDelayedMessagesMode();
        const inputBlock = getChatInput(state);

        const replyByMessageId = getReplyMessageId(state);
        if (!isDelayedMsgMode && replyByMessageId) {
          dispatch(ac.clearReplyMsg(replyByMessageId));
          handleUnsentMessages('MsgReply');
        }
        const { html, msg, mentions } = value;

        dispatch(
          sendForwardMessages({
            mentions,
            sendMessage: initSendForwardMessage(),
            isCreateUnsent: !isDelayedMsgMode
          })
        );
        dispatch(ac.removeLastUnreadMsg(chatId));

        if (!haveInternetConnection) {
          const onCloseCallback = () =>
            dispatch(
              setInfoMessage(
                {
                  key: `Messenger ${isMessengerServiceText()} is trying to connect to the Internet`
                },
                false
              )
            );
          dispatch(
            setErrorMessage({
              key: 'Message not sent No internet connection',
              onCloseCallback
            })
          );
        }

        if (!isDelayedMsgMode && !replyByMessageId) {
          handleUnsentMessages('MsgUser');
        }

        if (!isEmptyMsg(msg, inputBlock.files)) {
          const message = {
            channelId: chatId,
            msg,
            html,
            mentions,
            files: inputBlock.files,
            forwardedMessages: null,
            replyByMessageId,
            deliveryId
          };

          if (isChatInputLoadingFilesIdsEmpty(getState())) {
            sendSimpleMessage(message);
          } else {
            dispatch(addMessageToWaiting(message));
          }
          dispatch(ac.setInputBlock(makeBlockInput()));
        }
      }
    }

    return undefined;

    function sendSimpleMessage(msg, idx) {
      return checkIsDelayedMessagesMode()
        ? dispatch(delayedMessagesCases.sendDelayedMessage({ ...msg, idx }))
        : sckt.sendMsg(msg);
    }

    function initSendForwardMessage() {
      return checkIsDelayedMessagesMode()
        ? (msg, idx) =>
            dispatch(delayedMessagesCases.sendDelayedMessage({ ...msg, idx }))
        : undefined;
    }

    function checkIsDelayedMessagesMode() {
      const plannedDate = delayedMessagesGetters.getDelayMessagesPlannedDate(
        getState()
      );

      return !isNil(plannedDate) && isFuture(new Date(plannedDate));
    }

    function isEmptyMsg(msgText, msgFiles) {
      return isEmpty(msgText) && isEmpty(msgFiles);
    }
  };

  const onChangeChat = () => {
    if (isNil(value) || isEmpty(value.prevText)) return;

    const { prevId, prevText } = value;
    dispatch(setDraftMessage(prevId, { withTimeout: false, text: prevText }));
  };

  const handlers = {
    typing: () => typingCases.typing(chatId),
    submit,
    resend,
    edit: () => dispatch(messageInputCases.editMessage(chatId)),
    'attach-storage': async () =>
      dispatch(messageInputCases.attachFilesFromStorageModal(chatId)),
    'attach-local': async () =>
      dispatch(messageInputCases.attachLocalFiles(chatId, value)),
    'delete-file': async () =>
      dispatch(messageInputCases.removeUnsentFile(chatId, value)),
    'read-messages': () => dispatch(setReadMessagesInChat(chatId)),
    'remove-divider': () => dispatch(clearMessageDivider()),
    'view-specified-profile': () => dispatch(showProfile(value)),
    'change-text': () => dispatch(messageInputCases.changeText(value)),
    'set-draft': () => {
      dispatch(setDraftMessage(chatId, { withTimeout: true, text: value }));
    },
    'change-chat': onChangeChat,
    'set-text': () =>
      dispatch(chatInputStorageCases.saveChatInputDataToStorage(value))
  };

  await dispatcher('handleInputMsg', handlers, event);
};

export const handleChatList =
  (event, chat, interlocutor, employeeId) => async (dispatch, getState) => {
    const repeatLastRequestGetChats = async () => {
      const query = getQuery('chats', getState());
      dispatch(chunkUploadChats({ query: nextPage(query) }));
    };

    const addToGroupList = async () => {
      dispatch(showEditChatIncludesGroups(chat));
    };

    const userEmail = getState().getIn(['user', 'user', 'email']);

    const openChat = async () => {
      dispatch(clearMessages());
      dispatch(ac.setSearchMessages([], 0));
      const openedChat = getOpenedChat(getState());
      if (openedChat && openedChat.id) {
        if (openedChat.id === chat.id) return;
        emitTypingEnd(openedChat.id);
      }
      const chatMode = getChatMode(getState());
      const haveInternetConnection = getState()
        .get('connections')
        .toJS().internet;
      if (!haveInternetConnection) {
        dispatch(setChatMode('no-connection-internet'));
        return;
      }
      if (['topic-create', 'topic-edit'].includes(chatMode.currentMode)) {
        await req.clearTopicUnsaveInfoFile('_');
        dispatch(prevChatMode());
        dispatch(ac.setInputFiles([]));
      }

      await showChat(chat)(dispatch, getState);

      if (chat.type === 'Dialog') {
        dispatch(
          gaSend({
            category: 'Messenger',
            action: 'personal_chat',
            label: userEmail
          })
        );
      }
      if (chat.type === 'Channel') {
        dispatch(
          gaSend({
            category: 'Messenger',
            action: 'group_chat',
            label: userEmail
          })
        );
      }
      if (chat.type === 'Topic') {
        dispatch(
          gaSend({ category: 'Messenger', action: 'topic', label: userEmail })
        );
      }

      activeChatNotification(chat.id);
    };

    const handlers = {
      'add-to-list': addToGroupList,
      'open-chat': openChat,
      'load-more': repeatLastRequestGetChats,
      'notice-on': async () => toggleNotify(chat)(dispatch, getState),
      'notice-off': async () => toggleNotify(chat)(dispatch, getState),
      archive: async () => toggleArchive(chat)(dispatch, getState),
      edit: () =>
        dispatch(showModal('CW_GROUP_CHAT_EDIT', { chat, mode: chat.type })),
      unarchive: async () => toggleArchive(chat)(dispatch, getState),
      'leave-channel': () => dispatch(leave(chat.type, chat)),
      view: () => dispatch(viewTopic(chat)),
      'leave-topic': () => dispatch(leave(chat.type, chat)),
      'close-topic': () => dispatch(closeTopic(chat)),
      pin: () => sckt.pinChat(chat.id),
      unpin: () => sckt.unpinChat(chat.id),
      'view-specified-profile': () => dispatch(showProfile(employeeId)),
      'unsubscribe-purchase-requests': () =>
        dispatch(purchaseRequestsStorage.unsubscribe())
    };

    await dispatcher('handleChatList', handlers, event);
  };

export const handleArchives = (event, chat) => async (dispatch, getState) => {
  const loadMore = async () => {
    const query = getQuery('archives', getState());
    dispatch(chunkUploadArhives({ query: nextPage(query) }));
  };

  const handlers = {
    'open-chat': () => showChat(chat)(dispatch, getState),
    'load-more': loadMore,
    'notice-on': async () => toggleNotify(chat)(dispatch, getState),
    'notice-off': async () => toggleNotify(chat)(dispatch, getState),
    archive: async () => toggleArchive(chat)(dispatch, getState),
    unarchive: async () => toggleArchive(chat)(dispatch, getState)
  };

  await dispatcher('handleArchives', handlers, event);
};

export const handleFavorites = (event, message) => async (dispatch) => {
  const goToMsg = async () => {
    dispatch(hideFavorites());
    await dispatch(jumpToMsg(message));
  };

  const loadMore = async () => {
    const pageNumber = message;
    dispatch(loadMoreFavoriteMsgs(pageNumber));
  };

  const handlers = {
    'back-to-chat': () => dispatch(hideFavorites()),
    'go-to-msg': goToMsg,
    favorite: () => dispatch(toggleBookmark(message)),
    'view-profile': async () => dispatch(showProfile(message.employeeId)),
    'load-more': loadMore
  };

  await dispatcher('handleFavorites', handlers, event);
};

export const handleDelayedMessages =
  (event, chat, message) => async (dispatch) => {
    const goToMsg = async () => {
      dispatch(delayedMessagesCases.hideDelayedMessages());
      await dispatch(jumpToMsg(message));
    };

    const loadMore = async () => {
      const pageNumber = chat;
      dispatch(delayedMessagesCases.loadMoreDelayedMessages(pageNumber));
    };

    const edit = () => {
      dispatch(editMessage(message, chat));
      dispatch(delayedMessagesCases.setPlannedDate(new Date(message.time)));
    };

    const handlers = {
      'back-to-chat': () =>
        dispatch(delayedMessagesCases.hideDelayedMessages()),
      'go-to-msg': goToMsg,
      'view-profile': async () => dispatch(showProfile(message.employeeId)),
      'load-more': loadMore,
      edit,
      publish: () =>
        dispatch(delayedMessagesCases.publishDelayedMessage(message.id)),
      'delete-message': () =>
        dispatch(delayedMessagesCases.deleteDelayedMessage(message.id))
    };

    await dispatcher('handleDelayedMessages', handlers, event);
  };

const addToList = (ids) => async (dispatch, getState) => {
  const includeItems = (block) => {
    const updatedBlock = prepareBGEList(block, ids, true);

    return includeBGEIds(updatedBlock, ids);
  };

  const blockGroup = getGroupEdit(getState());
  const updatedBlock = includeItems(blockGroup);
  dispatch(ac.setGroupChatsEdit(updatedBlock));
};

const deleteFromList = (deletedItem) => async (dispatch, getState) => {
  const excludeItem = (block, id) => {
    const updatedBlock = prepareBGEList(block, [id], false);
    return excludeBGEId(updatedBlock, id);
  };

  const blockGroup = getGroupEdit(getState());
  const updatedGroup = excludeItem(blockGroup, deletedItem);
  dispatch(ac.setGroupChatsEdit(updatedGroup));
};

const deleteAllFromList =
  (fieldId = 'id') =>
  async (dispatch, getState) => {
    const excludeAllItems = (block) => {
      const ids = block.list
        .filter((item) => item.alreadyAdded)
        .map((item) => item[fieldId]);

      const updatedBlock = prepareBGEList(block, ids, false);

      return excludeBGEIds(updatedBlock, ids);
    };

    const blockGroup = getGroupEdit(getState());
    const updatedBlock = excludeAllItems(blockGroup);
    dispatch(ac.setGroupChatsEdit(updatedBlock));
  };

const cancelEditGroup = () => async (dispatch) => {
  dispatch(ac.setGroupChatsEdit(makeBlockGroupEdit()));
};

const onDeleteGroup =
  ({ id, name, uploadGroups, deleteGroup }) =>
  async (dispatch) => {
    dispatch(
      showModal('SIMPLE_SUBMIT', {
        title: { tkey: 'confirm-delete-group', options: { name } },
        textBtnConfirm: 'Confirm',
        submitAction: async () => {
          await deleteGroup(id);
          await uploadGroups();
        }
      })
    );
  };

export const handleGroupContacts =
  (event, value) => async (dispatch, getState, service) => {
    const { createGroup, createSubGroup, updateGroup, deleteGroup } =
      service.contacts;
    const state = getState();
    const group = getGroupContacts(state);
    const userEmail = state.getIn(['user', 'user', 'email']);
    const onToggleSelect = async () => {
      const contact = value;
      if (includesItem(group, contact)) {
        dispatch(filterContacts(unselectId(group, contact.id)));
      } else {
        dispatch(filterContacts(selectId(group, contact.id)));
      }
    };

    const onToggleSelectAll = async () => {
      if (isSelectedAll(group)) {
        dispatch(filterContacts(clearSelect(group)));
      } else {
        dispatch(filterContacts(selectAll(group)));
      }
    };

    const uploadGroups = () => {
      dispatch(uploadContacts());
    };

    const onNewGroup = async () => {
      const name = value;
      await createGroup(name);
      const blockContact = getChatContact(state);
      const currentQuery = getQuery('contacts', state);
      const query = makeQuery({
        ...currentQuery,
        page: 0,
        limit: blockContact.size
      });
      dispatch(uploadContacts({ query }));
      dispatch(
        gaSend({
          category: 'Messenger',
          action: 'chat_contacts_list',
          label: userEmail
        })
      );
    };

    const onNewSubGroup = async () => {
      const { parentId, name } = value;
      await createSubGroup(parentId, name);
      uploadGroups();
    };

    const onUpdateGroup = async () => {
      const { includeIds, excludeIds } = getGroupEdit(state);

      const payload = {
        includeIds,
        excludeIds
      };
      if (value.name) {
        payload.name = value.name;
      }
      await updateGroup(value.id, payload);
      uploadGroups();
      dispatch(
        gaSend({
          category: 'Messenger',
          action: 'chat_contacts_list_edit',
          label: userEmail
        })
      );
    };

    const onEditGroup = () => {
      const prepareChat = ({
        company,
        employeeId,
        userId,
        name,
        avatar,
        lists
      }) => {
        const listIds = lists.map((item) => ({
          id: item.id,
          checked: item.checked
        }));

        const isAlreadyAdded = () => {
          const { checked } =
            listIds.find((item) => item.id === value.id) || {};

          return !!checked;
        };

        return {
          id: userId,
          company,
          employeeId,
          name,
          avatar,
          alreadyAdded: isAlreadyAdded()
        };
      };

      const { list } = getChatContact(state);
      const blockGroupEdit = getGroupEdit(state);
      const updatedBlock = setBGEList(blockGroupEdit, list.map(prepareChat));
      dispatch(ac.setGroupChatsEdit(updatedBlock));
      dispatch(showModal('CW_EDIT_CONTACT_LIST', value));
    };

    const handlers = {
      'create-group': () => dispatch(showModal('CW_CREATE_CONTACT_LIST')),
      'create-subgroup': () =>
        dispatch(
          showModal('CW_CREATE_CONTACT_SUBLIST', { parentId: value.id })
        ),
      'edit-group': onEditGroup,
      'add-chats-to-list': () => dispatch(addToList(value)),
      'delete-from-list': () => dispatch(deleteFromList(value)),
      'delete-all-from-list': () => dispatch(deleteAllFromList()),
      'cancel-change-group': () => dispatch(cancelEditGroup()),
      'new-group': () => onNewGroup(),
      'new-subgroup': () => onNewSubGroup(),
      'update-group': () => onUpdateGroup(),
      'delete-group': () =>
        dispatch(
          onDeleteGroup({
            id: value.id,
            name: value.name,
            uploadGroups,
            deleteGroup
          })
        ),
      'toggle-select': onToggleSelect,
      'toggle-select-all': onToggleSelectAll
    };

    await dispatcher('handleGroupContacts', handlers, event);
  };

export const handleEditUserInGroup =
  (event, params) => async (dispatch, getState, service) => {
    const { addToGroup, removeFromGroup } = service.contacts;

    const changeCheckedAtListsContact = (checked) => {
      const { group, contact } = params;
      const lists = contact.lists.map((g) => {
        if (g.id === group.id) {
          return { ...g, checked };
        }
        return g;
      });
      dispatch(ac.updateContact({ ...contact, lists }));
      dispatch(updateCountInGroupContacts(checked, group.id));
    };

    const onToggle = async () => {
      const { group, contact } = params;
      if (group.checked) {
        await removeFromGroup(group.id, contact.userId);
        changeCheckedAtListsContact(false);
      } else {
        await addToGroup(group.id, contact.userId);
        changeCheckedAtListsContact(true);
      }
    };

    const handlers = {
      'toggle-add': onToggle
    };
    await dispatcher('handleEditUserInGroup', handlers, event);
  };

export const handleEditChatInGroup =
  (event, params) => async (dispatch, getState, service) => {
    const { addToGroup, removeFromGroup } = service.chat;

    const changeCountInBlockList = (blockList, itemId, value) => ({
      ...blockList,
      list: blockList.list.map((item) =>
        item.id === itemId ? { ...item, count: item.count + value } : item
      )
    });

    const updateCountInGroup = (checked, groupId) => {
      const blockGroup = getGroupChats(getState());
      const changedCount = checked ? 1 : -1;
      const updatedBlockGroup = changeCountInBlockList(
        blockGroup,
        groupId,
        changedCount
      );
      dispatch(ac.setGroupChats(updatedBlockGroup));
    };

    const changeCheckedAtLists = (checked) => {
      const { group, chat } = params;

      let user = [];
      if (checked) {
        user = uniq([...chat.checkedLists.user, group.id]);
      } else {
        user = chat.checkedLists.user.filter((id) => id !== group.id);
      }
      updateCountInGroup(checked, group.id);
      const updatedChat = assocPath(['checkedLists', 'user'], user, chat);
      dispatch(ac.updateArchive(updatedChat));
      dispatch(ac.updateChat(updatedChat));
    };

    const onToggle = async () => {
      const { group, chat } = params;
      const isSelected = chat.checkedLists.user.includes(group.id);
      if (isSelected) {
        await removeFromGroup(group.id, chat);
        changeCheckedAtLists(false);
      } else {
        await addToGroup(group.id, chat);
        changeCheckedAtLists(true);
      }
    };

    const handlers = {
      'toggle-add': onToggle
    };
    await dispatcher('handleEditChatInGroup', handlers, event);
  };

export const handleGroupChats =
  (event, value) => async (dispatch, getState, service) => {
    const {
      getAllChats,
      createGroup,
      updateGroup,
      getGroups,
      deleteGroup,
      createSubGroup
    } = service.chat;
    const state = getState();
    const blockGroups = getGroupChats(state);

    const userEmail = state.getIn(['user', 'user', 'email']);

    const onClearAll = async () =>
      dispatch(loadChatsByGroups(clearSelect(blockGroups)));

    const onToggleSelect = async () => {
      const group = value;
      if (includesItem(blockGroups, group)) {
        dispatch(loadChatsByGroups(unselectId(blockGroups, group.id)));
      } else {
        dispatch(loadChatsByGroups(selectId(blockGroups, group.id)));
      }
    };

    const onSetFilterIfExists = async () => {
      const requestId = value;
      const foundGroup = blockGroups.list.find((group) =>
        group.name.includes(requestId)
      );

      if (foundGroup) {
        await dispatch(loadChatsByGroups(clearSelect(blockGroups)));
        dispatch(loadChatsByGroups(selectId(blockGroups, foundGroup.id)));
        dispatch(showChatWidget());
      }
    };

    const clearGroups = async () => {
      const groups = await getGroups();
      let updatedGroups = setList(blockGroups, groups.user.all);

      if (isEmpty(groups.user.all)) updatedGroups = clearSelect(updatedGroups);

      dispatch(loadChatsByGroups(clearSelect(updatedGroups)));
    };

    const onToggleSelectAll = async () => {
      if (isSelectedAll(blockGroups)) {
        dispatch(loadChatsByGroups(clearSelect(blockGroups)));
      } else {
        dispatch(loadChatsByGroups(selectAll(blockGroups)));
      }
    };

    const uploadGroups = async () => {
      const groups = await getGroups();
      let updatedGroups = setList(blockGroups, groups.user.all);

      if (isEmpty(groups.user.all)) updatedGroups = clearSelect(updatedGroups);

      dispatch(loadChatsByGroups(updatedGroups));
    };

    const onNewGroup = async () => {
      const name = value;
      await createGroup(name);
      await uploadGroups();
      dispatch(
        gaSend({
          category: 'Messenger',
          action: 'chat_list_create',
          label: userEmail
        })
      );
    };

    const onNewSubGroup = async () => {
      const { parentId, name } = value;
      await createSubGroup(parentId, name);
      await uploadGroups();
    };

    const onUpdateGroup = async () => {
      const { includeIds, excludeIds, list } = getGroupEdit(state);

      const payload = {
        includeItems: [],
        excludeIds
      };
      if (value.name) {
        payload.name = value.name;
      }
      if (includeIds.length) {
        payload.includeItems = list
          .filter((item) => includeIds.includes(item.id))
          .map(({ id, type }) => ({ id, type }));
      }

      await updateGroup(value.id, payload);
      dispatch(
        updateGroupOfChats({
          groupId: value.id,
          exclude: payload.excludeIds,
          include: payload.includeItems.map(({ id }) => id)
        })
      );
      await uploadGroups();

      dispatch(
        gaSend({
          category: 'Messenger',
          action: 'chat_list_edit_finish',
          label: userEmail
        })
      );
    };

    const logEditChatGroup = logUseCase.extend('onEditGroup');
    const onEditGroup = async () => {
      const toModelView = ({
        id,
        type,
        color,
        name,
        members,
        checkedLists
      }) => {
        const isAlreadyAdded = ({ user }) => user.includes(value.id);

        const chat = {
          id,
          type,
          color,
          name,
          membersCount: members.length,
          alreadyAdded: isAlreadyAdded(checkedLists)
        };

        if (type === 'Dialog') {
          const interlocutor = getInterlocutor(getCurrEmplId(state), {
            members
          });
          chat.name = interlocutor.userName;
          chat.companyName = interlocutor.companyName;
          chat.avatar = interlocutor.avatarSrc;
        }

        return chat;
      };

      const allChats = await getAllChats();
      logEditChatGroup('chats', allChats);

      const blockGroupEdit = getGroupEdit(state);
      const updatedBlock = setBGEList(
        blockGroupEdit,
        allChats.map(toModelView)
      );
      logEditChatGroup('updatedBlock', updatedBlock);
      dispatch(ac.setGroupChatsEdit(updatedBlock));
      dispatch(showModal('CW_EDIT_DIALOG_LIST', value));
    };

    const handlers = {
      'create-group': () => dispatch(showModal('CW_CREATE_DIALOG_LIST')),
      'create-subgroup': () =>
        dispatch(showModal('CW_CREATE_DIALOG_SUBLIST', { parentId: value.id })),
      'edit-group': onEditGroup,
      'add-chats-to-list': () => dispatch(addToList(value)),
      'delete-from-list': () => dispatch(deleteFromList(value)),
      'delete-all-from-list': () => dispatch(deleteAllFromList()),
      'cancel-change-group': () => dispatch(cancelEditGroup()),
      'new-group': () => onNewGroup(),
      'new-subgroup': () => onNewSubGroup(),
      'update-group': () => onUpdateGroup(),
      'delete-group': () =>
        dispatch(
          onDeleteGroup({
            id: value.id,
            name: value.name,
            uploadGroups: clearGroups,
            deleteGroup
          })
        ),
      'toggle-select': onToggleSelect,
      'toggle-select-all': onToggleSelectAll,
      'set-filter-if-exist': onSetFilterIfExists,
      'clear-filter': onClearAll
    };

    await dispatcher('handleGroupChats', handlers, event);
  };

export const openSupportChat = () => async (dispatch, getState, service) => {
  const { getData } = service.support;
  const support = await getData();
  window.open(
    `${process.env.UNICAT_MESSENGER_SERVICE}/chat/join/${support.employeeId}`,
    '_blank'
  );
};

export const openDialogChat = (employeeId) => (dispatch, getState) => {
  const currentCompanyId = getUserCurrCompanyId(getState());
  if (!currentCompanyId) {
    window.location = '/profile/companies';
    return;
  }
  window.open(
    `${process.env.UNICAT_MESSENGER_SERVICE}/chat/join/${employeeId}`
  );
};

export const openDialogChatM = (employeeId) => (dispatch, getState) => {
  const currentCompanyId = getUserCurrCompanyId(getState());
  if (!currentCompanyId) {
    window.location = '/profile/companies';
    return;
  }

  if (isMessengerService()) {
    customHistory.push(`/chat/join/${employeeId}`);
    return;
  }

  window.open(
    `${process.env.UNICAT_MESSENGER_SERVICE}/chat/join/${employeeId}`
  );
};

export const openContactProfile = (employeeId) => (dispatch, getState) => {
  const currentCompanyId = getUserCurrCompanyId(getState());
  if (!currentCompanyId) {
    window.location = '/profile/companies';
    return;
  }
  window.open(
    `${process.env.UNICAT_MESSENGER_SERVICE}/chat/?profile=${employeeId}`
  );
};

export const openContactProfileM = (employeeId) => (dispatch, getState) => {
  const currentCompanyId = getUserCurrCompanyId(getState());
  if (!currentCompanyId) {
    window.location = '/profile/companies';
    return;
  }
  window.open(
    `${process.env.UNICAT_MESSENGER_SERVICE}/chat/?profile=${employeeId}`
  );
};

export const openContacts = () => async (dispatch, getState) => {
  const currentCompanyId = getUserCurrCompanyId(getState());
  if (!currentCompanyId) {
    window.location = '/profile/companies';
    return;
  }
  window.open(`${process.env.UNICAT_MESSENGER_SERVICE}/chat/?contacts=true`);
};

export const handleSearchMode =
  (event, value) => async (dispatch, getState, service) => {
    const getChatId = () => {
      const openedChat = getOpenedChat(getState());
      return isNil(openedChat) ? null : openedChat.id;
    };

    const onBackToChat = () => {
      dispatch(prevChatMode());
      dispatch(chatInputStorageCases.setReduxDataToChatInput());
      dispatch(ac.setSearchMessages([], 0));
    };

    const goToMsg = async () => {
      const message = value;
      await dispatch(jumpToMsg(message));
      dispatch(prevChatMode());
      dispatch(ac.setSearchMessages([], 0));
    };

    const searchMsgs = async () => {
      const { search } = service.chatMessage;
      const chatId = getChatId();

      if (chatId) {
        const querySearch = value;
        const response = await search(chatId, querySearch);
        dispatch(ac.setSearchMessages(response.list, response.amountLeft));
      }
    };

    const loadMore = async () => {
      const { search } = service.chatMessage;
      const msgs = getChatSearchMsgs(getState());
      const chatId = getChatId();

      if (chatId) {
        const querySearch = value;
        const response = await search(chatId, querySearch);
        dispatch(
          ac.setSearchMessages(
            msgs.list.concat(response.list),
            response.amountLeft
          )
        );
      }
    };

    const handlers = {
      'back-to-chat': onBackToChat,
      'go-to-msg': goToMsg,
      search: searchMsgs,
      'view-profile': async () => dispatch(showProfile(value.employeeId)),
      'load-more': loadMore
    };

    await dispatcher('handleSearchMode', handlers, event);
  };

export const handleNotificationSettings = (event) => async (dispatch) => {
  const handlers = {
    'email-toggle': () =>
      dispatch(emailNotificationActions.toggleChatEmailNotification()),
    subscribe: () => dispatch(pushesCases.subscribe()),
    sound: () => dispatch(emailNotificationActions.toggleSoundNotification()),
    unsubscribe: () => dispatch(pushesCases.unsubscribe())
  };

  await dispatcher('handleNotificationSettings', handlers, event);
};

export const handleAdvancedSearch =
  (event, value) => async (dispatch, getState, service) => {
    const { search: searchReq } = service.chat;

    const setParam = () => {
      dispatch(ac.setAdvancedSearchParam({ key: event, value }));

      const userEmail = getState().getIn(['user', 'user', 'email']);
      dispatch(
        gaSend({
          category: 'Messenger',
          action: 'chat_adv_search_add',
          label: userEmail
        })
      );
    };

    const goSearch = async () => {
      const blockSearch = getAdvancedSearch(getState());
      const query = {
        // ...blockSearch.params,
        globalSearch: blockSearch.globalSearch
      };

      const results = await searchReq(query);
      dispatch(ac.setAdvancedSearchResults(results));
    };

    const setAdvancedSearchParam = async () => {
      setParam();
      await goSearch();
    };

    const setGlobalSearch = async () => {
      dispatch(ac.setAdvancedSearchGlobalSearch(value));
      await goSearch();
    };

    const close = () => {
      dispatch(ac.setAdvancedSearchBlock(makeBlockAdvancedSearch()));
    };

    const handlers = {
      messageDate: setAdvancedSearchParam,
      globalSearch: setGlobalSearch,
      close
    };

    await dispatcher('handleAdvancedSearch', handlers, event);
  };

export const handleChatHistoryDownload =
  (event, value) => async (dispatch, getState, service) => {
    const clearErrors = () => {
      const errors = getErrors(getState());
      if (errors.length) {
        dispatch(ac.clearErrors());
      }
    };

    const submit = async () => {
      const { password, chatId, checkboxes, interval } = value;
      const { chatHistory } = service.chat;
      dispatch(startLoader('modalConfirm'));
      dispatch(setInfoMessage({ key: 'wait_archive_is_being_formed' }));
      chatHistory(chatId, password, checkboxes, interval)
        .then(() => {
          dispatch(
            modalActions.hideTargetModal('CW_CONFIRM_ACTION_WITH_PASSWORD')
          );
          dispatch(setSuccessMessage({ key: 'archive_formed_successfully' }));
        })
        .catch(errorHandler);
      dispatch(finishLoader('modalConfirm'));
      return undefined;

      function errorHandler(err) {
        const { errors } = err;
        if (!err) return console.error(err);
        errors.map((error) => dispatch(ac.addError(error)));
        const isPasswordIncorrect = Boolean(
          errors.find((error) => error.type === 'passwordError')
        );
        if (isPasswordIncorrect) {
          dispatch(setErrorMessage({ key: 'The password is not correct' }));
        } else {
          dispatch(setErrorMessage({ key: 'Download error' }));
        }
        return undefined;
      }
    };

    const handlers = {
      submit,
      clearErrors
    };

    await dispatcher('handleChatHistoryDownload', handlers, event);
  };

export const handleDemoModals = (event) => async (dispatch) => {
  const confirmExitDemo = () =>
    dispatch(
      showModal('SIMPLE_SUBMIT', {
        captionKey: 'Exit from demo',
        text: 'Exit demo text',
        textBtnConfirm: 'Exit',
        submitAction: () => {
          window.location = '/logout/demo';
        }
      })
    );

  const confirmGoToRegistration = () =>
    dispatch(
      showModal('SIMPLE_SUBMIT', {
        captionKey: 'go_to_registration',
        text: 'text_go_to_registration',
        textBtnConfirm: 'go_to',
        submitAction: () => {
          window.location = '/logout/demo/register';
        }
      })
    );

  const handlers = {
    'exit-from-demo': confirmExitDemo,
    'go-to-registration': confirmGoToRegistration
  };

  await dispatcher('handleFavorites', handlers, event);
};

export const setInputText = (text) => (dispatch) => {
  dispatch(ac.setInputText(text));
};
