import React, { useCallback, useMemo } from 'react';
import { connect } from 'react-redux';
import { Badge } from 'react-bootstrap';
import { withRouter } from '../../hoc/withRouter';
import WorkspaceHeader from '../../components/WorkspaceHeader/WorkspaceHeader';
import * as storeGetters from '../../storeGetters';
import { useTranslate } from '../../TranslateProvider';
import './style.css';

const TabWithBadge = ({ t, transKey, count = 0 }) => {
  if (!count) return t(transKey);

  return (
    <span>
      {t(transKey)}
      <Badge className="badge-round" pullRight>
        {count}
      </Badge>
    </span>
  );
};

const generalNavItems = (
  t,
  {
    allPurchaseRequestsCount = 0,
    myPurchaseResponsesCount = 0,
    responsePurchaseRequestCount = 0
  }
) => [
  {
    eventKey: 'all',
    name: (
      <TabWithBadge
        t={t}
        transKey="All purchase requests"
        count={allPurchaseRequestsCount}
      />
    )
  },
  {
    eventKey: 'my',
    name: (
      <TabWithBadge
        t={t}
        transKey="My purchase requests"
        count={responsePurchaseRequestCount}
      />
    )
  },
  {
    eventKey: 'my-responses',
    name: (
      <TabWithBadge
        t={t}
        transKey="my-responses"
        count={myPurchaseResponsesCount}
      />
    )
  }
];

const PurchaseRequestsTabs = ({
  activeKey,
  language,
  history,
  allPurchaseRequestsCount = 0,
  responsePurchaseRequestCount = 0,
  myPurchaseResponsesCount = 0,
  onTabChange
}) => {
  const t = useTranslate();

  const handleTabChange = useCallback(
    (tab) => {
      if (typeof onTabChange === 'function') {
        onTabChange(tab);
        return;
      }

      history.push(`/requests/${tab}`);
    },
    [onTabChange]
  );

  const items = useMemo(
    () =>
      generalNavItems(t, {
        allPurchaseRequestsCount,
        responsePurchaseRequestCount,
        myPurchaseResponsesCount
      }),
    [
      language,
      allPurchaseRequestsCount,
      responsePurchaseRequestCount,
      myPurchaseResponsesCount
    ]
  );

  const navigation = useMemo(
    () => ({ activeKey, items, handleTabChange }),
    [activeKey, items, handleTabChange]
  );

  return <WorkspaceHeader navigation={navigation} />;
};

export default withRouter(
  connect((state) => ({
    language: storeGetters.getCurrentUserLanguage(state),
    allPurchaseRequestsCount: storeGetters.getAllPurchaseRequestsNotify(state),
    responsePurchaseRequestCount:
      storeGetters.getResponsePurchaseRequestNotify(state),
    myPurchaseResponsesCount: storeGetters.getMyPurchaseResponsesNotify(state)
  }))(PurchaseRequestsTabs)
);
