import * as emailMessagesStorage from '../storage';

const verifyEmail = async (code, scope) => {
  try {
    await emailMessagesStorage.verifyEmail({ code, scope });
  } catch (error) {
    console.log(error);
  } finally {
    // Delete query from URL
    window.history.replaceState(null, null, window.location.pathname);
  }
};

export default verifyEmail;
