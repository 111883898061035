import { isNil } from 'ramda';
import {
  draft,
  rework
} from '../../../presenters/sidebars/PurchaseRequestResponsesSidebar/status';
import { PurchaseRequestStatuses } from '../entities/PurchaseRequestStatuses';

export const getRequestStatus = (request) => request.get('status');

export const responseEndDate = (request) => request.get('responseEndDate');

export const isPublished = (request) => Boolean(request.get('isPublished'));

export const responseEndTime = (request) =>
  new Date(responseEndDate(request)).getUTCHours();

export const isEndRespondTime = (request) => {
  const endDate = responseEndDate(request);
  return endDate
    ? new Date() >
        new Date(new Date(endDate).setHours(responseEndTime(request)))
    : false;
};

export const isFromThatCompany = (request, company) =>
  request.get('companyId') === company.get('id');

export const havePermissionToRespond = (
  fullPermissionToCustomers,
  fullPermissionToCustomersOrders
) => fullPermissionToCustomers && fullPermissionToCustomersOrders;

export const hasResponded = (request) =>
  !isNil(request.getIn(['responded', 'status']));

export const getStatusForSupplier = (request) => {
  const status = request.getIn(['responded', 'status']);
  if (!status) return 'none';
  return status === 'sent' ? 'sent-as-supplier' : status;
};

export const isStatusDraft = (request) =>
  [draft, rework].includes(getStatusForSupplier(request));

export const isCanRespond = (request) =>
  (!hasResponded(request) || isStatusDraft(request)) &&
  !isEndRespondTime(request);

export const isShowControlButtons = (
  request,
  havePermissionToUpdate = false
) => {
  if (!havePermissionToUpdate) return false;

  if (
    getRequestStatus(request) === PurchaseRequestStatuses.receptionCompleted
  ) {
    return (
      !isNil(request.getIn(['responded', 'status'])) &&
      request.getIn(['responded', 'status']) !== draft
    );
  }

  return true;
};

export const greaterThanDraft = (request) =>
  !hasResponded(request) || !isStatusDraft(request);

export const getResponseId = (request) => request.getIn(['responded', 'id']);

export const getRespondTextByRequest = (request) =>
  request.getIn(['responded', 'text']) || '';

export const getRespondComment = (request) =>
  request.getIn(['responded', 'comment']) || '';

export const getLinksByRequest = (request) =>
  request.hasIn(['responded', 'links'])
    ? request.getIn(['responded', 'links']).toJS()
    : [];

export const getFilesByRequest = (request) =>
  request.hasIn(['responded', 'files'])
    ? request.getIn(['responded', 'files']).toJS()
    : [];

const prepareInitProducts = (product) => ({ ...product, price: '', count: '' });

export const getInitProductsByRequest = (request) =>
  request.get('products')?.toJS() || [];

export const getProductsByRequest = (request) =>
  request.hasIn(['responded', 'products'])
    ? request.getIn(['responded', 'products']).toJS()
    : getInitProductsByRequest(request).map(prepareInitProducts) || [];

// NOTE: Old relize
// const isCanRespond =
// fullPermissionToCustomers &&
// fullPermissionToCustomersOrders &&
// !isFromThatCompany &&
// (!request.get('responded') ||
//   (request.get('suppliers') && !request.get('suppliers').find(i => i.get('companyId') === company.get('id')))) &&
// !isEndRespondTime(responseEndDate);
