import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from '../hoc/withRouter';
import { map } from '../work-with-data/standard';

import ModalSendOrderToSupplier from '../components/Modal/ModalSendOrderToSupplier';
import * as modalAction from '../action-creators/modal';
import * as ordersAction from '../action-creators/orders';
import * as servicesActions from '../action-creators/services';

const ContModalSendOrderToSupplier = ({
  captionKey,
  hideModalDialog,
  submitOrderToSupplier,
  price,
  order,
  currency,
  gaPageView,
  sum,
  gaSend,
  company,
  clearOrderDraft,
  history,
  splitOrder,
  combineOrdersStocks,
  deliveryDate,
  consignee,
  user
}) => (
  <ModalSendOrderToSupplier
    title={captionKey}
    hideModalDialog={hideModalDialog}
    map={map}
    submit={submitOrderToSupplier}
    onSubmited={() => history.push('/orders-company?sentFirst=true')}
    clearOrderDraft={clearOrderDraft}
    sum={sum}
    price={price}
    order={order}
    currency={currency}
    gaPageView={gaPageView}
    gaSend={gaSend}
    company={company}
    splitOrder={splitOrder}
    combineOrdersStocks={combineOrdersStocks}
    deliveryDate={deliveryDate}
    consignee={consignee}
    user={user}
  />
);

export default withRouter(
  connect(
    (state) => ({
      price: state.getIn(['publicPrices', 'price']),
      order: state.getIn(['orders', 'order']),
      currency: state.getIn(['orders', 'order', 'currency']),
      company: state.get('company'),
      user: state.getIn(['user', 'user']),
      combineOrdersStocks: state.getIn([
        'ordersCombine',
        'orderDraft',
        'combineOrdersStocks'
      ])
    }),
    (dispatch) =>
      bindActionCreators(
        {
          hideModalDialog: modalAction.hideModalDialog,
          submitOrderToSupplier: ordersAction.submitOrderToSupplier,
          clearOrderDraft: ordersAction.clearOrderDraft,
          gaPageView: servicesActions.gaPageView,
          gaSend: servicesActions.gaSend
        },
        dispatch
      )
  )(ContModalSendOrderToSupplier)
);
