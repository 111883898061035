import React, { Component } from 'react';
import PropTypes from 'prop-types';
import block from 'bem-cn-lite';
import { translate } from 'react-i18next';
import { Modal, Form, Button } from 'react-bootstrap';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { map, pipe, prop, sortBy, toLower } from 'ramda';
import { fromJS } from 'immutable';

import SearchBlockDefault from '../SearchBlock/SearchBlockDefault';
import ListOfNoticationCategories from '../ListOfNotificationCategories/ListOfNoticationCategories';

import './styles/modal_theme_default.styl';

import { ModalContainer } from './elements';

import { requestCategories } from '../../storage/purchaseRequest.storage';

import { textMatch } from '../../utils/utils';

import * as modalActions from '../../action-creators/modal';
import * as requestsActions from '../../action-creators/purchaseRequests';

const b = block('modal-container');

class ModalNotificationCategories extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // loaded: false,
      categories: [],
      search: '',
      selectedCategories: [],
      changes: false
    };
    this.setCategories = this.setCategories.bind(this);
    this.selectCategory = this.selectCategory.bind(this);
    this.selectCategories = this.selectCategories.bind(this);
    this.deselectCategory = this.deselectCategory.bind(this);
    this.deselectCategories = this.deselectCategories.bind(this);
    this.selectAllCategories = this.selectAllCategories.bind(this);
    this.deselectAllCategories = this.deselectAllCategories.bind(this);
    this.onSearch = this.onSearch.bind(this);
  }

  getChildContext() {
    return { b };
  }

  UNSAFE_componentWillMount() {
    const { purchaseRequestsNotifyCategories } = this.props;
    requestCategories().then(this.setCategories);
    const selectedCategories = purchaseRequestsNotifyCategories
      ? purchaseRequestsNotifyCategories.toJS()
      : [];
    this.setState({ selectedCategories });
  }

  setCategories(categories) {
    this.setState({ categories });
  }

  getCategories() {
    const { t } = this.props;
    if (this.state.search) {
      return this.state.categories.filter((i) =>
        textMatch(this.state.search, t(`purchaseRequestsCategories.${i.name}`))
      );
    }
    return this.state.categories;
  }

  getDisplayCategories() {
    const { t, selectedCategoriesId } = this.props;

    return pipe(
      map((cat) => ({
        ...cat,
        selected: selectedCategoriesId.has(cat.id),
        name: t(`purchaseRequestsCategories.${cat.name}`)
      })),
      sortBy(pipe(prop('name'), toLower))
    )(this.getCategories());
  }

  selectCategory(id) {
    let cats = this.state.selectedCategories;
    if (cats.includes(id)) {
      cats = cats.filter((sId) => sId !== id);
    } else {
      cats.push(id);
    }
    this.setState({ selectedCategories: cats, changes: true });
  }

  selectCategories(ids) {
    const cats = this.state.selectedCategories;
    ids.forEach((id) => {
      if (!cats.includes(id)) {
        cats.push(id);
      }
    });
    this.setState({ selectedCategories: cats, changes: true });
  }

  deselectCategory(id) {
    const cats = this.state.selectedCategories;
    this.setState({
      selectedCategories: cats.filter((sId) => sId !== id),
      changes: true
    });
  }

  deselectCategories(ids) {
    const cats = this.state.selectedCategories;
    this.setState({
      selectedCategories: cats.filter((sId) => !ids.includes(sId)),
      changes: true
    });
  }

  selectAllCategories() {
    const { categories } = this.state;
    this.setState({
      selectedCategories: categories.map((i) => i.id),
      changes: true
    });
  }

  deselectAllCategories() {
    this.setState({ selectedCategories: [], changes: true });
  }

  onSearch(search) {
    return this.setState({ search });
  }

  render() {
    const {
      t,
      onCancel = () => {},
      setNotifySettings,
      type,
      hideModalDialog,
      onSubmited = () => {}
    } = this.props;

    const categories = fromJS(this.getDisplayCategories());

    return (
      <Modal
        id="notificationCategories"
        onHide={() => hideModalDialog()}
        className="wide-modal"
        show>
        <ModalContainer mods={{ type: type || 'default', theme: 'default' }}>
          <Modal.Header>
            <Modal.Title>
              {t('modal_notify_title')}
              <span
                className="modal-header-cross pull-right"
                onClick={() => {
                  onCancel();
                  hideModalDialog();
                }}
              />
            </Modal.Title>
          </Modal.Header>
          <div
            style={{
              paddingLeft: 42,
              paddingRight: 42,
              backgroundColor: '#fafbfc'
            }}>
            <span>{t('modal_notification_text')}</span>
          </div>
          <Modal.Body>
            <Form>
              <div style={{ marginBottom: 10 }}>
                <SearchBlockDefault
                  placeholder={t('Category name')}
                  onSubmit={this.onSearch}
                  onEmpty={this.onSearch}
                />
              </div>
              {categories.isEmpty() && <div>{t('cats_not_found')}</div>}
              {categories.size > 0 && (
                <ListOfNoticationCategories
                  list={categories}
                  selectedCategories={this.state.selectedCategories}
                  selectCategory={this.selectCategory}
                  selectCategories={this.selectCategories}
                  deselectCategory={this.deselectCategory}
                  deselectCategories={this.deselectCategories}
                  selectAllCategories={this.selectAllCategories}
                  deselectAllCategories={this.deselectAllCategories}
                />
              )}
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button
              id="cancel"
              className="pull-left"
              disabled={!this.state.changes}
              onClick={() => {
                onCancel();
                hideModalDialog();
              }}>
              {t('Cancel')}
            </Button>
            <Button
              id="saveButton"
              bsStyle="primary"
              disabled={!this.state.changes}
              onClick={async () => {
                await setNotifySettings(this.state.selectedCategories);
                hideModalDialog();
                onSubmited();
              }}>
              {t('Choose')}
            </Button>
          </Modal.Footer>
        </ModalContainer>
      </Modal>
    );
  }
}

ModalNotificationCategories.propTypes = {
  t: PropTypes.func.isRequired
};

ModalNotificationCategories.childContextTypes = {
  b: PropTypes.func
};

export default connect(
  (state) => ({
    user: state.getIn(['user', 'user']),
    purchaseRequestsNotifyCategories: state.getIn([
      'user',
      'user',
      'purchaseRequestsNotifyCategories'
    ]),
    selectedCategoriesId: state.getIn([
      'purchaseRequests',
      'selectedCategoriesId'
    ])
  }),
  (dispatch) =>
    bindActionCreators(
      {
        hideModalDialog: modalActions.hideModalDialog,
        setNotifySettings: requestsActions.setNotifySettings
      },
      dispatch
    )
)(translate(['ui'], { wait: true })(ModalNotificationCategories));
