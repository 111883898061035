import { useDispatch } from 'react-redux';
import { redirectCases } from './useCases';

const RedirectPage = (props) => {
  const dispatch = useDispatch();
  dispatch(redirectCases(props));

  return null;
};

export default RedirectPage;
