import { Map } from 'immutable';
import { reducer as formReducer } from 'redux-form/immutable';
import accessReducer from './access';
import tablesReducer from './tables';
import userReducer from './user';
import catalogReducer from './catalog';
import catalogCombine from './catalog/index';
import categoryReducer from './category';
import productReducer from './product';
import validatorReducer from './validator';
import modalReducer from './modal';
import messageReducer from './message';
import languageListReducer from './languageList';
import currencyListReducer from './currencyList';
import importReducer from './import';
import browserReducer from './browser';
import companyReducer from './company';
import stocksReducer from './stocks';
import customersReducer from './customers';
import pricesReducer from './prices';
import pricesCombine from './prices/index';
import productPositionsReducer from './product-positions';
import priceSuppliers from './prices-suppliers';
import publicPrices from './public-prices';
import ordersReducer from './orders';
import ordersCombine from './orders/index.js';
import listsReducers from './lists';
import companyCombine from './company/info';
import whitelistReducer from './whitelist';
import companyBaidges from './company/baidges';
import suppliersReducer from './suppliers';
import chatReducer from './chat';
import badgeReducer from './badge';
import tablesReducers from './tablesNew';
import unitReducer from './units';
import serviceReducer from './service';
import storageReducer from './storage';
import adminReducer from './admin';
import analyticsReducer from './analytics';
import requestsReducer from './purchaseRequests';
import userConfigs from './userConfigs';
import loaderReducer from './loaderList';
import filterOptions from './filterOptions';
import userAccessRoles from './userAccessRoles';
import channelReducer from './channel';
import contactsReducer from './contacts';
import temporaryReducer from './temporary';
import partnersReducer from './partners';
import visualReducers from '../modules/visual/reducers';
import onlineReducers from '../modules/online/reducers';
import dialogLists from './dialogLists';
import topicReducers from '../modules/topic/reducers';
import chatWidgetReducers from '../components/ChatWidget/reducers';
import verifyEmail from './verifyEmail';
import resetPassword from './resetPassword';
import invitesReducer from './invites';
import connectionsReducer from './connections';
import errorsReducer from './errors';
import purchaseRequestResponseReducer from '../components/PurchaseRequests/Responses/reducer';
import myResponses from '../components/PurchaseRequests/Responses/MyResponses/reducer';
import myPurchaseRequestReducer from '../components/PurchaseRequests/MyRequests/reducer';
import dictionariesReducer from '../modules/dictionaries/slice';
import taskManagerReducer from '../tasks-manager/reducers/task';
import emailMessagesReducer from '../components/ChatWidget/modules/emailMessages/redux';
import externalAPIReducer from '../components/ChatWidget/modules/externalAPI/redux';

export default (state = Map(), action) =>
  state
    .update('access', (access) => accessReducer(access, action))
    .update('user', (user) => userReducer(user, action))
    .update('stocks', (stocks) => stocksReducer(stocks, action))
    .update('tables', (tablesState) => tablesReducer(tablesState, action))
    .update('catalog', (catalogState) => catalogReducer(catalogState, action))
    .update('catalogCombine', (catalogState) =>
      catalogCombine(catalogState, action)
    )
    .update('category', (categoryState) =>
      categoryReducer(categoryState, action)
    )
    .update('product', (productState) => productReducer(productState, action))
    .update('validator', (validatorState) =>
      validatorReducer(validatorState, action)
    )
    .update('modal', (modalState) => modalReducer(modalState, action))
    .update('message', (messageState) => messageReducer(messageState, action))
    .update('languageList', (languageList) =>
      languageListReducer(languageList, action)
    )
    .update('currencyList', (currencyList) =>
      currencyListReducer(currencyList, action)
    )
    .update('import', (importState) => importReducer(importState, action))
    .update('browserProps', (browserState) =>
      browserReducer(browserState, action)
    )
    .update('company', (companyState) => companyReducer(companyState, action))
    .update('companiesInfo', (companyState) =>
      companyCombine(companyState, action)
    )
    .update('companyBaidges', (companyState) =>
      companyBaidges(companyState, action)
    )
    .update('customers', (customerState) =>
      customersReducer(customerState, action)
    )
    .update('prices', (pricesState) => pricesReducer(pricesState, action))
    .update('pricesCombine', (pricesState) =>
      pricesCombine(pricesState, action)
    )
    .update('pricesSuppliers', (pricesState) =>
      priceSuppliers(pricesState, action)
    )
    .update('productPositions', (productPositions) =>
      productPositionsReducer(productPositions, action)
    )
    .update('publicPrices', (publicPricesState) =>
      publicPrices(publicPricesState, action)
    )
    .update('orders', (orders) => ordersReducer(orders, action))
    .update('ordersCombine', (orders) => ordersCombine(orders, action))
    .update('lists', (lists) => listsReducers(lists, action))
    .update('userConfigs', (configs) => userConfigs(configs, action))
    .update('whitelist', (whitelist) => whitelistReducer(whitelist, action))
    .update('suppliers', (services) => suppliersReducer(services, action))
    .update('chat', (services) => chatReducer(services, action))
    .update('badges', (services) => badgeReducer(services, action))
    .update('tablesNew', (states) => tablesReducers(states, action))
    .update('unit', (states) => unitReducer(states, action))
    .update('service', (states) => serviceReducer(states, action))
    .update('storage', (states) => storageReducer(states, action))
    .update('admin', (states) => adminReducer(states, action))
    .update('analytics', (states) => analyticsReducer(states, action))
    .update('purchaseRequests', (states) => requestsReducer(states, action))
    .update('purchaseRequestResponses', (states) =>
      purchaseRequestResponseReducer(states, action)
    )
    .update('myResponses', (states) => myResponses(states, action))
    .update('myPurchaseRequests', (states) =>
      myPurchaseRequestReducer(states, action)
    )
    .update('loaderList', (states) => loaderReducer(states, action))
    .update('userAccessRoles', (states) => userAccessRoles(states, action))
    .update('channel', (states) => channelReducer(states, action))
    .update('filterOptions', (states) => filterOptions(states, action))
    .update('temporary', (states) => temporaryReducer(states, action))
    .update('contacts', (states) => contactsReducer(states, action))
    .update('partners', (states) => partnersReducer(states, action))
    .update('visual', (states) => visualReducers(states, action))
    .update('online', (states) => onlineReducers(states, action))
    .update('topicStore', (states) => topicReducers(states, action))
    .update('chat-widget', (states) => chatWidgetReducers(states, action))
    .update('dialogLists', (states) => dialogLists(states, action))
    .update('form', (states) => formReducer(states, action))
    .update('verifyEmail', (states) => verifyEmail(states, action))
    .update('resetPassword', (states) => resetPassword(states, action))
    .update('invite', (states) => invitesReducer(states, action))
    .update('connections', (states) => connectionsReducer(states, action))
    .update('dictionaries', (states) => dictionariesReducer(states, action))
    .update('task-manager', (states) => taskManagerReducer(states, action))
    .update('emailMessages', (states) => emailMessagesReducer(states, action))
    .update('externalAPI', (states) => externalAPIReducer(states, action))
    .update('errors', (states) => errorsReducer(states, action));
