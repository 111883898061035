import React from 'react';
import { translate } from 'react-i18next';
import block from 'bem-cn-lite';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Typography } from '@link/react-components';
import { format } from '../../../../lib/date';

const b = block('cr-info');

function WithTooltip({ id, tooltip, children }) {
  return (
    <OverlayTrigger
      overlay={
        <Tooltip id={id} positionLeft={0}>
          {tooltip}
        </Tooltip>
      }
      placement="left"
      delayShow={300}
      delayHide={100}>
      {children}
    </OverlayTrigger>
  );
}

const Info = ({ t, request }) => {
  const sum = new Intl.NumberFormat('ru-RU', {
    style: 'currency',
    currency: 'RUB',
    maximumFractionDigits: 0
  }).format(request.sum || 0);

  return (
    <div className={b()}>
      <div>
        <Typography className={b('label')} variant="body2Reg">
          {t('sum_request')}
        </Typography>
        <WithTooltip id={request.id} tooltip={sum}>
          <div className={b('value', { big: true })}>{sum}</div>
        </WithTooltip>
      </div>
      <div>
        <div className={b('dates')}>
          <Typography className={b('label')} variant="body2Reg">{`${t(
            'Publication date'
          )}:`}</Typography>
          <Typography className={b('value')} variant="body2Reg">
            {format(request.lastPublishedDate, 'dd.MM.yyyy')}
          </Typography>
        </div>
        <div className={b('dates')}>
          <Typography className={b('label')} variant="body2Reg">{`${t(
            'date_responses_end_card'
          )}:`}</Typography>
          <Typography className={b('value')} variant="body2Reg">
            {format(request.responseEndDate, 'dd.MM.yyyy')}
          </Typography>
        </div>
      </div>
    </div>
  );
};

export default translate()(Info);
