import React, { Component } from 'react';
import PropTypes from 'prop-types';
// import block from 'bem-cn-lite';
import { translate } from 'react-i18next';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Modal, Button, Row } from 'react-bootstrap';

import Checkbox from '../Checkbox';
import { ModalContainer } from './elements';

import './styles/modal_theme_default.styl';

import * as modalActions from '../../action-creators/modal';
import * as billingActions from '../../action-creators/billing';

// const b = block('modal-container');

class ModalChangeTariffAdditions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      period: ''
    };
  }

  render() {
    const {
      t,
      hideModalDialog,
      billing,
      tariff,
      additions,
      setTariff,
      additionsList,
      getBlockingInfo,
      viewBlockingPage,
      showPayment,
      sum
    } = this.props;

    const haveDecrementChanges = additionsList.reduce((acc, add) => {
      let flag = acc;
      const addition = additions.find((i) => i.name === add);
      if (
        ((addition && addition.count) || 0) <
        (billing.getIn(['additions', add]) || 0)
      ) {
        flag = true;
      }
      return flag;
    }, false);

    const daysToEnd = +(
      (new Date(billing.get('endDate')) - new Date()) /
      (1000 * 60 * 60 * 24)
    ).toFixed();

    return (
      <Modal id="changeTariff" onHide={() => hideModalDialog()} show>
        <ModalContainer mods={{ theme: 'default' }}>
          <Modal.Header>
            <Modal.Title>
              {t('To plug in additions')}
              <span
                className="modal-header-cross pull-right"
                onClick={() => hideModalDialog()}
              />
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div style={{ paddingLeft: 10, paddingRight: 10 }}>
              <Row style={{ marginBottom: 10 }}>
                {t('change_tariff_additions_text')}
              </Row>
              {(!haveDecrementChanges || daysToEnd <= 0) && (
                <Row>
                  <Checkbox
                    set={this.state.period === 'current'}
                    onClick={() => this.setState({ period: 'current' })}
                  />
                  <span>{t('curr_per_option_additons')}</span>
                </Row>
              )}
              {daysToEnd > 0 && (
                <Row>
                  <Checkbox
                    set={this.state.period === 'next'}
                    onClick={() => this.setState({ period: 'next' })}
                  />
                  <span>{t('next_per_option_additons')}</span>
                </Row>
              )}
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              id="cancel"
              className="pull-left"
              onClick={() => {
                hideModalDialog();
              }}>
              {t('Cancel')}
            </Button>
            <Button
              id="copyProductButton"
              bsStyle="primary"
              disabled={!this.state.period}
              onClick={async () => {
                if (this.state.period === 'current') {
                  const blockingInfo = await getBlockingInfo(tariff, additions);
                  if (!blockingInfo.options.length && !blockingInfo.storage) {
                    if (sum === 0) {
                      setTariff(tariff, additions, this.state.period);
                      hideModalDialog();
                    } else {
                      showPayment(sum, ({ name, email }) =>
                        setTariff(tariff, additions, this.state.period, null, {
                          name,
                          email
                        })
                      );
                    }
                  } else {
                    viewBlockingPage();
                    hideModalDialog();
                  }
                } else {
                  setTariff(tariff, additions, this.state.period);
                  hideModalDialog();
                }
              }}>
              {t('To plug')}
            </Button>
          </Modal.Footer>
        </ModalContainer>
      </Modal>
    );
  }
}

ModalChangeTariffAdditions.propTypes = {
  t: PropTypes.func.isRequired
};

ModalChangeTariffAdditions.childContextTypes = {
  b: PropTypes.func
};

export default connect(
  (state) => ({
    billing: state.getIn(['company', 'billing'])
  }),
  (dispatch) =>
    bindActionCreators(
      {
        hideModalDialog: modalActions.hideModalDialog,
        setTariff: billingActions.setTariff,
        getBlockingInfo: billingActions.getBlockingInfo
      },
      dispatch
    )
)(translate(['ui'], { wait: true })(ModalChangeTariffAdditions));
