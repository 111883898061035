import React from 'react';
import { translate } from 'react-i18next';

const CWMSearchNotFound = ({ t }) => (
  <div className="cwm-search-nfm">
    <img
      className="cwm-search-nfm__icon"
      src="/img/chat-widget/message.svg"
      alt="not found messages"
    />
    <div className="cwm-search-nfm__msg">
      {t('chat-widget.section.search-mode.not-found-messages')}
    </div>
  </div>
);

export default translate()(CWMSearchNotFound);
