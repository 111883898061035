import React, { useState, useCallback, useEffect } from 'react';

import { FormControl } from 'react-bootstrap';
import { debounce } from '../../utils/debounce';
import { getUsrleInfo } from '../../storage/company.storage';
import { useTranslate } from '../../TranslateProvider';
import PopupSmall from '../PopupSmall';
import './styles/forms_theme_default.styl';
import Loader from '../Loader';

const Option = ({ company, t, handleClick }) => {
  const { shortName, kpp, inn, legalAddress } = company;
  return (
    <div
      className="usrle-select-option"
      key={inn}
      onClick={handleClick(company)}>
      <div style={{ fontWeight: 500 }}>{shortName}</div>
      <div style={{ fontSize: 12 }}>{legalAddress}</div>
      <div>{`${t('inn')}: ${inn} ${t('kpp')}: ${kpp || '-'}`}</div>
    </div>
  );
};

const Content = ({ t, suggestions, handleClick, isLoading }) => {
  if (isLoading) {
    return (
      <div className="usrle-select-popup">
        <Loader />
      </div>
    );
  }
  if (suggestions.length === 0) {
    return (
      <div className="usrle-select-option">
        {t('Requests filters empty text')}
      </div>
    );
  }

  return (
    <div className="usrle-select-popup">
      {suggestions.map((company) => (
        <Option
          key={company.inn}
          t={t}
          handleClick={handleClick}
          company={company}
        />
      ))}
    </div>
  );
};

const FormUsrle = ({
  setInfo,
  disabled,
  defaultValue = '',
  onFocus,
  onBlur
}) => {
  const t = useTranslate();
  const [isOpened, setIsOpened] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [suggestions, setSuggestions] = useState([]);
  const [searchValue, setSearchValue] = useState(defaultValue);

  useEffect(() => {
    setSearchValue(defaultValue);
  }, [defaultValue]);

  const searchInfo = async (value) => {
    const res = await getUsrleInfo(value);
    setSuggestions(res);
    setIsLoading(false);
  };

  const debouncedSearch = useCallback(debounce(searchInfo, 500), []);

  const onInputChange = ({ target: { value } }) => {
    setInfo({});
    setSearchValue(value);

    if (value.length > 0) {
      setIsLoading(true);
      setIsOpened(true);
      debouncedSearch(value);
    } else {
      setSuggestions([]);
      setIsOpened(false);
    }
  };

  const handleToggleOpen = () =>
    searchValue.length > 0 ? setIsOpened(true) : {};

  const handleClick = (company) => async () => {
    setInfo(company);
    setSearchValue(company.shortName);
    setIsOpened(false);
  };

  return (
    <div>
      <FormControl
        disabled={disabled}
        placeholder={t('enter_company_info')}
        value={searchValue}
        type="text"
        onChange={onInputChange}
        onClick={handleToggleOpen}
        onFocus={onFocus}
        onBlur={onBlur}
      />
      <PopupSmall
        top={5}
        setIsOpened={setIsOpened}
        isOpened={isOpened}
        content={
          <Content
            isLoading={isLoading}
            suggestions={suggestions}
            t={t}
            handleClick={handleClick}
          />
        }
      />
    </div>
  );
};

export default FormUsrle;
