import React from 'react';
import { Routes, Route } from 'react-router-dom';
import TasksList from './pages/TasksList';

const TaskRoutes = () => (
  <div style={{ width: '100%', height: '100%' }}>
    <Routes>
      <Route path="list" element={<TasksList />} />
    </Routes>
  </div>
);

export default TaskRoutes;
