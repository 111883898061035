import { Map, List, fromJS } from 'immutable';

const initState = Map({
  offset: 0,
  size: 0,
  limit: 20,
  positions: List(),
  currency: null
});

const setPublicPricePositionsCategory = (
  data,
  { productPositions, offset, size, limit, currency }
) =>
  data
    .set('offset', Number(offset))
    .set('size', Number(size))
    .set('limit', Number(limit))
    .set('positions', fromJS(productPositions))
    .set('currency', currency);

const publicPricesProducts = (state = initState, { type, payload }) => {
  switch (type) {
    case 'PUBLIC_PRICE:GET_PUBLIC_CATEGORY_POSITIONS:SUCCESS':
      return setPublicPricePositionsCategory(state, payload);
    default:
      return state;
  }
};

export default publicPricesProducts;
