import React, { Component } from 'react';
import PropTypes from 'prop-types';
// import block from 'bem-cn-lite';
import { translate } from 'react-i18next';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  Modal,
  FormGroup,
  FormControl,
  Button,
  ControlLabel,
  Col
} from 'react-bootstrap';
import './styles/modal_theme_default.styl';

import { ModalContainer } from './elements';
import * as orderActions from '../../action-creators/orders';
import * as modalActions from '../../action-creators/modal';
import * as languageListActionCreators from '../../action-creators/languageList';
import * as servicesActions from '../../action-creators/services';

class ModalCopyProduct extends Component {
  constructor(props) {
    super(props);
    this.state = {
      language: ''
    };
  }

  UNSAFE_componentWillMount() {
    const { getLanguageList, lang } = this.props;
    getLanguageList();
    this.setState({ language: lang });
  }

  render() {
    const {
      t,
      hideModalDialog,
      languageList,
      products,
      copyProducts,
      companyName,
      selectedPrice,
      orderId,
      gaSend,
      user
    } = this.props;

    return (
      <Modal id="inviteEmployee" onHide={() => hideModalDialog()} show>
        <ModalContainer mods={{ theme: 'default' }}>
          <Modal.Header>
            <Modal.Title>
              {t('copy_product_title')}
              <span
                className="modal-header-cross pull-right"
                onClick={() => hideModalDialog()}
              />
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Col md={12}>
              <div className="">{t('copy_product_text')}</div>
            </Col>
            <Col style={{ marginTop: 10 }} md={6}>
              <FormGroup controlId="langs">
                <ControlLabel>{t('Language')}</ControlLabel>
                <FormControl
                  componentClass="select"
                  value={this.state.language}
                  defaultValue={this.state.language}
                  onChange={(e) => this.setState({ language: e.target.value })}>
                  {languageList.map((lang) => (
                    <option key={lang.get('code')} value={lang.get('code')}>
                      {t(lang.get('view'))}
                    </option>
                  ))}
                </FormControl>
              </FormGroup>
            </Col>
          </Modal.Body>
          <Modal.Footer>
            <Button
              id="cancel"
              className="pull-left"
              onClick={() => {
                hideModalDialog();
              }}>
              {t('Cancel')}
            </Button>
            <Button
              id="copyProductButton"
              bsStyle="primary"
              onClick={async () => {
                await gaSend({
                  category: 'Customer',
                  action: 'keep_yourself_success',
                  label: user.get('email')
                });
                hideModalDialog();
                await copyProducts(
                  products,
                  this.state.language,
                  companyName,
                  selectedPrice,
                  orderId
                );
              }}>
              {t('Add')}
            </Button>
          </Modal.Footer>
        </ModalContainer>
      </Modal>
    );
  }
}

ModalCopyProduct.propTypes = {
  t: PropTypes.func.isRequired
};

ModalCopyProduct.childContextTypes = {
  b: PropTypes.func
};

export default connect(
  (state) => ({
    company: state.get('company'),
    languageList: state.get('languageList'),
    user: state.getIn(['user', 'user'])
  }),
  (dispatch) =>
    bindActionCreators(
      {
        hideModalDialog: modalActions.hideModalDialog,
        getLanguageList: languageListActionCreators.getLanguageList,
        copyProducts: orderActions.copyProducts,
        gaSend: servicesActions.gaSend
      },
      dispatch
    )
)(translate(['ui'], { wait: true })(ModalCopyProduct));
