import fetch from 'isomorphic-fetch';
import { getSortedCategories } from './category';

export const createAdditionalCategory =
  (name, desc) => (dispatch, getState) => {
    const price = getState().getIn(['prices', 'price']);
    dispatch({ type: 'PRICE:CREATE_ADDITIONAL_CATEGORY:REQUEST' });

    return fetch(`/api/prices/${price.get('id')}/additional-categories`, {
      credentials: 'include',
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ name, desc })
    })
      .then((res) =>
        res.json().then((json) =>
          // eslint-disable-next-line prefer-promise-reject-errors
          res.status < 400
            ? Promise.resolve(json)
            : Promise.reject({ errors: json.errors, status: res.status })
        )
      )
      .then((json) => json)
      .then((category) => {
        dispatch({
          type: 'PRICE:CREATE_ADDITIONAL_CATEGORY:SUCCESS',
          payload: { category }
        });
        dispatch(getSortedCategories(price.get('id')));
        return Promise.resolve();
      })
      .catch((e) => {
        dispatch({
          type: 'PRICE:CREATE_ADDITIONAL_CATEGORY:FAILURE',
          payload: { errors: e }
        });
        return Promise.reject(e.errors);
      });
  };

export const updateAdditionalCategory =
  (categoryId, name, desc) => (dispatch, getState) => {
    const price = getState().getIn(['prices', 'price']);
    dispatch({ type: 'PRICE:UPDATE_ADDITIONAL_CATEGORY:REQUEST' });

    return fetch(
      `/api/prices/${price.get('id')}/additional-categories/${categoryId}`,
      {
        credentials: 'include',
        method: 'PATCH',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ name, desc })
      }
    )
      .then((res) =>
        res.json().then((json) =>
          // eslint-disable-next-line prefer-promise-reject-errors
          res.status < 400
            ? Promise.resolve(json)
            : Promise.reject({ errors: json.errors, status: res.status })
        )
      )
      .then((json) => json)
      .then((updatedCategory) => {
        dispatch({
          type: 'PRICE:UPDATE_ADDITIONAL_CATEGORY:SUCCESS',
          payload: { updatedCategory }
        });
        return Promise.resolve();
      })
      .catch((e) => {
        dispatch({
          type: 'PRICE:UPDATE_ADDITIONAL_CATEGORY:FAILURE',
          payload: { errors: e }
        });
        return Promise.reject(e.errors);
      });
  };

export const deleteAdditionalCategory =
  (categoryId) => (dispatch, getState) => {
    const price = getState().getIn(['prices', 'price']);
    dispatch({ type: 'PRICE:DELETE_ADDITIONAL_CATEGORY:REQUEST' });

    return fetch(
      `/api/prices/${price.get('id')}/additional-categories/${categoryId}`,
      {
        credentials: 'include',
        method: 'DELETE',
        headers: { 'Content-Type': 'application/json' }
      }
    )
      .then((res) =>
        res.json().then((json) =>
          // eslint-disable-next-line prefer-promise-reject-errors
          res.status < 400
            ? Promise.resolve(json)
            : Promise.reject({ errors: json.errors, status: res.status })
        )
      )
      .then((json) => json)
      .then((id) => {
        dispatch({
          type: 'PRICE:DELETE_ADDITIONAL_CATEGORY:SUCCESS',
          payload: { id }
        });
        dispatch(getSortedCategories(price.get('id')));
        return Promise.resolve();
      })
      .catch((e) => {
        dispatch({
          type: 'PRICE:DELETE_ADDITIONAL_CATEGORY:FAILURE',
          payload: { errors: e }
        });
        return Promise.reject(e.errors);
      });
  };

export const checkAdditionalCategoryNameUniq =
  (name) => (dispatch, getState) => {
    const price = getState().getIn(['prices', 'price']);
    dispatch({ type: 'PRICE:ADDITIONAL_CATEGORY_CHECK_NAME:REQUEST' });

    return fetch(
      `/api/prices/${price.get('id')}/additional-categories/uniq-name`,
      {
        credentials: 'include',
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ name })
      }
    )
      .then((res) =>
        res.json().then((json) =>
          // eslint-disable-next-line prefer-promise-reject-errors
          res.status < 400
            ? Promise.resolve(json)
            : Promise.reject({ errors: json.errors, status: res.status })
        )
      )
      .then((json) => {
        dispatch({ type: 'PRICE:ADDITIONAL_CATEGORY_CHECK_NAME:UNIQ' });
        return json;
      })
      .catch((e) => {
        console.error('CREATE CATEGORY FAILED', e);
        dispatch({
          type: 'PRICE:ADDITIONAL_CATEGORY_CHECK_NAME:FAILURE',
          payload: { errors: e }
        });
        return Promise.reject(e.errors);
      });
  };

export const getCategories = (priceId) => (dispatch, getState) => {
  const price = getState().getIn(['prices', 'price']);
  dispatch({ type: 'PRICE:GET_ADDITIONAL_CATEGORIES:REQUEST' });

  return fetch(
    `/api/prices/${priceId || price.get('id')}/additional-categories`,
    {
      credentials: 'include',
      method: 'GET'
    }
  )
    .then((res) => res.json())
    .then((categories) => {
      dispatch({
        type: 'PRICE:GET_ADDITIONAL_CATEGORIES:SUCCESS',
        payload: categories
      });
      return Promise.resolve(categories);
    })
    .catch((err) => {
      console.error(err);
      dispatch({
        type: 'PRICE:GET_ADDITIONAL_CATEGORIES:FAILURE',
        payload: { errors: err }
      });
    });
};

export const addProducts = (categoryId, priceId) => (dispatch, getState) => {
  const state = getState();
  const price = state.getIn(['prices', 'price']);
  const list = state.getIn(['tablesNew', 'priceProducts', 'selectedList']);
  dispatch({ type: 'PRICE:ADD_PRODUCTS_TO_ADDITIONAL_CATEGORIES:REQUEST' });

  return fetch(
    `/api/prices/${
      priceId || price.get('id')
    }/additional-categories/${categoryId}/products/add`,
    {
      credentials: 'include',
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ list })
    }
  )
    .then((res) => res.json())
    .then((products) => {
      dispatch({
        type: 'PRICE:ADD_PRODUCTS_TO_ADDITIONAL_CATEGORIES:SUCCESS',
        payload: { products, categoryId }
      });
      return Promise.resolve(products);
    })
    .catch((err) => {
      console.error(err);
      dispatch({
        type: 'PRICE:ADD_PRODUCTS_TO_ADDITIONAL_CATEGORIES:FAILURE',
        payload: { errors: err }
      });
    });
};

export const deleteProducts = (categoryId, priceId) => (dispatch, getState) => {
  const state = getState();
  const price = state.getIn(['prices', 'price']);
  const list = state.getIn(['tablesNew', 'priceProducts', 'selectedList']);
  dispatch({ type: 'PRICE:REMOVE_PRODUCTS_TO_ADDITIONAL_CATEGORIES:REQUEST' });

  return fetch(
    `/api/prices/${
      priceId || price.get('id')
    }/additional-categories/${categoryId}/products/delete`,
    {
      credentials: 'include',
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ list })
    }
  )
    .then((res) => res.json())
    .then((products) => {
      dispatch({
        type: 'PRICE:REMOVE_PRODUCTS_TO_ADDITIONAL_CATEGORIES:SUCCESS',
        payload: { products, categoryId }
      });
      return Promise.resolve(products);
    })
    .catch((err) => {
      console.error(err);
      dispatch({
        type: 'PRICE:REMOVE_PRODUCTS_TO_ADDITIONAL_CATEGORIES:FAILURE',
        payload: { errors: err }
      });
    });
};
