import React from 'react';

/* eslint-disable max-len */
const MessengerWidgetCross = () => (
  <svg
    width="13"
    height="13"
    viewBox="0 0 13 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.146447 0.146447C0.341709 -0.0488155 0.658291 -0.0488155 0.853553 0.146447L6.5 5.79289L12.1464 0.146447C12.3417 -0.0488155 12.6583 -0.0488155 12.8536 0.146447C13.0488 0.341709 13.0488 0.658291 12.8536 0.853553L7.20711 6.5L12.8536 12.1464C13.0488 12.3417 13.0488 12.6583 12.8536 12.8536C12.6583 13.0488 12.3417 13.0488 12.1464 12.8536L6.5 7.20711L0.853553 12.8536C0.658291 13.0488 0.341709 13.0488 0.146447 12.8536C-0.0488155 12.6583 -0.0488155 12.3417 0.146447 12.1464L5.79289 6.5L0.146447 0.853553C-0.0488155 0.658291 -0.0488155 0.341709 0.146447 0.146447Z"
      fill="#767B92"
    />
  </svg>
);
/* eslint-enable max-len */

export default MessengerWidgetCross;
