import React from 'react';
import cn from 'classnames';
import Text from 'react-components/src/components/Typography';
import styles from './Badge.module.css';

export const Badge = ({ badgeType, text, className }) => (
  <div className={cn(styles.badge, styles[badgeType], className)}>
    <Text variant="body2Med">{text}</Text>
  </div>
);
