/* eslint-disable max-len */
import React from 'react';

const ExitIcon = () => (
  <svg
    width="18"
    height="19"
    viewBox="0 0 18 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      className="snm-icon__path"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1 3.5C1 1.84315 2.34314 0.5 4 0.5H11C11 0.5 11 0.937903 11 2.5H3V16.5H11V18.5H4C2.34315 18.5 1 17.1569 1 15.5V3.5Z"
      fill="#C6CAE3"
    />
    <path className="snm-icon__path" d="M7 7.5H12V11.5H7V7.5Z" fill="#C6CAE3" />
    <path
      className="snm-icon__path"
      d="M17 9.95455L11 14.5C11 14.5 11 8.05022 11 4.5C13.3431 6.27511 17 9.95455 17 9.95455Z"
      fill="#C6CAE3"
    />
  </svg>
);

export default ExitIcon;
