export class PurchaseRequestDTO {
  constructor(props) {
    this.id = props.id;
    this.type = props.type;
    this.date = props.date || null;
    this.routeId = props.routeId;
    this.route = props.route;
    this.categoriesId = props.categoriesId || [];
    this.country = props.country;
    this.region = props.region;
    this.city = props.city;
    this.productsList = props.productsList;
    this.products = props.products;
    this.partResponse = props.partResponse;
    this.supplierRequirements = props.supplierRequirements;
    this.attachments = props.attachments;
    this.companyId = props.companyId;
    this.responsibleUser = props.responsibleUser;
    this.responsibleUserInfo = props.responsibleUserInfo;
    this.sum = props.sum || null;
    this.responseEndDate = props.responseEndDate || null;
    this.currency = props.currency;
    this.links = props.links;
    this.title = props.title;
    this.number = props.number;
    this.accessCompanyList = props.accessCompanyList || [];
    this.suppliers = props.suppliers || [];
    this.lastPublishedDate = props.lastPublishedDate;
    this.status = props.status;
    this.bookmarkedBy = props.bookmarkedBy;
    this.createdAt = props.createdAt;
    this.isArchived = props.isArchived;
    this.responses = props.responses;
    this.viewedBy = props.viewedBy;
    this.updatedAt = props.updatedAt;
    this.authorizedCompanies = props.authorizedCompanies;
    this.authorId = props.authorId;
    this.executors = props.executors || [];
    this.executorType = props.executorType || null;
    this.isDynamic = props.isDynamic || false;
    this.authorInfo = props.authorInfo || null;
    this.type = props.type || null;
  }
}
