.button {
  cursor: pointer;
  border: none;
  background-color: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  margin: 0;
}

.large {
  min-width: 32px;
  max-width: 32px;
  min-height: 32px;
  max-height: 32px;
}

.medium {
  min-width: 24px;
  max-width: 24px;
  min-height: 24px;
  max-height: 24px;
}

.small {
  min-width: 18px;
  max-width: 18px;
  min-height: 18px;
  max-height: 18px;
}

.large:hover,
.medium:hover {
  background: rgb(17 22 147 / 6%);
  border-radius: 4px;
}

.disabled {
  cursor: none;
  stroke: var(--color-gray-600);

  &:hover {
    cursor: default;
    background-color: transparent;
  }

  > svg {
    > path {
      stroke: var(--color-gray-600);
    }
  }
}

.small:hover > svg {
  stroke: var(--color-gray-700);

  > path {
    stroke: var(--color-gray-700);
  }
}
