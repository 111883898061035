import React from 'react';
import { Alert } from 'react-bootstrap';
import { translate } from 'react-i18next';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import styled from 'styled-components';
import { withRouter } from '../hoc/withRouter';
import { isMessengerService } from '../lib/goodwix';
import * as messageActionCreators from '../action-creators/message';
import * as visualData from '../modules/visual/getter';
import Icon from '../icons';
import { getNavSidebarWidth } from './MessengerNavSidebar/getters';

const Message = translate(['ui'], { wait: true })(
  class extends React.Component {
    constructor(props) {
      super(props);
      this.onLinkClick = this.onLinkClick.bind(this);
      this.onClose = this.onClose.bind(this);
    }

    onLinkClick() {
      const { t, message, setMessage, history } = this.props;
      const messageLinkUrl = message.get('body').linkUrl;
      const { linkClickHandler } = message.get('body');
      setMessage(null);
      if (messageLinkUrl) {
        history.push(messageLinkUrl);
      }
      if (linkClickHandler) {
        linkClickHandler(t);
      }
    }

    onClose() {
      const { message, setMessage } = this.props;
      const { onCloseCallback } = message.get('body');
      if (onCloseCallback) {
        onCloseCallback();
      } else setMessage(null);
    }

    getLeftPos() {
      const { isStyleFull, allWidth, messengerNavSidebarWidth } = this.props;
      if (allWidth) return 0;
      if (isMessengerService()) return messengerNavSidebarWidth;
      return isStyleFull ? 230 : 48;
    }

    render() {
      const { t, message } = this.props;

      const alertStyle = {
        position: 'fixed',
        left: this.getLeftPos(),
        right: 0,
        bottom: 0,
        zIndex: 1000,
        height: 42
      };
      let messageText = null;
      let messageLinkText = null;
      let params = null;
      if (message.has('type') && message.get('body')) {
        messageText = message.get('body').key;
        messageLinkText = message.get('body').linkKey;
        params = message.get('body').params;
      }
      const dismissible = Boolean(message.get('dismissible'));
      if (messageText) {
        return (
          <div style={alertStyle} id="alertMessage">
            <Alert
              bsStyle={message.get('type')}
              onDismiss={dismissible ? this.onClose : undefined}
              /* eslint-disable-next-line max-len */
              closeLabel={`<img role="presentation" alt="close button" className="msg-icon" src="/img/cross_old.svg" />`}>
              <IconWrapper>
                <Icon name={message.get('type')} />
              </IconWrapper>
              <div className="alert-text">{t(messageText, params)}</div>
              {messageLinkText ? (
                <button
                  type="button"
                  className="link link-alert"
                  id="messageLink"
                  style={{ cursor: 'pointer', marginLeft: 5 }}
                  onClick={this.onLinkClick}>
                  {t(messageLinkText)}
                </button>
              ) : null}
            </Alert>
          </div>
        );
      }

      return null;
    }
  }
);

const IconWrapper = styled.div`
  float: left;
  padding-right: 10px;
`;

export default withRouter(
  connect(
    (state) => ({
      message: state.get('message'),
      isStyleFull: visualData.isStyleFull(state),
      messengerNavSidebarWidth: getNavSidebarWidth(state)
    }),
    (dispatch) => bindActionCreators(messageActionCreators, dispatch)
  )(Message)
);
