import React, { useEffect, useMemo, useReducer } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { translate } from 'react-i18next';
import { useUnmount } from 'react-use';
import block from 'bem-cn-lite';
import { Button, ControlLabel } from 'react-bootstrap';
import { isEmpty, isNil } from 'ramda';
import { Select } from '../../FormControls';
import RangeSum from './rangeSum';

import * as dictionariesGetters from '../../../modules/dictionaries/getters';
import * as dictionariesStorage from '../../../modules/dictionaries/storage';
import * as getters from './getters';
import * as storage from './storage';
import * as PRFactions from './actions';
import { actions as dictionariesActions } from '../../../modules/dictionaries/slice';
import { getCurrentUserLanguage } from '../../../storeGetters';
import './style.css';
import CategoriesSelect from '../../CategoriesSelect';

const DEFAULT_COUNTRY_ID = '3159'; // Россия
const DEFAULT_FILTER_VALUE = {
  countries: [],
  regions: [],
  cities: [],
  sum: {
    from: '',
    to: ''
  },
  customers: [],
  categories: [],
  submitted: false
};

const b = block('cw-pr-filter-form');

const FormChangeSubscribePurchaseRequests = ({
  t,
  filterData,
  customersList,
  countriesList,
  regionsList,
  citiesList,
  categoriesList,
  language,
  isSubscribed,
  changeSubscribe,
  unsubscribe,
  getFilterData,
  getCustomers,
  getCountries,
  getRegions,
  getCities,
  getCategories,
  cleanRegions,
  cleanCities,
  cleanFilterData,
  onClose
}) => {
  const [state, dispatch] = useReducer(reducer, DEFAULT_FILTER_VALUE);

  const actions = useMemo(
    () => ({
      setCountries: (payload) => dispatch({ type: 'set_countries', payload }),
      setRegions: (payload) => dispatch({ type: 'set_regions', payload }),
      setCities: (payload) => dispatch({ type: 'set_cities', payload }),
      setSumFrom: (payload) => dispatch({ type: 'set_sum_from', payload }),
      setSumTo: (payload) => dispatch({ type: 'set_sum_to', payload }),
      setCustomers: (payload) => dispatch({ type: 'set_customers', payload }),
      setCategories: (payload) => dispatch({ type: 'set_categories', payload }),
      setState: (payload) => dispatch({ type: 'set_state', payload }),
      setSubmitted: () => dispatch({ type: 'set_submitted' })
    }),
    []
  );

  useEffect(() => {
    getCategories();
    getFilterData();
    getCustomers();
  }, []);

  useUnmount(cleanFilterData);

  useEffect(() => {
    if (!isNil(filterData)) actions.setState(filterData);
  }, [filterData]);

  useEffect(() => {
    getCountries({ lang: language });
  }, [language]);

  function onChangeCountries(value) {
    if (!value) return;

    if (Array.isArray(value)) {
      actions.setCountries(value);
    }

    if (typeof value === 'string') {
      cleanRegions();
      cleanCities();
    }
  }

  function onChangeRegions(value) {
    if (!value) return;
    if (isEmpty(state.countries)) setDefaultCountry();

    if (Array.isArray(value)) {
      actions.setRegions(value);

      if (isRemoveRegion(state.regions, value)) {
        cleanCities();
      }
    }

    if (typeof value === 'string') {
      cleanRegions();
      cleanCities();
    }
  }

  function onChangeCities(value) {
    if (!value) return;

    if (Array.isArray(value)) {
      actions.setCities(value);
    }

    if (typeof value === 'string') cleanCities();
  }

  function onChangeCustomers(value) {
    if (!value) return;

    if (Array.isArray(value)) {
      actions.setCustomers(value);
    }
  }

  function onChangeCategories(value) {
    if (!value) return;

    if (Array.isArray(value)) {
      actions.setCategories(value);
    }
  }

  function onCountrySearch(value) {
    if (value) {
      getCountries({ search: value });
    }
  }

  function onRegionSearch(value) {
    if (!value) {
      cleanRegions();
      return;
    }

    if (isEmpty(state.countries)) setDefaultCountry();

    getRegions({ countryId: getCountyId(), search: value });
  }

  function onCitySearch(value) {
    if (value) {
      getCities({
        countryId: state.countries,
        regionId: state.regions,
        search: value
      });
    }
  }

  function onCustomersSearch(value) {
    if (value) {
      getCustomers(value);
    }
  }

  function setDefaultCountry() {
    getCountries({ countryId: DEFAULT_COUNTRY_ID }).then((countries) => {
      actions.setCountries([getDefaultCountry(countries)]);
    });
    cleanCities();
  }

  function getCountyId() {
    return isEmpty(state.countries) ? [DEFAULT_COUNTRY_ID] : state.countries;
  }

  function isShowCitySelect() {
    return !isEmpty(state.regions);
  }

  const onSelectKeyDown = (type) => (e) => {
    if (e.keyCode === 13) {
      switch (type) {
        case 'countries':
          onChangeCountries([...state.countries, e.target.value]);
          break;
        case 'regions':
          onChangeRegions([...state.regions, e.target.value]);
          break;
        case 'cities':
          onChangeCities([...state.cities, e.target.value]);
          break;
        case 'customers':
          onChangeCustomers([...state.customers, e.target.value]);
          break;
        case 'categories':
          onChangeCategories([...state.customers, e.target.value]);
          break;
        default:
          throw new Error(`No such handler for type: ${type}.`);
      }
    }
  };

  function onSumBlur(name, value) {
    if (name === 'from') actions.setSumFrom(value);
    if (name === 'to') actions.setSumTo(value);
  }

  function onSubmit(event) {
    const data = makeFilters(state);
    changeSubscribe(data);
    actions.setSubmitted();
    onClose(event);
  }

  function onUnsubscribe(event) {
    unsubscribe();
    onClose(event);
  }

  return (
    <div className={b()}>
      <div className={b('header')}>
        <div className={b('title')}>{t('pr_filter_title')}</div>
        <div className={b('subtitle')}>{t('pr_filter_subtitle')}</div>
      </div>
      <div className={b('content')}>
        <div className="cw-multi-select-wrapper">
          <ControlLabel className="control-label-row">
            {t('Delivery country')}
          </ControlLabel>
          <Select
            options={countriesList}
            value={state.countries}
            onChange={onChangeCountries}
            onInputChange={onCountrySearch}
            onInputKeyDown={onSelectKeyDown('countries')}
            id="country-select"
            arrowRenderer={false}
            multi
            placeholder={t('start_input')}
            noResultsText={t('noResultsText')}
            clearable={false}
            removeSelected
          />
        </div>
        <div className="cw-multi-select-wrapper">
          <ControlLabel className="control-label-row">
            {t('Delivery region')}
          </ControlLabel>
          <Select
            options={regionsList}
            value={state.regions}
            onChange={onChangeRegions}
            onInputChange={onRegionSearch}
            onInputKeyDown={onSelectKeyDown('regions')}
            id="region-select"
            arrowRenderer={false}
            multi
            placeholder={t('start_input')}
            noResultsText={t('noResultsText')}
            clearable={false}
            removeSelected
          />
        </div>
        {isShowCitySelect() && (
          <div className="cw-multi-select-wrapper">
            <ControlLabel className="control-label-row">
              {t('Delivery city')}
            </ControlLabel>
            <Select
              options={citiesList}
              value={state.cities}
              onChange={onChangeCities}
              onInputChange={onCitySearch}
              onInputKeyDown={onSelectKeyDown('cities')}
              id="city-select"
              arrowRenderer={false}
              placeholder={t('start_input')}
              noResultsText={t('noResultsText')}
              multi
              clearable={false}
              removeSelected
            />
          </div>
        )}
        <div>
          <ControlLabel className="control-label-row">
            {t('category_goods_and_services')}
          </ControlLabel>
          <CategoriesSelect
            selectedList={state.categories}
            list={categoriesList}
            onChoose={onChangeCategories}
          />
        </div>
        <div className="cw-multi-select-wrapper">
          <ControlLabel className="control-label-row">
            {t('Customer')}
          </ControlLabel>
          <Select
            options={customersList}
            value={state.customers}
            onChange={onChangeCustomers}
            onInputChange={onCustomersSearch}
            onInputKeyDown={onSelectKeyDown('customers')}
            id="customers-select"
            arrowRenderer={false}
            placeholder={t('not selected')}
            noResultsText={t('noResultsText')}
            multi
            clearable={false}
            removeSelected
          />
        </div>
        <div>
          <ControlLabel className="control-label-row">{`${t(
            'sum_request'
          )}, ${t('rub')}.`}</ControlLabel>
          <RangeSum
            from={state.sum.from}
            to={state.sum.to}
            onBlur={onSumBlur}
          />
        </div>
      </div>
      <div className={b('footer')}>
        <Button
          name="cancel"
          className={b('btn', { cancel: true })}
          onClick={onClose}>
          {t('Cancel')}
        </Button>
        <Button
          name="unsubscribe"
          onClick={onUnsubscribe}
          disabled={isDisabledUnsubscribe(isSubscribed)}>
          {t('unsubscribe')}
        </Button>
        <Button
          name="subscribe"
          bsStyle="primary"
          onClick={onSubmit}
          disabled={isDisabledSubscribe(isSubscribed, state)}>
          {getSubscribeButtonName(t, isSubscribed, state)}
        </Button>
      </div>
    </div>
  );
};

function isDisabledUnsubscribe(isSubscribed = false) {
  return !isSubscribed;
}

function isDisabledSubscribe(isSubscribed = false, { isSubmitted = false }) {
  return isSubscribed && isSubmitted;
}

function getSubscribeButtonName(
  t,
  isSubscribed = false,
  { isSubmitted = false }
) {
  if (!isSubscribed) return t('subscribe_to_update');
  return t(isSubmitted ? 'changed_subscribe' : 'change_subscribe');
}

function reducer(state, action) {
  switch (action.type) {
    case 'set_countries':
      return {
        ...state,
        countries: action.payload,
        regions: DEFAULT_FILTER_VALUE.regions,
        cities: DEFAULT_FILTER_VALUE.cities,
        isSubmitted: false
      };
    case 'set_regions':
      return {
        ...state,
        countries: state.countries,
        regions: action.payload,
        cities: isRemoveRegion(state.regions, action.payload)
          ? DEFAULT_FILTER_VALUE.cities
          : state.cities,
        isSubmitted: false
      };
    case 'set_cities':
      return { ...state, cities: action.payload, isSubmitted: false };
    case 'set_sum_from':
      return {
        ...state,
        sum: {
          ...state.sum,
          from: action.payload,
          to: calcSumTo(action.payload, state.sum.to)
        },
        isSubmitted: false
      };
    case 'set_sum_to':
      return {
        ...state,
        sum: {
          ...state.sum,
          from: calcSumFrom(state.sum.from, action.payload),
          to: action.payload
        },
        isSubmitted: false
      };
    case 'set_customers':
      return { ...state, customers: action.payload, isSubmitted: false };
    case 'set_categories':
      return { ...state, categories: action.payload, isSubmitted: false };
    case 'set_state':
      return { ...DEFAULT_FILTER_VALUE, ...action.payload, isSubmitted: false };
    case 'set_submitted':
      return { ...state, isSubmitted: true };
    default:
      throw new Error(`No such handler for type: ${action.type}.`);
  }
}

function calcSumFrom(from, to) {
  if ((from !== 0 && !from) || isNil(to)) return from;

  return Math.min(to, from);
}

function calcSumTo(from, to) {
  if (to !== 0 && !to) return to;

  return Math.max(to, from);
}

function isRemoveRegion(prevRegions, currentRegions) {
  return prevRegions.length > currentRegions.length;
}

function makeFilters(state) {
  return {
    countries: extractValue(state.countries),
    regions: extractValue(state.regions),
    cities: extractValue(state.cities),
    customers: extractValue(state.customers),
    categories: state.categories,
    sumFrom: state.sum.from,
    sumTo: state.sum.to
  };

  function extractValue(arr = []) {
    return arr.map(({ value }) => value);
  }
}

function getDefaultCountry(countriesList) {
  return countriesList.find((country) => country.value === DEFAULT_COUNTRY_ID);
}

export default connect(
  (state) => ({
    customersList: dictionariesGetters.getCompanies(state),
    countriesList: dictionariesGetters.getCountries(state),
    regionsList: dictionariesGetters.getRegions(state),
    citiesList: dictionariesGetters.getCities(state),
    categoriesList: dictionariesGetters.getPurchaseRequestsCategories(state),
    filterData: getters.getPurchaseRequestFilterData(state),
    isSubscribed: getters.isSubscribeToPRChannel(state),
    language: getCurrentUserLanguage(state)
  }),
  (dispatch) =>
    bindActionCreators(
      {
        getCustomers: dictionariesStorage.getCompanies,
        getCountries: dictionariesStorage.getCountries,
        getRegions: dictionariesStorage.getRegions,
        getCities: dictionariesStorage.getCities,
        getCategories: dictionariesStorage.getPurchaseRequestsCategories,
        cleanRegions: dictionariesActions.purgeRegions,
        cleanCities: dictionariesActions.purgeCities,
        changeSubscribe: storage.changeSubscribe,
        unsubscribe: storage.unsubscribe,
        getFilterData: storage.getPurchaseRequestFilterData,
        cleanFilterData: PRFactions.cleanPurchaseRequestFilterData
      },
      dispatch
    )
)(translate()(FormChangeSubscribePurchaseRequests));
