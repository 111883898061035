import React, { Component } from 'react';
import PropTypes from 'prop-types';
import block from 'bem-cn-lite';
import { translate } from 'react-i18next';
import {
  Modal,
  Form,
  FormGroup,
  FormControl,
  ControlLabel,
  Button,
  HelpBlock
} from 'react-bootstrap';
import '../styles/modal_theme_default.styl';

import { ModalContainer } from '../elements';

const b = block('modal-container');

class ModalEditCustomerCategory extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: 0,
      name: ''
    };
    this.inputProps = this.inputProps.bind(this);
  }

  getChildContext() {
    return { b };
  }

  UNSAFE_componentWillMount() {
    const { group } = this.props;
    this.setState({ id: group.get('id'), name: group.get('name') });
  }

  isValidNameField() {
    return this.state.name.length < 1 || /^\s+$/.test(this.state.name);
  }

  asterisk() {
    // eslint-disable-line
    return <span style={{ color: 'red' }}>*</span>;
  }

  inputProps(field) {
    return {
      value: this.state[field],
      onChange: (e) => this.setState({ [field]: e.target.value })
    };
  }

  render() {
    const { t, captionKey, hideModalDialog, updateCustomersCategory, group } =
      this.props;

    return (
      <ModalContainer mods={{ theme: 'default' }}>
        <Modal id="editCategory" onHide={() => hideModalDialog()} show>
          <Modal.Header>
            <Modal.Title>
              {t(captionKey)}
              <span
                className="modal-header-cross pull-right"
                onClick={() => hideModalDialog()}
              />
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <FormGroup
                controlId="name"
                validationState={this.isValidNameField() ? 'error' : null}
                style={{ overflow: 'hidden' }}>
                <ControlLabel>
                  {`${t('Name')} `}
                  {this.asterisk()}
                </ControlLabel>
                <FormControl
                  type="text"
                  {...this.inputProps('name')}
                  maxLength="60"
                  onKeyDown={(e) =>
                    e.keyCode === 13 ? e.preventDefault() : null
                  }
                />
                <h6 className="pull-right">
                  <small>
                    {this.state.name.length}
                    /60
                  </small>
                </h6>
                {this.isValidNameField() && (
                  <HelpBlock style={{ marginBottom: 0 }}>
                    {t('Required fields empty')}
                  </HelpBlock>
                )}
              </FormGroup>
            </Form>
            {this.asterisk()}
            {` ${t('Required fields')}`}
          </Modal.Body>
          <Modal.Footer>
            <Button
              id="cancel"
              className="pull-left"
              onClick={() => hideModalDialog()}>
              {t('Cancel')}
            </Button>
            <Button
              id="saveCharacteristicButton"
              bsStyle="primary"
              disabled={
                this.isValidNameField() || group.get('name') === this.state.name
              }
              onClick={async () => {
                await updateCustomersCategory(this.state);
                return hideModalDialog();
              }}>
              {t('Save')}
            </Button>
          </Modal.Footer>
        </Modal>
      </ModalContainer>
    );
  }
}

ModalEditCustomerCategory.propTypes = {
  t: PropTypes.func.isRequired
};

ModalEditCustomerCategory.childContextTypes = {
  b: PropTypes.func
};

export default translate(['ui'], { wait: true })(ModalEditCustomerCategory);
