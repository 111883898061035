/* eslint-disable max-len */
import React from 'react';

const CancelIcon = () => (
  <svg
    width="18"
    height="19"
    viewBox="0 0 18 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9 17.9023C13.4183 17.9023 17 14.3206 17 9.90234C17 5.48407 13.4183 1.90234 9 1.90234C4.58172 1.90234 1 5.48407 1 9.90234C1 14.3206 4.58172 17.9023 9 17.9023ZM9 18.9023C13.9706 18.9023 18 14.8729 18 9.90234C18 4.93178 13.9706 0.902344 9 0.902344C4.02944 0.902344 0 4.93178 0 9.90234C0 14.8729 4.02944 18.9023 9 18.9023Z"
      fill="#767B92"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.64645 15.5488L14.6465 3.54879L15.3536 4.2559L3.35356 16.2559L2.64645 15.5488Z"
      fill="#767B92"
    />
  </svg>
);

export default CancelIcon;
