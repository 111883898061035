import { defRecord } from 'core/lib';
import { curry } from 'ramda';
import {
  initUseStateDataType,
  getState,
  getData,
  updateState
} from '../../../lib/data-hook';
import { createFileUrlToIstock } from '../../ChatWidget/modules/files/utils';
import {
  makeList,
  add,
  removeById,
  total,
  isEmpty,
  getItems,
  clear,
  isEqualItems
} from './list';

export const { makeFile } = defRecord('File', [
  'id',
  'name',
  'size',
  'url',
  'createdAt',
  'extension'
]);

const tranformToFile = ({
  fileInfo: { id, name, size, url, originalUrl, createdAt, type }
}) =>
  makeFile({
    id,
    name,
    size,
    url: originalUrl || url,
    createdAt,
    extension: type
  });

const addFileToList = curry((uploadedFile, hookList) =>
  add(tranformToFile(uploadedFile), hookList)
);

export const useListFiles = initUseStateDataType(makeList);
export const addFile = updateState(addFileToList);
export const removeFile = updateState(removeById);
export const downloadFile = (file) =>
  window.open(
    `${createFileUrlToIstock(file, 'url')}?name=${file.name}`,
    '_blank'
  );
export const clearFiles = updateState((none, list) => clear(list), null);
export const totalFiles = getData(total);
export const isNoFiles = getData(isEmpty);
export const getFiles = getData(getItems);
export const isEqualFiles = (files, hook) =>
  isEqualItems(files, getState(hook));
