import React from 'react';
import { Link } from 'react-router-dom';
import { Interpolate } from 'react-i18next';
import { isType } from 'core/lib';
import { getInterlocutor } from 'core/data/light/dialog';
import { interpreterMessageKey } from '../../utils';

function isMessengerGreetingMsg(message) {
  return (
    message.msg === 'messenger_greeting' ||
    message.msg.tkey === 'messenger_greeting'
  );
}

const renderLink = (t, message, handler) => {
  const text = isMessengerGreetingMsg(message) ? 'help' : 'Documentation';

  if (window.location.origin === process.env.UNICAT_DEFAULT_SERVICE) {
    return (
      <Link to="/help" onClick={() => handler('move-by-link')}>
        {t(text)}
      </Link>
    );
  }

  return (
    <a
      href={`${process.env.UNICAT_DEFAULT_SERVICE}/help`}
      onClick={() => handler('move-by-link')}>
      {t(text)}
    </a>
  );
};

export const getTextMessage = ({ t, message, chat, handler, interlocutor }) => {
  if (isType('MsgSupport', message) || message.msg?.tkey) {
    return {
      ...message,
      msg: (
        <Interpolate
          i18nKey={interpreterMessageKey(message)}
          link={renderLink(t, message, handler)}
          userName={
            interlocutor?.userName ||
            getInterlocutor(message.employeeId, chat)?.userName
          }
        />
      )
    };
  }

  return message;
};
