import React, {
  useState,
  useEffect,
  useMemo,
  useCallback,
  useRef
} from 'react';
import useResizeObserver from '@react-hook/resize-observer';
import cn from 'classnames';
import {
  Typography,
  ExpandableText,
  Icon,
  Link,
  Button,
  Loader
} from '@link/react-components';
import { useTranslate, useActions } from '@hooks';
import { convertUrlToHref } from '../../../ChatWidget/modules/files/utils';
import { copy } from '../../../../lib/clipboard';
import { useCompanyProfileContext } from '../../CompanyProfile.context';
import styles from './AboutCompany.module.css';
import * as notificationMessageActions from '../../../../action-creators/message';

const OVERVIEW_CLAMP = 10;

const attributesReference = [
  'inn',
  'kpp',
  'ogrn',
  'bik',
  'fullBankName',
  'checkingAccount',
  'correspondentAccount',
  'legalAddress',
  'actualAddress'
];
const dialReference = ['phone', 'fax'];

const SWITCH_VIEW_WIDTH = 842;

const useSize = (target) => {
  const [size, setSize] = useState();

  useResizeObserver(target, (entry) => setSize(entry?.contentRect));
  return size;
};

const getValueOrDefault = (value, defaultValue = '') =>
  value !== null && value !== undefined && value.length !== 0
    ? value
    : defaultValue;

const Title = ({ title }) => (
  <Typography variant="h3" className={styles.title}>
    {title}
  </Typography>
);

const AttributeRow = ({ attributeTitle, attributeValue }) => (
  <div className={styles.row}>
    <Typography
      key={attributeTitle}
      variant="body1Reg"
      className={styles.attributeTitle}>
      {attributeTitle}
    </Typography>
    <Typography
      key={attributeValue}
      variant="body1Reg"
      className={styles.attributeValue}>
      {getValueOrDefault(attributeValue)}
    </Typography>
  </div>
);

const Delimiter = () => <div className={styles.delimiter} />;

const DialRow = ({ dialType, dialNumber }) => (
  <div key={dialType} className={styles.string}>
    <Typography variant="body1Med" className={styles.dialType}>
      {dialType}
    </Typography>
    <Typography variant="body1Reg">{dialNumber}</Typography>
  </div>
);

const SiteRow = ({ site }) => (
  <div className={styles.string}>
    <Icon iconName="globe" height={18} width={18} />
    <Link href={convertUrlToHref(site)} displayName={site} />
  </div>
);

const EmailRow = ({ email }) => (
  <div className={styles.string}>
    <Icon iconName="mail" height={18} width={18} />
    <Typography variant="body1Reg">{email}</Typography>
  </div>
);

const getTranslate = (t, tKey) => t(`companyCard.tabs.aboutCompany.${tKey}`);

function getCompanyData(company) {
  const companyData = new Map();

  const getAttributeValue = (object, label) => {
    if (!company || !company[object]) return null;
    return Object.values(company[object]).find(
      (object) => object.label === label
    )?.value;
  };

  attributesReference.forEach((attribute) =>
    companyData.set(
      attribute,
      getValueOrDefault(getAttributeValue('requisites', attribute), '-')
    )
  );
  dialReference.forEach((dial) =>
    companyData.set(
      dial,
      getValueOrDefault(getAttributeValue('contacts', dial), '')
    )
  );
  companyData.set(
    'url',
    getValueOrDefault(getAttributeValue('contacts', 'url'), '')
  );
  companyData.set(
    'email',
    getValueOrDefault(getAttributeValue('contacts', 'email'), '')
  );

  companyData.set(
    'email',
    getValueOrDefault(getAttributeValue('contacts', 'email'), '')
  );

  companyData.set('overview', getValueOrDefault(company?.description, ''));

  return companyData;
}

export function AboutCompany() {
  const { loading, company } = useCompanyProfileContext();

  const [companyData, setCompanyData] = useState(null);

  useEffect(() => {
    const data = getCompanyData(company);
    setCompanyData(data);
  }, [company]);

  const { setSuccessMessage, setErrorMessage } = useActions(
    notificationMessageActions
  );

  const copyAttributes = useCallback(async () => {
    const attributes = attributesReference.map(
      (attribute) =>
        `${getTranslate(t, attribute)} ${companyData.get(attribute)};`
    );
    try {
      await copy(attributes.join(''));
      setSuccessMessage({
        key: 'notifications.successCopy'
      });
    } catch (e) {
      setErrorMessage({
        key: 'notifications.failedCopy'
      });
    }
  }, [companyData]);

  const ref = useRef(null);
  const size = useSize(ref);

  const isWide = useMemo(() => size?.width >= SWITCH_VIEW_WIDTH, [size?.width]);

  const t = useTranslate();

  return (
    <div
      ref={ref}
      className={cn(styles.about, !isWide ? styles.oneColumnLayout : '')}>
      {(loading || companyData === null) && <Loader />}
      {!loading && companyData !== null && (
        <>
          <div className={styles.block}>
            <div className={styles.section}>
              <div className={styles.row}>
                <Title title={getTranslate(t, 'attributes')} />
                <Button
                  className={styles.button}
                  mode="text"
                  size="small"
                  before={<Icon iconName="copy" width={16} height={16} />}
                  onClick={copyAttributes}>
                  {getTranslate(t, 'copyAttributes')}
                </Button>
              </div>
              <div className={styles.content}>
                {attributesReference.map((attribute, index) => (
                  <>
                    <AttributeRow
                      key={attribute}
                      attributeTitle={getTranslate(t, attribute)}
                      attributeValue={companyData.get(attribute)}
                    />
                    {index < attributesReference.length - 1 && <Delimiter />}
                  </>
                ))}
              </div>
            </div>
          </div>

          <div className={styles.block}>
            <div className={styles.section}>
              <div className={styles.row}>
                <Title title={getTranslate(t, 'contacts')} />
              </div>
              <div className={styles.content}>
                <div className={styles.contactsBlock}>
                  <div
                    className={cn(styles.dialBlock, !isWide && styles.dialRow)}>
                    {dialReference.map(
                      (dial) =>
                        companyData.get(dial).length !== 0 && (
                          <DialRow
                            key={dial}
                            dialType={getTranslate(t, dial)}
                            dialNumber={companyData.get(dial)}
                          />
                        )
                    )}
                  </div>
                  {companyData.get('url').length !== 0 && (
                    <SiteRow site={companyData.get('url')} />
                  )}
                  {companyData.get('email').length !== 0 && (
                    <EmailRow email={companyData.get('email')} />
                  )}
                </div>
              </div>
            </div>
            <div className={styles.section}>
              <div className={styles.row}>
                <Title title={getTranslate(t, 'overview')} />
              </div>
              <div className={styles.content}>
                {companyData.get('overview').length !== 0 ? (
                  <ExpandableText
                    textContent={companyData.get('overview')}
                    showMoreText={getTranslate(t, 'showMore')}
                    showLessText={getTranslate(t, 'showLess')}
                    clamp={OVERVIEW_CLAMP}
                  />
                ) : (
                  <Typography variant="body1Reg" className={styles.noOverview}>
                    {getTranslate(t, 'noOverview')}
                  </Typography>
                )}
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
}
