import React from 'react';
import classNames from 'classnames';
import useSelect from './useSelect';
import PopupSmall from '../PopupSmall';
import PopupContent from '../PopupSmall/PopupContent';
import Tag from './components/Tag';
import { getOptionByValue } from './helper';
import Icon, { Icons } from '../../icons';
import './style.css';

function ComplexMultiSelect({
  t,
  selectedList,
  list,
  onChoose,
  onClickByIcon,
  disabled,
  maxCount,
  inputPlaceholder,
  noResultPlaceholder,
  showLimit,
  dataTestid
}) {
  const {
    onChange,
    value,
    isOpened,
    setIsOpened,
    menuOptions,
    onToggle,
    onFocus,
    isFocused,
    onBlur,
    inputRef,
    onOpenModal,
    onKeyDown
  } = useSelect({
    t,
    list,
    onChoose,
    onClickByIcon,
    selectedList,
    disabled,
    noResultPlaceholder,
    showLimit
  });

  return (
    <div onClick={onFocus} onBlur={onBlur} data-testid={dataTestid}>
      <div
        className={classNames('cmulti-wrapper', {
          focus: !disabled && isFocused
        })}>
        <div className="cmulti-tag-wrapper">
          {selectedList.map((val) => (
            <Tag
              onDelete={onToggle}
              option={getOptionByValue(list, val)}
              key={val}
              disabled={disabled}
            />
          ))}
          {maxCount && selectedList.length < maxCount && (
            <input
              placeholder={inputPlaceholder || t('add_category')}
              ref={inputRef}
              onChange={onChange}
              value={value}
              onKeyDown={onKeyDown}
              className="cmulti-select"
              disabled={disabled}
            />
          )}
        </div>
        <div className="cmulti-tag-wrapper-icon" onClick={onOpenModal}>
          <Icon icon={Icons.Drop} size={28} />
        </div>
      </div>

      <PopupSmall
        isOpened={isOpened}
        setIsOpened={setIsOpened}
        content={
          <PopupContent
            style={{ minWidth: 'auto' }}
            menuOptions={menuOptions}
          />
        }
        style={{ width: '100%' }}
        top={5}
      />
    </div>
  );
}

export default ComplexMultiSelect;
