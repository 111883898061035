import React from 'react';
import cn from 'classnames';
import { Typography } from '@components';
import Cell from '../Cell/Cell';
import styles from './RowNumberCell.module.css';

const RowNumberCell = ({ className, children }) => (
  <div className={cn(styles.container, className)}>
    <Cell className={styles.cell}>
      <Typography variant="body1Reg" className={styles.text} title={children}>
        {children}
      </Typography>
    </Cell>
  </div>
);

export default RowNumberCell;
