import React, { Component } from 'react';
import PropTypes from 'prop-types';
import block from 'bem-cn-lite';
import { translate } from 'react-i18next';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  Modal,
  Form,
  Button,
  FormGroup,
  FormControl,
  ControlLabel,
  HelpBlock
} from 'react-bootstrap';
import './styles/modal_theme_default.styl';

import { ModalContainer } from './elements';
import * as modalActions from '../../action-creators/modal';

const b = block('modal-container');

class ModalEditSupplierGroup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      text: ''
    };
    this.setSubmitText = this.setSubmitText.bind(this);
  }

  getChildContext() {
    return { b };
  }

  UNSAFE_componentWillMount() {
    const { value } = this.props;
    this.setState({ text: value || '' });
  }

  setSubmitText(e) {
    this.setState({ text: e.target.value });
  }

  isValidNameField() {
    return this.state.text.length < 1 || /^\s+$/.test(this.state.text);
  }

  asterisk() {
    // eslint-disable-line
    return <span style={{ color: 'red' }}>*</span>;
  }

  handleClick = () => {
    const { submit, hideModalDialog, onSubmited, groupId } = this.props;

    submit(this.state.text, groupId).then(() => {
      hideModalDialog();
      if (onSubmited) onSubmited();
    });
  };

  render() {
    const {
      t,
      text,
      type,
      textBtnConfirm,
      hideModalDialog,
      placeholder,
      value
    } = this.props;

    return (
      <Modal id="sendOrderToSupplier" onHide={() => hideModalDialog()} show>
        <ModalContainer mods={{ type: type || 'default', theme: 'default' }}>
          <Modal.Header>
            <Modal.Title>
              {t('Edit group')}
              <span
                className="modal-header-cross pull-right"
                onClick={() => hideModalDialog()}
              />
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <FormGroup
              controlId="name"
              validationState={this.isValidNameField() ? 'error' : null}
              style={{ overflow: 'hidden' }}>
              <ControlLabel>
                {`${t('Name')} `}
                {this.asterisk()}
              </ControlLabel>
              <FormControl
                type="text"
                placeholder={t(placeholder || '')}
                value={this.state.text}
                maxLength="60"
                onChange={this.setSubmitText}
              />
              <h6 className="pull-right">
                <small>
                  {this.state.text.length}
                  /60
                </small>
              </h6>
              {this.isValidNameField() && (
                <HelpBlock style={{ marginBottom: 0 }}>
                  {t('Required fields empty')}
                </HelpBlock>
              )}
            </FormGroup>
            <Form>
              <p className={b('row')}>{text}</p>
            </Form>
            {this.asterisk()}
            {` ${t('Required fields')}`}
          </Modal.Body>
          <Modal.Footer>
            <Button
              id="cancel"
              className="pull-left"
              onClick={() => hideModalDialog()}>
              {t('Cancel')}
            </Button>
            <Button
              id="saveCharacteristicButton"
              bsStyle="primary"
              disabled={this.isValidNameField() || value === this.state.text}
              onClick={this.handleClick}>
              {textBtnConfirm || t('Save')}
            </Button>
          </Modal.Footer>
        </ModalContainer>
      </Modal>
    );
  }
}

ModalEditSupplierGroup.propTypes = {
  t: PropTypes.func.isRequired
};

ModalEditSupplierGroup.childContextTypes = {
  b: PropTypes.func
};

export default connect(null, (dispatch) =>
  bindActionCreators(
    {
      hideModalDialog: modalActions.hideModalDialog
    },
    dispatch
  )
)(translate(['ui'], { wait: true })(ModalEditSupplierGroup));
