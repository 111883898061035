import React from 'react';
import PropTypes from 'prop-types';
// import { Map } from 'immutable';
import { translate } from 'react-i18next';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import * as modalActions from '../../../../action-creators/modal';
// import * as contactsActions from '../../../../action-creators/contacts';
import { getDialogInfo } from '../../getter';
import * as ContactUC from '../../modules/contacts';

const addContact = (user, t, showModal, unhideContact) => {
  const { employeeId, lastName, firstName } = user;
  const userName = `${lastName} ${firstName}`;

  // const uniqueId = user.get('uniqueId');
  // const employeeId = user.get('employeeId');
  // const userName = `${user.get('lastName')} ${user.get('firstName')}`;
  // const [contactCompanyId, contactUserId] = uniqueId.split('.');

  showModal('SIMPLE_SUBMIT', {
    captionKey: 'addContact',
    text: t('add_contact_modal_ask', { userName }),
    textBtnConfirm: t('Add'),
    submitAction: async () => {
      // await unhideContact(employeeId, contactCompanyId, contactUserId);
      await unhideContact(employeeId);
    }
  });
};

const NotContactCard = ({ t, user, showModal, unhideContact }) => (
  <div className="cwm-notify-container">
    <div>{t('contact_missing_in_list')}</div>
    <button
      type="button"
      className="text-btn-primary"
      onClick={() => addContact(user, t, showModal, unhideContact)}>
      {t('addContact')}
    </button>
  </div>
);

NotContactCard.propTypes = {
  t: PropTypes.func.isRequired,
  showModal: PropTypes.func.isRequired,
  unhideContact: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired
};

const mapState = (state) => ({
  user: getDialogInfo(state)
});

const mapDispatch = (dispatch) =>
  bindActionCreators(
    {
      showModal: modalActions.showModal,
      unhideContact: ContactUC.unhideContactAndDialog
      // unhideContact: contactsActions.unhideContact
    },
    dispatch
  );

export default connect(mapState, mapDispatch)(translate()(NotContactCard));
