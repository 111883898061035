import React, { useEffect, useMemo } from 'react';
import { useParams } from '@hooks';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import MessengerOther from '../../components/Layouts/MessengerOther';

import { MessengerWidgetWrapper } from '../../components/Widgets/Messenger/MessengerWidget';
import { useTranslate } from '../../TranslateProvider';
import { formatRequestNumber } from '../../utils/utils';
import * as storeGetters from '../../storeGetters';
import { getPurchaseRequest } from '../../components/PurchaseRequests/message';
import { WithConfirmationRequestContent } from './components/withConfirmationRequestContent';

const WithConfirmationRequestPage = () => {
  const t = useTranslate();
  const { requestId } = useParams();
  const user = useSelector(storeGetters.getCurrentUser);

  useEffect(() => {
    getPurchaseRequest({
      requestId,
      language: user.get('language'),
      userId: user.get('id'),
      currentCompany: user.get('currentCompany'),
      isReturnDynamic: true
    });
  }, []);

  const request = useSelector(storeGetters.getCurrentPurchaseRequest);

  const breadcrumbs = useMemo(
    () => [
      {
        name: t('Purchase requests'),
        element: (
          <>
            <Link
              to={{
                pathname: '/requests/my',
                state: { myRequestsPage: true }
              }}>
              {t('Purchase requests')}
            </Link>
          </>
        )
      },
      {
        name: `${t('request_view')} №${formatRequestNumber(requestId)} - ${
          request.get('number') || formatRequestNumber(requestId)
        }`
      }
    ],
    []
  );
  return (
    <>
      <MessengerOther
        theme="gray"
        title={t('Purchase requests')}
        breadcrumbs={breadcrumbs}
        content={<WithConfirmationRequestContent />}
      />
      <MessengerWidgetWrapper />
    </>
  );
};

export default WithConfirmationRequestPage;
