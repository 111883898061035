import React from 'react';
import { translate } from 'react-i18next';
import block from 'bem-cn-lite';
import Tooltip from '../../../../ChatWidget/Tooltip';
import Icon from '../Icon';
import LinkBtn from './LinkBtn';

const b = block('snm-footer');

const Footer = ({ t, list = [], onlyIcons = false }) => (
  <ul className={b({ 'only-icons': onlyIcons })}>
    {list.map((option, key) => (
      <li key={key}>
        <LinkBtn
          type={option.type}
          onClick={option.onClick}
          to={option.to}
          target={option.target}>
          <Tooltip text={option.tooltip} isShowTooltip={onlyIcons}>
            <Icon icon={option.icon} />
          </Tooltip>
          <span className={b('text')}>{t(option.name)}</span>
        </LinkBtn>
      </li>
    ))}
  </ul>
);

export default translate()(Footer);
