import React, { Component } from 'react';
import PropTypes from 'prop-types';
import block from 'bem-cn-lite';
import { translate } from 'react-i18next';
import { Button, Modal } from 'react-bootstrap';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from '../../hoc/withRouter';

import './styles/modal_theme_default.styl';

import { format } from '../../lib/date';
import { ModalContainer } from './elements';
import Attachments from '../Channel/attachments/Attachments';

import * as modalActions from '../../action-creators/modal';

const b = block('modal-container');

class ModalViewForwardMessage extends Component {
  getChildContext() {
    return { b };
  }

  render() {
    const { t, hideModalDialog, messages = [] } = this.props;
    return (
      <Modal id="messageForward" show>
        <ModalContainer mods={{ type: 'default', theme: 'default' }}>
          <Modal.Header>
            <Modal.Title>
              {t('forwarded_messages')}
              <span
                className="modal-header-cross pull-right"
                onClick={() => hideModalDialog()}
              />
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div style={{ height: 500, overflowY: 'auto' }}>
              {messages.map((msg) => (
                <div className="message-user">
                  <div
                    className="message-user__left"
                    style={{ cursor: 'pointer' }}>
                    <img
                      className="message-user__img"
                      src={msg.avatar || '/img/user.svg'}
                      alt={msg.userName}
                    />
                  </div>
                  <div className="message-user__right">
                    <div
                      className="message-user__name"
                      style={{ color: '#000000' }}>
                      {msg.userName}
                    </div>
                    <div className="message-user__time">
                      {format(msg.date, 'HH:mm')}
                    </div>
                    <div className="message-user__text">
                      <span>{msg.text}</span>
                    </div>
                    <div>
                      <div
                        style={{
                          display: 'flex',
                          flexWrap: 'wrap',
                          overflow: 'hidden'
                        }}>
                        <Attachments
                          messageId={msg.id}
                          files={msg.files}
                          allowDelete={false}
                          mode="disable-menu"
                          showSingleImage
                        />
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              id="close"
              bsStyle="primary"
              className="pull-right"
              onClick={() => hideModalDialog()}>
              {t('Close')}
            </Button>
          </Modal.Footer>
        </ModalContainer>
      </Modal>
    );
  }
}

ModalViewForwardMessage.propTypes = {
  t: PropTypes.func.isRequired,
  hideModalDialog: PropTypes.func.isRequired,
  messages: PropTypes.array.isRequired
};

ModalViewForwardMessage.childContextTypes = {
  b: PropTypes.func
};

export default withRouter(
  connect(
    () => ({}),
    (dispatch) =>
      bindActionCreators(
        {
          hideModalDialog: modalActions.hideModalDialog
        },
        dispatch
      )
  )(translate(['ui'], { wait: true })(ModalViewForwardMessage))
);
