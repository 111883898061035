import { curry } from 'ramda';
import { NUMBER_OF_DIGITS } from '../viewModelAdapters/constans.js';

export const onePercentOfNumber = (number) => number / 100;

export const vatOfSum = (sum, vat) => sum * (vat / (100 + vat));

export const discountSum = (discount, price) =>
  price * onePercentOfNumber(discount);

export const discountPrice = curry(
  (discount, price) => price - discountSum(discount, price)
);

export const roundUpFrationalDigits = curry((fractionalDigits, number) =>
  Number(number.toFixed(fractionalDigits || NUMBER_OF_DIGITS.HUNDREDS))
);
