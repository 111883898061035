import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { translate } from 'react-i18next';

import ButtonIcon from 'react-components/src/components/ButtonIcon';
import icons from 'react-components/src/icons/constants';
import Filter from '../filter';
import {
  setSearchQueryDebounced,
  resetSearchQuery
} from '../../AdvancedSearch/messages';
import * as useCases from '../../../useCases';
import { getAdvancedSearch } from '../../../getter';
import { isActiveFilter } from '../../../data-type/block-advanced-search';

import './style.css';

const CWSSearch = ({ t, handleSearchHeader, search }) => {
  const [isShowFilter, setShowFilter] = useState(false);
  const toggleShowFilter = () => setShowFilter((v) => !v);
  const [value, setValue] = useState('');
  const ref = useRef(null);
  useEffect(() => {
    ref.current.focus();
  }, []);

  return (
    <>
      <div className="cw-header-search">
        <div className="cw-header-search-block__container">
          <ButtonIcon icon={icons.Search} size={18} />
          <input
            ref={ref}
            className="cw-header-search-block__input"
            placeholder={t('chat-widget.tooltips.search')}
            value={value}
            onChange={(e) => {
              const val = e.target.value;
              setValue(val);
              setSearchQueryDebounced.emit(val);
            }}
          />
        </div>
        <ButtonIcon
          className="cw-header-search_subsearch"
          t={t}
          disabled={false}
          onClick={toggleShowFilter}
          icon={icons.Sliders}
          size={18}
          active={isActiveFilter(search)}
          tooltip={t('advanced-search')}
        />
        <ButtonIcon
          icon={icons.CloseLarge}
          size={18}
          onClick={() => {
            resetSearchQuery.emit();
            handleSearchHeader('close');
          }}
        />
      </div>
      <Filter isShow={isShowFilter} />
    </>
  );
};

CWSSearch.propTypes = {
  t: PropTypes.func.isRequired,
  handleSearchHeader: PropTypes.func.isRequired
};

export default connect(
  (state) => ({
    search: getAdvancedSearch(state)
  }),
  (dispatch) =>
    bindActionCreators(
      {
        ...useCases
      },
      dispatch
    )
)(translate()(CWSSearch));
