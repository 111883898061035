const { assocPath, hasPath, path, curry } = require('ramda');
const { defStruct } = require('../../lib');
const { getSection, getUseCase, havePermission } = require('../policy');
const { getRole } = require('../userAccessRoles');

/**
 * @typedef ListOfPolicies
 * @typedef {import('../policy')} Policy
 * @typedef {import('../userAccessRoles')} UserAccessRoles
 */
const { makeListOfPolicies, getList, isListOfPolicies } = defStruct(
  'ListOfPolicies',
  ['list']
);

/**
 * Sets policy to the list of policies
 *
 * @param {Policy} policy
 * @param {ListOfPolicies} listOfPolicies
 * @returns {ListOfPolicies}
 */
const setPolicy = curry((policy, listOfPolicies) =>
  assocPath(
    ['list', getSection(policy), getUseCase(policy)],
    policy,
    listOfPolicies
  )
);

/**
 * Gives a policy
 *
 * @param {string} section
 * @param {string} useCase
 * @param {ListOfPolicies} listOfPolicies
 * @returns {Policy}
 */
const getPolicy = (section, useCase, listOfPolicies) =>
  path([section, useCase], getList(listOfPolicies));

/**
 * Creates a list of policies
 *
 * @param {[Policy]} policies
 * @returns {ListOfPolicies}
 */
exports.makeListOfPolicies = (policies = []) =>
  policies.reduce(
    (acc, policy) => setPolicy(policy, acc),
    makeListOfPolicies({})
  );

/**
 * Checks if is a list of policies
 *
 * @param {*} a
 * @returns {boolean}
 */
exports.isListOfPolicies = (a) => isListOfPolicies(a);

/**
 * Checks if have policy by section and use case
 *
 * @param {string} section
 * @param {string} useCase
 * @param {ListOfPolicies} listOfPolicies
 * @returns {boolean}
 */
const havePolicy = (section, useCase, listOfPolicies) =>
  hasPath([section, useCase], getList(listOfPolicies));
exports.havePolicy = havePolicy;

exports.setPolicy = setPolicy;

/**
 * Checks if have permission by userAccessRoles
 *
 * @param {string} section
 * @param {string} useCase
 * @param {ListOfPolicies} listOfPolicies
 * @param {UserAccessRoles} userAccessRoles
 * @returns {boolean}
 */
exports.havePermission = (
  section,
  useCase,
  listOfPolicies,
  userAccessRoles
) => {
  if (!havePolicy(section, useCase, listOfPolicies)) {
    console.error(`No such section "${section}" or use case "${useCase}"`); // eslint-disable-line
    return false;
  }

  const policy = getPolicy(section, useCase, listOfPolicies);
  const role = getRole(section, userAccessRoles);

  return havePermission(role, policy);
};
