import request from '../../../lib/request';
import {
  accepted,
  rejected,
  rework
} from '../../../presenters/sidebars/PurchaseRequestResponsesSidebar/status';
import { actions } from './reducer';
import * as prActions from '../../../action-creators/purchaseRequests';
import * as LS from '../../../storage/lib/LS';
import * as storeGetters from '../../../storeGetters';
import { PurchaseRequestMapper } from '../infrastructure';

function prepareSelectedCategoriesId(list = []) {
  if (Array.isArray(list)) return list;
  return list.toJS();
}

function getCompareResponsesLSKey(userId) {
  return `compare-responses--u-${userId}`;
}

export const getMyRequests =
  ({
    search = '',
    limit = 20,
    offset = 0,
    order,
    direction,
    selectedCategoriesId = [],
    selectedStatus = [],
    selectedResponseStatus = [],
    isArchive = false
  } = {}) =>
  async (dispatch) => {
    let baseUrl = `/api/requests/my?limit=${limit}&offset=${offset}`;

    if (search) {
      baseUrl += `&search=${search}`;
    }

    if (order) {
      baseUrl += `&orderBy=${order}&direction=${direction}`;
    }

    const preparedSelectedCategoriesId =
      prepareSelectedCategoriesId(selectedCategoriesId);
    if (preparedSelectedCategoriesId.length > 0) {
      baseUrl += preparedSelectedCategoriesId.reduce(
        (str, id) => `${str}&selCatsId[]=${id}`,
        ''
      );
    }

    dispatch(actions.setIsUploaded(false));

    try {
      const data = await request.post(baseUrl, {
        selectedStatus,
        selectedResponseStatus,
        isArchived: isArchive
      });
      const requests = data.requests.map((req) =>
        PurchaseRequestMapper.toDTO(req)
      );

      dispatch(actions.getMyRequests({ ...data, requests }));
    } catch (e) {
      console.error(e);
      dispatch(actions.setIsUploaded(true));
    }
  };

export const publishRequest = (id) => async (dispatch) => {
  await request.post(`/api/requests/${id}/publish`);
  dispatch(actions.publishRequest({ id }));
};

export const unpublishRequest = (id) => async (dispatch) => {
  await request.post(`/api/requests/${id}/unpublish`);
  dispatch(actions.unpublishRequest({ id }));
  dispatch(prActions.unpublishPurchaseRequest(id));
};

export const toggleArchiveRequest = (id, isArchived) => async (dispatch) => {
  await request.post(`/api/requests/${id}/archive`, { isArchived });
  dispatch(actions.archiveRequest({ id, isArchived }));
};

export const acceptRespond =
  ({ requestId, responseId }) =>
  async (dispatch) => {
    await request.post(
      `/api/requests/${requestId}/respond/${responseId}/accept`
    );
    dispatch(
      actions.changeResponseStatus({ requestId, responseId, status: accepted })
    );
    dispatch(prActions.setResponseStatus({ responseId, status: accepted }));
  };

export const refuseRespond =
  ({ requestId, responseId, text = '' }) =>
  async (dispatch) => {
    await request.post(
      `/api/requests/${requestId}/respond/${responseId}/reject`,
      { text }
    );
    dispatch(
      actions.changeResponseStatus({ requestId, responseId, status: rejected })
    );
    dispatch(prActions.setResponseStatus({ responseId, status: rejected }));
  };

export const setViewedRespond =
  ({ requestId, responseId }) =>
  async (dispatch) => {
    const response = await request.post(
      `/api/requests/${requestId}/respond/${responseId}/view`
    );
    dispatch(
      actions.changeResponseStatus({
        requestId,
        responseId,
        status: response.status
      })
    );
    dispatch(
      prActions.setResponseStatus({ responseId, status: response.status })
    );
  };

export const sendToReworkRespond =
  ({ requestId, responseId }) =>
  async (dispatch) => {
    dispatch(
      actions.changeResponseStatus({ requestId, responseId, status: rework })
    );
    dispatch(prActions.setResponseStatus({ responseId, status: rework }));
  };

export const viewAllResponses = (requestId) => async (dispatch) => {
  dispatch(actions.viewAllResponses({ requestId }));
};

export const setInitialCompareList = () => async (dispatch, getState) => {
  const currentUserId = storeGetters.getCurrentUserId(getState());

  const compareResponses = LS.getDataDefault(
    getCompareResponsesLSKey(currentUserId),
    {}
  );
  const compareRequest = getCompareRequest(compareResponses);

  dispatch(actions.updateCompareList({ compareResponses, compareRequest }));
};

export const addToCompare =
  ({ requestId, responseId, requestName }) =>
  async (dispatch, getState) => {
    const state = getState().toJS().myPurchaseRequests;
    const currentUserId = storeGetters.getCurrentUserId(getState());

    const compareList = state.compareResponses[requestId]?.list || [];

    compareList.push(responseId);

    const compareResponses = {
      ...state.compareResponses,
      [requestId]: { name: requestName, list: compareList }
    };

    LS.setData(getCompareResponsesLSKey(currentUserId), compareResponses);

    const compareRequest =
      compareResponses[requestId].list.length > 0
        ? requestId
        : getCompareRequest(compareResponses);

    dispatch(actions.updateCompareList({ compareResponses, compareRequest }));
  };

export const removeFromCompare =
  ({ requestId, responseId }) =>
  async (dispatch, getState) => {
    const state = getState().toJS().myPurchaseRequests;
    const compareList = state.compareResponses[requestId].list;
    const currentUserId = storeGetters.getCurrentUserId(getState());

    const compareResponses = {
      ...state.compareResponses,
      [requestId]: {
        ...state.compareResponses[requestId],
        list: compareList.filter((id) => id !== responseId)
      }
    };

    LS.setData(getCompareResponsesLSKey(currentUserId), compareResponses);

    const compareRequest =
      compareResponses[requestId].list.length > 0
        ? requestId
        : getCompareRequest(compareResponses);

    dispatch(actions.updateCompareList({ compareResponses, compareRequest }));
  };

export const deleteCompareList =
  ({ requestId }) =>
  async (dispatch, getState) => {
    const state = getState().toJS().myPurchaseRequests;
    const currentUserId = storeGetters.getCurrentUserId(getState());

    const compareResponses = {
      ...state.compareResponses,
      [requestId]: { ...state.compareResponses[requestId], list: [] }
    };

    LS.setData(getCompareResponsesLSKey(currentUserId), compareResponses);

    const compareRequest =
      compareResponses[requestId].list.length > 0
        ? requestId
        : getCompareRequest(compareResponses);

    dispatch(actions.updateCompareList({ compareResponses, compareRequest }));
  };

export const restoreCompareList =
  ({ requestId, responseList }) =>
  async (dispatch, getState) => {
    const state = getState().toJS().myPurchaseRequests;
    const currentUserId = storeGetters.getCurrentUserId(getState());

    const compareResponses = {
      ...state.compareResponses,
      [requestId]: { ...state.compareResponses[requestId], list: responseList }
    };

    LS.setData(getCompareResponsesLSKey(currentUserId), compareResponses);

    const compareRequest =
      compareResponses[requestId].length > 0
        ? requestId
        : getCompareRequest(compareResponses);
    dispatch(actions.updateCompareList({ compareResponses, compareRequest }));
  };

function getCompareRequest(requests) {
  if (Object.keys(requests).length === 0) {
    return null;
  }

  const foundCompareRequest = Object.keys(requests).find(
    (requestId) => requests[requestId].list.length > 0
  );

  return foundCompareRequest > 0 ? foundCompareRequest : null;
}
