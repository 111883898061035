import request from '../lib/request';

class RouteService {
  routeUrl = '/api/route';

  async getRoutesListByAuthorId(authorId, excludedUsers) {
    return request.post(`${this.routeUrl}/author/${authorId}`, {
      excludedUsers
    });
  }

  async getCompanyRoutesList() {
    return request.get(`${this.routeUrl}/company/available-routes`);
  }

  async getCompanyRoutesListWithoutMe() {
    return request.get(`${this.routeUrl}/company/available-routes/without-me`);
  }

  async saveRoute(route) {
    return request.post(`${this.routeUrl}/single/`, { route });
  }

  async deleteRoute(routeId) {
    return request.delete(`${this.routeUrl}/single/${routeId}`);
  }

  async updateRoute({ route, routeId }) {
    return request.put(`${this.routeUrl}/single/${routeId}`, { route });
  }

  async getRouteByRouteId(routeId) {
    return request.get(`${this.routeUrl}/single/${routeId}`);
  }

  async getCompanyRoutesCount() {
    return request.get(`${this.routeUrl}/count`);
  }

  async getExecutors() {
    return request.get(`${this.routeUrl}/executors`);
  }
}

export const routeService = new RouteService();
