import React, { useEffect, useRef } from 'react';
import DOMPurify from 'dompurify';
import ReactSummernote from 'react-summernote';
import 'react-summernote/dist/react-summernote.css';
import 'react-summernote/lang/summernote-ru-RU';
import 'bootstrap/js/modal';
import 'bootstrap/js/dropdown';
import 'bootstrap/js/tooltip';
import 'bootstrap/dist/css/bootstrap.css';
import { useIsomorphicLayoutEffect } from 'react-use';
import { createPortal } from 'react-dom';
import getBrowserLanguage from '../../../utils/languageDetector';
import { useRemountByKey } from '../../ChatWidget/hooks/remount';
import './Editor.css';

const fixEmptyText = (text) => (text === '<p><br></p>' ? '' : text);

const BtnUndo = ({ onClick, disabled = false }) => (
  <div id="button-undo" className="note-btn-group btn-group">
    <button
      type="button"
      className="note-btn btn btn-default btn-sm"
      tabIndex="-1"
      title=""
      data-original-title="Отменить"
      onClick={onClick}
      disabled={disabled}>
      <i className="note-icon-undo" />
    </button>
  </div>
);

const TOOLBAR_CN = 'note-toolbar panel-heading';
// NOTE: value set once at the start
const DescriptionEditor = ({
  show = true,
  value,
  placeholder,
  onChange,
  btnUndo,
  disabled = false
}) => {
  const { key, remount } = useRemountByKey();
  const containerRef = useRef();
  const toolbarRef = useRef();

  useIsomorphicLayoutEffect(() => {
    if (!containerRef.current) return;

    // Step 1: Don't change this, it has risk to missing link by node
    toolbarRef.current =
      containerRef.current.getElementsByClassName(TOOLBAR_CN);
  }, []);

  // NOTE: need for translate placeholder
  // if not end when switch language component don't change placeholder translate
  useEffect(() => {
    remount();
  }, [placeholder]);

  // Step 2: Get link of node
  const destination = toolbarRef.current && toolbarRef.current[0];

  if (!show) return null;
  return (
    <div className="react-summernote-wrapper" ref={containerRef}>
      {/* Order nodes is important! Set portal is first */}
      {destination &&
        createPortal(
          <BtnUndo onClick={btnUndo.onClick} disabled={btnUndo.disabled} />,
          destination
        )}
      <ReactSummernote
        key={key}
        options={{
          lang: `${getBrowserLanguage()}-${getBrowserLanguage().toUpperCase()}`,
          height: 133,
          dialogsInBody: true,
          placeholder,
          toolbar: [
            ['style', ['style']],
            ['font', ['bold', 'underline', 'italic']],
            ['para', ['ul', 'ol', 'paragraph']],
            ['view', ['codeview']],
            ['insert', []]
          ],
          disableDragAndDrop: true,
          disableResizeEditor: true
        }}
        value={value}
        onChange={(text) => onChange({ target: { value: fixEmptyText(text) } })}
        disabled={disabled}
        onInit={({ summernote }) =>
          value && summernote('code', DOMPurify.sanitize(value))
        }
      />
    </div>
  );
};

export default DescriptionEditor;
