import {
  useState,
  useCallback,
  useRef,
  useImperativeHandle,
  KeyboardEvent,
  ChangeEvent,
  forwardRef,
  useEffect
} from 'react';
import cn from 'classnames';
import { useInView } from 'react-intersection-observer';
import {
  ClickAwayListener,
  TextareaAutosize,
  Tooltip as MUITooltip
} from '@mui/material';
import { ButtonIcon } from '@/components/ButtonIcon';
import { Tooltip } from '@/components/Tooltip';
import { Keys } from '@/lib/accessibility';
import { isFunction } from '@/lib/utils';
import {
  ConfirmationInputProps,
  ConfirmationInputRef
} from './ConfirmationInput.types';
import styles from './ConfirmationInput.module.scss';

const CONTROL_ICON_SIZE = 18;

const tooltipClasses = {
  popper: styles.popper
};

export const ConfirmationInput = forwardRef<
  ConfirmationInputRef,
  ConfirmationInputProps
>(
  (
    {
      defaultValue,
      placeholder,
      className,
      disabled,
      minRows,
      maxRows,
      minLength,
      maxLength,
      onChange: change,
      onSubmit: submit,
      onCancel: cancel,
      onKeyDown: keyDown,
      confirmIcon,
      cancelIcon,
      tooltip = '',
      tooltipPlacement = 'top-start'
    },
    ref
  ) => {
    const { ref: containerRef, inView } = useInView();
    const textareaRef = useRef<HTMLTextAreaElement>(null);

    const [value, setValue] = useState(defaultValue || '');
    const [showControls, setShowControls] = useState(false);

    const onChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
      const newValue = event.target.value;
      const reg = /\r\n|\r|\n|\u2028|&nbsp/gm;
      const updatedNote = newValue.replace(reg, ' ');

      setValue(updatedNote);

      if (change && isFunction(change)) {
        change(updatedNote);
      }
    };

    const [focused, setFocused] = useState(false);
    const focusOn = useCallback(() => setFocused(true), []);
    const focusOff = useCallback(() => setFocused(false), []);

    const onFocus = useCallback(() => {
      textareaRef.current!.focus();
      focusOn();
    }, [focusOn]);

    const onBlur = useCallback(() => {
      textareaRef.current!.blur();
      focusOff();
    }, [focusOff]);

    useImperativeHandle(
      ref,
      () => ({
        focus: onFocus,
        blur: onBlur
      }),
      [onFocus, onBlur]
    );

    useEffect(() => {
      setValue(defaultValue || '');
    }, [defaultValue]);

    useEffect(() => {
      setShowControls(focused && inView);
    }, [inView, focused]);

    const onKeyDown = useCallback(
      (event: KeyboardEvent<HTMLTextAreaElement>) => {
        if (keyDown && isFunction(keyDown)) {
          keyDown(event);
          return;
        }

        switch (event.key) {
          case Keys.ESCAPE:
            onBlur();
            break;
          case Keys.ENTER: {
            event.preventDefault();
            break;
          }
          default:
            break;
        }
      },
      [onBlur, keyDown]
    );

    const onSubmit = useCallback(() => {
      if (submit && isFunction(submit)) {
        submit(value);
      }

      onBlur();
    }, [submit, value, onBlur]);

    const onCancel = useCallback(() => {
      if (cancel && isFunction(cancel)) {
        cancel();
      }

      setValue(defaultValue || '');
      onBlur();
    }, [cancel, onBlur, defaultValue, setValue]);

    const tooltipText = !focused ? tooltip : '';

    return (
      <div
        data-testid="confirmation-input"
        className={cn(styles.container, Boolean(className) && className, {
          [styles.focused]: focused
        })}
        ref={containerRef}
        onFocus={onFocus}>
        <Tooltip title={tooltipText} placement={tooltipPlacement}>
          <ClickAwayListener onClickAway={onCancel}>
            <div className={styles.inputContainer}>
              <MUITooltip
                classes={tooltipClasses}
                title={
                  <div className={styles.controls}>
                    <ButtonIcon
                      iconName={confirmIcon || 'ok'}
                      variant="medium"
                      size={CONTROL_ICON_SIZE}
                      onClick={onSubmit}
                    />
                    <ButtonIcon
                      iconName={cancelIcon || 'close'}
                      variant="medium"
                      size={CONTROL_ICON_SIZE}
                      onClick={onCancel}
                    />
                  </div>
                }
                placement="bottom-end"
                open={showControls}
                disableHoverListener>
                <TextareaAutosize
                  data-testid="confirmation-input-textarea"
                  ref={textareaRef}
                  className={styles.input}
                  value={value}
                  onChange={onChange}
                  placeholder={placeholder}
                  minRows={minRows}
                  maxRows={maxRows}
                  disabled={disabled}
                  minLength={minLength}
                  maxLength={maxLength}
                  onKeyDown={onKeyDown}
                />
              </MUITooltip>
            </div>
          </ClickAwayListener>
        </Tooltip>
      </div>
    );
  }
);
