import { getChatWidget } from '../../getterCommon';
import { REDUX_KEY } from './constants';

export const getConstructor = (state) => getChatWidget(state)[REDUX_KEY];
export const getConstructorState = (state) =>
  getChatWidget(state)[REDUX_KEY].constructors;
export const getConstructorUsersState = (state) =>
  getChatWidget(state)[REDUX_KEY].users;
export const getBuilderState = (state) =>
  getChatWidget(state)[REDUX_KEY].constructor;
export const getConstructorName = (state) =>
  getChatWidget(state)[REDUX_KEY].constructor.builder.name;

export const getIsCanBeDeleted = (state) =>
  getChatWidget(state)[REDUX_KEY].constructor.builder?.isCanBeDeleted;
