const mainPath = (state) => state.getIn(['pricesSuppliers', 'tablePrices']);

export default (state) => ({
  prices: () => mainPath(state).get('prices'),
  badges: () => mainPath(state).get('badges'),
  products: () => mainPath(state).get('products'),
  limit: () => mainPath(state).get('limit'),
  offset: () => mainPath(state).get('offset'),
  size: () => mainPath(state).get('size')
});
