export const colorList = {
  none: '#000000',
  draft: '#767B92',
  'sent-as-supplier': '#163881',
  'sent-as-buyer': '#1C50DE',
  'request-canceled': '#cccccc',
  viewed: '#00CEE0',
  rejected: '#FD4F53',
  clarification: '#9B51E0',
  accepted: '#37B450',
  'on-rework': '#F2C94C',
  'rework-completed': '#F2994A'
};

export const getColor = (statusName) => {
  if (colorList[statusName]) return colorList[statusName];
  throw new Error(`Not color for statusName "${statusName}"`);
};
