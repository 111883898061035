import React, { useCallback, useState } from 'react';
import Select, { components } from 'react-select';

function DynamicBorderSelect({
  id,
  value,
  options,
  onChange,
  onInputChange,
  onInputKeyDown,
  arrowRenderer,
  placeholder,
  noResultsText,
  isMulti = false,
  isClearable = false,
  isRemoveSelected = false
}) {
  const [isFocused, setIsFocused] = useState(false);

  const onFocus = useCallback(() => setIsFocused(true), []);
  const onBlur = useCallback(() => {
    setIsFocused(false);
  }, []);

  return (
    <div
      className={`dynamic-border-select${
        isFocused ? ' dynamic-border-select_focused' : ''
      }`}
      onFocus={onFocus}
      onBlur={onBlur}>
      <Select
        id={id}
        classNamePrefix="dbs"
        value={value}
        options={options}
        onChange={onChange}
        onInputChange={onInputChange}
        onInputKeyDown={onInputKeyDown}
        arrowRenderer={arrowRenderer}
        placeholder={placeholder}
        noResultsText={noResultsText}
        isMulti={isMulti}
        isClearable={isClearable}
        isRemoveSelected={isRemoveSelected}
        components={{
          DropdownIndicator: arrowRenderer
            ? (props) => <DropdownIcon {...props} icon={arrowRenderer} />
            : null,
          IndicatorSeparator: null
        }}
      />
    </div>
  );
}

function DropdownIcon(props) {
  return (
    <components.DropdownIndicator {...props}>
      {props.icon}
    </components.DropdownIndicator>
  );
}

export default DynamicBorderSelect;
