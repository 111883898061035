import React, { useCallback } from 'react';
import cn from 'classnames';
import Icon from 'react-components/src/icons';
import iconView from 'react-components/src/icons/constants';
import { useActions } from '@hooks/useActions';
import { useTranslate } from '../../../../../TranslateProvider';
import styles from './StepCard.module.css';
import { Badge } from '../badge';
import * as constructorActions from '../../../../../components/ChatWidget/modules/constructor/actions';
import * as modalActions from '../../../../../action-creators/modal';
import { isPublicationType, isTypeBegin } from '../helpers';
import { ActionButton } from '../buttons/actionButton';
import { CardContent } from './components';
import { useIsCanBeDeleted } from '../../../../../contexts/RouteIsCanBeDeletedContext';

const badges = {
  agreement: 'constructor.badge.agreement',
  introduction: 'constructor.badge.introduction',
  publication: 'constructor.badge.publication',
  begin: 'constructor.badge.begin'
};

export const StepCard = ({ type, deleteCardFromStep, stepId, card }) => {
  const isCanBeDeleted = useIsCanBeDeleted();
  const t = useTranslate();
  const {
    removeUserFromCard,
    changeUserIsExecuteAutomatically,
    addUserToCard,
    exchangeUser
  } = useActions(constructorActions);
  const { showModal } = useActions(modalActions);

  const onShowModalAddUser = useCallback(
    (user) => {
      showModal('MODAL_ADD_USER_TO_CONSTRUCTOR', {
        actionArgs: {
          stepId,
          stepType: type,
          cardId: card.id,
          user
        },
        action: addUserToCard
      });
    },
    [stepId, type, card?.id]
  );

  const onRemoveUserFromCard = useCallback(
    (userId) => {
      removeUserFromCard({ stepId, stepType: type, cardId: card.id, userId });
    },
    [stepId, card?.id, type]
  );

  const onChangeExecute = useCallback(
    (userId) => {
      changeUserIsExecuteAutomatically({
        stepId,
        stepType: type,
        cardId: card.id,
        userId
      });
    },
    [stepId, type, card?.id]
  );
  const onExchangeUser = useCallback(
    (deletedUserId) => {
      if (!isCanBeDeleted) return;
      showModal('MODAL_ADD_USER_TO_CONSTRUCTOR', {
        actionArgs: {
          stepId,
          stepType: type,
          cardId: card.id,
          deletedUserId
        },
        action: exchangeUser
      });
    },
    [stepId, type, card?.id]
  );
  return (
    <div
      id={isTypeBegin(type) ? 'begin-step-card' : card.id}
      className={cn(styles.wrapper, {
        [styles.small]: isTypeBegin(type)
      })}>
      <Badge badgeType={type} className={styles.badge} text={t(badges[type])} />
      <div className={styles.content}>
        <CardContent
          isError={card?.isError}
          type={type}
          users={card?.members}
          onExchangeUser={onExchangeUser}
          onShowModalAddUser={onShowModalAddUser}
          onChangeExecute={onChangeExecute}
          onRemoveUserFromCard={onRemoveUserFromCard}
        />
      </div>
      {isPublicationType(type) && (
        <span className={styles.publishIcon}>
          <Icon icon={iconView.Publish} size={14} />
        </span>
      )}

      {!isTypeBegin(type) && isCanBeDeleted && (
        <div className={styles.deleteIconWrapper}>
          <ActionButton
            icon={iconView.Trash}
            size={14}
            onClick={deleteCardFromStep}
          />
        </div>
      )}
    </div>
  );
};
