import fetch from 'isomorphic-fetch';
import { loadTable, removeFromTable } from '../table';
import { clearValidatorState, validate } from '../../validator';
import { showModal } from '../modal';
import { setSuccessMessage, setWarningMessage } from '../message';
import { publishCatalog } from '../catalog/publish';

// VALIDATION rules
const validationRules = {
  name: 'required',
  value: 'required'
};

export const loadProductFeature = (item) => ({
  type: 'PRODUCT_FEATURE_LOAD',
  item
});

export const trimProductFeatureField = (field) => ({
  type: 'PRODUCT_FEATURE_TRIM_FIELD',
  field
});

export const clearValidator = () => ({ type: 'VALIDATOR_CLEAR_STATE' });

export const updateProductFeatureField = (field, value) => (dispatch) => {
  validate(value, validationRules[field], dispatch, field);

  dispatch({
    type: 'PRODUCT_FEATURE_UPDATE_FIELD',
    field,
    value
  });
};

export const getProductFeatures =
  (catalogId, categoryId, productId) => (dispatch) =>
    fetch(
      `/api/catalogs/${catalogId}/categories/${categoryId}/products/${productId}/features`,
      {
        credentials: 'include'
      }
    )
      .then((response) => response.json())
      .then((data) => dispatch(loadTable(data, 'features')));

export const addProductCharacteristic = () => (dispatch) => {
  clearValidatorState(dispatch);
  dispatch(loadProductFeature({}));
  dispatch(
    showModal('ADD_CHARACTERISTIC', {
      captionKey: 'New feature',
      isCreate: true
    })
  );
};

export const addExistedProductCharacteristic =
  (catalogId, categoryId, productId) => (dispatch) =>
    dispatch(
      showModal('ADD_EXISTED_CHARACTERISTIC', {
        catalogId,
        categoryId,
        productId
      })
    );

export const editSelectProductCharacteristic = () => (dispatch, getState) => {
  const table = getState().getIn(['tables', 'features']);
  const selected = table.get('selected');
  const selectedId = selected && selected.first();
  if (!selectedId) return;

  const selectedFeature = table
    .get('items')
    .find((value) => value.get('id') === selectedId);
  clearValidatorState(dispatch);
  dispatch(loadProductFeature(selectedFeature.toJS()));
  dispatch(
    showModal('EDIT_CHARACTERISTIC', {
      captionKey: 'Edit feature',
      isCreate: false
    })
  );
};

export const editProductCharacteristic = (id) => (dispatch, getState) => {
  const table = getState().getIn(['tables', 'features']);

  const selectedFeature = table
    .get('items')
    .find((value) => value.get('id') === id);
  clearValidatorState(dispatch);
  dispatch(loadProductFeature(selectedFeature.toJS()));
  dispatch(
    showModal('EDIT_CHARACTERISTIC', {
      captionKey: 'Edit feature',
      isCreate: false,
      featureId: id
    })
  );
};

export const createProductFeature = () => (dispatch, getState) => {
  const catalogId = getState().getIn([
    'product',
    'item',
    'attributes',
    'catalogId'
  ]);
  const categoryId = getState().getIn(['category', 'item', 'id']);
  const productId = getState().getIn(['product', 'item', 'id']);
  const feature = getState().getIn(['product', 'item', 'feature']).toJS();

  return fetch(
    `/api/catalogs/${catalogId}/categories/${categoryId}/products/${productId}/features`,
    {
      credentials: 'include',
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(feature)
    }
  )
    .then((res) => res.json())
    .then((response) => {
      dispatch(getProductFeatures(catalogId, categoryId, productId));
      if (response.added) {
        dispatch(
          setWarningMessage({
            key: 'Feature already added'
          })
        );
      } else {
        dispatch(
          setSuccessMessage({
            key: 'The data was saved',
            linkKey: 'Publish catalog',
            linkClickHandler: (t) =>
              confirm(t('Catalogue will published in a few minutes')) &&
              dispatch(publishCatalog(catalogId))
          })
        );
      }
      return Promise.resolve();
    });
};

export const updateProductFeature = (featureId) => (dispatch, getState) => {
  const catalogId = getState().getIn([
    'product',
    'item',
    'attributes',
    'catalogId'
  ]);
  const categoryId = getState().getIn(['category', 'item', 'id']);
  const productId = getState().getIn(['product', 'item', 'id']);
  const feature = getState().getIn(['product', 'item', 'feature']).toJS();

  return fetch(
    `/api/catalogs/${catalogId}/categories/${categoryId}/products/${productId}/features/${featureId}`,
    {
      credentials: 'include',
      method: 'PATCH',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(feature)
    }
  ).then(() => {
    dispatch(getProductFeatures(catalogId, categoryId, productId));
    console.log('Feature for product ', productId, ' updated successfully');

    dispatch(
      setSuccessMessage({
        key: 'The data was saved',
        linkKey: 'Publish catalog',
        linkClickHandler: (t) =>
          confirm(t('Catalogue will published in a few minutes')) &&
          dispatch(publishCatalog(catalogId))
      })
    );

    return Promise.resolve();
  });
};

export const deleteSelectedProductFeatures = () => (dispatch, getState) => {
  const catalogId = getState().getIn([
    'product',
    'item',
    'attributes',
    'catalogId'
  ]);
  const categoryId = getState().getIn(['category', 'item', 'id']);
  const productId = getState().getIn(['product', 'item', 'id']);
  const selected = getState()
    .getIn(['tables', 'features', 'selected'])
    .toArray();

  fetch(
    `/api/catalogs/${catalogId}/categories/${categoryId}/products/${productId}/features/delete`,
    {
      credentials: 'include',
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ ids: selected })
    }
  ).then(({ ok, status, statusText }) => {
    selected.map((featureId) =>
      ok
        ? dispatch(removeFromTable([featureId], 'features'))
        : console.log('ERROR', 'PRODUCT FEATURE DELETE', status, statusText)
    );

    dispatch(
      setSuccessMessage({
        key: 'The data was saved',
        linkKey: 'Publish catalog',
        linkClickHandler: (t) =>
          confirm(t('Catalogue will published in a few minutes')) &&
          dispatch(publishCatalog(catalogId))
      })
    );

    return Promise.resolve();
  });
};

export const deleteProductFeatures = (featureId) => (dispatch, getState) => {
  const catalogId = getState().getIn([
    'product',
    'item',
    'attributes',
    'catalogId'
  ]);
  const categoryId = getState().getIn(['category', 'item', 'id']);
  const productId = getState().getIn(['product', 'item', 'id']);

  return fetch(
    `/api/catalogs/${catalogId}/categories/${categoryId}/products/${productId}/features/${featureId}`,
    {
      credentials: 'include',
      method: 'DELETE'
    }
  )
    .then(() => dispatch(removeFromTable([featureId], 'features')))
    .then(() =>
      dispatch(
        setSuccessMessage({
          key: 'The data was saved',
          linkKey: 'Publish catalog',
          linkClickHandler: (t) =>
            confirm(t('Catalogue will published in a few minutes')) &&
            dispatch(publishCatalog(catalogId))
        })
      )
    )
    .catch((e) => console.log(e));
};

export const getAllProductsFeatures =
  (catalogId, search = '', limit = 20, offset = 0) =>
  (dispatch) =>
    fetch(
      `/api/catalogs/${catalogId}/features?search=${search}&limit=${limit}&offset=${offset}`,
      {
        credentials: 'include',
        method: 'GET'
      }
    )
      .then((res) => res.json())
      // eslint-disable-next-line no-shadow
      .then(({ features, size, limit, offset }) =>
        dispatch({
          type: 'CATALOG:GET_FEATURES:SUCCESS',
          payload: { features, size, limit, offset }
        })
      );

export const getPublishedProductsFeatures =
  (catalogId, search = '', limit = 20, offset = 0) =>
  (dispatch) =>
    fetch(
      `/api/catalogs/${catalogId}/published_features?search=${search}&limit=${limit}&offset=${offset}`,
      {
        credentials: 'include',
        method: 'GET'
      }
    )
      .then((res) => res.json())
      // eslint-disable-next-line no-shadow
      .then(({ features, size, limit, offset }) =>
        dispatch({
          type: 'CATALOG:GET_FEATURES:SUCCESS',
          payload: { features, size, limit, offset }
        })
      );

export const selectFeature = (item) => ({
  type: 'CATALOG:SELECT_FEATURE',
  payload: { item }
});
export const clearSelectedFeatures = () => ({
  type: 'CATALOG:CLEAR_SELECTED_FEATURES'
});
export const updateSelectedFeatureValue = (name, unit, value) => ({
  type: 'CATALOG:UPDATE_SELECTED_FEATURE',
  payload: { name, unit, value }
});
export const modalAddFeature =
  (name, unit, catalogId, categoryId, productId) => (dispatch) =>
    dispatch(
      showModal('ADD_FEATURE', { name, unit, catalogId, categoryId, productId })
    );

export const addExistedFeatures =
  (catalogId, productId, features) => (dispatch) =>
    fetch(`/api/catalogs/${catalogId}/features/${productId}`, {
      credentials: 'include',
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ features })
    })
      .then((res) => res.json())
      .then(() => dispatch({ type: 'CATALOG:SET_FEATURES:SUCCESS' }));
