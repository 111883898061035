import { Map, Set } from 'immutable';

// TODO: add jsdoc! for arguments

export const consProduct = (product) => Map(product);

export const consListOfProducts = (listOfProducts = []) =>
  listOfProducts.reduce(
    (acc, product) => acc.set(product.id, consProduct(product)),
    Map()
  );

export const consFoundProducts = (data, newData) =>
  data && newData
    ? Map({
        products: consListOfProducts(newData.products),
        productsId: Set(newData.productsId),
        rawProducts: newData.products,
        totalProducts: newData.totalProducts,
        initialLoad: newData.initialLoad,
        limit: newData.limit,
        offset: newData.offset,
        currency: newData.currency,
        query: newData.query,
        notAdded: newData.notAdded,
        stockSize: newData.stockSize,
        selected: data.getIn(['foundProducts', 'selected']) || Set(),
        isEnabledFilterSelected: data.getIn([
          'foundProducts',
          'isEnabledFilterSelected'
        ]),
        orderBy: newData.orderBy,
        direction: newData.direction
      })
    : Map({
        products: consListOfProducts(),
        productsId: Set(),
        selected: Set(),
        initialLoad: false,
        isEnabledFilterSelected: false,
        orderBy: 'name',
        direction: 'ASC'
      });

export const getProductsOfFoundProducts = (foundProducts) =>
  foundProducts.get('products');

export const getRawProducts = (foundProducts) =>
  foundProducts.get('rawProducts');

export const getProductOfFoundProducts = (foundProducts, productSku) =>
  foundProducts.getIn(['products', productSku]);

export const getTotalProducts = (foundProducts) =>
  foundProducts.get('totalProducts');

export const isInitialLoad = (foundProducts) =>
  foundProducts.get('initialLoad');

export const getLimit = (foundProducts) => foundProducts.get('limit');

export const getOffset = (foundProducts) => foundProducts.get('offset');

export const getQuery = (foundProducts) => foundProducts.get('query');

export const getStockSize = (foundProducts) => foundProducts.get('stockSize');

export const getPropOfItem = (product, prop) => product.get(prop);
