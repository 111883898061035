import io from 'socket.io-client';
import { emit, handle } from 'core/data/messageBus/frontend';

let socket;

export const init = () => {
  socket = io('/message', {
    reconnection: true,
    reconnectionDelay: 1000,
    reconnectionDelayMax: 5000,
    reconnectionAttempts: 99999
  });

  socket.on('/to-frontend', ({ message, params }) => {
    emit(message, params);
  });

  handle({ name: 'some-msg' }, (...args) => {
    console.log('some-msg', args);
  });
};

export const emitToServer = (message, params) =>
  socket.emit('/to-backend', { message, params });
