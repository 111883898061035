export const validateEmail = (email) => {
  // regex from http://stackoverflow.com/questions/46155/validate-email-address-in-javascript
  // eslint-disable-next-line max-len,no-useless-escape
  const re =
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
};

export const validateSystemId = (systemId) => {
  const re = /^[a-zA-Z0-9_\-.]*$/;
  return re.test(systemId);
};

export const validateOnlyCyrillicOrLatin = (text) => {
  const re = /^[a-zA-Zа-яА-ЯёЁ\s]*$/;
  return re.test(text);
};

export const validateCyrLatDigitSpaceDash = (text) => {
  const re = /^[a-zA-Zа-яА-ЯёЁ0-9-\s]*$/;
  return re.test(text);
};

// const urlPattern = /^((http[s]?|ftp):\/)?\/?([^:\/\s]+)((\/\w+)*\/)([\w\-\.]+[^#?\s]+)(.*)?(#[\w\-]+)?$/;

export const validateUrl = (url) => {
  // eslint-disable-next-line max-len
  const re =
    /^((ftp|http|https):\/\/)?(www.)?(?!.*(ftp|http|https|www.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+((\/)[\w#]+)*(\/\w+\?[a-zA-Z0-9_]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?$/;
  return re.test(url);
};
