import React from 'react';

import { Icon, Button } from '@link/react-components';
import Title from './Title';
import EditorBlock from './EditorBlock';
import RespondTitleStatus from './RespondTitleStatus';
import FilesBlock from './FilesBlock';
import LinksListBlock from './LinksListBlock';
import SectionOffset from './SectionOffset';
import ChatDropzone from '../../Dropzone/Chat';
import ProductsTable from '../common/ProductsTable';
import CommentBlock from './components/CommentBlock';
import { useTranslate } from '../../../TranslateProvider';
import { ResponseExtraOptions } from './components/ResponseExtraOptions';

const BottomButtons = ({ show = true, deleteBtn, saveBtn, sendBtn }) => {
  if (!show) return null;

  return (
    <div className="form-respond-bottom-buttons">
      {deleteBtn.show && (
        <Button
          mode="outline"
          onClick={() => deleteBtn.onClick()}
          disabled={deleteBtn.disabled}
          className="btn-cancel">
          {deleteBtn.text}
        </Button>
      )}
      {saveBtn.show && (
        <Button
          mode="outline"
          onClick={() => saveBtn.onClick()}
          disabled={saveBtn.disabled}>
          {saveBtn.text}
        </Button>
      )}
      {sendBtn.show && (
        <Button
          mode="primary"
          onClick={() => sendBtn.onClick()}
          disabled={sendBtn.disabled}>
          {sendBtn.text}
        </Button>
      )}
    </div>
  );
};

const Notice = ({ show = false, text }) => {
  if (!show) return null;

  return <div className="form-respond-notice">{text}</div>;
};

const FormRespondGrid = ({
  importButton,

  show = true,
  title,
  status,
  extraOptions,
  editor,
  files,
  links,
  bottomButtons,
  notice,
  table,
  comment
}) => {
  if (!show) return null;
  const t = useTranslate();
  return (
    <div className="form-respond-grid">
      <div className="form-respond-grid__header">
        <div className="form-respond-grid__header-title">{title}</div>
        <div className="form-respond-grid__header-status">{status}</div>
      </div>
      <div>{comment}</div>
      <div className="form-respond-grid__editor">
        <div>
          <div className="form-respond-editor__title">
            {t('respondForm.form.editor.title')}
          </div>
          <div className="form-respond-editor__require-field">*</div>
        </div>
        {importButton}
      </div>
      <div className="form-respond-grid__editor">{table}</div>
      {Boolean(editor) && (
        <div className="form-respond-grid__editor">{editor}</div>
      )}
      <div className="form-respond-grid__extra-options">{extraOptions}</div>
      <div className="form-respond-grid__files">{files}</div>
      <div className="form-respond-grid__links">{links}</div>
      <div className="form-respond-grid__buttons">{bottomButtons}</div>
      <div className="form-respond-grid__notice">{notice}</div>
    </div>
  );
};

const LinksListOffset = ({ show = true, children }) => {
  if (!show) return null;
  return <div className="form-respond-links-list-block-offset">{children}</div>;
};

const FormRespond = ({ form, dragAndDrop }) => (
  <>
    <SectionOffset offset={20} />
    <ChatDropzone
      tkeyText={dragAndDrop.tkeyText}
      tkeySubText={dragAndDrop.tkeySubText}
      disabled={dragAndDrop.disabled}
      onDrop={dragAndDrop.onDrop}>
      <FormRespondGrid
        show={form.show}
        title={<Title color={form.titleColor} />}
        importButton={
          form.importButton.show && (
            <Button
              hasActive={false}
              hasHover
              mode="outline"
              before={<Icon {...form.importButton.iconProps} />}
              className="import-excel-btn"
              onClick={form.importButton.onClick}>
              {form.importButton.text}
            </Button>
          )
        }
        status={
          <RespondTitleStatus
            show={form.respondStatus.show}
            figureColor={form.respondStatus.figureColor}
            statusName={form.respondStatus.statusName}
          />
        }
        comment={
          <CommentBlock
            show={form.comment.show}
            title={form.comment.title}
            text={form.comment.text}
          />
        }
        editor={
          <EditorBlock
            text={form.editorText.text}
            editor={form.editorText.editor}
            buttons={form.editorText.buttons}
          />
        }
        table={
          form.productsList.products.length > 0 ? (
            <ProductsTable
              defaultProducts={form.productsList.defaultProducts}
              handleChangeProduct={form.productsList.onChange}
              products={form.productsList.products}
              editPriceAndCount={form.productsList.editMode}
              editMode={false}
              onFocusPrice={form.productsList.onFocusPrice}
              isShowExportToExcelBtn={form.isShowExportToExcelBtn}
              exportToExcelBtn={form.exportToExcelBtn}
            />
          ) : null
        }
        extraOptions={
          <ResponseExtraOptions
            mode={form.extraOptions.mode}
            extraOptions={form.extraOptions.options}
            setExtraOptions={form.extraOptions.setter}
          />
        }
        files={
          <LinksListOffset show={form.fileList.show}>
            <FilesBlock
              show={form.fileList.show}
              total={form.fileList.total}
              files={form.fileList.files}
              showAddBtn={form.fileList.showAddBtn}
              onClickAdd={form.fileList.onClickAdd}
            />
          </LinksListOffset>
        }
        links={
          <LinksListOffset show={form.linksList.show}>
            <LinksListBlock
              show={form.linksList.show}
              total={form.linksList.total}
              links={form.linksList.links}
              showAddBtn={form.linksList.showAddBtn}
              onClickAdd={form.linksList.onClickAdd}
            />
          </LinksListOffset>
        }
        bottomButtons={
          <BottomButtons
            show={form.buttons.show}
            deleteBtn={form.buttons.deleteBtn}
            saveBtn={form.buttons.saveBtn}
            sendBtn={form.buttons.sendBtn}
          />
        }
        notice={<Notice show={form.notice.show} text={form.notice.text} />}
      />
    </ChatDropzone>
  </>
);

export default FormRespond;
