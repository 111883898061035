const { makePolicy } = require('../../data/policy');

// Section name
const EMPLOYEES = 'EMPLOYEES';

// Use cases
const VIEW_EMPLOYEE_LIST = 'VIEW_EMPLOYEE_LIST';
const ADD_EMPLOYEE = 'ADD_EMPLOYEE';
const CHANGE_EMPLOYEE_DETAILS = 'CHANGE_EMPLOYEE_DETAILS';
const CHANGE_EMPLOYEE_ACCESS = 'CHANGE_EMPLOYEE_ACCESS';
const DELETE_EMPLOYEE = 'DELETE_EMPLOYEE';
const ASSIGNING_NEW_OWNER = 'ASSIGNING_NEW_OWNER';

const settings = [
  makePolicy(EMPLOYEES, VIEW_EMPLOYEE_LIST, {
    reader: true,
    writer: true,
    advanced: true
  }),
  makePolicy(EMPLOYEES, ADD_EMPLOYEE, {
    reader: false,
    writer: true,
    advanced: true
  }),
  makePolicy(EMPLOYEES, CHANGE_EMPLOYEE_DETAILS, {
    reader: false,
    writer: true,
    advanced: true
  }),
  makePolicy(EMPLOYEES, CHANGE_EMPLOYEE_ACCESS, {
    reader: false,
    writer: false,
    advanced: true
  }),
  makePolicy(EMPLOYEES, DELETE_EMPLOYEE, {
    reader: false,
    writer: false,
    advanced: true
  }),
  makePolicy(EMPLOYEES, ASSIGNING_NEW_OWNER, {
    reader: false,
    writer: false,
    advanced: false
  })
];

module.exports = {
  EMPLOYEES,
  VIEW_EMPLOYEE_LIST,
  ADD_EMPLOYEE,
  CHANGE_EMPLOYEE_DETAILS,
  CHANGE_EMPLOYEE_ACCESS,
  DELETE_EMPLOYEE,
  ASSIGNING_NEW_OWNER,
  settings
};
