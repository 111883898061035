import React, { useEffect, useState } from 'react';
import { useTranslate } from '@hooks';
import cs from 'classnames';
import { useSelector } from 'react-redux';
import ReactSelect from 'react-select';
import { Typeahead } from '@link/react-components';
import MainForm from '../../NewRequest/components/MainForm';
import { getTranslate } from '../utils';
import styles from './generalInfo.module.css';
import { purchaseRequestTypes } from '../../domain/entities';
import * as requestsActions from '../../../../action-creators/purchaseRequests';
import { formatRequestNumber } from '../../../../utils/utils';
import { routeService } from '../../../../api';
import * as storeGetters from '../../../../storeGetters';
import ErrorNotice from '../../NewRequest/components/ErrorNotice';
import { MainFormDatePicker } from '../../NewRequest/components/MainFormDatePicker';
import { MODES } from '../constants';
import { Popover } from '../../NewRequest/components/Popover';
import { InteractiveHelper } from '../../../InteractiveHelper';

const MAX_TITLE_LENGTH = 100;
const MAX_NUMBER_LENGTH = 12;
const MIN_TITLE_LENGTH = 4;

const RequiredField = ({ text }) => (
  <div className="help-block" style={{ marginTop: 0 }}>
    {text}
  </div>
);

const Editable = ({
  className,
  body,
  setBody,
  isErrorShown,
  request,
  typeaheadRef,
  typeaheadTitleKey
}) => {
  const t = useTranslate();

  const { title, number, routeId, route } = body;
  const { setTitle, setNumber, setRouteId, setAuthorId, setRoute } = setBody;
  const user = useSelector(storeGetters.getCurrentUser);
  const [id, setId] = useState(formatRequestNumber(request.id));
  const [routes, setRoutes] = useState([]);

  useEffect(() => {
    routeService
      .getCompanyRoutesListWithoutMe()
      .then((res) => setRoutes(res.result));
  }, []);
  useEffect(() => {
    (async function getNumber() {
      if (!request.id) {
        const requestId = await requestsActions.getRequestNumber();
        const formatNumber = formatRequestNumber(requestId);
        if (!number) {
          setNumber(formatNumber);
        }
        setId(formatNumber);
      }
    })();
    setAuthorId(user.toJS().id);
  }, []);

  const onChange =
    (setState) =>
    ({ target: { value } }) =>
      setState(value);

  const routeOptions = routes.map((currentRoute) => ({
    value: currentRoute.id,
    label: currentRoute.name
  }));

  const handleInputChange = (inputValue) => {
    setRouteId(inputValue.value);
    setRoute(inputValue);
  };

  return (
    <div className={className}>
      <div className="new-request-title">
        {getTranslate(t, 'generalInformation')}
      </div>
      <div className={styles.block}>
        <div className={styles.blockTitle}>
          <div className="control-label-row control-label" id="title-form">
            {getTranslate(t, 'requestNumber')}
          </div>
          <span className={styles.required}>*</span>
          <InteractiveHelper top={-3}>
            <Popover text={getTranslate(t, 'idHelpInfo')} />
          </InteractiveHelper>
        </div>
        <div className={styles.dataBlock}>
          <input
            className={cs(
              'form-control',
              styles.quarterSize,
              styles.readOnly,
              styles.dash
            )}
            value={id}
          />
          <div className={styles.dash}>—</div>
          <ErrorNotice isError={isErrorShown.publish && number.length === 0}>
            <input
              className={cs('form-control', styles.quarterSize)}
              value={number}
              onChange={onChange(setNumber)}
              maxLength={MAX_NUMBER_LENGTH}
            />
          </ErrorNotice>
        </div>
        {isErrorShown.publish && number.length === 0 && (
          <RequiredField text={getTranslate(t, 'required')} />
        )}
      </div>
      <hr className={styles.horizontalRule} />
      <div className={styles.block}>
        <div className={styles.blockTitle}>
          <div className="control-label-row control-label">
            {getTranslate(t, 'approvalRoute')}
          </div>
          <span className={styles.required}>*</span>
        </div>
        <div className={styles.dataBlock}>
          <ErrorNotice
            isError={isErrorShown.publish && routeId === null}
            className={styles.fullSize}>
            <ReactSelect
              value={route}
              noResultsText={getTranslate(t, 'noResults')}
              className={cs(styles.select, styles.fullSize)}
              options={routeOptions}
              placeholder={getTranslate(t, 'selectRoute')}
              onChange={handleInputChange}
              clearable={false}
            />
          </ErrorNotice>
        </div>
        {isErrorShown.publish && routeId === null && (
          <RequiredField text={getTranslate(t, 'required')} />
        )}
      </div>
      <hr className={styles.horizontalRule} />
      <div className={styles.block}>
        <div className={styles.blockTitle}>
          <div className="control-label-row control-label">
            {getTranslate(t, 'requestTitle')}
          </div>
          <span className={styles.required}>*</span>
          <small className={cs('new-request-title-length', styles.counter)}>
            {`${title.length}/${MAX_TITLE_LENGTH}`}
          </small>
        </div>
        <div className={styles.dataBlock}>
          <ErrorNotice
            isError={
              (isErrorShown.save || isErrorShown.publish) &&
              title.length < MIN_TITLE_LENGTH
            }
            className={styles.fullSize}>
            <Typeahead
              store={typeaheadTitleKey}
              ref={typeaheadRef}
              initialValue={title}
              placeholder={getTranslate(t, 'requestTitle')}
              maxLength={MAX_TITLE_LENGTH}
              data-testid="new-request-name-input"
              onChange={setTitle}
            />
          </ErrorNotice>
        </div>
        {(isErrorShown.save || isErrorShown.publish) &&
          title.length < MIN_TITLE_LENGTH && (
            <RequiredField>{getTranslate(t, 'minLength')}</RequiredField>
          )}
      </div>
      <div className={styles.block}>
        <div className="control-label-row control-label">
          {getTranslate(t, 'requestAuthor')}
        </div>
        <div className={styles.dataBlock}>
          <input
            className={cs('form-control', styles.readOnly, styles.fullSize)}
            value={`${user.toJS().firstName} ${user.toJS().lastName}`}
          />
        </div>
      </div>
    </div>
  );
};

const Readonly = ({ className }) => {
  const t = useTranslate();
  const request = useSelector(storeGetters.getCurrentPurchaseRequest).toJS();
  const user = useSelector(storeGetters.getCurrentUser).toJS();
  const [authorInfo, setAuthorInfo] = useState({
    firstName: '',
    lastName: ''
  });
  const [route, setRoute] = useState({
    label: request?.route?.name,
    value: request?.route?.id
  });

  useEffect(() => {
    if (request?.id) {
      setRoute({ label: request.route?.name, value: request.route?.id });
      setAuthorInfo({
        ...request.authorInfo
      });
    }
  }, [request?.id]);

  return (
    <div className={className}>
      <div className={styles.block}>
        <div className={styles.blockTitle}>
          <div className="control-label-row control-label">
            {getTranslate(t, 'ETA')}
          </div>
        </div>
        <div className={styles.dataBlock}>
          <MainFormDatePicker
            disabled
            className="form-control"
            lang={user.language}
            weekStartsOn={user.language === 'en' ? 0 : 1}
            value={request.date}
            calendarPlacement="bottom"
            dateFormat="DD/MM/YYYY"
          />
        </div>
      </div>

      <div className={styles.block}>
        <div className={styles.blockTitle}>
          <div className="control-label-row control-label">
            {getTranslate(t, 'approvalRoute')}
          </div>
        </div>
        <div className={styles.dataBlock}>
          <ReactSelect
            value={route}
            className={cs(
              styles.select,
              styles.fullSize,
              styles.readOnly,
              styles.selectDisabled
            )}
            disabled
            placeholder={getTranslate(t, 'selectRoute')}
          />
        </div>
      </div>
      <div className={styles.block}>
        <div className="control-label-row control-label">
          {getTranslate(t, 'requestAuthor')}
        </div>
        <div className={styles.dataBlock}>
          <input
            disabled
            className={cs('form-control', styles.readOnly, styles.fullSize)}
            value={`${authorInfo.firstName} ${authorInfo.lastName}`}
          />
        </div>
      </div>
    </div>
  );
};

const GeneralContent = ({
  className,
  request,
  body,
  setBody,
  isErrorShown,
  mode,
  typeaheadRef,
  typeaheadTitleKey
}) => {
  switch (mode) {
    case MODES.edit:
      return (
        <Editable
          className={className}
          body={body}
          isErrorShown={isErrorShown}
          request={request}
          setBody={setBody}
          typeaheadRef={typeaheadRef}
          typeaheadTitleKey={typeaheadTitleKey}
        />
      );
    case MODES.readonly:
      return <Readonly className={className} />;
    default:
      console.error('no such Mode');
      return <></>;
  }
};

export default function GeneralInfo({
  request = {},
  isErrorShown,
  body,
  setBody,
  requestType,
  mode = MODES.edit,
  typeaheadRef,
  typeaheadTitleKey
}) {
  if (requestType === purchaseRequestTypes.regular) {
    return (
      <MainForm
        request={request}
        body={body}
        setBody={setBody}
        isErrorShown={isErrorShown}
        typeaheadRef={typeaheadRef}
        typeaheadTitleKey={typeaheadTitleKey}
      />
    );
  }
  return (
    <GeneralContent
      mode={mode}
      className={styles.generalContent}
      request={request}
      body={body}
      setBody={setBody}
      isErrorShown={isErrorShown}
      typeaheadRef={typeaheadRef}
      typeaheadTitleKey={typeaheadTitleKey}
    />
  );
}
