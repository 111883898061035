import React, { Component } from 'react';
import PropTypes from 'prop-types';
import block from 'bem-cn-lite';
import { translate } from 'react-i18next';
import {
  Modal,
  Form,
  FormGroup,
  FormControl,
  ControlLabel,
  Button,
  HelpBlock
} from 'react-bootstrap';
import '../styles/modal_theme_default.styl';

import { ModalContainer } from '../elements';

const b = block('modal-container');

class ModalAddCustomerCategory extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pristine: true
    };
    this.inputProps = this.inputProps.bind(this);
    this.isValidNameField = this.isValidNameField.bind(this);
    this.addCategory = this.addCategory.bind(this);
  }

  getChildContext() {
    return { b };
  }

  UNSAFE_componentWillMount() {}

  getProperty(field) {
    return this.props.feature.has(field) ? this.props.feature.get(field) : '';
  }

  isValidNameField() {
    return (
      this.props.newCategory.get('name').length < 1 ||
      /^\s+$/.test(this.props.newCategory.get('name'))
    );
  }

  asterisk() {
    // eslint-disable-line
    return <span style={{ color: 'red' }}>*</span>;
  }

  inputProps(field) {
    return {
      value: this.props.newCategory.get(field),
      onChange: (e) => this.props.updateNewCategoryName(field, e.target.value)
    };
  }

  addCategory() {
    const {
      hideModalDialog,
      addCategory,
      getGroups,
      onSubmit = () => {}
    } = this.props;

    addCategory().then(() => {
      onSubmit();
      getGroups();
      hideModalDialog();
    });
  }

  render() {
    const { t, captionKey, hideModalDialog, newCategory } = this.props;

    return (
      <ModalContainer mods={{ theme: 'default' }}>
        <Modal id="addCategory" onHide={() => hideModalDialog()} show>
          <Modal.Header>
            <Modal.Title>
              {t(captionKey)}
              <span
                className="modal-header-cross pull-right"
                onClick={() => hideModalDialog()}
              />
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <FormGroup
                controlId="name"
                validationState={
                  this.isValidNameField() && !this.state.pristine
                    ? 'error'
                    : null
                }
                style={{ overflow: 'hidden' }}>
                <ControlLabel>
                  {`${t('Name')} `}
                  {this.asterisk()}
                </ControlLabel>
                <FormControl
                  type="text"
                  {...this.inputProps('name')}
                  maxLength="60"
                  onKeyDown={(e) => {
                    this.setState({ pristine: false });
                    return e.keyCode === 13 ? e.preventDefault() : null;
                  }}
                />
                <h6 className="pull-right">
                  <small>
                    {newCategory.get('name').length}
                    /60
                  </small>
                </h6>
                {this.isValidNameField() && !this.state.pristine && (
                  <HelpBlock style={{ marginBottom: 0 }}>
                    {t('Required fields empty')}
                  </HelpBlock>
                )}
              </FormGroup>
            </Form>
            {this.asterisk()}
            {` ${t('Required fields')}`}
          </Modal.Body>
          <Modal.Footer>
            <Button
              id="cancel"
              className="pull-left"
              onClick={() => hideModalDialog()}>
              {t('Cancel')}
            </Button>
            <Button
              id="saveCharacteristicButton"
              bsStyle="primary"
              disabled={this.isValidNameField()}
              onClick={this.addCategory}>
              {t('New group')}
            </Button>
          </Modal.Footer>
        </Modal>
      </ModalContainer>
    );
  }
}

ModalAddCustomerCategory.propTypes = {
  t: PropTypes.func.isRequired
};

ModalAddCustomerCategory.childContextTypes = {
  b: PropTypes.func
};

export default translate(['ui'], { wait: true })(ModalAddCustomerCategory);
