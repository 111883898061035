const { indexOf, append, remove, isEmpty } = require('ramda');
const { defStruct } = require('../../lib');

/**
 * @typedef SelectedList
 */
const {
  makeSelectedList: makeSelList,
  getIds,
  isSelectedList
} = defStruct('SelectedList', ['ids']);

/**
 * Creates a selected list data type
 *
 * @param {object} params
 * @param {[number]} params.ids
 * @returns {SelectedList}
 */
const makeSelectedList = ({ ids = [] } = {}) => makeSelList(ids);
exports.makeSelectedList = makeSelectedList;

/**
 * Gives list of id from selected list
 *
 * @param {SelectedList} selectedList
 * @returns {[number]}
 */
exports.getIds = (selectedList) => getIds(selectedList);

/**
 * Checks if is selected list
 *
 * @param {*} a
 * @returns {boolean}
 */
exports.isSelectedList = (a) => isSelectedList(a);

/**
 * Checks if id contains in selected list
 *
 * @param {number} id
 * @param {SelectedList} selectedList
 * @returns {boolean}
 */
const contains = (id, selectedList) => {
  const indexOfId = indexOf(id, getIds(selectedList));

  return indexOfId !== -1;
};
exports.contains = contains;

/**
 * Adds at selected list id
 *
 * @param {number} id
 * @param {SelectedList} selectedList
 * @returns {SelectedList}
 */
exports.add = (id, selectedList) => {
  return contains(id, selectedList)
    ? selectedList
    : makeSelectedList({ ids: append(id, getIds(selectedList)) });
};

/**
 * Removes at selected list id
 *
 * @param {number} id
 * @param {SelectedList} selectedList
 * @returns {SelectedList}
 */
exports.remove = (id, selectedList) => {
  const indexOfId = indexOf(id, getIds(selectedList));

  if (indexOfId === -1) return selectedList;

  const selectedListWithoutId = remove(indexOfId, 1, getIds(selectedList));
  return makeSelectedList({ ids: selectedListWithoutId });
};

/**
 * Checks if selected list is empty
 *
 * @param {SelectedList} selectedList
 * @returns {boolean}
 */
exports.isEmpty = (selectedList) => isEmpty(getIds(selectedList));

/**
 * Gives first value from selected list
 *
 * @param {SelectedList} selectedList
 * @returns {number}
 */
exports.getFirst = (selectedList) => getIds(selectedList)[0];

/**
 * Gives first value from selected list
 *
 * @param {number} number
 * @param {SelectedList} selectedList
 * @returns {boolean}
 */
exports.lengthIsMoreThan = (number, selectedList) =>
  getIds(selectedList).length > number;
