import { useDispatch } from 'react-redux';
import { useCallback } from 'react';
import * as storage from '../../modules/response-chat/storage';
import { setViewedRespond } from '../PurchaseRequests/MyRequests/storage';
import { noop } from '../../utils/utils';

export function useOpenResponseChat({
  response,
  requestId = response?.attrs?.purchaseRequest?.id,
  onOpened = noop
}) {
  const dispatch = useDispatch();

  const respondedUserId = response?.attrs?.purchaseRequest
    ? response.attrs.purchaseRequest.respondedUser
    : null;
  const responseFrom = response?.id ? response.from : null;

  const getChat = useCallback(
    async () =>
      storage.open(requestId, response?.id, responseFrom, respondedUserId),
    [requestId, response?.id, responseFrom, respondedUserId]
  );

  const handleOpened = useCallback(() => {
    if (response.id) {
      dispatch(setViewedRespond({ requestId, responseId: response.id }));
    }
    onOpened();
  }, [requestId, response?.id]);

  return { getChat, onOpened: handleOpened };
}
