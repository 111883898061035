import React from 'react';
import cn from 'classnames';
import Icon from 'react-components/src/icons';
import iconsView from 'react-components/src/icons/constants';
import styles from './EmojiSelect.module.css';
import { ChatButton } from '../new-components/chat-button/ChatButton';
import { useIsClickOutside } from '../../../../../hooks/useIsClickOutside';

export const EmojiSelect = ({ children }) => {
  const { ref, isShow, setIsShow } = useIsClickOutside(false);
  return (
    <div className={styles.emojiSection} ref={ref}>
      <ChatButton
        isActive={isShow}
        icon={<Icon icon={iconsView.Emoji} size={18} />}
        onClick={() => setIsShow((prevState) => !prevState)}
      />
      <div
        className={cn(styles.emojiPicker, {
          [styles.emojiPickerShow]: isShow
        })}>
        {children}
      </div>
    </div>
  );
};
