import React from 'react';
import { useNavigate } from '@hooks';
import ButtonIcon from '../../ChatWidget/ButtonIcon';
import { useTranslate } from '../../../TranslateProvider';
import './style.css';

const GoBack = (props) => {
  const t = useTranslate();
  const navigate = useNavigate();

  return (
    <ButtonIcon
      icon="arrow-left-circle"
      className="button-go-back"
      type="button"
      onClick={() => navigate(-1)}
      {...props}>
      {t('back')}
    </ButtonIcon>
  );
};

export default GoBack;
