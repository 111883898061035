import purchaseRequestMSG from 'backend/src/modules/purchaseRequests/domain/entities/messages';
import { emitToServer } from '../../storage/message';

export const createPurchaseRequest = (data) => {
  emitToServer(purchaseRequestMSG.createCMD, { data });
};

export const getPurchaseRequest = (data) => {
  emitToServer(purchaseRequestMSG.purchaseRequestQRY.get, data);
};
