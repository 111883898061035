import React from 'react';
import { Link } from 'react-router-dom';

const LinkBtn = ({
  type,
  target = null,
  to = '/',
  children,
  onClick: onClickFn
}) => {
  const onClick = async () => {
    if (onClickFn) {
      await onClickFn();
    }
  };
  if (type === 'link') {
    return (
      <Link to={to} target={target} className="snm-link" onClick={onClick}>
        {children}
      </Link>
    );
  }
  if (type === 'button') {
    return (
      <button type="button" className="snm-link" onClick={onClick}>
        {children}
      </button>
    );
  }
  return null;
};

export default LinkBtn;
