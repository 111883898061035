import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as getters from '../../getters';
import * as storage from '../../storage';
import ListView from './tabs/list';
import { STATUS_SECTIONS } from './constants';

const TaskListContent = () => {
  const dispatch = useDispatch();
  const taskList = useSelector(getters.getSortedTasks);
  const taskBadges = useSelector(getters.getTaskBadges);

  useEffect(() => {
    dispatch(storage.getAllCompanyTasks());
  }, []);

  return (
    <ListView
      taskList={taskList}
      badges={taskBadges}
      statusList={STATUS_SECTIONS}
    />
  );
};

export default TaskListContent;
