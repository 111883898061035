export const NUMBER_OF_DIGITS = {
  UNITS: 0,
  DECIMAL: 1,
  HUNDREDS: 2
};

export const TYPES_OF_CATEGORY = {
  SUM_OF_ORDER: 1,
  AMOUNT_OF_PRODUCT_ORDER: 2,
  SPECIAL: 3
};
