import React from 'react';
import cs from 'classnames';
import styles from './selectIcon.module.css';

export const SelectIcon = ({ status }) => (
  <div className={cs(styles.icon, styles[status])}>
    <svg viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        /* eslint-disable-next-line max-len */
        d="M5.4 9L7.8 11.4L12.6 6.6M17 9C17 13.4183 13.4183 17 9 17C4.58172 17 1 13.4183 1 9C1 4.58172 4.58172 1 9 1C13.4183 1 17 4.58172 17 9Z"
        stroke="#B9BFCF"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  </div>
);
