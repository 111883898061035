import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { ModalAddDiscount } from '../components/Modal';

import * as priceProductsActions from '../action-creators/price-products-list';
import * as modalAction from '../action-creators/modal';
import * as servicesAction from '../action-creators/services';

const ContModalAddDiscount = (props) => <ModalAddDiscount {...props} />;

export default connect(
  (state) => ({
    user: state.getIn(['user', 'user']),
    search: state.getIn(['tablesNew', 'priceProducts', 'search']),
    limit: state.getIn(['tablesNew', 'priceProducts', 'limit']),
    offset: state.getIn(['tablesNew', 'priceProducts', 'offset']),
    filters: state.getIn(['tablesNew', 'priceProducts', 'filterValues']),
    direction: state.getIn(['tablesNew', 'priceProducts', 'direction']),
    orderBy: state.getIn(['tablesNew', 'priceProducts', 'orderBy'])
  }),
  (dispatch) =>
    bindActionCreators(
      {
        submit: priceProductsActions.setDiscountToProducts,
        hideModalDialog: modalAction.hideModalDialog,
        getProducts: priceProductsActions.getProducts,
        gaSend: servicesAction.gaSend
      },
      dispatch
    )
)(ContModalAddDiscount);
