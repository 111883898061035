import React, { Component } from 'react';
import { translate } from 'react-i18next';
import { List, Map, OrderedMap } from 'immutable';
import { isMessengerService } from '../../lib/goodwix';

import Table from '../../components/TableNew';

class TablePermissions extends Component {
  static orderPermissions = isMessengerService()
    ? List(['company', 'employees', 'purchaseRequests'])
    : List([
        'company',
        'billing',
        'employees',
        'catalogs',
        'stocks',
        'prices',
        'customerOrders',
        'supplierOrders',
        'purchaseRequests',
        'customers',
        'suppliers'
      ]);

  static sortPermissions(permissions) {
    return TablePermissions.orderPermissions.reduce(
      (acc, key) => acc.set(key, permissions.get(key)),
      OrderedMap()
    );
  }

  static permissionToRenderStruction(permissions) {
    return permissions.reduce(
      (acc, value, key) => acc.push(Map({ keyName: key }).concat(value)),
      List()
    );
  }

  determineRead(keyName, permissions) {
    if (keyName === 'employees' || keyName === 'company') return true;
    if (permissions.getIn([keyName, 'update'])) return true;
    return !permissions.getIn([keyName, 'read']); // toggle
  }

  determineUpdate(keyName, permissions) {
    if (permissions.getIn([keyName, 'delete'])) return true;
    return !permissions.getIn([keyName, 'update']); // toggle
  }

  onSelect(section, { keyName }) {
    const { permissions, onChange } = this.props;

    let updatedPermisitons = permissions;
    if (section === 'not-access') {
      updatedPermisitons = updatedPermisitons
        .setIn([keyName, 'read'], false)
        .setIn([keyName, 'update'], false)
        .setIn([keyName, 'delete'], false);
      if (updatedPermisitons.hasIn([keyName, 'editPermissions'])) {
        updatedPermisitons = updatedPermisitons.setIn(
          [keyName, 'editPermissions'],
          false
        );
      }
    } else if (section === 'read') {
      updatedPermisitons = updatedPermisitons
        .setIn(
          [keyName, 'read'],
          this.determineRead(keyName, updatedPermisitons)
        )
        .setIn([keyName, 'update'], false)
        .setIn([keyName, 'delete'], false);
    } else if (section === 'update') {
      updatedPermisitons = updatedPermisitons
        .setIn([keyName, 'read'], true)
        .setIn(
          [keyName, 'update'],
          this.determineUpdate(keyName, updatedPermisitons)
        )
        .setIn([keyName, 'delete'], false);
    } else if (section === 'full-access') {
      updatedPermisitons = updatedPermisitons
        .setIn([keyName, 'read'], true)
        .setIn([keyName, 'update'], true)
        .setIn(
          [keyName, 'delete'],
          !updatedPermisitons.getIn([keyName, 'delete'])
        );
      if (updatedPermisitons.hasIn([keyName, 'editPermissions'])) {
        updatedPermisitons = updatedPermisitons.setIn(
          [keyName, 'editPermissions'],
          true
        );
      }
    }

    onChange(updatedPermisitons);
  }

  onSelectBind(section) {
    return this.onSelect.bind(this, section);
  }

  get permissionsForRender() {
    const { permissions, billingAllowed } = this.props;
    const { sortPermissions, permissionToRenderStruction } = TablePermissions;
    let sortedPermissions = permissionToRenderStruction(
      sortPermissions(permissions)
    );
    if (!billingAllowed) {
      sortedPermissions = sortedPermissions.filter(
        (i) => i.get('keyName') !== 'billing'
      );
    }
    return sortedPermissions;
  }

  render() {
    const { t, readOnly } = this.props;

    return (
      <Table
        theme="permissions"
        columns={[
          Table.ColText({
            accessor: ({ keyName }) => t(keyName),
            title: t('Name of section'),
            width: '31%'
          }),
          Table.ColCheckbox({
            title: t('Not access'),
            accessor: (item) => {
              const copyObj = { ...item };
              delete copyObj.keyName;
              return Object.keys(copyObj).every(
                (key) => copyObj[key] === false
              );
            },
            onSelect: this.onSelectBind('not-access'),
            options: { textAlign: 'center' },
            hide: (item) =>
              item.keyName === 'employees' || item.keyName === 'company',
            readOnly: () => readOnly
          }),
          Table.ColCheckbox({
            accessor: 'read',
            onSelect: this.onSelectBind('read'),
            options: { textAlign: 'center' },
            readOnly: () => readOnly
          }),
          Table.ColCheckbox({
            accessor: 'update',
            onSelect: this.onSelectBind('update'),
            options: { textAlign: 'center' },
            hide: (item) => item.keyName === 'billing',
            readOnly: () => readOnly
          }),
          Table.ColCheckbox({
            title: t('Full access'),
            accessor: (item) => {
              const copyObj = { ...item };
              delete copyObj.keyName;
              return Object.keys(copyObj).every((key) => copyObj[key] === true);
            },
            readOnly: (item) => {
              if (readOnly) return true;

              if (item.keyName === 'company') {
                return true;
              }
              return false;
            },
            onSelect: this.onSelectBind('full-access'),
            options: { textAlign: 'center' }
          })
        ]}
        data={this.permissionsForRender}
      />
    );
  }
}

export default translate(['ui'], { wait: true })(TablePermissions);
