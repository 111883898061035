import React, { useCallback, useMemo, useState } from 'react';
import { SearchInput, DynamicList, Button } from '@link/react-components';
import { useDebounce, useTranslate } from '@hooks';
import { companyService } from '../../../../api';
import { CompanyCard } from '../CompanyCard';
import { NoSearchResult } from '../NoSearchResult';
import { InvitePartnerButton } from '../InvitePartnerButton';
import { calcOffset } from '../../utils';
import classes from './CompaniesList.module.css';
import { useChooseSupplierCompanyWizard } from '../../../../components/Wizards';

const ITEMS_PER_PAGE = 20;
const GAP_BETWEEN_ITEMS = 12;
const SEARCH_TYPING_DEBOUNCE = 500;

export function CompaniesList() {
  const t = useTranslate();
  const [search, setSearch] = useState('');
  const { open } = useChooseSupplierCompanyWizard();

  const onSearchTyping = useDebounce((searchValue) => {
    setSearch(searchValue);
  }, SEARCH_TYPING_DEBOUNCE);

  const onLoad = useCallback(
    async (page, limit) =>
      companyService.getCompanies({
        limit,
        offset: calcOffset(page, limit),
        search
      }),
    [search]
  );

  const provider = useMemo(
    () => ({
      load: onLoad
    }),
    [onLoad]
  );

  return (
    <div className={classes.container}>
      <div className={classes.controlPanel}>
        <div className={classes.searchInput}>
          <SearchInput
            defaultValue={search}
            onSearch={setSearch}
            onCleanUp={() => setSearch('')}
            onChange={onSearchTyping}
            placeholder={t('modules.company.inputs.search.placeholder')}
          />
        </div>
        <div className={classes.actions}>
          <Button onClick={() => open()} mode="text" size="small">
            {t('modules.company.groupsList')}
          </Button>
          <InvitePartnerButton />
        </div>
      </div>
      <div className={classes.list}>
        <DynamicList
          provider={provider}
          renderItem={(company, updateCompany) => (
            <CompanyCard company={company} updateCompany={updateCompany} />
          )}
          noResultsScreen={<NoSearchResult />}
          batchSize={ITEMS_PER_PAGE}
          gap={GAP_BETWEEN_ITEMS}
        />
      </div>
    </div>
  );
}
