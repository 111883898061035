export const maskList = {
  amount: /^\d+(?:([.,])\d{0,2})?$/
};

export const checkMask = {
  amount: checkAmountMask
};

function checkAmountMask(value) {
  if (!value?.length) return true;

  return maskList.amount.test(value);
}
