import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Map, List } from 'immutable';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { translate } from 'react-i18next';
import block from 'bem-cn-lite';

import './style.styl';

const b = block('set-category');

class SetCategory extends Component {
  static TOP_LEVEL = null;

  static NO_SELECTED = null;

  static MAX_LENGTH = 22;

  static cliping(string, maxLengthParam) {
    const maxLength =
      maxLengthParam === undefined ? SetCategory.MAX_LENGTH : maxLengthParam;
    return string.length > maxLength
      ? `${string.substr(0, maxLength - 3)}...`
      : string;
  }

  constructor(props) {
    super(props);
    this.state = {
      currentParentId: SetCategory.TOP_LEVEL,
      selectedId: SetCategory.NO_SELECTED,
      showPanel: false
    };
    this.selectCategory = this.selectCategory.bind(this);
    this.onChose = this.onChose.bind(this);
    this.haveChildrens = this.haveChildrens.bind(this);
    this.toTopLevel = this.toTopLevel.bind(this);
    this.toUpLevel = this.toUpLevel.bind(this);
    this.onCleanSelect = this.onCleanSelect.bind(this);
    this.handlerClickOutside = this.handlerClickOutside.bind(this);
    this.toTogglePanel = this.toTogglePanel.bind(this);
    this.toShowPanel = this.toShowPanel.bind(this);
    this.toHidePanel = this.toHidePanel.bind(this);
    this.getDefaultCategories = this.getDefaultCategories.bind(this);
  }

  UNSAFE_componentWillReceiveProps({ selectedId }) {
    this.selectCategory(selectedId || SetCategory.NO_SELECTED);
  }

  onChose() {
    const { onChose } = this.props;
    onChose(this.state.selectedId);
    this.toHidePanel();
  }

  onCleanSelect() {
    this.selectCategory(this.props.selectedId);
    this.toTopLevel();
  }

  setCurrentParentId(id) {
    this.setState({ currentParentId: id });
  }

  getCurrentParentId() {
    return this.state.currentParentId;
  }

  getIsDisabled() {
    return this.props.disabled || false;
  }

  getDefaultCategories() {
    const { enableNotSelected, categories, t } = this.props;
    let cats = categories || List();
    if (enableNotSelected) {
      const notSelectCategory = Map({
        id: SetCategory.NO_SELECTED,
        name: t('Not set'),
        parentId: SetCategory.TOP_LEVEL
      });
      cats = cats.unshift(notSelectCategory);
    }
    return cats || List();
  }

  getDisplayCategories() {
    return this.getDefaultCategories().filter(
      (cat) => cat.get('parentId') === this.getCurrentParentId()
    );
  }

  getCategoryById(id) {
    return this.getDefaultCategories().find((cat) => cat.get('id') === id);
  }

  getCurrentCategory() {
    return this.getCategoryById(this.getCurrentParentId());
  }

  getSelectedCategory() {
    return this.getCategoryById(this.state.selectedId);
  }

  selectCategory(id) {
    this.setState({ selectedId: id });
    const currentCategory = this.getCategoryById(id);
    if (currentCategory) {
      this.setCurrentParentId(currentCategory.get('parentId'));
    }
  }

  isSelect(id) {
    return id === this.state.selectedId;
  }

  isDefaultSelect() {
    return this.isSelect(this.props.selectedId);
  }

  haveChildrens(id) {
    if (id === SetCategory.NO_SELECTED) {
      return false;
    }

    const index = this.props.categories.findIndex(
      (cat) => cat.get('parentId') === id
    );
    return index !== -1;
  }

  isTopLevel() {
    return this.getCurrentParentId() === SetCategory.TOP_LEVEL;
  }

  toChild(id) {
    if (this.haveChildrens(id)) {
      this.setCurrentParentId(id);
    }
  }

  toTopLevel() {
    if (this.getCurrentParentId() !== SetCategory.TOP_LEVEL) {
      this.setCurrentParentId(SetCategory.TOP_LEVEL);
    }
  }

  toUpLevel() {
    const parentId = this.getCurrentParentId();
    const currentCategory = this.getCategoryById(parentId);
    this.setCurrentParentId(currentCategory.get('parentId'));
  }

  toShowPanel() {
    const currentCategory = this.getSelectedCategory();
    if (currentCategory) {
      this.setCurrentParentId(currentCategory.get('parentId'));
    }
    this.setState({ showPanel: true });
    document.addEventListener('mousedown', this.handlerClickOutside);
  }

  handlerClickOutside(e) {
    if (!this.panel.contains(e.target)) {
      this.toHidePanel();
    }
  }

  toHidePanel() {
    this.setState({ showPanel: false });
    this.onCleanSelect();
    document.removeEventListener('mousedown', this.handlerClickOutside);
  }

  toTogglePanel() {
    const toggleFunc = this.state.showPanel
      ? this.toHidePanel
      : this.toShowPanel;
    toggleFunc();
  }

  render() {
    const { style, readOnly } = this.props;

    if (readOnly) {
      return (
        <div style={{ padding: 7 }}>
          {(this.getSelectedCategory() &&
            this.getSelectedCategory().get('name')) ||
            '-'}
        </div>
      );
    }

    const styleForScrollbar = this.isTopLevel() ? { height: 231 } : {};

    return (
      <div
        className={b()}
        style={style}
        ref={(panel) => {
          this.panel = panel;
        }}>
        <div className={`${b('input-wrapper')} ${'input-group'}`}>
          <input
            className={`${b('input')} ${'form-control'}`}
            readOnly
            type="text"
            value={
              (this.getSelectedCategory() &&
                this.getSelectedCategory().get('name')) ||
              ''
            }
            onClick={(e) => {
              e.preventDefault();
              this.toTogglePanel();
            }}
            disabled={this.getIsDisabled()}
          />
          <div className="input-group-btn">
            <button
              type="button"
              className="btn btn-default dropdown-toggle"
              onClick={(e) => {
                e.preventDefault();
                this.toTogglePanel();
              }}
              disabled={this.getIsDisabled()}>
              <span
                className="caret"
                style={{
                  transform: this.state.showPanel
                    ? 'rotate(270deg)'
                    : 'rotate(90deg)'
                }}
              />
            </button>
          </div>
        </div>
        <div className={b('toggle-panel', { show: this.state.showPanel })}>
          {!this.isTopLevel() && this.getCurrentCategory() && (
            <div
              className={b('header')}
              title={this.getCurrentCategory().get('name')}>
              {SetCategory.cliping(this.getCurrentCategory().get('name'), 18)}
            </div>
          )}
          <div className={b('list')}>
            <div className={b('list-scroll')} style={styleForScrollbar}>
              {this.getDisplayCategories().map((category) => {
                const id = category.get('id');
                const name = category.get('name');

                return (
                  <div className={b('item')} key={id}>
                    <span
                      className={b('radio-btn', { set: this.isSelect(id) })}
                      onClick={() => this.selectCategory(id)}
                    />
                    <span
                      className={b('title', {
                        pointer: this.haveChildrens(id)
                      })}
                      onClick={() => this.toChild(id)}
                      title={name}>
                      {SetCategory.cliping(name)}
                      {this.haveChildrens(id) && (
                        <img
                          src="/img/arrow-1.svg"
                          className={b('to-child')}
                          onClick={() => this.toChild(id)}
                          alt="to children categories"
                        />
                      )}
                    </span>
                  </div>
                );
              })}
            </div>
          </div>
          <div className={b('control-panel')}>
            <div
              className={b('control-item', { disabled: this.isTopLevel() })}
              onClick={this.toTopLevel}>
              <img src="/img/arrow-start.svg" alt="to begin" />
            </div>
            <div
              className={b('control-item', { disabled: this.isTopLevel() })}
              onClick={this.toUpLevel}>
              <img src="/img/arrow-prev.svg" alt="back" />
            </div>
            <div
              className={b('control-item', {
                disabled: this.isDefaultSelect()
              })}
              onClick={this.onCleanSelect}>
              <img src="/img/cross.svg" alt="reset" />
            </div>
            <div className={b('control-item')} onClick={this.onChose}>
              <img src="/img/ok.svg" alt="ok" />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

SetCategory.propTypes = {
  t: PropTypes.func.isRequired,
  categories: ImmutablePropTypes.listOf(
    ImmutablePropTypes.contains({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      parentId: PropTypes.number
    })
  ).isRequired,
  onChose: PropTypes.func.isRequired
};

export default translate(['ui'], { wait: true })(SetCategory);
