import React, { useState, useRef } from 'react';
import { useParams, useNavigate, useTranslate } from '@hooks';
import { Typography, Button } from '@link/react-components';
import cn from 'classnames';
import { useSelector } from 'react-redux';
import { notificationSettingsService } from '../../api/notificationSettingsService';
import * as storeGetters from '../../storeGetters';
import styles from './SubscriptionRefusal.module.css';
import { getLocationOrigin } from '../../lib/goodwix';

export function SubscriptionRefusal() {
  const [success, setSuccess] = useState(null);
  const emailRef = useRef(null);
  const isAuth = useSelector(storeGetters.isAuth);

  const { token } = useParams();
  const navigate = useNavigate();

  const unsubscribe = async (token) => {
    const res = await notificationSettingsService
      .turnOffEmailNotificationsByToken(token)
      .catch(() => {
        // TODO: to add error handler
        setSuccess(false);
        console.log('Something went wrong');
      });

    if (res) {
      emailRef.current = res.email;
      setSuccess(typeof emailRef.current === 'string');
    }
  };

  function cancel() {
    const path = isAuth ? '/' : `${getLocationOrigin()}/login`;
    navigate(path);
  }

  const t = useTranslate();

  function getTranslate(tKey) {
    return t(`subscriptionRefuse.${tKey}`);
  }

  return (
    <div className={styles.unsubscribe}>
      <div className={styles.container}>
        <dic className={styles.content}>
          <img className={styles.image} src="/img/email.svg" alt="" />
          <Typography
            variant="h2"
            align="center"
            className={cn(styles.mainText, success && styles.mainTextSuccess)}>
            {getTranslate(success ? 'successfulRefuse' : 'refuseConfirmation')}
          </Typography>
          {success ? (
            <div className={styles.textBlock}>
              <Typography variant="body1Reg" align="center">
                {getTranslate('noMoreEmail')}
              </Typography>
              <Typography
                variant="body1Sb"
                align="center"
                className={styles.email}>
                {emailRef.current}
              </Typography>
            </div>
          ) : (
            <div className={styles.buttonsBlock}>
              <Button mode="text" onClick={cancel}>
                {getTranslate('buttonCancel')}
              </Button>
              <Button mode="primary" onClick={() => unsubscribe(token)}>
                {getTranslate('buttonUnsubscribe')}
              </Button>
            </div>
          )}
        </dic>
      </div>
    </div>
  );
}
