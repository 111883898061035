import { makeRequest } from '../domain/request';
import { makeProduct } from '../domain/product';

function requestMapper(request) {
  return makeRequest({
    id: request.id,
    name: request.title,
    products: request.products.map(makeProduct)
  });
}

export default requestMapper;
