import React, { useCallback } from 'react';
import iconsView from 'react-components/src/icons/constants';
import { useXarrow } from 'react-xarrows';
import { useActions, useIsClickOutside } from '@hooks';
import { useTranslate } from '../../../../../TranslateProvider';
import { ButtonConstructor } from '../buttons/buttonConstructor';
import * as constructorActions from '../../../../../components/ChatWidget/modules/constructor/actions';
import { DropMenu } from '../dropMenu';
import styles from './CreateNewStep.module.css';
import { DropMenuItem } from '../dropMenuItem';
import { isLastStepHasPublication } from '../helpers';
import { MAX_STEPS_IN_PATH } from '../constants';
import { useIsCanBeDeleted } from '../../../../../contexts/RouteIsCanBeDeletedContext';

export const CreateNewStep = ({ path }) => {
  const isCanBeDeleted = useIsCanBeDeleted();
  if (isLastStepHasPublication(path)) return null;
  const isDisabled = path.length === MAX_STEPS_IN_PATH || !isCanBeDeleted;

  return <CreateNewStepButton isDisabled={isDisabled} />;
};

function CreateNewStepButton({ isDisabled = false }) {
  const { ref, isShow, setIsShow } = useIsClickOutside(false);
  const t = useTranslate();

  return (
    <div
      ref={ref}
      id="create-step-button"
      className={styles.createButtonWrapper}>
      <ButtonConstructor
        className={styles.createButtonWidth}
        text={t('constructor.createStep')}
        onClick={() => setIsShow((prevState) => !prevState)}
        isDisabled={isDisabled}
      />
      {isShow && <CreateMenuList setIsShow={setIsShow} />}
    </div>
  );
}

function CreateMenuList({ setIsShow }) {
  const t = useTranslate();
  const { createStep } = useActions(constructorActions);
  const updateXarrow = useXarrow();
  const onCreateNewStep = useCallback((type) => {
    createStep({ stepType: type });
    setIsShow(false);
    updateXarrow();
  }, []);

  return (
    <DropMenu className={styles.menu} style={{ position: 'absolute' }}>
      <DropMenuItem
        textContent={t('constructor.badge.agreement')}
        iconName={iconsView.Ok}
        onClick={() => onCreateNewStep('agreement')}
      />
      <DropMenuItem
        textContent={t('constructor.badge.introduction')}
        iconName={iconsView.View}
        onClick={() => onCreateNewStep('introduction')}
      />
      <DropMenuItem
        textContent={t('constructor.badge.publication')}
        iconName={iconsView.Publish}
        onClick={() => onCreateNewStep('publication')}
      />
    </DropMenu>
  );
}
