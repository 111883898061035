import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { translate } from 'react-i18next';
import { Modal, Form, Button } from 'react-bootstrap';
import * as modalAction from '../../action-creators/modal';
import * as ordersUC from '../../useCases/orders';
import * as blockContactUC from '../ChatWidget/modules/blockedContact';

class SimpleModal extends Component {
  constructor(props) {
    super(props);
    this.onConfirm = this.onConfirm.bind(this);
    this.onCancel = this.onCancel.bind(this);
  }

  // NOTE: add to listOfMethod and this will make it possible to call it when calling modal
  onConfirm() {
    const { args, onCloseModal } = this.props;

    this.getConfirmMethod(args);
    onCloseModal();
  }

  onCancel() {
    this.props.onCloseModal();
  }

  get getConfirmMethod() {
    const { useCaseName } = this.props;

    const method = this.listOfMethods[useCaseName];
    if (method) {
      return method;
    }
    console.error(`Not such method in confirm modal: ${useCaseName}`);
    return () => {};
  }

  get listOfMethods() {
    const { removeSelectedOrders, removeOrder, copyOrder, toggleBlock } =
      this.props;

    return {
      removeSelectedOrders,
      removeOrder,
      copyOrder,
      toggleBlock
    };
  }

  render() {
    const {
      t,
      title,
      titleArgs,
      text,
      btnTextConfirm,
      btnTextCancel,
      textArgs
    } = this.props;

    return (
      <Modal id="simpleSubmit" onHide={this.onCancel} show>
        <div className="modal-container modal-container_type_default modal-container_theme_default">
          <Modal.Header>
            <Modal.Title>
              {t(title, titleArgs)}
              <span
                className="modal-header-cross pull-right"
                onClick={this.onCancel}
              />
            </Modal.Title>
          </Modal.Header>
          {text && (
            <Modal.Body>
              <Form>
                <p className="modal-container__row">{t(text, textArgs)}</p>
              </Form>
            </Modal.Body>
          )}
          <Modal.Footer>
            <Button id="cancel" className="pull-left" onClick={this.onCancel}>
              {t(btnTextCancel)}
            </Button>
            <Button id="confirm" bsStyle="primary" onClick={this.onConfirm}>
              {t(btnTextConfirm)}
            </Button>
          </Modal.Footer>
        </div>
      </Modal>
    );
  }
}

SimpleModal.propTypes = {
  t: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  titleArgs: PropTypes.object,
  text: PropTypes.string,
  textArgs: PropTypes.object,
  btnTextConfirm: PropTypes.string,
  btnTextCancel: PropTypes.string,
  onCloseModal: PropTypes.func.isRequired,
  useCaseName: PropTypes.string.isRequired,
  args: PropTypes.any,
  removeSelectedOrders: PropTypes.func.isRequired,
  removeOrder: PropTypes.func.isRequired,
  copyOrder: PropTypes.func.isRequired,
  toggleBlock: PropTypes.func.isRequired
};

SimpleModal.defaultProps = {
  titleArgs: {},
  text: '',
  btnTextConfirm: 'Confirm',
  btnTextCancel: 'Cancel',
  textArgs: {},
  args: undefined
};

export default connect(null, (dispatch) =>
  bindActionCreators(
    {
      onCloseModal: modalAction.hideModalDialog,
      removeSelectedOrders: ordersUC.removeSelectedOrders,
      removeOrder: ordersUC.removeOrder,
      copyOrder: ordersUC.copyOrder,
      toggleBlock: blockContactUC.toggleBlock
    },
    dispatch
  )
)(translate(['ui'])(SimpleModal));
