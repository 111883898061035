const { makePolicy } = require('../../data/policy');

// Section name
const PRODUCTS_SUPPLIERS = 'PRODUCTS_SUPPLIERS';

// Use cases
const VIEW_PRICE_LISTS_WITHOUT_RESTRICTIONS =
  'VIEW_PRICE_LISTS_WITHOUT_RESTRICTIONS';
const VIEW_PRICE_LISTS_WITH_DIFF_ACCESS = 'VIEW_PRICE_LISTS_WITH_DIFF_ACCESS';
const VIEW_PRICE_LIST_PRODUCTS = 'VIEW_PRICE_LIST_PRODUCTS';
const VIEW_PRICE_LIST_PROPERTIES = 'VIEW_PRICE_LIST_PROPERTIES';
const EXPORT_PRICE_LIST_PRODUCTS = 'EXPORT_PRICE_LIST_PRODUCTS';

const settings = [
  makePolicy(PRODUCTS_SUPPLIERS, VIEW_PRICE_LISTS_WITHOUT_RESTRICTIONS, {
    reader: true,
    writer: true,
    advanced: true
  }),
  makePolicy(PRODUCTS_SUPPLIERS, VIEW_PRICE_LISTS_WITH_DIFF_ACCESS, {
    reader: true,
    writer: true,
    advanced: true
  }),
  makePolicy(PRODUCTS_SUPPLIERS, VIEW_PRICE_LIST_PRODUCTS, {
    reader: true,
    writer: true,
    advanced: true
  }),
  makePolicy(PRODUCTS_SUPPLIERS, VIEW_PRICE_LIST_PROPERTIES, {
    reader: true,
    writer: true,
    advanced: true
  }),
  makePolicy(PRODUCTS_SUPPLIERS, EXPORT_PRICE_LIST_PRODUCTS, {
    reader: true,
    writer: true,
    advanced: true
  })
];

module.exports = {
  PRODUCTS_SUPPLIERS,
  VIEW_PRICE_LISTS_WITHOUT_RESTRICTIONS,
  VIEW_PRICE_LISTS_WITH_DIFF_ACCESS,
  VIEW_PRICE_LIST_PRODUCTS,
  VIEW_PRICE_LIST_PROPERTIES,
  EXPORT_PRICE_LIST_PRODUCTS,
  settings
};
