import React from 'react';
import PropTypes from 'prop-types';
import { translate } from 'react-i18next';

const ChannelClosed = ({
  t,
  chat,
  currentMemberIsChannelOwner,
  handleChatNotifications
}) => (
  <div className="cwm-notify-container">
    <div>{`${t(`${chat.type.toLowerCase()}.help-close`)} `}</div>
    {currentMemberIsChannelOwner && (
      <button
        type="button"
        className="channel__btn-restore-send"
        onClick={() => handleChatNotifications('close', chat)}>
        {t(`${chat.type.toLowerCase()}.help-restore`)}
      </button>
    )}
  </div>
);

ChannelClosed.propTypes = {
  t: PropTypes.func.isRequired,
  chat: PropTypes.object.isRequired,
  currentMemberIsChannelOwner: PropTypes.bool.isRequired,
  handleChatNotifications: PropTypes.func.isRequired
};

export default translate()(ChannelClosed);
