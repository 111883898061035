import React, { useCallback, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import styled from 'styled-components';
import ButtonIcon from 'react-components/src/components/ButtonIcon';
import icons from 'react-components/src/icons/constants';
import { isNil } from 'ramda';
import Select from '../../../Dropdown/Select';
import CWSIconDropdown from '../../../Dropdown/Icon';
import Dashboard from './layout';
import { getChatWidget } from '../../../getter';
import * as chatWidgetUC from '../../../useCases';
import * as storeGetters from '../../../../../storeGetters';
import { useTranslate } from '../../../../../TranslateProvider';

const listAdd = [
  { value: 'employee-your-company' },
  {
    value: 'employee-other-company',
    helper: 'chat-widget.menu-items.employee-other-company-helper'
  }
];
const listFilters = [
  { value: 'company', selected: false },
  { value: 'department', selected: false }
];

const AddContact = ({ isOwner, havePermissionToUpdate, handler, t }) =>
  isOwner || havePermissionToUpdate ? (
    <CWSIconDropdown
      title={() => (
        <ButtonIcon t={t} icon={icons.Add} size={18} tooltip="add-contact" />
      )}
      onSelect={handler}
      list={listAdd}
    />
  ) : (
    <ButtonIcon
      t={t}
      icon={icons.Add}
      size={18}
      tooltip="add-contact"
      onClick={() => handler('employee-other-company')}
    />
  );

const FilterContacts = ({ handler, active, t }) => {
  const [list, setList] = useState(listFilters);

  function toggleOption(options, value) {
    return options.map((option) =>
      option.value === value
        ? { ...option, selected: !option.selected }
        : { ...option, selected: false }
    );
  }

  const onSelect = useCallback(
    (value) => {
      handler(value);
      setList((prevList) => toggleOption(prevList, value));
    },
    [handler]
  );

  return (
    <CWSIconDropdown
      t={t}
      title={() => (
        <ButtonIcon
          t={t}
          icon={icons.Filter}
          size={18}
          tooltip="contacts-filter"
        />
      )}
      active={active}
      onSelect={onSelect}
      list={list}
    />
  );
};

const CWSDashboardContact = ({
  chatWidget,
  handleContactBtnClick,
  isOwnerCompany = false,
  havePermissionToUpdate = false,
  config = {}
}) => {
  const { showSearch, showAddContact, showFolder } = config;
  const t = useTranslate();

  const checkIfSelected = (type) => chatWidget.filter.contact.value === type;

  const filterContacts = [
    {
      icon: 'without',
      value: 'all-contacts',
      selected: checkIfSelected('all-contacts')
    },
    { icon: 'without', value: 'active', selected: checkIfSelected('active') },
    { icon: 'without', value: 'blocked', selected: checkIfSelected('blocked') }
  ];

  const handleGroupBy = useCallback(
    (value) =>
      handleContactBtnClick(
        'groupBy',
        chatWidget.groupBy === value ? '' : value
      ),
    [chatWidget?.groupBy]
  );

  return (
    <Dashboard
      left={
        <Select
          title={chatWidget.filter.contact}
          onSelect={(item) => handleContactBtnClick('change-filter', item)}
          list={filterContacts}
        />
      }
      right={
        <ButtonsContainer>
          {showSearch && (
            <ButtonIcon
              t={t}
              icon={icons.Search}
              size={18}
              tooltip="contact-search"
              onClick={() => handleContactBtnClick('search')}
            />
          )}
          <FilterContacts
            t={t}
            handler={handleGroupBy}
            active={chatWidget.groupBy.length > 0}
          />
          {showAddContact && (
            <AddContact
              t={t}
              isOwner={isOwnerCompany}
              havePermissionToUpdate={havePermissionToUpdate}
              handler={handleContactBtnClick}
            />
          )}
          {showFolder && (
            <ButtonIcon
              t={t}
              icon={icons.StylePunkt}
              size={18}
              tooltip="contacts-list"
              active={!isNil(chatWidget.blockGroupContacts.kind)}
              onClick={() => handleContactBtnClick('folder')}
            />
          )}
        </ButtonsContainer>
      }
    />
  );
};

const ButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  column-gap: var(--padding-m);
`;

export default connect(
  (state) => ({
    chatWidget: getChatWidget(state),
    isOwnerCompany: storeGetters.isCurrentUserOwnerCompany(state),
    havePermissionToUpdate: storeGetters.haveUpdateEmployeesPermission(state)
  }),
  (dispatch) =>
    bindActionCreators(
      {
        ...chatWidgetUC
      },
      dispatch
    )
)(CWSDashboardContact);
