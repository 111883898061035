import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { translate } from 'react-i18next';
import { withRouter } from '../hoc/withRouter';

import WorkspaceHeader from '../components/WorkspaceHeader/WorkspaceHeader';
import ContainersDefault from '../components/Containers/ContainersDefault';
import TableFileStorage from '../containers/Tables/TableFileStorage';
import TableFileStorageUploads from '../containers/Tables/TableFileStorageUploads';
import Loader from '../components/Loader';
import MessengerOther from '../components/Layouts/MessengerOther';
import FileSelectWrap from '../components/FileSelectWrap';

import * as modalActions from '../action-creators/modal';
import * as messageAction from '../action-creators/message';
import * as companyActions from '../action-creators/company';
import * as modalUC from '../useCases/modal-list';
import * as storageActions from '../action-creators/storage';
import * as serviceActions from '../action-creators/services';
import { convertUrlToFile } from '../components/ChatWidget/modules/files/utils';

class MFilesStorage extends Component {
  constructor(props) {
    super(props);
    this.renderTable = this.renderTable.bind(this);
    this.items = this.items.bind(this);
    this.onBack = this.onBack.bind(this);
    this.fetchFiles = this.fetchFiles.bind(this);
  }

  async UNSAFE_componentWillMount() {
    const {
      getCompany,
      getChatStorage,
      getUploadsStorage,
      unsetLink,
      offset,
      limit,
      getStorageSize,
      section
    } = this.props;
    if (section === 'chat') {
      await getChatStorage({ offset, limit });
    } else if (section === 'uploads') {
      await getUploadsStorage({ offset, limit });
    }

    getCompany();
    await getStorageSize();
    unsetLink();
  }

  componentDidUpdate(prevProps) {
    if (this.props.section !== prevProps.section) {
      this.fetchFiles();
    }
  }

  get storageUrlWithoutPort() {
    const [protocol, url] = process.env.STORAGE_FILES_URL.split(':');
    return convertUrlToFile(`${protocol}:${url}`);
  }

  componentWillUnmount() {
    this.props.clearSelected();
    this.props.setSuccessMessage({ key: '' });
  }

  get description() {
    const { t, section } = this.props;
    if (section === 'uploads') {
      return t('attache_tab_text');
    }
    return '';
  }

  get files() {
    const { section, chatFiles, uploadsFiles } = this.props;
    if (section === 'chat') return chatFiles;
    if (section === 'uploads') return uploadsFiles;
    return [];
  }

  get isUpload() {
    const { section, isUploadChatFiles, isUploadUploadsFiles } = this.props;
    if (section === 'chat') return isUploadChatFiles;
    if (section === 'uploads') return isUploadUploadsFiles;
    return true;
  }

  async fetchFiles() {
    const { section, offset, limit, getChatStorage, getUploadsStorage } =
      this.props;

    if (section === 'chat') {
      await getChatStorage({ offset, limit });
    } else if (section === 'uploads') {
      await getUploadsStorage({ offset, limit });
    }
  }

  renderTable(section) {
    const {
      getChatStorage,
      getUploadsStorage,
      selectRow,
      link,
      removeFileLinkBySection,
      storageSize,
      maxStorageSize,
      getStorageSize,
      showModal
    } = this.props;

    if (!this.isUpload) return <Loader theme="page" />;

    if (section === 'uploads') {
      return (
        <TableFileStorageUploads
          files={this.files}
          getFiles={getUploadsStorage}
          selectRow={selectRow}
          link={link}
          onBack={this.onBack}
          section={section}
          removeFileLinkBySection={removeFileLinkBySection}
          storageSize={storageSize}
          maxStorageSize={maxStorageSize}
          getStorageSize={getStorageSize}
          showModal={showModal}
          description={this.description}
        />
      );
    }

    return (
      <TableFileStorage
        files={this.files}
        getFiles={getChatStorage}
        selectRow={selectRow}
        link={link}
        onBack={this.onBack}
        section={section}
        removeFileLinkBySection={removeFileLinkBySection}
        storageSize={storageSize}
        maxStorageSize={maxStorageSize}
        getStorageSize={getStorageSize}
        showModal={showModal}
        description={this.description}
      />
    );
  }

  onBack() {
    this.props.showPrevIfHasOneName(['CREATE_TOPIC_CHAT', 'EDIT_TOPIC']);
  }

  isBlockedFunctions() {
    const { maxStorageSize, storageSize } = this.props;
    const haveFreeSpace = storageSize < maxStorageSize;

    return !haveFreeSpace;
  }

  renderButtonBlock(text, onChangeCallback, accept) {
    const { t } = this.props;
    return (
      <FileSelectWrap
        multiple={false}
        accept={accept}
        onChange={onChangeCallback}
        component={({ onClick }) => (
          <div onClick={(e) => onClick(e)}>{t(text)}</div>
        )}
      />
    );
  }

  getOriginalFileUrl(file) {
    return convertUrlToFile(file.get('originalUrl') || file.get('url'));
  }

  items() {
    const {
      t,
      user,
      getChatStorage,
      getUploadsStorage,
      selected,
      offset,
      limit,
      showModal,
      removeFileLinkBySection,
      section,
      maxStorageSize,
      storageSize,
      uploadFile,
      gaSend
    } = this.props;

    const menu = [
      {
        name: t('Download'),
        onClick: async () => {
          if (selected.size === 1) {
            const fileId = selected.toJS()[0];
            const f = this.files.find((file) => file.get('id') === fileId);
            window.open(
              `${this.getOriginalFileUrl(f)}?name=${f.get('name')}`,
              '_blank'
            );
            return;
          }

          const query = selected
            .toJS()
            .reduce(
              (str, id) => `${str}&id[]=${id}`,
              `?timezoneOffset=${new Date().getTimezoneOffset()}`
            );

          window.open(`${this.storageUrlWithoutPort}${query}`, '_blank');
        },
        disabled: this.isBlockedFunctions() || selected.size === 0
      },
      {
        name: t('Delete'),
        onClick: async () => {
          showModal('SIMPLE_SUBMIT', {
            captionKey: 'confirm_action',
            text: 'Delete?',
            textBtnConfirm: 'OK',
            submitAction: async () => {
              await removeFileLinkBySection(selected, section);
              if (section === 'chat') return getChatStorage({ offset, limit });
              if (section === 'uploads')
                return getUploadsStorage({ offset, limit });
              return '';
            }
          });
        },
        disabled: selected.size === 0
      }
    ];

    if (section === 'uploads') {
      const loadFile = async (file) => {
        await uploadFile(file);
        await getUploadsStorage({ offset, limit });
        await gaSend({
          category: 'FileStorage',
          action: 'loading_file',
          label: user.get('email')
        });
      };

      menu.unshift({
        name: t('Upload'),
        disabled: this.isBlockedFunctions(),
        popover: storageSize >= maxStorageSize,
        popoverText: t('tariff_limit_txt'),
        element: this.renderButtonBlock(
          t('Upload'),
          (f) => loadFile(f[0]),
          '',
          false,
          t
        )
      });
    }

    return menu;
  }

  render() {
    const { t, history, section } = this.props;

    const title = t('File storage');
    const breadcrumbs = [{ name: t('File storage') }];
    const panelBtns = this.items();
    const navigationTabs = {
      activeKey: section,
      items: [
        { eventKey: 'chat', name: t('Chat') },
        { eventKey: 'uploads', name: t('Uploads') }
      ],
      handleTabChange: (tab) => history.push(`/file-storage/${tab}`)
    };

    return (
      <MessengerOther
        title={title}
        breadcrumbs={breadcrumbs}
        panelBtns={panelBtns}
        content={
          <>
            <WorkspaceHeader navigation={navigationTabs} />
            <ContainersDefault>{this.renderTable(section)}</ContainersDefault>
          </>
        }
      />
    );
  }
}

MFilesStorage.contextTypes = {
  badgeSocket: PropTypes.object
};

export default withRouter(
  connect(
    (state) => ({
      user: state.getIn(['user', 'user']),
      company: state.get('company'),
      selected: state.getIn(['storage', 'selected']),
      chatFiles: state.getIn(['storage', 'chat', 'files']),
      uploadsFiles: state.getIn(['storage', 'uploads', 'files']),
      size: state.getIn(['storage', 'size']),
      limit: state.getIn(['storage', 'limit']),
      offset: state.getIn(['storage', 'offset']),
      link: state.getIn(['storage', 'link']),
      isUploadChatFiles: state.getIn(['storage', 'chat', 'isUpload']),
      isUploadUploadsFiles: state.getIn(['storage', 'uploads', 'isUpload']),
      maxStorageSize: state.getIn(['storage', 'maxStorageSize']),
      storageSize: state.getIn(['storage', 'storageSize'])
    }),
    (dispatch) =>
      bindActionCreators(
        {
          showModal: modalActions.showModal,
          setErrorMessage: messageAction.setErrorMessage,
          setSuccessMessage: messageAction.setSuccessMessage,
          showPrevIfHasOneName: modalUC.showPrevIfHasOneName,
          getChatStorage: storageActions.getChatStorage,
          getUploadsStorage: storageActions.getUploadsStorage,
          selectRow: storageActions.selectRow,
          getCompany: companyActions.getCompany,
          clearSelected: storageActions.clearSelected,
          uploadFile: storageActions.uploadFile,
          unsetLink: storageActions.unsetLink,
          removeFileLinkBySection: storageActions.removeFileLinkBySection,
          getStorageSize: storageActions.getStorageSize,
          gaSend: serviceActions.gaSend
        },
        dispatch
      )
  )(translate(['ui'], { wait: true })(MFilesStorage))
);
