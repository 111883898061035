import React from 'react';
import PropTypes from 'prop-types';
import './style.css';

const Cross = ({ onClick, category }) => (
  <div
    className="tags__tag-cross tags__tag-cross_offset-right"
    onClick={(e) => {
      e.preventDefault();
      onClick(category);
    }}>
    ×
  </div>
);

Cross.propTypes = {
  category: PropTypes.any.isRequired,
  onClick: PropTypes.func.isRequired
};

const Tag = ({ isShowDelete, onDelete, category }) => (
  <div className="tags__tag">
    {`${category.name}`}
    {isShowDelete ? <Cross category={category} onClick={onDelete} /> : null}
  </div>
);

Tag.propTypes = {
  category: PropTypes.shape({
    name: PropTypes.string.isRequired
  }).isRequired,
  isShowDelete: PropTypes.bool.isRequired,
  onDelete: PropTypes.func.isRequired
};

const Tags = ({
  categories,
  plainText,
  isShowDelete = false,
  onDelete = () => {},
  testId
}) => {
  if (plainText) {
    return categories.map((category) => category.name).join(', ');
  }

  return (
    <div className="tags" data-testid={testId}>
      {categories.map((category) => (
        <Tag
          key={category.id}
          category={category}
          isShowDelete={isShowDelete}
          onDelete={onDelete}
        />
      ))}
    </div>
  );
};

Tags.propTypes = {
  categories: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired
    })
  ).isRequired
};

export default Tags;
