import React from 'react';

import '../styles/modal_theme_default.styl';

import CategoryCard from './CategoryCard';

const getChildren = (parentId, list) =>
  list.filter((item) => item.parentId === parentId);

const MapCategories = ({
  fullList,
  list,
  selectedIds,
  onCategoryChoose,
  openedCategories,
  setIsOpened,
  isSearch,
  level
}) =>
  list.map((category) => (
    <CategoryCard
      key={category.id}
      category={category}
      selectedIds={selectedIds}
      openedCategories={openedCategories}
      onChoose={onCategoryChoose}
      categoryChildren={getChildren(category.id, fullList)}
      setIsOpened={setIsOpened}
      isSearch={isSearch}
      fullList={fullList}
      level={level}
    />
  ));

export default MapCategories;
