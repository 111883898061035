import React from 'react';
import { translate } from 'react-i18next';

import DatePickerSide from './datepickerSide';

import '../datepicker.css';

const PickDateRange = ({
  t,
  isShowDPStart = true,
  setShowDPStart,
  setStartDate,
  setEndDate,
  startDate,
  endDate,
  minDate,
  maxDate,
  onApply,
  clearDates,
  reference
}) => {
  const switchToEndDatePicker = () => {
    if (endDate === null) {
      setShowDPStart(false);
    }
  };

  const onChangeStartDateDP = (date) => {
    setStartDate(date);
    switchToEndDatePicker();
  };

  return (
    <div className="datepicker-goodwix__wrapper" ref={reference}>
      <div className="datepicker-goodwix__picker">
        <DatePickerSide
          isShow={isShowDPStart}
          isStartPicker
          onChange={onChangeStartDateDP}
          startDate={startDate}
          endDate={endDate}
          minDate={minDate}
          maxDate={maxDate}
        />
        <DatePickerSide
          isShow={!isShowDPStart}
          isStartPicker={false}
          onChange={setEndDate}
          startDate={startDate}
          endDate={endDate}
          minDate={minDate}
          maxDate={maxDate}
        />
      </div>
      <div className="datepicker-goodwix__footer">
        <button
          type="button"
          onClick={clearDates}
          className="datepicker-goodwix__btn-clear">
          {t('chat-widget.section.search-mode.datepicker.btn-clear')}
        </button>
        <button
          type="button"
          onClick={onApply}
          className="datepicker-goodwix__btn-apply">
          {t('chat-widget.section.search-mode.datepicker.btn-apply')}
        </button>
      </div>
    </div>
  );
};

export default translate()(PickDateRange);
