import React from 'react';
import Text from 'react-components/src/components/Typography';
import Loader from '../../../../../Loader';
import { formatTypingText } from '../../../../utils';
import { useTranslate } from '../../../../../../TranslateProvider';
import './style.css';

const Typing = ({ typingList }) => {
  const t = useTranslate();

  return (
    <div className="cws-chat-item-typing">
      <Loader theme="intext" />
      <Text className="cw-typing_text" level={2} weight={2} lineHeight={2}>
        {formatTypingText(t, typingList)}
      </Text>
    </div>
  );
};

export default Typing;
