import React from 'react';
import { useTranslate } from '@hooks';
import { Container, TopPanel } from '../Layouts';
import { Content } from './Content';

export function Settings() {
  const t = useTranslate();

  return (
    <Container>
      <TopPanel title={t('modules.settings.title')} />
      <Content />
    </Container>
  );
}
