import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { translate } from 'react-i18next';
import * as modalActions from '../../../../action-creators/modal';
import { CREATE_NEW_TASK_MODAL } from '../../Modals/constants';
import AddButton from '../../../../components/Buttons/AddButton';

const CreateTaskButton = ({ t }) => {
  const dispatch = useDispatch();
  const onCreateNewTask = useCallback(() => {
    dispatch(
      modalActions.showModal(CREATE_NEW_TASK_MODAL, { enforceFocus: false })
    );
  }, []);

  return (
    <AddButton variant="outline-primary" onClick={onCreateNewTask}>
      {t('taskManager.buttons.createTask')}
    </AddButton>
  );
};

export default translate()(CreateTaskButton);
