import React from 'react';
import {
  ButtonCallEnd,
  ButtonCamera,
  ButtonHideVideoChat,
  ButtonMicrophone,
  ButtonControlTranscript
} from '../Buttons';

const ControlPanel = () => (
  <div className="video-chat__section-buttons">
    <ButtonMicrophone />
    <ButtonCamera />
    <ButtonCallEnd />
    <ButtonHideVideoChat />
    <ButtonControlTranscript />
  </div>
);

export default ControlPanel;
