import React from 'react';
import { Icon, Tooltip } from '@link/react-components';

const DEFAULT_SIZE = 18;
const DEFAULT_TOOLTIP_MAX_WIDTH = 207;
const DEFAULT_TOOLTIP_ALIGN = 'center';
const DEFAULT_TOOLTIP_PLACEMENT = 'top';

export function Helper({
  tooltip,
  size = DEFAULT_SIZE,
  tooltipMaxWidth = DEFAULT_TOOLTIP_MAX_WIDTH,
  tooltipAlign = DEFAULT_TOOLTIP_ALIGN,
  tooltipPlacement = DEFAULT_TOOLTIP_PLACEMENT
}) {
  return (
    <Tooltip
      title={tooltip}
      maxWidth={tooltipMaxWidth}
      align={tooltipAlign}
      placement={tooltipPlacement}>
      <Icon iconName="help" width={size} height={size} />
    </Tooltip>
  );
}
