import React from 'react';

// eslint-disable-next-line @typescript-eslint/no-empty-function
export const noop = () => {};

export function isFunction(value: any): boolean {
  return typeof value === 'function';
}

export function setRef<T>(element: T, ref?: React.Ref<T>): void {
  if (!ref) return;

  if (isFunction(ref)) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    ref(element);
  }

  // eslint-disable-next-line no-param-reassign
  (ref as React.MutableRefObject<T>).current = element;
}
