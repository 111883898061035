import React from 'react';
import PropTypes from 'prop-types';
import { translate } from 'react-i18next';
import CWSAvatar from '../../Avatar';
import './style.css';

// Modal container
const Container = ({ children = null, className = '' }) => (
  <div className={`cw-modal-container ${className}`}>{children}</div>
);

Container.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string
};

Container.defaultProps = {
  className: ''
};
// --------------------------------------------------------------------------------------------------------------------

// Modal header
const Header = ({ title = '', onClose = () => {}, className = '' }) => (
  <div className={`cw-modal-header ${className}`}>
    <span className="cw-modal-header_title">{title}</span>
    <img
      className="cw-modal-header_cross"
      src="/img/cross-modal.svg"
      alt="close"
      onClick={onClose}
    />
  </div>
);

Header.propTypes = {
  title: PropTypes.string.isRequired,
  onClose: PropTypes.func,
  className: PropTypes.string
};

Header.defaultProps = {
  onClose: () => {},
  className: ''
};
// --------------------------------------------------------------------------------------------------------------------

// Modal avatar header
const HeaderAvatar = ({
  avatar = '',
  fullName = '',
  firstName = '',
  lastName = '',
  companyName = '',
  onClose = () => {},
  className = ''
}) => (
  <div className={`cw-modal-header ${className}`}>
    <div className="cw-modal-header-avatar">
      <div className="cw-modal-header-avatar_avatar">
        <CWSAvatar size={40} info={{ src: avatar, type: 'user', alt: '' }} />
      </div>
      <div className="cw-modal-header-avatar_titles">
        <div className="cw-modal-header-avatar_titles_contact-name">
          {fullName || `${firstName} ${lastName}`}
        </div>
        <div className="cw-modal-header-avatar_titles_company-name">
          {companyName}
        </div>
      </div>
    </div>
    <img
      className="cw-modal-header_cross"
      src="/img/cross-modal.svg"
      alt="close"
      onClick={onClose}
    />
  </div>
);

HeaderAvatar.propTypes = {
  avatar: PropTypes.string,
  fullName: PropTypes.string,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  companyName: PropTypes.string,
  onClose: PropTypes.func,
  className: PropTypes.string
};

HeaderAvatar.defaultProps = {
  avatar: '',
  fullName: '',
  firstName: '',
  lastName: '',
  companyName: '',
  onClose: () => {},
  className: ''
};
// --------------------------------------------------------------------------------------------------------------------

// Modal dialog header
const HeaderDialog = ({
  employeeId = '',
  dialog = {},
  onClose = () => {},
  className = ''
}) => {
  const { members } = dialog;
  const participant = members.find(
    (member) => member.employeeId === employeeId
  );
  const { avatarSrc = '', userName = '', companyName = '' } = participant || {};

  return (
    <div className={`cw-modal-header ${className}`}>
      <div className="cw-modal-header-avatar">
        <div className="cw-modal-header-avatar_avatar">
          <CWSAvatar
            size={40}
            info={{ src: avatarSrc, type: 'user', alt: '' }}
          />
        </div>
        <div className="cw-modal-header-avatar_titles">
          <div className="cw-modal-header-avatar_titles_contact-name">
            {userName}
          </div>
          <div className="cw-modal-header-avatar_titles_company-name">
            {companyName}
          </div>
        </div>
      </div>
      <img
        className="cw-modal-header_cross"
        src="/img/cross-modal.svg"
        alt="close"
        onClick={onClose}
      />
    </div>
  );
};

HeaderDialog.propTypes = {
  employeeId: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
  dialog: PropTypes.shape({
    members: PropTypes.arrayOf(
      PropTypes.shape({
        employeeId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        avatarSrc: PropTypes.string,
        userName: PropTypes.string,
        companyName: PropTypes.string
      })
    )
  }).isRequired,
  onClose: PropTypes.func,
  className: PropTypes.string
};

HeaderDialog.defaultProps = {
  onClose: () => {},
  className: ''
};
// --------------------------------------------------------------------------------------------------------------------

// Modal channel header
const HeaderChannel = translate()(
  ({ t, channel = {}, onClose = () => {}, className = '' }) => {
    const { color = '', name = '', members = [] } = channel;
    const membersCount = members.length;

    return (
      <div className={`cw-modal-header ${className}`}>
        <div className="cw-modal-header-avatar">
          <div className="cw-modal-header-avatar_avatar">
            <CWSAvatar
              size={40}
              info={{ type: 'color-char', text: name, color }}
            />
          </div>
          <div className="cw-modal-header-avatar_titles">
            <div className="cw-modal-header-avatar_titles_contact-name">
              <img src="/img/chat-widget/channel.svg" alt="channel" />
              <span>{name}</span>
            </div>
            <div className="cw-modal-header-avatar_titles_company-name">
              {`${t('channel.amount_members')}: ${membersCount}`}
            </div>
          </div>
        </div>
        <img
          className="cw-modal-header_cross"
          src="/img/cross-modal.svg"
          alt="close"
          onClick={onClose}
        />
      </div>
    );
  }
);

HeaderChannel.propTypes = {
  channel: PropTypes.shape({
    color: PropTypes.string,
    name: PropTypes.string,
    members: PropTypes.array
  }).isRequired,
  onClose: PropTypes.func,
  className: PropTypes.string
};

HeaderChannel.defaultProps = {
  onClose: () => {},
  className: ''
};
// --------------------------------------------------------------------------------------------------------------------

// Modal topic header
const HeaderTopic = translate()(
  ({ t, topic = {}, onClose = () => {}, className = '' }) => {
    const { color = '', name = '', members = [] } = topic;
    const membersCount = members.length;

    return (
      <div className={`cw-modal-header ${className}`}>
        <div className="cw-modal-header-avatar">
          <div className="cw-modal-header-avatar_avatar">
            <CWSAvatar
              size={40}
              info={{ type: 'color-char', text: name, color }}
            />
          </div>
          <div className="cw-modal-header-avatar_titles">
            <div className="cw-modal-header-avatar_titles_contact-name">
              <img src="/img/chat-widget/topic.svg" alt="channel" />
              <span>{name}</span>
            </div>
            <div className="cw-modal-header-avatar_titles_company-name">
              {`${t('channel.amount_members')}: ${membersCount}`}
            </div>
          </div>
        </div>
        <img
          className="cw-modal-header_cross"
          src="/img/cross-modal.svg"
          alt="close"
          onClick={onClose}
        />
      </div>
    );
  }
);

HeaderTopic.propTypes = {
  topic: PropTypes.shape({
    color: PropTypes.string,
    name: PropTypes.string,
    members: PropTypes.array
  }).isRequired,
  onClose: PropTypes.func,
  className: PropTypes.string
};

HeaderTopic.defaultProps = {
  onClose: () => {},
  className: ''
};
// --------------------------------------------------------------------------------------------------------------------

// Modal body
const Body = ({ children = null, className = '', style }) => (
  <div className={`cw-modal-body ${className}`} style={{ ...style }}>
    {children}
  </div>
);

Body.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  style: PropTypes.object
};

Body.defaultProps = {
  className: '',
  style: {}
};
// --------------------------------------------------------------------------------------------------------------------

// Modal footer
const Footer = ({
  lbTitle = '',
  lbDisabled = false,
  lbHidden = false,
  lbOnClick = () => {},
  lbStyle = {},
  lbClassName = '',
  rbTitle = '',
  rbDisabled = false,
  rbHidden = false,
  rbOnClick = () => {},
  rbStyle = {},
  rbClassName = '',
  className = ''
}) => (
  <div className={`cw-modal-footer ${className}`}>
    {lbHidden ? (
      <span />
    ) : (
      <button
        type="button"
        className={`cw-modal-footer_button cw-modal-footer_button-left ${lbClassName}`}
        style={{ ...lbStyle }}
        disabled={lbDisabled}
        onClick={lbOnClick}>
        {lbTitle}
      </button>
    )}
    {rbHidden ? (
      <span />
    ) : (
      <button
        type="button"
        className={`cw-modal-footer_button cw-modal-footer_button-right ${rbClassName}`}
        style={{ ...rbStyle }}
        disabled={rbDisabled}
        onClick={rbOnClick}>
        {rbTitle}
      </button>
    )}
  </div>
);

Footer.propTypes = {
  lbTitle: PropTypes.string,
  lbDisabled: PropTypes.bool,
  lbHidden: PropTypes.bool,
  lbOnClick: PropTypes.func,
  lbStyle: PropTypes.object,
  lbClassName: PropTypes.string,
  rbTitle: PropTypes.string,
  rbDisabled: PropTypes.bool,
  rbHidden: PropTypes.bool,
  rbOnClick: PropTypes.func,
  rbStyle: PropTypes.object,
  rbClassName: PropTypes.string,
  className: PropTypes.string
};

Footer.defaultProps = {
  lbTitle: '',
  lbDisabled: false,
  lbHidden: false,
  lbOnClick: () => {},
  lbStyle: {},
  lbClassName: '',
  rbTitle: '',
  rbDisabled: false,
  rbHidden: false,
  rbOnClick: () => {},
  rbStyle: {},
  rbClassName: '',
  className: ''
};
// --------------------------------------------------------------------------------------------------------------------

export {
  Container,
  Header,
  HeaderAvatar,
  HeaderDialog,
  HeaderChannel,
  HeaderTopic,
  Body,
  Footer
};
