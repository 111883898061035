import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import ModalAddProductsToPrice from '../../components/Modal/ModalAddProductsToPrice';
import * as pricesAction from '../../action-creators/prices';
import * as modalAction from '../../action-creators/modal';

const ContModalAddProductsToPrice = (props) => (
  <ModalAddProductsToPrice {...props} />
);

export default connect(null, (dispatch) =>
  bindActionCreators(
    {
      hideModalDialog: modalAction.hideModalDialog,
      submit: pricesAction.addProductToPrice,
      updateSearchProductOfPrice: pricesAction.searchProductOfPrice,
      getPrice: pricesAction.getPrice
    },
    dispatch
  )
)(ContModalAddProductsToPrice);
