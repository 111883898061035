import { Map, fromJS } from 'immutable';

const loadProductMedia = (state, mediaType, item) =>
  state.set(mediaType, fromJS(item));

const updateProductMediaField = (state, mediaType, field, value) =>
  state.setIn([mediaType, field], value);

const trimProductMediaField = (state, mediaType, field) =>
  state.updateIn([mediaType, field], (value) => (value || '').trim());

const media = (state = Map(), action) => {
  switch (action.type) {
    case 'PRODUCT_MEDIA_LOAD':
      return loadProductMedia(state, action.mediaType, action.item);
    case 'PRODUCT_MEDIA_UPDATE_FIELD':
      return updateProductMediaField(
        state,
        action.mediaType,
        action.field,
        action.value
      );
    case 'PRODUCT_MEDIA_TRIM_FIELD':
      return trimProductMediaField(state, action.mediaType, action.field);
    default:
      return state;
  }
};

export default media;
