import {
  LISTS_SET,
  LISTS_CLEAR,
  LISTS_TOGGLE_ALL,
  LISTS_TOGGLE_ONE,
  LISTS_CLEAR_SELECT_LIST
} from '../actionTypes/lists';

export const generateActions = (name) => ({
  setList: (payload) => ({ type: LISTS_SET, name, payload }),
  toggleAllList: () => ({ type: LISTS_TOGGLE_ALL, name, payload: {} }),
  toggleOneList: (id) => ({ type: LISTS_TOGGLE_ONE, name, payload: { id } }),
  clearList: () => ({ type: LISTS_CLEAR, name, payload: {} }),
  clearSelectList: () => ({ type: LISTS_CLEAR_SELECT_LIST, name, payload: {} })
});
