import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { translate } from 'react-i18next';
import { withRouter } from '../hoc/withRouter';

import ContainersDefault from '../components/Containers/ContainersDefault';
import MessengerOther from '../components/Layouts/MessengerOther';
import TableFilesLinkInfo from '../containers/Tables/TableFilesLinkInfo';

import * as modalActions from '../action-creators/modal';
import * as ordersAction from '../action-creators/orders';
import {
  setGoBackPage,
  clearSelected,
  selectRow,
  removeOrderAttach,
  unsetLink,
  getFilesById,
  removeFileLink
} from '../action-creators/storage';
import { convertUrlToFile } from '../components/ChatWidget/modules/files/utils';

/* eslint-disable no-shadow */

class MFilesStorage extends Component {
  componentWillUnmount() {
    this.props.clearSelected();
  }

  get Buttons() {
    const {
      t,
      files,
      selected,
      section,
      match: {
        params: { fileId }
      },
      removeFileLink,
      getFilesById,
      showModal
    } = this.props;

    return [
      {
        name: t('Download'),
        onClick: async () => {
          selected.forEach((id) => {
            const f = files.find((file) => file.get('id') === id);
            window.open(
              `${convertUrlToFile(
                f.get('fileInfo.originalUrl') || f.get('fileInfo.url')
              )}?name=${f.get('fileInfo.name')}`,
              '_blank'
            );
          });
        },
        disabled: selected.size === 0
      },
      {
        name: t('Delete'),
        onClick: async () => {
          showModal('SIMPLE_SUBMIT', {
            captionKey: 'confirm_action',
            text: 'Delete?',
            textBtnConfirm: 'OK',
            submitAction: async () => {
              await removeFileLink(selected);
              await getFilesById(fileId, section);
            }
          });
        },
        disabled: selected.size === 0
      }
    ];
  }

  render() {
    const {
      t,
      files,
      selectRow,
      section,
      match: {
        params: { fileId }
      },
      removeFileLink,
      getFilesById,
      showModal
    } = this.props;

    const title = t('File storage');
    const breadcrumbs = [{ name: t('File storage') }];

    return (
      <MessengerOther
        title={title}
        breadcrumbs={breadcrumbs}
        panelBtns={this.Buttons}
        content={
          <>
            <ContainersDefault>
              <TableFilesLinkInfo
                fileId={fileId}
                files={files}
                getFiles={getFilesById}
                selectRow={selectRow}
                section={section}
                removeLink={removeFileLink}
                match={this.props.match}
                showModal={showModal}
              />
            </ContainersDefault>
          </>
        }
      />
    );
  }
}
/* eslint-enable no-shadow */

MFilesStorage.contextTypes = {
  badgeSocket: PropTypes.object
};

export default withRouter(
  connect(
    (state) => ({
      company: state.get('company'),
      selected: state.getIn(['storage', 'selected']),
      files: state.getIn(['storage', 'files'])
    }),
    (dispatch) =>
      bindActionCreators(
        {
          showModal: modalActions.showModal,
          selectRow,
          deleteAttach: ordersAction.deleteAttach,
          removeOrderAttach,
          clearSelected,
          setGoBackPage,
          unsetLink,
          // attachFile,
          getFilesById,
          removeFileLink
        },
        dispatch
      )
  )(translate(['ui'], { wait: true })(MFilesStorage))
);
