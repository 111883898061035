.checkbox {
  display: flex;

  &.noWrap {
    overflow: hidden;
  }

  :global(.MuiCheckbox-root) {
    margin: 0;
    padding: 0;
  }

  :global(.MuiCheckbox-root.Mui-disabled) > svg {
    fill: var(--color-gray-400);
    stroke: var(--color-gray-400);

    > rect:first-child {
      fill: var(--color-gray-400);
    }

    > rect:last-child {
      stroke: var(--color-gray-400);
    }
  }

  :global(.MuiCheckbox-root.Mui-disabled.MuiCheckbox-indeterminate) > svg,
  :global(.MuiCheckbox-root.Mui-disabled.Mui-checked) > svg {
    fill: var(--color-gray-600);
    stroke: var(--color-gray-600);

    > rect:first-child {
      fill: var(--color-gray-600);
    }

    > rect:last-child {
      stroke: var(--color-gray-600);
    }
  }

  :global(.MuiFormControlLabel-root) {
    overflow: hidden;
    text-overflow: ellipsis;
    gap: 8px;
    padding: 0;
    margin: 0;
  }

  :global(.MuiFormControlLabel-root input) {
    margin: 0;
  }

  :global(.MuiFormControlLabel-root.Mui-disabled) > span {
    color: var(--color-gray-600);
  }
}
