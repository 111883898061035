import React from 'react';

/* eslint-disable max-len */
const IconMessenger = () => (
  <svg
    width="42"
    height="42"
    viewBox="0 0 42 42"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <rect width="42" height="42" rx="21" fill="#EBEEF4" />
    <path
      className="cws-icon-main-fill"
      d="M21 32L18.6571 29.5078L16.0071 29.5007C13.8 29.5007 12 27.7114 12 25.5174V15.9833C12 13.7893 13.8 12 16.0071 12H25.9929C28.2 12 30 13.7893 30 15.9833V16.6436H27.4286V15.9833C27.4286 15.1951 26.7857 14.5561 25.9929 14.5561H16.0071C15.2143 14.5561 14.5714 15.1951 14.5714 15.9833V25.5174C14.5714 26.3055 15.2143 26.9446 16.0071 26.9446L19.7714 26.9517L20.9929 28.251L22.2143 26.9446L25.9857 26.9375C26.7786 26.9375 27.4214 26.2984 27.4214 25.5103V21.5428C27.4214 21.2517 27.1786 21.0103 26.8857 21.0103H20.2714C19.9643 21.0103 19.7143 21.2588 19.7143 21.5641C19.7143 21.8695 19.9643 22.118 20.2714 22.118H24.1571V24.6741H20.2714C18.5429 24.6741 17.1429 23.2753 17.1429 21.5641C17.1429 19.8459 18.55 18.4542 20.2714 18.4542H26.8857C28.6 18.4542 29.9929 19.8388 29.9929 21.5428V25.5103C29.9929 27.7043 28.1929 29.4936 25.9857 29.4936L23.3357 29.5007L21 32Z"
      fill="#767B92"
    />
  </svg>
);
/* eslint-enable max-len */
//
export default IconMessenger;
