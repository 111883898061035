/* eslint-disable no-param-reassign */
import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import CreatableSelect from 'react-components/creatableSelect';
import MaskInput from 'react-components/inputs/mask-input';
import { Button, Icon, Typography } from '@link/react-components';
import { TAX_PERCENT } from '../constants';
import { round } from '../../../utils';
import { getVatSum } from '../../../utils/utils';
import SortUpIcon from '../../../icons/sort-up';
import SortDownIcon from '../../../icons/sort-down';
import IconSimplePlus from '../../../icons/plus';
import IconSimpleCross from '../../../icons/cross';
import Tooltip from '../../ChatWidget/Tooltip';
import { countSum, getSumWithVat } from '../NewRequest/utils';
import { useTranslate } from '../../../TranslateProvider';

const UP = 'up';
const DOWN = 'down';

const ProductsTable = ({
  products = [],
  defaultProducts = {},
  handlePasteTable = () => () => {},
  handleChangeProduct = () => () => {},
  onFocusPrice = () => () => {},
  handleRemoveProductRow,
  handleAddProductRow,
  editMode = true,
  editPriceAndCount = false,
  isErrorShown,
  show = true,
  isShowExportToExcelBtn = true,
  exportToExcelBtn
}) => {
  const t = useTranslate();

  if (!show) return null;
  const disableSort = editMode || editPriceAndCount;

  const UNITS = [
    { value: 'instances', label: t('units.instances') },
    { value: 'amount', label: t('units.amount') },
    { value: 'kg', label: t('units.kg') },
    { value: 'liter', label: t('units.liter') },
    { value: 'packages', label: t('units.packages') },
    { value: 'm', label: t('units.m') },
    { value: 'm2', label: t('units.m2') },
    { value: 'm3', label: t('units.m3') }
  ];

  const [sort, setSort] = useState({});
  const [hoverColumn, setHoverColumn] = useState({});

  const sortButtons = (key) =>
    (sort[key] || hoverColumn === key) &&
    !disableSort && (
      <div className="product-table-sort-buttons" key={key}>
        <SortUpIcon
          isActive={sort[key] === UP}
          onClick={() => setSort({ [key]: UP })}
        />
        <SortDownIcon
          isActive={sort[key] === DOWN}
          onClick={() => setSort({ [key]: DOWN })}
        />
      </div>
    );

  const setHover = (name) => () => setHoverColumn(name);

  const handleSort = (a, b) => {
    const key = Object.keys(sort)[0];
    const direction = sort[key] === UP ? 1 : -1;

    if (key === 'name') {
      if (a[key].toLowerCase() === b[key].toLowerCase()) {
        return 0;
      }
      return direction * (a[key].toLowerCase() > b[key].toLowerCase() ? 1 : -1);
    }

    if (key === 'sum') {
      return direction * (Number(getSumWithVat(a)) - Number(getSumWithVat(b)));
    }

    return direction * (Number(a[key]) - Number(b[key]));
  };

  const sortProducts = () => products.sort(handleSort);

  const isRowEmpty = (row) => !(row.name || row.price || row.count);

  const validate = (product, value) =>
    !isRowEmpty(product) &&
    !value &&
    (isErrorShown?.save || isErrorShown?.publish)
      ? 'error-border'
      : '';

  const onChangeUnit = (product, index) => (target) =>
    handleChangeProduct(product, index, 'unit')({ target });

  const getUnitValue = (product) =>
    UNITS.find((unit) => unit.value === product.unit) || {
      value: '',
      label: product.unit || '-'
    };

  const handleInputTextarea = (product, index, id) => (event) => {
    document.getElementById(id).style.height = '32px';
    document.getElementById(id).style.height = `${event.target.scrollHeight}px`;
    handleChangeProduct(product, index, 'name')(event);
  };

  useEffect(() => {
    document.querySelectorAll('textarea').forEach((textarea) => {
      textarea.style.height = '32px';
      textarea.style.height = `${textarea.scrollHeight}px`;
    });
  }, [products.length]);

  return (
    <div style={{ width: '100%' }}>
      <div className="product-table" data-testid="product-table">
        <div
          className="product-table-head"
          data-testid="product-table-paragraph">
          {t('Paragraph')}
        </div>
        <div
          className="product-table-head"
          data-testid="product-table-products-name"
          onMouseEnter={setHover('name')}
          onMouseLeave={setHover('')}>
          {t('products_name')}
          {sortButtons('name')}
        </div>
        <div
          className="product-table-head"
          data-testid="product-table-count"
          onMouseEnter={setHover('count')}
          onMouseLeave={setHover('')}>
          {t('Count')}
          {sortButtons('count')}
        </div>
        <div
          className="product-table-head"
          data-testid="product-table-unit-unit">
          {t('units.units')}
        </div>
        <div
          className="product-table-head"
          data-testid="product-table-price"
          onMouseEnter={setHover('price')}
          onMouseLeave={setHover('')}>
          {t('Price for one in rub')}
          {sortButtons('price')}
        </div>
        <div
          className="product-table-head"
          onMouseEnter={setHover('sum')}
          onMouseLeave={setHover('')}
          data-testid="product-table-order-price-with-taxes">
          {t('Order price with taxes')}

          {sortButtons('sum')}
        </div>
      </div>

      {sortProducts().map((product, index) => (
        <div
          key={`${index}pr`}
          className="product-table-space"
          data-testid="product-table-space"
          style={editMode ? {} : { padding: 0, position: 'relative' }}>
          {editMode && (
            <div
              className="product-table-delete"
              data-testid="product-table-delete"
              onClick={handleRemoveProductRow(index)}>
              <Tooltip text="delete_row">
                <IconSimpleCross />
              </Tooltip>
            </div>
          )}
          <div className="product-table">
            <div>{index + 1}.</div>
            <div className={validate(product, product.name)}>
              {editMode ? (
                <textarea
                  data-testid="product-table-product-placeholder"
                  id={`${index}pr-name`}
                  value={product.name}
                  onChange={handleInputTextarea(
                    product,
                    index,
                    `${index}pr-name`
                  )}
                  onPaste={handlePasteTable(index)}
                  placeholder={t('product_placeholder')}
                />
              ) : (
                <>{product.name}</>
              )}
            </div>
            <div
              className={`${validate(
                product,
                product.count
              )} product-table-number`}
              data-testid="product-table-number">
              <MaskInput
                mask="amount"
                min="1"
                type="number"
                placeholder={
                  defaultProducts[index] ? defaultProducts[index].count : 0
                }
                value={product.count}
                onChange={(value) =>
                  handleChangeProduct(
                    product,
                    index,
                    'count'
                  )({ target: { value } })
                }
                onPaste={handlePasteTable(index)}
                readOnly={!(editMode || editPriceAndCount)}
              />
            </div>
            <div
              className={classNames(
                validate(product, product.unit),
                'product-table-unit'
              )}
              data-testid="product-table-unit"
              style={{ overflow: 'visible', padding: 0 }}>
              {editMode ? (
                <CreatableSelect
                  data-testid="product-table-unit-select"
                  value={getUnitValue(product)}
                  onChange={onChangeUnit(product, index)}
                  placeholder=""
                  options={UNITS}
                  promptTextCreator={(input) => input}
                  clearable={false}
                  noResultsText={t('noResultsText')}
                />
              ) : (
                <div
                  style={{ paddingLeft: 9 }}
                  data-testid="product-table-get-unit-value">
                  {getUnitValue(product).label}
                </div>
              )}
            </div>
            <div className="product-table-number">
              <MaskInput
                mask="amount"
                min="0"
                type="number"
                maxLength="99999999999"
                data-testid={`product-table-number-withoutVat-test-${index}`}
                placeholder={
                  defaultProducts[index] ? defaultProducts[index].price || 0 : 0
                }
                value={product.price}
                onChange={(value) =>
                  handleChangeProduct(
                    product,
                    index,
                    'price'
                  )({ target: { value } })
                }
                onPaste={handlePasteTable(index)}
                onFocus={onFocusPrice(product, index)}
                readOnly={!(editMode || editPriceAndCount)}
              />
            </div>
            <div
              className="product-table-number"
              data-testid={`product-table-number-withVat-test-${index}`}>
              {getSumWithVat(product)}
            </div>
          </div>
        </div>
      ))}

      <div className="product-table">
        {editMode && (
          <div onClick={handleAddProductRow} className="product-table-plus">
            <Tooltip text="add_row">
              <IconSimplePlus />
            </Tooltip>
          </div>
        )}
        <div
          className="product-table-collspan"
          data-testid="product-table-price-with-vat">
          {`${t('Price\n with VAT')}`}
        </div>
        <div
          className="product-table-number"
          data-testid="product-table-count-sum">
          {round(countSum(products), 2)}
        </div>
        <div
          className="product-table-collspan"
          data-testid="product-table-including-vat">
          {t('includingVAT')}
        </div>
        <div
          className="product-table-number"
          data-testid="product-table-get-vat-sum">
          {round(getVatSum(countSum(products), TAX_PERCENT), 2)}
        </div>
      </div>

      {exportToExcelBtn && isShowExportToExcelBtn ? (
        <div className="product-table-export-block">
          <div className="product-table-export-excel">
            <Button
              onClick={() => exportToExcelBtn.onClick(products)}
              className="export-to-excel-btn"
              mode="outline"
              before={<Icon iconName="excel" width={18} height={18} />}>
              {t(exportToExcelBtn.tkeyText)}
            </Button>
            <div className="product-table-export-excel-info">
              <Icon iconName="info" width={14} height={14} />
              <Typography
                className="product-table-export-excel-info-text"
                variant="body1Reg">
                {t('respondForm.form.youCanUseThisFile')}
              </Typography>
              <div />
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default ProductsTable;
