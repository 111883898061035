import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import ModalAddProductsToStock from '../../components/Modal/ModalAddProductsToStock';
import * as stocksAction from '../../action-creators/stocks';
import * as modalAction from '../../action-creators/modal';

const ContModalAddProductsToStock = (props) => (
  <ModalAddProductsToStock {...props} />
);

export default connect(null, (dispatch) =>
  bindActionCreators(
    {
      hideModalDialog: modalAction.hideModalDialog,
      submit: stocksAction.addProductToStock,
      updateSearchProductOfStock: stocksAction.searchProductOfStock
    },
    dispatch
  )
)(ContModalAddProductsToStock);
