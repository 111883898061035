import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { ModalEditPriceCategory } from '../components/Modal';
import * as pricesAction from '../action-creators/prices';
import * as modalAction from '../action-creators/modal';

const ContModalEditPriceCategory = (props) => (
  <ModalEditPriceCategory {...props} />
);

const getCategoryItem = (state) => {
  let item = null;
  const selected = state.getIn(['prices', 'selectedCategories']);
  state.getIn(['prices', 'priceCategories']).filter((category) => {
    if (category.get('id') === selected.first()) {
      item = category;
      return true;
    }
    return false;
  });
  return item;
};

export default connect(
  (state) => ({
    selectedCat: getCategoryItem(state)
  }),
  (dispatch) =>
    bindActionCreators(
      {
        updatePriceCategory: pricesAction.updatePriceCategory,
        setSelectedCategory: pricesAction.setSelectedCategory,
        getPriceCategories: pricesAction.getPriceCategories,
        hideModalDialog: modalAction.hideModalDialog
      },
      dispatch
    )
)(ContModalEditPriceCategory);
