.label {
  display: block;
  margin-bottom: var(--indent-xxs, 4px);
}

.withLabel {
  justify-content: right;
}

.labelContent {
  display: flex;
  justify-content: space-between;
  column-gap: var(--indent-xxs, 4px);
  color: var(--color-gray-800, #5b5f73);
}

.input {
  box-sizing: border-box;
  width: 100%;
  padding: 0 var(--indent-m, 16px);
  background-color: var(--color-white);
  border: 1px solid var(--color-border-chat);
  border-radius: 4px;
  height: 34px;
  color: var(--color-gray-900);
  outline: none;

  &.isError {
    border-color: var(--color-red-error);
  }

  &::placeholder {
    color: var(--color-gray-600);
  }

  &:disabled {
    border-color: var(--color-blue-200);
    background: inherit;
  }

  &:hover {
    border-color: var(--color-blue-200);
  }

  &:focus {
    border: 1px solid var(--color-blue-500);
    box-shadow: 0 0 5px rgb(35 105 241 / 31%);
  }
}
