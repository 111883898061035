import React from 'react';
import { NavLink } from 'react-router-dom';
import block from 'bem-cn-lite';
import { useTranslate } from '../../../../TranslateProvider';
import Badge from './Badge';
import Icon from './Icon';

const b = block('snm-section');

const Section = ({
  section,
  isMain = false,
  isActive = false,
  isShowBadge = true,
  isShowIcon = false,
  isActiveIcon = false
}) => {
  const t = useTranslate();

  return (
    <NavLink
      to={section.to}
      target={section.target}
      className={b({
        active: isActive,
        main: isMain,
        nest: !isMain
      })}>
      {isShowIcon && <Icon icon={section.icon} isActive={isActiveIcon} />}
      {t(section.name)}
      {isShowBadge && section.isShowBadge && (
        <Badge amount={section.amountNotify} />
      )}
    </NavLink>
  );
};

export default Section;
