import React from 'react';
import Button from '../Button';
import './style.css';

const ButtonSmallArrow = ({ children, isActive, onClick }) => (
  <div
    className={`add-button arrow-btn ${isActive ? 'arrow-btn-active' : ''}`}
    onClick={onClick}>
    <Button>
      {children}
      <svg
        width="10"
        height="6"
        viewBox="0 0 10 6"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path d="M9 5L5 1L1 5" stroke="#1C50DE" />
      </svg>
    </Button>
  </div>
);

export default ButtonSmallArrow;
