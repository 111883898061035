import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { useNavigate, useParams } from '@hooks';
import { useActions } from '@hooks/useActions';
import Request from '../components/PurchaseRequests/Request';

import * as storeGetters from '../storeGetters';
import MessengerShare from '../components/Layouts/MessengerShare';
import { useTranslate } from '../TranslateProvider';
import * as purchaseRequestsActions from '../action-creators/purchaseRequests';

function getPrivateRequestPath(requestId) {
  return `/requests/all/${requestId}`;
}

const MPurchaseRequestShare = () => {
  const { requestId } = useParams();
  const navigate = useNavigate();

  const t = useTranslate();

  const isAuth = useSelector(storeGetters.isAuth);
  const { getPublicRequest } = useActions(purchaseRequestsActions);

  useEffect(() => {
    if (isAuth) {
      navigate(getPrivateRequestPath(requestId));
    } else {
      getPublicRequest(requestId);
    }
  }, [isAuth]);

  if (isAuth) return null;

  return (
    <MessengerShare
      theme="gray"
      title={t('Purchase requests')}
      content={
        <Request
          requestId={requestId}
          isShowBackBtn={false}
          isShowExportToExcelBtn={false}
        />
      }
    />
  );
};

export default MPurchaseRequestShare;
