import React, { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useActions } from '@hooks/useActions';
import styles from '../Layout.module.css';
import Logo from '../../../components/MessengerLogo';
import LanguageSwitcher from '../../../components/LanguageSwitcher';
import LinkBtn from '../../../components/SidebarNavMenu/common/components/Footer/LinkBtn';
import Icon from '../../../components/SidebarNavMenu/common/components/Icon';
import * as pushesUC from '../../../components/ChatWidget/modules/pushes/useCases';
import userStorage from '../../../storage/user.storage';
import { getCurrentUser } from '../../../storeGetters';
import { useTranslate } from '../../../TranslateProvider';

const HeaderSeparator = () => <div className={styles.headerSeparator} />;

export const Header = () => {
  const t = useTranslate();
  const { setPushNotificationsMuted } = useActions(pushesUC);

  const handleLogout = useCallback(async () => {
    setPushNotificationsMuted();
    await userStorage.messengerLogout();
  }, []);

  const user = useSelector(getCurrentUser);

  const userName = useMemo(() => {
    if (!user || !user.get('firstName')) {
      return t('Profile');
    }

    if (user && user.get('type') === 'demo') {
      return t('Demo user');
    }

    return `${user.get('lastName')} ${user.get('firstName')}`;
  }, [user]);

  return (
    <header className={styles.header}>
      <div className={styles.headerColumnLeft}>
        <div className={styles.headerLogo}>
          <Logo link="https://istock.link/" />
        </div>
      </div>

      <div className={styles.headerColumnRight}>
        <LanguageSwitcher />
        <HeaderSeparator />
        <a className={styles.userName} href="/profile/edit">
          {userName}
        </a>
        <LinkBtn type="button" onClick={handleLogout}>
          <Icon icon="exit" />
        </LinkBtn>
      </div>
    </header>
  );
};
