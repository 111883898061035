export const STEP_TYPES = {
  agreement: 'agreement',
  introduction: 'introduction',
  publication: 'publication',
  begin: 'begin'
};

export const MAX_STEPS_IN_PATH = 15;

export const ACCEPTABLE_AGREEMENT_CARDS = 15;

export const THRESHOLD_FOR_PARALLEL_STEP = 1;

export const MAX_USERS_IN_INTRODUCTION_CARD = 10;
