import React from 'react';
import Tooltip from '../../tooltip';
import IconArrowLeft from './icons/arrow-left';
import IconArrowRight from './icons/arrow-right';
import IconBell from './icons/bell';
import IconBellOff from './icons/bell-off';
import IconView from './icons/view';
import IconSearch from './icons/search';
import IconSimpleSearch from './icons/simple-search';
import IconAddManCircle from './icons/add-man-circle';
import IconAddMan from './icons/add-man';
import IconConversationM from './icons/conversation-m';
import IconAdd from './icons/add';
import IconAddM from './icons/add-m';
import IconRemoveM from './icons/remove-m';
import IconRemove1 from './icons/remove-1';
import IconRemove2 from './icons/remove-2';
import IconFolder from './icons/folder';
import IconForward from './icons/forward';
import IconExtra from './icons/extra';
import IconStar from './icons/star';
import IconChat from './icons/chat';
import IconSettings1 from './icons/settings-1';
import IconFilter from './icons/filter';
import IconEyeOff from './icons/eye-off';
import IconCalendar from './icons/calendar';
import IconChat1Circle from './icons/chat-1-circle';
import IconCopyM from './icons/copy-m';
import IconCall from './icons/call';
import IconVideoCall from './icons/video-call';
import IconDownload from './icons/download';
import IconDownload1 from './icons/download-1';
import IconSaveFile from './icons/save-file';
import IconShare from './icons/share';
import IconShare1 from './icons/share-1';
import IconEditCircle from './icons/edit-circle';
import IconArrowUpCircle from './icons/arrow-up-circle';
import IconDownUpCircle from './icons/arrow-down-circle';
import IconArrowLeftCircle from './icons/arrow-left-circle';
import IconAttach from './icons/attach';
import IconCross from './icons/cross';
import IconHideCircle from './icons/hide';
import IconHideOffCircle from './icons/hide-off';
import IconDangerExclamation from './icons/danger-exclamation';
import IconPlus from './icons/plus';
import IconMinus from './icons/minus';
import IconFitIn from './icons/fit-in';
import IconPlusS from './icons/plus-s';
import IconMinusS from './icons/minus-s';
import IconFitInS from './icons/fit-in-s';
import IconPerson from './icons/person';
import IconCross1 from './icons/cross-1';
import IconOk from './icons/ok';
import IconMessage from './icons/message';
import IconMessageCircle from './icons/message-circle';
import IconMessage1 from './icons/message-1';
import IconOkCircle from './icons/ok-circle';
import IconCancelCircle from './icons/cancel-circle';
import IconFilterCircle from './icons/filter-circle';
import IconPlusRoundCircle from './icons/plus-round-circle';
import IconMinusRoundCircle from './icons/minus-round-circle';
import IconSettingsCircle from './icons/settings-circle';
import IconVecticalExtra from './icons/vertical-extra';
import IconCrossCircle from './icons/cross-circle';
import './style.css';
import IconVideo from '../../src/icons/18x18/video';
import IconNotification from '../../src/icons/18x18/notification';
import IconNotificationMute from '../../src/icons/18x18/notification-mute';
import IconDelayedMessage from '../../src/icons/18x18/delayed-message';
import IconDelayedMessageEmpty from '../../src/icons/18x18/delayed-message-empty';

function ButtonIcon({
  t,
  icon,
  disabled,
  tooltipId,
  active = false,
  color = 'gray',
  activeColor = 'blue',
  tooltip,
  onClick = () => {},
  width,
  height
}) {
  return (
    <button
      className={`button-icon ${disabled ? 'disabled' : ''} ${
        active ? 'active' : ''
      } button-icon_color_${color} button-icon_active-color_${activeColor}`}
      type="button"
      onClick={(event) => !disabled && onClick(event)}>
      <Tooltip t={t} text={tooltip} tooltipId={tooltipId}>
        <div className="button-icon__img" onClick={onClick}>
          {icon === 'person' && <IconPerson />}
          {icon === 'arrow-left' && <IconArrowLeft />}
          {icon === 'arrow-right' && <IconArrowRight />}
          {icon === 'bell' && <IconBell />}
          {icon === 'bell-off' && <IconBellOff />}
          {icon === 'view' && <IconView />}
          {icon === 'search' && <IconSearch />}
          {icon === 'simple-search' && <IconSimpleSearch />}
          {icon === 'add-man' && <IconAddMan />}
          {icon === 'add' && <IconAdd />}
          {icon === 'remove-1' && <IconRemove1 />}
          {icon === 'remove-2' && <IconRemove2 />}
          {icon === 'folder' && <IconFolder />}
          {icon === 'forward' && <IconForward />}
          {icon === 'extra' && <IconExtra />}
          {icon === 'vertical-extra' && <IconVecticalExtra />}
          {icon === 'star' && <IconStar />}
          {icon === 'chat' && <IconChat />}
          {icon === 'settings-1' && <IconSettings1 />}
          {icon === 'filter' && <IconFilter />}
          {icon === 'eye-off' && <IconEyeOff />}
          {icon === 'calendar' && <IconCalendar />}
          {icon === 'call' && <IconCall />}
          {icon === 'video-call' && <IconVideoCall />}
          {icon === 'video' && <IconVideo />}
          {icon === 'notification' && <IconNotification />}
          {icon === 'notification-mute' && <IconNotificationMute />}
          {icon === 'download' && <IconDownload />}
          {icon === 'download-1' && <IconDownload1 />}
          {icon === 'save-file' && <IconSaveFile />}
          {icon === 'share' && <IconShare />}
          {icon === 'share-1' && <IconShare1 />}
          {icon === 'attach' && <IconAttach />}
          {icon === 'cross' && <IconCross width={width} height={height} />}
          {icon === 'danger-exclamation' && <IconDangerExclamation />}
          {icon === 'plus' && <IconPlus />}
          {icon === 'minus' && <IconMinus />}
          {icon === 'fit-in' && <IconFitIn />}
          {icon === 'cross-1' && <IconCross1 />}
          {icon === 'ok' && <IconOk />}
          {icon === 'message' && <IconMessage />}
          {icon === 'delayed-message' && <IconDelayedMessage />}
          {icon === 'delayed-message-empty' && <IconDelayedMessageEmpty />}
          {icon === 'message-1' && (
            <IconMessage1 width={width} height={height} />
          )}

          {/* icons with circle */}
          {icon === 'chat-1-circle' && <IconChat1Circle />}
          {icon === 'add-man-circle' && <IconAddManCircle />}
          {icon === 'edit-circle' && <IconEditCircle />}
          {icon === 'arrow-up-circle' && (
            <IconArrowUpCircle width={width} height={height} />
          )}
          {icon === 'arrow-down-circle' && (
            <IconDownUpCircle width={width} height={height} />
          )}
          {icon === 'arrow-left-circle' && <IconArrowLeftCircle />}
          {icon === 'arrow-right-circle' && (
            <IconArrowLeftCircle style={{ transform: 'rotate(180deg)' }} />
          )}
          {icon === 'hide' && <IconHideCircle />}
          {icon === 'hide-off' && <IconHideOffCircle />}
          {icon === 'message-circle' && (
            <IconMessageCircle width={width} height={height} />
          )}
          {icon === 'ok-circle' && <IconOkCircle />}
          {icon === 'cancel-circle' && <IconCancelCircle />}
          {icon === 'filter-circle' && <IconFilterCircle />}
          {icon === 'plus-round-circle' && <IconPlusRoundCircle />}
          {icon === 'minus-round-circle' && <IconMinusRoundCircle />}
          {icon === 'settings-circle' && <IconSettingsCircle />}
          {icon === 'cross-circle' && <IconCrossCircle />}

          {/* size m */}
          {icon === 'add-m' && <IconAddM />}
          {icon === 'remove-m' && <IconRemoveM />}
          {icon === 'copy-m' && <IconCopyM />}
          {icon === 'conversation-m' && <IconConversationM />}

          {/* size s */}
          {icon === 'plus-s' && <IconPlusS />}
          {icon === 'minus-s' && <IconMinusS />}
          {icon === 'fit-in-s' && <IconFitInS />}
        </div>
      </Tooltip>
    </button>
  );
}

ButtonIcon.defaultProps = {
  active: false,
  disabled: false,
  color: 'gray',
  activeColor: 'blue',
  tooltip: '',
  onClick: () => {}
};

export default ButtonIcon;
