import React, { Component } from 'react';
import PropTypes from 'prop-types';
import block from 'bem-cn-lite';
import { Map } from 'immutable';
import { translate } from 'react-i18next';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  Modal,
  Col,
  Row,
  Form,
  FormGroup,
  FormControl,
  ControlLabel,
  Button,
  HelpBlock
} from 'react-bootstrap';
import Select from 'react-select';

import '../styles/modal_theme_default.styl';
import * as servicesAction from '../../../action-creators/services';
import * as customersAction from '../../../action-creators/customers';

import { isMessengerService } from '../../../lib/goodwix';
import { ModalContainer } from '../elements';
import { validateEmail } from '../../../utils/validateField';
import { InputText } from '../../FormControls';
import * as companyAction from '../../../action-creators/company';
import keyDebounce from '../../../utils/debounce';

const languages = [
  { value: 'ru', label: 'Русский' },
  { value: 'en', label: 'English' }
];

const b = block('modal-container');

class ModalAddCustomer extends Component {
  static isEmailValid(value) {
    if (value && value.length) {
      return validateEmail(value);
    }
    return true;
  }

  constructor(props) {
    super(props);
    this.onKeyUp = keyDebounce(this.onKeyUp.bind(this));
    this.inputProps = this.inputProps.bind(this);
    this.isValidNameField = this.isValidNameField.bind(this);
    this.state = {
      pristine: true,
      lockName: false,
      consignees: [{ consignee: '', identifier: '' }],
      language: props.company.get('language')
    };
  }

  getChildContext() {
    return { b };
  }

  UNSAFE_componentWillMount() {
    const { gaPageView, getGroups, groups } = this.props;
    gaPageView('customer_add_open');
    if (!groups || groups.size === 0) {
      getGroups();
    }
  }

  componentWillUnmount() {
    this.props.clearForm();
  }

  getProperty(field) {
    return this.props.newCustomer.has(field)
      ? this.props.newCustomer.get(field)
      : '';
  }

  isValidNameField() {
    const { newCustomer } = this.props;
    return newCustomer.get('email').length < 1;
  }

  asterisk() {
    // eslint-disable-line
    return <span style={{ color: 'red' }}>*</span>;
  }

  async onKeyUp() {
    const { getACompanyName, newCustomer } = this.props;
    const { companyName } = await getACompanyName(newCustomer.get('email'));
    if (companyName !== null) {
      this.setState({ lockName: true });
      return this.props.updateCustomerField('companyName', companyName);
    }
    this.setState({ lockName: false });
    return this.props.updateCustomerField(
      'companyName',
      newCustomer.get('companyName')
    );
  }

  inputProps(field) {
    return {
      value: this.props.newCustomer.get(field),
      onChange: (e) => {
        if (field === 'email') {
          return this.props.updateCustomerField(field, e.target.value.trim());
        }
        return this.props.updateCustomerField(field, e.target.value);
      }
    };
  }

  showValidMailInfo() {
    const { t, newCustomer, company } = this.props;
    if (!ModalAddCustomer.isEmailValid(this.getProperty('email'))) {
      return (
        <HelpBlock id="invalidFieldMessage" style={{ color: 'red' }}>
          {t('Wrong email format')}
        </HelpBlock>
      );
    }
    if (newCustomer.get('email') === company.get('email')) {
      return (
        <HelpBlock id="invalidFieldMessage" style={{ color: 'red' }}>
          {t('Can not invite yourself')}
        </HelpBlock>
      );
    }
    return null;
  }

  render() {
    const { badgeSocket: socket } = this.context;
    const {
      t,
      captionKey,
      hideModalDialog,
      newCustomer,
      addCustomer,
      groups,
      gaSend,
      getAllInvitations,
      company,
      user
    } = this.props;

    const extGroups = groups.unshift(
      new Map({ id: 0, name: t('All customers') })
    );
    const category = extGroups
      .filter((i) => !i.get('service'))
      .map((group) => ({ value: group.get('id'), label: group.get('name') }));

    const EXT_FUNC_1_ACCS =
      process.env.EXT_FUNC_1_ACCS && process.env.EXT_FUNC_1_ACCS.split(',');

    return (
      <ModalContainer mods={{ theme: 'default' }}>
        <Modal id="addCategory" onHide={() => hideModalDialog()} show>
          <Modal.Header>
            <Modal.Title>
              {t(captionKey)}
              <span
                className="modal-header-cross pull-right"
                onClick={() => hideModalDialog()}
              />
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              {t(
                isMessengerService()
                  ? 'invite_customer-suppler-modal-text-messenger'
                  : 'invite_customer-suppler-modal-text'
              )}
            </div>
            <Form>
              <FormGroup
                controlId="email"
                validationState={
                  (this.isValidNameField() ||
                    !ModalAddCustomer.isEmailValid(
                      this.getProperty('email') ||
                        newCustomer.get('email') === company.get('email')
                    )) &&
                  !this.state.pristine
                    ? 'error'
                    : null
                }
                style={{ overflow: 'hidden', marginBottom: 0, marginTop: 10 }}>
                <ControlLabel>
                  {`${t('E-mail')} (${t('only latin')}) `}
                  {this.asterisk()}
                </ControlLabel>
                <FormControl
                  type="text"
                  {...this.inputProps('email')}
                  placeholder="example@email.com"
                  maxLength="60"
                  onKeyUp={this.onKeyUp}
                  onKeyDown={() => {
                    if (this.state.pristine) {
                      this.setState({ pristine: false });
                    }
                  }}
                />
                <h6
                  className="pull-right"
                  style={{ marginTop: 5, marginBottom: 0 }}>
                  <small>
                    {newCustomer.get('email').length}
                    /60
                  </small>
                </h6>
                {this.isValidNameField() && !this.state.pristine && (
                  <HelpBlock style={{ marginBottom: 0 }}>
                    {t('Required fields empty')}
                  </HelpBlock>
                )}
                {this.showValidMailInfo()}
              </FormGroup>
              <FormGroup
                controlId="companyName"
                style={{ overflow: 'hidden', marginBottom: 0 }}>
                <ControlLabel>{t('Company name')}</ControlLabel>
                <FormControl
                  disabled={this.state.lockName}
                  type="text"
                  {...this.inputProps('companyName')}
                  maxLength="60"
                />
                <h6
                  className="pull-right"
                  style={{ marginTop: 5, marginBottom: 0 }}>
                  <small>
                    {newCustomer.get('companyName').length}
                    /60
                  </small>
                </h6>
              </FormGroup>
              <Row>
                <Col md={6}>
                  <FormGroup controlId="category">
                    <ControlLabel>{t('invite_lang')}</ControlLabel>
                    <Select
                      id="language-select"
                      value={this.state.language}
                      onChange={({ value }) =>
                        this.setState({ language: value })
                      }
                      options={languages}
                      clearable={false}
                      searchable={false}
                    />
                  </FormGroup>
                </Col>
                {!isMessengerService() && (
                  <Col md={6}>
                    <FormGroup controlId="category">
                      <ControlLabel>{t('Suppliers groups')}</ControlLabel>
                      <Select
                        id="group-select"
                        value={this.props.newCustomer.get('category')}
                        onChange={(e) =>
                          this.props.updateCustomerField('category', e.value)
                        }
                        options={category.toJS()}
                        placeholder={t('All customers')}
                        clearable={false}
                        searchable={false}
                      />
                    </FormGroup>
                  </Col>
                )}
              </Row>
              {EXT_FUNC_1_ACCS &&
                EXT_FUNC_1_ACCS.includes(company.get('email')) && (
                  <FormGroup
                    controlId="consignees"
                    style={{ overflow: 'hidden' }}>
                    <ControlLabel>{t('Consignees')}</ControlLabel>
                    <div style={{ width: '93%', display: 'inline-block' }}>
                      <Row>
                        <Col md={9}>
                          <FormGroup
                            controlId="consigneeNameLabel"
                            style={{ overflow: 'hidden', marginBottom: 0 }}>
                            <ControlLabel>{t('Name')}</ControlLabel>
                          </FormGroup>
                        </Col>
                        <Col md={3}>
                          <FormGroup
                            controlId="consigneeIdentifierLabel"
                            style={{ overflow: 'hidden', marginBottom: 0 }}>
                            <ControlLabel>{t('Identifier')}</ControlLabel>
                          </FormGroup>
                        </Col>
                      </Row>
                    </div>
                    {this.state.consignees &&
                      this.state.consignees.map(
                        ({ consignee, identifier }, n) => (
                          <div style={{ marginBottom: 10 }}>
                            <div
                              style={{ width: '93%', display: 'inline-block' }}>
                              <Row>
                                <Col md={9}>
                                  <FormGroup
                                    controlId="consigneeName"
                                    style={{
                                      overflow: 'hidden',
                                      marginBottom: 0
                                    }}>
                                    <InputText
                                      maxLength="128"
                                      placeholder={`${t('Consignee')} ${n + 1}`}
                                      value={consignee}
                                      onChange={(e) => {
                                        const { consignees } = this.state;
                                        consignees[n].consignee =
                                          e.target.value;
                                        this.setState({ consignees });
                                      }}
                                      onKeyDown={(e) =>
                                        e.keyCode === 13
                                          ? e.preventDefault()
                                          : null
                                      }
                                    />
                                  </FormGroup>
                                </Col>
                                <Col md={3}>
                                  <FormGroup
                                    controlId="consigneeIdentifier"
                                    style={{
                                      overflow: 'hidden',
                                      marginBottom: 0
                                    }}>
                                    <InputText
                                      placeholder={12345678}
                                      maxLength="8"
                                      value={identifier}
                                      onChange={(e) => {
                                        const { consignees } = this.state;
                                        consignees[n].identifier =
                                          e.target.value;
                                        this.setState({ consignees });
                                      }}
                                      onKeyDown={(e) =>
                                        e.keyCode === 13
                                          ? e.preventDefault()
                                          : null
                                      }
                                    />
                                  </FormGroup>
                                </Col>
                              </Row>
                            </div>
                            {n === 0 && (
                              <img
                                role="presentation"
                                onClick={this.addConsignee}
                                className="profile-category-sign"
                                src="/img/add.svg"
                                alt=""
                              />
                            )}
                            {n > 0 && (
                              <img
                                role="presentation"
                                onClick={() => this.removeConsignee(n)}
                                className="profile-category-sign"
                                src="/img/remove.svg"
                                alt=""
                              />
                            )}
                          </div>
                        )
                      )}
                  </FormGroup>
                )}
            </Form>
            {this.asterisk()}
            {` ${t('Required fields')}`}
          </Modal.Body>
          <Modal.Footer>
            <Button
              id="cancel"
              className="pull-left"
              onClick={() => {
                gaSend({
                  category: 'Common',
                  action: 'customer_add_cancel',
                  label: user.get('email')
                });
                hideModalDialog();
              }}>
              {t('Cancel')}
            </Button>
            <Button
              id="saveCharacteristicButton"
              bsStyle="primary"
              disabled={
                this.isValidNameField() ||
                !ModalAddCustomer.isEmailValid(this.getProperty('email')) ||
                newCustomer.get('email') === company.get('email')
              }
              onClick={() => {
                const consignees = this.state.consignees[0].consignee
                  ? this.state.consignees
                  : false;
                return addCustomer(
                  newCustomer.toJS(),
                  null,
                  consignees,
                  this.state.language
                ).then(() => {
                  getAllInvitations();
                  socket.emit(
                    'updateCompanyBadges',
                    JSON.stringify({ email: newCustomer.get('email') })
                  );
                  hideModalDialog();
                });
              }}>
              {t('invite_customer')}
            </Button>
          </Modal.Footer>
        </Modal>
      </ModalContainer>
    );
  }
}

ModalAddCustomer.propTypes = {
  t: PropTypes.func.isRequired
};

ModalAddCustomer.childContextTypes = {
  b: PropTypes.func
};

ModalAddCustomer.contextTypes = {
  badgeSocket: PropTypes.object
};

export default connect(
  (state) => ({
    newCustomer: state.getIn(['customers', 'newCustomer']),
    company: state.get('company'),
    user: state.getIn(['user', 'user'])
  }),
  (dispatch) =>
    bindActionCreators(
      {
        gaPageView: servicesAction.gaPageView,
        gaSend: servicesAction.gaSend,
        getAllInvitations: customersAction.getAllInvitations,
        getACompanyName: companyAction.getACompanyName
      },
      dispatch
    )
)(translate(['ui'], { wait: true })(ModalAddCustomer));
