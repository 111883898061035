export const getEmployeeId = (state) =>
  state.getIn(['contacts', 'contactInfo', 'employeeId']);
export const isBlockedContact = (state) =>
  state.getIn(['contacts', 'contactInfo', 'isBlockedContact']);

export const getName = (state) => {
  const lastName = state.getIn(['contacts', 'contactInfo', 'lastName']);
  const firstName = state.getIn(['contacts', 'contactInfo', 'firstName']);

  return `${lastName} ${firstName}`;
};
