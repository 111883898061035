import React from 'react';
import block from 'bem-cn-lite';
import Section from '../../common/components/section';
import { getNestActiveIndex, isActiveMainSection } from '../../utils';
import NestedList from './nestedList';

const b = block('snm-short-tooltip');

const TooltipList = ({ section, index = -1, activeIndex }) => (
  <div className="snm-short-backdrop">
    <div className={b()}>
      <ul className={b('list')}>
        <li>
          <Section
            section={section}
            isMain
            isActive={isActiveMainSection(activeIndex, index)}
            isShowBadge={false}
          />
        </li>
        <NestedList
          listOfSection={section.nestedList}
          activeIndex={getNestActiveIndex(activeIndex, index)}
          isShowBadge={false}
        />
      </ul>
    </div>
  </div>
);

export default TooltipList;
