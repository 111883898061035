import React, { Component } from 'react';
import { FormGroup, ControlLabel } from 'react-bootstrap';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { translate } from 'react-i18next';
import { reduxForm, Field, SubmissionError } from 'redux-form/immutable';
import resetPasswordValidator from '../utils/resetPasswordValidator';
import SecurityBoxLogo from '../components/MessengerAuth/SecurityBoxLogo';
import SecurityBoxInput from '../components/MessengerAuth/SecurityBoxInput';
import ResetPasswordSecurityBoxControls from '../components/MessengerAuth/ResetPasswordSecurityBoxControls';
import SecurityBoxCommonError from '../components/MessengerAuth/SecurityBoxCommonError';

import * as authActions from '../action-creators/auth';
import * as user from '../action-creators/user';
import * as serviceActions from '../action-creators/services';

import '../components/MessengerAuth/style.styl';

class MessengerResetPass extends Component {
  constructor(props) {
    super(props);
    this.state = {
      recovery: false
    };
    this.onSubmit = this.onSubmit.bind(this);
  }

  UNSAFE_componentWillMount() {
    this.props.getCurrentUser();
  }

  onSubmit(val) {
    const { t, resetPassword, gaSend } = this.props;

    const email = val.get('email');
    // Google Analytics
    gaSend({ category: 'Common', action: 'change_password_reg', label: email });

    return resetPassword(email)
      .then((res) => {
        if (res.ok) {
          // alert(t('Message with a link to reset your password has been sent to your email address please check the mail'));
          this.setState({ recovery: true });
        }
        return res.json();
      })
      .then((data) => {
        if (data && data.error) {
          throw new SubmissionError({ _error: t(data.error) });
        }
      });
  }

  render() {
    const { t, error, handleSubmit, theme = 'desktop' } = this.props;

    return (
      <div className={`basic-container basic-container_theme_${theme}`}>
        <SecurityBoxLogo />
        <div className="small-form">
          {!this.state.recovery ? (
            <div>
              <ControlLabel
                className={`recovery-text recovery-text_theme_${theme}`}>
                {t('Password recovery')}
              </ControlLabel>
              <FormGroup
                className={`form-group-login form-group-login_theme_${theme}`}
                style={{ marginTop: 22 }}>
                <ControlLabel className="control-label-login">
                  {t('E-mail')}
                </ControlLabel>
                <Field
                  name="email"
                  component={SecurityBoxInput}
                  type="text"
                  placeholder={t('Enter email')}
                />
              </FormGroup>
              <ResetPasswordSecurityBoxControls
                linkText={t('Enter')}
                submitText={t('Change password')}
                submitAction={handleSubmit(this.onSubmit)}
                theme={theme}
              />

              <div style={{ marginTop: 20 }}>
                {error && <SecurityBoxCommonError error={t(error)} />}
              </div>
            </div>
          ) : (
            <div style={{ paddingTop: 64 }}>
              <p
                style={{ marginLeft: 183, fontSize: '18px', paddingBottom: 5 }}>
                <img src="/img/ico_ok.svg" alt="" />
              </p>
              <p className="reset-text">{t('recovery_text')}</p>
              <p className="link-main-wrap">
                <Link className="link-main" to="/login">
                  {t('to_main')}
                </Link>
              </p>
            </div>
          )}
        </div>
      </div>
    );
  }
}

const MessengerResetPassForm = reduxForm({
  form: 'resetPassword',
  validate: resetPasswordValidator
})(MessengerResetPass);

export default connect(() => ({}), {
  ...authActions,
  ...serviceActions,
  getCurrentUser: user.getCurrentUser
})(translate()(MessengerResetPassForm));
