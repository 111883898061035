import React, { Component } from 'react';
import PropTypes from 'prop-types';
import block from 'bem-cn-lite';
import { translate } from 'react-i18next';
import { Modal, Form, FormControl, Button } from 'react-bootstrap';
import './styles/modal_theme_default.styl';
import './styles/modal_type_add-products-to-stock.styl';

import { ModalContainer } from './elements';

const b = block('modal-container');

class ModalAddProductsToStock extends Component {
  constructor(props) {
    super(props);
    this.state = {
      count: props.product['ProductsInStocks.inStock'] || 0
    };
    this.setCount = this.setCount.bind(this);
  }

  getChildContext() {
    return { b };
  }

  setCount(e) {
    e.target.value = Number(e.target.value); // eslint-disable-line
    this.setState({ count: e.target.value });
  }

  handleClick() {
    const {
      hideModalDialog,
      updateSearchProductOfStock,
      submit,
      stockId,
      product: { id },
      query,
      limit,
      offset
    } = this.props;
    const categoryId = this.props.product['Category.id'];

    submit({
      stockId,
      productId: id,
      categoryId,
      inStock: +this.state.count
    }).then(() => {
      updateSearchProductOfStock({ stockId, query, limit, offset });
      hideModalDialog();
    });
  }

  render() {
    const {
      t,
      captionKey,
      hideModalDialog,
      product: { sku, name }
    } = this.props;

    return (
      <Modal id="addProductsToStock" onHide={() => hideModalDialog()} show>
        <ModalContainer
          mods={{ type: 'add-products-to-stock', theme: 'default' }}>
          <Modal.Header>
            <Modal.Title>
              {t(captionKey)}
              <span
                className="modal-header-cross pull-right"
                onClick={() => hideModalDialog()}
              />
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <div className={b('row')}>
                <div className={b('title')}>{t('SKU')}</div>
                <div className={b('value')}>{sku}</div>
              </div>
              <div className={b('row')}>
                <div className={b('title')}>{t('Name')}</div>
                <div className={b('value')}>{name}</div>
              </div>
              <div className={b('row', { 'with-input': true })}>
                <div className={b('title')}>{t('Count')}</div>
                <div className={b('input', { count: true })}>
                  <FormControl
                    type="number"
                    value={this.state.count}
                    onKeyDown={(e) => e.keyCode === 13 && e.preventDefault()}
                    onChange={this.setCount}
                    min="0"
                  />
                </div>
              </div>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button
              id="cancel"
              className="pull-left"
              onClick={() => hideModalDialog()}>
              {t('Cancel')}
            </Button>
            <Button
              id="saveCharacteristicButton"
              bsStyle="primary"
              disabled={parseInt(this.state.count) === 0 || !this.state.count} // eslint-disable-line
              onClick={this.handleClick}>
              {t('Add products')}
            </Button>
          </Modal.Footer>
        </ModalContainer>
      </Modal>
    );
  }
}

ModalAddProductsToStock.propTypes = {
  t: PropTypes.func.isRequired
};

ModalAddProductsToStock.childContextTypes = {
  b: PropTypes.func
};

export default translate(['ui'], { wait: true })(ModalAddProductsToStock);
