import { isNil, isEmpty } from 'ramda';
import { nanoid } from 'nanoid';
import { getList } from '../block-list-forward';
import { makeMsgForward } from '../entities/forwardMsg';
import { logUseCase } from '../../../../../utils/logger';

const sendLog = logUseCase.extend('sendForwardMessages');
export const sendCase = (
  {
    getOpenedChatId,
    getChatForward,
    getInputFiles,
    createUnsentForwardMessages,
    setForwardMessages,
    sendMessage
  },
  { mentions, deliveryId = nanoid(), isCreateUnsent = true }
) => {
  const chatId = getOpenedChatId();
  const files = getInputFiles();

  const forwardedMessages = getForwardedMessages();
  sendLog(`is forwardMessages nil=${isNil(forwardedMessages)}`);
  if (isNil(forwardedMessages)) return null;

  const unsentForwardedMessages = generateUnsentMessages();

  clearForwardMessagesBlock();

  sendLog(
    `is unsent forwardMessages empty=${isEmpty(unsentForwardedMessages)}`
  );
  if (!isEmpty(unsentForwardedMessages)) {
    sendUnsentMessages();
  } else {
    sendMessages();
  }

  return undefined;

  function clearForwardMessagesBlock() {
    setForwardMessages({ toChatId: chatId });
  }

  function getForwardedMessages() {
    if (isListEmpty()) {
      sendLog(`is list of forward block is empty`);
      return null;
    }

    const forwardBlock = getForwardBlock();
    const { list, fromChatId: fwrdChatId } = forwardBlock;
    const msgsIds = list.map((i) => i.id);

    return { msgsIds, chatId: fwrdChatId };
  }

  function isListEmpty() {
    const forwardBlock = getForwardBlock();

    return isNil(forwardBlock) || isEmpty(forwardBlock.list);
  }

  function getForwardBlock() {
    const forwardList = getChatForward();
    return getList(forwardList, chatId);
  }

  function generateUnsentMessages() {
    if (!isCreateUnsent) return [];

    return createUnsentForwardMessages();
  }

  function sendUnsentMessages() {
    unsentForwardedMessages.forEach((message) =>
      sendMessage(createMsgForward(message.originId, message.deliveryId))
    );
  }

  function sendMessages() {
    const total = forwardedMessages.msgsIds.length;
    forwardedMessages.msgsIds.forEach((id, idx) =>
      sendMessage(createMsgForward(id, deliveryId), total - idx)
    );
  }

  function createMsgForward(id, _deliveryId) {
    return makeMsgForward({
      chatId,
      mentions,
      files,
      forwardedMessages: { msgsIds: [id], chatId: forwardedMessages.chatId },
      deliveryId: _deliveryId
    });
  }
};
