import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Map } from 'immutable';
import { translate } from 'react-i18next';
import { map, isEmpty } from '../../work-with-data/standard';

import ModalGrantAccess from '../../components/Modal/ModalGrantAccess';
import * as modalAction from '../../action-creators/modal';
import * as customersActions from '../../action-creators/customers';
import * as pricesActions from '../../action-creators/prices';
import * as servicesActions from '../../action-creators/services';

class ContModalGrantAccess extends Component {
  UNSAFE_componentWillMount() {
    this.props.getGroups();
  }

  render() {
    const {
      t,
      priceId,
      captionKey,
      customers,
      groups,
      hideModalDialog,
      setAccessToPrice,
      getAccessList,
      gaPageView,
      getAllPrices,
      gaSend,
      company,
      user
    } = this.props;

    const extGroups = groups.unshift(
      new Map({ id: 0, name: t('All customers') })
    );

    return (
      <ModalGrantAccess
        title={captionKey}
        hideModalDialog={hideModalDialog}
        listOfCategoryBuyers={extGroups}
        isEmptyCategory={false && isEmpty(customers)}
        map={map}
        priceId={priceId}
        defaultCategory={extGroups.first().get('id')}
        getCategoryValue={(group) => group.get('id')}
        getCategoryName={(group) => group.get('name')}
        submit={(groupId) =>
          setAccessToPrice(priceId, groupId).then(() => getAccessList(priceId))
        }
        getAllPrices={getAllPrices}
        gaPageView={gaPageView}
        gaSend={gaSend}
        company={company}
        user={user}
      />
    );
  }
}

export default connect(
  (state) => ({
    groups: state.getIn(['customers', 'groups']),
    user: state.getIn(['user', 'user']),
    company: state.get('company')
  }),
  (dispatch) =>
    bindActionCreators(
      {
        hideModalDialog: modalAction.hideModalDialog,
        getAccessList: pricesActions.getAccessList,
        getGroups: customersActions.getGroups,
        setAccessToPrice: pricesActions.setAccessToPrice,
        gaPageView: servicesActions.gaPageView,
        gaSend: servicesActions.gaSend,
        getAllPrices: pricesActions.getAllPrices
      },
      dispatch
    )
)(translate(['ui'], { wait: true })(ContModalGrantAccess));
