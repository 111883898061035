.container {
  max-width: 530px;
  min-width: 530px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
  background: #fff;
  padding: 32px;
  box-shadow: 0 6px 10px rgb(118 123 146 / 20%);
  border-radius: 8px;
  position: relative;
  overflow: hidden;
  height: auto;
}

.closeBtn {
  position: absolute;
  right: 0;
  top: 0;
}

.header {
  padding: 6px 0;

  h2 {
    color: var(--color-gray-900);
  }
}

.footer {
  display: flex;
  align-items: center;
  column-gap: 16px;
  row-gap: 16px;
  flex-wrap: wrap;
}

.content {
  width: 100%;
  max-height: 478px;
  overflow-y: auto;
  overflow-x: hidden;
  color: var(--color-gray-900);
}

.content::-webkit-scrollbar {
  width: 8px;
  background-color: var(--color-gray-200);
}

.content::-webkit-scrollbar-thumb {
  background: rgb(12 35 98 / 20%);
  border-radius: 13px;
}

.actions {
  display: flex;
  align-items: center;
  gap: 16px;
}

.right {
  justify-content: right;
}

.center {
  justify-content: center;
}

.left {
  justify-content: left;
}

.space-between {
  justify-content: space-between;
}
