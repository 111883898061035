import req from '../utils/request';
import customHistory from '../customHistory';

const setEmployees = (users) => ({
  type: 'ACCESS:SET_EMPLOYEES_TO_ACCESS:SUCCESS',
  payload: { users }
});

const setAccessType = (accessType) => ({
  type: 'ACCESS:SET_TYPE:SUCCESS',
  payload: { accessType }
});

export const cleanAccess = () => ({ type: 'ACCESS:CLEAN' });

export const getEmployees =
  ({ resourceType, id }) =>
  (dispatch) =>
    req
      .get(`/api/access/resources/${resourceType}/${id}/employees`)
      .then(({ accessType, users }) => {
        dispatch(setEmployees(users));
        dispatch(setAccessType(accessType));
      });

export const updateAccess =
  ({ resourceType, id }, { accessType, usersId }) =>
  () =>
    req.patch(`/api/access/resources/${resourceType}/${id}`, {
      accessType,
      usersId
    });

const checkAccess = (resourceType, id) => () =>
  req
    .get(`/api/access/resources/${resourceType}/${id}`)
    .catch(() => customHistory.replace('/not-permission'));

export const checkAccessCatalog = (id) => checkAccess('catalogs', id);
export const checkAccessStock = (id) => checkAccess('stocks', id);
export const checkAccessPrice = (id) => checkAccess('prices', id);
