import React, { useCallback, useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { isNil } from 'ramda';
import { Button } from 'react-bootstrap';
import { getCurrentResponse } from '../getters';
import * as modalsActions from '../../../../action-creators/modal';
import * as storage from '../storage';
import { actions as responseActions } from '../reducer';
import Status from '../../../Status';
import Loader from '../../../Loader';
import Tooltip from '../../../ChatWidget/Tooltip';
import RenderHTML from '../../Request/RenderHTML';
import SectionOffset from '../../Request/SectionOffset';
import FilesBlock from '../../Request/FilesBlock';
import LinksListBlock from '../../Request/LinksListBlock';
import { colorList } from '../../Request/respondColor';
import {
  Body,
  Footer,
  GoBackWrapper,
  Header,
  IconsBlock,
  TextTitle,
  Text,
  Row,
  ColHalf,
  Divider,
  StatusBlock,
  SupplierBlock,
  Title,
  Wrapper,
  Content
} from './styles';
import {
  getAcceptButtonText,
  getHeaderText,
  getRejectButtonText,
  getRequestId,
  getRequestText,
  showLinksBlock,
  getRespondLinks,
  getRespondTotalLinks,
  showFilesBlock,
  getRespondFiles,
  getRespondTotalFiles,
  getRespondedUserName,
  getResponseCreatedDate,
  getStatus,
  getSupplierCompanyName,
  getTitle,
  isRejectButtonDisable,
  isShowConfirmButton,
  isConfirmButtonDisable,
  isShowConfirmTooltip,
  isShowLoader,
  isShowRejectButton,
  getRequestProducts,
  isResponseFromCompany,
  isCurrentCompanyBuyer
} from './viewModel';
import {
  getStatusByBuyerForColor,
  getStatusBySupplierForColor,
  getTransStatusByBuyer,
  getTransStatusBySupplier,
  isDisableChatWithSupplier,
  rework
} from '../../../../presenters/sidebars/PurchaseRequestResponsesSidebar/status';
import GoBack from '../../../Buttons/GoBack';
import ProductsTable from '../../common/ProductsTable';
import { useTranslate } from '../../../../TranslateProvider';
import * as storeGetters from '../../../../storeGetters';
import { haveUpdatePermission } from '../../utils';
import OpenResponseChatButton from '../../../OpenResponseChat';
import { ResponseExtraOptions } from '../../Request/components/ResponseExtraOptions';

const ResponsePurchaseRequest = ({
  responseId,
  response,
  currentCompanyId,
  getResponse,
  clearResponse,
  showModal,
  acceptResponse,
  user,
  permissions
}) => {
  const t = useTranslate();

  useEffect(() => {
    getResponse(responseId);

    return () => clearResponse();
  }, []);

  const extraOptions = useMemo(
    () => ({
      delivery: response.deliveryCost,
      deadline: response.deliveryDate ? new Date(response.deliveryDate) : null,
      payment: response.prepayment
    }),
    [response]
  );

  const decline = useCallback(() => {
    if (isNil(response)) return;

    showModal('DECLINE_PURCHASE_RESPONSE', {
      requestId: getRequestId(response),
      responseId
    });
  }, [response]);

  const sendToRework = () => {
    showModal('SEND_TO_REWORK_PURCHASE_RESPONSE', {
      companyName: getSupplierCompanyName(response),
      requestId: getRequestId(response),
      responseId
    });
  };

  const accept = useCallback(() => {
    if (isNil(response)) return;

    showModal('SIMPLE_SUBMIT', {
      title: t('accept_to_respond'),
      text: t('request_response_accept_modal_text'),
      textBtnConfirm: t('Confirm'),
      submitAction: async () => {
        await acceptResponse({ requestId: getRequestId(response), responseId });
      }
    });
  }, [response]);

  const isChatHided = () =>
    isDisableChatWithSupplier(getStatus(response)) ||
    response.responsibleUser !== user.get('id');

  if (isShowLoader(response)) return <Loader theme="page" />;

  const status = isCurrentCompanyBuyer(response, currentCompanyId)
    ? getStatusByBuyerForColor(getStatus(response))
    : getStatusBySupplierForColor(getStatus(response));

  const statusText = isCurrentCompanyBuyer(response, currentCompanyId)
    ? t(getTransStatusByBuyer(getStatus(response)))
    : t(getTransStatusBySupplier(getStatus(response)));

  return (
    <Wrapper>
      <GoBackWrapper>
        <GoBack />
      </GoBackWrapper>
      <Content>
        <Header>
          <Title>{getHeaderText(t, response)}</Title>
          <StatusBlock>
            <Status
              status={status}
              text={statusText}
              statusColors={colorList}
            />
          </StatusBlock>
        </Header>
        <Divider />
        <Body>
          <Row>
            <ColHalf>
              <TextTitle>{t('purchase_request_title')}</TextTitle>
            </ColHalf>
            <ColHalf>
              <Text>{getTitle(response)}</Text>
            </ColHalf>
          </Row>
          <Row>
            <ColHalf>
              <TextTitle>{t('response_date')}</TextTitle>
            </ColHalf>
            <ColHalf>
              <Text>{getResponseCreatedDate(response)}</Text>
            </ColHalf>
          </Row>
          <Divider />
          <Row>
            <ColHalf>
              <TextTitle>{t('Supplier')}</TextTitle>
            </ColHalf>
            <ColHalf>
              <Text>{getSupplierCompanyName(response)}</Text>
            </ColHalf>
          </Row>
          <Row>
            <ColHalf>
              <TextTitle>{t('supplier_employee')}</TextTitle>
            </ColHalf>
            <ColHalf>
              <SupplierBlock>
                <Text>{getRespondedUserName(response)}</Text>
              </SupplierBlock>
              {!isChatHided() && (
                <IconsBlock>
                  <OpenResponseChatButton
                    requestId={getRequestId(response)}
                    response={response}
                    isWidget
                    tooltip={
                      isCurrentCompanyBuyer(response, currentCompanyId)
                        ? 'chat_with_supplier'
                        : 'chat_with_buyer'
                    }
                    disabled={isDisableChatWithSupplier(getStatus(response))}
                  />
                </IconsBlock>
              )}
            </ColHalf>
          </Row>
          <Divider />

          {getStatus(response) === rework ? (
            <>
              <SectionOffset offset={30} />
              {t('content_hidden_because_of_rework')}
              <SectionOffset offset={30} />
            </>
          ) : (
            <>
              <Row>
                <ColHalf>
                  <TextTitle>{t('content')}</TextTitle>
                </ColHalf>
              </Row>
              {getRequestProducts(response)?.length > 0 && (
                <Row>
                  <ProductsTable
                    products={getRequestProducts(response)}
                    editPriceAndCount={false}
                    editMode={false}
                  />
                </Row>
              )}
              {Boolean(getRequestText(response)) && (
                <Row>
                  <Text>
                    <RenderHTML html={getRequestText(response)} />
                  </Text>
                </Row>
              )}
              <ResponseExtraOptions
                mode="view"
                extraOptions={extraOptions}
                marginOff
              />
              <Divider />
              {showFilesBlock(response) && (
                <>
                  <SectionOffset show={showFilesBlock(response)} offset={50} />
                  <FilesBlock
                    show={showFilesBlock(response)}
                    total={getRespondTotalFiles(response)}
                    files={getRespondFiles(response, showModal)}
                  />
                </>
              )}
              {showLinksBlock(response) && (
                <>
                  <SectionOffset show={showLinksBlock(response)} offset={50} />
                  <LinksListBlock
                    show={showLinksBlock(response)}
                    total={getRespondTotalLinks(response)}
                    links={getRespondLinks(response)}
                  />
                </>
              )}
            </>
          )}
        </Body>
        <Footer>
          {!isResponseFromCompany(response, currentCompanyId) &&
            isShowRejectButton(response) &&
            haveUpdatePermission(permissions) && (
              <>
                <Button
                  className="btn-cancel"
                  onClick={decline}
                  disabled={isRejectButtonDisable(response)}>
                  {getRejectButtonText(t, response)}
                </Button>
                <Button
                  bsStyle="default"
                  onClick={sendToRework}
                  disabled={isRejectButtonDisable(response)}>
                  {t('send_to_rework')}
                </Button>
              </>
            )}
          {!isResponseFromCompany(response, currentCompanyId) &&
            isShowConfirmButton(response) &&
            haveUpdatePermission(permissions) && (
              <Button
                onClick={accept}
                bsStyle="primary"
                disabled={isConfirmButtonDisable(response)}>
                <Tooltip
                  isShowTooltip={isShowConfirmTooltip(response)}
                  text="accept_offer"
                  triggerOffset={15}>
                  {getAcceptButtonText(t, response)}
                </Tooltip>
              </Button>
            )}
        </Footer>
      </Content>
    </Wrapper>
  );
};

export default connect(
  (state) => ({
    response: getCurrentResponse(state),
    user: state.getIn(['user', 'user']),
    permissions: storeGetters.getPurchaseRequestsPermission(state),
    currentCompanyId: storeGetters.getCompanyId(state)
  }),
  (dispatch) =>
    bindActionCreators(
      {
        getResponse: storage.getRequestResponse,
        clearResponse: responseActions.purgeResponse,
        showModal: modalsActions.showModal,
        acceptResponse: storage.acceptRespond,
        cancelResponse: storage.cancelRespond
      },
      dispatch
    )
)(ResponsePurchaseRequest);
