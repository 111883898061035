import { getBillingEditPermission } from '../../../../../storeGetters';
import { applyState } from '../../utils';
import { setErrorMessage } from '../../../../../action-creators/message';
import { showModal } from '../../../../../action-creators/modal';
import { showSpaceLimitNotificationCase } from './showSpaceLimitNotification';

export const showSpaceLimitNotification = () => (dispatch, getState) =>
  showSpaceLimitNotificationCase({
    getBillingEditPermission: applyState(getState, getBillingEditPermission),
    setMessage: (prop) => dispatch(setErrorMessage(prop)),
    showModal: (name, props) => dispatch(showModal(name, props))
  });
