import React, { Component } from 'react';
import { translate } from 'react-i18next';
import { withRouter } from '../../../../hoc/withRouter';
import MessengerOther from '../../../../components/Layouts/MessengerOther';
import { MessengerWidgetWrapper } from '../../../../components/Widgets/Messenger/MessengerWidget';
import { formatRequestNumber } from '../../../../utils/utils';
import Content from './Content';

class MPurchaseResponse extends Component {
  get breadcrumbs() {
    const { t, history } = this.props;
    return [
      {
        name: t('Purchase requests'),
        element: (
          <a onClick={() => history.push('/requests/my-responses')}>
            {t('Purchase requests')}
          </a>
        )
      },
      {
        name: `${t('response_to_request')} №${formatRequestNumber(
          this.requestId
        )}`
      }
    ];
  }

  get responseId() {
    const { responseId } = this.props.match.params;

    return +responseId;
  }

  get requestId() {
    const { requestId } = this.props.match.params;

    return +requestId;
  }

  render() {
    const { t } = this.props;

    return (
      <>
        <MessengerOther
          title={t('Purchase requests')}
          breadcrumbs={this.breadcrumbs}
          content={
            <Content requestId={this.requestId} responseId={this.responseId} />
          }
        />
        <MessengerWidgetWrapper />
      </>
    );
  }
}

export default withRouter(translate(['ui'], { wait: true })(MPurchaseResponse));
