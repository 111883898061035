import React, { Component } from 'react';
import PropTypes from 'prop-types';
import block from 'bem-cn-lite';
import './panel_theme_default.styl';

import { Panel } from '../elements';

const b = block('panel-top');

class PanelThemeDefault extends Component {
  getChildContext() {
    return { b };
  }

  render() {
    const { left, right, theme = 'default' } = this.props;
    return (
      <Panel mods={{ theme }}>
        {left || <span />}
        {right || <span />}
      </Panel>
    );
  }
}

PanelThemeDefault.propTypes = {
  left: PropTypes.element,
  right: PropTypes.any
};

PanelThemeDefault.defaultProps = {
  left: null,
  right: null
};

PanelThemeDefault.childContextTypes = {
  b: PropTypes.func
};

export default PanelThemeDefault;
