import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import block from 'bem-cn-lite';
import { fromJS } from 'immutable';
import { Popover, OverlayTrigger } from 'react-bootstrap';
import { translate } from 'react-i18next';

import { getPermissionsByCurrentSection } from '../../utils';
import YourOrder from '../YourOrder';

import './style.styl';

const b = block('breadcrumbs-buttons');

const getPopover = (popoverText, t) => (
  <Popover style={{ transitionDelay: '.4s' }} id="popover-positioned-left">
    {t(popoverText)}
  </Popover>
);

class BreadcrumbsButtons extends Component {
  shouldComponentUpdate(nextProps) {
    if (nextProps.permissions === undefined) return false;
    return (
      !nextProps.permissions.equals(this.props.permissions) ||
      !fromJS(nextProps.items).equals(fromJS(this.props.items))
    );
  }

  get items() {
    const { permissions, items } = this.props;

    if (!permissions || permissions.isEmpty()) return items;

    const permissionName = getPermissionsByCurrentSection();
    const currentPermissions = permissions.get(permissionName);
    if (currentPermissions === undefined) {
      return items;
    }
    return items.filter((btn) => {
      if (btn.permissionGroup === undefined) return true;
      return currentPermissions.get(btn.permissionGroup);
    });
  }

  render() {
    const { t, theme } = this.props;
    return (
      <ul name={name} className={b({ theme })}>
        {this.items.map(
          (
            {
              type,
              args,
              link,
              element,
              dropdown = false,
              popover = false,
              popoverText,
              name,
              disabled = false,
              onClick,
              download = false,
              blank = false,
              className,
              hidden = false,
              popoverPlacement,
              showOnActive = true
            },
            i
          ) => (
            <li
              key={i}
              className={b(
                dropdown ? 'common' : 'item',
                disabled || (popover && !showOnActive)
                  ? { disabled: true }
                  : { active: !type }
              )}
              style={{ display: hidden ? 'none' : '' }}>
              {disabled && !popover && (
                <span className={className}>{t(name)}</span>
              )}
              {disabled && popover && (
                <OverlayTrigger
                  trigger={['hover']}
                  placement={popoverPlacement || 'left'}
                  overlay={getPopover(popoverText, t)}>
                  <span
                    onClick={!disabled ? onClick : () => {}}
                    className={className}>
                    {t(name)}
                  </span>
                </OverlayTrigger>
              )}
              {!disabled && link && !popover && download && (
                <a className={className} href={link} download="">
                  {t(name)}
                </a>
              )}
              {!disabled && link && !popover && !download && (
                <Link
                  className={className}
                  to={link}
                  target={blank ? '_blank' : ''}>
                  {t(name)}
                </Link>
              )}
              {!disabled && !link && !element && !popover && (
                <span className={className} onClick={onClick}>
                  {t(name)}
                </span>
              )}
              {!disabled && !element && popover && (
                <OverlayTrigger
                  trigger={['hover']}
                  placement={popoverPlacement || 'left'}
                  overlay={getPopover(popoverText, t)}>
                  {/* eslint-disable-next-line no-nested-ternary */}
                  {link ? (
                    download ? (
                      <a className={className} href={link}>
                        {t(name)}
                      </a>
                    ) : (
                      <Link
                        className={className}
                        to={link}
                        target={blank ? '_blank' : ''}>
                        {t(name)}
                      </Link>
                    )
                  ) : (
                    <span
                      onClick={!disabled ? onClick : () => {}}
                      className={className}>
                      {t(name)}
                    </span>
                  )}
                </OverlayTrigger>
              )}
              {!disabled && element}
              {type === 'yourOrder' && <YourOrder args={args} />}
            </li>
          )
        )}
      </ul>
    );
  }
}

BreadcrumbsButtons.propTypes = {
  items: PropTypes.array.isRequired
};

export default connect((state) => ({
  permissions: state.getIn(['user', 'user', 'permissions'])
}))(translate(['ui'], { wait: true })(BreadcrumbsButtons));
