import React, { Component } from 'react';
import PropTypes from 'prop-types';
import block from 'bem-cn-lite';
import { translate } from 'react-i18next';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Modal } from 'react-bootstrap';
import { List } from 'immutable';
import { withRouter } from '../../hoc/withRouter';

import './styles/modal_theme_default.styl';

import { ModalContainer } from './elements';
import SearchBlockDefault from '../SearchBlock/SearchBlockDefault';
import Pagination from '../Pagination';

import * as modalActions from '../../action-creators/modal';
import * as contactActions from '../../action-creators/contacts';
import * as dialogsActions from '../../action-creators/dialogs';
import * as channelUC from '../../useCases/channel';
import * as dialogApi from '../../modules/dialog/storage';

const b = block('modal-container');

class ModalContactForwardMessage extends Component {
  getChildContext() {
    return { b };
  }

  UNSAFE_componentWillMount() {
    const { loadContacts, loadContactsLists } = this.props;
    loadContacts();
    loadContactsLists();
  }

  async forwardMessage(contact) {
    // const companyId = contact.get('companyId');
    // const userId = contact.get('userId');
    const {
      history,
      location,
      hideModalDialog,
      fromType,
      getChannelMessagesForForward,
      channelId,
      selectedMsgs,
      clearSelectedMsgs = () => {},
      reloadData = () => {}
    } = this.props;
    if (['topic', 'channel', 'dialog'].includes(fromType)) {
      const messages = await getChannelMessagesForForward(
        channelId,
        selectedMsgs
      );
      clearSelectedMsgs();
      const dialogId = await dialogApi.getIdByEmployeeId(
        contact.get('employeeId')
      );
      history.replace({
        pathname: `/dialogs/${dialogId}`,
        state: { ...location.state, forwarded: true, messages }
      });
      reloadData(dialogId, channelId);
      hideModalDialog();
    }
  }

  onChangePage = async (offset, limit) => {
    const { loadContacts } = this.props;
    await loadContacts({ offset, limit });
  };

  onSearch = async (search) => {
    const { setSearch, loadContacts, limit, checked } = this.props;
    const contactLists = checked.toJS();
    setSearch(search);
    await loadContacts({ search, limit, contactLists });
  };

  render() {
    const {
      t,
      hideModalDialog,
      contacts = List(),
      isEmpty,
      size,
      limit,
      offset
    } = this.props;

    return (
      <Modal id="messageForward" show>
        <ModalContainer mods={{ type: 'default', theme: 'default' }}>
          <Modal.Header>
            <Modal.Title>
              {t('message_forward_contact')}
              <span
                className="modal-header-cross pull-right"
                onClick={() => hideModalDialog()}
              />
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {!isEmpty && (
              <SearchBlockDefault
                placeholder={t('find_contacts')}
                onSubmit={this.onSearch}
                onEmpty={this.onSearch}
                width={400}
              />
            )}
            {!isEmpty && size === 0 && (
              <div style={{ marginTop: 35, fontSize: 14, width: '100%' }}>
                {t('contactsNotFound')}
              </div>
            )}
            {!isEmpty && size !== 0 && (
              <div style={{ height: 500, overflowY: 'auto', marginTop: 10 }}>
                {contacts.map((contact) => (
                  <div
                    className="contact-row-container"
                    style={{ cursor: 'pointer', width: 508, height: 52 }}
                    onClick={() => this.forwardMessage(contact)}>
                    <img
                      src={contact.get('avatar') || '/img/user.svg'}
                      alt="logo"
                      style={{ width: 38, height: 38 }}
                      className="contact-row-container__logo"
                    />
                    <div className="contact-row-container__titles">
                      <span className="contact-row-container__titles-name">
                        {contact.get('name') || '–'}
                      </span>
                      <span className="contact-row-container__titles-company">
                        {contact.get('company') || '–'}
                      </span>
                    </div>
                    <div style={{ width: 32 }} />
                  </div>
                ))}
              </div>
            )}
            <div style={{ marginTop: 35 }}>
              <Pagination
                totalSize={size}
                limit={limit}
                offset={offset}
                nextPage={this.onChangePage}
              />
            </div>
          </Modal.Body>
        </ModalContainer>
      </Modal>
    );
  }
}

ModalContactForwardMessage.propTypes = {
  t: PropTypes.func.isRequired,
  hideModalDialog: PropTypes.func.isRequired,
  loadContacts: PropTypes.func.isRequired,
  contacts: PropTypes.array.isRequired
};

ModalContactForwardMessage.childContextTypes = {
  b: PropTypes.func
};

export default withRouter(
  connect(
    (state) => ({
      contacts: state.getIn(['contacts', 'contacts']),
      checked: state.getIn(['contacts', 'checked']),
      size: state.getIn(['contacts', 'size']),
      limit: state.getIn(['contacts', 'limit']),
      offset: state.getIn(['contacts', 'offset']),
      isEmpty: state.getIn(['contacts', 'isEmpty'])
    }),
    (dispatch) =>
      bindActionCreators(
        {
          hideModalDialog: modalActions.hideModalDialog,
          loadContacts: contactActions.loadContacts,
          clearContacts: contactActions.clearContacts,
          setSearch: contactActions.setSearch,
          loadContactsLists: contactActions.loadContactsLists,
          clearContactsLists: contactActions.clearContactsLists,
          getChannelMessagesForForward: channelUC.getMessagesForForward,
          getDialogMessagesForForward: dialogsActions.getMessagesForForward
        },
        dispatch
      )
  )(translate(['ui'], { wait: true })(ModalContactForwardMessage))
);
