import React, { Component } from 'react';
import PropTypes from 'prop-types';
// import block from 'bem-cn-lite';
import { translate } from 'react-i18next';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Modal, Button, Row } from 'react-bootstrap';

import Checkbox from '../Checkbox';
import { ModalContainer } from './elements';

import './styles/modal_theme_default.styl';

import * as modalActions from '../../action-creators/modal';
import * as billingActions from '../../action-creators/billing';

// const b = block('modal-container');

class ModalChangeTariff extends Component {
  static tariffsWeight = { free: 0, base: 1, standart: 2, premium: 3 };

  constructor(props) {
    super(props);
    this.state = {
      period: ''
    };
  }

  UNSAFE_componentWillMount() {
    const { billing, tariff } = this.props;
    const { tariffsWeight } = ModalChangeTariff;
    const tariffDecrease =
      tariffsWeight[tariff] < tariffsWeight[billing.get('tariff')];
    const daysToEnd = +(
      (new Date(billing.get('endDate')) - new Date()) /
      (1000 * 60 * 60 * 24)
    ).toFixed();
    if (daysToEnd > 0 && tariffDecrease) {
      this.setState({ period: 'next' });
    }
    if (daysToEnd <= 0 || billing.get('tariff') === 'free') {
      this.setState({ period: 'current' });
    }
  }

  render() {
    const {
      t,
      hideModalDialog,
      tariff,
      additions,
      setTariff,
      getBlockingInfo,
      viewBlockingPage,
      billing,
      showPayment,
      sum
    } = this.props;

    const { tariffsWeight } = ModalChangeTariff;
    const tariffDecrease =
      tariffsWeight[tariff] < tariffsWeight[billing.get('tariff')];

    const daysToEnd = +(
      (new Date(billing.get('endDate')) - new Date()) /
      (1000 * 60 * 60 * 24)
    ).toFixed();

    const onlyCurrent = daysToEnd <= 0 || billing.get('tariff') === 'free';
    const onlyNext =
      daysToEnd > 0 && tariffDecrease && billing.get('tariff') !== 'free';

    let changeTariffText = t('change_tariff_text');
    if (onlyCurrent) {
      changeTariffText = t('change_tariff_current_text');
    }
    if (onlyNext) {
      changeTariffText = t('change_tariff_next_text');
    }

    const decreaseAdditions = additions.reduce((acc, i) => {
      if (i.count < billing.getIn(['additions', i.name])) {
        // eslint-disable-next-line no-param-reassign
        acc = true;
      }
      return acc;
    }, false);

    return (
      <Modal id="changeTariff" onHide={() => hideModalDialog()} show>
        <ModalContainer mods={{ theme: 'default' }}>
          <Modal.Header>
            <Modal.Title>
              {`${t('Change to tariff')} ${t(tariff)}`}
              <span
                className="modal-header-cross pull-right"
                onClick={() => hideModalDialog()}
              />
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div style={{ paddingLeft: 10, paddingRight: 10 }}>
              <Row style={{ marginBottom: 10 }}>{changeTariffText}</Row>
              {!onlyCurrent &&
                this.state.period === 'current' &&
                decreaseAdditions && (
                  <Row style={{ marginBottom: 10 }}>
                    {t('additions_decrease_text')}
                  </Row>
                )}
              {!onlyNext && !onlyCurrent && (
                <Row>
                  <Checkbox
                    set={this.state.period === 'current'}
                    onClick={() => this.setState({ period: 'current' })}
                  />
                  <span>{t('curr_per_option')}</span>
                </Row>
              )}
              {!onlyCurrent && !onlyNext && (
                <Row>
                  <Checkbox
                    set={this.state.period === 'next'}
                    onClick={() => this.setState({ period: 'next' })}
                  />
                  <span>{t('next_per_option_additons')}</span>
                </Row>
              )}
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              id="cancel"
              className="pull-left"
              onClick={() => {
                hideModalDialog();
              }}>
              {t('Cancel')}
            </Button>
            <Button
              id="copyProductButton"
              bsStyle="primary"
              disabled={!this.state.period}
              onClick={async () => {
                if (this.state.period === 'current') {
                  const blockingInfo = await getBlockingInfo(tariff, additions);
                  if (!blockingInfo.options.length && !blockingInfo.storage) {
                    if (sum === 0) {
                      setTariff(tariff, additions, this.state.period);
                      hideModalDialog();
                    } else {
                      showPayment(sum, ({ name, email }) =>
                        setTariff(tariff, additions, this.state.period, null, {
                          name,
                          email
                        })
                      );
                    }
                  } else {
                    viewBlockingPage();
                    hideModalDialog();
                  }
                } else {
                  setTariff(tariff, additions, this.state.period);
                  hideModalDialog();
                }
              }}>
              {t('Change')}
            </Button>
          </Modal.Footer>
        </ModalContainer>
      </Modal>
    );
  }
}

ModalChangeTariff.propTypes = {
  t: PropTypes.func.isRequired
};

ModalChangeTariff.childContextTypes = {
  b: PropTypes.func
};

export default connect(
  (state) => ({
    company: state.get('company'),
    billing: state.getIn(['company', 'billing'])
  }),
  (dispatch) =>
    bindActionCreators(
      {
        hideModalDialog: modalActions.hideModalDialog,
        setTariff: billingActions.setTariff,
        getBlockingInfo: billingActions.getBlockingInfo
      },
      dispatch
    )
)(translate(['ui'], { wait: true })(ModalChangeTariff));
