export const TASK = 'task';

export const STATUSES = {
  todo: 'todo',
  progress: 'progress',
  done: 'done'
};

export const STATUS_SECTIONS = [
  STATUSES.todo,
  STATUSES.progress,
  STATUSES.done
];

export const TASK_COMMANDS = {
  removeTask: 'remove-task'
};

export const EXTRA_TASK_OPTIONS = [
  { icon: 'delete', value: TASK_COMMANDS.removeTask }
];
