import React, { useCallback, useMemo } from 'react';
import { length } from 'ramda';
import cn from 'classnames';
import Text from 'react-components/src/components/Typography';
import iconView from 'react-components/src/icons/constants';
import { useActions } from '@hooks/useActions';
import { StepCard } from '../stepCard';
import { useTranslate } from '../../../../../TranslateProvider';
import styles from './Step.module.css';
import { STEP_TYPES } from '../constants';
import { ButtonConstructor } from '../buttons/buttonConstructor';
import {
  isCanAddAgreementCard,
  isCanAddIntroductionCard,
  isCardsExist,
  isParallelStep,
  isStepHasPublication
} from '../helpers';
import * as constructorActions from '../../../../../components/ChatWidget/modules/constructor/actions';
import { ParallelLine } from '../arrows';
import { ActionButton } from '../buttons/actionButton';
import { useIsCanBeDeleted } from '../../../../../contexts/RouteIsCanBeDeletedContext';

function CreateCardsInSameStepButtons({ step }) {
  const t = useTranslate();
  const { addCardToStep } = useActions(constructorActions);

  const onAddCardToStep = useCallback(
    (type) => {
      addCardToStep({
        stepType: type,
        stepId: step.id
      });
    },
    [step.id]
  );

  return (
    <div className={styles.stepButtons}>
      {isCanAddAgreementCard(step[STEP_TYPES.agreement]) && (
        <ButtonConstructor
          text={t('constructor.badge.agreement')}
          onClick={() => {
            onAddCardToStep(STEP_TYPES.agreement);
          }}
        />
      )}
      {isCanAddIntroductionCard(step[STEP_TYPES.introduction]) && (
        <ButtonConstructor
          text={t('constructor.badge.introduction')}
          onClick={
            () => onAddCardToStep(STEP_TYPES.introduction)
            // eslint-disable-next-line react/jsx-curly-newline
          }
        />
      )}
    </div>
  );
}

export const Step = ({ step, stepIndex }) => {
  const t = useTranslate();

  const isCanBeDeleted = useIsCanBeDeleted();
  const isParallel = useMemo(() => isParallelStep(step), [step]);

  const { deleteCardFromStep, deleteStep } = useActions(constructorActions);

  const onDeleteCardFromStep = useCallback(
    (type, cardId) => {
      deleteCardFromStep({
        stepId: step.id,
        stepType: type,
        cardId
      });
    },
    [step.id]
  );

  return (
    <div
      className={cn(styles.wrapper, {
        [styles.stepWidthBig]: isParallel
      })}>
      <fieldset
        id={step.id}
        className={cn(styles.fieldset, {
          [styles.fieldsetBorder]: isParallel
        })}>
        {isParallel && (
          <legend className={styles.text}>
            {t('constructor.step', { step: `${stepIndex + 1}` })}
          </legend>
        )}
        <div
          className={cn(styles.step, { [styles.noParallelStep]: !isParallel })}>
          {isCardsExist(step[STEP_TYPES.agreement]) && (
            <div className={cn(styles.agreementCards)}>
              {step[STEP_TYPES.agreement].map((card, cardIndex, cards) => (
                <React.Fragment key={`${card.id}-step-card`}>
                  <StepCard
                    type={STEP_TYPES.agreement}
                    deleteCardFromStep={() =>
                      onDeleteCardFromStep(STEP_TYPES.agreement, card.id)
                    }
                    stepId={step.id}
                    card={card}
                  />
                  {length(cards) - 1 !== cardIndex && (
                    <>
                      <ParallelLine
                        start={card.id}
                        end={cards[cardIndex + 1].id}
                      />
                    </>
                  )}
                </React.Fragment>
              ))}
            </div>
          )}

          {isCardsExist(step[STEP_TYPES.introduction]) && (
            <div>
              {step[STEP_TYPES.introduction].map((card) => (
                <StepCard
                  key={`${card.id}-step-card`}
                  type={STEP_TYPES.introduction}
                  deleteCardFromStep={() =>
                    onDeleteCardFromStep(STEP_TYPES.introduction, card.id)
                  }
                  stepId={step.id}
                  card={card}
                />
              ))}
            </div>
          )}

          {isCardsExist(step[STEP_TYPES.publication]) && (
            <div>
              {step[STEP_TYPES.publication].map((card) => (
                <StepCard
                  key={`${card.id}-step-card`}
                  type={STEP_TYPES.publication}
                  deleteCardFromStep={() =>
                    onDeleteCardFromStep(STEP_TYPES.publication, card.id)
                  }
                  stepId={step.id}
                  card={card}
                />
              ))}
            </div>
          )}
          {!isParallel && (
            <Text variant="body1Med" className={styles.stepCardText}>
              {t('constructor.step', { step: `${stepIndex + 1}` })}
            </Text>
          )}
        </div>
        {isParallel && isCanBeDeleted && (
          <div className={styles.deleteIconWrapper}>
            <ActionButton
              icon={iconView.Trash}
              size={14}
              onClick={() => {
                deleteStep({ stepId: step.id });
              }}
            />
          </div>
        )}
      </fieldset>
      {isCanBeDeleted && !isStepHasPublication(step) && (
        <CreateCardsInSameStepButtons step={step} />
      )}
    </div>
  );
};
