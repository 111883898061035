import React, { useEffect, useState } from 'react';
import { translate } from 'react-i18next';
import block from 'bem-cn-lite';
import InputNumber from '../../InputNumber';

function prepareSumValue(value) {
  return value === '' ? null : +value;
}

const b = block('cw-pr-filter-amount');
const RangeSum = ({ t, from, to, onBlur }) => {
  const [sumFrom, setSumFrom] = useState(from);
  const [sumTo, setSumTo] = useState(to);

  useEffect(() => {
    setSumFrom(from);
  }, [from]);

  useEffect(() => {
    setSumTo(to);
  }, [to]);

  const blurHandler = (event) => {
    const { name, value } = event.target;

    if (typeof onBlur === 'function') onBlur(name, prepareSumValue(value));
  };

  return (
    <div className={b('wrapper')}>
      <InputNumber
        name="from"
        className={b('item')}
        value={sumFrom}
        onChange={setSumFrom}
        onBlur={blurHandler}
        min={0}
        placeholder={t('minvalue')}
        noArrows
        autoSet={false}
      />
      <div className={b('divider')}>—</div>
      <InputNumber
        name="to"
        className={b('item')}
        value={sumTo}
        onChange={setSumTo}
        onBlur={blurHandler}
        min={sumFrom}
        placeholder={t('maxvalue')}
        noArrows
        autoSet={false}
      />
    </div>
  );
};

export default translate()(RangeSum);
