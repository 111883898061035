import React, {
  FocusEvent,
  HTMLInputTypeAttribute,
  ReactNode,
  useCallback,
  useEffect,
  useState
} from 'react';
import cn from 'classnames';
import { useId, useFocusVisible } from '@/hooks';
import { StandardProps } from '@/components/common.types';
import { TextFieldClasses, TextField } from '@mui/material';
import styles from './Input.module.scss';

const textFieldClasses: Partial<TextFieldClasses> = {
  root: styles.root
};

export interface InputProps extends StandardProps {
  id?: string;
  defaultValue?: string;
  onChange: (value: string) => void;
  valid?: boolean;
  disabled?: boolean;
  type?: HTMLInputTypeAttribute;
  placeholder?: string;
  startAdornment?: ReactNode;
  endAdornment?: ReactNode;
  autocompleteOn?: boolean;
  autocompleteOnlyByFocus?: boolean;

  name?: string;
}

const AUTOCOMPLETE = {
  On: 'on',
  Off: 'new-password'
} as const;

type Autocomplete = typeof AUTOCOMPLETE[keyof typeof AUTOCOMPLETE];

interface AutocompleteProps {
  autocompleteOn: boolean;
  autocompleteOnlyByFocus: boolean;
}

interface AutocompleteActions {
  onFocus: (event: FocusEvent<HTMLElement>) => void;
  onBlur: (event: FocusEvent<HTMLElement>) => void;
}

function useAutocomplete({
  autocompleteOn,
  autocompleteOnlyByFocus
}: AutocompleteProps): [Autocomplete, AutocompleteActions] {
  const { isFocused, onFocus, onBlur } = useFocusVisible();

  const calcAutocomplete = useCallback(() => {
    if (autocompleteOn && autocompleteOnlyByFocus) {
      return isFocused ? AUTOCOMPLETE.On : AUTOCOMPLETE.Off;
    }

    return autocompleteOn ? AUTOCOMPLETE.On : AUTOCOMPLETE.Off;
  }, [autocompleteOn, autocompleteOnlyByFocus, isFocused]);

  const [autocomplete, setAutocomplete] = useState<Autocomplete>(
    calcAutocomplete()
  );

  useEffect(() => {
    if (autocompleteOnlyByFocus) {
      setAutocomplete(calcAutocomplete());
    }
  }, [isFocused, autocompleteOnlyByFocus, calcAutocomplete]);

  return [autocomplete, { onFocus, onBlur }];
}

export function Input({
  className,
  defaultValue = '',
  onChange,
  valid = true,
  id: idOverride,
  type,
  disabled,
  placeholder,
  startAdornment,
  endAdornment,
  name,
  autocompleteOn = true,
  autocompleteOnlyByFocus = false
}: InputProps) {
  const id = useId(idOverride);
  const [value, setValue] = useState<string>(defaultValue || '');
  const [autocomplete, autocompleteActions] = useAutocomplete({
    autocompleteOn,
    autocompleteOnlyByFocus
  });

  const onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    setValue(newValue);
    onChange(newValue);
  };

  return (
    <TextField
      name={name}
      classes={textFieldClasses}
      className={cn(className)}
      error={!valid}
      id={id}
      data-testid="input"
      type={type}
      onChange={onInputChange}
      value={value}
      InputProps={{
        endAdornment,
        startAdornment
      }}
      placeholder={placeholder}
      disabled={disabled}
      autoComplete={autocomplete}
      onFocus={autocompleteActions.onFocus}
      onBlur={autocompleteActions.onBlur}
    />
  );
}
