import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { translate } from 'react-i18next';

import ModalMoveCategory from '../../components/Modal/ModalMoveCategory';
import * as modalAction from '../../action-creators/modal';
import * as categoryActions from '../../action-creators/category';
import * as productsActions from '../../action-creators/products-list';
import * as catalogsActions from '../../action-creators/catalog';

class ContModalMoveCategory extends Component {
  UNSAFE_componentWillMount() {
    const { getCategories, catalogId } = this.props;
    getCategories(catalogId);
  }

  render() {
    const {
      t,
      hideModalDialog,
      getCatalog,
      categories,
      changeCategory,
      catalogId,
      categoryId
    } = this.props;

    if (!categories || categories.size === 0) {
      return null;
    }

    return (
      <ModalMoveCategory
        title={t('change_category_products')}
        hideModalDialog={hideModalDialog}
        listOfCategory={categories || []}
        defaultValue={categories.first().get('id')}
        getCatalog={getCatalog}
        catalogId={catalogId}
        submit={(catId) => changeCategory(catalogId, catId, categoryId)}
      />
    );
  }
}

export default connect(
  (state) => ({
    company: state.get('company'),
    categories: state.getIn(['tables', 'common', 'items'])
  }),
  (dispatch) =>
    bindActionCreators(
      {
        hideModalDialog: modalAction.hideModalDialog,
        getCategories: categoryActions.getCategories,
        changeCategory: productsActions.changeCategory,
        getCatalog: catalogsActions.getCatalog
      },
      dispatch
    )
)(translate(['ui'], { wait: true })(ContModalMoveCategory));
