import React from 'react';
import {
  Row,
  Col,
  Modal,
  Button,
  FormGroup,
  FormControl,
  ControlLabel,
  HelpBlock
} from 'react-bootstrap';
import { translate } from 'react-i18next';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { List } from 'immutable';
import { withRouter } from '../../hoc/withRouter';
import SetCategory from '../SetCategory/SetCategory';

import * as modalActionCreators from '../../action-creators/modal';
import * as catalogActionCreators from '../../action-creators/catalog';
import * as categoryActionCreators from '../../action-creators/category';
import * as tableActionCreators from '../../action-creators/table';
import * as servicesAction from '../../action-creators/services';

class ModalCreateCatalogCategory extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      errors: [],
      showBacklight: false,
      pristine: true
    };
    this.onSaveClick = this.onSaveClick.bind(this);
    this.onSetImg = this.onSetImg.bind(this);
    this.onDelImg = this.onDelImg.bind(this);
  }

  onSetImg() {
    const { showModal, id, setNewCategoryField, uploadImg } = this.props;

    showModal('SAVE_IMG_URL', {
      submit: (url) => Promise.resolve(setNewCategoryField('imgUrl', url)),
      upload: async (file) => {
        const src = await uploadImg(file);
        return Promise.resolve(setNewCategoryField('imgUrl', src));
      },
      onClosed: () => showModal('CREATE_CATALOG_CATEGORY', { id })
    });
  }

  onDelImg() {
    const { setNewCategoryField } = this.props;

    setNewCategoryField('imgUrl', '');
  }

  async onSaveClick() {
    const {
      createCategory,
      history,
      currentCatalog,
      currentCategory,
      getCatalog,
      selectedCategories,
      newCategory,
      id,
      getCategories,
      hideModalDialog,
      clearNewCategory,
      getCategory,
      user,
      gaSend,
      parentId
    } = this.props;

    if (selectedCategories && selectedCategories.first()) {
      await gaSend({
        category: 'Supplier',
        action: 'create_subcategory',
        label: user.get('email')
      });
      return createCategory(
        currentCatalog.get('id'),
        newCategory.get('name'),
        newCategory.get('desc'),
        newCategory.get('imgUrl'),
        selectedCategories.first()
      )
        .then(() => {
          hideModalDialog();
          getCategories(currentCatalog.get('id'));
          getCatalog(currentCatalog.get('id'));
        })
        .catch((e) => {
          this.setState({ errors: e });
          this.setState({ showBacklight: true });
        });
    }
    if (parentId) {
      await gaSend({
        category: 'Supplier',
        action: 'create_subcategory',
        label: user.get('email')
      });
    } else {
      await gaSend({
        category: 'Supplier',
        action: 'create_category',
        label: user.get('email')
      });
    }

    const parentCategory = currentCategory
      ? currentCategory.get('id')
      : newCategory.get('parentId');

    return createCategory(
      currentCatalog.get('id'),
      newCategory.get('name'),
      newCategory.get('desc'),
      newCategory.get('imgUrl'),
      parentCategory
    )
      .then((data) => {
        hideModalDialog();
        history.push(
          `/catalogs/${currentCatalog.get('id')}/categories/${data.id}/products`
        );
        getCategory(id, data.id);
        clearNewCategory();
        return undefined;
      })
      .catch((e) => {
        this.setState({ errors: e });
        this.setState({ showBacklight: true });
      });
  }

  getProperty(field) {
    return this.props.newCategory.get(field);
  }

  showHelpInfo(field) {
    const { t } = this.props;
    if (!this.getProperty(field) && !this.state.pristine) {
      return (
        <HelpBlock id="invalidFieldMessage" style={{ color: 'red' }}>
          {t('Required fields empty')}
        </HelpBlock>
      );
    }
    return null;
  }

  showErrorUnUniqNameCategory() {
    const { t } = this.props;
    const error =
      this.state.errors &&
      this.state.errors.find(
        (err) => err.path === 'name' && err.type === 'unique violation'
      );

    if (!error) {
      return null;
    }

    return (
      <HelpBlock id="invalidFieldMessage" style={{ color: 'red' }}>
        {t('unique_category_name')}
      </HelpBlock>
    );
  }

  inputProps(field, includeBlur = true) {
    const { setNewCategoryField } = this.props;
    return {
      value: this.getProperty(field),
      onChange: (e) => {
        setNewCategoryField(field, e.target.value);
        this.setState({ showBacklight: false });
      },
      onBlur: includeBlur ? undefined : undefined
    };
  }

  render() {
    const {
      t,
      categories,
      selectedCategories,
      setNewCategoryField,
      hideModalDialog,
      newCategory,
      currentCategory,
      clearNewCategory
    } = this.props;

    return (
      <Modal
        id="userModalWindow"
        enforceFocus
        show
        onHide={() => hideModalDialog()}>
        <Modal.Header>
          <Modal.Title>
            {t('Create product category')}
            <span
              className="modal-header-cross pull-right"
              onClick={() => {
                clearNewCategory();
                hideModalDialog();
              }}
            />
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col xs={12}>
              <Row>
                <Col xs={12}>
                  <FormGroup
                    validationState={
                      (newCategory &&
                        newCategory.get('name') &&
                        !this.state.showBacklight) ||
                      this.state.pristine
                        ? null
                        : 'error'
                    }
                    controlId="name">
                    <ControlLabel className="control-label-row">
                      {t('Category name')}
                      <span style={{ color: 'red' }}> *</span>
                    </ControlLabel>
                    <h6 className="pull-right" style={{ marginTop: 5 }}>
                      <small>
                        {this.props.newCategory.get('name').length}
                        /60
                      </small>
                    </h6>
                    <FormControl
                      type="text"
                      maxLength="60"
                      {...this.inputProps('name')}
                      onKeyDown={() => {
                        if (this.state.pristine) {
                          this.setState({ pristine: false });
                        }
                      }}
                    />
                    {this.showHelpInfo('name')}
                    {this.state.showBacklight
                      ? this.showErrorUnUniqNameCategory()
                      : ''}
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col xs={8}>
                  <FormGroup controlId="category">
                    <ControlLabel className="control-label-row">
                      {t('Location')}
                    </ControlLabel>
                    <SetCategory
                      selectedId={
                        (currentCategory && currentCategory.get('id')) ||
                        (selectedCategories &&
                          Number(selectedCategories.first())) ||
                        this.getProperty('parentId') ||
                        null
                      }
                      disabled={
                        !!(
                          (selectedCategories && selectedCategories.first()) ||
                          (currentCategory && currentCategory.get('id'))
                        )
                      }
                      onChose={(value) => {
                        setNewCategoryField('parentId', value);
                        this.setState({ showBacklight: false });
                      }}
                      enableNotSelected
                      categories={categories || List()}
                    />
                  </FormGroup>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <FormGroup controlId="text" style={{ marginBottom: 15 }}>
                <h6 className="pull-right" style={{ marginTop: 0 }}>
                  <small>
                    {newCategory.get('desc')
                      ? newCategory.get('desc').length
                      : '0'}
                    /690
                  </small>
                </h6>
                <FormControl
                  componentClass="textarea"
                  maxLength="690"
                  rows="5"
                  placeholder={t('Category description')}
                  {...this.inputProps('desc')}
                />
              </FormGroup>
            </Col>
          </Row>
          <span style={{ color: 'red' }}>* </span>
          {t('Required fields')}
        </Modal.Body>
        <Modal.Footer>
          <Button
            id="cancel"
            className="pull-left"
            onClick={() => {
              clearNewCategory();
              hideModalDialog();
            }}>
            {t('Cancel')}
          </Button>
          <Button
            id="saveUserButton"
            bsStyle="primary"
            onClick={this.onSaveClick}
            disabled={!newCategory.get('name')}>
            {t('Save')}
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default withRouter(
  connect(
    (state) => ({
      user: state.getIn(['user', 'user']),
      currentCatalog: state.getIn(['catalogCombine', 'properties']),
      currentCategory: state.getIn(['category', 'item']),
      table: state.getIn(['tables', 'products']),
      selected: state.getIn(['tables', 'products', 'selected']),
      selectedCategories: state.getIn(['tables', 'common', 'selected']),
      priceCategories: state.getIn(['tables', 'priceCategories', 'items']),
      validator: state.get('validator'),
      windowHeight: state.getIn(['browserProps', 'windowHeight']),
      newCategory: state.getIn(['category', 'new']),
      parentId: state.getIn(['category', 'new', 'parentId']),
      categories: state.getIn(['tables', 'common', 'items'])
    }),
    (dispatch) =>
      bindActionCreators(
        {
          ...catalogActionCreators,
          ...modalActionCreators,
          ...categoryActionCreators,
          touchCategory: tableActionCreators.touchRow,
          gaSend: servicesAction.gaSend
        },
        dispatch
      )
  )(translate(['ui'], { wait: true })(ModalCreateCatalogCategory))
);
