import React, { useCallback, useMemo, useState, memo } from 'react';

import { useActions, useAsyncFn } from '@hooks';
import { Button, Icon, Modal, Typography } from '@link/react-components';

import cn from 'classnames';
import { nanoid } from 'nanoid';
import { usePrevious } from 'react-use';
import * as modalActions from '../../../action-creators/modal';
import { useTranslate } from '../../../TranslateProvider';
import styles from './RequestKPByEmail.module.css';
import * as notificationMessageActions from '../../../action-creators/message';
import { purchaseRequestService } from '../../../api';
import { validateEmail } from '../../../utils/validateField';
import { InfoBlock } from './infoBlock';
import { EmailRequestInput } from './EmailRequestInput';
import { generateEmailErrorMsg, validate } from './validation';

export const INPUT_HEIGHT = 55;
export const MAX_EMAILS_COUNT = 5;

const createEmailEntity = () => ({
  id: nanoid(),
  email: '',
  errorMessage: ''
});

function canSubmitEmails(emails) {
  const isValidAndNotEmpty = emails.every(
    (emailEntity) =>
      Boolean(emailEntity.email) && validateEmail(emailEntity.email)
  );

  if (!isValidAndNotEmpty) return false;

  const allEmails = emails.map((emailEntity) => emailEntity.email);

  return allEmails.length === new Set(allEmails).size;
}

const AddEmailButton = memo(({ addEmail, length }) => {
  const t = useTranslate();
  return (
    <Button
      mode="text"
      before={<Icon iconName="plus" width={18} height={18} />}
      size="small"
      onClick={addEmail}
      disabled={length === MAX_EMAILS_COUNT}>
      {t('requestKPByEmail.addField')}
    </Button>
  );
});

const RequestKPByEmail = ({ requestId }) => {
  const t = useTranslate();

  const [emails, setEmails] = useState([createEmailEntity()]);
  const { hideModalDialog } = useActions(modalActions);
  const { setSuccessMessage, setErrorMessage } = useActions(
    notificationMessageActions
  );
  const prevEmails = usePrevious(emails);

  const [{ loading }, submit] = useAsyncFn(async (emails) => {
    const emailsForSubmit = emails.map((item) => item.email.trim());

    try {
      await purchaseRequestService.sendToEmails({
        emails: emailsForSubmit,
        requestId
      });
      setSuccessMessage({
        key: 'requestKPByEmail.successSendToEmail'
      });
    } catch (reason) {
      console.error(reason);
      setErrorMessage({
        key: 'requestKPByEmail.sendError'
      });
    } finally {
      hideModalDialog();
    }
  }, []);

  const onChange = useCallback((value, id) => {
    setEmails((prevState) => {
      const changedEmails = prevState.map((emailEntity) => {
        if (emailEntity.id === id) {
          const errorMessage = generateEmailErrorMsg(
            { ...emailEntity, email: value },
            prevState
          );
          return {
            ...emailEntity,
            errorMessage,
            email: value
          };
        }
        return emailEntity;
      });
      return validate(changedEmails);
    });
  }, []);

  const addEmail = useCallback(() => {
    setEmails((prevState) => [...prevState, createEmailEntity()]);
  }, []);

  const deleteEmail = useCallback((emailId) => {
    setEmails((prevState) => {
      const afterDelete = prevState.filter((item) => item.id !== emailId);
      return validate(afterDelete);
    });
  }, []);

  const canSubmit = canSubmitEmails(emails);

  const inputsBlockStyles = useMemo(() => {
    if (prevEmails && prevEmails.length > emails.length) {
      return {
        maxHeight: `${INPUT_HEIGHT * emails.length}px`,
        height: `${INPUT_HEIGHT * prevEmails.length}px`
      };
    }

    return {
      maxHeight: `${INPUT_HEIGHT * emails.length}px`,
      height: `${INPUT_HEIGHT * emails.length}px`
    };
  }, [emails.length]);

  return (
    <Modal id="modal-send-to-email" onClose={hideModalDialog} open>
      <Modal.Header>
        <div className={styles.header}>
          <Typography className={styles.headerTitle} variant="h2">
            {t('requestKPByEmail.title')}
          </Typography>
          <InfoBlock />
        </div>
      </Modal.Header>
      <Modal.Content className={styles.content}>
        <div style={inputsBlockStyles} className={cn(styles.inputs)}>
          {emails.map((emailEntity, index) => (
            <EmailRequestInput
              errorMessage={emailEntity.errorMessage}
              id={emailEntity.id}
              key={emailEntity.id}
              onChange={onChange}
              deleteEmail={deleteEmail}
              showAdornment={index !== 0}
            />
          ))}
        </div>
        <AddEmailButton length={emails.length} addEmail={addEmail} />
      </Modal.Content>
      <Modal.Footer align="right">
        <Modal.Actions>
          <Button mode="text" onClick={hideModalDialog}>
            {t('requestKPByEmail.textBtnCancel')}
          </Button>
          <Button
            disabled={!canSubmit}
            type="submit"
            loading={loading}
            onClick={() => submit(emails)}>
            {t('requestKPByEmail.textBtnConfirm')}
          </Button>
        </Modal.Actions>
      </Modal.Footer>
    </Modal>
  );
};

export default RequestKPByEmail;
