import React, { Suspense, useEffect, useState, lazy } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { useParams } from 'react-router-dom';
import { useTranslate } from '../TranslateProvider';
import './MessengerPage.css';
import * as useCase from '../components/ChatWidget/useCases';
import * as badgesUC from '../components/ChatWidget/modules/badges';
import { getBadgeCount } from '../lib/utils';

import {
  maybeGetOpenedChatId,
  getUserLang
} from '../components/ChatWidget/getter';
import MessengerOther from '../components/Layouts/MessengerOther';
import Loader from '../components/Loader/SectionLoader';
// import Messenger from '../components/ChatWidget/messenger';
const Messenger = lazy(() => import('../components/ChatWidget/Messenger'));

const MessengerPage = ({
  chatId,
  chatBadgeCount,
  language,
  user,
  getAllBadges,
  subscribeToUpdateBadges,
  unsubscribeFromUpdateBadges,
  openChannelById,
  openDialogByEmployeeId
}) => {
  const t = useTranslate();
  const params = useParams();

  const openChat = () => {
    if (params.chatId && params.chatId !== chatId) {
      openChannelById(params.chatId);
      return;
    }

    if (params.employeeId) {
      openDialogByEmployeeId(params.employeeId);
    }
  };

  useEffect(() => {
    openChat();
  }, [params]);

  useEffect(() => {
    getAllBadges();
    subscribeToUpdateBadges();
    return () => {
      unsubscribeFromUpdateBadges();
    };
  }, []);

  const isDemo = user && user.get('type') === 'demo';

  const getPageTitle = () => {
    const badgeCount = getBadgeCount(chatBadgeCount);
    if (!badgeCount) return t('chat-widget.section.chat.pageTitle');
    return `(${badgeCount}) ${t('chat-widget.section.chat.pageTitle')}`;
  };

  const [title, setTitle] = useState(getPageTitle());

  useEffect(() => {
    setTitle(getPageTitle());
  }, [chatBadgeCount, language]);

  const sectionName = t(
    `chat-widget.section.chat.${isDemo ? 'demoSectionName' : 'sectionName'}`
  );

  const breadcrumbs = [{ name: t(sectionName) }];

  return (
    <MessengerOther
      theme="chat"
      title={title}
      breadcrumbs={breadcrumbs}
      content={
        <div className="layout-messenger">
          <Suspense fallback={<Loader />}>
            <Messenger />
          </Suspense>
        </div>
      }
    />
  );
};

export default connect(
  (state) => ({
    user: state.getIn(['user', 'user']),
    chatBadgeCount: state.getIn(['chat', 'chatBadgeCount']),
    language: getUserLang(state),
    chatId: maybeGetOpenedChatId(state)
  }),
  (dispatch) =>
    bindActionCreators(
      {
        getAllBadges: badgesUC.getAllBadges,
        subscribeToUpdateBadges: badgesUC.subscribeToUpdateBadges,
        unsubscribeFromUpdateBadges: badgesUC.unsubscribeFromUpdateBadges,
        ...useCase
      },
      dispatch
    )
)(MessengerPage);
