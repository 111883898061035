import React from 'react';
import CWSIconDropdown from '../../../../ChatWidget/Dropdown/Icon';
import useShareDropdownHandler from './useCase';

const BUTTON_OPTIONS = [
  { icon: 'chat', value: 'send-to-chat' },
  { icon: 'mail', value: 'ask-RFP-by-email' },
  { icon: 'linkNew', value: 'copy-link' }
];

const ShareDropdown = ({ requestId }) => {
  const { handler } = useShareDropdownHandler(requestId);

  return (
    <CWSIconDropdown icon="share-1" list={BUTTON_OPTIONS} onSelect={handler} />
  );
};

export default ShareDropdown;
