import React from 'react';
import PropTypes from 'prop-types';
import { translate } from 'react-i18next';

const BlockedMe = ({ t }) => (
  <div className="chat-not-my-contact-notify-container">
    <p>{t('chat.blocked_me')}</p>
  </div>
);

BlockedMe.propTypes = {
  t: PropTypes.func.isRequired
};
export default translate()(BlockedMe);
