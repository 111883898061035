import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { ModalAddPriceCategoryByCondition } from '../components/Modal';
import * as pricesAction from '../action-creators/prices';
import * as modalAction from '../action-creators/modal';
import * as servicesAction from '../action-creators/services';

const ContModalAddPriceCategory = (props) => (
  <ModalAddPriceCategoryByCondition {...props} />
);

export default connect(
  (state) => ({
    user: state.getIn(['user', 'user']),
    newCategory: state.getIn(['prices', 'newCategory'])
  }),
  (dispatch) =>
    bindActionCreators(
      {
        updateNewCategory: pricesAction.updateNewCategory,
        hideModalDialog: modalAction.hideModalDialog,
        gaSend: servicesAction.gaSend
      },
      dispatch
    )
)(ContModalAddPriceCategory);
