export const toggleBlockModalCase = (
  { toggleBlock, getDialogInfo, getChatContact, showModal },
  { employeeId }
) => {
  const dialogInfo = getDialogInfo();
  const contact = getChatContact().list.find(
    (user) => user.employeeId === employeeId
  );
  const { isBlockedContact } = contact || dialogInfo;

  const onBlock = (id) => {
    toggleBlock(id);
  };

  showModal({
    employeeId,
    isBlocked: isBlockedContact,
    onBlock
  });
};
