import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { translate } from 'react-i18next';
import { withRouter } from '../hoc/withRouter';
import WorkspaceHeader from '../components/WorkspaceHeader/WorkspaceHeader';
import ContainersDefault from '../components/Containers/ContainersDefault';
import TableFileStorageUploads from '../containers/Tables/TableFileStorageUploads';
import FileSelectWrap from '../components/FileSelectWrap';
import Loader from '../components/Loader';

import * as modalActions from '../action-creators/modal';
import * as messageAction from '../action-creators/message';
import {
  getUploadsStorage,
  setGoBackPage,
  clearSelected,
  selectRow,
  removeOrderAttach,
  unsetLink,
  attachFileOrder,
  attachFileCatalog,
  removeFileLinkBySection,
  uploadFile,
  attachFileChat,
  getStorageSize,
  getFilesByIds,
  addSeletedFilesIds
} from '../action-creators/storage';
import * as serviceActions from '../action-creators/services';
import * as requestsActions from '../action-creators/purchaseRequests';
import { publishCatalog } from '../action-creators/catalog/publish';
import * as channelAPI from '../storage/channel.storage';
import * as topicAPI from '../modules/topic/storage';
import * as modalUC from '../useCases/modal-list';

import { getSelectedFilesFromState } from '../utils/utils';
import MessengerOther from '../components/Layouts/MessengerOther';

/* eslint-disable no-shadow */
class FilesStorageUploads extends Component {
  constructor(props) {
    super(props);
    this.renderTable = this.renderTable.bind(this);
    this.onBack = this.onBack.bind(this);
  }

  async UNSAFE_componentWillMount() {
    const {
      getUploadsStorage,
      unsetLink,
      offset,
      limit,
      getStorageSize,
      match: {
        params: { orderId, productId, dialogId, channelId, topicId, requestId }
      },
      location: { state }
    } = this.props;
    const from = state && state.from;
    await getUploadsStorage({ offset, limit });
    await getStorageSize();
    if (orderId || productId || dialogId || channelId || topicId || requestId) {
      if (from) {
        await setGoBackPage(from);
      }
    } else {
      unsetLink();
    }
  }

  componentDidMount() {
    const {
      location: { state },
      addSeletedFilesIds
    } = this.props;
    const selected = getSelectedFilesFromState(state);
    if (selected.length > 0) {
      addSeletedFilesIds(selected);
    }
  }

  componentWillUnmount() {
    this.props.clearSelected();
    this.props.setSuccessMessage({ key: '' });
  }

  renderTable() {
    const {
      isUpload,
      files,
      getUploadsStorage,
      removeFileLinkBySection,
      storageSize,
      maxStorageSize,
      getStorageSize,
      showModal,
      selectRow,
      link,
      match: {
        params: { orderId, productId, dialogId, requestId, channelId, topicId }
      }
    } = this.props;

    if (!isUpload) return <Loader theme="page" />;

    return (
      <TableFileStorageUploads
        files={files}
        getFiles={getUploadsStorage}
        selectRow={(id, item) => selectRow(item.fileId)}
        removeFileLinkBySection={removeFileLinkBySection}
        storageSize={storageSize}
        maxStorageSize={maxStorageSize}
        getStorageSize={getStorageSize}
        showModal={showModal}
        orderId={orderId}
        dialogId={dialogId}
        productId={productId}
        requestId={requestId}
        channelId={channelId}
        topicId={topicId}
        link={link}
        onBack={this.onBack}
      />
    );
  }

  onBack() {
    this.props.showPrevIfHasOneName(['CREATE_TOPIC_CHAT', 'EDIT_TOPIC']);
  }

  renderButtonBlock(text, onChangeCallback, accept) {
    const { t } = this.props;
    return (
      <FileSelectWrap
        multiple={false}
        accept={accept}
        onChange={onChangeCallback}
        component={({ onClick }) => (
          <div onClick={(e) => onClick(e)}>{t(text)}</div>
        )}
      />
    );
  }

  get items() {
    const {
      t,
      files,
      getUploadsStorage,
      selected,
      offset,
      limit,
      uploadFile,
      removeFileLinkBySection,
      imageAttach,
      setErrorMessage,
      setSuccessMessage,
      maxStorageSize,
      match: {
        params: { orderId, productId, dialogId, requestId, channelId, topicId }
      },
      link,
      attachFileOrder,
      attachFileCatalog,
      showModal,
      // attachFileChat,
      storageSize,
      gaSend,
      user,
      addAttachmentsToNewRequest,
      history,
      getFilesByIds,
      publishCatalog: pubCat,
      location: { state: { forwardedMessages, operation = '' } = {} } = {}
    } = this.props;

    const loadFile = async (file) => {
      await uploadFile(file);
      await gaSend({
        category: 'FileStorage',
        action: 'loading_file',
        label: user.get('email')
      });
    };

    const it = [
      {
        name: t('Upload'),
        disabled:
          orderId || productId || dialogId || requestId || channelId || topicId,
        popover: storageSize >= maxStorageSize,
        popoverText: t('tariff_limit_txt'),
        element: this.renderButtonBlock(
          t('Upload'),
          (f) => loadFile(f[0]),
          '',
          false,
          t
        )
      }
    ];

    if (orderId || productId || dialogId || channelId || topicId || requestId) {
      it.push({
        name: t('Attach file'),
        onClick: async () => {
          if (orderId) {
            attachFileOrder(selected, orderId, link);
          }
          if (productId) {
            const resp = await attachFileCatalog(
              selected,
              productId,
              link,
              !!imageAttach
            );
            if (resp.error) {
              return setErrorMessage({ key: resp.error });
            }
            setSuccessMessage({
              key: 'Document saved successfully',
              linkKey: 'Publish catalog',
              linkClickHandler: () =>
                confirm(t('Catalogue will published in a few minutes')) &&
                pubCat(resp.catalogId, true)
            });
          }
          if (dialogId) {
            if (this.props.location.state.count + selected.size > 5) {
              return this.props.setErrorMessage({
                key: 'file_upload_allowed_limit'
              });
            }
            await channelAPI.attachUnsentFileFromStorage(dialogId, selected);
            history.push({
              pathname: link,
              state: {
                forwarded: !!forwardedMessages,
                messages: forwardedMessages
              }
            });
          }
          if (channelId) {
            if (this.props.location.state.count + selected.size > 5) {
              return this.props.setErrorMessage({
                key: 'file_upload_allowed_limit'
              });
            }
            await channelAPI.attachUnsentFileFromStorage(channelId, selected);
            history.push({
              pathname: link,
              state: {
                forwarded: !!forwardedMessages,
                messages: forwardedMessages
              }
            });
          }
          if (topicId) {
            if (this.props.location.state.count + selected.size > 5) {
              return this.props.setErrorMessage({
                key: 'file_upload_allowed_limit'
              });
            }
            if (operation === 'create' || operation === 'edit') {
              await topicAPI.attachUnsaveInfoFileFromStorage(topicId, selected);
              this.onBack();
            } else {
              await channelAPI.attachUnsentFileFromStorage(topicId, selected);
            }
            return history.push({
              pathname: link,
              state: {
                forwarded: !!forwardedMessages,
                messages: forwardedMessages
              }
            });
          }
          if (requestId) {
            if (this.props.location.state.count + selected.size > 5) {
              return this.props.setErrorMessage({
                key: 'file_upload_allowed_limit'
              });
            }
            const selectedFiles = await getFilesByIds(selected, 'uploads');
            await addAttachmentsToNewRequest(selectedFiles);
            if (requestId === 'new') {
              history.push('/requests/my/create');
            } else {
              history.push(`/requests/my/${requestId}/edit`);
            }
          }
          return undefined;
        },
        disabled:
          selected.size === 0 ||
          (!orderId &&
            !productId &&
            !dialogId &&
            !channelId &&
            !topicId &&
            !requestId)
      });
    }
    return it.concat([
      {
        name: t('Download'),
        onClick: async () => {
          if (selected.size === 1) {
            const fileId = selected.toJS()[0];
            const f = files.find(
              (file) => file.getIn(['fileInfo', 'id']) === fileId
            );
            window.open(
              `${f.getIn(['fileInfo', 'url'])}?name=${f.get('name')}`,
              '_blank'
            );
            return;
          }

          const query = selected
            .toJS()
            .reduce(
              (str, id) => `${str}&id[]=${id}`,
              `?timezoneOffset=${new Date().getTimezoneOffset()}`
            );

          window.open(`${process.env.STORAGE_FILES_URL}${query}`, '_blank');
        },
        disabled: selected.size === 0
      },
      {
        name: t('Delete'),
        onClick: async () => {
          showModal('SIMPLE_SUBMIT', {
            captionKey: 'confirm_action',
            text: 'Delete?',
            textBtnConfirm: 'OK',
            submitAction: async () => {
              await removeFileLinkBySection(selected, 'uploads');
              await getUploadsStorage({ offset, limit });
            }
          });
        },
        disabled: selected.size === 0
      }
    ]);
  }

  render() {
    const {
      t,
      history,
      match: {
        params: { requestId }
      },
      selected,
      location
    } = this.props;

    const selectedFiles = selected.toJS();

    return (
      <MessengerOther
        title={t('File storage')}
        breadcrumbs={[{ name: t('File storage') }]}
        panelBtns={this.items}
        content={
          <>
            <WorkspaceHeader
              navigation={{
                activeKey: 'uploads',
                items: [
                  { eventKey: 'chat', name: t('Chat') },
                  { eventKey: 'uploads', name: t('Uploads') }
                ],
                handleTabChange: (tab) => {
                  if (requestId) {
                    history.push({
                      pathname: `/file-storage/${tab}/purchaserequest/attach/${requestId}`,
                      state: { ...location.state, selectedFiles }
                    });
                  } else {
                    history.push(`/file-storage/${tab}`);
                  }
                }
              }}
            />
            <ContainersDefault>{this.renderTable()}</ContainersDefault>
          </>
        }
      />
    );
  }
}
/* eslint-enable no-shadow */

FilesStorageUploads.contextTypes = {
  badgeSocket: PropTypes.object
};

export default withRouter(
  connect(
    (state) => ({
      company: state.get('company'),
      user: state.getIn(['user', 'user']),
      selected: state.getIn(['storage', 'selected']),
      files: state.getIn(['storage', 'uploads', 'files']),
      size: state.getIn(['storage', 'uploads', 'size']),
      limit: state.getIn(['storage', 'uploads', 'limit']),
      offset: state.getIn(['storage', 'uploads', 'offset']),
      link: state.getIn(['storage', 'link']),
      isUpload: state.getIn(['storage', 'uploads', 'isUpload']),
      maxStorageSize: state.getIn(['storage', 'maxStorageSize']),
      storageSize: state.getIn(['storage', 'storageSize'])
    }),
    (dispatch) =>
      bindActionCreators(
        {
          showModal: modalActions.showModal,
          setErrorMessage: messageAction.setErrorMessage,
          setSuccessMessage: messageAction.setSuccessMessage,
          gaSend: serviceActions.gaSend,
          addAttachmentsToNewRequest:
            requestsActions.addAttachmentsToNewRequest,
          showPrevIfHasOneName: modalUC.showPrevIfHasOneName,
          getUploadsStorage,
          selectRow,
          removeOrderAttach,
          clearSelected,
          setGoBackPage,
          unsetLink,
          attachFileOrder,
          attachFileCatalog,
          removeFileLinkBySection,
          attachFileChat,
          uploadFile,
          getStorageSize,
          getFilesByIds,
          addSeletedFilesIds,
          publishCatalog
        },
        dispatch
      )
  )(translate(['ui'], { wait: true })(FilesStorageUploads))
);
