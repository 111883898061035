import { useCallback } from 'react';
import { actions } from '../redux';
import * as emailMessagesStorage from '../storage';
import { useActions } from '../../../../../hooks/useActions';

const useFetchAllMessages = () => {
  const { setLoading, setMessages } = useActions(actions);

  const fetchAllMessages = useCallback(async () => {
    try {
      setLoading(true);
      const messages = await emailMessagesStorage.fetchAllMessages();
      setMessages(messages);
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  }, []);

  return { fetchAllMessages };
};

export default useFetchAllMessages;
