import React from 'react';
import { Route, Navigate, Outlet } from 'react-router-dom';

export const PrivateRoutesWrapper = ({
  children,
  isAllowed = true,
  defaultRoute = '/company/edit'
}) => (isAllowed ? children : <Navigate to={defaultRoute} replace />);

export const PrivateRoute = ({
  component,
  isAllowed = true,
  defaultRoute = '/company/edit',
  ...rest
}) => (
  <Route
    {...rest}
    element={(props) =>
      isAllowed ? component(props) : <Navigate to={defaultRoute} replace />
    }
  />
);

export const ProtectedRoute = ({
  isAllowed = false,
  redirectPath = '/',
  children
}) => {
  if (!isAllowed) {
    return <Navigate to={redirectPath} replace />;
  }

  return children ?? <Outlet />;
};
