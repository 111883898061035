import React from 'react';
import { Button } from 'react-bootstrap';
import IconPlusRoundCircle from '../../../ChatWidget/ButtonIcon/icons/plus-round-circle';

const ComponentButton = ({ isOpened, onClick, name, isError, testId }) => {
  if (isOpened) {
    return null;
  }

  return (
    <Button
      data-testid={testId}
      style={{ marginRight: 4, borderColor: isError ? '#FD4F53' : '' }}
      onClick={onClick}>
      <div style={{ display: 'flex' }}>
        <div style={{ margin: '-10px 0px -12px -16px' }}>
          <IconPlusRoundCircle />
        </div>
        {name}
      </div>
    </Button>
  );
};

export default ComponentButton;
