import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import ModalSimpleSubmit from '../components/Modal/ModalSimpleSubmit';
import * as modalAction from '../action-creators/modal';
import { removeOrdersToSuppliers } from '../action-creators/orders';

const ContModalConfirmDeleteOrdersToSuppliers = ({
  confirmDeleteOrder,
  onSubmited = () => {},
  hideModalDialog,
  ids
}) => (
  <ModalSimpleSubmit
    title="Delete order"
    hideModalDialog={hideModalDialog}
    submit={() => confirmDeleteOrder({ ids })}
    onSubmited={onSubmited}
    text="Deleted order can not be restored Delete the order"
    textBtnConfirm="Delete"
  />
);

export default connect(null, (dispatch) =>
  bindActionCreators(
    {
      confirmDeleteOrder: removeOrdersToSuppliers,
      hideModalDialog: modalAction.hideModalDialog
    },
    dispatch
  )
)(ContModalConfirmDeleteOrdersToSuppliers);
