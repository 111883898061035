import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { ModalEditPriceCategoryByCondition } from '../components/Modal';
import * as modalAction from '../action-creators/modal';

const ContModalEditPriceCategory = (props) => (
  <ModalEditPriceCategoryByCondition {...props} />
);

export default connect(null, (dispatch) =>
  bindActionCreators(
    {
      hideModalDialog: modalAction.hideModalDialog
    },
    dispatch
  )
)(ContModalEditPriceCategory);
