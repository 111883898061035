import React from 'react';
import { Image } from 'react-bootstrap';
import { translate } from 'react-i18next';
import { convertUrl } from './ChatWidget/modules/files/utils';

class ImageControl extends React.Component {
  static getPlaceholder() {
    return null;
  }

  constructor(props) {
    super(props);
    this.state = {
      errorLoading: false
    };
    this.onAddImg = this.onAddImg.bind(this);
    this.onErrorLoadImg = this.onErrorLoadImg.bind(this);
    this.onRemoveImg = this.onRemoveImg.bind(this);
  }

  UNSAFE_componentWillReceiveProps(props) {
    if (props.imgSrc !== this.props.imgSrc) {
      this.setState({ errorLoading: false });
    }
  }

  onErrorLoadImg() {
    this.setState({ errorLoading: true });
  }

  onAddImg(event) {
    this.props.onFileChoose(event);
  }

  async onRemoveImg() {
    this.setState({ errorLoading: false });
    await this.props.onImageRemove();
  }

  haveImgSrc() {
    return !!this.props.imgSrc;
  }

  showControlBtn() {
    const { readOnly = false } = this.props;
    if (!readOnly && !this.errorLoading) return true;
    return false;
  }

  showBtnRemoveImg() {
    return (this.showControlBtn() && this.haveImgSrc()) || this.errorLoading;
  }

  showBtnAddImg() {
    return this.showControlBtn() && !this.haveImgSrc();
  }

  get errorLoading() {
    return this.state.errorLoading;
  }

  render() {
    const { t, imgSrc, style = {}, name } = this.props;

    return (
      <div className="image-control" style={style}>
        <div className="image-control__container">
          {this.errorLoading ? (
            <span className="img-thumbnail img-loading-error">
              {t('The image on the link is not available')}
            </span>
          ) : (
            <Image
              key={imgSrc}
              className="image-control__image"
              name={name}
              src={imgSrc ? convertUrl(imgSrc) : null}
              thumbnail
              rounded
              onError={this.onErrorLoadImg}
            />
          )}
          {this.showBtnRemoveImg() && (
            <div className="btn-img-remove" onClick={this.onRemoveImg} />
          )}
          {this.showBtnAddImg() && (
            <div className="btn-img-add" onClick={this.onAddImg} />
          )}
        </div>
      </div>
    );
  }
}

export default translate(['ui'], { wait: true })(ImageControl);
