import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { ModalEditCustomerCategory } from '../components/Modal';
import * as customersAction from '../action-creators/customers';
import * as modalAction from '../action-creators/modal';

const ContModalEditCustomerCategory = (props) => (
  <ModalEditCustomerCategory {...props} />
);

export default connect(
  (state) => ({
    categories: state.getIn(['customers', 'groups'])
  }),
  (dispatch) =>
    bindActionCreators(
      {
        updateCustomersCategory: customersAction.updateCustomersCategory,
        updateCategoryName: customersAction.updateCategoryName,
        setSelectedCategory: customersAction.setSelectedCategory,
        getGroups: customersAction.getGroups,
        hideModalDialog: modalAction.hideModalDialog
      },
      dispatch
    )
)(ContModalEditCustomerCategory);
