import React, { Component } from 'react';
import PropTypes from 'prop-types';
import block from 'bem-cn-lite';
import { translate, Interpolate } from 'react-i18next';
import { Modal, Form, FormControl, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import './styles/modal_theme_default.styl';
import './styles/modal_type_grant-access.styl';

import { ModalContainer } from './elements';

const b = block('modal-container');

class ModalGrantAccess extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectValue: props.defaultCategory
    };
    this.setSelectValue = this.setSelectValue.bind(this);
  }

  getChildContext() {
    return { b };
  }

  setSelectValue(e) {
    this.setState({ selectValue: e.target.value });
  }

  handleClick() {
    const { badgeSocket: socket } = this.context;
    const { hideModalDialog, submit, company, getAllPrices, priceId } =
      this.props;

    submit(this.state.selectValue).then(() => {
      hideModalDialog();
      getAllPrices();
      socket.emit(
        'priceAccess',
        JSON.stringify({ priceId, companyId: company.get('id') })
      );
    });
  }

  render() {
    const {
      t,
      title,
      hideModalDialog,
      isEmptyCategory,
      map,
      listOfCategoryBuyers,
      getCategoryValue,
      getCategoryName,
      gaPageView,
      gaSend,
      user
    } = this.props;

    return (
      <Modal id="grandAccess" onHide={() => hideModalDialog()} show>
        <ModalContainer mods={{ type: 'grant-access', theme: 'default' }}>
          <Modal.Header>
            <Modal.Title>
              {t(title)}
              <span
                className="modal-header-cross pull-right"
                onClick={() => hideModalDialog()}
              />
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              {isEmptyCategory ? (
                <div>
                  <p>{t('You have no buyers.')}</p>
                  <p>
                    <Interpolate
                      i18nKey="You can add contacts under"
                      AddCustomer={
                        <Link to="/customers/list" onClick={hideModalDialog}>
                          {t('Customers')}
                        </Link>
                      }
                    />
                  </p>
                </div>
              ) : (
                <div>
                  <p className={b('row', { 'with-input': true })}>
                    <div className={b('title')}>{t('Buyers group')}</div>
                    <div className={b('input', { select: true })}>
                      <FormControl
                        componentClass="select"
                        value={this.state.selectValue}
                        onChange={this.setSelectValue}>
                        {map(listOfCategoryBuyers, (categoryBuyers) => (
                          <option value={getCategoryValue(categoryBuyers)}>
                            {t(getCategoryName(categoryBuyers))}
                          </option>
                        ))}
                      </FormControl>
                    </div>
                  </p>
                  <p>
                    <Interpolate
                      i18nKey="If you have new partners, you can add contacts under"
                      AddCustomer={
                        <Link to="/customers/list" onClick={hideModalDialog}>
                          {t('Customers')}
                        </Link>
                      }
                    />
                  </p>
                </div>
              )}
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button
              id="cancel"
              className="pull-left"
              onClick={() => {
                gaPageView('price_add_access_cancel');
                gaSend({
                  category: 'Supplier',
                  action: 'price_add_access_cancel',
                  label: user.get('email')
                });
                hideModalDialog();
              }}>
              {t('Cancel')}
            </Button>
            <Button
              id="saveCharacteristicButton"
              bsStyle="primary"
              disabled={isEmptyCategory}
              onClick={this.handleClick}>
              {t('Share catalogue')}
            </Button>
          </Modal.Footer>
        </ModalContainer>
      </Modal>
    );
  }
}

ModalGrantAccess.contextTypes = {
  badgeSocket: PropTypes.object
};

ModalGrantAccess.propTypes = {
  t: PropTypes.func.isRequired
};

ModalGrantAccess.childContextTypes = {
  b: PropTypes.func
};

export default translate(['ui'], { wait: true })(ModalGrantAccess);
