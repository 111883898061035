import React from 'react';
import VerticalDots from '../../../Buttons/VerticalDots';
import PopupSmall from '../../../PopupSmall';
import PopupContent from '../../../PopupSmall/PopupContent';

const ExtraButton = ({ options = [], top = 35 }) => {
  const [isMenuOpened, setIsMenuOpened] = React.useState(false);

  const toggle = () => setIsMenuOpened((prevState) => !prevState);

  return (
    <>
      <VerticalDots isActive={isMenuOpened} onClick={toggle} />
      <PopupSmall
        isOpened={isMenuOpened}
        setIsOpened={setIsMenuOpened}
        content={<PopupContent menuOptions={options} />}
        top={top}
      />
    </>
  );
};

export default ExtraButton;
