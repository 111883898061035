import React from 'react';
import { Typography } from '../../../../shared/components';
import classes from '../../SuppliersActivities.module.css';

const ResponsesInfo = ({ count, newCount }) => (
  <>
    <Typography variant="body1Sb">{count}</Typography>
    {newCount > 0 && (
      <Typography className={classes.newResponses} variant="body1Sb">
        &nbsp;
        {` (+${newCount})`}
      </Typography>
    )}
  </>
);

export default ResponsesInfo;
