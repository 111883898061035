import React from 'react';
import MapCategories from './MapCategories';
import { useTranslate } from '../../../TranslateProvider';
import { getNameString } from '../../CategoriesSelect/helper';
import AcceptRoundIcon from '../../../icons/accept-round';
import './style.css';

const isSelected = (id, list) => list.includes(id);

const CategoryCard = ({
  category,
  openedCategories,
  selectedIds,
  onChoose,
  categoryChildren,
  setIsOpened,
  isSearch,
  fullList,
  level
}) => {
  const t = useTranslate();

  const onClick = (event) => {
    event.stopPropagation();
    setIsOpened(category.id);
  };

  return (
    <>
      <div
        className={`category-modal-card ${
          isSelected(category.id, selectedIds) ? 'selected' : ''
        }`}
        onClick={onChoose(category.id)}>
        <div style={{ minWidth: level * 28 }} />
        {!isSearch && (
          <button
            className="category-modal-button"
            onClick={onClick}
            type="button"
            style={{
              visibility: categoryChildren.length > 0 ? 'visible' : 'hidden'
            }}>
            <span
              className="category-modal-arrow"
              style={{
                transform: `rotate(${
                  isSelected(category.id, openedCategories) ? '225deg' : '45deg'
                })`
              }}
            />
          </button>
        )}
        <div className="category-modal-name">
          {getNameString(category.name, t)}
        </div>
        <div className="category-modal-accept">
          <AcceptRoundIcon />
        </div>
      </div>
      {!isSearch && isSelected(category.id, openedCategories) && (
        <MapCategories
          list={categoryChildren}
          selectedIds={selectedIds}
          onCategoryChoose={onChoose}
          openedCategories={openedCategories}
          setIsOpened={setIsOpened}
          isSearch={isSearch}
          fullList={fullList}
          level={level + 1}
        />
      )}
    </>
  );
};

export default CategoryCard;
