import { Map } from 'immutable';
import { equals, curry, pipe } from 'ramda';
// eslint-disable-next-line import/no-cycle
import {
  matchedPriceCategoryBySumOfOrder,
  isEmptyPriceCategoryBySumOrder,
  totalDiscountByConditions,
  matchedPriceCategoryBySumOfOrderNotDraft,
  isEmptyPriceCategoryBySumOrderNotDraft
} from '../viewModels/sum/purchasePriceCategories';
// eslint-disable-next-line import/no-cycle
import { getProducts, getProductsNotDraft } from './orderDraft';
import { NUMBER_OF_DIGITS, TYPES_OF_CATEGORY } from '../constants';
import {
  isIncludeInRange,
  discountPriceWithRoundUp,
  fractionalDigitsByNumber,
  ifZeroToInfinity
} from '../lib/math';

const mainPath = (state) => state.get('publicPrices');

const adapterPurchaseCategories = (category) =>
  category
    .set('discount', Number(category.get('discount')))
    .set(
      'fractionalDigits',
      fractionalDigitsByNumber(
        category.get('roundUp') || NUMBER_OF_DIGITS.HUNDREDS
      )
    )
    .set('to', ifZeroToInfinity(category.get('to')));

const purchaseCategories = (state) =>
  mainPath(state).get('purchaseCategories').map(adapterPurchaseCategories);

const filterCategoryByType = curry((type, categories) =>
  categories.filter((category) => category.get('type') === type)
);

const findIndividualCategory = (product, priceCategory) => {
  const foundCategory = product
    .get('offers')
    .find(
      (individualPriceCategory) =>
        individualPriceCategory.get('purchaseCategoryId') ===
        priceCategory.get('id')
    );

  if (!foundCategory) return foundCategory;

  return foundCategory.set(
    'fractionalDigits',
    priceCategory.get('fractionalDigits')
  );
};

const inividualOrCommonCategory = (individual, common) => individual || common;

export const emptyPriceCategory = Map({
  minOrder: 0,
  maxOrder: 0,
  discount: 0,
  name: '',
  id: -1,
  roundUp: 100,
  fractionalDigits: NUMBER_OF_DIGITS.HUNDREDS
});

const matchRangePriceCategoryForProduct = (rangeCategories, product) => {
  const unit = product.get('unit');
  const quantity = product.get('count');

  const foundCategory = rangeCategories.find((category) => {
    const from = category.get('from');
    const to = category.get('to');
    const categoryUnit = category.get('unit');

    return equals(unit, categoryUnit) && isIncludeInRange(from, to, quantity);
  });

  return foundCategory || emptyPriceCategory;
};

const matchPriceCatTypeSumOfOrder = (categoriesBySum, sumOfProducts) => {
  const foundCategory = categoriesBySum.find((category) => {
    const minOrder = category.get('minOrder');
    const maxOrder = category.get('maxOrder');

    return isIncludeInRange(minOrder, maxOrder, sumOfProducts);
  });

  return foundCategory || emptyPriceCategory;
};

const getRangePriceCategories = pipe(
  purchaseCategories,
  filterCategoryByType(TYPES_OF_CATEGORY.AMOUNT_OF_PRODUCT_ORDER)
);
const getSumOfOrderPriceCategories = pipe(
  purchaseCategories,
  filterCategoryByType(TYPES_OF_CATEGORY.SUM_OF_ORDER)
);

const getInividualOrCommonCategory = curry((commonCategory, product) =>
  inividualOrCommonCategory(
    findIndividualCategory(product, commonCategory),
    commonCategory
  )
);

export const pricePerAmount = (price, product) => price * product.get('count');
export const calcPriceOfProductList = curry((calcPriceProduct, products) =>
  products.reduce(
    (total, product) =>
      total + pricePerAmount(calcPriceProduct(product), product),
    0
  )
);

export const discountPriceByOrderProduct = (product) =>
  discountPriceWithRoundUp(
    product.get('discount'),
    product.get('fractionalDigits'),
    product.get('cost')
  );

export const getRoundUp = (state) =>
  mainPath(state).getIn(['price', 'roundUp']);

export default (state) => ({
  price: () => mainPath(state).get('price'),
  roundUp: () => getRoundUp(state),
  purchaseCategories: () => purchaseCategories(state),
  priceCategoryTypeAmountOfProduct: () => getRangePriceCategories(state),
  priceCategoryTypeSumOfOrder: () => getSumOfOrderPriceCategories(state),
  getRangePriceCategoryForProduct: (product) =>
    getInividualOrCommonCategory(
      matchRangePriceCategoryForProduct(
        getRangePriceCategories(state),
        product
      ),
      product
    ),
  getSumOfProductPriceCategoryForProduct: (product) =>
    getInividualOrCommonCategory(
      matchPriceCatTypeSumOfOrder(
        getSumOfOrderPriceCategories(state),
        calcPriceOfProductList(discountPriceByOrderProduct, getProducts(state))
      ),
      product
    ),
  productsList: () => mainPath(state).get('productsList'),
  categories: () => mainPath(state).get('categories'),
  matchedPriceCategoryBySumOfOrder: () =>
    matchedPriceCategoryBySumOfOrder(state),
  isEmptyPriceCategoryBySumOrder: () => isEmptyPriceCategoryBySumOrder(state),
  matchedPriceCategoryBySumOfOrderNotDraft: () =>
    matchedPriceCategoryBySumOfOrderNotDraft(state, getProductsNotDraft(state)),
  isEmptyPriceCategoryBySumOrderNotDraft: () =>
    isEmptyPriceCategoryBySumOrderNotDraft(state, getProductsNotDraft(state)),
  totalDiscountByConditions: () => totalDiscountByConditions(state)
});
