import fetch from 'isomorphic-fetch';
import {
  setSuccessMessage,
  setWarningMessage,
  setErrorMessage
} from './message';
import getBrowserLanguage from '../utils/languageDetector';
import request from '../lib/request';

export const getSuppliers =
  (groupId, { offset = 0, limit = 20, requestId }) =>
  (dispatch) => {
    dispatch({ type: 'SUPPLIERS:GET_SUPPLIERS:REQUEST' });
    return (
      fetch(
        `/api/suppliers/${groupId}?offset=${offset}&limit=${limit}${
          requestId ? `&requestId=${requestId}` : ''
        }`,
        {
          credentials: 'include',
          method: 'GET'
        }
      )
        .then((res) => res.json())
        // eslint-disable-next-line no-shadow
        .then(({ suppliers, size, totalSize, offset, limit }) => {
          dispatch({
            type: 'SUPPLIERS:GET_SUPPLIERS:SUCCESS',
            payload: { suppliers, size, totalSize, offset, limit, groupId }
          });
          return Promise.resolve();
        })
        .catch((res) => {
          dispatch({
            type: 'SUPPLIERS:GET_SUPPLIERS:FAILURE',
            payload: { error: res.error }
          });
          console.error(res);
        })
    );
  };

export const getSuppliersByName =
  (name = '', groupId = 0, { offset = 0, limit = 20 }) =>
  (dispatch) => {
    dispatch({ type: 'SUPPLIERS:GET_SUPPLIERS:REQUEST' });

    const url = `/api/suppliers/search?name=${name}&groupId=${groupId}&offset=${offset}&limit=${limit}`;
    fetch(url, {
      credentials: 'include',
      method: 'GET'
    })
      .then((res) => res.json())
      // eslint-disable-next-line no-shadow
      .then(({ suppliers, size, totalSize, offset, limit }) => {
        dispatch({
          type: 'SUPPLIERS:GET_SUPPLIERS:SUCCESS',
          payload: { suppliers, size, totalSize, offset, limit, groupId }
        });
      })
      .catch((res) => {
        dispatch({
          type: 'SUPPLIERS:GET_SUPPLIERS:FAILURE',
          payload: { error: res.error }
        });
        console.error(res);
      });
  };

export const getAllSuppliers =
  (offset = 0, limit = 20) =>
  (dispatch) => {
    dispatch({ type: 'SUPPLIERS:GET_SUPPLIERS:REQUEST' });

    fetch(`/api/suppliers?offset=${offset}&limit=${limit}`, {
      credentials: 'include',
      method: 'GET'
    })
      .then((res) => res.json())
      // eslint-disable-next-line no-shadow
      .then(({ suppliers, size, totalSize, offset, limit }) => {
        dispatch({
          type: 'SUPPLIERS:GET_SUPPLIERS:SUCCESS',
          payload: { suppliers, size, totalSize, offset, limit }
        });
      })
      .catch((res) => {
        dispatch({
          type: 'SUPPLIERS:GET_SUPPLIERS:FAILURE',
          payload: { error: res.error }
        });
        console.error(res);
      });
  };

export const removeSupplierFromGroup =
  (suppliers, groupId, deleteGroups = false) =>
  (dispatch) => {
    dispatch({ type: 'SUPPLIERS:GROUP_REMOVE:REQUEST' });
    return fetch('/api/suppliers/removeFromGroup', {
      credentials: 'include',
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ suppliers, groupId })
    })
      .then(() => {
        if (deleteGroups) {
          dispatch({
            type: 'SUPPLIERS:GROUP_REMOVE:SUCCESS',
            payload: { suppliers, groupId }
          });
        } else {
          dispatch({
            type: 'SUPPLIERS:DELETE:SUCCESS',
            payload: { suppliers }
          });
        }
      })
      .catch((res) => {
        dispatch({
          type: 'SUPPLIERS:GROUP_REMOVE:FAILURE',
          payload: { error: res.error }
        });
        console.error(res);
      });
  };

export const addSupplierToGroup = (suppliers, groupId) => (dispatch) => {
  dispatch({ type: 'SUPPLIERS:GROUP_ADD:REQUEST' });
  return fetch('/api/suppliers/addToGroup', {
    credentials: 'include',
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ suppliers, groupId })
  })
    .then(() => {
      dispatch({
        type: 'SUPPLIERS:GROUP_ADD:SUCCESS',
        payload: { suppliers, groupId }
      });
    })
    .catch((res) => {
      dispatch({
        type: 'SUPPLIERS:GROUP_ADD:FAILURE',
        payload: { error: res.error }
      });
      console.error(res);
    });
};

export const getGroups = () => (dispatch) => {
  dispatch({ type: 'SUPPLIERS:GET_GROUPS:REQUEST' });
  return fetch('/api/suppliers/groups', {
    credentials: 'include',
    method: 'GET'
  })
    .then((res) => res.json())
    .then((responseJson) => {
      dispatch({ type: 'SUPPLIERS:GET_GROUPS:SUCCESS', payload: responseJson });
      return responseJson.groups;
    })
    .catch((res) => {
      dispatch({
        type: 'SUPPLIERS:GET_GROUPS:FAILURE',
        payload: { error: res.error }
      });
      console.error(res);
    });
};

// eslint-disable-next-line consistent-return
export const editGroup = (name, groupId) => async (dispatch) => {
  try {
    dispatch({ type: 'SUPPLIERS:GROUP_EDIT:REQUEST' });
    const res = await fetch(`/api/suppliers/groups/${groupId}`, {
      credentials: 'include',
      method: 'PATCH',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ name })
    });
    const json = await res.json();
    if (json.error) {
      return dispatch(setErrorMessage({ key: json.error }));
    }
    dispatch({ type: 'SUPPLIERS:GROUP_EDIT:SUCCESS', payload: json });
  } catch (err) {
    dispatch({ type: 'SUPPLIERS:GROUP_EDIT:FAILURE', payload: { error: err } });
    console.error(err);
  }
};

export const deleteGroup = (groupId) => (dispatch) => {
  dispatch({ type: 'SUPPLIERS:GROUP_DELETE:REQUEST' });
  return fetch(`/api/suppliers/groups/${groupId}`, {
    credentials: 'include',
    method: 'DELETE',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ groupId })
  })
    .then((res) => res.json())
    .then(() => {
      dispatch({
        type: 'SUPPLIERS:GROUP_DELETE:SUCCESS',
        payload: { groupId }
      });
    })
    .catch((res) => {
      dispatch({
        type: 'SUPPLIERS:GROUP_DELETE:FAILURE',
        payload: { error: res.error }
      });
      console.error(res);
    });
};

export const getAdditionRequests = () => (dispatch) => {
  dispatch({ type: 'SUPPLIERS:GET_ADDITION_REQUESTS:REQUEST' });
  fetch('/api/suppliers/addition_requests', {
    credentials: 'include',
    method: 'GET'
  })
    .then((res) => res.json())
    .then((responseJson) => {
      dispatch({
        type: 'SUPPLIERS:GET_ADDITION_REQUESTS:SUCCESS',
        payload: responseJson
      });
    })
    .catch((res) => {
      dispatch({
        type: 'SUPPLIERS:GET_ADDITION_REQUESTS:FAILURE',
        payload: { error: res.error }
      });
      console.error(res);
    });
};

export const confirmAddSupplier =
  (requestId, fromResponseRequest = false) =>
  (dispatch) =>
    fetch('/api/suppliers/confirm_add', {
      credentials: 'include',
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ requestId })
    })
      .then((res) => res.json())
      .then((resp) => {
        if (fromResponseRequest) {
          dispatch({
            type: 'PURCHASE_REQUEST:CONFIRM_SUPPLIER',
            payload: { requestId }
          });
        } else {
          dispatch({
            type: 'SUPPLIERS:CONFIRM_ADD:SUCCESS',
            payload: { requestId, type: 'suppliersAdditionRequests' }
          });
        }
        return resp;
      })
      .catch((res) => {
        dispatch({
          type: 'SUPPLIERS:CONFIRM_ADD:FAILURE',
          payload: { error: res.error }
        });
        console.error(res);
      });

export const rejectAddSupplier =
  (requestId, fromResponseRequest) => (dispatch) =>
    fetch('/api/suppliers/reject_add', {
      credentials: 'include',
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ requestId })
    })
      .then((res) => res.json())
      .then(() => {
        if (fromResponseRequest) {
          dispatch({
            type: 'PURCHASE_REQUEST:REJECT_SUPPLIER',
            payload: { requestId }
          });
        } else {
          dispatch({
            type: 'SUPPLIERS:REJECT_ADD:SUCCESS',
            payload: { requestId, type: 'suppliersAdditionRequests' }
          });
        }
      })
      .catch((res) => {
        dispatch({
          type: 'SUPPLIERS:REJECT_ADD:FAILURE',
          payload: { error: res.error }
        });
        console.error(res);
      });

export const getRequests = () => (dispatch) => {
  dispatch({ type: 'SUPPLIERS:GET_REQUESTS:REQUEST' });
  fetch('/api/suppliers/requests', {
    credentials: 'include',
    method: 'GET'
  })
    .then((res) => res.json())
    .then((responseJson) => {
      dispatch({
        type: 'SUPPLIERS:GET_REQUESTS:SUCCESS',
        payload: responseJson
      });
    })
    .catch((res) => {
      dispatch({
        type: 'SUPPLIERS:GET_REQUESTS:FAILURE',
        payload: { error: res.error }
      });
      console.error(res);
    });
};

export const deleteRequests = (requests) => (dispatch) => {
  dispatch({ type: 'SUPPLIERS:DELETE_REQUESTS:REQUEST' });
  return fetch('/api/suppliers/requests/delete', {
    credentials: 'include',
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ requests })
  })
    .then((res) => res.json())
    .then(() => {
      dispatch({
        type: 'SUPPLIERS:DELETE_REQUESTS:SUCCESS',
        payload: { requests }
      });
    })
    .catch((res) => {
      dispatch({
        type: 'SUPPLIERS:DELETE_REQUESTS:FAILURE',
        payload: { error: res.error }
      });
      console.error(res);
    });
};

export const toggleLink = (isTokenOn) => (dispatch) => {
  dispatch({ type: 'SUPPLIERS:TOGGLE_LINK:REQUEST' });
  return (
    fetch('/api/suppliers/toggle_link', {
      credentials: 'include',
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ isTokenOn })
    })
      .then((res) => res.json())
      // eslint-disable-next-line no-shadow
      .then(({ isTokenOn }) => {
        dispatch({
          type: 'SUPPLIERS:TOGGLE_LINK:SUCCESS',
          payload: { isTokenOn }
        });
      })
      .catch((res) => {
        dispatch({
          type: 'SUPPLIERS:TOGGLE_LINK:FAILURE',
          payload: { error: res.error }
        });
        console.error(res);
      })
  );
};

export const getInvites = () => (dispatch) => {
  dispatch({ type: 'SUPPLIERS:GET_INVITES:REQUEST' });
  fetch('/api/suppliers/invites', {
    credentials: 'include',
    method: 'GET'
  })
    .then((res) => res.json())
    .then((responseJson) => {
      dispatch({
        type: 'SUPPLIERS:GET_INVITES:SUCCESS',
        payload: responseJson
      });
    })
    .catch((res) => {
      dispatch({
        type: 'SUPPLIERS:GET_INVITES:FAILURE',
        payload: { error: res.error }
      });
      console.error(res);
    });
};

export const deleteInvites = (invites) => (dispatch) => {
  dispatch({ type: 'SUPPLIERS:DELETE_INVITES:REQUEST' });
  return fetch('/api/suppliers/invites/delete', {
    credentials: 'include',
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ invites })
  })
    .then((res) => res.json())
    .then(() => {
      dispatch({
        type: 'SUPPLIERS:DELETE_INVITES:SUCCESS',
        payload: { invites }
      });
    })
    .catch((res) => {
      dispatch({
        type: 'SUPPLIERS:DELETE_INVITES:FAILURE',
        payload: { error: res.error }
      });
      console.error(res);
    });
};

export const addSupplier =
  ({ email, companyName, groupId, responsibleUser, language }) =>
  async (dispatch) => {
    dispatch({ type: 'SUPPLIERS:ADD:REQUEST' });
    try {
      const res = await fetch('/api/suppliers/add', {
        credentials: 'include',
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          email,
          companyName,
          groupId,
          language,
          responsibleUser
        })
      });
      const json = await res.json();
      if (res.status === 208) {
        dispatch(setWarningMessage({ key: json.msg, params: { email } }));
        return;
      }
      if (res.status === 409) {
        dispatch(
          setErrorMessage({ key: json.msg, params: { time: json.time } })
        );
        return;
      }
      if (json.isExists) {
        dispatch(
          setSuccessMessage({ key: 'supplier_request_send', params: { email } })
        );
        return;
      }
      if (json.inviteAdded) {
        dispatch(
          setSuccessMessage({
            key: 'Invite already sent to email',
            params: { email }
          })
        );
        return;
      }
      if (!json.isExists) {
        dispatch(
          setSuccessMessage({
            key: 'Invite was sent to email',
            params: { email }
          })
        );
        return;
      }
      dispatch({ type: 'SUPPLIERS:ADD:SUCCESS', payload: json });
    } catch (err) {
      dispatch({ type: 'SUPPLIERS:ADD:FAILURE', payload: { err } });
      console.error(err);
    }
  };

export const deleteSuppliers = (suppliers) => (dispatch) => {
  dispatch({ type: 'SUPPLIERS:DELETE:REQUEST' });
  return fetch('/api/suppliers/delete', {
    credentials: 'include',
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ suppliers })
  })
    .then((res) => res.json())
    .then((responseJson) => {
      dispatch({ type: 'SUPPLIERS:DELETE:SUCCESS', payload: responseJson });
    })
    .catch((res) => {
      dispatch({
        type: 'SUPPLIERS:DELETE:FAILURE',
        payload: { error: res.error }
      });
      console.error(res);
    });
};

// eslint-disable-next-line consistent-return
export const createSuppliersGroup = (name) => async (dispatch) => {
  try {
    dispatch({ type: 'SUPPLIERS:CREATE_GROUP:REQUEST' });
    const res = await fetch('/api/suppliers/groups', {
      credentials: 'include',
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ name: name.trim() })
    });
    const json = await res.json();
    if (json.error) {
      return dispatch(setErrorMessage({ key: json.error }));
    }
    dispatch({ type: 'SUPPLIERS:CREATE_GROUP:SUCCESS', payload: json });
  } catch (res) {
    dispatch({
      type: 'SUPPLIERS:CREATE_GROUP:FAILURE',
      payload: { error: res.error }
    });
    console.error(res);
  }
};

export const resendInvite = (supplierEmail) => (dispatch) => {
  dispatch({ type: 'SUPPLIERS:RESEND_INVITE:REQUEST' });
  return fetch('/api/suppliers/invite', {
    credentials: 'include',
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      data: { email: supplierEmail, language: getBrowserLanguage() }
    })
  })
    .then((res) => res.json())
    .then(({ email }) => {
      dispatch(
        setSuccessMessage({
          key: 'Invite was sent to email',
          params: { email }
        })
      );
      dispatch({ type: 'SUPPLIERS:RESEND_INVITE:SUCCESS', payload: { email } });
    })
    .catch((res) => {
      dispatch({
        type: 'SUPPLIERS:RESEND_INVITE:FAILURE',
        payload: { error: res.errors }
      });
      console.error(res);
    });
};

export const setSelectedSuppliers = (group, item) => ({
  type: 'SUPPLIERS:SELECT_SUPPLIERS',
  payload: { group, item }
});

export const setSelectedItem = (id) => ({
  type: 'SUPPLIERS:SELECT_ITEM',
  payload: { id }
});

export const setSelectedRequests = (id) => ({
  type: 'SUPPLIERS:SELECT_REQUEST',
  payload: { id }
});

// export const setSelectedGroup = group => ({ type: 'SUPPLIERS:SELECT_GROUP', payload: { group } });

export const clearSelectedGroup = () => ({
  type: 'SUPPLIERS:CLEAR_SELECT_GROUP'
});

export const deselectAllSuppliers = () => ({ type: 'DESELECT_ALL_SUPPLIERS' });

export const selectAll = (items) => ({
  type: 'SUPPLIERS:SELECT:ALL',
  payload: { items }
});

export const setSupplierResponsibleUser =
  (supplierId, userId) => (dispatch) => {
    dispatch({ type: 'SUPPLIERS:SET_RESPONSIBLE:REQUEST' });
    return fetch('/api/suppliers/responsible', {
      credentials: 'include',
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ supplierId, userId })
    })
      .then((res) => res.json())
      .then(() => {
        dispatch({
          type: 'SUPPLIERS:SET_RESPONSIBLE:SUCCESS',
          payload: { supplierId, userId }
        });
      })
      .catch((res) => {
        dispatch({
          type: 'SUPPLIERS:SET_RESPONSIBLE:FAILURE',
          payload: { error: res.error }
        });
        console.error(res);
      });
  };

export const getToken = () => (dispatch) =>
  request
    .get('/api/suppliers/token')
    .then((payload) => {
      dispatch({ type: 'SUPPLIERS:SET_TOKEN', payload });
      return payload;
    })
    .catch((err) => {
      console.error(err);
      dispatch({ type: 'SUPPLIERS:CLEAR_TOKEN' });
    });

export const refreshToken = () => (dispatch) =>
  request
    .post('/api/suppliers/refresh_token', {
      credentials: 'include',
      method: 'POST',
      headers: { 'Content-Type': 'application/json' }
    })
    .then((payload) => {
      dispatch({ type: 'SUPPLIERS:SET_TOKEN', payload });
      return payload;
    })
    .catch((err) => {
      console.error(err);
      dispatch({ type: 'SUPPLIERS:CLEAR_TOKEN' });
    });

export const updateSuppliers = (payload) => (dispatch) =>
  dispatch({ type: 'SUPPLIERS:UPDATE_DATA', payload });
