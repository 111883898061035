import { connect } from 'react-redux';

import SingleProductMediaModal from './SingleProductMediaModal';
import * as modalActionCreators from '../../../../../action-creators/modal';
import * as mediaActionCreators from '../../../../../action-creators/product/media';
import * as productActionCreators from '../../../../../action-creators/product';
import * as messageActions from '../../../../../action-creators/message';
import * as catalogActions from '../../../../../action-creators/catalog/publish';
import * as servicesAction from '../../../../../action-creators/services';

const mapStateToProps = (state) => {
  const user = state.getIn(['user', 'user']);
  const media = state.getIn(['product', 'item', 'media', 'video']);
  const productId = state.getIn(['product', 'item', 'id']);
  const catalogId = state.getIn(['product', 'item', 'attributes', 'catalogId']);
  const validator = state.get('validator');
  const errors = validator && validator.get('invalidFields');
  const uploadDisabled =
    !(media && media.get('src')) || (errors && errors.has('src'));
  return {
    user,
    productId,
    catalogId,
    media,
    errors,
    uploadDisabled,
    type: 'video',
    titleKey: 'New video',
    text: 'Enter URL to YouTube video',
    placeholderKey: 'https://youtube.com/example',
    tableStyle: { width: '100%' },
    imageContainerStyle: { width: '70px' },
    imageStyle: { height: '70px' }
  };
};

export default connect(mapStateToProps, {
  ...modalActionCreators,
  ...mediaActionCreators,
  getProduct: productActionCreators.getProduct,
  uploadProductMedia: mediaActionCreators.uploadProductVideo,
  setSuccessMessage: messageActions.setSuccessMessage,
  publishCatalog: catalogActions.publishCatalog,
  gaSend: servicesAction.gaSend
})(SingleProductMediaModal);
