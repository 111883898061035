import React from 'react';
import { useTranslate } from '@hooks';
import { Typography } from '@link/react-components';
import classes from './NoResult.module.css';

export function NoResult() {
  const t = useTranslate();

  return (
    <div className={classes.container}>
      <div className={classes.content}>
        <Typography variant="body1Med" className={classes.text}>
          {t('modules.noResult.text')}
        </Typography>
      </div>
    </div>
  );
}
