import React from 'react';

/* eslint-disable max-len */
const IconMessage = ({ width = 34, height = 34 }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 34 34"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      className="cws-icon-main-fill"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20 23H26V8H8V23H14L17 26L20 23ZM17 24.5858L19.5858 22H25V9H9V22H14.4142L17 24.5858Z"
      fill="#767B92"
    />
    <path
      className="cws-icon-main-fill"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.5 16C12.7761 16 13 15.7761 13 15.5C13 15.2239 12.7761 15 12.5 15C12.2239 15 12 15.2239 12 15.5C12 15.7761 12.2239 16 12.5 16ZM12.5 17C13.3284 17 14 16.3284 14 15.5C14 14.6716 13.3284 14 12.5 14C11.6716 14 11 14.6716 11 15.5C11 16.3284 11.6716 17 12.5 17Z"
      fill="#767B92"
    />
    <path
      className="cws-icon-main-fill"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17 16C17.2761 16 17.5 15.7761 17.5 15.5C17.5 15.2239 17.2761 15 17 15C16.7239 15 16.5 15.2239 16.5 15.5C16.5 15.7761 16.7239 16 17 16ZM17 17C17.8284 17 18.5 16.3284 18.5 15.5C18.5 14.6716 17.8284 14 17 14C16.1716 14 15.5 14.6716 15.5 15.5C15.5 16.3284 16.1716 17 17 17Z"
      fill="#767B92"
    />
    <path
      className="cws-icon-main-fill"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M21.5 16C21.7761 16 22 15.7761 22 15.5C22 15.2239 21.7761 15 21.5 15C21.2239 15 21 15.2239 21 15.5C21 15.7761 21.2239 16 21.5 16ZM21.5 17C22.3284 17 23 16.3284 23 15.5C23 14.6716 22.3284 14 21.5 14C20.6716 14 20 14.6716 20 15.5C20 16.3284 20.6716 17 21.5 17Z"
      fill="#767B92"
    />
  </svg>
);
/* eslint-enable max-len */

export default IconMessage;
