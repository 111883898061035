import React from 'react';
import Icon, { Icons } from '../../../icons';

function Tag({ option, onDelete, disabled }) {
  if (!option) {
    return null;
  }

  return (
    <div className="cmulti-select-tag-wrapper">
      <div className="cmulti-select-tag">{option.label}</div>
      {!disabled && (
        <div onClick={onDelete(option.value)} style={{ display: 'flex' }}>
          <Icon icon={Icons.Cross} size={12} />
        </div>
      )}
    </div>
  );
}

export default Tag;
