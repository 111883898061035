import URLSearchParams from 'url-search-params';
import request from '../lib/request';
import {
  CompanyInfoDto,
  CompanyPurchaseRequestDTO,
  EmployeeDto,
  SaveNoteDto
} from '../DTOs';

class CompanyService {
  #baseUrl = '/api/companies';

  async getCompanies({ offset = 0, limit = 20, search = '' } = {}) {
    const searchParams = new URLSearchParams({ offset, limit, search });
    return request.get(`${this.#baseUrl}/list/?${searchParams}`);
  }

  async getCompanyInfo(companyId) {
    const result = await request.get(`${this.#baseUrl}/${companyId}/info`);

    if (!result) return null;

    return new CompanyInfoDto(result);
  }

  async getEmployees(companyId, { offset = 0, limit = 20 } = {}) {
    const searchParams = new URLSearchParams({ offset, limit });

    const employees = await request.get(
      `${this.#baseUrl}/${companyId}/employees?${searchParams}`
    );

    return employees.map((employee) => new EmployeeDto(employee));
  }

  async getPurchaseRequests(companyId, { offset = 0, limit = 20 } = {}) {
    const searchParams = new URLSearchParams({ offset, limit });

    const requests = await request.get(
      `${this.#baseUrl}/${companyId}/requests?${searchParams}`
    );

    return requests.map((request) => new CompanyPurchaseRequestDTO(request));
  }

  async saveNote(companyId, description) {
    const savedNote = await request.post(
      `${this.#baseUrl}/${companyId}/save-note`,
      { description }
    );

    return new SaveNoteDto(savedNote);
  }
}

export const companyService = new CompanyService();
