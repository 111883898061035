import React, { Fragment } from 'react';
import linkifyHtml from 'linkifyjs/html';
import DOMPurify from 'dompurify';
import PropTypes from 'prop-types';
import { prop, pipe, isNil } from 'ramda';
// import { toImage } from 'emoji-toolkit';
import Files from './files';
import { linkToProfileHandler, replaceEmojiToMarkup } from '../../../utils';

const sanitizeDOM = (text) =>
  DOMPurify.sanitize(text, {
    ADD_ATTR: ['target']
  });

const prepareMsg = pipe(
  prop('html'),
  linkifyHtml,
  replaceEmojiToMarkup,
  sanitizeDOM
);

const renderMessage = (message, showProfile) => {
  if (typeof message.html === 'string') {
    return (
      <div
        dangerouslySetInnerHTML={{ __html: prepareMsg(message) }}
        onClick={(event) => linkToProfileHandler(event, showProfile)}
      />
    );
  }
  // Type message answerphone render
  if (React.isValidElement(message.msg)) {
    return message.msg;
  }
  return message.html;
};

const CWMUMMsg = ({
  chat,
  message,
  isReadMsg,
  isOwnMsg,
  showProfile,
  theme = 'main-chat',
  onLoadedSingleImage,
  filesData
}) => (
  <>
    <div className="cwm-um-msg">{renderMessage(message, showProfile)}</div>
    {!isNil(filesData) && (
      <Files
        chat={chat}
        isOwnMsg={isOwnMsg}
        isReadMsg={isReadMsg}
        message={message}
        theme={theme}
        onLoadedSingleImage={onLoadedSingleImage}
        filesData={filesData}
      />
    )}
  </>
);

CWMUMMsg.propTypes = {
  chat: PropTypes.object.isRequired,
  message: PropTypes.object.isRequired,
  isOwnMsg: PropTypes.bool
};

CWMUMMsg.defaultProps = {
  isOwnMsg: false
};

export default CWMUMMsg;
