import { Map, List, fromJS } from 'immutable';
import translateCatalogReducer from './catalog/translate';
import catalogAccessReducer from './catalog/access';
import catalogPriceCategoryReducer from './catalog/priceCategory';

const loadItem = (state, item) => state.set('item', fromJS(item));

const clearCatalog = (state) => state.clear();

const showHideDeleteConfirmation = (state, flag) =>
  state.set('deleteConfirmation', flag);

const setCatalogField = (state, field, value) =>
  state.update('item', (item) => item.set(field, fromJS(value)));

const trimCatalogField = (state, field) =>
  state.updateIn(['item', field], (value) => (value || '').trim());

const setImportInfo = (state, { catalogs }) =>
  state.set('catalogs', fromJS(catalogs)).set('infoLoaded', true);

const availableLanguagesLoad = (state, languages) =>
  state.set('languages', fromJS(languages));

const initState = Map({
  langs: List(),
  importInfo: Map(),
  infoLoaded: false,
  productInfo: Map(),
  selectedFeatures: List()
});

const setProductInfo = (data, { product }) =>
  data.set('productInfo', fromJS(product));

const setFeatures = (data, { features, size, limit, offset }) =>
  data
    .set('features', fromJS(features))
    .set('size', +size)
    .set('limit', +limit)
    .set('offset', +offset);

const selectFeature = (data, { item }) =>
  data.update('selectedFeatures', (selected) => {
    const fItem = selected.find(
      (i) => i.get('name') === item.name && i.get('unit') === item.unit
    );
    if (fItem) {
      return selected.filter(
        (i) => i.get('name') !== item.name || i.get('unit') !== item.unit
      );
    }
    return selected.push(fromJS(item));
  });

const updateSelectedFeatureValue = (data, { name, unit, value }) =>
  data.update('selectedFeatures', (selected) => {
    const index = selected.findIndex(
      (i) => i.get('name') === name && i.get('unit') === unit
    );
    return selected.setIn([index, 'value'], value);
  });

const clearSelectedFeatures = (data) => data.set('selectedFeatures', List());

const catalogReducer = (state = initState, action) => {
  switch (action.type) {
    case 'CATALOG_LOAD':
      return loadItem(state, action.item);
    case 'CATALOG_CLEAR':
      return clearCatalog(state);
    case 'CATALOG_DELETE_CONFIRMATION':
      return showHideDeleteConfirmation(state, action.flag);
    case 'CATALOG_SET_FIELD':
      return setCatalogField(state, action.field, action.value);
    case 'CATALOG_TRIM_FIELD':
      return trimCatalogField(state, action.field);
    case 'AVAILABLE_LANGUAGES_LOAD':
      return availableLanguagesLoad(state, action.languages);
    case 'CATALOG:IMPORT_INFO:SUCCESS':
      return setImportInfo(state, action.payload);
    case 'PRICE:GET_PRICE_PRODUCT:SUCCESS':
    case 'ORDER:GET_ORDER_PRODUCT:SUCCESS':
    case 'PRODUCT:GET_PUBLISHED_PRODUCT:SUCCESS':
      return setProductInfo(state, action.payload);
    case 'CATALOG:GET_FEATURES:SUCCESS':
      return setFeatures(state, action.payload);
    case 'CATALOG:SELECT_FEATURE':
      return selectFeature(state, action.payload);
    case 'CATALOG:UPDATE_SELECTED_FEATURE':
      return updateSelectedFeatureValue(state, action.payload);
    case 'CATALOG:CLEAR_SELECTED_FEATURES':
      return clearSelectedFeatures(state);
    default:
      return state
        .updateIn(['access'], (accessState) =>
          catalogAccessReducer(accessState, action)
        )
        .updateIn(['item', 'translateCatalog'], (translateCatalogState) =>
          translateCatalogReducer(translateCatalogState, action)
        )
        .update('priceCategory', (priceCategoryState) =>
          catalogPriceCategoryReducer(priceCategoryState, action)
        );
  }
};

export default catalogReducer;
