import React, { Component } from 'react';
import { translate } from 'react-i18next';
import { withRouter } from '../hoc/withRouter';
import MessengerOther from '../components/Layouts/MessengerOther';

import ResponsePurchaseRequest from '../components/PurchaseRequests/Responses/one';
import { MessengerWidgetWrapper } from '../components/Widgets/Messenger/MessengerWidget';
import { formatRequestNumber } from '../utils/utils';

class MResponsePurchaseRequest extends Component {
  get breadcrumbs() {
    const { t, history, match } = this.props;
    return [
      {
        name: t('Purchase requests'),
        element: (
          <a onClick={() => history.push({ pathname: '/requests/my' })}>
            {t('Purchase requests')}
          </a>
        )
      },
      {
        name: `${t('response_to_request')} №${formatRequestNumber(
          match.params.requestId
        )}`
      }
    ];
  }

  get responseId() {
    const { responseId } = this.props.match.params;

    return +responseId;
  }

  render() {
    const { t } = this.props;

    return (
      <>
        <MessengerOther
          title={t('Purchase requests')}
          breadcrumbs={this.breadcrumbs}
          content={<ResponsePurchaseRequest responseId={this.responseId} />}
        />
        <MessengerWidgetWrapper />
      </>
    );
  }
}

export default withRouter(
  translate(['ui'], { wait: true })(MResponsePurchaseRequest)
);
