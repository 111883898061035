import React, { Component } from 'react';
import PropTypes from 'prop-types';
import block from 'bem-cn-lite';
import { translate } from 'react-i18next';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Modal, FormGroup, Button, Col, Row } from 'react-bootstrap';
import './styles/modal_theme_default.styl';

import { ModalContainer } from './elements';
import * as orderActions from '../../action-creators/orders';
import * as modalActions from '../../action-creators/modal';

const b = block('modal-container');

class ModalCopyProduct extends Component {
  constructor(props) {
    super(props);
    this.state = {
      replace: true
    };
  }

  render() {
    const {
      t,
      hideModalDialog,
      supplierCompanyId,
      catalogId,
      categoryId,
      products,
      createOrReplace
    } = this.props;

    return (
      <Modal id="inviteEmployee" onHide={() => hideModalDialog()} show>
        <ModalContainer mods={{ theme: 'default' }}>
          <Modal.Header>
            <Modal.Title>
              {t('copy_product_title')}
              <span
                className="modal-header-cross pull-right"
                onClick={() => hideModalDialog()}
              />
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Col md={12}>
              <div className="">{t('copy_or_replace_product_text')}</div>
            </Col>
            <Col md={6} style={{ marginLeft: 10, marginTop: 10 }}>
              <FormGroup controlId="langs">
                <Row style={{ paddingBottom: 10 }}>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <span
                      className={b('radio-btn', { set: this.state.replace })}
                      onClick={() => this.setState({ replace: true })}
                    />
                    <span className="modal-checkbox-label">
                      {t('replace_product')}
                    </span>
                  </div>
                </Row>
                <Row>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <span
                      className={b('radio-btn', { set: !this.state.replace })}
                      onClick={() => this.setState({ replace: false })}
                    />
                    <span className="modal-checkbox-label">
                      {t('create_product')}
                    </span>
                  </div>
                </Row>
              </FormGroup>
            </Col>
          </Modal.Body>
          <Modal.Footer>
            <Button
              id="cancel"
              className="pull-left"
              onClick={() => {
                hideModalDialog();
              }}>
              {t('Cancel')}
            </Button>
            <Button
              id="CreateOrReplaceProductButton"
              bsStyle="primary"
              onClick={async () => {
                await createOrReplace(
                  this.state.replace,
                  products,
                  catalogId,
                  categoryId,
                  supplierCompanyId
                );
                hideModalDialog();
              }}>
              {t('Confirm')}
            </Button>
          </Modal.Footer>
        </ModalContainer>
      </Modal>
    );
  }
}

ModalCopyProduct.propTypes = {
  t: PropTypes.func.isRequired
};

ModalCopyProduct.childContextTypes = {
  b: PropTypes.func
};

export default connect(
  (state) => ({
    company: state.get('company'),
    supplierCompanyId: state.getIn(['orders', 'order', 'supplier'])
  }),
  (dispatch) =>
    bindActionCreators(
      {
        hideModalDialog: modalActions.hideModalDialog,
        createOrReplace: orderActions.createOrReplace
      },
      dispatch
    )
)(translate(['ui'], { wait: true })(ModalCopyProduct));
