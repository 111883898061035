import React from 'react';
import { translate } from 'react-i18next';

const PopupContent = ({ goOnPage, t = () => {}, compareResponses }) => {
  const filteredLinks = Object.keys(compareResponses).filter(
    (reqId) => compareResponses[reqId].list.length > 0
  );

  return (
    <div className="compare-popup">
      {filteredLinks.map((reqId) => (
        <div
          className="compare-popup-item compare-popup-links"
          onClick={goOnPage(`/requests/compare/${reqId}`)}>
          {`${t('comparing_responses')} №${reqId} ${
            compareResponses[reqId].name
          }`}
        </div>
      ))}
    </div>
  );
};
export default translate(['ui'], { wait: true })(PopupContent);
