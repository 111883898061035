export const ORDERS_TO_SUPPLIERS_SET = 'ORDERS_TO_SUPPLIERS : SET';
export const ORDERS_TO_SUPPLIERS_SELECT_TOGGLE =
  'ORDERS_TO_SUPPLIERS : SELECT_TOGGLE';
export const ORDERS_TO_SUPPLIERS_SELECT = 'ORDERS_TO_SUPPLIERS : SELECT';
export const ORDERS_TO_SUPPLIERS_FILTER_SELECT_TOGGLE =
  'ORDERS_TO_SUPPLIERS : FILTER_SELECT_TOGGLE';
export const ORDERS_FROM_CUSTOMERS_FILTER_SELECT_TOGGLE =
  'ORDERS_FROM_CUSTOMERS : FILTER_SELECT_TOGGLE';
export const ORDERS_TO_SUPPLIERS_UNSELECT = 'ORDERS_TO_SUPPLIERS : UNSELECT';
export const ORDERS_TO_SUPPLIERS_SET_FILTER =
  'ORDERS_TO_SUPPLIERS : SET FILTER';
export const ORDERS_TO_SUPPLIERS_CONFIRM_DELETE =
  'ORDERS_TO_SUPPLIERS : CONFIRM_DELETE';
export const ORDERS_TO_SUPPLIERS_CONFIRM_COPY =
  'ORDERS_TO_SUPPLIERS : CONFIRM_COPY';
export const ORDERS_TO_SUPPLIERS_CLEAR = 'ORDERS_TO_SUPPLIERS : CLEAR';

export const ORDERS_TO_SUPPLIERS_GET = 'ORDERS_TO_SUPPLIERS : GET';
export const ORDERS_TO_SUPPLIERS_REMOVE = 'ORDERS_TO_SUPPLIERS : REMOVE';
export const ORDERS_TO_SUPPLIERS_COPY = 'ORDERS_TO_SUPPLIERS : COPY';
export const ORDERS_TO_SUPPLIERS_CUSTOMER_VIEWED_ORDER =
  'ORDERS_TO_SUPPLIERS : CUSTOMER VIEWED ORDER';

export const ORDERS_ADD = 'ORDERS:ADD';
export const ORDERS_CLEAR = 'ORDERS:CLEAR';
export const ORDERS_TOGGLE_ALL = 'ORDERS:TOGGLE_ALL';
export const ORDERS_TOGGLE_ONE = 'ORDERS:TOGGLE_ONE';
export const ORDERS_CLEAR_SELECT_LIST = 'ORDERS:CLEAR_SELECT_LIST';
