import React from 'react';
import cn from 'classnames';
import { Typography } from '@link/react-components';

export function Counter({
  value,
  total,
  className,
  variant,
  leftClass,
  leftVariant,
  rightVariant,
  rightClass
}) {
  return (
    <div className={cn(className)}>
      <Typography className={leftClass} variant={leftVariant || variant}>
        {value}
      </Typography>
      /
      <Typography className={rightClass} variant={rightVariant || variant}>
        {total}
      </Typography>
    </div>
  );
}
