import React from 'react';
import { translate } from 'react-i18next';
import './RespondTitleStatus.css';

const RespondTitleStatus = ({ t, show, figureColor, statusName }) => {
  if (!show || statusName === 'none') return null;

  return (
    <div className="respond-title-status">
      <div
        className="respond-title-status__status-figure"
        style={{ backgroundColor: figureColor }}
      />
      <div className="respond-title-status__status-name">
        {t(`respondStatus.${statusName}`)}
      </div>
    </div>
  );
};

export default translate()(RespondTitleStatus);
