import React from 'react';
import PropTypes from 'prop-types';
import { Interpolate, translate } from 'react-i18next';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
  handleDashboardChannel,
  handleDashboardTopic,
  handleDashboardDialog
} from '../../modules/dashboard/wrapper';

const Archived = ({
  t,
  tkey,
  chat,
  handlerDialog,
  handlerChannel,
  handlerTopic
}) => {
  const unarchive = () => {
    if (chat.type === 'Dialog') handlerDialog('unarchive', chat);
    if (chat.type === 'Channel') handlerChannel('unarchive', chat);
    if (chat.type === 'Topic') handlerTopic('unarchive', chat);
  };

  return (
    <div className="chat-not-my-contact-notify-container">
      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
      <Interpolate
        i18nKey={tkey}
        action={<a onClick={unarchive}>{t('return_from_archive')}</a>}
      />
    </div>
  );
};

Archived.propTypes = {
  t: PropTypes.func.isRequired,
  tkey: PropTypes.string
};

Archived.defaultProps = {
  tkey: 'chat_archived'
};

export default connect(null, (dispatch) =>
  bindActionCreators(
    {
      handlerDialog: handleDashboardDialog,
      handlerChannel: handleDashboardChannel,
      handlerTopic: handleDashboardTopic
    },
    dispatch
  )
)(translate()(Archived));
