import { fromJS } from 'immutable';

import {
  initState,
  clearAllStocks,
  setAllStocks,
  selectStock,
  sortStocks,
  deleteStock,
  deselectStock,
  updateField,
  setMoreInfo,
  clearForm,
  setListOfErrors,
  removeErrorByField,
  setDataInForm,
  setDefaultValuesToForm,
  setProducts,
  clearProducts,
  updateCountProduct,
  setFoundProducts,
  setQueryFoundProduct,
  clearFoundProducts,
  setImportStatusError,
  setImportStatusLoading,
  setImportStatusSuccess,
  updateUnitsProduct,
  updateReservedProduct,
  updateHandReservedProduct,
  updateReceiptDateProduct,
  setCategories,
  selectCategory,
  selectProducts,
  deleteProducts,
  clearSelectCategory,
  deleteCategories,
  clearFormCreate,
  selectAllProducts,
  selectFoundProducts,
  selectAllFoundProducts,
  setPropsForFoundProducts,
  clearImportStatusError,
  setSelectCategory,
  updateCountProducts,
  updateReceiptDateProducts,
  selectProductList,
  unselectProductList,
  toggleFilterSelected,
  selectSearchProducts,
  unselectSearchProducts,
  toggleFilterSelectedFound,
  clearSelectFoundProducts
} from '../work-with-data/stock';

const setUnitCodes = (data, { unitsCodes }) =>
  data.set('unitCodes', fromJS(unitsCodes));

const setTransferValue = (data, { sku, value }) =>
  data.setIn(['products', sku, 'toTransfer'], value);

const setAttrsFilter = (data, { excludeAttrs }) =>
  data.set('excludeAttrs', fromJS(excludeAttrs));

const unblockItems = (data, { ids = [] }) =>
  data.update('items', (items) => {
    if (!items) return items;
    ids.forEach((id) => {
      const index = items.findIndex((i) => i.get('id') === +id);
      // eslint-disable-next-line no-param-reassign
      items = items.setIn([index, 'blocked'], false);
    });
    return items;
  });

const setFilterField = (data, { name, value }) => {
  const index = data
    .get('filterValues')
    .findIndex((filter) => filter.get('fieldName') === name);
  const filter = data.getIn(['filterValues', index]).toJS();
  return data.setIn(['filterValues', index], fromJS({ ...filter, ...value }));
};

const applyFilter = (data, { filters }) =>
  data.set('appliedFilterValues', filters);

const setUnits = (data, { units }) => data.set('units', fromJS(units));

export default (state = initState(), { type, payload }) => {
  let newState;

  switch (type) {
    case 'STOCKS:FILTER_SELECT_TOGGLE':
      return toggleFilterSelected(state);
    case 'STOCKS:FILTER_SELECT_FOUND_TOGGLE':
      return toggleFilterSelectedFound(state);
    case 'STOCKS:GET_ALL_STOCKS:SUCCESS':
      return setAllStocks(state, payload);
    case 'STOCKS:CLEAR':
      return clearAllStocks();
    case 'STOCKS:CLEAR_SELECT_FOUND_PRODUCTS':
      return clearSelectFoundProducts(state);
    case 'STOCKS:CLEAR_FORM':
      return clearForm(state);
    case 'STOCKS:CLEAR_FORM_CREATE':
      return clearFormCreate(state);
    case 'STOCKS:SELECT':
      return selectStock(state, payload);
    case 'STOCKS:SORT':
      return sortStocks(state, payload);
    case 'STOCKS:DELETE:SUCCESS': // eslint-disable-line
      // TODO: moved deselectStock to component after deleteStock
      newState = deselectStock(state, payload);
      return deleteStock(newState, payload);
    case 'STOCKS:FORM_ADD:UPDATE_FIELD':
      return updateField(state, payload);
    case 'STOCKS:FORM_ADD:REMOVE_ERROR':
      return removeErrorByField(state, payload);
    case 'STOCKS:ADD:ERRORS':
    case 'STOCKS:UPDATE:ERRORS':
      return setListOfErrors(state, payload);
    case 'STOCKS:GET_MORE_INFO:SUCCESS':
      return setMoreInfo(state, payload);
    case 'STOCKS:SET_DEFAULT_VALUES_TO_FORM':
      return setDefaultValuesToForm(state);

    case 'STOCKS:GET_STOCK:SUCCESS':
      return setDataInForm(state, payload);

    case 'STOCKS:LOAD_CATEGORIES_OF_STOCK:SUCCESS':
      return setCategories(state, payload);

    case 'STOCKS:LOAD_PRODUCTS_OF_STOCK:SUCCESS':
      return setProducts(state, payload);

    case 'STOCKS:CLEAR_PRODUCTS_OF_STOCK':
      return clearProducts(state);

    case 'STOCKS:UPDATE_PRODUCTS_OF_STOCK:SUCCESS':
      return updateCountProduct(state, payload);
    case 'STOCKS:UPDATE_FEW_PRODUCTS_OF_STOCK:SUCCESS':
      return updateCountProducts(state, payload);
    case 'STOCKS:UPDATE_PRODUCTS_OF_STOCK_UNITS:SUCCESS':
      return updateUnitsProduct(state, payload);
    case 'STOCKS:UPDATE_PRODUCTS_OF_STOCK_RESERVED:SUCCESS':
      return updateReservedProduct(state, payload);
    case 'STOCKS:UPDATE_PRODUCTS_OF_STOCK_HAND_RESERVED:SUCCESS':
      return updateHandReservedProduct(state, payload);
    case 'STOCKS:UPDATE_RECEIPT_DATE:SUCCESS':
      return updateReceiptDateProduct(state, payload);
    case 'STOCKS:UPDATE_FEW_RECEIPT_DATE:SUCCESS':
      return updateReceiptDateProducts(state, payload);

    case 'STOCKS:CLEAR_FOUND_PRODUCTS_OF_STOCK':
      return clearFoundProducts(state);

    case 'STOCKS:SEARCH_PRODUCT:REQUEST':
      return setQueryFoundProduct(state, payload);
    case 'STOCKS:SEARCH_PRODUCT:SUCCESS':
      return setFoundProducts(state, payload);
    case 'STOCKS:SELECT_SEARCH_PRODUCT':
      return selectFoundProducts(state, payload);
    case 'STOCKS:ALL_SELECT_SEARCH_PRODUCT':
      return selectAllFoundProducts(state, payload);
    case 'STOCKS:SELECT_SEARCH_PRODUCTS':
      return selectSearchProducts(state, payload);
    case 'STOCKS:UNSELECT_SEARCH_PRODUCTS':
      return unselectSearchProducts(state, payload);
    case 'STOCKS:SET_PROPS_FOR_SEARCH_PRODUCT':
      return setPropsForFoundProducts(state, payload);

    case 'STOCKS:IMPORT_EXCEL:FAILURE':
      return setImportStatusError(state, payload);
    case 'STOCKS:IMPORT_EXCEL:REQUEST':
      return setImportStatusLoading(state, payload);
    case 'STOCKS:IMPORT_EXCEL:SUCCESS':
      return setImportStatusSuccess(state, payload);
    case 'STOCKS:IMPORT_EXCEL_CLEAR_ERROR':
      return clearImportStatusError(state);

    case 'STOCKS:SELECT_PRODUCT':
      return selectProducts(state, payload);
    case 'STOCKS:All_SELECT_PRODUCT':
      return selectAllProducts(state);

    case 'STOCKS:SELECT_PRODUCTS':
      return selectProductList(state, payload);
    case 'STOCKS:UNSELECT_PRODUCTS':
      return unselectProductList(state, payload);

    case 'STOCKS:SELECT_CATEGORY':
      return selectCategory(state, payload);
    case 'STOCKS:SET_SELECT_CATEGORY':
      return setSelectCategory(state, payload);
    case 'STOCKS:CLEAR_SELECT_CATEGORIES':
      return clearSelectCategory(state);

    case 'STOCKS:DELETE_PRODUCTS:SUCCESS':
      return deleteProducts(state);
    case 'STOCKS:DELETE_CATEGORIES:SUCCESS':
      return deleteCategories(state);
    case 'STOCKS:LOAD_UNITS_CODES:SUCCESS':
      return setUnitCodes(state, payload);
    case 'STOCKS:SET_TRANSFER_VALUE':
      return setTransferValue(state, payload);
    case 'STOCKS:LOAD_ATTRS_FILTER:SUCCESS':
      return setAttrsFilter(state, payload);
    case 'STOCKS:UNBLOCK_ITEMS:SUCCESS':
      return unblockItems(state, payload);
    case 'STOCKS:SET_FILTER_FIELD':
      return setFilterField(state, payload);
    case 'STOCKS:APPLY_FILTER':
      return applyFilter(state, payload);
    case 'STOCKS:GET_UNITS:SUCCESS':
      return setUnits(state, payload);
    default:
      return state;
  }
};
