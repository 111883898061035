import React from 'react';
import Loader from '.';
import './style.styl';

const SectionLoader = () => (
  <div className="section-loader">
    <Loader />
  </div>
);

export default SectionLoader;
