import { List, fromJS } from 'immutable';

const initState = () => List();

const setEmployees = ({ users }) => fromJS(users);

const list = (state = initState(), { type, payload }) => {
  switch (type) {
    case 'ACCESS:SET_EMPLOYEES_TO_ACCESS:SUCCESS':
      return setEmployees(payload);
    case 'ACCESS:CLEAN':
      return initState();
    default:
      return state;
  }
};

export default list;
