import { Map, List, fromJS } from 'immutable';
import { selectCompany, clearSelected } from '../work-with-data/user';

const initState = () =>
  Map({
    user: Map({
      isUploaded: false,
      permissions: Map(),
      type: 'none',
      emailNotifications: Map({
        price: List()
      }),
      partnerRequestModalShowed: false
    }),
    selectedCompany: Map()
  });

const setCurrentUser = (state, user) =>
  state
    .remove('user')
    .set('user', fromJS(user))
    .setIn(['user', 'isUploaded'], true);

const setIsCurrentUserUploaded = (state) =>
  state.setIn(['user', 'isUploaded'], true);

const updateUserCompany = (state, company) => state.set('company', company);

const setUserLanguage = (state, { langCode }) =>
  state.setIn(['user', 'language'], langCode);

const setUserField = (state, field, value) =>
  state.setIn(['user', field], fromJS(value));

const toggleChatEmailNotification = (state) =>
  state.setIn(
    ['user', 'emailNotifications', 'chat'],
    !state.getIn(['user', 'emailNotifications', 'chat'])
  );

const toggleChatSoundNotification = (state, payload) =>
  state.setIn(['user', 'soundNotifications'], payload);

const togglePriceEmailNotification = (state, { settings }) =>
  state.setIn(['user', 'emailNotifications', 'price'], fromJS(settings));

const disableInitialHelp = (state) => state.setIn(['user', 'new'], false);

const disablePartnerInfo = (state) =>
  state.setIn(['user', 'partnerRequestModalShowed'], true);

const turnPurchaseRequestNotifications = (data) =>
  data
    .setIn(
      ['user', 'emailNotifications', 'purchaseRequests'],
      !data.getIn(['user', 'emailNotifications', 'purchaseRequests'])
    )
    .setIn(['user', 'requestNotificationsFirstEnable'], false);

const setPurchaseRequestNotificationsSettings = (data, { ids }) =>
  data.setIn(['user', 'purchaseRequestsNotifyCategories'], fromJS(ids));

export default (state = initState(), action) => {
  switch (action.type) {
    case 'USER:NOTIFICATIONS:TOGGLE_CHAT:SUCCESS':
      return toggleChatEmailNotification(state, action.payload);
    case 'USER:NOTIFICATIONS:TOGGLE_SOUND':
      return toggleChatSoundNotification(state, action.payload);
    case 'USER:NOTIFICATIONS:TOGGLE_PRICE_CHANGE:SUCCESS':
      return togglePriceEmailNotification(state, action.payload);
    case 'USER_SET_FIELD':
      return setUserField(state, action.field, action.value);
    case 'USER_UPDATE_COMPANY':
      return updateUserCompany(state, action.company);
    case 'USER_SET':
      return setCurrentUser(state, action.user);
    case 'USER_SET_IS_UPLOADED':
      return setIsCurrentUserUploaded(state);
    case 'USER:SELECT_COMPANY':
      return selectCompany(state, action.payload);
    case 'USER:CLEAR_SELECTED_COMPANY':
      return clearSelected(state, action.payload);
    case 'USER:CHANGE_LANGUAGE:REQUEST':
      return setUserLanguage(state, action.payload);
    case 'USER:INITIAL_HELP:DISABLE':
      return disableInitialHelp(state, action.payload);
    case 'USER:PARTNER_INFO:DISABLE':
      return disablePartnerInfo(state, action.payload);
    case 'PURCHASE_REQUEST:TURN_NOTIFICATIONS:SUCCESS':
      return turnPurchaseRequestNotifications(state);
    case 'PURCHASE_REQUEST:SETTINGS':
      return setPurchaseRequestNotificationsSettings(state, action.payload);
    default:
      return state;
  }
};
