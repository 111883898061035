/* eslint-disable max-len */
import React from 'react';

const GoodwixIcon = () => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      className="snm-icon__path"
      d="M9.44444 5.02222V12.2909H12.482C11.7162 13.2425 10.6186 13.8768 9.44444 14.0354V17.9738C11.0015 17.8416 12.482 17.2865 13.7327 16.3614C14.9835 15.4627 15.979 14.2205 16.6171 12.7931C16.7447 12.5288 16.8468 12.2381 16.9234 11.9738C16.9745 11.8152 17 11.683 17 11.5244V5.02222H9.44444Z"
      fill="#C6CAE3"
    />
    <path
      className="snm-icon__path"
      d="M7.98949 0C5.81982 0.185022 3.80331 1.21586 2.32282 2.85463C0.816818 4.54626 0 6.71366 0 8.98678C0 11.2599 0.816818 13.4537 2.32282 15.1189C3.80331 16.7841 5.81982 17.7885 7.98949 18V0Z"
      fill="#C6CAE3"
    />
  </svg>
);

export default GoodwixIcon;
