import fetch from 'isomorphic-fetch';
import req from '../utils/request';

import customHistory from '../customHistory';
import {
  setSuccessMessage,
  setWarningMessage,
  setErrorMessage,
  setInfoMessage
} from './message';
import { getCurrentLocation } from '../utils/location';

export const clearAllStocks = () => ({ type: 'STOCKS:CLEAR' });

export const clearForm = () => ({ type: 'STOCKS:CLEAR_FORM' });

export const clearFormCreate = () => ({ type: 'STOCKS:CLEAR_FORM_CREATE' });

export const toggleFilterSelected = () => ({
  type: 'STOCKS:FILTER_SELECT_TOGGLE'
});

export const toggleFilterSelectedFound = () => ({
  type: 'STOCKS:FILTER_SELECT_FOUND_TOGGLE'
});

export const selectStock = (id) => ({ type: 'STOCKS:SELECT', payload: { id } });

export const selectProduct = (id) => ({
  type: 'STOCKS:SELECT_PRODUCT',
  payload: { id }
});

export const selectProducts = (ids) => ({
  type: 'STOCKS:SELECT_PRODUCTS',
  payload: { ids }
});

export const unselectProducts = (ids) => ({
  type: 'STOCKS:UNSELECT_PRODUCTS',
  payload: { ids }
});

export const clearSelectFoundProducts = () => ({
  type: 'STOCKS:CLEAR_SELECT_FOUND_PRODUCTS',
  payload: {}
});

export const selectSearchProducts = (ids) => ({
  type: 'STOCKS:SELECT_SEARCH_PRODUCTS',
  payload: { ids }
});

export const unselectSearchProducts = (ids) => ({
  type: 'STOCKS:UNSELECT_SEARCH_PRODUCTS',
  payload: { ids }
});

export const selectAllProducts = () => ({
  type: 'STOCKS:All_SELECT_PRODUCT',
  payload: {}
});

export const importFailure = (stockId, errors) => ({
  type: 'STOCKS:IMPORT_EXCEL:FAILURE',
  payload: { stockId, errors }
});

export const selectCategory = (id) => ({
  type: 'STOCKS:SELECT_CATEGORY',
  payload: { id }
});

export const setSelectCategory = (ids) => ({
  type: 'STOCKS:SET_SELECT_CATEGORY',
  payload: { ids }
});

export const clearImportError = () => ({
  type: 'STOCKS:IMPORT_EXCEL_CLEAR_ERROR'
});

export const clearSelectCategory = () => ({
  type: 'STOCKS:CLEAR_SELECT_CATEGORIES',
  payload: {}
});

export const sortBy = (key) => ({ type: 'STOCKS:SORT', payload: { key } });

export const updateFieldStock = (field, value) => ({
  type: 'STOCKS:FORM_ADD:UPDATE_FIELD',
  payload: { field, value }
});

export const removeErrorByField = (field) => ({
  type: 'STOCKS:FORM_ADD:REMOVE_ERROR',
  payload: { field }
});

export const setDefaultValuesToForm = () => ({
  type: 'STOCKS:SET_DEFAULT_VALUES_TO_FORM'
});

export const clearProductsOfStock = () => ({
  type: 'STOCKS:CLEAR_PRODUCTS_OF_STOCK'
});

export const clearFoundProductsOfStock = () => ({
  type: 'STOCKS:CLEAR_FOUND_PRODUCTS_OF_STOCK'
});

export const getProductReservedInfo = (stockId, productId) => (dispatch) => {
  dispatch({ type: 'STOCKS:GET_RESERVED_INFO:REQUEST' });

  return fetch(`/api/stocks/${stockId}/product/${productId}/reserved`, {
    credentials: 'include',
    method: 'GET'
  })
    .then((res) => res.json())
    .then(({ stock, product, orders }) => {
      dispatch({
        type: 'STOCKS:GET_STOCK:SUCCESS',
        payload: { stockDetail: stock }
      });
      dispatch({ type: 'PRODUCT_LOAD', item: product });
      dispatch({
        type: 'ORDERS:TABLE_ORDERS_CUSTOMERS:LOAD:SUCCESS',
        payload: { orders }
      });
    })
    .catch((res) => {
      dispatch({
        type: 'STOCKS:GET_RESERVED_INFO:FAILURE',
        payload: { error: res.error }
      });
      console.error(res);
    });
};

export const getOrderReservedInfo =
  (orderId, query = '') =>
  (dispatch) => {
    dispatch({ type: 'STOCKS:GET_ORDER_RESERVED_INFO:REQUEST' });
    return fetch(`/api/stocks/order/${orderId}/reserve?query=${query}`, {
      credentials: 'include',
      method: 'GET'
    })
      .then((res) => res.json())
      .then(({ products, stock, order }) => {
        dispatch({ type: 'ORDER:GET_ORDER:SUCCESS', payload: { order } });
        dispatch({
          type: 'STOCKS:GET_STOCK:SUCCESS',
          payload: { stockDetail: stock }
        });
        dispatch({
          type: 'STOCKS:LOAD_PRODUCTS_OF_STOCK:SUCCESS',
          payload: { products }
        });
      })
      .catch((res) => {
        dispatch({
          type: 'STOCKS:GET_RESERVED_INFO:FAILURE',
          payload: { error: res.error }
        });
        console.error(res);
      });
  };

export const getAllStocks = (orderBy, direction) => (dispatch) => {
  dispatch({ type: 'STOCKS:GET_ALL_STOCKS:REQUEST' });

  const url =
    orderBy && direction
      ? `/api/stocks?orderBy=${orderBy}&direction=${direction}`
      : '/api/stocks';

  return fetch(url, {
    credentials: 'include',
    method: 'GET'
  })
    .then((res) => res.json())
    .then((json) => {
      dispatch({
        type: 'STOCKS:GET_ALL_STOCKS:SUCCESS',
        payload: { items: json }
      });
    })
    .catch((res) => {
      dispatch({
        type: 'STOCKS:GET_ALL_STOCKS:FAILURE',
        payload: { error: res.error }
      });
      console.error(res);
    });
};

export const deleteStock = (id) => (dispatch) => {
  dispatch({ type: 'STOCKS:DELETE:REQUEST', payload: { id } });

  return fetch(`/api/stocks/${id}`, {
    credentials: 'include',
    method: 'DELETE'
  })
    .then(() => {
      dispatch({ type: 'STOCKS:DELETE:SUCCESS', payload: { id } });
    })
    .catch((res) => {
      dispatch({
        type: 'STOCKS:DELETE:FAILURE',
        payload: { error: res.error }
      });
      console.error(res);
    });
};

export const addStock = () => (dispatch, getState) => {
  const { name, number, address, catalogId } = getState()
    .getIn(['stocks', 'formCreate'])
    .toJS();

  dispatch({ type: 'STOCKS:ADD:REQUEST' });

  return req
    .post('/api/stocks', { number, name, address, catalogId })
    .then(() => {
      dispatch({
        type: 'STOCKS:ADD:SUCCESS',
        payload: { number, name, address, catalogId }
      });
      return '';
    })
    .catch(async (resp) => {
      const { error, errors } = await resp.json();
      if (errors) {
        dispatch({ type: 'STOCKS:ADD:ERRORS', payload: { errors } });
      }
      return error;
    });
};

export const getMoreInfo = () => (dispatch) => {
  dispatch({ type: 'STOCKS:GET_MORE_INFO:REQUEST' });

  return fetch('/api/stocks/new', {
    credentials: 'include',
    method: 'GET'
  })
    .then((res) => res.json())
    .then((json) =>
      dispatch({
        type: 'STOCKS:GET_MORE_INFO:SUCCESS',
        payload: { ...json }
      })
    )
    .catch((res) => {
      dispatch({
        type: 'STOCKS:GET_MORE_INFO:FAILURE',
        payload: { error: res.error }
      });
      console.error(res);
    });
};

export const getStockDetail = (stockId) => (dispatch) => {
  dispatch({ type: 'STOCKS:GET_STOCK:REQUEST' });

  return fetch(`/api/stocks/${stockId}`, {
    credentials: 'include',
    method: 'GET'
  })
    .then((res) => res.json())
    .then((json) => {
      if (json.blocked) {
        customHistory.push('/stocks');
      }
      dispatch({
        type: 'STOCKS:GET_STOCK:SUCCESS',
        payload: { stockDetail: json }
      });
      return json;
    })
    .catch((res) => {
      dispatch({
        type: 'STOCKS:GET_STOCK:FAILURE',
        payload: { error: res.error }
      });
      console.error(res);
      return Promise.reject();
    });
};

export const getStockForPrice = (stockId, priceId) => async (dispatch) => {
  dispatch({ type: 'STOCKS:GET_STOCK:REQUEST' });

  try {
    const res = await fetch(`/api/stocks/${stockId}/prices/${priceId}`, {
      credentials: 'include',
      method: 'GET'
    });
    const resp = await res.json();
    if (res.status === 409) {
      customHistory.push('/prices-suppliers');
      dispatch(setErrorMessage({ key: resp.msg }));
      return;
    }
    dispatch({
      type: 'STOCKS:GET_STOCK:SUCCESS',
      payload: { stockDetail: resp }
    });
  } catch (e) {
    console.error(e);
  }
};

export const updateStock = () => (dispatch, getState) => {
  const { id, name, number, address, language } = getState()
    .getIn(['stocks', 'formCreate'])
    .toJS();

  dispatch({ type: 'STOCKS:UPDATE:REQUEST' });

  return fetch(`/api/stocks/${id}`, {
    credentials: 'include',
    method: 'PATCH',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ number, name, address, language })
  })
    .then((res) =>
      res.json().then((json) =>
        // eslint-disable-next-line prefer-promise-reject-errors
        res.status < 400
          ? Promise.resolve()
          : Promise.reject({ error: json.error, status: res.status })
      )
    )
    .then(() => {
      dispatch({
        type: 'STOCKS:UPDATE:SUCCESS',
        payload: { number, name, address, language }
      });
      return Promise.resolve();
    })
    .catch(({ error }) => error);
};

const fetchProductOfStock = (
  stockId,
  {
    search = '',
    offset = 0,
    limit = 20,
    selectedIds = [],
    categoriesId = [],
    orderBy,
    direction,
    filter = []
  }
) => {
  let url = `/api/stocks/${stockId}/products/show`;
  if (orderBy && direction) {
    url = `/api/stocks/${stockId}/products/show?orderBy=${orderBy}&direction=${direction}`;
  }

  return fetch(url, {
    credentials: 'include',
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      search,
      offset,
      limit,
      categoriesId,
      ids: selectedIds,
      filter
    })
  }).then((res) =>
    res.json().then((data) =>
      res.status < 400
        ? Promise.resolve({ orderBy, direction, ...data })
        : // eslint-disable-next-line prefer-promise-reject-errors
          Promise.reject({ errors: data.errors, status: res.status })
    )
  );
};

export const fetchProductsByIds =
  (stockId, ids, search, orderBy, direction) => (dispatch) => {
    dispatch({ type: 'STOCKS:LOAD_PRODUCTS_OF_STOCK:REQUEST' });

    let url = `/api/stocks/${stockId}/products/show`;
    if (orderBy && direction) {
      url = `/api/stocks/${stockId}/products/show?orderBy=${orderBy}&direction=${direction}`;
    }

    return fetch(url, {
      credentials: 'include',
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ ids, search, limit: 0, offset: 0 })
    })
      .then((res) => res.json())
      .then((data) =>
        dispatch({
          type: 'STOCKS:LOAD_PRODUCTS_OF_STOCK:SUCCESS',
          payload: data
        })
      )
      .catch((res) => {
        dispatch({
          type: 'STOCKS:LOAD_PRODUCTS_OF_STOCK:FAILURE',
          payload: { error: res.error }
        });
        console.error(res);
      });
  };

const getArgsForProductOfStock = (state, args) => {
  const stockId = args.stockId || args.id;

  const isEnabledFilterSelected = state.getIn([
    'stocks',
    'isEnabledFilterSelected'
  ]);
  const selectedIdsArray = state
    .getIn(['stocks', 'selectedProducts'])
    .toArray();

  const selected = selectedIdsArray.length === 0 ? [0] : selectedIdsArray;
  const selectedIds = isEnabledFilterSelected ? selected : [];

  const categoriesId = state.getIn(['stocks', 'selectedCategories']).toArray();

  const search = state.getIn(['stocks', 'search']) || '';
  const limit = state.getIn(['stocks', 'limit']) || 20;
  const offset = state.getIn(['stocks', 'offset']);

  const orderBy = state.getIn(['stocks', 'orderBy']);
  const direction = state.getIn(['stocks', 'direction']);
  const filter = [];

  return {
    stockId,
    search: search.trim(),
    offset,
    limit,
    selectedIds,
    categoriesId,
    orderBy,
    direction,
    filter,
    ...args
  };
};

export const getProductOfStock =
  (args = {}) =>
  async (dispatch, getState) => {
    dispatch({ type: 'STOCKS:LOAD_PRODUCTS_OF_STOCK:REQUEST' });

    try {
      const filter = getState().getIn(['stocks', 'appliedFilterValues']);
      const options = getArgsForProductOfStock(getState(), args);
      const payload = await fetchProductOfStock(options.stockId, {
        ...options,
        filter
      });
      dispatch({ type: 'STOCKS:LOAD_PRODUCTS_OF_STOCK:SUCCESS', payload });
    } catch (res) {
      dispatch({
        type: 'STOCKS:LOAD_PRODUCTS_OF_STOCK:FAILURE',
        payload: { error: res.error }
      });
      console.error(res);
    }
  };

export const loadProductsOfStock = (
  stockId,
  search,
  offset,
  limit,
  orderBy,
  direction
) => getProductOfStock({ stockId, search, offset, limit, orderBy, direction });

export const loadProductsOfStockForPrice =
  (stockId, categoryName, priceId) => (dispatch) => {
    dispatch({ type: 'STOCKS:LOAD_PRODUCTS_OF_STOCK:REQUEST' });

    return fetch(
      `/api/stocks/${stockId}/categories/${categoryName}/prices/${priceId}/products`,
      {
        credentials: 'include',
        method: 'GET'
      }
    )
      .then((res) => res.json())
      .then((result) => {
        /* eslint-disable */
        result.products = result.products.map((p) => {
          p.category = p.Category.name;
          delete p.Category;
          p.name = p.Product.name;
          p.sku = p.Product.sku;
          delete p.Product;
          return p;
        });
        /* eslint-enable */
        return dispatch({
          type: 'STOCKS:LOAD_PRODUCTS_OF_STOCK:SUCCESS',
          payload: result
        });
      })
      .catch((res) => {
        dispatch({
          type: 'STOCKS:LOAD_PRODUCTS_OF_STOCK:FAILURE',
          payload: { error: res.error }
        });
        console.error(res);
      });
  };

export const loadAllProductsOfStock = (stockId) => (dispatch) => {
  dispatch({ type: 'STOCKS:LOAD_PRODUCTS_OF_STOCK:REQUEST' });

  return fetch(`/api/stocks/${stockId}/products`, {
    credentials: 'include',
    method: 'GET'
  })
    .then((res) => res.json())
    .then((products) =>
      dispatch({
        type: 'STOCKS:LOAD_PRODUCTS_OF_STOCK:SUCCESS',
        payload: { products }
      })
    )
    .catch((res) => {
      dispatch({
        type: 'STOCKS:LOAD_PRODUCTS_OF_STOCK:FAILURE',
        payload: { error: res.error }
      });
      console.error(res);
    });
};

export const loadCategoriesOfStock = (stockId) => (dispatch) => {
  dispatch({ type: 'STOCKS:LOAD_CATEGORIES_OF_STOCK:REQUEST' });
  return fetch(`/api/stocks/${stockId}/categories`, { credentials: 'include' })
    .then((res) => res.json())
    .then((categories) => {
      dispatch({
        type: 'STOCKS:LOAD_CATEGORIES_OF_STOCK:SUCCESS',
        payload: { categories }
      });
    })
    .catch((res) => {
      dispatch({
        type: 'STOCKS:LOAD_CATEGORIES_OF_STOCK:FAILURE',
        payload: { error: res.error }
      });
      dispatch(setErrorMessage({ key: 'Products load failure' }));
      console.error(res);
    });
};

export const updateCountOfProductInStock =
  (stockId, productInStock, inStock) => (dispatch) => {
    dispatch({
      type: 'STOCKS:UPDATE_PRODUCTS_OF_STOCK:REQUEST',
      payload: { productInStock, inStock }
    });

    return fetch(`/api/stocks/${stockId}/products/${productInStock.id}`, {
      credentials: 'include',
      method: 'PATCH',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ inStock })
    })
      .then((res) =>
        res.json().then((json) =>
          // eslint-disable-next-line prefer-promise-reject-errors
          res.status < 400
            ? Promise.resolve(json)
            : Promise.reject({ errors: json.errors, status: res.status })
        )
      )
      .then(() => {
        dispatch({
          type: 'STOCKS:UPDATE_PRODUCTS_OF_STOCK:SUCCESS',
          payload: { stockId, product: productInStock, inStock }
        });
      })
      .catch((res) => {
        dispatch({
          type: 'STOCKS:UPDATE_PRODUCTS_OF_STOCK:FAILURE',
          payload: { errors: res.errors }
        });
        console.error(res);

        return Promise.reject();
      });
  };

export const updateCountOfFewProductInStock =
  (stockId, ids, inStock) => (dispatch) => {
    dispatch({
      type: 'STOCKS:UPDATE_PRODUCTS_OF_STOCK:REQUEST',
      payload: { ids, inStock }
    });
    dispatch(setInfoMessage({ key: 'change_process_in_progress' }));

    return fetch(`/api/stocks/${stockId}/products`, {
      credentials: 'include',
      method: 'PATCH',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ inStock, ids })
    })
      .then((res) =>
        res.json().then((json) =>
          // eslint-disable-next-line prefer-promise-reject-errors
          res.status < 400
            ? Promise.resolve(json)
            : Promise.reject({ errors: json.errors, status: res.status })
        )
      )
      .then(() => {
        dispatch(setSuccessMessage());
        dispatch({
          type: 'STOCKS:UPDATE_FEW_PRODUCTS_OF_STOCK:SUCCESS',
          payload: { stockId, ids, inStock }
        });
      })
      .catch((res) => {
        dispatch({
          type: 'STOCKS:UPDATE_PRODUCTS_OF_STOCK:FAILURE',
          payload: { errors: res.errors }
        });
        console.error(res);

        return Promise.reject();
      });
  };

export const updateOrCreateProductInStock =
  (stockId, product, inStock) => (dispatch) => {
    dispatch({
      type: 'STOCKS:UPDATE_PRODUCTS_OF_STOCK:REQUEST',
      payload: { product, inStock }
    });

    return fetch(`/api/stocks/${stockId}/products/${product.productId}`, {
      credentials: 'include',
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ inStock, unit: product.productUnit })
    })
      .then((res) =>
        res.json().then((json) =>
          // eslint-disable-next-line prefer-promise-reject-errors
          res.status < 400
            ? Promise.resolve(json)
            : Promise.reject({ errors: json.errors, status: res.status })
        )
      )
      .then(() => {
        dispatch({
          type: 'STOCKS:UPDATE_PRODUCTS_OF_STOCK:SUCCESS',
          payload: { stockId, product, inStock }
        });
      })
      .catch((res) => {
        dispatch({
          type: 'STOCKS:UPDATE_PRODUCTS_OF_STOCK:FAILURE',
          payload: { errors: res.errors }
        });
        console.error(res);

        return Promise.reject();
      });
  };

export const deleteCategories = (stockId, categories) => (dispatch) => {
  dispatch({ type: 'STOCKS:DELETE_CATEGORIES:REQUEST' });

  return fetch(`/api/stocks/${stockId}/categories/delete`, {
    credentials: 'include',
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(categories)
  })
    .then((res) =>
      res.json().then((json) =>
        // eslint-disable-next-line prefer-promise-reject-errors
        res.status < 400
          ? Promise.resolve(json)
          : Promise.reject({ errors: json.errors, status: res.status })
      )
    )
    .then(() => {
      dispatch({
        type: 'STOCKS:DELETE_CATEGORIES:SUCCESS'
      });
      loadCategoriesOfStock(stockId)(dispatch);
    })
    .catch((res) => {
      dispatch({
        type: 'STOCKS:DELETE_CATEGORIES:FAILURE',
        payload: { errors: res.errors }
      });
      console.error(res);

      return Promise.reject();
    });
};

export const deleteProducts = (stockId, ids) => (dispatch) => {
  dispatch({ type: 'STOCKS:DELETE_PRODUCTS:REQUEST', payload: { ids } });

  return fetch(`/api/stocks/${stockId}/products/delete`, {
    credentials: 'include',
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ ids })
  })
    .then((res) =>
      res.json().then((json) =>
        // eslint-disable-next-line prefer-promise-reject-errors
        res.status < 400
          ? Promise.resolve(json)
          : Promise.reject({ errors: json.errors, status: res.status })
      )
    )
    .then(() => {
      dispatch({
        type: 'STOCKS:DELETE_PRODUCTS:SUCCESS'
      });
    })
    .catch((res) => {
      dispatch({
        type: 'STOCKS:UPDATE_PRODUCTS_OF_STOCK:FAILURE',
        payload: { errors: res.errors }
      });
      console.error(res);

      return Promise.reject();
    });
};

export const updateReservedOfProductInStock =
  (stockId, product, reserved) => (dispatch) => {
    dispatch({ type: 'STOCKS:UPDATE_PRODUCTS_OF_STOCK_RESERVED:REQUEST' });

    return fetch(`/api/stocks/${stockId}/products/${product.sku}/reserved`, {
      credentials: 'include',
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ reserved })
    })
      .then((res) =>
        res.json().then((json) =>
          // eslint-disable-next-line prefer-promise-reject-errors
          res.status < 400
            ? Promise.resolve(json)
            : Promise.reject({ errors: json.errors, status: res.status })
        )
      )
      .then(() => {
        dispatch({
          type: 'STOCKS:UPDATE_PRODUCTS_OF_STOCK_RESERVED:SUCCESS',
          payload: { stockId, product, reserved }
        });
      })
      .catch(({ errors }) => {
        dispatch({
          type: 'STOCKS:UPDATE_PRODUCTS_OF_STOCK_RESERVED:FAILURE',
          payload: { errors }
        });
        console.error(errors);

        return Promise.reject();
      });
  };

export const updateHandReservedOfProductInStock =
  (stockId, product, handReserved) => (dispatch) => {
    dispatch({ type: 'STOCKS:UPDATE_PRODUCTS_OF_STOCK_HAND_RESERVED:REQUEST' });

    return fetch(`/api/stocks/${stockId}/products/${product.id}`, {
      credentials: 'include',
      method: 'PATCH',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ handReserved })
    })
      .then((res) =>
        res.json().then((json) =>
          // eslint-disable-next-line prefer-promise-reject-errors
          res.status < 400
            ? Promise.resolve(json)
            : Promise.reject({ errors: json.errors, status: res.status })
        )
      )
      .then(() => {
        dispatch({
          type: 'STOCKS:UPDATE_PRODUCTS_OF_STOCK_HAND_RESERVED:SUCCESS',
          payload: { stockId, product, reserved: handReserved }
        });
      })
      .catch(({ errors }) => {
        dispatch({
          type: 'STOCKS:UPDATE_PRODUCTS_OF_STOCK_HAND_RESERVED:FAILURE',
          payload: { errors }
        });
        console.error(errors);

        return Promise.reject();
      });
  };

export const updateReceiptDateOfProductInStock =
  (stockId, product, receiptDate) => (dispatch) => {
    dispatch({ type: 'STOCKS:UPDATE_RECEIPT_DATE:REQUEST' });

    return fetch(`/api/stocks/${stockId}/products/${product.id}`, {
      credentials: 'include',
      method: 'PATCH',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ receiptDate })
    })
      .then((res) =>
        res.json().then((json) =>
          // eslint-disable-next-line prefer-promise-reject-errors
          res.status < 400
            ? Promise.resolve(json)
            : Promise.reject({ errors: json.errors, status: res.status })
        )
      )
      .then(() => {
        dispatch({
          type: 'STOCKS:UPDATE_RECEIPT_DATE:SUCCESS',
          payload: { stockId, product, receiptDate }
        });
      })
      .catch((errors) => {
        dispatch({
          type: 'STOCKS:UPDATE_RECEIPT_DATE:FAILURE',
          payload: { errors }
        });
        console.error(errors);

        return Promise.reject();
      });
  };

export const updateReceiptDateOfFewProductInStock =
  (stockId, ids, receiptDate) => (dispatch) => {
    dispatch({ type: 'STOCKS:UPDATE_RECEIPT_DATE:REQUEST' });
    dispatch(setInfoMessage({ key: 'change_process_in_progress' }));

    return fetch(`/api/stocks/${stockId}/products`, {
      credentials: 'include',
      method: 'PATCH',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ receiptDate, ids })
    })
      .then((res) =>
        res.json().then((json) =>
          // eslint-disable-next-line prefer-promise-reject-errors
          res.status < 400
            ? Promise.resolve(json)
            : Promise.reject({ errors: json.errors, status: res.status })
        )
      )
      .then(() => {
        dispatch(setSuccessMessage());
        dispatch({
          type: 'STOCKS:UPDATE_FEW_RECEIPT_DATE:SUCCESS',
          payload: { stockId, ids, receiptDate }
        });
      })
      .catch((errors) => {
        dispatch({
          type: 'STOCKS:UPDATE_RECEIPT_DATE:FAILURE',
          payload: { errors }
        });
        console.error(errors);

        return Promise.reject();
      });
  };

export const updateUnitsOfProductInStock =
  (stockId, product, unit) => (dispatch) => {
    dispatch({ type: 'STOCKS:UPDATE_PRODUCTS_OF_STOCK_UNITS:REQUEST' });

    return fetch(`/api/stocks/${stockId}/products/${product.id}`, {
      credentials: 'include',
      method: 'PATCH',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ unit })
    })
      .then((res) => res.json())
      .then(() => {
        dispatch({
          type: 'STOCKS:UPDATE_PRODUCTS_OF_STOCK_UNITS:SUCCESS',
          payload: { stockId, product, unit }
        });
      })
      .catch(({ errors }) => {
        dispatch({
          type: 'STOCKS:UPDATE_PRODUCTS_OF_STOCK_UNITS:FAILURE',
          payload: { errors }
        });
        console.error(errors);

        return Promise.reject();
      });
  };

export const setPropForSearchProduct = (productId, props = {}) => ({
  type: 'STOCKS:SET_PROPS_FOR_SEARCH_PRODUCT',
  payload: { productId, props }
});

export const selectSearchProduct = (productId) => ({
  type: 'STOCKS:SELECT_SEARCH_PRODUCT',
  payload: { productId }
});

export const selectAllSearchProducts = () => ({
  type: 'STOCKS:ALL_SELECT_SEARCH_PRODUCT',
  payload: {}
});

export const searchProductOfStock =
  (stockId, options = {}) =>
  (dispatch, getState) => {
    const { search = '', limit = 20, offset = 0 } = options;
    const state = getState();
    const categoriesId = state.getIn(['tables', 'common', 'selected']);
    const isEnabledFilterSelected = state.getIn([
      'stocks',
      'foundProducts',
      'isEnabledFilterSelected'
    ]);

    const selectedIdsArray = state
      .getIn(['stocks', 'foundProducts', 'selected'])
      .toArray();
    const selected = selectedIdsArray.length === 0 ? [0] : selectedIdsArray;
    const selectedIds = isEnabledFilterSelected ? selected : [];

    const orderBy =
      options.orderBy || state.getIn(['stocks', 'foundProducts', 'orderBy']);
    const direction =
      options.direction ||
      state.getIn(['stocks', 'foundProducts', 'direction']);

    const body = {
      search,
      limit,
      offset,
      categoriesId,
      ids: selectedIds,
      orderBy,
      direction
    };

    dispatch({ type: 'STOCKS:SEARCH_PRODUCT:REQUEST', payload: body });

    return fetch(`/api/stocks/${stockId}/products/search`, {
      credentials: 'include',
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(body)
    })
      .then((res) =>
        res.json().then((json) =>
          // eslint-disable-next-line prefer-promise-reject-errors
          res.status < 400
            ? Promise.resolve(json)
            : Promise.reject({ errors: json.errors, status: res.status })
        )
      )
      .then((data) => {
        dispatch({
          type: 'STOCKS:SEARCH_PRODUCT:SUCCESS',
          payload: {
            search,
            products: data.products,
            size: data.size,
            productsCount: data.productsCount,
            limit,
            offset,
            categoriesToAdd: data.notAddedCategories,
            ids: data.ids || [],
            orderBy,
            direction
          }
        });
        return Promise.resolve(data.products);
      })
      .catch(({ errors }) => {
        dispatch({
          type: 'STOCKS:SEARCH_PRODUCT:FAILURE',
          payload: { errors }
        });
        console.error(errors);

        return Promise.reject();
      });
  };

export const searchProductOfStockOptions = (options = {}) =>
  searchProductOfStock(options.stockId || options.id, options);

// const serializeProductsForBack = products =>
//   products
//     .filter(product => product.get('isSelected'))
//     .toList()
//     .toJS()
//     .map(({ id: productId, publishedCategoryId, count: inStock, unit }) => ({
//       productId,
//       publishedCategoryId,
//       inStock,
//       unit
//     }));

export const addProductToStock = (stockId) => (dispatch, getState) => {
  const products = getState().getIn(['stocks', 'foundProducts', 'counts']);
  const selectedProducts = getState()
    .getIn(['stocks', 'foundProducts', 'selected'])
    .toJS()
    .map((id) => {
      const productCount = products.find((p) => p.get('id') === id);
      const count = productCount ? productCount.get('count') : 0;
      return { id, count };
    });

  dispatch({ type: 'STOCKS:ADD_PRODUCT_TO_STOCK:REQUEST' });
  dispatch(setInfoMessage({ key: 'change_process_in_progress' }));

  return fetch(`/api/stocks/${stockId}/products`, {
    credentials: 'include',
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ products: selectedProducts })
  })
    .then(() =>
      dispatch({
        type: 'STOCKS:ADD_PRODUCT_TO_STOCK:SUCCESS',
        payload: {}
      })
    )
    .catch(({ errors }) => {
      dispatch({
        type: 'STOCKS:ADD_PRODUCT_TO_STOCK:FAILURE',
        payload: { errors }
      });
      console.error(errors);

      return Promise.reject();
    });
};

export const addAllProductToStock = (stockId, products) => (dispatch) => {
  dispatch({ type: 'STOCKS:ADD_ALL_PRODUCT_TO_STOCK:REQUEST' });
  dispatch(setInfoMessage({ key: 'change_process_in_progress' }));

  const prodArr = [];
  Object.keys(products).forEach((key) => {
    prodArr.push(products[key]);
  });
  return fetch(`/api/stocks/${stockId}/products/all`, {
    credentials: 'include',
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ products: prodArr })
  })
    .then(() =>
      dispatch({
        type: 'STOCKS:ADD_ALL_PRODUCT_TO_STOCK:SUCCESS',
        payload: {}
      })
    )
    .catch(({ errors }) => {
      dispatch({
        type: 'STOCKS:ADD_ALL_PRODUCT_TO_STOCK:FAILURE',
        payload: { errors }
      });
      console.error(errors);

      return Promise.reject();
    });
};

export const importExcelToStock = (stockId, body) => (dispatch) => {
  dispatch({ type: 'STOCKS:IMPORT_EXCEL:REQUEST', payload: { stockId } });

  return fetch(`/api/import/stock/${stockId}`, {
    credentials: 'include',
    method: 'POST',
    body
  })
    .then((res) =>
      res.json().then((json) =>
        res.status < 400
          ? Promise.resolve({
              data: json,
              errorsList: json.errors.map((error) => error.message) || []
            })
          : // eslint-disable-next-line prefer-promise-reject-errors
            Promise.reject({
              errorsList: json.errors.map((error) => error.message) || [],
              status: res.status
            })
      )
    )
    .then(({ data, errorsList }) => {
      const errors = errorsList.filter((err) => err.type === 'critical');
      const warnings = errorsList.filter((err) => err.type === 'uncritical');
      const currentLocation = getCurrentLocation();
      const isImportLocation =
        currentLocation === `/stocks/import/${stockId}/` ||
        currentLocation === `/stocks/import/${stockId}` ||
        currentLocation === '/stocks/import';
      dispatch({
        type: 'STOCKS:IMPORT_EXCEL:SUCCESS',
        payload: { stockId, errors, warnings }
      });

      const linkParams = {
        linkUrl: `/stocks/import/${stockId}`,
        linkKey: 'Move to finish'
      };

      if (!isImportLocation) {
        if (!warnings.length) {
          dispatch(
            setSuccessMessage({
              key: 'Download complete successfully',
              ...(!isImportLocation ? linkParams : {})
            })
          );
        } else {
          dispatch(
            setWarningMessage({
              key: 'Stock upload finished with warnings',
              ...(!isImportLocation ? linkParams : {})
            })
          );
        }
      }

      return data;
    })
    .catch(({ errorsList }) => {
      const errors = errorsList.filter((err) => err.type === 'critical');
      const warnings = errorsList.filter((err) => err.type === 'uncritical');
      dispatch({
        type: 'STOCKS:IMPORT_EXCEL:FAILURE',
        payload: { stockId, errors, warnings }
      });
      console.error(errorsList);

      return Promise.reject();
    });
};

export const exportExcelFromStock = (stockId) => (dispatch) => {
  dispatch({ type: 'STOCKS:EXPORT_EXCEL' });
  window.open(`/api/stocks/${stockId}/excel`, '_self');
};

export const exportExcelFromStockReserve = (stockId) => (dispatch) => {
  dispatch({ type: 'STOCKS:EXPORT_EXCEL_RESERVE' });
  window.open(`/api/stocks/${stockId}/excel-reserve`, '_self');
};

export const loadUnitsCodes = () => (dispatch) => {
  dispatch({ type: 'STOCKS:LOAD_UNITS_CODES:REQUEST' });

  return fetch('/api/stocks/unit_codes', {
    credentials: 'include',
    method: 'GET'
  })
    .then((res) =>
      res
        .json()
        .then((json) =>
          res.status < 400 ? Promise.resolve(json) : Promise.reject()
        )
    )
    .then((unitsCodes) => {
      dispatch({
        type: 'STOCKS:LOAD_UNITS_CODES:SUCCESS',
        payload: { unitsCodes }
      });
    })
    .catch((err) => {
      console.error(err);
      dispatch({ type: 'STOCKS:LOAD_UNITS_CODES:FAILURE' });
    });
};

export const getUnits = (stockId) => (dispatch) =>
  fetch(`/api/stocks/${stockId}/units`, {
    credentials: 'include',
    method: 'GET'
  })
    .then((res) => res.json())
    .then((units) => {
      dispatch({
        type: 'STOCKS:GET_UNITS:SUCCESS',
        payload: { units }
      });
      return Promise.resolve();
    })
    .catch((res) => {
      dispatch({
        type: 'STOCKS:GET_UNITS:FAILURE',
        payload: { error: res.error }
      });
      return Promise.reject();
    });

export const setTransferValue = (sku, value) => ({
  type: 'STOCKS:SET_TRANSFER_VALUE',
  payload: { sku, value }
});

export const getStocksByCatalog = (catalogId) => (dispatch) => {
  dispatch({ type: 'STOCKS:GET_ALL_STOCKS:REQUEST' });

  return fetch(`/api/stocks/catalogId/${catalogId}`, {
    credentials: 'include',
    method: 'GET'
  })
    .then((res) => res.json())
    .then((json) => {
      dispatch({
        type: 'STOCKS:GET_ALL_STOCKS:SUCCESS',
        payload: { items: json }
      });
    })
    .catch((res) => {
      dispatch({
        type: 'STOCKS:GET_ALL_STOCKS:FAILURE',
        payload: { error: res.error }
      });
      console.error(res);
    });
};

export const transferItems = (stockId, toStock, items) => (dispatch) => {
  const toTransferItems = items.filter((i) => i.count);
  dispatch({ type: 'STOCKS:TRANSFER_PRODUCTS:REQUEST' });
  dispatch(setInfoMessage({ key: 'change_process_in_progress' }));

  return fetch(`/api/stocks/${stockId}/products/transfer`, {
    credentials: 'include',
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ toStock: toStock.value, items: toTransferItems })
  })
    .then(() => {
      dispatch(
        setSuccessMessage({
          key: 'Transfer to stock {{stockName}} completed',
          params: { stockName: toStock.label }
        })
      );
      dispatch({ type: 'STOCKS:TRANSFER_PRODUCTS:SUCCESS', payload: {} });
    })
    .catch(({ errors }) => {
      dispatch({
        type: 'STOCKS:TRANSFER_PRODUCTS:FAILURE',
        payload: { errors }
      });
      console.error(errors);
      return Promise.reject();
    });
};

export const getUserStockAttrs = (stockId) => (dispatch) => {
  dispatch({ type: 'STOCKS:LOAD_ATTRS_FILTER:REQUEST' });

  return fetch(`/api/stocks/${stockId}/attrs_filter`, {
    credentials: 'include',
    method: 'GET'
  })
    .then((res) => res.json())
    .then(({ excludeAttrs }) => {
      dispatch({
        type: 'STOCKS:LOAD_ATTRS_FILTER:SUCCESS',
        payload: { excludeAttrs }
      });
    })
    .catch((err) => {
      console.error(err);
      dispatch({ type: 'STOCKS:LOAD_ATTRS_FILTER:FAILURE' });
    });
};

export const setAttrsFilter =
  (stockId, attr, selectAll = null) =>
  (dispatch) => {
    dispatch({ type: 'STOCKS:SET_FILTER:REQUEST' });
    return fetch(`/api/stocks/${stockId}/attrs_filter`, {
      credentials: 'include',
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ attr, selectAll })
    })
      .then((res) => res.json())
      .then(({ excludeAttrs }) =>
        dispatch({
          type: 'STOCKS:LOAD_ATTRS_FILTER:SUCCESS',
          payload: { excludeAttrs }
        })
      )
      .catch(({ errors }) => {
        dispatch({ type: 'STOCKS:SET_FILTER:FAILURE', payload: { errors } });
        console.error(errors);
      });
  };

export const setFilterField = (name, value) => ({
  type: 'STOCKS:SET_FILTER_FIELD',
  payload: { name, value }
});

export const applyFilter = (filters) => ({
  type: 'STOCKS:APPLY_FILTER',
  payload: { filters }
});
