import { Map } from 'immutable';
import { equals, curry } from 'ramda';
import {
  getPriceCatsTypeSumOfOrder,
  getPriceCatsTypeAmountOfProduct,
  emptyPriceCategory,
  isEmptyPriceCategory,
  getPriceCatsTypeSectialConditions,
  getOrderCatsTypeSectialConditions
} from '../../viewModelAdapters/purchasePriceCategories';
import { isIncludeInRange } from '../../lib/math';
// eslint-disable-next-line import/no-cycle
import {
  calcPriceOfProductList,
  discountPriceByOrderProduct
} from './orderDraft';
// eslint-disable-next-line import/no-cycle
import { getSimpleProducts } from '../../storeSections/orders';

export const findPriceCatTypeAmoundOfProductInOrder = curry(
  (rangeCategories, product) => {
    const unit = product.get('unit');
    const quantity = product.get('count');

    const foundCategory = rangeCategories.find((category) => {
      const from = category.get('from');
      const to = category.get('to');
      const categoryUnit = category.get('unit');

      return equals(unit, categoryUnit) && isIncludeInRange(from, to, quantity);
    });

    return foundCategory || emptyPriceCategory;
  }
);

export const findPriceCatTypeSumOfOrder = (categoriesBySum, sumOfProducts) => {
  const foundCategory = categoriesBySum.find((category) => {
    const minOrder = category.get('minOrder');
    const maxOrder = category.get('maxOrder');

    return isIncludeInRange(minOrder, maxOrder, sumOfProducts);
  });

  return foundCategory || emptyPriceCategory;
};

export const findIndividualCategory = (product, priceCategory) => {
  if (!product.get('offers')) {
    return null;
  }
  const foundCategory = product
    .get('offers')
    .find(
      (individualPriceCategory) =>
        individualPriceCategory.get('purchaseCategoryId') ===
        priceCategory.get('id')
    );

  if (!foundCategory) return foundCategory;

  return foundCategory.set(
    'fractionalDigits',
    priceCategory.get('fractionalDigits')
  );
};

export const matchedPriceCategoryBySumOfOrder = (state) =>
  findPriceCatTypeSumOfOrder(
    getPriceCatsTypeSumOfOrder(state),
    calcPriceOfProductList(
      discountPriceByOrderProduct,
      state instanceof Map
        ? state.getIn(['ordersCombine', 'orderDraft', 'items'])
        : state.orderDraft.items()
    ) // TODO: remove check instanceof, pass resource right away
  );

export const matchedPriceCategoryBySumOfOrderNotDraft = (state, items) =>
  findPriceCatTypeSumOfOrder(
    getPriceCatsTypeSumOfOrder(state),
    calcPriceOfProductList(discountPriceByOrderProduct, items) // TODO: remove check instanceof, pass resource right away
  );

export const matchedPriceCategoryByAmoundOfProduct = (state, product) =>
  findPriceCatTypeAmoundOfProductInOrder(
    getPriceCatsTypeAmountOfProduct(state),
    product
  );

export const isEmptyPriceCategoryBySumOrder = (state) =>
  isEmptyPriceCategory(matchedPriceCategoryBySumOfOrder(state));
export const isEmptyPriceCategoryBySumOrderNotDraft = (state, items) =>
  isEmptyPriceCategory(matchedPriceCategoryBySumOfOrder(state, items));

const inividualOrCommonCategory = (individual, common) => individual || common;

export const adapterInvidualOrCommonCategoryByAmoundOfProduct = (
  state,
  product
) => {
  const commmonCategory = matchedPriceCategoryByAmoundOfProduct(state, product);
  const individualCategory = findIndividualCategory(product, commmonCategory);

  return inividualOrCommonCategory(individualCategory, commmonCategory);
};

export const adapterInvidualOrCommonCategoryBySumOfOrder = (state, product) => {
  const commmonCategory = matchedPriceCategoryBySumOfOrder(state);
  const individualCategory = findIndividualCategory(product, commmonCategory);

  return inividualOrCommonCategory(individualCategory, commmonCategory);
};

export const adapterInvidualOrCommonCategoryBySumOfOrderNotDraft = (
  state,
  product
) => {
  const commmonCategory = matchedPriceCategoryBySumOfOrderNotDraft(
    state,
    getSimpleProducts(state)
  );
  const individualCategory = findIndividualCategory(product, commmonCategory);

  return inividualOrCommonCategory(individualCategory, commmonCategory);
};

export const totalDiscountByConditions = (state) =>
  getPriceCatsTypeSectialConditions(state).reduce(
    (acc, condition) => acc + condition.get('discount'),
    0
  );

export const totalDraftDiscountByConditions = (state) =>
  getOrderCatsTypeSectialConditions(state).reduce(
    (acc, condition) => acc + condition.get('discount'),
    0
  );
