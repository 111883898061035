import React, { Component } from 'react';
import { translate } from 'react-i18next';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  Modal,
  Button,
  Row,
  FormControl,
  HelpBlock,
  FormGroup
} from 'react-bootstrap';

import Checkbox from '../Checkbox';

import { ModalContainer } from './elements';

import { validateEmail } from '../../utils/validateField';

import './styles/modal_theme_default.styl';

import * as modalActions from '../../action-creators/modal';
import * as billingActions from '../../action-creators/billing';
import * as servicesActions from '../../action-creators/services';
import { setRedirectCookie } from '../../modules/redirectTo/utils';
import { BILLING_PATH } from '../../modules/redirectTo/constants';

class ModalBillingPayment extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: '',
      email: '',
      requestSent: false,
      bindCard: false
    };
    this.showEmailError = this.showEmailError.bind(this);
  }

  UNSAFE_componentWillMount() {
    const { billing } = this.props;
    this.setState({
      name: billing.getIn(['contact', 'employee']) || '',
      email: billing.getIn(['contact', 'email']) || ''
    });
  }

  verifyEmail(email) {
    if (email.length && this.state.error !== 'email') {
      const valid = validateEmail(email);
      if (!valid) {
        this.setState({ error: 'email' });
        return valid;
      }
    }
    return true;
  }

  showEmailError() {
    const { t } = this.props;
    if (this.state.error === 'email') {
      return (
        <HelpBlock id="emailErrorMessage" style={{ color: '#fd4f53' }}>
          {t('Wrong email format')}
        </HelpBlock>
      );
    }
    return null;
  }

  render() {
    const {
      t,
      hideModalDialog,
      sum,
      currency = 'rub',
      action = () => {},
      gaSend,
      user,
      moveToPay
    } = this.props;

    const curT = { rub: '₽', usd: '$' };

    const setFormField = (form, field, value) => {
      const fieldEl = document.createElement('input');
      fieldEl.setAttribute('type', 'hidden');
      fieldEl.setAttribute('name', field);
      fieldEl.setAttribute('value', value);
      form.appendChild(fieldEl);
    };

    return (
      <Modal id="payment" onHide={() => hideModalDialog()} show>
        <ModalContainer mods={{ theme: 'default' }}>
          <form
            id="paykeepersubmit"
            method="POST"
            action="https://goodwixcom.server.paykeeper.ru/create/">
            <Modal.Header>
              <Modal.Title>
                {`${t('Payment')}`}
                <span
                  className="modal-header-cross pull-right"
                  onClick={() => hideModalDialog()}
                />
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div style={{ padding: '0px 10px' }}>
                <Row style={{ marginBottom: 10 }}>
                  <div style={{ marginBottom: 3 }}>{t('Contact')}</div>
                  <FormControl
                    type="text"
                    name="clientid"
                    maxLength={255}
                    value={this.state.name}
                    onChange={(e) => this.setState({ name: e.target.value })}
                    onKeyPress={(e) => {
                      if (e.key === 'Enter') {
                        e.preventDefault();
                      }
                    }}
                  />
                </Row>
                <Row>
                  <FormGroup
                    controlId="email"
                    validationState={
                      this.state.error === 'email' ? 'error' : null
                    }
                    style={{ overflow: 'hidden' }}>
                    <div
                      style={{
                        marginBottom: 3,
                        color: this.state.error === 'email' ? '#fd4f53' : ''
                      }}>
                      {t('Email')}
                    </div>
                    <FormControl
                      type="text"
                      name="client_email"
                      maxLength={255}
                      value={this.state.email}
                      onChange={async (e) => {
                        await this.setState({ email: e.target.value.trim() });
                        this.verifyEmail(this.state.email.trim());
                      }}
                      onKeyPress={(e) => {
                        if (e.key === 'Enter') {
                          e.preventDefault();
                        }
                        if (this.state.error === 'email') {
                          this.setState({ error: '' });
                        }
                      }}
                    />
                    {this.showEmailError()}
                  </FormGroup>
                </Row>
                <Row style={{ marginBottom: 10 }}>
                  <div>{`${t('Sum')}: ${sum} ${curT[currency]}`}</div>
                </Row>
                {false && (
                  <Row>
                    <Checkbox
                      set={this.state.bindCard}
                      onClick={() =>
                        this.setState({ bindCard: !this.state.bindCard })
                      }
                    />
                    <span>{t('bind card')}</span>
                  </Row>
                )}
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button
                id="cancel"
                className="pull-left"
                onClick={() => {
                  hideModalDialog();
                }}>
                {t('Cancel')}
              </Button>
              <Button
                id="pay"
                bsStyle="primary"
                disabled={
                  !sum ||
                  !this.state.name ||
                  !this.state.email ||
                  this.state.error ||
                  this.state.requestSent
                }
                onClick={async (e) => {
                  e.preventDefault();
                  setRedirectCookie('billing', BILLING_PATH);
                  await this.setState({ requestSent: true });
                  const { name, email } = this.state;
                  const { billId } = await action({ name: name.trim(), email });
                  if (billId) {
                    // const form = document.getElementById('paykeepersubmit');
                    const getSum = () => {
                      // NOTE: hardcode for checkout the payment on test and production
                      if (user.get('email') === 'postnikov.smm@mail.ru') {
                        return 2;
                      }
                      return sum;
                    };
                    // setFormField(form, 'sum', getSum());
                    // setFormField(form, 'orderid', billId);
                    // if (this.state.bindCard) {
                    //   setFormField(form, 'msgtype', 'createbinding');
                    // }
                    await gaSend({
                      category: 'Billing',
                      action: 'tariff_payment',
                      label: user.get('email')
                    });
                    // form.submit();
                    await moveToPay({ sum: getSum(), orderId: billId });
                  }
                }}>
                {t('Pay tariff')}
              </Button>
            </Modal.Footer>
          </form>
        </ModalContainer>
      </Modal>
    );
  }
}

export default connect(
  (state) => ({
    billing: state.getIn(['company', 'billing']),
    user: state.getIn(['user', 'user'])
  }),
  (dispatch) =>
    bindActionCreators(
      {
        hideModalDialog: modalActions.hideModalDialog,
        getBlockingInfo: billingActions.getBlockingInfo,
        moveToPay: billingActions.moveToPay,
        gaSend: servicesActions.gaSend
      },
      dispatch
    )
)(translate(['ui'], { wait: true })(ModalBillingPayment));
