import React, { useCallback } from 'react';
import { ButtonIcon, Tooltip } from '@link/react-components';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslate } from '../../TranslateProvider';
import * as emailNotificationActions from '../../action-creators/emailNotification';
import { getCurrentUserSoundNotificationsStatus } from '../../storeGetters';

export default function MuteUnmuteSwitch() {
  const t = useTranslate();

  function getTranslate(tKey) {
    return t(`notificationSettings.sound.${tKey}`);
  }

  const soundNotifications = useSelector(
    getCurrentUserSoundNotificationsStatus
  );

  const dispatch = useDispatch();
  const handler = useCallback(
    () => dispatch(emailNotificationActions.toggleSoundNotification()),
    [dispatch]
  );

  return (
    <Tooltip
      title={getTranslate(soundNotifications ? 'mute' : 'unmute')}
      placement="bottom">
      <ButtonIcon
        iconName={soundNotifications ? 'unmute' : 'mute'}
        variant="large"
        size={18}
        onClick={handler}
      />
    </Tooltip>
  );
}
