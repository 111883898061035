import React from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { Flex } from '../../../styledComponents/atoms';
import { getActiveTab } from '../../ChatWidget/getter';
import Contacts from './pages/Contacts';
import Chats from './pages/Chats';
import { bodyConfig, subHeaderConfig } from './config';
import ChatWidgetMain from '../../ChatWidget/main';
import { isIstockMobService } from '../../../lib/goodwix';
import { ConfigContext } from '../../ChatWidget/Messenger/context/ConfigContext';

const MessengerBody = ({ currentMode }) => {
  const activeTab = useSelector(getActiveTab);
  const { chatList } = bodyConfig;
  const { chatWidgetMain } = subHeaderConfig;

  // const style = isIstockMobService()
  //   ? {
  //       height: currentMode === 'none' ? 'calc(100% - 56px - 56px - 56px)' : 'calc(100% - 56px - 30px)'
  //     }
  //   : { minHeight: '54px' };
  const style = isIstockMobService() ? {} : { minHeight: '54px' };
  return (
    <ConfigContext.Provider value={chatWidgetMain}>
      <Container style={style} isIstockMobService={isIstockMobService()}>
        {activeTab === 'contacts' && currentMode === 'none' && <Contacts />}
        {activeTab === 'chats' && currentMode === 'none' && (
          <Chats config={chatList} />
        )}
        {currentMode !== 'none' && (
          <ChatWidgetWrapper>
            <ChatWidgetMain />
          </ChatWidgetWrapper>
        )}
      </Container>
    </ConfigContext.Provider>
  );
};

const Container = styled(Flex)`
  width: 100%;
  height: 100%;
  background: ${(p) => p.theme.colors.white};
  position: relative;
  flex-shrink: 99;

  ::-webkit-scrollbar {
    height: 4px;
    width: 4px;
  }
`;

const ChatWidgetWrapper = styled.div`
  width: 100%;
  height: 100%;

  .chat-widget-main {
    width: 100%;
  }
`;

export default MessengerBody;
