import React from 'react';
import {
  Modal,
  Col,
  Form,
  FormGroup,
  FormControl,
  ControlLabel,
  Button,
  Alert
} from 'react-bootstrap';
import { connect } from 'react-redux';
import { translate } from 'react-i18next';
import getBrowserLanguage from '../../../utils/languageDetector';
import * as modalActionCreators from '../../../action-creators/modal';
import * as translateActionCreators from '../../../action-creators/catalog/translate';

const TranslateCatalogModal = translate(['ui'], { wait: true })(
  class extends React.Component {
    constructor(props) {
      super(props);
      this.submit = this.submit.bind(this);
    }

    getCatalog() {
      const { table, selected } = this.props;
      const selectedId = selected.first();
      return table.find((value) => value.get('_id') === selectedId);
    }

    getAvailableLanguages() {
      const catalog = this.getCatalog();
      const catalogLanguage = catalog.get('language');
      return this.props.languageList
        .filter((l) => l.get('code') !== catalogLanguage)
        .map((l) => (
          <option key={l.get('code')} value={l.get('code')}>
            {l.get('view')}
          </option>
        ));
    }

    submit(e) {
      e.preventDefault();
      const { user, language, sendNotification, hideModalDialog, t } =
        this.props;
      const catalog = this.getCatalog();
      sendNotification(
        user,
        catalog.get('_id'),
        language,
        getBrowserLanguage(),
        catalog.getIn(['json', 'src'])
      ).then((msg) => {
        if (msg) alert(t(msg));
        hideModalDialog();
      });
    }

    render() {
      const { t, hideModalDialog, language, setField } = this.props;

      return (
        <Modal
          show
          onHide={() => hideModalDialog()}
          id="translateCatalogModalWindow">
          <Modal.Header>
            <Modal.Title>{t('Send catalogue for translate')}</Modal.Title>
            <span
              className="modal-header-cross pull-right"
              onClick={() => hideModalDialog()}
            />
          </Modal.Header>
          <Modal.Body>
            <Form horizontal>
              <FormGroup controlId="languageForTranslate">
                <Col
                  componentClass={ControlLabel}
                  xs={4}
                  style={{ textAlign: 'left' }}>
                  {t('Select language')}
                </Col>
                <Col xs={8}>
                  <FormControl
                    componentClass="select"
                    value={language}
                    onChange={(e) => setField('language', e.target.value)}>
                    <option value="">{t('Not set')}</option>
                    {this.getAvailableLanguages()}
                  </FormControl>
                </Col>
              </FormGroup>

              <FormGroup controlId="unit" style={{ marginBottom: 0 }}>
                <Col
                  componentClass={ControlLabel}
                  xs={12}
                  style={{
                    fontSize: '12px',
                    color: 'lightgrey',
                    textAlign: 'left'
                  }}>
                  <Alert bsStyle="info">
                    {t(
                      'Please note that the latest published version of the catalogue will be translated'
                    )}
                  </Alert>
                </Col>
              </FormGroup>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button
              id="sendCatalogForTranslate"
              bsStyle="primary"
              disabled={!language}
              onClick={this.submit}>
              {t('Send')}
            </Button>
          </Modal.Footer>
        </Modal>
      );
    }
  }
);

const mapStateToProps = (state) => ({
  language: state.getIn(['catalog', 'item', 'translateCatalog', 'language']),
  table: state.getIn(['tables', 'catalogs', 'items']),
  selected: state.getIn(['tables', 'catalogs', 'selected']),
  user: state.getIn(['user', 'login']),
  languageList: state.get('languageList')
});

export default connect(mapStateToProps, {
  ...modalActionCreators,
  ...translateActionCreators
})(TranslateCatalogModal);
