import React from 'react';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import IconHelper from './Icons/Help';
import { useTranslate } from '../TranslateProvider';

const Helper = ({ text, id, textParams, children, placement = 'right' }) => {
  const t = useTranslate();

  return (
    <OverlayTrigger
      trigger={['hover', 'focus']}
      placement={placement}
      style={{ width: 500 }}
      overlay={
        <Popover
          id={id || 'popover-positioned-left'}
          className="popover-text-wrap">
          {t(text, textParams)}
          {children}
        </Popover>
      }>
      <span style={{ height: '14px', width: '14px' }}>
        <IconHelper />
      </span>
    </OverlayTrigger>
  );
};

export default Helper;
