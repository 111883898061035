const { makeQuery } = require('core/messages/utils');
const { makeCommand, makeEvent } = require('core/data/messageBus/message');

const NS = 'TRANSCRIPT';

const roomName = ({ roomId }) => `transcript-view.${roomId}`;

const startRecordingCMD = makeCommand(NS, 'RECORDING_START');
const startedRecordingEVT = makeEvent(NS, 'RECORDING_STARTED');
const endRecordingCMD = makeCommand(NS, 'RECORDING_END');
const endedRecordingEVT = makeEvent(NS, 'RECORDING_ENDED');
const sendMessageCMD = makeCommand(NS, 'MESSAGE_SEND');
const receivedMessageEVT = makeEvent(NS, 'MESSAGE_RECEIVED');
const transcriptQRY = makeQuery(NS, 'transcript', roomName);
const viewCMD = makeCommand(NS, 'view');

module.exports = {
  viewCMD,
  transcriptQRY,
  startRecordingCMD,
  startedRecordingEVT,
  endRecordingCMD,
  endedRecordingEVT,
  sendMessageCMD,
  receivedMessageEVT
};
