export const getAllRequests = (state) =>
  state.getIn(['purchaseRequests', 'requests']);
export const getRequest = (state) =>
  state.getIn(['purchaseRequests', 'request']);
export const getResponses = (state) =>
  state.getIn(['purchaseRequests', 'responses']);
export const getSize = (state) => state.getIn(['purchaseRequests', 'size']);
export const getLimit = (state) => state.getIn(['purchaseRequests', 'limit']);
export const getOffset = (state) => state.getIn(['purchaseRequests', 'offset']);
export const getOrder = (state) => state.getIn(['purchaseRequests', 'orderBy']);
export const getDirection = (state) =>
  state.getIn(['purchaseRequests', 'direction']);
export const getSelectedCategoriesId = (state) =>
  state.getIn(['purchaseRequests', 'selectedCategoriesId']);
export const getSearch = (state) => state.getIn(['purchaseRequests', 'search']);
export const getShowMode = (state) =>
  state.getIn(['purchaseRequests', 'showMode']);
