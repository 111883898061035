import { List, fromJS } from 'immutable';

const initState = () => List();

const setPublicPriceCategories = (categories, { item }) =>
  categories.clear().merge(fromJS(item));

const selectCategory = (categories, { categoryId }) => {
  const categoryIndex = categories.findIndex(
    (category) => category.get('id') === categoryId
  );
  return categories.setIn([categoryIndex, 'selected'], true);
};

const clearSelectCategory = (categories) =>
  categories.map((category) => category.set('selected', false));

const publicPricesCategories = (state = initState(), { type, payload }) => {
  switch (type) {
    case 'PUBLIC_PRICE:GET_CATEGORIES:SUCCESS':
      return clearSelectCategory(setPublicPriceCategories(state, payload));
    case 'PUBLIC_PRICE:SELECT_CATEGORY':
      return selectCategory(state, payload);
    case 'PUBLIC_PRICE:CLEAR_SELECT_CATEGORY':
      return clearSelectCategory(state);
    default:
      return state;
  }
};

export default publicPricesCategories;
