import { List, Set } from 'immutable';
import { loadTable } from '../table';
import { clearValidatorState, validate } from '../../validator';
import { showModal } from '../modal';
import { updateProductFieldNow, addProductMedia } from '../product';

// VALIDATION rules
const validationRules = {
  src: 'url'
};

export const loadProductMedia = (mediaType, item) => ({
  type: 'PRODUCT_MEDIA_LOAD',
  mediaType,
  item
});

export const trimProductMediaField = (mediaType, field) => ({
  type: 'PRODUCT_MEDIA_TRIM_FIELD',
  mediaType,
  field
});

export const updateProductMediaField =
  (mediaType, field, value) => (dispatch) => {
    validate(value, validationRules[field], dispatch, field);

    dispatch({
      type: 'PRODUCT_MEDIA_UPDATE_FIELD',
      mediaType,
      field,
      value
    });
  };

export const getProductMedia = () => (dispatch, getState) => {
  const media = (
    getState().getIn(['product', 'item', 'attributes', 'media']) || List()
  ).toJS();
  const video = media.filter((mediaItem) => mediaItem.type === 'youtube');
  const audio = media.filter((mediaItem) => mediaItem.type === 'soundcloud');
  dispatch(loadTable(video, 'productVideo'));
  dispatch(loadTable(audio, 'productAudio'));
};

export const deleteSelectedProductMedia = () => (dispatch, getState) => {
  const media = (
    getState().getIn(['product', 'item', 'attributes', 'media']) || List()
  ).toJS();
  const selected = (
    getState().getIn(['tables', 'productVideo', 'selected']) || Set()
  ).concat(getState().getIn(['tables', 'productAudio', 'selected']) || Set());
  const newMedia = media.filter(
    (mediaItem) => !selected.includes(mediaItem._id)
  );
  dispatch(updateProductFieldNow('media', newMedia));
  dispatch(getProductMedia());
};

export const addProductVideo = () => (dispatch) => {
  clearValidatorState(dispatch);
  dispatch(loadProductMedia('video', {}));
  dispatch(showModal('LOAD_PRODUCT_VIDEO'));
};

export const addProductAudio = () => (dispatch) => {
  clearValidatorState(dispatch);
  dispatch(loadProductMedia('audio', {}));
  dispatch(showModal('LOAD_PRODUCT_AUDIO'));
};

// TODO: not need now
// const addProductMedia = media =>
//   (dispatch, getState) => {
//     const productMedia = (getState().getIn(['product', 'item', 'attributes', 'media']) || List())
//       .toJS();
//     productMedia.unshift(media);
//     dispatch(updateProductFieldNow('media', productMedia));
//     dispatch(getProductMedia());
//   };

// const uploadProductMedia = (url, uploadMediaApi) =>
//   dispatch => {
//     uploadMediaApi(url)
//       .then(([json, ok]) => {
//         if (ok) {
//           dispatch(addProductMedia(json.data.attributes));
//           dispatch(hideModal());
//         } else {
//           const { code, detail } = json.errors[0];
//           dispatch(updateValidatorState('src', false, code, { detail }));
//         }
//       })
//       .catch(e => console.error('FAILED UPLOAD MEDIA', e));
//   };

export const uploadProductVideo = (id, src) =>
  addProductMedia(id, 'video', src);
export const uploadProductAudio = (id, src) =>
  addProductMedia(id, 'audio', src);
