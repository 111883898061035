import React from 'react';
import { connect } from 'react-redux';
import { translate } from 'react-i18next';
import { bindActionCreators } from 'redux';

import ModalSimpleSubmit from '../components/Modal/ModalSimpleSubmit';

import store from '../storeSections';

import * as modalAction from '../action-creators/modal';
import { copyOrderProduct } from '../action-creators/orders';
import * as servicesActions from '../action-creators/services';

const ContModalConfirmCopyOrdersToSuppliers = ({
  t,
  hideModalDialog,
  order,
  gaSend,
  user
}) => (
  <ModalSimpleSubmit
    title="Copy order"
    hideModalDialog={() => hideModalDialog()}
    submit={async () => {
      await gaSend({
        category: 'Customer',
        action: 'create_copy_order',
        label: user.get('email')
      });
      return copyOrderProduct({ id: order.get('id') });
    }}
    onSubmited={() => hideModalDialog()}
    text={t('To create a new order based on the order №', {
      number: order.get('number')
    })}
    textBtnConfirm="Copy"
  />
);

export default connect(
  store((state) => ({
    user: state.user.data()
  })),
  (dispatch) =>
    bindActionCreators(
      {
        gaSend: servicesActions.gaSend,
        hideModalDialog: modalAction.hideModal,
        copyOrderProduct
      },
      dispatch
    )
)(translate(['ui'], { wait: true })(ContModalConfirmCopyOrdersToSuppliers));
