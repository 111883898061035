import React, { useCallback } from 'react';
import { Switch as MUISwitch } from '@mui/material';
import cn from 'classnames';
import { isFunction } from '@/lib/utils';
import styles from './Switch.module.scss';
import { SwitchProps } from './Switch.types';

export function Switch({ checked, onSwitch, className }: SwitchProps) {
  const handleToggle = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      if (onSwitch && isFunction(onSwitch)) {
        onSwitch(event.target.checked || false);
      }
    },
    [onSwitch]
  );

  return (
    <div className={cn(styles.switch, className)} data-testid="switch">
      <MUISwitch checked={checked} onChange={handleToggle} disableRipple />
    </div>
  );
}
