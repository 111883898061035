import React from 'react';
import styled from 'styled-components';
import ContactsList from '../../../../ChatWidget/sidebar/list/contacts';

const config = {
  clickByRow: 'view-profile-widget',
  extra: {
    list: [
      { icon: 'person', value: 'view-profile-widget' },
      {
        conditionName: 'block-person',
        items: [
          { icon: 'unblock', value: 'unblock' },
          { icon: 'block', value: 'block', color: 'red' }
        ]
      },
      { icon: 'delete', value: 'delete', color: 'red' }
    ],
    adminList: [{ icon: 'person', value: 'view-profile-widget' }]
  }
};

const Contacts = () => (
  <ContactsListWrapper>
    <ContactsList config={config} />
  </ContactsListWrapper>
);

const ContactsListWrapper = styled.div`
  height: 100%;
  width: 100%;

  .cws-contact-list {
    ::-webkit-scrollbar {
      height: 4px;
      width: 4px;
    }
  }

  .cws-contact-item__buttons {
    padding-right: 16px;
  }
`;

export default Contacts;
