import React, { Component } from 'react';
import { hasPath, isNil } from 'ramda';
import PropTypes from 'prop-types';
import block from 'bem-cn-lite';
import { translate } from 'react-i18next';
import {
  Modal,
  Col,
  Row,
  Form,
  Button,
  FormGroup,
  ControlLabel,
  FormControl
} from 'react-bootstrap';
import './styles/modal_theme_default.styl';
import './styles/modal_type_send-order.styl';

import { ModalContainer } from './elements';

const b = block('modal-container');

const getOrderId = (order) => {
  if (order?.size !== 0) {
    return order.get('id');
  }
  return undefined;
};

const getComment = (user, order) => {
  const unsentComments =
    JSON.parse(localStorage.getItem('unsentOrdersSubmitComments')) || {};

  const currentUserId = user.get('employeeId');
  const orderId = getOrderId(order);

  if (
    isNil(currentUserId) ||
    isNil(orderId) ||
    !hasPath([currentUserId.toString(), orderId.toString()], unsentComments)
  ) {
    return '';
  }
  return unsentComments[currentUserId][orderId];
};

class ModalSendOrderToSupplier extends Component {
  constructor(props) {
    super(props);
    this.state = {
      comment: getComment(props.user, props.order),
      submitting: false
    };
    this.setComment = this.setComment.bind(this);
  }

  getChildContext() {
    return { b };
  }

  onClick = () => {
    const {
      submit,
      order,
      splitOrder,
      combineOrdersStocks,
      deliveryDate,
      consignee,
      hideModalDialog,
      onSubmited = () => {}
    } = this.props;
    const { comment } = this.state;

    this.setState(
      {
        submitting: true
      },
      () => {
        submit(
          order.get('id'),
          comment,
          splitOrder,
          combineOrdersStocks,
          deliveryDate,
          consignee
        )
          .then(() => {
            this.setState({
              submitting: false
            });
            hideModalDialog();
            onSubmited();
          })
          .catch((error) => {
            this.setState({
              submitting: false
            });
            console.error(error);
          });
      }
    );
  };

  setComment(e) {
    this.setState({ comment: e.target.value });
  }

  render() {
    const {
      t,
      title,
      hideModalDialog,
      sum,
      price,
      currency,
      gaPageView,
      gaSend,
      user
    } = this.props;

    return (
      <Modal id="sendOrderToSupplier" onHide={() => hideModalDialog()} show>
        <ModalContainer mods={{ type: 'send-order', theme: 'default' }}>
          <Modal.Header>
            <Modal.Title>
              {t(title)}
              <span
                className="modal-header-cross pull-right"
                onClick={() => hideModalDialog()}
              />
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Row>
                <Col md={12}>
                  <h4>{t('Send order to the supplier')}</h4>
                </Col>
              </Row>
              <Row>
                <Col md={12}>
                  <p className={b('row')}>
                    {`${t('Supplier')}:`}
                    <span style={{ paddingLeft: 5 }}>
                      {price.get('supplierInfo.name')}
                    </span>
                  </p>
                </Col>
              </Row>
              <Row>
                <Col md={12}>
                  <p className={b('row')}>
                    {`${t('Order amount')}:`}
                    <span
                      style={{ paddingLeft: 5 }}>{`${sum} ${currency}`}</span>
                  </p>
                </Col>
              </Row>
            </Form>
            <Form>
              <FormGroup controlId="comment">
                <ControlLabel
                  style={{
                    fontWeight: 'normal',
                    fontSize: '14px'
                  }}>
                  {t('You can add comment to your order')}
                </ControlLabel>
                <FormControl
                  componentClass="textarea"
                  rows="5"
                  maxLength={500}
                  value={this.state.comment}
                  onChange={this.setComment}
                />
                <h6 className="pull-right">
                  <small>
                    {this.state.comment.length}
                    /500
                  </small>
                </h6>
              </FormGroup>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button
              id="cancel"
              className="pull-left"
              onClick={() => {
                gaPageView('order_send_cancel');
                gaSend({
                  category: 'Customer',
                  action: 'order_send_cancel',
                  label: user.get('email')
                });
                hideModalDialog();
              }}>
              {t('Cancel')}
            </Button>
            <Button
              id="saveCharacteristicButton"
              bsStyle="primary"
              disabled={this.state.submitting}
              onClick={this.onClick}>
              {t('Send')}
            </Button>
          </Modal.Footer>
        </ModalContainer>
      </Modal>
    );
  }
}

ModalSendOrderToSupplier.propTypes = {
  t: PropTypes.func.isRequired
};

ModalSendOrderToSupplier.childContextTypes = {
  b: PropTypes.func
};

export default translate(['ui'], { wait: true })(ModalSendOrderToSupplier);
