const { makePolicy } = require('../../data/policy');

// Section name
const PRICE_LISTS = 'PRICE_LISTS';

// Use cases
const VIEW_PRICE_LISTS = 'VIEW_PRICE_LISTS';
const VIEW_PRODUCTS = 'VIEW_PRODUCTS';
const VIEW_PRICE_LIST_PROPERTIES = 'VIEW_PRICE_LIST_PROPERTIES';
const UPDATE_PRICE_LIST = 'UPDATE_PRICE_LIST';
const IMPORT_PRODUCTS = 'IMPORT_PRODUCTS ';
const EXPORT_PRODUCTS = 'EXPORT_PRODUCTS';
const PROVIDE_ACCESS = 'PROVIDE_ACCESS';
const ADD_PRODUCT_PRICE_CATEGORY = 'ADD_PRODUCT_PRICE_CATEGORY';
const EDIT_PRODUCT_PRICE_CATEGORY = 'EDIT_PRODUCT_PRICE_CATEGORY';
const DELETE_PRODUCT_PRICE_CATEGORY = 'DELETE_PRODUCT_PRICE_CATEGORY';
const ADD_PRODUCT = 'ADD_PRODUCT';
const REMOVE_PRODUCT = 'REMOVE_PRODUCT';
const ADD_PRICE_LIST = 'ADD_PRICE_LIST';
const DELETE_PRICE_LIST = 'DELETE_PRICE_LIST';

const settings = [
  makePolicy(PRICE_LISTS, VIEW_PRICE_LISTS, {
    reader: true,
    writer: true,
    advanced: true
  }),
  makePolicy(PRICE_LISTS, VIEW_PRODUCTS, {
    reader: true,
    writer: true,
    advanced: true
  }),
  makePolicy(PRICE_LISTS, VIEW_PRICE_LIST_PROPERTIES, {
    reader: true,
    writer: true,
    advanced: true
  }),
  makePolicy(PRICE_LISTS, UPDATE_PRICE_LIST, {
    reader: false,
    writer: true,
    advanced: true
  }),
  makePolicy(PRICE_LISTS, IMPORT_PRODUCTS, {
    reader: false,
    writer: true,
    advanced: true
  }),
  makePolicy(PRICE_LISTS, EXPORT_PRODUCTS, {
    reader: false,
    writer: true,
    advanced: true
  }),
  makePolicy(PRICE_LISTS, PROVIDE_ACCESS, {
    reader: false,
    writer: true,
    advanced: true
  }),
  makePolicy(PRICE_LISTS, ADD_PRODUCT_PRICE_CATEGORY, {
    reader: false,
    writer: true,
    advanced: true
  }),
  makePolicy(PRICE_LISTS, EDIT_PRODUCT_PRICE_CATEGORY, {
    reader: false,
    writer: true,
    advanced: true
  }),
  makePolicy(PRICE_LISTS, DELETE_PRODUCT_PRICE_CATEGORY, {
    reader: false,
    writer: true,
    advanced: true
  }),
  makePolicy(PRICE_LISTS, ADD_PRODUCT, {
    reader: false,
    writer: true,
    advanced: true
  }),
  makePolicy(PRICE_LISTS, REMOVE_PRODUCT, {
    reader: false,
    writer: true,
    advanced: true
  }),
  makePolicy(PRICE_LISTS, ADD_PRICE_LIST, {
    reader: false,
    writer: true,
    advanced: true
  }),
  makePolicy(PRICE_LISTS, DELETE_PRICE_LIST, {
    reader: false,
    writer: false,
    advanced: true
  })
];

module.exports = {
  PRICE_LISTS,
  VIEW_PRICE_LISTS,
  VIEW_PRODUCTS,
  VIEW_PRICE_LIST_PROPERTIES,
  UPDATE_PRICE_LIST,
  IMPORT_PRODUCTS,
  EXPORT_PRODUCTS,
  PROVIDE_ACCESS,
  ADD_PRODUCT_PRICE_CATEGORY,
  EDIT_PRODUCT_PRICE_CATEGORY,
  DELETE_PRODUCT_PRICE_CATEGORY,
  ADD_PRODUCT,
  REMOVE_PRODUCT,
  ADD_PRICE_LIST,
  DELETE_PRICE_LIST,
  settings
};
