import React, { useCallback } from 'react';
import { useActions, useTranslate } from '@hooks';
import ModalSubmitWithComment from '../ModalSubmitWithComment';
import * as storage from '../../PurchaseRequests/Responses/storage';

const ModalRefuseRespond = ({ requestId, responseId, submit }) => {
  const t = useTranslate();
  const { refuseRespond } = useActions(storage);
  const onSubmit = useCallback((text) => {
    const callback = submit || refuseRespond;
    callback({ requestId, responseId, text });
  }, []);

  return (
    <ModalSubmitWithComment
      title="refuse_to_respond"
      text="confirm_sending_refusal"
      defaultInputText={t('default_refusal_text', { requestNumber: requestId })}
      onSubmit={onSubmit}
      maxInputSymbols={500}
    />
  );
};

export default ModalRefuseRespond;
