/* eslint-disable max-len */
import React from 'react';

const CompanyIcon = () => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      className="snm-icon__path"
      d="M0 5C0 3.89543 0.895431 3 2 3H16C17.1046 3 18 3.89543 18 5V10C12.158 11.9473 5.842 11.9473 0 10V5Z"
      fill="#C6CAE3"
    />
    <path
      className="snm-icon__path"
      d="M0 12C5.88231 13.6807 12.1177 13.6807 18 12V16C18 17.1046 17.1046 18 16 18H2C0.89543 18 0 17.1046 0 16V12Z"
      fill="#C6CAE3"
    />
    <path
      className="snm-icon__path"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.5 2H7.5C6.94772 2 6.5 2.44772 6.5 3C6.5 3.55228 6.94772 4 7.5 4H10.5C11.0523 4 11.5 3.55228 11.5 3C11.5 2.44772 11.0523 2 10.5 2ZM7.5 0C5.84315 0 4.5 1.34315 4.5 3C4.5 4.65685 5.84315 6 7.5 6H10.5C12.1569 6 13.5 4.65685 13.5 3C13.5 1.34315 12.1569 0 10.5 0H7.5Z"
      fill="#C6CAE3"
    />
  </svg>
);

export default CompanyIcon;
