import React from 'react';
import { translate } from 'react-i18next';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Modal } from 'react-bootstrap';
import styled from 'styled-components';

import { ModalContainer } from './elements';
import WarningIcon from './icons/warning';
import CrossIcon from './icons/cross';

import './styles/modal_theme_default.styl';
import * as modalActions from '../../action-creators/modal';
import { Text } from '../../styledComponents/atoms';

const ModalNotificationNoInternetConnection = ({
  t,
  tkey,
  hideModalDialog
}) => (
  <ModalWrapper
    id="no-internet-connection-modal"
    onHide={() => hideModalDialog()}
    show
    bsSize="small"
    backdropClassName="no-internet-connection-modal-backdrop">
    <ModalContainer mods={{ theme: 'default' }}>
      <ModalHeader>
        <Modal.Title>
          <CrossWrapper onClick={hideModalDialog}>
            <CrossIcon />
          </CrossWrapper>
        </Modal.Title>
      </ModalHeader>
      <ModalBody>
        <ContentWrapper>
          <WarningIcon />
          <TextWrapper noMargin>{t(tkey)}</TextWrapper>
        </ContentWrapper>
      </ModalBody>
    </ModalContainer>
  </ModalWrapper>
);

const ModalWrapper = styled(Modal)`
  display: flex !important;

  .modal-dialog {
    margin: auto;
    max-width: 267px;
  }

  .modal-content {
    border: 2px solid ${(p) => p.theme.colors.gray.disabled};
    border-radius: 4px;
    box-shadow: none;
  }
`;

const ModalHeader = styled(Modal.Header)`
  padding: 0;
`;

const ModalBody = styled(Modal.Body)`
  padding: 0 16px 20px;
`;

const ContentWrapper = styled.div`
  display: grid;
  grid-gap: 8px;
  grid-auto-flow: column;
  justify-content: flex-start;
`;

const CrossWrapper = styled.div`
  display: flex;
  float: right;
  margin: 9px 9px 0 0;
  cursor: pointer;
`;

const TextWrapper = styled(Text)`
  color: ${(p) => p.theme.colors.gray.additional};
`;

export default connect(null, (dispatch) =>
  bindActionCreators(
    {
      hideModalDialog: modalActions.hideModalDialog
    },
    dispatch
  )
)(translate(['ui'])(ModalNotificationNoInternetConnection));
