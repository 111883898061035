import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import WindowResizer from '../../WindowResizer';
import MessengerButton from '../../MessengerButton';
import Messenger from './Messenger';
import { getActiveTab, getIsShown } from '../../ChatWidget/getter';
import { getDataDefault, setData } from '../../../storage/lib/LS';
import { handlerChatWidget } from '../../ChatWidget/useCases';
import { changeTab } from '../../ChatWidget/modules/tabs/wrapper';
import { isCurrentPath } from '../../../utils';
import { getIsUserUploaded } from '../../../storeGetters';
import { isMessengerService } from '../../../lib/goodwix';
import './style.css';

const LS_KEY = 'chatWidgetSize';
const DEFAULT_SIZE = { width: 330, height: 480 };
const getSize = () => getDataDefault(LS_KEY, DEFAULT_SIZE);

const setSize = (size) => {
  setData(LS_KEY, size);
};

export const MessengerWidgetWrapper = () => {
  const dispatch = useDispatch();
  const chatWidgetShown = useSelector(getIsShown);
  const activeTab = useSelector(getActiveTab);
  const isUploaded = useSelector(getIsUserUploaded);

  useEffect(() => {
    dispatch(changeTab('chats'));
    dispatch(handlerChatWidget('init', { activeTab: activeTab || 'chats' }));
  }, []);

  if (!isUploaded && isCurrentPath('/prices/:priceId/share/:token'))
    return null;

  return (
    <MessengerWidgetContainer
      isShownChatWidget={isMessengerService() || chatWidgetShown}>
      {chatWidgetShown ? (
        <WindowResizer defaultSize={getSize()} saveSize={setSize}>
          <Messenger />
        </WindowResizer>
      ) : (
        <MessengerButton />
      )}
    </MessengerWidgetContainer>
  );
};

const MessengerWidgetContainer = styled.div((props) => ({
  pointerEvents: props.isShownChatWidget ? 'visible' : 'none',
  position: 'absolute',
  bottom: '14px',
  right: '14px',
  zIndex: 998
}));
